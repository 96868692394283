import * as actions from '@modules/label/actions/labelActionsTypes';
import { LabelActionType } from '@modules/label/actions/labelActionsTypes';

export function onGetLabel(): actions.GetLabelAction {
  return {
    type: LabelActionType.GET_LABEL
  };
}

export function getLabelRequest(language: string): actions.GetLabelRequestAction {
  return {
    type: LabelActionType.GET_LABEL_REQUEST,
    language
  };
}

export function getLabelSuccess(labels: any): actions.GetLabelSuccessAction {
  return {
    type: LabelActionType.GET_LABEL_SUCCESS,
    labels
  };
}

export function getLabelFailure(
  error: Error | string,
): actions.GetLabelFailureAction {
  return {
    type: LabelActionType.GET_LABEL_FAILURE,
    error
  };
}

export function onPutLanguageRequest(language: string): actions.PutLanguageRequestAction {
  return {
    type: LabelActionType.PUT_LANGUAGE_REQUEST,
    language
  };
}
export function onPutLanguageSuccess(language: string): actions.PutLanguageSuccessAction {
  return {
    type: LabelActionType.PUT_LANGUAGE_SUCCESS,
    language
  };
}
