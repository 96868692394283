import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

export const useStyles = () => {
  const theme = useTheme();

  const styles = useMemo(
    () =>
      StyleSheet.create({
        expendableLineContainer: {
          padding: 1,
        },
        expendableLineHeader: {
          marginBottom: theme.metrics.spacing.none,
          paddingHorizontal: theme.metrics.spacing.none,
          borderBottomWidth: 1,
          borderColor: theme.colors.basics.grey.c200,
        },
        expendableLineIcon: {
          color: theme.colors.basics.grey.c900
        },
        expendableLineTitle: {
          color: theme.colors.basics.grey.c900
        },
        expendableLineContent: {
          borderBottomWidth: 0
        },
        htmlStyledRenderer: {
          marginVertical: theme.metrics.spacing.m
        },
        htmlStyledRendererBaseFont: {
          fontFamily: theme.fonts.fontFamily.light,
        }
      }),
    [theme]
  );

  return styles;
};
