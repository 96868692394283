import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useScreenSizes, useTheme } from '@ere-uilib/styles';

export const useStyles = () => {
  const theme = useTheme();
  const { isMobile } = useScreenSizes();

  const styles = useMemo(
    () =>
      StyleSheet.create({
        mainContainer: {
          flexDirection: isMobile ? 'column' : 'row',
          minHeight: 150,
          position: 'relative'
        },
        button: {
          marginTop: theme.metrics.spacing.l,
        },
        leftContainer: {
          flex: 1,
          alignItems: 'flex-start'
        },
        rightContainer: {
          paddingHorizontal: theme.metrics.spacing.xm,
          justifyContent: 'center',
          alignItems: 'center',
        },
        contentContainer: {
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
          top: 22
        },
        iconStyle: {
          marginBottom: theme.metrics.spacing.xm
        },
        abondementContainer: {
          flexDirection: 'row',
          marginBottom: theme.metrics.spacing.xm
        },
        abondementPart: {
          width: 120,
          height: 41,
          justifyContent: 'center',
          alignItems: 'center'
        },
        abondementMiddleBar: {
          borderRightWidth: 1,
          borderRightColor: theme.colors.borders.fields.c500,
          height: 35
        },
        abondementMaxContainer: {}
      }),
    [theme, isMobile]
  );

  return styles;
};
