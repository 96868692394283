import React, { useCallback } from 'react'
import { Pressable } from 'react-native'
import { Text } from '@ere-uilib/atoms'
import { DashboardContentWrapperCardComponent } from '@ere-uilib/molecules'
import { useTranslation } from '@ere-uilib/translations'
import { RecentOperationsCardProps } from './interfaces'
import { useStyles } from './useStyle'
import { RecentOperationsCardContent } from './components'
import { RecentOperationsLoader } from '../Loaders'

export const RecentOperationsCard = ({
  operations,
  error,
  onGetOperations,
  onPressSeeAll,
  isLoading
}: RecentOperationsCardProps) => {
  const { formatMessage } = useTranslation()
  const styles = useStyles()

  const renderLink = useCallback(() => {
    return (
      <Pressable onPress={() => onPressSeeAll('Account')}>
        <Text
          accessibilityLabel="operation-status_see-all"
          style={styles.viewAllText}
          testID="operation-status_see-all"
          variant="t3"
          weight="regular">
          {formatMessage({ id: 'operation-status_see-all' })}
        </Text>
      </Pressable>
    )
  }, [formatMessage, onPressSeeAll, styles])

  const renderLoader = useCallback(() => <RecentOperationsLoader />, [])

  return (
    <DashboardContentWrapperCardComponent
      containerStyle={styles.dashboardContainerStyle}
      errorRetryAction={onGetOperations}
      isError={error?.message !== ''}
      renderLoader={renderLoader}
      isLoading={isLoading}
      renderLink={renderLink}
      testId="operation-status_title_testId"
      title={formatMessage({ id: 'operation-status_title' })}>
      <RecentOperationsCardContent operations={operations} />
    </DashboardContentWrapperCardComponent>
  )
}
