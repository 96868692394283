import React from 'react';
import { Animated, StyleProp, View, ViewStyle } from 'react-native';

import { createUseStyles, ThemeType, useTheme } from '@ere-uilib/styles';

interface Props {
  progress: number;
  progressBarColor: string;
  staticProgressBarContainerStyle?: StyleProp<ViewStyle>;
  staticProgressBarStyle?: StyleProp<ViewStyle>;
}

type StaticProgressBarStyles = {
  staticProgressBarContainerStyle?: StyleProp<ViewStyle>;
  staticProgressBarStyle?: StyleProp<ViewStyle>;
};

type StylesContext = { theme: ThemeType };

export const StaticProgressBar: React.FC<React.PropsWithChildren<Props>> = props => {

  const { progress, progressBarColor, staticProgressBarStyle, staticProgressBarContainerStyle } = props;
  const theme = useTheme();
  const styles = useStyles(
    { theme },
    { staticProgressBarContainerStyle, staticProgressBarStyle },
  );

  return (
    <View style={styles.staticProgressBarContainerStyle}>
      <View style={styles.staticProgressBarStyle}>
        <Animated.View
          style={{
            backgroundColor: progressBarColor,
            borderTopLeftRadius: theme.metrics.borderRadius.m,
            borderBottomLeftRadius: theme.metrics.borderRadius.m,
            width: `${progress}%`
          }}
        />
      </View>
    </View>
  );
};

const getStyles = (
  context?: StylesContext,
  style?: StaticProgressBarStyles,
): StaticProgressBarStyles => ({
  staticProgressBarContainerStyle: [
    {
      width: '100%',
      height: 20,
      borderRadius: 30
    },
    style?.staticProgressBarContainerStyle
  ],
  staticProgressBarStyle: [
    {
      width: '100%',
      flexDirection: 'row',
      height: 20,
      backgroundColor: context?.theme.colors.basics.grey.c200,
      borderColor: 'transparent',
      borderWidth: 0,
      borderRadius: 30
    },
    style?.staticProgressBarStyle
  ]
});

const useStyles = createUseStyles(getStyles);
