import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useScreenSizes, useTheme } from '@ere-uilib/styles';

export function useStyles() {
  const theme = useTheme();
  const { isDesktop } = useScreenSizes();

  const styles = useMemo(() => StyleSheet.create({
    content: {
      flex: 2.1,
      marginHorizontal: isDesktop ? theme.metrics.spacing.xxl : 0,
    },
    errorViewStyle: {
      marginBottom: theme.metrics.spacing.xm,
    },
    buttonsMobileRowStyle: {
      flexDirection: 'column',
    },
    contentContainer: {},
    bottomButtons: {
      marginTop: theme.metrics.spacing.l,
    },
    errorNotification: {
      marginTop: theme.metrics.spacing.l,
    },
  }), [theme, isDesktop]);

  return styles;
}
