import { Platform } from 'react-native';
import { Styles, StylesContext } from './types';

export const getStyles = (
  context?: StylesContext,
  style?: Styles,
): Styles => ({
  mainStyle: [{
    alignItems: 'flex-start',
    borderRadius: context?.theme.metrics.borderRadius.xs,
    backgroundColor: context?.theme.colors.basics.white,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 9,
    shadowOpacity: 0.85,
    shadowColor: context?.theme.colors.notifications.shadow,
    elevation: 7,
    padding: context?.theme.metrics.spacing.m,
    minHeight: 'auto',
  },
  style?.mainStyle],
  iconContainerStyle: [{
    marginRight: context?.theme.metrics.spacing.m,
    width: 40,
    justifyContent: 'center',
    alignItems: 'center',
    height: 40,
    borderRadius: context?.theme.metrics.borderRadius.xs,
    backgroundColor: context?.iconContainerBgColor,
  },
  context?.isLarge
    ? {
      width: 60,
      height: 60,
    } : {
      width: 40,
      height: 40,
    },
  style?.iconContainerStyle],
  textContainerStyle: [{
    alignSelf: 'flex-start',
    marginTop: context?.theme.metrics.spacing.xm,
  }, style?.textContainerStyle],
  titleStyle: [{
    marginBottom: context?.theme.metrics.spacing.xs,
    marginLeft: context?.theme.metrics.spacing.m,
    alignSelf: 'center',
    width: '90%',
  }],
  headerStyle: [{
    display: 'flex',
    flexDirection: 'row',
    minHeight: 'auto',
    marginRight: context?.theme.metrics.spacing.m,
    paddingEnd: Platform.OS !== "web" ? context?.theme.metrics.spacing.xxl : 0,
    width: '100%',
  },
  style?.headerStyle],
  chevronStyle: [{
    alignSelf: 'center',
  },
  style?.chevronStyle],
});
