import React, { useCallback, useState } from 'react';
import { View } from 'react-native';

import { Text } from '@ere-uilib/atoms';
import { FiletStepper, Pen } from '@ere-uilib/molecules';
import { HelpPoint } from '@ere-uilib/organisms';
import { useTheme } from '@ere-uilib/styles';

import { Props, StyleProps } from './types';
import { useStyles } from './useStyles';

export const FiletStepperQuestionAnswerWrapper: React.FC<React.PropsWithChildren<Props & StyleProps>> = ({
  title,
  value,
  description,
  isEditing = true,
  disableLine = false,
  children,
  showHelpPoint = false,
  helpPointContentHtml = '',
  helpPointModalTitle = '',
  helpPointContentTitle = '',
  onEdit,
  containerStyle,
  childrenContainerStyle
}) => {
  const styles = useStyles();
  const [] = useState();
  const theme = useTheme();

  const renderAction = useCallback(
    () => (
      <View style={styles.actionContainer}>
        {isEditing && showHelpPoint && (
          <HelpPoint
            contentHtml={helpPointContentHtml}
            contentTitle={helpPointContentTitle}
            iconSize={theme.metrics.iconSizes.xxm}
            modalTitle={helpPointModalTitle}
          />
        )}
        {!isEditing && <Pen onPress={onEdit} />}
      </View>
    ),
    [
      helpPointContentHtml,
      helpPointContentTitle,
      helpPointModalTitle,
      isEditing,
      onEdit,
      showHelpPoint,
      styles,
      theme
    ]
  );

  return (
    <View style={containerStyle}>
      <FiletStepper
        containerStyle={styles.container}
        disableLine={disableLine || isEditing}>
        <Text
          style={styles.text}
          variant="t3"
          weight={isEditing ? 'bold' : 'light'}>
          {isEditing ? description : title + ' '}
          {!isEditing && (
            <Text
              variant="t3"
              weight="bold">
              {value}
            </Text>
          )}
          {(!isEditing || showHelpPoint) && renderAction()}
        </Text>
      </FiletStepper>
      {isEditing && (
        <View style={[styles.childrenContainer, childrenContainerStyle]}>{children}</View>
      )}
    </View>
  );
};
