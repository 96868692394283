import { connect } from 'react-redux';

import { getNewsFeedRequest } from '@modules/history/actions/historyActions';
import { HistoryActionsType } from '@modules/history/actions/historyActionsTypes';
import { AppState } from '@modules/reducers';
import {
  getNewsFeed,
  getHistoryError,
} from '@modules/history/selectors';

import { History } from './History.component';

const mapStateToProps = (state: AppState) => {
  return {
    newsFeedData: getNewsFeed(state),
    isLoading: state.isLoading[HistoryActionsType.GET_NEWS_FEED_REQUEST],
    error: getHistoryError(state),
  };
};

const mapDispatchToProps = ({
  getFeedNews: getNewsFeedRequest,
});

export const HistoryPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(History);
