import { StyleSheet } from 'react-native';

import { Context, Styles } from './interface';

export const getStyles = (
  context?: Context,
  style?: Styles,
): Styles => ({
  overlay: [
    {
      ...StyleSheet.absoluteFillObject,
      flex: 1,
    },
    style?.overlay,
  ],
  iconContainer: [
    {
      alignSelf: 'center',
    },
    style?.iconContainer,
  ],
  textContainer: [
    {
      marginTop: context?.theme.metrics.spacing.xm,
      marginBottom: context?.theme.metrics.spacing.l,
      alignItems: 'center',
      justifyContent: 'center',
    },
    style?.textContainer,
  ],
  title: [
    {
      alignItems: 'center',
      textAlign: 'center',
      marginBottom: context?.theme.metrics.spacing.s,
    },
    style?.title,
  ],
  text: [
    {
      textAlign: 'center',
    },
    style?.text,
  ],
  errorNotification: [
    {
      marginBottom: context?.theme.metrics.spacing.m,
    },
    style?.errorNotification,
  ],
  buttonsContainer: [
    {
      flexDirection: 'row',
      alignItems: 'center',
    },
    style?.buttonsContainer,
  ],
  button: [
    {
      flex: 1,
    },
    style?.button,
  ],
  firstButton: [
    {
      marginRight: context?.theme.metrics.spacing.m,
    },
    style?.firstButton,
  ],
});
