import React, { useCallback, useEffect, useState } from "react";
import { ColorValue, LayoutChangeEvent, View } from "react-native";

import { FontIcon, Title, Paragraph, Text } from "@ere-uilib/atoms";
import { IconEnum } from "@ere-uilib/enums";
import { useTheme } from "@ere-uilib/styles";

import { CardStepperStepProps, IconProps } from "./interfaces";
import { CardStepperStepStatus } from "./sharedInterfaces";
import { useStyles } from "./styles";

export const CardStepperStep: React.FC<React.PropsWithChildren<CardStepperStepProps>> = ({
  step,
  onChangeMeasure,
  style
}) => {
  const STEP_INDICATOR_HEIGHT = 30;
  const theme = useTheme();
  const styles = useStyles({
    style,
    STEP_INDICATOR_HEIGHT
  });
  const [indicatorTop, setIndicatorTop] = useState<number>(0);
  const [mainContentHeight, setMainContentHeight] = useState<number>(0);
  const [secondaryContentHeight, setSecondaryContentHeight] = useState<number>(0);

  const handleScondaryContentLayout = useCallback(({ nativeEvent }: LayoutChangeEvent) => {
    const { height = 0 } = nativeEvent?.layout ?? {};
    if (secondaryContentHeight !== height) {
      setSecondaryContentHeight(height)
    }
  }, [
    secondaryContentHeight
  ])

  const handleMainContentLayout = useCallback(({ nativeEvent }: LayoutChangeEvent) => {
    const { height = 0 } = nativeEvent?.layout ?? {};
    if (mainContentHeight !== height) {
      const center = height * 0.5 - STEP_INDICATOR_HEIGHT * 0.5;
      setIndicatorTop(center);
      setMainContentHeight(height);
    }
  }, [
    mainContentHeight
  ])

  const renderIcon = useCallback((status: CardStepperStepStatus) => {
    const iconProps: { [p: string]: IconProps } = {
      [CardStepperStepStatus.SCHEDULED]: {
        color: theme.colors.basics.grey.c500,
        name: IconEnum.TIME,
        size: theme.metrics.iconSizes.mplus,
      },
      [CardStepperStepStatus.PENDING]: {
        color: theme.colors.basics.white,
        name: IconEnum.PAUSE,
        size: theme.metrics.iconSizes.m,
      },
      [CardStepperStepStatus.IN_PROGRESS]: {
        color: theme.colors.basics.white,
        name: IconEnum.WAITING,
        size: theme.metrics.iconSizes.xm,
      },
      [CardStepperStepStatus.SUCCESS]: {
        color: theme.colors.basics.white,
        name: IconEnum.CHECK,
        size: theme.metrics.iconSizes.s,
      },
      [CardStepperStepStatus.FAILED]: {
        color: theme.colors.basics.white,
        name: IconEnum.CLOSE,
        size: theme.metrics.iconSizes.s,
      },
      [CardStepperStepStatus.CANCELED]: {
        color: theme.colors.basics.grey.c500,
        name: IconEnum.CLOSE,
        size: theme.metrics.iconSizes.s,
      },
    }
    const finalIconProps =  {
      color: step.customIconProps?.color || iconProps[status].color,
      name: step.customIconProps?.name || iconProps[status].name,
      size: step.customIconProps?.size || iconProps[status].size
    }

    return <FontIcon {...finalIconProps} />
  }, [theme, step.customIconProps])

  const renderStepIndicator = useCallback(() => {
    const backgroundColor: { [p: string]: ColorValue } = {
      [CardStepperStepStatus.SCHEDULED]: theme.colors.operationState.scheduled.c500,
      [CardStepperStepStatus.PENDING]: theme.colors.operationState.pending.c500,
      [CardStepperStepStatus.IN_PROGRESS]: theme.colors.operationState.inProgress.c500,
      [CardStepperStepStatus.SUCCESS]: theme.colors.operationState.success.c500,
      [CardStepperStepStatus.FAILED]: theme.colors.operationState.failed.c500,
      [CardStepperStepStatus.CANCELED]: theme.colors.operationState.canceled.c500,
    }
    const {
      backgroundColor: customBackgroundColor,
      size,
      color,
      text
    } = step.customIconProps ?? {}

    return <View
      style={[
        styles.stepIndicator,
        {
          top: indicatorTop,
          backgroundColor: customBackgroundColor || backgroundColor[step.status]
        }
      ]}
    >
      {!text ? 
        renderIcon(step.status)
        : <Text 
          style={{
            ...(!!size && { fontSize: size } ),
            ...(!!color && { color: color } )
          }}
          variant="t2"
        >
          {text}
        </Text>
      }
    </View>
  }, [
    indicatorTop, 
    renderIcon, 
    styles.stepIndicator, 
    theme.colors,
    step
  ])

  useEffect(() => {
    if ( mainContentHeight > 0 ) {
      onChangeMeasure({
        indicatorTop,
        indicatorHeight: STEP_INDICATOR_HEIGHT,
        mainContentHeight,
        secondaryContentHeight
      })
    }
  }, [
    indicatorTop,
    mainContentHeight,
    secondaryContentHeight
  ])

  return <View style={styles.stepContainer}>
    {renderStepIndicator()}
    <View style={styles.card}>
      <View onLayout={handleMainContentLayout}>
        {(!!step.title || !!step.description) && (
          <View style={styles.mainContent}>
            {!!step.title && (
              <Title 
                style={[
                  styles.title,
                  !step.description && styles.titleAlone
                ]}
                variant='t7'
              >
                {step.title}
              </Title>
            )}
            {!!step.description && (
              <Paragraph 
                variant='t3'
                weight="light"
              >
                {step.description}
              </Paragraph>
            )}
          </View>
        )}
        {step?.renderMainContent?.()}
      </View>
      <View onLayout={handleScondaryContentLayout}>
        {step?.renderSecondaryContent?.()}
      </View>
      {!!step.isDisabled && (
        <View
          pointerEvents={'none'}
          style={styles.cardDisabled}
        />
      )}
    </View>
  </View>
}
