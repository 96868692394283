import React from 'react';
import { Pressable as RNWebPressable, PressableProps as RNWebPressableProps } from 'react-native';

type PressableProps = RNWebPressableProps & {
    onHoverIn: (e: MouseEvent) => void;
    onHoverOut: (e: MouseEvent) => void;
    onPressIn?: (e: MouseEvent) => void;
    onPressOut?: (e: MouseEvent) => void;
};

export const Pressable = (props: PressableProps) => {
    return <RNWebPressable {...props}>{props.children}</RNWebPressable>;
};
