import {
  ArbitrationDisinvestmentsSavingContext,
  ArbitrationDisinvestmentsSavingStyle,
} from './interfaces';

export const getStyles = (
  context?: ArbitrationDisinvestmentsSavingContext,
  style?: ArbitrationDisinvestmentsSavingStyle
): ArbitrationDisinvestmentsSavingStyle => ({
  dashboardWrapper: [
    { backgroundColor: context?.theme.colors.basics.white },
    style?.dashboardWrapper,
  ],
  stepperContainer: [
    {
      alignItems: 'center',
    },
    style?.stepperContainer,
  ],
  stepperStyle: [
    {
      paddingBottom: context?.theme.metrics.spacing.s,

    },
    style?.stepperStyle,
  ],
  compareButtonContainerStyle: [
    {
      borderBottomWidth: 1,
      borderBottomColor: context?.theme.colors.basics.grey.c100,
      alignItems: 'center',
    },
    style?.compareButtonContainerStyle,
  ],
  compareButtonStyle: [
    {
      marginBottom: context?.theme.metrics.spacing.xm,
      marginTop: context?.theme.metrics.spacing.xm,
    },
    style?.compareButtonStyle,
  ],
  bottomActionsStyle: [
    {
      width: '100%',
    },
    !(context?.isMobile || context?.isTablet) && {
      marginTop: context?.theme.metrics.spacing.xl,
    },
    style?.bottomActionsStyle,
  ],
  bottomActionsButtonsContainerStyle: [
    {
      width: '100%',
      flexDirection: 'row',
    },
    style?.bottomActionsButtonsContainerStyle,
  ],
  arrowButtonStyle: [
    style?.arrowButtonStyle,
  ],
  simpleButtonStyle: [{
    marginLeft: context?.theme.metrics.spacing.m,
    flex: 1,
  },
  style?.simpleButtonStyle,
  ],
  savingContainer: [
    {

    },
    style?.savingContainer,
  ],
  wrapperContent: [
    {
      minWidth: '100%',
    },
    style?.wrapperContent,
  ],
  bodyContainer: [
    {
      marginTop: context?.theme.metrics.spacing.xm,
    },
    style?.bodyContainer,
  ],
  cardContainer: [
    {
      marginTop: context?.theme.metrics.spacing.l,
    },
    style?.cardContainer,
  ],

});
