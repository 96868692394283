import { useMemo } from 'react';
import { StyleSheet, ViewStyle } from 'react-native';

import { useScreenSizes, useTheme } from '@ere-uilib/styles';

interface UseStyle {
  container: ViewStyle;
}

export function useStyles(customStyles?: UseStyle) {
  const theme = useTheme();
  const { isMobile } = useScreenSizes();

  const styles = useMemo(() => StyleSheet.create({
    container: {
      backgroundColor: theme.colors.background.poppinFilter.c500,
      flex: 1,
      alignItems: 'center',
      justifyContent: isMobile ? 'flex-end' : 'center',
      ...customStyles?.container
    },
    modalFrame: {
      flex: isMobile ? 1 : undefined,
      minHeight: isMobile ? undefined : 600
    },
    modalContainer: {
      alignItems: 'center',
      paddingHorizontal: theme.metrics.spacing.s,
      textAlign: 'left'
    },
    mainContainer: { flexGrow: 1 },
    safeArea: {},
    iconContainerStyle: {
      padding: theme.metrics.spacing.s,
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: theme.metrics.spacing.xm,
      marginTop: theme.metrics.spacing.l
    },
    description: {
      fontSize: theme.fonts.fontSize.paragraph.t3,
      fontFamily: theme.fonts.fontFamily.light,
      textAlign: 'center'
    },
    title: {
      marginBottom: theme.metrics.spacing.xm
    },
    notificationWrapper: {
      marginTop: theme.metrics.spacing.xm,
      alignSelf: 'stretch'
    },
    buttonWrapper: {
      alignItems: isMobile ? undefined : 'center',
      margin: theme.metrics.spacing.xm,
    },
    otpFieldsWrapper: {
      alignItems: 'flex-end'
    },
    otpFields: {
      marginTop: theme.metrics.spacing.xm,
      marginBottom: theme.metrics.spacing.m
    },
    overlay: {
      ...StyleSheet.absoluteFillObject,
      flex: 1
    },
    notificationHard: {
      alignSelf: 'stretch'
    }
  }), [customStyles, theme]);

  return styles;
}
