import { useTheme } from "@ere-uilib/styles";
import { useMemo } from "react"
import { StyleSheet } from "react-native";

export const useStyles = () => {
  const theme = useTheme();
  return useMemo(() => StyleSheet.create({
    container: {
      paddingTop: theme.metrics.spacing.m,
      paddingBottom: theme.metrics.spacing.m,
      borderTopWidth: 1,
      borderTopColor: theme.colors.basics.grey.c200
    },
    innerContainerWithIcon: {
      flexDirection: 'row',
      alignItems: 'stretch',
      justifyContent: 'space-between',
    },
    iconWrapper: {
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: theme.metrics.spacing.s
    },
    textContainer: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    availabilityContainer: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.metrics.spacing.xs,
    },
    lastAvailabilityContainer: {
      marginBottom: 0,
    },
    columnTextWrapper: {
      flex: 1
    },
    amountLabel: {
      textAlign: "right",
    },
    listContainer: {
      marginLeft: theme.metrics.spacing.xl,
      marginTop: theme.metrics.spacing.s,
    },

  }), [theme]);
}
