import React, { useCallback, useMemo } from 'react'
import { Text } from '@ere-uilib/atoms'
import { DashboardContentWrapperCardComponent } from '@ere-uilib/molecules'
import { useTheme } from '@ere-uilib/styles'
import { useTranslation } from '@ere-uilib/translations'

import { GlobalSavingsProps } from './interface'
import { useStyles } from './useStyles'
import { GlobalSavingsContent } from './components'
import { View } from 'react-native'
import { RepartitionsLoader } from '../Loaders'

export const GlobalSavings: React.FC<GlobalSavingsProps> = ({
  error,
  allPlans = [],
  allocations,
  onGetAllocations,
  onPlanPress,
  isLoading
}) => {
  const theme = useTheme()
  const { formatMessage, getMessageRaw } = useTranslation()
  // previous allPlan was an object, as it has changed to array
  // if user have obsolete redux data we need to verify and prevent crash
  const allPlanIsArray = Array.isArray(allPlans)
  // test allPlans
  // get total amount
  const totalAmount = useMemo(() => {
    if (!allPlanIsArray) return 0

    let newTotalAmount = 0

    allPlans.forEach(plan => {
      newTotalAmount += plan.value
    })

    return newTotalAmount
  }, [allPlanIsArray, allPlans])

  // filter plan to display on graphs
  // prevent plan with percentage below 0
  let filteredPlans = useMemo(
    () => (allPlanIsArray ? allPlans.filter(plan => plan.value > totalAmount / 100) : []),
    [allPlanIsArray, allPlans, totalAmount]
  )
  // prevent remaining plans to be more than 6
  filteredPlans = filteredPlans.filter((_, index) => index < 6)
  // test if remaining plans are enough to display graph
  const isGraphDisplayed = totalAmount > 0 && filteredPlans?.length > 1

  const styles = useStyles(!!error?.message)

  const helpPoint = useMemo(
    () => ({
      contentHtml: getMessageRaw({
        id: 'Dashboard_CardRepartition_info_content',
        defaultMessage: ' '
      }),
      contentTitle: formatMessage({
        id: 'Dashboard_CardRepartition_info_content_title',
        defaultMessage: ' '
      }),
      modalTitle: formatMessage({ id: 'fund-card_help-info_menu' })
    }),
    [formatMessage, getMessageRaw]
  )

  const renderFooter = useCallback(
    () => (
      <Text
        style={styles.informationText}
        variant="t4"
        weight="light">
        {formatMessage({
          id: 'Dashboard_CardRepartition_Footer',
          defaultMessage: ' '
        })}
      </Text>
    ),
    [formatMessage, styles, theme]
  )

  const renderLoader = useCallback(() => <RepartitionsLoader />, [])

  return (
    <View style={styles.commonBlocStyle}>
      <DashboardContentWrapperCardComponent
        errorRetryAction={onGetAllocations}
        helpPoint={helpPoint}
        isError={!!error?.message}
        renderFooter={renderFooter}
        isLoading={isLoading}
        renderLoader={renderLoader}
        title={formatMessage({ id: 'Dashboard_CardRepartition_Title' })}
        testId="Dashboard_CardRepartition_Title">
        <GlobalSavingsContent
          isGraphDisplayed={isGraphDisplayed}
          filteredPlans={filteredPlans}
          allocations={allocations}
          onPlanPress={onPlanPress}
        />
      </DashboardContentWrapperCardComponent>
    </View>
  )
}
