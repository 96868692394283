import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { StyleProp, TextStyle, View, ViewStyle } from 'react-native';

import {
  HtmlRenderConnectedComponent,
  NotificationHard,
  OCDCard,
  SavingsOperationsHistoryCard,
  Text
} from '@components/index';
import { RouteNames } from '@constants/navigation';
import { NotificationIconTypeEnum } from '@ere-uilib/atoms';
import { NewsFeedTypeEnum, OperationsHistoryStatusEnum } from '@ere-uilib/enums';
import { useScreenSizes } from '@ere-uilib/styles';
import { createUseStyles } from '@ere-uilib/styles/createUseStyles';
import { useTheme } from '@ere-uilib/styles/theme';
import { useTranslation } from '@ere-uilib/translations';
import { OCDState, OperationsHistoryState } from '@ere-uilib/types/index';
import { NewsFeedTypeState } from '@modules/history/types';

interface DataProps {
  itemsList: [string, NewsFeedTypeState[]][];
}

type HistoryRenderItemsStyles = {
  containerStyle?: StyleProp<ViewStyle>;
  dateStyles?: StyleProp<TextStyle>;
  itemMarginBottom?: StyleProp<ViewStyle>;
  SavingsOperationsHistoryCardStyle?: StyleProp<ViewStyle>;
};

type HistoryRenderItemsContext = {
  theme?: any;
  isMobile?: boolean;
};

type Props = DataProps & HistoryRenderItemsContext & HistoryRenderItemsStyles;

export const HistoryRenderItems: React.FC<React.PropsWithChildren<Props>> = ({ itemsList }: Props) => {
  const { formatDate } = useTranslation();
  const navigation = useNavigation();
  const theme = useTheme();
  const { isMobile } = useScreenSizes();
  const styles = useStyles({ theme, isMobile }, {});

  const handleOnOperationCardPress = (operationId: string) => {
    navigation.navigate(RouteNames.OperationDetails, {
      id: operationId
    });
  };

  const renderNewsFeedByType = (newsFeed: NewsFeedTypeState, index: number) => {
    switch (newsFeed.type) {
      case NewsFeedTypeEnum.OCD:
        const ocdData:OCDState[] = [{
          contenu: newsFeed.content,
          appContenu: newsFeed.appContent,
          backgroundColor: newsFeed.backgroundColor,
          position: newsFeed.position || 1,
          image1SmallUrl: newsFeed.image1SmallUrl,
          image1LargeUrl: newsFeed.image1LargeUrl,
          image2SmallUrl: newsFeed.image2SmallUrl,
          image2LargeUrl: newsFeed.image2LargeUrl
        }];
        return (
          <OCDCard
            Cards={ocdData}
            cardSize="full"
            containerStyle={styles.itemMarginBottom}
            key={`OCD`+index}
          />
        );
      case NewsFeedTypeEnum.OPERATION:
        const operationData: OperationsHistoryState = {
          label: newsFeed.content,
          type: newsFeed.type,
          amount: newsFeed.amount,
          statusCode: newsFeed.operationStatusCode,
          statusLabel: newsFeed.operationStatusLabel,
          id: newsFeed.operationId,
          code: newsFeed.operationStatusCode,
          dateTime: newsFeed.date
        };
        const canNavigateToOperationDetail = operationData.statusCode !== OperationsHistoryStatusEnum.ANNULE
        return (
          <SavingsOperationsHistoryCard
            containerStyle={styles.SavingsOperationsHistoryCardStyle}
            isPressable={canNavigateToOperationDetail}
            onCardPress={() => canNavigateToOperationDetail && handleOnOperationCardPress(operationData?.id)}
            operation={operationData}
          />
        );
      case NewsFeedTypeEnum.ALERT:
        return (
          <NotificationHard
            key={index}
            type={NotificationIconTypeEnum.WARNING}>
            <HtmlRenderConnectedComponent html={newsFeed.content} />
          </NotificationHard>
        );
    }
  };
  return (
    <View style={styles.containerStyle}>
      {itemsList.map((data: [string, NewsFeedTypeState[]], index: number) => {
        return (
          <View key={index}>
            <Text
              style={styles.dateStyles}
              variant="t4"
              weight="light">
              {formatDate({ value: data[0] })}
            </Text>
            {data[1].map((item: NewsFeedTypeState, i: number) => {
              return renderNewsFeedByType(item, i);
            })}
          </View>
        );
      })}
    </View>
  );
};

const getStyles = (
  context?: HistoryRenderItemsContext,
  style?: HistoryRenderItemsStyles
): HistoryRenderItemsStyles => ({
  containerStyle: [
    {
      flex: 1
    },
    context?.isMobile
      ? {
        paddingHorizontal: context?.theme.metrics.spacing.s
      }
      : {
        paddingHorizontal: context?.theme.metrics.spacing.xhuge
      },
    style?.containerStyle
  ],
  dateStyles: [
    {
      color: context?.theme.colors.basics.grey.c500,
      marginBottom: context?.theme.metrics.spacing.xm,
      marginTop: context?.theme.metrics.spacing.xm
    },
    style?.dateStyles
  ],
  itemMarginBottom: [
    {
      marginBottom: context?.theme.metrics.spacing.xm
    },
    style?.itemMarginBottom
  ],
  SavingsOperationsHistoryCardStyle: [
    {
      backgroundColor: context?.theme.colors.basics.white
    },
    style?.SavingsOperationsHistoryCardStyle
  ]
});

const useStyles = createUseStyles(getStyles);
