import { StyleSheet } from 'react-native';

import {
  useTheme,
  useScreenSizes,
} from '@components/index';

export const useStyles = ({
  isEERLeftMenu,
}:{
  isEERLeftMenu?:boolean
}) => {

  const theme = useTheme();
  const { isMobile, isTablet } = useScreenSizes();

  return StyleSheet.create({
    footerContentContainer: {
      paddingHorizontal: theme.metrics.spacing.xm,
    },
    buttonStyle: !(isMobile || isTablet) ? {
      maxWidth: 300,
    } : {},
  });
};
