import React, { useCallback } from 'react';
import { View } from 'react-native';

import { Text, Title } from '@ere-uilib/atoms';
import { SimpleButton } from '@ere-uilib/molecules';

import { CardWithHeaderButtonProps } from './interfaces';
import { useStyles } from './useStyles';

export const CardWithHeaderButton: React.FC<React.PropsWithChildren<CardWithHeaderButtonProps>> = ({
  title,
  subTitle,
  description,
  children,
  withDescription = false,
  isDisabled = false,
  isHeaderButtonHidden = false,
  headerButtonLabel,
  onHeaderButtonPress,
  renderSeparator,
  keyExtractor,
  ...props
}) => {
  const styles = useStyles(isDisabled);
  
  const renderContents = useCallback(() => {
    const Contents = React.Children.toArray(children);

    return Contents.map((Content, index, array) => {
      const lastIndex = array.length - 1;
      const isLastIndex = index === lastIndex;
      return (
        <React.Fragment key={keyExtractor?.(index)}>
          <View style={styles.cardContent}>{Content}</View>
          {!isLastIndex && renderSeparator?.()}
        </React.Fragment>
      );
    })

  }, [renderSeparator, keyExtractor, children, styles])

  return (
    <View
      {...props}
      style={[styles.container, props.style]}>
      <View style={styles.cardHeader}>
        <View style={styles.cardHeaderTop}>
          <View>
            <Text
              variant="t4"
              weight="light">
              {title}
            </Text>
            <Title
              variant="t7"
              weight="bold">
              {subTitle}
            </Title>
          </View>
          {!isHeaderButtonHidden && !!onHeaderButtonPress &&
            <SimpleButton
              design="solid"
              disabled={isDisabled}
              onPress={onHeaderButtonPress}
              size="small"
              title={headerButtonLabel}
            />
          }
        </View>
        {withDescription && (
          <Text
            style={styles.description}
            variant="t5"
            weight="light">
            {description}
          </Text>
        )}
      </View>
      {renderContents()}
    </View>
  );
}