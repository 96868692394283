import { AuthActionType, AuthenticateAction } from '@modules/auth/actions/authActionsTypes';
import { ErrorActionsType } from '@modules/error/actions/errorActionsTypes';
import { SettingsState } from './types';
import { parseApplicationSettings } from './utils';
import { SettingsAction, SettingsActionsType } from './actions/settingsActionsTypes';

export const initialState: SettingsState = {
  mobileMenuList: [],
  operationModalList: undefined,
  sapiendoRedirectUrl: undefined,
  applicationSettings: { RemboursementSyntheseTarifs: '' },
  externalClientID: undefined,
  error: {
    code: '',
    correlationId: '',
    message: '',
    innerMessage: '',
  },
  maintenanceContent: null,
};

export default function SettingsReducer(
  state: SettingsState = initialState,
  action: SettingsAction | AuthenticateAction,
): SettingsState {
  switch (action?.type) {
    case SettingsActionsType.SET_ATINTERNET_CLIENT_ID:
      return {
        ...state,
        atInternetClientIdSession: action.id
      }
    case SettingsActionsType.GET_APPLICATION_SETTINGS_SUCCESS:
      return {
        ...state,
        applicationSettings: parseApplicationSettings(
          action.applicationSettings
        ),
      };
    case SettingsActionsType.GET_APPLICATION_SETTINGS_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case SettingsActionsType.APPLICATION_SETTINGS_INIT_ERROR:
      return {
        ...state,
        error: initialState.error,
      };
    case SettingsActionsType.GET_MOBILE_MENU_LIST_SUCCESS:
      return {
        ...state,
        mobileMenuList: action.mobileMenuList,
      };
    case SettingsActionsType.SET_EXTERNAL_CLIENT_ID:
      return {
        ...state,
        externalClientID: action.externalClientID
      }
    case SettingsActionsType.GET_MOBILE_MENU_LIST_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case SettingsActionsType.MOBILE_MENU_LIST_INIT_ERROR:
    case ErrorActionsType.INIT_ALL_ERRORS:
      return {
        ...state,
        error: initialState.error,
      };
    case SettingsActionsType.SET_MAINTENANCE_CONTENT:
      return {
        ...state,
        maintenanceContent: action.maintenanceContent
      };
    case AuthActionType.LOGOUT_CALLBACK_SUCCESS:
      return {
        ...state,
        externalClientID: initialState.externalClientID,
        atInternetClientIdSession: initialState.atInternetClientIdSession,
      }
    default:
      return state;
  }
}
