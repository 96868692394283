import React from 'react';
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native';

import { Hoverable, SVGLocalLoader } from '@ere-uilib/atoms';
import { createUseStyles, useTheme, ThemeType } from '@ere-uilib/styles';

interface DataProps {
  onClick: () => void
}

type FranceConnectButtonStyles = {
  buttonContainer?: StyleProp<ViewStyle>;
  childrenStyle?: StyleProp<ViewStyle>;
};

type FranceConnectButtonStylesContext = {theme: ThemeType};

type Props = DataProps & FranceConnectButtonStyles

export const FranceConnectButton: React.FC<React.PropsWithChildren<Props>> =  ({
  onClick,
  buttonContainer
}: Props) => {
  const theme = useTheme();
  const styles = useStyles({ theme }, { buttonContainer });

  return (
    <Hoverable>
      {
        (isHoverable: boolean) =>

          <TouchableOpacity
            onPress={onClick}
            style={styles.buttonContainer} >
            <SVGLocalLoader name={isHoverable ? 'franceConnectHover' : 'franceConnect'}/>
          </TouchableOpacity>
      }
    </Hoverable>

  );
};

const getStyles = (
  context?: FranceConnectButtonStylesContext,
  style?: FranceConnectButtonStyles,
): FranceConnectButtonStyles => ({
  buttonContainer: [{
    height: 60,
    width: 230 }, style?.buttonContainer]
});

const useStyles = createUseStyles(getStyles);
