import { Notification } from '@ere-uilib/types/notificationSystemTypes';

export enum NotificationSystemActionsType {
  ADD_NOTIFICATION = 'ADD_NOTIFICATION',
  REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION'
}

export interface AddNotification {
  type: NotificationSystemActionsType.ADD_NOTIFICATION;
  notification: Notification;
}

export interface RemoveNotification {
  type: NotificationSystemActionsType.REMOVE_NOTIFICATION;
  uid: string;
}

export type NotificationSystemAction =
  | AddNotification
  | RemoveNotification
