import React, { useState } from 'react'
import { View } from 'react-native'

import { Title, Pressable } from '@ere-uilib/atoms'
import { createUseStyles, useTheme } from '@ere-uilib/styles'

import { RadioButtonProps } from './interfaces'
import { getStyles } from './styles'

export const RadioButton = ({
  containerStyle,
  value,
  label,
  isHoverableButton = true,
  isColoredBackground = false,
  labelIsPressable = true,
  onPress,
  disabled = false,
  testId,
  labelStyle
}: RadioButtonProps) => {
  const [hovered, setHovered] = useState<boolean>(false)
  const [pressed, setPressed] = useState<boolean>(false)

  const theme = useTheme()
  const styles = useStyles(
    {
      theme,
      checked: !!value,
      disabled,
      hovered,
      pressed,
      isColoredBackground
    },
    {
      container: containerStyle
    }
  )
  return (
    <View style={styles.container}>
      <Pressable
        onPressIn={() => setPressed(true)}
        onPressOut={() => setPressed(false)}
        onHoverIn={() => isHoverableButton && setHovered(true)}
        onHoverOut={() => setHovered(false)}
        disabled={disabled}
        onPress={onPress}>
        <View
          accessibilityLabel={testId}
          testID={testId}
          style={styles.buttonStyle}>
          <View style={styles.subButtonStyle} />
        </View>
      </Pressable>

      {label && (
        <Title
          onPress={labelIsPressable && !disabled ? onPress : () => {}}
          style={[styles.labelStyle, labelStyle]}
          variant="t7">
          {label}
        </Title>
      )}
    </View>
  )
}

const useStyles = createUseStyles(getStyles)
