import React from 'react';
import { View } from 'react-native';

import { InstallmentsStepper, NavigationHeader } from '@ere-uilib/molecules';
import { HelpPointPositiontype } from '@ere-uilib/molecules/headers/NavigationHeader/interface';
import { StepperItemType, StepperSizeEnum } from '@ere-uilib/molecules/steppers/InstallmentsStepper/interfaces';
import { useScreenSizes, useTheme } from '@ere-uilib/styles';

import { useStyles } from './useStyles';

interface Props {
  displayCloseButton: boolean
  displayBackButton?: boolean
  helpPointContentHtml?: string
  helpPointModalTitle?: string
  helpPointPosition?: HelpPointPositiontype
  onClose?: () => void
  title: string
  stepperItems?: StepperItemType[]
  helpPoint: boolean
  id?: string
  onGoBack?: () => void
}

export function StepperHeader({
  onClose,
  title,
  displayCloseButton,
  displayBackButton,
  helpPointModalTitle,
  helpPointContentHtml,
  helpPointPosition,
  stepperItems,
  helpPoint,
  id,
  onGoBack,
}: Props) {
  const theme = useTheme();
  const { isMobile, isTablet } = useScreenSizes();
  const styles = useStyles();

  return (
    <NavigationHeader
      displayBackButton={displayBackButton}
      displayCloseButton={displayCloseButton}
      helpPoint={helpPoint}
      helpPointContentHtml={helpPointContentHtml}
      helpPointModalTitle={helpPointModalTitle}
      helpPointPosition={helpPointPosition}
      onClose={onClose}
      onGoBack={onGoBack}
      title={title}
    >
      {stepperItems &&
        <View
          testID={`${id}_header`}
          accessibilityLabel={`${id}_header`}
          style={styles.stepperContainer}>
          <InstallmentsStepper
            containerStyle={styles.stepperStyle}
            lineLength={theme.metrics.spacing.m}
            stepperItems={stepperItems}
            stepperSize={(isMobile || isTablet) ? StepperSizeEnum.SMALL : StepperSizeEnum.BIG}
          />
        </View>
      }
    </NavigationHeader>);
}
