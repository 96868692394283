import { createStackNavigator } from '@react-navigation/stack'
import React from 'react'

import { RouteNames } from '@constants/navigation'
import { BankDetailsNavigatorInterface } from '@navigation/Interfaces/BankDetailsNavigatorInterface'
import { useDefaultScreensOptions } from '@navigation/useDefaultScreensOptions'
import {
  BankCopyIbanPage,
  BankSetDocumentsPage,
  BankDomiciliationPage,
  IbanFormPage
} from '@pages/BankDetails'
import { ConfirmModalPageComponent } from '@pages/Common'
import { BankSignaturePageRC } from '@pages/BankDetails/BankSignaturePageRC/BankSignatureRC.container'
import { BankSignaturePage } from "@pages/BankDetails/BankSignaturePage";
import BankSummaryPageContainer from "@pages/BankDetails/BankSummaryPage/BankSummaryPage.container";

const BankDetailsStack = createStackNavigator<BankDetailsNavigatorInterface>()

export const BankDetailsStackNavigator = () => {
  const defaultScreensOptions = useDefaultScreensOptions()

  return (
    <BankDetailsStack.Navigator
      initialRouteName={RouteNames.BankDetailsSummary}
      screenOptions={{
        gestureEnabled: false,
        headerShown: false
      }}>
      <BankDetailsStack.Screen
        component={BankDomiciliationPage}
        name={RouteNames.BankSetDomiciliation}
        options={defaultScreensOptions.BankSetDomiciliationPage}
      />
      <BankDetailsStack.Screen
        component={BankSetDocumentsPage}
        name={RouteNames.BankSetDocuments}
        options={defaultScreensOptions.BankSetDocumentsPage}
      />
      <BankDetailsStack.Screen
        component={BankSummaryPageContainer}
        name={RouteNames.BankDetailsSummary}
        options={defaultScreensOptions.BankSummaryPage}
      />
      <BankDetailsStack.Screen
        component={IbanFormPage}
        name={RouteNames.BankSetIban}
        options={defaultScreensOptions.BankSetDocumentsPage}
      />
      <BankDetailsStack.Screen
        component={BankCopyIbanPage}
        name={RouteNames.BankCopyIban}
        options={defaultScreensOptions.BankCopyIbanPage}
      />
      <BankDetailsStack.Screen
        component={BankSignaturePage}
        name={RouteNames.BankSignature}
        options={defaultScreensOptions.BankSignaturePage}
      />
      <BankDetailsStack.Screen
        component={BankSignaturePageRC}
        name={RouteNames.BankSignatureRC}
        options={defaultScreensOptions.BankSignaturePageRC}
      />
      {/* Modal screen here if needed */}
      <BankDetailsStack.Screen
        component={ConfirmModalPageComponent}
        name={RouteNames.BankDetailsModal}
        options={defaultScreensOptions.ConfirmModal}
      />
    </BankDetailsStack.Navigator>
  )
}
