import { useCallback, useMemo } from 'react'

import { AllocationsEnum, RepartitionEnum } from '@constants/arbitrations'
import { useTheme } from '@ere-uilib/styles'
import { useTranslation } from '@ere-uilib/translations'
import { ArbitrationAnswersTypesEnum } from '@modules/arbitration/constants'
import { OptionsState } from '@modules/common/types'
import { getEligibleDispositifs } from '@utils/eligiblePlansCompute'

import { UseController } from './interface'

export const useController = ({
  requirementDecisionData,
  respondedQuestion,
  response,
  questions,
  unRespondedQuestion,
  onUpdateQuestion
}: UseController) => {
  const theme = useTheme()
  const { formatMessage } = useTranslation()
  const getAllocationAndRepartitionColor = useCallback(
    type => {
      switch (type) {
        default:
        case AllocationsEnum.Monetary:
        case RepartitionEnum.monetaryPercentage:
          return theme.colors.oadChart.monetary
        case AllocationsEnum.Obligatory:
        case RepartitionEnum.obligatoryPercentage:
          return theme.colors.oadChart.obligatory
        case AllocationsEnum.Diversified:
        case RepartitionEnum.diversifiedPercentage:
          return theme.colors.oadChart.diversified
        case AllocationsEnum.Action:
        case RepartitionEnum.actionPercentage:
          return theme.colors.oadChart.action
      }
    },
    [theme]
  )

  const eligibleDispositifsData = useMemo(
    () =>
      getEligibleDispositifs(
        requirementDecisionData.dispositifs,
        [...respondedQuestion, response],
        response.type
      ),
    [requirementDecisionData, respondedQuestion, response]
  )

  const hasEligibleDispositifs = useMemo(
    () =>
      !!eligibleDispositifsData?.eligibleDispositifs?.find(
        dispositif => dispositif.isEligible === true
      ),
    [eligibleDispositifsData]
  )

  let options = response?.options
  //check if we had to display oadstart qeuestion responses, we do not have de check eligibility of plans.
  const oadStartYesQuestionType = ArbitrationAnswersTypesEnum.OAD_START_YES
  const oadStartNoQuestionType = ArbitrationAnswersTypesEnum.OAD_START_NO
  const isOadStartResponse =
    response.type === oadStartYesQuestionType || response.type === oadStartNoQuestionType
  // if answer isNoEligibleDispositif we disable radio buttons
  const isNoEligibleDispositif =
    response.disabled || (!hasEligibleDispositifs && !isOadStartResponse)
  if (isNoEligibleDispositif) {
    options = options?.map(option => ({
      ...option,
      disabled: true
    }))
  }

  const refundUnavailableDispositifTableDecisionParams = useMemo(() => {
    if (!unRespondedQuestion) {
      return
    }
    const payloadResponse = {
      respondedTitle: response.title,
      respondedValue: response.value,
      title: unRespondedQuestion[0].respondedTitle || unRespondedQuestion[0].title,
      respondedPropertyFilter: response.propertyFilter,
      options: response?.options
    }
    return {
      responsePayload: payloadResponse,
      index: questions.indexOf(unRespondedQuestion[0]),
      canNotBeAsked: false
    }
  }, [questions, response, unRespondedQuestion])

  const handleSelectResponse = useCallback(
    (selectedOption?: OptionsState) => {
      if (!unRespondedQuestion) {
        return
      }
      const returnedTitle = !unRespondedQuestion?.[0]?.title
        ? formatMessage({ id: response.title })
        : response.title

      return onUpdateQuestion(
        {
          selectedOption,
          respondedTitle: selectedOption?.name || returnedTitle,
          respondedValue: selectedOption?.value || response.value,
          title: unRespondedQuestion[0].respondedTitle || unRespondedQuestion[0].title,
          respondedPropertyFilter: response.propertyFilter,
          options: response?.options,
          type: response?.type
        },
        questions.indexOf(unRespondedQuestion[0]),
        false
      )
    },
    [unRespondedQuestion, onUpdateQuestion, response, questions]
  )

  const elligibleDispositifs = getEligibleDispositifs(requirementDecisionData.dispositifs, [
    ...respondedQuestion,
    response
  ])?.eligibleDispositifs

  const formattedElligibleDispositifs = elligibleDispositifs?.map(item => ({
    name: item.name,
    planFamily: item.planFamily,
    isEligible: !!item.isEligible
  }))

  const handlePressButton = !isNoEligibleDispositif ? handleSelectResponse : undefined

  return {
    eligibleDispositifsData,
    isNoEligibleDispositif,
    options,
    formattedElligibleDispositifs,
    getAllocationAndRepartitionColor,
    handleSelectResponse,
    handlePressButton,
    refundUnavailableDispositifTableDecisionParams
  }
}
