import React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

import { DashedLine, Text } from '@ere-uilib/atoms';
import { useTheme } from '@ere-uilib/styles';
import { DashboardRepartitionPlanType } from '@ere-uilib/types';

import { useStyles } from './styles';
interface SharedStyles {
  containerStyle?: StyleProp<ViewStyle>;
}
interface RepartitionGraphProps extends SharedStyles {
  allPlans: DashboardRepartitionPlanType[];
}

export const RepartitionGraph = ({ allPlans, containerStyle }: RepartitionGraphProps) => {
  let dashlineHeight = {
    height: -4
  };
  const theme = useTheme();
  const styles = useStyles(containerStyle);
  let totalAmount = 0;
  allPlans.forEach(plan => {
    totalAmount += plan.value;
  });
  return (
    <View style={styles.containerStyle}>
      <View style={styles.graphContainer}>
        {allPlans?.map((plan, index) => {
          const isFirstPlan = index === 0;
          const isLastPlan = index === allPlans.length - 1;
          return (
            <View
              key={index}
              style={[
                styles.innerContainer,
                {
                  backgroundColor: plan.color,
                  flex: Math.round((plan.value * 100) / totalAmount) / 10,
                  borderTopLeftRadius: isFirstPlan ? 15 : 0,
                  borderBottomLeftRadius: isFirstPlan ? 15 : 0,
                  borderTopRightRadius: isLastPlan ? 15 : 0,
                  borderBottomRightRadius: isLastPlan ? 15 : 0
                }
              ]}
            />
          );
        })}
      </View>
      <View style={styles.repartitionDetailsContainer}>
        {allPlans?.map((plan, index) => {
          dashlineHeight = {
            ...dashlineHeight,
            height: dashlineHeight.height + 14
          };
          const percentage = Math.round((plan.value * 100) / totalAmount);
          return (
            <View
              key={index}
              style={[
                styles.repartitionDetails,
                {
                  flex: percentage / 10
                }
              ]}>
              <View style={styles.dashlineContainer}>
                <DashedLine
                  axis="vertical"
                  dashColor={theme.colors.basics.grey.c300}
                  dashGap={2}
                  dashLength={4}
                  dashStyle={{ borderRadius: 5 }}
                  style={{ minHeight: dashlineHeight.height, alignSelf: 'center' }}
                />
                <View style={{ flex: 1 }}>
                  <Text
                    //numberOfLines={1}
                    style={styles.title}
                    weight="bold">
                    {`${plan.label}: ${percentage}%`}
                  </Text>
                </View>
              </View>
            </View>
          );
        })}
      </View>
    </View>
  );
};
