export enum LabelActionType {
  GET_LABEL = 'GET_LABEL',
  GET_LABEL_REQUEST = 'GET_LABEL_REQUEST',
  GET_LABEL_SUCCESS = 'GET_LABEL_SUCCESS',
  GET_LABEL_FAILURE = 'GET_LABEL_FAILURE',
  PUT_LANGUAGE_REQUEST = 'PUT_LANGUAGE_REQUEST',
  PUT_LANGUAGE_SUCCESS = 'PUT_LANGUAGE_SUCCESS',
}

export interface GetLabelAction {
  type: LabelActionType.GET_LABEL;
}

export interface GetLabelRequestAction {
  type: LabelActionType.GET_LABEL_REQUEST;
  language: string
}

export interface GetLabelSuccessAction {
  type: LabelActionType.GET_LABEL_SUCCESS;
  labels: any;
}

export interface GetLabelFailureAction {
  type: LabelActionType.GET_LABEL_FAILURE;
  error: Error | string;
}

export interface PutLanguageRequestAction {
  type: LabelActionType.PUT_LANGUAGE_REQUEST;
  language: string
}

export interface PutLanguageSuccessAction {
  type: LabelActionType.PUT_LANGUAGE_SUCCESS;
  language: string
}


export type LabelAction =
  | GetLabelAction
  | GetLabelRequestAction
  | GetLabelSuccessAction
  | GetLabelFailureAction
  | PutLanguageRequestAction
  | PutLanguageSuccessAction