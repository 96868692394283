import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { View } from 'react-native'

import {
  DashboardPageWrapperConnected,
  Paragraph,
  useTranslation,
  Title,
  useTheme,
  NavigationHeader,
  TextField,
  NotificationHard,
  SimpleButton,
  ColumnsContainer,
  NotificationIconTypeEnum,
  InformativePopin,
  IconEnum
} from '@components/index'

import { useScreenSizes } from '@components/index'
import { useStyles } from './styles'
import { REGEX, UserInfoTypeEnum } from '@constants/index'
import { RouteNames } from '@constants/navigation'
import { ProfileEditEmailPageProps } from './interfaces'
import { changeEmailOrPasswordErrorKinds } from '../constants'

export const ProfileEditEmailPageComponent: React.FC<React.PropsWithChildren<ProfileEditEmailPageProps>> = ({
  profileUserInfos,
  error,
  otpError,
  onSetProfileUserInfoChangeRequest,
  isProfileLoading,
  navigation,
  editMailIsEnable,
  onCheckEditMailIsEnable
}) => {
  const { formatMessage } = useTranslation()
  const { isMobile, isTablet } = useScreenSizes()
  const theme = useTheme()
  const { styles } = useStyles()

  useEffect(() => {
    onCheckEditMailIsEnable()
  }, [onCheckEditMailIsEnable])

  const userEmail = useMemo(() => profileUserInfos?.mail || '', [profileUserInfos])

  const [email, setEmail] = useState<string>(userEmail)
  const isEnabledButtonDefault = REGEX.EMAIL.test(userEmail)
  const [isEnabledButton, setIsEnabledButton] = useState<boolean>(isEnabledButtonDefault)
  const has24hBlockingError = error?.code === changeEmailOrPasswordErrorKinds.ERR_EML_05
  const hasServerError =
    (otpError?.innerMessage !== '' || error.message !== '') && !has24hBlockingError
  const [showServerErrorPopin, setShowServerErrorPopin] = useState(hasServerError)

  useEffect(() => {
    setShowServerErrorPopin(hasServerError)
  }, [hasServerError])

  const onEmailSubmit = useCallback(() => {
    onSetProfileUserInfoChangeRequest(UserInfoTypeEnum.MAIL, email)
  }, [email, onSetProfileUserInfoChangeRequest])
  const renderInformativePopin = useCallback(() => {
    return (
      <InformativePopin
        description={formatMessage({ id: 'Profil_telephone_technical_error_content' })}
        iconHeight={60}
        iconWidth={60}
        iconName={IconEnum.WARNING_WITH_BG}
        isModalVisible={showServerErrorPopin}
        cancelButtonTitle={formatMessage({ id: 'Profil_telephone_technical_error_leavebutton' })}
        onCancel={() => {
          setShowServerErrorPopin(false)
          return navigation.navigate(RouteNames.BottomTabNavigator, {
            screen: RouteNames.ProfileStack,
            params: {
              screen: RouteNames.Profile
            }
          })
        }}
        onClose={() => {
          setShowServerErrorPopin(false)
        }}
        title={formatMessage({ id: 'Profil_telephone_technical_error_title' })}
      />
    )
  }, [showServerErrorPopin, setShowServerErrorPopin])

  const RenderHeader = useCallback(() => {
    return (
      <NavigationHeader
        displayBackButton={true}
        onGoBack={() =>
          navigation.replace(RouteNames.BottomTabNavigator, {
            screen: RouteNames.ProfileStack,
            params: {
              screen: RouteNames.EditProfileInformations
            }
          })
        }
        title={formatMessage({ id: 'Profil_email_edit_title' })}
      />
    )
  }, [navigation, formatMessage])

  const renderSubmitButton = useCallback(() => {
    return (
      <View style={styles.buttonContainer}>
        <SimpleButton
          disabled={!isEnabledButton || !editMailIsEnable || has24hBlockingError}
          loading={isProfileLoading}
          onPress={onEmailSubmit}
          title={formatMessage({ id: 'Profil_email_edit_save_button' })}
        />
      </View>
    )
  }, [
    styles,
    isEnabledButton,
    isProfileLoading,
    onEmailSubmit,
    formatMessage,
    editMailIsEnable,
    has24hBlockingError
  ])

  return (
    <DashboardPageWrapperConnected
      cardContentStyle={{ backgroundColor: theme.colors.basics.white }}
      renderStickyMobileBottom={renderSubmitButton}>
      <ColumnsContainer
        hasRenderRightDesktopColumn={false}
        renderHeader={RenderHeader}
        leftColumnStyle={{ paddingVertical: theme.metrics.spacing.xm }}>
        <>
          <View style={styles.contentStyle}>
            {has24hBlockingError && (
              <NotificationHard
                containerStyle={styles.notificationHardStyle}
                type={NotificationIconTypeEnum.WARNING}
                text={formatMessage({ id: 'Profil_telephone_notification_deadline24h_tomodify' })}
              />
            )}
            <Title
              variant="t6"
              weight="bold">
              {formatMessage({ id: 'Profil_email_edit_subtitle' })}
            </Title>
            <Paragraph
              variant="t4"
              weight="light">
              {formatMessage({ id: 'Profil_email_edit_label' })}
            </Paragraph>
            <TextField
              isDisabled={has24hBlockingError}
              containerStyle={styles.emailInputStyle}
              inputProps={{
                value: email,
                placeholder: formatMessage({ id: 'Profil_email_edit_placeholder_label' }),
                onChangeText: mail => {
                  setEmail(mail)
                  setIsEnabledButton(REGEX.EMAIL.test(mail))
                }
              }}
              labelOnTop
              onCleanField={() => setEmail('')}
              onSubmit={onEmailSubmit}
            />
            <NotificationHard
              containerStyle={styles.notificationHardStyle}
              text={formatMessage({ id: 'Profil_email_edit_info_label' })}
              type={NotificationIconTypeEnum.INFO}
            />

            {renderInformativePopin()}

            {!isMobile && !isTablet && renderSubmitButton()}
          </View>
        </>
      </ColumnsContainer>
    </DashboardPageWrapperConnected>
  )
}
