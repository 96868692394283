import { useTheme } from "@ere-uilib/styles";
import { useMemo } from "react"
import { StyleSheet } from "react-native"

export const useStyles = () => {
  const theme = useTheme();

  const styles = useMemo(() => StyleSheet.create({
    container: {
      display: 'flex',
      position: 'relative',
      marginTop: theme.metrics.spacing.s,
      alignSelf: 'center',
      alignItems: 'center',
      justifyContent: 'center',
    }
  }), [theme]);

  return styles;
}
