import React, { useState } from 'react';
import { LayoutChangeEvent, View } from 'react-native';
import {
  VictoryAxis, VictoryChart, VictoryLabel, VictoryLine, VictoryTooltip, VictoryVoronoiContainer
} from 'victory-native';

import { DotsCursorsType, LineChartToolTip, Text } from '@ere-uilib/atoms';
import { createUseStyles, useScreenSizes, useTheme } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';

import {
  FundPerformanceChartProps,
  ToolTipFlyoutProps
} from './interfaces';
import {
  FundPerformanceChartDataProps
} from './sharedInterfaces';
import { getStyles } from './styles';

export const FundPerformanceChart: React.FC<React.PropsWithChildren<FundPerformanceChartProps>> = ({
  data,
  containerStyle,
}) => {
  const theme = useTheme();
  const { isMobile, isTablet, windowHeight } = useScreenSizes();
  const {
    formatDate,
    formatMessage,
    formatCurrencyNumber,
    formatPercentNumber,
  } = useTranslation();
  const [
    containerSize,
    setContainerSize,
  ] = useState<{width: number; height: number}>({ width: 300, height: 300 });
  // to prevent tooltip default size to be to different from expected we save the last tooltip size
  const [tooltipLayoutSize, setTooltipLayoutSize] = useState<{width: number; height: number}>();
  const styles = useStyles(
    { theme, isMobile, isTablet },
    {
      containerStyle,
    },
  );

  const handleContainerLayout = ({ nativeEvent }:LayoutChangeEvent)=>{
    const { width, height } = nativeEvent.layout;
    setContainerSize({ width, height });
  };

  const tableLength = data.length;
  const maxIndex = tableLength - 1;
  let minYAxisValue = data?.length > 0 ? data[0].y : 0;
  let maxYAxisValue = 0;
  const allXKeys:FundPerformanceChartDataProps['x'][] = [];

  data.forEach(item=>{
    if (item.y  > maxYAxisValue) {
      maxYAxisValue = item.y;
    }
    if (item.y  < minYAxisValue) {
      minYAxisValue = item.y;
    }
    allXKeys.push(item.x);
  });

  const maxBottomLabels = containerSize.width / 40;
  const chartSize = {
    width: containerSize.width,
    height: windowHeight / 2  < 300 ?
      300
      : windowHeight / 2  > 400 ?
        400
        : windowHeight / 2,
  };
  const chartYDomain: {min:number, max:number} = {
    min: minYAxisValue > 0 && minYAxisValue < 0 ?
      0 : minYAxisValue,
    max: maxYAxisValue,
  };
  const domainYSpace = chartYDomain.max - chartYDomain.min;

  const firstItemY = data[0].y;

  const VictoryCustomTooltip = (props: ToolTipFlyoutProps) => {

    const { datum, x, y } = props;
    const currentItem = data.find(item=>item.x === datum?.x);
    if (!currentItem || !datum) {return null;}

    const cumulatedPerformancePercentage = currentItem?.y &&
      (((currentItem?.y - firstItemY) * 100) / firstItemY);

    // positioning tooltip
    const xIndex = allXKeys.findIndex(xKey => xKey === datum.x);
    const isInRightPart = xIndex > maxIndex / 2;
    const isInTopPart = ( datum.y - chartYDomain.min ) > (domainYSpace / 2);

    const dotsCursors:DotsCursorsType = [
      {
        color: theme.colors.basics.primary.c500,
        y: 0,
      },
    ];
    return (
      <LineChartToolTip
        displayLineCursor
        dotsCursors={dotsCursors}
        isInRightPart={isInRightPart}
        isInTopPart={isInTopPart}
        lastLayoutSaved={tooltipLayoutSize}
        onContainerLayout={setTooltipLayoutSize}
        x={x}
        y={y}
      >
        <View>
          <Text
            variant="t5"
            weight="light"
          >
            {formatDate( { value: datum?.x,
              options: {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              } })}
          </Text>
        </View>
        <View style={styles.tooltipDetailStyle}>
          <Text
            variant="t5"
          >
            {formatMessage({ id: 'Fundsheet_Tab3Perf_VL_legend' })}
            {' '}
            {formatCurrencyNumber({ value: currentItem?.y || 0 })}
          </Text>
          <Text
            variant="t5"
          >
            {formatMessage({ id: 'Fundsheet_Tab3Perf_Cumulated_perf_legend' })}
            {cumulatedPerformancePercentage ?
              formatPercentNumber({ value: cumulatedPerformancePercentage })
              : formatPercentNumber({ value: 0 })
            }
          </Text>
        </View>
      </LineChartToolTip>
    );
  };
  const formatCurrencyTickToRightKiloValue = (tickValue:number)=>{
    if (maxYAxisValue >= 10000) {
      return `${(tickValue / 1000).toFixed(2).replace(/\.?0+$/, '').replace('.', ',')}k€`;
    } else {
      return `${tickValue.toFixed(2).replace(/\.?0+$/, '').replace('.', ',')}€`;
    }
  };
  const axisStyles = {
    axis: { stroke: theme.colors.basics.grey.c100 },
    axisLabel: {
      fontSize: theme.fonts.fontSize.title.t8s,
      padding: theme.metrics.spacing.xs,
      fontFamily: theme.fonts.fontFamily.light,
    },
    grid: { stroke: theme.colors.basics.grey.c100 },
    ticks: { stroke: theme.colors.basics.grey.c100, size: 2 },
    tickLabels: {
      fontSize: theme.fonts.fontSize.title.t8s,
      padding: theme.metrics.spacing.xs,
      fontFamily: theme.fonts.fontFamily.light,
    },
  };
  return (
    <View
      onLayout={handleContainerLayout}
      style={styles.containerStyle}
    >
      <VictoryChart
        animate={{ duration: 500 }}
        containerComponent={
          <VictoryVoronoiContainer
            labelComponent={
              <VictoryTooltip
                flyoutComponent={<VictoryCustomTooltip/>}
              />
            }
            labels={() => ' '} // needed to be set to allow labelComponent
          />
        }
        domain={{ y: [chartYDomain.min, chartYDomain.max] }}
        domainPadding={{
          y: [
            theme.metrics.spacing.s,
            theme.metrics.spacing.s,
          ],
        }}
        height={chartSize.height}
        padding={{ left: 50, right: 10, top: 10, bottom: 32 }}
        width={chartSize.width}
      >
        <VictoryAxis
          crossAxis
          style={axisStyles}
          tickCount={maxBottomLabels}
          tickFormat={x => formatDate({
            value: x,
            options: {
              year: '2-digit',
              month: 'numeric',
            },
          })}
          tickLabelComponent={
            <VictoryLabel
              angle={-45}
              dx={-13}
              dy={0}
            />
          }
        />
        <VictoryAxis
          crossAxis
          dependentAxis
          style={axisStyles}
          tickFormat={formatCurrencyTickToRightKiloValue}
        />
        <VictoryLine
          data={data}
          style={{
            data: {
              stroke: theme.colors.basics.primary.c500,
              strokeWidth: 2.5,
            },
            labels: {
              fill: theme.colors.basics.primary.c500,
            },
          }}
        />
      </VictoryChart>
    </View>
  );
};

const useStyles = createUseStyles(getStyles);
