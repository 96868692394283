import { connect } from 'react-redux';

import { clearProfitSharingIncentiveData } from '@modules/profit-sharing-incentive/actions/profitSharingIncentiveActions';

import { ProfitSharingIncentiveFailedPage } from './ProfitSharingIncentiveFailedPage.component';

const mapDispatchToProps = ({
  clearProfitSharingIncentiveData: clearProfitSharingIncentiveData
});

export const ProfitSharingIncentiveFailed = connect(null, mapDispatchToProps)(ProfitSharingIncentiveFailedPage);
