import { connect } from 'react-redux';

import { setInstallmentPaymentModeChoice } from '@modules/installment/actions/installmentActions';
import { AppState } from '@modules/reducers';
import {
  getInstallmentRepartitionData,
  getInstallmentPaymentMode,
  getInstallmentPaymentModeData,
  getInstallmentDecisionAllResponses,
  getCalculatedContributionError,
  getInstallmentEditedVVPDtata,
} from '@modules/installment/selectors';

import InstallmentsPaymentModePageComponent from './InstallmentsPaymentModePage.component';

const mapStateToProps = (state: AppState) => {
  const isEditMode = !!getInstallmentEditedVVPDtata(state);
  return {
    isEditMode,
    installmentRepartitionData: getInstallmentRepartitionData(state),
    installmentPaymentMode: getInstallmentPaymentMode(state),
    installmentPaymentModeData: getInstallmentPaymentModeData(state),
    installmentDecisionAllResponses: getInstallmentDecisionAllResponses(state),
    calculatedContributionError: getCalculatedContributionError(state),
  };
};

const mapDispatchToProps = ({
  onSetInstallmentPaymentModeChoice: setInstallmentPaymentModeChoice,
});

export const InstallmentsPaymentModePage = connect(
  mapStateToProps,
  mapDispatchToProps
)(InstallmentsPaymentModePageComponent);

