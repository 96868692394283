import { connect } from 'react-redux';

import { AppState } from '@modules/index';
import { getApplicationSettings } from '@modules/settings/selectors';

import {
  ActivateAccountModeSelectionPageComponent,
} from './ActivateAccountModeSelectionPage.component';

const mapStateToProps = (state: AppState) => {
  const applicationSettings = getApplicationSettings(state);
  return {
    applicationSettings,
  };
};

const mapDispatchToProps = ({

});
export const ActivateAccountModeSelectionPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ActivateAccountModeSelectionPageComponent);
