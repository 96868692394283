import { palette } from '@ere-uilib/styles/theme/themeFolder/colors/palette'
import React from 'react'
import { View, Text, StyleSheet } from 'react-native'

interface DateCellProps {
  value: any
}

export const DateCell = (value: any) => (
  <View style={styles.dateCellContainer}>
    <Text style={styles.dateCellContainerText}>{value}</Text>
  </View>
)

const styles = StyleSheet.create({
  dateCellContainer: {
    backgroundColor: palette.white,
    flex: 1,
    width: '100%',
    minHeight: 50,
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderColor: palette.grey.c200
  },
  dateCellContainerText: {
    color: palette.black,
    fontWeight: '700',
    fontSize: 14,
    textAlign: 'center'
  }
})
