import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { Text, View } from 'react-native';

import { Modal, NotificationIcon, NotificationIconTypeEnum, SquareIconSizeEnum, Title } from '@ere-uilib/atoms';
import { ModalFrame, SimpleButton } from '@ere-uilib/molecules';
import { useTheme } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';

interface Props {
  visible: boolean;
  onClose?: () => void;
  onValidate: () => void;
  children: ReactNode;
}

export function VVAmountErrorPopin({ visible, onClose, onValidate, children }: Props) {
  const theme = useTheme();
  const { formatMessage } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(visible);
  }, [visible]);

  const handleClose = useCallback(() => {
    setIsVisible(false);
    onClose && onClose();
  }, [onClose]);

  return (
    <Modal visible={isVisible}>
      <ModalFrame
        desktopPosition="center"
        mobilePosition="bottom"
        onClose={handleClose}
      >
        <View style={{alignItems: 'center' }}>
          <NotificationIcon
            size={SquareIconSizeEnum.BIG}
            type={NotificationIconTypeEnum.WARNING}
          />
          <Title
            style={{ marginTop: theme.metrics.spacing.m }}
            variant="t7"
            weight="bold">
            {formatMessage({ id: 'VV_repartition_threshold_title' })}
          </Title>
          <Text
            style={{ paddingVertical: theme.metrics.spacing.xm, textAlign: 'center', fontFamily: theme.fonts.fontFamily.light }}
          >
            {children}
          </Text>
          <SimpleButton
            containerStyle={{
              alignSelf: 'stretch'
            }}
            onPress={onValidate}
            title={formatMessage({ id: 'VV_repartition_entrancepopin_button' })}
          />
        </View>
      </ModalFrame>
    </Modal>
  );
}
