import React, { useCallback } from 'react';
import { ContentLoader, LoaderPlaceholderView } from '@ere-uilib/atoms';
import { useScreenSizes } from '@ere-uilib/styles';
import { View } from 'react-native';
import { Path } from 'react-native-svg';
import {
  repartitionCirclePathMobile,
  repartitionCirclePathOne,
  repartitionCirclePathTwo
} from './constants';
import { useStyles } from './useStyles';


export const RepartitionsContentLoader: React.FC = () => {
  const isMobile = !useScreenSizes().isDesktop;
  const styles = useStyles();

  const Sqaures = useCallback(() => {
    return (
      <View style={styles.squaresContainerStyle}>
        <LoaderPlaceholderView
          style={{ width: '50%' }}
        />
        <LoaderPlaceholderView
          style={{ width: '100%', marginVertical: 15 }}
        />
        <LoaderPlaceholderView
          style={{ width: '100%' }}
        />
      </View>
    );
  }, []);

  const renderSVG = useCallback(
    () => {
      if (!isMobile) {
        return (
          <>
            <Path
              d={repartitionCirclePathOne}
            />
            <Path
              d={repartitionCirclePathTwo}
            />
          </>
        );
      }

      return (
        <Path
          d={repartitionCirclePathMobile}
          fill="#E8E8E8"
        />
      );

    }, [isMobile]);

  return (
    <View style={styles.containerStyle}>
      <View style={styles.innerContainerStyle}>
        <ContentLoader
          height={isMobile ? 69 : 170}
          width={isMobile ? 69 : 170}
        >
          {renderSVG()}
        </ContentLoader>
      </View>
      <View style={styles.squaresWrapperStyle}>
        {
          Array(2).fill('repartitions_loader').map((_: string, index: number) => <Sqaures key={`repartitions_loader_sqaure_#${index}`} />)
        }
      </View>
    </View>
  )
}