import React from 'react';

import { useScreenSizes } from '@ere-uilib/styles';
import { useStyles } from './useStyle';
import { AvailabilitiesContentLoader } from './components';
import { LoaderCard } from '@ere-uilib/molecules';

export const AvailabilitiesLoader: React.FC = () => {
  const isMobile = !useScreenSizes().isDesktop;
  const styles = useStyles();

  return (
    <LoaderCard
      isMobile={isMobile}
      loaderContainerStyle={styles.loaderCardContainer}
    >
      <AvailabilitiesContentLoader />
    </LoaderCard>
  );
};

