import { Context, Styles } from './interfaces'

export const getStyles = (context?: Context, style?: Styles): Styles => ({
  containerStyle: [
    {
      flexDirection: context?.flexDirection
    },
    style?.containerStyle
  ],
  radioButton: [{ marginBottom: context?.theme.metrics.spacing.s }, style?.radioButton],
  radioButtonMarginIfRow: [
    context?.flexDirection === 'row' && {
      marginLeft: context?.theme.metrics.spacing.xm
    },
    style?.radioButtonMarginIfRow
  ]
})
