import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

export function useStyles() {
  const theme = useTheme();
  const styles = useMemo(() => StyleSheet.create({
    iconBack: {
      marginRight: theme.metrics.spacing.s,
    },
    linkTitle: {
      color: theme.colors.text.primary.c500,
    },
  }), [theme]);
  return styles;
}
