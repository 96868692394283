import { InstallmentDecisionPropertiesEnum } from '@constants/installment';
import { FilterPropertiesType } from '@modules/common/types';
import { InstallmentState } from '@modules/installment/types';
import { convertAPIPaymentPeriodicityToInstallmentDecisionVVPFrequency } from '@modules/installment/utils';
import { AppState } from '@modules/reducers';

export const getInstallmentError = (state: AppState) => state.installment.error;
export const getInstallmentRepartitionData = (state: AppState) => state.installment.repartitionData;
export const getInstallmentRepartitionAmountModalVisibleState = (state: AppState) =>
  state.installment.repartitionAmountModalVisibleState;
export const getCalculatedContribution = (state: AppState) =>
  state.installment.calculatedContribution;
export const getCalculatedContributionError = (state: AppState) =>
  state.installment.calculatedContributionError;
export const getInstallmentDecision = (state: AppState) => state.installment.decision;
export const getInstallmentPaymentMode = (state: AppState) => state.installment.paymentMode;
export const getInstallmentPaymentModeData = (state: AppState) => state.installment.paymentModeData;
export const getInstallmentElligibleDispositif = (state: AppState) =>
  state.installment.decision.elligibleDispositifs;
export const getInstallmentSelectedDispositif = (state: AppState) =>
  state.installment.decision.selectedDispositif;
export const getInstallmentDecisionAllResponses = (state: AppState) =>
  getDecisionAllResponses(state.installment);
export const getInstallmentPaymentResponseData = (state: AppState) =>
  state.installment.paymentResponseData;
export const getInstallmentEditedVVPDtata = (state: AppState) => state.installment.editedVVPData;
const getDecisionAllResponses = (state: InstallmentState): FilterPropertiesType => {
  const isEditMode = state.editedVVPData;
  let allResponses = {};
  if (isEditMode) {
    allResponses = {
      ...(state.editedVVPData?.periodicity && {
        [InstallmentDecisionPropertiesEnum.VVP]:
          convertAPIPaymentPeriodicityToInstallmentDecisionVVPFrequency(
            state.editedVVPData.periodicity
          )
      }),
      ...(state.editedVVPData?.taxIn && { [InstallmentDecisionPropertiesEnum.TAX_IN]: true }),
      ...(state.editedVVPData?.taxOut && { [InstallmentDecisionPropertiesEnum.TAX_OUT]: true })
    };
  } else {
    const questions = state.decision.questions;
    questions.forEach(question => {
      if (question?.responded?.respondedPropertyFilter) {
        const isPropertyFilter =
          question?.responded?.respondedPropertyFilter?.name &&
          question.responded.respondedPropertyFilter.value;

        const isOption =
          question?.responded?.selectedOption?.name && question?.responded?.selectedOption?.value;

        const propertyFilter = isPropertyFilter
          ? {
              [question.responded?.respondedPropertyFilter.name]:
                question.responded?.respondedPropertyFilter.value
            }
          : {};

        const option =
          isOption && question?.responded?.selectedOption
            ? {
                [question.responded?.selectedOption?.name]:
                  question.responded?.selectedOption?.value
              }
            : {};

        allResponses = {
          ...allResponses,
          ...propertyFilter,
          ...option
        };
      }
    });
  }

  return allResponses;
};
