import { useState } from 'react';

export const useAmountFieldParseManager = ({
  initialAmount,
  maxDecimal = 2,
}:{
  initialAmount: number | undefined | null
  maxDecimal?: number
})=>{

  const parseAmountNumberToString = (newAmount: number | undefined | null): string => {
    const amountString = newAmount ? newAmount.toString() : '';
    return amountString;
  };
  const parseAmountStringToNumber = (newAmount: string | undefined | null): number | undefined => {
    const amountNumericOnly = newAmount?.replace(',', '.').replace(/[^0-9|.]/g, '');
    const parsedAmount = amountNumericOnly ?
      Math.round(parseFloat(amountNumericOnly) * 100) / 100
      : undefined;
    return parsedAmount;
  };
  const parseAmountStringToLimitChar = (newAmount: string | undefined): string | undefined => {
    // force only number
    const gotComma = !!newAmount?.includes(',');
    const decimalSeparator = gotComma ? ',' : '.';
    let amountNumericOnly:string = newAmount?.replace(',', '.').replace(/[^0-9|.]/g, '') || '';

    // verify and limit to allowed max decimal
    let amountSplitted: string[] = amountNumericOnly.split('.');

    if (maxDecimal === 0) {
      amountSplitted = amountSplitted.splice(0, 1);
    }
    if (amountSplitted.length > 2) {
      amountSplitted = amountSplitted.splice(0, 2);

    }
    if (amountSplitted[1] && amountSplitted[1].length > maxDecimal) {
      amountSplitted[1] = amountSplitted[1].substring(0, maxDecimal);
    }
    amountNumericOnly = amountSplitted.join(decimalSeparator);

    return amountNumericOnly;
  };

  const initialAmountString = parseAmountNumberToString(initialAmount);
  const [amountString, setAmountStateString] =
    useState<undefined | string | null>(initialAmountString);
  const amountNumber = parseAmountStringToNumber(amountString);

  const setAmountString = (newAmount: string) => {
    const parsedAmount = parseAmountStringToLimitChar(newAmount);

    setAmountStateString(parsedAmount);
  };

  return {
    amountNumber,
    amountString,
    initialAmountString,
    setAmountString,
    parseAmountNumberToString,
    parseAmountStringToNumber,
    parseAmountStringToLimitChar,
  };
};
