import { IconEnum } from '@ere-uilib/enums';
import { AddressUpdateStatus } from '@modules/profile/types';

type Label = Partial<{
  [status in AddressUpdateStatus]: {
    title: string;
    description: string;
    button: {
      validate: string;
      cancel?: string;
    };
    iconName: string;
  };
}>;

export const label: Label = {
  [AddressUpdateStatus.SUCCESS]: {
    title: 'PersonalDetailsPagePostalAdressModificationPopinConfirmationMessageTitle',
    description: 'PersonalDetailsPagePostalAdressModificationPopinConfirmationMessage',
    button: {
      validate: 'PersonalDetailsPagePostalAdressModificationPopinConfirmationButton'
    },
    iconName: IconEnum.BANK_DETAILS_SUCCESS
  },
  [AddressUpdateStatus.ERROR]: {
    title: 'PersonalDetailsPagePostalAdressModificationPopinErrorMessageTitle',
    description: 'PersonalDetailsPagePostalAdressModificationPopinErrorMessage',
    button: {
      validate: 'PersonalDetailsPagePostalAdressModificationPopinErrorButtonRetry',
      cancel: 'PersonalDetailsPagePostalAdressModificationPopinErrorButtonExit'
    },
    iconName: IconEnum.BANK_DETAILS_ERROR
  }
};
