import { FormatNumberOptions, useIntl } from 'react-intl';

import { useRawLabels } from '@ere-uilib/translations/TranslateProvider';

import 'intl'
import 'intl/locale-data/jsonp/en'
import 'intl/locale-data/jsonp/fr'
import 'intl/locale-data/jsonp/zh'
import 'intl/locale-data/jsonp/ar'
import 'intl/locale-data/jsonp/ro'
import 'intl/locale-data/jsonp/es'
import 'intl/locale-data/jsonp/tr'
import 'intl/locale-data/jsonp/sl'
import 'intl/locale-data/jsonp/de'
import 'intl/locale-data/jsonp/nl'
import 'intl/locale-data/jsonp/it'
import 'intl/locale-data/jsonp/pt'

import {
  FormatDateProps,
  FormatMessageProps, FormatNumberProps, FormatTimeProps,
  GetMessageRawProps
} from './interfaces';

const latinNumberingSystem = {
  numberingSystem: 'latn',
}
import { FormatDateOptions } from 'react-intl';

export const useTranslation = () => {
  const intl = useIntl();
  const rawLabels = useRawLabels();
  const getLocal = () => intl.locale
  const formatMessage = ({
    id = '',
    description = '',
    defaultMessage = '...',
    values,
  }: FormatMessageProps): string => {
    if (id === '' || id === undefined) {
      return defaultMessage;
    }

    const translatedMessage = intl.formatMessage({ id: id, description, defaultMessage }, values)?.toString() || defaultMessage;
    return translatedMessage;
  };
  const formatMessageWithJSXInsertion = ({
    id,
    description = '',
    defaultMessage = '...',
    values,
  }: FormatMessageProps): string | {} => {
    if (id === '' || id === undefined) {
      return defaultMessage;
    }
    const translatedMessage = intl.formatMessage({ id, description, defaultMessage }, values) || defaultMessage;
    return translatedMessage;
  };

  const getMessageRaw = ({
    id,
    defaultMessage = '...',
  }: GetMessageRawProps): string => {
    const rawMessage = rawLabels && id && rawLabels[id] && rawLabels[id];
    return rawMessage || defaultMessage;
  };

  const formatNumber = ({ value, options }: FormatNumberProps) => {
    return intl.formatNumber(value, {
      ...options,
      ...latinNumberingSystem
    });
  };

  const formatCurrencyNumber = ({ value, options }: FormatNumberProps) => {
    const currencyOptions: FormatNumberOptions = {
      maximumFractionDigits: 2,
      style: 'currency',
      currency: 'EUR',
      ...latinNumberingSystem,
      ...options,
    };

    return intl.formatNumber(value, currencyOptions);
  };

  const formatCurrencyNumberWithPlusMinus = ({ value, options }: FormatNumberProps): string => {
    // define signe prefix as string to manage space
    const prefix = value > 0 ? '+ ' : value < 0 ? '- ' : '';
    // prevent amount to be nagative as we manage the negative signe
    const normalizedValue = value >= 0 ? value : -value;
    return prefix + formatCurrencyNumber({ value: normalizedValue, ...options, ...latinNumberingSystem });
  };

  const formatShareCountNumber = ({ value, options }: FormatNumberProps) => {
    const currencyOptions: FormatNumberOptions = {
      maximumFractionDigits: 4,
      minimumFractionDigits: 0,
      style: 'decimal',
      ...latinNumberingSystem,
      ...options,
    };
    return intl.formatNumber(value, currencyOptions);
  };

  const formatPercentNumber = ({ value, options = {} }: FormatNumberProps) => {
    const percentageOptions: FormatNumberOptions = {
      minimumFractionDigits: 2,
      style: 'percent',
      ...latinNumberingSystem,
      ...options,
    };
    return intl.formatNumber(Number(value) / 100, percentageOptions);
  };
  const formatPercentNumberWithPlusMinus = ({ value, options }: FormatNumberProps): string => {
    // define signe prefix as string to manage space
    const prefix = value > 0 ? '+ ' : value < 0 ? '- ' : '';
    // prevent amount to be nagative as we manage the negative signe
    const normalizedValue = value >= 0 ? value : -value;
    return prefix + formatPercentNumber({
      value: normalizedValue,
      ...options,
      ...latinNumberingSystem
    });
  };

  const formatDate = ({ value, options }: FormatDateProps) => {
    let formatedDate = intl.formatDate(value, options);
    const dateOptionsAr: FormatDateOptions | undefined = options?.dateStyle === 'short' ? {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
      second: '2-digit',
      minute: '2-digit',
      hour: '2-digit'
    } : options
    if (intl.locale === 'ar') {
      formatedDate = intl.formatDate(value, {
        ...dateOptionsAr,
        ...latinNumberingSystem,
        era: 'short',
      });
    }
    if (intl.locale === 'fr') {
      formatedDate = formatedDate.replaceAll('/', '.');
    }
    return formatedDate;
  };

  const formatTime = ({ value, options }: FormatTimeProps) => {
    return intl.formatTime(value, { ...options, ...latinNumberingSystem });
  };

  return {
    formatMessage,
    formatMessageWithJSXInsertion,
    getMessageRaw,
    formatNumber,
    formatPercentNumber,
    formatCurrencyNumber,
    formatShareCountNumber,
    formatCurrencyNumberWithPlusMinus,
    formatPercentNumberWithPlusMinus,
    formatDate,
    formatTime,
    getLocal,
  };
};
