import React from 'react'
import { View, StyleProp, ViewStyle, TextStyle } from 'react-native'

import { Text } from '@ere-uilib/atoms'
import { createUseStyles, useTheme, ThemeType } from '@ere-uilib/styles'
import { palette } from '@ere-uilib/styles/theme/themeFolder/colors/palette'

interface DataProps {
  titleKey?: string | null
}

type FundSheetHeaderBandStyles = {
  containerStyle?: StyleProp<ViewStyle>
  innerContainer?: StyleProp<TextStyle>
  titleStyle?: StyleProp<TextStyle>
}

type FundSheetHeaderBandStylesContext = { theme: ThemeType }

type Props = DataProps & FundSheetHeaderBandStyles

export const FundSheetHeaderBand: React.FC<React.PropsWithChildren<Props>> = ({
  titleKey,
  innerContainer,
  containerStyle,
  titleStyle
}: Props) => {
  const theme: any = useTheme()
  const styles = useStyles({ theme }, { containerStyle, innerContainer, titleStyle })

  return (
    <View style={styles.containerStyle}>
      <View style={styles.innerContainer}>
        <Text
          style={styles.titleStyle}
          variant="t1"
          weight={'bold'}>
          {titleKey}
        </Text>
      </View>
    </View>
  )
}

const getStyles = (
  context?: FundSheetHeaderBandStylesContext,
  style?: FundSheetHeaderBandStyles
): FundSheetHeaderBandStyles => ({
  containerStyle: [
    {
      display: 'flex',
      flexDirection: 'row',
      padding: 15,
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      backgroundColor: palette.grey.c100
    },
    style?.containerStyle
  ],
  innerContainer: [
    {
      display: 'flex',
      maxWidth: '100%',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      marginLeft: 70,
      marginRight: 70
    },
    style?.innerContainer
  ],
  titleStyle: [
    {
      alignSelf: 'center'
    },
    style?.titleStyle
  ]
})

const useStyles = createUseStyles(getStyles)
