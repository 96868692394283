import React, { PropsWithChildren, useState } from 'react';
import { View } from 'react-native';

import { FontIcon, Title, Pressable } from '@ere-uilib/atoms';
import { useTheme, createUseStyles } from '@ere-uilib/styles';

import { CheckBoxProps } from './interface';
import { getStyles } from './styles';

export const CheckBox: React.FC<PropsWithChildren<CheckBoxProps>> = ({
  value,
  disabled,
  label,
  onPress,
  isHoverableButton = true,
  children,
  containerStyle,
  customColor,
  labelStyle,
  testId
}) => {
  const theme = useTheme();
  const [hovered, setHovered] = useState<boolean>(false);

  const styles = useStyles({
    theme,
    disabled,
    value,
    customColor,
    hovered,
  },
    {
      containerStyle,
      labelStyle,
    });

  return (
    <View style={[styles.containerStyle, children ? { alignItems: 'flex-start' } : null]}>
      <Pressable
        accessibilityLabel={testId}
        testID={testId}
        disabled={disabled}
        onPress={onPress}
        onHoverIn={() => isHoverableButton && setHovered(true)}
        onHoverOut={() => setHovered(false)}>
        <View style={styles.squareViewStyle}>
          {value && (
            <FontIcon
              color={disabled ? theme.colors.basics.grey.c700 : theme.colors.basics.white}
              name="cocher"
            />
          )}
        </View>
      </Pressable>

      {!children && label && (
        <Title
          style={styles.labelStyle}
          variant="t7">
          {label}
        </Title>
      )}
      {children && <View style={styles.childrenStyle}>{children}</View>}
    </View>
  );
};

const useStyles = createUseStyles(getStyles);
