import React from 'react';

interface ModalTimerInferface {
  showModal: (e: boolean) => void;
  duration?: number;
  clicksLimit?: number;
}

export function useModalTimer({
  showModal,
  duration = 3000,
  clicksLimit = 3
}: ModalTimerInferface) {
  const [numClicks, setNumClick] = React.useState<number>(0);
  const [clickTimer, setClickTimer] = React.useState<any>(null);
  const handleClick = React.useCallback(() => {
    // Increment the click counter and set a timer to reset it after {duration} seconds
    if (numClicks < clicksLimit) {
      setNumClick(numClicks + 1);
      setClickTimer(
        setTimeout(() => {
          setClickTimer(null);
          setNumClick(0);
        }, duration)
      );
    }
    // If the button has been clicked {clicksLimit} times within {duration} seconds, open the modal
    if (numClicks === clicksLimit) {
      clearTimeout(clickTimer);
      showModal(true);
      setNumClick(0);
    }
  }, [numClicks, clickTimer]);

  return { handleClick };
}
