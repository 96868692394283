import { Platform } from 'react-native';
import Config from 'react-native-config';

const {
  REACT_APP_API_URL,
  REACT_APP_AUTH_CONFIG_CLIENT_APP_ID,
  REACT_APP_ENV: REACT_APPENV,
  REACT_APP_AES_KEY: REACT_APP_AESKEY,
  REACT_APP_COOKIES_SHARED_DOMAIN,
  REACT_APP_PACKAGE_IDENTIFIER,
  REACT_IOS_BUNDLE_VERSION,
  REACT_IOS_APP_VERSION,
  REACT_APP_WEB_URL,
  REACT_APP_IOS_TEAM_ID,
  REACT_APP_CLIENT_SECRET,
  REACT_ANDROID_VERSION_NAME,
  REACT_MOBILE_APP_SITE_ID,
  REACT_MOBILEE_APP_PIANO_COLLECTION_DOMAIN
} = Config;

const isWeb = Platform.OS === 'web';

const API_URL: string | undefined = isWeb ? process.env.REACT_APP_API_URL : REACT_APP_API_URL;
const CLIENT_SECRET: string = (isWeb ? process.env.REACT_APP_CLIENT_SECRET : REACT_APP_CLIENT_SECRET) ?? ''; // for natie app claimes
const AUTH_CONFIG_CLIENT_ID: string = isWeb
  ? process.env.REACT_APP_AUTH_CONFIG_CLIENT_ID! || ''
  : REACT_APP_AUTH_CONFIG_CLIENT_APP_ID!;
const REACT_APP_AES_KEY: string | undefined = isWeb
  ? process.env.REACT_APP_AES_KEY
  : REACT_APP_AESKEY;
const REACT_APP_ENV: string = (isWeb ? process.env.REACT_APP_ENV : REACT_APPENV) ?? 'NO_ENV';
const COOKIES_SHARED_DOMAIN: string | undefined = isWeb
  ? process.env.REACT_APP_COOKIES_SHARED_DOMAIN
  : REACT_APP_COOKIES_SHARED_DOMAIN;
const REACT_APP_PACKAGE_IDENTIFIER_: string | undefined = isWeb
  ? process.env.REACT_APP_PACKAGE_IDENTIFIER
  : REACT_APP_PACKAGE_IDENTIFIER;
const WEB_URL: string | undefined = isWeb ? process.env.REACT_APP_WEB_URL : REACT_APP_WEB_URL;
const IOS_TEAM_ID: string | undefined = isWeb
  ? process.env.REACT_APP_IOS_TEAM_ID
  : REACT_APP_IOS_TEAM_ID;
const WEB_APP_PORT = process.env.REACT_APP_PORT
const SITE_ID: string | undefined = isWeb
  ? process.env.REACT_APP_SITE_ID
  : REACT_MOBILE_APP_SITE_ID;
const PIANO_COLLECTION_DOMAIN: string | undefined = isWeb
  ? process.env.REACT_APP_PIANO_COLLECTION_DOMAIN
  : REACT_MOBILEE_APP_PIANO_COLLECTION_DOMAIN;
const featureFlipping = {
  vvpTaxDetails: false
};

export {
  REACT_APP_AES_KEY,
  API_URL,
  AUTH_CONFIG_CLIENT_ID,
  REACT_APP_ENV,
  COOKIES_SHARED_DOMAIN,
  featureFlipping,
  REACT_APP_PACKAGE_IDENTIFIER_,
  REACT_IOS_APP_VERSION,
  WEB_URL,
  REACT_IOS_BUNDLE_VERSION,
  IOS_TEAM_ID,
  CLIENT_SECRET,
  REACT_ANDROID_VERSION_NAME,
  WEB_APP_PORT,
  SITE_ID,
  PIANO_COLLECTION_DOMAIN,
};
