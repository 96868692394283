import { Context, FullStyle } from './interfaces';

export const getStyles = (
  context?: Context,
  style?: FullStyle
): FullStyle => ({
  container: [
    {
      flexDirection: 'column',
      justifyContent: 'center',
      padding: context?.theme.metrics.spacing.m,
      borderRadius: context?.theme.metrics.borderRadius.xs,
      shadowRadius: 6,
      shadowColor: context?.theme.colors.notifications.shadow,
      backgroundColor: context?.theme.colors.basics.white,
      shadowOffset: {
        width: 0,
        height: 0,
      },
      shadowOpacity: 1,
      elevation: 10,
    },
    style?.container,
  ],
  statusTag: [
    {
      alignSelf: 'flex-end',
    },
  ],
  loaderContainerStyle: [
    {
      minHeight: 115,
      flex: 0.7,
    },
    context?.isMobile && {
      flex: 1,
    },
  ],
  titleContainer: [
    {
      justifyContent: 'space-between',
      flexDirection: 'row',
      marginBottom: context?.theme.metrics.spacing.xs,
    },
  ],
  title: [
    {
      flex: 1,
      marginRight: context?.theme.metrics.spacing.s,
    },
  ],
  amountStyle: [
    {
      marginRight: context?.theme.metrics.spacing.s,
    },
  ],
  amountAndIconContainer: [
    {
      flexDirection: 'row',
      alignItems: 'flex-start',
    },
  ],
  amountAndSubtitleContainer: [
    {
      flexDirection: 'column',
    },
  ],
  bottomLeftContainer: [
    {
      flex: 1,
      marginRight: context?.theme.metrics.spacing.xs,
    },
  ],
  labelContainer: [
    {
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: context?.theme.metrics.spacing.xs,
    },
  ],
  bottomContainer: [
    {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
    },
  ],
  bottomLeftLabels: [
    {
      marginLeft: context?.theme?.metrics.spacing.xs,
    },
  ],
  iconChevron: [
    {
      marginTop: context?.theme?.metrics.iconSizes.xxxs,
    },
  ],
});
