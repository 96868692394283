import { ThemeType } from '@ere-uilib/styles';
import React from 'react';
import {
  StyleProp,
  TextStyle,
  ViewStyle
} from 'react-native';


export enum EnumSizeTypes {
  small = 'small',
  large = 'large'
}

export interface ActivableButtonSetProps extends ActivableButtonSetStyles {
  label?: string
  children?: React.ReactNode
  testID?: string;
  disabled?: boolean;
  isSelected?: boolean;
  isClassicStyle?: boolean;
  size?: EnumSizeTypes;
  onChange?: () => void;
  hovered: boolean;
  setHovered: (arg: boolean) => void
}

export type ActivableButtonSetStyles = {
  labelStyle?: StyleProp<TextStyle>;
  buttonStyle?: StyleProp<ViewStyle>;
  container?: StyleProp<ViewStyle>;
};

export type ActivableButtonSetStylesContext = {
  theme: ThemeType
  disabled?: boolean
  size?: EnumSizeTypes
  hovered?: boolean;
  isSelected?: boolean;
  isClassicStyle?: boolean;
};
