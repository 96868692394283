import { useMemo } from 'react';
import { Platform, StyleSheet } from 'react-native';

import { useScreenSizes, useTheme } from '@ere-uilib/styles';

import { UseStyle } from './interfaces';

export function useStyles(options?: UseStyle) {
  const theme = useTheme();
  const { isDesktop } = useScreenSizes();
  const shadow = {
    shadowColor: theme.colors.notifications.shadow,
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.85,
    shadowRadius: 10,
    elevation: 1
  };

  const pieChartTopWidth = useMemo(() => {
    if (isDesktop) {
      return;
    }

    return '100%';
  }, [isDesktop]);

  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          flex: 1,
          backgroundColor: theme.colors.basics.white
        },
        rightDesktopColumnInnerHeaderContainer: {
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0
        },
        squareFront: {
          backgroundColor: theme.colors.basics.primary.c500
        },
        subHeaderContainer: {
          marginBottom: theme.metrics.spacing.xm
        },
        mainContentContainer: {
          marginBottom: isDesktop ? theme.metrics.spacing.xl : 0
        },
        mainContentDescription: {
          marginBottom: theme.metrics.spacing.xm
        },
        bottomActions: {
          width: '100%',
          marginBottom:
            !isDesktop && options?.displaySubHeaderSticky ? theme.metrics.spacing.xhuge : 0
        },
        bottomActionsButtonsContainer: {
          width: '100%',
          flexDirection: 'row'
        },
        simpleButton: {
          marginLeft: theme.metrics.spacing.m,
        },
        simpleButtonFlex: {
          flex: 1,
        },
        pieChartContainerHeader: {
          backgroundColor: theme.colors.basics.grey.c100
        },
        pieChartContainerRight: {
          borderBottomLeftRadius: theme.metrics.borderRadius.xs,
          borderBottomRightRadius: theme.metrics.borderRadius.xs,
          paddingTop: 30,
          ...shadow
        },
        chartCard: {
          backgroundColor: theme.colors.basics.white,
          padding: 10,
          marginHorizontal: 5,
          marginVertical: 15,
          borderRadius: theme.metrics.borderRadius.xs,
          minWidth: 150,
          ...(isDesktop ? { width: 150 } : { flex: 1 }),
          ...shadow
        },
        chartTitleStyle: {
          textAlign: 'center'
        },
        legendContainer: {
          marginVertical: 20,
          marginHorizontal: 'auto',
          flexDirection: 'row',
          alignSelf: 'center'
        },
        legendPointStyle: {
          width: 13,
          height: 13,
          borderRadius: 50,
          marginRight: theme.metrics.spacing.xxs
        },
        pieChartTop: {
          flexDirection: 'row',
          flex: 1,
          display: 'flex',
          ...(!isDesktop && { width: pieChartTopWidth })
        },
        pieChartScrollView: {
          marginHorizontal: theme.metrics.spacing.s
        },
        expandableCardContainer: {
          marginBottom: theme.metrics.spacing.xm
        },
        subStikyHeaderTopContainer: {
          backgroundColor: theme.colors.buttons.classic.c500,
          alignItems: 'center',
          paddingVertical: theme.metrics.spacing.s
        },
        subStikyHeaderTitle: {
          color: theme.colors.basics.white
        },
        subStikyHeaderBottomContainer: {
          backgroundColor: theme.colors.basics.white,
          flexDirection: 'column',
          alignItems: 'center',
          paddingVertical: theme.metrics.spacing.s,
          shadowColor: theme.colors.basics.black,
          shadowOffset: {
            width: 0,
            height: 3
          },
          shadowOpacity: 0.17,
          shadowRadius: 3.05,
          elevation: 4
        },
        subStikyHeaderResultContainer: {
          width: '100%',
          marginTop: theme.metrics.spacing.xs,
          flexDirection: 'row',
          justifyContent: 'space-around'
        },
        subStikyHeaderResultItemContainer: {
          flexDirection: 'column',
          alignItems: 'center'
        },
        subStikyHeaderSubResultItemPastille: {
          width: theme.metrics.spacing.s,
          height: theme.metrics.spacing.s,
          borderRadius: theme.metrics.spacing.s,
          marginRight: theme.metrics.spacing.xs
        },
        subStikyHeaderSubResultItemContainer: {
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: theme.metrics.spacing.xs
        },
        honnorDeclarationContentWrapper: {
          alignItems: 'center'
        },
        handShakeIconContainerStyle: {
          borderRadius: theme.metrics.borderRadius.xs,
          padding: theme.metrics.spacing.s,
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: theme.metrics.spacing.xm,
          marginTop: theme.metrics.spacing.xxm
        },
        honnorModalCloseIcon: {
          display: 'flex',
          alignSelf: 'flex-end',
          marginRight: theme.metrics.spacing.xs,
          marginTop: theme.metrics.spacing.xs
        },
        honnorModalTitleStyle: {
          marginTop: theme.metrics.spacing.xs,
          marginBottom: theme.metrics.spacing.s
        },
        honnorModalCheckBoxStyle: {
          marginBottom: theme.metrics.spacing.l
        },
        honnorModalContentTextStyle: {
          marginLeft: theme.metrics.spacing.m
        },
        buttonContainer: {
          width: "100%"
        },
      }),
    [isDesktop, theme, pieChartTopWidth, shadow]
  );

  return styles;
}
