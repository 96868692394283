
import React, { useState } from 'react';
import { View, TouchableOpacity } from 'react-native';

import { Text } from '@ere-uilib/atoms';
import { createUseStyles, useScreenSizes, useTheme } from '@ere-uilib/styles';

import { LegalMenuFooterAccordion } from "./components/LegalMenuFooterAccordion";
import { LegalMenuFooterItemType, LegalMenuFooterProps } from './interface';
import { getStyles } from './styles';

export const LegalMenuFooterComponent = ({
  onGetLegalContent,
  LegalContentsModal,
  links,
  isWhite = false,
  listContainerStyle,
  listItemContainerStyle,
  listItemTextStyle,
  listItemTextSeparatorStyle,
  appVersion,
}:LegalMenuFooterProps) => {
  const theme = useTheme();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedElementTitle, setSelectedElementTitle] = useState('');
  const { isMobile } = useScreenSizes();

  const styles = useStyles(
    {
      theme,
      isWhite,
    },
    {
      listContainerStyle,
      listItemContainerStyle,
      listItemTextStyle,
      listItemTextSeparatorStyle,
    }
  );

  const onItemPressed = (item: LegalMenuFooterItemType) =>{
    onGetLegalContent(item.url);
    setSelectedElementTitle(item.label);
    setIsModalVisible(true);
  };
  if (links?.length < 1) { return null;}
  return (
    <>
      {
        isMobile ? <LegalMenuFooterAccordion
          appVersion={appVersion}
          isWhite={isWhite}
          links={links}
          onItemPressed={onItemPressed}
        /> :
          <View style={styles.listContainerStyle}>
            {links.map((item, index)=>{
              return (
                <React.Fragment
                  key={item.label + '_' + index}>
                  {index > 0 && (
                    <Text style={[styles.listItemTextStyle, styles.listItemTextSeparatorStyle]}>
                      {'|'}
                    </Text>
                  )}
                  <Text style={styles.listItemTextStyle} onPress={()=>{onItemPressed(item)}}>
                    {item.label}
                  </Text>
                </React.Fragment>
              );
            })}
          </View>
      }
      {
        LegalContentsModal({ display: isModalVisible, onClose: ()=>{setIsModalVisible(false);},
          title: selectedElementTitle })
      }
    </>
  );
};
const useStyles = createUseStyles(getStyles);
