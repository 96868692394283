import "setimmediate";
import React, { useState, useEffect, useRef } from 'react';
import {
  View,
  Easing,
  Animated
} from 'react-native';


import { Text, Pressable, Spinner } from '@ere-uilib/atoms';

import { SwitchProps } from './interface';
import { useStyles } from './useStyles';

export const Switch: React.FC<React.PropsWithChildren<SwitchProps>> = ({
  disabled,
  label,
  onToggle,
  containerStyle,
  toggleStyle,
  knobStyle,
  labelStyle,
  isOn,
  isLoading,
  testID,
  toggleOnColor
}) => {

  const knobOffset = 13;
  const animatedValue = useRef(new Animated.Value(isOn ? knobOffset : 0)).current;

  const [hovered, setHovered] = useState<boolean>(false);
  const [pressed, setPressed] = useState<boolean>(false);

  useEffect(() => {
    Animated.timing(animatedValue, {
      toValue: isOn ? knobOffset : 0,
      easing: Easing.ease,
      duration: 100,
      useNativeDriver: true,
    }).start();
  }, [isOn]);


  const styles = useStyles({
    toggleOnColor,
    disabled,
    hovered,
    pressed
  });

  return (
    <View
      style={[styles.container, containerStyle]}
      testID={testID}>
      <Pressable
        disabled={disabled || isLoading}
        onPressIn={() => setPressed(true)}
        onPressOut={() => setPressed(false)}
        onHoverIn={() => setHovered(true)}
        onHoverOut={() => setHovered(false)}
        onPress={() => onToggle?.(!isOn)}
        style={[
          styles.toggle,
          !isLoading && isOn && styles.toggleOn,
          isLoading && styles.toggleLoading,
          toggleStyle
        ]}>
        {isLoading && <Spinner size={16}/>}
        {!isLoading &&
          <Animated.View
            style={[
              styles.knob,
              isOn && styles.knobOn,
              knobStyle,
              {
                transform: [
                  {
                    translateX: animatedValue
                  }
                ]
              }
            ]}
          />
        }
      </Pressable>
      <Text style={[styles.label, labelStyle]}>{label}</Text>
    </View>
  )
};
