
import { connect } from 'react-redux';

import { AppState } from '@modules/index';
import {
  getCalculatedContributionDataRequest,
  resetProfitSharingIncentiveCalculatedContributionAction,
  setProfitSharingIncentiveRepartitionAmountModalVisibleState,
  setProfitSharingIncentiveRepartitionAmountRequest
} from '@modules/profit-sharing-incentive/actions/profitSharingIncentiveActions';
import {
  ProfitSharingIncentiveActionsType,
} from '@modules/profit-sharing-incentive/actions/profitSharingIncentiveActionsTypes';
import {
  getProfitSharingIncentiveAssigmentAmounts,
  getProfitSharingIncentiveCalculatedContribution,
  getProfitSharingIncentiveRepartitionAmountModalVisibleState,
  getProfitSharingIncentiveRepartitionState,
} from '@modules/profit-sharing-incentive/selectors';

import { ProfitSharingIncentiveAmountFieldModalConnectedComponent } from './ProfitSharingIncentiveAmountFieldModalConnected.component';

const mapStateToProps = (state: AppState) => {
  return {
    assigmentAmounts: getProfitSharingIncentiveAssigmentAmounts(state),
    profitSharingIncentiveRepartitionData: getProfitSharingIncentiveRepartitionState(state),
    calculatedContribution: getProfitSharingIncentiveCalculatedContribution(state),
    isSetProfitSharingIncentiveAmountLoading:
      state.isLoading[
        ProfitSharingIncentiveActionsType.SET_PARTICIPATION_PROFITSHARING_REPARTITION_AMOUNT_REQUEST
      ],
    isCalculContributionLoading:
      state.isLoading[
        ProfitSharingIncentiveActionsType
          .GET_PARTICIPATION_PROFITSHARING_CALCULATED_CONTRIBUTION_REQUEST
      ],
    calculatedContributionError:
      state.error[
        ProfitSharingIncentiveActionsType
          .GET_PARTICIPATION_PROFITSHARING_CALCULATED_CONTRIBUTION_REQUEST
      ],
    visible: getProfitSharingIncentiveRepartitionAmountModalVisibleState(state)
  };
};

const mapDispatchToProps = {
  onSetProfitSharingIncentiveRepartitionAmount: setProfitSharingIncentiveRepartitionAmountRequest,
  onCalculContribution: getCalculatedContributionDataRequest,
  onInitCalculatedContribution: resetProfitSharingIncentiveCalculatedContributionAction,
  onSetProfitSharingIncentiveRepartitionAmountModalVisibleState: setProfitSharingIncentiveRepartitionAmountModalVisibleState,
};

export const ProfitSharingIncentiveAmountFieldModalConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(ProfitSharingIncentiveAmountFieldModalConnectedComponent);
