import React, { useCallback } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

import { HtmlStyledRenderConnectedContainer } from '@components/index';
import { Title, Text, FontIcon, useTheme, IconEnum } from '@ere-uilib/index';
import { BankStatusTypesEnum } from '@modules/bank-details/constants';
import { InputMode } from '@modules/common/constants';

import { useLabels } from '../../hooks';
import { useStyles } from './useStyles';

interface Props {
  inputMode?: string | null;
  status?: string | null,
  onDocumentPress?: (documentType: string, documentTitle: string) => void;
}

interface StyleProps {
  stepperContainer?: StyleProp<ViewStyle>
  stepperStyle?: StyleProp<ViewStyle>
}

export const SupportingDocuments: React.FC<React.PropsWithChildren<Props & StyleProps>> = ({ inputMode, status }) => {

  const theme = useTheme();
  const styles = useStyles();
  const labels = useLabels();
  const justificatifsLabels: string[] = [
    labels.cniLabel,
    labels.passportLabel,
  ]
  const renderItems = () => {
    // TODO: remove mock and replace with getMessage
    const mandatSampleHtml = `<a href='/telechargement?documentType=Brochure&language=French'>(Modèle accessible sur le site web salarié)</a>`
    return (
      <View style={styles.renderContentContainerStyle}>
        {justificatifsLabels.map((justificatifLabel, index) => (
          <View
            key={`${justificatifLabel}#${index}`}
            style={styles.rowStyle}>
            <View
              style={styles.iconStyle}>
              <FontIcon
                color={theme.colors.basics.white}
                name={IconEnum.CHECK}
                size={theme.metrics.iconSizes.xxs}
              />
            </View>
            <Text
              variant="t3"
              weight="light"
            >
              {justificatifLabel}
            </Text>
          </View>
        ))}
        {
          status === BankStatusTypesEnum.INVALIDE &&
          <View

            style={styles.rowStyle}>
            <View
              style={styles.iconStyle}>
              <FontIcon
                color={theme.colors.basics.white}
                name={IconEnum.CHECK}
                size={theme.metrics.iconSizes.xxs}
              />
            </View>
            <View style={styles.mandatSampleStyle}>
              <View>
                <Text
                  variant="t3"
                  weight="light"
                >
                  {labels.mandatSampleLabel}
                </Text>
                <HtmlStyledRenderConnectedContainer
                  baseFontStyle={{ fontFamily: theme.fonts.fontFamily.light }}
                  html={mandatSampleHtml}
                />
              </View>
            </View>
          </View>
        }
      </View>
    );
  };

  const renderTSAMode = useCallback(() => {
    if (inputMode !== InputMode.TSA) { return null; }

    return (
      <>
        <Title
          style={styles.titleStyles}
          variant="t4"
          weight="bold">
          {labels.rhCorrespondantTitle}
        </Title>
        <Text
          variant='t3'>
          {labels.courrierEnvoiTitle}
        </Text>
        {renderItems()}
      </>
    )
  }, [
    inputMode
  ]);

  const renderTSAORUPLOADMode = useCallback(() => {
    if (inputMode !== InputMode.TSA_OR_UPLOAD) { return null; }
    return (<>
      <Title
        style={styles.titleStyles}
        variant="t7"
        weight="bold">
        {labels.tsaOrUploadtitle}
      </Title>
      {renderItems()}
    </>
    )
  }, [
    inputMode
  ]);

  const renderSERVICERHMode = useCallback(() => {
    if (inputMode !== InputMode.SERVICE_RH) { return null; }
    return (
      <>
        <Title
          style={styles.titleStyles}
          variant="t4"
          weight="bold">
          {labels.rhCorrespondantTitle}
        </Title>
        <Text
          variant='t3'>
          {labels.rhDescription}
        </Text>
        {renderItems()}
        <Text
          variant='t3'
          weight="light">
          {labels.existedMandatLabel}
        </Text>
      </>
    )
  }, [
    inputMode
  ]);

  const renderCORRESPONDANTMode = useCallback(() => {
    if (inputMode !== InputMode.CORRESPONDANT) { return null; }
    return (
      <>
        <Title
          style={styles.titleStyles}
          variant="t4"
          weight="bold">
          {labels.rhCorrespondantTitle}
        </Title>
        <Text
          variant='t3'>
          {labels.correspondantDescription}
        </Text>
      </>
    )
  }, [
    inputMode
  ]);

  return (
    <View style={styles.container}>
      {renderTSAMode()}
      {renderTSAORUPLOADMode()}
      {renderSERVICERHMode()}
      {renderCORRESPONDANTMode()}
    </View>
  );
};
