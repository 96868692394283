import * as actions from './campaignActionsTypes';
import { CampaignActionsType } from './campaignActionsTypes';
import {
  CampaignData,
  CampaignErrorState,
  CampaignValueState
} from '../types';

export function getUserCampaignRequest(
  campaignId: string
): actions.GetUserCampaignRequestAction {
  return {
    campaignId,
    type: CampaignActionsType.GET_USER_CAMPAIGN_REQUEST,
  };
}

export function getUserCampaignSuccess(campaignData: CampaignData): actions.GetUserCampaignSuccessAction {
  return {
    type: CampaignActionsType.GET_USER_CAMPAIGN_SUCCESS,
    campaignData
  };
}

export function getUserCampaignFailure(
  error: CampaignErrorState,
): actions.GetUserCampaignFailureAction {
  return {
    type: CampaignActionsType.GET_USER_CAMPAIGN_FAILURE,
    error,
  };
}

export function submitUserCampaignRequest({
  campaignId,
  value,
  toDoPostSuccessActionType
}:{
  campaignId: string,
  value: CampaignValueState,
  toDoPostSuccessActionType?: string,
}): actions.SubmitUserCampaignRequestAction {
  return {
    type: CampaignActionsType.SUBMIT_USER_CAMPAIGN_REQUEST,
    campaignId,
    value,
    toDoPostSuccessActionType,
  };
}

export function submitUserCampaignSuccess(
  campaignId: string,
  isLaterAnswer?: boolean
): actions.SubmitUserCampaignSuccessAction {
  return {
    type: CampaignActionsType.SUBMIT_USER_CAMPAIGN_SUCCESS,
    campaignId,
    isLaterAnswer
  };
}

export function submitUserCampaignFailure(
  error: CampaignErrorState,
): actions.SubmitUserCampaignFailureAction {
  return {
    type: CampaignActionsType.SUBMIT_USER_CAMPAIGN_FAILURE,
    error,
  };
}

export function executeCallback(
  callbackActionType: string,
  redirectionAfterCallback?: keyof ReactNavigation.RootParamList
): actions.ExecuteCallbackAction {
  return {
    type: CampaignActionsType.EXECUTE_CALLBACK,
    callbackActionType,
    redirectionAfterCallback
  }
}

export function resetCampaignReducer(): actions.ResetCampaignReducerAction {
  return {
    type: CampaignActionsType.RESET_CAMPAIGN_REDUCER,
  };
}