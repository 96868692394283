import { connect } from 'react-redux';

import { DashboardActionsType } from '@modules/dashboard/actions/dashboardActionsTypes';
import { getBudgetInsightUrlRequest } from '@modules/dashboard/actions/dashboardActions';
import { AppState } from '@modules/index';
import { getBudgetInsightUrl, getError } from '@modules/dashboard/selectors';

import { BudgetInsightConsentementComponent } from './BudgetInsightConsentementPage.component';

const mapStateToProps = (state: AppState) => {
  return {
    budgetInsightUrl: getBudgetInsightUrl(state),
    isApiLoading: state.isLoading[DashboardActionsType.GET_BUDGET_INSIGHT_URL_REQUEST],
    error: getError('budgetInsightGetUrlError', state)
  };
};

const mapDispatchToProps = {
  onGetBudgetInsightUrl: getBudgetInsightUrlRequest
};

export const BudgetInsightConsentementPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(BudgetInsightConsentementComponent);
