export enum ProfileStackNavigatorRouteNames {
  Profile = 'Profile',
  ProfileEditEmail = 'ProfileEditEmail',
  ProfileEditPhone = 'ProfileEditPhone',
  ProfileLegalInfo = 'ProfileLegalInfo',
  EditProfileInformations = 'EditProfileInformations',
  EditPassword = 'EditPassword',
  ContactPreferences = 'ContactPreferences',
  BusinessContactPreferences = 'BusinessContactPreferences',
  ConnexionBiometrique = 'ConnexionBiometrique',
  Editorial = 'Editorial'
}

export type ProfileStackNavigatorInterface = {
  [ProfileStackNavigatorRouteNames.Profile]: undefined;
  [ProfileStackNavigatorRouteNames.ProfileEditEmail]: undefined;
  [ProfileStackNavigatorRouteNames.ProfileEditPhone]: undefined;
  [ProfileStackNavigatorRouteNames.ProfileLegalInfo]: undefined;
  [ProfileStackNavigatorRouteNames.EditProfileInformations]: undefined;
  [ProfileStackNavigatorRouteNames.EditPassword]: undefined;
  [ProfileStackNavigatorRouteNames.ContactPreferences]: undefined;
  [ProfileStackNavigatorRouteNames.BusinessContactPreferences]: undefined;
  [ProfileStackNavigatorRouteNames.ConnexionBiometrique]: undefined;
  [ProfileStackNavigatorRouteNames.Editorial]: undefined;
};
