import React, { useEffect, useRef, useState } from 'react'

import { Animated, Pressable, View } from 'react-native'
import { useStyles } from './useStyles'
import { Text } from '../texts'

interface IToggleProps {
  disabled?: boolean
  label: string
  onToggleStatusChange: (_arg: boolean) => void
}
export const ToggleText = ({ disabled = false, label, onToggleStatusChange }: IToggleProps) => {
  const [isToggleActive, setIsToggleActive] = useState<boolean>(false)
  const [animatedValue] = useState(new Animated.Value(isToggleActive ? 1 : 0));

  const AnimatedTouchable = Animated.createAnimatedComponent(Pressable)
  const styles = useStyles(isToggleActive, disabled)

  useEffect(() => {
    disabled && setIsToggleActive(false)
  }, [disabled])

  useEffect(() => {
    Animated.timing(animatedValue, {
      toValue: isToggleActive ? 1 : 0,
      useNativeDriver: true
    }).start()
  }, [isToggleActive])

  const toggleSwitch = () => {
    !disabled && setIsToggleActive(!isToggleActive);
    onToggleStatusChange(!isToggleActive)
  };

  const translateX = animatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: [1, 16], // Adjust the distance of the switch head
  });

  return (
    <View style={styles.container}>
      <AnimatedTouchable
        style={styles.toggleContainer}
        onPress={() => toggleSwitch() }>
        <Animated.View
          style={[styles.circleContainer, { transform: [{ translateX }] }]}
        />
      </AnimatedTouchable>
      <Text
        variant="t3"
        weight="light"
        style={styles.toggleText}>
        {label}
      </Text>
    </View>
  )
}
