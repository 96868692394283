import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import { useTranslation } from '@components/ERE-UILib';

import { AnnuityBirthDate } from '../components/AnnuityBirthDate';
import { AnnuityDepartureAge } from '../components/AnnuityDepartureAge';
import { AnnuityGender } from '../components/AnnuityGender';
import { AnnuityReversion } from '../components/AnnuityReversion';
import { AnnuitySimulationType } from '../components/AnnuitySimulationType';
import { RENTE_REVERSION_RATES_DEFAULT } from '../constants';
import { AnnuitySimulatorStepperItem, AnnuitySimulatorStepperProps } from './interfaces';
import { Data, Gender, SimulationTypeOperation } from './sharedInterfaces';
import { useAnnuitySimulatorValidator } from './useAnnuitySimulatorValidator';
// TODO: to be remobed once the formatDate is corrected

dayjs.extend(utc);
export const useAnnuitySimulatorStepper = ({
  onUpdateData,
  settings
}: AnnuitySimulatorStepperProps) => {
  const { formatMessage, formatDate, formatCurrencyNumber, getMessageRaw } = useTranslation();

  const { validateBirthDate, validateDepartureAge, validateAmount } = useAnnuitySimulatorValidator(settings);

  const stepper = useMemo<{ [P in keyof Data]: AnnuitySimulatorStepperItem }>(
    () => ({
      gender: {
        title: formatMessage({ id: 'PensionSimulatorGender' }),
        description: formatMessage({ id: 'PensionSimulatorGender' }),
        formatValue: (data: Data) =>
          formatMessage({
            id:
              (data.gender === Gender.Woman && 'PensionSimulatorGenderFemale') ||
              (data.gender === Gender.Man && 'PensionSimulatorGenderMale') ||
              ''
          }),
        renderChildren: (data: Data) => (
          <AnnuityGender
            onSubmit={value => onUpdateData({ key: 'gender', value })}
            value={data.gender}
          />
        )
      },
      birthDate: {
        title: formatMessage({ id: 'PensionSimulatorBithDate' }),
        description: formatMessage({ id: 'PensionSimulatorRentalBirthDateEntry' }),
        formatValue: (data: Data) => {
          if (!data.birthDate) return '';
          // TODO: birthdays must not be affected by timezone
          return dayjs(data.birthDate, {utc: true}).format('DD-MM-YYYY').replaceAll('-', '.');
        },
        renderChildren: (data: Data) => (
          <AnnuityBirthDate
            onSubmit={value => onUpdateData({ key: 'birthDate', value })}
            validator={validateBirthDate}
            value={data.birthDate}
          />
        )
      },
      departureAge: {
        title: formatMessage({ id: 'PensionSimulatorAgeForRental' }),
        description: formatMessage({ id: 'PensionSimulatorRentalDepartureWhish' }),
        formatValue: (data: Data) =>
          (data.departureAge &&
            data.departureAge +
            ' ' +
            formatMessage({
              id: 'PensionSimulatorRentalDepartureYearNumber'
            })) ||
          '',
        renderChildren: (data: Data) => (
          <AnnuityDepartureAge
            birthDate={data.birthDate}
            departureAge={data.departureAge}
            onSubmit={value => onUpdateData({ key: 'departureAge', value })}
            validator={validateDepartureAge(data.birthDate)}
          />
        )
      },
      simulationType: {
        title: formatMessage({ id: 'PensionSimulatorSimulationType' }),
        description: formatMessage({ id: 'PensionSimulatorIWantSimulate' }),
        formatValue: (data: Data) =>
          formatMessage({
            id:
              (data.simulationType?.operation === SimulationTypeOperation.CapitalToAnnuity &&
                'PensionSimulatorCapitalTitle') ||
              (data.simulationType?.operation === SimulationTypeOperation.AnnuityToCapital &&
                'PensionSimulatorMonthlyPaymentTitle') ||
              'NO_LABEL'
          }) +
          ' - ' +
          formatCurrencyNumber({
            value: +(data.simulationType?.amount ?? 0),
            options: { maximumFractionDigits: 0 }
          }),
        renderChildren: (data: Data) => (
          <AnnuitySimulationType
            onSubmit={value => onUpdateData({ key: 'simulationType', value })}
            validator={validateAmount}
            value={data.simulationType}
          />
        )
      },
      reversion: {
        title: formatMessage({ id: 'PensionSimulatorRecipientDesignation' }),
        description: formatMessage({ id: 'PensionSimulatorCapitalReversionIntro' }),
        showHelpPoint: true,
        helpPointContentHtml: getMessageRaw({
          id: 'PensionSimulatorCapitalReversionIntroHelp'
        }),
        helpPointModalTitle: formatMessage({ id: 'PensionSimulatorTitleHelpHeader' }),
        formatValue: (data: Data) =>
          formatMessage({
            id: data.reversion?.rate
              ? 'PensionSimulatorRecipientDesignationYes'
              : 'PensionSimulatorRecipientDesignationNo'
          }),
        renderChildren: (data: Data) => (
          <AnnuityReversion
            onSubmit={value => onUpdateData({ key: 'reversion', value })}
            ratesList={settings.RenteReversionRates || RENTE_REVERSION_RATES_DEFAULT}
            validator={validateBirthDate}
            value={data.reversion}
          />
        )
      }
    }),
    [
      formatMessage,
      onUpdateData,
      formatDate,
      validateBirthDate,
      validateDepartureAge,
      settings,
      formatCurrencyNumber,
      validateAmount,
      getMessageRaw
    ]
  );

  return stepper;
};
