import { useMemo } from "react";
import { StyleSheet } from "react-native";

import { useScreenSizes } from "@ere-uilib/styles";

export const useStyles = () => {
  const { isMobile } = useScreenSizes();

  const styles = useMemo(() => StyleSheet.create({
    containerStyle: {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row'
    },
    innerContainerStyle: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
      margin: 20
    },
    placeholder75: {
      width: '75%',
      marginBottom: 15
    },
    placeholder100: {
      width: '100%',
      marginBottom: 15
    }
  }), [isMobile]);

  return styles;
};