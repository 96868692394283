import {
  RefundAskProofPageContext,
  RefundAskProofPageStyles,
} from './interfaces';

export const getStyles = (
  context: RefundAskProofPageContext,
  style: RefundAskProofPageStyles
): RefundAskProofPageStyles => ({
  container: [
    {
      backgroundColor: context?.theme.colors.basics.white,
    },
    style?.container,
  ],
  bottomActionsStyle: [
    {
      width: '100%',
    },
    !(context?.isMobile) && {
      marginTop: context?.theme.metrics.spacing.xl,
    },
    style?.bottomActionsStyle,
  ],
  bottomActionsButtonsContainerStyle: [
    {
      width: '100%',
      flexDirection: 'row',
    },
    style?.bottomActionsButtonsContainerStyle,
  ],
  simpleButtonStyle: [{
    marginLeft: context?.theme.metrics.spacing.m,
    flex: 1,
  },
  style?.simpleButtonStyle,
  ],
  skipStepImgStyle: [
    {
      width: 225,
      height: 276,
      alignSelf: 'center',
    },
    style?.skipStepImgStyle,
  ],
  filetStepperContainerStyle: [
    {
      flex: 1,
    },
    style?.filetStepperContainerStyle,
  ],
  contentStyle: [
    {
      marginLeft: context?.theme.metrics.spacing.xm,
      width: '100%',
    },
    style?.contentStyle,
  ],
  textContentStyle: [
    {
      marginTop: context?.theme.metrics.spacing.l,
    },
    style?.textContentStyle,
  ],
});
