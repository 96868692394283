import { Context, Styles } from './interfaces';

export const getStyles = (
  context?: Context,
  style?: Styles
): Styles => ({
  validationCodeContainerContentStyle: [
    (context?.isMobile || context?.isTablet) && {
      marginTop: context?.theme.metrics.spacing.xbig
    },
    context?.isTablet && {
      marginLeft: context?.theme.metrics.spacing.xhuge
    },
    style?.validationCodeContainerContentStyle
  ],
  titleStyle: [
    {
      marginBottom: context?.theme.metrics.spacing.xl
    },
    style?.titleStyle
  ],
  subtitleStyle: [
    {
      marginBottom: 0
    },
    style?.subtitleStyle
  ]
});
