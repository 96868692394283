import { connect } from 'react-redux';

import { AppState } from '@modules/reducers';
import { getInstallmentPaymentResponseData } from '@modules/installment/selectors';

import { InstallmentPaymentCBHtmlPageComponent } from './InstallmentPaymentCBHtmlPage.component';

const mapStateToProps = (state: AppState) => {
  return {
    installmentPaymentResponseData: getInstallmentPaymentResponseData(state)
  };
};

export const InstallmentPaymentCBHtmlPage = connect(mapStateToProps, null)(InstallmentPaymentCBHtmlPageComponent);
