import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useScreenSizes, useTheme } from '@ere-uilib/styles';

export function useStyles() {
  const theme = useTheme();
  const { isDesktop } = useScreenSizes()

  const styles = useMemo(() => StyleSheet.create({
    title: {
      marginBottom: theme.metrics.spacing.s
    },
    description: {
      marginBottom: theme.metrics.spacing.l
    },
    assigmentSelectorStyle: {
      maxWidth: theme.metrics.contentSizes.leftSideContentMaxWidth,
      marginBottom: theme.metrics.spacing.l,
    },
    bottomButtons: {
      marginTop: theme.metrics.spacing.xm,
      ...(isDesktop && {
        maxWidth: theme.metrics.contentSizes.leftSideContentMaxWidth
      })
    },
    notifications: { 
      marginTop: theme.metrics.spacing.xm,
    },
    fiscalityRatecontainer: {
      flexDirection: 'row',
      alignItems: 'flex-start',
      marginTop: theme.metrics.spacing.xs
    },
    fiscalityRateLabel: {
      flex: 1,
      marginRight: theme.metrics.spacing.l
    },
    paymentModeDashLine: {
      marginVertical: theme.metrics.spacing.xm,
      marginHorizontal: - theme.metrics.spacing.m
    },
    linkParagraph: {
      marginTop: theme.metrics.spacing.xs
    },
    bankAccountHeader: {
      backgroundColor: theme.colors.basics.white,
      borderBottomColor: theme.colors.basics.grey.c200,
      borderBottomWidth: 1,
    },
    originRecapStyle: {
      marginTop: theme.metrics.spacing.l,
    },
    contentContainer: { 
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    content: { 
      flex: 1,
      maxWidth: theme.metrics.contentSizes.centeredContentMaxWidth,
      ...(isDesktop ? {
        margin: theme.metrics.spacing.xm,
      }:{
        margin: theme.metrics.spacing.s,
      })
    },
    errorNotificationContainerStyle: {
      marginBottom: theme.metrics.spacing.s,
    },
    subHeaderSeilContainer: {
      backgroundColor: theme.colors.buttons.classicHover.c500,
      paddingVertical: theme.metrics.spacing.m,
      paddingHorizontal: theme.metrics.spacing.s,
    },
    subHeaderSeilText: {
      color: theme.colors.basics.white,
      marginBottom: theme.metrics.spacing.xs,
    },
    triggerThresholdContainer: {
      paddingHorizontal: theme?.metrics.spacing.s,
      paddingBottom: theme?.metrics.spacing.xs,
    },
    bankAccountContainer: {
      marginLeft: theme.metrics.spacing.s,
      marginTop: theme.metrics.spacing.s,
      marginBottom: theme.metrics.spacing.xm,
    },
  }), [theme, isDesktop]);

  return styles;
}
