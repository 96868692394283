import React, { ReactNode } from 'react';

import {
  DashboardPageWrapperConnected,
  ColumnsContainer,
  useScreenSizes,
} from '@components/index';

export interface Props {
  renderHeader: () => ReactNode;
  renderSubHeader: () => ReactNode;
  renderContent: () => ReactNode;
  renderActionButtons: () => ReactNode;
}

export const VVPDetailsPageTemplate: React.FC<React.PropsWithChildren<Props>> = ({
  renderHeader,
  renderSubHeader,
  renderContent,
  renderActionButtons,
}) => {
  const { isDesktop } = useScreenSizes();
  return (
    <DashboardPageWrapperConnected 
      renderStickyMobileBottom={renderActionButtons}
    >
      <ColumnsContainer
        hasRenderRightDesktopColumn={false}
        leftColumnStyle={{
          paddingRight: 0,
          paddingLeft: 0,
          paddingTop: 0,
        }}
        renderHeader={renderHeader}
      >
        {renderSubHeader()}
        {renderContent()}
        {isDesktop && renderActionButtons()}
      </ColumnsContainer>
    </DashboardPageWrapperConnected>
  );
};

