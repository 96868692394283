import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useScreenSizes, useTheme } from '@ere-uilib/styles';

export function useStyles() {
  const theme = useTheme();
  const { isDesktop, isTablet, isMobile } = useScreenSizes()

  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          zIndex: 1,
          backgroundColor: theme.colors.basics.white,
        },
        headerHtmlWrapper: {
          marginBottom: theme.metrics.spacing.l
        },
        contentWrapper: {
          alignSelf: 'center',
          width: isDesktop ? "80%" : "100%"
        },
        buttonContainer: {
          marginVertical: theme.metrics.spacing.l,
          alignSelf: (isMobile || isTablet) ? 'auto' : 'flex-start',
        },
        svgImgWrapper: {
          flex: 1
        },
        successPopinSVGImgContent: {
          width: "100%",
          height: theme.metrics.spacing.huge * 2
        },
        successTextDescription: {
          marginTop: theme.metrics.spacing.l
        }
      }),
    [theme, isDesktop, isMobile, isTablet]
  );

  return styles;
}
