import {
  StylesContext,
  EndOidcSessionWebViewStyles,
} from './interface';
export const getStyles = (
  context?: StylesContext,
  style?: EndOidcSessionWebViewStyles,
): EndOidcSessionWebViewStyles => ({
  oidcEndSessionWebView: [
    {
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: -1,
      width: 0,
      height: 0,
      overflow: 'hidden',
    },
    style?.oidcEndSessionWebView,
  ],
});
