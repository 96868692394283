import { connect } from 'react-redux';

import { getProfitSharingIncentiveInitDataState } from '@modules/profit-sharing-incentive/selectors';
import {
  ProfitSharingIncentiveActionsType
} from '@modules/profit-sharing-incentive/actions/profitSharingIncentiveActionsTypes';
import {
  getProfitSharingIncentiveInitDataRequest
} from '@modules/profit-sharing-incentive/actions/profitSharingIncentiveActions';
import { AppState } from '@modules/reducers';

import { ProfitSharingIncentiveDescriptionPageComponent } from './ProfitSharingIncentiveDescription.component';

const mapStateToProps = (state: AppState) => {
  return {
    isLoading: state.isLoading[ProfitSharingIncentiveActionsType.GET_PARTICIPATION_PROFITSHARING_INIT_DATA_REQUEST],
    error: state.error[ProfitSharingIncentiveActionsType.GET_PARTICIPATION_PROFITSHARING_INIT_DATA_REQUEST],
    initData: getProfitSharingIncentiveInitDataState(state)
  };
};

const mapDispatchToProps = {
  getProfitSharingIncentiveInitData: getProfitSharingIncentiveInitDataRequest
};

export const ProfitSharingIncentiveDescription = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfitSharingIncentiveDescriptionPageComponent);
