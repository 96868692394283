import React, { useMemo } from "react";
import { View } from "react-native";

import { Text } from "@ere-uilib/atoms";
import { useTranslation } from "@ere-uilib/translations";

import { DispositifTableProps } from "./interface";
import { useStyles } from "./useStyles";

export const DispositifTable: React.FC<React.PropsWithChildren<DispositifTableProps>> = ({ tableData }) => {
  const styles = useStyles()
  const { formatMessage, formatCurrencyNumber } = useTranslation()

  const tableDictionnary = useMemo(() => {
    return {
      tableHeader: {
        dispositifs: formatMessage({ id: 'UnavailableRepaymentHeaderPlan' }),
        brutAmount: formatMessage({ id: 'UnavailableRepaymentHeaderAmount' }),
        deadline: formatMessage({ id: 'UnavailableRepaymentHeaderDeadline' }),
      },
      mention: formatMessage({ id: 'UnavailableRepaymentMentionDeadline' }),
      disabledDispositifMessage: formatMessage({ id: 'UnavailableRepaymentMessageIneligiblePlan' }),
      noEligibleDispositifs: formatMessage({ id: 'UnavailableRepaymentMessageIneligibleCard' }),
    }
  }, [formatMessage]);

  return (!!tableData?.length) ? (
    <View style={styles.wrapperContainer}>
      <View style={styles.tableContainer}>
        <View style={styles.tableHeader}>
          <Text
            style={styles.disabledTextStyle}
            variant="t4"
            weight="light">{tableDictionnary.tableHeader.dispositifs}</Text>
          <Text
            style={styles.disabledTextStyle}
            variant="t4"
            weight="light">{tableDictionnary.tableHeader.brutAmount}</Text>
        </View>
        {tableData?.map((dispositif, index, array) => {
          const lastIndex = array.length - 1 === index

          return (
            <View
              key={index}
              style={[styles.tableBody, !lastIndex && styles.tableLineStyle]}>
              <Text
                variant="t3"
                weight="regular">{dispositif.planName}</Text>

              <Text
                variant="t3"
                weight="regular">{formatCurrencyNumber({ value: dispositif.amount })}</Text>
            </View>
          )
        })}
      </View>
    </View >
  ) : (
    <Text
      style={[styles.disabledTextStyle, styles.noEligibleDispositif]}
      variant="t3"
      weight="light">{tableDictionnary.noEligibleDispositifs}</Text>
  )

}