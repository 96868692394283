import { useTheme } from '@ere-uilib/styles';
import React, { useMemo } from 'react';
import { StyleSheet } from 'react-native';

export const useStyles = () => {
  const theme = useTheme();

  const style = useMemo(() => StyleSheet.create({
    cardContent: {
      padding: theme.metrics.spacing.s,
      display: 'flex',
      borderBottomColor: theme.colors.basics.grey.c100,
      borderBottomWidth: 1,
    },
    cardContentTitle: {
      textDecorationLine: 'underline',
      marginBottom: theme.metrics.spacing.xs,
    },
    cardContentLabelValue: {
      fontFamily: theme.fonts.fontFamily.regular
    },
    repartionSection: {
      flex: 1,
      flexDirection: 'row',
      paddingVertical: theme.metrics.spacing.xm,
      alignItems: 'center',
    }
  }), [theme]);

  return style;
}
