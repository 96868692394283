import {useScreenSizes, useTheme} from '@ere-uilib/styles';
import {useMemo} from 'react';
import {StyleSheet} from 'react-native';


export function useStyles() {
  const theme = useTheme();
  const { isDesktop } = useScreenSizes();

  const styles = useMemo(() => StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: theme.colors.basics.white,
    },
    subHeader: {
      marginBottom: theme.metrics.spacing.xm,
    },
    bottomActions: {
      width: '100%',
      marginTop: isDesktop ? theme.metrics.spacing.xl : undefined,
    },
    bottomActionsButtonsContainer: {
      width: '100%',
      flexDirection: 'row',
    },
    simpleButton: {
      marginLeft: theme.metrics.spacing.m,
      flex: 1,
    },
    notificationContainerStyle: { marginBottom: !isDesktop ? theme.metrics.spacing.xm : undefined },
    bankAccountHeader: {
      backgroundColor: theme.colors.basics.white,
      borderBottomColor: theme.colors.basics.grey.c200,
      borderBottomWidth: 1,
    },
    originRecapStyle: {
      marginTop: theme.metrics.spacing.l,
    },
    filetStepperContainer: {
      maxWidth: 400,
      alignSelf: 'center',
      width: '100%',
      paddingHorizontal: theme.metrics.spacing.s,
    },
    errorNotificationContainerStyle: {
      marginBottom: theme.metrics.spacing.s,
    },
    subHeaderSeilContainer: {
      paddingVertical: theme.metrics.spacing.m,
      paddingHorizontal: theme.metrics.spacing.s,
    },
    subHeaderSeilText: {
      color: theme.colors.basics.white,
      marginBottom: theme.metrics.spacing.xs,
    },
    triggerThresholdContainer: {
      paddingHorizontal: theme?.metrics.spacing.s,
      paddingBottom: theme?.metrics.spacing.xs,
    },
    filetContainer: {
      backgroundColor: theme.colors.basics.white,
      flex: 1,
      marginRight: theme.metrics.spacing.xm,
    },
    cardContainer: {
      backgroundColor: theme.colors.basics.white,
    },
    textContainer: {
      paddingHorizontal: theme.metrics.spacing.s,
      paddingBottom: theme.metrics.spacing.xm,
    },
    fromContent: {
      marginLeft: theme.metrics.spacing.xm,
      width: '100%',
      marginTop: isDesktop ? theme.metrics.spacing.m : 0,
    },
    filetText: {
      marginBottom: theme.metrics.spacing.xm,
    },
    filetSubContainer: {
      marginTop: isDesktop ?
        theme?.metrics.spacing.l :
        theme?.metrics.spacing.m
    }
  }), [theme, isDesktop]);

  return styles;
}
