import { connect } from 'react-redux';

import { AppState } from '@modules/index';
import {
  setRefundRepartitionAmountModalVisibleState,
  setRefundRepartitionAmount,
} from '@modules/refund/available-refund/actions/refundActions';
import {
  getRefundRepartitionAmountModalVisibleState,
} from '@modules/refund/available-refund/selectors';

import { RefundAmountFieldModalConnectedComponent } from './RefundAmountFieldModalConnected.component';

const mapStateToProps = (state: AppState) => {
  return {
    visible: getRefundRepartitionAmountModalVisibleState(state),
  };
};

const mapDispatchToProps = {
  onSetRefundRepartitionAmountModalVisibleState: setRefundRepartitionAmountModalVisibleState,
  onSetRefundRepartitionAmount: setRefundRepartitionAmount,
};

export const RefundAmountFieldModalConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(RefundAmountFieldModalConnectedComponent);
