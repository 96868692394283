import { useNavigation } from '@react-navigation/native';
import React, { useCallback, useRef } from 'react';
import { View } from 'react-native';

import { DashboardPageWrapperConnected, HtmlStyledRenderConnectedContainer } from '@components/ERE360Components';
import { createUseStyles, useTheme, } from '@ere-uilib/styles';
import { ColumnsContainer } from '@ere-uilib/templates';
import { useTranslation } from '@ere-uilib/translations';

import { RefundsHeader } from '../../components/RefundsHeader';
import { Text, Title } from '@ere-uilib/atoms';
import { getStyles } from './styles';

export const UnavailableRefundRC: React.FC<React.PropsWithChildren<{}>> = ({ }) => {
  const refColumnsContainer = useRef() as React.MutableRefObject<{ scrollToTop: () => void }>;
  const refDashboardContainer = useRef() as React.MutableRefObject<{ scrollToTop: () => void }>;

  const theme = useTheme();
  const { formatMessage, getMessageRaw, } = useTranslation();
  const { goBack } = useNavigation()
  const styles = useStyles({ theme }, {});

  const RenderHeader = useCallback(
    () => (
      <RefundsHeader
        displayBackButton={true}
        displayCloseButton
        helpPoint
        onGoBack={goBack}
        title={formatMessage({
          id: 'UnavailableRepaymentOustandingRC_Header',
        })}
      />
    ),
    [formatMessage, goBack]
  );

  const renderDescriptionByLabels = useCallback(() => {
    return (
      [...Array(7)].map((_item, index) => {
        const title =
          formatMessage({
            id: `UnavailableRepaymentOustandingRC_Title${index + 1
              }`,
          })
        const subtitle =
          formatMessage({
            id: `UnavailableRepaymentOustandingRC_Subtitle${index + 1}`,
          })

        const link = getMessageRaw({ id: `UnavailableRepaymentOustandingRC_Link${index + 1}` })

        return (
          <View key={index} style={styles.descriptionContainer}>
            {title.length > 1 && <Title
              variant="t6"
              weight="bold"
              style={styles.descriptionTitle}
            >
              {formatMessage({
                id: `UnavailableRepaymentOustandingRC_Title${index + 1}`,
              })}
            </Title>
            }
            {subtitle.length > 1 &&
              <Text
                variant="t2"
                weight="light"
                style={styles.descriptionSubTitle}
              >
                {formatMessage({
                  id: `UnavailableRepaymentOustandingRC_Subtitle${index + 1}`,
                })}
              </Text>
            }
            {link.length > 1 && <HtmlStyledRenderConnectedContainer
              html={link}
            />
            }
          </View>
        )
      })
    )
  }, [])



  return (
    <View>
      <DashboardPageWrapperConnected
        cardContentStyle={{ backgroundColor: theme.colors.basics.white }}
        dashboardPageWrapperRef={refDashboardContainer}
        renderStickyMobileBottom={() => undefined}
      >
        <ColumnsContainer
          columnsContainerRef={refColumnsContainer}
          hasColumnCenter={true}
          renderHeader={RenderHeader}
          renderRightDesktopColumn={undefined}
        >
          <Title
            variant="t4"
            weight="bold"
          >
            {formatMessage({
              id: 'UnavailableRepaymentOustandingRC_MainTitle',
            })}
          </Title>
          <Text
            variant="t2"
            weight="light"
          >
            {formatMessage({
              id: 'UnavailableRepaymentOustandingRC_Plan',
            })}
          </Text>
          <View>
            {renderDescriptionByLabels()}
          </View>
        </ColumnsContainer>
      </DashboardPageWrapperConnected>
    </View>
  );
};

const useStyles = createUseStyles(getStyles);
