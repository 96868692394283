import { connect } from 'react-redux';

import { logoutCallbackRequest } from '@modules/auth/actions/authActions';
import {
  EndOidcSessionCallbackComponent
} from './EndOidcSessionCallbackPage.component';

const mapDispatchToProps = {
  signoutRedirectCallback: logoutCallbackRequest,
};

export const EndOidcSessionCallbackPage = connect(
  null,
  mapDispatchToProps,
)(EndOidcSessionCallbackComponent);
