import { PlanFamillyList } from '@constants/index';
import { FileData } from "@modules/common/types";
import {
  BankDataType,
  BankDetailsErrorState,
  BankDetailsInitType,
  PopinContentTypeEnum,
  RegisterRulesType
} from '../types';
import { BankDetailsActionsType } from './actionsTypes';
import * as actions from './actionsTypes';

export function setSelectedPlanFamily(
  selectedPlanFamily: PlanFamillyList
): actions.SetSelectedPlanFamily {
  return {
    type: BankDetailsActionsType.SET_SELECTED_PLAN_FAMILY,
    selectedPlanFamily
  };
}

export function setSelectedPlanId(
  selectedPlanId: string | undefined
): actions.SetSelectedPlanId {
  return {
    type: BankDetailsActionsType.SET_SELECTED_PLAN_ID,
    selectedPlanId
  };
}

export function setUpdateFinished(status: PopinContentTypeEnum | undefined): actions.SetUpdateFinishedAction {
  return {
    type: BankDetailsActionsType.SET_UPDATE_FINISHED,
    updateFinished: status
  };
}

export function setRegisterRules(registerRules: RegisterRulesType): actions.SetRegisterRulesAction {
  return {
    type: BankDetailsActionsType.SET_REGISTER_RULES,
    registerRules
  };
}

export function setSelectedDomiciliationCountry(
  country: string | null
): actions.SetSelectedDomiciliationCountryAction {
  return {
    type: BankDetailsActionsType.SET_SELECTED_DOMICILIATION_COUNTRY,
    selectedCountry: country ? country?.slice(-2) : null
  };
}

export function setSelectedAccountType(accountType: string): actions.SetSelectedAccountTypeAction {
  return {
    type: BankDetailsActionsType.SET_SELECTED_ACCOUNT_TYPE,
    accountType: accountType
  };
}

export function getBankDataRequest(): actions.GetBankDataRequestAction {
  return {
    type: BankDetailsActionsType.GET_BANK_DATA_REQUEST
  };
}

export function getBankDataSuccess(bankData: BankDataType): actions.GetBankDataSuccessAction {
  return {
    type: BankDetailsActionsType.GET_BANK_DATA_SUCCESS,
    bankData
  };
}

export function getBankDataFailure(error: BankDetailsErrorState): actions.GetBankDataFailureAction {
  return {
    type: BankDetailsActionsType.GET_BANK_DATA_FAILURE,
    error
  };
}

export function initBankDataRequest(
  iban?: string,
  bic?: string
): actions.InitBankDataRequestAction {
  return {
    type: BankDetailsActionsType.INIT_BANK_DATA_REQUEST,
    iban,
    bic
  };
}

export function initBankDataSuccess(
  initData: BankDetailsInitType,
  isRc: boolean,
): actions.InitBankDataSuccessAction {
  return {
    type: BankDetailsActionsType.INIT_BANK_DATA_SUCCESS,
    initData,
    isRc
  };
}

export function initBankDataFailure(
  error: BankDetailsErrorState | null
): actions.InitBankDataFailureAction {
  return {
    type: BankDetailsActionsType.INIT_BANK_DATA_FAILURE,
    error
  };
}

export function uploadDocumentBankDataRequest(
  documentKey: string,
  file: FileData
): actions.UploadDocumentBankDataRequestAction {
  return {
    type: BankDetailsActionsType.UPLOAD_DOCUMENT_BANK_DATA_REQUEST,
    documentKey,
    file
  };
}

export function uploadDocumentBankDataSuccess(
  documentKey: string,
  documentId: string,
  file: FileData
): actions.UploadDocumentBankDataSuccessAction {
  return {
    type: BankDetailsActionsType.UPLOAD_DOCUMENT_BANK_DATA_SUCCESS,
    documentKey,
    documentId,
    file
  };
}

export function uploadDocumentBankDataFailure(
  documentKey: string
): actions.UploadDocumentBankDataFailureAction {
  return {
    type: BankDetailsActionsType.UPLOAD_DOCUMENT_BANK_DATA_FAILURE,
    documentKey
  };
}

export function downloadDocumentMandatRCRequest(): actions.DownloadDocumentMandatRcRequestAction {
  return {
    type: BankDetailsActionsType.DOWNLOAD_DOCUMENT_MANDAT_RC_REQUEST
  };
}

export function downloadDocumentMandatRCSuccess(
  documentBlob: string | undefined
): actions.DownloadDocumentMandatRcSuccessAction {
  return {
    type: BankDetailsActionsType.DOWNLOAD_DOCUMENT_MANDAT_RC_SUCCESS,
    documentBlob
  };
}

export function downloadDocumentMandatRCFailure(
  error: Error
): actions.DownloadDocumentMandatRcFailureAction {
  return {
    type: BankDetailsActionsType.DOWNLOAD_DOCUMENT_MANDAT_RC_FAILURE,
    error
  };
}

export function signMandatRCRequest(selectedOTPChannel: string): actions.SignMandatRcRequestAction {
  return {
    type: BankDetailsActionsType.SIGN_MANDAT_RC_REQUEST,
    selectedOTPChannel
  };
}

export function signMandatRCSuccess(): actions.SignMandatRcSuccessAction {
  return {
    type: BankDetailsActionsType.SIGN_MANDAT_RC_SUCCESS,
  }
}

export function signMandatRCFailure(
  error: BankDetailsErrorState
): actions.SignMandatRcFailureAction {
  return {
    type: BankDetailsActionsType.SIGN_MANDAT_RC_FAILURE,
    error
  };
}

export function removeDocumentBankDataRequest(
  documentKey: string
): actions.RemoveDocumentBankDataRequestAction {
  return {
    type: BankDetailsActionsType.REMOVE_DOCUMENT_BANK_DATA_REQUEST,
    documentKey
  };
}

export function removeDocumentBankDataSuccess(
  documentKey: string
): actions.RemoveDocumentBankDataSuccessAction {
  return {
    type: BankDetailsActionsType.REMOVE_DOCUMENT_BANK_DATA_SUCCESS,
    documentKey
  };
}

export function removeDocumentBankDataFailure(
  documentKey: string
): actions.RemoveDocumentBankDataFailureAction {
  return {
    type: BankDetailsActionsType.REMOVE_DOCUMENT_BANK_DATA_FAILURE,
    documentKey
  };
}

export function registerBankDataRequest(
  iban: string,
  bic: string
): actions.RegisterBankDataRequestAction {
  return {
    type: BankDetailsActionsType.REGISTER_BANK_DATA_REQUEST,
    iban,
    bic
  };
}

export function registerBankDataSuccess(
  quickSignUrl: string | null
): actions.RegisterBankDataSuccessAction {
  return {
    type: BankDetailsActionsType.REGISTER_BANK_DATA_SUCCESS,
    quickSignUrl: quickSignUrl
  };
}
export function registerBankDataFailure(
  error: BankDetailsErrorState
): actions.RegisterBankDataFailureAction {
  return {
    type: BankDetailsActionsType.REGISTER_BANK_DATA_FAILURE,
    error
  };
}

export function clearBankData(): actions.ClearBankDataAction {
  return {
    type: BankDetailsActionsType.CLEAR_BANK_DATA
  };
}
