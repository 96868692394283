import { useTheme } from "@ere-uilib/styles";
import { useMemo } from "react"
import { StyleSheet } from "react-native"

export const useStyles = (isMobileOrHasOperations: boolean) => {
  const theme = useTheme();

  const styles = useMemo(() => StyleSheet.create({
    remainingItemListStyle: {
      flexDirection: !isMobileOrHasOperations ? 'row' : undefined,
      justifyContent: !isMobileOrHasOperations ? 'space-around' : undefined,
    },
    remainingItemStyle: {
      flexDirection: isMobileOrHasOperations ? 'row' : "column",
      alignItems: 'center',
      marginBottom: isMobileOrHasOperations ? theme.metrics.spacing.xm : undefined,
      flex: !isMobileOrHasOperations ? 1 : undefined,
    },
    lastRemainingItemStyle: {
      marginBottom: isMobileOrHasOperations ? theme.metrics.spacing.none : undefined,
    },
    iconContainerStyle: {
      alignItems: 'center',
      justifyContent: !isMobileOrHasOperations ? "center" : undefined,
      flexDirection: isMobileOrHasOperations ? "row" : undefined,
    },
    iconCircleStyle: {
      backgroundColor: theme.colors.basics.grey.c100,
      height: theme.metrics.iconSizes.xl,
      width: theme.metrics.iconSizes.xl,
      borderRadius: theme.metrics.borderRadius.full,
      alignItems: 'center',
      justifyContent: 'center'
    },
    firstGroupStyle: {
      flex: isMobileOrHasOperations ? 1 : undefined,
      alignItems: isMobileOrHasOperations ? 'flex-start' : undefined,
    },
    secondGroupStyle: {
      alignItems: isMobileOrHasOperations ? 'flex-end' : undefined,
    },
    labelContainerStyle:
    {
      alignItems: isMobileOrHasOperations ? 'flex-start' : 'center',
      justifyContent: 'center',
    },
    labelStyle: {
      marginLeft: isMobileOrHasOperations ? theme.metrics.spacing.s : undefined,
      textAlign: !isMobileOrHasOperations ? 'center' : undefined,
    },
    rowStyle: {
      marginLeft: isMobileOrHasOperations ? theme.metrics.spacing.s : undefined,
      flexDirection: !isMobileOrHasOperations ? 'row' : undefined,
      paddingLeft: !isMobileOrHasOperations ? theme.metrics.spacing.l : undefined,
      paddingRight: !isMobileOrHasOperations ? theme.metrics.spacing.l : undefined,
    },
    subtitleStyle: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: !isMobileOrHasOperations ? theme.metrics.spacing.xs : undefined,
    },
    subtitleTextStyle: {
      color: theme.colors.basics.primary.c500,
      textAlign: !isMobileOrHasOperations ? 'center' : undefined,
    },
    untilStyle: {
      marginRight: !isMobileOrHasOperations ? theme.metrics.spacing.xs : undefined
    },
    amountContainerStyle: {
      flexDirection: isMobileOrHasOperations ? 'column' : 'row',
      alignItems: 'flex-end',
      justifyContent: isMobileOrHasOperations ? 'flex-end' : 'center',
      marginLeft: isMobileOrHasOperations ? theme.metrics.spacing.s : undefined,
      paddingLeft: !isMobileOrHasOperations ? theme.metrics.spacing.l : undefined,
      paddingRight: !isMobileOrHasOperations ? theme.metrics.spacing.l : undefined,
      marginTop: !isMobileOrHasOperations ? theme.metrics.spacing.s : undefined,
    }

  }), [
    theme,
    isMobileOrHasOperations
  ]);

  return styles;
}