import { SimulatorType } from "@constants/sapiendo";
import * as actions from "@modules/redirect-operation/actions/redirectOperationActionsTypes";
import { RedirectOperationActionsType } from "@modules/redirect-operation/actions/redirectOperationActionsTypes";
import { AccountsErrorState } from "@modules/dashboard/types";

import {RedirectOperationResponsePayload} from "@modules/redirect-operation/types";

export function getRedirectOperationRequest(
  operationType: string
): actions.GetOperationRedirectRequestAction {
  return {
    type: RedirectOperationActionsType.GET_OPERATION_REDIRECT_REQUEST,
    operationType,
  };
}

export function getRedirectOperationSuccess(
  operationModalList: RedirectOperationResponsePayload
): actions.GetOperationRedirectSuccessAction {
  return {
    type: RedirectOperationActionsType.GET_OPERATION_REDIRECT_SUCCESS,
    operationModalList,
  };
}

export function getRedirectOperationFailure(
  error: any
): actions.GetOperationRedirectFailureAction {
  return {
    type: RedirectOperationActionsType.GET_OPERATION_REDIRECT_FAILURE,
    error,
  };
}
export function getRedirectOperationUrlRequest(
  operationType: string,
  provider: string,
): actions.GetOperationRedirectUrlRequestAction {
  return {
    type: RedirectOperationActionsType.GET_OPERATION_REDIRECT_URL_REQUEST,
    operationType,
    provider
  };
}

export function getRedirectOperationUrlSuccess(
): actions.GetOperationRedirectUrlSuccessAction {
  return {
    type: RedirectOperationActionsType.GET_OPERATION_REDIRECT_URL_SUCCESS,
  };
}

export function getRedirectOperationUrlFailure(
  error: any
): actions.GetOperationRedirectUrlFailureAction {
  return {
    type: RedirectOperationActionsType.GET_OPERATION_REDIRECT_URL_FAILURE,
    error,
  };
}

export function operationModalInitError(): actions.OperationModalInitErrorAction {
  return {
    type: RedirectOperationActionsType.OPERATION_MODAL_INIT_ERROR,
  };
}

// SAPIENDO REDIRECT URL

export function sapiendoRedirectUrlInitError(): actions.SapiendoRedirectUrlInitErrorAction {
  return {
    type: RedirectOperationActionsType.SAPIENDO_REDIRECT_URL_INIT_ERROR,
  };
}

export function getSapiendoRedirectUrlRequest(simulatorType: SimulatorType): actions.GetSapiendoRedirectUrlRequestAction {
  return {
    type: RedirectOperationActionsType.GET_SAPIENDO_REDIRECT_URL_REQUEST,
    simulatorType
  };
}

export function getSapiendoRedirectUrlSuccess(
): actions.GetSapiendoRedirectUrlSuccessAction {
  return {
    type: RedirectOperationActionsType.GET_SAPIENDO_REDIRECT_URL_SUCCESS,
  };
}

export function getSapiendoRedirectUrlFailure(
  error: AccountsErrorState
): actions.GetSapiendoRedirectUrlFailureAction {
  return {
    type: RedirectOperationActionsType.GET_SAPIENDO_REDIRECT_URL_FAILURE,
    error,
  };
}