import { AnnuityErrorState, FetchAnnuityResultsData, FetchAnnuitySimulationData } from '../types';
import * as actions from './annuityActionsTypes';
import { AnnuityActionsType } from './annuityActionsTypes';

// Init Error
export function annuityInitError(): actions.AnnuityInitErrorAction {
  return {
    type: AnnuityActionsType.ANNUITY_INIT_ERROR
  };
}

export function setAnnuitySimulationDataRequest(
  simulationData: FetchAnnuitySimulationData
): actions.SetAnnuitySimulationDataRequestAction {
  return {
    type: AnnuityActionsType.SET_ANNUITY_SIMULATION_DATA_REQUEST,
    simulationData
  };
}

export function setAnnuitySimulationDataSuccess(
  resultsData: FetchAnnuityResultsData
): actions.SetAnnuitySimulationDataSuccessAction {
  return {
    type: AnnuityActionsType.SET_ANNUITY_SIMULATION_DATA_SUCCESS,
    resultsData
  };
}

export function setAnnuitySimulationDataFailure(
  error: AnnuityErrorState
): actions.SetAnnuitySimulationDataFailureAction {
  return {
    type: AnnuityActionsType.SET_ANNUITY_SIMULATION_DATA_FAILURE,
    error
  };
}
