
import React, { useEffect, useState } from 'react';

import { ConfirmTriggerThresholdPopin } from '@components/index';
import { useTranslation } from '@ere-uilib/translations';
import { TriggerThresholdModal } from '@pages/Common';

import { ModalData, TriggerThresholdModalConnectedProps } from './interfaces';
import { TriggerThresholdModalConnectedRefType } from './sharedInterfaces';

export const RefundTriggerThresholdModalConnectedComponent = React.forwardRef<TriggerThresholdModalConnectedRefType, TriggerThresholdModalConnectedProps>(({
  onSetModalVisibleState,
  onSetSupportTriggerThresholdAndFormatRepartition,
  visible,
}, ref) => {

  const defaultData = {
    displayData: {
      title: '',
      amount: undefined,
      valuationDate: '',
      sharesValue: 0,
      triggerPointDate: '',
      thresholdTriggerType: '',
      thresholdTriggerTitle: '',
      course: '',
    },
    compartmentId: '',
    managementId: '',
    supportIsInCode: '',
  };
  const [data, setData] = useState<ModalData>(defaultData);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  useEffect(() => {
    if (!visible) {
      setData(defaultData);
    }
  }, [visible]);
  // allow method via ref
  React.useImperativeHandle(ref, () => ({
    openWithData: receivedData => {
      onSetModalVisibleState(true);
      setData(receivedData);
    },
  }));

  const onClose = () => {
    onSetModalVisibleState(false);
  };
  const { formatMessage, getMessageRaw } = useTranslation();
  return (
    <>
      <TriggerThresholdModal
        data={data.displayData}
        onClose={onClose}
        onValid={amount => {
          setIsModalVisible(true),
            onSetSupportTriggerThresholdAndFormatRepartition({
              triggerPointData: {
                amount,
                date: data.displayData.triggerPointDate,
              },
              compartmentId: data.compartmentId,
              managementId: data.managementId,
              supportIsInCode: data.supportIsInCode,
            });
        }}
        visible={visible} />
      <ConfirmTriggerThresholdPopin
        buttonTitle={formatMessage({ id: 'Remboursement_seuilDeclench_Bouton' })}
        description={getMessageRaw({ id: 'Remboursement_seuilDeclench_content' })}
        isModalVisible={isModalVisible}
        onValidate={() => setIsModalVisible(false)}
        title={formatMessage({ id: 'Remboursement_seuilDeclench_confirmation_titre' })}

      />
    </>
  );
});
