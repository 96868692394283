import { AmountFieldModalStyles, StylesContext } from './interface';

export const getStyles = (
  context?: StylesContext,
  style?:  AmountFieldModalStyles,
):  AmountFieldModalStyles => ({
  modalFrameStyle: [
    {
      padding: 0,
    },
    style?.modalFrameStyle,
  ],
  containerStyle: [
    {
      paddingHorizontal: context?.isMobile ? context?.theme.metrics.spacing.s : context?.theme.metrics.spacing.l,
    },
    style?.containerStyle,
  ],
  titleContainerStyle: [
    {
      alignItems: 'center',
      paddingTop: context?.theme.metrics.spacing.xxm,
    },
    style?.titleContainerStyle,
  ],
  titleStyle: [
    {
      textAlign: 'center',
    },
    style?.titleStyle,
  ],
  subTitleStyle: [
    {
      textAlign: 'center',
    },
    style?.subTitleStyle,
  ],
  dashedLineStyle: [
    {
      alignSelf: 'stretch',
      marginTop: context?.theme.metrics.spacing.xm,
    },
    style?.dashedLineStyle,
  ],
  contentContainerStyle: [
    {
      paddingTop: context?.theme.metrics.spacing.xm,
    },
    style?.contentContainerStyle,
  ],
  detailsTitleStyle: [
    {
      textAlign: 'center',
      marginBottom: context?.theme.metrics.spacing.s,
    },
    style?.subTitleStyle,
  ],
  detailsParametersContainerStyle: [
    {
      marginBottom: context?.theme.metrics.spacing.s,
    },
    style?.detailsParametersContainerStyle,
  ],
  detailsParameterTextStyle: [
    {
      textAlign: 'center',
    },
    style?.detailsParameterTextStyle,
  ],
  inputContainerStyle: [
    {
      alignSelf: 'center',
      width: '100%',
      maxWidth: 275,
      marginTop: context?.theme.metrics.spacing.s,
      marginBottom: context?.theme.metrics.spacing.s,
    },
    style?.inputContainerStyle,
  ],
  underFieldSpaceStyle: [
    {
      minHeight: 100,
    },
    style?.underFieldSpaceStyle,
  ],
  contibutionContainerStyle: [
    {
      alignItems: 'center',
    },
    style?.contibutionContainerStyle,
  ],
  contibutionTextStyle: [
    {},
    style?.contibutionTextStyle,
  ],
  allAmountLinkContainerStyle: [
    {
      alignItems: 'center',
      marginBottom: context?.theme.metrics.spacing.s,
    },
    style?.allAmountLinkContainerStyle,
  ],
  actionsContainerStyle: [
    {
      flexDirection: 'row',
      paddingTop: context?.theme.metrics.spacing.xm,
      paddingBottom: context?.theme.metrics.spacing.xxm,
    },
    style?.actionsContainerStyle,
  ],
  actionsButtonStyle: [
    {
      flex: 1,
      marginRight: context?.theme.metrics.spacing.m,
    },
    style?.actionsButtonStyle,
  ],
  actionsButtonLastStyle: [
    {
      marginRight: 0,
    },
    style?.actionsButtonLastStyle,
  ],
});
