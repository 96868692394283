import { StyleSheet } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

import { EditAmountButtonStylesContext } from './interfaces'

export const useStyles = ({
  isDisabled,
  hasDarkBackground,
  isExtended,
  isFilled,
  style,
  amountStyle
}:EditAmountButtonStylesContext) => {
  const theme = useTheme();
  const styles = StyleSheet.create({
    containerStyle: {
      alignSelf: 'flex-end',
      alignItems: 'flex-end',
      borderColor: isDisabled || !isFilled ? 
        theme?.colors.basics.grey.c300
        : theme?.colors.basics.grey.c500,
      borderWidth: 1,
      borderBottomWidth: isDisabled ? 0 : 1,
      paddingHorizontal: theme?.metrics.spacing.xs,
      paddingVertical: theme?.metrics.spacing.s,
      minWidth: isExtended ? 
        theme?.metrics.spacing.xhuge 
        : theme?.metrics.spacing.xxxbig,
      marginBottom: theme?.metrics.spacing.xs,
      borderRadius: theme?.metrics.spacing.xs,
      ...(hasDarkBackground && {
        borderColor: theme?.colors.basics.white,
      }),
      ...StyleSheet.flatten(style)
    },
    amountsContainer: {
      alignSelf: 'flex-end', 
      flexDirection: 'column' 
    },
    mainAmountLine: {
      alignSelf: 'flex-end', 
      flexDirection: 'row' 
    },
    subAmountLine: {
      alignSelf: 'flex-end', 
      flexDirection: 'row',
      marginTop: theme?.metrics.spacing.xs
    },
    amountStyle: {
      alignContent: 'flex-end',
      textAlign: 'right',
      color: isFilled  ?
        !isDisabled ?
          theme?.colors.basics.black
          : theme?.colors.basics.grey.c500 
        : theme?.colors.basics.grey.c500,
      ...(hasDarkBackground && {
        color: theme?.colors.basics.white,
      }),
      ...StyleSheet.flatten(amountStyle)
    },
    positiveAmountStyle: {
      color: theme?.colors.text.link.c500,
      ...(hasDarkBackground && {
        color: theme?.colors.basics.white,
      })
    },
    subAmountStyle: {
      color: theme?.colors.basics.grey.c400,
      textAlign: 'right',
    },
    resetButtonStyle: {
      width: 20,
      height: 20,
      marginBottom: 0,
      marginLeft: 5,
    },
  });

  return styles
};
