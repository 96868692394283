import React from 'react';

import {
  DashboardAnnounce, SimpleButton, Text
} from '@components/index';
import { RouteNames } from '@constants/navigation';
import { createUseStyles, useTheme } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';

import { ArbitrationFailurePageProps } from './interface';
import { getStyles } from './styles';

export const ArbitrationFailurePageComponent: React.FC<React.PropsWithChildren<ArbitrationFailurePageProps>> = ({
  navigation,
  route,
}) => {

  const { formatMessage } = useTranslation();
  const theme = useTheme();
  const useStyles = createUseStyles(getStyles);
  const styles = useStyles(
    { theme },
    {}
  );

  const renderActions = ()=> {
    return (
      <SimpleButton
        onPress={()=> {
          navigation.navigate(RouteNames.BottomTabNavigator, {
            screen: RouteNames.Home,
          });
        }}
        title={formatMessage({ id: 'Arbitrage_PageConfirmation_BoutonRetourAccueil' })}
      />
    );
  };

  return (
    <DashboardAnnounce
      actions={renderActions}
      description={
        <>
          <Text
            variant="t3"
          >
            {formatMessage({ id: 'Arbitrage_ecranfinal_contentRejetee' })}
          </Text>
        </>
      }
      sourceName={'installmentError'}
      title={formatMessage({
        id: 'Arbitrage_ecranfinalTitre_demandeRejetee',
      })}
    />
  );
};
