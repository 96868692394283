import { StylesContext, FundSheetStyles } from './interface';

export const getStyles = (
  context?: StylesContext,
  style?: FundSheetStyles,
): FundSheetStyles => ({
  containerStyle: [
    {
      flexDirection: 'column',
      flex: 1,
      backgroundColor: context?.theme.colors.basics.white
    },
    style?.containerStyle
  ]
});
