//Reducer convertors
import { BackStringBooleanEnum } from '@constants/general';
import {
  ApplicationSettingsReceivedState,
  ApplicationSettingsState
} from '@modules/settings/types';

export const parseNumberArray = (value: string | null | undefined): number[] | undefined =>
  value?.split(',').reduce<number[]>((acc, s) => {
    const n = s.trim();
    if (n && !isNaN(+n)) return [...acc, +n];
    return acc;
  }, []);

export const parseApplicationSettings = (
  applicationSettings: ApplicationSettingsReceivedState
): ApplicationSettingsState => {
  // extract applicationSettings that need to be adapted
  const {
    EnableFeatureSapiendo,
    EnableFeatureFranceConnect,
    EnableFeatureAideDeblocage,
    redirect_uris,
    post_logout_redirect_uris,
    EnableFeatureRegroupementContrats,
    OptionEERPremiereConnexion,
    OptionEERIdentifiantCourrier,
    OptionEERIdentifiantPersoneo,
    OptionEERIdentifiantVisiogo,
    FundsDocOrder,
    RenteReversionRates,
    RenteGuaranteedAnnuities,
    ...restApplicationSettings
  } = applicationSettings;

  // define real bollean for applicationSettings based on string "True" "False"
  const newApplicationSettings: ApplicationSettingsState = {
    ...restApplicationSettings,
    EnableFeatureSapiendo: EnableFeatureSapiendo === BackStringBooleanEnum.TRUE,
    EnableFeatureFranceConnect: EnableFeatureFranceConnect === BackStringBooleanEnum.TRUE,
    EnableFeatureAideDeblocage: EnableFeatureAideDeblocage === BackStringBooleanEnum.TRUE,
    EnableFeatureRegroupementContrats:
      EnableFeatureRegroupementContrats === BackStringBooleanEnum.TRUE,
    OptionEERPremiereConnexion: OptionEERPremiereConnexion === BackStringBooleanEnum.TRUE,
    OptionEERIdentifiantCourrier: OptionEERIdentifiantCourrier === BackStringBooleanEnum.TRUE,
    OptionEERIdentifiantPersoneo: OptionEERIdentifiantPersoneo === BackStringBooleanEnum.TRUE,
    OptionEERIdentifiantVisiogo: OptionEERIdentifiantVisiogo === BackStringBooleanEnum.TRUE,
    RenteReversionRates: parseNumberArray(RenteReversionRates),
    RenteGuaranteedAnnuities: parseNumberArray(RenteGuaranteedAnnuities)
  };

  const parsedFundsDocOrder: string[] =
    typeof FundsDocOrder === 'string' ? FundsDocOrder.split(' ').join('').split(',') : [];
  newApplicationSettings.FundsDocOrder = parsedFundsDocOrder;

  // define real string array for jsonStrigified string arrays
  if (redirect_uris) {
    try {
      newApplicationSettings.redirect_uris = JSON.parse(redirect_uris);
    } catch {
      newApplicationSettings.redirect_uris = undefined;
    }
  }
  if (post_logout_redirect_uris) {
    try {
      newApplicationSettings.post_logout_redirect_uris = JSON.parse(post_logout_redirect_uris);
    } catch {
      newApplicationSettings.post_logout_redirect_uris = undefined;
    }
  }

  return newApplicationSettings;
};
