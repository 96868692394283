import { useMemo } from "react"
import { StyleSheet } from "react-native"

import { useTheme } from "@ere-uilib/styles";

export const useStyles = () => {
  const theme = useTheme();

  const tabLeftFlex = useMemo(() => {
    const flex = 3;

    return flex;
  }, [])

  const styles = useMemo(() => StyleSheet.create({
    container: {
      width: '100%',
    },
    textWhite: {
      color: theme.colors.basics.white,
    },
    textDark: {
      color: theme.colors.basics.black,
    },
    textGrey: {
      color: theme.colors.basics.grey.c500,
    },
    flex: {
      flex: 1,
    },
    headerBottomContentRow: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'flex-end'
    },
    center: { flex: 1, alignItems: 'center' },
    tabContainer: {
      display: 'flex',
      flex: 1,
      borderColor: theme.colors.basics.grey.c200,
      borderWidth: 1,
      borderRadius: theme.metrics.borderRadius.s,
      overflow: 'hidden',
    },
    tabHeader: {
      flex: 1,
      backgroundColor: theme.colors.basics.grey.c500,
      flexDirection: 'row',
    },
    tabHeaderColumnSubTitle: {
      color: theme.colors.basics.white,
      fontWeight: '700',
    },
    tabLeft: {
      borderColor: theme.colors.basics.white,
      borderRightWidth: 1,
      flex: tabLeftFlex,
      padding: theme.metrics.spacing.s,
    },
    tabHeaderRight: {
      flex: 1,
      padding: theme.metrics.spacing.s,
    },
    tabHeaderLeftTop: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: theme.metrics.spacing.xs,
    },
    tabHeaderLeftBottom: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
    },
    tabHeaderRightTop: {
      alignItems: 'center',
      marginBottom: theme.metrics.spacing.xs,
    },
    tabHeaderRightBottom: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    tabContentRow: {
      borderBottomColor: theme.colors.basics.grey.c200,
      borderBottomWidth: 1,
    },
    tabContentRowSection: {
      flexDirection: 'row',
    },
    tabContentRowLeft: {
      backgroundColor: theme.colors.basics.grey.c100,
      borderColor: theme.colors.basics.white,
      borderRightWidth: 1,
    },
    tabContentRowRight: {
      backgroundColor: theme.colors.basics.white,
      flex: 1,
      padding: theme.metrics.spacing.s,
      flexDirection: 'row',
    },
    tabContentRowItem: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  }), [theme, tabLeftFlex]);

  return styles;
}
