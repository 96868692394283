import { useRoute } from '@react-navigation/core';
import { useIsFocused } from '@react-navigation/native';
import { useNavigation } from '@react-navigation/native';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { View } from 'react-native';

import { SavingRepartitionCard } from '@components/ERE360Components/SavingRepartitionCard';
import {
  BubbleContainer,
  CardManagedFundsWrapper,
  CardWithLeftThread,
  ColumnsContainer,
  CompareFundsModal,
  DashboardPageWrapperConnected,
  FundSheetModal,
  FundSheetModalDataType,
  getPlanTypeColors,
  HtmlStyledRenderConnectedContainer,
  InstallmentAmountFieldModalConnected,
  InstallmentAmountFieldModalConnectedRefType,
  InstallmentRepartitionConsentModal,
  InstallmentsStepper,
  OperationTotalSubHeader,
  ManagementCardHeader,
  NavigationHeader,
  NotificationHard,
  SimpleButton,
  Spinner,
  Text,
  Title,
  TotalSavingHeader,
  VVAmountErrorPopin,
  NotificationSoft,
  ContributionInfoEligibleCard,
  ContributionInfoNotEligibleCard
} from '@components/index';
import {
  InstallmentDecisionVVPFrequencyEnum,
  PaymentModeList,
  PlanFamillyList
} from '@constants/index';
import { locator } from '@constants/locator';
import { RouteNames } from '@constants/navigation';
import { Link, NotificationIconTypeEnum } from '@ere-uilib/atoms';
import { IconEnum } from '@ere-uilib/enums';
import {
  DirectionEnum,
  StepperSizeEnum
} from '@ere-uilib/molecules/steppers/InstallmentsStepper/interfaces';
import { createUseStyles, useScreenSizes, useTheme } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';
import { InstallmentActionsType } from '@modules/installment/actions/installmentActionsTypes';
import {
  InstallmentRepartitionCompartmentManagementSupportState,
  InstallmentRepartitionCompartmentManagementState,
  PaymentModeState
} from '@modules/installment/types';
import { constructStepperItems, formatCustomPlanName } from '@pages/Common/utils';

import { Alert } from './components/alert';
import { InstallmentRepartitionPageProps as Props } from './interfaces';
import { getStyles } from './styles';

export const InstallmentRepartitionPage: React.FC<React.PropsWithChildren<Props>> = ({
  installmentRepartitionData,
  onGetInstallmentRepartitionDataRequest,
  onGetInstallmentPaymentModeAndBankAccountDetailRequest,
  onSetInstallmentPaymentModeChoice,
  installmentPaymentMode,
  installmentPaymentModeData,
  installmentDecisionAllResponses,
  calculatedContributionError,
  isEditMode,
  installmentDecision,
  contributionByOperations
}) => {
  const navigation = useNavigation();
  const route: any = useRoute();
  const [VVAmountErrorPopinVisible, setVVAmountErrorPopinVisible] = useState(false);
  const routePlanId = route?.params?.planid;
  const isFocused = useIsFocused();
  const amountFieldModal = useRef<InstallmentAmountFieldModalConnectedRefType>(null);
  const { isMobile, isTablet, windowHeight } = useScreenSizes();
  const theme = useTheme();
  const { formatMessage, formatCurrencyNumber, getMessageRaw } = useTranslation();

  const styles = useStyles({ theme, isMobile, isTablet, windowHeight }, {});

  const [displayFundSheetModal, setDisplayFundSheetModal] = useState(false);
  const [displayCompareFundsModal, setDisplayCompareFundsModal] = useState(false);
  const [fundSheetModalData, setFundSheetModalData] = useState<FundSheetModalDataType | null>();
  const [consentModalVisible, setConsentModalVisible] = useState(false);
  const [fundsToCompare, setFundsToCompare] = useState<
    InstallmentRepartitionCompartmentManagementSupportState[] | undefined
  >();

  const planColors = installmentRepartitionData && theme.colors.basics.primary;

  useEffect(() => {
    if (isFocused) {
      if (!installmentRepartitionData) {
        onGetInstallmentRepartitionDataRequest &&
          onGetInstallmentRepartitionDataRequest({ routePlanId });
      }
      !installmentPaymentModeData?.length &&
        onGetInstallmentPaymentModeAndBankAccountDetailRequest &&
        onGetInstallmentPaymentModeAndBankAccountDetailRequest({ routePlanId });
      if (installmentPaymentModeData.length === 1) {
        onSetInstallmentPaymentModeChoice(
          installmentPaymentModeData[0].paymentMethod as PaymentModeState
        );
      } else {
        onSetInstallmentPaymentModeChoice(null);
      }
    }
  }, [isFocused]);

  const stepperItems = constructStepperItems({
    size: isEditMode ? 2 : 4,
    direction: DirectionEnum.ROW,
    activeStep: isEditMode ? 1 : 2
  });

  // renders

  const renderAlert = useMemo(() => {
    return (
      <Alert
        installmentDecision={installmentDecision}
        installmentDecisionAllResponses={installmentDecisionAllResponses}
        installmentRepartitionData={installmentRepartitionData}
        styles={styles}
        theme={theme}
      />
    );
  }, [
    installmentDecision,
    installmentDecisionAllResponses,
    installmentRepartitionData,
    styles,
    theme
  ]);

  const renderNoDataAlert = () => {
    return (
      <NotificationHard
        containerStyle={[
          styles.notificationContainerStyle,
          { marginBottom: theme.metrics.spacing.xm }
        ]}
        text={formatMessage({ id: 'VV_Repartition_NoSupport_label' })}
        title={formatMessage({ id: 'VV_Repartition_NoSupport_title' })}
        type={NotificationIconTypeEnum.WARNING}
      />
    );
  };

  const renderSubHeader = ({ addShadow }: { addShadow: boolean }) => (
    <OperationTotalSubHeader
      addShadow={addShadow}
      contributionAmount={installmentRepartitionData?.formValues?.totalContribution}
      contributionTitle={formatMessage({
        id: 'VV_repartition_total_contribution_label'
      })}
      hasContributionError={!!calculatedContributionError?.code}
      mainAmount={installmentRepartitionData?.formValues?.totalAmount}
      mainTitle={formatMessage({
        id: 'VV_repartition_total_amount_label'
      })}
      showContribution={false}
    />
  );

  const renderHeader = () => (
    <>
      <NavigationHeader
        displayCloseButton
        helpPoint
        helpPointContentHtml={getMessageRaw({ id: 'VV_needs_info_content' })}
        helpPointModalTitle={formatMessage({ id: 'VV_repartition_info_label' })}
        onClose={() =>
          navigation.navigate(RouteNames.InstallmentStack, {
            screen: RouteNames.InstallmentModal,
            params: {
              clearAction: InstallmentActionsType.CLEAR_INSTALLMENT_DATA
            }
          })
        }
        title={formatMessage({
          id: isEditMode ? 'VV_VVP_Actuel_modification_titre' : 'VV_repartition_page_title'
        })}>
        <View style={styles.installmentsStepperContainerStyle}>
          <InstallmentsStepper
            containerStyle={styles.stepperStyle}
            stepperItems={stepperItems}
            stepperSize={isMobile ? StepperSizeEnum.SMALL : StepperSizeEnum.BIG}
          />
        </View>
      </NavigationHeader>
      {(isMobile || isTablet) && installmentRepartitionData && renderSubHeader({ addShadow: true })}
    </>
  );

  const renderContributionInfoCard = useCallback(() => {
    const planFamily = installmentRepartitionData?.planFamily;
    if (planFamily === PlanFamillyList.RC) {
      return null;
    }
    const vvContribution = contributionByOperations?.payment;
    const availableContributionAmount =
      installmentRepartitionData?.advancedProperties?.AvailableContributionAmount;
    if (!installmentRepartitionData || availableContributionAmount === null) return null;
    const estimatedContribution = installmentRepartitionData?.formValues?.totalContribution;
    const selectedPlanName = installmentRepartitionData?.planName;
    const contributionForSelectedPlan = vvContribution?.operations.find(
      operation => operation.plan === selectedPlanName
    );
    const isTargeted = installmentRepartitionData?.advancedProperties?.IsVVContributionTargetingOK;
    const suggestedContributionPaymentAmount =
      installmentRepartitionData?.advancedProperties?.SuggestedContributionPaymentAmount;

    if (availableContributionAmount)
      return (
        <View style={styles.contributionCardStyle}>
          <ContributionInfoEligibleCard
            avialableContributionAmount={availableContributionAmount}
            contributionPlan={contributionForSelectedPlan}
            descriptionText={formatMessage({
              id: 'EmployerContributionTopupVVMaxInvestTexteZone'
            })}
            estimatedContribution={estimatedContribution}
            isTargeted={isTargeted}
            leftText={formatMessage({
              id: 'EmployerContributionTopupVVTopupAvailableAmountTitle'
            })}
            linkText={formatMessage({
              id: 'EmployerContributionTopupVVTopupLinkToAbondementDetail'
            })}
            rightText={formatMessage({
              id: 'EmployerContributionTopupVVTopupEstimateAmountTitle'
            })}
            suggestedContributionPaymentAmount={suggestedContributionPaymentAmount}
          />
        </View>
      );
    return (
      <View>
        <ContributionInfoNotEligibleCard
          contributionAmount={availableContributionAmount}
          emptyContributionTitle={formatMessage({
            id: 'EmployerContributionTopupVVWarningAbondment Consumed'
          })}
          estimatedAmount={estimatedContribution}
          title={formatMessage({ id: 'EmployerContributionTopupVVTopupEstimateAmountTitle' })}
        />
      </View>
    );
  }, [contributionByOperations, installmentRepartitionData, styles]);

  const renderRightDesktopColumn = () => {
    if (installmentRepartitionData)
      return (
        <>
          <View style={styles.rightContributionContainer}>
            {renderSubHeader({ addShadow: false })}
          </View>
          {renderAlert}
        </>
      );
  };

  const handleValidateVVAmountErrorPopin = useCallback(() => {
    setVVAmountErrorPopinVisible(false);
  }, []);

  const minimumAmount = useMemo(() => {
    if (installmentRepartitionData?.planFamily !== PlanFamillyList.RC) {
      return 0;
    }

    if (installmentDecisionAllResponses?.VVL) {
      return installmentRepartitionData?.advancedProperties?.VVLLimit;
    }

    switch (installmentDecisionAllResponses?.VVP) {
      case InstallmentDecisionVVPFrequencyEnum.FREQUENCY_1M:
        return installmentRepartitionData?.advancedProperties?.VVPMonthlyLimit;
      case InstallmentDecisionVVPFrequencyEnum.FREQUENCY_3M:
        return installmentRepartitionData?.advancedProperties?.VVPQuaterlyLimit;
      case InstallmentDecisionVVPFrequencyEnum.FREQUENCY_6M:
        return installmentRepartitionData?.advancedProperties?.VVPSemiAnnualLimit;
      default:
        return installmentRepartitionData?.advancedProperties?.VVPAnnualLimit;
    }
  }, [installmentDecisionAllResponses, installmentRepartitionData]);

  const isVVAmountValid = useCallback(() => {
    if (!installmentRepartitionData?.formValues?.totalAmount) {
      return false;
    }

    return installmentRepartitionData?.formValues?.totalAmount >= minimumAmount;
  }, [minimumAmount, installmentRepartitionData?.formValues?.totalAmount]);

  const handleValidateAndContinue = useCallback(() => {
    if (!isVVAmountValid()) {
      setVVAmountErrorPopinVisible(true);

      return;
    }

    if (!installmentPaymentMode || installmentPaymentMode === PaymentModeList.debit) {
      navigation.navigate(RouteNames.InstallmentStack, {
        screen: RouteNames.InstallmentsPaymentMode
      });
    } else {
      setConsentModalVisible(true);
    }
  }, [installmentPaymentMode, isVVAmountValid, navigation]);

  const renderBottomActions = useCallback(
    () => (
      <View style={styles.bottomActionsStyle}>
        {!isEditMode && (
          <SimpleButton
            containerStyle={styles.arrowButtonStyle}
            design="outlined"
            leftIcon={IconEnum.CHEVRON_LEFT}
            onPress={() => {
              navigation.goBack();
            }}
            title={
              !isMobile && !isEditMode ? formatMessage({ id: 'VV_needs_back_button' }) : undefined
            }
          />
        )}
        <SimpleButton
          containerStyle={styles.simpleButtonStyle}
          disabled={
            !(
              installmentRepartitionData?.formValues?.totalAmount &&
              installmentRepartitionData?.formValues?.totalAmount > 0
            )
          }
          onPress={handleValidateAndContinue}
          testId={locator._payment._confirm_and_continue}
          title={formatMessage({ id: 'VV_repartition_nextstep_button' })}
        />
        {installmentPaymentMode && (
          <InstallmentRepartitionConsentModal
            modalVisible={consentModalVisible}
            onClose={() => {
              setConsentModalVisible(false);
            }}
            onValid={() => {
              setConsentModalVisible(false);
              navigation.navigate(RouteNames.InstallmentStack, {
                screen: RouteNames.InstallmentsSynthesis
              });
            }}
            paymentMethod={installmentPaymentMode}
            planFamily={installmentRepartitionData?.planFamily}
          />
        )}
      </View>
    ),
    [
      handleValidateAndContinue,
      setConsentModalVisible,
      consentModalVisible,
      formatMessage,
      styles,
      isMobile,
      navigation,
      installmentRepartitionData,
      installmentPaymentMode
    ]
  );

  const renderFunds = ({
    management,
    managementTitle
  }: {
    management?: InstallmentRepartitionCompartmentManagementState;
    managementTitle: string;
  }) => {
    if ((management?.supports?.length || 0) === 0) {
      return renderNoDataAlert();
    } else {
      return management?.supports?.map((support, i) => {
        const isFreeManagement = management.isFree;
        const isRC = installmentRepartitionData?.planFamily === PlanFamillyList.RC;
        // UC support are multiple types, so MOA gave us a negative test. if we have a support type and it's not FR or AD so it's a UC kind
        const isUC =
          typeof support.supportType === 'string' && !['FR', 'AD'].includes(support.supportType);
        const isUCMinActive = isFreeManagement && isRC && isUC;
        const isEditable = management.isFree;
        return (
          <SavingRepartitionCard
            amount={management.isFree ? support.amount.amount : undefined}
            contribution={
              support.hasContribution ? support?.formValues?.contribution || 0 : undefined
            }
            disabled={!management.isFree}
            editable={isEditable} // for case of contribution calcul error
            fieldAmount={support?.formValues?.amount || 0}
            hasNoFundSheet={support.hasNoFundSheet}
            investedAmount={isEditable ? support.amount.amount : undefined}
            isContributionDisplayed={support.hasContribution}
            isContributionError={!!calculatedContributionError?.code}
            isRiskLevelSRI={!!support.riskLevelSRI}
            key={i}
            legalStatus={support.legalStatus}
            nextValorisationDate={support?.nextNavDate || undefined}
            onCardPress={() => {
              setDisplayFundSheetModal(true);
              setFundSheetModalData({
                id: support.supportIsin,
                title: support.supportName
              });
            }}
            onEditAmountPress={() => {
              installmentRepartitionData &&
                amountFieldModal?.current?.openWithData({
                  displayData: {
                    title: managementTitle,
                    subTitle:
                      installmentRepartitionData.horizon === 'Retraite' // is project or retirement
                        ? formatMessage({
                            id: 'VV_repartition_edit_retirement_label'
                          })
                        : formatMessage({
                            id: 'VV_repartition_edit_project_label'
                          }),
                    initialAmount: support?.formValues?.amount,
                    contentTitle: support?.supportName?.toUpperCase(),
                    amountParameters: {
                      ...(isUCMinActive ? { ucMin: true } : {})
                    },
                    isContributionActive: support.hasContribution
                  },
                  managementId: management.id,
                  fundId: support.supportIsin
                });
            }}
            performanceValue={support.performanceValue}
            riskLevel={support.riskLevelSRI || support.riskLevelSRRI}
            shouldDisplayAddedValue={!isRC}
            source={support.source}
            supportName={support.supportName}
            testId={`${i}`}
          />
        );
      });
    }
  };

  const renderMainContent = () => {
    const isRetirement = installmentRepartitionData?.horizon === 'Retraite';
    const isRC = installmentRepartitionData?.planFamily === PlanFamillyList.RC;

    return (
      <View>
        <Title variant="t7">
          {isRetirement
            ? formatMessage({ id: 'VV_repartition_retirement_title' })
            : formatMessage({ id: 'VV_repartition_project_title' })}
        </Title>
        <Title
          variant="t7"
          weight="light">
          {isRetirement
            ? formatMessage({ id: 'VV_repartition_retirement_label' })
            : formatMessage({ id: 'VV_repartition_project_label' })}
        </Title>
        {installmentRepartitionData ? (
          <View style={styles.managementsContainer}>
            {(installmentRepartitionData?.compartments?.[0]?.managements?.length || 0) === 0
              ? renderNoDataAlert()
              : installmentRepartitionData?.compartments?.[0]?.managements?.map((management, i) => {
                  const { isCustomPlan, planFullName, shortLabel, planType } = installmentRepartitionData;
                  const isFreeManagement = management.isFree;
                  const isManagementDirectAmounts = isRC && !isFreeManagement;
                  const isManagementDirectContributionAmounts = isRC;
                  const filtredFunds = management?.supports;
                  const managementTitle = `${formatMessage({
                    id: 'VV_repartition_myplan_label'
                  })} ${ formatCustomPlanName(isCustomPlan ? planFullName : shortLabel, management.label, planType)}`;
                  return (
                    <View
                      key={i}
                      style={styles.managementContainer}>
                      <CardWithLeftThread
                        addShadow={true}
                        borderLeftColor={planColors?.c500}>
                        <ManagementCardHeader
                          backGroundColorCustom={planColors?.c100}
                          details={
                            management.isFree
                              ? [
                                  {
                                    label: formatMessage({
                                      id: 'VV_repartition_free_description'
                                    })
                                  }
                                ]
                              : [
                                  {
                                    label: formatMessage({
                                      id: 'VV_repartition_Pilote_description'
                                    })
                                  }
                                ]
                          }
                          opened={
                            management?.advancedProperties?.HasIncorrectDrivingData
                              ? true
                              : !!(
                                  installmentRepartitionData?.compartments?.[0]?.managements &&
                                  installmentRepartitionData?.compartments?.[0]?.managements
                                    .length === 1
                                )
                          }
                          title={managementTitle}>
                          {management.advancedProperties?.HasIncorrectDrivingData ? (
                            <>
                              <NotificationSoft
                                text={formatMessage({
                                  id: 'VV_repartition_riskProfileUndefined_label'
                                })}
                                type="info">
                                <HtmlStyledRenderConnectedContainer
                                  baseFontStyle={{ fontFamily: theme.fonts.fontFamily.regular }}
                                  html={getMessageRaw({
                                    id: 'VV_repartition_riskProfileUndefinedLien_label'
                                  })}
                                />
                              </NotificationSoft>
                            </>
                          ) : (
                            <>
                              <TotalSavingHeader
                                amount={management?.formValues?.totalAmount}
                                backgroundColor={planColors?.c500}
                                editable={!management.isFree}
                                hasContributionError={!!calculatedContributionError?.code}
                                onEditAmountPress={() => {
                                  amountFieldModal.current?.openWithData({
                                    displayData: {
                                      title: managementTitle,
                                      subTitle:
                                        installmentRepartitionData.horizon === 'Retraite' // is project or retirement
                                          ? formatMessage({
                                              id: 'VV_repartition_edit_retirement_label'
                                            })
                                          : formatMessage({
                                              id: 'VV_repartition_edit_project_label'
                                            }),
                                      initialAmount: management?.formValues?.totalAmount,
                                      isContributionActive:
                                        management?.advancedProperties?.HasContribution
                                    },
                                    managementId: management.id,
                                    isManagementDirectAmounts,
                                    isManagementDirectContributionAmounts
                                  });
                                }}
                                title={formatMessage({
                                  id: 'VV_repartition_Pilote_amount'
                                })}
                              />

                              {management.isFree ? (
                                <View>
                                  <View style={styles.repartitionFreeFilterwarningLabelContainer}>
                                    <Text
                                      variant="t3"
                                      weight="light">
                                      {formatMessage({
                                        id: 'VV_repartition_free_filterwarning_label'
                                      })}
                                    </Text>
                                    {filtredFunds && filtredFunds?.length > 1 && (
                                      <View style={styles.compareButtonContainerStyle}>
                                        <SimpleButton
                                          containerStyle={styles.compareButtonStyle}
                                          design="outlined"
                                          onPress={() => {
                                            setFundsToCompare(management.supports);
                                            setDisplayCompareFundsModal(true);
                                          }}
                                          size="small"
                                          title={formatMessage({
                                            id: 'MyFunds_Screen1Compare_Header_Title'
                                          })}
                                        />
                                      </View>
                                    )}
                                  </View>

                                  {renderFunds({
                                    management,
                                    managementTitle
                                  })}
                                </View>
                              ) : (
                                <CardManagedFundsWrapper
                                  investedAmmont={formatCurrencyNumber({
                                    value: management.totalAmount
                                  })}>
                                  {renderFunds({
                                    management,
                                    managementTitle
                                  })}
                                </CardManagedFundsWrapper>
                              )}
                            </>
                          )}
                        </ManagementCardHeader>
                      </CardWithLeftThread>
                    </View>
                  );
                })}
          </View>
        ) : (
          <Spinner color={theme.colors.basics.primary.c500} />
        )}
        {(isMobile || isTablet) && <View style={styles.alertContainerStyle}>{renderAlert}</View>}
        {!(isMobile || isTablet) && renderBottomActions()}
        {fundSheetModalData && (
          <FundSheetModal
            id={fundSheetModalData?.id}
            modalIsVisible={displayFundSheetModal}
            onCloseModal={() => {
              setDisplayFundSheetModal(false);
              setFundSheetModalData(null);
            }}
            title={fundSheetModalData?.title}
          />
        )}
        <CompareFundsModal
          fundsToCompare={fundsToCompare?.map(fund => {
            return {
              isinCode: fund.supportIsin,
              supportName: fund.supportName,
              addedValue: fund.amount.addedValue,
              hasContribution: fund.hasContribution,
              amount: fund.amount.amount,
              hasNoFundSheet: fund.hasNoFundSheet,
              source: fund.source
            };
          })}
          modalIsVisible={displayCompareFundsModal}
          onCloseModal={() => {
            setDisplayCompareFundsModal(false);
          }}
        />
        <InstallmentAmountFieldModalConnected ref={amountFieldModal} />
      </View>
    );
  };

  const handleCloseVVAmountErrorPopin = useCallback(() => {
    setVVAmountErrorPopinVisible(false);
  }, [setVVAmountErrorPopinVisible]);

  return (
    <>
      <DashboardPageWrapperConnected
        cardContentStyle={{ backgroundColor: theme.colors.basics.white }}
        renderStickyMobileBottom={() => renderBottomActions()}>
        <View style={styles.containerStyle}>
          <ColumnsContainer
            renderHeader={() => renderHeader()}
            renderRightDesktopColumn={() => renderRightDesktopColumn()}>
            {renderContributionInfoCard()}
            {renderMainContent()}
          </ColumnsContainer>
        </View>
      </DashboardPageWrapperConnected>
      <VVAmountErrorPopin
        onClose={handleCloseVVAmountErrorPopin}
        onValidate={handleValidateVVAmountErrorPopin}
        visible={VVAmountErrorPopinVisible}>
        {formatMessage({
          id: 'VV_repartition_threshold_label',
          values: { montant: formatCurrencyNumber({ value: minimumAmount }) }
        })}
      </VVAmountErrorPopin>
    </>
  );
};

const useStyles = createUseStyles(getStyles);

export default InstallmentRepartitionPage;
