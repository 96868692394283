import { connect } from 'react-redux';

import { getFundRequest } from '@modules/funds/actions/fundsActions';
import { FundsActionsType } from '@modules/funds/actions/fundsActionsTypes';
import { AppState } from '@modules/reducers';
import {
  getFundsOverview,
  getFundsPerformance,
  getFundsPerformanceSharePriceHisto,
  getFundsDocumentsList,
  getInnerNavigations,
} from '@modules/funds/selectors';

import { FundSheetModalComponent } from './FundSheetModal.component';

const mapStateToProps = (state: AppState) => {
  return {
    funds: getFundsOverview(state),
    innerNavigations: getInnerNavigations(state),
    fundsPerformance: getFundsPerformance(state),
    fundsPerformanceSharePriceHisto: getFundsPerformanceSharePriceHisto(state) || [],
    fundsDocumentsList: getFundsDocumentsList(state),
    isApiLoadingOverview:
            state.isLoading[FundsActionsType.GET_FUND_OVERVIEW_REQUEST],
    isApiLoadingDocuments:
            state.isLoading[FundsActionsType.GET_FUNDS_DOCUMENTS_LIST_REQUEST],
    isApiLoadingPerformance:
            state.isLoading[FundsActionsType.GET_FUND_PERFORMANCE_REQUEST],
  };
};

const mapDispatchToProps = {
  onGetFunds: getFundRequest,
};

export const FundSheetModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(FundSheetModalComponent);
