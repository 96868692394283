import { connect } from 'react-redux';

import { AppState } from '@modules/reducers';

import { ConnexionBiometriquePageComponent } from './ConnexionBiometriquePage.component';
import { SetBiometricLockingUserFromProfileMenu } from '@modules/profile/actions/profileActions';
import { getIsBiometricLockedFromProfileMenu } from '@modules/profile/selectors';

const mapStateToProps = (state: AppState) => {
  return {
    isBiometricLockedFromProfileMenu: getIsBiometricLockedFromProfileMenu(state)
  };
};

const mapDispatchToProps = ({
  setIsBiometricLocked: SetBiometricLockingUserFromProfileMenu
})

export const ConnexionBiometriquePage = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnexionBiometriquePageComponent);
