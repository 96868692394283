import React, { PropsWithChildren } from 'react';
import { View } from 'react-native';

import {
  useScreenSizes,
  Title,
  Paragraph,
} from '@components/index';
import { BackButton } from '@ere-uilib/molecules/buttons/BackButton';

import { Props } from './interfaces';
import { useStyles } from './useStyles';

export const EERContentWrapper: React.FC<PropsWithChildren<Props>> = ({
  children,
  containerStyle,
  description,
  backButtonTitle,
  onPressBackButton,
  title,
  titleStyle,
  testId
}) => {
  const { isDesktop } = useScreenSizes();
  const styles = useStyles();

  return (
    <View style={[containerStyle, styles.container]}>
      {isDesktop && (
        <View style={styles.topDesktopContainerStyle}>
          {(!!backButtonTitle) && (
            <BackButton
              onPress={onPressBackButton}
              style={styles.backButtonStyle}
              title={backButtonTitle}
            />
          )}
        </View>
      )}
      {(!!title || !!description) && (
        <View style={styles.titleView}>
          <Title
            style={[styles.titleStyle, titleStyle]}
            variant="t4"
            weight="bold"
            testId={testId}
          >{title}</Title>
          <Paragraph variant="t2">{description}</Paragraph>
        </View>
      )}
      {children}
    </View>
  );
};
