import { connect } from 'react-redux';

import {
  backToQuestionRequest,
  setEligibleDispositifs,
  updateInstallmentQuestionWithResponse,
  setSelectedDispositif
} from '@modules/installment/actions/installmentActions';
import { AppState } from '@modules/reducers';
import { getInstallmentDecision } from '@modules/installment/selectors';

import { RequirementDefinitonComponent } from './RequirementDefinitonPage.component';

const mapStateToProps = (state: AppState) => ({
  requirementDecisionData: getInstallmentDecision(state)
});

const mapDispatchToProps = ({
  onSelectDispositif: setSelectedDispositif,
  setEligibleDispositifs: setEligibleDispositifs,
  onUpdateQuestion: updateInstallmentQuestionWithResponse,
  onBackToQuestion: backToQuestionRequest
});

export const RequirementDefinitonPage = connect(mapStateToProps, mapDispatchToProps)(RequirementDefinitonComponent);
