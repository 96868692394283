import { decode } from 'html-entities';

export const labelsHtmlHandler = (labels: any) => {
  for (const item in labels) {
    labels[item] = labelHtmlCleaner(labels[item]);
  }
  return labels;
};

export const labelHtmlCleaner = (label: string):string => {
  return decode(label.replace(/(<([^>]+)>)/gi, ''));
};
