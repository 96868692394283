import { extractBIC, isSEPACountry, isValidIBAN } from 'ibantools';

export const validateBankDetails = (iban: string, bic: string) => {
  const IBANCountryCode = iban.match(`[a-zA-Z]{2}`)?.[0];
  const { countryCode: BICCountryCode, valid: isValidBIC } = extractBIC(bic);

  return {
    isValidIBAN: isValidIBAN(iban),
    isValidBIC: isValidBIC && (!IBANCountryCode || BICCountryCode === IBANCountryCode),
    isSEPACountry: isSEPACountry(IBANCountryCode || ''),
    IBANCountryCode
  };
};
