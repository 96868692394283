import React, { useCallback } from 'react';
import { Text, TouchableOpacity, ViewStyle, StyleProp } from 'react-native';
import { View } from 'react-native';

import { CardWithLeftThread, SVGLocalLoader, Title } from '@ere-uilib/atoms';
import { InputEditAmmont } from '@ere-uilib/molecules';
import { ManagementCardHeader } from '@ere-uilib/templates';
import { useTranslation } from '@ere-uilib/translations';
import { SupportType } from '@modules/common/types';

import { useStyles } from './useStyles';

export interface ExpandableCardContentData {
  uuid: string;
  title: string;
  riskLevel: string;
  actualRepartition: number;
  newRepartition?: number;
  support: SupportType
};

interface ActiveClassSupport {
  [uuid: string]: ExpandableCardContentData;
};

interface Props {
  display: boolean;
  contentData: ActiveClassSupport;
  title: string;
  containerStyle?: StyleProp<ViewStyle>;
  backgroundColor: string;
  borderLeftColor: string;
  onCardTitlePress?: (support: SupportType) => void;
  onInputPress?: (supportUuid: string) => void;
  onResetInput?: (supportUuid: string) => void;
  inputValues?: {
    [supportUuid: string]: number | undefined;
  };
  testId?: string
};

export const ExpandableCard = ({
  display,
  onCardTitlePress,
  contentData,
  title,
  containerStyle,
  backgroundColor,
  borderLeftColor,
  inputValues,
  onInputPress,
  onResetInput,
  testId
}: Props) => {
  const styles = useStyles();
  const { formatMessage } = useTranslation();

  const renderContentItem = useCallback((item: ExpandableCardContentData, index: number) => {
    const handlePressInputItem = () => {
      onInputPress?.(item.uuid);
    };

    const handleResetItemPress = () => onResetInput?.(item.uuid);

    return (
      <View
        key={index}
        style={styles.cardContent}>
        <View>
          <View>
            <TouchableOpacity onPress={() => onCardTitlePress?.(item.support)}>
              <Title
                style={styles.cardContentTitle}
                variant="t7"
                weight="bold"
              >
                {item.title}
              </Title>
            </TouchableOpacity>
          </View>
          <View>
            <Title
              variant="t7"
              weight="light"
            >
              {formatMessage({ id: 'AbritrageOADPageSyntheseRegroupementNiveauRisque' })} :
              &nbsp;<Text style={styles.cardContentLabelValue}>
                {item.riskLevel}
              </Text>
            </Title>
          </View>
        </View>
        <View style={styles.repartionSection}>
          <View style={{ flex: 1 }}>
            <Title
              variant="t7"
              weight="light"
            >
              {formatMessage({ id: 'AbritrageOADPageSyntheseRegroupementRepartitionActuelle' })}
            </Title>
            <Title
              variant='t7'
              weight='bold'>{Math.round(item.actualRepartition * 100)}%</Title>
          </View>
          <View style={{ height: 20, width: 40 }}>
            <SVGLocalLoader name="longArrow" />
          </View>
          <View style={{ flex: 1 }}>
            <InputEditAmmont
              isExtended
              isPercentage
              label={formatMessage({ id: 'AbritrageOADPageSyntheseRegroupementRepartitionActuelle' })}
              onPress={handlePressInputItem}
              onResetPress={handleResetItemPress}
              options={{ minimumFractionDigits: 0 }}
              percent={inputValues?.[item.uuid]}
              testId={`edit_allocation_${index}_${testId}`}
            />
          </View>
        </View>
      </View>
    )
  }, [styles, inputValues, onCardTitlePress, formatMessage, onInputPress, onResetInput]);

  return (
    <CardWithLeftThread
      addShadow={true}
      borderLeftColor={borderLeftColor}
      containerStyle={containerStyle}
    >
      <ManagementCardHeader
        backGroundColorCustom={backgroundColor}
        opened={display}
        title={title}
      >
        {Object.keys(contentData).map((uuid, index) => renderContentItem(contentData[uuid], index))}
      </ManagementCardHeader>
    </CardWithLeftThread>
  );
}
