import { RefundPageContext, RefundPageStyle } from './interfaces';

export const getStyles = (
  context?: RefundPageContext,
  style?: RefundPageStyle
): RefundPageStyle => ({
  containerStyle: [{}, style?.containerStyle],
  bodyContainer: [
    !context?.isMobile && {
      paddingLeft: context?.theme?.metrics.spacing.xl,
    },
    style?.bodyContainer,
  ],
  title: [
    {
      marginVertical: context?.theme?.metrics.spacing.xm,
    },
    style?.title,
  ],
  stepperContainer: [
    {
      marginVertical: context?.theme?.metrics.spacing.xm,
    },
    style?.stepperContainer,
  ],
  buttonContainer: [
    context?.isMobile
      ? {
        paddingHorizontal: context?.theme?.metrics.spacing.s,
      }
      : {
        flexDirection: 'row',
        marginTop: context?.theme?.metrics.spacing.xxl,
      },
    style?.buttonContainer,
  ],
  dialOnceHelpButton: [
    {
      marginTop: context?.theme?.metrics.spacing.s,
      width: context?.theme?.metrics.contentSizes.dialOnceButton,
    },
    context?.isMobile && {
      alignSelf: 'center',
    },
    style?.dialOnceHelpButton,
  ],
  loader: [
    { flex: 1, marginLeft: context?.theme?.metrics.spacing.xm },
    style?.loader,
  ],
  blockedPageContainer: [
    {
      width: '100%',
      maxWidth: context?.theme?.metrics.contentSizes.centeredContentMaxWidth,
      alignSelf: 'center',
    },
    style?.blockedPageContainer,
  ],
  errorContainer: [
    {
      marginVertical: context?.theme?.metrics.spacing.xm,
    },
    style?.errorContainer,
  ],
  loaderContainer: [
    {
      flexDirection: 'row',
      alignItems: 'center',
      paddingVertical: context?.theme?.metrics.spacing.xs,
    },
    style?.loaderContainer,
  ],
  dashboardWrapper: [
    { backgroundColor: context?.theme?.colors.basics.white },
    style?.dashboardWrapper,
  ],
  notification: [
    {
      alignSelf: 'flex-start',
    },
    context?.isMobile && {
      flex: 1,
    },
    style?.notification,
  ],
  notificationLabel: [
    {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    style?.notificationLabel,
  ],

});
