import { Slider as ModuleSlider } from '@miblanchard/react-native-slider';
import React, { useCallback, useRef, useState, useMemo } from 'react';
import { View, StyleProp, ViewStyle, LayoutChangeEvent } from 'react-native';

import { FontIcon, HatchedBackgroundView } from '@ere-uilib/atoms'
import { IconEnum } from '@ere-uilib/enums';
import { useTheme } from '@ere-uilib/styles'

import { useStyles } from './useStyles'

interface SliderProps {
  value: number,
  minValue:number,
  maxValue:number,
  minAcceptedValue?:number,
  maxAcceptedValue?:number,
  onChange(value:number):void
  style?: StyleProp<ViewStyle>;
  step?:number
}

export const Slider: React.FC<React.PropsWithChildren<SliderProps>> = ({
  style,
  minValue,
  maxValue,
  minAcceptedValue,
  maxAcceptedValue,
  onChange,
  step,
  value
}) => {

  const TRACK_HEIGHT = 15
  const THUMB_SIZE = 30

  const theme = useTheme()
  const styles = useStyles({
    trackHeight: TRACK_HEIGHT,
    thumbSize: THUMB_SIZE
  });
  const ref = useRef<ModuleSlider>(null)
  const [containerWidth, setContainerWidth] = useState(0)

  const initialAcceptedValue = useMemo(()=>{
    let testedValue = value

    if(typeof minAcceptedValue === 'number' && value < minAcceptedValue) {
      testedValue = minAcceptedValue
    }

    if(typeof maxAcceptedValue === 'number' && value > maxAcceptedValue) {
      testedValue = maxAcceptedValue
    }

    return testedValue
  }, [
    maxAcceptedValue,
    minAcceptedValue,
    value
  ])

  const minMaxMarks = useMemo(()=>{
    const marks:number[] = []
    if(typeof minAcceptedValue === 'number' && minAcceptedValue !== minValue) {
      marks.push(minValue)
    }
    if(typeof maxAcceptedValue === 'number' && maxAcceptedValue !== maxValue) {
      marks.push(maxValue)
    }
    const isMarks = marks.length > 0
    return isMarks ? marks : undefined
  }, [
    maxAcceptedValue,
    minAcceptedValue,
    minValue,
    maxValue
  ])

  const trackMarks = useMemo(()=>{
    let marks:number[] = []
    if(minMaxMarks && minMaxMarks.length > 0) {
      marks = [
        ...marks,
        ...minMaxMarks
      ]
    }
    const isMarks = marks.length > 0
    return isMarks ? marks : undefined
  }, [
    minMaxMarks
  ])

  const handleOnChange = useCallback((newValue:number|number[])=>{

    let monoValue = !Array.isArray(newValue) ? newValue : newValue[0]

    if(typeof minAcceptedValue === 'number' && monoValue < minAcceptedValue) {
      monoValue = minAcceptedValue
      ref.current?._setCurrentValue(monoValue, undefined)
    }

    if(typeof maxAcceptedValue === 'number' && monoValue > maxAcceptedValue) {
      monoValue = maxAcceptedValue
      ref.current?._setCurrentValue(monoValue, undefined)
    }

    if(monoValue !== value) {
      onChange?.(monoValue)
    }
  }, [
    maxAcceptedValue,
    minAcceptedValue,
    value,
    onChange
  ])

  const handleOnLayout = useCallback((e:LayoutChangeEvent)=>{
    const { width } = e.nativeEvent.layout
    if(width !== containerWidth) {
      setContainerWidth(width)
    }
  }, [containerWidth])

  const renderThumbComponent = useCallback(() => {
    return (
      <View style={styles.thumb}>
        <FontIcon
          name={IconEnum.CHEVRON_LEFT}
          selectable={false}
          style={[styles.arrows, styles.firstArrows]} />
        <FontIcon
          name={IconEnum.CHEVRON_RIGHT}
          selectable={false}
          style={styles.arrows} />
      </View>
    )
  }, [styles])

  const renderTrackMarkComponent = useCallback((markIndex:number) => {
    if(containerWidth === 0) { return }

    const markValue = trackMarks?.[markIndex]
    if(typeof markValue !== 'number') { return }

    let markStyle: StyleProp<ViewStyle>[] = []
    const positiveMinValue = Math.abs(minValue)
    const valueRange = maxValue - minValue
    const rangeValuePercentageUnit = valueRange / 100
    const ajustedContentWidth = containerWidth - THUMB_SIZE
    const widthPercentageUnit = ajustedContentWidth / 100

    if( typeof minAcceptedValue === 'number' && markValue === minValue) {

      let markerRangeSize = minAcceptedValue
      if(minValue < 0) {
        if(minAcceptedValue < 0) {
          markerRangeSize = positiveMinValue - Math.abs(minAcceptedValue)
        }else{
          markerRangeSize = minAcceptedValue + positiveMinValue
        }
      }
      const markWidth = widthPercentageUnit * (markerRangeSize / rangeValuePercentageUnit) + THUMB_SIZE / 2

      markStyle = [
        styles.mark,
        styles.minMark,
        {
          width: markWidth,
        }
      ]
    }

    if(typeof maxAcceptedValue === 'number' && markValue === maxValue) {

      let markerRangeSize = valueRange - maxAcceptedValue
      if(minValue < 0) {
        if(maxAcceptedValue < 0) {
          markerRangeSize = Math.abs(maxAcceptedValue) + maxValue
        }else{
          markerRangeSize = maxValue - maxAcceptedValue
        }
      }

      const markWidth = widthPercentageUnit * (markerRangeSize / rangeValuePercentageUnit) + THUMB_SIZE / 2

      markStyle = [
        styles.mark,
        styles.maxMark,
        {
          width: markWidth,
        }
      ]
    }
    return (
      <HatchedBackgroundView style={markStyle}/>
    )

  }, [
    maxAcceptedValue,
    minAcceptedValue,
    minValue,
    maxValue,
    trackMarks,
    containerWidth,
    styles
  ])

  return (
    <View
      onLayout={handleOnLayout}
      style={[styles.container, style]}
    >
      <ModuleSlider
        maximumTrackTintColor={theme.colors.basics.grey.c200}
        maximumValue={maxValue}
        minimumTrackTintColor={theme.colors.basics.primary.c500}
        minimumValue={minValue}
        onValueChange={handleOnChange}
        ref={ref}
        renderThumbComponent={renderThumbComponent}
        renderTrackMarkComponent={renderTrackMarkComponent}
        step={step}
        thumbTintColor={theme.colors.basics.primary.c700}
        trackMarks={trackMarks}
        trackStyle={styles.track}
        value={initialAcceptedValue}
      />
    </View>
  );
};

