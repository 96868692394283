import { connect } from 'react-redux';

import { AppState } from '@modules/reducers';
import { setRefundsSelectedDispositif } from '@modules/refund/actions/refundActions';
import { getRefundsElligibleDispositif } from '@modules/refund/selectors';

import { RefundsPlanChoicePageComponent } from './RefundsPlanChoicePage.component';

const mapStateToProps = (state: AppState) => ({
  dispositifs: getRefundsElligibleDispositif(state),
});

const mapDispatchToProps = {
  onSelectDispositif: setRefundsSelectedDispositif,
};

export const RefundsPlanChoicePage = connect(mapStateToProps, mapDispatchToProps)(RefundsPlanChoicePageComponent);
