import * as actions from './pendingTasksActionsTypes';
import { PendingTasksActionsType } from './pendingTasksActionsTypes';
import { PendingTask, PendingTasksError } from './pendingTasksActionsTypes';

export function getPendingTasksRequest(): actions.GetPendingTasksRequest {
  return {
    type: PendingTasksActionsType.GET_PENDING_TASKS_REQUEST
  };
}

export function getPendingTasksSuccess(
  pendingTasks: PendingTask[],
): actions.GetPendingTasksSuccess {
  return {
    type: PendingTasksActionsType.GET_PENDING_TASKS_SUCCESS,
    pendingTasks
  };
}

export function getPendingTasksFailure(
  error: PendingTasksError,
): actions.GetPendingTasksFailure {
  return {
    type: PendingTasksActionsType.GET_PENDING_TASKS_FAILURE,
    error
  };
}
