import { useNavigation } from '@react-navigation/native';
import React, { useCallback } from 'react';
import { View } from 'react-native';

import {
  ChoiceButton,
  ColumnsContainer,
  DashboardPageWrapperConnected,
  NavigationHeader,
  SVGLocalLoader,
  Title,
} from '@components/index';
import { RouteNames } from '@constants/navigation';
import { RetirementActionsType } from '@modules/retirement/actions/retirementActionsTypes';

import { useController } from '../components';
import { useStyles } from './styles';
import { useDispatch } from 'react-redux';
import { getRetirementDecisionRequest } from '@modules/retirement/actions/retirementActions';

export const RetirementOnboardingPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const styles = useStyles();
  const navigation = useNavigation()
  const { retirementOnboardingPageDictionnary } = useController();
  const dispatch = useDispatch();

  const handleGoBackPress = useCallback(() => {
    return navigation.navigate(RouteNames.RetirementStack, {
      screen: RouteNames.RetirementModal,
      params: {
        clearAction: RetirementActionsType.CLEAR_RETIREMENT_DATA,
      },
    });
  }, [navigation]);

  const renderHeader = useCallback(
    () => (
      <NavigationHeader
        displayCloseButton
        helpPoint
        helpPointContentHtml={retirementOnboardingPageDictionnary.RetirementJourneyMyRetirementInfoContent}
        helpPointModalTitle={retirementOnboardingPageDictionnary.RetirementJourneyMyRetirementInfoTitle}
        onClose={handleGoBackPress}
        title={retirementOnboardingPageDictionnary.retirementPageTitle}
      />
    ),
    [handleGoBackPress, retirementOnboardingPageDictionnary]
  );

  interface RenderButtonOptionProps {
    title: string;
    text: string;
    onCardPress?: () => void;
    disabled?: boolean
  };

  const renderButtonOption = useCallback(
    ({
      title,
      text,
      onCardPress,
      disabled = false
    }: RenderButtonOptionProps) => (
      <View style={styles.choiceButtonContainer}>
        <ChoiceButton
          description={text}
          disabled={disabled}
          onSelect={onCardPress}
          title={title}
        />
      </View>
    ),
    [styles]
  );

  const handleGoToDetailsPage = useCallback(() => {
    navigation.navigate(RouteNames.RetirementStack, {
      screen: RouteNames.DetailsPage,
    });
  }, [navigation]);

  const handleGoToRetirementPage = useCallback(() => {
    dispatch(getRetirementDecisionRequest());

    navigation.navigate(RouteNames.RetirementStack, {
      screen: RouteNames.RetirementPage,
    });
  }, [navigation, getRetirementDecisionRequest]);

  return (
    <DashboardPageWrapperConnected cardContentStyle={styles.container}>
      <ColumnsContainer
        hasRenderRightDesktopColumn={false}
        renderHeader={renderHeader}>
        <View style={styles.contentContainer}>
          <View style={styles.svgContainer}>
            <SVGLocalLoader name="retirement" />
          </View>
          <Title
            style={styles.contentTitle}
            variant={'t7'}>
            {retirementOnboardingPageDictionnary.retirementPageDescription}
          </Title>

          {renderButtonOption({
            title: retirementOnboardingPageDictionnary.retirementCard1Title,
            text: retirementOnboardingPageDictionnary.retirementCard1Text,
            onCardPress: handleGoToDetailsPage
          })}
          {renderButtonOption({
            title: retirementOnboardingPageDictionnary.retirementCard2Title,
            text: retirementOnboardingPageDictionnary.retirementCard2Text,
            onCardPress: handleGoToRetirementPage,
          })}
        </View>
      </ColumnsContainer>
    </DashboardPageWrapperConnected>
  );
};
