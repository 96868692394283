import React, { useEffect, useMemo, useState } from 'react';
import { BulletList } from 'react-content-loader/native';
import { useWindowDimensions, View } from 'react-native';

import { InnerPageContainer, ExpendableLineSet } from '@ere-uilib/molecules';
import { SavingsPlaceholder, HelpPoint, FilterDateSelector } from '@ere-uilib/organisms';
import { createUseStyles, useScreenSizes, useTheme } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';
import { Installments } from '@ere-uilib/types/index';

import InstallmentListItem from './components/InstallmentListItem';
import { getStyles } from './styles';

const currentYear = new Date().getFullYear();
const years = [
  currentYear,
  currentYear - 1,
  currentYear - 2,
  currentYear - 3,
  currentYear - 4,
];

interface InstallmentsTabProps {
  installments: Installments;
  getInstallments: (year: string, planId?: string) => void;
  planId?: string;
  planName: string;
  amount?: number
  selectedPlan: number
  isApiLoading: boolean;
  testId?: string
}
export const InstallmentsTab = ({
  installments,
  getInstallments,
  planId,
  amount,
  selectedPlan,
  planName,
  isApiLoading,
  testId
}: InstallmentsTabProps) => {
  const [year, setYear] = useState(currentYear.toString());
  const isMobile = useScreenSizes().isMobile;
  const theme = useTheme();
  const { getMessageRaw, formatMessage } = useTranslation();

  const styles = useStyles(
    {
      theme,
      isMobile,
    },
    {}
  );
  useEffect(() => {
    getInstallments(year, planId);
  }, [year, planId]);

  const { width, height } = useWindowDimensions()
  const bulletListHeight = useMemo(() => {
    return height / 4;
  }, [])

  const renderList = () => {
    return (
      <>
        {installments.length > 0 ? (
          <ExpendableLineSet
            autoOpenFirstItem
            list={installments.map(installment => ({
              title: installment.label,
              amount: installment.totalAmount,
              testId: installment.code,
              children: installment.items.map(item => (
                <InstallmentListItem
                  amount={item.totalAmount}
                  key={installment.code + '-' + item.code}
                  title={item.label}
                  testId={item.code}
                />
              )),
              design: 'grey',
              size: 'small',
              isHeaderDoubleBorder: true,
            }))}
          />
        )
          : <SavingsPlaceholder titleLabelId="Saving_Tabs_noDataFiltered_label" />}
      </>
    );
  };

  return (
    <InnerPageContainer>
      <View style={styles.buttonContainer}>
        <FilterDateSelector
          items={years}
          onSetSelectedItem={(item: any) => setYear(item.toString())}
          selectedItem={year}
          testId={testId}
        />
        <HelpPoint
          contentHtml={getMessageRaw({
            id: `saving_Tab3_info_content_${planName}`,
            defaultMessage: ' ',
          })}
          iconSize={theme.metrics.iconSizes.l}
          modalTitle={formatMessage({ id: 'fund-card_help-info_menu' })}
          style={styles.helpPointStyle}
        />
      </View>
      {isApiLoading
        ?
        <View>
          <BulletList
            style={{ flex: 1 }}
            width={'100%'}
            height={bulletListHeight}
          />
        </View>
        : amount === 0
          ? <SavingsPlaceholder
            titleLabelId={
              selectedPlan === 0
                ? 'Saving_Tab1_noFundsAll_label'
                : 'Saving_Tab1_noFunds_label'
            } />
          : renderList()
      }
    </InnerPageContainer>
  );
};
const useStyles = createUseStyles(getStyles);
