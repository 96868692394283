import { Notification } from '@ere-uilib/types/notificationSystemTypes';

import {
  NotificationSystemAction,
  NotificationSystemActionsType
} from './actions/notificationSystemActionsTypes';

export interface NotificationSystemState {
  notifications: Notification[]
}

export const initialState: NotificationSystemState = {
  notifications: [],
};

export function removeNotificationCase(state: NotificationSystemState, uid: string)
: NotificationSystemState {
  const newNotifications = state.notifications.filter(notification => notification.uid !== uid);

  return {
    ...state,
    notifications: newNotifications,
  };
}

export function addNotificationCase(state: NotificationSystemState, notification: Notification)
: NotificationSystemState {
  const newNotifications = [...state.notifications];
  newNotifications.unshift(notification);

  return {
    ...state,
    notifications: newNotifications,
  };
}

export default function NotificationSystemReducer(
  state: NotificationSystemState = initialState,
  action: NotificationSystemAction,
): NotificationSystemState {
  switch (action?.type) {
  case NotificationSystemActionsType.ADD_NOTIFICATION:
    return addNotificationCase(state, action.notification);
  case NotificationSystemActionsType.REMOVE_NOTIFICATION:
    return removeNotificationCase(state, action.uid);
  default:
    return state;
  }
}
