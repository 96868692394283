import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

export const useStyles = () => {
  const theme = useTheme();

  const styles = useMemo(() => {

    return StyleSheet.create({
      containerStyle: {
        overflow: 'hidden',
        borderWidth: 0,
        borderColor: theme.colors.basics.grey.c200,
        borderRadius: theme.metrics.borderRadius.xs,
        backgroundColor: theme.colors.background.greenBackground.c100,
        width: '100%',
        padding: theme.metrics.spacing.s,
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: theme.metrics.spacing.m
      },
      textStyle: {
        color: theme.colors.basics.primary.c500,
      },
      rightContainerStyle: {
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
      contributionIcon: {
        color: theme.colors.basics.primary.c500,
        marginRight: theme.metrics.spacing.xs
      },
      containerCenterStyle: {
        textAlign: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginHorizontal: 'auto'
      }
    });
  }, [theme]);

  return styles;
}