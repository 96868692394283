import React, { useMemo, useState } from 'react';
import { View } from 'react-native';

import { Text } from '@ere-uilib/atoms';
import { TextField, useTranslation } from '@ere-uilib/index';

import { addYearToDate, getAge } from '../../utils';
import { AnnuityBottomActionsWrapper } from '../AnnuityBottomActionsWrapper';
import { useStyles } from './useStyles';

interface Props {
  departureAge: string | undefined;
  birthDate: Date | undefined;
  onSubmit: (departureAge: string | undefined) => void;
  validator: (departureAge: string | undefined) => boolean;
}

export const AnnuityDepartureAge: React.FC<React.PropsWithChildren<Props>> = ({
  departureAge,
  birthDate,
  onSubmit,
  validator
}) => {
  const styles = useStyles();
  const [value, setValue] = useState(departureAge);
  const [hasRegexError, setHasRegexError] = useState<boolean>(false);
  const { formatMessage, formatDate } = useTranslation();

  const isValid = useMemo(() => validator(value), [validator, value]);

  const { departureDate, remainingYears } = useMemo(() => {
    if (!value || isNaN(+value) || !birthDate) return {};
    const age = getAge(birthDate);
    const departureAge = +value;
    const remainingYears = departureAge - age;
    const departureDate = addYearToDate(birthDate, departureAge);
    return { departureDate, remainingYears };
  }, [birthDate, value]);

  return (
    (<AnnuityBottomActionsWrapper
      confirmButtonDisabled={hasRegexError || !isValid}
      confirmButtonLabel={formatMessage({
        id: 'PensionSimulatorStartSimulationContinueButton'
      })}
      onValidate={() => onSubmit(value)}>
      <View style={styles.inputContainer}>
        <TextField
          errored={hasRegexError || (!!value && !isValid)}
          inputProps={{
            value,
            placeholder: formatMessage({
              id: 'PensionSimulatorRentalDepartureYearNumber'
            }),
            onChangeText: setValue,
            editable: true,
            keyboardType: 'numeric'
          }}
          inputStyle={styles.textFieldInputStyle}
          labelOnTop
          regexError={setHasRegexError}
          regexPattern={/^[0-9]{2,3}$/}
          style={styles.textFieldStyle}
        />
        {!hasRegexError && isValid && (
          <Text
            style={styles.caption}
            variant="t3">
            {formatMessage({
              id: 'PensionSimulatorEstimatedDateStartPension',
              values: {
                variabledate: formatDate({ value: departureDate }),
                variableyear: remainingYears
              }
            })}
          </Text>
        )}
      </View>
    </AnnuityBottomActionsWrapper>)
  );
};
