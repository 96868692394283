import React from 'react'
import { View } from 'react-native'

import { LoaderPlaceholderView } from '@ere-uilib/atoms'

import { useStyles } from './useStyles'

export const DocumentSkeleton = () => {
  const styles = useStyles()

  return (
    <View style={styles.container}>
      <View style={styles.left}>
        <LoaderPlaceholderView style={styles.line1} />
        <LoaderPlaceholderView style={styles.line2} />
      </View>
      <LoaderPlaceholderView style={styles.right} />
    </View>
  )
}
