import {
  FundPerformanceChartStyles,
  StylesContext,
} from './interfaces';

export const getStyles = (
  context?: StylesContext,
  style?: FundPerformanceChartStyles,
): FundPerformanceChartStyles => ({
  containerStyle: [
    {
      display: 'flex',
      position: 'relative',
      alignSelf: 'flex-start',
      width: '100%',
    },
    (context?.isMobile || context?.isTablet) && {
      marginHorizontal: -context?.theme.metrics.spacing.s,
    },
    style?.containerStyle,
  ],
  tooltipDetailStyle: [
    {
      marginTop: context?.theme.metrics.spacing.xs,
    },
    style?.tooltipDetailStyle,
  ],
});
