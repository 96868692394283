import { Linking, Platform } from 'react-native'
import { all, call, take, fork, put, select, takeLatest } from 'redux-saga/effects'

import { mockContributionByOperations } from '@__mocks__/mockContributionByCompany'
import { API_ERROR_CODE } from '@constants/apiErrorCode'
import { RouteNames } from '@constants/navigation'
import { getLoginOpenIdConfigForClientId, signinRedirectUsingToken } from '@modules/auth/services';
import * as actionAccounts from '@modules/dashboard/actions/dashboardActions'
import {
  BackOfficeImplicitConnectionAction,
  DashboardActionsType,
  GetAllocationsRequestAction,
  GetAvailabilitiesRequestAction,
  GetCibledContributionPageContentRequestAction,
  GetRecentOperationsRequestActions,
  GetToolRequestAction,
  PutSelectedCompanyRequestAction
} from '@modules/dashboard/actions/dashboardActionsTypes'
import { getSelectedCompany, getUsedCompanyId } from '@modules/dashboard/selectors'
import { runManager } from '@modules/moduleManager'
import { fetchEditorialContent } from '@modules/profile/service';
import * as savingActions from '@modules/savings/actions/savingsActions'

import {
  fetchAccounts,
  fetchAllocations,
  fetchAvailabilities,
  fetchBudgetInsightManagerUrl,
  fetchBudgetInsightUrl,
  fetchContributionByCompany,
  fetchContributionByOperation,
  fetchGetBudgetInsightAccounts,
  fetchOCD,
  fetchRecentOperations,
  fetchTool,
  getSSORedirectionUrlApi
} from './services';
import * as actionSettings from '@modules/settings/actions/settingsActions';
import { getAuthConfig } from '@modules/settings/selectors';
import { AuthConfigType } from '@modules/settings/types';
import { RootNavigation } from '@navigation/RootNavigation';

import { authenticatedCall, catchApiExceptions } from '../apiAuthorization';
import { ToolsFlowTypeEnum } from './types';
import { getActiveCompanies } from './utils';

function* onLoadAccounts(): any {
  yield put(actionAccounts.dashboardInitError())
  const response = yield authenticatedCall(fetchAccounts)
  if (response?.data?.companies?.length > 0) {
    yield put(actionAccounts.getAccountsSuccess(response.data))

    const activeCompanies = getActiveCompanies(response.data.companies)

    if (activeCompanies.length > 1) {
      yield RootNavigation.replace(RouteNames.CompanyChoicePage, {})
    } else {
      yield put(actionAccounts.putSelectedCompanyRequest(activeCompanies[0]))
      yield RootNavigation.replace(RouteNames.BottomTabNavigator, {
        screen: RouteNames.Home
      })
    }
  } else {
    const error = {
      code: API_ERROR_CODE.INF_NO_COMPANIES,
      correlationId: '',
      message: 'Error: no company',
      innerMessage: 'Error: no company'
    }
    yield catchApiExceptions(DashboardActionsType.GET_ACCOUNTS_FAILURE, error)
  }
}

function* loadDashboardData(): any {
  yield put(actionAccounts.dashboardInitError())

  const usedCompanyId = yield select(getUsedCompanyId)
  if (usedCompanyId === null || usedCompanyId === undefined)
    yield put(actionAccounts.getAccountsRequest()) // GET /companies

  yield put(actionAccounts.getOCDRequest())
  yield put(actionAccounts.getToolRequest(ToolsFlowTypeEnum.DASHBOARD))
  yield put(actionAccounts.getBudgetInsightAccountsRequest())

  yield put(actionAccounts.getAccountDataRequest())

  // TODO : disable contribution call since the cacluclated contribution is wrong
  yield take([DashboardActionsType.GET_ALLOCATIONS_SUCCESS, DashboardActionsType.GET_ALLOCATIONS_FAILURE])
  yield put(actionAccounts.getContributionRequest())

  yield put(actionAccounts.getDashboardDataSuccess())
}

function* onLoadAccountData(): any {
  const selectedCompanyId = yield select(getUsedCompanyId)
  if (selectedCompanyId !== null && selectedCompanyId !== undefined) {
    yield put(actionSettings.getMobileMenuListRequest(selectedCompanyId));

    yield take([DashboardActionsType.GET_OCD_SUCCESS, DashboardActionsType.GET_OCD_FAILURE]);
    yield put(actionAccounts.getAvailabilitiesRequest(selectedCompanyId));

    yield take([
      DashboardActionsType.GET_AVAILABILITIES_SUCCESS,
      DashboardActionsType.GET_AVAILABILITIES_FAILURE
    ]);
    yield put(actionAccounts.getRecentOperationsRequest(selectedCompanyId));

    yield take([
      DashboardActionsType.GET_RECENT_OPERATIONS_SUCCESS,
      DashboardActionsType.GET_RECENT_OPERATIONS_FAILURE
    ]);
    yield put(actionAccounts.getAllocationsRequest(selectedCompanyId));
  }
}

function* getRecentOperations(action: GetRecentOperationsRequestActions): any {
  yield put(actionAccounts.recentOperationsInitError())
  const response = yield call(fetchRecentOperations, action.companyId)
  yield put(actionAccounts.getRecentOperationsSuccess(response.data))
}

function* getAvailabilities(action: GetAvailabilitiesRequestAction): any {
  yield put(actionAccounts.availabilitiesInitError())
  const response = yield authenticatedCall(fetchAvailabilities, action.companyId)
  yield put(actionAccounts.getAvailabilitiesSuccess(response.data))
}

function* getAllocations(action: GetAllocationsRequestAction): any {
  yield put(actionAccounts.allocationsInitError())
  const response = yield authenticatedCall(fetchAllocations, action.companyId)
  yield put(actionAccounts.getAllocationsSuccess(response.data))
}

function* getTools(action: GetToolRequestAction): any {
  const toolFlow = action.flow === ToolsFlowTypeEnum.DASHBOARD ? null : action.flow
  const response = yield call(fetchTool, toolFlow)
  yield put(actionAccounts.getToolSuccess(response.data, action.flow))
}

function* getOCD(): any {
  yield put(actionAccounts.ocdInitError())
  const companyId = yield select(getUsedCompanyId)
  const response = yield call(fetchOCD, companyId)
  yield put(actionAccounts.getOCDSuccess(response.data))
}

function* selectCompany(action: PutSelectedCompanyRequestAction): any {
  yield put(actionAccounts.putSelectedCompanySuccess(action.company))
  RootNavigation.replace(RouteNames.BottomTabNavigator, {
    screen: RouteNames.Home
  })
}

function* backOfficeLoadData(action: BackOfficeImplicitConnectionAction): any {
  //save external ID on store
  yield put(actionSettings.setExternalClientID(action.clientId))
  // get authConfig from redux
  const authConfig: AuthConfigType = yield select(getAuthConfig)

  // call oidc api to get config for the clientId ( we need the specific scope)
  const clientIdConfigurationResponse = yield call(
    getLoginOpenIdConfigForClientId,
    action.clientId,
    authConfig.authority
  )
  const clientIdConfiguration = clientIdConfigurationResponse.data
  if (clientIdConfiguration) {
    yield call(signinRedirectUsingToken, action.token, clientIdConfiguration)
  }
}

function* getContributionByCompany(): any {
  yield put(actionAccounts.dashboardInitError())
  const companyId = yield select(getUsedCompanyId)
  const response = yield call(fetchContributionByCompany, companyId)
  yield put(actionAccounts.getContributionSuccess(response.data))
}

function* getContributionByOperation(): any {
  yield put(actionAccounts.dashboardInitError())
  const companyId = yield select(getUsedCompanyId)
  const response = yield call(fetchContributionByOperation, companyId)
  // yield delay(1500);
  // const response = {
  //   data: mockContributionByOperations
  // }
  yield put(actionAccounts.getContributionByOperationSuccess(response.data))
}

function* onLoadCibledContributionPageContent(
  action: GetCibledContributionPageContentRequestAction,
): any {
  const companyId = yield select(getUsedCompanyId);
  const response = yield call(fetchEditorialContent, action.code, companyId);
  yield put(actionAccounts.getCibledContributionPageContentSuccess(response?.data?.[0]));
}

function* getBudgetInsightUrl(): any {
  yield put(actionAccounts.dashboardInitError())
  const response = yield call(fetchBudgetInsightUrl)
  yield put(actionAccounts.getBudgetInsightUrlSuccess(response.data))
  if (Platform.OS !== 'web') return Linking.openURL(response.data)
  return (window.location.href = response.data)
}
function* getDashboardDataSaga(): any {
  yield takeLatest(
    DashboardActionsType.GET_DASHBOARD_REQUEST,
    runManager(loadDashboardData, DashboardActionsType.GET_DASHBOARD_FAILURE)
  )
}
function* getAccountsSagas(): any {
  yield takeLatest(
    DashboardActionsType.GET_ACCOUNTS_REQUEST,
    runManager(onLoadAccounts, DashboardActionsType.GET_ACCOUNTS_FAILURE)
  )
}

export function* getBudgetInsightAccounts(): any {
  const company = yield select(getSelectedCompany)
  yield put(actionAccounts.dashboardInitError())
  if (company?.isBudgetInsightEligible) {
    const response = yield call(fetchGetBudgetInsightAccounts)
    yield put(actionAccounts.getBudgetInsightAccountsSuccess(response.data))
  }
}

function* getBudgetInsightManagerUrl(): any {
  yield put(actionAccounts.dashboardInitError())
  const response = yield call(fetchBudgetInsightManagerUrl)
  yield put(actionAccounts.getBudgetInsightManagerUrlSuccess(response.data))
  if (Platform.OS !== 'web') return Linking.openURL(response.data)
  return (window.location.href = response.data)
}

function* getSSORedirectionUrl(): any {
  const response = yield call(getSSORedirectionUrlApi)
  const url = response?.data?.url

  yield put(actionAccounts.getSSORedirectionUrlSuccess(url))
  if (Platform.OS === 'web') {
    yield window.location.replace(url)
  } else {
    Linking.canOpenURL(url).then(() => {
      setTimeout(() => RootNavigation.goBack(), 1000)
      return Linking.openURL(url)
    })
  }
}

export function* getBudgetInsightAccountsSagas(): any {
  yield takeLatest(
    DashboardActionsType.GET_BUDGET_INSIGHT_ACCOUNTS_REQUEST,
    runManager(getBudgetInsightAccounts, DashboardActionsType.GET_BUDGET_INSIGHT_ACCOUNTS_FAILURE)
  )
}

function* getAccountDataSagas(): any {
  yield takeLatest(
    DashboardActionsType.GET_ACCOUNTS_DATA_REQUEST,
    runManager(onLoadAccountData, DashboardActionsType.GET_ACCOUNTS_DATA_FAILURE)
  )
}

function* getAvailabilitiesSagas() {
  yield takeLatest(
    DashboardActionsType.GET_AVAILABILITIES_REQUEST,
    runManager(getAvailabilities, DashboardActionsType.GET_AVAILABILITIES_FAILURE)
  )
}

function* getAllocationsSagas() {
  yield takeLatest(
    DashboardActionsType.GET_ALLOCATIONS_REQUEST,
    runManager(getAllocations, DashboardActionsType.GET_ALLOCATIONS_FAILURE)
  )
}

function* getToolSagas() {
  yield takeLatest(
    DashboardActionsType.GET_TOOL_REQUEST,
    runManager(getTools, DashboardActionsType.GET_TOOL_FAILURE)
  )
}

function* getOCDSagas() {
  yield takeLatest(
    DashboardActionsType.GET_OCD_REQUEST,
    runManager(getOCD, DashboardActionsType.GET_OCD_FAILURE)
  )
}

function* selectCompanySagas() {
  yield takeLatest(
    DashboardActionsType.PUT_SELECTED_COMPANY_REQUEST,
    runManager(selectCompany, DashboardActionsType.GET_ACCOUNTS_FAILURE)
  )
}

function* getRecentOperationsSagas() {
  yield takeLatest(
    DashboardActionsType.GET_RECENT_OPERATIONS_REQUEST,
    runManager(getRecentOperations, DashboardActionsType.GET_RECENT_OPERATIONS_FAILURE)
  )
}

function* backOfficeImplicitConnection() {
  yield takeLatest(
    DashboardActionsType.BACK_OFFICE_IMPLICIT_CONNECTION,
    runManager(backOfficeLoadData, DashboardActionsType.GET_ACCOUNTS_FAILURE)
  )
}

function* getContributionByCompanySagas() {
  yield takeLatest(
    DashboardActionsType.GET_CONTRIBUTION_REQUEST,
    runManager(getContributionByCompany, DashboardActionsType.GET_CONTRIBUTION_FAILURE)
  )
}

function* getContributionByOperationSagas() {
  yield takeLatest(
    DashboardActionsType.GET_CONTRIBUTION_BY_OPERATION_REQUEST,
    runManager(
      getContributionByOperation,
      DashboardActionsType.GET_CONTRIBUTION_BY_OPERATION_FAILURE
    )
  )
}

function* getBudgetInsightUrlSagas() {
  yield takeLatest(
    DashboardActionsType.GET_BUDGET_INSIGHT_URL_REQUEST,
    runManager(getBudgetInsightUrl, DashboardActionsType.GET_BUDGET_INSIGHT_URL_FAILURE)
  )
}

function* getBudgetInsightManagerUrlSagas() {
  yield takeLatest(
    DashboardActionsType.GET_BUDGET_INSIGHT_MANAGER_URL_REQUEST,
    runManager(
      getBudgetInsightManagerUrl,
      DashboardActionsType.GET_BUDGET_INSIGHT_MANAGER_URL_FAILURE
    )
  )
}

function* getSSORedirectionUrlSagas() {
  yield takeLatest(
    DashboardActionsType.GET_SSO_REDIRECT_URL_REQUEST,
    runManager(getSSORedirectionUrl, DashboardActionsType.GET_SSO_REDIRECT_URL_FAILURE)
  )
}

export function* onLoadCibledContributionPageContentSagas(): any {
  yield takeLatest(
    DashboardActionsType.GET_CIBLED_CONTRIBUTION_PAGE_CONTENT_REQUEST,
    runManager(onLoadCibledContributionPageContent, DashboardActionsType.GET_CIBLED_CONTRIBUTION_PAGE_CONTENT_FAILURE)
  );
}

export function* AccountsSaga() {
  yield all([
    fork(getAccountsSagas),
    fork(getAccountDataSagas),
    fork(getAvailabilitiesSagas),
    fork(getAllocationsSagas),
    fork(getOCDSagas),
    fork(getToolSagas),
    fork(getRecentOperationsSagas),
    fork(selectCompanySagas),
    fork(backOfficeImplicitConnection),
    fork(getContributionByCompanySagas),
    fork(getContributionByOperationSagas),
    fork(getBudgetInsightUrlSagas),
    fork(getBudgetInsightAccountsSagas),
    fork(getBudgetInsightManagerUrlSagas),
    fork(getDashboardDataSaga),
    fork(onLoadCibledContributionPageContentSagas),
    fork(getSSORedirectionUrlSagas)
  ]);
}
