import jwt from "jwt-decode";

import { REACT_APP_AES_KEY } from '@config/index';
import { AccessTokenState, DecodedTokenState, UserState } from '@modules/auth/types';
import { AppState } from '@modules/reducers';
import { decryptData } from '@utils/encryptDecryptData';

export const getUser = (state: AppState): UserState => {
  return {
    ...state.authentication.user,
    profile: decryptData(state.authentication?.user?.profile, REACT_APP_AES_KEY)
  };
};

export const getDecodedUserAccessToken = (state: AppState) => {
  const decodedToken: DecodedTokenState | undefined = state.authentication?.user?.access_token
    ? jwt(state.authentication?.user?.access_token)
    : undefined;
  return decodedToken;
};

export const getDecodedUserTokenId = (state: AppState) => {
  const decodedToken: DecodedTokenState | undefined = state.authentication?.user?.id_token
    ? jwt(state.authentication?.user?.id_token)
    : undefined;
  return decodedToken;
};

export const getUserAccessTokenData = (state: AppState) => {
  const accessTokenData: AccessTokenState | undefined = state.authentication?.user?.access_token
    ? jwt(state.authentication?.user?.access_token)
    : undefined;
  return accessTokenData;
};

export const userLoggedSelector = (state: AppState) => state;

export const getUserInfo = (state: AppState) => {
  return state.authentication.userInfo;
};
export const getLoginError = (state: AppState) => state.authentication.error;

export const getNativeAuthenticationError = (state: AppState) =>
  state.authentication.nativeAuthenticationError;

export const getBiometricNativeAuthenticationError = (state: AppState) =>
  state.authentication.nativeBiometricAuthenticationError;

export const getIsRefreshSessionRequestAlreadyLoading = (state: AppState) =>
  state.authentication.refreshSessionRequestAlreadyLoading;

export const getIsOidcSessionEnded = (state: AppState): boolean =>
  state.authentication.isOidcSessionEnded;

export const getLoginMail = (state: AppState) => state.authentication.loginMail;

export const getBiometricUserAcceptionStatus = (state: AppState) => ({
  biometricAcceptationStatus: state.authentication.biometricAcceptationStatus
});

export const getIsPromptBiometricData = (state: AppState) => ({
  shouldPromptBiometricModalAskVisible: state.authentication.shouldPromptBiometricModalAskVisible,
  BiometryType: state.authentication.BiometryType
});
