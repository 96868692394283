import {
  AuthConfigType,
  AuthSilentRenewConfigType,
  ApplicationSettingsState,
} from '@modules/settings/types';

export const mockApplicationSettings:ApplicationSettingsState = {
  RemboursementSyntheseTarifs: 'https://admin-medias-epargne-retraite-entreprises-int.am.staging.echonet/Documents/TARIFS_2022_Standard_Titulaire.pdf',
  PhoneFormat: '^\\+((?:9[679]|8[035789]|6[789]|5[90]|42|3[578]|2[1-689])|9[0-58]|8[1246]|6[0-6]|5[1-8]|4[013-9]|3[0-469]|2[70]|7|1)(?:\\W*\\d){0,13}\\d$',
  HelpLoginPageDialOnceId: 'ere360_loginpage',
  HelpErrorPageDialOnceId: 'ere360_errorpage',
  HelpActionMenuDialOnceId: 'ere360_actionmenu',
  VVRCUCThreshold: '15',
  FundMorningStarUrl: 'https://lt.morningstar.com/chkgs8xqjf/snapshotpdf/default.aspx?externalid=[ISINCODE]&externalidtype=ISIN&frame=0',
  FundReportingUrl: 'http://doc.morningstar.com/latestdoc.aspx?clientid=bnppar&key=d978d11408912278&ISIN=[ISINCODE]&documenttype=52&language=454&frame=0',
  FundDiciUrl: 'http://doc.morningstar.com/latestdoc.aspx?clientid=bnppar&key=d978d11408912278&ISIN=[ISINCODE]&documenttype=74&language=454&frame=0',
  BOAuditPageSize: '50',
  SessionExpirationTime: '5',
  PasswordRegexAtLeastOneUppercase: '(?=.*[A-Z])',
  PasswordRegexAtLeastOneLowercase: '(?=.*[a-z])',
  EmailFormat: RegExp('^[^@\\s]+@[^@\\s]+\\.[^@\\s]+$'),
  PasswordRegexMinimumNumberOfCharacters: '.{8,}',
  HelpCredentialsIssuePageDialOnceId: 'ere360_errorpage',
  FundsDocOrder: [
    'KIID', 
    'FACTSHEET', 
    'MORNINGSTAR_FACTSHEET', 
    'FP', 
    'REPA', 
    'REPS', 
    'REGULATION', 
    'IPTM', 
    'XFR', 
    'TRANSPARENCY_CODE', 
    'IMPACT_REPORT'
  ],
  CookieOneTrustKey: 'bbb015c9-fe95-4567-a4ec-3fbd9002b09c-test',
  HelpDialOnceIntegrationKey: '7bfe6d45-5f85-45e3-8717-bc8b1e2ca64e',
  PasswordRegexAtLeastOneSpecialCharacter: '(?=.*[#$^+\\/=!*()@%&.-])',
  PasswordRegexAtLeastOneNumber: '(?=.*\\d)',
  EmailDomainFormat: '(@)(.+)$',
  ApplicationATInternetSiteID: '623604',
  authority: 'https://api.ere.staging.bnpparibas.com/auth',
  client_id: 'ERE360FRONT',
  redirect_uri: 'https://api.ere.staging.bnpparibas.com/oidc/callback',
  post_logout_redirect_uri: 'https://api.ere.staging.bnpparibas.com/oidc/logout',
  response_type: 'code',
  scope: 'openid profile email companysaving ere360 api_epargne_salariale web_epargne_salariale api_ere360_middleware am_funds_api offline_access',
  origin: '',
  FundApiUrl: 'https://funds-api.am.dev.echonet/',
  EnableFeatureSapiendo: true,
  EnableFeatureFranceConnect: true,
  EnableFeatureAideDeblocage: true,
  redirect_uris: [
    'https://api.ere.staging.bnpparibas.com/oidc/callback',
    'https://api.ere.staging.bnpparibas.com/oidc/silent',
    'https://bnppereconnect.azurewebsites.net/oidc/callback',
    'https://bnppereconnect.azurewebsites.net/oidc/silent',
    'https://ere360.am.dev.echonet/oidc/callback',
    'https://ere360.am.dev.echonet/oidc/silent',
  ],
  post_logout_redirect_uris: [
    'https://api.ere.staging.bnpparibas.com/oidc/logout',
    'https://bnppereconnect.azurewebsites.net/oidc/logout',
    'https://ere360.am.dev.echonet/oidc/logout',
  ],
};
export const mockAuthConfig:AuthConfigType = {
  authority: 'https://api.ere.staging.bnpparibas.com/auth',
  client_id: 'ERE360FRONT',
  loadUserInfo: true,
  post_logout_redirect_uri: 'https://ere360.am.dev.echonet/oidc/logout',
  redirect_uri: 'https://ere360.am.dev.echonet/oidc/callback',
  response_type: 'code',
  scope: 'openid profile email companysaving ere360 api_epargne_salariale web_epargne_salariale api_ere360_middleware am_funds_api offline_access',
};
export const mockAuthSilentRenewConfig:AuthSilentRenewConfigType = {
  authority: 'https://api.ere.staging.bnpparibas.com/auth',
  automaticSilentRenew: true,
  client_id: 'ERE360FRONT',
  loadUserInfo: true,
  post_logout_redirect_uri: 'https://ere360.am.dev.echonet/oidc/logout',
  redirect_uri: 'https://ere360.am.dev.echonet/oidc/callback',
  response_type: 'code',
  scope: 'openid profile email companysaving ere360 api_epargne_salariale web_epargne_salariale api_ere360_middleware am_funds_api offline_access',
  silent_redirect_uri: 'https://ere360.am.dev.echonet/oidc/silent',
};
