import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useScreenSizes, useTheme } from '@ere-uilib/styles';

export function useStyles() {
  const theme = useTheme();
  const { isMobile } = useScreenSizes();

  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          backgroundColor: theme.colors.basics.white
        },
        contentContainer: {
          flex: 1,
          flexDirection: 'column',
          width: isMobile ? '90%' : '60%',
          alignSelf: 'center',
          alignItems: 'center'
        },
        svgContainer: {
          alignSelf: 'center',
          width: 400,
          height: 200,
          padding: theme.metrics.spacing.m
        },
        contentTitle: {
          textAlign: 'center',
          padding: theme.metrics.spacing.m
        },
        choiceButtonContainer: {
          paddingVertical: theme.metrics.spacing.xm,
          width: '100%',
        }
      }),
    [isMobile, theme]
  );

  return styles;
}
