import { useIsFocused } from '@react-navigation/native';
import loadash from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { View } from 'react-native';

import {
  ColumnsContainer,
  DialOncePressable,
  InstallmentsStepper,
  OperationsNotificationErrorsWrapperList,
  useScreenSizes,
  HtmlStyledRenderConnectedContainer
} from '@components/index';
import {
  DashboardPageWrapperConnected,
  useTranslation,
  Title,
  useTheme,
} from '@components/index';
import { locator } from '@constants/locator';
import { RouteNames } from '@constants/navigation';
import { LoaderPlaceholderView, NotificationIconTypeEnum } from '@ere-uilib/atoms';
import { CircleLoader } from '@ere-uilib/atoms/loaders/Circle/Circle';
import { HelpButton, NotificationHard, SimpleButton } from '@ere-uilib/molecules';
import {
  DirectionEnum,
} from '@ere-uilib/molecules/steppers/InstallmentsStepper/interfaces';
import { createUseStyles } from '@ere-uilib/styles';
import { DispositifState } from '@modules/common/types';
import { removeDuplicatePlanFamilyInstallments } from '@modules/installment/utils';
import { constructStepperItems } from '@pages/Common/utils';
import { useFormatHrefHtml } from '@utils/useFormatHrefHtml';

import { InstallmentHeader } from './components/InstallmentHeader';
import { Props } from './interfaces';
import { getStyles } from './styles';
import { useNavigation } from '@react-navigation/native';

export const InstallmentPageComponent: React.FC<Props> = ({
  getInstallMentDecisionData,
  decision,
  isApiLoading,
  applicationSettings,
}) => {
  const { formatMessage, getMessageRaw } = useTranslation();
  const isFocused = useIsFocused();
  const navigation = useNavigation();
  const messageIds = [
    'VV_needs_VoiciEtapes_DefinitionBesoins_label',
    'VV_needs_VoiciEtapes_Repartition_Besoins_label',
    'VV_needs_VoiciEtapes_ChoixMode_Reglement_label',
    'VV_needs_VoiciEtapes_ValidationDemande_label',
  ];
  const stepperItems = constructStepperItems({
    size: 4,
    formatMessage,
    messageIds,
  });

  const pageTitle = formatMessage({ id: 'VV_needs_VoiciEtapes_title' });
  const subtitle = formatMessage({ id: 'VV_needs_VoiciEtapes_label' });
  const notificationTitle = formatMessage({ id: 'VV_needs_infoiban_title' });
  const buttonSubmitTitle = formatMessage({ id: 'VV_needs_cestParti_button' });
  const theme = useTheme();
  const { isMobile, isTablet, isDesktop } = useScreenSizes();
  const style = styleFunction(
    {
      theme,
      isMobile: isMobile || isTablet,
    },
    {}
  );

  const blockedDispositifs: DispositifState[] = decision?.dispositifs.filter(
    (dispositif: DispositifState) =>
    ((dispositif?.filterProperties.VVP === false &&
      dispositif.filterProperties.VVL === false)
      && (dispositif?.blocagesCodes && dispositif.blocagesCodes.length > 0) ||
      (dispositif?.advancedProperties.HasIncorrectDrivingData === true)
    )
  );

  const availableDispositifs: DispositifState[] = decision?.dispositifs.filter(
    (dispositif: DispositifState) =>
      (dispositif?.filterProperties.VVP === true ||
        dispositif.filterProperties.VVL === true)
      &&
      (dispositif?.advancedProperties.HasIncorrectDrivingData === false || !dispositif?.advancedProperties.HasIncorrectDrivingData)
  );

  const isAllDispositifsBlocked = availableDispositifs?.length === 0;
  const isAtLeastOneDispotifEligible = availableDispositifs?.length > 0;

  useEffect(() => {
    if (isFocused) {
      getInstallMentDecisionData();
    }
  }, [isFocused]);

  const renderLoader = (steps: any) =>
    steps.map((index) => (
      <View style={style.loaderContainer} key={`stepInstallement_${index}`}>
        <CircleLoader diameter={36} />
        <LoaderPlaceholderView style={style.loader} />
      </View>
    ));

  const RenderHeader = useCallback(
    () => (
      <InstallmentHeader
        displayCloseButton
        helpPoint
        helpPointContentHtml={getMessageRaw({ id: 'VV_needs_info_content' })}
        helpPointModalTitle={formatMessage({ id: 'VV_repartition_info_label' })}
        helpPointPosition="left"
        stepperItems={[]}
        title={pageTitle}
      />
    ),
    [pageTitle]
  );

  const RenderNotificationHard = useCallback(
    () => {
      const isBankDataEnabled = decision.questions[0].saverDetails?.bankAccountDetail !== null;
      const sortedFamilies = removeDuplicatePlanFamilyInstallments(loadash.map(decision.dispositifs, 'planFamily'));
      const checkFamilyType =  sortedFamilies.length === 2 ? "Mixte" :
      (sortedFamilies.length === 1 && sortedFamilies[0] === "ES" ? "ES" : "RC")
      let notificationSubtitleTitleId = "";
      if (isBankDataEnabled) {
        notificationSubtitleTitleId = 'VV_needs_infoiban_label'
      }
      else {
        notificationSubtitleTitleId = `VV_needs_infoiban_Label_${checkFamilyType}`
      }
      const notificationSubtitle = `${formatMessage({ id: notificationSubtitleTitleId })} ${formatMessage({ id: 'VV_needs_infoiban_link_label' })}`;
      return (
        <>
          {!isApiLoading && (
            <NotificationHard
              containerStyle={style.notification}
              title={notificationTitle}
              type={NotificationIconTypeEnum.HELP}
            >
              <HtmlStyledRenderConnectedContainer
                baseFontStyle={{ fontFamily: theme.fonts.fontFamily.light }}
                html={useFormatHrefHtml(notificationSubtitle)} />

            </NotificationHard>
          )}
        </>
      )
    },
    [isApiLoading, decision]
  );


  const renderSubmitButton = () => (
    <View style={style.buttonContainer}>
      <SimpleButton
        disabled={isApiLoading}
        loading={isApiLoading}
        onPress={() => {
          navigation.navigate(RouteNames.InstallmentStack, {
            screen: RouteNames.InstallmentsRequirementDefiniton,
          });
        }}
        testId={locator._payment._lets_go}
        title={buttonSubmitTitle}
      />
    </View>
  );

  const renderErrors = useCallback(() => {
    if (isAtLeastOneDispotifEligible &&
      (!blockedDispositifs || blockedDispositifs?.length === 0)
    ) {
      return <></>;
    }
    return (
      <OperationsNotificationErrorsWrapperList
        cardTitleLabelCode={'VersementPageErreurCardInfoTitre'}
        containerStyle={isAllDispositifsBlocked && style.blockedPageContainer}
        dispositifs={blockedDispositifs}
        isExpandable={isAtLeastOneDispotifEligible}
        subtitle={formatMessage({ id: 'VersementPageErreurDescription' })}
        title={formatMessage({
          id: 'VersementPageErreurTitre',
        })}
      >
        <DialOncePressable
          integrationKey={applicationSettings?.HelpDialOnceIntegrationKey || ''}
          targetedFlowId={applicationSettings?.HelpActionMenuDialOnceId || ''}
        >
          <HelpButton
            containerStyle={style.dialOnceHelpButton}
            onPress={() => { }}
            title={formatMessage({ id: 'session_security_help_contact_button' })}
          />
        </DialOncePressable>
      </OperationsNotificationErrorsWrapperList>
    );
  }, [
    blockedDispositifs,
    formatMessage,
    isAtLeastOneDispotifEligible,
  ]);

  const renderOnboardingInstallmentStepper = () => (
    <>
      <Title
        style={style.title}
        variant={isDesktop ? 't3' : 't4'}>
        {subtitle}
      </Title>
      <View style={style.stepperContainer}>
        <InstallmentsStepper
          direction={DirectionEnum.COLUMN}
          stepperItems={stepperItems}
        />
      </View>
      {(isMobile || isTablet) && (
        <View style={style.notificationContainer}>
          <RenderNotificationHard />
        </View>
      )}
      {renderErrors()}
      {!isMobile && !isTablet && renderSubmitButton()}
    </>
  );

  return (
    <DashboardPageWrapperConnected
      cardContentStyle={{ backgroundColor: theme.colors.basics.white }}
      renderStickyMobileBottom={
        isAtLeastOneDispotifEligible
          ? renderSubmitButton
          : undefined
      }
    >
      <ColumnsContainer
        hasRenderRightDesktopColumn={!isAllDispositifsBlocked}
        renderHeader={RenderHeader}
        renderRightDesktopColumn={RenderNotificationHard}
      >
        {
          isApiLoading ? (
            renderLoader(stepperItems)
          ) : (
            <View style={style.bodyContainer}>
              {
                isAtLeastOneDispotifEligible ?
                  renderOnboardingInstallmentStepper()
                  : renderErrors()
              }
            </View>
          )
        }
      </ColumnsContainer>
    </DashboardPageWrapperConnected>
  );
};

const styleFunction = createUseStyles(getStyles);
