import { PiProfitSharingHorizon } from '@constants/index';
import { SupportType } from '@modules/common/types';
import { AppState } from '@modules/reducers';

import { AllSupports } from './types';

export const getProfitSharingIncentiveError = (state: AppState) => {
  return state.profitSharingIncentive.error;
};
export const getProfitSharingIncentiveInitDataState = (state: AppState) => {
  return state.profitSharingIncentive.initData;
};

export const getProfitSharingIncentiveAssigmentAmounts = (state: AppState) => {
  return state.profitSharingIncentive.asignmentAmounts;
};

export const getProfitSharingIncentiveRepartitionState = (state: AppState) => {
  return state.profitSharingIncentive.repartition;
};

export const getProfitSharingIncentiveCalculatedContribution = (state: AppState) => {
  return state.profitSharingIncentive.calculatedContribution;
};

export const getProfitSharingIncentiveRepartitionAmountModalVisibleState = (state: AppState) => {
  return state.profitSharingIncentive.repartitionAmountModalVisibleState;
};

export const getProfitSharingIncentiveRepartitionRetirementValidationModalVisibleState = (state: AppState) => {
  return state.profitSharingIncentive.repartitionRetirementValidationModalVisibleState;
};

export const getInvestedProjectPlans = (state: AppState) => {
  return state.profitSharingIncentive.repartition?.plans.filter(plan => plan.horizon !== PiProfitSharingHorizon.RETRAITE);
}

export const getInvestedRetirementPlans = (state: AppState) => {
  return state.profitSharingIncentive.repartition?.plans.filter(plan => plan.horizon === PiProfitSharingHorizon.RETRAITE);
}
export const getSavedDistributions = (state: AppState) => {
  return state.profitSharingIncentive.calculateContributionInput
}

export const getAllSupportsToCompare = (state: AppState): AllSupports => {
  const plans = state.profitSharingIncentive.repartition?.plans;
  const constructedAllSupports: SupportType[] = [];
  plans?.map?.(plan => {
    plan.compartments.map(compartment => {
      compartment.managements
        .filter(management => !management?.advancedProperties?.IsTechnicalManagement)
        .map(management => {
        management.supports
          .filter(support => !support?.advancedProperties?.IsTechnicalFund)
          .map(support => {
          const supportIndexInList = constructedAllSupports.findIndex(currentSupport => currentSupport.supportIsin === support.supportIsin)
          if (supportIndexInList >= 0) {
            constructedAllSupports[0] = {
              ...constructedAllSupports[0],
              hasContribution: constructedAllSupports[0].hasContribution || support.hasContribution,
              amount: {
                ...constructedAllSupports[0].amount,
                amount: (constructedAllSupports[0].amount.amount || 0) + (support.amount.amount || 0)
              }
            }
          } else {
            constructedAllSupports.push(support);
          }
        })
      })
    })
  });
  const constructedAllSupportsUniqueSupportIsin: SupportType[] = [];

  constructedAllSupports.map(x => constructedAllSupportsUniqueSupportIsin.filter(a => a.supportIsin == x.supportIsin).length > 0 ? null : constructedAllSupportsUniqueSupportIsin.push(x));

  return {
    supports: constructedAllSupportsUniqueSupportIsin,
  }
}
