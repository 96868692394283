
import { connect } from 'react-redux';

import { AppState } from '@modules/index';
import {
  CancelProfitSharingIncentiveRetirementAmountModal, 
  ValidateProfitSharingIncentiveRetirementAmountModal,
} from '@modules/profit-sharing-incentive/actions/profitSharingIncentiveActions';

import { 
  ProfitSharingIncentiveRetirementAmountValidationConnectedComponent 
} from './ProfitSharingIncentiveRetirementAmountValidationModalConnected.component';
import {
  getProfitSharingIncentiveRepartitionRetirementValidationModalVisibleState
} from '@modules/profit-sharing-incentive/selectors';

const mapStateToProps = (state: AppState) => {
  return {
    isVisible: getProfitSharingIncentiveRepartitionRetirementValidationModalVisibleState(state),
  };
};

const mapDispatchToProps = {
  onCancel: CancelProfitSharingIncentiveRetirementAmountModal,
  onValidate: ValidateProfitSharingIncentiveRetirementAmountModal,
};

export const ProfitSharingIncentiveRetirementAmountValidationConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(ProfitSharingIncentiveRetirementAmountValidationConnectedComponent);
