import axios from 'axios';

import { API_URL } from '@config/index';
import {
  FetchInvitationIdentificationDataType,
  FetchInvitationIdentificationLetterOtpDataType, InvitationState
} from '@modules/invitation/types';
import { ValidateIdentityState } from '@modules/validate-identity/types';

const AXIOS_REQUEST_CONFIG = {
  headers: {
    'Content-Type': 'application/json'
  }
};

export async function fetchInvitation(token: string): Promise<InvitationState> {
  return axios.get(`${API_URL}/api/v1/registrations/token=${token}`);
}

// EER Identity Validation OTP CALLS (SEND MAIL OR SMS AND CHECK CODE)

export async function fetchSendEERValidationIdentityCode(
  actionBody: any,
): Promise<ValidateIdentityState> {
  return axios.post(
    `${API_URL}/api/v1/registrations/${actionBody.userId}/auth/2fa/send`,
  );
}

export async function fetchCheckEERValidationIdentityCode(
  actionBody: any,
): Promise<ValidateIdentityState> {
  return axios.post(
    `${API_URL}/api/v1/registrations/${actionBody.userId}/auth/2fa/check`,
    JSON.stringify(actionBody.validationCode),
    AXIOS_REQUEST_CONFIG
  );
}

// EER Identitifier Validation OTP CALLS (SEND MAIL OR SMS AND CHECK CODE)

export async function fetchValidateEEREmailNewIdentifier(
  actionBody: {
    userId: string
    email: string
  },
): Promise<ValidateIdentityState> {
  return axios.post(
    `${API_URL}/api/v1/registrations/${actionBody.userId}/email`,
    JSON.stringify(actionBody.email),
    AXIOS_REQUEST_CONFIG
  );
}
export async function fetchValidateEEREmailNewIdentifierCode(
  actionBody: {
    userId: string
    emailCode: string
  },
): Promise<ValidateIdentityState> {
  return axios.post(
    `${API_URL}/api/v1/registrations/${actionBody.userId}/email/confirm`,
    JSON.stringify(actionBody.emailCode),
    AXIOS_REQUEST_CONFIG
  );
}
export async function fetchValidateEERPhoneNewIdentifier(
  actionBody:{
    userId: string
    phone: string
  },
): Promise<ValidateIdentityState> {
  return axios.post(
    `${API_URL}/api/v1/registrations/${actionBody.userId}/mobile`,
    JSON.stringify(actionBody.phone),
    AXIOS_REQUEST_CONFIG
  );
}
export async function fetchValidateEERPhoneNewIdentifierCode(
  actionBody:{
    userId: string
    phoneCode: string
  }
): Promise<ValidateIdentityState> {
  return axios.post(
    `${API_URL}/api/v1/registrations/${actionBody.userId}/mobile/confirm`,
    JSON.stringify(actionBody.phoneCode),
    AXIOS_REQUEST_CONFIG
  );
}

export async function identityValidationSetEmailCode(
  actionBody: any,
): Promise<ValidateIdentityState> {
  return axios.post(
    `${API_URL}/api/v1/registrations/${actionBody.userId}/emailconfirmations/confirm/`,
    JSON.stringify(actionBody.emailCode),
    AXIOS_REQUEST_CONFIG
  );
}

export async function registrationRequestConfirm(
  actionBody: {
    userId: string,
    password: string
  },
): Promise<ValidateIdentityState> {
  return axios.post(
    `${API_URL}/api/v1/registrations/${actionBody.userId}/registrationrequests/confirm`,
    {
      password: actionBody.password,
    },
    AXIOS_REQUEST_CONFIG
  )
}

// FRANCE CONNECT

export async function franceConnectCreateIdentityValidation(
  userId: string,
): Promise<ValidateIdentityState> {
  return axios.post(
    `${API_URL}/api/v1/registrations/${userId}/franceconnectconfirmations`);
}

export async function franceConnectConfirmationIdentity(
  registrationId: string,
  state: string,
  code: string
): Promise<ValidateIdentityState> {
  return axios.post(
    `${API_URL}/api/v1/registrations/${registrationId}/franceconnectconfirmations/${state}/confirm`,
    JSON.stringify(code),
    AXIOS_REQUEST_CONFIG
  );
}

// selfcare EER
export async function fetchCreateSelfcareInvitationFromExternalIdentity({
  type,
  identifier,
  birthDate,
}: FetchInvitationIdentificationDataType): Promise<ValidateIdentityState> {
  return axios.post(
    `${API_URL}/api/v1/registrations/createFromExternal`,
    {
      type,
      identifier,
      birthDate,
    },
    AXIOS_REQUEST_CONFIG
  );
}
export async function fetchCreateSelfcareInvitationFromLetterOtpIdentity({
  otpCode,
  birthDate,
}:  FetchInvitationIdentificationLetterOtpDataType): Promise<{ data: InvitationState }> {
  return axios.post(
    `${API_URL}/api/v1/registrations/init`,
    {
      otpCode,
      individualBirthDate: birthDate,
    },
    AXIOS_REQUEST_CONFIG
  );
}
