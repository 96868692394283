import React, { useCallback, useMemo } from 'react';
import { View } from 'react-native';

import { Title } from '@ere-uilib/atoms';
import { Card } from '@ere-uilib/molecules';
import { HelpPoint, RetryActionPlaceHolder } from '@ere-uilib/organisms';
import { useTheme } from '@ere-uilib/styles';
import { Props, StylesProps } from './interface';
import { useStyles } from './useStyles';

export const DashboardContentWrapperCardComponent: React.FC<Props & StylesProps> = ({
  title,
  helpPoint,
  isError = false,
  isLoading = false,
  children,
  testId,
  containerStyle,
  headerStyle,
  helpPointStyle,
  titleStyle,
  errorRetryAction,
  renderLoader,
  renderLink,
  renderFooter,
}) => {
  const theme = useTheme();
  const disabledColor = useMemo(() => (isError || isLoading)
    ? theme.colors.basics.grey.c400
    : theme.colors.basics.black, [
    isError,
    isLoading
  ]);

  const styles = useStyles(disabledColor);

  const canRenderError = useMemo(() => !isLoading && isError, [isError, isLoading]);
  const canRenderLoader = useMemo(() => isLoading, [isLoading]);
  const canRenderContent = useMemo(() => !isLoading && !isError, [isError, isLoading]);
  const canRenderLink = useMemo(() => !isLoading && !isError && renderLink, [isError, isLoading, renderLink]);

  const renderError = useCallback(() => (
    <RetryActionPlaceHolder retryAction={errorRetryAction} />
  ), [errorRetryAction])

  return (
    <Card containerStyle={[styles.containerStyle, containerStyle]}>
      <View style={[styles.headerStyle, headerStyle]}>
        <View style={styles.headerContentContainer}>
          {helpPoint && (
            <HelpPoint
              contentHtml={helpPoint?.contentHtml ?? ''}
              contentTitle={helpPoint?.contentTitle ?? ''}
              iconColor={disabledColor}
              iconSize={theme.metrics.spacing.xxm}
              modalTitle={helpPoint?.modalTitle ?? ''}
              style={[styles.helpPointStyle, helpPointStyle]}
            />
          )}
          {<Title
            style={[styles.titleStyle, titleStyle]}
            testId={testId}
            variant="t6"
            weight="bold">
            {title}
          </Title>}
        </View>
        {canRenderLink && renderLink?.()}
      </View>
      {canRenderError && renderError?.()}
      {canRenderLoader && renderLoader?.()}
      {canRenderContent && children}
      {canRenderContent && renderFooter?.()}
    </Card>
  );
};

