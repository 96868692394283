import { OTPLimitErrorModalStyles, StylesContext } from './interface';

export const getStyles = (
  context?: StylesContext,
  style?:  OTPLimitErrorModalStyles,
):  OTPLimitErrorModalStyles => ({
  containerStyle: [
    {
      alignItems: 'center',
      justifyContent: 'flex-start'
    }, style?.containerStyle
  ],
  iconContainerStyle: [
    {
      borderRadius: context?.theme.metrics.borderRadius.xs,
      padding: context?.theme.metrics.spacing.s,
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: context?.theme.metrics.spacing.xm,
      marginTop: context?.theme.metrics.spacing.l
    },
    style?.iconContainerStyle
  ],
  titleStyle: [
    {
      marginTop: context?.theme.metrics.spacing.s,
      textAlign: 'center'
    },
    style?.titleStyle
  ],
  descriptionStyle: [
    {
      marginTop: context?.theme.metrics.spacing.s,
      textAlign: 'center'
    },
    style?.descriptionStyle
  ],
  hoursStyle: [
    {
      marginTop: context?.theme.metrics.spacing.s,
      textAlign: 'center'
    },
    style?.hoursStyle
  ],
  buttonContainerStyle: [
    {
      marginTop: context?.theme.metrics.spacing.l
    },
    style?.buttonContainerStyle
  ]
});
