import * as actions from './invitationActionsTypes';
import { InvitationActionsType } from './invitationActionsTypes';
import {
  InvitationState,
  InvitationErrorState,
  InvitationIdentificationDataType,
  InvitationLetterOtpIdentificationDataType,
  RedirectionErrorState
} from '../types';

export function getInvitationRequest(token: string): actions.GetInvitationRequestAction {
  return {
    type: InvitationActionsType.GET_INVITATION_REQUEST,
    token
  };
}

export function getInvitationSuccess(
  data: InvitationState,
): actions.GetInvitationSuccessAction {
  return {
    type: InvitationActionsType.GET_INVITATION_SUCCESS,
    data,
  };
}

export function getInvitationFailure(
  error: InvitationErrorState,
): actions.GetInvitationFailureAction {
  return {
    type: InvitationActionsType.GET_INVITATION_FAILURE,
    error,
  };
}

// REDIRECTION ERROR

export function RedirectionErrorRequest(
  redirectionError: RedirectionErrorState,
): actions.RedirectionErrorAction {
  return {
    type: InvitationActionsType.REDIRECTION_ERROR_REQUEST,
    redirectionError,
  };
}

// Selfcare invitation request

export function createSelfcareInvitationFromExternalIdentityRequest(
  {
    type,
    identifier,
    birthDate,
  }:InvitationIdentificationDataType
): actions.CreateSelfcareInvitationFromExternalIdentityRequestAction {
  return {
    type: InvitationActionsType.CREATE_SELFCARE_INVITATION_FROM_EXTERNAL_IDENTITY_REQUEST,
    identificationData: {
      type,
      identifier,
      birthDate,
    },
  };
}

export function createSelfcareInvitationFromExternalIdentitySuccess(
  email: string
):actions.CreateSelfcareInvitationFromExternalIdentitySuccessAction {
  return {
    type: InvitationActionsType.CREATE_SELFCARE_INVITATION_FROM_EXTERNAL_IDENTITY_SUCCESS,
    email,
  };
}

export function createSelfcareInvitationFromExternalIdentityFailure(
  error: InvitationErrorState,
): actions.CreateSelfcareInvitationFromExternalIdentityFailureAction {
  return {
    type: InvitationActionsType.CREATE_SELFCARE_INVITATION_FROM_EXTERNAL_IDENTITY_FAILURE,
    error,
  };
}

// Selfcare invitation Letter otp request

export function createSelfcareInvitationFromLetterOtpIdentityRequest(
  {
    otpCode,
    birthDate,
  }: InvitationLetterOtpIdentificationDataType
): actions.CreateSelfcareInvitationFromLetterOtpRequestAction {
  return {
    type: InvitationActionsType.CREATE_SELFCARE_INVITATION_FROM_LETTER_OTP_REQUEST,
    identificationData: {
      otpCode,
      birthDate,
    },
  };
}

export function createSelfcareInvitationLetterOtpIdentitySuccess(
  data: InvitationState
): actions.CreateSelfcareInvitationFromLetterOtpSuccessAction {
  return {
    type: InvitationActionsType.CREATE_SELFCARE_INVITATION_FROM_LETTER_OTP_SUCCESS,
    data,
  };
}

export function createSelfcareInvitationLetterOtpIdentityFailure(
  error: InvitationErrorState,
): actions.CreateSelfcareInvitationFromLetterOtpFailureAction {
  return {
    type: InvitationActionsType.CREATE_SELFCARE_INVITATION_FROM_LETTER_OTP_FAILURE,
    error,
  };
}

export function createSelfcareInvitationResetError(): actions.CreateSelfcareInvitationResetErrorAction {
  return {
    type: InvitationActionsType.CREATE_SELFCARE_INVITATION_RESET_ERROR,
  };
}
