import React, { PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { Platform, StyleProp, View, ViewStyle } from 'react-native';
import { ForeignObject, G } from 'react-native-svg';

import { createUseStyles, ThemeType, useTheme } from '@ere-uilib/styles';

interface LineChartToolTipProps {
  isInRightPart:boolean
  isInTopPart:boolean
  x: number | undefined
  y: number | undefined
  displayLineCursor?: boolean
  dotsCursors?: DotsCursorsType
  onContainerLayout?:({ width, height }:SavedLayoutType)=>void
  lastLayoutSaved?:SavedLayoutType
}
export type DotsCursorsType =  {
  color: string
  y: number
}[]
interface StylesContext {
  theme: ThemeType
  isInRightPart:boolean
  isInTopPart:boolean
  containerSize:SavedLayoutType
  toolTipSpaceSize: number
  decal: number
}
type SavedLayoutType= {width: number; height: number}
interface LineChartToolTipStyles {
  spaceContainerStyle?: StyleProp<ViewStyle>
  absoluteContainerStyle?: StyleProp<ViewStyle>
  containerStyle?: StyleProp<ViewStyle>
  lineCursorStyle?: StyleProp<ViewStyle>
  dotCursorContainerStyle?: StyleProp<ViewStyle>
  dotCursorStyle?: StyleProp<ViewStyle>
}

const dotDiameter = 12;
const dotRadius = dotDiameter / 2;

export const LineChartToolTip: React.FC<PropsWithChildren<LineChartToolTipProps>> = ({
  children,
  isInRightPart,
  isInTopPart,
  x,
  y,
  displayLineCursor,
  dotsCursors = [],
  onContainerLayout,
  lastLayoutSaved = { width: 150, height: 150 },
}) => {
  const theme = useTheme();
  const [
    containerSize,
    setContainerSize,
  ] = useState<SavedLayoutType>(lastLayoutSaved);

  const toolTipSpaceSize = 2000;
  const decal = useMemo(() => {
    if (Platform.OS === 'android') {
      return 0;
    }

    return toolTipSpaceSize / 2;
  }, []);

  const positionX = x && x - decal;
  const positionY = y && y - decal;

  const styles = useStyles(
    { theme, isInRightPart, isInTopPart, containerSize, toolTipSpaceSize, decal },
    {},
  );

  const handleLayout = useCallback(({ nativeEvent })=>{
    const { width, height } = nativeEvent.layout;
    if (width !== containerSize.width || height !== containerSize.height) {
      setContainerSize({ width, height });
      onContainerLayout?.({ width, height });
    }
  }, [onContainerLayout]);

  const renderDotsCursors = useCallback(() => (
    dotsCursors?.map((cursorData, index) => {
      return (
        <View
          key={index + '_' + cursorData.y}
          style={[
            styles.dotCursorContainerStyle,
            {
              top: -dotRadius - cursorData.y,
              backgroundColor: cursorData.color + '4D',
              left: Platform.OS === 'android' ? -10 : -dotRadius,
            },
          ]}
        >
          <View
            style={[
              styles.dotCursorStyle,
              {
                backgroundColor: cursorData.color,
              },
            ]}
          />
        </View>
      );
    })
  ), [dotsCursors]);

  const androidToolTipPositionX = useMemo(() => {
    if (!positionX) {
      return;
    }

    if (isInRightPart) {
      return  positionX - containerSize.width - 10;
    }

    return positionX + 10;
  }, [positionX, containerSize, isInRightPart]);

  const androidToolTipPositionY = useMemo(() => {
    if (!positionY) {
      return;
    }

    if (!isInTopPart) {
      return  positionY - containerSize.height - 10;
    }

    return positionY + 10;
  }, [positionY, containerSize, isInTopPart]);

  if (Platform.OS === 'android') {
    return (
      <G pointerEvents='none'>
        {displayLineCursor && (
          <ForeignObject
            height={toolTipSpaceSize}
            width={toolTipSpaceSize}
            x={positionX && positionX}
            y={positionY}
          >
            <View style={styles.lineCursorStyle}/>
          </ForeignObject>
        )}
        <ForeignObject
          height={toolTipSpaceSize}
          width={toolTipSpaceSize}
          x={positionX && positionX - dotRadius}
          y={positionY && positionY - dotRadius}
        >
          {renderDotsCursors()}
        </ForeignObject>
        <ForeignObject
          height={toolTipSpaceSize}
          width={toolTipSpaceSize}
          x={androidToolTipPositionX}
          y={androidToolTipPositionY}
        >
          <View style={styles.spaceContainerStyle}>
            <View style={styles.absoluteContainerStyle}>
              <View
                onLayout={handleLayout}
                style={styles.containerStyle}
              >
                {children}
              </View>
            </View>
          </View>
        </ForeignObject>
      </G>
    )
  }

  return (
    <G pointerEvents="none">
      <ForeignObject
        height={toolTipSpaceSize}
        width={toolTipSpaceSize}
        x={positionX}
        y={positionY}
      >
        <View style={styles.spaceContainerStyle}>
          <View style={styles.absoluteContainerStyle}>
            <View
              onLayout={handleLayout}
              style={styles.containerStyle}
            >
              {children}
            </View>
            {displayLineCursor && (
              <View style={styles.lineCursorStyle}/>
            )}
            {renderDotsCursors()}
          </View>
        </View>
      </ForeignObject>
    </G>
  );
};

const getStyles = (
  context?: StylesContext,
  style?: LineChartToolTipStyles,
): LineChartToolTipStyles => ({
  spaceContainerStyle: [
    {
      width: context?.toolTipSpaceSize,
      height: context?.toolTipSpaceSize,
    },
    style?.spaceContainerStyle,
  ],
  absoluteContainerStyle: [
    {
      position: 'absolute',
      width: context?.toolTipSpaceSize,
      height: context?.toolTipSpaceSize,
      left: context?.decal,
      top: context?.decal,
    },
    style?.absoluteContainerStyle,
  ],
  containerStyle: [
    context && {
      borderRadius: 5,
      padding: context.theme.metrics.spacing.s,
      backgroundColor: context?.theme.colors.basics.grey.c100,
      position: 'absolute',
      shadowColor: context?.theme.colors.basics.black,
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.2,
      shadowRadius: 9,
      elevation: 1,
      ...(context.isInRightPart ? {
        transform: [
          ...(!context.isInTopPart ? [{ translateY: -context.containerSize.height }] : []),
          { translateX: -context.containerSize.width - context.theme.metrics.spacing.s },
        ],
      } : {
        marginLeft: context.theme.metrics.spacing.s,
        ...(!context.isInTopPart && {
          transform: [{ translateY: -context.containerSize.height }],
        }),
      }),
    },
    style?.containerStyle,
  ],
  lineCursorStyle: [
    {
      width: 1,
      minHeight: 9999,
      backgroundColor: context?.theme.colors.basics.grey.c500,
      position: 'absolute',
      top: -999,
    },
    style?.lineCursorStyle,
  ],
  dotCursorContainerStyle: [
    {
      width: dotDiameter,
      height: dotDiameter,
      borderRadius: 24,
      position: 'absolute',
      left: -dotRadius,
      justifyContent: 'center',
      alignItems: 'center',
    },
    style?.dotCursorContainerStyle,
  ],
  dotCursorStyle: [
    {
      width: 8,
      height: 8,
      borderRadius: 16,
    },
    style?.dotCursorStyle,
  ],
});

const useStyles = createUseStyles(getStyles);
