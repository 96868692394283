import { ArbitrationTypes } from '@constants/arbitrations'
import * as actions from '@modules/arbitration/actions/arbitrationActionsTypes'
import { ArbitrationActionsType } from '@modules/arbitration/actions/arbitrationActionsTypes'
import {
  CompartmentType,
  DispositifState,
  DivestedManagementSource,
  OperationDecisionState,
  PlanCompartmentsType,
  QuestionsState,
  ReallocationDivestmentState,
  ResponseType
} from '@modules/common/types'
import { TriggerThresholdProperties } from '@modules/refund/available-refund/types'

import {
  ArbitrationDivestmentValidationParamsType,
  ArbitrationOadRepartitionData,
  ArbitrationErrorType,
  ArbitrationState,
  DivestmentArbitrationPayloadParams,
  FetchCreateArbitrationAnswerData,
  OadAllocationsState,
  ReallocationChoiceType,
  GetRiskProfileParams,
  RiskProfileData,
} from '../types'
import { PieChartData } from '@ere-uilib/types'

//OAD
export function submitOadReallocationsRequest(): actions.SubmitOadReallocationsRequestAction {
  return {
    type: ArbitrationActionsType.SUBMIT_OAD_REALLOCATIONS_REQUEST
  }
}

export function submitOadReallocationsSuccess(
  data: FetchCreateArbitrationAnswerData
): actions.SubmitOadReallocationsSuccessAction {
  return {
    type: ArbitrationActionsType.SUBMIT_OAD_REALLOCATIONS_SUCCESS,
    data
  }
}

export function submitOadReallocationsFailure(
  error: ArbitrationErrorType
): actions.SubmitOadReallocationsFailureAction {
  return {
    type: ArbitrationActionsType.SUBMIT_OAD_REALLOCATIONS_FAILURE,
    error
  }
}

export function clearArbitrationData(): actions.ClearArbitrationDataAction {
  return {
    type: ArbitrationActionsType.CLEAR_ARBITRATION_DATA
  }
}

export function selectArbitrationTypeRequest(
  arbitrationType: ArbitrationTypes
): actions.SelectArbitrationTypeRequest {
  return {
    type: ArbitrationActionsType.SELECT_ARBITRATION_TYPE_REQUEST,
    arbitrationType
  }
}

export function selectArbitrationTypeSuccess(): actions.SelectArbitrationTypeSuccess {
  return {
    type: ArbitrationActionsType.SELECT_ARBITRATION_TYPE_SUCCESS
  }
}

export function selectArbitrationTypeFailure(
  error: ArbitrationErrorType
): actions.SelectArbitrationTypefailure {
  return {
    type: ArbitrationActionsType.SELECT_ARBITRATION_TYPE_FAILURE,
    error
  }
}
export function getArbitrationQuestionsRequest(): actions.GetArbitrationQuestionsRequestAction {
  return {
    type: ArbitrationActionsType.GET_ARBITRATION_QUESTIONS_REQUEST
  }
}

export function getArbitrationQuestionsSuccess(
  decision: OperationDecisionState & ReallocationChoiceType
): actions.GetArbitrationQuestionsSuccessAction {
  return {
    type: ArbitrationActionsType.GET_ARBITRATION_QUESTIONS_SUCCESS,
    decision
  }
}

export function getArbitrationQuestionsFailure(
  error: ArbitrationErrorType
): actions.GetArbitrationQuestionsFailureAction {
  return {
    type: ArbitrationActionsType.GET_ARBITRATION_QUESTIONS_FAILURE,
    error
  }
}

export function getOadAllocationsRequest(
  profile: string,
  horizon: string
): actions.GetOadAllocationsRequestAction {
  return {
    type: ArbitrationActionsType.GET_OAD_ALLOCATIONS_REQUEST,
    profile,
    horizon
  }
}

export function getOadAllocationsSuccess(
  arbitrationOadAllocations: OadAllocationsState[]
): actions.GetOadAllocationsSuccessAction {
  return {
    type: ArbitrationActionsType.GET_OAD_ALLOCATIONS_SUCCESS,
    arbitrationOadAllocations
  }
}

export function getOadAllocationsFailure(
  error: ArbitrationErrorType
): actions.GetOadAllocationsFailureAction {
  return {
    type: ArbitrationActionsType.GET_OAD_ALLOCATIONS_FAILURE,
    error
  }
}

export function ArbitrationQuestionsInitError(): actions.ArbitrationQuestionsInitErrorAction {
  return {
    type: ArbitrationActionsType.ARBITRATION_QUESTION_INIT_ERROR
  }
}

export function getDispositifDetailRequest(
  planId: string
): actions.GetDispositifDetailRequestAction {
  return {
    type: ArbitrationActionsType.GET_DISPOSITIF_DETAIL_REQUEST,
    planId
  }
}

export function getDispositifDetailSuccess(
  planCompartments: PlanCompartmentsType
): actions.GetDispositifDetailSuccessAction {
  return {
    type: ArbitrationActionsType.GET_DISPOSITIF_DETAIL_SUCCESS,
    planCompartments
  }
}

export function getDispositifDetailFailure(
  error: ArbitrationErrorType
): actions.GetDispositifDetailFailureAction {
  return {
    type: ArbitrationActionsType.GET_DISPOSITIF_DETAIL_FAILURE,
    error
  }
}

/// update question with set response

export function updateArbitrationQuestionWithResponseRequest(
  response: ResponseType,
  index: number,
  canNotBeAsked: boolean,
  OADDelayQuestion: QuestionsState,
  OADRiskQuestion: QuestionsState
): actions.UpdateArbitrationQuestionWithResponseRequest {
  return {
    type: ArbitrationActionsType.UPDATE_ARBITRATION_QUESTION_WITH_RESPONSE_REQUEST,
    response,
    index,
    canNotBeAsked,
    OADDelayQuestion,
    OADRiskQuestion
  }
}
export function updateArbitrationQuestionWithResponseSuccess(
  newDecision: ArbitrationState['decision']
): actions.UpdateArbitrationQuestionWithResponseSuccess {
  return {
    type: ArbitrationActionsType.UPDATE_ARBITRATION_QUESTION_WITH_RESPONSE_SUCCESS,
    newDecision
  }
}
export function updateArbitrationQuestionWithResponseFailure(
  error: ArbitrationErrorType
): actions.UpdateArbitrationQuestionWithResponseFailure {
  return {
    type: ArbitrationActionsType.UPDATE_ARBITRATION_QUESTION_WITH_RESPONSE_FAILURE,
    error
  }
}

export function arbitrationBackToQuestionRequest(index: number): actions.ArbitrationBackToQuestion {
  return {
    type: ArbitrationActionsType.ARBITRATION_BACK_TO_QUESTION,
    index
  }
}

export function selectArbitrationRepartitionManagement({
  selectState,
  compartmentId,
  managementId,
  amount
}: {
  selectState: boolean
  compartmentId: string
  managementId: string
  amount: number
}): actions.SelectArbitrationRepartitionManagementAction {
  return {
    type: ArbitrationActionsType.ARBITRATION_REPARTITION_SELECT_MANAGEMENT,
    selectState,
    compartmentId,
    managementId,
    amount
  }
}

// Arbitration Repartition select one support
export function selectArbitrationRepartitionSupport(
  params: ArbitrationDivestmentValidationParamsType
): actions.SelectArbitrationRepartitionSupportAction {
  return {
    type: ArbitrationActionsType.ARBITRATION_REPARTITION_SELECT_SUPPORT,
    params
  }
}

export function selectInvestedSupport(
  params: ArbitrationDivestmentValidationParamsType
): actions.SelectInvestedSupportAction {
  return {
    type: ArbitrationActionsType.ARBITRATION_INVESTED_SUPPORT,
    params
  }
}

export function selectInvestedManagement(
  params: ArbitrationDivestmentValidationParamsType
): actions.SelectInvestedManagementAction {
  return {
    type: ArbitrationActionsType.ARBITRATION_INVESTED_MANAGEMENT,
    params
  }
}

//TODO Type
export function selectArbitrationReinvestSupport(
  params: any
): actions.SelectArbitrationReinvestSupportAction {
  return {
    type: ArbitrationActionsType.ARBITRATION_REINVEST_SELECT_SUPPORT,
    params
  }
}

export function setDivestedManagamentSource(
  params: DivestedManagementSource
): actions.SetDivestedManagementSource {
  return {
    type: ArbitrationActionsType.SET_DIVESTED_MANAGEMENT_SOURCE,
    params
  }
}

export function setIsVisibleArbitrationDecisionSubmitModal(
  isVisible: boolean
): actions.SetIsVisibleSubmitModal {
  return {
    type: ArbitrationActionsType.SET_IS_VISIBLE_SUBMIT_MODAL,
    isVisible
  }
}

export function setSelectedDispositif(
  dispositif: DispositifState[]
): actions.SetSelectedDispositif {
  return {
    type: ArbitrationActionsType.SET_SELECTED_DISPOSITIF,
    dispositif
  }
}

export function setSelectedCompartment(
  compartment?: CompartmentType
): actions.SetSelectedCompartment {
  return {
    type: ArbitrationActionsType.SET_SELECTED_COMPARTMENT,
    compartment
  }
}

export function setArbitrationDesinvestAmountModalVisibleState(
  state: boolean
): actions.SetArbitrationDesinvestAmountModalVisibleStateAction {
  return {
    type: ArbitrationActionsType.SET_ARBITRATION_DESINVEST_AMOUNT_MODAL_VISIBLE_STATE,
    state
  }
}
export function getRealocationDivestmentRequest(
  DivestmentPayloadParams: DivestmentArbitrationPayloadParams,
  shouldNavigate: boolean = true
): actions.GetRealocationDivestmentRequestAction {
  return {
    type: ArbitrationActionsType.GET_REALOCATION_DIVESTMENT_REQUEST,
    DivestmentPayloadParams,
    shouldNavigate
  }
}

export function getRealocationDivestmentSuccess(
  reallocationDivested: ReallocationDivestmentState
): actions.GetRealocationDivestmentSuccessAction {
  return {
    type: ArbitrationActionsType.GET_REALOCATION_DIVESTMENT_SUCCESS,
    reallocationDivested
  }
}

export function getRealocationInvestFailure(
  error: ArbitrationErrorType
): actions.GetRealocationDivestmentFailureAction {
  return {
    type: ArbitrationActionsType.GET_REALOCATION_DIVESTMENT_FAILURE,
    error
  }
}
export function setArbitrationResinvestPerentageModalVisibleState(
  isVisibleState: boolean
): actions.SetArbitrationResinvestPercentageModalVisibleStateAction {
  return {
    type: ArbitrationActionsType.SET_ARBITRATION_REINVEST_PERCENTAGE_MODAL_VISIBLE_STATE,
    isVisibleState
  }
}

// create arbitration demand

export function createArbitrationRequest(): actions.CreateArbitrationRequestAction {
  return {
    type: ArbitrationActionsType.CREATE_ARBITRATION_REQUEST
  }
}

export function createArbitrationSuccess(
  arbitrationAnswerData: FetchCreateArbitrationAnswerData
): actions.CreateArbitrationSuccessAction {
  return {
    type: ArbitrationActionsType.CREATE_ARBITRATION_SUCCESS,
    arbitrationAnswerData
  }
}
export function setReallocationType(type: ArbitrationTypes): actions.SetReallocationTypeAction {
  return {
    type: ArbitrationActionsType.SET_REALLOCATION_TYPE,
    reallocationType: type
  }
}

export function createArbitrationFailure(
  error: ArbitrationErrorType
): actions.CreateArbitrationFailureAction {
  return {
    type: ArbitrationActionsType.CREATE_ARBITRATION_FAILURE,
    error
  }
}

export function setArbitrationTriggerThresholdModalVisibleState(
  state: boolean
): actions.SetArbitrationTriggerThresholdModalVisibleStateAction {
  return {
    type: ArbitrationActionsType.SET_ARBITRATION_TRIGGER_POINT_MODAL_VISIBLE_STATE,
    state
  }
}

export function setArbitrationSupportTriggerThreshold(
  params: ArbitrationDivestmentValidationParamsType,
  TriggerThreshold: TriggerThresholdProperties
): actions.SetArbitrationSupportTriggerThresholdAction {
  return {
    type: ArbitrationActionsType.SET_ARBITRATION_SUPPORT_TRIGGER_THRESHOLD,
    TriggerThreshold,
    params
  }
}

export function clearArbitrationSupportTriggerThreshold(
  params: ArbitrationDivestmentValidationParamsType
): actions.ClearArbitrationSupportTriggerThresholdAction {
  return {
    type: ArbitrationActionsType.CLEAR_ARBITRATION_SUPPORT_TRIGGER_THRESHOLD,
    params
  }
}
// GET RISK PROFILE
export function getRiskProfileRequest(
  params: GetRiskProfileParams
): actions.GetRiskProfileRequestAction {
  return {
    type: ArbitrationActionsType.GET_RISK_PROFILE_REQUEST,
    params
  }
}

export function getRiskProfileSuccess(
  riskProfile: RiskProfileData
): actions.GetRiskProfileSuccessAction {
  return {
    type: ArbitrationActionsType.GET_RISK_PROFILE_SUCCESS,
    riskProfile
  }
}

export function getRiskProfileFailure(
  error: ArbitrationErrorType
): actions.GetRiskProfileFailureAction {
  return {
    type: ArbitrationActionsType.GET_RISK_PROFILE_FAILURE,
    error
  }
}

export function setOadManualRepartitionData(
  arbitrationOadManualRepartitionData: ArbitrationOadRepartitionData[]
): actions.SetOadManualRepartitionDataAction {
  return {
    type: ArbitrationActionsType.SET_OAD_MANUAL_REPARTITION_DATA,
    arbitrationOadManualRepartitionData
  }
}

export function setOadRepartitionData(
  arbitrationOadRepartitionData: ArbitrationOadRepartitionData[]
): actions.SetOadRepartitionDataRequestAction {
  return {
    type: ArbitrationActionsType.SET_OAD_REPARTITION_DATA,
    arbitrationOadRepartitionData
  }
}

export function setOadSimulationData(
  arbitrationOadSimulationData: PieChartData[]
): actions.SetOadSimulationDataRequestAction {
  return {
    type: ArbitrationActionsType.SET_OAD_SIMULATION_DATA,
    arbitrationOadSimulationData
  }
}

// par echeance

export function setArbitrationDueDateAmountModalVisibleState(
  state: boolean
): actions.SetArbitrationDueDateAmountModalVisibleStateAction {
  return {
    type: ArbitrationActionsType.SET_ARBITRATION_DUE_DATE_AMOUNT_MODAL_VISIBLE_STATE,
    state
  }
}

export function selectArbitrationDueDateAmountFieldAmount(
  amount: number
): actions.SelectArbitrationDueDateAmountFieldAction {
  return {
    type: ArbitrationActionsType.SET_ARBITRATION_DUE_DATE_AMOUNT_FIELD_VALUE,
    dueDateAmountFieldValue: amount
  }
}