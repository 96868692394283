import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { View, Text as RNText } from 'react-native';

import {
  useTheme,
  Modal,
  ModalFrame,
  Title,
  Paragraph,
  HelpButton,
  SimpleButton,
  SquareIcon,
  DialOncePressable
} from '@components/index';
import { OtpChanelTypeEnum } from '@constants/index';
import { RouteNames } from '@constants/navigation';
import { IconEnum } from '@ere-uilib/enums';
import { useTranslation } from '@ere-uilib/translations';

import { Props } from './interfaces';
import { useStyles } from './useStyles';

export const OtpHelpModal: React.FC<React.PropsWithChildren<Props>> = ({
  setVisible,
  visible,
  email,
  channelType,
  integrationKey,
  targetedFlowId
}) => {
  const navigation = useNavigation();
  const { formatMessage } = useTranslation();
  const theme = useTheme();
  const styles = useStyles();

  // chanel labels definition
  function getCorrectLabels() {
    switch (channelType) {
      case OtpChanelTypeEnum.SMS_DOCAPOSTE:
      case OtpChanelTypeEnum.SMS:
        return {
          title: formatMessage({ id: 'sessionsecurity_help_mobile_aide' }),
          helpLabel: formatMessage({ id: 'sessionsecurity_help_mobile_label' }),
          lastLabel: formatMessage({ id: 'sessionsecurity_help_all_title' })
        };
      case OtpChanelTypeEnum.MAIL_DOCAPOSTE:
      case OtpChanelTypeEnum.MAIL:
        return {
          title: formatMessage({ id: 'sessionsecurity_help_email_aide' }),
          helpLabel: formatMessage({ id: 'sessionsecurity_help_email_label' }),
          lastLabel: formatMessage({ id: 'sessionsecurity_help_all_title' })
        };

      default:
        return {
          title: formatMessage({ id: 'sessionsecurity_help_all_title' }),
          helpLabel: formatMessage({ id: 'sessionsecurity_help_all_label' }),
          lastLabel: formatMessage({ id: 'sessionsecurity_help_all_title' })
        };
    }
  }

  const labels = getCorrectLabels();

  // chanel buttons renderer
  const renderEmailButton = () => {
    return (
      <SimpleButton
        containerStyle={{ marginBottom: 20 }}
        onPress={() => {
          navigation.navigate(RouteNames.BottomTabNavigator, {
            screen: RouteNames.ProfileStack,
            params: {
              screen: RouteNames.ProfileEditEmail
            }
          });
        }}
        title={
          <>
            <RNText>{formatMessage({ id: 'sessionsecurity_help_email_button' })}</RNText>
            {'\n'}
            <RNText>{email}</RNText>
          </>
        }
      />
    );
  };
  const renderPhoneButton = () => {
    return null;
    // TODO: phone edit page is not ready yet, reactivate button while page is ready
    // return (
    //   <SimpleButton
    //     containerStyle={{marginBottom: 20}}
    //     onPress={()=>{
    //       navigation?.navigate('Profile', {screen: 'ProfileEditPhone'});
    //     }}
    //     title={<>
    //       <RNText>{formatMessage({id: 'sessionsecurity_help_mobile_button'})}</RNText>
    //       {'\n'}
    //       <RNText>{phoneNumber}</RNText>
    //     </>}
    //   />
    // );
  };
  const renderButtons = () => {
    switch (channelType) {
      case OtpChanelTypeEnum.SMS:
        return renderPhoneButton();
      case OtpChanelTypeEnum.MAIL:
        return renderEmailButton();
      default:
        return (
          <>
            {renderPhoneButton()}
            {renderEmailButton()}
          </>
        );
    }
  };
  return (
    <Modal visible={visible}>
      <ModalFrame
        desktopPosition="center"
        mobilePosition="bottom"
        onClose={() => {
          setVisible(false);
        }}>
        <View style={styles.HelpModalGlobalContainerStyle}>
          <SquareIcon
            backgroundColor={theme.colors.notifications.tips.c100}
            iconColor={theme.colors.notifications.tips.c500}
            iconName={IconEnum.IDEA}
            iconSize={theme.metrics.iconSizes.m}
            style={{ container: styles?.iconContainerStyle }}
          />
          <Title
            style={styles.allTextsStyle}
            variant="t6">
            {labels.title}
          </Title>
          <Paragraph
            style={styles.allTextsStyle}
            variant="t3"
            weight="light">
            {labels.helpLabel}
          </Paragraph>
          {renderButtons()}
          <Paragraph
            style={styles.allTextsStyle}
            variant="t3">
            {labels.lastLabel}
          </Paragraph>
          <DialOncePressable
            integrationKey={integrationKey}
            targetedFlowId={targetedFlowId}>
            <HelpButton
              onPress={() => { }}
              title={formatMessage({ id: 'session_security_help_contact_button' })}
            />
          </DialOncePressable>
        </View>
      </ModalFrame>
    </Modal>
  );
};
