import axios from 'axios';

import { API_URL } from '@config/index';
import {
  FetchRetirementDecisionResponse,
  PostRetirementDecisionResponse,
  RetirementDecisionSubmitForm,
} from '@modules/retirement/types';
import { mockRetirementDecisions } from '@__mocks__/index';
/**
 *
 * @param companyId
 * @returns
 *
 * @mock
 * import { mockRetirementDecisions } from '@__mocks__/mockRetirementDecision';
 *
 * return new Promise((resolve, reject) => {
 *   setTimeout(() => {
 *     resolve({
 *       data: mockRetirementDecisions
 *     });
 *   }, 300);
 * });
 */
export async function fetchRetirementDecision(companyId: string): Promise<FetchRetirementDecisionResponse> {
  return axios.get(`${API_URL}/api/v1/companies/${companyId}/retirement`);
}

export async function postRetirementDecisions({
  formDecision,
  companyId
}: {
  formDecision: RetirementDecisionSubmitForm[]
  companyId: string;
}): Promise<PostRetirementDecisionResponse> { // @todo: typing unknow, to be done on hamed PR
  return axios.post(`${API_URL}/api/v1/companies/${companyId}/createretirement`, formDecision);
}