import { StackNavigationProp } from '@react-navigation/stack';
import React, { useCallback } from 'react';

import { RouteNames } from '@constants/navigation';
import { AppNavigatorInterface } from '@navigation/Interfaces';

import { LetterIdentifierFormPageContainer } from './LetterIdentifierForm.container';

interface Props {
  navigation: StackNavigationProp<AppNavigatorInterface>;
}

export function LetterIdentifierFormPage({ navigation }: Props) {
  const canGoBack = navigation.canGoBack();
  const handleClose = useCallback(() => {
    navigation.navigate(RouteNames.OidcLogoutCallbackRedirect);
  }, [navigation]);

  return (
    <LetterIdentifierFormPageContainer
      onClose={handleClose}
      onPressBackButton={canGoBack ? navigation.goBack : undefined}
    />
  );
}
