import React, { FC } from "react";
import {View} from "react-native";
import {BankStatusTypesEnum} from "@modules/bank-details/constants";
import {useStyles} from "@pages/BankDetails/BankSummaryPage/useStyles";
import {useTranslate} from "@pages/BankDetails/BankSummaryPage/hooks/useTranslate";
import {BankAccountStatus} from "@pages/BankDetails/BankSummaryPage/components/BankAccountStatus";
import {BankAccountEmpty} from "@pages/BankDetails/BankSummaryPage/components/BankAccountEmpty";
import {PlanFamillyList} from "@constants/common";
import {ProfileBankAccountCard} from "@ere-uilib/molecules";
import {useBankSummaryContext} from "@pages/BankDetails/BankSummaryPage/BankSummaryProvider";

export const BankAccountCardES: FC = () => {

    const styles= useStyles();
    const { dictionnary} = useTranslate();
    const {bankData} = useBankSummaryContext();

    const eligibleDispositifsES = bankData?.es?.planList && bankData?.es?.planList.join(', ');
    const debitBankAccountStatus = bankData?.es?.debitBankAccountDetails?.status;
    const transfertAccountStatus = bankData?.es?.transferBankAccountDetails?.status;
    const bankStatusTypes = [BankStatusTypesEnum.DEMANDE_INITIEE, BankStatusTypesEnum.DEMANDE_EN_COURS];
    const hasDebitBankAccount = (!!bankData?.es?.debitBankAccountDetails?.bankAccountIsSet) ||
        bankStatusTypes.some(item => debitBankAccountStatus?.includes(item));
    const hasTransfertBankAccount = (!!bankData?.es?.transferBankAccountDetails?.bankAccountIsSet) ||
        bankStatusTypes.some(item => transfertAccountStatus?.includes(item));

    return(<ProfileBankAccountCard
        cardTitle={dictionnary.CoordonneesBancairesRecapEpargneSalarialeESTitre}
        eligibleDispositifs={eligibleDispositifsES ?? ''}
        isES>
        <View style={styles.separator} />
        {hasDebitBankAccount &&
            <BankAccountStatus
                bankData={bankData?.es?.debitBankAccountDetails}
                planFamily={PlanFamillyList.ES}
                dispositifs={eligibleDispositifsES ?? ''}
                isRefund={false}
                selectedBankDataIndex={0}
                planId={""}
            />
        }
        {!hasDebitBankAccount &&
            <BankAccountEmpty
                planFamily={PlanFamillyList.ES}
                dispositifs={eligibleDispositifsES ?? ''}
                planId={""}/>
        }
        <View style={styles.separator} />
        {hasTransfertBankAccount &&

            <BankAccountStatus
                bankData={bankData?.es?.transferBankAccountDetails}
                planFamily={PlanFamillyList.ES}
                dispositifs={eligibleDispositifsES ?? ''}
                isRefund={true}
                selectedBankDataIndex={0}
                planId={""}/>
        }
        {!hasTransfertBankAccount &&
            <BankAccountEmpty
                planFamily={PlanFamillyList.ES}
                dispositifs={eligibleDispositifsES ?? ''}
                isRefund={true}
                planId={""}/>
        }
    </ProfileBankAccountCard>)
}
