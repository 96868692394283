import { StackNavigationProp } from '@react-navigation/stack';
import React from 'react';
import { View, StyleProp, ViewStyle } from 'react-native';

import {
  AccountTerms,
  OfflinePageLayout,
  useTheme,
  ThemeType,
  LegalMenuFooterConnected,
  useScreenSizes,
  NavigationHeader,
} from '@components/index';
import { createUseStyles } from '@ere-uilib/styles';

import { RouteNames } from '@constants/navigation';
import { useTranslation } from '@ere-uilib/translations';
import { AppNavigatorInterface } from '@navigation/Interfaces';
import { useLogoNavigation } from '@utils/index';

interface Props {
  navigation: StackNavigationProp<AppNavigatorInterface>;
  containerStyle?: StyleProp<ViewStyle>;
  contentStyle?: StyleProp<ViewStyle>;
}

type AccountTermsPageStyles = {
  containerStyle?: StyleProp<ViewStyle>;
  contentStyle?: StyleProp<ViewStyle>;
};

type StylesContext = { theme: ThemeType; isMobile: boolean; isTablet: boolean };

export const AccountTermsPage: React.FC<React.PropsWithChildren<Props>> = ({
  navigation,
  contentStyle,
  containerStyle,
}) => {
  const { formatMessage, getMessageRaw } = useTranslation();
  const theme = useTheme();
  const { isMobile, isTablet, isDesktop } = useScreenSizes();
  const styles = useStyles({ theme, isMobile, isTablet }, { containerStyle, contentStyle });
  const { logoNavigateTo } = useLogoNavigation();

  const onAccountTermsButtonPress = () => navigation.navigate(RouteNames.RelationshipEntringProcess, {
    screen: RouteNames.CompanyBenefits,
  });

  const renderLegalMenuFooter = ()=>{
    return <LegalMenuFooterConnected isWhite={isDesktop}/>;
  };

  const renderMobileHeader = ()=>{
    return <NavigationHeader
      displayBackButton
      helpPoint
      helpPointContentHtml={getMessageRaw({ id: 'eer_info_content' })}
      helpPointModalTitle={formatMessage({ id: 'eer_info_title' })}
      helpPointPosition="right"
      onGoBack={() => navigation?.goBack()}
      title={formatMessage({ id: 'validate-inscription_step3_sub-label_cgu' })} />;
  };

  return (
    <OfflinePageLayout
      displayLogo={!(isMobile || isTablet)}
      onLogoPress={logoNavigateTo}
      renderDesktopCardBelowContent={renderLegalMenuFooter}
      renderMobileHeaderContent={renderMobileHeader}
      renderMobileStickyGradientFooterContent={renderLegalMenuFooter}
    >
      <View style={styles.contentStyle}>
        <AccountTerms onButtonPress={onAccountTermsButtonPress} />
      </View>
    </OfflinePageLayout>
  );
};

const getStyles = (
  context?: StylesContext,
  style?: AccountTermsPageStyles,
): AccountTermsPageStyles => ({
  contentStyle: [
    !(context?.isMobile || context?.isTablet)
      ? {
        flex: 2.1,
        borderTopRightRadius: context?.theme.metrics.spacing.xm,
        borderBottomRightRadius: context?.theme.metrics.spacing.xm,
        padding: context?.theme.metrics.spacing.xxl,
        paddingTop: context?.theme.metrics.spacing.huge,
      }
      : { flex: 1, flexGrow: 1, padding: context?.theme.metrics.spacing.xs },
    style?.contentStyle,
  ],
});

const useStyles = createUseStyles(getStyles);
