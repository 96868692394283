import { AccountsErrorState } from "@modules/dashboard/types";
import {
  ApplicationSettingsReceivedState,
  MobileMenuListState,
} from '@modules/settings/types';

import { InitAllErrorsAction } from '@modules/error/actions/errorActionsTypes';

export enum SettingsActionsType {
  APPLICATION_SETTINGS_INIT_ERROR = 'APPLICATION_SETTINGS_INIT_ERROR',
  GET_APPLICATION_SETTINGS_REQUEST = 'GET_APPLICATION_SETTINGS_REQUEST',
  GET_APPLICATION_SETTINGS_SUCCESS = 'GET_APPLICATION_SETTINGS_SUCCESS',
  GET_APPLICATION_SETTINGS_FAILURE = 'GET_APPLICATION_SETTINGS_FAILURE',
  MOBILE_MENU_LIST_INIT_ERROR = 'MOBILE_MENU_LIST_INIT_ERROR',
  GET_MOBILE_MENU_LIST_REQUEST = 'GET_MOBILE_MENU_LIST_REQUEST',
  GET_MOBILE_MENU_LIST_SUCCESS = 'GET_MOBILE_MENU_LIST_SUCCESS',
  GET_MOBILE_MENU_LIST_FAILURE = 'GET_MOBILE_MENU_LIST_FAILURE',
  SET_EXTERNAL_CLIENT_ID = 'SET_EXTERNAL_CLIENT_ID',
  SET_ATINTERNET_CLIENT_ID = 'SET_ATINTERNET_CLIENT_ID',
  SET_MAINTENANCE_CONTENT = 'SET_MAINTENANCE_CONTENT'
}

// APPLICATION SETTINGS
export interface SetAtInternetClientIdAction {
  type: SettingsActionsType.SET_ATINTERNET_CLIENT_ID;
  id: string
}
export interface SetExternalClientIDAction {
  type: SettingsActionsType.SET_EXTERNAL_CLIENT_ID;
  externalClientID: string
}

export interface ApplicationSettingsInitErrorAction {
  type: SettingsActionsType.APPLICATION_SETTINGS_INIT_ERROR;
}

export interface GetApplicationSettingsRequestAction {
  type: SettingsActionsType.GET_APPLICATION_SETTINGS_REQUEST;
}


export interface GetApplicationSettingsSuccessAction {
  type: SettingsActionsType.GET_APPLICATION_SETTINGS_SUCCESS;
  applicationSettings: ApplicationSettingsReceivedState;
}

export interface GetApplicationSettingsFailureAction {
  type: SettingsActionsType.GET_APPLICATION_SETTINGS_FAILURE;
  error: AccountsErrorState;
}

// MOBILE MENU LIST
export interface MobileMenuListInitErrorAction {
  type: SettingsActionsType.MOBILE_MENU_LIST_INIT_ERROR;
}

export interface GetMobileMenuListRequestActions {
  type: SettingsActionsType.GET_MOBILE_MENU_LIST_REQUEST;
  companyId: string;
}

export interface GetMobileMenuListSuccessAction {
  type: SettingsActionsType.GET_MOBILE_MENU_LIST_SUCCESS;
  mobileMenuList: MobileMenuListState[];
}

export interface GetMobileMenuListFailureAction {
  type: SettingsActionsType.GET_MOBILE_MENU_LIST_FAILURE;
  error: AccountsErrorState;
}

export interface SetMaintenanceContentAction {
  type: SettingsActionsType.SET_MAINTENANCE_CONTENT;
  maintenanceContent: string;
}

export type SettingsAction =
  | GetApplicationSettingsRequestAction
  | GetApplicationSettingsSuccessAction
  | GetApplicationSettingsFailureAction
  | ApplicationSettingsInitErrorAction
  | GetMobileMenuListRequestActions
  | GetMobileMenuListSuccessAction
  | GetMobileMenuListFailureAction
  | MobileMenuListInitErrorAction
  | InitAllErrorsAction
  | SetExternalClientIDAction
  | SetAtInternetClientIdAction
  | SetMaintenanceContentAction
