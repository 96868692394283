import { useNavigation } from "@react-navigation/native";
import React, { FC, useCallback, useMemo } from 'react';
import { View } from 'react-native';

import { locator } from "@constants/locator";
import { RouteNames } from "@constants/navigation";
import { FontIcon, Text } from '@ere-uilib/atoms';
import { Gauge, SimpleButton } from '@ere-uilib/molecules';
import { useScreenSizes, useTheme } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';

import { DashboardContributionCardContentProps } from './interface';
import { useStyles } from './useStyles';

export const DashboardContributionCardContent: FC<React.PropsWithChildren<DashboardContributionCardContentProps>> = ({
  description,
  buttonText,
  contributionData
}) => {
  const { formatCurrencyNumber, formatMessage } = useTranslation();
  const theme = useTheme();
  const { isMobile } = useScreenSizes();
  const styles = useStyles();
  const navigation = useNavigation();

  const handleOpenContributionModal = useCallback(() => {
    navigation.navigate(RouteNames.ContributionModal);
  }, [navigation]);

  const ButtonContribution = useMemo(
    () => (
      <SimpleButton
        containerStyle={styles.button}
        onPress={handleOpenContributionModal}
        title={buttonText} />
    ),
    [buttonText, handleOpenContributionModal, styles]
  );

  const LeftCard = useMemo(
    () => (
      <View style={styles.leftContainer}>
        <Text
          testId={locator._dashboard._my_employer_top._description}
          variant="t3"
          weight="light">
          {description}
        </Text>

        {!isMobile && ButtonContribution}
      </View>
    ),
    [styles, description, isMobile, ButtonContribution]
  );

  const RightCard = useMemo(() => {
    const gaugeValue = (contributionData?.totalConsumed || 0) / (contributionData?.totalLimit || 1);

    return <View style={styles.rightContainer}>
      <View style={styles.contentContainer}>
        <FontIcon
          color={theme.colors.basics.black}
          name="abondement"
          size={theme.metrics.iconSizes.xl}
          style={styles.iconStyle}
        />

        <View style={styles.abondementContainer}>
          <View style={styles.abondementPart}>
            <Text
              variant="t3"
              weight="light">
              {formatMessage({ id: 'DashboardCardContributionAmountReceived' })}
            </Text>
            <Text
              variant="t1"
              weight="bold">
              {formatCurrencyNumber({ value: contributionData?.totalConsumed || 0 })}
            </Text>
          </View>

          <View style={styles.abondementMiddleBar}></View>

          <View style={styles.abondementPart}>
            <Text
              variant="t3"
              weight="light">
              {formatMessage({ id: 'DashboardCardContributionAmountAvailable' })}
            </Text>
            <Text
              variant="t1"
              weight="bold">
              {formatCurrencyNumber({ value: contributionData?.totalBalanced || 0 })}
            </Text>
          </View>
        </View>

        <Text
          variant="t4"
          weight="light">
          {formatMessage({ id: 'DashboardCardContributionMaximumGross' }) +
            formatCurrencyNumber({ value: contributionData?.totalLimit || 0 })}
        </Text>
      </View>

      <Gauge
        size={300}
        value={gaugeValue}
      />
    </View>;
  }, [contributionData, formatCurrencyNumber, formatMessage, styles, theme]);

  return (<View style={styles.mainContainer}>
    {!isMobile && LeftCard}
    {RightCard}

    {isMobile && ButtonContribution}
  </View>);
};
