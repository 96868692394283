import { useNavigation } from '@react-navigation/native';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { KeyboardAvoidingView, Platform } from 'react-native';

import {
  ColumnsContainer,
  FiletStepperQuestionAnswerWrapper,
  useTranslation
} from '@components/ERE-UILib';
import { DashboardPageWrapperConnected } from '@components/ERE360Components';
import { NavigationHeader } from '@ere-uilib/molecules';

import { AnnuityResult } from '../components/AnnuityResults';
import { AnnuityStepperLoader } from '../components/AnnuityStepperLoader';
import { RENTE_GUARANTEED_ANNUITIES_DEFAULT, RENTE_REVERSION_RATES_DEFAULT } from '../constants';
import { getQuestionsAndAnswers, hasChanged } from '../utils';
import { AnnuitySimulatorProps, OnUpdateDataParams } from './interfaces';
import { Data, Gender, QuestionAnsweredStatus, SimulationTypeOperation } from './sharedInterfaces';
import { useAnnuitySimulatorStepper } from './useAnnuitySimulatorStepper';
import { useAnnuitySimulatorValidator } from './useAnnuitySimulatorValidator';
import { useStyles } from './useStyles';

type AnnuityResultProps = React.ComponentPropsWithoutRef<typeof AnnuityResult>;
type AnnuityResultOnSubmit = AnnuityResultProps['onSubmit'];

const initialData: Data = {
  gender: undefined,
  birthDate: undefined,
  departureAge: undefined,
  simulationType: undefined,
  reversion: undefined
};

const initialQuestionAnsweredStatus: QuestionAnsweredStatus = {
  gender: false,
  birthDate: false,
  departureAge: false,
  simulationType: false,
  reversion: false
};

const orders: (keyof Data)[] = [
  'gender',
  'birthDate',
  'departureAge',
  'simulationType',
  'reversion'
];

export const AnnuitySimulatorComponent: React.FC<React.PropsWithChildren<AnnuitySimulatorProps>> = ({
  settings,
  profileUserInfos,
  resultsData,
  isLoadingProfileUserInfos,
  isLoadingSimulationAnnuityData,
  onSetSimulationAnnuityData
}) => {
  const styles = useStyles();
  const { formatMessage, getMessageRaw } = useTranslation();
  const navigation = useNavigation();

  const { validateBirthDate, validateAmount } = useAnnuitySimulatorValidator(settings);

  const [data, setData] = useState<Data>(() => {
    let birthDate =
      (profileUserInfos.birthDate && new Date(profileUserInfos.birthDate)) || undefined;
    if (birthDate && !validateBirthDate(birthDate)) birthDate = undefined;

    let gender = profileUserInfos.gender as Gender | undefined;
    if (gender && !Object.values(Gender).includes(gender)) gender = undefined;

    return {
      ...initialData,
      birthDate,
      gender
    };
  });

  const [questionAnsweredStatus, setQuestionAnsweredStatus] = useState<QuestionAnsweredStatus>(
    initialQuestionAnsweredStatus
  );

  const close = useCallback(() => navigation.goBack(), [navigation]);

  const onUpdateData = useCallback(({ key, value }: OnUpdateDataParams) => {
    setData(prevData => {
      const changed = hasChanged<Data[keyof Data]>(prevData[key], value);

      if (changed) {
        const currentIndex = orders.indexOf(key);
        const nextKeys = [...orders].splice(currentIndex + 1);
        const nextData = nextKeys.reduce<Partial<Data>>(
          (acc, k) => ({ ...acc, [k]: initialData[k] }),
          {}
        );
        return {
          ...prevData,
          ...nextData,
          [key]: value
        };
      }

      setQuestionAnsweredStatus(prevStatus => ({ ...prevStatus, [key]: true }));
      return prevData;
    });
  }, []);

  const onSubmit = useCallback<AnnuityResultOnSubmit>(
    value => {
      onSetSimulationAnnuityData({
        birthDate: data.birthDate?.toISOString() ?? '',
        age: +(data.departureAge || 0),
        gender: (data.gender as string) ?? '',
        ...(data.simulationType?.operation === SimulationTypeOperation.AnnuityToCapital
          ? { monthlyPayment: +(value.amount || 0) }
          : { currentSavings: +(value.amount || 0) }),
        guaranteedAnnuities: value.guaranteedAnnuities,
        reversion:
          (data.reversion &&
            value.reversionRate && {
            recipientGender: (data.reversion.beneficiary?.gender as string) ?? '',
            recipientBirthDate: data.reversion.beneficiary?.birthDate?.toISOString() ?? '',
            rate: value.reversionRate
          }) ||
          undefined
      });
    },
    [data, onSetSimulationAnnuityData]
  );

  const renderHeader = useCallback(
    () => (
      <NavigationHeader
        displayCloseButton
        helpPoint
        helpPointContentHtml={getMessageRaw({
          id: 'PensionSimulatorTitleHelpHeaderBody'
        })}
        helpPointModalTitle={formatMessage({ id: 'PensionSimulatorTitleHelpHeader' })}
        onClose={close}
        title={formatMessage({ id: 'PensionSimulatorTitle' })}
      />
    ),
    [formatMessage, close]
  );

  const { questions, answers } = useMemo(
    () => getQuestionsAndAnswers(orders, questionAnsweredStatus),
    [questionAnsweredStatus]
  );

  const isAllAnswered = !questions.length;

  const stepper = useAnnuitySimulatorStepper({
    onUpdateData,
    settings
  });

  useEffect(() => {
    const nextStatus = Object.keys(data).reduce<QuestionAnsweredStatus>(
      (acc, key) => ({ ...acc, [key]: !!data[key as keyof Data] }),
      initialQuestionAnsweredStatus
    );
    setQuestionAnsweredStatus(nextStatus);
  }, [data]);

  if (isLoadingProfileUserInfos)
    return (
      <DashboardPageWrapperConnected>
        <ColumnsContainer renderHeader={renderHeader}>
          <AnnuityStepperLoader />
        </ColumnsContainer>
      </DashboardPageWrapperConnected>
    );

  return (
    <KeyboardAvoidingView
      behavior="position"
      enabled={orders.indexOf(questions[0]) > 2}
      keyboardVerticalOffset={Platform.select({ ios: -35, android: -25 })}
      style={styles.keyboardAvoidingView}>
      <DashboardPageWrapperConnected>
        <ColumnsContainer
          hasColumnCenter
          hasRenderRightDesktopColumn={false}
          renderHeader={renderHeader}>
          {answers.map((key, index, array) => {
            const answer = stepper[key];
            return (
              <FiletStepperQuestionAnswerWrapper
                {...answer}
                disableLine={index >= array.length - 1 && !questions.length}
                isEditing={false}
                onEdit={() =>
                  setQuestionAnsweredStatus(prev => ({
                    ...prev,
                    [key]: false
                  }))
                }
                value={answer.formatValue?.(data)}>
                {answer.renderChildren(data)}
              </FiletStepperQuestionAnswerWrapper>
            );
          })}
          {!isAllAnswered && (
            <FiletStepperQuestionAnswerWrapper
              {...stepper[questions[0]]}
              childrenContainerStyle={{ zIndex: 99 }}
              disableLine
              isEditing
              value={stepper[questions[0]].formatValue?.(data)}>
              {stepper[questions[0]].renderChildren(data)}
            </FiletStepperQuestionAnswerWrapper>
          )}
          {isAllAnswered && (
            <>
              <AnnuityResult
                guaranteedAnnuitiesList={
                  settings.RenteGuaranteedAnnuities || RENTE_GUARANTEED_ANNUITIES_DEFAULT
                }
                isLoading={isLoadingSimulationAnnuityData}
                onSubmit={onSubmit}
                resultsData={resultsData}
                reversionRate={data.reversion?.rate}
                reversionRatesList={settings.RenteReversionRates || RENTE_REVERSION_RATES_DEFAULT}
                simulationType={data.simulationType}
                validator={validateAmount}
              />
            </>
          )}
        </ColumnsContainer>
      </DashboardPageWrapperConnected>
    </KeyboardAvoidingView>
  );
};
