import { useNavigation } from '@react-navigation/native';
import React, { useCallback } from 'react';

import { RouteNames } from '@constants/navigation';
import { StepperHeader } from '@ere-uilib/molecules';
import { HelpPointPositiontype } from '@ere-uilib/molecules/headers/NavigationHeader/interface';
import { StepperItemType } from '@ere-uilib/molecules/steppers/InstallmentsStepper/interfaces';
import { CommonRefundActionsType } from '@modules/refund/types';

interface Props {
  displayCloseButton: boolean
  displayBackButton?: boolean
  helpPointContentHtml?: string
  helpPointModalTitle?: string
  helpPointPosition?: HelpPointPositiontype
  onClose?: () => void
  title: string
  stepperItems?: StepperItemType[]
  helpPoint: boolean
  onGoBack?: () => void
}

export const RefundsHeader: React.FC<React.PropsWithChildren<Props>> = ({
  onClose,
  title,
  displayCloseButton,
  helpPointModalTitle,
  helpPointContentHtml,
  helpPointPosition,
  stepperItems,
  helpPoint,
  displayBackButton = false,
  onGoBack
}) => {
  const navigation = useNavigation();

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();

      return;
    }

    navigation.navigate(RouteNames.RefundsStack, {
      screen: RouteNames.RefundModal,
      params: {
        clearAction: CommonRefundActionsType.CLEAR_REFUND_DATA,
      },
    });
  }, [navigation, onClose]);

  return (
    <StepperHeader
      displayBackButton={displayBackButton}
      displayCloseButton={displayCloseButton}
      helpPoint={helpPoint}
      helpPointContentHtml={helpPointContentHtml}
      helpPointModalTitle={helpPointModalTitle}
      helpPointPosition={helpPointPosition}
      onClose={handleClose}
      onGoBack={onGoBack}
      stepperItems={stepperItems}
      title={title}
    />);
};
