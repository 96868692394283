import {
  RefundRepartitionState,
  RefundAmountValidationParamsType,
  RepaymentDemandResponseState,
  TriggerThresholdProperties
} from '@modules/refund/available-refund/types'
import { RefundErrorState } from '@modules/refund/types'

export enum RefundActionsType {
  REFUND_REPARTITION_SELECT_ALL_SUPPORT = 'REFUND_REPARTITION_SELECT_ALL_SUPPORT',
  REFUND_REPARTITION_SELECT_SUPPORT = 'REFUND_REPARTITION_SELECT_SUPPORT',
  GET_REFUND_REPARTITION_REQUEST = 'GET_REFUND_REPARTITION_REQUEST',
  GET_REFUND_REPARTITION_SUCCESS = 'GET_REFUND_REPARTITION_SUCCESS',
  GET_REFUND_REPARTITION_FAILURE = 'GET_REFUND_REPARTITION_FAILURE',
  SET_REFUND_TRIGGER_POINT_MODAL_VISIBLE_STATE = 'SET_REFUND_TRIGGER_POINT_MODAL_VISIBLE_STATE',
  SET_SUPPORT_TRIGGER_POINT_AND_FORMAT_REPARTITION = 'SET_SUPPORT_TRIGGER_POINT_AND_FORMAT_REPARTITION',
  CLEAR_SUPPORT_TRIGGER_THRESHOLD = 'CLEAR_SUPPORT_TRIGGER_THRESHOLD',
  SET_REFUND_REPARTITION_AMOUNT = 'SET_REFUND_REPARTITION_AMOUNT',
  SET_REFUND_REPARTITION_AMOUNT_MODAL_VISIBLE_STATE = 'SET_REFUND_REPARTITION_AMOUNT_MODAL_VISIBLE_STATE',
  CREATE_REPAYMENT_DEMAND_REQUEST = 'CREATE_REPAYMENT_DEMAND_REQUEST',
  CREATE_REPAYMENT_DEMAND_SUCCESS = 'CREATE_REPAYMENT_DEMAND_SUCCESS',
  CREATE_REPAYMENT_DEMAND_FAILURE = 'CREATE_REPAYMENT_DEMAND_FAILURE'
}

// Refund Repartition select all support

export interface SelectRefundRepartitionAllSupportAction {
  type: RefundActionsType.REFUND_REPARTITION_SELECT_ALL_SUPPORT
  selectState: boolean
}

// Refund Repartition select one support

export interface SelectRefundRepartitionSupportAction {
  type: RefundActionsType.REFUND_REPARTITION_SELECT_SUPPORT
  selectState: boolean
  amount?: number
  compartmentId: string
  managementId: string
  isinCode: string
}

// Get Refund repartition

export interface GetRefundRepartitionRequestAction {
  type: RefundActionsType.GET_REFUND_REPARTITION_REQUEST
}

export interface GetRefundRepartitionSuccessAction {
  type: RefundActionsType.GET_REFUND_REPARTITION_SUCCESS
  repartition: RefundRepartitionState
}

export interface GetRefundRepartitionFailureAction {
  type: RefundActionsType.GET_REFUND_REPARTITION_FAILURE
  error: RefundErrorState
}

export interface SetRefundTriggerThresholdModalVisibleStateAction {
  type: RefundActionsType.SET_REFUND_TRIGGER_POINT_MODAL_VISIBLE_STATE
  state: boolean
}

export interface SetSupportTriggerThresholdAndFormatRepartition {
  type: RefundActionsType.SET_SUPPORT_TRIGGER_POINT_AND_FORMAT_REPARTITION
  compartmentId: string
  managementId: string
  supportIsInCode: string
  triggerPointData: TriggerThresholdProperties
}

export interface ClearSupportTriggerThreshold {
  type: RefundActionsType.CLEAR_SUPPORT_TRIGGER_THRESHOLD
  compartmentId: string
  managementId: string
  supportIsInCode: string
}

// Refund Repartition amounts
export interface SetRefundRepartitionAmountAction {
  type: RefundActionsType.SET_REFUND_REPARTITION_AMOUNT
  params: RefundAmountValidationParamsType
}

export interface SetRefundRepartitionAmountModalVisibleStateAction {
  type: RefundActionsType.SET_REFUND_REPARTITION_AMOUNT_MODAL_VISIBLE_STATE
  state: boolean
}

// create repayment demand

export interface CreateRepaymentRequestAction {
  type: RefundActionsType.CREATE_REPAYMENT_DEMAND_REQUEST
}

export interface CreateRepaymentSuccessAction {
  type: RefundActionsType.CREATE_REPAYMENT_DEMAND_SUCCESS
  repaymentDemandResponse: RepaymentDemandResponseState
}

export interface CreateRepaymentFailureAction {
  type: RefundActionsType.CREATE_REPAYMENT_DEMAND_FAILURE
  error: RefundErrorState
}

export type RefundAction =
  | GetRefundRepartitionRequestAction
  | GetRefundRepartitionSuccessAction
  | GetRefundRepartitionFailureAction
  | SetRefundRepartitionAmountAction
  | SetRefundRepartitionAmountModalVisibleStateAction
  | SetRefundTriggerThresholdModalVisibleStateAction
  | SetSupportTriggerThresholdAndFormatRepartition
  | ClearSupportTriggerThreshold
  | SelectRefundRepartitionAllSupportAction
  | SelectRefundRepartitionSupportAction
  | CreateRepaymentSuccessAction
  | CreateRepaymentRequestAction
  | CreateRepaymentFailureAction
