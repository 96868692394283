import { createRef, RefObject, useCallback, useMemo } from 'react'

const useDynamicRefs = () => {
    const map = useMemo<Map<string, RefObject<unknown>>>(() => new Map(), [])

    const getRef = useCallback((id: string) => map.get(id), [map])

    const setRef = useCallback(
      <T = unknown>(id: string) => {
        const ref = getRef(id) || createRef<T>()
        map.set(id, ref)
        return ref
      },
      [getRef, map]
    )

    return [getRef, setRef]
}

export default useDynamicRefs
