import { ErrorItemState } from '@modules/error/types';
import { AppState } from '@modules/reducers';

import { ControlDataActionsType } from './actions/controlDataActionsTypes';
import { FetchControlDataState } from './types';

export const getControlData = (state: AppState): FetchControlDataState[] =>
  state.controlData.data;

export const getControlDataError = (state: AppState) => state.error[ControlDataActionsType.GET_CONTROL_DATA_REQUEST];

export const getControlDataEditingOptions = (state: AppState) => state.controlData.editingOptions;

export const getControlDataEditingOptionsError = (state: AppState): ErrorItemState =>
  state.error[ControlDataActionsType.GET_CONTROL_DATA_EDITING_OPTIONS_REQUEST];

export const postDrivingDataError = (state: AppState): ErrorItemState =>
  state.error[ControlDataActionsType.POST_CONTROL_DATA_EDITING_OPTIONS_REQUEST];

export const isPostEditionDataSuccess = (state: AppState): boolean => state?.controlData.isEditedDrivingDataSuccess

