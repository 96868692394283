
import React from 'react';
import { connect } from 'react-redux';

import { AppState } from '@modules/index';
import {
  getMenuFooter
} from '@modules/legal/selectors';
import { getApplicationSettings, getMobileMenuList } from '@modules/settings/selectors';

import { MobileMenuModalConnectedComponent } from './MobileMenuModalConnected.component';

const mapStateToProps = (state: AppState) => {
  return {
    applicationSettings: getApplicationSettings(state),
    links: getMenuFooter(state),
    mobileMenuList: getMobileMenuList(state),
    isMobileMenuVisible: false
  };
};

const mapDispatchToProps = {};

export const MobileMenuModalConnected =  connect(mapStateToProps, mapDispatchToProps)(MobileMenuModalConnectedComponent);
