import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useScreenSizes, useTheme } from '@ere-uilib/styles';

export const useStyles = () => {
  const theme = useTheme();
  const { isMobile } = useScreenSizes();
  const { containerSpacing } = useMemo(
    () => ({ containerSpacing: theme.metrics.spacing[isMobile ? 's' : 'm'] }),
    [isMobile, theme]
  );

  const styles = useMemo(
    () =>
      StyleSheet.create({
        topLevelContainerStyle: {
          flexDirection: 'row',
          gap: theme.metrics.spacing.s,
          backgroundColor: theme.colors.basics.white,
          padding: containerSpacing
        },
        containerShadow: {
          backgroundColor: theme.colors.basics.white,
          shadowColor: 'rgba(96, 97, 112, 0.2)',
          shadowOffset: {
            width: 0,
            height: 6
          },
          shadowOpacity: 1,
          shadowRadius: 15,
          elevation: 6,
          zIndex: 1
        },
        modalContainerStyle: {
          backgroundColor: theme.colors.basics.white,
          flexDirection: 'row',
          padding: theme.metrics.spacing.m
        },
        amountStyle: {
          fontSize: 30,
        },
        centreContainerStyle: {
          flexGrow: 1,
          justifyContent: 'center',
          ...(!isMobile && { alignItems: 'center' })
        },
        contentStyle: {
          display: 'flex',
          flex: 1
        },
        selectionTitle: {
          textAlign: 'center',
          marginBottom: theme.metrics.spacing.xm
        },
        companySelectionButtonStyle: {
          flexDirection: 'row',
          flexShrink: 1,
          alignItems: 'center',
          minHeight: 30,
          maxHeight: 30,
          paddingVertical: theme.metrics.spacing.none,
          ...(!isMobile && {
            maxWidth: '30%',
            position: 'absolute',
            top: containerSpacing,
            right: containerSpacing
          })
        },
        activableButtonSetStyle: {
          marginBottom: theme.metrics.spacing.xm
        },
        buttonRightIcon: {
          color: theme.colors.buttons.classic.c500
        }
      }),
    [containerSpacing, isMobile, theme]
  );

  return styles;
};
