import React from 'react';
import { TouchableOpacity, View } from 'react-native';

import {
  convertFileSize,
  removeFileNameExtension,
  Spinner,
  useTranslation
} from '@components/ERE-UILib';
import { FontIcon, SVGLocalLoader, Text, Title } from '@ere-uilib/atoms';
import { IconEnum } from '@ere-uilib/enums';
import { useTheme } from '@ere-uilib/styles';

import { FilePreviewCardProps } from './interfaces';
import { useStyles } from './useStyles';

export const FilePreviewCard: React.FC<React.PropsWithChildren<FilePreviewCardProps>> = ({
  data,
  isRemoving = false,
  onRemove,
  containerStyle
}) => {
  const { formatMessage, formatNumber } = useTranslation();
  const theme = useTheme();
  const styles = useStyles();

  return (
    <View style={[styles.cardContainer, containerStyle]}>
      <View style={styles.documentIconContainer}>
        <SVGLocalLoader
          containerStyle={styles.documentIcon}
          name="uploadOk"
        />
        <Text
          numberOfLines={1}
          variant="t5"
          weight="light">
          {formatMessage({
            id: 'CoordonneesBancairesUploadTailleFichierOk',
            values: { filesize: formatNumber({ value: convertFileSize(data?.size, 'KB') }) }
          })}
        </Text>
      </View>
      <View style={styles.fileNameContainer}>
        <Title
          numberOfLines={2}
          variant="t7"
          weight="regular">
          {removeFileNameExtension(data?.name)}
        </Title>
      </View>
      <View>
        <TouchableOpacity
          disabled={isRemoving}
          onPress={onRemove}
          style={styles.closeIcon}>
          {isRemoving && (
            <Spinner
              color={theme.colors.basics.white}
              size={theme.metrics.iconSizes.s}
            />
          )}
          {!isRemoving && (
            <FontIcon
              color={theme.colors.basics.white}
              name={IconEnum.CLOSE}
              size={theme.metrics.iconSizes.xxs}
            />
          )}
        </TouchableOpacity>
      </View>
    </View>
  );
};
