import { Platform } from 'react-native';

import { REACT_APP_PACKAGE_IDENTIFIER_ } from '@config/index';
import { REGEX } from '@constants/regex';
import { OCDState } from '@modules/dashboard/types';
import { NewsFeedTypeState } from '@modules/history/types';

export const formatHref = (htmlContent: string) => {
  return htmlContent.replace(new RegExp(REGEX.HREFURL, 'g'), (match: string) => {
    const href = match.substring(0, match.length - 1);
    return `${href}${REACT_APP_PACKAGE_IDENTIFIER_}://`;
  });
};

export const formmatNewsFeedOCD = (newsFeedOCD: NewsFeedTypeState[]) => {
  return newsFeedOCD.map(item => {
    if (REGEX.HREFURL.test(item.content) && Platform.OS !== 'web') {
      return { ...item, content: formatHref(item.content) };
    }
    return item;
  });
};
export const formatOCDhref = (OCD: OCDState[]) => {
  return OCD.map(item => {
    if (REGEX.HREFURL.test(item.contenu) && Platform.OS !== 'web') {
      return { ...item, contenu: formatHref(item.contenu) };
    }
    return item;
  });
};

export const isDownloadLink = (href: string) => href.includes('telechargement');
