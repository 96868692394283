import { NavigationHeaderStylesContext, NavigationHeaderStyles } from './interface';

export const getStyles = (
  context?: NavigationHeaderStylesContext,
  style?: NavigationHeaderStyles,
): NavigationHeaderStyles => ({
  containerStyle: [
    {
      borderBottomWidth: 1,
      borderBottomColor: context?.theme.colors.basics.grey.c200
    },
    style?.containerStyle
  ],
  headerLine: [
    {
      height: 54,
      flexDirection: 'row',
      alignItems: 'center',
      position: 'relative',
      backgroundColor: context?.theme.colors.basics.white
    },
    style?.headerLine
  ],
  titleContainerStyle: [
    {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative'
    },
    style?.titleContainerStyle
  ],
  titleTextStyle: [
    {
      textAlign: 'center'
    },
    style?.titleTextStyle
  ],
  leftContainerStyle: [
    {
      flexDirection: 'row',
      alignItems: 'center'
    },

    style?.leftContainerStyle
  ],
  rightContainerStyle: [
    {
      flexDirection: 'row',
      justifyContent: 'flex-end'
    },
    style?.rightContainerStyle
  ],
  helpPointStyle: [
    context?.helpPointPosition === 'left' ? {
      marginLeft: !context?.displayBackButton ? context?.theme.metrics.spacing.s : 0
    } : {
      marginRight: !context?.displayCloseButton ? context?.theme.metrics.spacing.s : 0
    },
    style?.helpPointStyle
  ],
  closeButtonContainer: [
    {
      width: context?.theme.metrics.spacing.xl,
      height: context?.theme.metrics.spacing.xl,
      justifyContent: 'center',
      alignItems: 'center'
    },
    style?.closeButtonContainer
  ]
});
