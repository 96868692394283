import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { SavingRepartitionCardSelectTypeEnum } from '@components/ERE360Components'
import { FundSheetModalDataType } from '@components/templates'
import { ArbitrationTypes } from '@constants/arbitrations'
import { Title, ToggleText } from '@ere-uilib/atoms'
import { TriggerThresholdComponent } from '@ere-uilib/molecules'
import { useTheme } from '@ere-uilib/styles'
import { useTranslation } from '@ere-uilib/translations'
import { getReallocationType, getSelectedDispositif } from '@modules/arbitration/selectors'

import { DueDateCard } from './components/DueDateCard'
import { RenderSupportProps } from './Support'
import { useStyles } from './useStyles'
import { View } from 'react-native'
import { DueDateProps } from './components/DueDateCard/interface'

export const useController = ({
  support,
  supportAmount,
  isSupportSelected,
  isSupportSelectable,
  isDisabledSupport,
  isInputEditable,
  isFieldAmountPercentage,
  percentage,
  linkUnderfieldLabel,
  shouldDisplayRiskLevel,
  shouldDisplayAddedValue,
  isPercentageField,
  IsTriggerThreshold,
  onTriggerThresholdDelete,
  onTriggerThresholdEditPress,
  onSelectChange,
  onLinkUnderFieldPress,
  onEditAmountPress,
  ontriggerDueDateEditPress,
  onLinkUnderFieldEchenacePress
}: Omit<RenderSupportProps, 'navigation'>) => {
  const riskLevel = support.riskLevelSRI || support.riskLevelSRRI
  const supportAddedValue = support.amount.addedValue
  const supportAmountPercent = percentage ?? support.investedFormValues?.amountPercent
  const arbitrableAmount = support.amount.amount
  const hasNoFundSheet = support?.hasNoFundSheet
  const source = support?.source
  const dueDateFieldAmount =
    support.formValues?.dueDateOption && support.formValues.amount !== undefined
      ? support.formValues?.amount
      : 0
  const [fundSheetModalData, setFundSheetModalData] = useState<FundSheetModalDataType | null>()
  const [displayFundSheetModal, setDisplayFundSheetModal] = useState(false)
  const [showAvailabilities, setShowAvailabilities] = useState<boolean>(false)
  const selectedDispositif = useSelector(getSelectedDispositif)
  const reallocationType = useSelector(getReallocationType)

  const theme = useTheme()
  const { formatMessage } = useTranslation()
  const styles = useStyles(showAvailabilities)

  const fieldAmount = useMemo(() => {
    return support?.formValues?.dueDateOption?.code
      ? showAvailabilities
        ? 0
        : support.amount?.amount
      : support.formValues?.amount
  }, [support, showAvailabilities])

  const canShowAvailabilities = useMemo(
    () =>
      support?.schedules &&
      support?.schedules?.length > 1 &&
      selectedDispositif?.[0]?.filterProperties?.PartialReallocation &&
      reallocationType === ArbitrationTypes.stock,
    [selectedDispositif, reallocationType, support?.schedules]
  )

  const handleCardPress = useCallback(() => {
    setDisplayFundSheetModal(true)
    setFundSheetModalData({
      id: support?.supportIsin,
      title: support.supportName
    })
  }, [support, setDisplayFundSheetModal, setFundSheetModalData])

  const handleModalClose = useCallback(() => {
    setDisplayFundSheetModal(false)
    setFundSheetModalData(null)
  }, [setDisplayFundSheetModal, setFundSheetModalData])

  useEffect(() => {
    !showAvailabilities && support.formValues?.dueDateOption?.code && onLinkUnderFieldPress?.()
  }, [showAvailabilities, support, onLinkUnderFieldPress])

  const renderToggleButton = useCallback(() => {
    return (
      <ToggleText
        disabled={!isSupportSelected}
        label={formatMessage({ id: 'MaturityArbitrageFeature' })}
        onToggleStatusChange={setShowAvailabilities}
      />
    )
  }, [isSupportSelected, setShowAvailabilities])

  const renderSavingCardChildren = useCallback(() => {
    return !isDisabledSupport ? (
      <View style={styles.toggleContainer}>
        {IsTriggerThreshold && (
          <TriggerThresholdComponent
            data={support?.formValues?.triggerThreshold}
            isEditable={support?.formValues?.isSelected && !support?.formValues?.isDisabled}
            onDeletePress={onTriggerThresholdDelete}
            onEditPress={onTriggerThresholdEditPress}
          />
        )}
        {canShowAvailabilities && renderToggleButton()}
      </View>
    ) : (
      <Title
        style={styles.disabledSupportText}
        variant="t7"
        weight="light">
        {formatMessage({ id: 'Arbitrage_desinvest_montantInvesti_content' })}
      </Title>
    )
  }, [
    isDisabledSupport,
    IsTriggerThreshold,
    support,
    onTriggerThresholdDelete,
    onTriggerThresholdEditPress,
    renderToggleButton,
    showAvailabilities
  ])

  const renderDueDateFieldAmount = useCallback(
    (item: DueDateProps) => {
      return support.formValues?.dueDateOption?.date === item.date &&
        support.formValues?.dueDateOption?.code === item.scheduleCode &&
        support.formValues?.dueDateOption?.amount === item.amount
        ? dueDateFieldAmount
        : 0
    },
    [support, dueDateFieldAmount]
  )

  const renderEcheanceCard = useCallback(() => {
    return (
      showAvailabilities && (
        <>
          <Title
            style={styles.dueDateTitle}
            variant="t7"
            weight="regular">
            {formatMessage({ id: 'MaturityArbitrageFeatureDescription' })}
          </Title>
          {support?.schedules?.map((item, index) => {
            const amount = renderDueDateFieldAmount(item)
            return (
              <DueDateCard
                amount={item.amount}
                scheduleCode={item.scheduleCode}
                date={item?.date}
                dueDateFieldAmount={amount}
                key={`echeance_card_${index}`}
                numberOfShares={item.numberOfShares}
                onLinkUnderFieldEchenacePress={() => onLinkUnderFieldEchenacePress?.(item)}
                ontriggerDueDateEditPress={() => ontriggerDueDateEditPress?.(item)}
              />
            )
          })}
        </>
      )
    )
  }, [
    showAvailabilities,
    formatMessage,
    support?.schedules,
    support.formValues?.dueDateOption?.code,
    dueDateFieldAmount,
    onLinkUnderFieldEchenacePress,
    ontriggerDueDateEditPress
  ])

  const savingRepartitionCardProps = useMemo(() => {
    return !isDisabledSupport
      ? {
          addedValue: supportAddedValue,
          amount: supportAmount,
          arbitrableAmount: arbitrableAmount,
          disabled: isDisabledSupport,
          editable: isInputEditable,
          fieldAmount: isPercentageField ? undefined : fieldAmount,
          dueDateFieldAmount: dueDateFieldAmount,
          fieldPercent: supportAmountPercent,
          hasNoFundSheet: hasNoFundSheet,
          isFieldAmountPercentage: isFieldAmountPercentage,
          isLinkUnderField: isSupportSelected,
          isRiskLevelSRI: !!support.riskLevelSRI,
          isSelectable: isSupportSelectable,
          isSelected: isSupportSelected,
          legalStatus: '',
          linkUnderfieldLabel: linkUnderfieldLabel,
          nextValorisationDate: support.netAssetValue.dateValue,
          onCardPress: handleCardPress,
          onEditAmountPress: onEditAmountPress,
          onLinkUnderFieldPress: onLinkUnderFieldPress,
          onSelectChange: onSelectChange,
          performanceValue: support.performanceValue,
          riskLevel: shouldDisplayRiskLevel ? riskLevel : null,
          selectType: SavingRepartitionCardSelectTypeEnum.RADIO,
          shareCount: support.amount.numberOfUnits,
          shouldDisplayAddedValue: shouldDisplayAddedValue,
          source: source,
          supportName: support.supportName,
          availabilities: support?.schedules,
          showAvailabilities: showAvailabilities
        }
      : {
          disabled: isDisabledSupport,
          editable: false,
          hasNoFundSheet: hasNoFundSheet,
          investedAmount: arbitrableAmount,
          legalStatus: '',
          shouldDisplayAddedValue: shouldDisplayAddedValue,
          supportName: support.supportName
        }
  }, [
    isDisabledSupport,
    supportAddedValue,
    supportAmount,
    arbitrableAmount,
    isInputEditable,
    isPercentageField,
    fieldAmount,
    dueDateFieldAmount,
    supportAmountPercent,
    hasNoFundSheet,
    isFieldAmountPercentage,
    isSupportSelected,
    support.riskLevelSRI,
    support.netAssetValue.dateValue,
    support.performanceValue,
    support.amount.numberOfUnits,
    support.supportName,
    support?.schedules,
    isSupportSelectable,
    linkUnderfieldLabel,
    handleCardPress,
    onEditAmountPress,
    onLinkUnderFieldPress,
    onSelectChange,
    shouldDisplayRiskLevel,
    riskLevel,
    shouldDisplayAddedValue,
    source,
    showAvailabilities
  ])

  return {
    savingRepartitionCardProps,
    fundSheetModalData,
    displayFundSheetModal,
    handleModalClose,
    renderSavingCardChildren,
    renderEcheanceCard,
    showAvailabilities
  }
}
