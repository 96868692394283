import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PlanFamillyList } from '@constants/common';
import { InputMode } from '@modules/common/constants';
import { FileData } from '@modules/common/types';
import { updateProfileAddressRequest } from '@modules/profile/actions/profileActions';
import {
  getAddressAllowedFileExtensions,
  getProfileAddresses,
  getProfileAddressUpdateStatus
} from '@modules/profile/selectors';
import { AddressUpdateStatus } from '@modules/profile/types';

import { getAddress, mapFormValuesToDto } from './utils';
import { generateAddress } from '../utils';
import { FormValues } from './types';

export const useData = () => {
  const dispatch = useDispatch();

  const allowedFileExtensions = useSelector(getAddressAllowedFileExtensions(PlanFamillyList.ES));
  const { addressUpdateStatus } = useSelector(getProfileAddressUpdateStatus);
  const addresses = useSelector(getProfileAddresses);

  const {
    inputMode = InputMode.TSA,
    TSAAddress,
    ...other
  } = useMemo(() => {
    const getAddressByPlanFamily = getAddress(addresses);
    const { tsaAddress, inputMode, ...address } = getAddressByPlanFamily(PlanFamillyList.ES)!;
    const isTSAAddressVisible =
      (inputMode === InputMode.TSA || inputMode === InputMode.TSA_OR_UPLOAD) && !!tsaAddress;
    const TSAAddress = (isTSAAddressVisible && generateAddress(tsaAddress)) || '';

    return {
      ...address,
      inputMode,
      TSAAddress
    };
  }, [addresses]); // TODO: handle RC also

  const updateStatus = useMemo(
    () => ({
      isAddressUpdateLoading: addressUpdateStatus === AddressUpdateStatus.LOADING,
      hasAddressUpdateError: addressUpdateStatus === AddressUpdateStatus.ERROR
    }),
    [addressUpdateStatus]
  );

  const onUpdateUserAddress = useCallback(
    (values: FormValues, files: FileData[]) => {
      const paramsValues = inputMode === InputMode.TSA_OR_UPLOAD ? null : mapFormValuesToDto(values);
      dispatch(updateProfileAddressRequest(PlanFamillyList.ES, paramsValues, files)); // TODO: handle RC also
    },
    [dispatch]
  );

  return {
    inputMode,
    TSAAddress,
    onUpdateUserAddress,
    allowedFileExtensions,
    ...updateStatus,
    ...other
  };
};
