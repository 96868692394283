import { FundsDocumentsState } from '@modules/funds/types';

export const arrangeDocumentsFundList = ({
  list,
  typeOrderList
}: {
  list: FundsDocumentsState[];
  typeOrderList: string[];
}) => {
  const orderedDocList: FundsDocumentsState[] = [];
  const sortableDocList: FundsDocumentsState[] = list.filter(doc =>
    typeOrderList.includes(doc.type)
  );
  const unsortableDocList: FundsDocumentsState[] = list.filter(
    doc => !typeOrderList.includes(doc.type)
  );
  typeOrderList.forEach(docType => {
    sortableDocList.forEach(doc => {
      if (doc.type === docType) {
        orderedDocList.push(doc);
      }
    });
  });

  return [...orderedDocList, ...unsortableDocList];
};
