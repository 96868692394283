import { Context, Styles } from './interfaces';

export const getStyles = (
  context: Context,
  style: Styles
): Styles => {
  const squareSize = 18;
  const shadow = {
    backgoundColor: context?.theme.colors.basics.white,
    shadowColor: context?.theme.colors.notifications.shadow,
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.85,
    shadowRadius: 10,
    elevation: 1
  };
  let squarePositionStyle: Styles['square'] = {};
  let containerPositionStyle: Styles['containerStyle'] = {};
  switch (context?.squarePosition) {
    case 'left':
      squarePositionStyle = {
        left: 0,
        top: '50%',
        transform: [
          { translateX: -squareSize / 2 },
          { translateY: -squareSize / 2 },
          { rotate: '45deg' }
        ]
      };
      break;
    case 'right':
      squarePositionStyle = {
        right: 0,
        top: '50%',
        transform: [
          { translateX: squareSize / 2 },
          { translateY: -squareSize / 2 },
          { rotate: '45deg' }
        ]
      };
      break;
    case 'top':
      squarePositionStyle = {
        left: '50%',
        top: 0,
        transform: [
          { translateX: -squareSize / 2 },
          { translateY: -squareSize / 2 },
          { rotate: '45deg' }
        ]
      };
      containerPositionStyle = {
        marginTop: squareSize / 2
      };
      break;
    case 'bottom':
      squarePositionStyle = {
        left: '50%',
        bottom: 0,
        transform: [
          { translateX: -squareSize / 2 },
          { translateY: squareSize / 2 },
          { rotate: '45deg' }
        ]
      };
      containerPositionStyle = {
        marginBottom: squareSize / 2
      };
      break;
    default:
      squarePositionStyle = {
        display: 'none'
      };
      break;
  }
  return {
    containerStyle: [
      {
        ...containerPositionStyle
      },
      style?.containerStyle
    ],
    innerBackContainerStyle: [
      {
        flex: 1,
        borderRadius: context?.theme.metrics.borderRadius.xs,
        backgroundColor: context?.theme.colors.basics.white,
        ...shadow
      },
      style?.innerBackContainerStyle
    ],
    innerContainerStyle: [
      {
        flex: 1,
        borderRadius: context?.theme.metrics.borderRadius.xs,
        overflow: 'hidden',
        backgroundColor: context?.theme.colors.basics.white
      },
      style?.innerContainerStyle
    ],
    square: [
      {
        position: 'absolute',
        width: squareSize,
        height: squareSize,
        backgroundColor: context?.theme.colors.basics.white,
        ...squarePositionStyle
      },
      style?.square
    ],
    squareFront: [
      style?.squareFront
    ],
    squareBack: [
      {
        ...shadow
      },
      style?.square
    ]
  };
};
