import React, { useCallback } from 'react';
import { View } from 'react-native';

import { RoundedContainer, StraightLine, DashedLine, FontIcon, Title } from '@ere-uilib/atoms';
import { IconEnum } from '@ere-uilib/enums';
import { createUseStyles, useTheme, useScreenSizes } from '@ere-uilib/styles';
import { StepperStatus } from '@ere-uilib/types';

import { BubbleProps, DirectionEnum, Props, StepperSizeEnum } from './interfaces';
import { getStyle } from './styles';

export const InstallmentsStepper:React.FC<React.PropsWithChildren<Props>> = ({
  stepperItems,
  lineLength = 20,
  stepperSize = StepperSizeEnum.BIG,
  direction = DirectionEnum.ROW,
  containerStyle
}) => {
  const isMobile = useScreenSizes().isMobile;
  const isTablet = useScreenSizes().isTablet;
  const theme = useTheme();
  const styles = useStyle({
    theme,
    lineLength,
    isMobile: isMobile || isTablet,
    direction,
    stepperSize
  }, {
    containerStyle
  });
  const Bubble = useCallback(({ status, label }: BubbleProps) => {
    switch (status) {
    case StepperStatus.DONE:
      return <FontIcon
        color={theme.colors.basics.white}
        name={IconEnum.CHECK}
        size={stepperSize === 'small'
          ? theme.metrics.iconSizes.xxs
          : theme.metrics.iconSizes.s
        }
      />;
    case StepperStatus.WAITING:
      return <FontIcon
        color={theme.colors.basics.grey.c400}
        name={IconEnum.TIME}
        size={stepperSize === 'small'
          ? theme.metrics.iconSizes.m
          : theme.metrics.iconSizes.mplus
        }
      />;
    case StepperStatus.FINISH:
      return <FontIcon
        color={theme.colors.basics.grey.c400}
        name={IconEnum.CHECK}
        size={stepperSize === 'small'
          ? theme.metrics.iconSizes.xxs
          : theme.metrics.iconSizes.s
        }
      />;
    case StepperStatus.TO_DO:
      return <Title
        numberOfLines={0}
        style={styles.toDoRoundedTitle}
        variant={stepperSize === 'small' ? 't8s' : 't6'}
      > {label}</Title>;
    case StepperStatus.IN_PROGRESS:
    case StepperStatus.DEFAULT:
    default:
      return <Title
        numberOfLines={0}
        style={styles.defaultRoundedTitle}
        variant={stepperSize === 'small' ? 't8s' : 't6'}
      >{label}</Title>;
    }
  },
  [stepperSize, theme.colors.basics.grey.c400, theme.colors.basics.white, theme.metrics.iconSizes.mplus, theme.metrics.iconSizes.s],
  );

  return (
    <View style={styles.containerStyle}>
      {
        stepperItems?.map((item, index)=>{
          const isItemLast = (stepperItems.length - 1) === index;
          const nextItem = stepperItems[index + 1];
          //const isDashLine = nextItem?.status && [StepperStatus.IN_PROGRESS, StepperStatus.WAITING, StepperStatus.TO_DO, StepperStatus.FINISH].includes(nextItem.status);

          const getIsStepActive = (status:StepperStatus | undefined):boolean => {
            return [StepperStatus.DEFAULT, undefined, StepperStatus.IN_PROGRESS, StepperStatus.DONE].includes(status);
          };

          const isCurrentStepActive = getIsStepActive(item.status);
          const isNextStepActive = getIsStepActive(nextItem?.status);

          return (
            <View
              key={index}
              style={styles.itemContainer}>
              <View style={styles.lineAndRoundContainer}>
                <RoundedContainer
                  isActif={isCurrentStepActive}
                  key={'rounde_container_stepper' + index}
                  variant={stepperSize}
                >
                  <Bubble
                    label={(index + 1).toString()}
                    status={item.status}
                  />
                </RoundedContainer>
                {!isItemLast && (
                  <View style={styles.lineContainer}>
                    {!isItemLast && (isNextStepActive || direction === DirectionEnum.ROW) && (
                      <StraightLine
                        containerStyle={styles.straightline}
                        isActif={isNextStepActive}
                        orientation={direction}
                      />
                    )}
                    {!isItemLast && direction === DirectionEnum.COLUMN && !isNextStepActive && (
                      <DashedLine
                        axis="vertical"
                        customLength={lineLength}
                        dashColor={theme.colors.basics.grey.c200}
                        dashGap={2}
                        dashLength={2}
                      />
                    )}
                  </View>
                )}
              </View>
              {direction === DirectionEnum.COLUMN && (
                <View style={styles.stepContent}>
                  {item.title && (
                    <View style={styles.titleContainer}>
                      <Title
                        style={!isCurrentStepActive && styles.titleDisabled}
                        variant={stepperSize === 'small' ? 't7' : 't6'}
                      >
                        {item.title}
                      </Title>
                      {item.children}
                    </View>
                  )}
                </View>
              )}
            </View>
          );
        })
      }
    </View>
  );
};

const useStyle = createUseStyles(getStyle);
