import { useTheme } from "@ere-uilib/styles";
import { useMemo } from "react";
import { StyleSheet } from "react-native";

export const useStyles = () => {

  const theme = useTheme();

  const styles = useMemo(() => StyleSheet.create({
    contentContainer: {
      justifyContent: 'center',
      alignItems: 'center'
    },
    iconContainer: {
      borderRadius: theme.metrics.borderRadius.xs,
      padding: theme.metrics.spacing.s,
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: theme.metrics.spacing.xm,
      marginTop: theme.metrics.spacing.xxm
    },
    modalTitleStyle: {
      marginTop: theme.metrics.spacing.xs,
      marginBottom: theme.metrics.spacing.s
    },
    buttonWrapper: {
      marginVertical: theme.metrics.spacing.s
    },
    buttonContainer: {
      width: "100%"
    }
  }
  ), [theme]);

  return styles;

}