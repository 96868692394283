import { useTheme } from "@ere-uilib/styles"
import { useMemo } from "react"
import { StyleSheet } from "react-native"

export const useStyles = ()=>{
  const theme = useTheme()

  const styles = useMemo(()=>StyleSheet.create({
    loaderContainerStyle: {
      justifyContent: 'space-between',
      height:theme.metrics.spacing.xxxxl,
    },
  }
  ),[theme]);

  return styles;
}