import { Context, Styles } from './interfaces';

export const getStyle = (context?: Context, style?: Styles): Styles => ({
  toDoRoundedTitle: [
    {
      display: 'flex',
      lineHeight: undefined,
      color: context?.theme.colors.basics.grey.c400
    },
    style?.toDoRoundedTitle
  ],
  defaultRoundedTitle: [
    {
      display: 'flex',
      lineHeight: undefined,
      color: context?.theme.colors.basics.white
    },
    style?.defaultRoundedTitle
  ],
  containerStyle: [
    {
      flexDirection: context?.direction
    },
    style?.containerStyle
  ],
  lineAndRoundContainer: [
    {
      alignItems: 'center',
      flexDirection: context?.direction
    },
    style?.lineAndRoundContainer
  ],
  titleContainer: [
    {
      marginLeft: context?.theme.metrics.spacing.xm,
      alignItems: 'flex-start'
    },
    style?.titleContainer
  ],
  titleDisabled: [
    {
      color: context?.theme.colors.text.disabled.c500
    },
    style?.titleDisabled
  ],
  itemContainer: [
    {
      flexDirection: 'row',
      alignItems: 'stretch'
    },
    style?.itemContainer
  ],
  lineContainer: [
    context?.direction === 'column'
      ? {
        flex: 1,
        minHeight: context?.lineLength
      }
      : {},
    style?.lineContainer
  ],
  straightline: [
    context?.direction === 'column'
      ? {
        minHeight: context?.lineLength
      }
      : {
        minWidth: context?.lineLength
      },
    style?.straightline
  ],
  stepContent: [
    {
      marginBottom: context?.theme.metrics.spacing[context?.isMobile ? 'xm' : 'xxl'],
      flex: 1,
    },
    style?.stepContent
  ]
});
