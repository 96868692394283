import { useLinkTo } from '@react-navigation/native';
import { Linking, Platform } from 'react-native';

import { REGEX, LOCATION_URL } from '@constants/index';

export const useUrlLink = () => {
  const linkTo = useLinkTo();

  const linkToUrl = (url: string) => {
    if (!url) {
      return;
    }

    const isUrl = REGEX.URL.test(url);
    if (!isUrl) {
      const isValidPath = REGEX.ISVALIDPATH.test(url);

      if (isValidPath) {
        linkTo(url);
      }
    } else {
      const isLocalUrl = !url.includes('https://');

      if (isLocalUrl && Platform.OS === 'web') {
        const localUrlToPath = url.split(LOCATION_URL)[1];
        linkTo(localUrlToPath);

      } else {
        return Linking.canOpenURL(url).then(() => {
          return Linking.openURL(url);
        });
      }
    }
  };

  return {
    linkToUrl,
  };
};
