export enum CompartementLabelCodeEnum {
  SavingsPayment = 'CompartmentLabel1',
  NonDeductibleVoluntaryPayment = 'CompartmentLabel2',
  DeductibleVoluntaryPayment = 'CompartmentLabel3'
}

export interface ControlDataErrorState {
  code: string;
  correlationId: string;
  message: string;
  innerMessage: string;
}

export interface FetchControlDataState {
  planId: number;
  planName: string;
  authorization: boolean;
  managements: ControlDataManagement[]
}
export type FetchControlDataEditingOptionState = Omit<FetchControlDataState, 'authorization'>

export interface ControlDataManagementFormValue {
  riskProfileValue?: number
  horizonValue?: number
  riskProfileLabel?: string
  horizonLabel?: string
}
export interface ControlDataManagement {
  managementId: string;
  compartmentLabelCode: string;
  data: ControlDataManagementData | null;
  riskProfiles?: ControlDataManagementRiskProfile[]
  formValues?: ControlDataManagementFormValue
}

export interface ControlDataManagementData {
  riskProfile: string | null
  horizon: number | null
}
export interface ControlDataManagementRiskProfile {
  label: string;
  ageMin: number;
  ageMax: number;
}
export interface ControlDataState {
  data: FetchControlDataState[];
  editingOptions: FetchControlDataEditingOptionState | undefined;
  error: ControlDataErrorState | undefined;
  isEditedDrivingDataSuccess: boolean;
  editedDrivingData: EditedControlDataType | undefined;
}

export type AgeRangeType = {
  ageMin: number,
  ageMax: number
}

export type ControlDataGroupedRiskProfileState = {
  labels: string[],
  ageRange: AgeRangeType[]
}

export enum ControlDataInputTypeEnum {
  horizon = 'horizon',
  riskProfile = 'riskProfile'
}

export enum DrivingDataInputKeyValueEnum {
  HORIZON_KEY = 'horizonLabel',
  HORIZON_VALUE = 'horizonValue',
  RISK_KEY = 'riskProfileLabel',
  RISK_VALUE = 'riskProfileValue'
}

export type ControlDataFormatterType = {
  itemType: ControlDataInputTypeEnum
  minAge?: number
  maxAge?: number
  riskLabels?: string[]
}

export interface DrivingDataRequestViewModels {
  managementId: string;
  riskProfile: string;
  horizon: number
}

export type EditedControlDataType = {
  drivingDataRequestViewModels: DrivingDataRequestViewModels[]
}