import { StyleSheet } from 'react-native';

import { Context, Styles } from './interface';

export const getStyles = (
  context?: Context,
  style?: Styles,
): Styles => ({
  container: [
    {
      flex: 1,
      alignItems: 'center',
      backgroundColor: context?.theme.colors.background.poppinFilter.c500,
    },
    context?.isMobile
      ? {
        justifyContent: 'flex-end',
      }
      : {
        justifyContent: 'center',
      },
    style?.container,
  ],
  overlay: [
    {
      ...StyleSheet.absoluteFillObject,
      flex: 1,
    },
    style?.overlay,
  ],
  modalTextContainer: [
    {
      marginTop: context?.theme.metrics.spacing.xm,
      marginBottom: context?.theme.metrics.spacing.l,
      alignItems: 'center',
      justifyContent: 'center',
    },
    style?.modalTextContainer,
  ],
  modalTitle: [
    {
      alignItems: 'center',
      textAlign: 'center',
      marginBottom: context?.theme.metrics.spacing.s,
    },
    style?.modalTitle,
  ],
  modalText: [
    {
      textAlign: 'center',
    },
    style?.modalText,
  ],
  modalFirstActionStyle: [
    {
      marginBottom: context?.theme.metrics.spacing.xm,
      backgroundColor: context?.theme.colors.basics.white,
    },
    style?.modalFirstActionStyle,
  ],
  svgStyle: [
    {
      height: "100%" 
    },
    style?.svgStyle,
  ],
  imageStyle: [
    {
      alignSelf: 'center',
    },
    context?.isMobile ? {
      width: 245,
      height: 110,
      marginTop: context?.theme.metrics.spacing.m,
    } : {
      width: 300,
      height: 150,
      marginTop: context?.theme.metrics.spacing.l,
    },
    style?.imageStyle,
  ]
});
