import { useMemo } from 'react';

import { convertFileSize, useTranslation } from '@components/ERE-UILib';

export const useDictionary = (fileMaxSize: number | null) => {
  const { formatMessage } = useTranslation();

  const dictionary = useMemo(() => {
    const fileDescription = formatMessage({
      id: 'CoordonneesBancairesPopinSourceUploadSousTitre',
      values: { size: convertFileSize(fileMaxSize, 'MB') }
    });

    return {
      errorDescription: fileDescription,
      errorTitle: formatMessage({ id: 'ERR_FOR_02' }),
      popinDescription: fileDescription,
      popinTitle: formatMessage({ id: 'CoordonneesBancairesPopinSourceUploadTitre' }),
      cameraTitle: formatMessage({ id: 'CoordonneesBancairesPopinSourceUploadPhotoTitreBouton' }),
      cameraSubtitle: formatMessage({
        id: 'CoordonneesBancairesPopinSourceUploadPhotoDescriptionBouton'
      }),
      galleryTitle: formatMessage({ id: 'CoordonneesBancairesPopinSourceUploadImageTitreBouton' }),
      gallerySubtitle: formatMessage({
        id: 'CoordonneesBancairesPopinSourceUploadImageDescriptionBouton'
      }),
      explorerTitle: formatMessage({
        id: 'CoordonneesBancairesPopinSourceUploadFichierTitreBouton'
      }),
      explorerSubtitle: formatMessage({
        id: 'CoordonneesBancairesPopinSourceUploadFichierDescriptionBouton'
      })
    };
  }, [fileMaxSize, formatMessage]);

  return dictionary;
};
