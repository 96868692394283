
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getToolRequest,
} from '@modules/dashboard/actions/dashboardActions';
import { DashboardActionsType } from '@modules/dashboard/actions/dashboardActionsTypes';
import { RetirementActionsType } from '@modules/retirement/actions/retirementActionsTypes';
import {
  getError, getTools,
} from '@modules/dashboard/selectors';
import { ToolsFlowTypeEnum } from '@modules/dashboard/types';
import { AppState } from '@modules/index';
import { getRetirementDecisionRequest } from '@modules/retirement/actions/retirementActions';

import { useController } from '../components';
import { RetirementDetailsPageComponent } from './RetirementDetailsPage.component';

export const RetirementDetailsPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch = useDispatch();
  const toolsError = useSelector((state: AppState) => getError('toolsError', state));
  const tools = useSelector((state: AppState) => getTools(state, ToolsFlowTypeEnum.RETIREMENT));
  const { retirementDecisionTabFormValues, totalAmount } = useController();
  const isToolsApiLoading = useSelector((state: AppState) => state.isLoading[DashboardActionsType.GET_TOOL_REQUEST]);
  const isRetirementApiLoading = useSelector((state: AppState) => state.isLoading[RetirementActionsType.GET_RETIREMENT_DECISION_REQUEST]);
  const errors = { toolsError };
  const handleGetTool = useCallback(() => dispatch(getToolRequest(ToolsFlowTypeEnum.RETIREMENT)), [dispatch]);

  useEffect(() => {
    dispatch(getRetirementDecisionRequest());
  }, [dispatch])

  return (
    <RetirementDetailsPageComponent
      errors={errors}
      initialFormValues={retirementDecisionTabFormValues}
      isToolsApiLoading={isToolsApiLoading}
      isRetirementApiLoading={isRetirementApiLoading}
      onGetTool={handleGetTool}
      tools={tools}
      totalAmount={totalAmount}
    />
  )
};
