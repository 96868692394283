import _ from 'lodash';

import { Data, QuestionAnsweredStatus, SelectItem } from './AnnuitySimulator/sharedInterfaces';

export const getYearsBetween = (newer: Date | undefined, older: Date | undefined): number => {
  if (!older || !newer) return 0;
  let diff = (newer.getTime() - older.getTime()) / 1000;
  diff /= 60 * 60 * 24;
  return Math.abs(Math.round(diff / 365.25));
};

export const addYearToDate = (date: Date | undefined, years: number): Date | undefined => {
  if (!date) return undefined;
  const later = new Date(date);
  later.setFullYear(later.getFullYear() + years);
  return later;
};

export const getAge = (birthDate: Date | undefined) => {
  if (!birthDate) return 0;
  const now = new Date();
  const age = getYearsBetween(now, birthDate);
  return age;
};

export const isBirthDateValid = ({
  birthDate,
  maximumAge,
  minimumAge
}: {
  birthDate: Date | undefined;
  maximumAge: number;
  minimumAge: number;
}) => {
  const age = getAge(birthDate);
  return age >= minimumAge && age <= maximumAge;
};

export const getQuestionsAndAnswers = (orders: (keyof Data)[], status: QuestionAnsweredStatus) =>
  orders.reduce<{
    questions:(keyof Data)[];
    answers: (keyof Data)[];
      }>(
      (acc, key) => {
        if (acc.questions.length) return acc;
        const isAnswered = status[key];
        if (isAnswered) {
          acc.answers.push(key);
          return acc;
        }
        acc.questions.push(key);
        return acc;
      },
      { questions: [], answers: [] }
      );

export const hasChanged = <T>(prev: T, next: T): boolean => {
  if (prev instanceof Date && next instanceof Date)
    return prev.toLocaleDateString() !== next.toLocaleDateString();
  if (typeof prev === 'object' || typeof next === 'object') return !_.isEqual(prev, next);
  return prev !== next;
};

export const formatSelectValue = <T extends string | number>(
  value: T,
  formatter?: (value: T) => string
): SelectItem<T> => ({
    value,
    label: formatter?.(value) || value.toString()
  });
