import { RefundsRepartitionValidationModalContext, RefundsRepartitionValidationModalStyle } from './interfaces';

export const getStyles = (
  context?: RefundsRepartitionValidationModalContext,
  style?: RefundsRepartitionValidationModalStyle
): RefundsRepartitionValidationModalStyle => ({
  closeRefundModal: [
    {
      justifyContent: 'center',
      alignItems: 'center',
    },
    style?.closeRefundModal,
  ],
  closeRefundModalTitle: [
    {
      marginBottom: context?.theme?.metrics.spacing.s,
    },
    style?.closeRefundModalTitle,
  ],
  closeRefundModalTexts: [
    {
      marginTop: context?.theme?.metrics.spacing.xm,
      marginBottom: context?.theme?.metrics.spacing.l,
      textAlign: 'center',
    },
    style?.closeRefundModalTexts,
  ],
  closeRefundModalButtonContainer: [
    {
      width: '100%',
    },
    style?.closeRefundModalButtonContainer,
  ],
  closeRefundModalCancelButton: [
    {
      marginBottom: context?.theme?.metrics.spacing.xm,
    },
    style?.closeRefundModalCancelButton,
  ],
});
