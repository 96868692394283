import { NavigatorScreenParams } from "@react-navigation/native";

import { HistoryNavigatorInterface } from "./HistoryNavigatorInterface";
import { ProfileStackNavigatorInterface } from "./ProfileStackInterface";
import { SavingsNavigatorInterface } from "./SavingsNavigatorInterface";

export enum BottomTabNavigatorRouteNames {
  Home = "Home",
  Menu = "Menu",
  HistoryStack = "HistoryStack",
  ProfileStack = "ProfileStack",
  SavingsStack = "SavingsStack",
  MobileMenuModal = "MobileMenuModal",
}

export type BottomTabNavigatorInterface = {
  [BottomTabNavigatorRouteNames.Home]: undefined;
  [BottomTabNavigatorRouteNames.SavingsStack]: NavigatorScreenParams<SavingsNavigatorInterface>;
  [BottomTabNavigatorRouteNames.Menu]: undefined;
  [BottomTabNavigatorRouteNames.HistoryStack]: NavigatorScreenParams<HistoryNavigatorInterface>;
  [BottomTabNavigatorRouteNames.ProfileStack]: NavigatorScreenParams<ProfileStackNavigatorInterface>;
  [BottomTabNavigatorRouteNames.MobileMenuModal]: undefined;
};
