import React from 'react';
import { View, StyleProp, ViewStyle } from 'react-native';

import { Text, useTheme, ThemeType, SVGLocalLoader, useScreenSizes } from '@components/index';
import { locator } from '@constants/locator';
import { RectangleLoader } from '@ere-uilib/atoms/loaders/Rectangle';
import { createUseStyles } from '@ere-uilib/styles';
import { AccountState } from '@modules/dashboard/types';

interface Props {
  company?: AccountState;
  companyViewStyle?: StyleProp<ViewStyle>;
  isLoading?: boolean;
}

type ProfileCompanyStyles = {
  companyViewStyle?: StyleProp<ViewStyle>;
};

type StylesContext = { theme: ThemeType };

const ProfileCompany: React.FC<React.PropsWithChildren<Props>> = ({
  company,
  companyViewStyle,
  isLoading
}) => {
  const theme = useTheme();
  const { isMobile, isTablet } = useScreenSizes();
  const styles = useStyles({ theme }, { companyViewStyle });

  if (isLoading && !company) {
    return <RectangleLoader height={60} />;
  }

  return (
    <View
      style={[
        styles.companyViewStyle,
        { height: isMobile ? 100 : 60 },
        {
          paddingHorizontal: isMobile || isTablet ? 50 : 130
        }
      ]}>
      <Text
        style={{ fontWeight: 'bold', maxWidth: isMobile ? 250 : '100%' }}
        testId={locator._profile._company_name}>
        {company?.companyName}
      </Text>
      {company?.isActiveAccount && (
        <SVGLocalLoader
          containerStyle={{ maxWidth: 37 }}
          height={42}
          name="activeAccount"
          width={37}
        />
      )}
    </View>
  );
};

const getStyles = (
  context?: StylesContext,
  style?: ProfileCompanyStyles
): ProfileCompanyStyles => ({
  companyViewStyle: [
    {
      flexDirection: 'row',
      backgroundColor: context?.theme.colors.basics.grey.c100,
      paddingVertical: 20,
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    style?.companyViewStyle
  ]
});

const useStyles = createUseStyles(getStyles);

export default ProfileCompany;
