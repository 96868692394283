import { connect } from 'react-redux';

import { ValidateIdentityActionsType } from '@modules/validate-identity/actions/validateIdentityActionsTypes';
import { RegistrationRequestConfirmRequest } from '@modules/validate-identity/actions/validateIdentityActions';
import { AuthActionType } from '@modules/auth/actions/authActionsTypes';
import { AppState } from '@modules/index';
import { getApplicationSettings } from '@modules/settings/selectors';
import { getIdentityValidationModuleError } from '@modules/validate-identity/selectors';

import { ChoosePasswordPageComponent } from './ChoosePasswordPage.component';

const mapStateToProps = (state: AppState) => {
  return {
    validateIdentityError: getIdentityValidationModuleError(state),
    isApiLoading:
      state.isLoading[ValidateIdentityActionsType.CONFIRIM_REGISTRATION_REQUEST] ||
      state.isLoading[AuthActionType.SIGNIN_NATIVE_APP_REQUEST],
    applicationSettings: getApplicationSettings(state)
  };
};

const mapDispatchToProps = ({
  onRegisterConfirmation: RegistrationRequestConfirmRequest
});
export const ChoosePasswordPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChoosePasswordPageComponent);
