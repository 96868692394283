import React, { useCallback, useMemo, useState } from 'react';
import { View } from 'react-native';

import {
  ColumnsContainer,
  DashboardPageWrapperConnected,
  FundSheetModal,
  FundSheetModalDataType,
  NotificationHard,
  NotificationIconTypeEnum,
  SimpleButton,
  Text,
  useScreenSizes,
  useTranslation
} from '@components/index';
import { IconEnum } from '@ere-uilib/enums';
import { DirectionEnum } from '@ere-uilib/molecules/steppers/InstallmentsStepper/interfaces';
import { SupportType } from '@modules/common/types';
import { constructStepperItems } from '@pages/Common/utils';

import {
  ArbitrationHeader,
  ArbitrationPieChartSection,
  RecapOldAndNewRepartition
} from '../components';
import { useRepartitionLegend } from '../hooks/useRepartitionLegend';
import { ArbitrationOADRepartitionConfirmationProps } from './interfaces';
import { useStyles } from './styles';
import { locator } from '@constants/locator';

export const ArbitrationOADRepartitionConfirmationPage: React.FC<React.PropsWithChildren<ArbitrationOADRepartitionConfirmationProps>> = ({
  riskProfileData,
  planData,
  repartitionData,
  simulationData,
  manualRepartitionData,
  submitOadReallocations,
  isLoading,
  selectedCompartment,
  navigation,
}) => {
    const { formatMessage } = useTranslation();
    const { isMobile, isTablet, isDesktop } = useScreenSizes();
    const styles = useStyles();
    const repartitionLegend = useRepartitionLegend();
    const stepperItems = constructStepperItems({
      size: 3,
      direction: DirectionEnum.ROW,
      activeStep: 3
    });
    const [displayFundSheetModal, setDisplayFundSheetModal] = useState(false);
    const [fundSheetModalData, setFundSheetModalData] = useState<FundSheetModalDataType | null>();

    const dictionary = useMemo(() => ({
      headerTitle: formatMessage({ id: 'AbritrageOADPageSyntheseChoixEtapesTitre' }),
      choiceDescriptionTitle: formatMessage({
        id: 'AbritrageOADPageSyntheseChoixDescriptionTitre'
      }),
      choiceDescription: formatMessage({
        id: 'AbritrageOADPageSyntheseChoixDescription'
      }),
      oldRepartitionPieChartProfileLabel: formatMessage({
        id: 'AbritrageOADPageSyntheseAncienneRepartitionTitre'
      }),
      newRepartitionPieChartProfileLabel: formatMessage({
        id: 'AbritrageOADPageSyntheseNouvelleRepartitionTitre'
      }),
      bottomButtonActionsConfirm: formatMessage({
        id: 'AbritrageOADPageSyntheseChoixValiderBouton'
      }),
      notificationHardText: formatMessage({
        id: 'AbritrageOADPageSyntheseChoixCardRisque'
      }),
      bottomDescription: formatMessage({
        id: 'AbritrageOADPageSyntheseChoixCardValidationDescription'
      }),
      headerHelpPointContentHtml: formatMessage({
        id: 'AbritrageOADPageSyntheseChoixAideDescription'
      }),
      headerHelpPointModalTitle: formatMessage({
        id: 'AbritrageOADPageSyntheseChoixAideTitre'
      })
    }), [formatMessage]);

    const handleGoBackPress = useCallback(() => {
      navigation.goBack();
    }, [navigation]);

    const handleCardContentTitlePress = useCallback((support: SupportType) => {
      setDisplayFundSheetModal(true);
      setFundSheetModalData({
        id: support.supportIsin,
        title: support.supportName
      });
    }, []);

    const handleFundSheetModalClose = useCallback(() => {
      setDisplayFundSheetModal(false);
      setFundSheetModalData(null);
    }, []);

    const renderHeader = useCallback(
      () => (
        <ArbitrationHeader
          displayCloseButton
          helpPoint
          helpPointContentHtml={dictionary.headerHelpPointContentHtml}
          helpPointModalTitle={dictionary.headerHelpPointModalTitle}
          helpPointPosition="left"
          stepperItems={stepperItems}
          title={dictionary.headerTitle}
        />
      ),
      [dictionary, stepperItems, styles]
    );

    const chartData = useMemo(
      () => [
        {
          title: formatMessage({ id: 'AbritrageOADResultatQRRepartitionActuelleDescription' }),
          repartitionData: repartitionData,
          srriProfil: '',
          hasPlaceholder: false,
        },
        {
          title: formatMessage({ id: 'AbritrageOADResultatQRMaSimulationDescription' }),
          repartitionData: simulationData,
          srriProfil: riskProfileData?.actualProfileRisk,
          profilLabel: dictionary.oldRepartitionPieChartProfileLabel
        },
        {
          title: formatMessage({ id: 'AbritrageOADPageSyntheseChoixNouvelleRepartition' }),
          repartitionData: manualRepartitionData,
          srriProfil: riskProfileData?.srriProfil
            ? formatMessage({ id: riskProfileData?.srriProfil })
            : '',
          profilLabel: dictionary.newRepartitionPieChartProfileLabel,
        }
      ],
      [formatMessage, repartitionData, simulationData, riskProfileData, manualRepartitionData, dictionary]
    );
    const renderNotificationHard = useCallback(
      () => {
        if ((
          riskProfileData?.actualProfileRisk === undefined &&
          riskProfileData?.srriProfil === undefined
        ) ||
          (riskProfileData?.actualProfileRisk === formatMessage?.({ id: riskProfileData?.srriProfil }))
        ) {
          return
        }

        return (
          <NotificationHard
            containerStyle={styles.notificationHardContainer}
            type={NotificationIconTypeEnum.WARNING}>
            <Text
              style={styles.notificationHardContentText}
              variant="t3"
              weight="light">
              {dictionary.notificationHardText}
            </Text>
          </NotificationHard>
        )
      },
      [dictionary, riskProfileData, formatMessage, styles]
    );
    const renderBottomActions = useCallback(
      () => (
        <View style={styles.bottomActions}>
          <View style={styles.bottomActionsButtonsContainer}>
            <SimpleButton
              design="outlined"
              leftIcon={IconEnum.CHEVRON_LEFT}
              onPress={handleGoBackPress}
            />
            <SimpleButton
              containerStyle={[styles.simpleButton, !isDesktop && styles.simpleButtonFlex]}
              design="solid"
              loading={isLoading}
              onPress={submitOadReallocations}
              size="small"
              title={dictionary.bottomButtonActionsConfirm}
              testId={locator._arbitration._synthese_validate}
            />
          </View>
        </View>
      ),
      [styles, handleGoBackPress, isLoading, submitOadReallocations, dictionary]
    );

    const renderPieChartSection = useCallback(() => (
      <ArbitrationPieChartSection
        chartData={chartData}
        chartLegend={repartitionLegend}
      />
    ), [chartData, repartitionLegend]);

    const renderContent = useCallback(() => (
      <RecapOldAndNewRepartition
        newRepartition={manualRepartitionData}
        oldRepartition={repartitionData.filter(data => data.value !== 0)}
        onCardTitlePress={handleCardContentTitlePress}
        planData={planData}
        selectedCompartment={selectedCompartment}
      />
    ), [manualRepartitionData, repartitionData, planData, selectedCompartment]);

    const renderBottomDescription = useCallback(() => (
      <View style={styles.bottomDescriptionContainer}>
        <Text style={styles.bottomDescription}>
          {dictionary.bottomDescription}
        </Text>
      </View>
    ), [dictionary, styles]);

    const renderTextsHeader = useCallback(() => {
      return (
        <View style={styles.headerDescriptionBlock}>
          <Text
            variant="t3"
            weight="regular">
            {dictionary.choiceDescriptionTitle}
          </Text>
          <Text
            style={styles.headerDescriptionBlock}
            variant="t3"
            weight="light">
            {dictionary.choiceDescription}
          </Text>
        </View>
      )
    }, [dictionary, styles]);

    return (
      <DashboardPageWrapperConnected>
        <View style={styles.container}>
          <ColumnsContainer
            renderHeader={renderHeader}
            renderStickyMobileBottom={renderBottomActions}
          >
            {renderTextsHeader()}
            {renderPieChartSection()}
            {renderNotificationHard()}
            {renderContent()}
            {renderBottomDescription()}
            {!isMobile && !isTablet && renderBottomActions()}

            {fundSheetModalData && (
              <FundSheetModal
                id={fundSheetModalData.id}
                modalIsVisible={displayFundSheetModal}
                onCloseModal={handleFundSheetModalClose}
                title={fundSheetModalData.title}
              />
            )}
          </ColumnsContainer>
        </View>
      </DashboardPageWrapperConnected>
    );
  };
