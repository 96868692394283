import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

export const useStyles = () => {
  const theme = useTheme();
  const styles = useMemo(
    () =>
      StyleSheet.create({
        dateFieldContainer: {
          marginVertical: theme.metrics.spacing.s
        },
        buttonContainer: {
          marginVertical: theme.metrics.spacing.s,
          flexDirection: 'row'
        }
      }),
    [theme]
  );

  return styles;
};
