import {
  FundsComparisonContentStylesContext,
  FundsComparisonContentStyles,
} from './interfaces';

export const getStyles = (
  context?: FundsComparisonContentStylesContext,
  style?: FundsComparisonContentStyles
): FundsComparisonContentStyles => ({
  containerStyle: [
    {},
    style?.containerStyle,
  ],
  titleStyle: [
    {
      marginTop: context?.isMobile ?
        context?.theme.metrics.spacing.xxl
        : context?.theme.metrics.spacing.xxbig,
    },
    style?.titleStyle,
  ],
  PerfTableStyle: [
    {
      marginTop: context?.theme.metrics.spacing.xm,
    },
    style?.PerfTableStyle,
  ],
  PerfTableSubText01Style: [
    {
      marginTop: context?.theme.metrics.spacing.l,
      marginBottom: context?.theme.metrics.spacing.m,
    },
    style?.PerfTableSubText01Style,
  ],
  PerfTableSubText02Style: [
    {
      marginBottom: context?.theme.metrics.spacing.s,
    },
    style?.PerfTableSubText02Style,
  ],
});

