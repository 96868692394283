import React from 'react';
import {
  TouchableOpacity,
  View,
  StyleProp,
  ViewStyle,
} from 'react-native';

import { SVGLocalLoader } from '@ere-uilib/atoms';
import { useTheme, createUseStyles, ThemeType } from '@ere-uilib/styles';

interface Props {
  onPress: () => void;
  active:boolean;
}

type Styles = {
  container?: StyleProp<ViewStyle>;
  background?: StyleProp<ViewStyle>;
  button?: StyleProp<ViewStyle>;
  buttonBackground?: StyleProp<ViewStyle>;
};

type StylesContext = { theme: ThemeType };

export const TabBarAdvancedButton: React.FC<React.PropsWithChildren<Props>> = ({
  onPress,
  active,
}) => {
  const theme = useTheme();
  const styles = useStyles({ theme }, {});
  return (
    <View
      pointerEvents="box-none"
      style={styles.container}>
      <View 
        accessibilityLabel="more_options"
        testID="more_options"
        style={styles.buttonBackground}>
        <SVGLocalLoader
          height={61}
          name="tabBg"
          width={75} />
      </View>
      <TouchableOpacity
        onPress={onPress}
        style={styles.button}>
        <SVGLocalLoader
          height={50}
          name={active ? 'btnMenuActive' : 'btnMenuInactive'}
          width={50} />
      </TouchableOpacity>
      <View style={styles.background} />
    </View>
  );
};

const getStyles = (context?: StylesContext, style?: Styles): Styles => ({
  container: {
    width: 75,
    alignItems: 'center',
  },
  background: {
    position: 'absolute',
    width: '100%',
    height: 40,
    top: 35,
    backgroundColor: context?.theme.colors.basics.white,
  },
  buttonBackground: {
    width: 75,
    height: 61,
  },
  button: {
    top: -83,
    height: 50,
    width: 50,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 27,
    backgroundColor: context?.theme.colors.buttons.classic.c500,
  },
});

const useStyles = createUseStyles(getStyles);
