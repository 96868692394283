import { connect } from 'react-redux';

import { AppState } from '@modules/reducers';
import {
  clearSupportTriggerThreshold,
  getRefundRepartitionRequest,
  selectRefundRepartitionAllSupport,
  selectRefundRepartitionSupport,
  setRefundRepartitionAmount,
} from '@modules/refund/available-refund/actions/refundActions';
import { RefundActionsType } from '@modules/refund/available-refund/actions/refundActionsTypes';
import { getRefundRepartitionData } from '@modules/refund/available-refund/selectors';

import { RefundRepartitionPageComponent } from './RefundRepartitionPage.component';

const mapStateToProps = (state: AppState) => ({
  refundRepartitionData: getRefundRepartitionData(state),
  isRefundRepartitionDataLoading: state.isLoading[RefundActionsType.GET_REFUND_REPARTITION_REQUEST],
});
const mapDispatchToProps = ({
  onGetRefundRepartitionDataRequest: getRefundRepartitionRequest,
  onSelectRefundRepartitionAllSupport: selectRefundRepartitionAllSupport,
  onSelectRefundRepartitionSupport: selectRefundRepartitionSupport,
  ClearRefundSupportTriggerThresholdData: clearSupportTriggerThreshold,
  onSetRefundRepartitionAmount: setRefundRepartitionAmount,
});
export const RefundRepartitionPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(RefundRepartitionPageComponent);
