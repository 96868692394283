import { useMemo } from "react";
import { StyleSheet } from "react-native";

import { useTheme } from "@ere-uilib/styles";

export const useStyles = (
  isSelected:boolean,
  hovered: boolean
) => {
  const theme = useTheme();

  const styles = useMemo(() => StyleSheet.create({
    containerStyle:
      {
        flexDirection: 'row',
        alignItems: 'center',
        height: 50,
        paddingLeft: theme.metrics.spacing.m,
        backgroundColor: (hovered) ? "rgba(34, 84, 58, 0.1)" : ((isSelected) ? "rgba(0, 136, 89, 0.1)" : undefined),
        borderRightWidth: (hovered || isSelected) ? theme.metrics.borderRadius.s : undefined,
        borderRightColor: (hovered) ? "rgba(34, 84, 58, 1)" : ((isSelected) ? "rgba(0, 136, 89, 1)" : undefined),
      },
    textStyle:
      {
        fontSize: theme.fonts.fontSize.button.t1,
        color: theme.colors.basics.black
      },
    iconStyle:
      {
        width: 45,
        textAlign: 'center'
      },
  }), [theme, isSelected, hovered]);

  return styles;
};