import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

export const useStyles = () => {
  const theme = useTheme();

  const styles = useMemo(
    () =>
      StyleSheet.create({
        errorContainer: {
          marginTop: theme.metrics.spacing.s
        }
      }),
    [theme]
  );

  return styles;
};
