import {
  CompareFundsModal,
  ManagementWrapper,
  NotificationIconTypeEnum,
  SelectTypeEnum,
  SimpleButton
} from '@components/index';
import { OperationHistoryTypesEnum, PlanFamillyList } from '@constants/index';
import { RouteNames } from '@constants/navigation';
import { createUseStyles, ThemeType, useTheme } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';
import { ManagmentType } from '@modules/common/types';
import { RefundRepartitionSupportsState } from '@modules/refund/available-refund/types';
import { RootNavigation } from '@navigation/RootNavigation';
import React, { useCallback, useState } from 'react';
import { Platform, StyleProp, View, ViewStyle } from 'react-native';


interface RenderManagementStyle {
  backGroundColorCustom?: string
  wrapperContent?: StyleProp<ViewStyle>
  compareButtonContainerStyle?: StyleProp<ViewStyle>
  compareButtonStyle?: StyleProp<ViewStyle>
}

interface RenderManagementProps extends RenderManagementStyle {
  management: Partial<ManagmentType>
  isManagementSelectable?: boolean
  isVisibleManagementHeader?: boolean
  isNotSelectable?: boolean
  onEditMasterAmountPress?: () => void
  onSelectSupportMasterChange?: () => void
  onLinkUnderFieldPress?: () => void
  onSelectChange?: () => void
  isFieldAmountPercentage?: boolean
  hasIncorrectDrivingData?: boolean
  fieldAmountPercent?: number
  isSupportMasterSelectable?: boolean
  canCompareFunds?: boolean
  isDisplayingExtraManagement?: boolean
  linkUnderfieldLabel?: string
  cardTitle?: string
  isExtraManagementEditable?: boolean
}

interface RenderManagementContext {
  theme: ThemeType
  backGroundColorCustom?: string
}

export const Management: React.FC<React.PropsWithChildren<RenderManagementProps>> = ({
  management,
  backGroundColorCustom,
  hasIncorrectDrivingData,
  isVisibleManagementHeader,
  children,
  onSelectSupportMasterChange,
  fieldAmountPercent,
  isFieldAmountPercentage,
  onEditMasterAmountPress = () => { },
  onLinkUnderFieldPress = () => { },
  onSelectChange = () => { },
  isSupportMasterSelectable = true,
  canCompareFunds = true,
  isDisplayingExtraManagement,
  linkUnderfieldLabel,
  cardTitle,
  isExtraManagementEditable,
  isManagementSelectable
}) => {
  const { formatMessage } = useTranslation();
  const theme = useTheme();
  const getStyles = (
    context?: RenderManagementContext,
    style?: RenderManagementStyle
  ): RenderManagementStyle => ({
    wrapperContent: [
      {
        paddingEnd: Platform.OS !== "web" ? context?.theme.metrics.spacing.xm : context?.theme.metrics.spacing.none,
        minWidth: '100%',
      },
      style?.wrapperContent,
    ],
    compareButtonContainerStyle: [
      {
        borderBottomWidth: 1,
        borderBottomColor: context?.theme.colors.basics.grey.c100,
        alignItems: 'center',
      },
      style?.compareButtonContainerStyle,
    ],
    compareButtonStyle: [
      {
        marginBottom: context?.theme.metrics.spacing.xm,
        marginTop: context?.theme.metrics.spacing.xm,
      },
      style?.compareButtonStyle,
    ],
  });

  const useStyles = createUseStyles(getStyles);
  const styles = useStyles(
    {
      theme,
      backGroundColorCustom,
    },
    {}
  );

  const [fundsToCompare, setFundsToCompare] = useState<
    RefundRepartitionSupportsState[] | undefined
  >();

  const [displayCompareFundsModal, setDisplayCompareFundsModal] =
    useState(false);

  const managementAmount = management.formValues?.amount || management.totalAmount;
  const isManagementSelected = management?.formValues?.isSelected;
  const managementId = management.id ?? '';
  const isEditable = (isManagementSelected && isSupportMasterSelectable) || isExtraManagementEditable;

  const handleLinkButtonPress = useCallback(() => {
    RootNavigation.replace(RouteNames.RedirectOperationPage, {
      provider: PlanFamillyList.ES,
      operationType: OperationHistoryTypesEnum.ARBITRATION
    })
  }, []);

  return (
    <>
      <ManagementWrapper
        hasIncorrectDrivingData={hasIncorrectDrivingData}
        backGroundColorCustom={backGroundColorCustom}
        cardTitle={cardTitle}
        fieldAmount={managementAmount}
        fieldAmountPercent={fieldAmountPercent}
        isDisplayingExtraManagement={isDisplayingExtraManagement}
        isExpandable={isVisibleManagementHeader}
        isExtraManagementEditable={isEditable}
        isFieldAmountPercentage={isFieldAmountPercentage}
        isSelectable={isManagementSelectable}
        isSelected={isManagementSelected}
        isSupportMasterSelectable={isSupportMasterSelectable}
        isSupportMasterSelected={isManagementSelected}
        key={`managaement${managementId}`}
        linkUnderfieldLabel={linkUnderfieldLabel}
        mainStyle={styles.wrapperContent}
        onEditMasterAmountPress={onEditMasterAmountPress}
        onLinkUnderFieldPress={onLinkUnderFieldPress}
        onSelectChange={onSelectChange}
        onSelectSupportMasterChange={onSelectSupportMasterChange}
        selectType={SelectTypeEnum.RADIO}
        title={management.label}
        totalAmount={management.totalAmount}
        type={NotificationIconTypeEnum.WARNING}
        visibleHeader={isVisibleManagementHeader}
        onLinkButtonPress={handleLinkButtonPress}
      >
        {canCompareFunds && <View style={styles.compareButtonContainerStyle}>
          <SimpleButton
            containerStyle={styles.compareButtonStyle}
            design="outlined"
            onPress={() => {
              setFundsToCompare(management.supports);
              setDisplayCompareFundsModal(true);
            }}
            size="small"
            title={formatMessage({
              id: 'MyFunds_Screen1Compare_Header_Title',
            })}
          />
        </View>}
        {children}
        <CompareFundsModal
          fundsToCompare={fundsToCompare?.map(fund => {
            return {
              isinCode: fund.supportIsin,
              supportName: fund.supportName,
              addedValue: 0,
              hasContribution: fund.hasContribution,
              amount: fund.amount.amount,
              hasNoFundSheet: fund.hasNoFundSheet,
              source: fund.source
            };
          })}
          modalIsVisible={displayCompareFundsModal}
          onCloseModal={() => {
            setDisplayCompareFundsModal(false);
          }}
        />
      </ManagementWrapper>
    </>
  );
};