import { NewsFeedTypeEnum } from "@ere-uilib/enums";

const labelAndTypeCorrelationObject = [
  { tabName: 'MyFil_Tab_all_label', type: NewsFeedTypeEnum.ALL },
  { tabName: 'MyFil_Tab_Notif_label', type: NewsFeedTypeEnum.ALERT },
  { tabName: 'MyFil_Tab_Operation_label', type: NewsFeedTypeEnum.OPERATION },
  { tabName: 'MyFil_Tab_Infos_label', type: NewsFeedTypeEnum.OCD },
];

export {
  labelAndTypeCorrelationObject
}