import { connect } from 'react-redux';

import { AppState } from '@modules/index';
import { getError } from '@modules/dashboard/selectors';

import { BudgetInsightErrorComponent } from './BudgetInsightErrorPage.component';

const mapStateToProps = (state: AppState) => {
  return {
    errors: {
      budgetInsightError: getError('budgetInsightError', state)
    }
  };
};

const mapDispatchToProps = {};

export const BudgetInsightErrorPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(BudgetInsightErrorComponent);
