import { FC } from 'react';

import { HtmlStyledRenderConnectedContainer, NotificationHard, ThemeType } from '@components/index';
import { PlanFamillyList } from '@constants/index';
import { NotificationIconTypeEnum } from '@ere-uilib/atoms';
import { useTranslation } from '@ere-uilib/translations';
import { FilterPropertiesType, OperationDecisionState } from '@modules/common/types';
import { InstallmentRepartitionState } from '@modules/installment/types';

import { InstallmentRepartitionPageStyle } from '../interfaces';

type AlertProps = {
  installmentRepartitionData: InstallmentRepartitionState | null | undefined;
  installmentDecision: OperationDecisionState | undefined;
  installmentDecisionAllResponses: FilterPropertiesType | null;
  theme: ThemeType;
  styles: InstallmentRepartitionPageStyle;
};

export const Alert: FC<React.PropsWithChildren<AlertProps>> = ({
  installmentRepartitionData,
  installmentDecision,
  installmentDecisionAllResponses,
  styles,
  theme
}) => {
  const { formatMessage, getMessageRaw } = useTranslation();
  const isES = installmentRepartitionData?.planFamily === PlanFamillyList.ES;
  const isRC = installmentRepartitionData?.planFamily === PlanFamillyList.RC;

  const hasOnGoingRcVvp = !!installmentRepartitionData?.advancedProperties?.HasOnGoingRcVvp;
  const currentDispositif = installmentDecision?.selectedDispositif?.[0];
  const isHideInformationVvInf25 = !!currentDispositif?.filterProperties?.HideInformationVvInf25;

  return (
    <>
      {isES && !isHideInformationVvInf25 && (
        <NotificationHard
          containerStyle={[
            styles.notificationContainerStyle,
            { marginBottom: theme.metrics.spacing.xm }
          ]}
          text={formatMessage({
            id: 'VV_repartition_warning_ceilingES_label'
          })}
          type={NotificationIconTypeEnum.WARNING}
        />
      )}
      {isES && (
        <NotificationHard
          containerStyle={[
            styles.notificationContainerStyle,
            { marginBottom: theme.metrics.spacing.xm }
          ]}
          title={formatMessage({ id: 'VV_repartition_warning_LAB_ES_title' })}
          type={NotificationIconTypeEnum.WARNING}>
          <HtmlStyledRenderConnectedContainer
            baseFontStyle={{ fontFamily: theme.fonts.fontFamily.light }}
            html={getMessageRaw({
              id: 'VV_repartition_warning_LAB_ES_label'
            })}
          />
        </NotificationHard>
      )}
      {isRC && (
        <NotificationHard
          containerStyle={[
            styles.notificationContainerStyle,
            { marginBottom: theme.metrics.spacing.xm }
          ]}
          title={formatMessage({ id: 'VV_repartition_warning_LAB_RC_title' })}
          type={NotificationIconTypeEnum.WARNING}>
          <HtmlStyledRenderConnectedContainer
            baseFontStyle={{ fontFamily: theme.fonts.fontFamily.light }}
            html={getMessageRaw({
              id: 'VV_repartition_warning_LAB_RC_label'
            })}
          />
        </NotificationHard>
      )}
      {hasOnGoingRcVvp && installmentDecisionAllResponses?.VVP && (
        <NotificationHard
          containerStyle={styles.notificationContainerStyle}
          text={formatMessage({ id: 'VV_repartition_warning_VVP_RC_label' })}
          type={NotificationIconTypeEnum.WARNING}
        />
      )}
    </>
  );
};
