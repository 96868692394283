import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useScreenSizes, useTheme } from '@ere-uilib/styles';

export function useStyles() {
  const theme = useTheme();
  const { isDesktop, isTablet } = useScreenSizes();

  const styles = useMemo(
    () =>
      StyleSheet.create({
        title: {
          marginBottom: theme.metrics.spacing.s
        },
        cardContentInputsWrapper: {
          flex: 1,
          ...((isDesktop || isTablet) && {
            flexDirection: "row",
            justifyContent: 'space-between',
          })
        },
        rowItemStyle: {
          flex: 1,
          flexDirection: 'row',
          marginTop: 5,
          paddingRight: theme.metrics.spacing.l,
          ...(isDesktop && {
            justifyContent: 'flex-start',
            width: '80%'
          })
        },
        itemTitleText: {
          color: theme.colors.text.primary.c500,
          fontWeight: '400',
          fontStyle: 'normal'
        },
        emptyDueDateTextStyle: {
          color: theme.colors.notifications.warning.c500
        },
        rowItemContentContainer: {
          flex: 1,
          ...((isDesktop) && {
            flexDirection: 'row',
            justifyContent: 'flex-start',
            padding: 0
          })
        },
        itemValueText: {
          ...(isDesktop && {
            marginLeft: theme.metrics.spacing.m
          }),
          ...(!isDesktop && {
            marginTop: theme.metrics.spacing.xs
          })
        },
        selectContainer: {
          flex: 1,
          minWidth: theme.metrics.spacing.xxl,
          marginTop: theme.metrics.spacing.s,
          marginLeft: (isDesktop || isTablet) ? theme.metrics.spacing.xs : undefined,

        },
        selectItemsContainerStyle: {
          marginBottom: 0,
        },
        pickerText: {
          fontSize: theme.fonts.fontSize.title.t7
        },
        pickerTextContainer: {
          paddingHorizontal: theme.metrics.spacing.xs
        },
        selectTitle: {
          fontFamily: theme.fonts.fontFamily.regular,
          fontSize: theme.fonts.fontSize.title.t6
        },
        disabledTitleStyle: {
          fontFamily: theme.fonts.fontFamily.regular,
          fontSize: theme.fonts.fontSize.title.t6,
          color: theme.colors.text.disabled.c500
        },
        selectedItem: {
          height: theme.metrics.spacing.big,
          paddingHorizontal: theme.metrics.spacing.m,
        },
        inputContainer: {
          flex: 1
        },
        errorContainer: {
          flex: 1,
          flexDirection: 'row',
          margin: 0,
          padding: 0,
          marginTop: isDesktop ? theme.metrics.spacing.xxm : theme.metrics.spacing.xm,
          marginLeft: theme.metrics.spacing.s,
          alignItems: 'center'
        },
        errorText: {
          color: theme.colors.notifications.warning.c500,
          marginLeft: theme.metrics.spacing.s
        }
      }),
    [theme, isDesktop, isTablet]
  );

  return styles;
}
