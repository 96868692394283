import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

export const useStyles = () => {
  const theme = useTheme();
  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          flexDirection: 'row',
          marginVertical: theme.metrics.spacing.s
        },
        rightRadioButton: {
          marginLeft: theme.metrics.spacing.l
        },
      }),
    [theme]
  );

  return styles;
};
