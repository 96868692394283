import React from 'react';
import {RelationShipEnteringNavigatorInterface, RelationShipEnteringNavigatorRouteNames} from '@navigation/Interfaces/RelationshipEnteringNavigatorInterface';
import {useDefaultScreensOptions} from '@navigation/useDefaultScreensOptions';
import {
  AccountCreationSuccessPage,
  AccountTermsPage, ChooseIdentificationEmailCodePage,
  ChooseIdentificationMailPage, ChooseIdentificationPhoneCodePage,
  ChooseIdentificationPhonePage, ChoosePasswordPage,
  CompanyBenefitsPage, FConnectSignUp, Personeo,
  ValidateSignupThankYouPage, ValidationSignupEmailCodePage,
  ValidationSignupPage, ValidationSignupPhoneCodePage, Visiogo, WelcomePage
} from '@pages/Walkthrough';
import {createStackNavigator} from '@react-navigation/stack';

const Stack = createStackNavigator<RelationShipEnteringNavigatorInterface>();

export const RelationshipEntringProcess = () => {
  const defaultScreensOptions = useDefaultScreensOptions();
  return (
    <Stack.Navigator
      initialRouteName={RelationShipEnteringNavigatorRouteNames.Welcome}
      screenOptions={{
        gestureEnabled: false,
        headerShown: false,
        cardStyle: {
          backgroundColor: 'transparent',
        },
      }}>
      <Stack.Screen
        component={WelcomePage}
        name={RelationShipEnteringNavigatorRouteNames.Welcome}
        options={defaultScreensOptions.Welcome}
      />
      <Stack.Screen
        component={FConnectSignUp}
        name={RelationShipEnteringNavigatorRouteNames.FConnectSignUp}
        options={defaultScreensOptions.FConnectSignUp}
      />
      <Stack.Screen
        component={ValidationSignupEmailCodePage}
        name={RelationShipEnteringNavigatorRouteNames.ValidationSignupEmailCode}
        options={defaultScreensOptions.OidcLogoutCallbackRedirect}
      />
      <Stack.Screen
        component={ValidationSignupPhoneCodePage}
        name={RelationShipEnteringNavigatorRouteNames.ValidationSignupPhoneCode}
        options={defaultScreensOptions.OidcLogoutCallbackRedirect}
      />
      <Stack.Screen
        component={ValidationSignupPage}
        name={RelationShipEnteringNavigatorRouteNames.ValidateSignup}
        options={defaultScreensOptions.ValidateSignup}
      />
      <Stack.Screen
        component={ValidateSignupThankYouPage}
        name={RelationShipEnteringNavigatorRouteNames.ValidateSignupThankYou}
        options={defaultScreensOptions.ValidateSignupThankYou}
      />
      <Stack.Screen
        component={ChooseIdentificationMailPage}
        name={RelationShipEnteringNavigatorRouteNames.ChooseIdentificationMail}
        options={defaultScreensOptions.ChooseIdentificationMail}
      />
      <Stack.Screen
        component={ChooseIdentificationPhonePage}
        name={RelationShipEnteringNavigatorRouteNames.ChooseIdentificationPhone}
        options={defaultScreensOptions.ChooseIdentificationPhone}
      />
      <Stack.Screen
        component={ChooseIdentificationEmailCodePage}
        name={RelationShipEnteringNavigatorRouteNames.ChooseIdentificationEmailCodePage}
        options={defaultScreensOptions.ChooseIdentificationEmailCode}
      />
      <Stack.Screen
        component={ChooseIdentificationPhoneCodePage}
        name={RelationShipEnteringNavigatorRouteNames.ChooseIdentificationPhoneCodePage}
        options={defaultScreensOptions.ChooseIdentificationPhoneCode}
      />
      <Stack.Screen
        component={ChoosePasswordPage}
        name={RelationShipEnteringNavigatorRouteNames.ChoosePassword}
        options={defaultScreensOptions.ChoosePassword}
      />
      <Stack.Screen
        component={AccountCreationSuccessPage}
        name={RelationShipEnteringNavigatorRouteNames.AccountCreationSuccess}
        options={defaultScreensOptions.AccountCreationSuccess}
      />
      <Stack.Screen
        component={AccountTermsPage}
        name={RelationShipEnteringNavigatorRouteNames.AccountTerms}
        options={defaultScreensOptions.AccountTerms}
      />
      <Stack.Screen
        component={CompanyBenefitsPage}
        name={RelationShipEnteringNavigatorRouteNames.CompanyBenefits}
        options={defaultScreensOptions.CompanyBenefits}
      />
      <Stack.Screen
        component={Personeo}
        name={RelationShipEnteringNavigatorRouteNames.OnboardingPersoneo}
        options={defaultScreensOptions.OnboardingPersoneo}
      />
      <Stack.Screen
        component={Visiogo}
        name={RelationShipEnteringNavigatorRouteNames.OnboardingVisiogo}
        options={defaultScreensOptions.OnboardingVisiogo}
      />
    </Stack.Navigator>
  );
};
