import { StackNavigationProp } from '@react-navigation/stack';
import { useCallback, useEffect, useState } from 'react';

import { RouteNames } from '@constants/navigation';
import { ContactPreferencesRequest } from '@modules/profile/actions/profileActions';
import { AppNavigatorInterface } from '@navigation/Interfaces';

interface UseController {
  navigation: StackNavigationProp<AppNavigatorInterface>
  onSubmit: (values: ContactPreferencesRequest) => void | undefined
  onPressButtonSuccessModal: () => void
  hasNotificationsActivated: boolean
  emailOptIn: boolean
  smsOptIn: boolean
  getContactPreferencesRequest: () => void
  toDoActionType?: string
  demandId?: string
}

export function useController({
  onSubmit,
  onPressButtonSuccessModal,
  navigation,
  hasNotificationsActivated,
  emailOptIn,
  smsOptIn,
  toDoActionType,
  demandId,
  getContactPreferencesRequest,
}: UseController) {
  const [optInEmail, setOptInEmail] = useState(emailOptIn);
  const [optInSms, setOptInSms] = useState(smsOptIn);
  const [isNotificationsActivated, setIsNotificationsActivated] = useState(hasNotificationsActivated);
  const [isUpdated, setUpdated] = useState(false);
  const isSubmitActive =  isUpdated && (!isNotificationsActivated || optInSms || optInEmail);

  useEffect(() => {
    getContactPreferencesRequest();
  }, [getContactPreferencesRequest]);

  useEffect(() => {
    setIsNotificationsActivated(hasNotificationsActivated);
  }, [hasNotificationsActivated]);

  useEffect(() => {
    setOptInEmail(emailOptIn);
  }, [emailOptIn]);

  useEffect(() => {
    setOptInSms(smsOptIn);
  }, [smsOptIn]);

  const handlePressReceiveMessage = useCallback(() => {
    setIsNotificationsActivated(true);
    setUpdated(true);
  }, []);

  const handlePressNotReceiveMessage = useCallback(() => {
    setOptInEmail(false);
    setOptInSms(false);
    setIsNotificationsActivated(false);
    setUpdated(true);
  }, []);

  const handlePressEmail = useCallback(() => {
    if (optInSms || !optInEmail) {
      setIsNotificationsActivated(true);
    }

    setOptInEmail(!optInEmail);
    setUpdated(true);
  }, [optInEmail, optInSms]);

  const handlePressSMS = useCallback(() => {
    if (optInEmail || !optInSms) {
      setIsNotificationsActivated(true);
    }

    setOptInSms(!optInSms);
    setUpdated(true);
  }, [optInEmail, optInSms]);

  const handleSubmit = useCallback(() => {
    onSubmit({
      hasNotificationsActivated: isNotificationsActivated,
      emailOptIn: optInEmail,
      smsOptIn: optInSms,
      toDoPostSuccessActionType: toDoActionType,
      demandId: demandId,
    });
  }, [onSubmit, isNotificationsActivated, optInEmail, optInSms, toDoActionType, demandId]);

  const handlePressButtonSuccessModal = useCallback(() => {
    onPressButtonSuccessModal();
    navigation.replace(RouteNames.BottomTabNavigator, {
      screen: RouteNames.ProfileStack,
      params: {
        screen: RouteNames.Profile,
      },
    });
  }, [onPressButtonSuccessModal, navigation]);

  return {
    isSubmitActive,
    optInSms,
    optInEmail,
    isNotificationsActivated,
    handlePressReceiveMessage,
    handlePressNotReceiveMessage,
    handlePressEmail,
    handlePressSMS,
    handleSubmit,
    handlePressButtonSuccessModal,
  };
}
