import React from 'react';
import { Linking, View } from 'react-native';
import { useSelector } from 'react-redux';

import { Title, Text, Link, HtmlRenderer, SVGLocalLoader, IconEnum } from '@components/index';
import { getLanguage } from '@modules/label/selectors';

import { useStyles } from './useStyles';
import { ContactCard } from '../../ContactCard';
import { PhoneContactComponent } from '../PhoneContact';

interface Props {
  title: string;
  description: string;
  phoneNumber: string;
  contactPhoneInformation: string;
  contactPhoneNotice: string;
  linkDescription?: string;
  link: string;
  testId: string;
}

export const PhoneCardContent: React.FC<Props> = ({
  title,
  description,
  contactPhoneInformation,
  phoneNumber,
  linkDescription,
  contactPhoneNotice,
  testId,
  link
}) => {
  const styles = useStyles();
  const handleContactClick = (url: string) => {
    Linking.openURL(url);
  };
  const isFR = useSelector(getLanguage) === 'fr';
  return (
    <ContactCard>
      <Title
        style={styles.textCenteredStyle}
        testID={testId}
        variant={'t6'}
        weight="bold">
        {title}
      </Title>
      <View style={styles.separator} />
      <HtmlRenderer html={description} />
      <PhoneContactComponent
        description={contactPhoneNotice}
        phoneNumber={phoneNumber}
      />
      {isFR && (
        <View style={styles.footerContainer}>
          <SVGLocalLoader
            height={20}
            maxWidth={20}
            name={IconEnum.MALENTENDANT}
          />
          <View style={styles.footerTextStyle}>
            <Title variant="t6">{contactPhoneInformation}</Title>
            <Link
              onPress={() => handleContactClick(link)}
              textVariant="t4">
              {linkDescription}
            </Link>
          </View>
        </View>
      )}
    </ContactCard>
  );
};
