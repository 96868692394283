import * as actions from '@modules/retirement/actions/retirementActionsTypes';
import { RetirementActionsType } from '@modules/retirement/actions/retirementActionsTypes';

import { RetirementDecision, RetirementErrorState, RetirementDecisionSubmitForm, RetirementPlanDocumentType } from '../types';

export function getRetirementDecisionRequest(): actions.GetRetirementDecisionRequestAction {
  return {
    type: RetirementActionsType.GET_RETIREMENT_DECISION_REQUEST
  };
}

export function getRetirementDecisionSuccess(
  decision: RetirementDecision[]
): actions.GetRetirementDecisionSuccessAction {
  return {
    type: RetirementActionsType.GET_RETIREMENT_DECISION_SUCCESS,
    decision
  };
}

export function getRetirementDecisionFailure(
  error: RetirementErrorState
): actions.GetRetirementDecisionFailureAction {
  return {
    type: RetirementActionsType.GET_RETIREMENT_DECISION_FAILURE,
    error
  };
}

export function postRetirementDecisionRequest(
  formData: RetirementDecisionSubmitForm[]
): actions.PostRetirementDecisionRequestAction {
  return {
    type: RetirementActionsType.POST_RETIREMENT_DECISION_REQUEST,
    formData
  };
}

export function postRetirementDecisionSuccess(
  formDecision: RetirementPlanDocumentType[]
): actions.PostRetirementDecisionSuccessAction {
  return {
    type: RetirementActionsType.POST_RETIREMENT_DECISION_SUCCESS,
    formDecision
  };
}

export function postRetirementDecisionFailure(
  error: RetirementErrorState
): actions.PostRetirementDecisionFailureAction {
  return {
    type: RetirementActionsType.POST_RETIREMENT_DECISION_FAILURE,
    error
  };
}

export function clearRetirementData(): actions.ClearRetirementDataAction {
  return {
    type: RetirementActionsType.CLEAR_RETIREMENT_DATA,
  };
}
