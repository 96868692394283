import React, { useCallback } from 'react';
import { ListRenderItem } from 'react-native';

import { Card, Carousel, ToolCard, ToolCardLoader } from '@components/index';
import { DashboardImagePlaceholder } from '@ere-uilib/organisms/cards/DashboardImagePlaceholder';
import { useTheme } from '@ere-uilib/styles'
import { ToolState } from '@modules/dashboard/types';

import { ToolCardListProps as Props } from './interface';
import { useStyles } from './styles';

export const ToolCardList = ({
  error,
  onGetTools,
  toolsData,
  useUrlLink,
  containerStyle,
  isToolsApiLoading = false,
  ...props
}: Props<ToolState>) => {
  const theme = useTheme();
  const toolsDataLength = toolsData.length;
  const { linkToUrl } = useUrlLink();

  const styles = useStyles({ toolsDataLength });

  const renderItem = useCallback<ListRenderItem<ToolState>>(({ item }) => {
    return (
      <ToolCard
        buttonText={item.buttonText}
        containerStyle={[styles.toolsItemContainer]}
        content={item.content}
        horizontal={false}
        onPress={() => linkToUrl(item.url)}
        pictureUrl={item.pictureUrl}
        testId={item.url}
        title={item.title}
      />
    )
  }, [containerStyle, linkToUrl, styles.toolsItemContainer])

  const renderRetryAction = useCallback(() => {
    if (!error?.message || isToolsApiLoading) return null;

    return <DashboardImagePlaceholder buttonAction={onGetTools} />
  }, [error, isToolsApiLoading, onGetTools])

  const renderToolsLoader = useCallback(() => {
    if (!isToolsApiLoading) return null;

    return (
      <Card containerStyle={styles.loaderContainerWrapper}>
        <ToolCardLoader />
      </Card>
    )
  }, [isToolsApiLoading])

  const renderTools = useCallback(() => {
    if (!(!isToolsApiLoading && error?.message === '')) return null;

    return (
      <Carousel<ToolState>
        arrowsSize="large"
        bulletsSize={theme.metrics.spacing.m}
        containerStyle={containerStyle}
        data={toolsData}
        keyExtractor={(item, index) => 'tool' + `${index}${item.url}`}
        listContentContainerStyle={styles.toolsListContentContainer}
        renderItem={renderItem}
        {...props}
      />
    );

  }, [
    props,
    theme,
    error,
    styles,
    containerStyle,
    isToolsApiLoading,
    toolsData,
    renderItem
  ]);

  return (
    <>
      {renderTools()}
      {renderRetryAction()}
      {renderToolsLoader()}
    </>
  )
};
