import React, { useCallback, useEffect } from 'react';
import { ScrollView, View } from 'react-native';

import {
  Modal,
  Title,
  Paragraph,
  DashedLine,
  NotificationIconTypeEnum,
  HtmlRenderer,
} from '@ere-uilib/atoms';
import {
  ExpendableGreen,
  ModalFrame,
  NotificationHard,
  SimpleButton,
  TextField,
} from '@ere-uilib/molecules';
import { useTheme, createUseStyles, useScreenSizes } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';
import { useAmountFieldParseManager } from '@ere-uilib/utils';

import { useController } from './component';
import { Props } from './interfaces';
import { getStyles } from './styles';

export const TriggerThresholdModal = ({
  visible = false,
  data,
  onClose,
  onValid,
}: Props) => {
  const { formatMessage, getMessageRaw, formatCurrencyNumber, formatDate } = useTranslation();
  const theme = useTheme();
  const { windowHeight, isMobile } = useScreenSizes();
  const {
    title,
    amount,
    valuationDate,
    sharesValue,
    triggerPointDate,
    thresholdTriggerType,
    thresholdTriggerTitle,
    course
  } = data;

  const styles = useStyles(
    { theme, isMobile, windowHeight },
    {}
  );

  const {
    amountNumber,
    amountString,
    initialAmountString,
    setAmountString,
  } = useAmountFieldParseManager({
    initialAmount: amount,
    maxDecimal: 2,
  });

  const {
    dictionnary,
    aribrationParagraphText,
    refundParagraphText,
    isArbitrationCourse,
    handleSubmitButtonPress
  } = useController({
    thresholdTriggerType,
    thresholdTriggerTitle,
    title,
    course,
    amountNumber,
    onClose,
    onValid
  })

  useEffect(() => {
    if (visible) {
      setAmountString(initialAmountString);
    }
  }, [visible]);

  const renderHeader = useCallback(() => (
    <View style={styles.headerContainer}>
      <Title
        style={styles.modalTitle}
        variant="t6"
        weight="bold"
      >{title}</Title>
    </View>
  ), [styles.headerContainer, styles.modalTitle, title]);

  const renderImageContainer = useCallback(() => {
    return <HtmlRenderer html={getMessageRaw({ id: dictionnary.exampleExpandable.url })} />
  }, [dictionnary.exampleExpandable.url, getMessageRaw])

  const renderBodyContent = useCallback(() => (
    <>
      <Title
        style={styles.subtitleStyle}
        variant="t6"
      >{formatMessage({ id: dictionnary.remboursementSeuilDeclenchSubtitle })}</Title>
      <View style={styles.paragraphAndDashedLine}>
        <Paragraph
          style={styles.paragraphTextStyle}
          variant="t3"
          weight="light"
        >{formatMessage(isArbitrationCourse ? aribrationParagraphText : refundParagraphText)}
        </Paragraph>
        <DashedLine
          dashColor={theme.colors.basics.grey.c200}
          dashGap={5}
          dashLength={5} />
      </View>
      <View style={styles.inputContainer}>
        <Title
          style={styles.dateAndValueStyle}
          variant="t6"
        >
          {formatMessage({
            id: dictionnary.remboursementSeuilDeclenchInfoFonds,
            values: {
              date_valeur: formatDate({
                value: valuationDate,
                options: {
                  dateStyle: 'short',
                },
              }),
              Valeur_liquidative_montant: formatCurrencyNumber({ 
                value: sharesValue,
                options: {
                  maximumFractionDigits: 4
              } }),
            },
          })}
        </Title>
        <TextField
          inputProps={{
            placeholder: formatMessage({ id: dictionnary.outbreakThresholdArbitrageInputDescription }),
            value: amountString || '',
            onChangeText: amount => setAmountString(amount),
          }}
          labelOnTop
          onCleanField={() => setAmountString('')}
        />
      </View>
      <NotificationHard
        containerStyle={styles.notificationContainer}
        text={
          formatMessage({
            id: dictionnary.remboursementSeuilDeclenchCardinfo, values: {
              date_de_fin_de_validite: formatDate({
                value: triggerPointDate,
              })
            }
          })
        }
        type={NotificationIconTypeEnum.WARNING}
      />
      <ExpendableGreen
        displaySupportsContainerStyle={styles.wrapperContainer}
        title={formatMessage({ id: dictionnary.exampleExpandable.title })}>
        <Paragraph
          style={styles.paragraphTextStyle}
          variant="t2"
          weight="light"
        >{formatMessage({ id: dictionnary.exampleExpandable.description })}
        </Paragraph>

        {renderImageContainer()}
      </ExpendableGreen>
    </>
  ), [
    amountString,
    aribrationParagraphText,
    dictionnary,
    formatCurrencyNumber,
    formatDate,
    formatMessage,
    isArbitrationCourse,
    refundParagraphText,
    renderImageContainer,
    setAmountString,
    sharesValue,
    styles,
    theme,
    triggerPointDate,
    valuationDate
  ])

  const renderSubmitButtons = useCallback(() => (
    <View style={styles.submitButtonsContainer} >
      <SimpleButton
        containerStyle={{ flex: 1 }}
        design="outlined"
        onPress={() => !!onClose && onClose()}
        title={formatMessage({ id: dictionnary.remboursementSeuilDeclenchAnnuler })}
      />
      <View style={styles.buttonSpacingView} />
      <SimpleButton
        containerStyle={{ flex: 1 }}
        disabled={!amountNumber}
        onPress={handleSubmitButtonPress}
        title={formatMessage({ id: dictionnary.remboursementSeuilDeclenchValider })}
      />
    </View>
  ), [amountNumber,
    dictionnary,
    formatMessage,
    handleSubmitButtonPress,
    onClose,
    styles
  ]);

  return (
    <Modal visible={visible}>
      <ModalFrame
        innerContainer={styles.modalFrame}
        mobilePosition="bottom"
      >
        {renderHeader()}
        <ScrollView>
          {renderBodyContent()}
          {renderSubmitButtons()}
        </ScrollView>
      </ModalFrame>
    </Modal>
  );
};

const useStyles = createUseStyles(getStyles);
