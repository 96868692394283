import { validateBankDetails } from '@ere-uilib/utils';

export const BIC = {
  placeholder: '(ex.ADFS FRPP XXX)',
  mask: [/\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/],
  getErrorMessageId: () => 'ERR_CB_04'
};

export const IBAN = {
  placeholder: '(ex.FR76 1234 2345 2542 2345 2423 234)',
  mask: [
    /\w/,
    /\w/,
    /\w/,
    /\w/,
    ' ',
    /\w/,
    /\w/,
    /\w/,
    /\w/,
    ' ',
    /\w/,
    /\w/,
    /\w/,
    /\w/,
    ' ',
    /\w/,
    /\w/,
    /\w/,
    /\w/,
    ' ',
    /\w/,
    /\w/,
    /\w/,
    /\w/,
    ' ',
    /\w/,
    /\w/,
    /\w/,
    /\w/,
    ' ',
    /\w/,
    /\w/,
    /\w/
  ],
  getErrorMessageId: ({
    IBANCountryCode,
    isValidIBAN,
    isSEPACountry
  }: Partial<ReturnType<typeof validateBankDetails>>) =>
    (!IBANCountryCode && 'ERR_CB_02') ||
    (!isValidIBAN && 'ERR_CB_01') ||
    (!isSEPACountry && 'ERR_CB_03') ||
    ''
};

