import { useMemo } from 'react'
import { StyleSheet } from 'react-native'

import { useTheme } from '@ere-uilib/styles'

export const useStyles = ({ isMobile }: { isMobile: boolean }) => {
  const theme = useTheme()
  return useMemo(
    () =>
      StyleSheet.create({
        histogramTitle: {
          marginTop: theme.metrics.spacing.l
        },
        eventContainer: {
          marginBottom: isMobile ? theme.metrics.spacing.s : 0
        },
        histogramSubtitle: {
          marginTop: theme.metrics.spacing.xm,
          marginBottom: theme.metrics.spacing.s
        },
        barChartContainerStyle: {
          maxWidth: 730
        },
        chartContainer: {
          borderBottomColor: theme.colors.basics.grey.c200,
          borderBottomWidth: 1,
          paddingBottom: 25
        }
      }),
    [theme]
  )
}
