import React from 'react';
import { View, StyleProp, ViewStyle, TextStyle } from 'react-native';

import { Text, Title } from '@ere-uilib/atoms';
import { InputEditAmmont } from '@ere-uilib/molecules/';
import { createUseStyles, ThemeType, useTheme, useScreenSizes } from '@ere-uilib/styles';
import { useTranslation } from '@translations/index';
interface DataProps {
  title: string;
  backgroundColor?: string;
  editable?: boolean;
  amount?: number;
  amountType?: 'currency' | 'percentage';
  contribution?: number;
  onEditAmountPress?: () => void;
  hasContributionError?: boolean;
}

type TotalSavingHeaderStyles = {
  containerStyle?: StyleProp<ViewStyle>;
  amountTextStyle?:StyleProp<TextStyle>;
  contributionTextStyle?:StyleProp<TextStyle>;
  leftContainerStyle?: StyleProp<ViewStyle>;
  rightContainerStyle?: StyleProp<ViewStyle>;
  titleStyle?: StyleProp<TextStyle>;
  editableViewStyle?: StyleProp<ViewStyle>;
};

type TotalSavingHeaderStylesContext = {theme?: any; isMobile?: boolean; backgroundColor?: string;};

type Props = DataProps & TotalSavingHeaderStylesContext & TotalSavingHeaderStyles;

export const TotalSavingHeader: React.FC<React.PropsWithChildren<Props>> =  ({
  title,
  containerStyle,
  amountTextStyle,
  contributionTextStyle,
  titleStyle,
  editableViewStyle,
  backgroundColor = '#9A9A9A',
  amount = 0,
  amountType = 'currency',
  editable,
  contribution,
  onEditAmountPress,
  hasContributionError,
}) => {
  const theme: ThemeType = useTheme();
  const { isMobile } = useScreenSizes();
  const styles = useStyles({ theme, isMobile, backgroundColor }, { containerStyle, amountTextStyle,
    contributionTextStyle, titleStyle, editableViewStyle });
  const { formatMessage, formatCurrencyNumber, formatPercentNumber } = useTranslation();

  return (
    <View style={styles.containerStyle}>
      <View style={styles.leftContainerStyle}>
        <Text style={styles.titleStyle}>
          {title}
        </Text>
      </View>

      <View style={styles.rightContainerStyle}>
        {
          editable ?
            <View style={styles.editableViewStyle}>
              <InputEditAmmont
                amount={amount}
                hasDarkBackground
                onPress={onEditAmountPress}
              />
              { typeof contribution === 'number' && (
                <Title
                  style={styles.contributionTextStyle}
                  variant="t7"
                >
                  {formatMessage({ id: 'repartition_support_contribution_label' })}
                  {'+ '}
                  {formatCurrencyNumber({ value: contribution })}
                </Title>
              )}
            </View>
            :
            <View>
              <Text
                style={styles.amountTextStyle}
                weight="bold">
                {
                  amountType ===   'currency' ?
                    formatCurrencyNumber({ value: amount ? amount : 0 })
                    :
                    amountType === 'percentage' &&
          formatPercentNumber({ value: amount ? amount : 0 })
                }
              </Text>
              {(!hasContributionError && typeof contribution === 'number' ) && (
                <Title
                  style={styles.contributionTextStyle}
                  variant="t7"
                >
                  {formatMessage({ id: 'repartition_support_contribution_label' })}
                  {formatCurrencyNumber({ value: contribution })}
                </Title>
              )}
              {hasContributionError && (
                <Title
                  style={styles.contributionTextStyle}
                  variant="t7"
                >
                  {formatMessage({ id: 'repartition_support_contribution_label' })}
                  - €
                </Title>
              )}
            </View>
        }
      </View>
    </View>
  );
};

const getStyles = (
  context?: TotalSavingHeaderStylesContext,
  style?: TotalSavingHeaderStyles,
): TotalSavingHeaderStyles => ({
  containerStyle: [{
    width: '100%',
    flexDirection: 'row',
    backgroundColor: context?.backgroundColor,
    paddingBottom: context?.theme.metrics.spacing.s,
  }, style?.containerStyle],
  amountTextStyle: [{
    textAlign: 'right',
    color: 'white',
    marginTop: 10.5,
  }, style?.amountTextStyle],
  leftContainerStyle: [{
    flex: 1,
    marginTop: 13.5,
    marginLeft: context?.theme.metrics.spacing.s,
  }, style?.leftContainerStyle],
  rightContainerStyle: [{
    alignItems: 'flex-end',
    marginRight: context?.theme.metrics.spacing.s,
    justifyContent: 'center',
  }, style?.rightContainerStyle],
  titleStyle: [{ color: 'white' },
    style?.titleStyle],
  editableViewStyle: [{
    marginTop: context?.theme.metrics.spacing.s,
  }, style?.editableViewStyle],
  contributionTextStyle: [
    {
      textAlign: 'right',
      color: 'white',
    },
    style?.contributionTextStyle,
  ],
});

const useStyles = createUseStyles(getStyles);
