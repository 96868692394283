import React, { useMemo } from 'react'
import { 
  StyleSheet,
  StyleProp,
  ViewStyle,
} from 'react-native';

export const useStyles = ({
  style
}:{
  style?: StyleProp<ViewStyle>
}) => {
  const styles = useMemo(()=>StyleSheet.create({
    style: {
      overflow: 'hidden',
      ...(style && StyleSheet.flatten(style)),
    },
    background: { 
      position: "absolute", 
      top: 0, 
      left: 0, 
      right: 0, 
      bottom: 0 
    }
  })
  , [style])

  return styles
};
