import { DispositifState, OperationDecisionState, ResponseType } from '@modules/common/types';
import {
  InstallmentAmountValidationParamsType,
  InstallmentCalculatedContributionState,
  InstallmentCalculContributionParamsType,
  InstallmentErrorState,
  InstallmentRepartitionState,
  PaymentModeDataState,
  PaymentModeState,
  PaymentResponseDataState
} from '@modules/installment/types';
import { OnGoingVVP } from '@modules/savings/types';

import { InitAllErrorsAction } from '@modules/error/actions/errorActionsTypes';

export enum InstallmentActionsType {
  GET_INSTALLMENT_DECISION_REQUEST = 'GET_INSTALLMENT_DECISION_REQUEST',
  GET_INSTALLMENT_DECISION_SUCCESS = 'GET_INSTALLMENT_DECISION_SUCCESS',
  GET_INSTALLMENT_DECISION_FAILURE = 'GET_INSTALLMENT_DECISION_FAILURE',
  UPDATE_QUESTION_WITH_SET_RESPONSE = 'UPDATE_QUESTION_WITH_SET_RESPONSE',
  BACK_TO_QUESTION = 'BACK_TO_QUESTION',
  GET_INSTALLMENT_REPARTITION_DATA_REQUEST = 'GET_INSTALLMENT_REPARTITION_DATA_REQUEST',
  GET_INSTALLMENT_REPARTITION_DATA_SUCCESS = 'GET_INSTALLMENT_REPARTITION_DATA_SUCCESS',
  GET_INSTALLMENT_REPARTITION_DATA_FAILURE = 'GET_INSTALLMENT_REPARTITION_DATA_FAILURE',
  SET_INSTALLMENT_REPARTITION_AMOUNT_REQUEST = 'SET_INSTALLMENT_REPARTITION_AMOUNT_REQUEST',
  SET_INSTALLMENT_REPARTITION_AMOUNT_SUCCESS = 'SET_INSTALLMENT_REPARTITION_AMOUNT_SUCCESS',
  SET_INSTALLMENT_REPARTITION_AMOUNT_FAILURE = 'SET_INSTALLMENT_REPARTITION_AMOUNT_FAILURE',
  SET_INSTALLMENT_REPARTITION_AMOUNT_MODAL_VISIBLE_STATE = 'SET_INSTALLMENT_REPARTITION_AMOUNT_MODAL_VISIBLE_STATE',
  SET_INSTALLMENT_PAYMENT_MODE_CHOICE = 'SET_INSTALLMENT_PAYMENT_MODE_CHOICE',
  INSTALLMENT_INIT_ERROR = 'INSTALLMENT_INIT_ERROR',
  GET_CALCULATED_CONTRIBUTION_DATA_REQUEST = 'GET_CALCULATED_CONTRIBUTION_DATA_REQUEST',
  GET_CALCULATED_CONTRIBUTION_DATA_SUCCESS = 'GET_CALCULATED_CONTRIBUTION_DATA_SUCCESS',
  GET_CALCULATED_CONTRIBUTION_DATA_FAILURE = 'GET_CALCULATED_CONTRIBUTION_DATA_FAILURE',
  INIT_CALCULATED_CONTRIBUTION_DATA = 'INIT_CALCULATED_CONTRIBUTION_DATA',
  CALCULATED_CONTRIBUTION_INIT_ERROR = 'CALCULATED_CONTRIBUTION_INIT_ERROR',
  SET_INSTALMENT_RISK_PROFIL = 'SET_INSTALMENT_RISK_PROFIL',
  SET_ELIGIBLE_DISPOSITIFS = 'SET_ELIGIBLE_DISPOSITIFS',
  SET_SELECTED_DISPOSITIF = 'SET_SELECTED_DISPOSITIF',
  GET_INSTALLMENT_PAYMENT_MODE_AND_BANK_ACCOUNT_DETAIL_REQUEST = 'GET_INSTALLMENT_PAYMENT_MODE_AND_BANK_ACCOUNT_DETAIL_REQUEST',
  GET_INSTALLMENT_PAYMENT_MODE_AND_BANK_ACCOUNT_DETAIL_SUCCESS = 'GET_INSTALLMENT_PAYMENT_MODE_AND_BANK_ACCOUNT_DETAIL_SUCCESS',
  GET_INSTALLMENT_PAYMENT_MODE_AND_BANK_ACCOUNT_DETAIL_FAILURE = 'GET_INSTALLMENT_PAYMENT_MODE_AND_BANK_ACCOUNT_DETAIL_FAILURE',
  CLEAR_INSTALLMENT_DATA = 'CLEAR_INSTALLMENT_DATA',
  SEND_INSTALLMENT_PAYMENT_DATA_REQUEST = 'SEND_INSTALLMENT_PAYMENT_DATA_REQUEST',
  SEND_INSTALLMENT_PAYMENT_DATA_SUCCESS = 'SEND_INSTALLMENT_PAYMENT_DATA_SUCCESS',
  SEND_INSTALLMENT_PAYMENT_DATA_FAILURE = 'SEND_INSTALLMENT_PAYMENT_DATA_FAILURE',
  CHECK_INSTALLMENT_PAYMENT_STATUS_FOR_ID_REQUEST = 'CHECK_INSTALLMENT_PAYMENT_STATUS_FOR_ID_REQUEST',
  CHECK_INSTALLMENT_PAYMENT_STATUS_FOR_ID_SUCCESS = 'CHECK_INSTALLMENT_PAYMENT_STATUS_FOR_ID_SUCCESS',
  CHECK_INSTALLMENT_PAYMENT_STATUS_FOR_ID_FAILURE = 'CHECK_INSTALLMENT_PAYMENT_STATUS_FOR_ID_FAILURE',
  ON_VVP_EDIT_REQUEST = 'ON_VVP_EDIT_REQUEST',
  ON_VVP_EDIT_FAILURE = 'ON_VVP_EDIT_FAILURE',
  ON_VVP_EDIT_SUCCESS = 'ON_VVP_EDIT_SUCCESS',
  ON_VVP_DELETE_REQUEST = 'ON_VVP_DELETE_REQUEST',
  ON_VVP_DELETE_FAILURE = 'ON_VVP_DELETE_FAILURE',
  ON_VVP_DELETE_SUCCESS = 'ON_VVP_DELETE_SUCCESS'
}

// installment - decision step

export interface GetInstallmentDecisionRequestAction {
  type: InstallmentActionsType.GET_INSTALLMENT_DECISION_REQUEST,
}


export interface GetInstallmentDecisionSuccesstAction {
  type: InstallmentActionsType.GET_INSTALLMENT_DECISION_SUCCESS,
  decision: OperationDecisionState
}


export interface GetInstallmentDecisionFailureAction {
  type: InstallmentActionsType.GET_INSTALLMENT_DECISION_FAILURE,
  error: InstallmentErrorState
}

//update question with set response


export interface UpdateInstallmentQuestionWithResponse {
  type: InstallmentActionsType.UPDATE_QUESTION_WITH_SET_RESPONSE,
  response: ResponseType
  index: number
  canNotBeAsked: boolean
}

// back to question


export interface BackToQuestion {
  type: InstallmentActionsType.BACK_TO_QUESTION,
  index: number
}

// installment - repartition step
export interface GetInstallmentRepartitionDataRequestAction {
  type: InstallmentActionsType.GET_INSTALLMENT_REPARTITION_DATA_REQUEST;
  routePlanId?: string
}

export interface GetInstallmentRepartitionDataSuccessAction {
  type: InstallmentActionsType.GET_INSTALLMENT_REPARTITION_DATA_SUCCESS;
  installmentRepartitionData: InstallmentRepartitionState;
}

export interface GetInstallmentRepartitionDataFailureAction {
  type: InstallmentActionsType.GET_INSTALLMENT_REPARTITION_DATA_FAILURE;
  error: InstallmentErrorState;
}

export interface SetInstallmentRepartitionAmountRequestAction {
  type: InstallmentActionsType.SET_INSTALLMENT_REPARTITION_AMOUNT_REQUEST;
  params: InstallmentAmountValidationParamsType
}

export interface SetInstallmentRepartitionAmountSuccessAction {
  type: InstallmentActionsType.SET_INSTALLMENT_REPARTITION_AMOUNT_SUCCESS
  calculatedContribution: InstallmentCalculatedContributionState | undefined
  installmentAmountValidationParams: InstallmentAmountValidationParamsType
}

export interface SetInstallmentRepartitionAmountFailureAction {
  type: InstallmentActionsType.SET_INSTALLMENT_REPARTITION_AMOUNT_FAILURE;
  error: InstallmentErrorState;
  params?: InstallmentAmountValidationParamsType;
}
export interface SetInstallmentRepartitionAmountModalVisibleStateAction {
  type: InstallmentActionsType.SET_INSTALLMENT_REPARTITION_AMOUNT_MODAL_VISIBLE_STATE;
  state: boolean;
}

// PAYMENT MODE CHOICE
export interface SetInstallmentPaymentModeChoiceAction {
  type: InstallmentActionsType.SET_INSTALLMENT_PAYMENT_MODE_CHOICE;
  paymentMode: PaymentModeState;
}

// INIT ERROR
export interface InstallmentInitErrorAction {
  type: InstallmentActionsType.INSTALLMENT_INIT_ERROR;
}

// contribution
export interface GetCalculatedContributionDataRequestAction {
  type: InstallmentActionsType.GET_CALCULATED_CONTRIBUTION_DATA_REQUEST;
  params: InstallmentCalculContributionParamsType
}

export interface GetCalculatedContributionDataSuccessAction {
  type: InstallmentActionsType.GET_CALCULATED_CONTRIBUTION_DATA_SUCCESS;
  contributionData: InstallmentCalculatedContributionState;
}

export interface GetCalculatedContributionDataFailureAction {
  type: InstallmentActionsType.GET_CALCULATED_CONTRIBUTION_DATA_FAILURE;
  error: InstallmentErrorState;
}

export interface InitCalculatedContributionDataAction {
  type: InstallmentActionsType.INIT_CALCULATED_CONTRIBUTION_DATA;
}

export interface CalculatedContributionInitErrorAction {
  type: InstallmentActionsType.CALCULATED_CONTRIBUTION_INIT_ERROR;
}

// Risk profil
export interface SetInstallmentRiskProfilAction {
  type: InstallmentActionsType.SET_INSTALMENT_RISK_PROFIL;
}

// SET ELIGIBLE DISPOSITIFS IN VERSEMENT TUNNEL

export interface SetEligibleDispositifs {
  type: InstallmentActionsType.SET_ELIGIBLE_DISPOSITIFS;
  elligibleDispositifs: DispositifState[]
}

// SET SELECTED DISPOSITIFS IN VERSEMENT TUNNEL

export interface SetSelectedDispositif {
  type: InstallmentActionsType.SET_SELECTED_DISPOSITIF;
  selectedDispositif: DispositifState[]
}

// installment - payment mode && bank account detail
export interface GetInstallmentPaymentModeAndBankAccountDetailRequestAction {
  type: InstallmentActionsType.GET_INSTALLMENT_PAYMENT_MODE_AND_BANK_ACCOUNT_DETAIL_REQUEST;
  routePlanId?: string
}

export interface GetInstallmentPaymentModeAndBankAccountDetailSuccessAction {
  type: InstallmentActionsType.GET_INSTALLMENT_PAYMENT_MODE_AND_BANK_ACCOUNT_DETAIL_SUCCESS;
  paymentModeData: PaymentModeDataState[];
}

export interface GetInstallmentPaymentModeAndBankAccountDetailFailureAction {
  type: InstallmentActionsType.GET_INSTALLMENT_PAYMENT_MODE_AND_BANK_ACCOUNT_DETAIL_FAILURE;
  error: InstallmentErrorState;
}

export interface ClearInstallmentDataAction {
  type: InstallmentActionsType.CLEAR_INSTALLMENT_DATA;
}

// installment - send installment payment data
export interface SendInstallmentPaymentDataRequestAction {
  type: InstallmentActionsType.SEND_INSTALLMENT_PAYMENT_DATA_REQUEST;
}

export interface SendInstallmentPaymentDataSuccessAction {
  type: InstallmentActionsType.SEND_INSTALLMENT_PAYMENT_DATA_SUCCESS;
  paymentResponseData: PaymentResponseDataState
}

export interface SendInstallmentPaymentDataFailureAction {
  type: InstallmentActionsType.SEND_INSTALLMENT_PAYMENT_DATA_FAILURE;
  error: InstallmentErrorState;
}

// installment - check Payment status for id
export interface CheckInstallmentPaymentStatusForIdRequestAction {
  type: InstallmentActionsType.CHECK_INSTALLMENT_PAYMENT_STATUS_FOR_ID_REQUEST;
  id: string
}

export interface CheckInstallmentPaymentStatusForIdSuccessAction {
  type: InstallmentActionsType.CHECK_INSTALLMENT_PAYMENT_STATUS_FOR_ID_SUCCESS;
}

export interface CheckInstallmentPaymentStatusForIdFailureAction {
  type: InstallmentActionsType.CHECK_INSTALLMENT_PAYMENT_STATUS_FOR_ID_FAILURE;
  error: InstallmentErrorState;
}

export interface OnVVPEditRequestAction {
  type: InstallmentActionsType.ON_VVP_EDIT_REQUEST;
  operationId: string
}
export interface OnVVPEditFailureAction {
  type: InstallmentActionsType.ON_VVP_EDIT_FAILURE;
  error: InstallmentErrorState
}
export interface OnVVPEditSuccessAction {
  type: InstallmentActionsType.ON_VVP_EDIT_SUCCESS;
  editedVVPData: OnGoingVVP,
  revievedRepartition: InstallmentRepartitionState,
}

export interface OnVVPDeleteRequestAction {
  type: InstallmentActionsType.ON_VVP_DELETE_REQUEST;
  operationId: string
  planId: string
}
export interface OnVVPDeleteFailureAction {
  type: InstallmentActionsType.ON_VVP_DELETE_FAILURE;
  error: InstallmentErrorState
}
export interface OnVVPDeleteSuccessAction {
  type: InstallmentActionsType.ON_VVP_DELETE_SUCCESS;
}

// common
export type InstallmentAction =
| GetInstallmentRepartitionDataRequestAction
| GetInstallmentRepartitionDataSuccessAction
| GetInstallmentRepartitionDataFailureAction
| GetCalculatedContributionDataRequestAction
| GetCalculatedContributionDataSuccessAction
| GetCalculatedContributionDataFailureAction
| SetInstallmentRiskProfilAction
| GetInstallmentDecisionRequestAction
| GetInstallmentDecisionSuccesstAction
| GetInstallmentDecisionFailureAction
| SetInstallmentRepartitionAmountRequestAction
| SetEligibleDispositifs
| SetSelectedDispositif
| SetInstallmentRepartitionAmountSuccessAction
| SetInstallmentRepartitionAmountFailureAction
| SetInstallmentRepartitionAmountModalVisibleStateAction
| InitCalculatedContributionDataAction
| CalculatedContributionInitErrorAction
| SetInstallmentPaymentModeChoiceAction
| InstallmentInitErrorAction
| InitAllErrorsAction
| UpdateInstallmentQuestionWithResponse
| GetInstallmentPaymentModeAndBankAccountDetailRequestAction
| GetInstallmentPaymentModeAndBankAccountDetailSuccessAction
| GetInstallmentPaymentModeAndBankAccountDetailFailureAction
| BackToQuestion
| ClearInstallmentDataAction
| SendInstallmentPaymentDataRequestAction
| SendInstallmentPaymentDataSuccessAction
| SendInstallmentPaymentDataFailureAction
| CheckInstallmentPaymentStatusForIdRequestAction
| CheckInstallmentPaymentStatusForIdSuccessAction
| CheckInstallmentPaymentStatusForIdFailureAction
| OnVVPEditRequestAction
| OnVVPEditFailureAction
| OnVVPEditSuccessAction
| OnVVPDeleteRequestAction
| OnVVPDeleteFailureAction
| OnVVPDeleteSuccessAction
