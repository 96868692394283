import { connect } from 'react-redux';

import { AppState } from '@modules/reducers';
import {
  getApplicationSettings,
} from '@modules/settings/selectors';

import { DocumentsTabConnected as DocumentsTabComponent } from './DocumentsTabConnected.component';

const mapStateToProps = (state: AppState) => {
  return {
    applicationSettings: getApplicationSettings(state),
  };
};

const mapDispatchToProps = { };

export const DocumentsTabConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DocumentsTabComponent);
