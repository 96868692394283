import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

export const useStyles = () => {
  const theme = useTheme();

  const styles = useMemo(
    () =>
      StyleSheet.create({
        planFamily: {
          fontSize: theme.fonts.fontSize.title.t6,
          textAlign: 'center',
          marginTop: theme.metrics.spacing.s
        },
        plan: {
          fontSize: theme.fonts.fontSize.title.t8,
          textAlign: 'center',
          marginTop: theme.metrics.spacing.s
        },
        description: {
          fontSize: theme.fonts.fontSize.title.t8,
          textAlign: 'center',
          marginTop: theme.metrics.spacing.s
        },
        button: {
          marginTop: theme.metrics.spacing.m
        },
        buttonText: {
          textAlign: 'center'
        },
        hoveredTextStyle:{
          color:theme.colors.basics.white
        }
      }),
    [theme]
  );

  return styles;
};
