import { useMemo } from "react";
import { ColorValue, StyleSheet } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

export const useStyles = ({
  length = 0,
  offset = 0,
  color,

}: {
    length?: number;
    offset?: number;
    color?: ColorValue;
  }
) => {
  const theme = useTheme();

  const arrowColor = color || theme.colors.basics.grey.c300;

  const styles = useMemo(() => StyleSheet.create({
    stepArrow: {
      position: 'absolute',
      top: offset,
      height: length || '100%',
      width: 50,
      justifyContent: 'center',
      alignItems: 'center',
    },
    stepArrowShaft: {
      height: '100%',
      width: 2,
      backgroundColor: arrowColor,
    },
    stepArrowHead: {
      position: 'absolute',
      bottom: 1,
      height: 10,
      width: 10,
      borderBottomWidth: 2,
      borderRightWidth: 2,
      transform: [
        { rotate: '45deg' }
      ],
      borderBottomColor: arrowColor,
      borderRightColor: arrowColor,
    }
  }), [arrowColor, length, offset])

  return styles;
}

