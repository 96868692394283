import React, { useState } from 'react';
import { View, StyleProp, ViewStyle, TextStyle, Text as RNText } from 'react-native';

import { ExpendableLine, Text, useTranslation } from '@components/index';
import { createUseStyles, useTheme, ThemeType, useScreenSizes } from '@ere-uilib/styles';

interface RefundTotalSubheaderProps {
  backgroundColor?: string,
  title?: string,
  totalAmount: number,
  refundTotalAmount: number,
  containerStyle?: StyleProp<ViewStyle>
  titleContainer?: StyleProp<ViewStyle>
  textStyle?:  StyleProp<TextStyle>
  titleStyle?: StyleProp<TextStyle>
  childrenContainerStyle?: StyleProp<ViewStyle>
  rightTitleText?: StyleProp<TextStyle>
}

interface RefundTotalSubheaderStyles{
  containerStyle?: StyleProp<ViewStyle>
  textStyle?:  StyleProp<TextStyle>
  titleStyle?: StyleProp<TextStyle>
  childrenContainerStyle?: StyleProp<ViewStyle>
  rightTitleText?: StyleProp<TextStyle>
  titleContainer?: StyleProp<ViewStyle>
}

type StyleContext = {theme:ThemeType, backgroundColor?: string}

export const RefundTotalSubheader:React.FC<React.PropsWithChildren<RefundTotalSubheaderProps>> = ({
  backgroundColor,
  totalAmount,
  refundTotalAmount,
  containerStyle,
  childrenContainerStyle,
  rightTitleText,
  textStyle,
  titleStyle,
  titleContainer,
})=>{
  const [opened, setOpened] = useState(false);
  const theme = useTheme();
  const { isMobile, isTablet } = useScreenSizes();
  const { formatMessage, formatCurrencyNumber } = useTranslation();
  isMobile;
  const styles = useStyle({ theme, backgroundColor }, {
    containerStyle,
    textStyle,
    titleStyle,
    childrenContainerStyle,
    rightTitleText,
    titleContainer,
  });

  return (
    <ExpendableLine
      arrowPosition={'right'}
      design={'green'}
      disabled={true}
      isOpened={!(isMobile || isTablet) || opened}
      onChange={setOpened}
      size={'small'}
      title={
        <View style={styles.titleContainer}>
          {typeof refundTotalAmount === 'number' && typeof totalAmount === 'number' && (
            <Text
              style={styles.titleStyle}
              variant={'t2'}
            >
              {formatMessage({ id: 'Remboursement_TotalVsEncours' })}
              <RNText  style={styles.rightTitleText}>
                {formatMessage({
                  id: 'Remboursement_TotalVsEncours_montants',
                  values: {
                    montant1: formatCurrencyNumber({ value: refundTotalAmount }),
                    montant2: formatCurrencyNumber({ value: totalAmount }),
                  },
                })}
              </RNText>
            </Text>
          )}
        </View>
      }
    >
      {/*
      Hide Net Amount Required by MOA  (feat/162707)
      <View style={styles.childrenContainerStyle}>
        {typeof refundTotalAmount === 'number' && (
          <Text
            style={styles.textStyle}
            variant="t3"
            weight="light">
            {formatMessage({ id: 'Remboursement_virementRecap_totalNetRecap' })}
            {formatCurrencyNumber({ value: refundTotalAmount })}
          </Text>
        )}
      </View> */}
    </ExpendableLine>
  );
};

const getStyles = (
  context?: StyleContext,
  style?: RefundTotalSubheaderStyles
): RefundTotalSubheaderStyles =>({
  containerStyle: [
    {
      backgroundColor: context?.backgroundColor,
    },
    style?.containerStyle,
  ],
  titleStyle: [{
    color: context?.theme.colors.basics.white,
  },
  style?.titleStyle],
  textStyle: [{
    color: context?.theme.colors.basics.white,
    marginBottom: context?.theme.metrics.spacing.xs,
  },
  style?.textStyle],
  childrenContainerStyle: [
    { paddingTop: context?.theme.metrics.spacing.s,
      paddingLeft: context?.theme.metrics.spacing.s,
      paddingRight: context?.theme.metrics.spacing.s,
      paddingBottom: context?.theme.metrics.spacing.xs,
      backgroundColor: context?.theme.colors.basics.primary.c700 },
    style?.childrenContainerStyle,
  ],
  rightTitleText: [
    {
      fontFamily: context?.theme.fonts.fontFamily.bold,
    },
    style?.rightTitleText,
  ],
  titleContainer: [{  flexDirection: 'row',
    alignItems: 'center' },
  style?.titleContainer],
});

const useStyle = createUseStyles(getStyles);
