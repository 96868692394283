import React, { useLayoutEffect, useState } from 'react';
import { Platform } from 'react-native';

import { ApplicationSettingsState } from '@modules/settings/types';

export const OneTrustScriptComponent: React.FC<React.PropsWithChildren<{
  applicationSettings:ApplicationSettingsState
}>> = ({
  children,
  applicationSettings,
}) => {
  const [alreadyInserted, setAlreadyInserted] = useState(false);
  // parameters will probably come from redux after settings api call
  const insertOneTrustScript = (integrationKey:string) => {
    setAlreadyInserted(true);
    // first script
    const script = document.getElementsByTagName('script')[0];
    const oneTrust = document.createElement('script');
    oneTrust.type = 'text/javascript';
    oneTrust.src = `https://cdn.cookielaw.org/consent/${integrationKey}/OtAutoBlock.js`;
    script.parentNode?.insertBefore(oneTrust, script);
    // second script
    const oneTrust2 = document.createElement('script');
    oneTrust2.type = 'text/javascript';
    oneTrust2.setAttribute('charset', 'UTF-8');
    oneTrust2.setAttribute('data-language', 'fr');
    oneTrust2.setAttribute('data-domain-script', integrationKey);
    oneTrust2.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
    script.parentNode?.insertBefore(oneTrust2, script);
  };
  useLayoutEffect(() => {
    if (!alreadyInserted && Platform.OS === 'web' && applicationSettings?.CookieOneTrustKey) {
      insertOneTrustScript(applicationSettings.CookieOneTrustKey);
    }
  }, [applicationSettings]);

  return <>{children}</>;
};
