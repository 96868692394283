export interface CampaignErrorState {
  code: string;
  correlationId: string;
  message: string;
  innerMessage: string;
}

export enum CampaignValueState {
  YES = 'Approuve',
  NO = 'Decline',
  LATER = 'Later'
}

export interface CampaignData {
  campaignId: string
  bodyBas: string
  bodyHaut: string
  helpHaut: string
  helpBas: string
  documents: string[]
  yesTakenIntoAccountMessage: string
  notTakenIntoAccountMessage: string
  openQuestion: string
  isRequired: boolean
  responseValue: CampaignValueState
  title: string
}

export interface CampaignState {
  submittedCampaignIds: string[]
  isSubmitted: boolean
  campaignData?: CampaignData
  error: CampaignErrorState
}