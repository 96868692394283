import React from 'react';
import { View } from 'react-native';

import {
  useTranslation,
  useTheme,
  NotificationWrapper,
  Paragraph
} from '@components/index';
import { createUseStyles } from '@ere-uilib/styles';

import { NotificationIconTypeEnum, Title } from '@ere-uilib/atoms';
import { DispositifState } from '@modules/common/types';

import { Props } from './interfaces';
import { getStyles } from './styles';

export const OperationsNotificationErrorsWrapperList: React.FC<React.PropsWithChildren<Props>> = ({
  isExpandable,
  dispositifs,
  children,
  title,
  cardTitleLabelCode,
  subtitle,
  containerStyle
}) => {
  const { formatMessage } = useTranslation();
  const theme = useTheme();

  const style = useStyles(
    {
      theme
    },
    {}
  );

  const renderTopTexts = () =>
    title || subtitle ? (
      <View style={style.titleAndSubtitleContainer}>
        {title && (
          <Title
            variant="t6"
            weight="bold">
            {title}
          </Title>
        )}
        {subtitle && (
          <Title
            variant="t7"
            weight="light">
            {subtitle}
          </Title>
        )}
      </View>
    ) : (
      <></>
    );

  return (
    <View style={containerStyle}>
      {renderTopTexts()}
      {dispositifs?.map((dispositif: DispositifState) => (
        <NotificationWrapper
          isExpandable={isExpandable}
          mainStyle={style.notificationContainer}
          title={formatMessage({
            id: cardTitleLabelCode,
            values: {
              dispositif: dispositif.name
            }
          })}
          type={NotificationIconTypeEnum.WARNING}>
          {dispositif.blocagesCodes?.map(blocageCode => {
            return (
              <Paragraph
                variant="t3"
                weight="light">
                {'\u2022' + ' ' + formatMessage({ id: blocageCode })}
              </Paragraph>
            );
          })}
        </NotificationWrapper>
      ))}
      {/* 
        TODO: to be reactivated only at the request of the business
      */}
      {/* {children} */}
    </View>
  );
};

const useStyles = createUseStyles(getStyles);
