
import { StyleSheet } from 'react-native'

import {
  useScreenSizes,
  useTheme,
} from '@components/index';

export const useStyles = () => {

  const theme = useTheme();
  const { isMobile, isTablet, isDesktop } = useScreenSizes();
  const styles = StyleSheet.create({
    loaderContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      paddingVertical: theme.metrics.spacing.xs,
    },
    loader: { 
      flex: 1, 
      marginLeft: theme.metrics.spacing.xm 
    },
    contentContainer: { 
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    content: { 
      flex: 1,
      maxWidth: theme.metrics.contentSizes.centeredContentMaxWidth,
      ...((isMobile || isTablet) ? {
        margin: theme.metrics.spacing.s,
      }:{
        margin: theme.metrics.spacing.xm,
      })
    },
    filetStepperContainer: {
      alignSelf: 'flex-start',
      width: '100%',
      marginVertical: theme.metrics.spacing.l,
    },
    bottomActionsStyle: {
      ...(!(isMobile || isTablet) && {
        alignItems: 'flex-start',
        marginTop: theme.metrics.spacing.xl,
      })
    },
    notificationContainerStyle: {
      flexDirection: 'column',
    },
    premiumAmountCard: {
      flex: 1, 
      maxWidth: 550,
      marginVertical: theme.metrics.spacing.xm,
    },
    leftColumnStyle: {
      paddingLeft: 0, 
      paddingRight: 0,
      paddingVertical: 0
    },
  })
  return styles
};
