import React from 'react';
import { View } from 'react-native';

import { useTranslation } from '@components/index';
import { Text, Title } from '@ere-uilib/atoms';
import { RadioButton } from '@ere-uilib/molecules';

import { useStyles } from '../useStyles';
import { locator } from '@constants/locator';

interface Props {
  handlePressGroupEmail: (optin: boolean) => void;
  handlePressPartnerEmail: (optin: boolean) => void;
  optInGroupEmail: boolean | undefined;
  optInPartnerEmail: boolean | undefined;
  acceptPartnerEmail: boolean;
  acceptGroupEmail: boolean;
}

export function BusinessContactPreferencetemplate({
  handlePressGroupEmail,
  handlePressPartnerEmail,
  optInGroupEmail,
  optInPartnerEmail,
  acceptGroupEmail,
  acceptPartnerEmail
}: Props) {
  const { formatMessage } = useTranslation();

  const styles = useStyles();

  return (
    <>
      <Title
        variant="t3"
        weight="bold">
        {formatMessage({ id: 'OptinPageTitle' })}
      </Title>
      <Text style={styles.subTitleStyle}>{formatMessage({ id: 'OptinPageSubtitle' })}</Text>

      <View style={styles.form}>
        <View style={styles.section}>
          <Text
            variant="t3"
            weight="light">
            {formatMessage({ id: 'OptinAvantagesEreChoixLabel' })}
          </Text>
          <View style={styles.checkboxes}>
            <View style={styles.checkbox}>
              <RadioButton
                label={formatMessage({
                  id: 'OptinAvantagesOuiLabel'
                })}
                onPress={() => !optInGroupEmail && handlePressGroupEmail(true)}
                value={optInGroupEmail !== undefined && optInGroupEmail}
                testId={locator._profile._your_preferences._receive_offers_bnp}
              />
            </View>
            <View style={styles.checkbox}>
              <RadioButton
                label={formatMessage({
                  id: 'OptinAvantagesNonLabel'
                })}
                onPress={() =>
                  ((!optInGroupEmail && optInGroupEmail === undefined) || optInGroupEmail) &&
                  handlePressGroupEmail(false)
                }
                value={optInGroupEmail !== undefined && !optInGroupEmail}
                testId={locator._profile._your_preferences._do_not_receive_offers_bnp}
              />
            </View>
          </View>
        </View>

        <View style={styles.sectionLast}>
          <Text
            variant="t3"
            weight="light">
            {formatMessage({ id: 'OptinAvantagesGroupeChoixLabel' })}
          </Text>
          <View style={styles.checkboxes}>
            <View style={styles.checkbox}>
              <RadioButton
                label={formatMessage({
                  id: 'OptinAvantagesOuiLabel'
                })}
                onPress={() => !optInPartnerEmail && handlePressPartnerEmail(true)}
                value={optInPartnerEmail !== undefined && optInPartnerEmail}
                testId={locator._profile._your_preferences._receive_offers_parteners}
              />
            </View>
            <View style={styles.checkbox}>
              <RadioButton
                label={formatMessage({
                  id: 'OptinAvantagesNonLabel'
                })}
                onPress={() =>
                  ((!optInPartnerEmail && optInPartnerEmail === undefined) || optInPartnerEmail) &&
                  handlePressPartnerEmail(false)
                }
                value={optInPartnerEmail !== undefined && !optInPartnerEmail}
                testId={locator._profile._your_preferences._do_not_receive_offers_parteners}
              />
            </View>
          </View>
        </View>
      </View>
      <View></View>
    </>
  );
}
