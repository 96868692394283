import * as settings from "@modules/settings/actions/settingsActionsTypes";
import { SettingsActionsType } from "@modules/settings/actions/settingsActionsTypes";
import { AccountsErrorState } from "@modules/dashboard/types";
import {
  ApplicationSettingsReceivedState,
  MobileMenuListState,
} from "@modules/settings/types";

// APPLICATION SETTINGS

export function setAtInternetCliendIdBySession(id: string): settings.SetAtInternetClientIdAction {
  return {
    type: SettingsActionsType.SET_ATINTERNET_CLIENT_ID,
    id
  };
}

export function setExternalClientID(externalClientID: string): settings.SetExternalClientIDAction {
  return {
    type: SettingsActionsType.SET_EXTERNAL_CLIENT_ID,
    externalClientID
  };
}

export function getApplicationSettingsRequest(): settings.GetApplicationSettingsRequestAction {
  return {
    type: SettingsActionsType.GET_APPLICATION_SETTINGS_REQUEST,
  };
}

export function getApplicationSettingsSuccess(
  applicationSettings: ApplicationSettingsReceivedState
): settings.GetApplicationSettingsSuccessAction {
  return {
    type: SettingsActionsType.GET_APPLICATION_SETTINGS_SUCCESS,
    applicationSettings,
  };
}

export function getApplicationSettingsFailure(
  error: AccountsErrorState
): settings.GetApplicationSettingsFailureAction {
  return {
    type: SettingsActionsType.GET_APPLICATION_SETTINGS_FAILURE,
    error,
  };
}

export function applicationSettingsInitError(): settings.ApplicationSettingsInitErrorAction {
  return {
    type: SettingsActionsType.APPLICATION_SETTINGS_INIT_ERROR,
  };
}

// MOBILE MENU LIST

export function getMobileMenuListRequest(
  companyId: string
): settings.GetMobileMenuListRequestActions {
  return {
    type: SettingsActionsType.GET_MOBILE_MENU_LIST_REQUEST,
    companyId,
  };
}

export function getMobileMenuListSuccess(
  mobileMenuList: MobileMenuListState[]
): settings.GetMobileMenuListSuccessAction {
  return {
    type: SettingsActionsType.GET_MOBILE_MENU_LIST_SUCCESS,
    mobileMenuList,
  };
}

export function getMobileMenuListFailure(
  error: AccountsErrorState
): settings.GetMobileMenuListFailureAction {
  return {
    type: SettingsActionsType.GET_MOBILE_MENU_LIST_FAILURE,
    error,
  };
}

export function mobileMenuListInitError(): settings.MobileMenuListInitErrorAction {
  return {
    type: SettingsActionsType.MOBILE_MENU_LIST_INIT_ERROR,
  };
}

export function onSetMaintenanceContent(maintenanceContent: string): settings.SetMaintenanceContentAction {
  return {
    type: SettingsActionsType.SET_MAINTENANCE_CONTENT,
    maintenanceContent
  };
}