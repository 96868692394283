import {
  CookiesPolicyButton,
  DashboardPageWrapperConnected,
  FontIcon,
  HtmlStyledRenderConnectedContainer,
  NotificationSoft,
  Spinner,
  SVGLocalLoader,
  Text,
  useScreenSizes,
  useTheme,
  useTranslation
} from '@components/index';
import { createUseStyles } from '@ere-uilib/styles';

import {RouteNames} from '@constants/navigation';
import {IconEnum} from '@ere-uilib/enums';
import React, {useEffect} from 'react';
import {ScrollView, TouchableOpacity, View} from 'react-native';
import {EditorialPageProps} from './interfaces';
import {getStyles} from './styles';
import {useRoute} from "@react-navigation/core";
import {useNavigation} from "@react-navigation/native";



export const EditorialPage: React.FC<EditorialPageProps> = ({
  onGetEditorialContent,
  editorialContent,
  isGetEditorialContentLoading,
  error,
  notConnectedContentStyle,
  notConnectedInnerContentStyle,
  connectedContentStyle,
  connectedInnerContentStyle,
  logoStyle,
  titleStyle,
  fullSpaceContainer,
  scrollViewContainerStyle,
  scrollViewStyle,
}) => {
  const { formatMessage } = useTranslation();
  const route = useRoute();
  const navigation =  useNavigation<any>();
  const theme = useTheme();
  const { isMobile, isTablet, isDesktop } = useScreenSizes();
  const paramsValue  = route?.params as {code: string} | undefined;
  const isCookiePolicy = paramsValue?.code === 'CookiePolicy';

  useEffect(() => {
    if(paramsValue?.code) {
     onGetEditorialContent(paramsValue?.code);
    }
  }, [paramsValue?.code]);


  const useStyles = createUseStyles(getStyles);
  const styles = useStyles(
      {
        theme,
        isMobile,
        isTablet,
        isDesktop,
      },
      {
        notConnectedContentStyle,
        notConnectedInnerContentStyle,
        connectedContentStyle,
        connectedInnerContentStyle,
        logoStyle,
        titleStyle,
        fullSpaceContainer,
        scrollViewContainerStyle,
        scrollViewStyle,
      }
  );

  const content = editorialContent && editorialContent.content;
  const canGoBack = navigation?.canGoBack();
  const onClose = () => {
    if (canGoBack) {
      navigation?.goBack();
    } else {
      navigation.replace(RouteNames.BottomTabNavigator, {
        screen: RouteNames.ProfileStack,
        params: {
          screen: RouteNames.Profile,
        },
      });
    }
  };

  const renderContent = () => {
    return (
        <>
          <View style={styles.fullSpaceContainer}>
            {isGetEditorialContentLoading && (
                <Spinner color={theme.colors.basics.primary.c500} />
            )}
            {error && error?.innerMessage !== "" && (
                <NotificationSoft
                    text={error?.innerMessage}
                    type="warning" />
            )}
            {content && (
                <ScrollView
                    contentContainerStyle={styles.scrollViewContainerStyle}
                    style={styles.scrollViewStyle}
                >
                  {isCookiePolicy && <CookiesPolicyButton />}
                  <HtmlStyledRenderConnectedContainer html={content} />
                </ScrollView>
            )}
          </View>
        </>
    );
  };

  return (
      <DashboardPageWrapperConnected>
        <View style={styles.connectedContentStyle}>
          {!isDesktop && (
              <View style={styles.logoStyle}>
                <SVGLocalLoader name="logoHorizontalDark" />
              </View>
          )}
          <View style={styles.connectedInnerContentStyle}>
            <TouchableOpacity
                onPress={onClose}
                style={styles.backButtonStyle}>
              <FontIcon
                  color={theme?.colors.text.primary.c500}
                  name={IconEnum.CHEVRON_LEFT}
                  size={theme.metrics.iconSizes.xs}
              />
              <Text style={styles.backButtonTextStyle}>
                {formatMessage({ id: 'support_back_button' })}
              </Text>
            </TouchableOpacity>
            {renderContent()}
          </View>
        </View>
      </DashboardPageWrapperConnected>
  );
};
