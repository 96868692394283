import { useMemo } from 'react'

import { useTranslation } from '@ere-uilib/translations'

import { DocumentGroup } from './constants'

export const useLabels = () => {
  const { formatMessage, getMessageRaw } = useTranslation()

  const labels = useMemo(
    () => ({
      header: {
        help: {
          title: formatMessage({ id: 'DocCenterHelptitle' }),
          content: getMessageRaw({ id: 'DocCenterHelptext' })
        },
        title: formatMessage({ id: 'DocCenterGeneralDocumentsHeaderSortingPage' })
      },
      content: {
        title: formatMessage({ id: 'DocCenterIntro' }),
        iconName: 'receipt',
        cards: [
          {
            type: DocumentGroup.EDOCUMENT,
            title: formatMessage({ id: 'DocCenterDocMyEReleveTitle' }),
            description: formatMessage({ id: 'DocCenterDocMyEReleveSubTitleSubscribed' })
          },
          {
            type: DocumentGroup.GENERAL_DOCUMENT,
            title: formatMessage({ id: 'DocCenterGeneralDocumentsSubTitle' }),
            description: formatMessage({ id: 'DocCenterGeneralDocumentsSubTitleExplanation' })
          }
        ]
      }
    }),
    [formatMessage, getMessageRaw]
  )

  return labels
}
