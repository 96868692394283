import { SavingRepartitionCardStylesContext, SavingRepartitionCardStyles } from './interfaces'

export const getStyles = (
  context?: SavingRepartitionCardStylesContext,
  style?: SavingRepartitionCardStyles
): SavingRepartitionCardStyles => ({
  container: [
    {
      borderBottomWidth: 1,
      borderBottomColor: context?.theme.colors.basics.grey.c100,
      paddingTop: context?.theme.metrics.spacing.xm,
      paddingLeft: context?.theme.metrics.spacing.s,
      paddingRight: context?.theme.metrics.spacing.s,
      paddingBottom: context?.theme.metrics.spacing.m
    },
    context?.disabled && {
      backgroundColor: context?.theme.colors.basics.grey.c100,
      borderBottomColor: context?.theme.colors.basics.grey.c200
    },
    style?.container
  ],
  style: [
    {
      flexDirection: 'row'
    },
    style?.style
  ],
  contentTextStyle: [
    context?.disabled && {
      color: context?.theme.colors.basics.grey.c500
    },
    style?.contentTextStyle
  ],
  headerLineContainer: [
    {
      flexDirection: 'row',
      marginBottom: context?.theme.metrics.spacing.xs
    },
    style?.headerLineContainer
  ],
  leftContainer: [
    {
      flex: 1
    },
    style?.leftContainer
  ],
  checkBoxContainer: [
    {
      flexDirection: 'row'
    },
    style?.checkBoxContainer
  ],
  rightContainer: [
    {
      flexShrink: 1,
      // we add zIndex in order to have the full height of rightContainer
      // not overlaying the content
      zIndex: -1,
      alignItems: 'flex-end'
    },
    style?.rightContainer
  ],
  lowerLineContainer: [
    {
      flexDirection: 'row'
    },
    style?.lowerLineContainer
  ],
  commonLabelTextStyle: [
    context?.disabled && {
      color: context?.theme.colors.basics.grey.c500
    },
    style?.commonLabelTextStyle
  ],
  commonValueTextStyle: [
    {
      fontFamily: context?.theme.fonts.fontFamily.regular
    },
    context?.disabled && {
      color: context?.theme.colors.basics.grey.c500
    },
    style?.commonValueTextStyle
  ],
  textWrapper: [
    {
      display: 'flex',
      flexDirection: 'row'
    },
    style?.textWrapper
  ],
  checkBoxStyle: [
    {
      marginRight: context?.theme.metrics.spacing.xs
    },
    style?.checkBoxStyle
  ],
  supportNameContainerStyle: [
    {
      flex: 1,
      alignSelf: 'flex-start'
    },
    style?.supportNameContainerStyle
  ],
  supportNameStyle: [
    {
      alignSelf: 'flex-start'
    },
    !context?.isFundsSheetDisabled && {
      textDecorationLine: 'underline'
    },
    context?.disabled && {
      color: context?.theme.colors.basics.grey.c500
    },
    style?.supportNameStyle
  ],
  amountTextStyle: [
    {
      textAlign: 'right'
    },
    context?.disabled && {
      color: context?.theme.colors.basics.grey.c500
    },
    style?.addedValueTextStyle
  ],
  addedValueTextStyle: [
    {
      textAlign: 'right'
    },
    context?.disabled && {
      color: context?.theme.colors.basics.grey.c500
    },
    style?.addedValueTextStyle
  ],
  underFieldLinkStyle: [
    {
      textAlign: 'right'
    },
    style?.contributionTextStyle
  ],
  contributionTextStyle: [
    {
      textAlign: 'right',
      color: context?.theme.colors.basics.primary.c500
    },
    style?.contributionTextStyle
  ],
  isSolidaireContainer: [
    {
      flexDirection: 'row',
      alignItems: 'center'
    },
    style?.isSolidaireContainer
  ],
  isSolidaireIconContainer: [
    {
      height: context?.theme.metrics.iconSizes.xxm,
      width: context?.theme.metrics.iconSizes.xxm,
      marginRight: context?.theme.metrics.spacing.xs
    },
    style?.isSolidaireIconContainer
  ],
  contributionIcon: [
    {
      marginRight: context?.theme.metrics.spacing.xs
    },
    style?.contributionIcon
  ]
})
