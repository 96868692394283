import React from "react";
import {ProfitSharingIncentiveHeader} from "@pages/ProfitSharingIncentive/components";
import {label} from "@pages/ProfitSharingIncentive/ProfitSharingIncentiveRepartition/label";
import {SubHeader} from "@pages/ProfitSharingIncentive/ProfitSharingIncentiveRepartition/Components/SubHeader";
import {useScreenSizes, useTranslation} from "@components/ERE-UILib";
import {constructStepperItems} from "@pages/Common/utils";
import {ProfitSharingIncentiveInitDataState} from "@modules/profit-sharing-incentive/types";
import {DirectionEnum} from "@ere-uilib/molecules/steppers/InstallmentsStepper/interfaces";

type HeaderProps = {
    initData: ProfitSharingIncentiveInitDataState
}

const stepperItems = constructStepperItems({
    size: 3,
    direction: DirectionEnum.ROW,
    activeStep: 2,
});

export const Header: React.FC<HeaderProps> = ({initData}) => {
    if (!initData) return null;

    const {type, legalFramework } = initData;
    const { formatMessage, getMessageRaw } = useTranslation();
    const { isDesktop } = useScreenSizes();

    return (
        <>
            <ProfitSharingIncentiveHeader
                displayCloseButton
                helpPoint
                helpPointContentHtml={getMessageRaw({
                    id:
                        label.header.help.content[type]?.[legalFramework] ||
                        label.header.help.content[type]?.default
                })}
                helpPointModalTitle={formatMessage({
                    id: label.header.help.title,
                })}
                helpPointPosition="left"
                stepperItems={stepperItems}
                title={formatMessage({
                    id: label.header.title[type]?.[legalFramework] || label.header.title[type]?.default
                })}
            />
            {!isDesktop && <SubHeader />}
        </>
    );

}
