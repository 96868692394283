import { useMemo } from "react";
import { StyleSheet } from "react-native";

import { useScreenSizes } from "@ere-uilib/styles";

export const useStyles = () => {
  const { isMobile } = useScreenSizes();

  const styles = useMemo(() => StyleSheet.create({
    loaderCardContainer: {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      justifyContent: 'space-between'
    },
  }), [isMobile]);

  return styles;
};