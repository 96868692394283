import { StylesContext, ToolCardStyles } from './interface';

const IMAGE_HEIGHT = 188;

export const getStyles = (
  context?: StylesContext,
  style?: ToolCardStyles,
): ToolCardStyles => ({
  containerStyle: [
    {
      flex: 1,
      borderRadius: context?.theme.metrics.borderRadius.xs,
      overflow: 'hidden',
      alignItems: 'center',
      backgroundColor: context?.theme.colors.basics.white,
      shadowColor: context?.theme.colors.notifications.shadow,
      shadowOffset: {
        width: 0,
        height: 2
      },
      shadowOpacity: 0.85,
      shadowRadius: 10,
      elevation: 1,
    },
    (context?.horizontal && !context?.isMobile) ? {
      flexDirection: 'row'
    } : {
      flexDirection: 'column'
    },
    style?.containerStyle
  ],
  descriptionContainerStyle: [
    {
      flex: 1,
      flexDirection: 'column',
      alignItems: 'center',
      padding: context?.theme.metrics.spacing.m
    },
    style?.descriptionContainerStyle
  ],
  titleStyle: [
    {
      textAlign: 'center'
    },
    style?.titleStyle
  ],
  hookTextStyle: [
    {
      marginVertical: context?.theme.metrics.spacing.m,
      textAlign: 'center',
      flexGrow: 1,
      flexShrink: 1,
      overflow: 'hidden'
    },
    style?.hookTextStyle
  ],
  buttonStyle: [
    !(context?.isMobile || context?.isTablet) ?
      {
        marginRight: context?.theme.metrics.spacing.s
      } : {
        marginRight: context?.theme.metrics.spacing.s
      },
    style?.buttonStyle
  ],
  imageStyle: [
    context?.horizontal ? {
      width: 355,
      height: '100%'
    } : {
      width: '100%',
      height: IMAGE_HEIGHT,
      resizeMode: 'contain',
    },
    style?.imageStyle
  ]
});
