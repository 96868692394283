import { InitAllErrorsAction } from '@modules/error/actions/errorActionsTypes';
import { ForgetPasswordErrorState } from '@modules/forget-password/types';

export enum ForgetPasswordActionsType {
  FORGET_PASSWORD_INIT_BEGIN_REQUEST = 'FORGET_PASSWORD_INIT_BEGIN_REQUEST',
  FORGET_PASSWORD_INIT_BEGIN_SUCCESS = 'FORGET_PASSWORD_INIT_BEGIN_SUCCESS',
  FORGET_PASSWORD_INIT_BEGIN_FAILURE = 'FORGET_PASSWORD_INIT_BEGIN_FAILURE',
  FORGET_PASSWORD_CONFIRM_EMAIL_REQUEST = 'FORGET_PASSWORD_CONFIRM_EMAIL_REQUEST',
  FORGET_PASSWORD_CONFIRM_EMAIL_SUCCESS = 'FORGET_PASSWORD_CONFIRM_EMAIL_SUCCESS',
  FORGET_PASSWORD_CONFIRM_EMAIL_FAILURE = 'FORGET_PASSWORD_CONFIRM_EMAIL_FAILURE',
  FORGET_PASSWORD_CONFIRM_SMS_REQUEST = 'FORGET_PASSWORD_CONFIRM_SMS_REQUEST',
  FORGET_PASSWORD_CONFIRM_SMS_SUCCESS = 'FORGET_PASSWORD_CONFIRM_SMS_SUCCESS',
  FORGET_PASSWORD_CONFIRM_SMS_FAILURE = 'FORGET_PASSWORD_CONFIRM_SMS_FAILURE',
  FORGET_PASSWORD_CONFIRM_SMS_CODE_REQUEST = 'FORGET_PASSWORD_CONFIRM_SMS_CODE_REQUEST',
  FORGET_PASSWORD_CONFIRM_SMS_CODE_SUCCESS = 'FORGET_PASSWORD_CONFIRM_SMS_CODE_SUCCESS',
  FORGET_PASSWORD_CONFIRM_SMS_CODE_FAILURE = 'FORGET_PASSWORD_CONFIRM_SMS_CODE_FAILURE',
  FORGET_PASSWORD_CONFIRM_REQUEST = 'FORGET_PASSWORD_CONFIRM_REQUEST',
  FORGET_PASSWORD_CONFIRM_SUCCESS = 'FORGET_PASSWORD_CONFIRM_SUCCESS',
  FORGET_PASSWORD_CONFIRM_FAILURE = 'FORGET_PASSWORD_CONFIRM_FAILURE',
  FORGET_PASSWORD_INIT_ERROR = 'FORGET_PASSWORD_INIT_ERROR',
  SET_FORGET_PASSWORD_EMAIL_INPUT = 'SET_FORGET_PASSWORD_EMAIL_INPUT'
}

// FORGET PASSWORD INIT BEGIN
export interface ForgetPasswordInitBeginRequestAction {
  type: ForgetPasswordActionsType.FORGET_PASSWORD_INIT_BEGIN_REQUEST;
  email: string;
}

export interface ForgetPasswordInitBeginSuccessAction {
  type: ForgetPasswordActionsType.FORGET_PASSWORD_INIT_BEGIN_SUCCESS;
  uiidRequestedCode: string;
}

export interface ForgetPasswordInitBeginFailureAction {
  type: ForgetPasswordActionsType.FORGET_PASSWORD_INIT_BEGIN_FAILURE;
  error: ForgetPasswordErrorState;
}

// FORGET PASSWORD CONFIRM EMAIL

export interface ForgetPasswordConfirmEmailRequestAction {
  type: ForgetPasswordActionsType.FORGET_PASSWORD_CONFIRM_EMAIL_REQUEST
  code: string
}

export interface ForgetPasswordConfirmEmailSuccessAction {
  type: ForgetPasswordActionsType.FORGET_PASSWORD_CONFIRM_EMAIL_SUCCESS;
  phoneNumber: string;
}

export interface ForgetPasswordConfirmEmailFailureAction {
  type: ForgetPasswordActionsType.FORGET_PASSWORD_CONFIRM_EMAIL_FAILURE;
  error: ForgetPasswordErrorState;
}

// FORGET PASSWORD CONFIRM SMS

export interface ForgetPasswordConfirmSMSRequestAction {
  type: ForgetPasswordActionsType.FORGET_PASSWORD_CONFIRM_SMS_REQUEST;
}

export interface ForgetPasswordConfirmSMSSuccessAction {
  type: ForgetPasswordActionsType.FORGET_PASSWORD_CONFIRM_SMS_SUCCESS;
  status: boolean;
}

export interface ForgetPasswordConfirmSMSFailureAction {
  type: ForgetPasswordActionsType.FORGET_PASSWORD_CONFIRM_SMS_FAILURE;
  error: ForgetPasswordErrorState;
}

// FORGET PASSWORD CONFIRM SMS CODE

export interface ForgetPasswordConfirmSMSCodeRequestAction {
  type: ForgetPasswordActionsType.FORGET_PASSWORD_CONFIRM_SMS_CODE_REQUEST;
  code: string;
}

export interface ForgetPasswordConfirmSMSCodeSuccessAction {
  type: ForgetPasswordActionsType.FORGET_PASSWORD_CONFIRM_SMS_CODE_SUCCESS;
  status: boolean;
}

export interface ForgetPasswordConfirmSMSCodeFailureAction {
  type: ForgetPasswordActionsType.FORGET_PASSWORD_CONFIRM_SMS_CODE_FAILURE;
  error: ForgetPasswordErrorState;
}

// FORGET PASSWORD CONFIRM

export interface ForgetPasswordConfirmRequestAction {
  type: ForgetPasswordActionsType.FORGET_PASSWORD_CONFIRM_REQUEST;
  newPassword: string;
}

export interface ForgetPasswordConfirmSuccessAction {
  type: ForgetPasswordActionsType.FORGET_PASSWORD_CONFIRM_SUCCESS;
  status: boolean;
}

export interface ForgetPasswordConfirmFailureAction {
  type: ForgetPasswordActionsType.FORGET_PASSWORD_CONFIRM_FAILURE;
  error: ForgetPasswordErrorState;
}

// INIT ERROR

export interface ForgetPasswordInitErrorAction {
  type: ForgetPasswordActionsType.FORGET_PASSWORD_INIT_ERROR;
}

export interface SetForgetPasswordEmailInputAction {
  type: ForgetPasswordActionsType.SET_FORGET_PASSWORD_EMAIL_INPUT
  email: string
}
export type ForgetPasswordAction =
  | ForgetPasswordInitBeginRequestAction
  | ForgetPasswordInitBeginSuccessAction
  | ForgetPasswordInitBeginFailureAction
  | ForgetPasswordConfirmEmailRequestAction
  | ForgetPasswordConfirmEmailSuccessAction
  | ForgetPasswordConfirmEmailFailureAction
  | ForgetPasswordInitErrorAction
  | ForgetPasswordConfirmSMSRequestAction
  | ForgetPasswordConfirmSMSSuccessAction
  | ForgetPasswordConfirmSMSFailureAction
  | ForgetPasswordConfirmSMSCodeRequestAction
  | ForgetPasswordConfirmSMSCodeSuccessAction
  | ForgetPasswordConfirmSMSCodeFailureAction
  | ForgetPasswordConfirmRequestAction
  | ForgetPasswordConfirmSuccessAction
  | ForgetPasswordConfirmFailureAction
  | SetForgetPasswordEmailInputAction
  | InitAllErrorsAction
