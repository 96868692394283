import { StylesContext, PhoneFieldCheckStyles, PhoneFieldCheckProps } from './interface';

export const getStyles = (
  context?: StylesContext,
  style?: PhoneFieldCheckStyles,
  props?: PhoneFieldCheckProps,
): PhoneFieldCheckStyles => {
  return ({
    input: [
      {
        width: '100%'
      },
    ],
    textFieldContainerStyle: [
      {
        alignSelf: (context?.isMobile || context?.isTablet) ? 'flex-start' : 'flex-start',
        width: '100%',
        marginBottom: context?.theme.metrics.spacing.m,
      },
      style?.textFieldContainerStyle,
    ],
  })
}
