import { StyleSheet } from 'react-native';

import {
  useTheme,
} from '@ere-uilib/styles';

export const useStyles = () => {
  const theme =  useTheme();
  return StyleSheet.create({
    container: {
    },
    labelContainer: {
      flex: 1,
      alignItems: 'flex-start',
    },
    tableContainer: {
      marginBottom: theme.metrics.spacing.xxm,
    },
    tabItemContainer: {
      flexDirection: 'row',
      height: 45,
      alignItems: 'center',
      backgroundColor: theme.colors.basics.grey.c100,
      paddingVertical: theme.metrics.spacing.m,
      paddingHorizontal: theme.metrics.spacing.s,
      marginBottom: 1,
    },
    dateContainer:
    {
      flexDirection: 'row',
    },
    labelLegend: {
      marginTop: theme.metrics.spacing.m, 
    },
    labelNetValuation: { 
      marginTop: theme.metrics.spacing.l, 
      marginBottom: theme.metrics.spacing.m
    },
    radioButtonsListContainer: { 
      marginBottom: theme.metrics.spacing.xm
    },
    labelPastPerformance: {
      marginBottom: theme.metrics.spacing.s
    }
  });
};