import {StyleProp, TextStyle, ViewStyle} from "react-native";
import {ThemeType} from "@ere-uilib/styles";
type StylesContext = {
    theme: ThemeType
    isMobile: boolean
    isTitlePressable: boolean
    isBottomCard: boolean
}

export type Styles = {
    containerStyle?: StyleProp<ViewStyle>
    titleContainerStyle?: StyleProp<ViewStyle>
    titleTextStyle?: StyleProp<TextStyle>
    rowLabelsContainerStyle?: StyleProp<ViewStyle>
    rowStyle?: StyleProp<ViewStyle>
    checkBoxContainerStyle?: StyleProp<ViewStyle>
    detailsContainerStyle?: StyleProp<ViewStyle>
    subTitleStyle?: StyleProp<TextStyle>
    chevronStyle?: StyleProp<ViewStyle>
    expandContainerStyle?: StyleProp<ViewStyle>
    contributionLabelStyle?: StyleProp<TextStyle>
    percentageText?: StyleProp<TextStyle>
}

export const getStyles = (context?: StylesContext, style?: Styles): Styles => ({
    containerStyle: [
        {
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingHorizontal: context?.theme.metrics.spacing.s,
            borderTopWidth: 1,
            borderTopColor: context?.theme.colors.basics.grey.c200,
            paddingVertical: context?.theme.metrics.spacing.m
        },
        style?.containerStyle
    ],
    rowLabelsContainerStyle: [
        {
            flexShrink: 1,
            minWidth: 100,
            alignItems: 'flex-end',
        },
        style?.rowLabelsContainerStyle
    ],
    rowStyle: [
        {
            flexWrap: 'wrap',
            flexDirection: 'row',
            paddingHorizontal: context?.theme.metrics.spacing.s,
            paddingBottom: context?.theme.metrics.spacing.xs
        },
        style?.rowStyle
    ],
    checkBoxContainerStyle: [
        {
            flexDirection: 'row',
            marginBottom: context?.theme.metrics.spacing.none
        },
        style?.checkBoxContainerStyle
    ],
    detailsContainerStyle: [
        {
            paddingHorizontal: context?.theme.metrics.spacing.s,
            paddingBottom: context?.theme.metrics.spacing.s
        },
        style?.checkBoxContainerStyle
    ],

    subTitleStyle: [
        {
            marginRight: context?.theme.metrics.spacing.xs,
            color: context?.theme.colors.text.link.c500
        },
        style?.subTitleStyle
    ],
    chevronStyle: [
        {
            alignSelf: 'center',
            paddingLeft: context?.theme.metrics.spacing.xs
        },
        style?.chevronStyle
    ],
    expandContainerStyle: [
        {
            marginBottom: context?.theme.metrics.spacing.xm
        },
        context?.isMobile && {
            marginBottom: context?.theme.metrics.spacing.m
        },
        style?.expandContainerStyle
    ],
    titleContainerStyle: [
        {
            flexShrink: 1,
            flexGrow: 1
        },
        style?.titleContainerStyle
    ],
    titleTextStyle: [
        context?.isTitlePressable && {
            textDecorationLine: 'underline'
        },
        style?.titleTextStyle
    ],
    contributionLabelStyle: [
        {
            color: context?.theme.colors.basics.primary.c500,
            textAlign: 'right'
        },
        style?.contributionLabelStyle
    ],
    percentageText: [{ color: context?.theme.colors.basics.grey.c400 }, style?.percentageText]
})
