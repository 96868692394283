import { Context, Style } from './interfaces';

export const getStyles = (
  context?: Context,
  style?: Style,
): Style => ({
  container: [
    {
      backgroundColor: context?.theme.colors.basics.white,
      flex: 1,
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginBottom: context?.theme.metrics.spacing.huge,
    },
    style?.container,
  ],
  VVPListContainer: [
    {
      width: '100%',
      maxWidth: context?.theme.metrics.contentSizes.centeredContentMaxWidth,
    },
    context?.isMobile && {
      paddingHorizontal: context?.theme.metrics.spacing.s,
    },
    style?.VVPListContainer,
  ],
  vvpCardsStyle: [
    {
      marginBottom: context?.theme.metrics.spacing.xm,
    },
    style?.vvpCardsStyle,
  ],
  buttonStyle: [
    {
      marginTop: context?.theme.metrics.spacing.m,
    },
    style?.buttonStyle,
  ],
});
