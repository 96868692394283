import { Context, Styles } from './interfaces';

export const getStyles = (
  context: Context,
  style: Styles
): Styles => ({
  containerStyle: [
    {
      flex: 1

    },
    style?.containerStyle
  ],
  headerContainerStyle: [
    {
      flex: 1,
      paddingHorizontal: context?.theme.metrics.spacing.s,
      paddingVertical: context?.theme.metrics.spacing.m,
    },
    context?.expanded ? {
      borderTopRightRadius: context.theme.metrics.spacing.xs,
    } : {
      borderTopRightRadius: context.theme.metrics.spacing.xs,
      borderBottomRightRadius: context.theme.metrics.spacing.xs
    },
    context?.backGroundColorCustom ? {
      backgroundColor: context?.backGroundColorCustom
    } : {
      backgroundColor: context?.theme.colors.basics.grey.c100
    },
    style?.headerContainerStyle
  ],
  headerTopAlignement: [
    {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    style?.headerTopAlignement
  ],
  headerTitleAlignement: [
    {
      flexDirection: 'row',
      maxWidth: "94%"
    },
    style?.headerTitleAlignement
  ],
  DetailContainerStyle: [
    {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: context?.theme.metrics.spacing.xs,
    },
    style?.DetailContainerStyle
  ],
  helpPointStyle: [
    {
      paddingRight: context?.theme.metrics.spacing.s
    },
    style?.helpPointStyle
  ],
  DetailLabelStyle: [
    {
      flex: 1
    },
    style?.DetailLabelStyle
  ],
  DetailValueStyle: [
    {
      minWidth: 50,
      textAlign: 'right',
      marginLeft: context?.theme.metrics.spacing.s
    },
    style?.DetailValueStyle
  ],
  DetailsContainerStyles: [
    {
      marginTop: context?.theme.metrics.spacing.s
    },
    style?.DetailsContainerStyles
  ],
  iconContainer: [
    {
      width: context?.theme.metrics.spacing.xxm,
      height: context?.theme.metrics.spacing.xxm,
      backgroundColor: context?.theme.colors.basics.white,
      borderRadius: context?.theme.metrics.spacing.xxs,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center'
    },
    style?.iconContainer
  ]
});
