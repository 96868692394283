import axios from 'axios';

import { API_URL } from '@config/index';
import { EDocumentType } from '@constants/documents';

const LANGUAGES: { [p: string]: string } = {
  fr: 'French',
  en: 'English',
  de: 'German',
  it: 'Italian',
  es: 'Spanish',
  pt: 'Portuguese',
  ro: 'Romanian',
  tr: 'Turkish',
  sl: 'Slovenian',
  pl: 'Polish',
  nl: 'Dutch',
  cn: 'Chinese',
  ar: 'Arabic'
}

const mapLanguage = (isoCode: string) => LANGUAGES[isoCode] || 'None';

export const fetchDocumentsCategories = async (companyId: string, isoCode: string) => {
  const language = mapLanguage(isoCode);
  return axios.get(`${API_URL}/api/v1/company/${companyId}/documents?language=${language}`);
};

export const fetchDownloadDocumentByType = async (companyId: string, documentType: string, isoCode: string) => {
  const language = mapLanguage(isoCode);
  return axios.get(`${API_URL}/api/v1/company/${companyId}/download/${documentType}?language=${language}`, {
    responseType: "blob",
  });

}

export const fetchDownloadDocumentById = async (companyId: string, documentId: string) => {
  return axios.get(`${API_URL}/api/v1/companies/${companyId}/documents/edocuments/${documentId}`, {
    responseType: "blob",
  });
}

export const fetchEDocuments = async (companyId: string) => {
  return axios.get(`${API_URL}/api/v1/companies/${companyId}/documents/edocuments`);
}

export const postEDocumentsSubscription = async (
  companyId: string,
  isSubscriber: boolean
) => {
  return axios.post(`${API_URL}/api/v1/companies/${companyId}/documents/edocuments`, {
    choix: EDocumentType[isSubscriber ? 'CLASSIC' : 'PAPER']
  })
}