import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

import { Context } from './interfaces';

export const useStyles = (context: Context) => {
  const theme = useTheme();
  const styles = useMemo(
    () =>
      StyleSheet.create({
        datePickerContentTitle: {
          color: theme.colors.text.disabled.c500
        },

        buttonsContainer: {
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between'
        },
        datePickerContainer: {
          flexDirection: 'row',
          justifyContent: 'space-between'
        },
        datePicker: {
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderWidth: 1,
          borderColor: theme.colors.borders.fields.c500,
          borderTopLeftRadius: theme.metrics.borderRadius.xs,
          borderTopRightRadius: theme.metrics.borderRadius.xs,
          height: 60,
          width: '45%',
          padding: theme.metrics.spacing.s
        },
        datePickerContent: {
          justifyContent: 'space-between'
        },
        scrollViewContainer: {
          paddingBottom: theme.metrics.spacing.l
        },
        dateEndSelection: {
          position: 'absolute',
          top: 95,
          width: '45%',
          backgroundColor: theme.colors.basics.white,
          borderColor: theme.colors.borders.fields.c500,
          borderWidth: 1,
          borderBottomLeftRadius: theme.metrics.borderRadius.xs,
          borderBottomRightRadius: theme.metrics.borderRadius.xs,
          ...(context.datesSelectionVisible.end && {
            right: 0
          })
        },
        dateBeginSelection: {
          position: 'absolute',
          top: 95,
          width: '45%',
          backgroundColor: theme.colors.basics.white,
          borderColor: theme.colors.borders.fields.c500,
          borderWidth: 1,
          borderBottomLeftRadius: theme.metrics.borderRadius.xs,
          borderBottomRightRadius: theme.metrics.borderRadius.xs,
          ...(context.datesSelectionVisible.begin && {
            left: 0
          })
        }
      }),
    [theme]
  );
  return styles;
};
