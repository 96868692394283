import React, { useCallback, useMemo, useState } from 'react';
import { View } from 'react-native';

import { HeaderResponse, SimpleButton } from '@ere-uilib/molecules';
import { RadiosButtonList } from '@ere-uilib/organisms';
import { useTheme } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';

import { OptionsState, Props } from './interface';
import { useStyles } from './useStyles';
import { Text } from '@ere-uilib/atoms';

type SelectedOption = OptionsState | undefined;
export const CardResponse: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  headerTitle,
  headerAmount,
  shouldFormatAsPlusMinus,
  headerIconName,
  headerIconSize,
  headerContainerStyle,
  headerIconContainerStyle,
  headerTitleStyle,
  headerIconColor,
  headerAmountStyle,
  containerStyle,
  childrenViewStyle,
  options,
  renderHTML,
  buttonTitle,
  buttonLarge,
  showActionButton = true,
  buttonAction,
  isButtonDisabled,
  isDisabled,
  disabledMessage,
  isExternalButton = false,
  testId,
}) => {
  const theme = useTheme();
  const { formatMessage, formatCurrencyNumber } = useTranslation();
  const styles = useStyles({ isExternalButton });

  const defaultSelectedOption = options?.length === 1 ? options[0] : undefined;
  const [selectedOption, setSelectedOption] = useState<SelectedOption>(defaultSelectedOption);

  const _isButtonDisabled = useMemo(() => {
    return Boolean(options?.length && !selectedOption) || isButtonDisabled;
  }, [options, selectedOption, isButtonDisabled]);

  const handleSelectOption = useCallback((optionValue: string) => {
    if (!optionValue) {
      return;
    }

    const newSelectedOption = options?.find(
      item => item.value === optionValue
    );
    setSelectedOption(newSelectedOption);
  }, [options]);

  const optionsForRadiosButtonList = useMemo(() =>
    options?.map(option => {
      return {
        value: option.value,
        label: formatMessage({ id: option.labelCode }),
        disabled: false,
      };
    }) || [], [options, formatMessage]);

  const handlePressButton = useCallback(() => {
    return buttonAction && buttonAction(selectedOption);
  }, [buttonAction, selectedOption]);

  const renderActionButton = useCallback(() => {
    if (!(!!buttonTitle && showActionButton)) return;
    return (
      <View style={styles.buttonContainer}>
        <SimpleButton
          containerStyle={[styles.button, buttonLarge && styles.buttonLargeStyle]}
          disabled={_isButtonDisabled}
          onPress={handlePressButton}
          testId={`confirm_${testId}`}
          title={buttonTitle}
        />
      </View>
    )
  }, [
    _isButtonDisabled,
    buttonLarge,
    buttonTitle,
    handlePressButton,
    showActionButton,
    styles,
  ]);

  const renderContent = useCallback(() => {
    if (isDisabled && !!disabledMessage) return null;

    return (
      <View style={styles.renderContentWrapper}>
        {!!renderHTML && (
          <View style={styles.bodyTextContainer}>
            {renderHTML()}
          </View>
        )}
        {!!options && options.length > 1 && (
          <View style={styles.optionsList}>
            <RadiosButtonList
              activeRadio={selectedOption?.value}
              onChange={handleSelectOption}
              options={optionsForRadiosButtonList}
            />
          </View>
        )}
        {!!children && <View style={[styles.childrenViewWrappper, childrenViewStyle]}>{children}</View>}
        {!isExternalButton && renderActionButton()}
      </View>
    )
  }, [
    isDisabled,
    disabledMessage,
    styles,
    renderHTML,
    theme,
    formatMessage,
    formatCurrencyNumber,
    options,
    selectedOption,
    handleSelectOption,
    optionsForRadiosButtonList,
    children,
    isExternalButton,
    renderActionButton,
    childrenViewStyle,
  ]);

  const renderDisabledMessage = useCallback(() => {
    if (!isDisabled || !disabledMessage) return null;

    return (
      <View style={styles.disabledMeessageContainer}>
        <Text
          style={{ color: theme.colors.basics.grey.c500 }}
          variant="t2"
          weight="light"
        >
          {disabledMessage}
        </Text>
      </View>
    );
  }, [
    isDisabled,
    disabledMessage,
    styles
  ]);

  return (
    <View>
      <View style={[styles.container, containerStyle]}>
        <HeaderResponse
          amount={headerAmount}
          customAmountStyle={headerAmountStyle}
          customContainerStyle={headerContainerStyle}
          customIconContainerStyle={headerIconContainerStyle}
          customTitleStyle={headerTitleStyle}
          iconColor={headerIconColor}
          iconName={headerIconName}
          iconSize={headerIconSize}
          isDisabled={isDisabled}
          shouldFormatAsPlusMinus={shouldFormatAsPlusMinus}
          title={headerTitle}
        />
        <View
          style={[styles.bodyContainer, isDisabled && styles.disabledBodyContainer,]}
        >
          {renderDisabledMessage()}
          {renderContent()}
        </View>
      </View>
      {isExternalButton && renderActionButton()}
    </View>
  );
};