import { RadioButton } from "@ere-uilib/molecules";
import React, { useCallback, useState } from "react";

interface Props {
  title?: string;
  onChange?: () => void;
  defaultValue: boolean;

}

export function RadioResponseComponent({
  title,
  defaultValue,
  onChange,
}: Props) {

  const [value, setValue] = useState(defaultValue);

  const handleChange = useCallback(() => {
    const newValue = !value;
    setValue(newValue);
    onChange && newValue && onChange();
  }, [value, onChange]);

  return (
    <RadioButton
      testId={`checkBox_${title}`}
      label={title}
      onPress={handleChange}
      value={value}
    />
  );
}
