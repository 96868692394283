import React, { useCallback, useState } from 'react'
import { Pressable, TouchableOpacity, View } from 'react-native'

import { Calendar, FontIcon, Text } from '@ere-uilib/atoms'
import { useTheme } from '@ere-uilib/styles'
import { colors } from '@ere-uilib/styles/theme/themeFolder'
import { useTranslation } from '@ere-uilib/translations'

import { CalendarInputProps } from './interface'
import { useStyles } from './useStyles'
import { useCalendarLabels } from '@ere-uilib/hooks'

export const CalendarInput: React.FC<React.PropsWithChildren<CalendarInputProps>> = ({
  valueDate,
  disabled,
  onDateChange
}) => {
  const styles = useStyles()
  const [showPicker, setShowPicker] = useState<boolean>(false)
  const theme = useTheme()
  const { monthNames, dayNames, dayNamesShort } = useCalendarLabels()

  const { formatMessage, formatDate } = useTranslation()
  const cleanIconColor = colors.basics.grey.c500

  const handleDateSelect = useCallback(
    (date: Date) => {
      onDateChange(date)
      setShowPicker(false)
    },
    [onDateChange, setShowPicker]
  )

  return (
    <View>
      <Pressable
        onPress={() => setShowPicker(prev => !prev)}
        style={[styles.dateContainer, disabled && styles.disabledContainer]}>
        <View style={styles.textContainer}>
          <Text
            style={[
              styles.placeholder,
              valueDate && styles.textOnly,
              disabled && styles.disabledPlaceholder
            ]}
            variant="t5"
            weight="regular">
            {formatMessage({ id: 'UnavailableRepaymentReleaseSelectionTypeDate' })}
          </Text>
          {valueDate && (
            <Text
              style={styles.date}
              variant="t5"
              weight="regular">
              {formatDate({ value: valueDate })}
            </Text>
          )}
        </View>
        <View style={styles.iconsContainer}>
          {valueDate && (
            <TouchableOpacity
              onPress={() => onDateChange(undefined)}
              style={styles.cleanContainer}>
              <FontIcon
                color={cleanIconColor}
                name="effacer"
                size={theme.metrics.iconSizes.m}
              />
            </TouchableOpacity>
          )}
          <FontIcon
            name="calendar"
            size={theme.metrics.iconSizes.m}
          />
        </View>
      </Pressable>
      {!disabled && showPicker && (
        <Calendar
          monthNames={monthNames}
          dayNames={dayNames}
          dayNamesShort={dayNamesShort}
          onChange={handleDateSelect}
          value={valueDate}
        />
      )}
    </View>
  )
}
