import React from 'react';
import { View } from 'react-native';
import { Defs, LinearGradient, Rect, Stop, Svg } from 'react-native-svg';

import { createUseStyles } from '@ere-uilib/styles';
import { useTheme } from '@ere-uilib/styles/theme';

import { Props } from './interfaces';
import { getStyles } from './styles';

// TODO : allow multiple orientations,...

export const BackgroundGradientTransition: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  color,
  containerStyle,
  backgroundContainer,
  gradientContainer,
  background,
})=>{
  const theme = useTheme();
  const usedColor = color || theme.colors.basics.white;
  const styles = useStyles({
    theme,
    color: usedColor,
  }, {
    containerStyle,
    backgroundContainer,
    gradientContainer,
    background,
  });

  return (
    <View
      pointerEvents="box-none"
      style={styles.containerStyle} >
      <View
        pointerEvents="none"
        style={styles.backgroundContainer}
      >
        <View style={styles.gradientContainer}>
          <SvgGradient
            color={usedColor}
          />
        </View>
        <View style={styles.background} />
      </View>
      <>{children}</>
    </View>
  );
};

const SvgGradient = ({
  color,
}:{
  color?:string
}) => {
  return (
    <Svg>
      <Defs>
        <LinearGradient
          id={'gradient-' + color}
          x1="0%"
          x2="0%"
          y1="0%"
          y2="100%"
        >
          <Stop
            offset="0%"
            stopColor={color + '00'} />
          <Stop
            offset="100%"
            stopColor={color} />
        </LinearGradient>
      </Defs>
      <Rect
        fill={'url(#gradient-' + color + ')'}
        height="100%"
        width="100%"
        x="0"
        y="0"/>
    </Svg>
  );
};

const useStyles = createUseStyles(getStyles);
