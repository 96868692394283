import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

export const useStyles = ({ iconWidth, iconHeight }: { iconWidth: number; iconHeight: number }) => {
  const theme = useTheme();

  const styles = useMemo(
    () =>
      StyleSheet.create({
        htmlStyledRender: {
          marginTop: theme.metrics.spacing.m
        },
        iconStyle: {
          width: iconWidth,
          height: iconHeight
        }
      }),
    [theme, iconWidth, iconHeight]
  );

  return styles;
};
