import { Context, Styles } from './interfaces';

export const getStyles = (
  context?: Context,
  style?: Styles,
): Styles => ({
  imageContainer: [
    {
      paddingVertical: context?.theme.metrics.spacing.xxxxl,
      backgroundColor: context?.theme.colors.basics.grey.c200
    },
    !context?.isMobile ? {
      paddingHorizontal: context?.theme.metrics.spacing.huge
    } : {
      flex: 1,
      alignItems: 'center'
    },
    style?.imageContainer
  ],
  container: [
    {
      marginTop: context?.theme.metrics.spacing.xl,
      backgroundColor: context?.theme.colors.basics.white
    },
    !context?.isMobile && {
      flexDirection: 'row'
    },
    style?.container
  ],
  placeholderContainer: [
    {
      flex: 1,
      paddingVertical: context?.theme.metrics.spacing.xxxxl,
      paddingHorizontal: context?.theme.metrics.spacing.m
    },
    style?.placeholderContainer
  ],
});
