import {useSelector} from 'react-redux';

import { getSelectedAccountType } from '@modules/bank-details/selectors';
import { getProfileUserInfosRequest } from '@modules/profile/actions/profileActions';

import { BankSignaturePageComponent } from './BankSignaturePage.component';
export const BankSignaturePage : React.FC = () => {
  const selectedAccountType = useSelector(getSelectedAccountType);
  return (
      <BankSignaturePageComponent onGetUserInfo={getProfileUserInfosRequest} selectedAccountType={selectedAccountType} />
  );
};
