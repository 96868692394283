import { useSelector } from 'react-redux';

import { AppState } from '@modules/index';
import {
  getOptionEERRegexPersoneo,
  getOptionEERRegexVisiogo,
} from '@modules/settings/selectors';

export function useSettings() {
  const optionEERRegexPersoneo = useSelector((state: AppState) => getOptionEERRegexPersoneo(state));
  const optionEERRegexVisiogo = useSelector((state: AppState) => getOptionEERRegexVisiogo(state));

  return {
    optionEERRegexPersoneo,
    optionEERRegexVisiogo,
  };
}
