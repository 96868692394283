import React, { useMemo } from 'react'
import { StyleSheet, StyleProp, ViewStyle } from 'react-native'

import { 
  useTheme,
} from '@components/index';

export const  useStyles =({
  style,
}:{
  style?: StyleProp<ViewStyle>
})=>{
  const theme = useTheme();

  const styles = useMemo(()=>StyleSheet.create({
    premiumAmountCard: { 
      padding: theme.metrics.spacing.xm,
      backgroundColor: theme.colors.background.blueLight.c100,
      borderRadius: theme.metrics.borderRadius.xs,
      ...(style && StyleSheet.flatten(style))
    },
    AmountCardHeader: { 
      marginBottom: theme.metrics.spacing.xxl,
      flexDirection: 'row',
    },
    AmountCardHeaderDetail: { 
      flex: 1,
    },
    AmountCardImage: { 
      marginLeft: theme.metrics.spacing.m,
      height: 60,
      width: 85,
    },
    companyAmount: { },
    infoSet: { 
      flexDirection: 'row',
    },
  }), [theme, style])
  return styles

}