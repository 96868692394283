import { useDispatch, useSelector } from 'react-redux';

import { ProfileActionsType } from '@modules/profile/actions/profileActionsTypes';
import {
  profilEditPasswordReset,
  profilEditPasswordRequest,
} from '@modules/profile/actions/profileActions';
import {
  getEditPasswordError,
  getIsProfilEditPasswordSuccess,
  getProfileError
} from '@modules/profile/selectors';
import { getApplicationSettings } from '@modules/settings/selectors';
import { getLoadingState } from "@modules/funds/selectors";

import { EditPasswordPage as EditPasswordPageComponent } from './EditPasswordPage.component';

export const EditPasswordPage : React.FC = () => {
  const appSettings =  useSelector(getApplicationSettings);
  const isApiLoading = !!useSelector(getLoadingState(ProfileActionsType.EDIT_PASSWORD_REQUEST));
  const isDisplaySuccessModal = useSelector(getIsProfilEditPasswordSuccess);
  const error = useSelector(getProfileError);
  const editPasswordError = useSelector(getEditPasswordError);
  const disptach = useDispatch();

  return(<EditPasswordPageComponent
      editPasswordError={editPasswordError}
      error={error}
      isApiLoading={isApiLoading}
      isDisplaySuccessModal={isDisplaySuccessModal}
      appSettings={appSettings}
      onCloseSuccessModal={() => disptach(profilEditPasswordReset())}
      onSetProfileUserPasswordChangeRequest={(currentPassword, newPassword) => disptach(profilEditPasswordRequest(currentPassword, newPassword))}/>);
}
