import React from 'react';

import { NotificationSystem } from '@ere-uilib/organisms';
import { useNotificationSystem } from '@hooks/useNotificationSystem';

export function NotificationSystemContainer() {
  const {
    notifications,
    removeNotification,
  } = useNotificationSystem();

  return (
    <NotificationSystem
      notifications={notifications}
      onPressCloseButton={removeNotification}
      onTimeout={removeNotification}
    />
  );
}
