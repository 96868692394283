import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

export const useStyles = () => {
  const theme = useTheme();

  const styles = useMemo(
    () =>
      StyleSheet.create({
        cardAnnuityToCapital: {
          marginTop: theme.metrics.spacing.xm
        },
        formContainer: {
          display: 'flex',
          flexDirection: 'row',
          marginTop: theme.metrics.spacing.s,
        },
        leftContainer: {
          flex: 1,
        },
        leftInput: {
          width: '100%',
          paddingRight: theme.metrics.spacing.m
        },
        rightContainer: {
          marginLeft: theme.metrics.spacing.m,
          flex: 1,
        },
        rightInput: {
          width: '100%',
          paddingRight: theme.metrics.spacing.m
        },
        textFieldRightIcon: {
          right: theme.metrics.spacing.m,
          paddingLeft: theme.metrics.spacing.none,
          paddingRight: theme.metrics.spacing.none
        }
      }),
    [theme]
  );

  return styles;
};
