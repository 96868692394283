import { connect } from 'react-redux'

import { getSelectedAccountType } from '@modules/bank-details/selectors'
import { AppState } from '@modules/reducers'

import { BankSignaturePageComponentRC } from './BankSignaturePageRC.component'
import { downloadDocumentMandatRCRequest } from '@modules/bank-details/actions/actionsCreators'
import { BankDetailsActionsType } from '@modules/bank-details/actions/actionsTypes'

const mapStateToProps = (state: AppState) => ({
  signMandatLoadingStatus: state.isLoading[BankDetailsActionsType.SIGN_MANDAT_RC_REQUEST],
  selectedAccountType: getSelectedAccountType(state)
})

const mapDispatchToProps = {
  handleDocumentMandatRCRequest: downloadDocumentMandatRCRequest
}

export const BankSignaturePageRC = connect(
  mapStateToProps,
  mapDispatchToProps
)(BankSignaturePageComponentRC)
