import { useNavigation } from "@react-navigation/native";
import React, { useCallback, useRef } from 'react';
import { ScrollView, View } from 'react-native';

import {
  AvailabilityContainer, InstallmentsTab, SavingsEvolutionCard,
  SavingsHeader, SavingsOperationsHistory, SavingsRepartitionsConnected
} from '@components/index';
import { locator } from '@constants/locator';
import { RouteNames } from '@constants/navigation';
import { SimulatorType } from '@constants/sapiendo';
import { createUseStyles, useScreenSizes, useTheme } from '@ere-uilib/styles';
import { FilterRoulesState } from '@modules/savings/types';

import { SavingsInnerPageProps } from './interfaces';
import { getStyles } from './styles';

export const SavingsInnerPage = ({
  innerNavigations,
  repartitions,
  isRepartitionsApiLoading,
  isRepartitionsByPlansApiLoading,
  availabilitiesDetail,
  availabilitiesFunds,
  isAvailabilitiesApiLoading,
  isAvailabilitiesByPlansApiLoading,
  operationsHistory,
  appliedOperationsFilterRoules,
  isOperationsHistoryApiLoading,
  isSavingsEvolutionLoading,
  isSavingsEvolutionByPlanLoading,
  isOperationsHistoryByPlansApiLoading,
  onPressFilter,
  onSetRepartitionFilters,
  planId,
  installments,
  getInstallments,
  isInstallmentApiLoading,
  planName,
  selectedTab,
  onTabChange,
  selectedPlan,
  addedValue,
  titleKey,
  amount,
  swipeLeft,
  swipeRight,
  onApplySavingsHistoryFilters,
  savingsHistory,
  savingsHistoryFilters,
  appSettings,
  hasOnGoingVVPHistory,
  savingPlans,
  isFetchingOnGoingVVP,
  onPressVVPManagement,
}: SavingsInnerPageProps) => {
  const theme = useTheme();
  const navigation = useNavigation();
  const { isMobile, isTablet, windowHeight } = useScreenSizes();
  const scrollViewRef = useRef<ScrollView>(null);

  const onScrollToTop = () => {
      scrollViewRef?.current?.scrollTo?.({ x: 0, y: 0, animated: true });
  }
  const styles = useStyles({ theme, isMobile, isTablet, windowHeight }, {});
  const handleOnOperationCardPress = (operationId: string) => {
    navigation.navigate(RouteNames.OperationDetails, {
      id: operationId,
    })
  };

  const callbackWithScrollToTop = useCallback((callbackFunction: (navigationValue: string) => void, navigationValue: string) => {
      callbackFunction(navigationValue);
      onScrollToTop();
  }, []);

  const renderPages = (pageName: string) => {
    switch (pageName) {
      case innerNavigations[0]:
        return (
          <SavingsRepartitionsConnected
            amount={amount}
            isApiLoading={
              isRepartitionsApiLoading || isRepartitionsByPlansApiLoading
            }
            onSetRepartitionFilters={onSetRepartitionFilters}
            planId={planId}
            planName={planName}
            repartitions={repartitions}
            selectedPlan={selectedPlan}
          />
        );
      case innerNavigations[1]:
        return (
          <AvailabilityContainer
            amount={amount}
            availabilitiesDetail={availabilitiesDetail}
            availabilitiesFunds={availabilitiesFunds}
            isApiLoading={
              isAvailabilitiesApiLoading || isAvailabilitiesByPlansApiLoading
            }
            isSapiendoActive={appSettings?.EnableFeatureSapiendo}
            isUnlockHelpActive={appSettings?.EnableFeatureAideDeblocage}
            onSapiendoPress={() => {
              appSettings?.EnableFeatureSapiendo &&
              navigation.navigate(RouteNames.SapiendoRedirectPage, {
                type: SimulatorType.RETIREMENT
              });
            }}
            planName={planName}
            selectedPlan={selectedPlan}
          />
        );
      case innerNavigations[2]:
        return (
          <InstallmentsTab
            amount={amount}
            getInstallments={getInstallments}
            installments={installments}
            isApiLoading={isInstallmentApiLoading}
            planId={planId}
            planName={planName}
            selectedPlan={selectedPlan}
            testId={locator._my_savings._date_selector}
          />
        );
      case innerNavigations[3]:
        const loadingState = selectedPlan === 0
                                    ? isOperationsHistoryApiLoading
                                    : isOperationsHistoryByPlansApiLoading;
        return (
          <SavingsOperationsHistory
            amount={amount}
            appliedOperationsFilterRoules={appliedOperationsFilterRoules}
            hasOnGoingVVP={hasOnGoingVVPHistory}
            isApiLoading={loadingState}
            isFetchingOnGoingVVP={isFetchingOnGoingVVP}
            onOperationCardPress={handleOnOperationCardPress}
            onPress={(filterRoules: FilterRoulesState) =>
              onPressFilter(filterRoules)
            }
            onPressVVPManagement={onPressVVPManagement}
            operationsHistory={operationsHistory}
            planName={planName}
            selectedPlan={selectedPlan}
          />
        );
      case innerNavigations[4]:
        return (
          <SavingsEvolutionCard
            amount={amount}
            isSavingsEvolutionLoading={
              isSavingsEvolutionLoading || isSavingsEvolutionByPlanLoading
            }
            onApplySavingsHistoryFilters={onApplySavingsHistoryFilters}
            planName={planName}
            savingsHistory={savingsHistory}
            savingsHistoryFilters={savingsHistoryFilters}
            selectedPlan={selectedPlan}
          />
        );
      default:
        return <View style={styles.container} />;
    }
  };
  return (
   <View style={styles.container}>
      <SavingsHeader
        addedValue={addedValue}
        amount={amount}
        innerNavigations={innerNavigations}
        onTabChange={(tabId:string) => callbackWithScrollToTop(onTabChange, tabId)}
        savingPlansLength={savingPlans?.length}
        selectedPlan={selectedPlan}
        selectedTab={selectedTab || innerNavigations[0]}
        swipeLeft={(nav: string) => callbackWithScrollToTop(swipeLeft, nav)}
        swipeRight={(nav: string) => callbackWithScrollToTop(swipeRight, nav)}
        titleKey={titleKey}
      />
      <ScrollView
        contentContainerStyle={styles.scrollViewContentContainer}
        ref ={scrollViewRef}
        style={styles.scrollView}
      >
        {renderPages(selectedTab)}
      </ScrollView>
    </View>
  );
};

const useStyles = createUseStyles(getStyles);
