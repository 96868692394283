import { useDispatch, useSelector } from 'react-redux';

import { CampaignActionsType } from '@modules/campaign/actions/campaignActionsTypes';
import {
  executeCallback,
  getUserCampaignRequest,
  submitUserCampaignRequest,
} from '@modules/campaign/actions/campaignActions';
import { getCampaignData, getCampaignError, getIsCampaignSubmitted } from '@modules/campaign/selectors';
import { getLoadingState } from "@modules/funds/selectors";
import { ConsentTransferCreditComponent } from './ConsentTransferCredit.component';

export const ConsentTransferCreditPage : React.FC = () => {
  const isFormSuccessfullySubmitted = !!useSelector(getIsCampaignSubmitted);
  const isErrorSubmit = !!useSelector(getCampaignError);
  const isGetUserCampaignLoading = !!useSelector(getLoadingState(CampaignActionsType.GET_USER_CAMPAIGN_REQUEST));
  const isSubmitLoading = !!useSelector(getLoadingState(CampaignActionsType.SUBMIT_USER_CAMPAIGN_REQUEST));
  const campaignData = useSelector(getCampaignData);
  const disptach = useDispatch();

  return (<ConsentTransferCreditComponent
      getUserCampaign={(compagnId) => disptach(getUserCampaignRequest(compagnId))}
      executeCallback={(callbackType) => disptach(executeCallback(callbackType))}
      submitUserCampaign={(useCampaign) => disptach(submitUserCampaignRequest(useCampaign))}
      isFormSuccessfullySubmitted={isFormSuccessfullySubmitted}
      isErrorSubmit={isErrorSubmit}
      isGetUserCampaignLoading={isGetUserCampaignLoading}
      isSubmitLoading={isSubmitLoading}
      campaignData={campaignData} />);
}
