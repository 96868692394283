export interface RefundsCodeErreurLabelType {
  [key: string]: string
}
export const refundsCodeErreurLabels: RefundsCodeErreurLabelType = {
  'DONNEES_PERSO': 'ContextHabilitationS2E_donneesPerso',
  'INITIE': 'ContextHabilitationS2E_initie',
  'FONDS_CCB': 'ContextHabilitationS2E_fondsccb',
  'STATUT_FONDS': 'ContextHabilitationS2E_statutFonds',
  'SANS_FISCALITE': 'ContextHabilitationS2E_sansFiscalite',
  'SANS_FISCALITE_RACHAT': 'ContextHabilitationS2E_sansFiscaliteRachat',
  'SANS_RACHAT': 'ContextHabilitationS2E_sansRachat',
  'AVOIRS_BLOQUES': 'ContextHabilitationS2E_avoirsBloques',
};

export enum RefundDecisionPropertiesEnum {
  REPAYMENT_UNAVAILABLE = 'RepaymentUnavailable',
  REPAYMENT_AVAILABLE = 'RepaymentAvailable',
}
