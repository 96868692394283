import { HelpPoint } from '@ere-uilib/organisms';
import { useTranslation } from '@translations/index';
import React, { useMemo } from 'react';
import { View } from 'react-native';
import AvailabilityListItemByDate from '@components/ERE360Components/AvailabilityContainer/components/AvailibilitiesDetailsByDate/AvailabilitiesListItemByDate/AvailabilityListItemByDate';
import { useStyles } from './useStyles';
import { AvailabilitiesDetailCardProps } from './interfaces';
import { Text } from '@ere-uilib/atoms';
import { useTheme } from '@ere-uilib/styles';
import { AvalabilityItemByDate } from '@modules/savings/types';

export const AvailabilitiesDetailsByDate: React.FC<AvailabilitiesDetailCardProps> =
  ({
     availabilitiesDetail,
     planName,
     isSapiendoActive,
     isUnlockHelpActive,
     onSapiendoPress,
   }) => {
    const { formatMessage, getMessageRaw } = useTranslation();
    const theme = useTheme();
    const styles = useStyles();

    const availabilityListItems: AvalabilityItemByDate[] = [
    {
      condition: (!!availabilitiesDetail?.immediateAmount &&
          !isNaN(availabilitiesDetail.immediateAmount) &&
          availabilitiesDetail.immediateAmount !== 0),
      amount: availabilitiesDetail?.immediateAmount,
      icon: "horloge",
      label: "benefit-time_content1_label",
      testId: "benefit-time_content1_label",
    },
    {
      condition: (!!availabilitiesDetail?.midTermAmount &&
          !isNaN(availabilitiesDetail.midTermAmount) &&
          availabilitiesDetail.midTermAmount !== 0),
      amount: availabilitiesDetail?.midTermAmount,
      icon: "calendrier-5",
      items: availabilitiesDetail?.midTermAmountAvailabilities,
      label: "benefit-time_content2_label",
      testId: "benefit-time_content2_label",
    },
    {
      condition: (!!availabilitiesDetail?.longTermAmount &&
          !isNaN(availabilitiesDetail.longTermAmount) &&
          availabilitiesDetail.longTermAmount !== 0),
      amount:availabilitiesDetail?.longTermAmount,
      icon:"retraite",
      label:"benefit-time_content3_label",
      testId:"benefit-time_content3_label",
      linkAction:() =>
        isSapiendoActive && onSapiendoPress?.(),
      linkText:isSapiendoActive ? formatMessage({
        id: 'benefit-time_content3_sub-label'
      }) : undefined
    },
    {
      condition: (!!availabilitiesDetail?.earlyReleaseAmount &&
          !isNaN(availabilitiesDetail.earlyReleaseAmount) &&
          availabilitiesDetail.earlyReleaseAmount !== 0),
      amount:availabilitiesDetail?.earlyReleaseAmount,
      amountLabel:"Dashboard_CardDisponibility_Term4_upto_label",
      icon:"mdp",
      label:"Dashboard_CardDisponibility_Term4_label",
      testId:"Dashboard_CardDisponibility_Term4_label",
      linkAction:() => {
        isUnlockHelpActive && onSapiendoPress?.();
      },
      linkText:isUnlockHelpActive ? formatMessage({
        id: 'benefit-time_content4_sub-label'
      }) : undefined
    }
    ]

    const renderList = useMemo(() =>  {
      return (
        <>
          {
            availabilityListItems.map((itemInfo:AvalabilityItemByDate, index) => {
              return !!itemInfo.condition && (
                <AvailabilityListItemByDate
                  amount={itemInfo.amount}
                  amountLabel={itemInfo.amountLabel}
                  icon={itemInfo.icon}
                  items={itemInfo.items}
                  key={`availability_item_${index}`}
                  label={itemInfo.label}
                  linkAction={itemInfo.linkAction}
                  linkText={itemInfo.linkText}
                  testId={itemInfo.testId}
                /> )
            }
          )}
        </>
      );
    }, [availabilitiesDetail]);

    return (
      <>
        <View style={styles.buttonContainer}>
          <HelpPoint
            contentHtml={getMessageRaw({
              id: `saving_Tab2_info_content_${planName}`,
              defaultMessage: ' '
            })}
            iconSize={theme.metrics.iconSizes.l}
            modalTitle={formatMessage({ id: 'fund-card_help-info_menu' })}
          />
        </View>

        <View style={styles.contentContainer}>
          {renderList}
        </View>

        <Text variant="t4" weight="light" style={styles.brutIndicator}>
          {formatMessage({ id: 'RetirementJourneySavingGrossAmountHeader' })}
        </Text>
    </>
    );
  };