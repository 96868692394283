import React, { useState } from 'react';
import { View } from 'react-native';
import {
    VictoryAxis, VictoryChart, VictoryLabel, VictoryLine, VictoryTooltip, VictoryVoronoiContainer
} from 'victory-native';

import { DotsCursorsType, LineChartToolTip, Text } from '@ere-uilib/atoms';
import { createUseStyles, useScreenSizes, useTheme } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';

import {
    MultiFundPerformanceChartProps,
    ToolTipFlyoutProps
} from './interfaces';
import {
    MultiFundPerformanceChartPerfDataType
} from './sharedInterfaces';
import { getStyles } from './styles';

export const MultiFundPerformanceChart: React.FC<React.PropsWithChildren<MultiFundPerformanceChartProps>> = ({
  data,
  containerStyle,
}) => {
  const theme = useTheme();
  const { isMobile, isTablet, isDesktop, windowHeight } = useScreenSizes();
  const { formatDate, formatMessage, formatCurrencyNumber } = useTranslation();
  // to make the chart responsive we measure his container size
  const [
    containerSize,
    setContainerSize,
  ] = useState<{width: number; height: number}>({ width: 300, height: 300 });
  // to prevent tooltip default size to be to different from expected we save the last tooltip size
  const [tooltipLayoutSize, setTooltipLayoutSize] = useState<{width: number; height: number}>();
  const styles = useStyles(
    { theme, isMobile, isTablet, isDesktop },
    {
      containerStyle,
    },
  );

  // mapping data
  const lines = data.map(line=>{
    const baseValue = line.perfData[0].y;
    // calcul base 100 values and save vl in tooltipData
    const perfData = line.perfData.map(lineData=>{
      const base100Y = lineData.y / (baseValue / 100);
      return {
        ...lineData,
        y: base100Y,
        tooltipData: {
          color: line.color,
          vl: lineData.y,
        },
      };
    });
    // return final remapped line
    return {
      ...line,
      perfData,
    };
  });

  let minYAxisValue = lines?.[0]?.perfData?.length > 0 ? lines?.[0]?.perfData[0].y : 0;
  let maxYAxisValue = 0;
  // allXKeys is used to ensure line to be on same x, even if each line does not have same period
  const allXKeys:MultiFundPerformanceChartPerfDataType['x'][] = [];
  lines.forEach((line, iLine)=>{
    line?.perfData?.map((item:MultiFundPerformanceChartPerfDataType, index:number)=>{
      if (item.x && !allXKeys.includes(item.x)) {
        allXKeys.push(item.x);
      }
      if (item.y  > maxYAxisValue) {
        maxYAxisValue = item.y;
      }
      if (item.y  < minYAxisValue) {
        minYAxisValue = item.y;
      }
      return ({ ...item, index });
    });
  });
  // ensure reorder of all dates detected asc
  allXKeys.sort((a, b)=>{
    if (a === b) {return 0;}
    return a > b ? 1 : -1;
  });
  const tableLength = allXKeys.length;
  const maxIndex = tableLength - 1;

  const maxBottomLabels = containerSize.width / 40;
  const chartSize = {
    width: containerSize.width,
    height: windowHeight / 2  < 300 ?
      300
      : windowHeight / 2  > 400 ?
        400
        : windowHeight / 2,
  };
  const chartYDomain: {min:number, max:number} = {
    min: minYAxisValue > 0 &&
    (minYAxisValue - (maxYAxisValue - minYAxisValue) * 0.1) < 0 ?
      0 : minYAxisValue - (maxYAxisValue - minYAxisValue) * 0.1,
    max: maxYAxisValue + (maxYAxisValue - minYAxisValue) * 0.1,
  };
  const domainYSpace = chartYDomain.max - chartYDomain.min;

  const VictoryCustomTooltip = (props:ToolTipFlyoutProps) => {
    const { datum, x, y } = props;
    if (datum && datum._y ) {
      const xIndex = allXKeys.findIndex(xKey=>xKey === datum.x);

      // positioning tooltip
      const isInRightPart = xIndex > maxIndex / 2;
      const isInTopPart = ( datum._y - chartYDomain.min ) > (domainYSpace / 2);

      // getting tooltip data
      const fundsTooltipDataList:{
        color:string
        vl: number|undefined
        cumulatedPerf: number|undefined
      }[] = [];

      const dotsCursors:DotsCursorsType = [];
      lines.forEach((line, iLine) => {
        const lineItem = line.perfData.find(item=>item.x === datum.x);
        if (lineItem) {
          const lineFisrtItem = line.perfData[0];
          const periodBeginningVL = lineFisrtItem.y;
          const lineColor = lineItem.tooltipData.color;
          const cumulatedPerformance = lineItem?.y &&
          ((lineItem?.y - periodBeginningVL) * 100) / periodBeginningVL;
          fundsTooltipDataList.push({
            color: lineColor,
            vl: lineItem?.tooltipData?.vl,
            cumulatedPerf: cumulatedPerformance,
          });
          if (lineItem.y === datum.y)
          {dotsCursors.push({
            color: lineColor,
            y: 0,
          });}
        }
      });

      return (
        <>
          <LineChartToolTip
            displayLineCursor
            dotsCursors={dotsCursors}
            isInRightPart={isInRightPart}
            isInTopPart={isInTopPart}
            lastLayoutSaved={tooltipLayoutSize}
            onContainerLayout={setTooltipLayoutSize}
            x={x}
            y={y}
          >
            <View>
              <Text
                variant="t5"
                weight="light"
              >
                {formatDate( { value: datum.x,
                  options: {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  } })}
              </Text>
            </View>
            {fundsTooltipDataList.map((fundTooltipData, index)=>{
              const vlLabelText = formatMessage({ id: 'Fundsheet_Tab3Perf_VL_legend' });
              const vlValueText = formatCurrencyNumber({ value: fundTooltipData?.vl || 0 });
              const perfLabelText = formatMessage({
                id: 'Fundsheet_Tab3Perf_Cumulated_perf_legend',
              });
              const perfValueText = fundTooltipData?.vl ?
                fundTooltipData?.cumulatedPerf?.toFixed(2) + ' %'
                : 0;
              return (
                <View
                  key={index + '_' + fundTooltipData?.vl}
                  style={styles.tooltipDataLineStyle}
                >
                  <View
                    style={[
                      styles.tooltipColorDotStyle,
                      {
                        backgroundColor: fundTooltipData.color,
                      },
                    ]}
                  />
                  <Text
                    variant="t5"
                  >
                    {`${vlLabelText} ${vlValueText} `}
                    <Text
                      variant="t5"
                      weight="light"
                    >
                      {`(${perfLabelText} ${perfValueText})`}
                    </Text>
                  </Text>
                </View>
              );
            })}
          </LineChartToolTip>
        </>
      );
    } else {
      return null;
    }
  };

  const axisStyles = {
    axis: { stroke: theme.colors.basics.grey.c100 },
    axisLabel: {
      fontSize: theme.fonts.fontSize.title.t8s,
      padding: theme.metrics.spacing.xs,
      fontFamily: theme.fonts.fontFamily.light,
    },
    grid: { stroke: theme.colors.basics.grey.c100 },
    ticks: { stroke: theme.colors.basics.grey.c100, size: 2 },
    tickLabels: {
      fontSize: theme.fonts.fontSize.title.t8s,
      padding: theme.metrics.spacing.xs,
      fontFamily: theme.fonts.fontFamily.light,
    },
  };

  return (
    (<View
      onLayout={({ nativeEvent })=>{
        const { width, height } = nativeEvent.layout;
        if (width !== containerSize.width || height !== containerSize.height) {
          setContainerSize({ width, height });
        }
      }}
      style={styles.containerStyle}
    >
      <VictoryChart
        animate={{ duration: 500 }}
        containerComponent={
          <VictoryVoronoiContainer
            labelComponent={
              <VictoryTooltip
                flyoutComponent={<VictoryCustomTooltip />}
              />
            }
            labels={() => ' '} // needed to be set to allow labelComponent
          />
        }
        domainPadding={{
          y: [
            theme.metrics.spacing.s,
            theme.metrics.spacing.s,
          ],
        }}
        height={chartSize.height}
        padding={{
          left: 40,
          right: theme.metrics.spacing.s,
          top: theme.metrics.spacing.s,
          bottom: 32,
        }}
        width={chartSize.width}
      >
        <VictoryAxis
          crossAxis
          style={axisStyles}
          tickCount={maxBottomLabels}
          tickFormat={x => formatDate({
            value: x,
            options: {
              year: '2-digit',
              month: 'numeric',
            },
          })}
          tickLabelComponent={
            <VictoryLabel
              angle={-45}
              dx={-13}
              dy={0}
            />
          }
        />
        <VictoryAxis
          crossAxis
          dependentAxis
          style={axisStyles}
          tickCount={10}
          tickFormat={t => `${t.toFixed(2).replace(/\.?0+$/, '').replace('.', ',')}%`}

        />
        {/** this fisrt line is here to ensure x axis is always complete */}
        {allXKeys && allXKeys.length > 0 && (
          <VictoryLine
            data={allXKeys.map(item=>({ x: item, y: 100 }))}
            style={{
              data: {
                stroke: 'transparent',
                strokeWidth: 0,
              },
            }}
          />
        )}
        {lines.map((line, iline)=>{
          return (
            <VictoryLine
              data={line.perfData}
              key={iline + '_' + line.name}
              style={{
                data: {
                  stroke: line.color,
                  strokeWidth: 2.5,
                },
              }}
            />
          );
        })}
      </VictoryChart>
    </View>)
  );
};

const useStyles = createUseStyles(getStyles);
