import { SavingContext, SavingStyle } from './interfaces';

export const getStyles = (
  context?: SavingContext,
  style?: SavingStyle,
): SavingStyle => ({
  container: [
    {
      flex: 1,
      minHeight: '100%',
      backgroundColor: context?.theme.colors.basics.white,
      flexDirection: 'row',
      alignContent: 'center'
    },
    style?.container
  ],
  fullWidthContainer: [
    {
      flex: 1
    },
    style?.fullWidthContainer
  ]
});
