import { connect } from 'react-redux';

import { ValidateIdentityActionsType } from '@modules/validate-identity/actions/validateIdentityActionsTypes';
import {
  validateEEREmailNewIdentifierCodeRequest,
  validateEEREmailNewIdentifierRequest
} from '@modules/validate-identity/actions/validateIdentityActions';
import {
  AppState,
} from '@modules/index';
import { getInvitationSelector } from '@modules/invitation/selectors';
import {
  getIdentityValidationModuleError,
  getUserInputEmail
} from '@modules/validate-identity/selectors';

import {
  ChooseIdentificationEmailCodePageComponent,
} from './ChooseIdentificationEmailCodePage.component';

const mapStateToProps = (state: AppState) => {
  return {
    invitation: getInvitationSelector(state),
    mail: getUserInputEmail(state),
    validateIdentityError: getIdentityValidationModuleError(state),
    isValidateEEREmailNewIdentifierApiLoading: state.isLoading[
      ValidateIdentityActionsType.VALIDATE_EER_EMAIL_NEW_IDENTIFIER_REQUEST
    ],
    isValidateEEREmailNewIdentifierCodeApiLoading: state.isLoading[
      ValidateIdentityActionsType.VALIDATE_EER_EMAIL_NEW_IDENTIFIER_CODE_REQUEST
    ],
  };
};

const mapDispatchToProps = ({
  validateEEREmailNewIdentifier: validateEEREmailNewIdentifierRequest,
  validateEEREmailNewIdentifierCode: validateEEREmailNewIdentifierCodeRequest,
});
export const ChooseIdentificationEmailCodePage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChooseIdentificationEmailCodePageComponent);
