
import { DispositifState, QuestionsState, ResponseType } from '@modules/common/types';

import { getEligibleDispositifs } from './getEligibileDispositifs';
import { ImplicitResponseType, ResponseWithEligibleDispositifType } from './interface';

export const manageImplicitResponse =
(
  dispositifs: DispositifState[],
  unRespondedQuestion: QuestionsState[],
  respondedQuestion: QuestionsState[],
  allQuestions: QuestionsState[]
) => {
  // first case: when only one response has 1 or more than eligible dispositif
  let ReturnedImplicitResponse: ImplicitResponseType | undefined;
  const responses: ResponseType[] = unRespondedQuestion[0]?.responses;
  const responded = respondedQuestion.map(item => item.responded);

  if (!responses || responses.length === 0) {
    return;
  }

  const ResponseWithEligibleDispositif: ResponseWithEligibleDispositifType[] = [];

  // check eligibleDispositifs for each posible answer
  responses.forEach(response => {
    const eligibleDispositifs: DispositifState[] =
    getEligibleDispositifs(dispositifs, [...responded, response])?.eligibleDispositifs?.filter(dispositif => dispositif.isEligible === true);

    if (eligibleDispositifs.length > 0) {
      ResponseWithEligibleDispositif.push({
        dispositifs: eligibleDispositifs,
        response,
      });
    }
  });

  // check if only one answer got eligible dispositif
  const isOnlyOneAnswerWithEligibleDispositif = ResponseWithEligibleDispositif && ResponseWithEligibleDispositif.length === 1;
  if (isOnlyOneAnswerWithEligibleDispositif) {
    // target the only response with Eligible Dispositif
    const targetedResponse = ResponseWithEligibleDispositif[0]?.response;

    // answer only if no options or only one option ( else we let user answer with option selection )
    const targetOptions = targetedResponse?.options;
    const isMultipleOptions = targetOptions && targetOptions?.length > 1;
    if (!isMultipleOptions) {
      ReturnedImplicitResponse = {
        response: {
          selectedOption: (targetOptions && targetOptions[0] && targetOptions[0]) || undefined, // answer option if one
          respondedValue: targetedResponse?.value,
          respondedTitle: targetedResponse?.title,
          title: unRespondedQuestion[0].respondedTitle || unRespondedQuestion[0].title,
          respondedPropertyFilter: targetedResponse?.propertyFilter,
        },
        indexOfQuestion: (allQuestions.length - 1) - (unRespondedQuestion.length - 1),
        canNotBeAsked: false,
      };
    }

  }
  return ReturnedImplicitResponse;
};
