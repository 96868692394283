import {CardWithLeftThread, Text} from '@ere-uilib/atoms';
import {FiletStepper, HeaderResponse} from '@ere-uilib/molecules';
import {useTheme} from '@ere-uilib/styles/theme';
import {useTranslation} from '@ere-uilib/translations';
import React, {useCallback} from 'react';
import {View} from 'react-native';
import {Props} from './interfaces';
import {useStyles} from './useStyles';



export const FiletStepperCardOrigin: React.FC<React.PropsWithChildren<Props>> = ({
  isWrapper,
  isOrigin,
  title,
  amount,
  description,
  planType,
  containerStyle,
  headerResponseCustomContainerStyle,
  children
}) => {
  const theme = useTheme();
  const styles = useStyles();

  const {
    formatMessage,
    getMessageRaw,
  } = useTranslation()

  //   RENDER ORIGIN CARD
  const renderCard = useCallback(() => {

    return (
      <View >
        <FiletStepper
          containerStyle={styles.filetContainer}
          contentContainer={containerStyle}
          disableLine={false}
          filetContainerStyle={styles.filetSubContainer}>
          <View style={styles.fromContent}>
            <Text style={styles.filetText}>
              {formatMessage({ id: 'PIAffectationdefautDeLabel' })}
            </Text>
            <CardWithLeftThread
              addShadow
              containerStyle={styles.cardContainer}
            >
             
              <HeaderResponse
                amount={amount}
                customContainerStyle={
                  [
                    { backgroundColor: "#FFF",
                      borderTopLeftRadius: 0 },
                    headerResponseCustomContainerStyle,
                  ]
                }
                darkText={true}
                shouldFormatAsPlusMinus={false}
                title={title}
              />
              {description &&
              <Text style={styles.textContainer}>
                {description}
              </Text>
              }
              {children}
            </CardWithLeftThread>
          </View>
        </FiletStepper>
      </View>
    );
  }, []);

  return (
    renderCard()
  );
};