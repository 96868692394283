import React, { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import {
  useTheme,
} from '@components/index';

export const useStyles = ()=>{
  const theme = useTheme()
  const styles = useMemo(()=>StyleSheet.create({
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    contentStyles:
      {
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        marginBottom: theme.metrics.spacing.xm,
      },
    titleStyles:
      {
        textAlign: 'center',
        marginVertical: theme.metrics.spacing.s,
      },
    htmlText:
      {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        fontSize: theme.fonts.fontSize.paragraph.t3,
        fontWeight: '400',
      },
    error:
      {
        color: theme.colors.basics.message.error.c500,
      },
    buttonStyle:
      {
        margin: theme.metrics.spacing.s,
        width: '100%',
      },
    iconStyle: {
      height: theme.metrics.iconSizes.xxxl,
      marginVertical: theme.metrics.spacing.s,
    },
  }), [theme])
  return styles
};
