import { FontIcon, Modal, Title } from '@ere-uilib/atoms';
import { CheckBox, DatePickerSelection, ModalFrame, SimpleButton } from '@ere-uilib/molecules';
import { theme } from '@ere-uilib/theme';
import { useTranslation } from '@ere-uilib/translations';
import { RouleType } from '@ere-uilib/types/index';
import { range } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { FlatList, TouchableOpacity, View } from 'react-native';
import { Props } from './interfaces';
import { useStyles } from './useStyles';



export const FilterModal = ({
  modalVisible,
  onApplyFilter,
  onClose,
  appliedOperationsFilterRoules
}: Props) => {
  const currentDate = new Date().getFullYear().toString();
  const [appliedOperationsFilterDateRangeRoules, setAppliedOperationsFilterDateRangeRoules] =
    useState(appliedOperationsFilterRoules.DateRange);
  const [dateBeginSelectionVisible, setBeginSelectionVisible] = useState(false);
  const [dateEndSelectionVisible, setEndSelectionVisible] = useState(false);
  const [selectedDateBegin, setSelectedDateBegin] = useState(
    Number(appliedOperationsFilterDateRangeRoules[0])
  );
  const [selectedDateEnd, setSelectedDateEnd] = useState(
    Number(
      appliedOperationsFilterDateRangeRoules[appliedOperationsFilterDateRangeRoules.length - 1]
    )
  );
  const styles = useStyles({
    datesSelectionVisible: {
      begin: !!selectedDateBegin,
      end: !!selectedDateEnd
    }
  });

  const getDateEndSelectionRange = () => {
    if (selectedDateBegin <= +currentDate) {
      return +currentDate - selectedDateBegin + 1;
    }
    return 0;
  };
  const dateBeginSelectionRange: number[] = Array.from(new Array(5), (_, index) => {
    return Number(currentDate) - index;
  });
  const dateEndSelectionRange: number[] = Array.from(
    new Array(getDateEndSelectionRange()),
    (_, index) => {
      return Number(currentDate) - index;
    }
  );
  useEffect(() => {
    if (selectedDateEnd && selectedDateBegin > selectedDateEnd) {
      setSelectedDateEnd(selectedDateBegin);
    }
  }, [selectedDateBegin]);
  const [operationsHistoryTypesSelection, setOperationsHistoryTypesSelection] = useState(
    appliedOperationsFilterRoules.TypesRoule
  );
  const { formatMessage } = useTranslation();
  const onCheckBoxChange = (operationIndex: number) => {
    const updatedOperationsTypes: RouleType[] = operationsHistoryTypesSelection.map(
      (roule, index) => {
        if (index === operationIndex) {
          roule.checked = !roule.checked;
        }
        return roule;
      }
    );
    return setOperationsHistoryTypesSelection(updatedOperationsTypes);
  };
  const onSelectDateBegin = (date: number) => {
    setSelectedDateBegin(date);
    setBeginSelectionVisible(false);
  };

  const onSelectDateEnd = (date: number) => {
    setSelectedDateEnd(date);
    setEndSelectionVisible(false);
  };

  const initFilter = () => {
    const updatedOperationsTypes: RouleType[] = operationsHistoryTypesSelection.map(
      (roule, index) => {
        return { ...roule, checked: true };
      }
    );
    const operationHistoryRangeDateRoule = [Number(new Date().getFullYear().toString())];

    setOperationsHistoryTypesSelection(updatedOperationsTypes);
    setAppliedOperationsFilterDateRangeRoules(operationHistoryRangeDateRoule);
    setSelectedDateBegin(Number(appliedOperationsFilterDateRangeRoules[0]));
    setSelectedDateEnd(
      Number(
        appliedOperationsFilterDateRangeRoules[appliedOperationsFilterDateRangeRoules.length - 1]
      )
    );
  };
  const DatePicker = useCallback(
    ({
      onPress,
      date,
      isDateSelectionVisible,
      title
    }: {
      onPress: () => void;
      date: number;
      isDateSelectionVisible: boolean;
      title: string;
    }) => (
      <TouchableOpacity
        onPress={onPress}
        style={styles.datePicker}>
        <View style={styles.datePickerContent}>
          <Title
            style={styles.datePickerContentTitle}
            variant="t8">
            {title}
          </Title>
          <Title variant="t6">{date}</Title>
        </View>
        <FontIcon
          color={theme.colors.primaryGreen}
          name={!isDateSelectionVisible ? 'chevron-bas' : 'chevron-haut'}
          size={theme.iconSize.medium}
        />
      </TouchableOpacity>
    ),
    [styles]
  );
  const dateRange: number[] = [
    ...range(Number(selectedDateBegin), Number(selectedDateEnd), 1),
    Number(selectedDateEnd)
  ];
  const handleApplyFilter = () =>
    onApplyFilter({
      DateRange: dateRange,
      TypesRoule: operationsHistoryTypesSelection
    });

  const handlePress = () => {
    onClose();
    handleApplyFilter();
  };
  const renderListContent = useCallback(
    () => (
      <View>
        <Title
          style={{
            marginBottom: theme.marginsPaddings.m
          }}
          variant="t6"
          weight="bold">
          {formatMessage({ id: 'operation_filter_range' })}
        </Title>
        <View style={styles.datePickerContainer}>
          <DatePicker
            date={selectedDateBegin}
            isDateSelectionVisible={dateBeginSelectionVisible}
            onPress={() => setBeginSelectionVisible(!dateBeginSelectionVisible)}
            title={formatMessage({ id: 'operation_filter_start' })}
          />
          <DatePicker
            date={selectedDateEnd}
            isDateSelectionVisible={dateEndSelectionVisible}
            onPress={() => setEndSelectionVisible(!dateEndSelectionVisible)}
            title={formatMessage({ id: 'operation_filter_end' })}
          />
        </View>
        <>
          <Title
            style={{
              marginTop: theme.marginsPaddings.xxl,
              marginBottom: theme.marginsPaddings.m
            }}
            variant="t6"
            weight="bold">
            {formatMessage({ id: 'operation_filter_type' })}
          </Title>
          {operationsHistoryTypesSelection.map((operation, index) => {
            const ischecked = operation.checked;
            const checkBoxLabel = formatMessage({ id: operation.label });
            return (
              <CheckBox
                containerStyle={{ marginBottom: theme.marginsPaddings.s }}
                label={checkBoxLabel}
                onPress={() => onCheckBoxChange(index)}
                value={ischecked}
              />
            );
          })}
        </>
        {dateBeginSelectionVisible && (
          <View style={styles.dateBeginSelection}>
            <DatePickerSelection
              dateSelectionRange={dateBeginSelectionRange}
              onSelectDate={(date: number) => onSelectDateBegin(date)}
              selectedDateIndex={dateBeginSelectionRange.findIndex(
                date => selectedDateBegin === date
              )}
            />
          </View>
        )}
        {dateEndSelectionVisible && (
          <View style={styles.dateEndSelection}>
            <DatePickerSelection
              dateSelectionRange={dateEndSelectionRange}
              onSelectDate={(date: number) => onSelectDateEnd(date)}
              selectedDateIndex={dateEndSelectionRange.findIndex(date => selectedDateEnd === date)}
            />
          </View>
        )}
      </View>
    ),
    [
      selectedDateBegin,
      selectedDateEnd,
      dateBeginSelectionVisible,
      dateEndSelectionVisible,
      onCheckBoxChange,
      dateBeginSelectionRange,
      dateEndSelectionRange
    ]
  );
  return (
    <Modal visible={modalVisible}>
      <ModalFrame
        innerContainer={{ flex: 1 }}
        mobilePosition="full"
        onClose={onClose}
        title={formatMessage({ id: 'history-filters_menu' })}>
        <FlatList
          data={null}
          ListHeaderComponent={renderListContent}
          renderItem={null}
        />
        <View style={styles.buttonsContainer}>
          <SimpleButton
            design="outlined"
            onPress={() => initFilter()}
            title={formatMessage({ id: 'operation_filter_reset' })}
          />
          <SimpleButton
            onPress={handlePress}
            title={formatMessage({ id: 'operation_filter_apply' })}
          />
        </View>
      </ModalFrame>
    </Modal>
  );
};
