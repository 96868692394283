import * as qs from 'qs'
import React from 'react';

import { HtmlRenderer } from '@ere-uilib/index';
import { InformativePopin } from '@ere-uilib/organisms/overlay/InformativePopin';
import { useTranslation } from '@ere-uilib/translations';
import { isDownloadLink } from '@modules/utils/ocdMethods';
import { useUrlLink } from '@utils/useUrlLink';

import { Props } from './interface'
import { useController } from './useController';

export const HtmlRenderConnectedComponent: React.FC<React.PropsWithChildren<Props>> = ({
  html,
}) => {
  const { formatMessage } = useTranslation();
  const { linkToUrl } = useUrlLink();

  const {
    onGetDocumentDownloadFile,
    isDocumentDonwloading
  } = useController();

  const handleLinkPress = (href: string) => {
    if (isDownloadLink(href)) {
      const documentType = (qs.parse(href)['/telechargement?documentType']) as string
      return onGetDocumentDownloadFile!(documentType, 'Document')
    }
    return linkToUrl(href);
  }

  return (
    <>
      <InformativePopin
        description={formatMessage({ id: 'DocCenterDownloadMessage' })}
        iconName="idea"
        isLoading={isDocumentDonwloading}
        isModalVisible={isDocumentDonwloading||false}
      />
      <HtmlRenderer
        html={html}
        onLinkPress={handleLinkPress} />
    </>
  );
}
