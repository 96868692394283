import { AuthenticateAction } from '@modules/auth/actions/authActionsTypes';
import { OperationRedirectAction, RedirectOperationActionsType } from './actions/redirectOperationActionsTypes';
import { RedirectOperationState } from './types';

export const initialState: RedirectOperationState = {
  redirectOperation: undefined,
  error: {
    code: '',
    correlationId: '',
    message: '',
    innerMessage: '',
  },
};

export default function RedirectOperationReducer(
  state: RedirectOperationState = initialState,
  action: OperationRedirectAction | AuthenticateAction,
): RedirectOperationState {
  switch (action?.type) {
  case RedirectOperationActionsType.GET_OPERATION_REDIRECT_SUCCESS:
    return {
      ...state,
      redirectOperation: action.operationModalList,
    };
  case RedirectOperationActionsType.GET_SAPIENDO_REDIRECT_URL_FAILURE:
  case RedirectOperationActionsType.GET_OPERATION_REDIRECT_FAILURE:
    return {
      ...state,
      error: action.error,
    };
  case RedirectOperationActionsType.OPERATION_MODAL_INIT_ERROR:
  case RedirectOperationActionsType.SAPIENDO_REDIRECT_URL_INIT_ERROR:
    return {
      ...state,
      error: initialState.error,
    };
  default:
    return state;
  }
}
