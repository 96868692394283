import React, { PropsWithChildren } from 'react';
import { View, StyleProp, ViewStyle, TextStyle } from 'react-native';

import { SVGLocalLoader, Title, Paragraph } from '@components/index';
import { Spinner } from '@ere-uilib/atoms';
import { createUseStyles, useTheme, ThemeType, useScreenSizes } from '@ere-uilib/styles';

interface AnnounceContentProps {
  title?: string | React.ReactNode | null;
  description?: string | React.ReactNode | null;
  loading?: boolean
  sourceName: string
  actions?: ()=> React.ReactNode|null
 }

interface AnnounceContentStyles {
  announceContainer?: StyleProp<ViewStyle>
  svgContainer?: StyleProp<ViewStyle>
  buttonStyle?: StyleProp<ViewStyle>
  titleStyle?: StyleProp<TextStyle>
  subtitleStyle?: StyleProp<TextStyle>
}

type StyleContext = {
  theme?: ThemeType;
  isMobile?: boolean;
  isTablet?: boolean;
}

type Props = AnnounceContentProps & StyleContext & AnnounceContentStyles;

export const AnnounceContent:React.FC<PropsWithChildren<Props>> = ({
  actions,
  children,
  description,
  loading,
  sourceName,
  title,
  announceContainer,
  svgContainer,
  buttonStyle,
  titleStyle,
  subtitleStyle,
})=>{
  const theme = useTheme();
  const { isMobile, isTablet } = useScreenSizes();

  const styles = useStyle({ theme, isMobile, isTablet },
    { announceContainer,
      svgContainer,
      buttonStyle,
      titleStyle,
      subtitleStyle });
  return (
    <View style={styles.announceContainer}>
      <View style={styles.svgContainer}>
        <SVGLocalLoader
          maxHeight={'100%'}
          maxWidth={'100%'}
          name={sourceName}
        />
      </View>
      <Title
        style={styles.titleStyle}
        variant="t5s"
        weight="bold"
      >{title}</Title>
      {description !== "" &&
      <Paragraph
        style={styles.subtitleStyle}
        variant="t2"
      >
        {description}
      </Paragraph>
      }
      {children}
      {!(isMobile || isTablet)  && (
        <View style={styles.buttonStyle}>
          {actions?.()}
        </View>
      )}
      {loading && (
        <Spinner color={theme?.colors.buttons.classic.c500} />
      )}
    </View>
  );
};

const getStyles = (
  context?: StyleContext,
  style?: AnnounceContentStyles
): AnnounceContentStyles =>({
  announceContainer: [
    {
      alignItems: 'center',
      width: '100%',
      maxWidth: 450,
      paddingVertical: context?.theme?.metrics.spacing.xm,
      paddingHorizontal: context?.theme?.metrics.spacing.s,
    },
  ],
  svgContainer: [
    {
      width: '100%',
    },
    (context?.isMobile || context?.isTablet ) ? {
      minHeight: 200,
    } : {
      minHeight: 260,
    },
    style?.svgContainer,
  ],
  titleStyle: [
    {
      textAlign: 'center',
    },
    (context?.isMobile || context?.isTablet ) ? {
      marginTop: context?.theme?.metrics.spacing.xm,
    } : {
      marginTop: context?.theme?.metrics.spacing.xm,
    },

    style?.titleStyle,
  ],
  subtitleStyle: [
    {
      textAlign: 'center',
      width: '100%',
    },
    (context?.isMobile || context?.isTablet ) ? {
      marginVertical: context?.theme?.metrics.spacing.xm,
    } : {
      marginVertical: context?.theme?.metrics.spacing.xm,
    },
    style?.subtitleStyle,
  ],
  buttonStyle: [
    {
      marginTop: context?.theme?.metrics.spacing.xxl,
    },
    style?.buttonStyle,
  ],
});

const useStyle = createUseStyles(getStyles);
