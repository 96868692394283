import { useNavigation } from '@react-navigation/native';
import React, { useCallback } from 'react';
import { ScrollView, View } from 'react-native';

import { ModalFrame, Tab, Tabs, TabsProvider, useTranslation } from '@components/ERE-UILib';
import { NavigationHeader } from '@ere-uilib/molecules';

import { ContributionDetailsTab } from '../ContributionDetailsTab';
import { ContributionFAQTab } from '../ContributionFAQTab';
import { useStyles } from './useStyles';

enum TabEnum {
  'details' = 'EmployerContributionDetailTab',
  'faq' = 'EmployerContributionFAQTab'
}

const TABS = Object.values(TabEnum);

export const ContributionModal = () => {
  const styles = useStyles();
  const { formatMessage, getMessageRaw } = useTranslation();
  const navigation = useNavigation();

  const handleClose = useCallback(() => navigation.goBack(), [navigation]);

  const renderHeader = useCallback(
    () => (
      <NavigationHeader
        containerStyle={styles.headerContainer}
        displayCloseButton
        headerLine={styles.headerTitleContainer}
        helpPoint
        helpPointContentHtml={getMessageRaw({ id: 'EmployerContributionTopupHelptext' })}
        helpPointModalTitle={formatMessage({ id: 'EmployerContributionTopupHeaderHelptext' })}
        helpPointPosition="left"
        onClose={handleClose}
        title={formatMessage({ id: 'EmployerContributionTopupZoneTitle' })}>
        <Tabs tabs={TABS} />
      </NavigationHeader>
    ),
    [formatMessage, getMessageRaw, handleClose, styles]
  );

  return (
    <TabsProvider activeTab={TabEnum.details}>
      <View style={styles.container}>
        <ModalFrame
          desktopPosition="fullHeight"
          hideCloseButton
          innerContainer={styles.modalFrameInnerContainer}
          mobilePosition="full"
          modalFrame={styles.modalFrame}
          renderHeader={renderHeader}>
          <ScrollView showsVerticalScrollIndicator={false}>
            <Tab value={TabEnum.details}>
              <ContributionDetailsTab />
            </Tab>
            <Tab value={TabEnum.faq}>
              <ContributionFAQTab />
            </Tab>
          </ScrollView>
        </ModalFrame>
      </View>
    </TabsProvider>
  );
};
