import { ArbitrationTypes } from '@constants/arbitrations'
import {
  CompartmentType,
  DispositifState,
  DivestedManagementSource,
  OperationDecisionState,
  PlanCompartmentsType,
  QuestionsState,
  ReallocationDivestmentState,
  ResponseType
} from '@modules/common/types'
import { TriggerThresholdProperties } from '@modules/refund/available-refund/types'

import {
  ArbitrationDivestmentValidationParamsType,
  ArbitrationErrorType,
  ArbitrationOadRepartitionData,
  ArbitrationState,
  DivestmentArbitrationPayloadParams,
  FetchCreateArbitrationAnswerData,
  GetRiskProfileParams,
  OadAllocationsState,
  ReallocationChoiceType,
  RiskProfileData
} from '../types'
import { PieChartData } from '@ere-uilib/types'

export enum ArbitrationActionsType {
  GET_ARBITRATION_QUESTIONS_REQUEST = 'GET_ARBITRATION_QUESTIONS_REQUEST',
  GET_ARBITRATION_QUESTIONS_SUCCESS = 'GET_ARBITRATION_QUESTIONS_SUCCESS',
  GET_ARBITRATION_QUESTIONS_FAILURE = 'GET_ARBITRATION_QUESTIONS_FAILURE',
  ARBITRATION_QUESTION_INIT_ERROR = 'ARBITRATION_QUESTION_INIT_ERROR',
  CLEAR_ARBITRATION_DATA = 'CLEAR_ARBITRATION_DATA',
  SELECT_ARBITRATION_TYPE_REQUEST = 'SELECT_ARBITRATION_TYPE_REQUEST',
  SELECT_ARBITRATION_TYPE_SUCCESS = 'SELECT_ARBITRATION_TYPE_SUCCESS',
  SELECT_ARBITRATION_TYPE_FAILURE = 'SELECT_ARBITRATION_TYPE_FAILURE',
  GET_DISPOSITIF_DETAIL_REQUEST = 'GET_DISPOSITIF_DETAIL_REQUEST',
  GET_DISPOSITIF_DETAIL_SUCCESS = 'GET_DISPOSITIF_DETAIL_SUCCESS',
  GET_DISPOSITIF_DETAIL_FAILURE = 'GET_DISPOSITIF_DETAIL_FAILURE',
  UPDATE_ARBITRATION_QUESTION_WITH_RESPONSE_REQUEST = 'UPDATE_ARBITRATION_QUESTION_WITH_SET_RESPONSE_REQUEST',
  UPDATE_ARBITRATION_QUESTION_WITH_RESPONSE_SUCCESS = 'UPDATE_ARBITRATION_QUESTION_WITH_SET_RESPONSE_SUCCESS',
  UPDATE_ARBITRATION_QUESTION_WITH_RESPONSE_FAILURE = 'UPDATE_ARBITRATION_QUESTION_WITH_SET_RESPONSE_FAILURE',
  ARBITRATION_BACK_TO_QUESTION = 'ARBITRATION_BACK_TO_QUESTION',
  ARBITRATION_REPARTITION_SELECT_SUPPORT = 'ARBITRATION_REPARTITION_SELECT_SUPPORT',
  ARBITRATION_INVESTED_SUPPORT = 'ARBITRATION_INVESTED_SUPPORT',
  ARBITRATION_INVESTED_MANAGEMENT = 'ARBITRATION_INVESTED_MANAGEMENT',
  ARBITRATION_REINVEST_SELECT_SUPPORT = 'ARBITRATION_REINVEST_SELECT_SUPPORT',
  ARBITRATION_REPARTITION_SELECT_MANAGEMENT = 'ARBITRATION_REPARTITION_SELECT_MANAGEMENT',
  SET_DIVESTED_MANAGEMENT_SOURCE = 'SET_DIVESTED_MANAGEMENT_SOURCE',
  SET_IS_VISIBLE_SUBMIT_MODAL = 'SET_IS_VISIBLE_SUBMIT_MODAL',
  SET_SELECTED_DISPOSITIF = 'SET_SELECTED_DISPOSITIF',
  SET_SELECTED_COMPARTMENT = 'SET_SELECTED_COMPARTMENT',
  SET_ARBITRATION_DESINVEST_AMOUNT_MODAL_VISIBLE_STATE = 'SET_ARBITRATION_DESINVEST_AMOUNT_MODAL_VISIBLE_STATE',
  GET_REALOCATION_DIVESTMENT_REQUEST = 'GET_REALOCATION_DIVESTMENT_REQUEST',
  GET_REALOCATION_DIVESTMENT_SUCCESS = 'GET_REALOCATION_DIVESTMENT_SUCCESS',
  GET_REALOCATION_DIVESTMENT_FAILURE = 'GET_REALOCATION_DIVESTMENT_FAILURE',
  SET_ARBITRATION_REINVEST_PERCENTAGE_MODAL_VISIBLE_STATE = 'SET_ARBITRATION_REINVEST_PERCENTAGE_MODAL_VISIBLE_STATE',
  CREATE_ARBITRATION_REQUEST = 'CREATE_ARBITRATION_REQUEST',
  CREATE_ARBITRATION_SUCCESS = 'CREATE_ARBITRATION_SUCCESS',
  CREATE_ARBITRATION_FAILURE = 'CREATE_ARBITRATION_FAILURE',
  SET_REALLOCATION_TYPE = 'SET_REALLOCATION_TYPE',
  SET_ARBITRATION_TRIGGER_POINT_MODAL_VISIBLE_STATE = 'SET_ARBITRATION_TRIGGER_POINT_MODAL_VISIBLE_STATE',
  SET_ARBITRATION_SUPPORT_TRIGGER_THRESHOLD = 'SET_ARBITRATION_SUPPORT_TRIGGER_THRESHOLD',
  CLEAR_ARBITRATION_SUPPORT_TRIGGER_THRESHOLD = 'CLEAR_ARBITRATION_SUPPORT_TRIGGER_THRESHOLD',
  GET_OAD_ALLOCATIONS_REQUEST = 'GET_OAD_ALLOCATIONS_REQUEST',
  GET_OAD_ALLOCATIONS_SUCCESS = 'GET_OAD_ALLOCATIONS_SUCCESS',
  GET_OAD_ALLOCATIONS_FAILURE = 'GET_OAD_ALLOCATIONS_FAILURE',
  GET_RISK_PROFILE_REQUEST = 'GET_RISK_PROFILE_REQUEST',
  GET_RISK_PROFILE_SUCCESS = 'GET_RISK_PROFILE_SUCCESS',
  GET_RISK_PROFILE_FAILURE = 'GET_RISK_PROFILE_FAILURE',
  SET_OAD_MANUAL_REPARTITION_DATA = 'SET_OAD_MANUAL_REPARTITION_DATA',
  SET_OAD_REPARTITION_DATA = 'SET_OAD_REPARTITION_DATA',
  SET_OAD_SIMULATION_DATA = 'SET_OAD_SIMULATION_DATA',
  SUBMIT_OAD_REALLOCATIONS_REQUEST = 'SUBMIT_OAD_REALLOCATIONS_REQUEST',
  SUBMIT_OAD_REALLOCATIONS_SUCCESS = 'SUBMIT_OAD_REALLOCATIONS_SUCCESS',
  SUBMIT_OAD_REALLOCATIONS_FAILURE = 'SUBMIT_OAD_REALLOCATIONS_FAILURE',
  SET_ARBITRATION_DUE_DATE_AMOUNT_MODAL_VISIBLE_STATE = 'SET_ARBITRATION_DUE_DATE_AMOUNT_MODAL_VISIBLE_STATE',
  SET_ARBITRATION_DUE_DATE_AMOUNT_FIELD_VALUE = 'SET_ARBITRATION_DUE_DATE_AMOUNT_FIELD_VALUE',

}

//AAD

export interface SubmitOadReallocationsRequestAction {
  type: typeof ArbitrationActionsType.SUBMIT_OAD_REALLOCATIONS_REQUEST
}

export interface SubmitOadReallocationsSuccessAction {
  type: typeof ArbitrationActionsType.SUBMIT_OAD_REALLOCATIONS_SUCCESS
  data: FetchCreateArbitrationAnswerData
}

export interface SubmitOadReallocationsFailureAction {
  type: typeof ArbitrationActionsType.SUBMIT_OAD_REALLOCATIONS_FAILURE
  error: ArbitrationErrorType
}

//

export interface GetArbitrationQuestionsRequestAction {
  type: ArbitrationActionsType.GET_ARBITRATION_QUESTIONS_REQUEST
}

export interface GetArbitrationQuestionsSuccessAction {
  type: ArbitrationActionsType.GET_ARBITRATION_QUESTIONS_SUCCESS
  decision: OperationDecisionState & ReallocationChoiceType
}

export interface GetArbitrationQuestionsFailureAction {
  type: ArbitrationActionsType.GET_ARBITRATION_QUESTIONS_FAILURE
  error: ArbitrationErrorType
}

export interface ArbitrationQuestionsInitErrorAction {
  type: ArbitrationActionsType.ARBITRATION_QUESTION_INIT_ERROR
}

export interface ClearArbitrationDataAction {
  type: ArbitrationActionsType.CLEAR_ARBITRATION_DATA
}

export interface SelectArbitrationTypeRequest {
  type: ArbitrationActionsType.SELECT_ARBITRATION_TYPE_REQUEST
  arbitrationType: ArbitrationTypes
}

export interface SelectArbitrationTypeSuccess {
  type: ArbitrationActionsType.SELECT_ARBITRATION_TYPE_SUCCESS
}

export interface SelectArbitrationTypefailure {
  type: ArbitrationActionsType.SELECT_ARBITRATION_TYPE_FAILURE
  error: ArbitrationErrorType
}

export interface GetDispositifDetailRequestAction {
  type: ArbitrationActionsType.GET_DISPOSITIF_DETAIL_REQUEST
  planId: string
}

export interface GetDispositifDetailSuccessAction {
  type: ArbitrationActionsType.GET_DISPOSITIF_DETAIL_SUCCESS
  planCompartments: PlanCompartmentsType
}

export interface GetDispositifDetailFailureAction {
  type: ArbitrationActionsType.GET_DISPOSITIF_DETAIL_FAILURE
  error: ArbitrationErrorType
}

// Arbitration decision question answer
export interface UpdateArbitrationQuestionWithResponseRequest {
  type: ArbitrationActionsType.UPDATE_ARBITRATION_QUESTION_WITH_RESPONSE_REQUEST
  response: ResponseType
  index: number
  canNotBeAsked: boolean
  OADDelayQuestion: QuestionsState
  OADRiskQuestion: QuestionsState
}
export interface UpdateArbitrationQuestionWithResponseSuccess {
  type: ArbitrationActionsType.UPDATE_ARBITRATION_QUESTION_WITH_RESPONSE_SUCCESS
  newDecision: ArbitrationState['decision']
}
export interface UpdateArbitrationQuestionWithResponseFailure {
  type: ArbitrationActionsType.UPDATE_ARBITRATION_QUESTION_WITH_RESPONSE_FAILURE
  error: ArbitrationErrorType
}

export interface ArbitrationBackToQuestion {
  type: ArbitrationActionsType.ARBITRATION_BACK_TO_QUESTION
  index: number
}

export interface SelectArbitrationRepartitionSupportAction {
  type: ArbitrationActionsType.ARBITRATION_REPARTITION_SELECT_SUPPORT
  params: ArbitrationDivestmentValidationParamsType
}

export interface SelectInvestedSupportAction {
  type: ArbitrationActionsType.ARBITRATION_INVESTED_SUPPORT
  params: ArbitrationDivestmentValidationParamsType
}

export interface SelectInvestedManagementAction {
  type: ArbitrationActionsType.ARBITRATION_INVESTED_MANAGEMENT
  params: ArbitrationDivestmentValidationParamsType
}

export interface SelectArbitrationReinvestSupportAction {
  type: ArbitrationActionsType.ARBITRATION_REINVEST_SELECT_SUPPORT
  params: any // TODO Type
}

export interface SelectArbitrationRepartitionManagementAction {
  type: ArbitrationActionsType.ARBITRATION_REPARTITION_SELECT_MANAGEMENT
  selectState: boolean
  compartmentId: string
  amount: number
  managementId: string
}

export interface SetDivestedManagementSource {
  type: ArbitrationActionsType.SET_DIVESTED_MANAGEMENT_SOURCE
  params: DivestedManagementSource
}

export interface SetIsVisibleSubmitModal {
  type: ArbitrationActionsType.SET_IS_VISIBLE_SUBMIT_MODAL
  isVisible: boolean
}

export interface SetSelectedDispositif {
  type: ArbitrationActionsType.SET_SELECTED_DISPOSITIF
  dispositif: DispositifState[]
}

export interface SetSelectedCompartment {
  type: ArbitrationActionsType.SET_SELECTED_COMPARTMENT
  compartment?: CompartmentType
}

export interface SetArbitrationDesinvestAmountModalVisibleStateAction {
  type: ArbitrationActionsType.SET_ARBITRATION_DESINVEST_AMOUNT_MODAL_VISIBLE_STATE
  state: boolean
}

export interface GetRealocationDivestmentRequestAction {
  type: ArbitrationActionsType.GET_REALOCATION_DIVESTMENT_REQUEST
  DivestmentPayloadParams: DivestmentArbitrationPayloadParams
  shouldNavigate: boolean
}

export interface GetRealocationDivestmentSuccessAction {
  type: ArbitrationActionsType.GET_REALOCATION_DIVESTMENT_SUCCESS
  reallocationDivested: ReallocationDivestmentState
}

export interface GetRealocationDivestmentFailureAction {
  type: ArbitrationActionsType.GET_REALOCATION_DIVESTMENT_FAILURE
  error: ArbitrationErrorType
}

export interface SetArbitrationResinvestPercentageModalVisibleStateAction {
  type: ArbitrationActionsType.SET_ARBITRATION_REINVEST_PERCENTAGE_MODAL_VISIBLE_STATE
  isVisibleState: boolean
}

// create arbitration demand

export interface CreateArbitrationRequestAction {
  type: ArbitrationActionsType.CREATE_ARBITRATION_REQUEST
}

export interface CreateArbitrationSuccessAction {
  type: ArbitrationActionsType.CREATE_ARBITRATION_SUCCESS
  arbitrationAnswerData: FetchCreateArbitrationAnswerData
}

export interface CreateArbitrationFailureAction {
  type: ArbitrationActionsType.CREATE_ARBITRATION_FAILURE
  error: ArbitrationErrorType
}

export interface SetReallocationTypeAction {
  type: ArbitrationActionsType.SET_REALLOCATION_TYPE
  reallocationType: ArbitrationTypes
}

export interface SetArbitrationTriggerThresholdModalVisibleStateAction {
  type: ArbitrationActionsType.SET_ARBITRATION_TRIGGER_POINT_MODAL_VISIBLE_STATE
  state: boolean
}

export interface SetArbitrationSupportTriggerThresholdAction {
  type: ArbitrationActionsType.SET_ARBITRATION_SUPPORT_TRIGGER_THRESHOLD
  TriggerThreshold: TriggerThresholdProperties
  params: ArbitrationDivestmentValidationParamsType
}

export interface ClearArbitrationSupportTriggerThresholdAction {
  type: ArbitrationActionsType.CLEAR_ARBITRATION_SUPPORT_TRIGGER_THRESHOLD
  params: ArbitrationDivestmentValidationParamsType
}

export interface GetOadAllocationsRequestAction {
  type: ArbitrationActionsType.GET_OAD_ALLOCATIONS_REQUEST
  profile: string
  horizon: string
}

export interface GetOadAllocationsSuccessAction {
  type: ArbitrationActionsType.GET_OAD_ALLOCATIONS_SUCCESS
  arbitrationOadAllocations: OadAllocationsState[]
}

export interface GetOadAllocationsFailureAction {
  type: ArbitrationActionsType.GET_OAD_ALLOCATIONS_FAILURE
  error: ArbitrationErrorType
}
// Toolchain

export interface SetOadManualRepartitionDataAction {
  type: ArbitrationActionsType.SET_OAD_MANUAL_REPARTITION_DATA
  arbitrationOadManualRepartitionData: ArbitrationOadRepartitionData[]
}
// Toolchain

export interface SetOadRepartitionDataRequestAction {
  type: ArbitrationActionsType.SET_OAD_REPARTITION_DATA
  arbitrationOadRepartitionData: ArbitrationOadRepartitionData[]
}

export interface SetOadSimulationDataRequestAction {
  type: ArbitrationActionsType.SET_OAD_SIMULATION_DATA
  arbitrationOadSimulationData: PieChartData[]
}

export interface GetRiskProfileRequestAction {
  type: ArbitrationActionsType.GET_RISK_PROFILE_REQUEST
  params: GetRiskProfileParams
}

export interface GetRiskProfileSuccessAction {
  type: ArbitrationActionsType.GET_RISK_PROFILE_SUCCESS
  riskProfile: RiskProfileData
}

export interface GetRiskProfileFailureAction {
  type: ArbitrationActionsType.GET_RISK_PROFILE_FAILURE
  error: ArbitrationErrorType
}

// par échéance
export interface SetArbitrationDueDateAmountModalVisibleStateAction {
  type: ArbitrationActionsType.SET_ARBITRATION_DUE_DATE_AMOUNT_MODAL_VISIBLE_STATE
  state: boolean
}

export interface SelectArbitrationDueDateAmountFieldAction {
  type: ArbitrationActionsType.SET_ARBITRATION_DUE_DATE_AMOUNT_FIELD_VALUE
  dueDateAmountFieldValue: number
}

export type ArbitrationAction =
  | GetArbitrationQuestionsRequestAction
  | GetArbitrationQuestionsSuccessAction
  | GetArbitrationQuestionsFailureAction
  | GetDispositifDetailRequestAction
  | GetDispositifDetailSuccessAction
  | GetDispositifDetailFailureAction
  | ArbitrationQuestionsInitErrorAction
  | ClearArbitrationDataAction
  | SelectArbitrationTypeRequest
  | SelectArbitrationTypeSuccess
  | SelectArbitrationTypefailure
  | UpdateArbitrationQuestionWithResponseRequest
  | UpdateArbitrationQuestionWithResponseSuccess
  | UpdateArbitrationQuestionWithResponseFailure
  | ArbitrationBackToQuestion
  | SelectArbitrationRepartitionSupportAction
  | SetIsVisibleSubmitModal
  | SetSelectedCompartment
  | SetSelectedDispositif
  | SetArbitrationDesinvestAmountModalVisibleStateAction
  | SelectArbitrationRepartitionManagementAction
  | GetRealocationDivestmentRequestAction
  | GetRealocationDivestmentSuccessAction
  | GetRealocationDivestmentFailureAction
  | SetArbitrationResinvestPercentageModalVisibleStateAction
  | SelectArbitrationReinvestSupportAction
  | SelectInvestedSupportAction
  | SelectInvestedManagementAction
  | CreateArbitrationRequestAction
  | CreateArbitrationSuccessAction
  | CreateArbitrationFailureAction
  | SetReallocationTypeAction
  | SetDivestedManagementSource
  | SetArbitrationTriggerThresholdModalVisibleStateAction
  | SetArbitrationSupportTriggerThresholdAction
  | ClearArbitrationSupportTriggerThresholdAction
  | GetOadAllocationsRequestAction
  | GetOadAllocationsSuccessAction
  | GetOadAllocationsFailureAction
  | SetOadManualRepartitionDataAction
  | SetOadRepartitionDataRequestAction
  | SetOadSimulationDataRequestAction
  | GetRiskProfileRequestAction
  | GetRiskProfileSuccessAction
  | GetRiskProfileFailureAction
  | SubmitOadReallocationsFailureAction
  | SubmitOadReallocationsRequestAction
  | SubmitOadReallocationsSuccessAction
  | SetArbitrationDueDateAmountModalVisibleStateAction
  | SelectArbitrationDueDateAmountFieldAction
