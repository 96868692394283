import {createContext, FC, useCallback, useState, PropsWithChildren, useContext} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {PlanFamillyList} from "@constants/common";
import {ErrorItemState} from "@modules/error/types";
import {BankDataType} from "@modules/bank-details/types";
import {getLoadingState} from "@modules/funds/selectors";
import {ModalType} from "@pages/BankDetails/BankSummaryPage/interfaces";
import {
    getBankDataRequest,
    setSelectedAccountType,
    setSelectedPlanFamily as setSelectedPlanFamilyRequest, setSelectedPlanId
} from "@modules/bank-details/actions/actionsCreators";
import {getBankData, getBankDetailsError} from "@modules/bank-details/selectors";
import {BankDetailsActionsType} from "@modules/bank-details/actions/actionsTypes";

interface BankSummaryPageContextType {
    onGetBankData: () => void,
    onSetSelectedAccountType: (type: string) => void,
    onSetSelectedPlanFamily: (selectedPlanFamily: PlanFamillyList) => void;
    onSelectPlanId: ( selectedPlanId: string | undefined  ) => void;
    isLoadingBankData: boolean;
    bankDataError?: ErrorItemState;
    bankData: BankDataType;
    modalData: ModalType | undefined;
    setModalData: (data: ModalType | undefined) => void;
    isMandateModalVisible: boolean;
    setIsMandateModalVisible: (value: boolean) => void;
    selectedPlanFamily?: PlanFamillyList | undefined;
    setSelectedPlanFamily: (selectedPlanFamily: PlanFamillyList) => void;
    selectedRcBankDataIndex: number;
    setSelectedRcBankDataIndex: (selectedRcBankDataIndex: number) => void;
}

export const BankSummaryContext = createContext<BankSummaryPageContextType | null>(
    null
);

export const useBankSummaryContext = ():BankSummaryPageContextType  => {
    const context = useContext(BankSummaryContext);
    if (!context) {
        throw new Error("useBankSummaryContext must be used within BankSummaryProvider");
    }
    return context;
}

const BankSummaryProvider: FC<PropsWithChildren> = ({ children }) => {
    const bankData = useSelector(getBankData);
    const bankDataError = useSelector(getBankDetailsError);
    const isLoadingBankData = useSelector(getLoadingState(BankDetailsActionsType.GET_BANK_DATA_REQUEST)) ;

    const [modalData, setModalData] = useState<ModalType | undefined>(undefined);
    const [isMandateModalVisible, setIsMandateModalVisible] = useState<boolean>(false);
    const [selectedPlanFamily, setSelectedPlanFamily] = useState<PlanFamillyList | undefined>();
    const [selectedRcBankDataIndex, setSelectedRcBankDataIndex] = useState<number>(0);

    const dispatch = useDispatch();

    const onGetBankData =  useCallback(() => dispatch(getBankDataRequest()), []);
    const onSetSelectedAccountType =  useCallback((type:string) => dispatch(setSelectedAccountType(type)), []);
    const onSetSelectedPlanFamily =  useCallback((selectedPlanFamily: PlanFamillyList) => dispatch(setSelectedPlanFamilyRequest(selectedPlanFamily)), []);
    const onSelectPlanId =  useCallback(( selectedPlanId: string | undefined  ) => dispatch(setSelectedPlanId(selectedPlanId)),[]);

    const value: BankSummaryPageContextType = {
        onGetBankData,
        onSetSelectedAccountType,
        onSetSelectedPlanFamily,
        onSelectPlanId,
        isLoadingBankData,
        bankDataError,
        bankData,
        modalData,
        setModalData,
        isMandateModalVisible,
        setIsMandateModalVisible,
        selectedPlanFamily,
        setSelectedPlanFamily,
        selectedRcBankDataIndex,
        setSelectedRcBankDataIndex
    };

    return (
        <BankSummaryContext.Provider value={value}>
            {children}
        </BankSummaryContext.Provider>
    );
};

export default BankSummaryProvider;
