import { FontIcon, Spinner, SVGLocalLoader, Text, Title } from '@ere-uilib/atoms';
import { IconEnum } from '@ere-uilib/enums';
import { useTheme } from '@ere-uilib/styles';
import React from 'react';
import { View } from 'react-native';
import { DocumentProps } from './interfaces';
import { useStyles } from './useStyles';

export function DocumentCard({
  header,
  title,
  description,
  tags,
  documentLoading = false,
}: DocumentProps) {
  const theme = useTheme();
  const styles = useStyles();
  return (
    <View style={styles.cardContainer} >
      <View style={styles.leftCard}>
        {!!header && (
          <Text
            style={styles.headerText}
            variant="t4"
            weight="regular">
            {header}
          </Text>
        )}
        <Title
          variant='t7'
          weight="regular">
          {title}
        </Title>
        <Text
          variant='t4'
          weight="light">
          {description}
        </Text>
        {!!tags?.length && (
          <View style={styles.tagsContainer}>
            <View style={styles.rowIconContainer}>
              <SVGLocalLoader
                maxHeight='100%'
                maxWidth='8%'
                name="documentTag"
              />
            </View>
            <View style={styles.tagsTextContainer}>
              {tags?.map((tag, index) => {
                const checkLastComma = index < tags.length - 1 ? ',' : ''
                const tagLabel = tag.name + checkLastComma
                return (
                  <Text
                    style={styles.tagTextStyle}
                    variant='t4'
                    weight='light'>
                    {tagLabel}
                  </Text>
                )
              })}
            </View>
          </View>
        )}
      </View>
      <View style={styles.rightCard}>
        {documentLoading ?
          <View style={styles.spinnerView}>
            <Spinner />
          </View> :
          <FontIcon
            color={theme.colors.basics.primary.c500}
            name={IconEnum.PDF}
            size={theme.metrics.iconSizes.xxl}
            style={styles.iconPdf}
          />
        }
        {/*
          UNCOMMENT WHEN SIZE IS AVAILABLE FROM API
          <Text
            style={styles.sizeFileStyle}
            variant="t4"
            weight="light">{formatNumber({
              value: size,
            })} ko
            </Text> */}
      </View>
    </View>
  );
}