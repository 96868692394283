import {
  ProfitSharingIncentiveLegalFrameworkEnum,
  ProfitSharingIncentiveTypeEnum
} from '@constants/ProfitSharingIncentive';

export type LegalFrameworkByType<T = string> = {
  default?: string;
} & {
  [type in ProfitSharingIncentiveTypeEnum]: Partial<{
    [legalFramework in ProfitSharingIncentiveLegalFrameworkEnum | 'default']: T;
  }>;
};

type Label = {
  header: {
    title: string;
    help: {
      title: LegalFrameworkByType<string>;
      content: LegalFrameworkByType<string>;
    };
  };
  title: LegalFrameworkByType<string>;
  interestType: LegalFrameworkByType<string>;
};

export const label: Label = {
  header: {
    title: 'DetailOperationTitrePage',
    help: {
      title: {
        default: 'DetailOperationInfoTitle',
        [ProfitSharingIncentiveTypeEnum.incentive]: {
          default: 'PIDetailOperationPIAideTitre'
        },
        [ProfitSharingIncentiveTypeEnum.profitsharing]: {
          default: 'PIDetailOperationPIAideTitre'
        }
      },
      content: {
        default: 'DetailOperationInfoContent',
        [ProfitSharingIncentiveTypeEnum.incentive]: {
          default: 'PIDetailOperationPIAideHorsPPV',
          [ProfitSharingIncentiveLegalFrameworkEnum.ppv]: 'PIDetailOperationInteressementPPVAide'
        },
        [ProfitSharingIncentiveTypeEnum.profitsharing]: {
          default: 'PIDetailOperationPIAideHorsPPV'
        }
      }
    }
  },
  title: {
    [ProfitSharingIncentiveTypeEnum.incentive]: {
      [ProfitSharingIncentiveLegalFrameworkEnum.normal]: 'PIDetailOperationtInteressementTitre',
      [ProfitSharingIncentiveLegalFrameworkEnum.complementary]:
        'PIDetailOperationInteressementCOMPTitre',
      [ProfitSharingIncentiveLegalFrameworkEnum.exceptional]:
        'PIDetailOperationInteressementEXCEPTitre',
      [ProfitSharingIncentiveLegalFrameworkEnum.ppv]: 'PIDetailOperationInteressementPPVTitre'
    },
    [ProfitSharingIncentiveTypeEnum.profitsharing]: {
      [ProfitSharingIncentiveLegalFrameworkEnum.normal]: 'PIDetailOperationParticipationTitre',
      [ProfitSharingIncentiveLegalFrameworkEnum.complementary]:
        'PIADetailOperationParticipationCOMPTitre',
      [ProfitSharingIncentiveLegalFrameworkEnum.exceptional]:
        'PIDetailOperationParticipationEXCEPTitre'
    }
  },
  interestType: {
    [ProfitSharingIncentiveTypeEnum.incentive]: {
      [ProfitSharingIncentiveLegalFrameworkEnum.normal]: 'PITitre_Net_Interessement_Normal',
      [ProfitSharingIncentiveLegalFrameworkEnum.complementary]:
        'PITitre_Net_Interessement_Complementaire',
      [ProfitSharingIncentiveLegalFrameworkEnum.exceptional]:
        'PITitre_Net_Interessement_Exceptionnel',
      [ProfitSharingIncentiveLegalFrameworkEnum.ppv]: 'PITitre_Net_Interessement_PPV'
    },
    [ProfitSharingIncentiveTypeEnum.profitsharing]: {
      [ProfitSharingIncentiveLegalFrameworkEnum.normal]: 'PITitre_Net_Participation_Normal',
      [ProfitSharingIncentiveLegalFrameworkEnum.complementary]:
        'PITitre_Net_Participation_Complementaire',
      [ProfitSharingIncentiveLegalFrameworkEnum.exceptional]:
        'PITitre_Net_Participation_Exceptionnel'
    }
  }
};
