import _ from 'lodash';
import dayjs from 'dayjs';
import React, { useCallback, useMemo, useState } from 'react';
import { View } from 'react-native';
import { Defs, LinearGradient, Stop } from 'react-native-svg';
import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryLine,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory-native';

import { DotsCursorsType, LineChartToolTip, Text } from '@ere-uilib/atoms';
import { createUseStyles, useScreenSizes, useTheme } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';

import { SavingsEvolutionChartProps, ToolTipFlyoutProps } from './interfaces';
import { OperationsListItem } from './sharedInterfaces';
import { getStyles } from './styles';

interface FormattedData {
  cumulatedAmount: number;
  chart0: {
    x: string;
    y: number;
    realDate: string;
  }[];
  chart1: {
    x: string;
    y: number;
    y0: number;
    realDate: string;
    totalOperationsAmount: number;
    operations: OperationsListItem[];
  }[];
};

const enumerateMonthBetweenDates = function(startDate: Date, endDate: Date) {
  const dates: string[] = [];

  const currDate = dayjs(startDate).startOf('month');
  const lastDate = dayjs(endDate).startOf('month');

  while (currDate.diff(lastDate) <= 0) {
    dates.push(currDate.clone().format('YYYY-MM'));
    currDate.add(1, 'month');
  }

  return dates;
};

export const SavingsEvolutionChart: React.FC<React.PropsWithChildren<SavingsEvolutionChartProps>> = ({
  data,
  numberOfYearPeriod,
  containerStyle
}) => {
  const theme = useTheme();
  const { isMobile, isTablet, windowHeight } = useScreenSizes();
  const { formatDate, formatMessage, formatCurrencyNumberWithPlusMinus } = useTranslation();
  const [containerSize, setContainerSize] = useState<{ width: number; height: number }>({
    width: 300,
    height: 300
  });
  const endDate = dayjs(new Date(), 'YYYY-MM').toDate();
  const startDate: Date = useMemo(() => dayjs(endDate, 'YYYY-MM').subtract(numberOfYearPeriod, 'years').toDate(), [numberOfYearPeriod]);
  const checkDateIsValid = useCallback((chartDate: string):boolean =>  (
    dayjs(chartDate).format('YYYY-MM') >= dayjs(startDate).format('YYYY-MM') &&
    dayjs(chartDate).format('YYYY-MM') <= dayjs(endDate).format('YYYY-MM')
  ), [startDate, endDate])
  // to prevent tooltip default size to be to different from expected we save the last tooltip size
  const [tooltipLayoutSize, setTooltipLayoutSize] = useState<{ width: number; height: number }>();
  const styles = useStyles(
    { theme, isMobile, isTablet },
    {
      containerStyle
    }
  );

  const line0Color = theme.colors.basics.primary.c500;
  const line1Color = theme.colors.basics.grey.c400;

  const allXKeys: string[] = enumerateMonthBetweenDates(startDate, endDate);
  const maxIndex = allXKeys.length - 1;
  const maxBottomLabels = containerSize.width / 40;

  const formattedData: FormattedData = data.reduce((accumulator, currentValue) => {
    if (!checkDateIsValid(currentValue.x)) {
      return accumulator;
    }

    accumulator.cumulatedAmount += currentValue.totalOperationsAmount;

    accumulator.chart0.push({
      x: dayjs(currentValue.x).format('YYYY-MM'),
      y: currentValue?.y,
      realDate: dayjs(currentValue?.x).format('YYYY-MM-DD')
    });

    accumulator.chart1.push({
      x: dayjs(currentValue.x).format('YYYY-MM'),
      y: accumulator.cumulatedAmount,
      y0: 0,
      realDate: dayjs(currentValue.x).format('YYYY-MM-DD'),
      totalOperationsAmount: currentValue.totalOperationsAmount,
      operations: currentValue.operations
    });

    return accumulator;
  }, {
    chart0: [] as FormattedData['chart0'],
    chart1: [] as FormattedData['chart1'],
    cumulatedAmount: data && data[0] && data[0].y > 0 ? data[0].y : 0,
  })

  const minYAxisValue = useMemo(() => {
    if (!data || data.length === 0) {
      return 0;
    }

    const yValues = data.map(({ y }) => y);
    const minY = Math.min(...yValues, formattedData.cumulatedAmount);

    return minY;
  }, [data, formattedData.cumulatedAmount]);

  const maxYAxisValue = useMemo(() => {
    if (!data || data.length === 0) {
      return 0;
    }

    const yValues = data.map(({ y }) => y);
    const maxY = Math.max(...yValues, formattedData.cumulatedAmount);

    return maxY;
  }, [data, formattedData.cumulatedAmount]);

  const chartSize = {
    width: containerSize.width,
    height: windowHeight / 2 < 300 ? 300 : windowHeight / 2 > 400 ? 400 : windowHeight / 2
  };
  const chartYDomain: { min: number; max: number } = {
    min: minYAxisValue,
    max: maxYAxisValue
  };

  const domainYSpace = chartYDomain.max - chartYDomain.min;

  const VictoryCustomTooltip = useCallback((props: ToolTipFlyoutProps) => {
    const { datum, x, y } = props;
    if (datum && typeof datum.y === 'number') {
      const line0Item = formattedData.chart0.find(item => item?.x === datum.x);
      const line1Item = formattedData.chart1.find(item => item?.x === datum.x);
      const selectedOperations = _.slice(line1Item?.operations, 0, 5);

      const xIndex = allXKeys.findIndex(xKey => xKey === datum.x);

      // positioning tooltip
      const isInRightPart = xIndex > maxIndex / 2;
      const isInTopPart = datum.y - chartYDomain.min > domainYSpace / 2;

      const dotsCursors: DotsCursorsType = [];
      // TODO : display both dots and calcul each y position
      line0Item?.y === datum.y && dotsCursors.push({ color: line0Color, y: 0 });
      line1Item?.y === datum.y && dotsCursors.push({ color: line1Color, y: 0 });
      return (
        <LineChartToolTip
          displayLineCursor
          dotsCursors={dotsCursors}
          isInRightPart={isInRightPart}
          isInTopPart={isInTopPart}
          lastLayoutSaved={tooltipLayoutSize}
          onContainerLayout={setTooltipLayoutSize}
          x={x}
          y={y}>
          <View>
            <View style={styles.tooltipTitleLineStyle}>
              <View style={[styles.tooltipColorDotStyle, { backgroundColor: line0Color }]} />
              <Text
                style={[styles.tooltipText, { color: line0Color }]}
                variant="t5">
                {formatMessage({ id: 'Saving_Tab5_mysavingsdate_label' })}{' '}
                {formatDate({
                  value: datum.realDate,
                  options: {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric'
                  }
                })}
              </Text>
            </View>
            <View style={styles.tooltipTotalLineStyle}>
              <Text
                style={styles.tooltipText}
                variant="t5">
                {formatMessage({ id: 'Saving_Tab5_total_amount_label' })}
                {' : '}
                {formatCurrencyNumberWithPlusMinus({ value: line0Item?.y || 0 })}
              </Text>
            </View>
            <View style={styles.tooltipTitleLineStyle}>
              <View style={[styles.tooltipColorDotStyle, { backgroundColor: line1Color }]} />
              <Text
                style={[styles.tooltipText, { color: line1Color }]}
                variant="t5">
                {formatMessage({ id: 'Saving_Tab5_myoperations_label' })}
              </Text>
            </View>
            <View style={styles.tooltipTotalOperationsLineStyle}>
              <Text
                style={styles.tooltipText}
                variant="t5">
                {formatMessage({ id: 'Saving_Tab5_total_operation_label' })}
                {' : '}
                {formatCurrencyNumberWithPlusMinus({
                  value: line1Item?.totalOperationsAmount || 0
                })}
              </Text>
            </View>
            {selectedOperations &&
                selectedOperations.map((item, i) => {
                  return (
                    <View
                      key={i}
                      style={styles.tooltipOperationsLineStyle}>
                      <Text
                        style={styles.tooltipText}
                        variant="t5"
                        weight="light">
                        {formatDate({
                          value: item.date,
                          options: { month: 'numeric', day: 'numeric' }
                        })}
                        {' : '}
                        {formatCurrencyNumberWithPlusMinus({ value: item.amount })}
                      </Text>
                    </View>
                  );
                })}
            {selectedOperations.length === 5 && (
              <View style={styles.tooltipOperationsLineStyle}>
                <Text
                  style={styles.tooltipText}
                  variant="t5"
                  weight="light">
                  {formatMessage({ id: 'Saving_Tab5_moreoperations_label' })}
                </Text>
              </View>
            )}
          </View>
        </LineChartToolTip>
      );
    } else {
      return null;
    }
  }, [allXKeys, chartYDomain, domainYSpace,
    formatCurrencyNumberWithPlusMinus, formatDate, formatMessage,
    formattedData, maxIndex, tooltipLayoutSize]);

  const formatCurrencyTickToRightKiloValue = useCallback((tickValue: number) => {
    if (maxYAxisValue >= 10000) {
      return `${(tickValue / 1000)
        .toFixed(2)
        .replace(/\.?0+$/, '')
        .replace('.', ',')}k€`;
    } else {
      return `${tickValue
        .toFixed(2)
        .replace(/\.?0+$/, '')
        .replace('.', ',')}€`;
    }
  }, []);

  const axisStyles = {
    axis: { stroke: theme.colors.basics.grey.c100 },
    axisLabel: {
      fontSize: theme.fonts.fontSize.title.t8s,
      padding: theme.metrics.spacing.xs,
      fontFamily: theme.fonts.fontFamily.light
    },
    grid: { stroke: theme.colors.basics.grey.c100 },
    ticks: { stroke: theme.colors.basics.grey.c100, size: 2 },
    tickLabels: {
      fontSize: theme.fonts.fontSize.title.t8s,
      padding: theme.metrics.spacing.xs,
      fontFamily: theme.fonts.fontFamily.light
    }
  };

  const getTickFormat = useCallback(x => {
    const formattedDate = formatDate({
      value: x,
      options: {
        year: '2-digit',
        month: 'numeric'
      }
    });

    return formattedDate;
  }, [data]);

  return (
    <View
      onLayout={({ nativeEvent }) => {
        const { width, height } = nativeEvent.layout;
        if (width !== containerSize.width || height !== containerSize.height) {
          setContainerSize({ width, height });
        }
      }}
      style={styles.containerStyle}>
      <VictoryChart
        animate={{ duration: 500 }}
        containerComponent={
          <VictoryVoronoiContainer
            labelComponent={<VictoryTooltip flyoutComponent={<VictoryCustomTooltip />} />}
            labels={() => ' '} // needed to be set to allow labelComponent
          />
        }
        domain={{ y: [chartYDomain.min, chartYDomain.max] }}
        domainPadding={{ y: [theme.metrics.spacing.s, theme.metrics.spacing.s], x: [0, theme.metrics.spacing.s] }}
        height={chartSize.height}
        padding={{ left: 50, right: 10, top: 10, bottom: 40 }}
        width={chartSize.width}>
        <Defs>
          <LinearGradient
            id="AreaGradient"
            x1="0%"
            x2="0%"
            y1="0%"
            y2="100%">
            <Stop
              offset="0%"
              stopColor={line1Color + 'AA'}
            />
            <Stop
              offset="100%"
              stopColor={line1Color + '00'}
            />
          </LinearGradient>
        </Defs>
        <VictoryAxis
          crossAxis
          style={axisStyles}
          tickCount={maxBottomLabels}
          tickFormat={getTickFormat}
          tickLabelComponent={
            <VictoryLabel
              angle={-45}
              dx={-13}
              dy={0}
            />
          }
          tickValues={allXKeys}
        />
        <VictoryAxis
          crossAxis
          dependentAxis
          style={axisStyles}
          tickFormat={formatCurrencyTickToRightKiloValue}
        />
        <VictoryArea
          data={formattedData.chart1}
          style={{
            data: {
              fill: 'url(#AreaGradient)',
              stroke: line1Color,
              strokeWidth: 2,
              opacity: 0.5
            }
          }}
        />
        <VictoryLine
          data={formattedData.chart0}
          style={{
            data: {
              stroke: line0Color,
              strokeWidth: 2.5
            }
          }}
        />
      </VictoryChart>
    </View>
  );
};

const useStyles = createUseStyles(getStyles);
