import { FileData } from "@modules/common/types";

export const checkFile = (file: FileData, fileMaxSize: number, allowedExtensions: string[] = []) => {
  const isValidSize = file.size <= fileMaxSize;
  if (!isValidSize)
    throw new Error('File max size reached');
  const isValidExtension = allowedExtensions.includes(file.type);
  if (!isValidExtension)
    throw new Error('Not allowed file extension');
};
