import { combineReducers, Reducer } from 'redux'

import arbitrationReducer from '@modules/arbitration/reducer'
import { ArbitrationState } from '@modules/arbitration/types'
import CampaignReducer from '@modules/campaign/reducer'
import { CampaignState } from '@modules/campaign/types'
import documentsReducer from '@modules/document-space/reducer'
import { DocumentsState } from '@modules/document-space/types'
import { ErrorState } from '@modules/error/types'
import ProfitSharingIncentiveReducer from '@modules/profit-sharing-incentive/reducer'
import { ProfitSharingIncentiveState } from '@modules/profit-sharing-incentive/types'
import SavingsReducer from '@modules/savings/reducer'
import { SavingsState } from '@modules/savings/types'

import AnnuityReducer from './annuity/reducer'
import { AnnuityState } from './annuity/types'
import authReducer from './auth/authReducer'
import { AuthState } from './auth/types'
import BankDetailsReducer from './bank-details/reducer'
import { BankDetailsState } from './bank-details/types'
import DashboardReducer from './dashboard/reducer'
import { DashboardState } from './dashboard/types'
import errorReducer from './error/errorReducer'
import forgetPasswordReducer from './forget-password/reducer'
import { ForgetPasswordState } from './forget-password/types'
import FundsReducer from './funds/reducer'
import { FundsState } from './funds/types'
import historyReducer from './history/reducer'
import { HistoryState } from './history/types'
import { installmentReducer } from './installment/reducer'
import { InstallmentState } from './installment/types'
import invitationReducer from './invitation/reducer'
import { InvitationBodyState } from './invitation/types'
import isLoadingReducer, { IsLoadingState } from './isLoadingReducer'
import labelReducer, { LabelState } from './label/reducer'
import LegalReducer from './legal/reducer'
import { LegalState } from './legal/types'
import NotificationSystemReducer, {
  NotificationSystemState
} from './notification-system/notificationSystemReducer'
import PendingTasksReducer from './pending-tasks/pendingTasksReducer'
import { PendingTasksState } from './pending-tasks/types'
import ProfileReducer from './profile/reducer'
import { ProfileState } from './profile/types'
import redirectOperationReducer from './redirect-operation/reducer'
import { RedirectOperationState } from './redirect-operation/types'
import AvailableRefundReducer from './refund/available-refund/reducer'
import { AvailableRefundState } from './refund/available-refund/types'
import RefundReducer from './refund/reducer'
import { RefundsCommonState } from './refund/types'
import UnAvailableRefundReducer from './refund/unavailable-refund/reducer'
import { UnavailableRefundState } from './refund/unavailable-refund/types'
import retirementReducer from './retirement/retirementReducer'
import { RetirementState } from './retirement/types'
import SettingsReducer from './settings/reducer'
import { SettingsState } from './settings/types'
import { ValidateIdentityState } from './validate-identity/types'
import validateIdentityReducer from './validate-identity/validateIdentityReducer'
import ControlDataReducer from './control-data/reducer';
import { ControlDataState } from './control-data/types'

const rootReducer = (): Reducer =>
  combineReducers({
    invitation: invitationReducer,
    history: historyReducer,
    campaign: CampaignReducer,
    authentication: authReducer,
    validateIdentity: validateIdentityReducer,
    label: labelReducer,
    isLoading: isLoadingReducer,
    forgetPassword: forgetPasswordReducer,
    dashboard: DashboardReducer,
    pendingTasks: PendingTasksReducer,
    settings: SettingsReducer,
    savings: SavingsReducer,
    funds: FundsReducer,
    profile: ProfileReducer,
    legal: LegalReducer,
    installment: installmentReducer,
    refunds: RefundReducer,
    unavailableRefunds: UnAvailableRefundReducer,
    availableRefunds: AvailableRefundReducer,
    error: errorReducer,
    notificationSystem: NotificationSystemReducer,
    redirectOperation: redirectOperationReducer,
    arbitration: arbitrationReducer,
    profitSharingIncentive: ProfitSharingIncentiveReducer,
    documents: documentsReducer,
    retirement: retirementReducer,
    annuity: AnnuityReducer,
    controlData: ControlDataReducer,
    bankDetails: BankDetailsReducer
  })

export type AppState = {
  invitation: InvitationBodyState
  authentication: AuthState
  validateIdentity: ValidateIdentityState
  label: LabelState
  isLoading: IsLoadingState
  forgetPassword: ForgetPasswordState
  dashboard: DashboardState
  pendingTasks: PendingTasksState
  savings: SavingsState
  funds: FundsState
  profile: ProfileState
  campaign: CampaignState
  settings: SettingsState
  legal: LegalState
  installment: InstallmentState
  refunds: RefundsCommonState
  unavailableRefunds: UnavailableRefundState
  availableRefunds: AvailableRefundState
  history: HistoryState
  error: ErrorState
  notificationSystem: NotificationSystemState
  redirectOperation: RedirectOperationState
  arbitration: ArbitrationState
  profitSharingIncentive: ProfitSharingIncentiveState
  documents: DocumentsState
  retirement: RetirementState
  annuity: AnnuityState
  bankDetails: BankDetailsState
  controlData: ControlDataState;
}

export default rootReducer
