import { InstallmentRepartitionConsentModalStyles, StylesContext } from './interface';

export const getStyles = (
  context?: StylesContext,
  style?:  InstallmentRepartitionConsentModalStyles,
):  InstallmentRepartitionConsentModalStyles => ({
  scrollViewContentContainerStyle: [
    {
      alignItems: 'center',
      justifyContent: 'flex-start',
      paddingBottom: context?.theme.metrics.spacing.xm
    },
    style?.scrollViewContentContainerStyle
  ],
  iconContainerStyle: [
    {
      borderRadius: context?.theme.metrics.borderRadius.xs,
      padding: context?.theme.metrics.spacing.s,
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: context?.theme.metrics.spacing.xm,
      marginTop: context?.theme.metrics.spacing.l
    },
    style?.iconContainerStyle
  ],
  checkboxRowStyle: [
    {
      width: '100%',
      marginTop: context?.theme.metrics.spacing.xm
    },
    style?.checkboxRowStyle
  ],
  acceptButtonStyle: [{
    flex: 1,
    width: '100%',
    marginTop: context?.theme.metrics.spacing.xm,
    justifyContent: 'center'
  },
  style?.acceptButtonStyle
  ],
  checkboxLabel: [
    {
      paddingLeft: context?.theme.metrics.spacing.s
    },
    style?.checkboxLabel
  ]
});
