import {
  PendingTasksActions,
  PendingTasksActionsType
} from './actions/pendingTasksActionsTypes';
import { PendingTasksState } from './types';

export const initialState: PendingTasksState = [];

export default function PendingTasksReducer(
  state: PendingTasksState = initialState,
  action: PendingTasksActions
): PendingTasksState {
  switch (action?.type) {
  case PendingTasksActionsType.GET_PENDING_TASKS_SUCCESS:
    return action.pendingTasks;
  default:
    return state;
  }
}