import React, { useMemo } from 'react';
import { StyleSheet, StyleProp, ViewStyle } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

export const useStyles = ({
  style,
  disabled,
}: {
  disabled?: boolean
  style: StyleProp<ViewStyle>
}) => {
  const theme = useTheme();

  const styles = useMemo(() => StyleSheet.create({
    style: {
      ...StyleSheet.flatten(style)
    },
    contributionTextStyle: {
      textAlign: 'right',
      color: theme.colors.basics.primary.c500,
    },
    contributionIcon: {
      marginRight: theme.metrics.spacing.xs,
    },
    underFieldLinkStyle: {
      textAlign: 'right',
    },
    amountTextStyle: {
      textAlign: 'right',
      ...(disabled && {
        color: theme.colors.basics.grey.c500,
      })
    },
  }), [
    theme,
    disabled,
    style
  ])

  return styles;
};
