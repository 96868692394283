import { StackNavigationProp } from '@react-navigation/stack';
import React, { useCallback, useMemo, useState } from 'react';
import { View, StyleProp, ViewStyle, TextStyle } from 'react-native';

import {
  SimpleButton,
  IdentityValidation,
  WalkthroughStepper,
  OfflinePageLayout,
  useTheme,
  ThemeType,
  useTranslation,
  LegalMenuFooterConnected,
  NotificationSoft,
  useScreenSizes,
  NavigationHeader,
  LanguageSelector360,
  Title,
} from '@components/index';
import { createUseStyles } from '@ere-uilib/styles';

import { RouteNames } from '@constants/navigation';
import { OtpFields } from '@ere-uilib/molecules/inputs/OtpFields';
import { InvitationState } from '@modules/invitation/types';
import { IdentityValidationErrorState } from '@modules/validate-identity/types';
import { AppNavigatorInterface } from '@navigation/Interfaces';
import { useLogoNavigation } from '@utils/index';
import { locator } from '@constants/locator';
interface Props {
  navigation?: StackNavigationProp<AppNavigatorInterface>
  invitation: InvitationState
  validateIdentityError: IdentityValidationErrorState
  isSendCodeApiLoading: boolean
  isFCRegisterApiLoading: Boolean
  sendEERValidationCode: () => void
  onFranceConnectSignIn: () => void
  EnableFeatureFranceConnect?: boolean
  onLetterOTPSubmit: (code: string) => void
  isLoadingLetterOTPSubmit: boolean
}

type ValidationSignupStyles = {
  containerStyle?: StyleProp<ViewStyle>
  contentStyle?: StyleProp<ViewStyle>
  actionContentContainer?: StyleProp<ViewStyle>
  identityValidationLink?: StyleProp<TextStyle>
  fullSpaceContainer?: StyleProp<ViewStyle>
  mobileContentContainerStyle?: StyleProp<ViewStyle>
  buttonContainerStyle?: StyleProp<ViewStyle>
  errorViewStyle?: StyleProp<ViewStyle>
  identifierField?: StyleProp<ViewStyle>
  inputLabel?: StyleProp<TextStyle>
  bottomButtons?: StyleProp<ViewStyle>
  bottomButtonsContainer?: StyleProp<ViewStyle>
};

type StylesContext = { theme: ThemeType; isMobile: boolean; isTablet: boolean };

export const ValidationSignupPageComponent: React.FC<React.PropsWithChildren<Props>> = ({
  navigation,
  invitation,
  validateIdentityError,
  isSendCodeApiLoading,
  sendEERValidationCode,
  onFranceConnectSignIn,
  isFCRegisterApiLoading,
  EnableFeatureFranceConnect,
  onLetterOTPSubmit,
  isLoadingLetterOTPSubmit,
}) => {
  const theme = useTheme();
  const { isMobile, isTablet } = useScreenSizes();
  const styles = useStyles({ theme, isMobile, isTablet }, {});
  const { formatMessage, getMessageRaw } = useTranslation();
  const { logoNavigateTo } = useLogoNavigation();

  const isPhoneNumberValidation = !!invitation?.user.mobilePhone
    && invitation?.user.mobilePhone !== '';
  const isEmailValidation = !isPhoneNumberValidation
    && !!invitation?.user.email
    && invitation?.user.email !== '';
  const isLetterValidation = !isPhoneNumberValidation && !isEmailValidation;

  const identityValidationTitle = useMemo(() =>
    EnableFeatureFranceConnect ?
      formatMessage({ id: 'verify-identity_title' })
      : 'Pour créer votre compte, nous avons besoin de verifier votre identité'
    , [
      EnableFeatureFranceConnect,
      formatMessage,
    ]);
  // @todo: label to be aded in case when Fc is disabled

  const identityValidationSubtitle = useMemo(() => {
    switch (true) {
      case isEmailValidation:
        return `${formatMessage({ id: 'EerVerifyIdentityByEmailLabel' })} ${invitation?.user?.email}`;
      case isPhoneNumberValidation:
        return `${formatMessage({ id: 'verify-identity_description' })} ${invitation?.user?.mobilePhone}`;
      case isLetterValidation:
        return `${formatMessage({ id: 'EerSelfcare2emefacteurCourrierTitle' })} ${invitation?.user?.displayName}`;
      default:
        return '';
    }
  },
    [
      formatMessage,
      invitation?.user?.mobilePhone,
      invitation?.user?.email,
      isEmailValidation,
      isPhoneNumberValidation,
      isLetterValidation,
    ]);

  const renderLeftMenu = () => <WalkthroughStepper />;

  const renderLegalMenuFooter = () => <LegalMenuFooterConnected
    isWhite={!(isMobile || isTablet)}
  />;

  const onHelpPressed = () => {
    navigation?.navigate(RouteNames.Support);
  };

  const renderMobileHeader = () => {
    return <NavigationHeader
      displayBackButton
      helpPoint
      helpPointContentHtml={getMessageRaw({ id: 'eer_info_content' })}
      helpPointModalTitle={formatMessage({ id: 'eer_info_title' })}
      helpPointPosition="right"
      onGoBack={() => navigation?.goBack()}
      title={formatMessage({ id: 'validate-identity_menu' })} />;
  };
  const [code, setCode] = useState(['', '', '', '', '', '']);
  const submitButtonActive = code.join('').length === 6;
  const handleChangeCode = useCallback((value: string[]) => {
    setCode(value);
  }, []);
  const onSubmitButtonPress = useCallback(() => {
    sendEERValidationCode?.();
  }, [
    sendEERValidationCode,
  ]);

  const onPressSubmitOtp = useCallback(() => {
    const codeToSendPrefixed = code.join('');
    onLetterOTPSubmit?.(codeToSendPrefixed);
  }, [code]);

  const renderError = useCallback(() => {
    return validateIdentityError?.message !== '' && (
      <NotificationSoft
        containerStyle={styles.errorViewStyle}
        text={validateIdentityError.message}
        type="warning"
      />);
  }, [validateIdentityError, styles]);

  const renderIdentifierInput = useCallback(() => {
    return (
      <>
        <View style={styles.identifierField}>
          <Title
            style={styles.inputLabel}
            variant={'t4'}
            weight="bold">2 - </Title>
          <OtpFields
            code={code}
            hasError={!!validateIdentityError?.message}
            onChange={handleChangeCode}
            onSubmit={onPressSubmitOtp}
          />
        </View>
        {renderError()}
        <View style={styles.bottomButtonsContainer}>
          <SimpleButton
            containerStyle={styles.bottomButtons}
            disabled={!submitButtonActive}
            loading={isLoadingLetterOTPSubmit}
            onPress={onPressSubmitOtp}
            title={formatMessage({ id: 'EerSelfcare2emefacteurCourrierButton' })}
          />
        </View>
      </>
    );
  }, [
    styles,
    code,
    isLoadingLetterOTPSubmit,
    handleChangeCode,
    onPressSubmitOtp,
    submitButtonActive,
    renderError,
    formatMessage,
    validateIdentityError?.message,
  ]);
  return (
    <OfflinePageLayout
      displayLogo={!(isMobile || isTablet)}
      onLogoPress={logoNavigateTo}
      renderDesktopCardBelowContent={renderLegalMenuFooter}
      renderDesktopCardLeftContent={renderLeftMenu}
      renderMobileHeaderContent={renderMobileHeader}
      renderMobileStickyGradientFooterContent={renderLegalMenuFooter}
    >
      <View style={styles.contentStyle}>
        <View style={styles.fullSpaceContainer}>
          <IdentityValidation
            EnableFeatureFranceConnect={EnableFeatureFranceConnect}
            onFranceConnectPressed={() => !isFCRegisterApiLoading && onFranceConnectSignIn()}
            onHelpPressed={onHelpPressed}
            subtitle={identityValidationSubtitle}
            supportStyle={styles.identityValidationLink}
            title={identityValidationTitle}
          >
            <View style={styles.actionContentContainer}>
              {isLetterValidation ?
                renderIdentifierInput()
                :
                <>
                  {renderError()}
                  <SimpleButton
                    loading={isSendCodeApiLoading}
                    onPress={onSubmitButtonPress}
                    title={formatMessage({ id: 'verify-identity_button' })}

                    testId={locator._account_activation._receive_code}
                  />
                </>
              }
            </View>
          </IdentityValidation>
        </View>
      </View>
      <LanguageSelector360 offlineLayoutAbsolutePosition />
    </OfflinePageLayout>
  );
};

const getStyles = (
  context?: StylesContext,
  style?: ValidationSignupStyles,
): ValidationSignupStyles => ({
  contentStyle: [
    !(context?.isMobile || context?.isTablet)
      ? {
        flex: 1,
        justifyContent: 'center',
        marginHorizontal: context?.theme.metrics.spacing.xxl,
      }
      : { flex: 1, marginTop: context?.theme.metrics.spacing.xxl },
    style?.contentStyle,
  ],
  identityValidationLink: [
    (context?.isMobile || context?.isTablet) && {
      textAlign: 'center',
    },
    style?.identityValidationLink,
  ],
  buttonContainerStyle: [
    !(context?.isMobile || context?.isTablet)
      ? { marginTop: context?.theme.metrics.spacing.l, maxWidth: 250 }
      : { marginTop: context?.theme.metrics.spacing.l, width: '100%' },
  ],
  fullSpaceContainer: [
    {
      marginTop: !(context?.isMobile || context?.isTablet) ? context?.theme.metrics.spacing.xxhuge : context?.theme.metrics.spacing.none,
      flex: 1,
      alignItems: 'center',
    },
  ],
  actionContentContainer: [
    !(context?.isMobile || context?.isTablet) ?
      {
        marginTop: context?.theme.metrics.spacing.l,
      } : {
        marginTop: context?.theme.metrics.spacing.xm,
      },
  ],
  errorViewStyle: [
    {},
  ],
  identifierField: {
    flexDirection: 'row',
    alignSelf: !(context?.isMobile || context?.isTablet) ? 'flex-start' : 'center',
    alignItems: 'center',
  },
  inputLabel: { color: context?.theme.colors.basics.grey.c400 },
  bottomButtonsContainer: {
    alignItems: 'flex-start',
    flex: 1,
  },
  bottomButtons: [
    !(context?.isMobile || context?.isTablet) ?
      {
        marginTop: context?.theme.metrics.spacing.l,
      } : {
        marginTop: context?.theme.metrics.spacing.xm,
        width: '100%',
      },
  ],
});

const useStyles = createUseStyles(getStyles);
