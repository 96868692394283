import { useNavigation } from '@react-navigation/core';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { RouteNames } from '@constants/navigation';
import { AppState } from '@modules/index';
import { getAuthConfig } from '@modules/settings/selectors';
import { loginOpenId } from '@modules/auth/services';

import { useIsAuthenticated } from './useIsAuthenticated';

export const useLogoNavigation = ()=> {

  const authConfig = useSelector((state: AppState) => getAuthConfig(state));
  const navigation = useNavigation();
  const { isAuthenticated } = useIsAuthenticated();

  const logoNavigateTo = useCallback(()=> {
    if (isAuthenticated) {
      navigation.navigate(RouteNames.BottomTabNavigator, { screen: RouteNames.Home });
    } else {
      loginOpenId(authConfig);
    }
  }, [isAuthenticated]);

  return {
    logoNavigateTo,
  };

};
