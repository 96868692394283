import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from '@components/ERE-UILib';
import { IconEnum } from '@ere-uilib/enums';
import { InformativePopin } from '@ere-uilib/organisms';
import { initBankDataFailure } from '@modules/bank-details/actions/actionsCreators';
import { getBankDetailsError } from '@modules/bank-details/selectors';

const ICON_SIZE = 60;

export const BankDetailsErrorPopin = () => {
  const { formatMessage } = useTranslation();
  const { Code = '' } = useSelector(getBankDetailsError) || {}; // I know... it is 'Code' instead of 'code'
  const dispatch = useDispatch();

  const resetError = useCallback(() => dispatch(initBankDataFailure(null)), [dispatch]);

  const handleClose = useCallback(() => resetError(), [resetError]);

  return (
    <InformativePopin
      description={formatMessage({ id: Code })}
      iconHeight={ICON_SIZE}
      iconName={IconEnum.WARNING_WITH_BG}
      iconWidth={ICON_SIZE}
      isModalVisible={!!Code}
      onClose={handleClose}
      title={formatMessage({ id: 'CoordonneesBancairesMessagePopinErreurTitre' })}
    />
  );
};
