import React, { useEffect, useState } from 'react';

import { AmountFieldModal360 } from '@components/index';
import { useTranslation } from '@ere-uilib/translations';

import {
  ModalData, RefundAmountFieldModalConnectedProps
} from './interfaces';
import { RefundAmountFieldModalConnectedRefType } from './sharedInterfaces';

export const RefundAmountFieldModalConnectedComponent = React.forwardRef<RefundAmountFieldModalConnectedRefType, RefundAmountFieldModalConnectedProps>((
  {
    visible,
    onSetRefundRepartitionAmountModalVisibleState,
    onSetRefundRepartitionAmount,
  },
  ref
) => {
  const defaultData:ModalData = {
    displayData: {
      title: '',
      initialAmount: undefined,
    },
    supportIsIn: undefined,
  };
  const [data, setData] = useState<ModalData>(defaultData);
  const { formatMessage } = useTranslation();
  // allow method via ref
  React.useImperativeHandle(ref, () => ({
    openWithData: receivedData => {
      onSetRefundRepartitionAmountModalVisibleState(true);
      setData({
        ...receivedData,
        displayData: {
          ...receivedData.displayData,
          allAmountLabel: formatMessage({ id: 'Remboursement_ToutRecuperer' }),
        },
      });
    },
  }));

  useEffect(() => {
    if (!visible) {
      setData(defaultData);
    }
  }, [visible]);

  const onCancelPressed = ()=>{
    onSetRefundRepartitionAmountModalVisibleState(false);
    setData(defaultData);
  };

  const onValidPressed = (amount?:number|null)=>{
    if (data.supportIsIn && data.compartmentId && data.managementId) {
      onSetRefundRepartitionAmount({
        amount: amount || 0,
        compartmentId: data.compartmentId,
        managementId: data.managementId,
        supportIsin: data.supportIsIn,
      });
      onSetRefundRepartitionAmountModalVisibleState(false);
    }
  };
  return (
    <AmountFieldModal360
      data={data.displayData}
      isValidButtonLoading={false}
      onCancel={onCancelPressed}
      onValid={onValidPressed}
      visible={visible}
    />
  );
});
