import {useScreenSizes, useTheme} from '@ere-uilib/styles';
import {useMemo} from 'react';
import {StyleSheet} from 'react-native';


export function useStyles() {
  const theme = useTheme();
  const { isDesktop } = useScreenSizes();
  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          marginHorizontal: isDesktop ? theme.metrics.spacing.xxl : 0,
          flex: 1
        },
        topDesktopContainerStyle: {
          minHeight: theme.metrics.spacing.xxhuge,
          paddingTop: theme.metrics.spacing.s
        },
        backButtonStyle: {
          marginTop: theme.metrics.spacing.xxxbig
        },
        titleStyle: {
          ...(isDesktop
            ? {
              marginBottom: theme.metrics.spacing.xm
            }
            : {
              marginTop: theme.metrics.spacing.l,
              textAlign: 'left'
            })
        },
        titleView: {
          justifyContent: 'center'
        }
      }),
    [theme, isDesktop]
  );
  return styles;
}
