import { useScreenSizes, useTheme } from '@ere-uilib/styles';
import { useMemo } from 'react';
import { StyleSheet } from 'react-native';


export function useStyles() {
  const { isMobile } = useScreenSizes();
  const theme = useTheme();

  const styles = useMemo(() => StyleSheet.create({
    categoriesCardStyle: {
      flex: 1,
      flexDirection: "row",
      flexWrap: "wrap",
      width: "100%",
      marginTop: theme.metrics.spacing.m,
      justifyContent: 'space-between'
    },
    cardStyle: {
      width: "48%",
      marginBottom: theme.metrics.spacing.m
    },
    innerCardStyle: {
      ...isMobile ? {
        minWidth: '100%',
      } : {
        width: '100%',
        maxWidth: '80%',
        alignSelf: 'center',
        alignItems: 'center'
      }
    },
    titleStyle: {
      textAlign: 'left',
      marginRight: 'auto'
    },
  }), [isMobile]);

  return styles;
}
