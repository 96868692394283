import React from 'react';
import { View } from 'react-native';

import { createUseStyles, useTheme } from '@ere-uilib/styles';

import { BubbleContainerProps } from './interfaces';
import { getStyles } from './styles';

export const BubbleContainer: React.FC<React.PropsWithChildren<BubbleContainerProps>> = ({
  children,
  containerStyle,
  innerContainerStyle,
  squarePosition = 'left',
  squareFront,
})=> {
  const theme = useTheme();
  const styles = useStyles({
    theme,
    squarePosition
  }, {
    containerStyle,
    innerContainerStyle,
    squareFront,
  });

  return (
    <View style={styles.containerStyle}>
      <View style={[styles.square, styles.squareBack]} />
      <View style={styles.innerBackContainerStyle}>
        <View style={[styles.square, styles.squareFront]} />
        <View style={styles.innerContainerStyle} >
          {children}
        </View>
      </View>
    </View>
  );
};

const useStyles = createUseStyles(getStyles);
