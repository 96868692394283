import React from 'react';
import { View, StyleProp, ViewStyle } from 'react-native';

import { createUseStyles, useTheme, ThemeType } from '@ere-uilib/styles';

import { Row } from './Row';
import { TableCellInterface } from './sharedInterfaces';
interface TableProps {
  data: TableCellInterface[][];
  containerStyle?: StyleProp<ViewStyle>;
  cellPosition?: number;
  testId?: string;
}

interface TableStyles {
  containerStyle?: StyleProp<ViewStyle>;
  firstRowContainer?: StyleProp<ViewStyle>;
}

type StyleContext = {
  theme: ThemeType;
};

export const Table: React.FC<React.PropsWithChildren<TableProps>> = ({
  data,
  cellPosition,
  containerStyle,
  testId
}) => {
  const theme = useTheme();
  const styles = useStyle(
    {
      theme,
    },
    {
      containerStyle,
    }
  );
  return (
    <View style={styles.containerStyle} testID={testId}>
      {data.map((column: any, index:number) => (
        <Row
          cellPosition={cellPosition}
          column={column}
          containerStyle={index === 0 && styles.firstRowContainer}
          index={index}
          key={index}
          rowsLength={data.length}
          testId={testId}
        />
      ))}
    </View>
  );
};

const getStyles = (
  context?: StyleContext,
  style?: TableStyles
): TableStyles => ({
  containerStyle: [
    {

    },
    style?.containerStyle,
  ],
  firstRowContainer: {
    zIndex: 1,
  },
});

const useStyle = createUseStyles(getStyles);
