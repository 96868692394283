import { StylesContext, StylesProps } from './interfaces';

export const getStyles = (
  context?: StylesContext,
  style?: StylesProps,
): StylesProps => ({
  container: [
    {
      backgroundColor: context?.theme.colors.basics.white,
      alignSelf: 'stretch',
      flex: 1,
      zIndex: 1
    },
    context?.isMobile
      ? {
        paddingHorizontal: context?.theme.metrics.spacing.xs,
        paddingBottom: context?.theme.metrics.spacing.huge
      }
      : {
        paddingRight: '15%',
        paddingLeft: '15%',
        paddingTop: context?.theme.metrics.spacing.m,
        paddingBottom: context?.theme.metrics.spacing.none
      },
    style?.container
  ]
});
