import { connect, useDispatch, useSelector } from "react-redux";

import { getLoadingState } from "@modules/funds/selectors";
import { AppState } from "@modules/reducers";
import { createRepaymentDemandRequest } from "@modules/refund/available-refund/actions/refundActions";
import { RefundActionsType } from "@modules/refund/available-refund/actions/refundActionsTypes";
import { getRefundRepartitionData, getRefundsError } from "@modules/refund/available-refund/selectors";
import { getBankDetails } from "@modules/refund/selectors";
import { getApplicationSettings } from "@modules/settings/selectors";

import { RefundsRecapPageComponent } from "./RefundsRecapPage.component";

export const RefundsRecapPage : React.FC = () => {
  const dispatch = useDispatch()
  const repartitions = useSelector(getRefundRepartitionData);
  const bankInformations = useSelector(getBankDetails);
  const appSettings = useSelector(getApplicationSettings);
  const isLoading = useSelector(getLoadingState(RefundActionsType.CREATE_REPAYMENT_DEMAND_REQUEST));
  const repaymentDemandError = useSelector(getRefundsError);
  
  const onCreateRepaymentDemandRequest = () => {
    dispatch(createRepaymentDemandRequest());
  }
  return (
      <RefundsRecapPageComponent
          appSettings={appSettings}
          bankInformations={bankInformations}
          isLoading={isLoading}
          onSetRepaymentDemand={onCreateRepaymentDemandRequest}
          repartitions={repartitions}
          repaymentDemandError={repaymentDemandError}/>
  );
}
