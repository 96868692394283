import axios from 'axios';

import { API_URL } from '@config/index';
import { FetchAnnuityResultsData, FetchAnnuitySimulationData } from '@modules/annuity/types';

export async function fetchDataAnnuityResult(
  params: FetchAnnuitySimulationData | undefined
): Promise<FetchAnnuityResultsData> {
  return axios.post(`${API_URL}/api/v1/tools/sapiendo/simulation-capitalisation`, params);
}
