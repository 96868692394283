import React from 'react';
import { View } from 'react-native';

import { Title, useTranslation, FontIcon, IconEnum } from '@ere-uilib/index';
import { useTheme } from '@ere-uilib/styles/theme';

import { Props } from './interfaces';
import { useStyles } from './useStyles'

export const ContributionInfoNotEligibleCard: React.FC<Props> = ({
  contributionAmount,
  title,
  emptyContributionTitle,
  estimatedAmount
}) => {
  const { formatCurrencyNumber } = useTranslation();
  const styles = useStyles();
  const theme = useTheme();

  return (
    <View style={styles.containerStyle}>
      {contributionAmount ? 
      <>
          <Title
            style={styles.textStyle}
            variant="t7"
            weight="regular"
          >       {title}
          </Title>
          <View style={styles.rightContainerStyle}>
            <FontIcon
                    name={IconEnum.CONTRIBUTION}
                    size={theme.metrics.iconSizes.m}
                    style={styles.contributionIcon}
                  />
              <Title
              style={styles.textStyle}
              variant="t7"
              weight="regular"
            >
              {
                formatCurrencyNumber({ value: estimatedAmount || 0 })}
              </Title>
          </View>
          </>
        :
        <View style={styles.containerCenterStyle}>
        <FontIcon
                name={IconEnum.CONTRIBUTION}
                size={theme.metrics.iconSizes.m}
                style={styles.contributionIcon}
              />
          <Title
          style={styles.textStyle}
          variant="t7"
          weight="regular"
        >
          {emptyContributionTitle}
          </Title>
      </View>
        }
    </View>
  );
};