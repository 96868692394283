import { ManagmentType, SupportType } from '@modules/common/types'
import { useCallback, useState } from 'react'
import { DueDateProps } from '../components/Support/components/DueDateCard/interface'

export const useController = ({
  storeSelectedSupport,
  handleRenderArbitrationDueDateAmountModal,
  handleEditAmountPress,
  onSelectArbitrationDisinvestmentSavingSupport
}: any) => {
  const [showInfoPopin, setShowInfoPopin] = useState(false)

  const [selectedEcheance, setSelectedEcheance] = useState<DueDateProps | undefined>(undefined)
  const [nextSelectedSupport, setNextSelectedSupport] = useState<SupportType | undefined>()
  const [nextSelectedManagement, setnextSelectedManagement] = useState<ManagmentType | undefined>(
    undefined
  )
  const [nextSelectedEcheance, setNextSelectedEcheance] = useState<DueDateProps | undefined>(
    undefined
  )

  const handleChangeSelectedInput = useCallback(
    (support?, management?, echeance?) => {
      setnextSelectedManagement(management)
      setNextSelectedSupport(support)
      setNextSelectedEcheance(echeance)
    },
    [setnextSelectedManagement, setNextSelectedSupport, setNextSelectedEcheance]
  )

  const handleHideInfoModalPressed = useCallback(() => {
    setShowInfoPopin(false)
    setNextSelectedSupport(storeSelectedSupport)
    setNextSelectedEcheance(selectedEcheance)
  }, [
    setShowInfoPopin,
    setNextSelectedSupport,
    setNextSelectedEcheance,
    storeSelectedSupport,
    selectedEcheance
  ])

  const handleShowInfoPopin = useCallback(
    (support, management, echeance?) => {
      const isSameInput =
        support.supportName === nextSelectedSupport?.supportName &&
        echeance?.scheduleCode === nextSelectedEcheance?.scheduleCode &&
        echeance?.date === nextSelectedEcheance?.date &&
        echeance?.amount === nextSelectedEcheance?.amount &&
        management.id === nextSelectedManagement?.id

      if (isSameInput) {
        echeance
          ? handleRenderArbitrationDueDateAmountModal(echeance, support, management.id)
          : handleEditAmountPress(management, support, management.id)
      } else {
        setShowInfoPopin(true)
        handleChangeSelectedInput(support, management, echeance)
      }
    },
    [setShowInfoPopin, handleChangeSelectedInput, nextSelectedSupport, nextSelectedEcheance]
  )

  const handleResetEcheance = useCallback(() => {
    setSelectedEcheance(undefined)
    setNextSelectedEcheance(undefined)
  }, [setSelectedEcheance, setNextSelectedEcheance])

  const handleConfirmInfoModalPressed = useCallback(() => {
    if (nextSelectedManagement?.id && nextSelectedSupport) {
      if (nextSelectedSupport?.supportName !== storeSelectedSupport?.supportName) {
        if (nextSelectedEcheance)
          handleRenderArbitrationDueDateAmountModal(
            nextSelectedEcheance,
            nextSelectedSupport,
            nextSelectedManagement.id
          )
        else
          onSelectArbitrationDisinvestmentSavingSupport({
            selectState: true,
            amount: nextSelectedSupport.amount.amount,
            managementId: nextSelectedManagement.id,
            supportIsin: nextSelectedSupport.supportIsin
          })
      } else {
        nextSelectedEcheance
          ? handleRenderArbitrationDueDateAmountModal(
              nextSelectedEcheance,
              nextSelectedSupport,
              nextSelectedManagement.id
            )
          : handleEditAmountPress(
              nextSelectedManagement,
              nextSelectedSupport,
              nextSelectedManagement.id
            )
      }
      setSelectedEcheance(nextSelectedEcheance)
    }
    setShowInfoPopin(false)
  }, [
    nextSelectedSupport,
    nextSelectedManagement,
    nextSelectedEcheance,
    setSelectedEcheance,
    handleRenderArbitrationDueDateAmountModal,
    onSelectArbitrationDisinvestmentSavingSupport,
    setShowInfoPopin
  ])

  const handleSupportSelected = useCallback(
    (support, management, echeance?) => {
      if (!storeSelectedSupport) {
        onSelectArbitrationDisinvestmentSavingSupport({
          selectState: true,
          amount: support.amount.amount,
          managementId: management.id,
          supportIsin: support.supportIsin
        })
        setSelectedEcheance(echeance)
        handleChangeSelectedInput(support, management, echeance)
      } else handleShowInfoPopin(support, management, echeance)
    },
    [
      storeSelectedSupport,
      setSelectedEcheance,
      handleChangeSelectedInput,
      handleShowInfoPopin,
      onSelectArbitrationDisinvestmentSavingSupport
    ]
  )

  return {
    handleChangeSelectedInput,
    handleHideInfoModalPressed,
    handleShowInfoPopin,
    handleConfirmInfoModalPressed,
    handleSupportSelected,
    handleResetEcheance,
    showInfoPopin
  }
}
