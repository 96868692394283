import React, { createContext, useState } from 'react';

export interface ExpandableContextData {
  isExpanded: boolean;
  setIsExpanded: (isExpanded: boolean) => void;
}

interface ExpandableProviderProps {
  isDefaultExpanded?: boolean;
  children: (isToggled: boolean) => React.ReactNode;
}

export const ExpandableContext = createContext<ExpandableContextData>({
  isExpanded: false,
  setIsExpanded: () => {}
});

export const ExpandableProvider = ({
  children,
  isDefaultExpanded = true
}: ExpandableProviderProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(isDefaultExpanded);

  return (
    <ExpandableContext.Provider value={{ isExpanded, setIsExpanded }}>
      {children(isExpanded)}
    </ExpandableContext.Provider>
  );
};
