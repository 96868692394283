export type NetAssetValueType = {
  amount: number
  currency: string
  dateValue: string
}

export enum DisplayValueType {
  PERCENT = 'PERCENT',
  EURO = 'EURO'
}
