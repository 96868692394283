import React, { useEffect } from 'react';
import { Platform } from 'react-native';

import {
  OfflineAnnounceLayout360,
} from '@components/index';
import { useTranslation } from '@translations/index';
import { useLogoNavigation } from '@utils/index';
interface Props {
  signinRedirectCallback: () => void
}

export const OidcCallbackRedirectPageComponent: React.FC<React.PropsWithChildren<Props>> = ({
  signinRedirectCallback,
}) => {

  const { formatMessage } = useTranslation();
  const { logoNavigateTo } = useLogoNavigation();

  const manageSigninredirect = () => {
    if (Platform.OS === 'web') return signinRedirectCallback();
  };

  useEffect(() => {
    manageSigninredirect();
  }, []);

  return (
    <OfflineAnnounceLayout360
      description={formatMessage({ id: 'login_transition_label' })}
      onLogoPress={logoNavigateTo}
      sourceName="welcomeGif"
      title={formatMessage({ id: 'login_transition_title' })}
    />
  );
};

