import { OtpSecurisationStartPageStyles, StylesContext } from './interface';

export const getStyles = (
  context?: StylesContext,
  style?: OtpSecurisationStartPageStyles,
): OtpSecurisationStartPageStyles => ({
  helpContainerStyle: [
    {
      marginTop: context?.theme.metrics.spacing.l
    },
    style?.helpContainerStyle
  ],
  helpTextStyle: [
    {
      textAlign: 'center',
      marginBottom: context?.theme.metrics.spacing.s
    },
    style?.helpTextStyle
  ]
});
