import { useMemo } from "react";
import { StyleSheet } from "react-native";

import { useScreenSizes, useTheme } from "@ere-uilib/styles";

export const useStyles = () => {
  const theme = useTheme();
  const { isMobile } = useScreenSizes();

  const styles = useMemo(() => StyleSheet.create({
    containerStyle:
    {
      flexDirection: 'row'
    },
    chartContainerStyle:
    {
      flexDirection: 'row',
    },
    chartCardStyle:
    {
      flex: 1,
      backgroundColor: theme.colors.basics.white,
      marginHorizontal: theme.metrics.spacing.s,
      marginTop: theme.metrics.spacing.l,
      padding: theme.metrics.spacing.s,
      borderRadius: theme.metrics.spacing.xs,
      shadowColor: theme.colors.basics.black,
      shadowOffset: {
        width: 0,
        height: 0,
      },
      shadowOpacity: 0.15,
      shadowRadius: theme.metrics.spacing.s,
      elevation: 7,
    },
    chartTitleStyle:
    {
      textAlign: 'center'
    },
    legendContainer:
    {
      marginHorizontal: "auto",
      flexDirection: 'row',
      marginTop: theme.metrics.spacing.m,
      marginBottom: theme.metrics.spacing.xm
    },
    legendPointStyle:
    {
      width: 13,
      height: 13,
      borderRadius: 50,
      marginRight: theme.metrics.spacing.xxs
    },
    bottomTitle:
    {
      textAlign: "left",
      flex: 0.2,
      marginLeft: isMobile ? 0 : theme.metrics.spacing.s,
      textTransform: 'uppercase'
    },
    svgContainer:
    {
      flex: 1,
      right: isMobile ? 10 : 30,
      maxWidth: '20%',
      minHeight: isMobile ? theme.metrics.iconSizes.mplus : theme.metrics.iconSizes.xxxl
    },
    textStyle:
    {

      textAlign: 'justify'
    },
    textContainerStyle:
    {
      backgroundColor: theme.colors.basics.white,
      paddingVertical: theme.metrics.spacing.xm,
      paddingHorizontal: theme.metrics.spacing.s,
    },
  }), [isMobile, theme]);

  return styles;
};
