import { useMemo } from 'react';
import { StyleSheet } from "react-native";

import { useTheme } from '@ere-uilib/styles';

const ARROW_SIZE = 12;

export const useStyles = () => {
  const theme = useTheme();

  const styles = useMemo(
    () => StyleSheet.create({
      bottomSectionContainer: {
        marginTop: theme.metrics.spacing.xxm
      },
      amountContainerStyle: {
        flex: 1,
        flexDirection: 'row'
      },
      amountContentRight: {
        paddingLeft: theme.metrics.spacing.s
      },
      arrowIconStyle: {
        width: ARROW_SIZE,
        height: ARROW_SIZE,
        maxWidth: ARROW_SIZE,
        maxHeight: ARROW_SIZE,
      }
    }),
    [theme]
  );

  return styles;
};
