import { StackNavigationProp } from '@react-navigation/stack';
import { ComponentType, MutableRefObject, useCallback, useRef, useState } from 'react';
import { LayoutChangeEvent, NativeScrollEvent, NativeSyntheticEvent } from 'react-native';

import { FundSheetModalDataType } from '@components/templates';
import { RouteNames } from '@constants/navigation';
import { PieChartData } from '@ere-uilib/types';
import { GetRiskProfileParams } from '@modules/arbitration/types';
import { SupportType } from '@modules/common/types';
import { AppNavigatorInterface } from '@navigation/Interfaces';

import { FormValues } from './useForm';

type ScrollToEnd = (ComponentType<React.PropsWithChildren<any>> | null) & {
  scrollToEnd: () => void;
};

interface UseController {
  navigation: StackNavigationProp<AppNavigatorInterface, keyof AppNavigatorInterface>;
  manualRepartitionData: PieChartData[];
  hideFormModal: () => void;
  onSubmit?: (formValues: FormValues) => void;
  formValues?: FormValues;
  fetchRiskProfile?: (param: GetRiskProfileParams) => void;
}
enum RiskProfileKeysEnum {
  monetaryPercentage = 'monetaryPercentage',
  obligatoryPercentage = 'obligatoryPercentage',
  diversifiedPercentage = 'diversifiedPercentage',
  actionPercentage = 'actionPercentage'
}
export const useController = ({
  navigation,
  manualRepartitionData,
  hideFormModal,
  onSubmit,
  formValues,
  fetchRiskProfile,
}: UseController) => {
  const [displayFundSheetModal, setDisplayFundSheetModal] = useState(false);
  const [fundSheetModalData, setFundSheetModalData] = useState<FundSheetModalDataType | null>();
  const [chartsSectionHeight, setChartsSectionHeight] = useState(0);
  const [displaySubHeaderSticky, setDisplaySubHeaderSticky] = useState(false);
  const pieChartScrollViewRef = useRef() as MutableRefObject<ScrollToEnd>;
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [honnorAccepted, setHonnorAccepted] = useState<boolean>(false);

  const handleCardContentTitlePress = useCallback((support: SupportType) => {
    setDisplayFundSheetModal(true);
    setFundSheetModalData({
      id: support.supportIsin,
      title: support.supportName
    });
  }, []);

  const handleFundSheetModalClose = useCallback(() => {
    setDisplayFundSheetModal(false);
    setFundSheetModalData(null);
  }, []);

  const handleGoBackPress = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const handleLayout = useCallback((event: LayoutChangeEvent) => {
    const { height } = event.nativeEvent.layout;
    setChartsSectionHeight(height);
  }, []);

  const handleScroll = useCallback(
    (event: NativeSyntheticEvent<NativeScrollEvent>) => {
      if (event.nativeEvent.contentOffset.y > chartsSectionHeight) {
        setDisplaySubHeaderSticky(true);
      } else {
        setDisplaySubHeaderSticky(false);
      }
    },
    [chartsSectionHeight]
  );

  const handeAmountFieldModalCancel = useCallback(() => {
    hideFormModal();
  }, [hideFormModal]);

  const scrollToEnd = () => {
    pieChartScrollViewRef.current?.scrollToEnd();
  };

  const handleSubmit = useCallback(() => {
    if (!formValues) {
      return;
    }

    onSubmit?.(formValues);
  }, [onSubmit, formValues]);

  const handleShowHonnorDeclarationModal = useCallback(() => {
    setIsVisible(true);
  }, [setIsVisible]);
  const handleRiskProfilFetch = useCallback(() => {
    const params = {
      actions: 0,
      obligations: 0,
      monetary: 0,
      diversified: 0
    };
    manualRepartitionData.map(repartition => {
      switch (repartition.key) {
        case RiskProfileKeysEnum.actionPercentage:
          params.actions = repartition.value * 100;
          break;
        case RiskProfileKeysEnum.obligatoryPercentage:
          params.obligations = repartition.value * 100;
          break;
        case RiskProfileKeysEnum.monetaryPercentage:
          params.monetary = repartition.value * 100;
          break;
        default:
          params.diversified = repartition.value * 100;
          break;
      }
    });
    fetchRiskProfile?.(params);
  }, [fetchRiskProfile, manualRepartitionData]);
  const handleHonnorDeclarationModalClose = useCallback(() => {
    setIsVisible(false);
    setHonnorAccepted(false);
  }, [setIsVisible]);
  const handleHonnorCheckBoxPress = useCallback(() => {
    setHonnorAccepted(!honnorAccepted);
  }, [setHonnorAccepted, honnorAccepted]);

  const handleHonnorDeclarationModalPress = () => {
    handleHonnorDeclarationModalClose();
    handleSubmit();
    handleRiskProfilFetch();
    navigation.navigate(RouteNames.ArbitrationStack, {
      screen: RouteNames.ArbitrationOADRepartitionConfirmation
    });
  };

  return {
    displayFundSheetModal,
    fundSheetModalData,
    displaySubHeaderSticky,
    pieChartScrollViewRef,
    isVisible,
    honnorAccepted,
    handleHonnorDeclarationModalPress,
    handleCardContentTitlePress,
    handleFundSheetModalClose,
    handleLayout,
    handleScroll,
    handeAmountFieldModalCancel,
    handleGoBackPress,
    handleShowHonnorDeclarationModal,
    handleHonnorDeclarationModalClose,
    handleHonnorCheckBoxPress,
    scrollToEnd,
    handleSubmit,
  };
};
