import { useMemo } from "react";
import { StyleSheet } from "react-native";

import { useScreenSizes, useTheme } from "@ere-uilib/styles";

export const useStyles = () => {
  const { isMobile } = useScreenSizes();
  const theme = useTheme();

  const styles = useMemo(() => StyleSheet.create({
    squaresContainerStyle: {
      backgroundColor: theme.colors.basics.grey.c100,
      borderRadius: 5,
      margin: theme.metrics.spacing.s,
      paddingHorizontal: theme.metrics.spacing.xm,
      paddingVertical: theme.metrics.spacing.l
    },
    containerStyle: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: isMobile ? 'column' : 'row',
      marginTop: isMobile ? theme.metrics.spacing.xm : theme.metrics.spacing.xl,
      marginBottom: isMobile ? theme.metrics.spacing.xm : theme.metrics.spacing.xl,
    },
    innerContainerStyle: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flex: !isMobile ? 1 : undefined,
      marginBottom: isMobile ? theme.metrics.spacing.s : undefined
    },
    squaresWrapperStyle: {
      display: 'flex',
      flex: 1
    }
  }), [
    theme,
    isMobile
  ]);

  return styles;
};
