import React, { FC, useEffect, useRef } from 'react';
import {  TouchableOpacity, View } from 'react-native';

import { Text } from '@ere-uilib/atoms';
import { createUseStyles, useTheme } from '@ere-uilib/styles';

import { Props, SwitchItem } from './interfaces';
import { getStyles } from './styles';

export const SwitchButtons: FC<Props> = ({
  items,
  containerStyle,
  textStyle,
  activeTextStyle,
  onChange,
  defaultSelectValue,
  itemActiveStyle,
  itemStyle,
  disabled = false,

}:Props) => {
  const theme = useTheme();
  const styles = useStyles({
    theme,
  }, {
    containerStyle,
    textStyle,
    activeTextStyle,
    itemActiveStyle,
    itemStyle
  });

  const activeItemRef = useRef<SwitchItem>();

  useEffect(() => {
    const existValue = items.find(item => (item.value === defaultSelectValue));
    activeItemRef.current = existValue || items[0];
  }, [defaultSelectValue]);

  return (
    <View style={styles.containerStyle}>
        {items.map((item, index) => {
            const isCurrentValue = defaultSelectValue === item.value;
            return (
                <TouchableOpacity
                    disabled={disabled}
                    key={`switchButton-${index}`}
                    testID={`switchButton-${item.value}`}
                    onPress={() => onChange(item)}
                    style={isCurrentValue ? styles.itemActiveStyle : styles.itemStyle}
                >
                    <Text
                        numberOfLines={1}
                        style={isCurrentValue ? styles.activeTextStyle : styles.textStyle}
                    >
                        {item.label}
                    </Text>
                </TouchableOpacity>
            )
        })}

    </View>
  );
};

const useStyles = createUseStyles(getStyles);
