import axios from 'axios'

import { refundUnavailableMock } from '@__mocks__/mockRefund_indispo_Arbres_decision'
import { mockRefundIndispoQuestions_04 } from '@__mocks__/mockRefund_indispo_Questions'
import { API_URL } from '@config/index'
import { AvailableRefundState, RepaymentDemandState } from '@modules/refund/available-refund/types'
import { RefundUnavailabilityDecisionTreeType } from '@modules/refund/unavailable-refund/types'
import { mockUnavailableRefundDispositifs } from '@__mocks__/mockDispositif'
import { mockRefundUnavailableSignatureDate } from '@__mocks__/mockRefund_indispo_Signature_Date'

export async function fetchRefundQuestions(companyId: string): Promise<any> {
  return axios.get(`${API_URL}/api/v1/companies/${companyId}/plans?operationType=Repayments`)
  // todo :  remove mock
  // const res = {
  //   data: mockRefundIndispoQuestions_04
  // }
  // return Promise.resolve(res)
}

export async function fetchRepaymentDemand(
  companyId: string,
  planId: string,
  repaymentDemandData: RepaymentDemandState
): Promise<AvailableRefundState> {
  return axios.post(
    `${API_URL}/api/v1/demands/companies/${companyId}/plans/${planId}/available_repayments`,
    repaymentDemandData
  )
}

export async function fetchRefundRepartitionData(
  companyId: string,
  planId: string
): Promise<AvailableRefundState> {
  return axios.get(`${API_URL}/api/v1/companies/${companyId}/repayments/${planId}`)
}

export async function fetchRefundUnavailabilityDecisionTree(
  companyId: string
): Promise<RefundUnavailabilityDecisionTreeType> {
  // todo :  remove mock
  // return Promise.resolve(refundUnavailableMock)
  // TODO: UPDATATE THIS
  try {
    return await axios.get(`${API_URL}/api/v1/companies/${companyId}/repayments/earlyrelease`)
  } catch (e) {
    return Promise.reject(e)
  }
}

// unAvailable Refun Dispositifs

export async function fetchUnavailableRefundDispositifs(
  companyId: string,
  nodeId: string
): Promise<any> {
  // return Promise.resolve({ data: mockUnavailableRefundDispositifs })
  return axios.get(
    `${API_URL}/api/v1/companies/${companyId}/repayments/earlyrelease/${nodeId}/plans`
  )
}

export async function fetchRefundSignatureDate(
  companyId: string,
  nodeId: string
): Promise<{ data: RefundUnavailabilityDecisionTreeType }> {
  // todo :  remove mock
  // return Promise.resolve({ data: mockRefundUnavailableSignatureDate })
  // TODO: UPDATATE THIS
  return await axios.post(
    `${API_URL}/api/v1/companies/${companyId}/repayments/earlyrelease/${nodeId}`
  )
}
