import { useMemo } from "react";
import { StyleSheet } from "react-native";

import { useScreenSizes, useTheme } from "@ere-uilib/styles";

export const useStyles = () => {
  const theme = useTheme();
  const { isMobile } = useScreenSizes();

  const styles = useMemo(() => StyleSheet.create({
    containerStyle: {
      backgroundColor: theme.colors.basics.white,
      borderRadius: theme.metrics.borderRadius.xs,
      paddingHorizontal: isMobile ? theme.metrics.spacing.l : theme.metrics.spacing.s,
      paddingTop: theme.metrics.spacing.l,
      paddingBottom: theme.metrics.spacing.xxm
    },
    titleRow: {
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.metrics.spacing.xm
    },
    viewAllText: {
      color: theme.colors.basics.primary.c500,
      textDecorationLine: 'underline'
    },
    operationDetails: {
      marginBottom: theme.metrics.spacing.xxm
    },
    operationTitleText: {
      marginBottom: theme.metrics.spacing.xs
    },
    dateText: {
      color: theme.colors.text.disabled.c500
    },
    arrow: {
      marginLeft: theme.metrics.spacing.xm
    },
    dashboardContainerStyle: {
      height: !isMobile ? "100%" : undefined,
    },
    loader: {
      height: theme.metrics.spacing.m,
      marginVertical: theme.metrics.spacing.s,
    }
  }), [theme, isMobile]);

  return styles;
};