import { useMemo } from "react";
import { StyleSheet } from "react-native";

import { useScreenSizes, useTheme } from "@ere-uilib/styles";

export const useStyles = () => {
  const theme = useTheme();
  const { isMobile } = useScreenSizes();

  const styles = useMemo(() => StyleSheet.create({
    loader: {
      height: theme.metrics.spacing.m,
      marginVertical: theme.metrics.spacing.s,
    }
  }), [theme, isMobile]);

  return styles;
};