import React, { useMemo } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

import { IconEnum } from '@ere-uilib/enums';
import { useTheme } from '@ere-uilib/styles/theme';

import { SquareIconSizeEnum, SquareIcon } from '../SquareIcon/SquareIcon';

interface Styles {
  container?: StyleProp<ViewStyle>;
}

// eslint-disable-next-line no-shadow
export enum NotificationIconTypeEnum {
  SUCCESS = 'success',
  INFO = 'info',
  TIPS = 'tips',
  WARNING = 'warning',
  HELP = 'help',
}

interface Props {
  type: NotificationIconTypeEnum,
  customIconName?: IconEnum,
  size?: SquareIconSizeEnum,
  style?: Styles,
}

export function NotificationIcon({ 
  style, 
  type, 
  size = SquareIconSizeEnum.BIG,
  customIconName
}: Props) {
  const theme = useTheme();

  const { iconSize, iconName, backgroundColor, iconColor } = useMemo(() => {
    switch (type) {
    default:
    case NotificationIconTypeEnum.SUCCESS:
      return {
        iconSize: theme.metrics.iconSizes[size === SquareIconSizeEnum.BIG ? 'm' : 's'],
        iconName: IconEnum.CHECK,
        backgroundColor: theme.colors.notifications.success.c100,
        iconColor: theme.colors.notifications.success.c500
      };
    case NotificationIconTypeEnum.INFO:
      return {
        iconSize: theme.metrics.iconSizes[size === SquareIconSizeEnum.BIG ? 'l' : 'm'],
        iconName: IconEnum.INFO,
        backgroundColor: theme.colors.notifications.information.c100,
        iconColor: theme.colors.notifications.information.c500
      };
    case NotificationIconTypeEnum.TIPS:
      return {
        iconSize: theme.metrics.iconSizes[size === SquareIconSizeEnum.BIG ? 'xl' : 'm'],
        iconName: IconEnum.IDEA,
        backgroundColor: theme.colors.notifications.tips.c100,
        iconColor: theme.colors.notifications.tips.c500
      };
    case NotificationIconTypeEnum.WARNING:
      return {
        iconSize: theme.metrics.iconSizes[size === SquareIconSizeEnum.BIG ? 'l' : 'm'],
        iconName: IconEnum.WARNING,
        backgroundColor: theme.colors.notifications.warning.c100,
        iconColor: theme.colors.notifications.warning.c500
      };
    }
  }, [theme, type, size]);

  return (
    <View style={style?.container}>
      <SquareIcon
        backgroundColor={backgroundColor}
        iconColor={iconColor}
        iconName={customIconName||iconName}
        iconSize={iconSize}
        size={size}
      />
    </View>
  );
}
