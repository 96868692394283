import { connect } from 'react-redux';

import { initBankDataRequest, registerBankDataRequest } from '@modules/bank-details/actions/actionsCreators';
import { getSelectedAccountType, getSelectedPlanFamily, getUpdateFinishedStatus } from '@modules/bank-details/selectors';
import { AppState } from '@modules/reducers';

import { IbanFormComponent } from './IbanForm.component';

const mapStateToProps = (state: AppState) => ({
  selectedAccountType: getSelectedAccountType(state),
  selectedPlanFamily: getSelectedPlanFamily(state),
  updateStatus: getUpdateFinishedStatus(state),
});

const mapDispatchToProps = {
  onRegister: registerBankDataRequest,
  onGetInitData: initBankDataRequest,
};

export const IbanFormPage = connect(mapStateToProps, mapDispatchToProps)(IbanFormComponent);
