import { ActivableButtonSetStyles, ActivableButtonSetStylesContext, EnumSizeTypes } from './interfaces';

export const getStyles = (
  context?: ActivableButtonSetStylesContext,
  style?: ActivableButtonSetStyles
): ActivableButtonSetStyles => {
  const getButtonStyle = () => {
    if (context?.disabled) {
      return {
        backgroundColor: context?.theme.colors.buttons.disabled.c500,
      }
    }

    if (context?.isSelected) {
      return {
        backgroundColor: context?.theme.colors.buttons.classic.c500
      }
    }

    if (context?.hovered) {
      return {
        backgroundColor: context?.theme.colors.buttons.classicHover.c500
      }
    }

    return {
      borderColor: context?.theme.colors.basics.grey.c300,
      borderWidth: 1,
    }
  }

  const getLabelStyle = () => {
    if (context?.disabled) {
      return {
        color: context?.theme.colors.basics.grey.c400,
      }
    }

    if (context?.isSelected || context?.hovered) {
      return {
        color: context?.theme.colors.basics.white
      }
    }

    return {
      color: context?.theme.colors.basics.black,
    }
  }

  return {
    container: [
      {
        justifyContent: 'center',
      },
    ],
    buttonStyle: [
      {
        borderRadius: context?.theme.metrics.spacing.xs,
        padding: context?.size === EnumSizeTypes.large ? (
          context?.theme.metrics.spacing.xm
        ) : (
          context?.theme.metrics.spacing.s
        ),
      },
      getButtonStyle(),
      style?.buttonStyle,
    ],
    labelStyle: [
      {
        textAlign: 'center',
      },
      getLabelStyle(),
      style?.labelStyle,
    ],
  };
};
