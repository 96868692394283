import { Platform } from 'react-native';

import { Styles, StylesContext } from './types';

export const getStyles = (context?: StylesContext, style?: Styles): Styles => ({
  containerStyle: [
    {
      borderRadius: context?.theme.metrics.borderRadius.xs,
      backgroundColor: context?.theme.colors.basics.white,
      shadowOffset: {
        width: 0,
        height: 2
      },
      shadowColor:
        Platform.OS === 'android'
          ? context?.theme.colors.basics.black
          : context?.theme.colors.notifications.shadow,
      shadowOpacity: 1,
      shadowRadius: 10,
      elevation: 7,
      padding: context?.theme.metrics.spacing.m
    },
    style?.containerStyle
  ],
  contentStyle: [
    {
      flexDirection: 'row',
      alignItems: 'center'
    },
    style?.contentStyle
  ],
  iconContainerStyle: [
    {
      marginRight: context?.theme.metrics.spacing.m,
      width: 40,
      justifyContent: 'center',
      alignItems: 'center',
      height: 40,
      borderRadius: context?.theme.metrics.borderRadius.xs,
      backgroundColor: context?.iconContainerBgColor
    },
    context?.isLarge
      ? {
          width: 60,
          height: 60
        }
      : {
          width: 40,
          height: 40
        },
    style?.iconContainerStyle
  ],
  textContainerStyle: [
    {
      flexShrink: 1
    },
    style?.textContainerStyle
  ],
  titleStyle: [
    {
      marginBottom: context?.theme.metrics.spacing.xs
    }
  ]
});
