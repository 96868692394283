import React, { useCallback } from 'react';
import { SafeAreaView, View } from 'react-native';

import {
  AmountFieldModal360,
  BubbleContainer,
  ColumnsContainer,
  DashboardPageWrapperConnected,
  FundSheetModal,
  SimpleButton,
  Text,
  TotalSubHeader,
  useScreenSizes,
} from '@components/index';
import { IconEnum } from '@ere-uilib/enums';
import { useTranslation } from '@ere-uilib/translations';
import { ExpandableCard } from '@pages/Arbitration/ArbitrationOADRepartitionPage/ExpendableCard';

import { ArbitrationHeader, ArbitrationPieChartSection } from '../components';
import { useActiveClassLabel } from '../hooks/useActiveClassLabel';
import { HonorDeclarationModal } from './component';
import { useConfig } from './hooks/useConfig';
import { useController } from './hooks/useController';
import { useForm } from './hooks/useForm';
import { useModal } from './hooks/useModal';
import { ArbitrationOADRepartitionPageProps } from './interfaces';
import { useStyles } from './useStyles';
import { locator } from '@constants/locator';

export const ArbitrationOADRepartitionPage: React.FC<React.PropsWithChildren<ArbitrationOADRepartitionPageProps>> = ({
  navigation,
  repartitionLegend,
  simulatedRepartitionData,
  activeClassCardContentData,
  onSubmit,
  fetchRiskProfile,
}) => {
  const { isMobile, isTablet, isDesktop } = useScreenSizes();
  const { formatPercentNumber } = useTranslation();
  const { getActiveClassLabel } = useActiveClassLabel();

  const {
    formValues,
    remainingPercentage,
    formMaxValue,
    manualRepartitionData,
    initialModalAmount,
    isFormModalVisible,
    currentActiveClassName,
    currentSupportName,
    currentSupportValue,
    currentActiveClassSimulationValue,
    hideFormModal,
    handleInputPress,
    handeAmountFieldModalSubmit,
    handleResetInputPress
  } = useForm({
    activeClassCardContentData,
    simulatedRepartitionData
  });
  const { modalData } = useModal({
    currentSupportValue,
    currentActiveClassName,
    currentActiveClassSimulationValue,
    currentSupportName,
    initialModalAmount,
    formMaxValue
  });

  const { chartData, dictionary, stepperItems } = useConfig({
    activeClassCardContentData,
    simulatedRepartitionData,
    manualRepartitionData
  });

  const {
    displayFundSheetModal,
    fundSheetModalData,
    displaySubHeaderSticky,
    isVisible,
    honnorAccepted,
    handleCardContentTitlePress,
    handleFundSheetModalClose,
    handleLayout,
    handleScroll,
    handeAmountFieldModalCancel,
    handleGoBackPress,
    handleHonnorDeclarationModalPress,
    handleShowHonnorDeclarationModal,
    handleHonnorDeclarationModalClose,
    handleHonnorCheckBoxPress,
  } = useController({
    navigation,
    manualRepartitionData,
    hideFormModal,
    onSubmit,
    formValues,
    fetchRiskProfile,
  });
  const styles = useStyles({ displaySubHeaderSticky });

  const renderPieChartSection = useCallback(
    () => (
      <ArbitrationPieChartSection
        chartData={chartData}
        chartLegend={repartitionLegend}
      />
    ),
    [repartitionLegend, chartData]
  );

  const renderHeader = useCallback(
    () => (
      <>
        <ArbitrationHeader
          displayCloseButton
          helpPoint
          helpPointContentHtml={dictionary.headerHelpPointContentHtml}
          helpPointModalTitle={dictionary.headerHelpPointModalTitle}
          helpPointPosition="left"
          stepperItems={stepperItems}
          title={dictionary.headerTitle}
        />
        {!isDesktop && (
          <View
            onLayout={handleLayout}
            style={styles.pieChartContainerHeader}>
            {renderPieChartSection()}
          </View>
        )}
      </>
    ),
    [dictionary, stepperItems, isDesktop, styles, renderPieChartSection, handleLayout]
  );

  const renderMainContent = useCallback(
    () => (
      <View style={styles.mainContentContainer}>
        <Text
          style={styles.mainContentDescription}
          variant="t3"
          weight="light"
        >
          {dictionary.mainContentDescription}
        </Text>
        {Object.keys(activeClassCardContentData).map(uuid => {
          const handleInputItemPress = (supportUid: string) => handleInputPress(uuid, supportUid);
          const handleResetInputItemPress = (supportUid: string) =>
            handleResetInputPress(uuid, supportUid);
          const title = getActiveClassLabel(activeClassCardContentData[uuid].title);

          return (
            <ExpandableCard
              backgroundColor={activeClassCardContentData[uuid].backgroundColor}
              borderLeftColor={activeClassCardContentData[uuid].borderLeftColor}
              containerStyle={styles.expandableCardContainer}
              contentData={activeClassCardContentData[uuid].contentData}
              display={false}
              inputValues={formValues?.[uuid]}
              key={activeClassCardContentData[uuid].title}
              onCardTitlePress={handleCardContentTitlePress}
              onInputPress={handleInputItemPress}
              onResetInput={handleResetInputItemPress}
              title={title}
              testId={title}
            />
          );
        })}
      </View>
    ),
    [
      styles,
      dictionary,
      formValues,
      activeClassCardContentData,
      handleCardContentTitlePress,
      handleResetInputPress,
      handleInputPress,
      getActiveClassLabel,
    ]
  );

  const renderBottomActions = useCallback(
    () => (
      <View style={styles.bottomActions}>
        <View style={styles.bottomActionsButtonsContainer}>
          <SimpleButton
            design="outlined"
            leftIcon={IconEnum.CHEVRON_LEFT}
            onPress={handleGoBackPress}
          />
          <SimpleButton
            containerStyle={[
              styles.simpleButton,
              (isMobile || isTablet) && styles.simpleButtonFlex,
            ]}
            design="solid"
            disabled={!!remainingPercentage}
            onPress={handleShowHonnorDeclarationModal}
            size="small"
            title={dictionary.bottomButtonActionsConfirm}
            testId={locator._arbitration._confirm_and_continue}
          />
        </View>
      </View>
    ),
    [styles, handleGoBackPress, remainingPercentage, handleShowHonnorDeclarationModal, dictionary]
  );

  const renderRightDesktopColumn = useCallback(
    () => (
      <View>
        <BubbleContainer
          innerContainerStyle={styles.rightDesktopColumnInnerHeaderContainer}
          squareFront={styles.squareFront}>
          <TotalSubHeader
            amount={remainingPercentage}
            isPercent
            title={dictionary.subStikyHeaderBandeauTitle}
          />
        </BubbleContainer>
        <View style={styles.pieChartContainerRight}>{renderPieChartSection()}</View>
      </View>
    ),
    [styles, renderPieChartSection, dictionary, remainingPercentage]
  );

  const renderSubStikyHeader = useCallback(() => {
    if (!(isMobile || isTablet) || !displaySubHeaderSticky) return null;
    return (
      <>
        <View style={styles.subStikyHeaderTopContainer}>
          <Text
            style={styles.subStikyHeaderTitle}
            variant="t2"
            weight="light">
            {`${dictionary.subStikyHeaderBandeauTitle} ${formatPercentNumber({
              value: remainingPercentage, options: { minimumFractionDigits: 0 }
            })}`}
          </Text>
        </View>
        <View style={styles.subStikyHeaderBottomContainer}>
          <Text
            variant="t4"
            weight="regular">
            {dictionary.subStikyHeaderBandeauDescription}
          </Text>
          <View style={styles.subStikyHeaderResultContainer}>
            {manualRepartitionData.map((item, i) => (
              <View
                key={i}
                style={styles.subStikyHeaderResultItemContainer}>
                <View style={styles.subStikyHeaderSubResultItemContainer}>
                  <View
                    style={[
                      styles.subStikyHeaderSubResultItemPastille,
                      {
                        backgroundColor: item.color
                      }
                    ]}
                  />
                  <Text>{item.title}</Text>
                </View>
                <Text>{formatPercentNumber({ value: item.value * 100, options: { minimumFractionDigits: 0 } })}</Text>
              </View>
            ))}
          </View>
        </View>
      </>
    );
  }, [
    isMobile,
    isTablet,
    displaySubHeaderSticky,
    styles,
    dictionary,
    remainingPercentage,
    manualRepartitionData,
    formatPercentNumber
  ]);

  const renderDeclarationHonnorModal = useCallback(() => {
    return (
      <HonorDeclarationModal
        handleCloseButtonPress={handleHonnorDeclarationModalClose}
        handleHonnorCheckBoxPress={handleHonnorCheckBoxPress}
        handleValidationButtonPress={handleHonnorDeclarationModalPress}
        isDeclarationHonneurModalVisible={isVisible}
        isHonnorAccepted={honnorAccepted}
      />
    );
  }, [
    handleHonnorDeclarationModalClose,
    handleHonnorCheckBoxPress,
    handleHonnorDeclarationModalPress,
    isVisible,
    honnorAccepted
  ]);

  return (
    <SafeAreaView>
      {renderSubStikyHeader()}
      <>
        <DashboardPageWrapperConnected onScroll={handleScroll}>
          <View style={styles.container}>
            <ColumnsContainer
              renderHeader={renderHeader}
              renderRightDesktopColumn={renderRightDesktopColumn}
              renderStickyMobileBottom={renderBottomActions}>
              {renderMainContent()}
              {!isMobile && !isTablet && renderBottomActions()}
            </ColumnsContainer>
          </View>
          {fundSheetModalData && (
            <FundSheetModal
              id={fundSheetModalData?.id}
              modalIsVisible={displayFundSheetModal}
              onCloseModal={handleFundSheetModalClose}
              title={fundSheetModalData?.title}
            />
          )}
          <AmountFieldModal360
            data={modalData}
            isAmountPercentage
            onCancel={handeAmountFieldModalCancel}
            onValid={handeAmountFieldModalSubmit}
            placeholder={dictionary.modalFieldPlaceholder}
            visible={isFormModalVisible}
          />
          {renderDeclarationHonnorModal()}
        </DashboardPageWrapperConnected>
      </>
    </SafeAreaView>
  );
};
