import { DocumentsTabStyles, DocumentsTabStylesContext } from './interfaces';

export const getStyles = (
  context?: DocumentsTabStylesContext,
  style?: DocumentsTabStyles
): DocumentsTabStyles => ({
  globalContainer: [
    { backgroundColor: context?.theme.colors.basics.white },
    style?.globalContainer,
  ],
  containerStyle: [
    {
      paddingTop: context?.theme.metrics.spacing.s,
    },
    !(context?.isMobile || context?.isTablet) && {
      alignSelf: 'center',
      width: '100%',
      maxWidth: context?.theme.metrics.contentSizes.centeredContentMaxWidth,
    },
    style?.containerStyle,
  ],
  childrenStyle: [{}, style?.childrenStyle],
  titleAndDateView: [
    {
      flex: 1,
      paddingRight: context?.theme.metrics.spacing.s,
    },
    style?.globalContainer,
  ],
  documentsRow: [
    {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottomWidth: 1,
      borderBottomColor: context?.theme.colors.basics.grey.c100,
      paddingLeft: context?.theme.metrics.spacing.s,
      paddingRight: context?.theme.metrics.spacing.s,
      paddingVertical:
        context?.isMobile || context?.isTablet
          ? context?.theme.metrics.spacing.xm
          : context?.theme.metrics.spacing.m,
    },
    style?.documentsRow,
  ],
  dateTextStyle: [
    {
      color: context?.theme.colors.text.disabled.c500,
    },
    style?.icon,
  ],
  icon: [
    {
      width: 30,
      height: 32,
    },
    style?.icon,
  ],
});
