import React from "react";
import { View } from "react-native";

import {
  useTranslation,
  NotificationHard,
  Paragraph,
  Link,
} from "@components/index";
import { createUseStyles, useTheme } from '@ere-uilib/styles'
import { NotificationIconTypeEnum } from "@ere-uilib/atoms";

import { InformationPageComponentProps } from "./interfaces";
import { getStyles } from "./styles";

export const InformationPageComponent: React.FC<React.PropsWithChildren<InformationPageComponentProps>> = ({ onDowloadForm, onUpdatedUserData }) => {
  const { formatMessage } = useTranslation();
  const theme = useTheme();

  const style = useStyles(
    {
      theme,
    },
    {}
  );

  return (
    <View style={style.containerStyle}>
      <NotificationHard
        containerStyle={style.notificationContainer}
        title={formatMessage({
          id: "Remboursement_pageErreur_cardAlerteContent",
        })}
        type={NotificationIconTypeEnum.WARNING}
      >
        <Paragraph
variant="t3"
weight="light">
          {formatMessage({ id: "Remboursement_pageErreur_cardAlerteLink" })}
        </Paragraph>
      </NotificationHard>
      <NotificationHard
        containerStyle={style.notificationContainer}
        title={formatMessage({
          id: "Remboursement_pageErreur_cardAstuceContent",
        })}
        type={NotificationIconTypeEnum.TIPS}
      >
        <Link onPress={onDowloadForm}>
          {formatMessage({ id: "Remboursement_pageErreur_cardAstuceLink" })}
        </Link>
      </NotificationHard>
      <NotificationHard
        containerStyle={style.notificationContainer}
        title={formatMessage({ id: "Remboursement_pageErreur_cardInfoTitle" })}
        type={NotificationIconTypeEnum.TIPS}
      >
        <View>
          <Paragraph
variant="t3"
weight="light">
            {formatMessage({ id: "Remboursement_pageErreur_cardInfoContent" })}
          </Paragraph>
          <Link onPress={onUpdatedUserData}>
            {formatMessage({ id: "Remboursement_pageErreur_cardInfoLink" })}
          </Link>
        </View>
      </NotificationHard>
    </View>
  );
};

const useStyles = createUseStyles(getStyles);
