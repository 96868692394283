import { useMemo } from 'react'
import { StyleSheet } from 'react-native'

import { useTheme } from '@ere-uilib/styles'

export const useStyles = () => {
  const theme = useTheme()

  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          height: 90,
          borderBottomWidth: 1,
          borderBottomColor: theme.colors.borders.fields.c200,
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingVertical: theme.metrics.spacing.xm,
          paddingHorizontal: theme.metrics.spacing.s,
        },
        left: {
          flex: 1,
        },
        line1: {
          width: '40%',
          minWidth: 200,
          height: 14,
          marginBottom: theme.metrics.spacing.s,
        },
        line2: {
          width: '30%',
          minWidth: 100,
          height: 10,
        },
        right: {
          width: 50,
          height: '100%',
          borderRadius: theme.metrics.borderRadius.xs
        }
      }),
    [theme]
  )

  return styles
}
