import { Platform } from 'react-native';
import ReactNativeBlobUtil from 'react-native-blob-util';

type Nullable<T> = T | null | undefined;

type FileSizeUnit = 'KB' | 'MB';

export const convertFileSize = (fileSize: Nullable<number>, unit: FileSizeUnit): number => {
  if (!fileSize) return 0;
  const value = fileSize * (unit === 'KB' ? 1e-3 : 1e-6);
  return parseFloat(value.toFixed(2));
};
export const removeFileNameExtension = (fileName: Nullable<string>) =>
  fileName?.replace(/\.[^.$]+$/, '') || '';

export const fileToBase64 = (file: File): Promise<Nullable<string>> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
  });

export const uriToBase64 = (uri: Nullable<string>): Promise<string> =>
  new Promise(async (resolve, reject) => {
    if (!uri) return '';
    try {
      const path = Platform.select<string>({
        ios: uri?.replace('file://', ''),
        default: uri
      });
      const file = await ReactNativeBlobUtil.fs.readFile(decodeURI(path), 'base64');
      resolve(file);
    } catch {
      reject();
    }
  });

export const blobToBase64 = (response: any): Promise<string> =>
  new Promise(async (resolve, reject) => {
    try {
      const documentBlob = response?.data ?? '';
      const reader = new FileReader();
      reader.readAsDataURL(documentBlob);
      reader.onloadend = () => {
        const base64data = reader.result?.toString();
        resolve(`data:application/pdf;${base64data?.split(';')[1]}`);
      };
    } catch (err) {
      reject(err);
    }
  });

export const removeBase64Head = (base64: Nullable<string>) => base64?.split(',')[1] || '';
