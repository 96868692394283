import React from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';

import {
  useTheme,
  Modal,
  ModalFrame,
  Title,
  SimpleButton,
  useTranslation,
  IconEnum,
  FontIcon,
  SquareIcon,
  CheckBox,
  Text
} from '@ere-uilib/index';

import { useStyles } from '../useStyles';
import { locator } from '@constants/locator';

type HonnorDeclarationProps = {
  isDeclarationHonneurModalVisible: boolean;
  isHonnorAccepted: boolean;
  handleValidationButtonPress: () => void;
  handleCloseButtonPress: () => void;
  handleHonnorCheckBoxPress: () => void;
};
export const HonorDeclarationModal: React.FC<React.PropsWithChildren<HonnorDeclarationProps>> = ({
  isDeclarationHonneurModalVisible,
  isHonnorAccepted,
  handleValidationButtonPress,
  handleCloseButtonPress,
  handleHonnorCheckBoxPress
}) => {
  const { formatMessage } = useTranslation();

  const theme = useTheme();
  const styles = useStyles();

  return (
    <Modal visible={isDeclarationHonneurModalVisible}>
      <ModalFrame>
        <ScrollView
          contentContainerStyle={styles.honnorDeclarationContentWrapper}
        >
          <>
            <TouchableOpacity
              onPress={handleCloseButtonPress}
              style={styles.honnorModalCloseIcon}>
              <FontIcon
                color={theme.colors.text.primary.c500}
                name="fermer"
                size={theme.metrics.iconSizes.xxm}
              />
            </TouchableOpacity>
            <SquareIcon
              backgroundColor={theme.colors.basics.primary.c200}
              iconColor={theme.colors.basics.primary.c500}
              iconName={IconEnum.SHAKING_HAND}
              iconSize={theme.metrics.iconSizes.xl}
              style={{
                container: styles.handShakeIconContainerStyle
              }}
            />
            <Title
              style={styles.honnorModalTitleStyle}
              variant="t6">
              {formatMessage({ id: 'Arbitrage_popinDeclarationHonneur_title' })}
            </Title>
            <CheckBox
              containerStyle={styles.honnorModalCheckBoxStyle}
              onPress={handleHonnorCheckBoxPress}
              value={isHonnorAccepted}
              testId={locator._arbitration._consent_check_box}>
              <Text
                style={styles.honnorModalContentTextStyle}
                variant="t3"
                weight="light">
                {formatMessage({ id: 'Arbitrage_popinDeclarationHonneur_content' })}
              </Text>
            </CheckBox>
            <SimpleButton
              design="solid"
              containerStyle={styles.buttonContainer}
              disabled={!isHonnorAccepted}
              onPress={handleValidationButtonPress}
              title={formatMessage({ id: 'Arbitrage_popinDeclarationHonneur_bouton' })}
              testId={locator._arbitration._consent_accept}
            />
          </>
        </ScrollView>
      </ModalFrame>
    </Modal>
  );
};
