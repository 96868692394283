import { StackNavigationProp } from '@react-navigation/stack';
import React, { useCallback } from 'react';

import { RouteNames } from '@constants/navigation';
import { AppNavigatorInterface } from '@navigation/Interfaces';

import { VisiogoIdentifierFormPageContainer } from './VisiogoIdentifierForm.container';

interface Props {
  navigation: StackNavigationProp<AppNavigatorInterface>;
}

export function VisiogoIdentifierFormPage({ navigation }: Props) {
  const canGoBack = navigation.canGoBack();
  const handleClose = useCallback(() => {
    navigation.navigate(RouteNames.OidcLogoutCallbackRedirect);
  }, [navigation]);

  return (
    <VisiogoIdentifierFormPageContainer
      onClose={handleClose}
      onPressBackButton={canGoBack ? navigation.goBack : undefined}
    />
  );
}
