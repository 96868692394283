import React from 'react';
import { connect } from 'react-redux';

import {
  getProfitSharingIncentiveInitDataState,
  getProfitSharingIncentiveRepartitionState,
  getProfitSharingIncentiveAssigmentAmounts,
} from '@modules/profit-sharing-incentive/selectors';
import {
  ProfitSharingIncentiveActionsType
} from '@modules/profit-sharing-incentive/actions/profitSharingIncentiveActionsTypes';
import {
  sendProfitSharingIncentiveSubmitAllocationRequest
} from '@modules/profit-sharing-incentive/actions/profitSharingIncentiveActions';
import { AppState } from '@modules/reducers';
import { getSelectedCompany } from '@modules/dashboard/selectors';
import {
  getApplicationSettings,
} from '@modules/settings/selectors';

import {
  ProfitSharingIncentiveSynthesisPageComponent
}  from './ProfitSharingIncentiveSynthesisPage.component'

const mapStateToProps = (state: AppState) => {
  return {
    submitError: state.error[ProfitSharingIncentiveActionsType.SEND_PARTICIPATION_PROFITSHARING_SUBMIT_ALLOCATION_REQUEST],
    isSubmitLoading: state.isLoading[ProfitSharingIncentiveActionsType.SEND_PARTICIPATION_PROFITSHARING_SUBMIT_ALLOCATION_REQUEST],
    initData: getProfitSharingIncentiveInitDataState(state),
    repartition: getProfitSharingIncentiveRepartitionState(state),
    assigmentAmounts: getProfitSharingIncentiveAssigmentAmounts(state),
    selectedCompany: getSelectedCompany(state),
    applicationSettings: getApplicationSettings(state),
  };
};

const mapDispatchToProps = {
  onSubmitRequest: sendProfitSharingIncentiveSubmitAllocationRequest
};

export const ProfitSharingIncentiveSynthesisPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfitSharingIncentiveSynthesisPageComponent);
