import { BudgetInsightStyles, StylesContext } from './interface';

export const getStyles = (
  context?: StylesContext,
  style?: BudgetInsightStyles
): BudgetInsightStyles => ({
  containerStyle: [
    {
      padding: 0,
      marginBottom: context?.isMobile
        ? context?.theme.metrics.spacing.xm
        : context?.theme.metrics.spacing.xl,
    },
    style?.containerStyle
  ],

  contentStyle: [
    {
      flexDirection: context?.isMobile ? 'column' : 'row',
      alignItems: context?.isMobile ? 'stretch' : 'center',
      justifyContent: 'space-between'
    },
    style?.contentStyle
  ],
  contentTextStyle: [
    context?.isMobile && {
      marginVertical: context?.theme.metrics.spacing.xm
    },
    style?.contentTextStyle
  ],
  linkStyle: [
    {
      textDecorationLine: 'none'
    },
    !context?.isMobile && {
      minWidth: 300,
      textAlign: 'right'
    },
    style?.linkStyle
  ],
  iconLinkStyle: [
    {
      ...(!context?.isWeb && { lineHeight: context?.theme.metrics.spacing.l }),
      textAlign: 'right',
      fontWeight: 'bold',
      paddingRight: context?.theme.metrics.spacing.xxs,
      textAlignVertical: 'center'
    },
    style?.iconLinkStyle
  ],
  iconButton: [
    {
      flexDirection: 'row',
      paddingHorizontal: context?.theme.metrics.spacing.s,
      paddingVertical: 7,
      borderRadius: context?.theme.metrics.borderRadius.full,
      backgroundColor: context?.theme.colors.buttons.filter.c500,
      justifyContent: 'center',
      alignItems: 'center'
    },
    style?.iconButton
  ],
  iconButtonText: [
    {
      color: context?.theme.colors.basics.white,
      marginLeft: context?.theme.metrics.spacing.s
    },
    style?.iconButtonText
  ],
  iconLinkWrapper: [
    {
      position: 'absolute',
      right: context?.theme.metrics.spacing.xs
    }
  ],
  headerRow: [
    {
      flexDirection: 'row',
      display: 'flex'
    },
    style?.headerRow
  ],
  iconButonRow: [
    {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center'
    },
    style?.iconButonRow
  ],
  settingsIconButon: [
    {
      fontWeight: 'bold',
      paddingRight: context?.theme.metrics.spacing.s,
      color: context?.theme.colors.text.link.c500
    },
    style?.settingsIconButon
  ],
  IconButonRoWText: [
    {
      color: context?.theme.colors.text.link.c500
    }
  ]
});
