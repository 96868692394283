import { connect } from 'react-redux';

import { ForgetPasswordActionsType } from '@modules/forget-password/actions/forgetPasswordActionsTypes';
import { ForgetPasswordInitBeginRequest } from '@modules/forget-password/actions/forgetPasswordActions';
import { AppState } from '@modules/index';
import { getApplicationSettings } from '@modules/settings/selectors';

import { ForgetPasswordProcessBeginPage } from './ForgetPasswordProcessBegin.component';

const mapStateToProps = (state: AppState) => ({
  isApiLoading: state.isLoading[ForgetPasswordActionsType.FORGET_PASSWORD_INIT_BEGIN_REQUEST],
  appSettings: getApplicationSettings(state)
});

const mapDispatchToProps = {
  onForgetPasswordBeginProcess: ForgetPasswordInitBeginRequest,
};

export const ForgetPasswordProcessBegin =  connect(
  mapStateToProps,
  mapDispatchToProps,
)(ForgetPasswordProcessBeginPage);
