import { DashboardErrors, ToolsFlowTypeEnum } from '@modules/dashboard/types'
import { AppState } from '@modules/reducers'

export const getAccountsError = (state: AppState) => state.dashboard.error
export const getError = (errorKey: keyof DashboardErrors, state: AppState) =>
  state.dashboard[errorKey]
export const getAccountsSelector = (state: AppState) => state.dashboard.userAccounts
export const getAvailabilities = (state: AppState) => state.dashboard.availabilities
export const getAllocations = (state: AppState) => state.dashboard.allocations
export const getSavingsPlans = (state: AppState) => {
  if (state?.dashboard?.savingsPlans.length === 2) {
    const withoutConsolidedPlan = state?.dashboard?.savingsPlans.filter(
      plan => plan.planId !== 'Account'
    )
    return withoutConsolidedPlan
  }

  return state.dashboard.savingsPlans
}
export const getRecentOperations = (state: AppState) => state.dashboard?.recentOperations
export const getAllPlans = (state: AppState) => state.dashboard.allPlans
export const getIsAvailabilitiesError = (state: AppState) =>
  state.dashboard?.availabilitiesError?.code !== ''
export const getIsAllocationsError = (state: AppState) =>
  state.dashboard?.allocationsError?.code !== ''
export const getTools = (state: AppState, flow: ToolsFlowTypeEnum) => {
  return state.dashboard.tools[flow] || []
}
export const getOCDTop = (state: AppState) => state.dashboard.OCDTop
export const getOCDBottom = (state: AppState) => state.dashboard.OCDBottom
export const getSelectedCompany = (state: AppState) => state.dashboard?.selectedCompany
export const getUsedCompanyId = (state: AppState) => state.dashboard?.selectedCompany?.companyId
export const getBudgetInsightUrl = (state: AppState) => state.dashboard?.budgetInsightUrl
export const getBudgetInsightManagerUrl = (state: AppState) => state.dashboard?.budgetInsightManagerUrl;
export const getBudgetInsightAccounts = (state: AppState) => state.dashboard?.budgetInsightAccounts;
export const getContributionByCompany = (state: AppState) => state.dashboard.contributionByCompany;
export const getContributionByOperation = (state: AppState) => state.dashboard.contributionByOperation;
export const getCibledContributionContent = (state: AppState) => state.dashboard.contributionCibledContent;
export const getSsoUrlSelector = (state: AppState) => state.dashboard.ssoUrl
export const getSsoUrlErrorSelector = (state: AppState) => state.dashboard.ssoUrlError

