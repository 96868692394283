import { InformationPageComponentContext, InformationPageComponentStyles } from './interfaces';

export const getStyles = (
  context?: InformationPageComponentContext,
  style?: InformationPageComponentStyles,
): InformationPageComponentStyles => ({
  containerStyle: [
    {

    },
    style?.containerStyle,
  ],
  notificationContainer: [
    {
      flex: 1,
      marginVertical: context?.theme?.metrics.spacing.xm,
    },
    style?.notificationContainer,
  ],
});
