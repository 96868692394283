import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

export const useStyles = () => {
  const theme = useTheme();
  const styles = useMemo(
    () =>
      StyleSheet.create({
        inputContainer: {
          flexDirection: 'row',
          alignItems: 'center',
          marginVertical: theme.metrics.spacing.s,
        },
        textFieldStyle: {
          width: 80,
          height: 50
        },
        textFieldInputStyle: {
          width: 80,
          height: 50
        },
        caption: {
          flex: 1,
          marginLeft: theme.metrics.spacing.s,
          color: theme.colors.text.disabled.c500,
        },
      }),
    [theme]
  );

  return styles;
};
