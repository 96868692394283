import { StylesContext, CompareFundsModalStyles } from './interfaces';

export const getStyles = (
  context?: StylesContext,
  style?: CompareFundsModalStyles
): CompareFundsModalStyles => ({
  containerStyle: [
    {
      flex: 1,
    },
    style?.containerStyle,
  ],
  innerContainerPadding: [
    {
      paddingVertical: 0,
      paddingHorizontal: 0,
    },
    style?.innerContainerPadding,
  ],
  compareButtonStyle: [
    {
      width: '100%',
      alignItems: 'center',
      backgroundColor: 'white',
      paddingBottom: context?.theme.metrics.spacing.l,
    },
    style?.compareButtonStyle,
  ],

});
