import { useMemo } from 'react'
import { StyleSheet } from 'react-native'

import { useTheme } from '@ere-uilib/styles'

export const useStyles = ({ isMobile, isTablet }: { isMobile: boolean; isTablet: boolean }) => {
  const theme = useTheme()
  return useMemo(
    () =>
      StyleSheet.create({
        globalContainer: {
          backgroundColor: theme.colors.basics.white
        },
        containerStyle: {
          paddingTop: (theme.metrics.spacing.s || 10) - 2, // to be pixel perfect with overview title
          ...((isMobile || isTablet) && {
            paddingHorizontal: theme.metrics.spacing.s
          }),
          ...(!(isMobile || isTablet) && {
            alignSelf: 'center',
            width: '100%',
            maxWidth: theme.metrics.contentSizes.centeredContentMaxWidth
          })
        },
        labelContainer: {
          flex: 1,
          alignItems: 'flex-start'
        },
        tableContainer: {
          marginBottom: theme.metrics.spacing.xxm
        },
        tabItemContainer: {
          flexDirection: 'row',
          height: 45,
          alignItems: 'center',
          backgroundColor: theme.colors.basics.grey.c100,
          paddingVertical: theme.metrics.spacing.m,
          paddingHorizontal: theme.metrics.spacing.s,
          marginBottom: 1
        },
        dateContainer: {
          flexDirection: 'row'
        },
        noDataLabelStyle: {
          padding: theme.metrics.spacing.xm
        },
        labelNetValuation: {
          marginTop: theme.metrics.spacing.l,
          marginBottom: theme.metrics.spacing.m
        },
        headerCell: {
          backgroundColor: theme.colors.background.tableCellHeader.c500
        },
        headerCellRight: {
          backgroundColor: theme.colors.background.tableCellHeader.c500,
          justifyContent: 'flex-end',
          flexDirection: 'row'
        },
        headerCellText: {
          fontFamily: theme.fonts.fontFamily.regular
        },
        mobileRightTableCell: {
          justifyContent: 'flex-end',
          alignItems: 'center',
          alignContent: 'center',
          flexDirection: 'row',
          borderColor: theme.colors.basics.white,
          borderLeftWidth: 0,
          borderLeftColor: 'transparent'
        },
        fullDateText: {
          fontStyle: 'italic',
          color: theme.colors.text.secondary.c500
        },
        mobileLeftTableCell: {
          borderColor: theme.colors.basics.white,
          borderRightWidth: 0,
          borderRightColor: 'transparent'
        },
        barChartContainerStyle: {
          maxWidth: 730
        },
        chartContainer: {
          borderBottomColor: theme.colors.basics.grey.c200,
          borderBottomWidth: 1,
          paddingBottom: 25
        }
      }),
    [theme, isMobile, isTablet]
  )
}

export type UseStylesType = ReturnType<typeof useStyles>
