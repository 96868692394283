import React from 'react';
import { TouchableOpacity } from 'react-native';

import { FontIcon } from '@ere-uilib/atoms';
import { IconEnum } from '@ere-uilib/enums';
import { metrics, colors } from '@ere-uilib/styles/theme/themeFolder';

interface Props {
  onPress?: () => void
  iconSize?: number
  iconColor?: string
  testId?: string
}
export const Pen : React.FC <React.PropsWithChildren<Props>> =  ({
  onPress,
  iconSize = metrics.iconSizes.xxm,
  iconColor = colors.basics.primary.c500,
  testId
}: Props) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <FontIcon
        color={iconColor}
        name={IconEnum.EDIT}
        size={iconSize}
        testID={testId}/>
    </TouchableOpacity>
  );
};
