import React, { useCallback, useState } from 'react';
import { View } from 'react-native';

import { useTranslation, Text, SimpleButton, IconEnum } from '@ere-uilib/index';
import { documentType, RetirementPlanDocumentType } from '@modules/retirement/types';

import { FormTypeConditionEnum, FormTypeEnum } from '../../interfaces';
import { useController } from '../../useController';
import { useStyles } from '../../useStyles';

interface Props {
  documents: documentType[];
  onSubmit: (documentType: string, documentTitle: string) => void;
  retirementPlanDocument: RetirementPlanDocumentType;
  isLoading: boolean;
  setIsEsPacteFile: (isPacte: boolean) => void
  setIsLinkPressed: (isLink: boolean) => void
}

export const RenderDocs: React.FC<Props> = props => {
  const { documents, onSubmit, retirementPlanDocument, isLoading, setIsEsPacteFile, setIsLinkPressed } = props;
  const styles = useStyles();
  const { formatMessage } = useTranslation();

  const {
    sortOrderBy,
  } = useController(retirementPlanDocument);
  const [orderDocs, setOrderDocs] = useState<documentType[]>(sortOrderBy(documents));
  const [docToLoad, setDocToLoad] = useState(-1);
  const handleSubmit = useCallback((doc, docTitle, selectedIndex) => {
    onSubmit(doc.documentType, docTitle);
    setIsEsPacteFile(true);
    setIsLinkPressed(false);
    setDocToLoad(selectedIndex);
    setOrderDocs(
      orderDocs.map((item, index) => {

        if (index === selectedIndex) {

          return { ...item, isLoading: true };
        }
        else {
          return { ...item, isLoading: false };
        }
      })
    );
  },
    [onSubmit, setIsEsPacteFile, setIsLinkPressed, orderDocs, docToLoad,]
  );

  return (
    <View>
      {
        orderDocs.map((doc, index) => {
          const docTitle = formatMessage({ id: doc.labelKey })
          if (doc.documentFormat === FormTypeEnum.Document)
            return;
          return (
            <>
              {
                doc.condition === FormTypeConditionEnum.PensionAndAmount && (
                  <Text
                    style={styles.warningTxt}
                    variant="t4"
                    weight="light"
                  >
                    {formatMessage({ id: 'RetirementJourneyRecoverHisSavingTextPension100euros' })}
                  </Text>
                )
              }
              <SimpleButton
                containerStyle={styles.buttonStyle}
                design='outlined'
                key={index}
                leftIcon={IconEnum.PDF}
                loading={doc.isLoading ? isLoading : false}
                onPress={e => {

                  handleSubmit(doc, docTitle, index)
                }}
                size='small'
                title={docTitle}
              />
            </>
          )
        })
      }
    </View>
  );
};
