import { getParagraphFontStyle } from '@ere-uilib/styles';
import { ParagraphSizeTypes } from '@ere-uilib/styles/theme/themeFolder';

import { Context, Style } from './interfaces';

export const getStyles = (
  context?: Context,
  style?: Style,
): Style => ({
  textStyle: [
    context?.variant && context?.weight && {
      ...getParagraphFontStyle({
        weight: context?.weight,
        variant: context?.variant as keyof ParagraphSizeTypes,
        theme: context?.theme,
        numberOfLines: context?.numberOfLines,
      }),
    },
    {
      color: context?.theme.colors.text.link.c500,
      textDecorationLine: 'underline',
    },
    style?.textStyle,
  ],
});
