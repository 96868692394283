import { ResponseType } from '@modules/common/types'
import * as actions from '@modules/refund/unavailable-refund/actions/refundActionsTypes'

import {
  RefundUnavailabilityDecisionTreeResponseType,
  UnavailableRefundDispositifState,
  UnavailableRefundErrorState
} from '../types'

export function updateRefundUnavailableQuestionsRequest(
  response: ResponseType,
  index: number,
  canNotBeAsked: boolean,
  data?: any
): actions.UpdateRefundUnavailableQuestionsRequestAction {
  return {
    type: actions.UnavailableRefundActionsType.UPDATE_UNAVAILABLE_REFUND_QUESTIONS_REQUEST,
    response,
    index,
    canNotBeAsked,
    data
  }
}

export function updateRefundUnavailableQuestionsSuccess(
  unavailableRefundQuestions: any
): actions.UpdateRefundUnavailableQuestionsSuccessAction {
  return {
    type: actions.UnavailableRefundActionsType.UPDATE_UNAVAILABLE_REFUND_QUESTIONS_SUCCESS,
    unavailableRefundQuestions
  }
}

export function updateRefundUnavailableQuestionsFailure(
  error: UnavailableRefundErrorState
): actions.UpdateRefundUnavailableQuestionsFailureAction {
  return {
    type: actions.UnavailableRefundActionsType.UPDATE_UNAVAILABLE_REFUND_QUESTIONS_FAILURE,
    error
  }
}

export function getRefundUnavailabilityDecisionTreeRequest(): actions.GetRefundUnavailabilityDecisionTreeRequestAction {
  return {
    type: actions.UnavailableRefundActionsType.GET_REFUND_UNAVAILABILITY_DECISION_TREE_REQUEST
  }
}

export function getRefundUnavailabilityDecisionTreeSuccess(
  unavailabilityDecisionTreeResponse: RefundUnavailabilityDecisionTreeResponseType
): actions.GetRefundUnavailabilityDecisionTreeSuccessAction {
  return {
    type: actions.UnavailableRefundActionsType.GET_REFUND_UNAVAILABILITY_DECISION_TREE_SUCCESS,
    unavailabilityDecisionTreeResponse
  }
}

export function getRefundUnavailabilityDecisionTreeFailure(
  error: UnavailableRefundErrorState
): actions.GetRefundUnavailabilityDecisionTreeFailureAction {
  return {
    type: actions.UnavailableRefundActionsType.GET_REFUND_UNAVAILABILITY_DECISION_TREE_FAILURE,
    error
  }
}

// Unavailable Refund Dispositifs

export function getUnavailableRefundDispositifsRequest(): actions.GetUnavailableRefundDispositifsRequestAction {
  return {
    type: actions.UnavailableRefundActionsType.GET_UNAVAILABLE_REFUND_DISPOSITIFS_REQUEST
  }
}

export function getUnavailableRefundDispositifsSuccess(
  unavailableRefundDispositifs: UnavailableRefundDispositifState,
  motifId: string
): actions.GetUnavailableRefundDispositifsSuccessAction {
  return {
    type: actions.UnavailableRefundActionsType.GET_UNAVAILABLE_REFUND_DISPOSITIFS_SUCCESSS,
    unavailableRefundDispositifs,
    motifId
  }
}

export function getUnavailableRefundDispositifsFailure(
  error: UnavailableRefundErrorState
): actions.GetUnavailableRefundDispositifsFailureAction {
  return {
    type: actions.UnavailableRefundActionsType.GET_UNAVAILABLE_REFUND_DISPOSITIFS_FAILURE,
    error
  }
}

// Unavailable Refund Signature Date Actions
export function getRefundUnavailabilitySignatureDateRequest(): actions.GetRefundUnavailabilitySignatureDateRequestAction {
  return {
    type: actions.UnavailableRefundActionsType.GET_UNAVAILABLE_REFUND_SIGNATURE_DATE_REQUEST
  }
}

export function getRefundUnavailabilitySignatureDateSuccess(
  unavailabilitySignatureDateResponse: any
): actions.GetRefundUnavailabilitySignatureDateSuccessAction {
  return {
    type: actions.UnavailableRefundActionsType.GET_UNAVAILABLE_REFUND_SIGNATURE_DATE_SUCCESS,
    unavailabilitySignatureDateResponse
  }
}

export function getRefundUnavailabilitySignatureDateFailure(
  error: UnavailableRefundErrorState
): actions.GetRefundUnavailabilitySignatureDateFailureAction {
  return {
    type: actions.UnavailableRefundActionsType.GET_UNAVAILABLE_REFUND_SIGNATURE_DATE_FAILURE,
    error
  }
}
