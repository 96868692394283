import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

export const useStyles = () => {
  const theme = useTheme();
  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          flexDirection: 'column',
          flex: 1
        },
        cardContainer: {
          marginTop: theme.metrics.spacing.xm
        },
        rowContainer: {
          marginVertical: theme.metrics.spacing.m
        },
        rightRadioButton: {
          marginRight: theme.metrics.spacing.l
        },
        rateSelect: {
          width: '100%'
        },
        selectTitle: {
          fontFamily: theme.fonts.fontFamily.regular,
          fontSize: theme.fonts.fontSize.title.t6
        },
        selectPlaceholder: {
          color: theme.colors.text.disabled.c500
        },
        selectedItem: {
          borderColor: theme.colors.basics.black
        }
      }),
    [theme]
  );

  return styles;
};
