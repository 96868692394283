import { createStackNavigator } from '@react-navigation/stack';
import React from 'react'; //

import {
  ProfileStackNavigatorInterface,
  ProfileStackNavigatorRouteNames
} from '@navigation/Interfaces/ProfileStackInterface';
import { useDefaultScreensOptions } from '@navigation/useDefaultScreensOptions';
import { LegalInfoPage, EditorialPage } from '@pages/Common';
import {
  ProfilePage,
  ProfileEditEmailPage,
  ProfileEditPhonePage,
  EditProfileInformationsPage,
  EditPasswordPage,
  ContactPreferencesConnectedPage,
  BusinessContactPreferencesConnectedPage,
  ConnexionBiometriquePage,
  HelpContactsPage
} from '@pages/Dashboard';

const ProfilStack = createStackNavigator<ProfileStackNavigatorInterface>();

export const ProfilStackNavigator = () => {
  const defaultScreensOptions = useDefaultScreensOptions();
  return (
    <ProfilStack.Navigator
      initialRouteName={ProfileStackNavigatorRouteNames.Profile}
      screenOptions={{
        gestureEnabled: false,
        headerShown: false,
        cardStyle: {
          backgroundColor: 'transparent'
        }
      }}>
      <ProfilStack.Screen
        component={ProfilePage}
        name={ProfileStackNavigatorRouteNames.Profile}
        options={defaultScreensOptions.Profile}
      />
      <ProfilStack.Screen
        component={ProfileEditEmailPage}
        name={ProfileStackNavigatorRouteNames.ProfileEditEmail}
        options={defaultScreensOptions.ProfileEditMail}
      />
      <ProfilStack.Screen
        component={ProfileEditPhonePage}
        name={ProfileStackNavigatorRouteNames.ProfileEditPhone}
        options={defaultScreensOptions.ProfileEditPhone}
      />
      <ProfilStack.Screen
        component={LegalInfoPage}
        name={ProfileStackNavigatorRouteNames.ProfileLegalInfo}
        options={defaultScreensOptions.LegalInfoPage}
      />
      <ProfilStack.Screen
        component={EditorialPage}
        name={ProfileStackNavigatorRouteNames.Editorial}
        options={defaultScreensOptions.EditorialPage}
      />
      <ProfilStack.Screen
        component={EditProfileInformationsPage}
        name={ProfileStackNavigatorRouteNames.EditProfileInformations}
        options={defaultScreensOptions.EditProfileInformationsPage}
      />
      <ProfilStack.Screen
        component={EditPasswordPage}
        name={ProfileStackNavigatorRouteNames.EditPassword}
        options={defaultScreensOptions.EditPasswordPage}
      />
      <ProfilStack.Screen
        component={ContactPreferencesConnectedPage}
        name={ProfileStackNavigatorRouteNames.ContactPreferences}
        options={defaultScreensOptions.ContactPreferencesPage}
      />
      <ProfilStack.Screen
        component={BusinessContactPreferencesConnectedPage}
        name={ProfileStackNavigatorRouteNames.BusinessContactPreferences}
        options={defaultScreensOptions.BusinessContactPreferencesPage}
      />
      <ProfilStack.Screen
        component={ConnexionBiometriquePage}
        name={ProfileStackNavigatorRouteNames.ConnexionBiometrique}
        options={defaultScreensOptions.ConnexionBiometrique}
      />
    </ProfilStack.Navigator>
  );
};
