import { ItemState } from "@ere-uilib/molecules/inputs/Select/types";
import { useTranslation } from "@ere-uilib/translations";
import {
  AgeRangeType,
  ControlDataFormatterType,
  ControlDataInputTypeEnum,
  ControlDataGroupedRiskProfileState,
  ControlDataManagementRiskProfile
} from "@modules/control-data/types";

export const controlDataItemFormatter = ({ itemType, minAge, maxAge, riskLabels }: ControlDataFormatterType): ItemState[] | undefined => {
  const { formatMessage } = useTranslation();

  let itemLength = 0;
  if (itemType === ControlDataInputTypeEnum.horizon && minAge && maxAge) {
    itemLength = maxAge - minAge;

    return Array.from({ length: itemLength + 1 }, (_, k) => ({
      value: `${k + minAge}`,
      label: `${k + minAge} ${formatMessage({ id: 'DrivingDataJourneyESAge' })}`
    }));
  }
  if (itemType === ControlDataInputTypeEnum.riskProfile && riskLabels) {
    itemLength = riskLabels.length;

    return Array.from({ length: itemLength + 1 }, (_, k) => ({
      value: `${k + itemLength}`,
      label: `${riskLabels[k]}`
    }))
      .filter(element => element?.label !== 'undefined');
  }

};

export const adaptedRiskProfilesData = (data: ControlDataManagementRiskProfile[]) => {
  const result = data.reduce<ControlDataGroupedRiskProfileState>((acc, profile) => {
    const { label, ageMin, ageMax } = profile;

    if (!acc.labels.includes(label)) {
      acc.labels.push(label);
    }
    acc.ageRange.push({
      ageMax,
      ageMin
    })

    return acc;
  }, { labels: [], ageRange: [] as AgeRangeType[] });

  return result;
};


export const enumToStringArray = <T extends string>(enumObject: { [key: string]: T }): T[] => {
  return Object.values(enumObject);
}
