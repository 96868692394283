export enum SavingsNavigatorRouteNames {
  Savings = 'Savings',
  VVPList = 'VVPList',
  funds = 'funds',
}

export type SavingsNavigatorInterface = {
  [SavingsNavigatorRouteNames.Savings]: undefined
  [SavingsNavigatorRouteNames.funds]: undefined
  [SavingsNavigatorRouteNames.VVPList]: undefined
}