import * as actions from './fundsActionsTypes';
import { FundsActionsType } from './fundsActionsTypes';
import {
  OverviewState,
  FundsErrorState,
  FundRepartitionSupportsState,
  FundsDocumentsState,
  FundsPerformanceState
} from '../types';

export function getFundRequest(
  isinCode: string,
): actions.GetFundRequestAction {
  return {
    type: FundsActionsType.GET_FUND_REQUEST,
    isinCode,
  };
}

export function getFundOverviewRequest(
  isinCode: string,
): actions.GetFundOverviewRequestAction {
  return {
    type: FundsActionsType.GET_FUND_OVERVIEW_REQUEST,
    isinCode,
  };
}

export function getFundOverviewSuccess(
  fundOverview: OverviewState | undefined,
): actions.GetFundOverviewSuccessAction {
  return {
    type: FundsActionsType.GET_FUND_OVERVIEW_SUCCESS,
    fundOverview,
  };
}

export function getFundOverviewFailure(
  error: FundsErrorState,
): actions.GetFundOverviewFailureAction {
  return {
    type: FundsActionsType.GET_FUND_OVERVIEW_FAILURE,
    error,
  };
}

// repartition support

export function getFundRepartitionSupportsRequest(
  isinCode: string,
): actions.GetFundRepartitionSupportsRequestAction {
  return {
    type: FundsActionsType.GET_FUND_REPARTITION_SUPPORTS_REQUEST,
    isinCode,
  };
}

export function getFundRepartitionSupportsSuccess(
  fundRepartitionSupport: FundRepartitionSupportsState,
): actions.GetFundRepartitionSupportsSuccessAction {
  return {
    type: FundsActionsType.GET_FUND_REPARTITION_SUPPORTS_SUCCESS,
    fundRepartitionSupport,
  };
}

export function getFundRepartitionSupportsFailure(
  error: FundsErrorState,
): actions.GetFundRepartitionSupportsFailureAction {
  return {
    type: FundsActionsType.GET_FUND_REPARTITION_SUPPORTS_FAILURE,
    error,
  };
}

// performance

export function getFundPerformanceRequest(
  isinCode: string,
): actions.GetFundPerformanceRequestAction {
  return {
    type: FundsActionsType.GET_FUND_PERFORMANCE_REQUEST,
    isinCode,
  };
}

export function getFundPerformanceSuccess(
  fundPerformance: FundsPerformanceState | undefined,
): actions.GetFundPerformanceSuccessAction {
  return {
    type: FundsActionsType.GET_FUND_PERFORMANCE_SUCCESS,
    fundPerformance,
  };
}

export function getFundPerformanceFailure(
  error: FundsErrorState,
): actions.GetFundPerformanceFailureAction {
  return {
    type: FundsActionsType.GET_FUND_PERFORMANCE_FAILURE,
    error,
  };
}

// Func Documents List
export function getFundsDocumentsListRequest(
  isinCode: string,
): actions.GetFundsDocumentsListRequestAction {
  return {
    type: FundsActionsType.GET_FUNDS_DOCUMENTS_LIST_REQUEST,
    isinCode,
  };
}

export function getFundsDocumentsListSuccess(
  fundsDocumentsList: FundsDocumentsState[],
  fundDocTypeOrderList?: string[]
): actions.GetFundsDocumentsListSuccessAction {
  return {
    type: FundsActionsType.GET_FUNDS_DOCUMENTS_LIST_SUCCESS,
    fundsDocumentsList,
    fundDocTypeOrderList
  };
}

/// Multi Fund
export function GetMultiFundsRequest(
  isinCodes: string[],
): actions.GetMultiFundsRequestAction {
  return {
    type: FundsActionsType.GET_MULTI_FUNDS_REQUEST,
    isinCodes,
  };
}

export function GetMultiFundsSuccess(
  multiFundsOverviews: OverviewState[],
): actions.GetMultiFundsSuccessAction {
  return {
    type: FundsActionsType.GET_MULTI_FUNDS_SUCCESS,
  };
}

export function GetMultiFundsFailure(
  error: FundsErrorState,
): actions.GetMultiFundsFailureAction {
  return {
    type: FundsActionsType.GET_MULTI_FUNDS_FAILURE,
    error,
  };
}

/// Multi Func overview
export function GetMultiFundsOverviewsRequest(
  isinCodes: string[],
): actions.GetMultiFundsOverviewsRequestAction {
  return {
    type: FundsActionsType.GET_MULTI_FUNDS_OVERVIEWS_REQUEST,
    isinCodes,
  };
}

export function GetMultiFundsOverviewsSuccess(
  multiFundsOverviews: OverviewState[],
): actions.GetMultiFundsOverviewsSuccessAction {
  return {
    type: FundsActionsType.GET_MULTI_FUNDS_OVERVIEWS_SUCCESS,
    multiFundsOverviews,
  };
}

export function GetMultiFundsOverviewsFailure(
  error: FundsErrorState,
): actions.GetMultiFundsOverviewsFailureAction {
  return {
    type: FundsActionsType.GET_MULTI_FUNDS_OVERVIEWS_FAILURE,
    error,
  };
}

/// Multi Func Performance
export function GetMultiFundsPerformancesRequest(
  isinCodes: string[],
): actions.GetMultiFundsPerformancesRequestAction {
  return {
    type: FundsActionsType.GET_MULTI_FUNDS_PERFORMANCES_REQUEST,
    isinCodes,
  };
}

export function GetMultiFundsPerformancesSuccess(
  multiFundsPerformances: FundsPerformanceState[],
): actions.GetMultiFundsPerformancesSuccessAction {
  return {
    type: FundsActionsType.GET_MULTI_FUNDS_PERFORMANCES_SUCCESS,
    multiFundsPerformances,
  };
}

export function GetMultiFundsPerformancesFailure(
  error: FundsErrorState,
): actions.GetMultiFundsPerformancesFailureAction {
  return {
    type: FundsActionsType.GET_MULTI_FUNDS_PERFORMANCES_FAILURE,
    error,
  };
}

/// Multi Func Performance
export function GetMultiFundsRepartitionSupportsRequest(
  isinCodes: string[],
): actions.GetMultiFundsRepartitionSupportsRequestAction {
  return {
    type: FundsActionsType.GET_MULTI_FUNDS_REPARTITION_SUPPORTS_REQUEST,
    isinCodes,
  };
}

export function GetMultiFundsRepartitionSupportsSuccess(
  multiFundsRepartitionSupports: FundRepartitionSupportsState[],
): actions.GetMultiFundsRepartitionSupportsSuccessAction {
  return {
    type: FundsActionsType.GET_MULTI_FUNDS_REPARTITION_SUPPORTS_SUCCESS,
    multiFundsRepartitionSupports,
  };
}

export function GetMultiFundsRepartitionSupportsFailure(
  error: FundsErrorState,
): actions.GetMultiFundsRepartitionSupportsFailureAction {
  return {
    type: FundsActionsType.GET_MULTI_FUNDS_REPARTITION_SUPPORTS_FAILURE,
    error,
  };
}

export function SetCompareMode(
  compareMode: boolean,
): actions.SetCompareModeAction {
  return {
    type: FundsActionsType.SET_COMPARE_MODE,
    compareMode,
  };
}
