import {Context, Styles} from './interfaces';

export const getStyles = (context?: Context, style?: Styles): Styles => ({
  cardStyle: [
    {
      backgroundColor: context?.theme.colors.basics.white
    },
    style?.cardStyle
  ],
  innerView: [
    {
      height: 207,
      width: 355,
      alignSelf: 'center'
    },
    style?.innerView
  ],
  innerCardStyle: [
    context?.isMobile
      ? {
        minWidth: '100%',
      }
      : {
        maxWidth: '60%',
        alignSelf: 'center',
        alignItems: 'center'
      },
    
    style?.innerCardStyle
  ],
  containerStyle: [
    {
      backgroundColor: context?.theme.colors.basics.white,
      flexDirection: 'column',
      
    },
    style?.containerStyle
  ],
  contentStyle: [
    {
      marginVertical: context?.theme.metrics.spacing.l,
      textAlign: 'center'
    },
    style?.contentStyle
  ],
  titleStyle: [
    {
      marginTop: context?.theme.metrics.spacing.xxl,
      textAlign: 'center'
    },
    style?.titleStyle
  ]
});
