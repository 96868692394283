import { useNavigation } from '@react-navigation/native';
import { createContext, FC, useCallback, useState, PropsWithChildren } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RouteNames } from '@constants/navigation';
import { useTheme, useScreenSizes, ThemeType } from '@ere-uilib/styles';
import { DispositifState } from '@modules/common/types';
import { setSelectedDispositif } from '@modules/installment/actions/installmentActions';
import { getInstallmentElligibleDispositif } from '@modules/installment/selectors';

interface IntallementPlanChoiceContextType {
  dispositifs: DispositifState[];
  theme: ThemeType;
  isModalVisble: boolean;

  handleSelectDispositif: (index: number) => void;
  handleValidateInformativePopin: () => void;
}

export const IntallementPlanChoiceContext = createContext<IntallementPlanChoiceContextType | null>(
  null
);

const IntallementPlanChoiceContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const navigation = useNavigation();
  const dispositifs = useSelector(getInstallmentElligibleDispositif);
  const dispatch = useDispatch();

  const onSelectDispositif = useCallback(
    (dispositif: DispositifState[]) => {
      dispatch(setSelectedDispositif(dispositif));
    },
    [dispatch]
  );
  const theme = useTheme();
  const { isMobile, isTablet } = useScreenSizes();

  const [isModalVisble, setModalVisible] = useState(false);
  const [selectedDispositifIndex, setSelectedDispositifIndex] = useState<number>();

  const showModal = useCallback(() => setModalVisible(true), []);
  const closeModal = useCallback(() => setModalVisible(false), []);

  const handleSelectDispositif = useCallback(
    (index: number) => {
      setSelectedDispositifIndex(index);
      showModal();
    },
    [showModal]
  );

  const handleValidateInformativePopin = useCallback(() => {
    if (selectedDispositifIndex !== undefined) {
      onSelectDispositif([dispositifs[selectedDispositifIndex]]);
    }

    closeModal();
    navigation.navigate(RouteNames.InstallmentStack, {
      screen: RouteNames.InstallmentsRepartition
    });
  }, [selectedDispositifIndex, closeModal, onSelectDispositif, dispositifs, navigation]);

  const value = {
    dispositifs: dispositifs,
    theme,
    isMobile,
    isTablet,
    isModalVisble,
 
    handleSelectDispositif,
    handleValidateInformativePopin
  };

  return (
    <IntallementPlanChoiceContext.Provider value={value}>
      {children}
    </IntallementPlanChoiceContext.Provider>
  );
};

export default IntallementPlanChoiceContextProvider;
