import { useMemo } from 'react'
import { Platform, StyleSheet } from 'react-native'

import { useTheme } from '@ere-uilib/styles'

interface Props {
  isOpen?: boolean
  disabled?: boolean
}

export const useStyles = (props: Props | void) => {
  const { isOpen, disabled } = props || {}
  const theme = useTheme()
  const disabledBackgroundColor = disabled ? { backgroundColor: theme.colors.basics.grey.c200 } : {}
  const disabledColor = disabled ? { color: theme.colors.basics.grey.c400 } : {}
  const styles = useMemo(
    () =>
      StyleSheet.create({
        containerStyle: {
          zIndex: 9
        },
        placeholder: {
          color: theme.colors.basics.grey.c500,
          position: 'absolute',
          left: theme.metrics.spacing.m,
          top: theme.metrics.spacing.s,
          zIndex: 2,
          overflow: 'hidden'
        },
        selectedItemStyle: {
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderRadius: theme.metrics.spacing.xs,
          borderBottomLeftRadius: isOpen && Platform.OS === 'web' ? 0 : theme.metrics.spacing.xs,
          borderBottomRightRadius: isOpen && Platform.OS === 'web' ? 0 : theme.metrics.spacing.xs,
          borderBottomWidth: isOpen && Platform.OS === 'web' ? 0 : 1,
          height: theme.metrics.spacing.xbig,
          paddingHorizontal: theme.metrics.spacing.m,
          backgroundColor: theme.colors.basics.white,
          borderWidth: 1,
          borderColor: theme.colors.basics.grey.c300
        },
        touchableOpacityStyle: {
          ...disabledBackgroundColor,
          padding: theme.metrics.spacing.xs,
          overflow: 'hidden'
        },
        contentTextStyle: {
          ...disabledColor,
          overflow: 'hidden'
        },
        titleStyle: {
          color: theme.colors.basics.black,
          fontFamily: theme.fonts.fontFamily.light,
          marginRight: theme.metrics.spacing.xs
        },
        leftIconStyle: {
          marginRight: theme.metrics.spacing.s
        },
        errorText: {
          marginTop: theme.metrics.spacing.xs,
          color: theme.colors.operationState.failed.c500,
        }
      }),
    [isOpen, disabledBackgroundColor, disabledColor, theme]
  )

  return styles
}
