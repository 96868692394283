import { useMemo } from 'react'
import { StyleSheet } from 'react-native'

import { useTheme } from '@ere-uilib/styles'

export const useStyles = () => {
  const theme = useTheme()

  const styles = useMemo(
    () =>
      StyleSheet.create({
        dateContainer: {
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: theme.metrics.spacing.xbig,
          paddingHorizontal: theme.metrics.spacing.m,
          paddingVertical: theme.metrics.spacing.s,
          borderWidth: 1,
          borderColor: theme.colors.basics.grey.c200,
          borderRadius: theme.metrics.spacing.s,
          marginBottom: theme.metrics.spacing.xm
        },
        disabledContainer: {
          borderColor: theme.colors.basics.grey.c300,
          backgroundColor: theme.colors.basics.grey.c200
        },
        placeholder: {
          fontSize: theme.metrics.spacing.m,
          textAlign: 'center',
          color: theme.colors.basics.grey.c400
        },
        date: {
          fontSize: theme.metrics.spacing.m,
          textAlign: 'center',
          color: theme.colors.basics.black
        },
        disabledPlaceholder: {
          color: theme.colors.basics.grey.c500
        },
        textOnly: {
          flex: 1,
          fontSize: theme.metrics.spacing.m,
          alignItems: 'flex-start',
          justifyContent: 'space-between'
        },
        textContainer: {
          height: '100%',
          justifyContent: 'center',
          alignItems: 'flex-start'
        },
        iconsContainer: {
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center'
        },
        cleanContainer: {
          marginRight: theme.metrics.spacing.xs
        }
      }),
    [theme]
  )

  return styles
}
