import React, { useCallback } from 'react'
import { ListRenderItem } from 'react-native'

import { Carousel, SimpleButton } from '@ere-uilib/index'

import { DocumentFilterByYearProps } from './interfaces'

export const DocumentFilterByYear: React.FC<DocumentFilterByYearProps> = ({
  currentYear,
  years,
  onChange,
  containerStyle
}) => {

  const renderItem = useCallback<ListRenderItem<number>>(
    ({ item: nextYear }) => (
      <SimpleButton
        design={nextYear === currentYear ? 'solid' : 'light'}
        onPress={() => onChange(nextYear)}
        size='small'
        title={nextYear}
      />
    ),
    [currentYear, onChange]
  )

  if (!years.length) return null

  return (
    <Carousel<number>
      containerStyle={containerStyle}
      data={years}
      keyExtractor={item => 'year-' + item}
      renderItem={renderItem}
      spacingAfterLastItem={10}
      spacingBeforeFirstItem={10}
      withBullets={false}
    />
  )
}
