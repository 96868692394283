import { useMemo } from 'react'
import { StyleSheet } from 'react-native'

import { useTheme } from '@ere-uilib/styles'

export const useStyles = () => {
  const theme = useTheme()

  const styles = useMemo(
    () =>
      StyleSheet.create({
        wrapperContainer: {
          margin: theme.metrics.spacing.s
        },
        tableContainer: {
          flex: 1,
          borderWidth: 1.5,
          borderRadius: theme.metrics.spacing.xs,
          borderColor: theme.colors.notifications.information.c100
        },
        tableHeader: {
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: theme.metrics.spacing.s,
          borderBottomWidth: 1.5,
          borderBottomColor: theme.colors.notifications.information.c100
        },
        tableBody: {
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignContent: 'flex-end',
          padding: theme.metrics.spacing.s
        },
        disabledTableBody: {
          padding: theme.metrics.spacing.s,
          backgroundColor: theme.colors.notifications.information.c100
        },
        mentionText: {
          marginTop: theme.metrics.spacing.s
        },
        disabledTextStyle: {
          color: theme.colors.basics.grey.c500
        },
        dispositifNameWrapper: {
          flex: 1
        },
        disabledMessage: {
          marginTop: theme.metrics.spacing.s
        },
        noEligibleDispositif: {
          marginHorizontal: theme.metrics.spacing.s,
          marginVertical: theme.metrics.spacing.xm
        },
        tableLineStyle: {
          borderBottomWidth: 1,
          borderBottomColor: theme.colors.notifications.information.c100
        }
      }),
    [theme]
  )

  return styles
}
