import React, { useCallback, useState } from 'react'
import { View } from 'react-native'

import { Select, SimpleButton } from '@components/index'
import { useTranslation } from '@components/index'
import { ItemState } from '@ere-uilib/molecules/inputs/Select/types'
import { RefundUnavailabilityDecisionTreeType } from '@modules/refund/unavailable-refund/types'

import { UnavailableRefundUnlockingReasonSelectProps } from './interfaces'
import { useStyles } from './useStyles'

export const UnavailableRefundUnlockingReasonSelect: React.FC<React.PropsWithChildren<UnavailableRefundUnlockingReasonSelectProps>> = ({ decisionTreeData, handleNextPressed, buttonTitle }) => {
  const { formatMessage } = useTranslation()
  const styles = useStyles()

  const [selectedItems, setSelectedItems] = useState<{ [key: string]: ItemState }>({})
  const [isQuestionsFinished, setIsQuestionFinished] = useState(false)

  const renderNextActionButton = useCallback(() => {
    if (!isQuestionsFinished) return null
    const objectQuestionsToArray = Object.values(selectedItems)

    return (
      <SimpleButton
        onPress={() => handleNextPressed(objectQuestionsToArray[objectQuestionsToArray.length - 1])}
        title={buttonTitle}
      />
    )
  }, [handleNextPressed, isQuestionsFinished, selectedItems, buttonTitle])

  const getNextDataLevel = useCallback(
    (
      data: RefundUnavailabilityDecisionTreeType,
      items: { [key: string]: ItemState }
    ): RefundUnavailabilityDecisionTreeType | null => {
      if (!data.next) return null

      for (const level in items) {
        const selectedItem = items[level]
        const responseData = data?.next?.responses?.find(
          response => response.id === selectedItem.value
        )
        if (responseData) {
          data = responseData
        } else {
          return null
        }
      }
      return data
    },
    []
  )

  const handleSelectItem = useCallback(
    (item: ItemState, level: number) => {
      const newSelectedItems = {
        ...selectedItems,
        [level]: item
      }
      setSelectedItems(newSelectedItems)

      const currentDataLevel = getNextDataLevel(decisionTreeData, newSelectedItems)
      if (currentDataLevel && !currentDataLevel.next) {
        setIsQuestionFinished(true)
      } else setIsQuestionFinished(false)
    },
    [decisionTreeData, getNextDataLevel, selectedItems]
  )

  const decisionTreeSelect = useCallback(
    (data: RefundUnavailabilityDecisionTreeType, level: number) => {
      if (!data || !data?.next) return null

      const formatedItems = data.next.responses.map(res => {
        return {
          label: formatMessage({ id: res.response }),
          value: res.id
        }
      })

      const nextDataLevel = data.next.responses.find(
        response => response.id === selectedItems[level]?.value
      )

      return (
        <View
          key={level}
          style={{
            zIndex: 10 - level
          }}>
          <Select
            containerStyle={[styles.selectContainerStyles, { zIndex: 10 }]}
            defaultTitle={formatMessage({ id: data.next.question })}
            iconSize={5}
            items={formatedItems}
            onSelectItem={item => handleSelectItem(item, level)}
            placeholder={formatMessage({ id: data.next.question })}
            selectedItem={selectedItems[level]}
          />
          {nextDataLevel?.next && decisionTreeSelect(nextDataLevel, level + 1)}
        </View>
      )
    },
    [handleSelectItem, selectedItems, styles, formatMessage]
  )

  return (
    <View style={styles.containerStyle}>
      {decisionTreeSelect(decisionTreeData, 0)}
      {renderNextActionButton()}
    </View>
  )
}
