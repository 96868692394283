export enum OtpRequestTypeEnum {
  SECURE = 'SECURE',
  VERIFY = 'VERIFY',
}
export enum OtpChanelTypeEnum {
  MAIL = 'Email',
  SMS = 'SMS',
  MAIL_DOCAPOSTE = 'EmailDocaposte',
  SMS_DOCAPOSTE = 'SmsDocaposte',
  UNKNOWN = 'UNKNOWN'
}
