import { useMemo } from "react"
import { StyleSheet } from "react-native"

import { useScreenSizes, useTheme } from "@ere-uilib/styles";

export const useStyles = () => {
  const theme = useTheme();
  const { isDesktop } = useScreenSizes();

  const styles = useMemo(() => StyleSheet.create({
    SupplementaryFormContainer: {
      width: isDesktop ? '60%' : '100%',
      backgroundColor: theme?.colors.basics.primary.c100,
      borderRadius: theme?.metrics.spacing.xs,
      paddingHorizontal: theme?.metrics.spacing.s,
      paddingVertical: theme?.metrics.spacing.xm,
    },
    SupplementaryFormtxt: {
      marginVertical: theme?.metrics.spacing.s
    },
    buttonStyle: {
      alignSelf: 'flex-start',
      marginTop: theme?.metrics.spacing.s
    },
    txtStyles: {
      marginTop: theme?.metrics.spacing.xm,
      marginBottom: theme?.metrics.spacing.xs
    }
  }), [theme, isDesktop]);

  return styles;
}
