import { AppState } from '@modules/reducers'

import { RefundsCommonState } from './types'

const getDecisionAllResponses = (state: RefundsCommonState) => {
  const questions = state.decision.questions
  let allResponses = {}
  questions.forEach(question => {
    if (question?.responded?.respondedPropertyFilter) {
      const isPropertyFilter =
        question?.responded?.respondedPropertyFilter.name &&
        question.responded.respondedPropertyFilter.value

      const isOption =
        question?.responded?.selectedOption?.name && question.responded.selectedOption.value

      const propertyFilter = isPropertyFilter
        ? {
            [question.responded?.respondedPropertyFilter.name]:
              question.responded.respondedPropertyFilter.value
          }
        : {}

      const option =
        isOption && question?.responded?.selectedOption
          ? {
              [question.responded?.selectedOption?.name]: question.responded.selectedOption?.value
            }
          : {}

      allResponses = {
        ...allResponses,
        ...propertyFilter,
        ...option
      }
    }
  })
  return allResponses
}

export const getRefundGlobalState = (state: AppState) => state.refunds
export const getRefundsError = (state: AppState) => state.refunds.error

export const getRefundsDecision = (state: AppState) => state.refunds.decision

export const getRefundSelectedDispositif = (state: AppState) =>
  state.refunds.decision.selectedDispositif

export const getRefundsElligibleDispositif = (state: AppState) =>
  state.refunds.decision.elligibleDispositifs

export const getBankDetails = (state: AppState) => state.refunds.decision?.saverDetails

export const getRefundsDecisionAllResponses = (state: AppState) =>
  getDecisionAllResponses(state.refunds)