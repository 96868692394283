import {
  DirectionEnum,
  StepperItemType,
} from '@ere-uilib/molecules/steppers/InstallmentsStepper/interfaces';
import { StepperStatus } from '@ere-uilib/types';

type StepperParams = {
  size: number;
  formatMessage?: Function;
  messageIds?: string[];
  direction?: string;
  activeStep?: number;
};

export const constructStepperItems = (
  params: StepperParams
): StepperItemType[] => {
  const { direction, size, activeStep, formatMessage, messageIds } =
    params ?? {};
  const isRowStepper = direction === DirectionEnum.ROW;
  let lastStep = isRowStepper ? StepperStatus.TO_DO : StepperStatus.FINISH;

  return Array.from({ length: size }, (_, k) => {
    const currentPosition = k + 1;
    const isLastStep = currentPosition === size;
    const isActiveStep = currentPosition === activeStep;
    let otherSteps;
    if (isRowStepper && activeStep! > currentPosition) {
      otherSteps = StepperStatus.DONE;
    }
    if (isRowStepper && activeStep! < currentPosition) {
      otherSteps = StepperStatus.TO_DO;
    }
    if (isRowStepper && isActiveStep) {
      otherSteps = StepperStatus.IN_PROGRESS;
    }
    if (isRowStepper && isActiveStep && isLastStep) {
      lastStep = StepperStatus.IN_PROGRESS;
    }
    return {
      status: otherSteps,
      title: formatMessage?.({ id: messageIds?.[k] }),
    };
  });
};

export const formatCustomPlanName = (planName: string | null | undefined, label: string | null | undefined, planType) : string => {
  const redundancyPlans = ["PEE", "PEI", "PEG"];
  if(redundancyPlans.includes(planType) ) {
    return planName === label ? `${planName}` : `${planName} - ${label}`
  } else {
    return  `${planName} - ${label}`
  }
  
}
