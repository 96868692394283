import { useMemo } from 'react'
import {Dimensions, Platform, StyleSheet} from 'react-native'

import { useScreenSizes, useTheme } from '@ere-uilib/styles'

type UseStyles = {
  displayLeftButton?: boolean
  displayRightButton?: boolean
  scrollViewHeight?: number
}

const slideButtonSize = 40

export function useStyles({ displayLeftButton, displayRightButton, scrollViewHeight }: UseStyles) {
  const theme = useTheme()
  const { isDesktop } = useScreenSizes()

  const shadow = useMemo(
    () => ({
      shadowColor: theme.colors.notifications.shadow,
      shadowOffset: {
        width: 0,
        height: 2
      },
      shadowOpacity: 0.85,
      shadowRadius: 10,
      elevation: 1
    }),
    [theme]
  )

  const pieChartTopWidth = useMemo(() => {
    if (isDesktop) {
      return
    }

    return '100%'
  }, [isDesktop])

  const slideButtonPositionTop = useMemo(() => {
    const positionTop = !!scrollViewHeight ? (scrollViewHeight - slideButtonSize) / 2 : 0

    return positionTop
  }, [scrollViewHeight])

  const styles = useMemo(
    () =>
      StyleSheet.create({
        pieChartContainerHeader: {
          backgroundColor: theme.colors.basics.grey.c100
        },
        pieChartContainerRight: {
          borderBottomLeftRadius: theme.metrics.borderRadius.xs,
          borderBottomRightRadius: theme.metrics.borderRadius.xs,
          paddingTop: 30,
          ...shadow
        },
        chartCard: {
          backgroundColor: theme.colors.basics.white,
          padding: 10,
          marginHorizontal: 5,
          marginVertical: 15,
          borderRadius: theme.metrics.borderRadius.xs,
          minWidth: 150,
          ...(isDesktop ? { width: 200 } : { flex: 1 }),
          ...shadow
        },
        chartTitleStyle: {
          textAlign: 'center'
        },
        legendContainer: {
          marginVertical: 20,
          marginHorizontal: 'auto',
          flexDirection: 'row',
          alignSelf: 'center'
        },
        legendPointStyle: {
          width: 13,
          height: 13,
          borderRadius: 50,
          marginRight: theme.metrics.spacing.xxs
        },
        pieChartTop: {
          flexDirection: 'row',
          flex: 1,
          display: 'flex',
          ...(!isDesktop && { width: pieChartTopWidth })
        },
        pieChartScrollView: {},
        legendTitleRow: {
          flexDirection: 'row',
          marginLeft: theme.metrics.spacing.s,
          alignItems: 'center'
        },
        riskProfilecontainer: {
          marginTop: theme.metrics.spacing.s
        },
        riskProfileValue: {
          textAlign: 'center'
        },
        riskProfileLabel: {
          textAlign: 'center'
        },
        sliderButtonsContainer: {
          position: 'absolute',
          display: 'flex',
          width: '100%',
          top: slideButtonPositionTop,
          ...(displayLeftButton && { alignItems: 'flex-start' }),
          ...(displayRightButton && { alignItems: 'flex-end' }),
          ...(displayLeftButton && displayRightButton && { alignItems: undefined })
        },
        sliderButtons: {
          flex: 1,
          flexDirection: 'row',
          paddingHorizontal: theme.metrics.spacing.xm,
          ...(displayLeftButton && displayRightButton && { justifyContent: 'space-between' })
        },
        sliderButton: {
          backgroundColor: theme.colors.basics.white,
          width: slideButtonSize,
          height: slideButtonSize,
          borderRadius: slideButtonSize,
          justifyContent: 'center',
          alignItems: 'center',
          ...shadow
        }
      }),
    [
      isDesktop,
      theme,
      pieChartTopWidth,
      shadow,
      displayLeftButton,
      displayRightButton,
      slideButtonPositionTop
    ]
  )

  return styles
}
