import React from 'react';
import { useStyles } from './styles';
import { TouchableOpacity } from 'react-native';

import { PropsModal } from './interface';

const CustomModal: React.FC<React.PropsWithChildren<PropsModal>> = ({ children, isVisible, customStyle, setIsVisible }) => {
  const styles= useStyles();

  return (
    <>
      {isVisible &&
        <TouchableOpacity
          onPress={() => setIsVisible(false)}
          style={[
            styles.modalContainer,
            customStyle,
          ]}
        >
          {children}
        </TouchableOpacity>}
    </>
  )
}

export default CustomModal;
