import { GetAddressesDtoResponse, PostAddressDtoResponse } from '@modules/profile/service/types';
import {
  AddressState,
  AddressUpdateStatus,
  ProfileAddressUpdateStatusState
} from '@modules/profile/types';

export const mapGetAddressesResponseToState = (response?: GetAddressesDtoResponse): AddressState =>
  response as AddressState;

export const mapPostAddressResponseToState = ({
  isSucceeded
}: PostAddressDtoResponse): ProfileAddressUpdateStatusState => ({
  addressUpdateStatus: AddressUpdateStatus[isSucceeded ? 'SUCCESS' : 'ERROR']
});
