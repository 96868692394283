import { StackNavigationProp } from "@react-navigation/stack";
import React from "react";
import { View } from "react-native";

import { useTranslation } from "@components/ERE-UILib";
import { locator } from "@constants/locator";
import { IconEnum } from "@ere-uilib/enums";
import { SimpleButton } from "@ere-uilib/molecules";
import { AppNavigatorInterface } from "@navigation/Interfaces";
import { LoaderLayer } from "@pages/ProfitSharingIncentive/ProfitSharingIncentiveRepartition/Components/LoaderLayer";
import { useProfitSharringRepartitionContext } from "@pages/ProfitSharingIncentive/ProfitSharingIncentiveRepartition/context";
import { label } from "@pages/ProfitSharingIncentive/ProfitSharingIncentiveRepartition/label";
import { useStyles } from "@pages/ProfitSharingIncentive/ProfitSharingIncentiveRepartition/styles";

type BottomActionsType  = {
    navigation: StackNavigationProp<AppNavigatorInterface>;
    setDisplayModalConsentValidation: (value: boolean) => void;
    isContributionLoading?: boolean
}

export const BottomActions: React.FC<BottomActionsType> = ({ navigation, setDisplayModalConsentValidation, isContributionLoading }) => {
    const styles = useStyles();
    const { formatMessage } = useTranslation();
    const { isformFilled } = useProfitSharringRepartitionContext();

    return (
        <View style={styles.bottomActionsStyle}>
            <View style={styles.bottomActionsButtonsContainerStyle}>
                <SimpleButton
                    design="outlined"
                    leftIcon={IconEnum.CHEVRON_LEFT}
                    onPress={() => navigation.goBack()}
                />
                <SimpleButton
                    containerStyle={styles.simpleButtonStyle}
                    design="solid"
                    disabled={!isformFilled}
                    onPress={() => {
                        setDisplayModalConsentValidation(true);
                    }}
                    size="small"
                    testId={locator._pi._synthese_continu}
                    title={formatMessage({ id: label.button.next })}
                />
            </View>
           <LoaderLayer isContributionLoading={isContributionLoading}/>
        </View>
    )
}
