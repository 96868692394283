import React from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'

import { FiletIcon, StraightLine } from '@ere-uilib/atoms'
import { createUseStyles, ThemeType, useScreenSizes, useTheme } from '@ere-uilib/styles'

interface Props {
  children?: React.ReactNode
  disableLine?: boolean
  disableIcone?: boolean
}
interface StyleProps {
  containerStyle?: StyleProp<ViewStyle>
  straightLine?: StyleProp<ViewStyle>
  contentContainer?: StyleProp<ViewStyle>
  filetContainerStyle?: StyleProp<ViewStyle>
}
interface StyleContext {
  theme?: ThemeType
  isDesktop?: boolean
  disableIcone?: boolean
}

export const FiletStepper: React.FC<React.PropsWithChildren<Props & StyleProps & StyleContext>> = ({
  children,
  containerStyle,
  contentContainer,
  straightLine,
  disableLine = true,
  filetContainerStyle,
  disableIcone,
}) => {
  const theme: ThemeType = useTheme()
  const { isDesktop } = useScreenSizes()
  const styles = useStyles(
    { theme, isDesktop, disableIcone },
    { containerStyle, straightLine, contentContainer, filetContainerStyle }
  )

  return (
    <View style={styles.contentContainer}>
      <View style={styles.filetContainerStyle}>
        {!disableLine && (
          <StraightLine
            containerStyle={styles.straightLine}
            orientation={'column'}
          />
        )}
        {!disableIcone && <FiletIcon />}
      </View>
      <View style={styles.containerStyle}>{children}</View>
    </View>
  )
}

const getStyles = (context?: StyleContext, style?: StyleProps): StyleProps => ({
  containerStyle: [
    {
      flexDirection: 'row',
      maxWidth: '100%',
      marginVertical: context?.theme?.metrics.spacing.s
    },
    style?.containerStyle
  ],
  straightLine: [
    {
      position: 'absolute',
      height: '100%',
      backgroundColor: context?.theme?.colors.basics.black
    },
    style?.straightLine
  ],
  contentContainer: [
    {
      flexDirection: 'row',
      alignItems: 'flex-start',
    },
    style?.contentContainer
  ],
  filetContainerStyle: [
    {
      height: '100%',
      marginTop: context?.theme?.metrics.spacing.m,
      marginBottom: context?.theme?.metrics.spacing.m && -context.theme?.metrics.spacing.m,
      ...(context?.disableIcone && {
        marginLeft: (context?.theme?.metrics.spacing.xl || 0) / 10
      })
    },
    style?.filetContainerStyle
  ]
})

const useStyles = createUseStyles(getStyles)
