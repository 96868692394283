import { ArbitrationSuccessPageContext, ArbitrationSuccessPageStyle } from './interfaces';

export const getStyles = (
  context?: ArbitrationSuccessPageContext,
  style?: ArbitrationSuccessPageStyle,
): ArbitrationSuccessPageStyle => ({

  containerStepper: [
    {
      alignContent: 'flex-start',
      alignItems: 'flex-start',
    },
    style?.containerStepper,
  ],
  stepSubTextStyle: [
    {
      marginTop: context?.theme.metrics.spacing.xs,
      marginBottom: context?.theme.metrics.spacing.s,
    },
    style?.stepSubTextStyle,
  ],
  textStepStyle: [
    {
      color: context?.theme.colors.text.disabled.c500,
      textAlign: 'left',
    },
    style?.textStepStyle,
  ],

});

