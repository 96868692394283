import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useScreenSizes, useTheme } from '@ere-uilib/styles';

export const useStyles = () => {
  const theme = useTheme();
  const { isDesktop } = useScreenSizes();

  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          ...(isDesktop && {
            maxWidth: theme.metrics.contentSizes.leftSideContentMaxWidth
          }),
        },
        row1: {
          flexDirection: 'row',
          alignItems: 'center',
        },
        bullet: {
          width: 15,
          height: 15
        },
        title: {
          marginLeft: theme.metrics.spacing.s,
          flex: 1,
          height: 17
        },
        row2: {
          marginLeft: theme.metrics.spacing.xxm,
          marginVertical: theme.metrics.spacing.xxm,
          flexDirection: 'row',
        },
        content1: {
          width: 80,
          height: 50,
          borderRadius: theme.metrics.borderRadius.xs
        },
        content2: {
          flex: 1,
          marginLeft: theme.metrics.spacing.s,
        },
        line2: {
          marginTop: theme.metrics.spacing.s,
          width: '50%'
        },
        action: {
          marginLeft: theme.metrics.spacing.xxm,
          width: 50,
          height: 30
        }
      }),
    [isDesktop, theme]
  );

  return styles;
};
