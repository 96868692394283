
import { Platform } from 'react-native';
import { ThemeType } from '../../theme';
import { ParagraphSizeTypes, TitleSizeTypes } from '../fonts';

import { FontWeight, GetTextFontStyleInterfaces, GetTitleFontStyleInterfaces } from './interfaces';

type StyleConfigType = {
  theme: ThemeType;
  variant: any;
  weight: FontWeight | undefined;
  numberOfLines: number | undefined;
  textKind: "title" | "paragraph";
}
const styleConfig = ({
  theme,
  variant,
  weight,
  numberOfLines,
  textKind = "title"
}: StyleConfigType) => {
  const isTitle = textKind === "title"
  const fontSize = isTitle ?
    theme.fonts.fontSize.title[variant as keyof TitleSizeTypes] :
    theme.fonts.fontSize.paragraph[variant as keyof ParagraphSizeTypes];
  const lineHeightRatio = isTitle ?
    theme.metrics.lineHeightRatio.title :
    theme.metrics.lineHeightRatio.text;
  const textsSpacingRatio = isTitle ?
    theme.metrics.textsSpacingRatio.title :
    theme.metrics.textsSpacingRatio.paragraph;
  const fontFamily = weight ? theme.fonts.fontFamily[weight] : theme?.fonts.fontFamily.regular;
  const androidLineHeightRatio = Platform.OS === 'android' ? 5 : 0;
  const calculatedLineHeight = Math.round(fontSize * lineHeightRatio * 10) / 10 + androidLineHeightRatio;
  const isNumberOfLines = !!numberOfLines && numberOfLines > 0;
  return {
    fontSize,
    lineHeightRatio,
    textsSpacingRatio,
    fontFamily,
    calculatedLineHeight,
    isNumberOfLines
  }
}

export const getTitleFontStyle = ({
  autoMargin = false,
  theme,
  variant,
  weight,
  numberOfLines,
}: GetTitleFontStyleInterfaces) => {
  const {
    fontFamily,
    fontSize,
    textsSpacingRatio,
    calculatedLineHeight,
    isNumberOfLines
  } = styleConfig({
    theme,
    variant,
    weight,
    numberOfLines,
    textKind: "title"
  })
  return {
    color: theme?.colors.text.primary.c500,
    lineHeight: calculatedLineHeight,
    fontSize: fontSize,
    fontFamily: fontFamily,
    ...(autoMargin ? {
      marginVertical: calculatedLineHeight * textsSpacingRatio,
    } : {}),
    ...(Platform.OS === 'web' && isNumberOfLines && !!numberOfLines && {
      maxHeight: calculatedLineHeight * numberOfLines - calculatedLineHeight * 0.05,
    }),
  };
};

export const getParagraphFontStyle = ({
  autoMargin = false,
  theme,
  variant,
  weight,
  numberOfLines,
}: GetTextFontStyleInterfaces) => {
  const {
    fontFamily,
    fontSize,
    textsSpacingRatio,
    calculatedLineHeight,
    isNumberOfLines
  } = styleConfig({
    theme,
    variant,
    weight,
    numberOfLines,
    textKind: "paragraph"
  })
  return {
    color: theme?.colors.text.primary.c500,
    lineHeight: calculatedLineHeight,
    fontSize: fontSize,
    fontFamily: fontFamily,
    ...(autoMargin ? {
      marginVertical: textsSpacingRatio * fontSize,
    } : {}),
    ...(Platform.OS === 'web' && isNumberOfLines && !!numberOfLines && {
      maxHeight: calculatedLineHeight * numberOfLines - calculatedLineHeight * 0.05,
    }),
  };
};
