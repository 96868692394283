
import { connect } from 'react-redux';

import { getLegalContentRequest } from '@modules/legal/actions/legalActions';
import { AppState } from '@modules/reducers';
import { getLegalContent, getMenuFooter } from '@modules/legal/selectors';

import { LegalMenuFooterConnectedComponent } from './LegalMenuFooterConnected.components';

const mapStateToProps = (state: AppState)=>{
  return {
    links: getMenuFooter(state),
    content: getLegalContent(state)
  };
};

const mapDispatchToProps = ({
  onGetLegalContent: getLegalContentRequest
});

export const LegalMenuFooterConnected = connect(mapStateToProps, mapDispatchToProps)(LegalMenuFooterConnectedComponent);
