import React from 'react';
import { Card } from '@components/index';
import { useStyles } from './useStyles';

export const ContactCard: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const styles = useStyles();
  return (
    <Card containerStyle={styles.cardContainerStyle} childrenStyle={styles.cardChildrenStyle}>
      {children}
    </Card>
  );
};
