import { useMemo } from "react";
import { StyleSheet } from "react-native";

import { useScreenSizes, useTheme } from "@ere-uilib/styles";

const CURSOR_TRIANGLE_SIZE = 10;
const CURSOR_TRIANGLE_CONTAINER_SIZE = CURSOR_TRIANGLE_SIZE * Math.sqrt(2);
const BORDER_LINE_THICKNESS = 3;

export const useStyles = () => {
  const theme = useTheme();
  const { isMobile } = useScreenSizes();

  const styles = useMemo(() => StyleSheet.create(  {
    containerStyle: {
      zIndex: 3,
    },
    itemContainer: {
      alignItems: 'center'
    },
    line: {
      position: 'absolute',
      bottom: CURSOR_TRIANGLE_CONTAINER_SIZE * 0.5,
      height: '100%',
      width: '100%',
      backgroundColor: theme.colors.basics.white,
      borderBottomColor: theme.colors.basics.grey.c200,
      borderBottomWidth: BORDER_LINE_THICKNESS
    },
    containerInnerStyle: {
      flexDirection: 'row',
      minWidth: '100%',
      justifyContent: isMobile ? 'flex-start' : 'center',
    },
    linkBarListItemStyle: {
      borderBottomWidth: BORDER_LINE_THICKNESS,
      borderBottomColor: 'transparent',
      alignItems: 'center',
      padding: theme.metrics.spacing.s
    },
    linkBarListItemSelectedStyle: {
      borderBottomColor: theme.colors.basics.primary.c500,
    },
    listItemTextStyle: {
      color: theme.colors.text.disabled.c500,
    },
    listItemTextSelectedStyle: {
      color: theme.colors.basics.primary.c500,
    },
    cursorContainerStyle: {
      width: CURSOR_TRIANGLE_CONTAINER_SIZE,
      height: CURSOR_TRIANGLE_CONTAINER_SIZE * 0.5,
      overflow: 'hidden'
    },
    cursorTriangleStyle: {
      width: CURSOR_TRIANGLE_SIZE,
      height: CURSOR_TRIANGLE_SIZE,
      backgroundColor: theme.colors.basics.primary.c500,
      transform: [
        { rotateZ: '45deg' },
        { translateX: -CURSOR_TRIANGLE_CONTAINER_SIZE * 0.5 }
      ],
      position: 'absolute',
      top: 0,
      left: '50%'
    },
  }), [theme, isMobile]);

  return styles;
};
