import React, { useState } from 'react';

import { ExpendableLine } from './ExpandableLine';
import { ExpendableLineProps } from './ExpandableLine/interfaces';

export interface ExpendableLineSetProps {
  list: ExpendableLineListItemProps[]
  autoOpenFirstItem?: boolean
  testId?: string
}
export type ExpendableLineListItemProps = ExpendableLineProps

export const ExpendableLineSet: React.FC<ExpendableLineSetProps> = ({
  list,
  autoOpenFirstItem = false,
  testId
}) => {
  const defaultOpenedIndexList = autoOpenFirstItem ? [0] : [];
  const [openedIndexList, setOpenedIndexList] = useState(defaultOpenedIndexList);
  const onItemPressed = (index: number, newState: boolean) => {
    // as we are going to update the array, we need to spread it to make sure useState update correctly
    let newOpenedIndexList = [...openedIndexList];
    if (newState) {
      !newOpenedIndexList.includes(index) && newOpenedIndexList.push(index);
    } else {
      newOpenedIndexList = newOpenedIndexList.filter(target => target !== index);
    }
    setOpenedIndexList(newOpenedIndexList);
  };
  return (<>
    {list && list.map(({
      onChange,
      isOpened,
      ...otherProps
    }, index) => {
      const isOpen = openedIndexList.includes(index);
      return (
        <ExpendableLine
          isOpened={isOpen}
          key={index}
          testId={testId}
          onChange={(newState: boolean) => {
            onItemPressed(index, newState);
            onChange && onChange(newState);
          }}
          {...otherProps}
        />
      );
    })}
  </>);

};
