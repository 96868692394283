import { FetchCreateArbitrationExpectedData } from '@modules/arbitration/types'
import {
  CompartmentType,
  DivestedManagementSource,
  ReallocationDivestmentState
} from '@modules/common/types'

export const buildFetchCreateArbitrationBody = ({
  isStock,
  disinvestData,
  reinvestTargetedCompartement
}: {
  isStock: boolean
  selectedCompartment: CompartmentType | undefined
  disinvestData: DivestedManagementSource | undefined
  reinvestTargetedCompartement: ReallocationDivestmentState['compartments'][number] | undefined
}): FetchCreateArbitrationExpectedData['body'] | undefined => {
  if (disinvestData?.managementId && reinvestTargetedCompartement) {
    const targetPlanManagements: FetchCreateArbitrationExpectedData['body']['targetPlanManagements'] =
      []

    reinvestTargetedCompartement.managements.forEach(management => {
      let managementSupportRateSum = 0
      const formatedFundsList: FetchCreateArbitrationExpectedData['body']['targetPlanManagements'][number]['fundsList'] =
        management.supports
          .map(support => {
            const supportRate = support.investedFormValues?.amountPercent || 0
            managementSupportRateSum += supportRate
            return {
              rate: supportRate,
              supportIsinCode: support.supportIsin
            }
          })
          .filter(support => !!support.rate)
      const managementDirectRate = management.investedFormValues?.amountPercent || 0

      const managementRate = managementDirectRate || managementSupportRateSum

      managementRate > 0 &&
        targetPlanManagements.push({
          id: management.id,
          rate: managementRate,
          fundsList: formatedFundsList
        })
    })
    return {
      isStock: isStock,
      sourcePlanManagement: {
        funds: disinvestData.supportIsinCode
          ? {
              amount: disinvestData.amount || 0,
              executionTriggerValue: disinvestData?.executionTriggerValue,
              supportIsinCode: disinvestData.supportIsinCode,
              scheduleCode: disinvestData.dueDateOption?.code,
              scheduleDate: disinvestData.dueDateOption?.date
            }
          : null,
        id: disinvestData?.managementId
      },
      targetPlanManagements
    }
  } else {
    return undefined
  }
}
