import { RefundDecisionPropertiesEnum } from '@constants/refunds'
import { QuestionsState } from '@modules/common/types'
import {
  RefundUnavailabilityDecisionTreeType,
  UnavailableRefundDispositifItemState
} from './unavailable-refund/types'

export enum EnumRefundUnavailableDecision {
  REFUND_UNAVAILABLE = 'Unavailable',
  REFUND_UNAVAILABLE_DECISION_TREE = 'RefundUnavailableDecisionTree',
  REFUND_UNAVAILABLE_DISPOSITIF_TABLE = 'RefundUnavailableDispositifTable',
  REFUND_UNAVAILABLE_SIGNATURE_DATE = 'RefundUnavailableSignatureDate'
}

export const RefundUnavailableDecisionTreeQuestion = (
  data: RefundUnavailabilityDecisionTreeType
): QuestionsState => {
  return {
    key: data?.response || '',
    showEligibility: false,
    text: '',
    title: '',
    tooltip: '',
    responses: [
      {
        type: EnumRefundUnavailableDecision.REFUND_UNAVAILABLE_DECISION_TREE,
        propertyFilter: {
          name: RefundDecisionPropertiesEnum.REPAYMENT_UNAVAILABLE,
          value: true
        },
        data
      }
    ]
  }
}

export const RefundUnavailableDispositifTableQuestion = (
  data: UnavailableRefundDispositifItemState[] | null
): QuestionsState => {
  return {
    key: 'UnavailableRepaymentQuestionChoicePlan',
    showEligibility: false,
    text: '',
    title: '',
    titleNonFormatted: true,
    tooltip: '',
    responses: [
      {
        title: 'UnavailableRepaymentHeaderPlanES',
        type: EnumRefundUnavailableDecision.REFUND_UNAVAILABLE_DISPOSITIF_TABLE,
        propertyFilter: {
          name: RefundDecisionPropertiesEnum.REPAYMENT_UNAVAILABLE,
          value: true
        },
        data
      }
    ]
  }
}

export const RefundUnavailableSignatureDateQuestion = (
  data: RefundUnavailabilityDecisionTreeType
): QuestionsState => {
  return {
    key: data?.response || '',
    showEligibility: false,
    text: '',
    title: '',
    tooltip: '',
    responses: [
      {
        type: EnumRefundUnavailableDecision.REFUND_UNAVAILABLE_SIGNATURE_DATE,
        propertyFilter: {
          name: RefundDecisionPropertiesEnum.REPAYMENT_UNAVAILABLE,
          value: true
        },
        data
      }
    ]
  }
}
