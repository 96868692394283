import { put, call, select, takeLatest } from 'redux-saga/effects';

import { RouteNames } from '@constants/navigation';
import * as forgetPasswordActions from './actions/forgetPasswordActions';
import * as forgetPasswordActionsTypes from './actions/forgetPasswordActionsTypes';
import { ForgetPasswordActionsType } from './actions/forgetPasswordActionsTypes';
import { runManager } from '@modules/moduleManager';
import { getForgetPasswordRequestIdSelector } from './selectors';
import {
  forgetPasswordInitBeginService,
  forgetPasswordConfirmEmailService,
  forgetPasswordConfirmSMSService,
  forgetPasswordConfirmSMSSCodeService,
  forgetPasswordConfirmService
} from './services';
import { RootNavigation } from '@navigation/RootNavigation';

function* forgetPasswordInitBegin(
  action: forgetPasswordActionsTypes.ForgetPasswordInitBeginRequestAction,
): any {
  yield put(forgetPasswordActions.ForgetPasswordInitError());
  yield put(forgetPasswordActions.SetForgetPasswordEmailInput(action.email));
  const response = yield call(
    forgetPasswordInitBeginService,
    action.email.replace(/\s/g, ''),
  );
  yield put(
    forgetPasswordActions.ForgetPasswordInitBeginSuccess(response.data),
  );
  yield RootNavigation.navigate(RouteNames.ForgetPassword, {
    screen: RouteNames.ForgetPasswordValidationCodePage,
    params: {
      isForgetPasswordPhoneNumberVerify: false
    }
  });
}

function* forgetPasswordConfirmEmail(
  action: forgetPasswordActionsTypes.ForgetPasswordConfirmEmailRequestAction,
): any {
  yield put(forgetPasswordActions.ForgetPasswordInitError());
  const requestId = yield select(getForgetPasswordRequestIdSelector);
  const response = yield call(
    forgetPasswordConfirmEmailService,
    requestId,
    action.code,
  );
  yield put(
    forgetPasswordActions.ForgetPasswordConfirmEmailSuccess(response.data),
  );
  yield RootNavigation.navigate(RouteNames.ForgetPassword, {
    screen: RouteNames.ForgetPasswordValidationCodePage, params: {
      isForgetPasswordPhoneNumberVerify: true
    }
  });
}

function* forgetPasswordConfirmSMS(
): any {
  yield put(forgetPasswordActions.ForgetPasswordInitError());
  const requestId = yield select(getForgetPasswordRequestIdSelector);
  yield call(forgetPasswordConfirmSMSService, requestId);
  yield put(forgetPasswordActions.ForgetPasswordConfirmSMSSuccess(true));
}

function* forgetPasswordConfirmSMSCode(
  action: forgetPasswordActionsTypes.ForgetPasswordConfirmSMSCodeRequestAction,
): any {
  yield put(forgetPasswordActions.ForgetPasswordInitError());
  const requestId = yield select(getForgetPasswordRequestIdSelector);
  yield call(
    forgetPasswordConfirmSMSSCodeService,
    requestId,
    action.code,
  );
  yield put(forgetPasswordActions.ForgetPasswordConfirmSMSCodeSuccess(true));
  yield RootNavigation.replace(RouteNames.ForgetPassword, {
    screen: RouteNames.ForgetPasswordChoosePasswordPage
  });
}

function* forgetPasswordConfirm(
  action: forgetPasswordActionsTypes.ForgetPasswordConfirmRequestAction,
): any {
  yield put(forgetPasswordActions.ForgetPasswordInitError());
  const requestId = yield select(getForgetPasswordRequestIdSelector);
  yield call(
    forgetPasswordConfirmService,
    requestId,
    action.newPassword,
  );
  yield put(forgetPasswordActions.ForgetPasswordConfirmSuccess(true));
  yield RootNavigation.replace(RouteNames.ForgetPassword, {
    screen: RouteNames.ForgetPasswordCongratsPage
  });
}

export function* forgetPasswordInitBeginSagas() {
  yield takeLatest(
    ForgetPasswordActionsType.FORGET_PASSWORD_INIT_BEGIN_REQUEST,
    runManager(forgetPasswordInitBegin, ForgetPasswordActionsType.FORGET_PASSWORD_INIT_BEGIN_FAILURE),
  );
}

export function* forgetPasswordConfirmEmailSagas() {
  yield takeLatest(
    ForgetPasswordActionsType.FORGET_PASSWORD_CONFIRM_EMAIL_REQUEST,
    runManager(forgetPasswordConfirmEmail, ForgetPasswordActionsType.FORGET_PASSWORD_CONFIRM_EMAIL_FAILURE),
  );
}

export function* forgetPasswordConfirmSMSSagas() {
  yield takeLatest(
    ForgetPasswordActionsType.FORGET_PASSWORD_CONFIRM_SMS_REQUEST,
    runManager(forgetPasswordConfirmSMS, ForgetPasswordActionsType.FORGET_PASSWORD_CONFIRM_SMS_FAILURE),
  );
}

export function* forgetPasswordConfirmSMSCodeSagas() {
  yield takeLatest(
    ForgetPasswordActionsType.FORGET_PASSWORD_CONFIRM_SMS_CODE_REQUEST,
    runManager(forgetPasswordConfirmSMSCode, ForgetPasswordActionsType.FORGET_PASSWORD_CONFIRM_SMS_CODE_FAILURE),
  );
}

export function* forgetPasswordConfirmSagas() {
  yield takeLatest(
    ForgetPasswordActionsType.FORGET_PASSWORD_CONFIRM_REQUEST,
    runManager(forgetPasswordConfirm, ForgetPasswordActionsType.FORGET_PASSWORD_CONFIRM_FAILURE),
  );
}
