import { Context, Styles } from './interfaces';

export const getStyles = (
  context: Context,
  style: Styles
): Styles => ({
  container: [
    { flex: 1 },
    (context?.isMobile || context?.isTablet ) && {
      height: context?.windowHeight
    },
    style?.container
  ],
  scrollView: [
    (context?.isMobile || context?.isTablet ) && {
      flex: 1
    },
    style?.scrollView
  ],
  scrollViewContentContainer: [
    {
      paddingBottom: context?.theme.metrics.spacing.xxxbig
    },
    style?.scrollViewContentContainer
  ]
});
