//State extractors
// get external client id if exist
import { LOCATION_URL } from '@constants/location';
import { AppState } from '@modules/reducers';
import { AuthConfigType, AuthSilentRenewConfigType } from '@modules/settings/types';
import { Platform } from 'react-native';

export const getAtInternetClientIdSession = (state: AppState) => state.settings?.atInternetClientIdSession;

export const getExternalClientId = (state: AppState) => state.settings?.externalClientID;

export const getApplicationSettings = (state: AppState) => state.settings?.applicationSettings;

export const getMobileMenuList = (state: AppState) => state.settings?.mobileMenuList;

export const getIsFCFeatureEnabled = (state: AppState) =>
  state.settings?.applicationSettings.EnableFeatureFranceConnect;

export const getIsFeatureRegroupementContratsEnabled = (state: AppState) =>
  state.settings?.applicationSettings.EnableFeatureRegroupementContrats;

export const getIsATInternetEnabled = (state: AppState) => {
  const siteId = state.settings?.applicationSettings.ApplicationATInternetSiteID;
  const isATInternetEnabled = siteId && siteId !== '' ? true : false;
  return isATInternetEnabled;
};

export const getAuthConfig = (state: AppState): AuthConfigType => {
  return {
    authority: state.settings.applicationSettings.authority || '',
    client_id: state.settings.applicationSettings.client_id || '',
    redirect_uri: LOCATION_URL + '/oidc/callback' || '',
    post_logout_redirect_uri: LOCATION_URL + '/oidc/logout' || '',
    response_type: state.settings.applicationSettings.response_type || '',
    scope: state.settings.applicationSettings.scope || '',
    loadUserInfo: Platform.OS !== 'web'
  };
};

export const getAuthSilentRenewConfig = (state: AppState): AuthSilentRenewConfigType => {
  const authConfig = getAuthConfig(state);
  return {
    ...authConfig,
    silent_redirect_uri: LOCATION_URL + '/oidc/silent', // this url is not used, silent redirect page is created but not called, to delete when refacto
    automaticSilentRenew: true
  };
};

export const getFundApiUrl = (state: AppState) => state.settings.applicationSettings.FundApiUrl;

export const getOptionEERRegexPersoneo = (state: AppState) =>
  state.settings?.applicationSettings.OptionEERRegexPersoneo;

export const getOptionEERRegexVisiogo = (state: AppState) =>
  state.settings?.applicationSettings.OptionEERRegexVisiogo;

export const getSettingFundDocOrder = (state: AppState) =>
  state.settings?.applicationSettings.FundsDocOrder;
