import { connect } from 'react-redux';

import { getInstallmentDecisionRequest } from '@modules/installment/actions/installmentActions';
import { InstallmentActionsType } from '@modules/installment/actions/installmentActionsTypes';
import { AppState } from '@modules/reducers';
import { getInstallmentDecision } from '@modules/installment/selectors';
import { getApplicationSettings } from '@modules/settings/selectors';

import { InstallmentPageComponent } from './InstallmentPage.component';

const mapStateToProps = (state: AppState) => ({
  isApiLoading: state.isLoading[InstallmentActionsType.GET_INSTALLMENT_DECISION_REQUEST],
  decision: getInstallmentDecision(state),
  applicationSettings: getApplicationSettings(state),

});
const mapDispatchToProps = ({
  getInstallMentDecisionData: getInstallmentDecisionRequest,
});
export const InstallmentPage = connect(mapStateToProps, mapDispatchToProps)(InstallmentPageComponent);
