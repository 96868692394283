import { NativeModules, Platform } from 'react-native'

export const fallbackLanguage = 'fr';

 const getAppleLocale = () => {
  let locale = NativeModules.SettingsManager.settings.AppleLocale

  if (locale === undefined) {
    locale = NativeModules.SettingsManager.settings.AppleLanguages[0]

    if (locale === undefined) {
      locale = fallbackLanguage
    }
  }

  return locale
}

type LocaleType = {
  native?: string;
  language?: string;
  country?: string;
  splitted?: string[];
}

export function getLocaleLanguage() {
  const locale: LocaleType = {}
  if (NativeModules) {
    switch (Platform.OS) {
      case 'android':
        locale.native = NativeModules.I18nManager.localeIdentifier
        break
      case 'ios':
        locale.native = getAppleLocale()
        break
      case 'web':
        locale.native = navigator.language.split(/[-_]/)[0]
        break
      default:
        locale.native = fallbackLanguage
        break
    }
  }

  locale.native = locale?.native?.toLowerCase().replace('_', '-')

  if (!locale.native) {

    locale.native = fallbackLanguage
    return locale
  }

  locale.splitted = locale.native.split('-')

  locale.language = locale.splitted[0]
  locale.country = locale.splitted[locale.splitted.length - 1]

  return locale
}

