import {
  NewsFeedTypeState
} from '../types';
import {
  ProfileErrorState
} from '@modules/profile/types';

export enum HistoryActionsType {
  GET_NEWS_FEED_REQUEST = 'GET_NEWS_FEED_REQUEST',
  GET_NEWS_FEED_SUCCESS = 'GET_NEWS_FEED_SUCCESS',
  GET_NEWS_FEED_FAILURE = 'GET_NEWS_FEED_FAILURE',
  INIT_NEWS_FEED_LIST = 'INIT_NEWS_FEED_LIST'
}

// news feed
export interface GetNewsFeedRequestAction {
  type: HistoryActionsType.GET_NEWS_FEED_REQUEST,
}

export interface GetNewsFeedSuccessAction {
  type: HistoryActionsType.GET_NEWS_FEED_SUCCESS,
  newsFeed: NewsFeedTypeState[],
}

export interface GetNewsFeedFailureAction {
  type: HistoryActionsType.GET_NEWS_FEED_FAILURE,
  error: ProfileErrorState
}

export interface InitNewsFeedListAction {
  type: HistoryActionsType.INIT_NEWS_FEED_LIST,
}

export type HistoryActions =
  | GetNewsFeedRequestAction
  | GetNewsFeedSuccessAction
  | GetNewsFeedFailureAction
  | InitNewsFeedListAction
