import { connect } from 'react-redux';

import { AppState } from '@modules/index';
import { getApplicationSettings } from '@modules/settings/selectors';

import { OneTrustScriptComponent } from './OneTrustScript.component';

const mapStateToProps = (state: AppState) => {
  return {
    applicationSettings: getApplicationSettings(state),
  };
};

const mapDispatchToProps = {
};

export const OneTrustScript = connect(mapStateToProps, mapDispatchToProps)(OneTrustScriptComponent);
