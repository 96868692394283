import { HtmlStyledRenderConnectedContainer, LegalMenuFooterConnected, OfflinePageLayout, SimpleButton, ThemeType, Title, useScreenSizes, useTheme, useTranslation
} from '@components/index';
import { createUseStyles } from '@ere-uilib/styles';

import {
  AcceptConsentsRequestAction
} from '@modules/profile/actions/profileActionsTypes';
import {
  ConsentsState,
  ProfileErrorState
} from '@modules/profile/types';
import {useLogoNavigation} from '@utils/index';
import React from 'react';
import {ScrollView, StyleProp, TextStyle, View, ViewStyle} from 'react-native';


interface Props {
  consents: ConsentsState[];
  onAcceptCGU: (code: string) => AcceptConsentsRequestAction;
  isAcceptLoading: boolean;
  isGetAccountsLoading: boolean;
  error: ProfileErrorState;
  containerStyle?: StyleProp<ViewStyle>;
  scrollViewContainerStyle?: StyleProp<ViewStyle>;
  titleStyle?: StyleProp<TextStyle>;
  bottomViewStyle?: StyleProp<ViewStyle>;
  errorTitleStyle?: StyleProp<TextStyle>;
}

export type CGUComponentStyles = {
  containerStyle?: StyleProp<ViewStyle>;
  scrollViewContainerStyle?: StyleProp<ViewStyle>;
  scrollViewStyle?: StyleProp<ViewStyle>;
  titleStyle?: StyleProp<TextStyle>;
  legalMenuContainer?: StyleProp<ViewStyle>;
  fullSpaceContainer?: StyleProp<ViewStyle>;
  bottomViewStyle?: StyleProp<ViewStyle>;
  errorTitleStyle?: StyleProp<TextStyle>;
};

export type StylesContext = {
  theme: ThemeType;
  isMobile: boolean;
  isTablet: boolean;
};

export const CGUComponent: React.FC<Props> = props => {
  const {
    containerStyle,
    scrollViewContainerStyle,
    titleStyle,
    bottomViewStyle,
    errorTitleStyle,
    onAcceptCGU,
    consents,
    isAcceptLoading,
    isGetAccountsLoading,
    error,
  } = props;
  const theme = useTheme();
  const { isMobile, isTablet, isDesktop } = useScreenSizes();
  const getStyles = (
    context?: StylesContext,
    style?: CGUComponentStyles,
  ): CGUComponentStyles => ({
    containerStyle: [
      {
        flex: 1,
        flexDirection: 'column',
        paddingBottom: context?.theme.metrics.spacing.s,
      },
      (context?.isMobile || context?.isTablet) && {
        marginLeft: context?.theme.metrics.spacing.s,
        marginRight: context?.theme.metrics.spacing.s,
      },
      style?.containerStyle,
    ],
    legalMenuContainer: [{
      paddingTop: context?.theme.metrics.spacing.s,
    }],
    fullSpaceContainer: [{
      flex: 1,
    }],
    scrollViewContainerStyle: [
      {
        flex: 1,
        paddingLeft: context?.theme.metrics.spacing.huge,
        paddingRight: context?.theme.metrics.spacing.xbig,
        paddingBottom: context?.theme.metrics.spacing.xbig,
      },
      context?.isMobile  && {
        paddingLeft: context?.theme.metrics.spacing.s,
        paddingRight: context?.theme.metrics.spacing.s,
      },
      style?.scrollViewContainerStyle,
    ],
    scrollViewStyle: [
      {
        flex: 1,
      },
      !(context?.isMobile || context?.isTablet) && { maxHeight: 400 },
    ],
    titleStyle: [
      {
        fontFamily: context?.theme.fonts.fontFamily.bold,
        marginBottom: context?.theme.metrics.spacing.s,
        marginTop: context?.theme.metrics.spacing.huge,
      },
      !(context?.isMobile ) && {
        marginTop: context?.theme.metrics.spacing.xhuge,
        paddingLeft: context?.theme.metrics.spacing.huge,
        paddingBottom: context?.theme.metrics.spacing.xl,
      },
      style?.titleStyle,
    ],
    bottomViewStyle: [
      {
        marginTop: context?.theme.metrics.spacing.xm,
      },
      !(context?.isMobile || context?.isTablet) && {
        alignItems: 'flex-start',
      },
      style?.bottomViewStyle,
    ],
    errorTitleStyle: [
      {
        color: context?.theme.colors.basics.message.error.c500,
      },
      style?.errorTitleStyle,
    ],
  });

  const useStyles = createUseStyles(getStyles);
  const styles = useStyles({ theme, isMobile, isTablet }, { containerStyle, scrollViewContainerStyle, titleStyle, bottomViewStyle, errorTitleStyle });
  const { formatMessage } = useTranslation();
  const CGU = consents.find(item => item.code === 'CGU');
  const { logoNavigateTo } = useLogoNavigation();
  const renderLegalMenuFooter = ()=>{
    return <LegalMenuFooterConnected isWhite={isDesktop}/>;
  };
  return (
    <OfflinePageLayout
      onLogoPress={logoNavigateTo}
      renderDesktopCardBelowContent={renderLegalMenuFooter}
      renderMobileStickyGradientFooterContent={renderLegalMenuFooter}
    >
      <Title
        style={styles.titleStyle}
        variant="t4"
      >
        {formatMessage({ id: 'cgu_menu' })}
      </Title>
      <View style={styles.fullSpaceContainer}>
        <ScrollView
          contentContainerStyle={styles.scrollViewContainerStyle}
          style={styles.scrollViewStyle}>
          {CGU?.content && (
            <HtmlStyledRenderConnectedContainer
              html={CGU.content}
            />
          )}
          <View style={styles.bottomViewStyle}>
            {(!CGU?.isAccepted || isGetAccountsLoading ) && (
              <SimpleButton
                loading={isAcceptLoading || isGetAccountsLoading}
                onPress={() => {
                  onAcceptCGU('CGU');
                }}
                title={formatMessage({ id: 'cgu_button' })}
              />
            )}
            {error && error?.innerMessage !== "" && (
              <Title
                style={styles.errorTitleStyle}
                variant="t6">
                {error?.innerMessage}
              </Title>
            )}
          </View>
        </ScrollView>
      </View>
    </OfflinePageLayout>
  );
};
