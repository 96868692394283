import axios from 'axios'

import { API_URL } from '@config/index'
import { DashboardState } from '@modules/dashboard/types'

export async function fetchAccounts(): Promise<DashboardState> {
  return axios.get(`${API_URL}/api/v1/companies`)
}

export async function fetchAvailabilities(companyId: string): Promise<DashboardState> {
  return axios.get(`${API_URL}/api/v1/companies/${companyId}/availabilities`)
}

export async function fetchAllocations(companyId: string): Promise<DashboardState> {
  return axios.get(`${API_URL}/api/v1/companies/${companyId}/allocations`)
}
/**
 *
 * @param flow
 * @returns
 *
 * @mock
 * import { mockTools } from '@__mocks__/mockTools';
 *
 * return new Promise((resolve) => {
 *    return resolve({ data: mockTools })
 * })
 */

export async function fetchTool(flow: string | null): Promise<any> {
  return axios.get(`${API_URL}/api/v1/application/tools`, {
    params: {
      ...({ flow } || {})
    }
  })
}

export async function fetchOCD(companyId: string): Promise<DashboardState> {
  return axios.get(`${API_URL}/api/v1/crm/ocd/company/${companyId}`)
}

export async function fetchRecentOperations(companyId: string): Promise<DashboardState> {
  return axios.get(`${API_URL}/api/v1/companies/${companyId}/operations?take=3`)
}

export async function fetchContributionByCompany(companyId: string): Promise<DashboardState> {
  return axios.get(`${API_URL}/api/v1/companies/${companyId}/contributions`)
}

export async function fetchContributionByOperation(companyId: string): Promise<DashboardState> {
  return axios.get(`${API_URL}/api/v1/companies/${companyId}/contributions-optimization`)
}

export async function fetchBudgetInsightUrl(): Promise<DashboardState> {
  return axios.get(`${API_URL}/api/v1/application/providers/budgetinsight/connect`)
}

export function fetchGetBudgetInsightAccounts() {
  return axios.get(`${API_URL}/api/v1/application/providers/budgetinsight`)
}

export async function fetchBudgetInsightManagerUrl(): Promise<DashboardState> {
  return axios.get(`${API_URL}/api/v1/application/providers/budgetinsight/Manage`)
}

export async function getSSORedirectionUrlApi(): Promise<DashboardState> {
  return axios.get(`${API_URL}/api/v1/votaccess`)
}
