import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { useState } from 'react';
import { View, StyleProp, ViewStyle, TextStyle } from 'react-native';

import {
  SimpleButton,
  ChooseIdentificationMail,
  useTheme,
  ThemeType,
  useScreenSizes,
  EERPageWrapperContainer,
  EERContentWrapper,
  EERKeyboardAvoidingViewPageWrapper,
} from '@components/index';
import { NotificationSoft } from '@ere-uilib/molecules';
import { createUseStyles } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';
import { InvitationState } from '@modules/invitation/types';
import { ApplicationSettingsState } from '@modules/settings/types';
import { IdentityValidationErrorState } from '@modules/validate-identity/types';
import { locator } from '@constants/locator';

interface Props {
  validateEEREmailNewIdentifier: (email: string) => void
  isApiLoading: boolean
  invitation: InvitationState
  validateIdentityError: IdentityValidationErrorState
  appSettings: ApplicationSettingsState
}

type ChooseIdentificationMailPageStyles = {
  fullFlexView?: StyleProp<ViewStyle>
  footerContentContainer?: StyleProp<ViewStyle>
  containerStyle?: StyleProp<ViewStyle>
  contentStyle?: StyleProp<ViewStyle>
  buttonStyle?: StyleProp<ViewStyle>
  buttonsRowStyle?: StyleProp<ViewStyle>
  logoStyle?: StyleProp<ViewStyle>
  substepStyle?: StyleProp<TextStyle>
};

type StylesContext = { theme: ThemeType; isMobile: boolean; isTablet: boolean; };

export const ChooseIdentificationMailPageComponent: React.FC<React.PropsWithChildren<Props>> = ({
  validateEEREmailNewIdentifier,
  isApiLoading,
  validateIdentityError,
  appSettings,
}) => {
  const navigation = useNavigation();
  const theme = useTheme();
  const { isMobile, isTablet, isDesktop } = useScreenSizes();
  const styles = useStyles({ theme, isMobile, isTablet }, {});
  const [disabled, setDisabled] = useState(false);
  const [email, setEmail] = useState('');

  const handleSubmit = () => {
    if (!(disabled || isApiLoading)) {
      validateEEREmailNewIdentifier(email);
    }
  };
  const { formatMessage, getMessageRaw } = useTranslation();

  const renderActions = () => {
    return (
      <View style={styles.buttonsRowStyle}>
        <View style={styles.buttonStyle}>
          <SimpleButton
            disabled={disabled || isApiLoading}
            loading={isApiLoading}
            onPress={() => validateEEREmailNewIdentifier(email)}
            title={formatMessage({ id: 'choose-login_button' })}
            testId={locator._account_activation._confirm_user_name}
          />
        </View>
      </View>
    );
  };
  return (

    <EERKeyboardAvoidingViewPageWrapper>
      <EERPageWrapperContainer
        activeStep={2}
        displayCloseButton={false}
        helpPoint
        helpPointContentHtml={getMessageRaw({ id: 'eer_info_content' })}
        helpPointModalTitle={formatMessage({ id: 'eer_info_title' })}
        helpPointPosition="left"
        pageTitle={formatMessage({ id: 'verify-identity_menu' })}
        renderStickyMobileFooterContent={renderActions}
      >
        <EERContentWrapper
          title={formatMessage({ id: 'choose-login_title' })}
        >
          <View style={styles.contentStyle}>
            <View style={styles.fullFlexView}>
              <ChooseIdentificationMail
                appSettings={appSettings}
                onSubmit={handleSubmit}
                setDisable={setDisabled}
                setEmailValue={setEmail}
                testId={locator._account_activation._create_account}
              />
              {validateIdentityError.message !== '' && (
                <NotificationSoft
                  text={validateIdentityError.message}
                  type="warning"
                />)
              }
              {!(isMobile || isTablet) && renderActions()}
            </View>
          </View>
        </EERContentWrapper>
      </EERPageWrapperContainer>
    </EERKeyboardAvoidingViewPageWrapper>
  );
};

const getStyles = (
  context?: StylesContext,
): ChooseIdentificationMailPageStyles => ({
  containerStyle: [
    !(context?.isMobile || context?.isTablet) ?
      { flex: 1, flexDirection: 'row' }
      : { flex: 1, flexDirection: 'column', maxWidth: 450 },
  ],
  contentStyle: [
    !(context?.isMobile || context?.isTablet) ?
      {
        flex: 2.1,
        justifyContent: 'center',
      }
      : {
        flex: 1,
        marginRight: context?.theme.metrics.spacing.none,
      },
  ],
  buttonsRowStyle: [
    !(context?.isMobile || context?.isTablet) ?
      {
        flexDirection: 'row',
      }
      : {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        marginTop: context?.theme.metrics.spacing.xm,
        paddingHorizontal: context?.theme.metrics.spacing.s,
      },
  ],
  footerContentContainer: [
    {
      paddingHorizontal: context?.theme.metrics.spacing.xm,
    },
  ],
  fullFlexView: [
    {
      flex: 1,
    },
    (context?.isMobile || context?.isTablet) ?
      {
        alignItems: 'center',
      } : {
        marginTop: context?.theme.metrics.spacing.none,
      },
  ],
  logoStyle: { flex: 1, maxWidth: 316, maxHeight: 120 },
  substepStyle: { color: context?.theme.colors.basics.primary.c500 },
  buttonStyle:
    !(context?.isMobile || context?.isTablet) ?
      {
        marginTop: context?.theme.metrics.spacing.xm,
        marginRight: context?.theme.metrics.spacing.xm,
      }
      : { marginTop: context?.theme.metrics.spacing.xm },
});

const useStyles = createUseStyles(getStyles);
