import React, { useCallback, useEffect } from 'react'
import { View } from 'react-native'

import { WebViewProps } from './interface'
import { WebViewSourceTypeEnum } from './sharedInterfaces'
// TODO: créer un autre webview dédié QuickSign en exploitant le onMessage() du webview d'origine.
export const WebView = ({
  style,
  sourceHtml,
  sourceUri,
  sourceType = WebViewSourceTypeEnum.URI,
  onMessage,
  onQuisignMessage,
  isQuickSign,
  scrollEnabled = true,
  className = 'optanon-category-C0001'
}: WebViewProps) => {
  const handleMessage = useCallback(
    (message: MessageEvent) => {
      const data = message.data.signatureEnd
      if (isQuickSign) {
        if (typeof data === 'object' && data) {
          onQuisignMessage?.(data.success, data.errorCode)
        }
      }
      if (typeof data === 'string' && data) {
        onMessage?.(data)
      }
    },
    [onMessage]
  )

  useEffect(() => {
    window.addEventListener('message', handleMessage, true)
    return () => {
      window.removeEventListener('message', handleMessage, true)
    }
  }, [handleMessage])

  let finalHtml = sourceHtml?.html
  if (finalHtml) {
    // to make iframe link to current page instead of in iframe
    if (sourceHtml?.linkOpenedToTop) {
      // this <base> html tag allow to define default link target, "_top" target upper page
      const linkToTop = '<base target="_top" />'
      const isHeadTags = finalHtml.includes('</head>')
      if (isHeadTags) {
        finalHtml = finalHtml.replace('</head>', linkToTop + '</head>')
      } else {
        finalHtml = linkToTop + finalHtml
      }
    }
  }

  const src = sourceType === WebViewSourceTypeEnum.URI ? sourceUri?.uri : undefined
  const srcDoc = sourceType === WebViewSourceTypeEnum.HTML ? finalHtml : undefined

  return (
    <View style={style}>
      <iframe
        className={className}
        frameBorder="0"
        height="100%"
        src={src}
        srcDoc={srcDoc}
        style={{
          ...(!scrollEnabled && {
            overflow: 'hidden',
            height: '100%'
          })
        }}
        width="100%"
      />
    </View>
  )
}
