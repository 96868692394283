import React, { useMemo } from 'react';
import { StyleSheet, StyleProp, ViewStyle } from 'react-native';

import { useTheme } from '@ere-uilib/styles';
export const useStyles = ({
  backGroundColorCustom,
  isFundsDisabled,
  mainStyle,
  disabled,
}: {
  backGroundColorCustom?: string
  isFundsDisabled?: boolean
  disabled?: boolean
  mainStyle: StyleProp<ViewStyle>
}) => {
  const theme = useTheme();

  const styles = useMemo(() => StyleSheet.create({
    contentStyle: {
      alignItems: 'flex-start',
      backgroundColor: theme.colors.basics.white,
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowRadius: 9,
      shadowColor: theme.colors.notifications.shadow,
      elevation: 1,
      minHeight: 'auto',
    },
    commonLabelTextStyle: {
      ...(disabled && {
        color: theme.colors.basics.grey.c500,
      })
    },
    commonValueTextStyle: {
      fontFamily: theme.fonts.fontFamily.regular,
      ...(disabled && {
        color: theme.colors.basics.grey.c500,
      })
    },
    mainStyle: {
      backgroundColor:
        (isFundsDisabled && theme.colors.basics.grey.c200) ||
        backGroundColorCustom ||
        theme.colors.basics.grey.c100,
      ...StyleSheet.flatten(mainStyle)
    },
    titleTextStyle: {
      marginBottom: theme.metrics.spacing.none,
    },
    cardTitleContainerStyle: {
      flex: 1,
      alignSelf: 'flex-start',
    },
    cardTitleTextStyle: {
      marginBottom: theme.metrics.spacing.none,
      ...(disabled && {
        color: theme.colors.basics.grey.c500,
      })
    },
    cardTitleCheckBoxContainer: {
      flexDirection: 'row',
      marginBottom: theme.metrics.spacing.xs,
    },
    iconContainerStyle: {
      marginRight: theme.metrics.spacing.m,
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: theme.metrics.borderRadius.xs,
      width: 40,
      height: 40,
    },
    bodyContainerStyle: {
      alignSelf: 'flex-start',
      width: '100%',
      ...(isFundsDisabled ? {
        backgroundColor: theme.colors.basics.grey.c100,
        opacity: 0.6,
      } : {
        backgroundColor: theme.colors.basics.white,
      })
    },
    managementDetailContainer: {
      padding: theme.metrics.spacing.s,
      paddingBottom: theme.metrics.spacing.m,
      flexDirection: 'row'
    },
    managementDetailLeftContainer: {
      flex: 1
    },
    managementDetailRightContainer: {

    },
    subTitle: {
      color: theme.colors.basics.black,
    },
    checkBoxStyle: {
      marginRight: theme.metrics.spacing.xs,
    },
    checkBoxContainer: {
      flexDirection: 'row',
      marginBottom: theme.metrics.spacing.none,
    },
    titleStyle: {
      marginBottom: theme.metrics.spacing.xs,
      alignSelf: 'center',
      width: '100%',
      ...isFundsDisabled && {
        color: theme.colors.basics.grey.c500
      }
    },
    headerStyle: {
      width: '100%',
      padding: theme.metrics.spacing.s,
      flexDirection: 'row',
      ...(isFundsDisabled && {
        //
      })
    },
    headerLeftPartStyle: {
      flex: 1
    },
    chevronStyle: {
      alignSelf: 'center',
      paddingLeft: theme.metrics.spacing.s,
    },
    linkStyle:
    {
      marginVertical: theme.metrics.spacing.xs,
      fontSize: theme.fonts.fontSize.paragraph.t3
    },
    textStyle: {},
    pilotageTextLink:
    {
      fontSize: theme.fonts.fontSize.paragraph.t3
    },
  }), [
    theme,
    backGroundColorCustom,
    disabled,
    isFundsDisabled,
    mainStyle
  ])

  return styles;
};
