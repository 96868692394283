import {
  AuthActionType,
  AuthenticateAction
} from '@modules/auth/actions/authActionsTypes';
import { InvitationAction, InvitationActionsType } from '@modules/invitation/actions/invitationActionsTypes';
import { ErrorActionsType } from '@modules/error/actions/errorActionsTypes';
import { ValidateIdentityState } from './types';
import { ValidateIdentityAction, ValidateIdentityActionsType } from './actions/validateIdentityActionsTypes';

export const initialState: ValidateIdentityState = {
  inputEmail: '',
  inputPhone: '',
  registrationConfirm: {
    data: null,
  },
  franceConnectRegistration: {
    franceConnectRegistrationBody: {
      redirectUrl: '',
      state: '',
      },
  },
  error: {
      code: '',
      correlationId: '',
      message: '',
      innerMessage: '',
  },
};

export default function validateIdentityReducer(
    state: ValidateIdentityState = initialState,
    action: ValidateIdentityAction | AuthenticateAction | InvitationAction,
): ValidateIdentityState {
  switch (action.type) {
  case ValidateIdentityActionsType.SET_EER_NEW_EMAIL_INPUT:
    return {
      ...state,
      inputEmail: action.email,
    };
  case ValidateIdentityActionsType.SET_EER_NEW_PHONE_INPUT:
    return {
      ...state,
      inputPhone: action.phone,
    };
  case ValidateIdentityActionsType.CONFIRIM_REGISTRATION_SUCCESS:
    return {
      ...state, registrationConfirm: { data: action.data },
    };
  case ValidateIdentityActionsType.FRANCE_CONNECT_CREATE_IDENTIY_VALIDATION_SUCCESS:
    return {
      ...state, franceConnectRegistration: {
        franceConnectRegistrationBody: action.data,
      },
    };
  case ValidateIdentityActionsType.VALIDATE_EER_PHONE_NEW_IDENTIFIER_FAILURE:
  case ValidateIdentityActionsType.VALIDATE_EER_PHONE_NEW_IDENTIFIER_CODE_FAILURE:
  case ValidateIdentityActionsType.VALIDATE_EER_EMAIL_NEW_IDENTIFIER_FAILURE:
  case ValidateIdentityActionsType.VALIDATE_EER_EMAIL_NEW_IDENTIFIER_CODE_FAILURE:
    return {
      ...state,
      error: action.error,
    };
  case ValidateIdentityActionsType.CONFIRIM_REGISTRATION_FAILURE:
  case ValidateIdentityActionsType.SEND_EER_IDENTITY_VALIDATION_CODE_FAILURE:
  case ValidateIdentityActionsType.CHECK_EER_IDENTITY_VALIDATION_CODE_FAILURE:
    return {
      ...state,
      error: action.error,
    };
  case ValidateIdentityActionsType.INIT_ERROR:
  case ErrorActionsType.INIT_ALL_ERRORS:
    return {
      ...state,
      error: initialState.error,
    };
  case AuthActionType.LOGOUT_CALLBACK_SUCCESS:
    return initialState;
  case InvitationActionsType.GET_INVITATION:
    return initialState;
  default:
    return state;
  }
}
