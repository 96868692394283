import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

export const useStyles = () => {
  const theme = useTheme();

  const styles = useMemo(
    () =>
      StyleSheet.create({
        containerStyle: {
          marginVertical: theme.metrics.spacing.xs,
        },
        selectContainerStyles: {
          marginBottom: theme.metrics.spacing.xm,
          position: 'relative',
        }
      }),
    [theme]
  );

  return styles;
}
