import { connect } from 'react-redux';

import { AppState } from '@modules/index';
import { putSelectedCompanyRequest } from '@modules/dashboard/actions/dashboardActions';
import {
  getAccountsSelector,
} from '@modules/dashboard/selectors';
import { getIsFeatureRegroupementContratsEnabled } from '@modules/settings/selectors';

import { CompanyChoicePageComponent } from './CompanyChoicePage.component';

const mapStateToProps = (state: AppState) => {
  return {
    userAccount: getAccountsSelector(state),
    EnableFeatureRegroupementContrats: getIsFeatureRegroupementContratsEnabled(state),
  };
};

const mapDispatchToProps  = ({
  onSelectCompany: putSelectedCompanyRequest,
});

export const CompanyChoicePage = connect(mapStateToProps, mapDispatchToProps)(CompanyChoicePageComponent);
