import React, { ReactNode, useCallback } from 'react';
import { View } from 'react-native';

import {
  EERContentWrapper,
  EERPageWrapperContainer,
  TextIdentifierDictionaryType,
  BackAndConfirmButtons,
  useScreenSizes,
  SimpleButton,
  IdentifierFormNotification,
  EERKeyboardAvoidingViewPageWrapper,
} from '@components/index';

import { useStyles } from './useStyles';

interface Props {
  onPressBackButton?: () => void;
  onClose: () => void;
  onPressSubmit: () => void;
  dictionary: TextIdentifierDictionaryType;
  errorMessage?: string;
  isLoading?: boolean;
  identifierRegex?: string;
  renderContent: () => ReactNode;
  submitButtonActive?: boolean;
  displayCloseButton?: boolean;
  moveUpContentWithKeyboard?: boolean;
}

export function SelfcareIdentifierFormTemplate({
  onPressBackButton,
  onClose,
  onPressSubmit,
  dictionary,
  errorMessage,
  isLoading,
  renderContent,
  submitButtonActive,
  displayCloseButton,
  moveUpContentWithKeyboard
}: Props) {
  const styles = useStyles();
  const { isDesktop } = useScreenSizes();

  const renderDesktopBottomButtons = useCallback(() => {
    return (
      <SimpleButton
        containerStyle={styles.bottomButtons}
        disabled={!submitButtonActive}
        loading={isLoading}
        onPress={onPressSubmit}
        title={dictionary.confirmButton}
        testId='confirm'
      />
    );
  }, [dictionary, isLoading, onPressSubmit, styles, submitButtonActive]);

  const renderNotification = useCallback(() => {
    if (!errorMessage) {
      return null;
    }

    return (
      <View style={styles.errorContainerWrapper}>
        <IdentifierFormNotification
          errorHelperText={dictionary.errorHelper}
          helpPointContentHtml={dictionary.errorReadMoreContentHtml}
          helpPointModalTitle={dictionary.errorReadMoreModalTitle}
          message={errorMessage}
          reedMoreText={dictionary.reedMore}
        />
      </View>
    );
  }, [dictionary, errorMessage]);

  const renderMobileBottomButtons = useCallback(() => {
    return (
      <BackAndConfirmButtons
        confirmButtonDisabled={!submitButtonActive}
        confirmButtonLabel={dictionary.confirmButton}
        containerStyle={styles.bottomButtons}
        isLoading={isLoading}
        onBack={onPressBackButton}
        onValidate={onPressSubmit}
      />
    );
  }, [submitButtonActive, dictionary, styles, isLoading, onPressBackButton, onPressSubmit]);

  return (
    <EERKeyboardAvoidingViewPageWrapper>
      <EERPageWrapperContainer
        activeStep={1}
        displayCloseButton={displayCloseButton}
        onPressCloseButton={onClose}
        renderStickyMobileFooterContent={renderMobileBottomButtons}>
        <EERContentWrapper
          backButtonTitle={onPressBackButton ? dictionary.backButton : undefined}
          containerStyle={[styles.contentContainer]}
          onPressBackButton={onPressBackButton}
          title={dictionary.pageTitle}
          titleStyle={styles.titleStyle}>
          {renderContent()}
          {!!errorMessage && renderNotification()}
          {isDesktop && (
            <View style={styles.bottomButtonsContainer}>{renderDesktopBottomButtons()}</View>
          )}
        </EERContentWrapper>
      </EERPageWrapperContainer>
    </EERKeyboardAvoidingViewPageWrapper>
  );
}
