import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useScreenSizes, useTheme } from '@ere-uilib/styles';

export function useStyles() {
  const theme = useTheme();
  const { isDesktop } = useScreenSizes();

  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: { 
          flex: 1 
        },
        contentContainer: {
          flex: 2.1,
          marginHorizontal: isDesktop ? theme.metrics.spacing.xxl : 0
        },
        bottomButtons: {
          marginTop: isDesktop ? theme.metrics.spacing.l : theme.metrics.spacing.s
        },
        bottomButtonsContainer: {
          alignSelf: 'flex-start',
          ...(isDesktop && { paddingBottom: theme.metrics.spacing.m })
        },
        titleStyle: {
          textAlign: isDesktop ? undefined : 'left'
        },
        errorContainerWrapper: {
          ...(!isDesktop && { paddingBottom: theme.metrics.spacing.m })
        }
      }),
    [theme, isDesktop]
  );

  return styles;
}
