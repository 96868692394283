import {Context, Styles} from './interfaces';

export const getStyles = (context?: Context, style?: Styles): Styles => ({
  containerStyle: [
    {
      backgroundColor: context?.theme.colors.basics.white
    },
    style?.containerStyle
  ],
  WebViewStyle: [
    {
      flex: 1
    },
    style?.WebViewStyle
  ]
});
