import { AuthActionType, AuthenticateAction } from '@modules/auth/actions/authActionsTypes'
import { ErrorActionsType } from '@modules/error/actions/errorActionsTypes'

import { CommonRefundAction } from '../actions/refundActionsTypes'
import { CommonRefundActionsType } from '../types'
import { UnavailableRefundAction, UnavailableRefundActionsType } from './actions/refundActionsTypes'
import { UnavailableRefundState } from './types'

const initialState: UnavailableRefundState = {
  calculatedContributionError: {
    code: '',
    correlationId: '',
    message: '',
    innerMessage: ''
  },
  refundUnavailabilityDecisionTree: {
    id: '',
    response: '',
    next: {
      question: '',
      responses: []
    }
  },
  error: {
    code: '',
    correlationId: '',
    message: '',
    innerMessage: ''
  },
  unavailableRefundDispositifs: null,
  unavailabilitySignatureDateResponse: null,
  refundUnavailableMotifId: null
}

// selectors

export default function unavailableRefundReducer(
  state: UnavailableRefundState = initialState,
  action: UnavailableRefundAction | AuthenticateAction | CommonRefundAction
): UnavailableRefundState {
  switch (action?.type) {
    case UnavailableRefundActionsType.GET_REFUND_UNAVAILABILITY_DECISION_TREE_SUCCESS:
      return {
        ...state,
        refundUnavailabilityDecisionTree:
          action.unavailabilityDecisionTreeResponse.refundUnavailabilityDecisionTree
      }
    case UnavailableRefundActionsType.GET_REFUND_UNAVAILABILITY_DECISION_TREE_FAILURE:
      return {
        ...state,
        error: action.error
      }
    case UnavailableRefundActionsType.GET_UNAVAILABLE_REFUND_DISPOSITIFS_REQUEST:
      return {
        ...state,
        unavailableRefundDispositifs: initialState.unavailableRefundDispositifs
      }
    case UnavailableRefundActionsType.GET_UNAVAILABLE_REFUND_DISPOSITIFS_SUCCESSS:
      return {
        ...state,
        unavailableRefundDispositifs: action.unavailableRefundDispositifs,
        refundUnavailableMotifId: action.motifId
      }
    case UnavailableRefundActionsType.GET_UNAVAILABLE_REFUND_SIGNATURE_DATE_REQUEST:
      return {
        ...state,
        unavailabilitySignatureDateResponse: initialState.unavailabilitySignatureDateResponse
      }
    case UnavailableRefundActionsType.GET_UNAVAILABLE_REFUND_SIGNATURE_DATE_SUCCESS:
      return {
        ...state,
        unavailabilitySignatureDateResponse: action.unavailabilitySignatureDateResponse
      }
    case UnavailableRefundActionsType.GET_UNAVAILABLE_REFUND_DISPOSITIFS_FAILURE:
      return {
        ...state,
        error: action.error
      }
    case CommonRefundActionsType.REFUND_INIT_ERROR:
    case ErrorActionsType.INIT_ALL_ERRORS:
      return {
        ...state,
        error: initialState.error,
        calculatedContributionError: initialState.calculatedContributionError
      }
    case CommonRefundActionsType.CLEAR_REFUND_DATA:
    case AuthActionType.LOGOUT_CALLBACK_SUCCESS:
      return initialState
    default:
      return state
  }
}
