import React, { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useScreenSizes, useTheme } from '@ere-uilib/styles';

export const useStylees = () => {
  const theme = useTheme();
  const { isMobile } = useScreenSizes();
  const styles = useMemo(() => StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'center',
      backgroundColor: theme.colors.background.poppinFilter.c500,
      width: "100%",
      ...(isMobile ?
        { justifyContent: 'flex-end', } :
        { justifyContent: 'center', }
      )
    },
    overlay: {
      ...StyleSheet.absoluteFillObject,
      flex: 1,
    },
    modalIconContainer: {
      alignSelf: 'center',
    },
    modalTextContainer: {
      marginTop: theme.metrics.spacing.xm,
      marginBottom: theme.metrics.spacing.l,
      alignItems: 'center',
      justifyContent: 'center',
    },
    modalTitle: {
      alignItems: 'center',
      textAlign: 'center',
      marginBottom: theme.metrics.spacing.s,
    },
    modalText: {
      textAlign: 'center',
    },
    modalFirstActionStyle: {
      marginBottom: theme.metrics.spacing.xm,
      backgroundColor: theme.colors.basics.white,
    },
  }), [isMobile, theme])

  return styles
};
