import { RetirementPlanDocumentType } from "@modules/retirement/types";

export interface RetirementRecoverSavingPageProps {
  retirementDecisionForm: RetirementPlanDocumentType[]
  onSubmit: (documentType: string, documentTitle: string) => void
  isApiLoading: boolean
}

export enum TypeOfSendEnum {
  SentByPost = "POSTAL",
  BoneOnline = 'ONLINE',
  ReferralToTheCompany = 'MANUAL',
}
