import { useTheme } from "@ere-uilib/styles";
import { useMemo } from "react"
import { StyleSheet } from "react-native"

export const useStyles = () => {
  const theme = useTheme();

  const styles = useMemo(() => StyleSheet.create({
    operationDetails: {
      marginBottom: theme.metrics.spacing.xxm
    },
    operationTitleText: {
      marginBottom: theme.metrics.spacing.xs
    },
    dateText: {
      color: theme.colors.text.disabled.c500
    }
  }), [
    theme
  ]);

  return styles;
}