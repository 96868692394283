import { Platform } from "react-native";

import { REGEX } from "@constants/regex";
import { formatHref } from "@modules/utils/ocdMethods";

export const useFormatHrefHtml = (content: string) => {

  if (REGEX.HREFURL.test(content) && Platform.OS !== 'web') {
    return formatHref(content);
  }
  return content;

};