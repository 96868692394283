import * as Yup from 'yup';

import { AddressDetail } from '@modules/common/types';

import { FormValues } from '../types';

export const validationSchema: Yup.ObjectSchema<FormValues, Yup.AnyObject, {}, ''> = Yup.object({
  additionalAddress_1: Yup.string().max(255),
  additionalAddress_3: Yup.string().max(255),
  street: Yup.string().max(255).required(),
  zipcode: Yup.string().max(255).required(),
  city: Yup.string().max(255).required(),
  country: Yup.object().shape({
    value: Yup.string().required(),
    label: Yup.string().required(),
    defaultLabel: Yup.string().required()
  })
});

export const getInitialValues = (address: AddressDetail | null): FormValues => ({
  additionalAddress_1: address?.additionalAddress_1 || '',
  additionalAddress_3: address?.additionalAddress_3 || '',
  street: address?.street || '',
  zipcode: address?.zipcode || '',
  city: address?.city || '',
  country: {
    label: address?.country || '',
    value: address?.countryCode || 'FR',
    defaultLabel: address?.country?.toUpperCase() || 'FRANCE'
  }
});
