import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Notification } from '@ere-uilib/types/notificationSystemTypes';
import { addNotification, removeNotification } from '@modules/notification-system/actions/notificationSystemActions';
import { AppState } from '@modules/reducers';

export function useNotificationSystem() {
  const notificationSystem = useSelector((state: AppState) => state.notificationSystem);
  const dispatch = useDispatch();

  const dispatchAddNotification = useCallback((notification: Notification) => {
    return dispatch(addNotification(notification));
  }, [dispatch]);

  const dispatchRemoveNotification = useCallback((uid: string) => {
    return dispatch(removeNotification(uid));
  }, [dispatch]);

  return {
    notifications: notificationSystem.notifications,
    addNotification: dispatchAddNotification,
    removeNotification: dispatchRemoveNotification,
  };
}
