import React from 'react';
import { View } from 'react-native';

import { createUseStyles, useScreenSizes, useTheme } from '@ere-uilib/styles';

import { InnerPageContainerProps } from './interfaces';
import { getStyles } from './styles';

export const InnerPageContainer: React.FC<React.PropsWithChildren<InnerPageContainerProps>> = ({ children, isApiLoading }) => {
  const isMobile = useScreenSizes().isMobile;
  const isTablet = useScreenSizes().isTablet;
  const theme = useTheme();
  const styles = useStyles({
    theme,
    isMobile: isMobile || isTablet
  }, {});
  return (
    <View
      style={styles.container}
    >
      {children}
    </View>
  );
};

const useStyles = createUseStyles(getStyles);

// export default InnerPageContainer
