import { createStackNavigator } from '@react-navigation/stack';
import React from 'react'; //

import { RouteNames } from '@constants/navigation';
import { OtpValidationNavigatorInterface } from '@navigation/Interfaces/OtpValidationInterface';
import { useDefaultScreensOptions } from '@navigation/useDefaultScreensOptions';
import {
  OtpValidationStartPage,
  OtpValidationSuccessPage
} from '@pages/OtpFlow';
import {OtpValidationFormPage} from "@pages/OtpFlow/OtpValidationFlow/OtpValidationFormPage";

const Stack = createStackNavigator<OtpValidationNavigatorInterface>();

export const OtpValidation = () => {
  const defaultScreensOptions = useDefaultScreensOptions();
  return (
    <Stack.Navigator
      initialRouteName={RouteNames.OtpValidationStart}
      screenOptions={{
        gestureEnabled: false,
        headerShown: false,
        cardStyle: {
          backgroundColor: 'transparent'
        }
      }}>
      <Stack.Screen
        component={OtpValidationStartPage}
        name={RouteNames.OtpValidationStart}
        options={defaultScreensOptions.OtpValidationStart}
      />
      <Stack.Screen
        component={OtpValidationSuccessPage}
        name={RouteNames.OtpValidationSuccess}
        options={defaultScreensOptions.OtpValidationSuccess}
      />
      <Stack.Screen
        component={OtpValidationFormPage}
        name={RouteNames.OtpValidationForm}
        options={defaultScreensOptions.OtpValidationForm}
      />
    </Stack.Navigator>
  );
};
