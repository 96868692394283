import React, { useState } from 'react';
import { View } from 'react-native';

import {
  useTranslation,
  useTheme,
  useScreenSizes,
  PasswordCheck,
  TextField,
  NotificationHard,
  SimpleButton,
  NotificationIconTypeEnum,
} from '@components/index';
import { createUseStyles } from '@ere-uilib/styles';

import { EditPasswordCheckProps } from './interfaces';
import { getStyles } from './styles';
import { locator } from '@constants/locator';

export const EditPasswordCheckComponent: React.FC<React.PropsWithChildren<EditPasswordCheckProps>> = ({
  appSettings,
  error,
  isApiLoading,
  onPress,
}) => {
  const { formatMessage } = useTranslation();
  const theme = useTheme();
  const { isMobile, isTablet } = useScreenSizes();
  const styles = useStyles({ theme, isMobile, isTablet }, {});
  const [disabled, setDisable] = useState(true);
  const [clearInputs, setClearInputs] = useState(false);

  const [isDisabled, setIsDisabled] = useState(true);

  const [InputOldPassword, setInputOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const passwordIconisClicked = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleChangeOldPassword = (passwordValue: string) => {
    setInputOldPassword(passwordValue);
    if (passwordValue.length >= 1) {
      setIsDisabled(false);
    } else {
      setDisable(true);
      setIsDisabled(true);
      setClearInputs(true);
    }
  };

  const onSubmit = () => {
    onPress(InputOldPassword, newPassword);
  };

  const submitButtonProps = {
    disabled: isApiLoading || disabled,
    loading: isApiLoading,
    onPress: onSubmit,
    title: formatMessage({ id: 'define-password_input2_label' }),
  };

  return (
    <>
      <View style={styles.oldPasswordContainerStyle}>
        <TextField
          containerStyle={styles.input}
          inputProps={{
            placeholder: formatMessage({ id: 'Profil_ChangePassword_Actuel_Placeholder_label' }),
            value: InputOldPassword,
            onChangeText: passwordValue => {
              handleChangeOldPassword(passwordValue);
            },
          }}
          isPasswordVisible={isPasswordVisible}
          labelOnTop
          password
          setIsPasswordVisible={passwordIconisClicked}
          testId={locator._profile._change_password._current_password}
        />
      </View>
      <View style={styles.newPasswordContainerStyle}>
        <View style={styles.newPasswordContainerStyle}>
          <PasswordCheck
            appSettings={appSettings}
            buttonsRowStyle={styles.buttonsRowStyle}
            clearInputs={clearInputs}

            forgottenPasswordContext={true}
            isDisabled={isDisabled}
            placeholderConfirmNewPassword={formatMessage({
              id: 'Profil_ChangePassword_Actual_Placeholder_label',
            })}
            placeholderNewPassword={formatMessage({
              id: 'Profil_ChangePassword_New1_Placeholder_label',
            })}
            setDisable={setDisable}
            setPassword={setNewPassword}
            submitButtonProps={submitButtonProps}
            testIdNewPassword={locator._profile._change_password._new_password}
            testIdConfirmNewPassword={locator._profile._change_password._confirm_password}
          />
        </View>
      </View>
      <View style={styles.buttonContainer}>
        <View style={styles.buttonsRowStyle}>
          {error && error?.message !== "" && (
            <NotificationHard
              containerStyle={styles.errorViewStyle}
              text={error?.message}
              type={NotificationIconTypeEnum.WARNING}
            />
          )}
          <View style={styles.buttonsRowStyle}>
            <SimpleButton
              disabled={disabled}
              loading={isApiLoading}
              onPress={onSubmit}
              title={formatMessage({
                id: 'choose-pwd_input2_label',
              })}
              testId={locator._profile._change_password._save}
            />
          </View>
        </View>
      </View>
    </>
  );
};

const useStyles = createUseStyles(getStyles);
