import { Context, Style } from './interfaces';

export const getStyles = (
  context?: Context,
  style?: Style,
): Style => ({
  container: [
    {
      alignItems: 'center',
    },
    context?.isMobile
      ? {
        marginTop: context?.theme.metrics.spacing.xxm,
      }
      : {
        marginTop: context?.theme.metrics.spacing.big,
      },
    style?.container,
  ],
  SVGStyle: [
    {
      height: context?.theme.metrics.contentSizes.commonSvgLargeHeight,
    },
    style?.SVGStyle,
  ],
  title: [
    {
      marginVertical: context?.theme.metrics.spacing.xm,
      textAlign: 'center',
    },
    style?.title,
  ],
});
