import React from 'react';
import {
  ScrollView,
  View,
  Platform,
} from 'react-native';

import {
  SVGLocalLoader,
  Title,
  Paragraph,
  SimpleButton,
  useTheme,
  useScreenSizes,
  EERPageWrapperContainer,
  EERContentWrapper,
} from '@components/index';
import { createUseStyles } from '@ere-uilib/styles';

import { RouteNames } from '@constants/navigation';
import { useTranslation } from '@ere-uilib/translations';

import { CompanyBenefitsPageProps } from './interface';
import { getStyles } from './styles';

export const CompanyBenefitsPage = ({ navigation }: CompanyBenefitsPageProps) => {
  const { isMobile, isTablet, isDesktop } = useScreenSizes();
  const theme = useTheme();
  const styles = useStyles({ theme, isMobile, isTablet, isDesktop }, {});
  const { formatMessage } = useTranslation();

  const renderContent = () => {
    return (
      <View
        style={Platform.OS === 'web' && !(isMobile || isTablet) ? styles.row : styles.column}
      >
        <View style={styles.benefitCard}>
          <SVGLocalLoader
            height={50}
            name="lock"
            width={50}
          />
          <Title
            style={styles.benefitCardTitle}
            variant="t6"
            weight="bold"
          >Plan Épargne Entreprise</Title>
          <Paragraph
            style={styles.benefitCardDescription}
            variant="t3"
            weight="light"
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
            fringilla lorem id neque mattis, at suscipit sapien dapibus.
          </Paragraph>
          <Paragraph
            style={styles.benefitCardDescription}
            variant="t3"
            weight="light"
          >
            Phasellus luctus mollis ligula dignissim auctor.Cras sit amet lacus
            viverra, gravida leo eget, blandit nisl viverra nec elit at vehicula
            ac pulvinar lacus.
          </Paragraph>
          <Paragraph
            style={styles.benefitCardDescription}
            variant="t3"
            weight="light"
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
            fringilla lorem id neque mattis, at suscipit sapien dapibus.
          </Paragraph>
        </View>
        <View style={styles.benefitCard}>
          <SVGLocalLoader
            height={50}
            name="lock"
            width={50}
          />
          <Title
            style={styles.benefitCardTitle}
            variant="t6"
            weight="bold"
          >Plan Épargne Entreprise</Title>
          <Paragraph
            style={styles.benefitCardDescription}
            variant="t3"
            weight="light"
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
            fringilla lorem id neque mattis, at suscipit sapien dapibus.
          </Paragraph>
          <Paragraph
            style={styles.benefitCardDescription}
            variant="t3"
            weight="light"
          >
            Phasellus luctus mollis ligula dignissim auctor.Cras sit amet lacus
            viverra, gravida leo eget, blandit nisl viverra nec elit at vehicula
            ac pulvinar lacus.
          </Paragraph>
          <Paragraph
            style={styles.benefitCardDescription}
            variant="t3"
            weight="light"
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
            fringilla lorem id neque mattis, at suscipit sapien dapibus.
          </Paragraph>
        </View>
        <View style={styles.benefitCard}>
          <SVGLocalLoader
            height={50}
            name="lock"
            width={50}
          />
          <Title
            style={styles.benefitCardTitle}
            variant="t6"
            weight="bold"
          >Plan Épargne Entreprise</Title>
          <Paragraph
            style={styles.benefitCardDescription}
            variant="t3"
            weight="light"
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
            fringilla lorem id neque mattis, at suscipit sapien dapibus.
          </Paragraph>
          <Paragraph
            style={styles.benefitCardDescription}
            variant="t3"
            weight="light"
          >
            Phasellus luctus mollis ligula dignissim auctor.Cras sit amet lacus
            viverra, gravida leo eget, blandit nisl viverra nec elit at vehicula
            ac pulvinar lacus.
          </Paragraph>
          <Paragraph
            style={styles.benefitCardDescription}
            variant="t3"
            weight="light"
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
            fringilla lorem id neque mattis, at suscipit sapien dapibus.
          </Paragraph>
        </View>
      </View>
    );
  };

  return (
    <EERPageWrapperContainer
      activeStep={2}
      displayCloseButton={false}
      displayLeftContent={false}
      pageTitle={formatMessage({ id: 'verify-identity_menu' })}
    >
      <EERContentWrapper
        title={'Air France vous permet de bénéficier des avantages suivants :'}
      >
        <View style={styles.container}>
          <Title
            style={[styles.title, (isMobile || isTablet) ? { paddingLeft: theme.metrics.spacing.xm } : null]}
            variant="t3"
            weight="bold"
          />
          {(isMobile || isTablet) ? (
            <ScrollView>{renderContent()}</ScrollView>
          ) : (
            <View>{renderContent()}</View>
          )}
          <SimpleButton
            containerStyle={{ marginTop: theme.metrics.spacing.xm, marginBottom: theme.metrics.spacing.xm }}
            onPress={() => navigation.navigate(
              RouteNames.BottomTabNavigator,
              {
                screen: RouteNames.Home,
              }
            )}
            title={formatMessage({ id: 'Eer_GotoHome_button' })}
          />
        </View>

      </EERContentWrapper>
    </EERPageWrapperContainer>
  );
};

const useStyles = createUseStyles(getStyles);
