import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import {
  LanguageSelector,
} from '@components/index';
import { languageList } from '@constants/index';
import { onPutLanguageRequest } from '@modules/label/actions/labelActions';
import { getLanguage } from '@modules/label/selectors';
import { locator } from '@constants/locator';

export const LanguageSelector360 = ({
  style,
  offlineLayoutAbsolutePosition = false,
}:{
  style?: StyleProp<ViewStyle>
  offlineLayoutAbsolutePosition?: boolean
}) => {
  const language = useSelector(getLanguage);
  const dispatch = useDispatch();
  const onSelectLanguage = (languageParam: string) => {
    dispatch(onPutLanguageRequest(languageParam));
  }

  const handleLanguageChange = (option:{
    label: string
    value: string
    imageName: string
  })=>{
    onSelectLanguage(option.value);
  };

  return (
    <LanguageSelector
      containerStyles={style}
      offlineLayoutAbsolutePosition={offlineLayoutAbsolutePosition}
      onChange={handleLanguageChange}
      options={languageList}
      value={language}
      testId={locator._login._select_language}
    />
  );
};
