import React from 'react';
import { View } from 'react-native';

import { Text } from '@ere-uilib/atoms';
import { ExpendableGreen } from '@ere-uilib/molecules';

import { AccordionCardProps } from './types';
import { useStyles } from './useStyles';

export function FiletStepperAccordionCard({
  title,
  accordionTitle,
  expendableContent
}: AccordionCardProps) {
  const styles = useStyles();

  return (
    <View style={styles.bankAccountContainer}>
      <Text variant="t3">
        {title}
      </Text>
      {expendableContent && accordionTitle && (
        <ExpendableGreen
          title={accordionTitle}
        >
          {expendableContent()}
        </ExpendableGreen>
      )}
    </View>
  );
}
