import React, { useState } from 'react';

import { Text, Title } from '@ere-uilib/atoms';
import { useScreenSizes } from '@ere-uilib/styles';

import { ActivableButtonSet } from '../ActivableButtonSet';
import { useStyles } from './useStyles';

interface Props {
  title: string;
  description: string;
  subDescription?: string;
  onSelect?: () => void;
  disabled?: boolean
}

export const ChoiceButton = ({
  title,
  description,
  subDescription,
  onSelect,
  disabled = false
}: Props) => {
  const styles = useStyles();
  const { isMobile } = useScreenSizes();

  const [hovered, setHovered] = useState<boolean>(false);

  const isHoveredStyle = hovered ? styles.hoveredTextStyle : {}
  return (
    <ActivableButtonSet
      onChange={onSelect}
      disabled={disabled}
      hovered={hovered}
      setHovered={setHovered}
    >
      <>
        <Title
          style={[styles.textStyle, isHoveredStyle]}
          variant={isMobile ? 't7' : 't6'}>
          {title}
        </Title>
        <Text
          style={[styles.textStyle, isHoveredStyle]}
          variant={'t3'}
          weight="light">
          {description}
        </Text>
        {!!subDescription && (
          <Text
            style={[styles.textStyle, isHoveredStyle]}
            variant={'t3'}
            weight="light">
            {subDescription}
          </Text>
        )}
      </>
    </ActivableButtonSet>
  )
};
