import { StylesContext, WalkthroughStepperStyles } from './interface';

export const getStyles = (
  context?: StylesContext,
  style?: WalkthroughStepperStyles,
): WalkthroughStepperStyles => ({
  leftContainerStyle: [
    {
      flex: 1,
      padding: context?.theme.metrics.spacing.xxl,
      paddingTop: context?.theme.metrics.spacing.xxhuge,
      borderTopLeftRadius: context?.theme.metrics.borderRadius.m,
      borderBottomLeftRadius: context?.theme.metrics.borderRadius.m,
      alignItems: 'center',
      borderRightWidth: 2,
      borderRightColor: context?.theme.colors.basics.grey.c100,
    },
    style?.leftContainerStyle,
  ],
  leftContainerTitleStyle: [
    style?.leftContainerTitleStyle,
  ],
  leftContainerRowStyle: [
    {
      marginTop: context?.theme.metrics.spacing.xxl,
    },
    style?.leftContainerRowStyle,
  ],
  stepNumberViewSelectedStyle: [
    {
      width: 35,
      height: 35,
      backgroundColor: context?.theme.colors.basics.primary.c500,
      borderRadius: 100,
      justifyContent: 'center',
      alignItems: 'center',
    },
    style?.stepNumberViewSelectedStyle,
  ],
  stepNumberViewTitleStyle: [
    {
      fontSize: 18,
      lineHeight: 18,
    },
    style?.stepNumberViewTitleStyle,
  ],
  stepNumberViewTitleSelectedStyle: [
    {
      color: context?.theme.colors.basics.white,
    },
    style?.stepNumberViewTitleSelectedStyle,
  ],
  stepListStyle: [
    {
      marginTop: context?.theme.metrics.spacing.xs,
    },
    style?.stepListStyle,
  ],
  stepTitleStyle: [
    {
      marginLeft: context?.theme.metrics.spacing.s,
      maxWidth: 200,
    },
    style?.stepTitleStyle,
  ],
  stepTitleSelectedStyle: [
    {
      color: context?.theme.colors.basics.primary.c500,
    },
    style?.stepTitleSelectedStyle,
  ],

  stepNumberViewStyle: [
    {
      width: 35,
      height: 35,
      backgroundColor: 'transparent',
      borderRadius: 100,
      justifyContent: 'center',
      alignItems: 'center',
      borderColor: context?.theme.colors.text.primary.c500,
      borderWidth: 1,
    },
    style?.stepNumberViewStyle,
  ],
  stepSubtitleStyle: [
    {
      color: context?.stepOneDone ? context?.theme.colors.basics.black : context?.theme.colors.basics.grey.c300,
      marginTop: context?.theme.metrics.spacing.s,
      marginLeft: context?.theme.metrics.spacing.s,
    },
    style?.stepSubtitleStyle,
  ],
  stepSubtitleSelectedStyle: [
    {
      color: context?.theme.colors.text.primary.c500,
    },
    style?.stepSubtitleSelectedStyle,
  ],
  stepSubtitleBlackStyle: [{}, style?.stepSubtitleBlackStyle],
});
