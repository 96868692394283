import { connect } from 'react-redux';

import { AppState } from '@modules/reducers';
import { getAccountsSelector } from '@modules/dashboard/selectors';

import { DisponibilityTermComponent } from './DisponibilityTermPage.component';

const mapStateToProps = (state: AppState) => {
  return {
    accounts: getAccountsSelector(state)
  };
};

const mapDispatchToProps = ({
});

export const DisponibilityTermPage = connect(mapStateToProps, mapDispatchToProps)(DisponibilityTermComponent);

