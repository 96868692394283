import { Context, Style } from './interfaces';

export const getStyles = (context?: Context, style?: Style): Style => ({
  modalTitleStyle: [
    {
      textAlign: 'center'
    },
    style?.modalTitleStyle
  ],
  buttonContainer: [
    {
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingTop: context?.theme.metrics.spacing.l
    },
    style?.buttonContainer
  ],
  listContainer: [
    {
      marginBottom: context?.theme.metrics.spacing.l
    },
    style?.listContainer
  ],
  scrollViewContainer: [
    {
      paddingBottom: context?.theme.metrics.spacing.l
    },
    style?.scrollViewContainer
  ],
  radioButtonRowWrapper: [
    {
      marginBottom: context?.theme.metrics.spacing.s
    },
    style?.radioButtonRowWrapper
  ]
});
