import React from 'react';
import { TouchableOpacity, View } from 'react-native';

import { StatusTag } from '@components/index';
import { FontIcon, Title } from '@ere-uilib/atoms';
import { IconEnum } from '@ere-uilib/enums';
import { createUseStyles, useScreenSizes, useTheme } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';

import { Props } from './interfaces';
import { getStyles } from './styles';

export const OperationCard: React.FC<React.PropsWithChildren<Props>> = ({
  amount,
  title,
  deductibleLabel,
  reccurenceLabel,
  statusLabel,
  paymentModeLabel,
  statusType,
  createdAtDate,
  onItemPress,
  style,
  subStyles,
}) => {
  const theme = useTheme();
  const { formatDate, formatMessage, formatCurrencyNumber } = useTranslation();
  const formattedAmount = amount ? formatCurrencyNumber({ value: amount }) : undefined;
  const { windowWidth, isDesktop } = useScreenSizes();
  const formattedCreatedAtDate = formatDate({ value: createdAtDate });
  const formattedLabels = {
    createdAt: formatMessage({
      id: 'VV_VVP_detailCreationVVP_description', values: {
        date: formattedCreatedAtDate,
      }
    }),
    netAmount: formatMessage({ id: 'VV_VVP_detailMontantNet_description' }),
  };
  const styles = useStyles({
    isMobile: !isDesktop,
    theme,
    windowWidth,
  }, {
    container: style,
    ...subStyles,
  });

  const renderBottomLeftLabel = (iconName: IconEnum, label: string, iconSize: number) => (
    <View style={styles.labelContainer}>
      <FontIcon
        color={theme.colors.basics.black}
        name={iconName}
        size={iconSize}
      />
      <Title
        style={styles.bottomLeftLabels}
        variant="t8"
        weight="light"
      >{label}</Title>
    </View>
  );

  return (
    <TouchableOpacity
      disabled={!onItemPress}
      onPress={onItemPress}
      style={styles.container}
    >
      <View style={styles.titleContainer}>
        <Title
          numberOfLines={2}
          style={styles.title}
          variant="t7">{title}</Title>
        <View style={styles.amountAndIconContainer}>
          {amount && (
            <View style={styles.amountAndSubtitleContainer}>
              <Title
                style={styles.amountStyle}
                variant="t6"
                weight="bold"
                testID={`${Number(amount)}`}
              >
                {formattedAmount}
              </Title>
              <Title
                variant="t8"
                weight="light">
                {formattedLabels.netAmount}
              </Title>
            </View>
          )}
          {onItemPress && (
            <FontIcon
              color={theme.colors.basics.black}
              name={IconEnum.CHEVRON_RIGHT}
              size={theme.metrics.iconSizes.s}
              style={styles.iconChevron}
            />
          )}
        </View>
      </View>
      <View style={styles.bottomContainer}>
        <View style={styles.bottomLeftContainer}>
          {reccurenceLabel &&
            renderBottomLeftLabel(IconEnum.CALENDAR, reccurenceLabel, theme.metrics.iconSizes.m)
          }
          {deductibleLabel &&
            renderBottomLeftLabel(IconEnum.FISCALITE, deductibleLabel, theme.metrics.iconSizes.m)
          }
          {paymentModeLabel &&
            renderBottomLeftLabel(IconEnum.CARD, paymentModeLabel, theme.metrics.iconSizes.m)
          }
        </View>
        <View>
          {createdAtDate &&
            <Title
              variant="t8"
              weight="light">{formattedLabels.createdAt}</Title>
          }
          {statusLabel && statusType && (
            <StatusTag
              containerStyle={styles.statusTag}
              label={statusLabel}
              type={statusType} />
          )}
        </View>
      </View>
    </TouchableOpacity>
  );
};

const useStyles = createUseStyles(getStyles);
