import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useScreenSizes, useTheme } from '@ere-uilib/styles';

export function useStyles() {
  const theme = useTheme();
  const { isMobile, isTablet } = useScreenSizes();

  const styles = useMemo(
    () =>
      StyleSheet.create({
        dashboardWrapper: {
          backgroundColor: theme.colors.basics.white
        },
        cardInfoQuestion: {
          marginTop: theme.metrics.spacing.xm
        },
        cardInfoAnswer: {
          marginTop: theme.metrics.spacing.xs
        },
        moreInfosTxt: {

        },
        buttonContainer: {
          marginVertical: theme.metrics.spacing.l,
          marginHorizontal: theme.metrics.spacing[!(isMobile || isTablet) ? 'xhuge' : 's'],
          alignSelf: (isMobile || isTablet) ? 'auto' : 'flex-start'
        },
        formContainer: {
          marginVertical: theme.metrics.spacing.l
        },
        contentContainer: {
          paddingTop: theme.metrics.spacing.xxxxl,
          marginHorizontal: !(isMobile || isTablet) ?
            theme.metrics.spacing.xhuge : theme.metrics.spacing.s,
        },
        cardInfoContainer: {
          marginBottom: theme.metrics.spacing.xs
        },
        detailsButton: {
          alignSelf: 'flex-start',
          paddingHorizontal: 0
        },
        toolCardContainer: {
          marginTop: theme.metrics.spacing.l,
        },
        tabFormContainer: {
          maxWidth: (isMobile || isTablet) ? '100%' : 440
        }
      }),
    [theme, isMobile, isTablet]
  );

  return styles;
}