import { useMemo } from "react"
import { StyleProp, StyleSheet, TextStyle, ViewStyle } from "react-native"
import { useTheme } from "@ere-uilib/styles";


export interface ProfileInformationsEditCardStyles {
    containerStyle?: StyleProp<ViewStyle>;
    firstLineContainerStyle?: StyleProp<ViewStyle>;
    firstLinesStyle?: StyleProp<TextStyle>;
    statusTagStyle?: StyleProp<TextStyle>;
    separator?: StyleProp<ViewStyle>;
    lastLinesStyle?: StyleProp<TextStyle>;
    userInformationStyle?: StyleProp<TextStyle>;
}

export const useStyles = (isEditable: boolean) => {
    const theme = useTheme();
    const styles = useMemo(() => StyleSheet.create({
        firstLineContainerStyle: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        firstLines: {
            textAlign: 'left',
            flex: 1
        },
        statusTagStyle: { marginBottom: theme?.metrics.spacing.s },
        userInformation: {
            marginTop: theme.metrics.spacing.m,
            color: isEditable
              ? theme?.colors?.basics?.black
              : theme?.colors?.basics?.greyTransparent?.c200
        },
        lastLines: {
            marginTop: theme.metrics.spacing.m,
        }
    }), [isEditable, theme]);

    return styles;
}
