import { useMemo } from 'react'
import { StyleSheet } from 'react-native'

import { useTheme } from '@ere-uilib/styles'

export const useStyles = () => {
  const theme = useTheme()

  const styles = useMemo(
    () =>
      StyleSheet.create({
        labelsRadioStyle: { fontFamily: 'BNPPSans-Light' },
        radioGroupContainer: {
          marginTop: theme.metrics.spacing.s
        },
        errorDateText: {
          marginBottom: theme.metrics.spacing.m,
          color: theme.colors.operationState.failed.c500
        }
      }),
    [theme]
  )

  return styles
}
