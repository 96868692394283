import { FileExtensions } from "@ere-uilib/types";

export const ALLOWED_FILE_EXTENSIONS = [
  FileExtensions.JPEG,
  FileExtensions.JPG,
  FileExtensions.PDF
];

export enum BankStatusTypesEnum {
  DEMANDE_EN_COURS = 'DEMANDE_EN_COURS',
  DEMANDE_INITIEE = 'DEMANDE_INITIEE',
  VALIDE = 'VALIDE',
  INVALIDE = 'INVALIDE',
}
