import { Context, Styles } from './interfaces';

export const getStyles = (
  context?: Context,
  style?: Styles,
): Styles => ({
  container: [
    {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center'
    },
    style?.container,
    context?.isMobile && {
      paddingHorizontal: context?.theme.metrics.spacing.xl
    }
  ],
  svgContainer: [
    {
      height: !context?.isMobile
        ? context?.theme?.metrics?.contentSizes.savingsPlaceholderSvgDesktop
        : context?.theme?.metrics?.contentSizes.savingsPlaceholderSvgMobile,
      width: '100%',
      maxWidth: 450
    },
    style?.svgContainer
  ],
  titleContainer: [
    {
      marginTop: context?.theme.metrics.spacing.xm
    },
    style?.titleContainer
  ],
  titleStyle: [
    context?.isMobile && {
      textAlign: 'center'
    },
    style?.titleStyle
  ],
  buttonStyle: [
    {
      marginTop: context?.theme.metrics.spacing.xm
    },
    style?.buttonStyle
  ],
  imageBackgroundContainerStyle: [
    {
      flex: 1
    },
    style?.imageBackgroundContainerStyle
  ]
});
