import {
  PlanFamillyList,
  EstimateCalculContributionFetchParamsType,
  EstimateContributionFetchSupportParamsType
} from '@constants/index';

import {
  ProfitSharingIncentiveRepartitionState,
} from '../types'
export const buildProfitSharingIncentiveCalculatedContributionDistributionsParameter = ({
  repartition,
  planId,
  compartmentCode,
  managementId,
  supportId,
  amount,
}: {
  repartition: ProfitSharingIncentiveRepartitionState
  planId: number
  compartmentCode: string
  managementId: string
  supportId?: string
  amount: number
}): any => {
  const distributions: EstimateCalculContributionFetchParamsType['distributions'] = [];
  const targetedPlan = repartition.plans.find(plan => plan.planId === planId)
  const isRC = targetedPlan?.planFamily === PlanFamillyList.RC;
  const targetedCompartment = targetedPlan?.compartments.find(compartment => compartment.code === compartmentCode)

  // TODO : find if management or plan got contribution to prevent uneeded mapping

  targetedCompartment?.managements?.forEach(management => {
    const isFreeManagement = management.isFree;
    const isChangedManagement = managementId === management.id;
    const isManagementDirectAmounts = isRC && !isFreeManagement;
    const isManagementDirectContributionAmounts = isRC;

    let managementTotalAmount = 0;

    const supports: EstimateContributionFetchSupportParamsType[] = [];
    if (isManagementDirectContributionAmounts) {
      // in RC if management hasContribution, but no support has contribution, we want the total for the management
      management?.supports?.forEach(support => {

        const isChangedSupport = supportId && support.supportIsin === supportId;
        let supportAmount = support.formValues?.amount || 0;
        // we test if we use the formValue or the new field value
        if (isChangedManagement && isChangedSupport) {
          supportAmount = amount;
        }
        managementTotalAmount += supportAmount || 0;

      });
      if (isManagementDirectAmounts) {
        managementTotalAmount = isChangedManagement ?
          amount
          : management.formValues?.amount || 0;
      }
    } else {
      // in common situation we want a list of all support with hasContribution
      management?.supports?.forEach(support => {
        // if the management is not free management we want only the isMaster support to be listed
        if (!isFreeManagement && !support.isMaster) {
          return;
        }

        // we list all supports with hasContribution or Master support even if non abondable
        if (support?.hasContribution || (!isFreeManagement && support.isMaster)) {

          let supportDistribution: EstimateContributionFetchSupportParamsType;
          const isChangedSupport = supportId && support.supportIsin === supportId;
          // we test if we use the formValue or the new field value
          if (isChangedManagement && isChangedSupport) {
            supportDistribution = {
              supportId: support.supportIsin,
              amount: amount || 0,
            };
          } else {
            supportDistribution = {
              supportId: support.supportIsin,
              amount: support.formValues?.amount || 0,
            };
          }
          supports?.push(supportDistribution);
        }
      });
    }

    distributions.push({
      managementId: management.id,
      // cacul is made for management total amount of by managment support list
      ...(isManagementDirectContributionAmounts ? { amount: managementTotalAmount } : {}),
      ...(supports ? { supports: supports } : {}),
    });
  });
  return {
    planId: targetedPlan?.planId?.toString(),
    distributions
  };
};
