import React, { useState, useRef, useLayoutEffect, useCallback } from 'react';
import { View, TouchableOpacity, Animated, Easing, LayoutChangeEvent, Platform } from 'react-native';

import { Text } from '@ere-uilib/atoms';
import { createUseStyles, useTheme } from '@ere-uilib/styles';

import {
  Props,
} from './interfaces';
import { getStyles } from './styles';

export const MultiSwitch = <ValueType extends unknown>({
  options,
  onChange,
  value,
  containerStyle,
}:Props<ValueType>) => {
  const isAlreadyLayout = useRef<boolean>(false);
  const OptionRefList = useRef<{[key:string]: TouchableOpacity}>({});
  const theme = useTheme();
  const [positionAnimationValue] = useState(new Animated.Value(0));
  const [widthAnimationValue] = useState(new Animated.Value(0));
  const defaultSelectedValueIndex = 0;
  const [androidMeasurements, setAndroidMeasurements] = useState<{[key: string]: { x: number, width: number }}>();

  const styles = useStyles({
    theme,
  }, {
    containerStyle,
  });

  const handleOptionSelection = useCallback((newValue: ValueType) => {
    onChange(newValue);
  }, [onChange]);

  const animateOption = useCallback(({
    newXPosition,
    newWidth,
  }:{
    newXPosition: number
    newWidth: number
  }) => {
    Animated.timing(positionAnimationValue, {
      toValue: newXPosition,
      duration: 200,
      easing: Easing.ease,
      useNativeDriver: false,
    }).start();
    Animated.timing(widthAnimationValue, {
      toValue: newWidth,
      duration: 200,
      easing: Easing.ease,
      useNativeDriver: false,
    }).start();
  },
  [positionAnimationValue, widthAnimationValue],
  );

  const getSizeOfTargetedOption = useCallback((
    callback:({ newWidth, newXPosition }:{newWidth: number, newXPosition: number})=>void
  )=>{
    const selectedValueIndex = options.findIndex(item=>value === item.value);
    const isSelectedValueExist = selectedValueIndex >= 0;

    if (!isSelectedValueExist) {
      const defaultSelectedValue = options?.[defaultSelectedValueIndex]?.value;
      handleOptionSelection(defaultSelectedValue);
      return;
    }

    const targetedItem = options[selectedValueIndex];
    const targetedOptionRef =
    OptionRefList?.current?.[selectedValueIndex + '_' + targetedItem.label];
    
    if (targetedOptionRef) {
      if (Platform.OS === 'android' && androidMeasurements) {
        const selectedAndroidMeasurement = androidMeasurements[selectedValueIndex + '_' + targetedItem.label];

        callback({
          newWidth: selectedAndroidMeasurement?.width,
          newXPosition: selectedAndroidMeasurement?.x - 1,
        });
      } else {
        targetedOptionRef.measure((x, _, width,)=>{
          callback({
            newWidth: width,
            newXPosition: x - 1,
          });
        });
      }
    }
  },
  [options, value, handleOptionSelection, androidMeasurements],
  );

  const setActiveOptionPositionFromTargetedValue = useCallback(()=>{
    getSizeOfTargetedOption(({ newWidth, newXPosition })=>{
      widthAnimationValue.setValue(newWidth);
      positionAnimationValue.setValue(newXPosition);
    });
  },
  [getSizeOfTargetedOption, widthAnimationValue, positionAnimationValue],
  );

  const animateActiveOptionPositionFromTargetedValue = useCallback(()=>{
    getSizeOfTargetedOption(({ newWidth, newXPosition }) => {
      animateOption({
        newWidth: newWidth,
        newXPosition: newXPosition,
      });
    });
  },
  [getSizeOfTargetedOption, animateOption],
  );

  const handleLayout = useCallback(() => {
    if (isAlreadyLayout.current) {
      animateActiveOptionPositionFromTargetedValue();
    } else {
      isAlreadyLayout.current = true;
      setActiveOptionPositionFromTargetedValue();
    }
  }, [
    value,
    animateActiveOptionPositionFromTargetedValue,
    setActiveOptionPositionFromTargetedValue,
  ])

  useLayoutEffect(() => {
    handleLayout()
  }, [handleLayout]);

  const handleItemLayout = useCallback((
    e: LayoutChangeEvent,
    option: {
      label: string;
      value: ValueType;
    },
    index: number
  ) => {
    setAndroidMeasurements({
      ...androidMeasurements,
      [index + '_' + option.label]: {
        x: e.nativeEvent.layout.x,
        width: e.nativeEvent.layout.width,
      }
    })
  }, [androidMeasurements]);

  interface HandleRef {
    option: {
      label: string;
      value: ValueType
    },
    ref: TouchableOpacity | null,
    index: number,
  }
  const handleRef = useCallback(({ ref, option, index }: HandleRef) => {
    return ref && (OptionRefList.current[index + '_' + option.label] = ref)
  }, [])

  return (
    <View
      onLayout={handleLayout}
      style={styles.containerStyle}
    >
      {options.map((option, index) => {
        const handleIndexLayout = (e: LayoutChangeEvent) => handleItemLayout(e, option, index)

        return (
          <TouchableOpacity
            onLayout={handleIndexLayout}
            key={index + '_' + option.label}
            onPress={()=> handleOptionSelection(option.value)}
            ref={ref => handleRef({ ref, option, index })}
            style={[styles.segment, styles.touchableSegment]}
            testID={'button_' + index + '_' + option.label}
          >
            <Text
              style={value === option.value ?
                styles.activeText
                : styles.defaultText
              }
              variant={'t4'}
            >
              {option.label}
            </Text>
          </TouchableOpacity>
        )
      })}
      <Animated.View
        style={[
          styles.activeSegment,
          {
            width: widthAnimationValue,
            left: positionAnimationValue,
          },
        ]}
      />
    </View>
  );
};

const useStyles = createUseStyles(getStyles);
