import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

export const useStyles = () => {
  const theme = useTheme();

  const styles = useMemo(() => StyleSheet.create({
    container: {
      paddingVertical: theme.metrics.spacing.m,
      paddingHorizontal: theme.metrics.spacing.s,
      backgroundColor: theme.colors.basics.white
    },
    expandableContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      paddingTop: theme.metrics.spacing.s
    },
    expandableIcon: {
      paddingLeft: theme.metrics.spacing.xs
    }
  }), [theme]);

  return styles;
};
