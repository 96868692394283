import { useMemo } from 'react'
import { StyleSheet } from 'react-native'

import { useTheme } from '@ere-uilib/styles'

export function useStyles() {
  const theme = useTheme()
  const styles = useMemo(
    () =>
      StyleSheet.create({
        wrapper: {
          backgroundColor: theme?.colors.basics.white
        },
        container: {
          alignSelf: 'center',
          width: '100%',
          maxWidth: theme.metrics.contentSizes.centeredContentMaxWidth,
          paddingVertical: theme.metrics.spacing.none,
          paddingLeft: theme.metrics.spacing.none,
          paddingRight: theme.metrics.spacing.none
        },
        filterContainer: {
          paddingVertical: theme.metrics.spacing.xm,
          borderBottomWidth: 1,
          borderBottomColor: theme.colors.borders.fields.c200,
        },
        notificationContainer: {
          margin: theme.metrics.spacing.s,
        },
        notificationLink: {
          marginTop: theme.metrics.spacing.s
        },
        empty: {
          textAlign: 'center',
          padding: theme.metrics.spacing.xm
        }
      }),
    [theme]
  )

  return styles
}
