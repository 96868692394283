import { createStackNavigator } from "@react-navigation/stack";
import React from "react";

import { RouteNames } from "@constants/navigation";
import { useDefaultScreensOptions } from "@navigation/useDefaultScreensOptions";
import { HistoryPage } from "@pages/Dashboard";

const HistoryStack = createStackNavigator();

export const HistoryStackNavigator = () => {
  const defaultScreensOptions = useDefaultScreensOptions();

  return (
    <HistoryStack.Navigator
      initialRouteName={RouteNames.History}
      screenOptions={{
        gestureEnabled: false,
        headerShown: false,
      }}
    >
      <HistoryStack.Screen
        component={HistoryPage}
        name={RouteNames.History}
        options={defaultScreensOptions.History}
      />
    </HistoryStack.Navigator>
  );
};
