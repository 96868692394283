import { ModalWithHtmlContentStyles, StylesContext } from './interface';

export const getStyles = (
  context?: StylesContext,
  style?: ModalWithHtmlContentStyles
): ModalWithHtmlContentStyles => ({
  containerStyle: [
    {
      flex: 1
    },
    style?.containerStyle
  ],
  logoStyle: [
    {
      width: 316,
      height: 120,
      padding: context?.theme.metrics.spacing.xm
    },
    style?.logoStyle
  ],
  contentContainerStyle: [
    {
      flex: 1,
      ...(!context?.isMobile ? {
        paddingHorizontal: context?.theme.metrics.spacing.xbig
      } : {})
    },
    style?.contentContainerStyle
  ],
  innerScrollStyle: [
    {
      flex: 1
    },
    style?.innerScrollStyle
  ],
  titleStyle: [
    {
      marginTop: context?.isMobile ? context?.theme.metrics.spacing.none : context?.theme.metrics.spacing.xm,
      marginBottom: context?.theme.metrics.spacing.xm
    },
    style?.titleStyle
  ]
});

