import { useScreenSizes, useTheme } from "@ere-uilib/styles"
import { useMemo } from "react"
import { StyleSheet } from "react-native"

export const useStyles = () => {
  
  const theme = useTheme();

  const styles = useMemo(() => StyleSheet.create({
   mainContainer: {
    marginBottom: theme.metrics.spacing.l
   }
  }), [
    theme,
  ]);

  return styles;
}

