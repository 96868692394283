import React from 'react';
import { ScrollView, TouchableOpacity, View } from 'react-native';

import {
  Spinner,
  Title,
} from '@ere-uilib/atoms';
import { ModalFrame } from '@ere-uilib/molecules';
import { useTheme, createUseStyles } from '@ere-uilib/styles';

import { BankDomiciliationModalProps } from './interfaces';
import { getStyles } from './styles';

export const BankDomiciliationModal: React.FC<React.PropsWithChildren<BankDomiciliationModalProps>> = ({
  onClose,
  onSetSelectedItem,
  data,
  itemStyle
}) => {
  const theme = useTheme();
  const styles = useStyles(
    { theme },
    {
      itemStyle,
    }
  );
  const renderModalContent = () => {
    if (!data) {
      return <Spinner color={theme.colors.basics.primary.c500} />;
    }

    return (
      <>
        <View
          style={{
            padding: 0,
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          <Title variant="t6">Sélectionnez</Title>
        </View>
        <ScrollView
          showsVerticalScrollIndicator={true}
          style={styles.scrollViewStyle}>
          <View>

            {data.map((item, key) => (
              <View style={styles.itemStyle}>
                <TouchableOpacity
                  onPress={() => {
                    onClose();
                    onSetSelectedItem(item);
                  }}
                >
                  <Title variant="t7">
                    {item.label}
                  </Title>
                </TouchableOpacity>
              </View>
            ))}
          </View>
        </ScrollView>

      </>
    );

  };

  return (
    <ModalFrame
      innerContainer={{ paddingTop: 20, paddingHorizontal: 0 }}
      mobilePosition="bottom"
      onClose={onClose}
    >
      {renderModalContent()}
    </ModalFrame>
  );
};

const useStyles = createUseStyles(getStyles);