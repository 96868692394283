import { useNavigation } from '@react-navigation/native';
import React, { useState, useEffect, useMemo } from 'react';

import {
  ValidationCodePageTemplate,
  useTranslation,
} from '@components/index';
import { InvitationState } from '@modules/invitation/types';
import { IdentityValidationErrorState } from '@modules/validate-identity/types';
import { useLogoNavigation } from '@utils/index';
import {useRoute} from "@react-navigation/core";

interface Props {
  validateIdentityError: IdentityValidationErrorState;
  checkEERIdentityValidationCode: (code: string) => void;
  sendEERIdentityValidationCode: (param?: { isResend?: boolean }) => void;
  invitation: InvitationState;
  isCheckingCodeLoading: boolean;
  isSendingCodeLoading: boolean;
}

export const ValidationSignupPhoneCode: React.FC<Props> = ({
  validateIdentityError,
  invitation,
  checkEERIdentityValidationCode,
  sendEERIdentityValidationCode,
  isCheckingCodeLoading,
  isSendingCodeLoading,
}) => {
  const navigation = useNavigation();
  const route = useRoute();
  const [resetOTP, setResetOTP] = useState(false);
  const { formatMessage } = useTranslation();
  const { logoNavigateTo } = useLogoNavigation();
  // if screen was already rendered in navigation and we navigate again to it, it was not reseting the OTP
  // so we test the update of the route to ask to reset the OTP
  useEffect(() => {
    setResetOTP(!resetOTP);
  }, [route]);

  const onRetryEventOnPress = () => {
    setResetOTP(!resetOTP);
    sendEERIdentityValidationCode({ isResend: true });
  };

  const onValidateIdentity = (otpCode:string)=> {
    checkEERIdentityValidationCode(otpCode);
    setResetOTP(!resetOTP);
  };
  const canGoBack = navigation.canGoBack();
  const handleGoBack = useMemo(()=> canGoBack ? navigation.goBack : undefined, [canGoBack]);

  return (
    <ValidationCodePageTemplate
      description={`${formatMessage({ id: 'validate-identity_description' })} ${invitation.user.mobilePhone}`}
      EERLeftMenuActiveStep={1}
      errorMessage={validateIdentityError.message}
      isApiLoading={isCheckingCodeLoading}
      isEERLeftMenu
      isRetryEventApiLoading={isSendingCodeLoading}
      onBackPress={handleGoBack}
      onLogoPress={logoNavigateTo}
      onPress={onValidateIdentity}
      onRetryEventOnPress={onRetryEventOnPress}
      resetOTP = {resetOTP}
      title={formatMessage({ id: 'verify-identity_menu' })}
    />
  );
};

