import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects'

import { ResponseType } from '@modules/common/types'
import { getUsedCompanyId } from '@modules/dashboard/selectors'
import { runManager } from '@modules/moduleManager'

import {
  fetchRefundSignatureDate,
  fetchRefundUnavailabilityDecisionTree,
  fetchUnavailableRefundDispositifs
} from '../services'
import * as CommonRefundActions from '../actions/refundActions'
import {
  EnumRefundUnavailableDecision,
  RefundUnavailableDecisionTreeQuestion,
  RefundUnavailableDispositifTableQuestion,
  RefundUnavailableSignatureDateQuestion
} from '../constants'
import { getRefundGlobalState } from '../selectors'
import * as RefundActions from './actions/refundActions'
import { UnavailableRefundActionsType } from './actions/refundActionsTypes'
import { getRefundUnavailabilityDecisionTreeData, getRefundUnavailableMotifId } from './selectors'
import { RefundUnavailabilityDecisionTreeType } from './types'
import { RefundsCommonState } from '../types'
import { updateQuestionWhenResponded } from '@modules/utils/requirementDefinition'

function* updateRefundUnavailableQuestions({
  response,
  index,
  canNotBeAsked
}: {
  response: ResponseType
  index: number
  canNotBeAsked: boolean
}): any {
  const companyId = yield select(getUsedCompanyId)
  const refundGlobalState = yield select(getRefundGlobalState)
  let nextQuestionToBeAsked

  let respondedTitle

  switch (response?.type) {
    case EnumRefundUnavailableDecision.REFUND_UNAVAILABLE:
      {
        const data: RefundUnavailabilityDecisionTreeType = yield select(
          getRefundUnavailabilityDecisionTreeData
        )
        nextQuestionToBeAsked = yield call(RefundUnavailableDecisionTreeQuestion, data)
        respondedTitle = 'UnavailableRepaymentReleaseSelectionPageNeedDefinitionResume'
      }
      break
    case EnumRefundUnavailableDecision.REFUND_UNAVAILABLE_DECISION_TREE:
      {
        const dispositifs = yield call(
          fetchUnavailableRefundDispositifs,
          companyId,
          response.respondedValue as string
        )
        yield put(
          RefundActions.getUnavailableRefundDispositifsSuccess(
            dispositifs?.data,
            response.respondedValue as string
          )
        )
        nextQuestionToBeAsked = yield call(
          RefundUnavailableDispositifTableQuestion,
          dispositifs?.data
        )
        respondedTitle = 'UnavailableRepaymentReleaseSelectionPageReleaseReasonResponse'
      }
      break
    case EnumRefundUnavailableDecision.REFUND_UNAVAILABLE_DISPOSITIF_TABLE:
      {
        const refundUnavailableMotifId: string = yield select(getRefundUnavailableMotifId)

        const signatureDates = yield call(
          fetchRefundSignatureDate,
          companyId,
          refundUnavailableMotifId
        )
        yield put(RefundActions.getRefundUnavailabilitySignatureDateSuccess(signatureDates?.data))
        nextQuestionToBeAsked = yield call(
          RefundUnavailableSignatureDateQuestion,
          signatureDates?.data
        )
        respondedTitle = 'UnavailableRepaymentQuestionChoicePlanResponse'
      }
      break
    case EnumRefundUnavailableDecision.REFUND_UNAVAILABLE_SIGNATURE_DATE:
      {
        // todo : change key
        respondedTitle = 'UnavailableRepaymentReleaseSignatureDateSelection'
      }
      break
    default:
  }
  let newDecision: RefundsCommonState['decision'] = {
    ...refundGlobalState.decision,
    ...updateQuestionWhenResponded(
      refundGlobalState,
      response,
      index,
      canNotBeAsked,
      respondedTitle
    )
  }

  const updatedQuestions = nextQuestionToBeAsked
    ? [...newDecision.questions, { ...nextQuestionToBeAsked }]
    : newDecision.questions

  newDecision = {
    ...newDecision,
    questions: updatedQuestions
  }
  yield put(CommonRefundActions.updateUnavailableRefundsQuestionWithResponseRequest(newDecision))
}

function* updateRefundUnavailableQuestionsSaga() {
  yield takeLatest(
    UnavailableRefundActionsType.UPDATE_UNAVAILABLE_REFUND_QUESTIONS_REQUEST,
    runManager(
      updateRefundUnavailableQuestions,
      UnavailableRefundActionsType.UPDATE_UNAVAILABLE_REFUND_QUESTIONS_FAILURE
    )
  )
}

function* getRefundUnavailabilityDecisionTree(): any {
  const companyId = yield select(getUsedCompanyId)

  const responseTree: RefundUnavailabilityDecisionTreeType = yield call(
    fetchRefundUnavailabilityDecisionTree,
    companyId
  )

  yield put(
    RefundActions.getRefundUnavailabilityDecisionTreeSuccess({
      refundUnavailabilityDecisionTree: responseTree
    })
  )
}

function* getRefundUnavailabilityDecisionTreeSaga() {
  yield takeLatest(
    UnavailableRefundActionsType.GET_REFUND_UNAVAILABILITY_DECISION_TREE_REQUEST,
    runManager(
      getRefundUnavailabilityDecisionTree,
      UnavailableRefundActionsType.GET_REFUND_UNAVAILABILITY_DECISION_TREE_FAILURE
    )
  )
}

export function* UnavailableRefundSagas() {
  yield all([
    fork(getRefundUnavailabilityDecisionTreeSaga),
    fork(updateRefundUnavailableQuestionsSaga)
  ])
}
