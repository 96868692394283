import * as actions from './forgetPasswordActionsTypes';
import { ForgetPasswordActionsType } from './forgetPasswordActionsTypes';
import { ForgetPasswordErrorState } from '../types';

export function ForgetPasswordInitBeginRequest(
  email: string,
): actions.ForgetPasswordInitBeginRequestAction {
  return {
    type: ForgetPasswordActionsType.FORGET_PASSWORD_INIT_BEGIN_REQUEST,
    email
  };
}


export function ForgetPasswordInitBeginSuccess(
  uiidRequestedCode: string,
): actions.ForgetPasswordInitBeginSuccessAction {
  return {
    type: ForgetPasswordActionsType.FORGET_PASSWORD_INIT_BEGIN_SUCCESS,
    uiidRequestedCode
  };
}

export function ForgetPasswordInitBeginFailure(
  error: ForgetPasswordErrorState,
): actions.ForgetPasswordInitBeginFailureAction {
  return {
    type: ForgetPasswordActionsType.FORGET_PASSWORD_INIT_BEGIN_FAILURE,
    error
  };
}

// CONFIRM EMAIL

export function ForgetPasswordConfirmEmailRequest(
  code: string,
): actions.ForgetPasswordConfirmEmailRequestAction {
  return {
    type: ForgetPasswordActionsType.FORGET_PASSWORD_CONFIRM_EMAIL_REQUEST,
    code
  };
}

export function ForgetPasswordConfirmEmailSuccess(
  phoneNumber: string,
): actions.ForgetPasswordConfirmEmailSuccessAction {
  return {
    type: ForgetPasswordActionsType.FORGET_PASSWORD_CONFIRM_EMAIL_SUCCESS,
    phoneNumber
  };
}

export function ForgetPasswordConfirmEmailFailure(
  error: ForgetPasswordErrorState,
): actions.ForgetPasswordConfirmEmailFailureAction {
  return {
    type: ForgetPasswordActionsType.FORGET_PASSWORD_CONFIRM_EMAIL_FAILURE,
    error
  };
}

// CONFIRM SMS

export function ForgetPasswordConfirmSMSMRequest(
): actions.ForgetPasswordConfirmSMSRequestAction {
  return {
    type: ForgetPasswordActionsType.FORGET_PASSWORD_CONFIRM_SMS_REQUEST
  };
}

export function ForgetPasswordConfirmSMSSuccess(
  status: boolean,
): actions.ForgetPasswordConfirmSMSSuccessAction {
  return {
    type: ForgetPasswordActionsType.FORGET_PASSWORD_CONFIRM_SMS_SUCCESS,
    status
  };
}

export function ForgetPasswordConfirmSMSFailure(
  error: ForgetPasswordErrorState,
): actions.ForgetPasswordConfirmSMSFailureAction {
  return {
    type: ForgetPasswordActionsType.FORGET_PASSWORD_CONFIRM_SMS_FAILURE,
    error
  };
}

// CONFIRM SMS CODE

export function ForgetPasswordConfirmSMSCodeRequest(
  code: string
): actions.ForgetPasswordConfirmSMSCodeRequestAction {
  return {
    type: ForgetPasswordActionsType.FORGET_PASSWORD_CONFIRM_SMS_CODE_REQUEST,
    code
  };
}

export function ForgetPasswordConfirmSMSCodeSuccess(
  status: boolean,
): actions.ForgetPasswordConfirmSMSCodeSuccessAction {
  return {
    type: ForgetPasswordActionsType.FORGET_PASSWORD_CONFIRM_SMS_CODE_SUCCESS,
    status
  };
}

export function ForgetPasswordConfirmSMSCodeFailure(
  error: ForgetPasswordErrorState,
): actions.ForgetPasswordConfirmSMSCodeFailureAction {
  return {
    type: ForgetPasswordActionsType.FORGET_PASSWORD_CONFIRM_SMS_CODE_FAILURE,
    error
  };
}

// CONFIRM INIT REGISTRATION PROCESS

export function ForgetPasswordConfirmRequest(
  newPassword: string
): actions.ForgetPasswordConfirmRequestAction {
  return {
    type: ForgetPasswordActionsType.FORGET_PASSWORD_CONFIRM_REQUEST,
    newPassword
  };
}

export function ForgetPasswordConfirmSuccess(
  status: boolean,
): actions.ForgetPasswordConfirmSuccessAction {
  return {
    type: ForgetPasswordActionsType.FORGET_PASSWORD_CONFIRM_SUCCESS,
    status
  };
}

export function ForgetPasswordConfirmFailure(
  error: ForgetPasswordErrorState,
): actions.ForgetPasswordConfirmFailureAction {
  return {
    type: ForgetPasswordActionsType.FORGET_PASSWORD_CONFIRM_FAILURE,
    error
  };
}

// INIT ERROR

export function ForgetPasswordInitError(): actions.ForgetPasswordInitErrorAction {
  return {
    type: ForgetPasswordActionsType.FORGET_PASSWORD_INIT_ERROR
  };
}

export function SetForgetPasswordEmailInput(email: string): actions.SetForgetPasswordEmailInputAction {
  return {
    type: ForgetPasswordActionsType.SET_FORGET_PASSWORD_EMAIL_INPUT,
    email
  };
}
