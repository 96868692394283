import React, { useImperativeHandle, useRef, useState } from 'react';
import {
  SafeAreaView,
  View,
  ScrollView,
  NativeScrollEvent,
  NativeSyntheticEvent
} from 'react-native';

import { DesktopMenu } from '@components/index';
import { BackgroundGradientTransition, PageBackgroundGrey } from '@ere-uilib/atoms';
import { useScreenSizes } from '@ere-uilib/styles';

import { Props } from './interfaces';
import { useStyles } from './styles';

import CustomModal from './components/CustomModal/CustomModal';
import WishModalContent from '@components/ERE360Components/DashboardPageWrapper/components/WishModalContent/WishModalContent';

export const DashboardPageWrapper: React.FC<React.PropsWithChildren<Props>> = ({
  DesktopMenuUserInformationsConnected,
  route,
  onLogoPress,
  children,
  scrollContentContainerStyle,
  renderStickyHeader,
  dashboardPageWrapperRef,
  renderStickyMobileBottom,
  setScrollValue
}) => {
  const ref = useRef() as React.MutableRefObject<ScrollView>;
  const { isMobile, isTablet} = useScreenSizes();
  const styles= useStyles();
  const [isWishMenuModalVisible, setIsWishMenuModalVisible] = useState<boolean>(false)

  const handleScroll = (e: NativeSyntheticEvent<NativeScrollEvent>) => {
    if (setScrollValue) {
      setScrollValue(e.nativeEvent.contentOffset.y)
    }
  }

  useImperativeHandle(
    dashboardPageWrapperRef,
    () => ({
      scrollToTop: () => {
        ref?.current?.scrollTo?.({ y: 0 });
      }
    }),
    []
  );

  return (
    <SafeAreaView style={styles.containerStyle}>
      {isMobile || isTablet ?
        <>
          {renderStickyHeader && renderStickyHeader()}
          <ScrollView
            contentContainerStyle={[styles.scrollContentContainerStyle, scrollContentContainerStyle]}
            onScroll={e => handleScroll(e)}
            ref={ref}
            scrollEventThrottle={16}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            style={styles.scrollContainerStyle}>
            <PageBackgroundGrey>
              <View style={styles.frameContainer}>
                <View style={styles.cardContentStyle}>{children}</View>
              </View>
            </PageBackgroundGrey>
          </ScrollView>
          {renderStickyMobileBottom && (
            <BackgroundGradientTransition containerStyle={styles.stickyButtonsViewStyle}>
              {renderStickyMobileBottom()}
            </BackgroundGradientTransition>
          )}
        </>
        :
        <View style={styles.desktopContainer}>
          <DesktopMenu
            DesktopMenuUserInformationsConnected={DesktopMenuUserInformationsConnected}
            isWishMenuModalVisible={isWishMenuModalVisible}
            onLogoPress={onLogoPress}
            route={route}
            setIsWishMenuModalVisible={setIsWishMenuModalVisible}
          />
          <View style={styles.contentContainer}>
            {renderStickyHeader && renderStickyHeader()}
            <ScrollView
              contentContainerStyle={[styles.scrollContentContainerStyle, scrollContentContainerStyle]}
              onScroll={e => handleScroll(e)}
              ref={ref}
              scrollEventThrottle={16}
              showsHorizontalScrollIndicator={true}
              showsVerticalScrollIndicator={true}
              style={styles.scrollContainerStyle}>
              <PageBackgroundGrey>
                <View style={styles.frameContainer}>
                  <View style={styles.cardContentStyle}>{children}</View>
                </View>
              </PageBackgroundGrey>
            </ScrollView>

            <CustomModal
              customStyle={styles.modalProperties}
              isVisible={isWishMenuModalVisible}
              setIsVisible={setIsWishMenuModalVisible}
            >
              <WishModalContent
                isWishMenuModalVisible={isWishMenuModalVisible}
                setIsWishMenuModalVisible={setIsWishMenuModalVisible} />
            </CustomModal>
          </View>
        </View>
      }
    </SafeAreaView>
  );
};