import { RefundSuccessPageContext, RefundSuccessPageStyle } from './interfaces';

export const getStyles = (
  context?: RefundSuccessPageContext,
  style?: RefundSuccessPageStyle,
): RefundSuccessPageStyle => ({
  stepSubTextStyle: [
    {
      marginTop: context?.theme.metrics.spacing.xs,
      marginBottom: context?.theme.metrics.spacing.s,
      alignItems: 'flex-start',
    },
    style?.stepSubTextStyle,
  ],
  notificationContainerStyle: [{
    marginTop: context?.theme.metrics.spacing.m,
    width: '100%',
  },
  style?.notificationContainerStyle,
  ],
  buttonNewRefund: [{
    marginTop: context?.theme.metrics.spacing.m,
    marginBottom: context?.theme.metrics.spacing.m,
  },
  style?.buttonNewRefund,
  ],
  dashboardAnnonce: [{
    width: '100%',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
  },
  style?.dashboardAnnonce,
  ],
  containerStepper: [{
    alignContent: 'flex-start', alignItems: 'flex-start',
  }, style?.containerStepper],

});
