import * as actions from './installmentActionsTypes';
import { InstallmentActionsType } from './installmentActionsTypes';
import { DispositifState, OperationDecisionState, ResponseType } from '@modules/common/types';
import {
  InstallmentAmountValidationParamsType,
  InstallmentCalculatedContributionState,
  InstallmentCalculContributionParamsType,
  InstallmentErrorState, InstallmentRepartitionState, PaymentModeDataState, PaymentModeState,
  PaymentResponseDataState
} from '@modules/installment/types';
import { OnGoingVVP } from '@modules/savings/types';

// installment - decision step
export function getInstallmentDecisionRequest(): actions.GetInstallmentDecisionRequestAction {
  return {
    type: InstallmentActionsType.GET_INSTALLMENT_DECISION_REQUEST,
  };
}

export function getInstallmentDecisionSuccess(
  decision: OperationDecisionState,
): actions.GetInstallmentDecisionSuccesstAction {
  return {
    type: InstallmentActionsType.GET_INSTALLMENT_DECISION_SUCCESS,
    decision,
  };
}

export function getInstallmentDecisionFailure(
  error: InstallmentErrorState,
): actions.GetInstallmentDecisionFailureAction {
  return {
    type: InstallmentActionsType.GET_INSTALLMENT_DECISION_FAILURE,
    error,
  };
}
/// update question with set response

export function updateInstallmentQuestionWithResponse(
  response: ResponseType,
  index: number,
  canNotBeAsked: boolean
): actions.UpdateInstallmentQuestionWithResponse {
  return {
    type: InstallmentActionsType.UPDATE_QUESTION_WITH_SET_RESPONSE,
    response,
    index,
    canNotBeAsked,
  };
}

/// back to question

export function backToQuestionRequest(
  index: number
): actions.BackToQuestion {
  return {
    type: InstallmentActionsType.BACK_TO_QUESTION,
    index,
  };
}

export function setEligibleDispositifs(
  elligibleDispositifs: DispositifState[]
): actions.SetEligibleDispositifs {
  return {
    type: InstallmentActionsType.SET_ELIGIBLE_DISPOSITIFS,
    elligibleDispositifs,
  };
}

export function setSelectedDispositif(
  selectedDispositif: DispositifState[]
): actions.SetSelectedDispositif {
  return {
    type: InstallmentActionsType.SET_SELECTED_DISPOSITIF,
    selectedDispositif,
  };
}

// installment - repartition step
export function getInstallmentRepartitionDataRequest({ routePlanId }:{routePlanId?:string}): actions.GetInstallmentRepartitionDataRequestAction {
  return {
    type: InstallmentActionsType.GET_INSTALLMENT_REPARTITION_DATA_REQUEST,
    routePlanId,
  };
}

export function getInstallmentRepartitionDataSuccess(
  installmentRepartitionData: InstallmentRepartitionState,
): actions.GetInstallmentRepartitionDataSuccessAction {
  return {
    type: InstallmentActionsType.GET_INSTALLMENT_REPARTITION_DATA_SUCCESS,
    installmentRepartitionData,
  };
}

export function getInstallmentRepartitionDataFailure(
  error: InstallmentErrorState,
): actions.GetInstallmentRepartitionDataFailureAction {
  return {
    type: InstallmentActionsType.GET_INSTALLMENT_REPARTITION_DATA_FAILURE,
    error,
  };
}

export function setInstallmentRepartitionAmountRequest(params:InstallmentAmountValidationParamsType): actions.SetInstallmentRepartitionAmountRequestAction {
  return {
    type: InstallmentActionsType.SET_INSTALLMENT_REPARTITION_AMOUNT_REQUEST,
    params,
  };
}

export function setInstallmentRepartitionAmountSuccess(
  {
    calculatedContribution,
    installmentAmountValidationParams,
  }:{
    calculatedContribution: InstallmentCalculatedContributionState|undefined
    installmentAmountValidationParams: InstallmentAmountValidationParamsType
  }
): actions.SetInstallmentRepartitionAmountSuccessAction {
  return {
    type: InstallmentActionsType.SET_INSTALLMENT_REPARTITION_AMOUNT_SUCCESS,
    calculatedContribution,
    installmentAmountValidationParams,
  };
}

export function setInstallmentRepartitionAmountFailure(
  error: InstallmentErrorState,
  params?: InstallmentAmountValidationParamsType
): actions.SetInstallmentRepartitionAmountFailureAction {
  return {
    type: InstallmentActionsType.SET_INSTALLMENT_REPARTITION_AMOUNT_FAILURE,
    error,
    params,
  };
}

export function setInstallmentRepartitionAmountModalVisibleState(
  state: boolean,
): actions.SetInstallmentRepartitionAmountModalVisibleStateAction {
  return {
    type: InstallmentActionsType.SET_INSTALLMENT_REPARTITION_AMOUNT_MODAL_VISIBLE_STATE,
    state,
  };
}

// contribution
export function getCalculatedContributionDataRequest(params:InstallmentCalculContributionParamsType): actions.GetCalculatedContributionDataRequestAction {
  return {
    type: InstallmentActionsType.GET_CALCULATED_CONTRIBUTION_DATA_REQUEST,
    params,
  };
}

export function getCalculatedContributionDataSuccess(
  contributionData: InstallmentCalculatedContributionState,
): actions.GetCalculatedContributionDataSuccessAction {
  return {
    type: InstallmentActionsType.GET_CALCULATED_CONTRIBUTION_DATA_SUCCESS,
    contributionData,
  };
}

export function getCalculatedContributionDataFailure(
  error: InstallmentErrorState,
): actions.GetCalculatedContributionDataFailureAction {
  return {
    type: InstallmentActionsType.GET_CALCULATED_CONTRIBUTION_DATA_FAILURE,
    error,
  };
}
export function initCalculatedContributionData(
): actions.InitCalculatedContributionDataAction {
  return {
    type: InstallmentActionsType.INIT_CALCULATED_CONTRIBUTION_DATA,
  };
}

export function calculatedContributionInitError(
): actions.CalculatedContributionInitErrorAction {
  return {
    type: InstallmentActionsType.CALCULATED_CONTRIBUTION_INIT_ERROR,
  };
}

// Risk Profil
export function setInstallmentRiskProfil(): actions.SetInstallmentRiskProfilAction {
  return {
    type: InstallmentActionsType.SET_INSTALMENT_RISK_PROFIL,
  };
}

// common
export function setInstallmentPaymentModeChoice(
  paymentModeChoice: PaymentModeState
): actions.SetInstallmentPaymentModeChoiceAction {
  return {
    type: InstallmentActionsType.SET_INSTALLMENT_PAYMENT_MODE_CHOICE,
    paymentMode: paymentModeChoice,
  };
}

export function installmentInitError(): actions.InstallmentInitErrorAction {
  return {
    type: InstallmentActionsType.INSTALLMENT_INIT_ERROR,
  };
}

// installment - payment mode && bank account detail
export function getInstallmentPaymentModeAndBankAccountDetailRequest(
  { routePlanId }:{routePlanId?:string}
): actions.GetInstallmentPaymentModeAndBankAccountDetailRequestAction {
  return {
    type: InstallmentActionsType.GET_INSTALLMENT_PAYMENT_MODE_AND_BANK_ACCOUNT_DETAIL_REQUEST,
    routePlanId,
  };
}

export function getInstallmentPaymentModeAndBankAccountDetailSuccess(
  paymentModeData: PaymentModeDataState[],
): actions.GetInstallmentPaymentModeAndBankAccountDetailSuccessAction {
  return {
    type: InstallmentActionsType.GET_INSTALLMENT_PAYMENT_MODE_AND_BANK_ACCOUNT_DETAIL_SUCCESS,
    paymentModeData,
  };
}

export function clearInstallmentData(): actions.ClearInstallmentDataAction {
  return {
    type: InstallmentActionsType.CLEAR_INSTALLMENT_DATA,
  };
}

export function getInstallmentPaymentModeAndBankAccountDetailFailure(
  error: InstallmentErrorState,
): actions.GetInstallmentPaymentModeAndBankAccountDetailFailureAction {
  return {
    type: InstallmentActionsType.GET_INSTALLMENT_PAYMENT_MODE_AND_BANK_ACCOUNT_DETAIL_FAILURE,
    error,
  };
}

// installment - send installment payment data
export function SendInstallmentPaymentDataRequest(
): actions.SendInstallmentPaymentDataRequestAction {
  return {
    type: InstallmentActionsType.SEND_INSTALLMENT_PAYMENT_DATA_REQUEST,
  };
}

export function SendInstallmentPaymentDataSuccess(
  paymentResponseData: PaymentResponseDataState
): actions.SendInstallmentPaymentDataSuccessAction {
  return {
    type: InstallmentActionsType.SEND_INSTALLMENT_PAYMENT_DATA_SUCCESS,
    paymentResponseData,
  };
}

export function SendInstallmentPaymentDataFailure(
  error: InstallmentErrorState,
): actions.SendInstallmentPaymentDataFailureAction {
  return {
    type: InstallmentActionsType.SEND_INSTALLMENT_PAYMENT_DATA_FAILURE,
    error,
  };
}

// installment - check Payment status for id
export function CheckInstallmentPaymentStatusForIdRequest(
  id: string
): actions.CheckInstallmentPaymentStatusForIdRequestAction {
  return {
    type: InstallmentActionsType.CHECK_INSTALLMENT_PAYMENT_STATUS_FOR_ID_REQUEST,
    id,
  };
}

export function CheckInstallmentPaymentStatusForIdSuccess(
): actions.CheckInstallmentPaymentStatusForIdSuccessAction {
  return {
    type: InstallmentActionsType.CHECK_INSTALLMENT_PAYMENT_STATUS_FOR_ID_SUCCESS,
  };
}

export function CheckInstallmentPaymentStatusForIdFailure(
  error: InstallmentErrorState,
): actions.CheckInstallmentPaymentStatusForIdFailureAction {
  return {
    type: InstallmentActionsType.CHECK_INSTALLMENT_PAYMENT_STATUS_FOR_ID_FAILURE,
    error,
  };
}

// Get and gather VVP Details to edit

export function onEditVVPRequest({
  operationId,
}:{
  operationId: string
}): actions.OnVVPEditRequestAction {
  return {
    type: InstallmentActionsType.ON_VVP_EDIT_REQUEST,
    operationId,
  };
}

export function onEditVVPSuccess({
  editedVVPData,
  revievedRepartition,
}:{
  editedVVPData: OnGoingVVP,
  revievedRepartition: InstallmentRepartitionState,
}): actions.OnVVPEditSuccessAction {
  return {
    type: InstallmentActionsType.ON_VVP_EDIT_SUCCESS,
    editedVVPData,
    revievedRepartition,
  };
}

export function onEditVVPFailure(
  error: InstallmentErrorState
): actions.OnVVPEditFailureAction {
  return {
    type: InstallmentActionsType.ON_VVP_EDIT_FAILURE,
    error,
  };
}

// Delete ongoing VVP

export function onDeleteVVPRequest({
  planId,
  operationId,
}:{
  planId: string
  operationId: string
}): actions.OnVVPDeleteRequestAction {
  return {
    type: InstallmentActionsType.ON_VVP_DELETE_REQUEST,
    planId,
    operationId,
  };
}

export function onDeleteVVPSuccess(): actions.OnVVPDeleteSuccessAction {
  return {
    type: InstallmentActionsType.ON_VVP_DELETE_SUCCESS,
  };
}

export function onDeleteVVPFailure(
  error: InstallmentErrorState
): actions.OnVVPDeleteFailureAction {
  return {
    type: InstallmentActionsType.ON_VVP_DELETE_FAILURE,
    error,
  };
}
