import React, { createContext, PropsWithChildren, useCallback, useMemo, useState } from 'react';

type TabsProviderProps = PropsWithChildren<Partial<TabsContextData>>;

export interface TabsContextData<T = string> {
  activeTab: T | undefined;
  onChangeTab: (activeTab: T) => void;
}

export const TabsContext = createContext<TabsContextData>({
  activeTab: undefined,
  onChangeTab: () => {}
});

export const TabsProvider = ({
  children,
  activeTab: initialTab,
  onChangeTab: bubbleTab
}: TabsProviderProps) => {
  const [activeTab, setActiveTab] = useState(initialTab);

  const onChangeTab = useCallback(
    (value: string) => {
      setActiveTab(value);
      bubbleTab?.(value);
    },
    [bubbleTab]
  );

  const value = useMemo(() => ({ activeTab, onChangeTab }), [activeTab, onChangeTab]);

  return <TabsContext.Provider value={value}>{children}</TabsContext.Provider>;
};
