import jwt from 'jwt-decode';
import { REACT_APP_AES_KEY } from '@config/index';
import { AuthActionType, AuthenticateAction } from './actions/authActionsTypes';
import { ErrorActionsType } from '@modules/error/actions/errorActionsTypes';
import { encryptData } from '@utils/encryptDecryptData';

import { AuthState } from './types';

export declare type BiometryType = 'TouchID' | 'FaceID' | 'Biometrics';

export const initialState: AuthState = {
  login: {
    redirectUrl: '',
    isOk: false,
  },
  userInfo: {
    givenName: '',
    familyName: '',
    "https://login.ere.bnpparibas/claims/lastconnection": ''
  },
  error: {
    code: '',
    correlationId: '',
    message: '',
    innerMessage: '',
  },
  nativeAuthenticationError: {
    error: '',
    error_description: ''
  },
  nativeBiometricAuthenticationError: {
    error: '',
    error_description: ''
  },
  user: {
    id_token: '',
    session_state: '',
    access_token: '',
    refresh_token: '',
    token_type: '',
    scope: '',
    profile: '',
    expires_at: 0,
  },
  refreshSessionRequestAlreadyLoading: false,
  isOidcSessionEnded: false,
  loginMail: undefined,
  biometricAcceptationStatus: undefined,
  shouldPromptBiometricModalAskVisible: false,
  BiometryType: '',
};

export default function authReducer(
  state: AuthState = initialState,
  action: AuthenticateAction
): AuthState {
  switch (action?.type) {
    case AuthActionType.PROMPT_BIOMETRIC_USER_MODAL_ASK_REQUEST:
      return {
        ...state,
        shouldPromptBiometricModalAskVisible: action.isVisible,
        BiometryType: action.biometryType
      }
    case AuthActionType.PROMPT_BIOMETRIC_USER_MODAL_ASK_SUCCESS:
      return {
        ...state,
        shouldPromptBiometricModalAskVisible: action.isVisible,
        biometricAcceptationStatus: action.success
      }
    case AuthActionType.SET_BIOMETRIC_CREDENTIALS:
      return {
        ...state,
        biometricAcceptationStatus: action.biometricAcceptationStatus
      }
    case AuthActionType.SAVE_LOGIN:
      return {
        ...state,
        loginMail: action.loginMail
      };
    case AuthActionType.SILENT_RENEW_TOKEN_REQUEST:
      return {
        ...state,
        refreshSessionRequestAlreadyLoading: true,
      };
    case AuthActionType.SIGNIN_NATIVE_APP_USING_BIOMETRIC_SUCCESS:
    case AuthActionType.SIGNIN_NATIVE_APP_SUCCESS: {
      return {
        ...state,
        user: {
          ...action.user,
          profile: encryptData(jwt(action?.user?.access_token), REACT_APP_AES_KEY) || initialState.user.profile,
        }
      }
    }
     ;
    case AuthActionType.SILENT_RENEW_TOKEN_SUCCESS:
    case AuthActionType.SIGNIN_REDIRECT_CALLBACK_SUCCESS:
      return {
        ...state,
        refreshSessionRequestAlreadyLoading: false,
        user: {
          ...action?.user,
          profile: encryptData(action?.user?.profile, REACT_APP_AES_KEY) || initialState.user.profile
        },
        isOidcSessionEnded: false
      };
    case AuthActionType.SET_IS_OIDC_SESSION_ENDED:
      return {
        ...state,
        isOidcSessionEnded: action.newState,
      };
    case AuthActionType.SIGNIN_NATIVE_APP_FAILURE:
      return {
        ...state,
        nativeAuthenticationError: action.error,
      };
    case AuthActionType.SIGNIN_REDIRECT_CALLBACK_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case AuthActionType.SILENT_RENEW_TOKEN_FAILURE:
      return {
        ...state,
        refreshSessionRequestAlreadyLoading: false,
        error: action.error
      };
    case AuthActionType.SIGNIN_NATIVE_APP_USING_BIOMETRIC_FAILURE:
      return {
        ...state,
        nativeBiometricAuthenticationError: action.error,
      }
    case AuthActionType.LOGOUT_CALLBACK_SUCCESS:
      return {
        ...initialState,
        loginMail: state.loginMail,
        biometricAcceptationStatus: state.biometricAcceptationStatus,
        BiometryType: state.BiometryType
      };
    case AuthActionType.USER_INFO_SUCCESS:
      return {
        ...state,
        userInfo: action.userInfo
      };
    case AuthActionType.AUTH_INIT_ERROR:
    case ErrorActionsType.INIT_ALL_ERRORS:
      return {
        ...state,
        nativeAuthenticationError: initialState.nativeAuthenticationError,
        error: initialState.error,
        nativeBiometricAuthenticationError: initialState.nativeBiometricAuthenticationError
      };
    default:
      return state;
  }
}
