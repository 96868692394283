import { PlanCompartmentsType } from "@modules/common/types";

export const mockDispositifDetails: PlanCompartmentsType = {
  "planId": 2501,
  "planName": "PERO",
  "planFullName": "PERO SUPER",
  "isCustomPlan": false,
  "planType": "PERO",
  "compartments": [
    {
      "code": "1",
      "label": "Versements volontaires déductibles",
      "descriptionLabel": 'BLABLA',
      "managements": [
        {
          "id": "10004",
          "code": "Gestion Libre C1",
          "label": "Gestion Libre C1",
          "totalAmount": 4500.0,
          isFictive: true,
          "supports": [
            {
              source: '',
              "supportIsin": "LU0992628858", "supportName": "CARMIGNAC PORTFOLIO GRANDE EUROPE (F)",
              "supportType": '',
              "hasNoFundSheet": false,
              "supportIdNoee": "3337",
              "riskLevelSRI": "5",
              "riskLevelSRRI": "6",
              "performanceValue": 7.19,
              "amount": {
                "amount": 2000.0,
                "addedValue": 0.0, "numberOfUnits": 0.0
              },
              "rate": null,
              "supportDetailsUrl": null,
              "legalStatus": "SICAV",
              "isMaster": false,
              "hasContribution": false,
              "netAssetValue": {
                "amount": 215.79,
                "currency": 'eur',
                "dateValue": "2021-09-17T00:00:00+02:00"
              },
              "plans": null,
              "hasVersement": true,
              "hasInteressement": false,
              "hasParticipation": false,
              "nextNavDate": "21/09/2021 00:00:00 +02:00",
              "advancedProperties": {
                "HasRepaymentOrArbitrationTransactionInProgress": true,
                "HasThresholdTrigger": false,
                "CategorieClassification": "Action"
              },
              "filterProperties": {
                "StockReallocation": true
              },
              "isISR": false
            },
            {
              source: '',
              "supportIsin": "FR0010839274",
              "supportName": "Epargne salariale",
              "supportType": '',
              "hasNoFundSheet": false,
              "supportIdNoee": "3337",
              "riskLevelSRI": "5",
              "riskLevelSRRI": "6",
              "performanceValue": 7.19,
              "amount": {
                "amount": 1500.0,
                "addedValue": 0.0,
                "numberOfUnits": 0.0
              },
              "rate": null,
              "supportDetailsUrl": null,
              "legalStatus": "SICAV",
              "isMaster": false,
              "hasContribution": false,
              "netAssetValue": {
                "amount": 215.79,
                "currency": 'eur',
                "dateValue": "2021-09-17T00:00:00+02:00"
              },
              "plans": null,
              "hasVersement": true,
              "hasInteressement": false,
              "hasParticipation": false,
              "nextNavDate": "21/09/2021 00:00:00 +02:00",
              "advancedProperties": {
                "HasRepaymentOrArbitrationTransactionInProgress": true,
                "HasThresholdTrigger": false,
                "CategorieClassification": "Monétaire"
              },
              "filterProperties": {
                "StockReallocation": true
              },
              "isISR": false
            },
            {
              source: '',
              "supportIsin": "QS0002105TY1",
              "supportName": "Epargne salariale",
              "supportType": '',
              "hasNoFundSheet": false,
              "supportIdNoee": "3337",
              "riskLevelSRI": "5",
              "riskLevelSRRI": "6",
              "performanceValue": 7.19,
              "amount": {
                "amount": 1500.0,
                "addedValue": 0.0,
                "numberOfUnits": 0.0
              },
              "rate": null,
              "supportDetailsUrl": null,
              "legalStatus": "SICAV",
              "isMaster": false,
              "hasContribution": false,
              "netAssetValue": {
                "amount": 215.79,
                "currency": 'eur',
                "dateValue": "2021-09-17T00:00:00+02:00"
              },
              "plans": null,
              "hasVersement": true,
              "hasInteressement": false,
              "hasParticipation": false,
              "nextNavDate": "21/09/2021 00:00:00 +02:00",
              "advancedProperties": {
                "HasRepaymentOrArbitrationTransactionInProgress": true,
                "HasThresholdTrigger": false,
                "CategorieClassification": "Obligataire"
              },
              "filterProperties": {
                "StockReallocation": true
              },
              "isISR": false
            },
            {
              source: '',
              "supportIsin": "FG CS",
              "supportName": "Versements volontaires déductibles",
              "supportType": '',
              "hasNoFundSheet": false,
              "supportIdNoee": "3337",
              "riskLevelSRI": "5",
              "riskLevelSRRI": "6",
              "performanceValue": 7.19,
              "amount": {
                "amount": 1500.0,
                "addedValue": 0.0,
                "numberOfUnits": 0.0
              },
              "rate": null,
              "supportDetailsUrl": null,
              "legalStatus": "SICAV",
              "isMaster": false,
              "hasContribution": false,
              "netAssetValue": {
                "amount": 215.79,
                "currency": 'eur',
                "dateValue": "2021-09-17T00:00:00+02:00"
              },
              "plans": null,
              "hasVersement": true,
              "hasInteressement": false,
              "hasParticipation": false,
              "nextNavDate": "21/09/2021 00:00:00 +02:00",
              "advancedProperties": {
                "HasRepaymentOrArbitrationTransactionInProgress": true,
                "HasThresholdTrigger": false,
                "CategorieClassification": "Diversifié"
              },
              "filterProperties": {
                "StockReallocation": true
              },
              "isISR": false
            },
            {
              source: '',
              "supportIsin": "QS0002105TY1",
              "supportName": "Versements obligatoires",
              "supportType": '',
              "hasNoFundSheet": false,
              "supportIdNoee": "3337",
              "riskLevelSRI": "5",
              "riskLevelSRRI": "6",
              "performanceValue": 7.19,
              "amount": {
                "amount": 1500.0,
                "addedValue": 0.0,
                "numberOfUnits": 0.0
              },
              "rate": null,
              "supportDetailsUrl": null,
              "legalStatus": "SICAV",
              "isMaster": false,
              "hasContribution": false,
              "netAssetValue": {
                "amount": 215.79,
                "currency": 'eur',
                "dateValue": "2021-09-17T00:00:00+02:00"
              },
              "plans": null,
              "hasVersement": true,
              "hasInteressement": false,
              "hasParticipation": false,
              "nextNavDate": "21/09/2021 00:00:00 +02:00",
              "advancedProperties": {
                "HasRepaymentOrArbitrationTransactionInProgress": true,
                "HasThresholdTrigger": false,
                "CategorieClassification": "Diversifié"
              },
              "filterProperties": {
                "StockReallocation": true
              },
              "isISR": false
            },
          ],
          "order": 0,
          "isFree": true,
          "filterProperties": {
            "TaxOut": true,
            "VVL": true,
            "VVP": true
          },
          "advancedProperties": {
          },
        },
        {
          "id": "10005",
          "code": "Gestion Libre C1",
          "label": "Gestion Libre C1",
          "totalAmount": 4500.0,
          isFictive: true,
          "supports": [
            {
              source: '',
              "supportIsin": "LU0992628858", "supportName": "CARMIGNAC PORTFOLIO GRANDE EUROPE (F)",
              "supportType": '',
              "hasNoFundSheet": false,
              "supportIdNoee": "3337",
              "riskLevelSRI": "5",
              "riskLevelSRRI": "6",
              "performanceValue": 7.19,
              "amount": {
                "amount": 2000.0,
                "addedValue": 0.0, "numberOfUnits": 0.0
              },
              "rate": null,
              "supportDetailsUrl": null,
              "legalStatus": "SICAV",
              "isMaster": false,
              "hasContribution": false,
              "netAssetValue": {
                "amount": 215.79,
                "currency": 'eur',
                "dateValue": "2021-09-17T00:00:00+02:00"
              },
              "plans": null,
              "hasVersement": true,
              "hasInteressement": false,
              "hasParticipation": false,
              "nextNavDate": "21/09/2021 00:00:00 +02:00",
              "advancedProperties": {
                "HasRepaymentOrArbitrationTransactionInProgress": true,
                "HasThresholdTrigger": false,
                "CategorieClassification": "Action"
              },
              "filterProperties": {
                "StockReallocation": true
              },
              "isISR": false
            },
            {
              source: '',
              "supportIsin": "LU0992628858",
              "supportName": "CARMIGNAC PORTFOLIO GRANDE EUROPE (F)",
              "supportType": '',
              "hasNoFundSheet": false,
              "supportIdNoee": "3337",
              "riskLevelSRI": "5",
              "riskLevelSRRI": "6",
              "performanceValue": 7.19,
              "amount": {
                "amount": 1500.0,
                "addedValue": 0.0,
                "numberOfUnits": 0.0
              },
              "rate": null,
              "supportDetailsUrl": null,
              "legalStatus": "SICAV",
              "isMaster": false,
              "hasContribution": false,
              "netAssetValue": {
                "amount": 215.79,
                "currency": 'eur',
                "dateValue": "2021-09-17T00:00:00+02:00"
              },
              "plans": null,
              "hasVersement": true,
              "hasInteressement": false,
              "hasParticipation": false,
              "nextNavDate": "21/09/2021 00:00:00 +02:00",
              "advancedProperties": {
                "HasRepaymentOrArbitrationTransactionInProgress": true,
                "HasThresholdTrigger": false,
                "CategorieClassification": "Monétaire"
              },
              "filterProperties": {
                "StockReallocation": true
              },
              "isISR": false
            },
            {
              source: '',
              "supportIsin": "LU0992628858",
              "supportName": "CARMIGNAC PORTFOLIO GRANDE EUROPE (F)",
              "supportType": '',
              "hasNoFundSheet": false,
              "supportIdNoee": "3337",
              "riskLevelSRI": "5",
              "riskLevelSRRI": "6",
              "performanceValue": 7.19,
              "amount": {
                "amount": 1500.0,
                "addedValue": 0.0,
                "numberOfUnits": 0.0
              },
              "rate": null,
              "supportDetailsUrl": null,
              "legalStatus": "SICAV",
              "isMaster": false,
              "hasContribution": false,
              "netAssetValue": {
                "amount": 215.79,
                "currency": 'eur',
                "dateValue": "2021-09-17T00:00:00+02:00"
              },
              "plans": null,
              "hasVersement": true,
              "hasInteressement": false,
              "hasParticipation": false,
              "nextNavDate": "21/09/2021 00:00:00 +02:00",
              "advancedProperties": {
                "HasRepaymentOrArbitrationTransactionInProgress": false,
                "HasThresholdTrigger": false,
                "CategorieClassification": "Obligation"
              },
              "filterProperties": {
                "StockReallocation": true
              },
              "isISR": false
            },
            {
              source: '',
              "supportIsin": "LU0992628858",
              "supportName": "CARMIGNAC PORTFOLIO GRANDE EUROPE (F)",
              "supportType": '',
              "hasNoFundSheet": false,
              "supportIdNoee": "3337",
              "riskLevelSRI": "5",
              "riskLevelSRRI": "6",
              "performanceValue": 7.19,
              "amount": {
                "amount": 1500.0,
                "addedValue": 0.0,
                "numberOfUnits": 0.0
              },
              "rate": null,
              "supportDetailsUrl": null,
              "legalStatus": "SICAV",
              "isMaster": false,
              "hasContribution": false,
              "netAssetValue": {
                "amount": 215.79,
                "currency": 'eur',
                "dateValue": "2021-09-17T00:00:00+02:00"
              },
              "plans": null,
              "hasVersement": true,
              "hasInteressement": false,
              "hasParticipation": false,
              "nextNavDate": "21/09/2021 00:00:00 +02:00",
              "advancedProperties": {
                "HasRepaymentOrArbitrationTransactionInProgress": false,
                "HasThresholdTrigger": false,
                "CategorieClassification": "Diversifié"
              },
              "filterProperties": {
                "StockReallocation": true
              },
              "isISR": false
            },
            {
              source: '',
              "supportIsin": "LU0992628858",
              "supportName": "CARMIGNAC PORTFOLIO GRANDE EUROPE (F)",
              "supportType": '',
              "hasNoFundSheet": false,
              "supportIdNoee": "3337",
              "riskLevelSRI": "5",
              "riskLevelSRRI": "6",
              "performanceValue": 7.19,
              "amount": {
                "amount": 1500.0,
                "addedValue": 0.0,
                "numberOfUnits": 0.0
              },
              "rate": null,
              "supportDetailsUrl": null,
              "legalStatus": "SICAV",
              "isMaster": false,
              "hasContribution": false,
              "netAssetValue": {
                "amount": 215.79,
                "currency": 'eur',
                "dateValue": "2021-09-17T00:00:00+02:00"
              },
              "plans": null,
              "hasVersement": true,
              "hasInteressement": false,
              "hasParticipation": false,
              "nextNavDate": "21/09/2021 00:00:00 +02:00",
              "advancedProperties": {
                "HasRepaymentOrArbitrationTransactionInProgress": false,
                "HasThresholdTrigger": false,
                "CategorieClassification": "Diversifié"
              },
              "filterProperties": {
                "StockReallocation": true
              },
              "isISR": false
            },
          ],
          "order": 0,
          "isFree": true,
          "filterProperties": {
            "TaxOut": true,
            "VVL": true,
            "VVP": true
          },
          "advancedProperties": {
          },
        }
      ],
      "order": 3
    },
    {
      "code": "3",
      "label": "Versements volontaires non déductibles",
      "descriptionLabel": 'BLABLA',
      "managements": [
        {
          isFictive: false,
          "id": "30001",
          "code": "Gestion Libre",
          "label": "Gestion Libre",
          "totalAmount": 2500.0,
          "supports": [
            {
              source: '',
              "supportIsin": "LU0992628858",
              "supportName": "CARMIGNAC PORTFOLIO GRANDE EUROPE (F)",
              "supportType": '',
              "hasNoFundSheet": false,
              "supportIdNoee": "3337",
              "riskLevelSRI": "5",
              "riskLevelSRRI": "6",
              "performanceValue": 7.19,
              "amount": {
                "amount": 1500.0,
                "addedValue": 0.0,
                "numberOfUnits": 0.0
              },
              "rate": null,
              "supportDetailsUrl": null,
              "legalStatus": "SICAV",
              "isMaster": false,
              "hasContribution": false,
              "netAssetValue": {
                "amount": 215.79,
                "currency": 'eur',
                "dateValue": "2021-09-17T00:00:00+02:00"
              },
              "plans": null,
              "hasVersement": true,
              "hasInteressement": false,
              "hasParticipation": false,
              "nextNavDate": "21/09/2021 00:00:00 +02:00",
              "advancedProperties": {
                "HasRepaymentOrArbitrationTransactionInProgress": false,
                "HasThresholdTrigger": false,
                "CategorieClassification": "Action"
              },
              "filterProperties": {
                "StockReallocation": true
              },
              "isISR": false
            },
            {
              source: '',
              "supportIsin": "QS0002105TY1",
              "supportName": "MULTIPAR MONETAIRE SELECTION",
              "supportType": '',
              "hasNoFundSheet": false,
              "supportIdNoee": "1039",
              "riskLevelSRI": "1",
              "riskLevelSRRI": "2",
              "performanceValue": -0.124147047265255,
              "amount": {
                "amount": 1000.0,
                "addedValue": 0.0,
                "numberOfUnits": 0.0
              },
              "rate": null,
              "supportDetailsUrl": null,
              "legalStatus": "FCP",
              "isMaster": false,
              "hasContribution": false,
              "netAssetValue": {
                "amount": 11.0672,
                "currency": "EUR",
                "dateValue": "2021-03-14T00:00:00+01:00"
              },
              "plans": null,
              "hasVersement": true,
              "hasInteressement": false,
              "hasParticipation": false,
              "nextNavDate": "21/09/2021 00:00:00 +02:00",
              "advancedProperties": {
                "HasRepaymentOrArbitrationTransactionInProgress": false,
                "HasThresholdTrigger": false,
                "CategorieClassification": "Monétaire"
              },
              "filterProperties": {
                "StockReallocation": true
              },
              "isISR": false
            }
          ],
          "order": 0,
          "isFree": true,
          "filterProperties": {
            "TaxIn": true,
            "VVL": true,
            "VVP": true
          },
          "advancedProperties": {
          }
        }
      ],
      "order": 4
    },
    {
      "code": "4",
      "label": "Epargne salariale",
      "descriptionLabel": 'BLABLA',
      "managements": [
        {
          isFictive: true,
          "id": "40001",
          "code": "Gestion Libre C1 bis",
          "label": "Gestion Libre C1 bis",
          "totalAmount": 250.0,
          "supports": [
            {
              source: '',
              "supportIsin": "LU0992628858",
              "supportName": "CARMIGNAC PORTFOLIO GRANDE EUROPE (F)",
              "supportType": 'eur',
              "hasNoFundSheet": false,
              "supportIdNoee": "3337",
              "riskLevelSRI": "5",
              "riskLevelSRRI": "6",
              "performanceValue": 7.19,
              "amount": {
                "amount": 150.0,
                "addedValue": 0.0,
                "numberOfUnits": 0.0
              },
              "rate": null,
              "supportDetailsUrl": null,
              "legalStatus": "SICAV",
              "isMaster": false,
              "hasContribution": false,
              "netAssetValue": {
                "amount": 215.79,
                "currency": '',
                "dateValue": "2021-09-17T00:00:00+02:00"
              },
              "plans": null,
              "hasVersement": true,
              "hasInteressement": false,
              "hasParticipation": false,
              "nextNavDate": "21/09/2021 00:00:00 +02:00",
              "advancedProperties": {
                "HasRepaymentOrArbitrationTransactionInProgress": false,
                "HasThresholdTrigger": false,
                "CategorieClassification": "Action"
              },
              "filterProperties": {
                "StockReallocation": true
              },
              "isISR": false
            },
            {
              source: '',
              "supportIsin": "QS0002105TY1",
              "supportName": "MULTIPAR MONETAIRE SELECTION",
              "supportType": '',
              "hasNoFundSheet": false,
              "supportIdNoee": "1039",
              "riskLevelSRI": "1",
              "riskLevelSRRI": "2",
              "performanceValue": -0.124147047265255,
              "amount": {
                "amount": 100.0,
                "addedValue": 0.0,
                "numberOfUnits": 0.0
              },
              "rate": null,
              "supportDetailsUrl": null,
              "legalStatus": "FCP",
              "isMaster": false,
              "hasContribution": false,
              "netAssetValue": {
                "amount": 11.0672,
                "currency": "EUR",
                "dateValue": "2021-03-14T00:00:00+01:00"
              },
              "plans": null,
              "hasVersement": true,
              "hasInteressement": false,
              "hasParticipation": false,
              "nextNavDate": "21/09/2021 00:00:00 +02:00",
              "advancedProperties": {
                "HasRepaymentOrArbitrationTransactionInProgress": true,
                "HasThresholdTrigger": false,
                "CategorieClassification": "Monétaire"
              },
              "filterProperties": {
                "StockReallocation": true
              },
              "isISR": false
            }
          ],
          "order": 0,
          "isFree": true,
          "filterProperties": {
            "VVL": false,
            "VVP": false
          },
          "advancedProperties": {
          }
        }
      ],
      "order": 2
    }
  ],
  "filterProperties": {
    "VVL": true,
    "VVP": true,
    "TaxOut": true,
    "TaxIn": true,
    "RetirementOnly": true,
    "Abondement": false,
    "Invested": true,
    "ThresholdTrigger": false,
    "StockReallocation": true,
    "PartialReallocation": false,
    "FlowReallocation": true,
    "OadReallocation": false,
    "Contribution": false,
  },
  "advancedProperties": {
  },
  "colors": [
    "#F28B2D"
  ],
  "planFamily": "RC",
  "label": "​PER Obligatoire",
  "shortLabel": "PERO",
  "horizon": "Retraite"
};
