import { Context, Styles } from './interfaces';

export const getStyles = (
  context?: Context,
  style?: Styles,
): Styles => ({
  paragraphTextStyle: [
    {
      textAlign: 'center'
    },
    style?.paragraphTextStyle
  ],
  exempleImageContainer: [
    {
      width: '100%',
    }, style?.exempleImage,
  ],
  exempleImage: [
    {
      height: 400,
    }, style?.exempleImage,
  ],
  modalTitle: [
    {
      textAlign: 'center',
    }, style?.modalTitle,
  ],
  submitButtonsContainer: [
    {
      flexDirection: 'row',
      justifyContent: 'space-around',
      paddingVertical: context?.theme.metrics.spacing.s,
    }, style?.submitButtonsContainer,
  ],
  buttonSpacingView: [
    {
      width: context?.theme.metrics.spacing.m,
    }, style?.buttonSpacingView,
  ],
  paragraphAndDashedLine: [
    {
      marginVertical: context?.theme.metrics.spacing.xm,
    }, style?.paragraphAndDashedLine,
  ],
  dateAndValueStyle: [
    {
      textAlign: 'center',
      marginBottom: context?.theme.metrics.spacing.s,
    }, style?.dateAndValueStyle,
  ],
  headerContainer: [
    {
    }, style?.headerContainer,
  ],
  subtitleStyle: [
    {
      marginTop: context?.theme.metrics.spacing.xm,
      textAlign: 'center',
    }, style?.subtitleStyle,
  ],
  inputContainer: [
    {
      marginHorizontal: context?.theme.metrics.spacing.xxxxl,
    }, style?.inputContainer,
  ],
  notificationContainer: [
    {
      marginVertical: context?.theme.metrics.spacing.xm,
    }, style?.notificationContainer,
  ],
  wrapperContainer: [
    {
      marginBottom: context?.theme.metrics.spacing.xs,
    }, style?.wrapperContainer,
  ],
});
