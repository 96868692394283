import React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

import { SimpleButton, IconEnum } from '@components/index';

import { useStyles } from './useStyles';

type SizeTypes = 'small' | 'large';
interface Props {
  size?: SizeTypes;
  isLoading?: boolean;
  onValidate: () => void;
  confirmButtonLabel: string;
  onBack?: () => void;
  containerStyle?: StyleProp<ViewStyle>;
  confirmButtonStyle?: StyleProp<ViewStyle>;
  confirmButtonDisabled?: boolean;
  testId?: string;
}

export function BackAndConfirmButtons({
  size,
  isLoading,
  onBack,
  onValidate,
  confirmButtonLabel,
  containerStyle,
  confirmButtonStyle,
  confirmButtonDisabled,
  testId
}: Props) {
  const shouldDisplayBackButton = !!onBack;
  const styles = useStyles({ shouldDisplayBackButton });
  const handleBackPress = () => {
    onBack?.();
  };
  return (
    <View style={[styles.container, containerStyle]}>
      {shouldDisplayBackButton && (
        <SimpleButton
          design="outlined"
          leftIcon={IconEnum.CHEVRON_LEFT}
          onPress={handleBackPress}
          size={size}
        />)}
      <SimpleButton
        containerStyle={[styles.confirmButton, confirmButtonStyle]}
        design="solid"
        disabled={confirmButtonDisabled}
        loading={isLoading}
        onPress={onValidate}
        size={size}
        title={confirmButtonLabel}
        testId={testId}
      />
    </View>
  );
}
