import { useMemo } from 'react'
import { StyleSheet } from 'react-native'

import { useTheme } from '@ere-uilib/styles'

export function useStyles() {
  const theme = useTheme()
  const styles = useMemo(
    () =>
      StyleSheet.create({
        wrapper: {
          backgroundColor: theme.colors.basics.white
        },
        container: {
          alignSelf: 'center',
          width: '100%',
          maxWidth: theme.metrics.contentSizes.centeredContentMaxWidth,
        },
        switchContainer: {
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingVertical: theme.metrics.spacing.s
        },
        button: {
          alignSelf: 'flex-start'
        },
      }),
    [theme]
  )

  return styles
}
