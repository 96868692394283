import React, { useCallback, useMemo } from 'react';
import { View } from 'react-native';

import { Text, Title } from "@components/index"
import { TableCellInterface, TableCellTypeEnum } from "@ere-uilib/molecules";
import { PerformanceBarChartData } from "@ere-uilib/molecules/charts/PerformanceBarChart/sharedInterfaces";
import { SharePerfItem } from "@ere-uilib/types";

import { PerformanceTabsType } from './components/PerformanceTemplate';
import { UseControllerProps } from './interfaces';
import {
  PerformanceAnnualizedTypeEnum,
  PerformanceCalendarTypesEnum,
  PerformanceCumulatedTypesEnum,
  PerformanceTypeEnum
} from "./sharedInterfaces";

export const useController = ({
  performanceData,
  theme,
  styles,
  formatMessage,
  formatDate,
}: UseControllerProps) => {

  const getYearFromPerformanceDate = useCallback((
    date: Date
  ): string | undefined => {
    const year = formatDate({
      value: date,
      options: {
        year: 'numeric'
      }
    })
    if (year) {
      return year
    }
  }, [formatDate])

  const findArrayElementByName = useCallback((name: string) => {
    return performanceData?.sharePerf?.find((element: any) => {
      return element.name === name;
    });
  }, [performanceData]);
  
  const renderValues = useCallback((name: string) => {
    const value = findArrayElementByName(name)?.value?.toFixed(2);
    return value;
  }, [
    findArrayElementByName
  ]);

  const renderedText = useCallback((perfName: string) => {
    const perfValue = Number(renderValues(perfName));
    if (isNaN(Number(perfValue))) {
      return (
        <Text
          variant="t3"
          weight="light"
        >
          {formatMessage({ id: 'Funsheet_DataUnavailable' })}
        </Text>
      );
    }
    const textColor =
    perfValue < 0
      ? theme.colors.notifications.warning.c500
      : theme.colors.basics.primary.c500;
    let rendredText =
    perfValue > 0 ? `+ ${perfValue}` : `- ${Math.abs(perfValue)}`;
    if (perfValue === 0) {
      rendredText = `${perfValue}`;
    }
    return (
      <Text
        style={{ color: textColor }}
        variant="t3"
      >
        {`${rendredText} % `}
      </Text>
    );
  }, [
    formatMessage,
    renderValues,
    theme
  ]);

  const renderLabelWithDate = useCallback(({
    label,
    date
  }:{
    label: string
    date?: string,
  }) => {
    const fullDate = formatDate({
      value: date
    })
    return (
      <Title
        variant="t7" 
        weight='light'
      >
        {`${label} `}
        <Title
          style={styles.fullDateText} 
          variant='t8'
          weight='light'
        >
          {`(${fullDate})`}
        </Title>
      </Title>
    )
  }, [
    styles,
    formatDate
  ])
  const compare = (a: SharePerfItem, b: SharePerfItem) => {
    if (parseInt(a.name.substring(3, 5)) < parseInt(b.name.substring(3, 5))) {
      return -1;
    }
    if (parseInt(a.name.substring(3, 5)) > parseInt(b.name.substring(3, 5))) {
      return 1;
    }
    return 0;
  }
  const getPerformanceBarChartData = (items: SharePerfItem[]) => {
    const filtredItems = (items.filter((el) => el.name in PerformanceCalendarTypesEnum));
    filtredItems.sort( compare );
    const year = new Date().getFullYear()
    const data = filtredItems?.reduce<{
      barChartData: PerformanceBarChartData[];
      latestDate?: string;
      earliestDate?: string;
    }>(
      (acc, item, index) => {
        if (!year) return acc;
        if (!acc.latestDate || new Date(acc.latestDate).getTime() < new Date(item.date).getTime())
          acc.latestDate = item.date;
        if (!acc.earliestDate || new Date(acc.latestDate).getTime() > new Date(item.date).getTime())
          acc.earliestDate = item.date;
        return { ...acc, barChartData: [...acc.barChartData, { x: `${year - index - 1}`, y: item.value }] };
      },
      { barChartData: [] }
    );
    return data;
  };


  const getSharePerfDataAnnualized = useCallback((performanceType?: PerformanceTypeEnum.ANNUALIZED) => {
    if (performanceData && performanceData?.sharePerf) {
      const formattedSharePerfData: TableCellInterface[][] = []
      const perfAnnualizedOrderAndBase = [
        {
          name: PerformanceAnnualizedTypeEnum.ANSI,
          label: formatMessage({ id: 'fund-card_evolution-period-since-beguinning_label' })
        }, {
          name: PerformanceAnnualizedTypeEnum.AN1Y,
          label: formatMessage({ id: 'fund-card_evolution-period1_label' })
        }, {
          name: PerformanceAnnualizedTypeEnum.AN3Y,
          label: formatMessage({ id: 'fund-card_evolution-period3_label' })
        }, {
          name: PerformanceAnnualizedTypeEnum.AN5Y,
          label: formatMessage({ id: 'fund-card_evolution-period4_label' })
        }, {
          name: PerformanceAnnualizedTypeEnum.AN10Y,
          label: formatMessage({ id: 'fund-card_evolution-period10_label' })
        },
      ]
      perfAnnualizedOrderAndBase?.forEach(perfBase => {
        const itemFromData = performanceData?.sharePerf?.find(dataItem=>dataItem.name===perfBase.name)
        if(itemFromData) {
          const tableCells: TableCellInterface[] = []
          tableCells.push({
            type: TableCellTypeEnum.ENTRIES,
            children: renderLabelWithDate({ label: perfBase.label+'*', date: itemFromData.date }),
            containerStyle: styles.mobileLeftTableCell
          })
          tableCells.push({
            type: TableCellTypeEnum.ENTRIES,
            children: renderedText(itemFromData.name),
            containerStyle: styles.mobileRightTableCell
          })
          formattedSharePerfData.push(tableCells)
        }
      })
      if (formattedSharePerfData.length > 0) {
        return formattedSharePerfData
      }
    }
    return undefined
  }, [
    performanceData,
    styles,
    renderLabelWithDate,
    renderedText,
    formatMessage
  ])
  const getSharePerfDataCalendar = useCallback(() => {
    if (performanceData && performanceData?.sharePerf) {
      const formattedSharePerfData: TableCellInterface[][] = []
      const currentDateFullYear = new Date().getFullYear()
      const perfCalendarOrderAndBaseData = [
        {
          name: PerformanceCalendarTypesEnum.CAL1Y,
          yearLabel: currentDateFullYear - 1
        }, {
          name: PerformanceCalendarTypesEnum.CAL2Y,
          yearLabel: currentDateFullYear - 2
        }, {
          name: PerformanceCalendarTypesEnum.CAL3Y,
          yearLabel: currentDateFullYear - 3
        }, {
          name: PerformanceCalendarTypesEnum.CAL5Y,
          yearLabel: currentDateFullYear - 5
        }, {
          name: PerformanceCalendarTypesEnum.CAL10Y,
          yearLabel: currentDateFullYear - 10
        },
      ]

      perfCalendarOrderAndBaseData?.forEach(perfBase => {
        const itemFromData = performanceData?.sharePerf?.find(dataItem=>dataItem.name===perfBase.name)
        if(itemFromData) {
          const tableCells: TableCellInterface[] = []
          tableCells.push({
            type: TableCellTypeEnum.ENTRIES,
            children: renderLabelWithDate({ label: perfBase.yearLabel?.toString()+'*', date: itemFromData.date }),
            containerStyle: styles.mobileLeftTableCell
          })
          tableCells.push({
            type: TableCellTypeEnum.ENTRIES,
            children: renderedText(itemFromData.name),
            containerStyle: styles.mobileRightTableCell
          })
          formattedSharePerfData.push(tableCells)
        }
      })
      if (formattedSharePerfData.length > 0) {
        return formattedSharePerfData
      }
    }
    return undefined
  }, [
    performanceData,
    styles,
    renderLabelWithDate,
    renderedText
  ])

  const getSharePerfDataCumulated = useCallback(() => {
    if (performanceData && performanceData?.sharePerf) {
      const formattedSharePerfData:TableCellInterface[][] = []
      const perfCumulatedOrderAndBaseData = [
        {
          name: PerformanceCumulatedTypesEnum.YTD,
          label: formatMessage({ id: 'Fundsheet_Tab3Perf_Perf_YTD_title' })
        }, {
          name: PerformanceCumulatedTypesEnum['1Y'],
          label: formatMessage({ id: 'fund-card_evolution-period2_label' })
        }, {
          name: PerformanceCumulatedTypesEnum['3Y'],
          label: formatMessage({ id: 'fund-card_evolution-period3_label' })
        }, {
          name: PerformanceCumulatedTypesEnum['5Y'],
          label: formatMessage({ id: 'fund-card_evolution-period4_label' })
        },
      ]
      
      perfCumulatedOrderAndBaseData?.forEach(perfBase => {
        const itemFromData = performanceData?.sharePerf?.find(dataItem=>dataItem.name===perfBase.name)
        
        if(itemFromData) {
          const tableCells: TableCellInterface[] = []

          const isYTDName = perfBase.name === PerformanceCumulatedTypesEnum.YTD
          if(isYTDName) {
            const isYTDvalue = isYTDName && !!findArrayElementByName(PerformanceCumulatedTypesEnum.YTD)?.value

            tableCells.push({
              type: TableCellTypeEnum.ENTRIES,
              text: perfBase.label,
              containerStyle: styles.mobileLeftTableCell
            })
            tableCells.push({
              type: TableCellTypeEnum.ENTRIES,
              children: (
                <View>
                  <Text
                    variant="t3"
                    weight="light"
                  >
                    {isYTDvalue && renderedText('YTD')}
                    {formatMessage({
                      id: isYTDvalue?
                        'Fundsheet_Tab3Perf_Perf_YTD_date_legend'
                        :'Funsheet_DataUnavailable',
                    })}
                  </Text>
                </View>
              ),
              containerStyle: styles.mobileRightTableCell
            })
          }else{
            tableCells.push({
              type: TableCellTypeEnum.ENTRIES,
              children: renderLabelWithDate({ label: perfBase.label, date: itemFromData.date }),
              containerStyle: styles.mobileLeftTableCell
            })
            tableCells.push({
              type: TableCellTypeEnum.ENTRIES,
              children: renderedText(perfBase.name),
              containerStyle: styles.mobileRightTableCell
            })
          }

          formattedSharePerfData.push(tableCells)
        }
      });
      if (formattedSharePerfData.length > 0) {
        return formattedSharePerfData
      }
    }
    return undefined
  }, [
    styles,
    renderedText,
    renderLabelWithDate,
    formatMessage,
    performanceData,
    findArrayElementByName
  ])

  const YTDDate: string | undefined = performanceData?.sharePerf
    ?.filter(e => e.name === PerformanceCumulatedTypesEnum.YTD)
    .map(e => e.date)[0];
  const isCalendarPerformanceAvailable = !!performanceData?.sharePerf?.find((
    perf: SharePerfItem
  ) => perf?.name in PerformanceCalendarTypesEnum)
  const isAnnualizedPerformanceAvailable = !!performanceData?.sharePerf?.find((
    perf: SharePerfItem
  ) => perf?.name in PerformanceAnnualizedTypeEnum)

  const performanceTabs:PerformanceTabsType[] = useMemo(() => ([
    {
      label: formatMessage({ id: 'Fundsheet_Tab3Perf_Cumulated_Performance_selector' }), 
      value: PerformanceTypeEnum.CUMULATED,
      isMorningStarFund: !!performanceData?.isMorningStarFund,
      tableData: getSharePerfDataCumulated()|| []
    },
    ...(isCalendarPerformanceAvailable ? [{
      label: formatMessage({ id: 'Fundsheet_Tab3Perf_Calendar_Performance_selector' }), 
      value: PerformanceTypeEnum.CALENDAR,
      isMorningStarFund: !!performanceData?.isMorningStarFund,
      legend: formatMessage({ id: "Fundsheet_Tab3Perf_Calendar_performances_legend" }),
      tableData: getSharePerfDataCalendar() || []
    }]:[]),
    ...(isAnnualizedPerformanceAvailable ? [{
      label: formatMessage({ id: 'Fundsheet_Tab3Perf_Annualized_Performance_selector' }), 
      value: PerformanceTypeEnum.ANNUALIZED,
      isMorningStarFund: !!performanceData?.isMorningStarFund,
      legend: formatMessage({ id: "Fundsheet_Tab3Perf_Annualized_performances_legend" }),
      tableData: getSharePerfDataAnnualized() || []
    }]:[])
  ]), [
    formatMessage,
    isCalendarPerformanceAvailable,
    isAnnualizedPerformanceAvailable,
    getSharePerfDataCumulated,
    getSharePerfDataCalendar,
    getSharePerfDataAnnualized,
    performanceData?.isMorningStarFund
  ])

  const periodList = [
    {
      value: 1,
      label: formatMessage({
        id: 'Fundsheet_Tab3Perf_Performance_graph_period_1year',
      }),
    },
    {
      value: 3,
      label: formatMessage({
        id: 'Fundsheet_Tab3Perf_Performance_graph_period_3year',
      }),
    },
    {
      value: 5,
      label: formatMessage({
        id: 'Fundsheet_Tab3Perf_Performance_graph_period_5year',
      }),
    },
    {
      value: 0,
      label: formatMessage({
        id: 'Fundsheet_Tab3Perf_Performance_graph_period_custom',
      }),
    },
  ];

  const performanceTableHeader: TableCellInterface[] = [
    {
      type: TableCellTypeEnum.ENTRIES,
      text: formatMessage({
        id: 'Fundsheet_Tab3Perf_Last_Valuation_Date_title',
        values: {
          variable: ''
        },
      }),
      containerStyle: styles.headerCell,
      textStyle: styles.headerCellText
    },
    {
      type: TableCellTypeEnum.ENTRIES,
      text: formatDate({
        value: YTDDate,
        options: { weekday: undefined },
      }),
      containerStyle: styles.headerCellRight,
      textStyle: styles.headerCellText
    },
  ]

  return {
    performanceTabs,
    periodList,
    isCalendarPerformanceAvailable,
    isAnnualizedPerformanceAvailable,
    performanceTableHeader,
    getPerformanceBarChartData
  }
}