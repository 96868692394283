import { StylesContext, StylesProps } from './interfaces';

export const getStyles = (
  context?: StylesContext,
  style?: StylesProps,
): StylesProps => ({
  container: [
    {
      backgroundColor: context?.theme.colors.basics.white,
      minHeight: 156
    },
    style?.container
  ]
});
