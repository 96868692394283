import React, { useCallback, useEffect, useMemo } from 'react';
import { View } from 'react-native';

import {
  Modal,
  Text,
  Title,
  Spinner,
  DashedLine,
  Link,
} from '@ere-uilib/atoms';
import {
  ModalFrame,
  SimpleButton,
  FieldNotification,
  NotificationSoft,
  TextField,
} from '@ere-uilib/molecules';
import { useTheme, createUseStyles, useScreenSizes } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';
import { useAmountFieldParseManager } from '@ere-uilib/utils';

import { AmountFieldModalProps } from './interface';
import { getStyles } from './styles';

export type AmountFieldModalPropsType = AmountFieldModalProps
export const AmountFieldModal = ({

  title,
  subTitle,
  initialAmount,
  contentTitle,
  contentSubTitle,
  detailsParameterList,
  fieldError,
  isContributionActive,
  allAmountLabel,
  allAmountValue,

  visible = false,
  isAmountPercentage = false,
  placeholder,
  onAmountChange,
  onCancel,
  onValid,
  onCalculContribution,
  onResetCalculatedContribution,
  calculatedContribution,
  isCalculContributionLoading,
  isValidButtonLoading,
  isValidButtonDisable,
  formError,
  testId
}: AmountFieldModalProps) => {
  const { formatMessage, formatCurrencyNumber } = useTranslation();
  const theme = useTheme();
  const { isMobile } = useScreenSizes();
  const {
    amountNumber,
    amountString,
    initialAmountString,
    setAmountString,
    parseAmountNumberToString,
  } = useAmountFieldParseManager({
    initialAmount,
    maxDecimal: 2,
  });
  const styles = useStyles(
    { theme, isMobile },
    {}
  );
  // effects
  useEffect(() => {
    if (!visible) {
      return;
    }
    setAmountString(initialAmountString);
  }, [visible]);

  useEffect(() => {
    onAmountChange?.(amountNumber);
  }, [onAmountChange, amountNumber]);

  // actions
  const handleAmountChange = useCallback(
    (newAmount: string) => {
      const isInteger = new RegExp(/^[0-9]+$/).test(newAmount);

      if (!!newAmount && isAmountPercentage && !isInteger) return;

      if (isContributionActive) onResetCalculatedContribution?.();

      setAmountString(newAmount);
      onAmountChange?.(amountNumber);
    },
    [
      amountNumber,
      isContributionActive,
      onAmountChange,
      onResetCalculatedContribution,
      setAmountString,
      isAmountPercentage
    ]
  );

  const onContributionCalculPressed = () => {
    onCalculContribution?.(amountNumber);
  };
  const handleValidPressed = () => {
    onValid(amountNumber);
  };
  const onCancelPressed = () => {
    onCancel();
  };
  const onAllAmountPress = () => {
    typeof allAmountValue === 'number' && handleAmountChange(parseAmountNumberToString(allAmountValue));
  };

  const placeholderValue = useMemo(() => {
    if (placeholder) {
      return placeholder;
    }

    const value = isAmountPercentage ?
      formatMessage({ id: 'Arbitrage_Modale_saisirPourcentage' })
      : formatMessage({ id: 'repartition_edit_placeholder_label' });

    return value;
  }, [formatMessage, isAmountPercentage, placeholder]);

  // RENDERS
  const renderHeader = () => {
    return (
      <View style={styles.titleContainerStyle}>
        <Title
          style={styles.titleStyle}
          variant="t5s"
          weight="bold"
        >
          {title}
        </Title>
        {!!subTitle && (
          <Title
            style={styles.subTitleStyle}
            variant="t7"
          >
            {subTitle}
          </Title>
        )}
        <DashedLine
          axis={'horizontal'}
          dashColor={theme.colors.basics.grey.c200}
          dashGap={6}
          dashLength={6}
          dashStyle={{ borderRadius: 5 }}
          dashThickness={1}
          style={styles.dashedLineStyle}
        />
      </View>
    );
  };

  const renderContent = () => {
    return (
      <View style={styles.contentContainerStyle}>
        {!!contentTitle && (
          <Title
            style={styles.detailsTitleStyle}
            variant="t7"
            weight="bold"
          >
            {contentTitle}
          </Title>
        )}

        {!!contentSubTitle && (
          <Title
            style={styles.subTitleStyle}
            variant="t7"
          >
            {contentSubTitle}
          </Title>
        )}
        {detailsParameterList && detailsParameterList.length > 0 && (
          <View style={styles.detailsParametersContainerStyle}>
            <Text
              style={styles.detailsParameterTextStyle}
              variant="t4" >
              {detailsParameterList.join(' | ')}
            </Text>
          </View>
        )}
        {!!allAmountLabel && typeof allAmountValue === 'number' && (
          <View style={styles.allAmountLinkContainerStyle}>
            <Text
              variant="t3">
              <Link
                onPress={onAllAmountPress}
              >
                {allAmountLabel}
              </Link>
            </Text>
          </View>
        )}
        <View style={styles.inputContainerStyle}>
          <TextField
            errored={!!fieldError}
            inputProps={{
              value: amountString || '',
              placeholder: placeholderValue,
              keyboardType: 'numeric',
              onChangeText: handleAmountChange,
            }}
            labelOnTop
            onCleanField={() => {
              handleAmountChange('');
            }}
            onSubmit={handleValidPressed}
            testId={`${testId}_value`}
          />
        </View>
        <View style={styles.underFieldSpaceStyle}>
          {(fieldError === undefined || fieldError === '') && isContributionActive && (
            <View style={styles.contibutionContainerStyle}>
              <Text
                style={styles.contibutionTextStyle}
                variant="t3">
                {isCalculContributionLoading ?
                  <Spinner color={theme.colors.basics.primary.c500} />
                  : typeof calculatedContribution === 'number' ? (
                    formatMessage({ id: 'repartition_edit_contribution_label' })
                    + formatCurrencyNumber({ value: calculatedContribution })
                  )
                    :
                    <Link onPress={onContributionCalculPressed}>
                      {formatMessage({ id: 'repartition_edit_simulate_contribution_button' })}
                    </Link>
                }
              </Text>
            </View>
          )}
          {!!fieldError && (
            <FieldNotification
              containerStyle={{ alignSelf: 'center' }}
              text={fieldError}
              type="warning" />
          )}
          {!!formError && (
            <NotificationSoft
              containerStyle={{ alignSelf: 'center' }}
              text={formError}
              type="warning" />
          )}
        </View>
      </View>
    );
  };
  const renderActions = () => {
    return (
      <View style={styles.actionsContainerStyle}>
        <SimpleButton
          containerStyle={styles.actionsButtonStyle}
          design="outlined"
          onPress={() => onCancelPressed()}
          title={formatMessage({ id: 'repartition_edit_cancel_button' })}
        />
        <SimpleButton
          containerStyle={[styles.actionsButtonStyle, styles.actionsButtonLastStyle]}
          disabled={isValidButtonDisable}
          loading={isValidButtonLoading}
          onPress={() => handleValidPressed()}
          title={formatMessage({ id: 'repartition_edit_validate_button' })}
          testId={`${testId}_confirm`}
        />
      </View>
    );
  };

  return (
    <Modal visible={visible}>
      <ModalFrame
        innerContainer={styles.modalFrameStyle}
        mobilePosition="bottom"
        moveUpContentWithKeyboard
      >

        <View style={styles.containerStyle}>
          {renderHeader()}
          {renderContent()}
          {renderActions()}
        </View>

      </ModalFrame>
    </Modal>
  );
};

const useStyles = createUseStyles(getStyles);
