import React from 'react';
import { useState } from 'react';
import { StyleProp, ViewStyle } from 'react-native'
;

import {
  DefinePassword,
  useScreenSizes,
  useTheme,
  ThemeType,
} from '@components/index';
import { createUseStyles } from '@ere-uilib/styles';

import { ForgetPasswordErrorState } from '@modules/forget-password/types';
import { ApplicationSettingsState } from '@modules/settings/types';
import { useLogoNavigation } from '@utils/index';

interface Props {
  forgetPasswordError: ForgetPasswordErrorState
  isApiLoading: boolean
  onForgetPasswordRegisterConfirmation: (password: string) => void
  applicationSettings: ApplicationSettingsState
}
interface Context {
  theme: ThemeType
  isMobile: boolean
  isTablet: boolean
}

interface Styles {
  definePasswordContentStyle?: StyleProp<ViewStyle>
}

export const ForgetPasswordChoosePassword: React.FC<React.PropsWithChildren<Props>> = ({
  forgetPasswordError,
  isApiLoading,
  onForgetPasswordRegisterConfirmation,
  applicationSettings
}: Props) => {
  const [password, setPassword] = useState('');
  const theme = useTheme();
  const { isMobile, isTablet } = useScreenSizes();
  const styles = useStyles({
    theme,
    isTablet,
    isMobile
  }, {});
  const { logoNavigateTo } = useLogoNavigation();

  return (
    <DefinePassword
      appSettings={applicationSettings}
      contentStyle={styles.definePasswordContentStyle}
      displayLogo={true}
      error={forgetPasswordError.message}
      isApiLoading={isApiLoading}
      isLeftMenu={false}
      onLogoPress={logoNavigateTo}
      onPress={() => onForgetPasswordRegisterConfirmation(password)}
      setPassword={setPassword}
      title="choose-pwd_menu"/>
  );
};

const getStyles = (
  context?: Context,
  style?: Styles
): Styles => ({
  definePasswordContentStyle: [
    context?.isMobile  && {
      marginTop: context?.theme.metrics.spacing.huge
    },
    context?.isTablet && {
      marginTop: context?.theme.metrics.spacing.xxhuge,
      marginHorizontal: context?.theme.metrics.spacing.xhuge
    },
    style?.definePasswordContentStyle
  ]
});

const useStyles = createUseStyles(getStyles);
