import { useCallback } from 'react';

import { useTranslation } from '@components/ERE-UILib';

export const useFormatPercentNumber = () => {
  const { formatPercentNumber } = useTranslation();

  return useCallback(
    (value: number) =>
      formatPercentNumber({
        value,
        options: {
          maximumFractionDigits: 0,
          minimumSignificantDigits: 2
        }
      }),
    [formatPercentNumber]
  );
};
