import React, { useCallback } from "react"
import { View } from "react-native"

import { LoaderPlaceholderView } from "@ere-uilib/atoms";
import { RetryActionPlaceHolder } from "@ere-uilib/organisms";

import { ControlCardLoaderProps } from "./interface";
import { useStyles } from "./styles";
export const ControlDataCardLoader: React.FC<React.PropsWithChildren<ControlCardLoaderProps>> = ({ isLoading, isError, retryAction }) => {
  const styles = useStyles();

  const renderLoader = useCallback(() => {
    if (!isLoading) return;
    return <View >
      <LoaderPlaceholderView style={[styles.loader, styles.loaderFirstChilld]} />
      <LoaderPlaceholderView style={styles.loader} />
      <LoaderPlaceholderView style={styles.loader} />
    </View>
  }, [isLoading, styles]);

  const renderRetryAction = useCallback(() => {
    if (!isError || isLoading) return;

    return (
      <RetryActionPlaceHolder
        retryAction={retryAction}
      />
    );
  }, [isError, isLoading, retryAction]);

  return (
    <View style={[styles.loaderContainer, isLoading && styles.loadingLoaderContainer]}>
      {renderLoader()}
      {renderRetryAction()}
    </View>
  )
}