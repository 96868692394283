import React from 'react';
import { View } from 'react-native';

import {
  Title,
  Text,
  Modal,
  ModalFrame,
  GreenNumber
} from '@components/index';
import { SquareIcon } from '@ere-uilib/atoms';
import { IconEnum } from '@ere-uilib/enums';
import { useTheme, createUseStyles } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';

import { OTPLimitErrorModalProps } from './interface';
import { getStyles } from './styles';

export const OTPLimitErrorModal: React.FC<React.PropsWithChildren<OTPLimitErrorModalProps>> = ({
  onClose,
  modalVisible
}) => {
  const theme = useTheme();
  const { formatMessage } = useTranslation();
  const styles = useStyles({ theme }, {});

  return (
    <Modal
      visible={modalVisible}>
      <ModalFrame
        desktopPosition="center"
        mobilePosition="bottom"
        onClose={() => {
          onClose();
        }}

      >
        <View
          style={styles.containerStyle}
        >
          <SquareIcon
            backgroundColor="rgba(225, 103, 124, 0.15)"
            iconColor={theme.colors.notifications.warning.c500}
            iconName={IconEnum.WARNING}
            iconSize={theme.metrics.iconSizes.xl}
            style={{ container: styles.iconContainerStyle }}
          />
          <Title
            style={styles.titleStyle}
            variant="t7"
            weight="bold">
            {formatMessage({ id: 'Profil_edit_error_locked_title' })}
          </Title>
          <Text
            style={styles.descriptionStyle}
            variant="t3"
            weight="light">
            {formatMessage({ id: 'Profil_edit_error_locked_label' })}
          </Text>
          <Text
            style={styles.hoursStyle}
            variant="t3"
            weight="light">
            {formatMessage({ id: 'Profil_edit_error_locked_schedule_label' })}
          </Text>
          <GreenNumber style={styles.buttonContainerStyle} />
        </View>
      </ModalFrame>
    </Modal>
  );
};

const useStyles = createUseStyles(getStyles);
