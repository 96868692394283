import React from 'react';
import { View } from 'react-native';

import { Title } from '@ere-uilib/atoms';
import { EligibilityTag } from '@ere-uilib/molecules';
import { createUseStyles, useTheme } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';

import { Props } from './interfaces';
import { getStyles } from './styles';

export const EligibilityList: React.FC<React.PropsWithChildren<Props>> =  ({
  list,
  showEligiblity,
}) => {
  const theme = useTheme();
  const { formatMessage } = useTranslation();
  const title = formatMessage({ id: 'VV_needs_eligibility_title' });
  const styles = useStyles({
    theme,
  }, {});

  return list?.length > 0 && showEligiblity ?
    (
      <View style={styles.container}>
        <Title variant="t7">{title}</Title>
        <View style={styles.tagsContainer}>
          { list?.map(dispositif => {
            return (
              <EligibilityTag
                isAvailable={!!dispositif.isEligible}
                title={dispositif.name}
              />
            );})
          }
        </View>
      </View>
    )
    : <></>;
};

const useStyles = createUseStyles(getStyles);
