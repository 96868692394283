
import * as actions from './historyActionsTypes';
import { HistoryActionsType } from './historyActionsTypes';
import {
  HistoryErrorState,
  NewsFeedTypeState
} from '../types';

export function getNewsFeedRequest(
): actions.GetNewsFeedRequestAction {
  return {
    type: HistoryActionsType.GET_NEWS_FEED_REQUEST,
  };
}

export function getNewsFeedSuccess(
  newsFeed: NewsFeedTypeState[],
): actions.GetNewsFeedSuccessAction {
  return {
    type: HistoryActionsType.GET_NEWS_FEED_SUCCESS,
    newsFeed,
  };
}

export function getNewsFeedFailure(
  error: HistoryErrorState
): actions.GetNewsFeedFailureAction {
  return {
    type: HistoryActionsType.GET_NEWS_FEED_FAILURE,
    error,
  };
}

export function initNewsFeedList(
): actions.InitNewsFeedListAction {
  return {
    type: HistoryActionsType.INIT_NEWS_FEED_LIST,
  };
}

