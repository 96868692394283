import { useScreenSizes, useTheme } from '@ere-uilib/styles';
import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

export const useStyles = () => {
  const theme = useTheme();
  const { isMobile } = useScreenSizes();
  return useMemo(() => StyleSheet.create({
    buttonContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: theme.metrics.spacing.l,
    },
    pageContainer: {
      marginTop:isMobile ? theme.metrics.spacing.l : theme.metrics.spacing.m
    },
    button: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: 60,
      borderRadius: 5,
    },
    leftButton: {
      marginRight: isMobile ? theme.metrics.spacing.m : theme.metrics.spacing.xm,
    },
    activeButton: {
      borderWidth: 2,
      borderColor: theme.colors.buttons.classic.c500,
      backgroundColor: theme.colors.buttons.classic.c300,
    },
    inactiveButton: {
      borderWidth: 1,
      borderColor: "black",
    }
  }), [theme, isMobile]);
}