
import { ThemeType } from '@ere-uilib/styles';

import { Context, Style } from './interfaces';

export const getStyles = (
  theme?: ThemeType,
  context?: Context,
  style?: Style
): Style => ({
  cardResponseContainer: [
    {
      marginTop: context?.isMobile
        ? context?.theme.metrics.spacing.xm
        : context?.theme.metrics.spacing.xl
    },
    style?.cardResponseContainer
  ],
  stepperNoMargin: [
    {
      marginTop: 0
    },
    style?.stepperNoMargin
  ],
  leftContainerStyle: [
    style?.leftContainerStyle
  ],
  recapContainer: [
    {
    },
    context?.isMobile && {
      marginTop: context?.theme.metrics.spacing.xl
    }
  ],
  recapBodyContainer: [
    {
      paddingHorizontal: context?.theme.metrics.spacing.s,
      paddingVertical: context?.theme.metrics.spacing.xm
    }
  ],
  recapBodyTitle: [
    {
      marginBottom: context?.theme.metrics.spacing.xm
    }
  ],
  buttonContainer: [
    {
      marginVertical: context?.theme.metrics.spacing.xl
    }
  ],
  radioButtonGroup: [{
    marginLeft: theme?.metrics.spacing.s
  }],
  unavailableHeaderTitleStyle: [{
    marginBottom: theme?.metrics.spacing.s
  },]
});

