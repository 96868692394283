import { EditPasswordCheckContext, EditPasswordCheckStyle } from "./interfaces";

export const getStyles = (
  context?: EditPasswordCheckContext,
  style?: EditPasswordCheckStyle
): EditPasswordCheckStyle => ({
  containerStyle: [
    {
      backgroundColor: context?.theme.colors.basics.grey.c100,
    },
    style?.containerStyle,
  ],
  contentContainerStyle: [
    {
      alignItems: "center",
      paddingBottom: context?.theme?.metrics.spacing.big,
    },
    style?.contentContainerStyle,
  ],
  passwordContainerStyle: [
    {
      flex: 1,
      marginTop: context?.theme.metrics.spacing.xm,
      width:
        context?.isMobile || context?.isTablet
          ? "100%"
          : context?.theme.metrics.contentSizes.centeredContentMaxWidth,
      alignItems: "center",
      backgroundColor: context?.theme?.colors.basics.white,
      justifyContent: "center",
      minHeight: context?.theme?.metrics.spacing.xxhuge,
      flexDirection: "row",
      alignSelf:
        context?.isMobile || context?.isTablet ? "flex-start" : "center",
      borderRadius: context?.theme?.metrics.spacing.xs,
    },
    context?.isMobile
      ? {
          justifyContent: "flex-end",
        }
      : {
          justifyContent: "center",
        },
    style?.passwordContainerStyle,
  ],
  passwordContentStyle: [
    {
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "100%",
      paddingTop: context?.theme?.metrics.spacing.l,
      paddingBottom: context?.theme?.metrics.spacing.l,
      paddingLeft: context?.theme?.metrics.spacing.l,
      paddingRight: context?.theme?.metrics.spacing.l,
    },
    style?.passwordContentStyle,
  ],
  passwordFooterContentContainer: [
    {
      paddingHorizontal: context?.theme.metrics.spacing.xm,
    },
  ],

  input: [
    {
      width: "100%",
    },
  ],
  buttonsRowStyle: [
    {
      width: "100%",
      alignSelf: "flex-start",
    },
    style?.buttonsRowStyle,
  ],
  oldPasswordContainerStyle: [
    {
      flexDirection: "row",
      alignSelf: context?.isMobile || context?.isTablet ? "flex-start" : "auto",
      width: "100%",
      maxWidth: "100%",
      marginBottom: context?.theme.metrics.spacing.m,
    },
    style?.oldPasswordContainerStyle,
  ],
  newPasswordContainerStyle: [
    {
      flexDirection: "row",
      alignSelf: context?.isMobile || context?.isTablet ? "flex-start" : "auto",
      width: "100%",
    },
    style?.newPasswordContainerStyle,
  ],
  errorViewStyle: [
    {
      marginBottom: context?.theme.metrics.spacing.xm,
    },
    style?.errorViewStyle,
  ],
  buttonContainer: [
    {
      marginTop: context?.theme.metrics.spacing.xm,
    },
  ],
});
