import axios from 'axios';

import { API_URL } from '@config/index';
import { ForgetPasswordState } from '@modules/forget-password/types';

const AXIOS_REQUEST_CONFIG = {
  headers: {
    'Content-Type': 'application/json'
  }
};

export async function forgetPasswordInitBeginService(
  email: any,
): Promise<ForgetPasswordState> {
  return await axios.post(
    `${API_URL}/api/v1/resetpasswords`,
    JSON.stringify(email),
    AXIOS_REQUEST_CONFIG
  );
}

export async function forgetPasswordConfirmEmailService(
  requestId: string,
  code: string,
): Promise<ForgetPasswordState> {
  return await axios.post(
    `${API_URL}/api/v1/resetpasswords/${requestId}/emailconfirmations/confirm`,
    JSON.stringify(code),
    AXIOS_REQUEST_CONFIG
  );
}

export async function forgetPasswordConfirmSMSService(
  requestId: string,
): Promise<ForgetPasswordState> {
  return await axios.post(
    `${API_URL}/api/v1/resetpasswords/${requestId}/smsconfirmations`,
  );
}

export async function forgetPasswordConfirmSMSSCodeService(
  requestId: string,
  code: string,
): Promise<ForgetPasswordState> {
  return await axios.post(
    `${API_URL}/api/v1/resetpasswords/${requestId}/smsconfirmations/confirm`,
    JSON.stringify(code),
    AXIOS_REQUEST_CONFIG
  );
}

export async function forgetPasswordConfirmService(
  requestId: string,
  newPassword: string,
): Promise<ForgetPasswordState> {
  return await axios.post(
    `${API_URL}/api/v1/resetpasswords/${requestId}`,
    JSON.stringify(newPassword),
    AXIOS_REQUEST_CONFIG
  );
}
