import { RouteProp, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useCallback } from 'react';
import { StyleProp, TextStyle, View, ViewStyle } from 'react-native';

import {
  ColumnsContainer,
  HtmlRenderer,
  OfflinePageLayout,
  Text,
  ThemeType,
  useScreenSizes,
  useTheme,
  useTranslation
} from '@components/index';
import { SimpleButton } from '@ere-uilib/molecules';
import { createUseStyles } from '@ere-uilib/styles';
import { ContactPreferencesOptin } from '@modules/profile/actions/profileActions';
import { AppNavigatorInterface } from '@navigation/Interfaces';
import { useLogoNavigation } from '@utils/useLogoNavigation';

import { BusinessContactPreferencetemplate } from '../templates';
import { useController } from '../useController';
import { locator } from '@constants/locator';

interface Props {
  navigation: StackNavigationProp<AppNavigatorInterface>;
  onSubmit: (values: ContactPreferencesOptin) => void;
  isSuccess?: boolean;
  onPressButtonSuccessModal: () => void;
  acceptGroupEmail: boolean;
  acceptPartnerEmail: boolean;
  getContactPreferencesRequest: () => void;
  isLoading?: boolean;
}

type StylesContext = {
  theme: ThemeType;
  isMobile: boolean;
  isTablet: boolean;
};

type StylesProps = {
  container?: StyleProp<ViewStyle>;
  stickyButtonsViewStyle?: StyleProp<ViewStyle>;
  topButtonText?: StyleProp<ViewStyle>;
  button?: StyleProp<ViewStyle>;
  textContent?: StyleProp<TextStyle>;
  commonBlocStyle?: StyleProp<TextStyle>;
};

export function ContactPreferencesPageComponent({
  navigation,
  onSubmit,
  onPressButtonSuccessModal,
  acceptGroupEmail,
  acceptPartnerEmail,
  getContactPreferencesRequest,
  isLoading,
  container,
  stickyButtonsViewStyle,
  button
}: Props & StylesProps) {
  type ContactPreferencesParam = {
    contact: {
      toDoPostSuccessActionType: string;
      demandId: string;
    };
  };
  const route = useRoute<RouteProp<ContactPreferencesParam, 'contact'>>();
  const { logoNavigateTo } = useLogoNavigation();
  const toDoActionType = route?.params?.toDoPostSuccessActionType;
  const demandId = route?.params?.demandId;
  const {
    isSubmitActive,
    handlePressGroupEmail,
    handlePressPartnerEmail,
    handleSubmit,
    optInPartnerEmail,
    optInGroupEmail
  } = useController({
    onSubmit,
    onPressButtonSuccessModal,
    navigation,
    acceptGroupEmail,
    acceptPartnerEmail,
    getContactPreferencesRequest,
    demandId,
    toDoActionType
  });

  const theme = useTheme();
  const { formatMessage, getMessageRaw } = useTranslation();
  const { isMobile, isTablet } = useScreenSizes();
  const styles = useStyles(
    { theme, isMobile, isTablet },
    { container, stickyButtonsViewStyle, button }
  );

  const renderButton = useCallback(
    () => (
      <View style={styles.button}>
        <Text
          style={styles.topButtonText}
          variant="t4"
          weight="regular">
          {formatMessage({
            id: 'OptinAvantagesChoixModifiableLabel'
          })}
        </Text>

        <SimpleButton
          disabled={!isSubmitActive}
          loading={isLoading}
          onPress={handleSubmit}
          title={formatMessage({ id: 'Profil_Prefcontact_edit_page_Bouton' })}
          testId={locator._profile._your_preferences._save_preferences}
        />
        <View style={styles.textContent}>
          <HtmlRenderer
            html={getMessageRaw({ id: 'ProfilPrefcontactEditOptinContractFooterNotice' })}
          />
        </View>
      </View>
    ), [styles, formatMessage, isSubmitActive, isLoading, handleSubmit, getMessageRaw]);

  return (
    <OfflinePageLayout
      displayLogo={!(isMobile || isTablet)}
      onLogoPress={logoNavigateTo}
    >
      <ColumnsContainer
        hasRenderRightDesktopColumn={false}
      >
        <View style={styles.container}>
          <BusinessContactPreferencetemplate
            acceptGroupEmail={acceptGroupEmail}
            acceptPartnerEmail={acceptPartnerEmail}
            handlePressGroupEmail={handlePressGroupEmail}
            handlePressPartnerEmail={handlePressPartnerEmail}
            optInGroupEmail={optInGroupEmail}
            optInPartnerEmail={optInPartnerEmail}
          />
          {renderButton()}
        </View>
      </ColumnsContainer>
    </OfflinePageLayout>
  );
}
const getStyles = (context?: StylesContext, style?: StylesProps): StylesProps => ({
  container: [
    !(context?.isMobile || context?.isTablet)
      ? {
        paddingTop: 76,
        flex: 1,
        alignSelf: 'center',
        paddingHorizontal: context?.theme.metrics.spacing.xxxhuge
      }
      : { marginTop: context?.theme.metrics.spacing.m
      },
    style?.container
  ],
  topButtonText: [
    {
      marginBottom: context?.theme.metrics.spacing.s,
      marginTop: context?.isMobile?context?.theme.metrics.spacing.l : context?.theme.metrics.spacing.xxxxl
    },
    style?.topButtonText
  ],
  stickyButtonsViewStyle: [
    (context?.isMobile || context?.isTablet) && {
      marginTop: context && -context?.theme.metrics.spacing.l,
      paddingHorizontal: context?.theme.metrics.spacing.s,
      paddingBottom: context?.theme.metrics.spacing.l
    },
    style?.stickyButtonsViewStyle
  ],
  commonBlocStyle: [
    context?.isMobile
      ? {
        marginTop: context?.theme.metrics.spacing.xm,
        padding: 0
      }
      : {
        marginTop: context?.theme.metrics.spacing.xxl,
        padding: 0
      },
    style?.commonBlocStyle
  ],
  button: [
    !(context?.isMobile || context?.isTablet) && {
      alignItems: 'flex-start'
    },
    {
      marginBottom: 70
    },
    style?.button
  ],
  textContent: [
    !(context?.isMobile || context?.isTablet) && {
      alignItems: 'flex-start',
      paddingTop: context?.theme.metrics.spacing.xxxxl
    },
    { marginTop: context?.theme.metrics.spacing.m },
    style?.textContent
  ]
});

const useStyles = createUseStyles(getStyles);
