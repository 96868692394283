import { connect } from 'react-redux';

import {
  SendInstallmentPaymentDataRequest,
  installmentInitError,
} from '@modules/installment/actions/installmentActions';
import { InstallmentActionsType } from '@modules/installment/actions/installmentActionsTypes';
import { AppState } from '@modules/reducers';
import {
  getInstallmentRepartitionData,
  getInstallmentDecisionAllResponses,
  getInstallmentPaymentMode,
  getInstallmentPaymentModeData,
  getInstallmentError,
  getCalculatedContributionError,
  getInstallmentEditedVVPDtata,
} from '@modules/installment/selectors';

import { InstallmentSynthesisPageComponent  } from './InstallmentSynthesisPage.component';

const mapStateToProps = (state: AppState) => {
  const isEditMode = !!getInstallmentEditedVVPDtata(state);
  return {
    isEditMode,
    installmentRepartitionData: getInstallmentRepartitionData(state),
    installmentDecisionAllResponses: getInstallmentDecisionAllResponses(state),
    installmentPaymentMode: getInstallmentPaymentMode(state),
    installmentPaymentModeData: getInstallmentPaymentModeData(state),
    installmentError: getInstallmentError(state),
    calculatedContributionError: getCalculatedContributionError(state),
    isSendInstallmentPaymentDataLoading: state.isLoading[InstallmentActionsType.SEND_INSTALLMENT_PAYMENT_DATA_REQUEST],
  };
};

const mapDispatchToProps = ({
  onSendInstallmentPaymentData: SendInstallmentPaymentDataRequest,
  installmentInitError: installmentInitError,
});

export const InstallmentSynthesisPage = connect(mapStateToProps, mapDispatchToProps)(InstallmentSynthesisPageComponent);
