import { connect } from 'react-redux';

import { AppState } from '@modules/index';
import { resetCampaignReducer } from '@modules/campaign/actions/campaignActions';
import { getIsCampaignSubmitted } from '@modules/campaign/selectors';
import { logoutCallbackRequest, logoutCallbackSuccess } from '@modules/auth/actions/authActions';

import { LogoutPageComponent } from './LogoutPage.component';

const mapStateToProps = (state: AppState) => {
  return {
    isCampaignActive: !!getIsCampaignSubmitted(state)
  }
}
const mapDispatchToProps = {
  resetCampaignReducer: resetCampaignReducer,
  signoutRedirectCallback: logoutCallbackRequest,
  logoutCallbackSuccess: logoutCallbackSuccess
};

export const LogoutPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LogoutPageComponent);
