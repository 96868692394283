import { useTheme } from '@ere-uilib/styles';
import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

export const useStyles = () => {
  const theme = useTheme();
  return useMemo(() => StyleSheet.create({
    contentContainer: {
      borderBottomWidth: 1,
      borderColor: theme.colors.basics.grey.c200,
      marginBottom: theme.metrics.spacing.s,
    },
    brutIndicator: {
      textAlign: "right"
    },
    fundItemContainer: {
      paddingTop: theme.metrics.spacing.m,
      paddingBottom: theme.metrics.spacing.s,
      borderTopWidth: 1,
      borderTopColor: theme.colors.basics.grey.c200
    },
    nameAndAmountContainer: {
      flex:1,
      flexDirection: "row",
      marginBottom: theme.metrics.spacing.s
    },
    fundName: {
      flex: 1,
    },
    availabilityContainer: {
    },
    availabilityItem: {
      flex: 1,
      flexDirection: "row",
      marginBottom: theme.metrics.spacing.xs
    },
    availabilityDate: {
      flex: 4,
    },
    availabilityNumberOfUnits: {
      flex: 3,
      textAlign: "right",
    },
    availabilityAmount: {
      flex: 3,
      textAlign: "right",
    },
  }), [theme]);
}