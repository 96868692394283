import { FundsPerformanceState } from '@modules/funds/types';

export const mockPerformanceSharePriceHisto01: FundsPerformanceState['sharePriceHisto'] = [
  {
    'date': '2022-05-22T00:00:00',
    'value': 152.449,
    'valueF': 'null',
  },
  {
    'date': '2022-05-29T00:00:00',
    'value': 153.6442,
    'valueF': 'null',
  },
  {
    'date': '2022-06-05T00:00:00',
    'value': 154.8806,
    'valueF': 'null',
  },
  {
    'date': '2022-06-12T00:00:00',
    'value': 157.0179,
    'valueF': 'null',
  },
  {
    'date': '2022-06-19T00:00:00',
    'value': 154.5223,
    'valueF': 'null',
  },
  {
    'date': '2022-06-26T00:00:00',
    'value': 154.937,
    'valueF': 'null',
  },
];

export const mockPerformanceSharePriceHisto02: FundsPerformanceState['sharePriceHisto'] = [
  {
    'date': '2016-01-03T00:00:00',
    'value': 20.4750808,
    'valueF': 'null',
  },
  {
    'date': '2016-01-04T00:00:00',
    'value': 18.9498404,
    'valueF': 'null',
  },
  {
    'date': '2016-01-05T00:00:00',
    'value': 18.63205,
    'valueF': 'null',
  },
  {
    'date': '2016-01-06T00:00:00',
    'value': 19.236089200000002,
    'valueF': 'null',
  },
  {
    'date': '2016-01-07T00:00:00',
    'value': 17.811117600000003,
    'valueF': 'null',
  },
];
export const mockPerformanceSharePriceHisto03: FundsPerformanceState['sharePriceHisto'] = [
  {
    'date': '2016-06-23T00:00:00',
    'value': 67.6403592,
    'valueF': 'null',
  },
  {
    'date': '2016-06-26T00:00:00',
    'value': 64.67364,
    'valueF': 'null',
  },
  {
    'date': '2016-06-27T00:00:00',
    'value': 62.4089132,
    'valueF': 'null',
  },
];

export const mockPerformanceSharePriceHisto04: FundsPerformanceState['sharePriceHisto'] = [
  {
    'date': '2016-01-03T00:00:00',
    'value': 526.029672,
    'valueF': 'null',
  },
  {
    'date': '2016-01-04T00:00:00',
    'value': 517.0552779999999,
    'valueF': 'null',
  },
  {
    'date': '2016-01-05T00:00:00',
    'value': 518.05646,
    'valueF': 'null',
  },
  {
    'date': '2016-01-06T00:00:00',
    'value': 519.110628,
    'valueF': 'null',
  },
  {
    'date': '2016-01-07T00:00:00',
    'value': 508.211,
    'valueF': 'null',
  },
  {
    'date': '2016-01-10T00:00:00',
    'value': 511.042232,
    'valueF': 'null',
  },
  {
    'date': '2016-01-11T00:00:00',
    'value': 510.107266,
    'valueF': 'null',
  },
  {
    'date': '2016-01-12T00:00:00',
    'value': 511.51614,
    'valueF': 'null',
  },
  {
    'date': '2016-01-13T00:00:00',
    'value': 509.3193,
    'valueF': 'null',
  },
  {
    'date': '2016-01-14T00:00:00',
    'value': 509.334272,
    'valueF': 'null',
  },
  {
    'date': '2016-01-17T00:00:00',
    'value': 501.660984,
    'valueF': 'null',
  },
  {
    'date': '2016-01-18T00:00:00',
    'value': 500.242728,
    'valueF': 'null',
  },
  {
    'date': '2016-01-19T00:00:00',
    'value': 500.286,
    'valueF': 'null',
  },
  {
    'date': '2016-01-20T00:00:00',
    'value': 496.883898,
    'valueF': 'null',
  },
  {
    'date': '2016-01-21T00:00:00',
    'value': 503.11995,
    'valueF': 'null',
  },
  {
    'date': '2016-01-24T00:00:00',
    'value': 507.35744,
    'valueF': 'null',
  },
  {
    'date': '2016-01-25T00:00:00',
    'value': 503.312592,
    'valueF': 'null',
  },
  {
    'date': '2016-01-26T00:00:00',
    'value': 509.437176,
    'valueF': 'null',
  },
  {
    'date': '2016-01-27T00:00:00',
    'value': 505.987034,
    'valueF': 'null',
  },
  {
    'date': '2016-01-28T00:00:00',
    'value': 502.094658,
    'valueF': 'null',
  },
  {
    'date': '2016-01-31T00:00:00',
    'value': 509.02422,
    'valueF': 'null',
  },
  {
    'date': '2016-02-01T00:00:00',
    'value': 512.1508200000001,
    'valueF': 'null',
  },
  {
    'date': '2016-02-02T00:00:00',
    'value': 507.88473,
    'valueF': 'null',
  },
  {
    'date': '2016-02-03T00:00:00',
    'value': 506.837918,
    'valueF': 'null',
  },
  {
    'date': '2016-02-04T00:00:00',
    'value': 504.16051200000004,
    'valueF': 'null',
  },
  {
    'date': '2016-02-07T00:00:00',
    'value': 500.46825,
    'valueF': 'null',
  },
  {
    'date': '2016-02-08T00:00:00',
    'value': 492.41208199999994,
    'valueF': 'null',
  },
  {
    'date': '2016-02-09T00:00:00',
    'value': 494.611464,
    'valueF': 'null',
  },
  {
    'date': '2016-02-10T00:00:00',
    'value': 493.41444,
    'valueF': 'null',
  },
  {
    'date': '2016-02-11T00:00:00',
    'value': 488.397968,
    'valueF': 'null',
  },
  {
    'date': '2016-02-14T00:00:00',
    'value': 495.339872,
    'valueF': 'null',
  },
  {
    'date': '2016-02-15T00:00:00',
    'value': 498.907206,
    'valueF': 'null',
  },
  {
    'date': '2016-02-16T00:00:00',
    'value': 495.126396,
    'valueF': 'null',
  },
  {
    'date': '2016-02-17T00:00:00',
    'value': 500.350194,
    'valueF': 'null',
  },
  {
    'date': '2016-02-18T00:00:00',
    'value': 503.245056,
    'valueF': 'null',
  },
  {
    'date': '2016-02-21T00:00:00',
    'value': 497.8085,
    'valueF': 'null',
  },
  {
    'date': '2016-02-22T00:00:00',
    'value': 501.56052999999997,
    'valueF': 'null',
  },
  {
    'date': '2016-02-23T00:00:00',
    'value': 500.724634,
    'valueF': 'null',
  },
  {
    'date': '2016-02-24T00:00:00',
    'value': 498.449152,
    'valueF': 'null',
  },
  {
    'date': '2016-02-25T00:00:00',
    'value': 502.325732,
    'valueF': 'null',
  },
  {
    'date': '2016-02-28T00:00:00',
    'value': 503.925932,
    'valueF': 'null',
  },
  {
    'date': '2016-02-29T00:00:00',
    'value': 500.56550000000004,
    'valueF': 'null',
  },
  {
    'date': '2016-03-01T00:00:00',
    'value': 503.9515,
    'valueF': 'null',
  },
  {
    'date': '2016-03-02T00:00:00',
    'value': 511.37736,
    'valueF': 'null',
  },
  {
    'date': '2016-03-03T00:00:00',
    'value': 509.62989,
    'valueF': 'null',
  },
  {
    'date': '2016-03-06T00:00:00',
    'value': 512.941332,
    'valueF': 'null',
  },
  {
    'date': '2016-03-07T00:00:00',
    'value': 512.462484,
    'valueF': 'null',
  },
  {
    'date': '2016-03-08T00:00:00',
    'value': 507.42706799999996,
    'valueF': 'null',
  },
  {
    'date': '2016-03-09T00:00:00',
    'value': 506.96007199999997,
    'valueF': 'null',
  },
  {
    'date': '2016-03-10T00:00:00',
    'value': 509.06744399999997,
    'valueF': 'null',
  },
  {
    'date': '2016-03-13T00:00:00',
    'value': 507.79787999999996,
    'valueF': 'null',
  },
  {
    'date': '2016-03-14T00:00:00',
    'value': 513.884652,
    'valueF': 'null',
  },
  {
    'date': '2016-03-15T00:00:00',
    'value': 510.47862399999997,
    'valueF': 'null',
  },
  {
    'date': '2016-03-16T00:00:00',
    'value': 514.8731339999999,
    'valueF': 'null',
  },
  {
    'date': '2016-03-17T00:00:00',
    'value': 512.00128,
    'valueF': 'null',
  },
  {
    'date': '2016-03-20T00:00:00',
    'value': 514.706338,
    'valueF': 'null',
  },
  {
    'date': '2016-03-21T00:00:00',
    'value': 514.64366,
    'valueF': 'null',
  },
  {
    'date': '2016-03-22T00:00:00',
    'value': 507.57349999999997,
    'valueF': 'null',
  },
  {
    'date': '2016-03-23T00:00:00',
    'value': 512.293632,
    'valueF': 'null',
  },
  {
    'date': '2016-03-28T00:00:00',
    'value': 511.40460199999995,
    'valueF': 'null',
  },
  {
    'date': '2016-03-29T00:00:00',
    'value': 508.264392,
    'valueF': 'null',
  },
  {
    'date': '2016-03-30T00:00:00',
    'value': 513.8353079999999,
    'valueF': 'null',
  },
  {
    'date': '2016-03-31T00:00:00',
    'value': 511.97056,
    'valueF': 'null',
  },
  {
    'date': '2016-04-03T00:00:00',
    'value': 508.39154599999995,
    'valueF': 'null',
  },
  {
    'date': '2016-04-04T00:00:00',
    'value': 506.1115,
    'valueF': 'null',
  },
  {
    'date': '2016-04-05T00:00:00',
    'value': 503.782382,
    'valueF': 'null',
  },
  {
    'date': '2016-04-06T00:00:00',
    'value': 507.41193,
    'valueF': 'null',
  },
  {
    'date': '2016-04-07T00:00:00',
    'value': 509.239634,
    'valueF': 'null',
  },
  {
    'date': '2016-04-10T00:00:00',
    'value': 508.290668,
    'valueF': 'null',
  },
  {
    'date': '2016-04-11T00:00:00',
    'value': 507.43014600000004,
    'valueF': 'null',
  },
  {
    'date': '2016-04-12T00:00:00',
    'value': 507.169878,
    'valueF': 'null',
  },
  {
    'date': '2016-04-13T00:00:00',
    'value': 514.2663,
    'valueF': 'null',
  },
  {
    'date': '2016-04-14T00:00:00',
    'value': 518.088432,
    'valueF': 'null',
  },
  {
    'date': '2016-04-17T00:00:00',
    'value': 512.480352,
    'valueF': 'null',
  },
  {
    'date': '2016-04-18T00:00:00',
    'value': 513.660524,
    'valueF': 'null',
  },
  {
    'date': '2016-04-19T00:00:00',
    'value': 518.4908800000001,
    'valueF': 'null',
  },
  {
    'date': '2016-04-20T00:00:00',
    'value': 515.40708,
    'valueF': 'null',
  },
  {
    'date': '2016-04-21T00:00:00',
    'value': 518.9219840000001,
    'valueF': 'null',
  },
  {
    'date': '2016-04-24T00:00:00',
    'value': 514.41675,
    'valueF': 'null',
  },
  {
    'date': '2016-04-25T00:00:00',
    'value': 514.698836,
    'valueF': 'null',
  },
  {
    'date': '2016-04-26T00:00:00',
    'value': 516.0513,
    'valueF': 'null',
  },
  {
    'date': '2016-04-27T00:00:00',
    'value': 516.56028,
    'valueF': 'null',
  },
  {
    'date': '2016-04-28T00:00:00',
    'value': 512.147712,
    'valueF': 'null',
  },
  {
    'date': '2016-05-01T00:00:00',
    'value': 507.8345,
    'valueF': 'null',
  },
  {
    'date': '2016-05-02T00:00:00',
    'value': 512.28903,
    'valueF': 'null',
  },
  {
    'date': '2016-05-03T00:00:00',
    'value': 508.485662,
    'valueF': 'null',
  },
  {
    'date': '2016-05-05T00:00:00',
    'value': 509.227776,
    'valueF': 'null',
  },
  {
    'date': '2016-05-08T00:00:00',
    'value': 509.686528,
    'valueF': 'null',
  },
  {
    'date': '2016-05-09T00:00:00',
    'value': 505.6055,
    'valueF': 'null',
  },
  {
    'date': '2016-05-10T00:00:00',
    'value': 511.80249,
    'valueF': 'null',
  },
  {
    'date': '2016-05-11T00:00:00',
    'value': 509.398486,
    'valueF': 'null',
  },
  {
    'date': '2016-05-12T00:00:00',
    'value': 506.694484,
    'valueF': 'null',
  },
  {
    'date': '2016-05-16T00:00:00',
    'value': 506.615168,
    'valueF': 'null',
  },
  {
    'date': '2016-05-17T00:00:00',
    'value': 509.476848,
    'valueF': 'null',
  },
  {
    'date': '2016-05-18T00:00:00',
    'value': 506.1225,
    'valueF': 'null',
  },
  {
    'date': '2016-05-19T00:00:00',
    'value': 508.32572000000005,
    'valueF': 'null',
  },
  {
    'date': '2016-05-22T00:00:00',
    'value': 515.481784,
    'valueF': 'null',
  },
  {
    'date': '2016-05-23T00:00:00',
    'value': 508.730592,
    'valueF': 'null',
  },
  {
    'date': '2016-05-24T00:00:00',
    'value': 516.009468,
    'valueF': 'null',
  },
  {
    'date': '2016-05-25T00:00:00',
    'value': 515.86362,
    'valueF': 'null',
  },
  {
    'date': '2016-05-26T00:00:00',
    'value': 513.502608,
    'valueF': 'null',
  },
  {
    'date': '2016-05-29T00:00:00',
    'value': 512.7155,
    'valueF': 'null',
  },
  {
    'date': '2016-05-30T00:00:00',
    'value': 521.466756,
    'valueF': 'null',
  },
  {
    'date': '2016-05-31T00:00:00',
    'value': 518.998438,
    'valueF': 'null',
  },
  {
    'date': '2016-06-01T00:00:00',
    'value': 520.5296940000001,
    'valueF': 'null',
  },
  {
    'date': '2016-06-02T00:00:00',
    'value': 516.19665,
    'valueF': 'null',
  },
  {
    'date': '2016-06-05T00:00:00',
    'value': 514.968028,
    'valueF': 'null',
  },
  {
    'date': '2016-06-06T00:00:00',
    'value': 518.135946,
    'valueF': 'null',
  },
  {
    'date': '2016-06-07T00:00:00',
    'value': 516.91014,
    'valueF': 'null',
  },
  {
    'date': '2016-06-08T00:00:00',
    'value': 515.741196,
    'valueF': 'null',
  },
  {
    'date': '2016-06-09T00:00:00',
    'value': 516.11008,
    'valueF': 'null',
  },
  {
    'date': '2016-06-12T00:00:00',
    'value': 510.753048,
    'valueF': 'null',
  },
  {
    'date': '2016-06-13T00:00:00',
    'value': 507.51464799999997,
    'valueF': 'null',
  },
  {
    'date': '2016-06-14T00:00:00',
    'value': 504.374016,
    'valueF': 'null',
  },
  {
    'date': '2016-06-15T00:00:00',
    'value': 510.62139,
    'valueF': 'null',
  },
  {
    'date': '2016-06-16T00:00:00',
    'value': 506.02673999999996,
    'valueF': 'null',
  },
  {
    'date': '2016-06-19T00:00:00',
    'value': 505.619172,
    'valueF': 'null',
  },
  {
    'date': '2016-06-20T00:00:00',
    'value': 511.17111,
    'valueF': 'null',
  },
  {
    'date': '2016-06-21T00:00:00',
    'value': 508.062,
    'valueF': 'null',
  },
  {
    'date': '2016-06-22T00:00:00',
    'value': 511.248752,
    'valueF': 'null',
  },
  {
    'date': '2016-06-23T00:00:00',
    'value': 515.06598,
    'valueF': 'null',
  },
  {
    'date': '2016-06-26T00:00:00',
    'value': 499.61,
    'valueF': 'null',
  },
  {
    'date': '2016-06-27T00:00:00',
    'value': 501.544994,
    'valueF': 'null',
  },
  {
    'date': '2016-06-28T00:00:00',
    'value': 504.928368,
    'valueF': 'null',
  },
  {
    'date': '2016-07-18T00:00:00',
    'value': 516.870478,
    'valueF': 'null',
  },
  {
    'date': '2016-07-19T00:00:00',
    'value': 516.32461,
    'valueF': 'null',
  },
  {
    'date': '2016-07-20T00:00:00',
    'value': 519.8366100000001,
    'valueF': 'null',
  },
  {
    'date': '2016-07-21T00:00:00',
    'value': 519.415994,
    'valueF': 'null',
  },
  {
    'date': '2016-07-24T00:00:00',
    'value': 518.198654,
    'valueF': 'null',
  },
  {
    'date': '2016-07-25T00:00:00',
    'value': 513.890544,
    'valueF': 'null',
  },
  {
    'date': '2016-07-26T00:00:00',
    'value': 513.832584,
    'valueF': 'null',
  },
  {
    'date': '2016-07-27T00:00:00',
    'value': 513.676802,
    'valueF': 'null',
  },
  {
    'date': '2016-07-28T00:00:00',
    'value': 512.729528,
    'valueF': 'null',
  },
  {
    'date': '2016-07-31T00:00:00',
    'value': 518.048,
    'valueF': 'null',
  },
  {
    'date': '2016-08-01T00:00:00',
    'value': 516.6123,
    'valueF': 'null',
  },
  {
    'date': '2016-08-02T00:00:00',
    'value': 517.652204,
    'valueF': 'null',
  },
  {
    'date': '2016-08-03T00:00:00',
    'value': 515.95196,
    'valueF': 'null',
  },
  {
    'date': '2016-08-04T00:00:00',
    'value': 513.295824,
    'valueF': 'null',
  },
  {
    'date': '2016-08-07T00:00:00',
    'value': 513.1415,
    'valueF': 'null',
  },
  {
    'date': '2016-08-08T00:00:00',
    'value': 513.271,
    'valueF': 'null',
  },
  {
    'date': '2016-08-09T00:00:00',
    'value': 519.01644,
    'valueF': 'null',
  },
  {
    'date': '2016-08-10T00:00:00',
    'value': 515.140634,
    'valueF': 'null',
  },
  {
    'date': '2016-08-11T00:00:00',
    'value': 521.137408,
    'valueF': 'null',
  },
  {
    'date': '2016-08-15T00:00:00',
    'value': 515.7945,
    'valueF': 'null',
  },
  {
    'date': '2016-08-16T00:00:00',
    'value': 516.14292,
    'valueF': 'null',
  },
  {
    'date': '2016-08-17T00:00:00',
    'value': 513.723,
    'valueF': 'null',
  },
  {
    'date': '2016-08-18T00:00:00',
    'value': 515.9302319999999,
    'valueF': 'null',
  },
  {
    'date': '2016-08-21T00:00:00',
    'value': 519.6836,
    'valueF': 'null',
  },
  {
    'date': '2016-08-22T00:00:00',
    'value': 514.4789979999999,
    'valueF': 'null',
  },
  {
    'date': '2016-08-23T00:00:00',
    'value': 520.465394,
    'valueF': 'null',
  },
  {
    'date': '2016-08-24T00:00:00',
    'value': 517.844116,
    'valueF': 'null',
  },
  {
    'date': '2016-08-25T00:00:00',
    'value': 516.7427720000001,
    'valueF': 'null',
  },
  {
    'date': '2016-08-28T00:00:00',
    'value': 516.141442,
    'valueF': 'null',
  },
  {
    'date': '2016-08-29T00:00:00',
    'value': 518.9578240000001,
    'valueF': 'null',
  },
  {
    'date': '2016-08-30T00:00:00',
    'value': 519.6848640000001,
    'valueF': 'null',
  },
  {
    'date': '2016-08-31T00:00:00',
    'value': 520.877928,
    'valueF': 'null',
  },
  {
    'date': '2016-09-01T00:00:00',
    'value': 519.713926,
    'valueF': 'null',
  },
  {
    'date': '2016-09-04T00:00:00',
    'value': 517.05727,
    'valueF': 'null',
  },
  {
    'date': '2016-09-05T00:00:00',
    'value': 519.6592,
    'valueF': 'null',
  },
  {
    'date': '2016-09-06T00:00:00',
    'value': 517.429632,
    'valueF': 'null',
  },
  {
    'date': '2016-09-07T00:00:00',
    'value': 519.692728,
    'valueF': 'null',
  },
  {
    'date': '2016-09-08T00:00:00',
    'value': 518.203898,
    'valueF': 'null',
  },
  {
    'date': '2016-09-11T00:00:00',
    'value': 519.623016,
    'valueF': 'null',
  },
  {
    'date': '2016-09-12T00:00:00',
    'value': 517.4156800000001,
    'valueF': 'null',
  },
  {
    'date': '2016-09-13T00:00:00',
    'value': 518.283646,
    'valueF': 'null',
  },
  {
    'date': '2016-09-14T00:00:00',
    'value': 514.80945,
    'valueF': 'null',
  },
  {
    'date': '2016-09-15T00:00:00',
    'value': 513.356304,
    'valueF': 'null',
  },
  {
    'date': '2016-09-18T00:00:00',
    'value': 514.151464,
    'valueF': 'null',
  },
  {
    'date': '2016-09-19T00:00:00',
    'value': 512.0785000000001,
    'valueF': 'null',
  },
  {
    'date': '2016-09-20T00:00:00',
    'value': 516.59088,
    'valueF': 'null',
  },
  {
    'date': '2016-09-21T00:00:00',
    'value': 517.152728,
    'valueF': 'null',
  },
  {
    'date': '2016-09-22T00:00:00',
    'value': 517.7335439999999,
    'valueF': 'null',
  },
  {
    'date': '2016-09-25T00:00:00',
    'value': 518.227656,
    'valueF': 'null',
  },
  {
    'date': '2016-09-26T00:00:00',
    'value': 512.3525,
    'valueF': 'null',
  },
  {
    'date': '2016-09-27T00:00:00',
    'value': 516.81528,
    'valueF': 'null',
  },
  {
    'date': '2016-09-28T00:00:00',
    'value': 517.96329,
    'valueF': 'null',
  },
  {
    'date': '2016-09-29T00:00:00',
    'value': 519.291932,
    'valueF': 'null',
  },
  {
    'date': '2016-10-02T00:00:00',
    'value': 520.379988,
    'valueF': 'null',
  },
  {
    'date': '2016-10-03T00:00:00',
    'value': 520.611156,
    'valueF': 'null',
  },
  {
    'date': '2016-10-04T00:00:00',
    'value': 519.860992,
    'valueF': 'null',
  },
  {
    'date': '2016-10-05T00:00:00',
    'value': 519.16026,
    'valueF': 'null',
  },
  {
    'date': '2016-10-06T00:00:00',
    'value': 521.676696,
    'valueF': 'null',
  },
  {
    'date': '2016-10-09T00:00:00',
    'value': 521.593666,
    'valueF': 'null',
  },
  {
    'date': '2016-11-02T00:00:00',
    'value': 514.621214,
    'valueF': 'null',
  },
  {
    'date': '2016-11-03T00:00:00',
    'value': 508.18399999999997,
    'valueF': 'null',
  },
  {
    'date': '2016-12-01T00:00:00',
    'value': 520.282608,
    'valueF': 'null',
  },
  {
    'date': '2016-12-12T00:00:00',
    'value': 526.248456,
    'valueF': 'null',
  },
  {
    'date': '2016-12-18T00:00:00',
    'value': 527.47648,
    'valueF': 'null',
  },
  {
    'date': '2016-12-19T00:00:00',
    'value': 530.270166,
    'valueF': 'null',
  },
  {
    'date': '2016-12-20T00:00:00',
    'value': 527.52579,
    'valueF': 'null',
  },
  {
    'date': '2016-12-21T00:00:00',
    'value': 525.243876,
    'valueF': 'null',
  },
  {
    'date': '2017-01-12T00:00:00',
    'value': 524.488376,
    'valueF': 'null',
  },
  {
    'date': '2017-01-15T00:00:00',
    'value': 528.645212,
    'valueF': 'null',
  },
  {
    'date': '2017-01-16T00:00:00',
    'value': 523.6755,
    'valueF': 'null',
  },
  {
    'date': '2017-01-17T00:00:00',
    'value': 530.568534,
    'valueF': 'null',
  },
  {
    'date': '2017-01-18T00:00:00',
    'value': 522.947,
    'valueF': 'null',
  },
  {
    'date': '2017-05-09T00:00:00',
    'value': 540.6492619999999,
    'valueF': 'null',
  },
  {
    'date': '2017-06-06T00:00:00',
    'value': 544.48686,
    'valueF': 'null',
  },
  {
    'date': '2017-07-09T00:00:00',
    'value': 544.453524,
    'valueF': 'null',
  },
  {
    'date': '2017-07-10T00:00:00',
    'value': 542.3029760000001,
    'valueF': 'null',
  },
  {
    'date': '2017-07-11T00:00:00',
    'value': 536.784364,
    'valueF': 'null',
  },
  {
    'date': '2017-07-12T00:00:00',
    'value': 543.821056,
    'valueF': 'null',
  },
  {
    'date': '2018-03-18T00:00:00',
    'value': 542.172,
    'valueF': 'null',
  },
  {
    'date': '2018-05-01T00:00:00',
    'value': 550.68183,
    'valueF': 'null',
  },
  {
    'date': '2018-05-02T00:00:00',
    'value': 553.318732,
    'valueF': 'null',
  },
  {
    'date': '2018-05-03T00:00:00',
    'value': 549.93264,
    'valueF': 'null',
  },
  {
    'date': '2018-05-06T00:00:00',
    'value': 548.621688,
    'valueF': 'null',
  },
  {
    'date': '2018-05-08T00:00:00',
    'value': 553.458944,
    'valueF': 'null',
  },
  {
    'date': '2018-05-10T00:00:00',
    'value': 549.0615,
    'valueF': 'null',
  },
  {
    'date': '2018-05-13T00:00:00',
    'value': 556.64512,
    'valueF': 'null',
  },
  {
    'date': '2018-05-14T00:00:00',
    'value': 558.648612,
    'valueF': 'null',
  },
  {
    'date': '2018-05-15T00:00:00',
    'value': 553.2024,
    'valueF': 'null',
  },
  {
    'date': '2018-05-16T00:00:00',
    'value': 557.4096959999999,
    'valueF': 'null',
  },
  {
    'date': '2018-05-17T00:00:00',
    'value': 554.555752,
    'valueF': 'null',
  },
  {
    'date': '2018-05-21T00:00:00',
    'value': 554.78835,
    'valueF': 'null',
  },
  {
    'date': '2018-05-22T00:00:00',
    'value': 556.868864,
    'valueF': 'null',
  },
  {
    'date': '2018-05-24T00:00:00',
    'value': 555.612468,
    'valueF': 'null',
  },
  {
    'date': '2018-06-25T00:00:00',
    'value': 548.491244,
    'valueF': 'null',
  },
  {
    'date': '2018-06-26T00:00:00',
    'value': 547.509504,
    'valueF': 'null',
  },
  {
    'date': '2018-06-27T00:00:00',
    'value': 543.054344,
    'valueF': 'null',
  },
  {
    'date': '2018-09-05T00:00:00',
    'value': 551.236864,
    'valueF': 'null',
  },
  {
    'date': '2018-09-06T00:00:00',
    'value': 551.944224,
    'valueF': 'null',
  },
  {
    'date': '2018-09-19T00:00:00',
    'value': 554.270464,
    'valueF': 'null',
  },
  {
    'date': '2018-09-20T00:00:00',
    'value': 555.784448,
    'valueF': 'null',
  },
  {
    'date': '2018-09-23T00:00:00',
    'value': 556.11537,
    'valueF': 'null',
  },
  {
    'date': '2018-09-24T00:00:00',
    'value': 558.89122,
    'valueF': 'null',
  },
  {
    'date': '2018-09-25T00:00:00',
    'value': 551.818762,
    'valueF': 'null',
  },
  {
    'date': '2018-09-26T00:00:00',
    'value': 552.940464,
    'valueF': 'null',
  },
  {
    'date': '2018-09-30T00:00:00',
    'value': 554.745,
    'valueF': 'null',
  },
  {
    'date': '2018-10-01T00:00:00',
    'value': 559.32375,
    'valueF': 'null',
  },
  {
    'date': '2018-10-04T00:00:00',
    'value': 553.451776,
    'valueF': 'null',
  },
  {
    'date': '2018-10-07T00:00:00',
    'value': 553.81482,
    'valueF': 'null',
  },
  {
    'date': '2018-11-08T00:00:00',
    'value': 540.307,
    'valueF': 'null',
  },
  {
    'date': '2018-11-13T00:00:00',
    'value': 539.006376,
    'valueF': 'null',
  },
  {
    'date': '2018-12-04T00:00:00',
    'value': 539.288828,
    'valueF': 'null',
  },
  {
    'date': '2018-12-05T00:00:00',
    'value': 534.2466,
    'valueF': 'null',
  },
  {
    'date': '2018-12-06T00:00:00',
    'value': 530.504328,
    'valueF': 'null',
  },
  {
    'date': '2018-12-09T00:00:00',
    'value': 533.785314,
    'valueF': 'null',
  },
  {
    'date': '2018-12-10T00:00:00',
    'value': 523.4080799999999,
    'valueF': 'null',
  },
  {
    'date': '2019-01-01T00:00:00',
    'value': 518.370502,
    'valueF': 'null',
  },
  {
    'date': '2019-01-24T00:00:00',
    'value': 539.0277120000001,
    'valueF': 'null',
  },
  {
    'date': '2019-01-27T00:00:00',
    'value': 535.977654,
    'valueF': 'null',
  },
  {
    'date': '2019-01-28T00:00:00',
    'value': 534.836208,
    'valueF': 'null',
  },
  {
    'date': '2019-01-30T00:00:00',
    'value': 534.53189,
    'valueF': 'null',
  },
  {
    'date': '2019-01-31T00:00:00',
    'value': 535.401856,
    'valueF': 'null',
  },
  {
    'date': '2019-02-03T00:00:00',
    'value': 536.976768,
    'valueF': 'null',
  },
  {
    'date': '2019-02-04T00:00:00',
    'value': 542.92764,
    'valueF': 'null',
  },
  {
    'date': '2019-02-05T00:00:00',
    'value': 545.36047,
    'valueF': 'null',
  },
  {
    'date': '2019-02-07T00:00:00',
    'value': 540.820224,
    'valueF': 'null',
  },
  {
    'date': '2019-02-10T00:00:00',
    'value': 533.4784999999999,
    'valueF': 'null',
  },
  {
    'date': '2019-02-11T00:00:00',
    'value': 543.113916,
    'valueF': 'null',
  },
  {
    'date': '2019-02-12T00:00:00',
    'value': 538.408334,
    'valueF': 'null',
  },
  {
    'date': '2019-02-14T00:00:00',
    'value': 543.70503,
    'valueF': 'null',
  },
  {
    'date': '2019-02-17T00:00:00',
    'value': 544.0650519999999,
    'valueF': 'null',
  },
  {
    'date': '2019-02-18T00:00:00',
    'value': 545.933976,
    'valueF': 'null',
  },
  {
    'date': '2019-02-19T00:00:00',
    'value': 542.45847,
    'valueF': 'null',
  },
  {
    'date': '2019-02-21T00:00:00',
    'value': 548.712704,
    'valueF': 'null',
  },
  {
    'date': '2019-02-24T00:00:00',
    'value': 544.575906,
    'valueF': 'null',
  },
  {
    'date': '2019-02-25T00:00:00',
    'value': 552.135144,
    'valueF': 'null',
  },
  {
    'date': '2019-02-26T00:00:00',
    'value': 552.342576,
    'valueF': 'null',
  },
  {
    'date': '2019-02-27T00:00:00',
    'value': 548.09205,
    'valueF': 'null',
  },
  {
    'date': '2019-02-28T00:00:00',
    'value': 544.0141679999999,
    'valueF': 'null',
  },
  {
    'date': '2019-03-03T00:00:00',
    'value': 548.400316,
    'valueF': 'null',
  },
  {
    'date': '2019-03-04T00:00:00',
    'value': 554.256674,
    'valueF': 'null',
  },
  {
    'date': '2019-03-05T00:00:00',
    'value': 549.48388,
    'valueF': 'null',
  },
  {
    'date': '2019-03-06T00:00:00',
    'value': 547.013424,
    'valueF': 'null',
  },
  {
    'date': '2019-03-07T00:00:00',
    'value': 552.042224,
    'valueF': 'null',
  },
  {
    'date': '2019-03-10T00:00:00',
    'value': 545.0876480000001,
    'valueF': 'null',
  },
  {
    'date': '2019-03-11T00:00:00',
    'value': 544.058846,
    'valueF': 'null',
  },
  {
    'date': '2019-03-12T00:00:00',
    'value': 544.821384,
    'valueF': 'null',
  },
  {
    'date': '2020-03-10T00:00:00',
    'value': 545.0876480000001,
    'valueF': 'null',
  },
  {
    'date': '2022-02-11T00:00:00',
    'value': 544.458846,
    'valueF': 'null',
  },
  {
    'date': '2022-03-12T00:00:00',
    'value': 544.621384,
    'valueF': 'null',
  },
  {
    'date': '2022-04-12T00:00:00',
    'value': 544.221384,
    'valueF': 'null',
  },
  {
    'date': '2022-05-12T00:00:00',
    'value': 543.921384,
    'valueF': 'null',
  },
];
