import { createUseStyles } from '@ere-uilib/styles';

import { Context, Styles } from './interfaces';

const getStyles = (
  context?: Context,
): Styles => ({
  contentStyle: [
    !(context?.isMobile)
      ? {
        flex: 2.1,
        borderTopRightRadius: context?.theme.metrics.spacing.xm,
        borderBottomRightRadius: context?.theme.metrics.spacing.xm,
      }
      : {
        flex: 1,
        alignItems: 'center',
        marginTop: context?.theme.metrics.spacing.xm,
      },
  ],
  pageTitleStyle: [
    {
      marginBottom: context?.theme.metrics.spacing.s,
    },
  ],
  cardContainers: [
    context?.isMobile && {
      alignItems: 'center',
    },
  ],
  helpLinkStyle: [
    context?.isMobile && {
      marginBottom: context?.theme.metrics.spacing.m,
    },
  ],
  desktopFooterContainer: [
    {
      marginTop: context?.theme.metrics.spacing.xm,
    },
  ],
  footerContentView: [
    {
      alignItems: 'center',
    },
  ],
  cardChoiceContainer: [
    {
      width: context?.theme.metrics.contentSizes.eerCardChoice,
      padding: context?.theme.metrics.spacing.xm,
      borderRadius: context?.theme.metrics.borderRadius.xs,
      borderWidth: 1,
      justifyContent: 'center',
      alignItems: 'center',
      marginVertical: context?.theme.metrics.spacing.s,
      borderColor: context?.theme.colors.borders.fields.c500,
    },
  ],
});

export const useStyles = createUseStyles(getStyles);
