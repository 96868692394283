export enum StatusTagTypesEnum {
  TO_INFORM = "ToInform",
  DEFAULT = 'default',
  IN_PROGRESS = 'InProgress',
  TERMINE = 'Termine',
  ECHEC = 'Echec',
  EN_ATTENTE = 'EnAttente',
  ANNULE = 'Annule',
  EN_ATTENTE_OPERATION = 'EnAttente_Operation'
}
