
/* adding this file to resolve React Native Encrypted Storage Error: RNEncryptedStorage is undefined. trying to exclude 
 react-native-encrypted-storage from webpack but nothing work so i create a separate file fro web to dismiss react-native-encrypted-storage
 */

export function* isSensorAvailable() {
  return false
}

export function* simplePrompt() {
  return false
}

export function* getCredentialsFromKeystore() {
  return undefined
}

export function* setCredentialsIntoKeystore(username: string, password: string) {
  return undefined
}
