import React, { useCallback } from 'react'
import { View } from 'react-native'

import { DashboardPageWrapperConnected } from '@components/ERE360Components'
import {
  ColumnsContainer,
  Paragraph,
  SimpleButton,
  Switch,
  Title,
  useScreenSizes
} from '@ere-uilib/index'

import { DocumentSpaceHeader } from '../components'
import { useController } from './useController'
import { useLabels } from './useLabels'
import { useStyles } from './useStyles'

export const EDocumentsSubscription = () => {
  const { isDesktop } = useScreenSizes()
  const styles = useStyles()
  const labels = useLabels()

  const { goToEDocumentsFiles, handleToggle, willUnsubscribe, isSubscriber, isLoading } =
    useController()

  const renderHeader = useCallback(
    () => (
      <DocumentSpaceHeader
        displayBackButton
        displayCloseButton
        helpPoint={false}
        title={labels.headerTitle}
      />
    ),
    [labels]
  )

  const renderBottomAction = useCallback(
    () =>
      willUnsubscribe && (
        <SimpleButton
          containerStyle={isDesktop && styles.button}
          design='solid'
          disabled={isLoading}
          onPress={handleToggle(false)}
          size={isDesktop ? 'small' : 'large'}
          title={labels.unsubscriptionButton}
        />
      ),
    [handleToggle, isDesktop, isLoading, labels, styles, willUnsubscribe]
  )

  return (
    <DashboardPageWrapperConnected cardContentStyle={styles.wrapper}>
      <ColumnsContainer
        hasColumnCenter
        hasRenderRightDesktopColumn={false}
        leftColumnStyle={styles.container}
        renderHeader={renderHeader}
        renderStickyMobileBottom={renderBottomAction}>
        <View style={styles.switchContainer}>
          <Title
            variant="t6"
            weight="bold">
            {labels.switchLabel}
          </Title>
          <Switch
            isLoading={isLoading}
            isOn={isSubscriber}
            onToggle={handleToggle()}
          />
        </View>
        {!willUnsubscribe && (
          <>
            <Paragraph
              autoMargin
              variant="t3"
              weight="light">
              {labels.getSubscriptionStatus(isSubscriber)}
            </Paragraph>
            {isSubscriber && (
              <SimpleButton
                containerStyle={styles.button}
                design="solid"
                onPress={goToEDocumentsFiles}
                size="small"
                title={labels.goToEDocumentsFiles}
              />
            )}
          </>
        )}
        {willUnsubscribe && (
          <>
            <Title variant="t7">{labels.unsubscriptionTitle}</Title>
            <Paragraph
              autoMargin
              variant="t3"
              weight="light">
              {labels.unsubscriptionDescription}
            </Paragraph>
            {isDesktop && renderBottomAction()}
          </>
        )}
      </ColumnsContainer>
    </DashboardPageWrapperConnected>
  )
}
