import React, { useCallback } from "react";
import { LoaderPlaceholderView } from "@ere-uilib/atoms";
import { useScreenSizes } from "@ere-uilib/styles";
import { View } from "react-native";
import { useStyles } from "./useStyle";

export const AvailabilitiesContentLoader: React.FC = () => {
  const { isMobile } = useScreenSizes();
  const styles = useStyles();

  const LoaderItem = useCallback(() => (

    <View style={styles.loaderContainerStyle}>
      <LoaderPlaceholderView
        style={{ height: 35, width: 35, borderRadius: 35 }}
      />
      <LoaderPlaceholderView
        style={{ width: isMobile ? '47%' : '100%', margin: 15 }}
      />
      <LoaderPlaceholderView
        style={{ width: isMobile ? '28%' : '50%' }}
      />
    </View>

  ), [
    isMobile,
    styles
  ])

  return <>
    {
      Array(4).fill('availabilities_loader_item').map((item: string, index: number) => <LoaderItem key={item + index} />)
    }
  </>
}