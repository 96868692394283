import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useScreenSizes, useTheme } from '@ere-uilib/styles';

import { CarouselProps } from './Carousel';

export const useStyles = (
  args: Pick<
    CarouselProps<unknown>,
    | 'arrowsSize'
    | 'arrowsIndent'
    | 'bulletsSize'
    | 'spacingBeforeFirstItem'
    | 'spacingAfterLastItem'
    | 'spacingBetweenItems'
  >
) => {
  const { isMobile } = useScreenSizes();
  const theme = useTheme();

  const styles = useMemo(() => {
    const arrowIndent = args.arrowsIndent ?? theme.metrics.spacing.xl;

    const arrowsSize =
      (args.arrowsSize === 'large' && 50) ||
      (args.arrowsSize === 'small' && 30) ||
      (typeof args.arrowsSize === 'number' && Number(args.arrowsSize)) ||
      0;

    const bulletsSize = args.bulletsSize || 10;

    return StyleSheet.create({
      container: {
        flex: 1
      },
      hoverableContent: {
        flex: 1,
        alignItems: 'center'
      },
      list: {
        width: '100%'
      },
      listContentContainer: {
        flexDirection: 'row'
      },
      listItemSeparator: {
        width: args.spacingBetweenItems
      },
      listHeaderContainer: {
        width: args.spacingBeforeFirstItem,
        height: '100%'
      },
      listFooterContainer: {
        width: args.spacingAfterLastItem,
        height: '100%'
      },
      arrow: {
        position: 'absolute',
        top: '50%',
        zIndex: 1,
        transform: [{ translateY: -arrowsSize * 0.5 }],
        justifyContent: 'center',
        alignItems: 'center',
        width: arrowsSize,
        height: arrowsSize,
        borderRadius: arrowsSize * 0.5,
        shadowColor: theme.colors.basics.black,
        shadowOffset: {
          width: 0,
          height: 0
        },
        shadowOpacity: 0.2,
        shadowRadius: 10,
        elevation: 1,
        backgroundColor: theme.colors.basics.white
      },
      arrowIcon: {
        fontSize: arrowsSize * 0.33
      },
      leftArrow: {
        left: arrowIndent
      },
      rightArrow: {
        right: arrowIndent
      },
      bulletsContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        paddingTop: theme.metrics.spacing.m
      },
      bullet: {
        width: bulletsSize,
        height: bulletsSize,
        borderRadius: bulletsSize * 0.5,
        borderWidth: 1,
        borderColor: theme.colors.buttons.menuItemActive.c500,
        marginRight: theme.metrics.spacing.m
      },
      bulletLast: {
        marginRight: theme.metrics.spacing.none
      },
      bulletActive: {
        backgroundColor: theme.colors.buttons.menuItemActive.c500
      }
    });
  }, [args, theme, isMobile]);

  return styles;
};
