import * as actions from './authActionsTypes';
import { AuthActionType } from './authActionsTypes';
import { AuthErrorState, NativeAuthenticationError, SilentSigninOutputActionType, UserInfoState, UserState } from '../types';

export interface UserCredentials {
  userName: string | undefined
  password: string | undefined
}

export function getUserInfoSuccess(userInfo: UserInfoState) {
  return {
    type: AuthActionType.USER_INFO_SUCCESS,
    userInfo,
  }
}

export function signInNativeAppRequest(userCredentials: UserCredentials, memorizeLogin: boolean): actions.SigninNativeAppRequestAction {
  return {
    type: AuthActionType.SIGNIN_NATIVE_APP_REQUEST,
    userCredentials,
    memorizeLogin
  };
}
export function signInNativeAppSuccess(user: UserState): actions.SigninNativeAppSuccessAction {
  return {
    type: AuthActionType.SIGNIN_NATIVE_APP_SUCCESS,
    user
  };
}
export function signInNativeAppFailure(error: NativeAuthenticationError): actions.SigninNativeAppFailureAction {
  return {
    type: AuthActionType.SIGNIN_NATIVE_APP_FAILURE,
    error
  }
}

export function saveLogin(loginMail: string | undefined): actions.SaveLoginAction {
  return {
    type: AuthActionType.SAVE_LOGIN,
    loginMail
  };
}
export function saveBiometricCredentials(biometricAcceptationStatus: boolean | undefined): actions.SetBiometricCredentialsAction {
  return {
    type: AuthActionType.SET_BIOMETRIC_CREDENTIALS,
    biometricAcceptationStatus
  };
}
export function signInNativeAppUsingBiometricRequest(): actions.SigninNativeAppUsingBiometricAction {
  return {
    type: AuthActionType.SIGNIN_NATIVE_APP_USING_BIOMETRIC_REQUEST,
  }
}
export function signInNativeAppUsingBiometricSuccess(user: UserState): actions.SigninNativeAppUsingBiometricSuccessAction {
  return {
    type: AuthActionType.SIGNIN_NATIVE_APP_USING_BIOMETRIC_SUCCESS,
    user
  }
}
export function signInNativeAppUsingBiometricFailure(error: NativeAuthenticationError): actions.SigninNativeAppUsingBiometricFailureAction {
  return {
    type: AuthActionType.SIGNIN_NATIVE_APP_USING_BIOMETRIC_FAILURE,
    error
  }
}
export function logoutCallbackRequest(): actions.LogoutCallbackRequestAction {
  return {
    type: AuthActionType.LOGOUT_CALLBACK_REQUEST
  };
}

export function promptBiometricUserModalAskRequest(isVisible: boolean, biometryType: string): actions.PromptBiometricUserModalAskRequestAction {
  return {
    type: AuthActionType.PROMPT_BIOMETRIC_USER_MODAL_ASK_REQUEST,
    isVisible,
    biometryType
  }
}

export function promptBiometricUserModalAskSuccess(isVisible: boolean, success: boolean | undefined): actions.PromptBiometricUserModalAskSuccessAction {
  return {
    type: AuthActionType.PROMPT_BIOMETRIC_USER_MODAL_ASK_SUCCESS,
    isVisible,
    success
  }
}

export function logoutCallbackMobileAppRequest(): actions.LogoutCallbackMobileAppRequestAction {
  return {
    type: AuthActionType.LOGOUT_CALLBACK_MOBILE_APP_REQUEST
  };
}

export function logoutCallbackSuccess(): actions.LogoutCallbackSuccessAction {
  return {
    type: AuthActionType.LOGOUT_CALLBACK_SUCCESS
  };
}

export function logoutCallbackFailure(error: Error | string): actions.LogoutCallbackFailureAction {
  return {
    type: AuthActionType.LOGOUT_CALLBACK_FAILURE,
    error
  };
}

// SIGNIN REDIRECT CALLBACK

export function signinRedirectCallbackRequest(): actions.SigninRedirectCallbackRequestAction {
  return {
    type: AuthActionType.SIGNIN_REDIRECT_CALLBACK_REQUEST
  };
}

// SIGNIN REDIRECT CALLBACK native mobile app

export function signinRedirectCallbackMobileAppRequest(): actions.SigninRedirectCallbackRequestMobileAppAction {
  return {
    type: AuthActionType.SIGNIN_REDIRECT_CALLBACK_MOBILE_APP_REQUEST
  };
}

export function signinRedirectCallbackSuccess(
  user: UserState
): actions.SigninRedirectCallbackSuccessAction {
  return {
    type: AuthActionType.SIGNIN_REDIRECT_CALLBACK_SUCCESS,
    user
  };
}

export function signinRedirectCallbackFailure(
  error: any
): actions.SigninRedirectCallbackFailureAction {
  return {
    type: AuthActionType.SIGNIN_REDIRECT_CALLBACK_FAILURE,
    error
  };
}

//silent renew token

// silent

export function silentRenewTokenRequest(
  outputAction?: SilentSigninOutputActionType
): actions.SilentRenewTokenRequest {
  return {
    type: AuthActionType.SILENT_RENEW_TOKEN_REQUEST,
    outputAction
  };
}

export function silentRenewTokenSuccess(user: UserState): actions.SilentRenewTokenSuccess {
  return {
    type: AuthActionType.SILENT_RENEW_TOKEN_SUCCESS,
    user
  };
}

export function silentRenewTokenFailure(error: AuthErrorState): actions.SilentRenewTokenFailure {
  return {
    type: AuthActionType.SILENT_RENEW_TOKEN_FAILURE,
    error
  };
}

// INIT ERROR

export function authInitError(): actions.AuthInitErrorAction {
  return {
    type: AuthActionType.AUTH_INIT_ERROR
  };
}

export function setIsOidcSessionEnded(newState: boolean): actions.SetIsOidcSessionEndedAction {
  return {
    type: AuthActionType.SET_IS_OIDC_SESSION_ENDED,
    newState
  };
}
