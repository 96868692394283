import { useMemo } from 'react';
import { Context } from './interface';
import { StyleSheet } from 'react-native';
import { useScreenSizes, useTheme } from '@ere-uilib/styles';

export const useStyles = (context?: Context) => {
  const theme = useTheme();
  const { isMobile } = useScreenSizes();

  const styles = useMemo(() => StyleSheet.create({
    bodyTextContainer: {
      marginVertical: theme.metrics.spacing.xs,
    },
    renderContentWrapper: {
      zIndex: 9,
      ...(!context?.isExternalButton && {
        paddingTop: theme.metrics.spacing.s,
        paddingBottom: theme.metrics.spacing.xm
      })
    },
    disabledMeessageContainer: {
      marginVertical: theme.metrics.spacing.s
    },
    optionsList: {
      marginVertical: theme.metrics.spacing.xs,
    },
    buttonContainer: {
      ...(context?.isExternalButton && {
        marginTop: theme.metrics.spacing.xl,
      }),
      marginTop: theme.metrics.spacing.s,
      flexDirection: 'row',
    },
    button: {
      ...(context?.isExternalButton && !isMobile && {
        alignSelf: 'flex-start'
      }),
      minHeight: 0,
      paddingVertical: theme.metrics.spacing.xs,
      paddingHorizontal: theme.metrics.spacing.m,
    },
    buttonLargeStyle: {
      flex: 1,
      paddingVertical: theme.metrics.spacing.m
    },
    container: {
      backgroundColor: theme.colors.basics.white,
      shadowColor: theme.colors.basics.black,
      shadowOffset: {
        width: 0,
        height: 0,
      },
      shadowOpacity: 0.15,
      shadowRadius: theme.metrics.spacing.s,
      elevation: 7,
    },
    bodyContainer: {
      paddingHorizontal: theme.metrics.spacing.s
    },
    disabledBodyContainer: {
      backgroundColor: theme?.colors.basics.grey.c100,
      paddingVertical: theme.metrics.spacing.s,
    },
    childrenViewWrappper: {
      zIndex: 99,
    },

  }), [
    theme,
    isMobile
  ]);

  return styles;
}