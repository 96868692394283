import axios from 'axios';

import { API_URL } from '@config/index';

export async function fetchMenuFooter(): Promise<any> {
  return await axios.get(
    `${API_URL}/api/v1/application/menus/footerMenu`,
  );
}

export async function fetchLegalContent(code: string, isLogged:boolean): Promise<any> {
  if (isLogged) {
    return await axios.get(`${API_URL}/api/v1/application/pageContent/${code}`);
  } else {
    return await axios.get(`${API_URL}/api/v1/application/pageContent/${code}/global`);
  }
}
