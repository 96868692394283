import React, { useMemo } from 'react';
import { View } from 'react-native';

import { useScreenSizes, useTheme } from '@components/index';
import { CardWithLeftThread, Text, Title } from '@ere-uilib/atoms';
import { FiletStepper, SynthesisManagementRow } from '@ere-uilib/molecules';
import { useTranslation } from '@ere-uilib/translations';

import { Props } from './interfaces';
import { useStyles } from './useStyles';

export const FiletStepperCardOrigin: React.FC<React.PropsWithChildren<Props>> = ({
  defaultInterestAmount,
  netAmount,
  companyName,
  interestTitle,
  containerStyle
}) => {
  const styles = useStyles(defaultInterestAmount);
  const { formatMessage, formatCurrencyNumber } = useTranslation();

  const theme = useTheme();

  const { isMobile, isTablet } = useScreenSizes();
  const dispositifColor = theme.colors.basics.primary;

  const InterestDetails = useMemo(() => {
    if (!!defaultInterestAmount)
      return (
        <View style={styles.interestDetailsContainer}>
          <SynthesisManagementRow
            amount={netAmount}
            containerStyle={styles.interestType}
            fontWeight="regular"
            title={interestTitle}
            variant="t3"
          />
          <SynthesisManagementRow
            amount={defaultInterestAmount || 0}
            containerStyle={styles.ineterstAmount}
            fontWeight="regular"
            title={formatMessage({ id: 'PIDetailOperationInteretRetardTitre' })}
            variant="t3"
          />
        </View>
      );
  }, [
    defaultInterestAmount,
    formatMessage,
    interestTitle,
    netAmount,
    styles.ineterstAmount,
    styles.interestDetailsContainer,
    styles.interestType
  ]);

  return (
    <FiletStepper
      containerStyle={styles.filetContainer}
      contentContainer={containerStyle}
      disableIcone={false}
      disableLine={false}
      filetContainerStyle={styles.filetSubContainer}>
      <View style={styles.fromContent}>
        <Text style={styles.filetText}>{formatMessage({ id: 'PIAffectationdefautDeLabel' })}</Text>
        <CardWithLeftThread
          addShadow
          borderLeftColor={dispositifColor?.c500}>
          <View style={styles.interestCardHeader}>
            <View style={styles.interestCardHeaderAmountCard}>
              <Title
                style={[styles.interestCardRow, styles.leftText]}
                variant={isMobile || isTablet ? 't7' : 't6'}
                weight={'regular'}>
                {!!defaultInterestAmount
                  ? formatMessage({ id: 'PIDetailOperationMontantNetTitle' })
                  : interestTitle}
              </Title>
              <Title
                style={[styles.interestCardRow, styles.rightText]}
                variant={isMobile || isTablet ? 't7' : 't6'}
                weight="regular">
                {formatCurrencyNumber({ value: netAmount + (defaultInterestAmount || 0) })}
              </Title>
            </View>
            <View style={styles.interestCardHeaderCompanyContainer}>
              <Title
                style={[styles.interestCardRow, styles.leftText]}
                variant={isMobile || isTablet ? 't9' : 't8'}
                weight={'light'}>
                {companyName}
              </Title>
            </View>
          </View>
          {InterestDetails}
        </CardWithLeftThread>
      </View>
    </FiletStepper>
  );
};
