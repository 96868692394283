import React, { PropsWithChildren, ReactNode, useState } from 'react';
import { View, StyleProp, ViewStyle, TouchableOpacity, ScrollView } from 'react-native';

import { FontIcon, Title, Modal, HtmlStyledRenderConnectedContainer, HtmlRenderer } from '@components/index';
import { IconEnum } from '@ere-uilib/enums';
import {
  ModalFrame,
} from '@ere-uilib/molecules';
import { createUseStyles, ThemeType, useTheme } from '@ere-uilib/styles';

export interface HelpPointProps extends HelpPointStyles {
  modalTitle: string
  contentTitle?: string
  contentHtml?: string
  renderModalContent?(): ReactNode
  iconSize?: number
  iconColor?: string
}

type HelpPointStyles = {
  style?: StyleProp<ViewStyle>;
  modalFrameInnerContainer?: StyleProp<ViewStyle>;
  ScrollViewStyle?: StyleProp<ViewStyle>;
  contentContainerStyle?: StyleProp<ViewStyle>;
};

type StylesContext = { theme: ThemeType; };

export const HelpPoint: React.FC<PropsWithChildren<HelpPointProps>> = ({
  style,
  modalTitle = '',
  contentTitle = '',
  contentHtml = '',
  renderModalContent,
  iconSize,
  iconColor,
  modalFrameInnerContainer,
  ScrollViewStyle,
  contentContainerStyle,
  children,
}) => {
  const theme = useTheme();
  const styles = useStyles(
    { theme },
    {
      style,
      modalFrameInnerContainer,
      ScrollViewStyle,
      contentContainerStyle,
    }
  );
  const [displayModal, setDisplayModal] = useState(false);

  if ((!contentHtml || contentHtml === '' || contentHtml === ' ' || contentHtml === '...') && !renderModalContent) {
    return null;
  }
  return (
    <TouchableOpacity
      onPress={() => {
        setDisplayModal(true);
      }}
      style={styles.style}
    >
      {children ?
        children
        : (
          <FontIcon
            color={iconColor || theme.colors.text.primary.c500}
            name={IconEnum.INFO}
            size={iconSize ? iconSize : theme.metrics.contentSizes.helpPointIcon}
          />
        )
      }
      <Modal
        visible={displayModal}
      >
        <ModalFrame
          desktopPosition="fullHeight"
          innerContainer={styles.modalFrameInnerContainer}
          maxDesktopWidth={theme.metrics.breakpoints.desktop}
          mobilePosition="full"
          onClose={() => {
            setDisplayModal(false);
          }}
          title={modalTitle}
        >
          <ScrollView
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            style={styles.ScrollViewStyle}
          >
            <View style={styles.contentContainerStyle}>
              {contentTitle !== '' && !(contentTitle === '' || contentTitle === ' ' || contentTitle === '...') && (
                <Title
                  variant="t6"
                  weight="bold" >{contentTitle}</Title>
              )}
              {!!contentHtml &&
                <HtmlRenderer
                  html={contentHtml}
                />
              }
              {!!renderModalContent && renderModalContent()}
            </View>
          </ScrollView>
        </ModalFrame>
      </Modal>
    </TouchableOpacity>
  );
};

const getStyles = (context?: StylesContext, style?: HelpPointStyles): HelpPointStyles => ({
  style: [
    {},
    style?.style,
  ],
  modalFrameInnerContainer: [
    { paddingTop: 0 },
    style?.modalFrameInnerContainer,
  ],
  ScrollViewStyle: [
    style?.ScrollViewStyle,
  ],
  contentContainerStyle: [
    { paddingTop: context?.theme.metrics.spacing.xxl },
    style?.contentContainerStyle,
  ],
});

const useStyles = createUseStyles(getStyles);
