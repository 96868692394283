import React, { useState } from 'react';
import { StackNavigationProp } from '@react-navigation/stack';
import { View } from 'react-native';

import { FundSheetModal, FundSheetModalDataType } from '@components/templates';
import { useTheme } from '@ere-uilib/index';
import { SynthesisFundsRow, TriggerThresholdComponent } from '@ere-uilib/molecules';
import { SupportType, ManagmentType } from '@modules/common/types';
import { AppNavigatorInterface } from '@navigation/Interfaces';

import { Support } from '../Support';

interface SourceSupportProps {
  navigation: StackNavigationProp<AppNavigatorInterface>
  supports: SupportType[]
  management: Partial<ManagmentType>
  isPartialRealocation?: boolean
  shouldDisplayAddedValue?: boolean
}

export const SourceSupportWrapper: React.FC<React.PropsWithChildren<SourceSupportProps>> = ({
  navigation,
  management,
  supports,
  isPartialRealocation,
  shouldDisplayAddedValue = true,
}) => {

  const theme = useTheme();

  const [fundSheetModalData, setFundSheetModalData] =
    useState<FundSheetModalDataType | null>();
  const [displayFundSheetModal, setDisplayFundSheetModal] = useState(false);
  return (
    <>
      {
        supports.map((support: SupportType, supportIndex: number) => {
          return (
            management.isFree && isPartialRealocation ?
              <>
                <SynthesisFundsRow
                  amount={-(support?.formValues?.amount ?? 0)}
                  key={supportIndex}
                  onTitlePress={() => {
                    setDisplayFundSheetModal(true);
                    setFundSheetModalData({
                      id: support?.supportIsin,
                      title: support.supportName,
                    });
                  }}
                  title={support?.supportName}
                />
                {(support.formValues?.triggerThreshold) &&
                  <View
                    style={{
                      paddingHorizontal: theme.metrics.spacing.s,
                      paddingBottom: theme.metrics.spacing.xs,
                    }}>
                    <TriggerThresholdComponent
                      data={support.formValues?.triggerThreshold}
                      isEditable={false}
                    />
                  </View>
                }
              </>
              :
              <Support
                isFieldAmountPercentage
                isPercentageField
                isInputEditable={false}
                percentage={support.advancedProperties.PourcentageRepartition}
                shouldDisplayAddedValue={shouldDisplayAddedValue}
                support={support}
              />

          );
        })
      }
      {fundSheetModalData && (
        <FundSheetModal
          id={fundSheetModalData?.id}
          modalIsVisible={displayFundSheetModal}
          onCloseModal={() => {
            setDisplayFundSheetModal(false);
            setFundSheetModalData(null);
          }}
          title={fundSheetModalData?.title}
        />
      )}
    </>);
};
