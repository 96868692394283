import { Context, Style } from './interfaces';

export const getStyles = (
  context?: Context,
  style?: Style,
): Style => ({
  container: [
    {
      borderColor: context?.theme.colors.notifications.success.c500,
      flexDirection: 'row',
      borderWidth: 1,
      marginRight: context?.theme.metrics.spacing.s,
      marginTop: context?.theme.metrics.spacing.s,
      borderRadius: context?.theme.metrics.borderRadius.xs,
      paddingVertical: context?.theme.metrics.spacing.xxs,
      alignItems: 'center',
      paddingHorizontal: context?.theme.metrics.spacing.s
    },
    !context?.isAvailable && {
      backgroundColor: context?.theme.colors.buttons.disabled.c500,
      borderWidth: 0
    },
    style?.container
  ],
  title: [
    {
      lineHeight: undefined,
      color: context?.theme.colors.notifications.success.c500,
      marginLeft: context?.theme.metrics.spacing.xs
    },
    !context?.isAvailable && {
      color: context?.theme.colors.text.disabled.c500
    },
    style?.title
  ]
});
