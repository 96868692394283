import {
  DocumentApiFile,
  DocumentCategory,
  DocumentsCategoriesErrorState,
  EDocuments
} from '@modules/document-space/types';
import { ErrorItemState } from '@modules/error/types';

import {
  DocumentsActionsType,
  GetDocumentBlobFailure,
  GetDocumentBlobRequest,
  GetDocumentBlobSuccess,
  GetDocumentsCategoriesFailure,
  GetDocumentsCategoriesRequest,
  GetDocumentsCategoriesSuccess,
  GetEDocumentsFailure,
  GetEDocumentsRequest,
  GetEDocumentsSubscriptionFailure,
  GetEDocumentsSubscriptionRequest,
  GetEDocumentsSubscriptionSuccess,
  GetEDocumentsSuccess,
  SetAllDocumentsCategoriesFilter,
  SetDocumentsCategoriesFilter,
  SetDocumentsCategoriesReset
} from './documentsActionsTypes';

export const getDocumentsCategoriesRequest = (): GetDocumentsCategoriesRequest => {
  return { type: DocumentsActionsType.GET_DOCUMENTS_CATEGORIES_REQUEST };
};

export const getDocumentsCategoriesSuccess = (
  categories: DocumentCategory[],
  files: DocumentApiFile[]
): GetDocumentsCategoriesSuccess => {
  return {
    type: DocumentsActionsType.GET_DOCUMENTS_CATEGORIES_SUCCESS,
    categories,
    files
  };
};

export const getDocumentsCategoriesFailure = (
  error: DocumentsCategoriesErrorState
): GetDocumentsCategoriesFailure => {
  return {
    type: DocumentsActionsType.GET_DOCUMENTS_CATEGORIES_FAILURE,
    error
  };
};


export const setDocumentsCategoriesFilter = (
  categoryId: string,
  isSelected: boolean
): SetDocumentsCategoriesFilter => {
  return {
    type: DocumentsActionsType.SET_DOCUMENTS_CATEGORIES_FILTER,
    categoryId,
    isSelected
  };
};

export const setAllDocumentsCategoriesFilter = (
): SetAllDocumentsCategoriesFilter => {
  return {
    type: DocumentsActionsType.SET_ALL_DOCUMENTS_CATEGORIES_FILTER,
  };
};

export const setDocumentsCategoriesReset = (): SetDocumentsCategoriesReset => {
  return {
    type: DocumentsActionsType.SET_DOCUMENTS_CATEGORIES_RESET,
  };
};


export const getDocumentBlobRequestByType = (
  documentType: string,
  documentTitle: string,
): GetDocumentBlobRequest => {
  return {
    type: DocumentsActionsType.GET_DOCUMENT_BLOB_RESPONSE_REQUEST,
    documentType,
    documentTitle,
  };
};

export const getDocumentBlobRequestById = (
  documentId: string,
  documentTitle: string,
): GetDocumentBlobRequest => {
  return {
    type: DocumentsActionsType.GET_DOCUMENT_BLOB_RESPONSE_REQUEST,
    documentId,
    documentTitle,
  };
};

export const getDocumentBlobSuccess = (
  blobResponse: BlobPart
): GetDocumentBlobSuccess => {
  return {
    type: DocumentsActionsType.GET_DOCUMENT_BLOB_RESPONSE_SUCCESS,
    blobResponse
  };
};

export const getDocumentBlobFailure = (
  error: DocumentsCategoriesErrorState
): GetDocumentBlobFailure => {
  return {
    type: DocumentsActionsType.GET_DOCUMENT_BLOB_RESPONSE_FAILURE,
    error
  };
};

export const getEDocumentsRequest = (): GetEDocumentsRequest => {
  return {
    type: DocumentsActionsType.GET_EDOCUMENTS_REQUEST,
  };
};

export const getEDocumentsSuccess = (
  eDocuments: EDocuments
): GetEDocumentsSuccess => {
  return {
    type: DocumentsActionsType.GET_EDOCUMENTS_SUCCESS,
    eDocuments
  };
};

export const getEDocumentsFailure = (
  error: ErrorItemState
): GetEDocumentsFailure => {
  return {
    type: DocumentsActionsType.GET_EDOCUMENTS_FAILURE,
    error
  };
};

export const getEDocumentsSubscriptionRequest = (
  isSubscriber: boolean
): GetEDocumentsSubscriptionRequest => {
  return {
    type: DocumentsActionsType.GET_EDOCUMENTS_SUBSCRIPTION_REQUEST,
    isSubscriber
  };
}

export const getEDocumentsSubscriptionSuccess = (
  isSubscriber: boolean
): GetEDocumentsSubscriptionSuccess => {
  return {
    type: DocumentsActionsType.GET_EDOCUMENTS_SUBSCRIPTION_SUCCESS,
    isSubscriber
  };
}

export const getEDocumentsSubscriptionFailure = (
  error: ErrorItemState
): GetEDocumentsSubscriptionFailure => {
  return {
    type: DocumentsActionsType.GET_EDOCUMENTS_SUBSCRIPTION_FAILURE,
    error
  };
}
