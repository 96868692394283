
import React, { useEffect, useState } from 'react';
import { ScrollView, View } from 'react-native';

import {
  useTheme,
  Modal,
  ModalFrame,
  CompareFundsInnerPage,
} from '@components/index';
import { createUseStyles } from '@ere-uilib/styles';
import { SimpleIconButton } from '@ere-uilib/molecules';
import { useTranslation } from '@ere-uilib/translations';

import { CompareFundsModalProps } from './interfaces';
import { getStyles } from './styles';

export const CompareFundsModalComponent: React.FC<React.PropsWithChildren<CompareFundsModalProps>> = ({
  modalIsVisible = false,
  onCloseModal,
  fundsToCompare,
  compareMode,
  multiFundsOverviews,
  multiFundsPerformances,
  multiFundsRepartitionSupports,
  isApiLoadingMultiOverviews,
  isApiLoadingMultiPerformances,
  isApiLoadingMultiRepartitionSupports,
  onGetMultiFunds,
  setCompareMode,
}) => {
  const theme = useTheme();
  const styles = useStyles({ theme }, {});

  const { formatMessage } = useTranslation();

  const [fundsSelected, setFundsSelected] = useState([]);
  useEffect(() => {
    setCompareMode(false);
  }, []);
  useEffect(() => {
    if (!modalIsVisible) {
      setCompareMode(false);
      setFundsSelected([]);
    }
  }, [modalIsVisible]);
  return (
    <Modal visible={modalIsVisible}>
      <ModalFrame
        desktopPosition="fullHeight"
        innerContainer={styles.innerContainerPadding}
        maxDesktopWidth={1200}
        mobilePosition="bottom"
      >
        <ScrollView
          scrollIndicatorInsets={{ right: 1 }}
        >
          <View style={styles.containerStyle}>
            <CompareFundsInnerPage
              compareMode={!!compareMode}
              fundsSelected={fundsSelected}
              fundsToCompare={fundsToCompare}
              isModal={true}
              multiFundsOverviews={multiFundsOverviews}
              multiFundsPerformances={multiFundsPerformances}
              multiFundsRepartitionSupports={multiFundsRepartitionSupports}
              onCloseModal={() => {
                onCloseModal();
              }}
              setFundsSelected={setFundsSelected}
            />
          </View>
          <View style={styles.compareButtonStyle}>
            {compareMode ? (
              <SimpleIconButton
                design="outlined"
                onPress={() => setCompareMode(false)}
                title={formatMessage({ id: 'MyFunds_Screen2Compare_Back_button' })}
              />
            ) : (
              <SimpleIconButton
                disabled={fundsSelected.length < 2}
                loading={
                  isApiLoadingMultiOverviews
                  || isApiLoadingMultiPerformances
                  || isApiLoadingMultiRepartitionSupports
                }
                onPress={() => onGetMultiFunds(fundsSelected)}
                title={formatMessage({ id: 'MyFunds_Screen1_Compare_button' })}
              />
            )}
          </View>
        </ScrollView>
      </ModalFrame>
    </Modal>
  );
};

const useStyles = createUseStyles(getStyles);
