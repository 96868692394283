import { ArbitrationActionsType } from '@modules/arbitration/actions/arbitrationActionsTypes';

export enum ArbitrationNavigatorRouteNames {
  ArbitrationChoice = 'ArbitrationChoice',
  ArbitrationOnboarding = 'ArbitrationOnboarding',
  ArbitrationModal = 'ArbitrationModal',
  ArbitrationPlanChoice = 'ArbitrationPlanChoice',
  ArbitrationDisinvestmentsSaving = 'ArbitrationDisinvestmentsSaving',
  ArbitrationSynthesis = 'ArbitrationSynthesis',
  ArbitrationReinvestment = 'ArbitrationReinvestment',
  ArbitrationFailure = 'ArbitrationFailure',
  ArbitrationSuccess = 'ArbitrationSuccess',
  ArbitrationOADRepartition = 'ArbitrationOADRepartition',
  ArbitrationOADRepartitionConfirmation = 'ArbitrationOADRepartitionConfirmation'
}

export type ArbitrationNavigatorInterface = {
  [ArbitrationNavigatorRouteNames.ArbitrationChoice]: undefined;
  [ArbitrationNavigatorRouteNames.ArbitrationOnboarding]: undefined;
  [ArbitrationNavigatorRouteNames.ArbitrationModal]: {
    clearAction: ArbitrationActionsType.CLEAR_ARBITRATION_DATA;
  };
  [ArbitrationNavigatorRouteNames.ArbitrationPlanChoice]: undefined;
  [ArbitrationNavigatorRouteNames.ArbitrationDisinvestmentsSaving]: undefined;
  [ArbitrationNavigatorRouteNames.ArbitrationSynthesis]: undefined;
  [ArbitrationNavigatorRouteNames.ArbitrationReinvestment]: undefined;
  [ArbitrationNavigatorRouteNames.ArbitrationFailure]: undefined;
  [ArbitrationNavigatorRouteNames.ArbitrationSuccess]: undefined;
  [ArbitrationNavigatorRouteNames.ArbitrationOADRepartition]: undefined;
  [ArbitrationNavigatorRouteNames.ArbitrationOADRepartitionConfirmation]: undefined;
};
