import React from 'react';
import { View } from 'react-native';

import {
  NotificationHard,
  NotificationIconTypeEnum,
  Text,
  HelpPoint,
  Link,
} from '@components/index';

import { useStyles } from './useStyles';

interface Props {
  message: string;
  errorHelperText: string;
  reedMoreText: string;
  helpPointContentHtml: string;
  helpPointModalTitle: string;
}

export function IdentifierFormNotification({
  message,
  errorHelperText,
  reedMoreText,
  helpPointContentHtml,
  helpPointModalTitle,
}: Props) {
  const styles = useStyles();

  return (
    <NotificationHard
      containerStyle={styles.errorNotification}
      type={NotificationIconTypeEnum.WARNING}
    >
      <View>
        <View>
          <Text weight="light" testId="error_message">{message}</Text>
        </View>
        <View style={styles.textContainer}>
          <Text weight="light">
            {errorHelperText + ' '}
          </Text>
        </View>
        <View style={styles.textContainer}>
          <HelpPoint
            contentHtml={helpPointContentHtml}
            modalTitle={helpPointModalTitle}
          >
            <Text weight="bold">
              <Link>
                {reedMoreText}
              </Link>
            </Text>
          </HelpPoint>
        </View>
      </View>

    </NotificationHard>
  );
}
