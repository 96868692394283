import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import React from "react";
import { TouchableOpacity, View } from "react-native";
import { useSelector } from "react-redux";

import { PlanFamillyList } from "@constants/common";
import { RouteNames } from "@constants/navigation";
import { BankOperationTypesList } from "@constants/savings";
import { FontIcon, Link, Text } from "@ere-uilib/atoms";
import { IconEnum } from "@ere-uilib/enums";
import { StatusTag } from "@ere-uilib/molecules";
import { StatusTagTypesEnum } from "@ere-uilib/molecules/tags/StatusTag/sharedInterfaces";
import { useTheme } from "@ere-uilib/styles";
import { BankStatusTypesEnum } from "@modules/bank-details/constants";
import { AccountBankDetailsType } from "@modules/bank-details/types";
import { AppState } from "@modules/reducers";
import { AppNavigatorInterface } from "@navigation/Interfaces";
import { useBankSummaryContext } from "@pages/BankDetails/BankSummaryPage/BankSummaryProvider";
import { useTranslate } from "@pages/BankDetails/BankSummaryPage/hooks/useTranslate";
import { useStyles } from "@pages/BankDetails/BankSummaryPage/useStyles";

type BankAccountStatusProps = {
    bankData: AccountBankDetailsType | undefined | null,
    isRefund: boolean,
    planFamily: PlanFamillyList,
    dispositifs: string,
    selectedBankDataIndex: number,
    planId: string
}

export const BankAccountStatus: React.FC<BankAccountStatusProps> = ({ bankData,
                                                                        isRefund,
                                                                        planFamily,
                                                                        dispositifs,
                                                                        selectedBankDataIndex,
                                                                        planId
}) => {
    const styles = useStyles();
    const theme = useTheme();
    const { dictionnary, formatMessage } = useTranslate();
    const { setIsMandateModalVisible,
            setSelectedPlanFamily,
            onSetSelectedAccountType,
            onSetSelectedPlanFamily,
            setModalData,
            onSelectPlanId,
            setSelectedRcBankDataIndex } = useBankSummaryContext();

    const navigation = useNavigation<StackNavigationProp<AppNavigatorInterface>>();
    const cardTitle = isRefund
        ? 'CoordonneesBancairesRecapVirement'
        : 'CoordonneesBancairesRecapPrelevement';

    const RcActivateState = useSelector((state: AppState) => state.settings.applicationSettings.RcActivateState);

    const handleRedirect = (provider: string) => {
        navigation.navigate(RouteNames.RedirectOperationPage, {
            provider,
            operationType: 'IBAN',
        });
    }

    const handleEdit = (planFamily: PlanFamillyList, plan: string, isRefund?: boolean) => {
        onSetSelectedPlanFamily(planFamily);
        if (planFamily === PlanFamillyList.RC) {
            navigation.navigate(RouteNames.BankDetailsStack, {
                screen: RouteNames.BankSetDocuments
            });
            return;
        }
        const operationType = isRefund
            ? BankOperationTypesList.REMBOURSEMENT
            : BankOperationTypesList.PRELEVEMENT;
        setModalData({ plan, operationType });
    };

    return (
        <View style={styles.contentFilledStyle}>

            <View style={styles.leftCard}>
                {
                    planFamily === PlanFamillyList.RC &&
                    <Text style={styles.rcPlanName} variant="t3" weight="light">{formatMessage({ id: 'CoordonneesBancairesRecapDispositifEligibleRC' })}: {dispositifs}</Text>
                }
                <Text
                    style={styles.firstLinesStyle}
                    variant="t2"
                    weight="regular">
                    {formatMessage({ id: cardTitle })}
                </Text>
                {bankData?.status?.includes(BankStatusTypesEnum.DEMANDE_INITIEE) ||
                bankData?.status?.includes(BankStatusTypesEnum.DEMANDE_EN_COURS) ? (
                    <StatusTag
                        containerStyle={styles.statusTag}
                        label={formatMessage({ id: bankData?.statusLabel })}
                        type={
                            bankData?.status === BankStatusTypesEnum.DEMANDE_EN_COURS
                                ? StatusTagTypesEnum.IN_PROGRESS
                                : StatusTagTypesEnum.EN_ATTENTE
                        }
                    />
                ) : (
                    <>
                        <Text
                            variant="t3"
                            weight="light">
                            {dictionnary.CoordonneesBancairesRecapIBANBICTitre}
                        </Text>
                        <Text
                            variant="t3"
                            weight="regular">
                            {bankData?.iban}
                        </Text>
                        {!isRefund && (
                            <View style={styles.spacingCardStyle}>
                                <Text
                                    style={styles.firstLinesStyle}
                                    variant="t3"
                                    weight="regular">
                                    {dictionnary.mandateTitle}
                                </Text>
                                <Text weight="light">
                                    <Link
                                        onPress={() => {
                                            onSelectPlanId(planId )
                                            setSelectedRcBankDataIndex(selectedBankDataIndex)
                                            setIsMandateModalVisible(true);
                                            setSelectedPlanFamily(planFamily);
                                        }}
                                        textStyle={styles.mandatLinkStyle}
                                        textVariant="t3"
                                        textWeight="light">
                                        {dictionnary.mandateLinkTitle}
                                        <View>
                                            <FontIcon
                                                color={theme.colors.basics.primary.c500}
                                                name={IconEnum.CHEVRON_RIGHT}
                                                size={theme.metrics.iconSizes.xxxs}
                                                style={styles.chevronStyle}
                                            />
                                        </View>
                                    </Link>
                                </Text>
                            </View>
                        )}
                    </>
                )}
            </View>
            {!bankData?.status?.includes(BankStatusTypesEnum.DEMANDE_EN_COURS) && (
                <TouchableOpacity
                    onPress={() => {
                        onSelectPlanId(planId)
                        if (planFamily === PlanFamillyList.RC && RcActivateState === "Decrochage") {
                            return handleRedirect(planFamily);
                        }
                        onSetSelectedAccountType(bankData?.type ?? '');
                        handleEdit(planFamily, dispositifs, isRefund);
                    }}
                    style={styles.editButtonStyle}>
                    <FontIcon
                        color={theme?.colors.basics.primary.c500}
                        name="editer"
                        size={theme?.metrics.iconSizes.m}
                    />
                </TouchableOpacity>
            )}
        </View>
    );
}
