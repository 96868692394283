import { useScreenSizes, useTheme } from '@ere-uilib/styles';
import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

export const useStyles = () => {
  const theme = useTheme();
  const { isMobile } = useScreenSizes();

  return useMemo(() => StyleSheet.create({
    containerStyle: {

    },
    bodyContainer: {
      paddingLeft: !isMobile? theme?.metrics.spacing.xl : undefined,
    },
    title: {
      marginVertical: theme?.metrics.spacing.xm,
    },
    dialOnceHelpButton: {
      marginTop: theme?.metrics.spacing.s,
      width: theme?.metrics.contentSizes.dialOnceButton,
      alignSelf: isMobile? 'center' : undefined,
    },
    stepperContainer: {
        marginVertical: theme?.metrics.spacing.xm,
    },
    blockedPageContainer: {
      width: '100%',
      maxWidth: theme?.metrics.contentSizes.centeredContentMaxWidth,
      alignSelf: 'center',
    },
    buttonContainer: {
      marginTop: theme?.metrics.spacing.xxl,
      paddingHorizontal: isMobile? theme?.metrics.spacing.s : undefined,
      flexDirection: !isMobile? 'row' : undefined,
    },
    loader: {
      flex: 1,
      marginLeft: theme?.metrics.spacing.xm
    },
    loaderContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      paddingVertical: theme?.metrics.spacing.xs,
    },
    dashboardWrapper: {
      backgroundColor: theme?.colors.basics.white
    },
    notification: {
      alignSelf: 'flex-start',
      flex: isMobile? 1 : undefined,
    },
    notificationLabel: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },

    dialonceStyle: {
      alignSelf: 'flex-start',
      maxWidth: 335,
      width: '100%',
      marginTop: theme?.metrics.spacing.l,
    },
    titleStyle: {
      marginBottom: theme?.metrics.spacing.xs,
    },
    descriptionStyle: {
      marginBottom: theme?.metrics.spacing.xxl,
    },
  }), [isMobile, theme]);
};