import { Context, Styles } from './interfaces';

export const getStyles = (
  context: Context,
  style: Styles
): Styles => ({
  style: [
    {
      height: 15,
      borderRadius: context?.theme.metrics.borderRadius.m,
      overflow: 'hidden'
    },
    style?.style
  ]
});
