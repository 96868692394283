import React, { PropsWithChildren } from 'react';
import { View, Image, StyleProp, ViewStyle, ImageStyle } from 'react-native';

import { createUseStyles, useTheme, ThemeType, useScreenSizes } from '@ere-uilib/styles';

interface Props extends WebCardStyles {
  header?: boolean;

  width?: number;
  height?: number;

  fullHeight?: boolean;
  roundedBorder?: boolean;

  mobileCardStyle?: StyleProp<ViewStyle>;
  mobileHeaderImageStyle?: StyleProp<ImageStyle>;
}

type WebCardStyles = {
  webCardStyle?: StyleProp<ViewStyle>;
  mobileCardStyle?: StyleProp<ViewStyle>;
  mobileHeaderImageStyle?: StyleProp<ImageStyle>;
};

type StylesContext = {theme: ThemeType; width: number; height: number; fullHeight: boolean; roundedBorder: boolean; };

export const WebCard: React.FC<PropsWithChildren<Props>> = ({
  children,
  header,
  mobileHeaderImageStyle,
  mobileCardStyle,
  width = 1000,
  height = 700,
  fullHeight = false,
  roundedBorder = false
}) => {
  const theme = useTheme();
  const { isMobile, isTablet } = useScreenSizes();
  const styles = useStyles(
    { theme, height: height, width: width, fullHeight: fullHeight, roundedBorder: roundedBorder },
    { mobileCardStyle, mobileHeaderImageStyle },
  );

  const backgroundImage =
    require('@assets/icons/background-authentication.svg').default;

  return !(isMobile || isTablet) ? (
    <View style={styles.webCardStyle}>{children}</View>
  ) : (
    <View style={styles.mobileCardStyle}>
      {header ? (
        <Image
          source={backgroundImage}
          style={styles.mobileHeaderImageStyle} />
      ) : null}
      {children}
    </View>
  );
};

const getStyles = (
  context?: StylesContext,
  style?: WebCardStyles,
): WebCardStyles => ({
  webCardStyle: [
    {
      flexDirection: 'column',
      width: context?.width,
      marginVertical: context?.theme.metrics.spacing.xxl,
      minHeight: context?.fullHeight ? '100%' : context?.height,
      backgroundColor: context?.theme.colors.basics.white,
      shadowColor: context?.theme.colors.basics.grey.c900,
      shadowOffset: {
        width: 0,
        height: 2
      },
      shadowOpacity: 0.15,
      shadowRadius: 5,
      elevation: 7,
      borderRadius: context?.roundedBorder ? context?.theme.metrics.borderRadius.m : 0,
      alignSelf: 'center'
    },
    style?.webCardStyle
  ],
  mobileCardStyle: [{}, style?.mobileCardStyle],
  mobileHeaderImageStyle: [{}, style?.mobileHeaderImageStyle]
});

const useStyles = createUseStyles(getStyles);
