import { useIsFocused } from '@react-navigation/native'
import React, { useEffect, useCallback } from 'react'
import { View } from 'react-native'

import {
  DialOncePressable,
  HelpButton,
  OperationsNotificationErrorsWrapperList,
  useScreenSizes
} from '@components/index'
import {
  useTranslation,
  useTheme,
  DashboardPageWrapperConnected,
  ColumnsContainer,
  SimpleButton,
  LoaderPlaceholderView
} from '@components/index'
import { RouteNames } from '@constants/navigation'
import { CircleLoader } from '@ere-uilib/atoms/loaders/Circle/Circle'
import { createUseStyles } from '@ere-uilib/styles'
import { DispositifState } from '@modules/common/types'
import { constructStepperItems } from '@pages/Common/utils'

import { RefundsHeader } from '../components'
import { RefundsStepper, RefundsNotificationHard } from './Components'
import { RefundOnboardingPageProps } from './interfaces'
import { getStyles } from './styles'
import { locator } from '@constants/locator'

export const RefundOnboardingPageComponent: React.FC<React.PropsWithChildren<RefundOnboardingPageProps>> = ({
  isLoading,
  decision,
  applicationSettings,
  getRefundDecisionData,
  navigation
}) => {
  const isFocused = useIsFocused()
  const { formatMessage, getMessageRaw } = useTranslation()
  const theme = useTheme()
  const { isMobile, isTablet } = useScreenSizes()
  const style = useStyles(
    {
      theme,
      isMobile: isMobile || isTablet
    },
    {}
  )

  const blockedDispositifs: DispositifState[] = decision.dispositifs?.filter(
    (dispositif: DispositifState) =>
      !dispositif.filterProperties.RepaymentAvailable &&
      !dispositif.filterProperties.RepaymentUnavailable
  )
  const availableDispositifs: DispositifState[] = decision.dispositifs?.filter(
    (dispositif: DispositifState) =>
      dispositif.filterProperties.RepaymentAvailable ||
      dispositif.filterProperties.RepaymentUnavailable
  )

  const isAllDispositifsBlocked = availableDispositifs?.length === 0
  const isAtLeastOneDispotifEligible = availableDispositifs?.length > 0

  useEffect(() => {
    if (isFocused) {
      getRefundDecisionData()
    }
  }, [isFocused])

  const renderLoader = (steps: any) =>
    steps.map((item: any) => (
      <View style={style.loaderContainer}>
        <CircleLoader diameter={36} />
        <LoaderPlaceholderView style={style.loader} />
      </View>
    ))

  const RenderHeader = () => {
    return (
      <RefundsHeader
        displayCloseButton
        helpPoint={true}
        helpPointContentHtml={getMessageRaw({
          id: 'Remboursement_Page_recap_infocontent'
        })}
        helpPointModalTitle={formatMessage({
          id: 'Remboursement_Page_recap_infotitle'
        })}
        helpPointPosition="left"
        title={formatMessage({ id: 'Remboursement_TitreParcours' })}
      />
    )
  }

  const handlePress = () => {
    navigation.navigate(RouteNames.RefundsStack, {
      screen: RouteNames.RefundsRequirementDefiniton
    })
  }

  const renderSubmitButton = () => {
    return (
      <View style={style.buttonContainer}>
        <SimpleButton
          disabled={isLoading}
          onPress={handlePress}
          title={formatMessage({ id: 'Remboursement_boutonCestParti' })}
          testId={locator._recover_savings._lets_go}
        />
      </View>
    )
  }

  const messageIds = [
    'Remboursment_etape1',
    'Remboursment_etape2',
    'Remboursment_etape3',
    'Remboursment_etape4',
    'Remboursment_etape5'
  ]

  const stepperItems = constructStepperItems({
    size: 5,
    formatMessage,
    messageIds
  })

  const renderDispositifError = useCallback(() => {
    if (isAtLeastOneDispotifEligible && (!blockedDispositifs || blockedDispositifs?.length === 0)) {
      return <></>
    }
    return (
      <OperationsNotificationErrorsWrapperList
        cardTitleLabelCode={'Remboursement_pageErreur_cardAstuce_titre'}
        containerStyle={isAllDispositifsBlocked && style.blockedPageContainer}
        dispositifs={blockedDispositifs}
        isExpandable={!isAllDispositifsBlocked}
        subtitle={formatMessage({ id: 'Remboursement_pageErreur_description' })}
        title={formatMessage({
          id: 'Remboursement_pageErreur_titre'
        })}>
        <DialOncePressable
          integrationKey={applicationSettings?.HelpDialOnceIntegrationKey || ''}
          targetedFlowId={applicationSettings?.HelpActionMenuDialOnceId || ''}>
          <HelpButton
            containerStyle={style.dialOnceHelpButton}
            onPress={() => {}}
            title={formatMessage({ id: 'session_security_help_contact_button' })}
          />
        </DialOncePressable>
      </OperationsNotificationErrorsWrapperList>
    )
  }, [
    applicationSettings,
    blockedDispositifs,
    formatMessage,
    isAllDispositifsBlocked,
    isAtLeastOneDispotifEligible,
    style
  ])

  const RenderNotificationHard = useCallback(() => {
    return (
      <>
        <RefundsNotificationHard />
        {!isLoading && renderDispositifError()}
      </>
    )
  }, [isLoading, renderDispositifError])

  return (
    <DashboardPageWrapperConnected
      cardContentStyle={style.dashboardWrapper}
      renderStickyMobileBottom={!isAllDispositifsBlocked ? renderSubmitButton : undefined}>
      <ColumnsContainer
        hasRenderRightDesktopColumn={!isAllDispositifsBlocked}
        renderHeader={RenderHeader}
        renderRightDesktopColumn={RenderNotificationHard}>
        {isLoading ? (
          renderLoader(stepperItems)
        ) : isAllDispositifsBlocked ? (
          renderDispositifError()
        ) : (
          <RefundsStepper
            renderSubmitButton={renderSubmitButton()}
            stepperItems={stepperItems}>
            {(isMobile || isTablet) && renderDispositifError()}
          </RefundsStepper>
        )}
      </ColumnsContainer>
    </DashboardPageWrapperConnected>
  )
}

const useStyles = createUseStyles(getStyles)
