import React from 'react';

import {
  WebView,
  isInWebView,
} from '@components/index';
import { createUseStyles } from '@ere-uilib/styles';
import { LOCATION_URL } from '@constants/location';

import { EndOidcSessionWebViewProps } from './interface';
import { getStyles } from './styles';

export const EndOidcSessionWebView: React.FC<React.PropsWithChildren<EndOidcSessionWebViewProps>> = ({
  user,
  setIsOidcSessionEnded,
  isOidcSessionEnded,
}) => {

  const styles = useStyles({}, {},);

  const isUserLoggedIn = !!(user.access_token && user.access_token !== '');
  const isInIFrame: boolean = isInWebView();

  // manage oidc end session
  const shouldTryEndSession = !isInIFrame && !isOidcSessionEnded && isUserLoggedIn;
  const onEndSessionWebWiewMessage = (message: string) => {
    if (message === 'oidc-session-ended') {
      setIsOidcSessionEnded(true);
    }
  };
  if (!shouldTryEndSession) { return null; }

  return (
    <WebView
      onMessage={onEndSessionWebWiewMessage}
      scrollEnabled
      sourceUri={{ uri: `${LOCATION_URL}/oidc/endsession` }}
      style={styles.oidcEndSessionWebView} />
  );
};

const useStyles = createUseStyles(getStyles);
