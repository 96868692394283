import React, { useCallback, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';

import { Text }  from '@ere-uilib/atoms';
import { IconEnum } from '@ere-uilib/enums';
import { RoundedIcon } from '@ere-uilib/molecules';
import {
  Table,
  TableCellInterface,
  TableCellTypeEnum
} from '@ere-uilib/molecules/containers/Table';
import { useTheme } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';

import { Props } from './interfaces';

export function FundEligibility({ plans }: Props) {
  const theme = useTheme();
  const styles = useMemo(() => StyleSheet.create({
    headerContribution: {
      flexDirection: 'row',
      alignItems: 'center'
    },
    contributionIconContainer: {
      alignItems: 'center'
    },
    contributionIcon: {
      marginRight: theme.metrics.spacing.s
    }
  }), [theme]);

  const { formatMessage } = useTranslation();
  const tabHeader: TableCellInterface[][] = [[
    {
      type: TableCellTypeEnum.DASHED
    },
    {
      type: TableCellTypeEnum.DASHED,
      children: (
        <View style={styles.headerContribution}>
          <RoundedIcon
            containerBackground={theme.colors.basics.grey.c100}
            containerSize={25}
            containerStyle={styles.contributionIcon}
            iconColor={
              theme.colors.buttons.classic.c500
            }
            iconName={IconEnum.CONTRIBUTION}
            iconSize={theme.metrics.spacing.m}
          />
          <Text
            variant="t3">
            {formatMessage({ id: 'Fundsheet_Tab1_contributed_fund_title' })}
          </Text>
        </View>
      )
    },
    {
      type: TableCellTypeEnum.DASHED,
      text: formatMessage({ id: 'Fundsheet_Tab1_authorized_contribution_type_title' }),
      children: (
        <Text
          variant="t3">
          {formatMessage({ id: 'Fundsheet_Tab1_authorized_contribution_type_title' })}
        </Text>
      )
    }
  ]];

  const renderPossibleVersement = useCallback((
    hasVersement: boolean,
    hasInteressement: boolean,
    hasParticipation: boolean,
  ) => {
    if (!(hasVersement || hasInteressement || hasParticipation)) {
      return <Text weight="light" >-</Text>;
    }

    return (
      <>
        {hasVersement && (
          <Text weight="light" >{
            formatMessage({ id: 'Fundsheet_Tab1_Versement_Volontaire_fund_label' })
          }{hasInteressement && ','}</Text>
        )}

        {hasInteressement && (
          <Text weight="light" >{
            formatMessage({ id: 'Fundsheet_Tab1_Interessement_fund_label' })
          }{hasParticipation && ','}</Text>
        )}

        {hasParticipation && (
          <Text weight="light" >{
            formatMessage({ id: 'Fundsheet_Tab1_Participation_fund_label' })
          }</Text>)
        }
      </>
    );
  }, [formatMessage]);

  const renderContributionChildren = useCallback(hasContribution => {
    if (!hasContribution) {
      return null;
    }

    return (
      <RoundedIcon
        containerBackground={theme.colors.basics.grey.c100}
        containerSize={25}
        iconColor={
          theme.colors.buttons.classic.c500
        }
        iconName={IconEnum.CONTRIBUTION}
        iconSize={theme.metrics.spacing.m}
      />
    );
  }, [theme]);

  const planData = (plans.map(plan => ([
    {
      type: TableCellTypeEnum.DASHED,
      text: plan.planName
    },
    {
      type: TableCellTypeEnum.DASHED,
      containerStyle: styles.contributionIconContainer,
      children: renderContributionChildren(plan.hasContribution)
    },
    {
      type: TableCellTypeEnum.DASHED,
      children: renderPossibleVersement(
        plan.hasVersement,
        plan.hasInteressement,
        plan.hasParticipation,
      )
    }
  ])));

  const data = tabHeader.concat(planData);

  return <Table data={data} />;
}
