import { AnnuityErrorState, FetchAnnuityResultsData, FetchAnnuitySimulationData } from '../types';

export enum AnnuityActionsType {
  SET_ANNUITY_SIMULATION_DATA_REQUEST = 'SET_ANNUITY_SIMULATION_DATA_REQUEST',
  SET_ANNUITY_SIMULATION_DATA_SUCCESS = 'SET_ANNUITY_SIMULATION_DATA_SUCCESS',
  SET_ANNUITY_SIMULATION_DATA_FAILURE = 'SET_ANNUITY_SIMULATION_DATA_FAILURE',
  ANNUITY_INIT_ERROR = 'ANNUITY_INIT_ERROR'
}

// Set Simulation Data
export interface SetAnnuitySimulationDataRequestAction {
  type: AnnuityActionsType.SET_ANNUITY_SIMULATION_DATA_REQUEST;
  simulationData: FetchAnnuitySimulationData;
}

export interface SetAnnuitySimulationDataSuccessAction {
  type: AnnuityActionsType.SET_ANNUITY_SIMULATION_DATA_SUCCESS;
  resultsData: FetchAnnuityResultsData;
}

export interface SetAnnuitySimulationDataFailureAction {
  type: AnnuityActionsType.SET_ANNUITY_SIMULATION_DATA_FAILURE;
  error: AnnuityErrorState;
}

// InitProfile Error
export interface AnnuityInitErrorAction {
  type: AnnuityActionsType.ANNUITY_INIT_ERROR;
}

export type AnnuityAction =
  | SetAnnuitySimulationDataRequestAction
  | SetAnnuitySimulationDataSuccessAction
  | SetAnnuitySimulationDataFailureAction
  | AnnuityInitErrorAction;
