import { OtpRequestTypeEnum, OtpChanelTypeEnum } from '@ere-uilib/enums';

export interface ProfileUserInfosState {
  mail: string | null
  phone: string | null
}

export interface OtpParametersState {
  requestType?: OtpRequestTypeEnum
  chanelType?: OtpChanelTypeEnum
  chanelLabelMail?: string
  chanelLabelSms?: string
  otpIdentifierMail?: string
  otpIdentifierSms?: string
  outputActionType?: string
  outputActionParameters?: object
}

export enum FileExtensions {
  JPEG = 'image/jpeg',
  JPG = 'image/jpg',
  PDF = 'application/pdf'
}