import { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { Border } from 'victory-native';

import { useScreenSizes, useTheme } from '@ere-uilib/styles';
import { borderRadius, fontSize } from '@ere-uilib/styles/theme/themeFolder';

export function useStyles() {
  const theme = useTheme();
  const { isDesktop } = useScreenSizes();

  const styles = useMemo(
    () =>
      StyleSheet.create({
        triangle: {
          width: 0,
          height: 0,
          backgroundColor: 'transparent',
          borderTopWidth: 10,
          borderRightWidth: 0,
          borderBottomWidth: 10,
          borderLeftWidth: 10,
          borderTopColor: 'transparent',
          borderRightColor: 'transparent',
          borderBottomColor: 'transparent',
          borderLeftColor: theme.colors.basics.white,
          borderStyle: 'solid',
          position: 'absolute',
          zIndex: 99
        },
        container: {
          flexDirection: 'row',
          width: '100%',
          minWidth: 320,
          justifyContent: 'center',
          alignItems: 'center'
        },
        leftContainer: {
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          height: 50,
          paddingHorizontal: theme.metrics.spacing.m,
          backgroundColor: theme.colors.basics.white,
          borderTopLeftRadius: 25,
          borderBottomLeftRadius: 25,
          borderColor: theme.colors.text.link.c500,
          borderStyle: 'solid',
          borderWidth: 2
        },
        rightContainer: {
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          height: 50,
          paddingHorizontal: theme.metrics.spacing.m,
          backgroundColor: theme.colors.text.link.c500,
          borderTopRightRadius: 25,
          borderBottomRightRadius: 25,
          borderColor: theme.colors.text.link.c500,
          borderStyle: 'solid',
          borderWidth: 2
        },
        phoneText: {
          color: theme.colors.text.link.c500
        },
        descriptionContainer: {
          color: theme.colors.basics.white,
          fontSize: isDesktop
            ? theme.fonts.fontSize.paragraph.t3
            : theme.fonts.fontSize.paragraph.t4
        }
      }),
    [theme, isDesktop]
  );

  return styles;
}
