import { connect } from 'react-redux';

import { getContributionByOperation } from '@modules/dashboard/selectors';
import {
  getInstallmentRepartitionDataRequest,
  getInstallmentPaymentModeAndBankAccountDetailRequest,
  setInstallmentPaymentModeChoice,
} from '@modules/installment/actions/installmentActions';
import {
  getInstallmentRepartitionData,
  getInstallmentPaymentMode,
  getInstallmentPaymentModeData,
  getInstallmentDecisionAllResponses,
  getCalculatedContributionError,
  getInstallmentEditedVVPDtata,
  getInstallmentDecision,
  getCalculatedContribution,
} from '@modules/installment/selectors';
import { AppState } from '@modules/reducers';

import InstallmentRepartitionPageComponent from './InstallmentRepartitionPage.component';

const mapStateToProps = (state: AppState) => {
  const isEditMode = !!getInstallmentEditedVVPDtata(state);
  return {
    installmentRepartitionData: getInstallmentRepartitionData(state),
    installmentPaymentMode: getInstallmentPaymentMode(state),
    installmentPaymentModeData: getInstallmentPaymentModeData(state),
    installmentDecisionAllResponses: getInstallmentDecisionAllResponses(state),
    calculatedContributionError: getCalculatedContributionError(state),
    installmentDecision: getInstallmentDecision(state),
    isEditMode,
    contributionByOperations: getContributionByOperation(state),
    calculatedContribution: getCalculatedContribution(state)
  };
};

const mapDispatchToProps = ({
  onGetInstallmentRepartitionDataRequest: getInstallmentRepartitionDataRequest,
  onGetInstallmentPaymentModeAndBankAccountDetailRequest: getInstallmentPaymentModeAndBankAccountDetailRequest,
  onSetInstallmentPaymentModeChoice: setInstallmentPaymentModeChoice,
});

export const InstallmentRepartitionPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(InstallmentRepartitionPageComponent);

