import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

export const useStyles = () => {
  const theme = useTheme();
  const styles = useMemo(
    () =>
      StyleSheet.create({
        tableLoader: {
          justifyContent: 'center',
          height: 115,
          backgroundColor: theme.colors.basics.grey.c100,
          borderColor: theme.colors.basics.grey.c200,
          borderWidth: 1,
          borderRadius: 5,
          paddingHorizontal: theme.metrics.spacing.l
        },
        loaderPlaceholderView: {
          marginVertical: theme.metrics.spacing.s
        },
        loaderPlaceholderViewHalfLength: {
          width: '50%'
        }
      }),
    [theme]
  );

  return styles;
};
