import {
  getContactPreferencesSuccessOptinGroupEmail,
  getContactPreferencesSuccessOptinPartnerEmail
} from '@modules/profile/selectors';
import {ProfileActionsType} from '@modules/profile/actions/profileActionsTypes';
import {AppState} from '@modules/reducers';
import {
  getContactPreferencesOptinRequest,
  setContactPreferencesOptinRequest,
  setContactPreferencesOptinSuccess
} from '@modules/profile/actions/profileActions';
import {connect} from 'react-redux';
import {ContactPreferencesPageComponent} from './BusinessContactPreferencesPage.component';



const mapStateToProps = (state: AppState) => {
  return {
    acceptGroupEmail: getContactPreferencesSuccessOptinGroupEmail(state),
    acceptPartnerEmail: getContactPreferencesSuccessOptinPartnerEmail(state),
    isLoading: state.isLoading[ProfileActionsType.SET_CONTACT_PREFERENCES_OPTIN_REQUEST]
  };
};

const mapDispatchToProps = ({
  onSubmit: setContactPreferencesOptinRequest,
  onPressButtonSuccessModal: setContactPreferencesOptinSuccess,
  getContactPreferencesRequest: getContactPreferencesOptinRequest,
});

export const BusinessContactPreferencesPage = connect(mapStateToProps, mapDispatchToProps)(ContactPreferencesPageComponent);
