import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ArbitrationTypes } from '@constants/arbitrations';
import { useTranslation } from '@ere-uilib/translations';
import { getReallocationType } from '@modules/arbitration/selectors';

export const useLabels = () => {
  const { formatMessage, getMessageRaw, formatPercentNumber } = useTranslation();
  const reallocationType = useSelector(getReallocationType);
  const isFlow = reallocationType === ArbitrationTypes.flow;
  
  const labels = useMemo(() => ({
    helpContent: getMessageRaw({
      id: (isFlow && 'FlowReallocationsEnterTransactionDescription') ||
        'Arbitrage_choixDispositifsAide_description'
    }),
    helpTitle: formatMessage({
      id: (isFlow && 'FlowReallocationsEnterTransactionTitle') ||
        'Arbitrage_choixDispositifsAide_titre'
    }),
    pageTitle: formatMessage({ id: 'Arbitrage_etape2' }),
    reinvestTotal: formatMessage({
      id:
        (isFlow && 'FlowReallocationsModalPercentageToBePlaced') ||
        'Arbitrage_reinvest_resteReinvest'
    }),
    deinvestTitle: formatMessage({
      id: (isFlow && 'FlowReallocationsCurrentInvestment') ||
        'Arbitrage_reinvest_desinvestSubtitle'
    }),
    reinvestTitle: formatMessage({
      id: (isFlow && 'FlowReallocationsFutureInvestment') ||
        'Arbitrage_reinvest_resinvestSubtitle'
    }),
    getReinvestDescription: (value: number) =>
      `${formatMessage({
        id: (isFlow && 'FlowReallocationsPopInWordingInvested') ||
          'Arbitrage_reinvest_resteReinvest'
      })} ${formatPercentNumber({ value })}`,
    compareBtn: formatMessage({
      id: 'MyFunds_Screen1Compare_Header_Title',
    }),
    reinvestRemaining: formatMessage({ id: 'Arbitrage_modaleDesinvest_ToutReinvest' }),
    reinvestAll: formatMessage({
      id: (isFlow && 'FlowReallocationsPopInWordingReinvest') ||
        'Arbitrage_reinvest_lienreinvest'
    }),
    nextBtn: formatMessage({
      id: (isFlow && 'FlowReallocationsValidationButton') ||
        'Arbitrage_reinvest_boutonValider'
    }),
    managementTitle: formatMessage({
      id: (isFlow && 'FlowReallocationsFutureAllocation') ||
        'Arbitrage_etape2_reinvest_tout_une_partie',
    })
  }), [formatMessage, formatPercentNumber, getMessageRaw, isFlow]);

  return labels;
};
