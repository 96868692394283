import React, { useEffect } from 'react';

import {
  Spinner,
  useTranslation,
  OfflineAnnounceLayout360,
  sendMessageFromWebView,
} from '@components/index';

export const EndOidcSessionCallbackComponent = () => {
  const { formatMessage } = useTranslation();

  useEffect(() => {
    sendMessageFromWebView('oidc-session-ended');
  }, []);

  return (

    <OfflineAnnounceLayout360
      description={'end'}
      sourceName="logout"
      title={formatMessage({ id: 'logout_transition_title' })}
    >
      <Spinner />
    </OfflineAnnounceLayout360>
  );
};
