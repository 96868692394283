import { useScreenSizes, useTheme } from '@ere-uilib/styles';
import { useMemo } from 'react';
import { StyleSheet } from 'react-native';


export function useStyles() {
  const { isDesktop, isMobile, isTablet } = useScreenSizes();
  const theme = useTheme();
  const styles = useMemo(
    () =>
      StyleSheet.create({
        modalTitleStyle: {
          backgroundColor: theme?.colors.basics.white
        },
        buttonContainer: {
          flex: 1,
          flexDirection: 'row',
          alignItems: 'center',
          paddingTop: theme.metrics.spacing.l
        },
        scrollViewContainer: {
          paddingBottom: theme.metrics.spacing.l,
        },
        categoriesCardStyle: {
          flex: 1,
          flexDirection: "row",
          flexWrap: "wrap",
          width: "100%",
          marginTop: theme.metrics.spacing.m,
          justifyContent: 'space-between'
        },
        cardStyle: {
          width: '47%',
          marginRight: theme.metrics.spacing.s,
          marginBottom: theme.metrics.spacing.m
        },
        titleStyle: {
          textAlign: 'left',
          marginRight: 'auto'
        },
        bottomActionsStyle:
        {
          ...isMobile ? {
            marginRight: 0,
          } : {
            marginRight: 'auto',
          },

        },
        bottomActionsButtonsContainerStyle: {
          flex: 1,
          alignContent: 'space-between',
          width: '100%',
          flexDirection: 'row',
        },
        simpleButtonStyle: {
          flex: 1,
          padding: theme.metrics.spacing.s,
          minWidth: 130,
          marginRight: theme.metrics.spacing.s,
        },
        simpleButtonLeftStyle: {
          ...isMobile ? {
            width: '48%',
          } : {
            maxWidth: 130,
          },
          marginRight: theme.metrics.spacing.s,
        },
        simpleButtonRightStyle: {
          ...isMobile ? {
            width: '48%',
          } : {
            maxWidth: 130,
          },
        },
        scrollview: {
          height: '80%'
        },
      }),
    [isDesktop, isMobile, isTablet]
  );

  return styles;
}
