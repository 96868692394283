import { connect } from 'react-redux';

import { getArbitrationAnswerData, getSelectedDispositif } from '@modules/arbitration/selectors';
import { AppState } from '@modules/reducers';
import { ClearCacheAfterOperationDone } from '@modules/savings/actions/savingsActions';

import { ArbitrationSuccessPageComponent } from './ArbitrationSuccessPage.component';

const mapStateToProps = (state: AppState) => {
  return {
    selectedDispositif: getSelectedDispositif(state),
    arbitrationAnswerData: getArbitrationAnswerData(state),
  };
};

const mapDispatchToProps = ({
  onClearCacheAfterOperationDone: ClearCacheAfterOperationDone,
});

export const ArbitrationSuccessPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(ArbitrationSuccessPageComponent);
