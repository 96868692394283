import { useNavigation } from "@react-navigation/native"
import React, { useCallback } from "react"
import { View } from "react-native"

import { Modal, NotificationIcon, NotificationIconTypeEnum, Title } from "@ere-uilib/atoms"
import { ModalFrame, SimpleButton } from "@ere-uilib/molecules"

import { useModalData } from "./hooks"
import { ConfirmModalComponentProps } from "./interface"
import { useStylees } from "./styles"

const ConfirmModalComponent = ({ isPage, closeModalAction, goBackAction, modalVisible }: ConfirmModalComponentProps) => {
  const styles = useStylees()
  const navigation = useNavigation();
  const { cancelButtonLabel, confirmButtonLabel, label, title, onModalClose } =
    useModalData();

  const onAbortPress = useCallback(() => {
    onModalClose()
    closeModalAction?.()
  }, [onModalClose, closeModalAction]);

  const onResumePress = useCallback(() => {
    if (isPage) {
      navigation.goBack()
    } else {
      goBackAction?.()
    }
  }, [navigation, isPage, goBackAction])

  const ModalView = isPage ? View : Modal

  return (
    <ModalView
      overlayStyle={styles.container}
      style={styles.container}
      visible={modalVisible}>
      <ModalFrame>
        <View style={styles.modalIconContainer}>
          <NotificationIcon type={NotificationIconTypeEnum.WARNING} />
        </View>
        <View style={styles.modalTextContainer}>
          <Title
            style={styles.modalTitle}
            variant="t6">
            {title}
          </Title>
          <Title
            style={styles.modalText}
            variant="t7"
            weight="light">
            {label}
          </Title>
        </View>
        <View>
          <SimpleButton
            containerStyle={styles.modalFirstActionStyle}
            design="outlined"
            onPress={onAbortPress}
            title={confirmButtonLabel}
          />
          <SimpleButton
            onPress={onResumePress}
            title={cancelButtonLabel}
          />
        </View>
      </ModalFrame>
    </ModalView>
  )

}
export default ConfirmModalComponent