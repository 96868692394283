import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useScreenSizes, useTheme } from '@ere-uilib/styles';

export const useStyles = () => {
  const theme = useTheme();
  const { isDesktop } = useScreenSizes();

  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          paddingLeft: theme.metrics.spacing.xm,
          flexDirection: 'row',
          maxWidth: '100%'
        },
        text: {
          lineHeight: theme.metrics.spacing.xm
        },
        actionContainer: {
          paddingLeft: theme.metrics.spacing.s,
          textAlignVertical: 'center'
        },
        childrenContainer: {
          ...(isDesktop && {
            maxWidth: theme.metrics.contentSizes.leftSideContentMaxWidth
          }),
          paddingLeft: theme.metrics.spacing.xxm
        }
      }),
    [theme, isDesktop]
  );

  return styles;
};
