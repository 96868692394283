import { useMemo } from 'react';

import { useTheme } from '@ere-uilib/styles';

export const useStyles = () => {
  const theme = useTheme();

  const styles = useMemo(
    () => ({
      contributionTable: {
        marginTop: theme.metrics.spacing.xxm
      },
      bottomSectionContainer: {
        marginTop: theme.metrics.spacing.xxm
      },
      planCardContainer: {
        borderColor: theme.colors.basics.grey.c200,
        borderWidth: 1,
        borderTopWidth: 0,
        paddingVertical: theme.metrics.spacing.m,
        paddingHorizontal: theme.metrics.spacing.s,

      },
      amountContainerStyle: {
        flex: 1,
        flexDirection: 'row'
      },
      arrowIconStyle: {
        height: 10,
        width: 12,
        marginTop: theme.metrics.spacing.xxs
      },
      textPaymentStyle: {
        marginLeft: theme.metrics.spacing.s
      },
      textSeparator: {
        paddingVertical: theme.metrics.spacing.m
      },
      descriptionStyle: {
        paddingTop: theme.metrics.spacing.s
      },
      subtitleStyle: {
        paddingTop: theme.metrics.spacing.xs
      },
      notificationCardStyle: {
        marginTop: theme.metrics.spacing.xxm,
        paddingTop: theme.metrics.spacing.m,
        paddingHorizontal: theme.metrics.spacing.s,
        marginHorizontal: theme.metrics.spacing.xxs,
        shadowColor: theme.colors.basics.black,
        shadowOffset: {
          width: 0,
          height: 0
        },
        shadowOpacity: 0.15,
        shadowRadius: 10,
        elevation: 7,
      },
      notificationTitleStyle: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
      },
      contributionCardStyle: {
        fontFamily: theme.fonts.fontFamily.light
      }
    }),
    [theme]
  );

  return styles;
};
