import { useCallback, useMemo } from "react"

import { ThresholdCoursesEnum, ThresholdTriggerTypesEnum } from "@constants/arbitrations"

import { ThresholdModalControllerProps } from "./interface";

export const useController = ({
  thresholdTriggerType,
  thresholdTriggerTitle,
  title,
  course,
  amountNumber,
  onClose,
  onValid
}: ThresholdModalControllerProps) => {

  const isArbitrationCourse = useMemo(() => course === ThresholdCoursesEnum.arbitration, [course]);

  const thresholdTitle = useMemo(() => {
    if (thresholdTriggerType === ThresholdTriggerTypesEnum.VCP) {
      return thresholdTriggerTitle
    } else {
      return title
    }

  }, [thresholdTriggerTitle, thresholdTriggerType, title])

  const dictionnary = useMemo(() => {
    const suffix = isArbitrationCourse ? 'Arbitrage' : 'Refund';

    return {
      thresholdRefundVcpContent: { id: 'OutbreakThresholdRefundVCPPageContent', values: { libelle_sous_jacent: thresholdTitle } },
      thresholdRefundVppContent: { id: 'OutbreakThresholdRefundVPPPageContent' },
      thresholdArbitrageDescription: { id: 'OutbreakThresholdArbitrageDescriptionInput', values: { libelle_sous_jacent: thresholdTitle } },
      remboursementSeuilDeclenchSubtitle: 'Remboursement_seuilDeclench_subtitle',
      outbreakThresholdArbitrageInputDescription: `OutbreakThreshold${suffix}InputAmountDescription`,
      remboursementSeuilDeclenchInfoFonds: `OutbreakThreshold${suffix}InputFundDescription`,
      remboursementSeuilDeclenchCardinfo: isArbitrationCourse ?
        'ArbitrageSeuilDeclenchSaisieCardDescription' :
        'Remboursement_seuilDeclench_cardinfo',
      remboursementSeuilDeclenchAnnuler: 'Remboursement_seuilDeclench_annuler',
      remboursementSeuilDeclenchValider: 'Remboursement_seuilDeclench_valider',
      exampleExpandable: {
        title: `OutbreakThreshold${suffix}ExampleTitle`,
        url: 'Remboursement_seuilDeclenchExempleImage_lien',
        description: `OutbreakThreshold${suffix}ExampleText`

      },
      emptyLabel: { id: '' }
    }
  }, [thresholdTitle, isArbitrationCourse])

  const aribrationParagraphText = useMemo(() => {
    if (thresholdTriggerType === ThresholdTriggerTypesEnum.VCP) {
      return dictionnary.thresholdArbitrageDescription;
    } else {
      return dictionnary.emptyLabel;
    }
  }, [dictionnary.emptyLabel, dictionnary.thresholdArbitrageDescription, thresholdTriggerType])

  const refundParagraphText = useMemo(() => {
    switch (thresholdTriggerType) {
      case ThresholdTriggerTypesEnum.VCP:
        return dictionnary.thresholdRefundVcpContent;
      case ThresholdTriggerTypesEnum.VPP:
        return dictionnary.thresholdRefundVppContent;
      default:
        return dictionnary.emptyLabel;
    }
  }, [dictionnary, thresholdTriggerType])

  const handleSubmitButtonPress = useCallback(() => {
    onValid && amountNumber && onValid(amountNumber);
    onClose?.();
  }, [amountNumber, onClose, onValid]);

  return { dictionnary, aribrationParagraphText, refundParagraphText, isArbitrationCourse, handleSubmitButtonPress }
}