import React, { useState } from 'react';
import { View, Text } from 'react-native';

import {
  ActivableButtonSet,
  DashboardPageWrapperConnected,
  NavigationHeader,
  Title,
  Select,
} from '@components/index';
import { IconEnum } from '@ere-uilib/enums';
import { createUseStyles, useTheme, useScreenSizes } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';

import { DisponibilityTermPageProps as Props } from './interfaces';
import { getStyles } from './styles';

export const DisponibilityTermComponent: React.FC<React.PropsWithChildren<Props>> = ({
  navigation,
}: Props) => {
  const theme = useTheme();
  const { isMobile, isTablet } = useScreenSizes();
  const styles = useStyles({ theme, isMobile, isTablet }, {});

  const { formatMessage, getMessageRaw } = useTranslation();

  const activableButtonList = [
    {
      value: 1,
      label: formatMessage({ id: 'AideDeblocage_estimate_estimate_button' }),
    },
    {
      value: 2,
      label: formatMessage({ id: 'AideDeblocage_estimate_caselist_button' }),
    },
  ];

  const selectList = [
    { value: 1, label: 'Acquisition, construction résidence principale' },
    {
      value: 2,
      label: 'Agrandissement résidence principale',
      renderRightItem: () => {
        return <Text>custom render</Text>;
      },
    },
    {
      value: 3,
      label: "Mariage ou conclusion d'un PACS",
      leftIcon: { name: IconEnum.CHECK, color: 'black', size: 15 },
      rightIcon: { name: IconEnum.CHECK, color: 'black', size: 15 },
    },
    {
      value: 4,
      label:
        "Naissance ou adoption d'un 3ème enfant puis de chaque enfant suivant",
      rightIcon: { name: IconEnum.CHECK, color: 'black', size: 15 },
    },
    {
      value: 5,
      label: 'Cessation du contrat de travail',
      renderLeftItem: () => {
        return <Text style={{ marginRight: 10 }}>custom render</Text>;
      },
    },
  ];

  const [activableButtonValue, setActivableButtonValue] = useState(0);
  const [selectValue, setSelectValue] = useState(undefined);
  const [buttonSelectedValue, setButtonSelectedValue] = useState<number | undefined>(undefined);
  const [hovered, setHovered] = useState<boolean>(false);
  const [hoveredIndex, setHoveredIndex] = useState<number>();


  const handleHovered = (value: boolean, index: number) => {
    setHovered(value)
    setHoveredIndex(index)
  }


  const renderEstimateChoiceContent = () => (
    <View style={styles.contentContainerStyle}>
      <Title
        style={styles.afterChoiceLabelStyle}
        variant="t7"
        weight="light">
        {formatMessage({ id: 'AideDeblocage_estimate_afterchoice_label' })}
      </Title>
      <Select
        defaultTitle={formatMessage({
          id: 'AideDeblocage_estimate_selectreason_label',
        })}
        items={selectList}
        onSelectItem={(item: any) => setSelectValue(item)}
        selectedGreen
        selectedItem={selectValue}
      />
    </View>
  );

  const renderEstimateCaselistContent = () => <View />;

  const renderContent = () => (
    <View style={styles.containerStyle}>
      <Title
        style={styles.choiceLabelStyle}
        variant="t7"
        weight="bold">
        {formatMessage({ id: 'AideDeblocage_estimate_choice_label' })}
      </Title>

      <View style={styles.activableButtonContainerStyle}>
        {
          activableButtonList.map((buttonsItems, i) => {
            return (
              <ActivableButtonSet
                buttonStyle={styles.ActivableButtonStyle}
                isSelected={buttonSelectedValue === buttonsItems.value}
                key={i}
                label={buttonsItems.label}
                onChange={() => {
                  setButtonSelectedValue(buttonsItems.value);
                  setActivableButtonValue(buttonsItems.value);
                }}
                hovered={i === hoveredIndex ? hovered : false}
                setHovered={(hoveredValue) => handleHovered(hoveredValue, i)}
              />
            );
          })
        }
      </View>

      {activableButtonValue === 1
        ? renderEstimateChoiceContent()
        : activableButtonValue === 2
          ? renderEstimateCaselistContent()
          : null}
    </View>
  );

  return (
    <DashboardPageWrapperConnected>
      <NavigationHeader
        displayBackButton
        helpPoint
        helpPointContentHtml={getMessageRaw({ id: 'Dashboard_CardRepartition_info_content' })}
        helpPointContentTitle={formatMessage({ id: 'Dashboard_CardRepartition_info_content_title' })}
        helpPointModalTitle={formatMessage({ id: 'fund-card_help-info_menu' })}
        helpPointPosition={'right'}
        onGoBack={() => {
          navigation?.goBack();
        }}
        title={formatMessage({ id: 'AideDeblocage_estimate_title' })}
      />
      {renderContent()}
    </DashboardPageWrapperConnected>
  );
};

const useStyles = createUseStyles(getStyles);
