import { useScreenSizes, useTheme } from '@ere-uilib/styles';
import { useMemo } from 'react';
import { StyleSheet } from 'react-native';


export function useStyles() {
  const { isDesktop, isMobile, isTablet } = useScreenSizes();
  const theme = useTheme();
  const styles = useMemo(() => StyleSheet.create({
    dashboardWrapper: {
      backgroundColor: theme?.colors.basics.white
    },
    containerStyle: {
      paddingBottom: theme.metrics.spacing.m,
      paddingTop: 0,

    },
    filesContainerStyle: {
      width: "100%",
    },
    cardStyle: {
      width: "100%",
      marginRight: theme.metrics.spacing.s,
    },
    innerContainer: {
      ...isMobile ? {
        minWidth: '100%',
        marginTop: -15
      } :
        {
          width: '100%',
          maxWidth: '80%',
          alignSelf: 'center',
        }
    },
    filterContainer: {
      borderColor: theme.colors.background.default.c500,
      borderBottomWidth: 1,
      flexDirection: 'row',
      justifyContent: 'flex-start',
      paddingBottom: theme.metrics.spacing.xm,
      ...(isMobile)
        ? {} : {
          paddingRight: theme.metrics.spacing.s,
          paddingTop: theme.metrics.spacing.xm
        },
    },
    iconButton: {
      flexDirection: 'row',
      paddingRight: theme.metrics.spacing.xs,
      paddingTop: 7,
      borderRadius: theme.metrics.borderRadius.full,
      justifyContent: isMobile ? 'flex-start' : 'center',
      alignItems: 'center',

    },
    iconButtonText: {
      color: theme.colors.basics.primary.c500,
      marginLeft: theme.metrics.spacing.s
    },
    tagContainer: {
      flexWrap: 'wrap',
      flexDirection: 'row',
      flex: 1
    }
  }), [isDesktop, isMobile, isTablet]);

  return styles;
}
