
import React, { useMemo } from 'react';

import {
  IconEnum,
  OperationsHeaderDetailsItemType,
  OperationsSubHeaderAdvantageListItemType,
  Title,
  useTranslation
} from '@components/index';
import {
  ProfitSharingIncentiveLegalFrameworkEnum,
  ProfitSharingIncentiveTypeEnum
} from '@constants/ProfitSharingIncentive';
import { OperationDetails } from '@modules/savings/types';

export const useOperationDetailsHeaderData = ({
  operationDetails,
}: {
  operationDetails: OperationDetails | undefined;
}) => {
  const {
    formatMessage,
    formatCurrencyNumber,
    formatDate
  } = useTranslation();

  const operationDetailsHeaderDetailList = useMemo(() => {
    const output: OperationsHeaderDetailsItemType[] = []
    if (!operationDetails) return output

    const {
      identifier,
      netAmount,
      feeAmount,
      source,
      brutAmount,
      socialContributionsAmount,
    } = operationDetails

    if (brutAmount) {
      output.push({
        title: formatMessage({ id: 'Header_Operation_Montant_Brut' }),
        value: formatCurrencyNumber({ value: brutAmount })
      })
    }

    if (netAmount) {
      output.push({
        title: formatMessage({ id: 'Header_Operation_Montant_Net' }),
        value: formatCurrencyNumber({ value: netAmount })
      })
    }

    if (socialContributionsAmount) {
      output.push({
        title: formatMessage({ id: 'DetailOperationPrelevementSociaux' }),
        value: formatCurrencyNumber({ value: socialContributionsAmount })
      })
    }
    if (feeAmount) {
      output.push({
        title: formatMessage({ id: 'DetailOperationFrais' }),
        value: formatCurrencyNumber({ value: feeAmount })
      })
    }

    if (source && source.bulletinOption && (source.bulletinOption.fiscalYearStartDate && source.bulletinOption.fiscalYearEndDate)) {
      output.push({
        title: formatMessage({
          id: 'PIDescriptionPourPeriodeLabel',
          values: {
            date_debut: formatDate({ value: source.bulletinOption.fiscalYearStartDate || "" }),
            date_fin: formatDate({ value: source.bulletinOption.fiscalYearEndDate || "" })
          }
        }),
      })
    }

    if (identifier) {
      output.push({
        title: formatMessage({ id: 'DetailOperationReference' }),
        value: identifier
      })
    }

    return output
  }, [operationDetails, formatMessage, formatCurrencyNumber])

  const operationDetailsHeaderAdvantageList = useMemo(() => {
    const output: OperationsSubHeaderAdvantageListItemType[] = []
    if (!operationDetails) return output

    const {
      netAmount,
      abundanceBrutAmount,
      abundanceNetAmount,
      deductible,
      deductibleAmount,
      details
    } = operationDetails;

    const { type, frame } = details?.optionsBulletin ?? {};

    const isPPV = type === ProfitSharingIncentiveTypeEnum.incentive &&
      frame === ProfitSharingIncentiveLegalFrameworkEnum.ppv;

    if (abundanceBrutAmount) {
      output.push({
        icon: IconEnum.CONTRIBUTION,
        title:
        <>
        {
          abundanceNetAmount && abundanceNetAmount > 0 &&
          <Title
          variant="t8"
          weight='light'>
          {`${formatMessage({ id: "DetailOpération_AbondementNet" })} `}
          <Title
            variant="t8"
            weight='bold'
          >{formatCurrencyNumber({ value: abundanceNetAmount })}
          </Title>
          </Title>
        }
        <Title
        variant="t8"
        weight='light'>
        {`${formatMessage({ id: "DetailOpération_AbondementBrut" })} `}
          <Title
            variant="t8"
            weight='bold'
          >
            {formatCurrencyNumber({ value: abundanceBrutAmount })}
          </Title>
        </Title>
       </>,
        description: formatMessage({ id: "DetailOperationAbondementCommLabel" }),
      })
    }

    if ((deductible && deductibleAmount) || (isPPV && netAmount)) {
      const brutFiscalityAmountText =
        (deductibleAmount && formatCurrencyNumber({ value: deductibleAmount })) || '';
      const descriptionId =
        (isPPV && 'DetailOperationPIFiscalitePPV') || 'DetailOperationMontantDeductible';

      output.push({
        icon: IconEnum.FISCALITE,
        title: `${formatMessage({ id: "DetailOperationFiscalite" })} ${brutFiscalityAmountText}`,
        description: formatMessage({ id: descriptionId })
      })
    }
    return output
  }, [operationDetails, formatMessage, formatCurrencyNumber])

  return {
    operationDetailsHeaderDetailList,
    operationDetailsHeaderAdvantageList
  }
}
