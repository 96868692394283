import * as actions from '@modules/legal/actions/legalActionsTypes';
import { LegalActionsType } from '@modules/legal/actions/legalActionsTypes';
import {
  LegalContentState,
  LegalErrorState,
  MenuFooterState
} from '@modules/legal/types';

export function getMenuFooterRequest(): actions.GetMenuFooterRequestAction {
  return {
    type: LegalActionsType.GET_MENU_FOOTER_REQUEST,
  };
}

export function getMenuFooterSuccess(
  links: MenuFooterState[]
): actions.GetMenuFooterSuccessAction {
  return {
    type: LegalActionsType.GET_MENU_FOOTER_SUCCESS,
    links,
  };
}

export function getMenuFooterFailure(
  error: LegalErrorState,
): actions.GetMenuFooterFailureAction {
  return {
    type: LegalActionsType.GET_MENU_FOOTER_FAILURE,
    error
  };
}


export function getLegalContentRequest(
  code: string,
): actions.GetLegalContentRequestAction {
  return {
    type: LegalActionsType.GET_LEGAL_CONTENT_REQUEST,
    code
  };
}

export function getLegalContentSuccess(
  content: LegalContentState,
): actions.GetLegalContentSuccessAction {
  return {
    type: LegalActionsType.GET_LEGAL_CONTENT_SUCCESS,
    content
  };
}

export function getLegalContentFailure(
  error: LegalErrorState,
): actions.GetLegalContentFailureAction {
  return {
    type: LegalActionsType.GET_LEGAL_CONTENT_FAILURE,
    error
  };
}

export function legalInitError(): actions.LegalInitErrorAction {
  return {
    type: LegalActionsType.LEGAL_INIT_ERROR
  };
}
