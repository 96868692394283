import { connect } from "react-redux";

import {
  getArbitrationQuestionsRequest,
  selectArbitrationTypeRequest,
} from "@modules/arbitration/actions/arbitrationActions";
import { ArbitrationActionsType } from "@modules/arbitration/actions/arbitrationActionsTypes";
import { getEligibleDispositifs } from "@modules/arbitration/selectors";
import { AppState } from "@modules/reducers";

import { ArbitrationChoicePageComponent } from "./ArbitrationChoicePage.component";

const mapStateToProps = (state: AppState) => ({
  isFetching: state.isLoading[ArbitrationActionsType.GET_ARBITRATION_QUESTIONS_REQUEST],
  arbitrationError: state.arbitration.error,
  eligibleDispositifs: getEligibleDispositifs(state)
});

const mapDispatchToProps = {
  getArbitrationQuestions: getArbitrationQuestionsRequest,
  selectArbitrationType: selectArbitrationTypeRequest
};

export const ArbitrationChoicePage = connect(
  mapStateToProps,
  mapDispatchToProps
)(ArbitrationChoicePageComponent);
