import { OnboardingStepperContext, OnboardingStepperStyles } from './interfaces';

export const getStyles = (
  context?: OnboardingStepperContext,
  style?: OnboardingStepperStyles,
): OnboardingStepperStyles => ({
  containerStyle: [
    !context?.isMobile && {
      paddingLeft: context?.theme?.metrics.spacing.xl,
    },
    style?.containerStyle,
  ],
  title: [
    {
      marginVertical: context?.theme?.metrics.spacing.xm,
    },
    style?.title,
  ],
  stepperContainer: [
    {
      marginVertical: context?.theme?.metrics.spacing.xm,
    },
    style?.stepperContainer,
  ],
  notificationContainer: [
    {
      marginVertical: context?.theme?.metrics.spacing.xm,
    },
    style?.notificationContainer,
  ],
});
