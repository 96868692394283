import { connect } from 'react-redux';

import {
  checkEERSecondLetterIdentityValidationCodeRequest,
  FranceConnectRegistrationRequest,
  sendEERIdentityValidationCodeRequest
} from '@modules/validate-identity/actions/validateIdentityActions';
import { ValidateIdentityActionsType } from '@modules/validate-identity/actions/validateIdentityActionsTypes';
import {
  AppState,
} from '@modules/index';
import { getInvitationSelector } from '@modules/invitation/selectors';
import { getOtpError } from '@modules/profile/selectors';
import { getIsFCFeatureEnabled } from '@modules/settings/selectors';
import { getIdentityValidationModuleError } from '@modules/validate-identity/selectors';

import { ValidationSignupPageComponent } from './ValidationSignupPage.component';

const mapStateToProps = (state: AppState) => {
  return {
    invitation: getInvitationSelector(state),
    validateIdentityError: getIdentityValidationModuleError(state),
    isLoadingLetterOTPSubmit:
      state.isLoading[
        ValidateIdentityActionsType.CHECK_EER_SECOND_LETTER_IDENTITY_VALIDATION_CODE_REQUEST
      ],
    isSendCodeApiLoading:
      state.isLoading[ValidateIdentityActionsType.SEND_EER_IDENTITY_VALIDATION_CODE_REQUEST],
    isFCRegisterApiLoading:
      state.isLoading[ValidateIdentityActionsType.FRANCE_CONNECT_CREATE_IDENTIY_VALIDATION_REQUEST],
    EnableFeatureFranceConnect: getIsFCFeatureEnabled(state)
  };
};

const mapDispatchToProps = ({
  sendEERValidationCode: sendEERIdentityValidationCodeRequest,
  onFranceConnectSignIn: FranceConnectRegistrationRequest,
  onLetterOTPSubmit: checkEERSecondLetterIdentityValidationCodeRequest,
});
export const ValidationSignupPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ValidationSignupPageComponent);
