import { DocumentsActionsType } from '@modules/document-space/actions/documentsActionsTypes';
import { connect } from 'react-redux';
import { AppState } from '@modules/reducers';
import {
  getDocumentBlobRequestByType,
} from '@modules/document-space/actions/documentsActions';
import { HtmlStyledRenderConnectedComponent } from './HtmlStyledRenderConnected.component'

const mapStateToProps = (state: AppState) => ({
  isDocumentDonwloading: state.isLoading[DocumentsActionsType.GET_DOCUMENT_BLOB_RESPONSE_REQUEST],
  error: state.error[DocumentsActionsType.GET_DOCUMENT_BLOB_RESPONSE_REQUEST],
});
const mapDispatchToProps = {
  onGetDocumentDownloadFile: getDocumentBlobRequestByType
};

export const HtmlStyledRenderConnectedContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(HtmlStyledRenderConnectedComponent);