import React, { useMemo, useState } from 'react';
import { View, StyleProp, ViewStyle } from 'react-native';

import { createUseStyles, ThemeType, useScreenSizes, useTheme } from '@ere-uilib/styles';

interface DashedLineProps {
  axis?: string
  dashGap?: number
  dashLength?: number
  dashThickness?: number
  customLength?: number
  dashColor?: string
  dashStyle?: StyleProp<ViewStyle>;
  style?: StyleProp<ViewStyle>;
}

export interface Styles {
  row?: StyleProp<ViewStyle>
  column?: StyleProp<ViewStyle>
  containerStyle?: StyleProp<ViewStyle>
}
export interface Context {
  theme?: ThemeType
  isMobile?: boolean
}

export const DashedLine = ({
  axis = 'horizontal',
  dashGap = 2,
  dashLength = 4,
  dashThickness = 2,
  dashColor = '#000',
  dashStyle,
  customLength = 0,
  style
}: DashedLineProps) => {
  const [lineLength, setLineLength] = useState(customLength);
  const isRow = axis === 'horizontal';
  const theme = useTheme();
  const { isMobile, isTablet } = useScreenSizes();
  const numOfDashes = Math.ceil(lineLength / (dashGap + dashLength));
  const styles = useStyles({
    theme,
    isMobile: isMobile || isTablet
  },
    {
      containerStyle: style
    });
  const dashStyles = useMemo(
    () => ({
      width: isRow ? dashLength : dashThickness,
      height: isRow ? dashThickness : dashLength,
      marginRight: isRow ? dashGap : 0,
      marginBottom: isRow ? 0 : dashGap,
      backgroundColor: dashColor
    }),
    [dashColor, dashGap, dashLength, dashThickness, isRow],
  );

  return (
    <View
      onLayout={event => {
        const { width, height } = event.nativeEvent.layout;
        setLineLength(isRow ? width : height);
      }}
      style={[styles.containerStyle, isRow ? styles.row : styles.column]}
    >
      {[...Array(numOfDashes)].map((_, i) => {
        return (
          <View
            key={i}
            style={[dashStyles, dashStyle]}
          />);
      })}
    </View>
  );
};

const getStyle = (
  context?: Context,
  style?: Styles
): Styles => (
  {
    containerStyle: [
      {
      },
      style?.containerStyle
    ],
    row: [
      {
        flex: 1,
        flexDirection: 'row'
      },
      style?.row
    ],
    column: [
      {
        flex: 1,
        flexDirection: 'column'
      },
      style?.column
    ]
  }
);

const useStyles = createUseStyles(getStyle);
