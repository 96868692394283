import {useTranslation} from "@ere-uilib/index";
import {useMemo} from "react";

export const useTranslate = () => {

    const { formatMessage, getMessageRaw, formatDate } = useTranslation();
    const dictionnary = useMemo(() => {
        return {
            headerHelpPointContentHtml: getMessageRaw({ id: 'CoordonneesBancairesRecapHelpDescription' }),
            headerHelpPointModalTitle: formatMessage({ id: 'CoordonneesBancairesRecapHelpTitre' }),
            headerTitle: formatMessage({ id: 'DetailOperationPaimentIbanCoordonneesLabel' }),
            mandateTitle: formatMessage({ id: 'CoordonneesBancairesMandatTitre' }),
            CoordonneesBancairesMandatStatutLabel: formatMessage({
                id: 'CoordonneesBancairesMandatStatutLabel'
            }),
            CoordonneesBancairesMandatDateSignatureLabel: formatMessage({
                id: 'CoordonneesBancairesMandatDateSignatureLabel'
            }),
            CoordonneesBancairesMandatRUMLabel: formatMessage({
                id: 'CoordonneesBancairesMandatRUMLabel'
            }),
            CoordonneesBancairesMandatICSLabel: formatMessage({
                id: 'CoordonneesBancairesMandatICSLabel'
            }),
            mandateLinkTitle: formatMessage({ id: 'CoordonneesBancairesRecapMandatLien' }),
            CoordonneesBancairesRecapIBANBICTitre: formatMessage({
                id: 'CoordonneesBancairesRecapIBANBICTitre'
            }),
            CoordonneesBancairesRecapEpargneSalarialeESTitre: formatMessage({
                id: 'CoordonneesBancairesRecapEpargneSalarialeESTitre'
            }),
            CoordonneesBancairesRecapRetraiteCollectiveRCTitre: formatMessage({
                id: 'CoordonneesBancairesRecapRetraiteCollectiveRCTitre'
            })
        };
    }, [formatMessage, getMessageRaw]);

    return {dictionnary, formatMessage, formatDate};
}
