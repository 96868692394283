// eslint-disable-next-line no-shadow
export enum IconEnum {
  ACCESSIBILITE = 'accessibilite',
  ACTIONS = 'actions',
  AJOUTER = 'ajouter',
  ARGENT = 'argent',
  ARROW_RIGHT = 'fleche-droite',
  BRIEFCASE = 'debl-emploi',
  CALENDAR = 'calendar',
  CALENDAR_5 = 'calendrier-5',
  CARD = 'bancaire',
  CHECK = 'cocher',
  CHEVRON_BOTTOM = 'chevron-bas',
  CHEVRON_LEFT = 'chevron-gauche',
  CHEVRON_RIGHT = 'chevron-droite',
  CHEVRON_TOP = 'chevron-haut',
  CLOCK = 'horloge',
  CLOSE = 'fermer',
  COMPARATEUR = 'comparateur',
  CONNECT = 'connect',
  CONTACT = 'contact',
  CONTRIBUTION = 'abondement',
  COOKIES = 'cookies',
  CROSS = 'cross',
  EDIT = 'editer',
  EFFACER_VIDE = 'effacer-vide',
  EXCEL = 'doc-excel',
  FACE_ID = 'face-id',
  FAMILY_HEART = 'debl-famille',
  FAVORITE = 'favoris',
  FAVORITE_BLACK = 'favoris-solide',
  FIL = 'fil',
  FILTERS = 'filtres',
  FISCALITE = 'fiscalite',
  GRAPH = 'graph',
  HELP = 'aide',
  HIDE = 'cache',
  HISTORIC = 'historique',
  HOME = 'accueil',
  HOUSE = 'debl-rp',
  IDEA = 'idee',
  INFO = 'info',
  INFO_BLACK = 'info-solide',
  INSTALLMENT = 'versement',
  LEGAL_NOTICE = 'mentions-legales',
  LOOK = 'voir',
  MENU = 'menu',
  MONNAIE = 'monnaie',
  NOTIFICATION = 'notif',
  PARTAGER = 'partager',
  PASSWORD = 'mdp',
  PAUSE = 'pause',
  PAYS = 'pays',
  PDF = 'doc-pdf',
  PICTURE = 'image',
  PLUS = 'plus',
  PRIVATE = 'perso',
  PROFILE = 'profil',
  PROTECTIONS = 'protections',
  REMOVE = 'effacer',
  RETIREMENT = 'retraite',
  SAVING = 'epargne',
  SEARCH = 'recherche',
  SETTING = 'parametrage',
  SHAKING_HAND = 'mains-serrees',
  STORM = 'debl-acc-vie',
  SYNCHRONISER = 'synchroniser',
  TELECHARGER = 'telecharger',
  TERMS_OF_SERVICE = 'cgu',
  TAG = 'tag',
  TIME = 'temps',
  TOUCH_ID = 'touch-id',
  TROPHY = 'trophee',
  TUTORIAL = 'tuto',
  VVP_ICON = 'VVP_Icon',
  UNLOCK = 'deblocage',
  WAITING = 'en-attente',
  WARNING = 'attention',
  LECTURE = 'lecture',
  UPLOAD_OK = 'upload-ok',
  BANK_DETAILS_SUCCESS = 'budgetinsightSuccess',
  BANK_DETAILS_ERROR = 'oupsWithComputer',
  WARNING_WITH_BG = 'warningWithBackground',
  PILOTAGE = 'pilotage',
  PILOTAGE_NOTIF = 'pilotageNotif',
  MALENTENDANT = 'malentendant'
}
