import { InitAllErrorsAction } from '@modules/error/actions/errorActionsTypes'

import {
  AuthErrorState,
  NativeAuthenticationError,
  SilentSigninOutputActionType,
  UserInfoState,
  UserState
} from '../types'
import { UserCredentials } from './authActions'

export enum AuthActionType {
  USER_INFO_SUCCESS = 'USER_INFO_SUCCESS',
  SIGNIN_NATIVE_APP_REQUEST = 'SIGNIN_NATIVE_APP_REQUEST',
  SIGNIN_NATIVE_APP_SUCCESS = 'SIGNIN_NATIVE_APP_SUCCESS',
  SIGNIN_NATIVE_APP_FAILURE = 'SIGNIN_NATIVE_APP_FAILURE',
  SAVE_LOGIN = 'SAVE_LOGIN',
  SET_BIOMETRIC_CREDENTIALS = 'SET_BIOMETRIC_CREDENTIALS',
  LOGOUT_CALLBACK_REQUEST = 'LOGOUT_CALLBACK_REQUEST',
  LOGOUT_CALLBACK_MOBILE_APP_REQUEST = 'LOGOUT_CALLBACK_MOBILE_APP_REQUEST',
  LOGOUT_CALLBACK_SUCCESS = 'LOGOUT_CALLBACK_SUCCESS',
  LOGOUT_CALLBACK_FAILURE = 'LOGOUT_CALLBACK_FAILURE',
  SIGNIN_NATIVE_APP_USING_BIOMETRIC_REQUEST = 'SIGNIN_NATIVE_APP_USING_BIOMETRIC_REQUEST',
  SIGNIN_NATIVE_APP_USING_BIOMETRIC_SUCCESS = 'SIGNIN_NATIVE_APP_USING_BIOMETRIC_SUCCESS',
  SIGNIN_NATIVE_APP_USING_BIOMETRIC_FAILURE = 'SIGNIN_NATIVE_APP_USING_BIOMETRIC_FAILURE',
  PROMPT_BIOMETRIC_USER_MODAL_ASK_REQUEST = 'PROMPT_BIOMETRIC_USER_MODAL_ASK_REQUEST',
  PROMPT_BIOMETRIC_USER_MODAL_ASK_SUCCESS = 'PROMPT_BIOMETRIC_USER_MODAL_ASK_SUCCESS',
  SIGNIN_REDIRECT_CALLBACK_REQUEST = 'SIGNIN_REDIRECT_CALLBACK_REQUEST',
  SIGNIN_REDIRECT_CALLBACK_MOBILE_APP_REQUEST = 'SIGNIN_REDIRECT_CALLBACK_MOBILE_APP_REQUEST',
  SIGNIN_REDIRECT_CALLBACK_SUCCESS = 'SIGNIN_REDIRECT_CALLBACK_SUCCESS',
  SIGNIN_REDIRECT_CALLBACK_FAILURE = 'SIGNIN_REDIRECT_CALLBACK_FAILURE',
  SILENT_RENEW_TOKEN_REQUEST = 'SILENT_RENEW_TOKEN_REQUEST',
  SILENT_RENEW_TOKEN_SUCCESS = 'SILENT_RENEW_TOKEN_SUCCESS',
  SILENT_RENEW_TOKEN_FAILURE = 'SILENT_RENEW_TOKEN_FAILURE',
  AUTH_INIT_ERROR = 'AUTH_INIT_ERROR',
  SET_IS_OIDC_SESSION_ENDED = 'SET_IS_OIDC_SESSION_ENDED'
}

export interface UserInfoSuccessAction {
  type: AuthActionType.USER_INFO_SUCCESS
  userInfo: UserInfoState
}

export interface SigninNativeAppRequestAction {
  type: AuthActionType.SIGNIN_NATIVE_APP_REQUEST
  userCredentials: UserCredentials
  memorizeLogin: boolean
}

export interface SigninNativeAppSuccessAction {
  type: AuthActionType.SIGNIN_NATIVE_APP_SUCCESS
  user: UserState
}

export interface SigninNativeAppFailureAction {
  type: AuthActionType.SIGNIN_NATIVE_APP_FAILURE
  error: NativeAuthenticationError
}

export interface SaveLoginAction {
  type: AuthActionType.SAVE_LOGIN
  loginMail: string | undefined
}

export interface SetBiometricCredentialsAction {
  type: AuthActionType.SET_BIOMETRIC_CREDENTIALS
  biometricAcceptationStatus: boolean | undefined
}
export interface LogoutCallbackRequestAction {
  type: AuthActionType.LOGOUT_CALLBACK_REQUEST
}

export interface LogoutCallbackMobileAppRequestAction {
  type: AuthActionType.LOGOUT_CALLBACK_MOBILE_APP_REQUEST
}

export interface LogoutCallbackSuccessAction {
  type: AuthActionType.LOGOUT_CALLBACK_SUCCESS
}

export interface LogoutCallbackFailureAction {
  type: AuthActionType.LOGOUT_CALLBACK_FAILURE
  error: Error | string
}

export interface SigninNativeAppUsingBiometricAction {
  type: AuthActionType.SIGNIN_NATIVE_APP_USING_BIOMETRIC_REQUEST
}

export interface SigninNativeAppUsingBiometricSuccessAction {
  type: AuthActionType.SIGNIN_NATIVE_APP_USING_BIOMETRIC_SUCCESS
  user: UserState
}

export interface SigninNativeAppUsingBiometricFailureAction {
  type: AuthActionType.SIGNIN_NATIVE_APP_USING_BIOMETRIC_FAILURE
  error: NativeAuthenticationError
}
export interface PromptBiometricUserModalAskRequestAction {
  type: AuthActionType.PROMPT_BIOMETRIC_USER_MODAL_ASK_REQUEST
  isVisible: boolean
  biometryType: string
}
export interface PromptBiometricUserModalAskSuccessAction {
  type: AuthActionType.PROMPT_BIOMETRIC_USER_MODAL_ASK_SUCCESS
  isVisible: boolean
  success: boolean | undefined
}
// SIGNIN REDIRECT CALLBACK

export interface SigninRedirectCallbackRequestAction {
  type: AuthActionType.SIGNIN_REDIRECT_CALLBACK_REQUEST
}

// mobile app SIGNIN REDIRECT CALLBACK
export interface SigninRedirectCallbackRequestMobileAppAction {
  type: AuthActionType.SIGNIN_REDIRECT_CALLBACK_MOBILE_APP_REQUEST
}

export interface SigninRedirectCallbackSuccessAction {
  type: AuthActionType.SIGNIN_REDIRECT_CALLBACK_SUCCESS
  user: UserState
}

export interface SigninRedirectCallbackFailureAction {
  type: AuthActionType.SIGNIN_REDIRECT_CALLBACK_FAILURE
  error: any
}

// silent renew token

export interface SilentRenewTokenRequest {
  type: AuthActionType.SILENT_RENEW_TOKEN_REQUEST
  outputAction: SilentSigninOutputActionType | undefined
}

export interface SilentRenewTokenSuccess {
  type: AuthActionType.SILENT_RENEW_TOKEN_SUCCESS
  user: UserState
}

export interface SilentRenewTokenFailure {
  type: AuthActionType.SILENT_RENEW_TOKEN_FAILURE
  error: AuthErrorState
}

// INIT ERROR

export interface AuthInitErrorAction {
  type: AuthActionType.AUTH_INIT_ERROR
}

export interface SetIsOidcSessionEndedAction {
  type: AuthActionType.SET_IS_OIDC_SESSION_ENDED
  newState: boolean
}

export type AuthenticateAction =
  | AuthInitErrorAction
  | SigninRedirectCallbackRequestAction
  | SigninRedirectCallbackSuccessAction
  | SigninRedirectCallbackFailureAction
  | LogoutCallbackSuccessAction
  | LogoutCallbackFailureAction
  | LogoutCallbackRequestAction
  | InitAllErrorsAction
  | SilentRenewTokenRequest
  | SilentRenewTokenSuccess
  | SilentRenewTokenFailure
  | SetIsOidcSessionEndedAction
  | SigninRedirectCallbackRequestMobileAppAction
  | LogoutCallbackMobileAppRequestAction
  | SigninNativeAppFailureAction
  | SigninNativeAppRequestAction
  | SigninNativeAppSuccessAction
  | SaveLoginAction
  | SetBiometricCredentialsAction
  | SigninNativeAppUsingBiometricAction
  | PromptBiometricUserModalAskRequestAction
  | PromptBiometricUserModalAskSuccessAction
  | SigninNativeAppUsingBiometricSuccessAction
  | SigninNativeAppUsingBiometricFailureAction
  | UserInfoSuccessAction
