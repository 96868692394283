import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useScreenSizes, useTheme } from '@ere-uilib/styles';

export function useStyles() {
  const theme = useTheme();
  const { isDesktop } = useScreenSizes();

  return useMemo(
    () =>
      StyleSheet.create({
        container: {
          gap: theme.metrics.spacing.xm,
        },
        button: {
          alignSelf: isDesktop ? 'flex-start' : 'auto',
          marginVertical: theme.metrics.spacing.xs
        }
      }),
    [theme, isDesktop]
  );
}
