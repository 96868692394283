import {
  OperationDetailsBoTypeEnum,
  OperationDetailsPaymentModeTypeEnum,
  OperationDetailsTypeEnum,
  OperationFamilyEnum,
  OperationHistoryTypesEnum,
  ProfitSharingIncentiveLegalFrameworkEnum,
  ProfitSharingIncentiveTypeEnum
} from '@constants/index';
import { OperationsHistoryStatusEnum } from '@ere-uilib/enums';
import { OperationDetails } from '@modules/savings/types';
import { AVAILABILITY_TYPE } from '@pages/Arbitration/components/Support/components/DueDateCard/interface';

export const mockOperationDetailsInteressment: OperationDetails = {
  identifier: 'O-123054485',
  planCode: '',
  planName: 'PEE',
  code: 'INT',
  type: OperationHistoryTypesEnum.INCENTIVE,
  label: 'Intéressement sur mon PEE',
  deductible: false,
  compartmentCode: 'PEE',
  compartmentName: 'Epargne salariale',
  brutAmount: 2966.72,
  netAmount: 2678.95,
  abundanceBrutAmount: 1041.79,
  abundanceNetAmount: 940.74,
  abundanceIdentifier: null,
  feeAmount: 0,
  socialContributionsAmount: 388.82,
  deductibleAmount: 0,
  statusCode: 'Termine',
  statusLabel: 'Validé',
  creationDate: '2020-07-13T00:00:00',
  updateDate: '2020-07-13T00:00:00',
  cancelable: false,
  editable: false,
  masterOperationCode: OperationDetailsBoTypeEnum.INTERESSEMENT,
  masterOperationType: OperationHistoryTypesEnum.INCENTIVE,
  details: {
    optionsBulletin: {
      amountNetShare: null,
      entryDeadline: '2020-06-16T00:00:00Z',
      averageAmount: 0.0,
      totalAmount: 4988657.0,
      type: ProfitSharingIncentiveTypeEnum.incentive,
      fiscalYearStartDate: '2019-01-01T00:00:00Z',
      fiscalEndStartDate: '2019-12-31T00:00:00Z',
      frame: ProfitSharingIncentiveLegalFrameworkEnum.normal,
      abundance: '',
      defaultInterestAmount: null
    },
    withholdingTax: {
      taxableNetAmount: null,
      pasAmount: null,
      rateOrigin: null,
      pasRate: null,
      deductibleCSGAmount: null
    },
    amed: {
      entryDeadline: null
    },
    conditionalOrder: {
      thresholdType: '',
      threshold: 0,
      expirationDate: '2019-12-31T00:00:00Z',
      netAssetValue: 0,
      netAssetDate: '2019-12-31T00:00:00Z'
    },
    repayment: {
      validSupportingDocuments: null,
      paymentDate: null,
      dateOfTheEvent: null,
      taxableAmount: null,
      unlockCaseIdentifier: null
    }
  },
  instructions: [
    {
      id: 'I-177019842',
      code: '',
      planId: '',
      supportId: '897',
      amountNet: 940.74,
      amountBrut: 1041.79,
      sharesCount: 24.9272,
      step: 'SOUSCRIPTION',
      stepDate: '2019-12-31T00:00:00Z',
      nature: 'ABONDEMENT',
      netAssetValue: 0,
      netAssetValueDate: '2019-12-31T00:00:00Z'
    },
    {
      id: '1-1PNB1X7',
      code: '',
      planId: '',
      supportId: '897',
      amountNet: 2678.95,
      amountBrut: 2966.72,
      sharesCount: 70.9853,
      step: 'SOUSCRIPTION',
      stepDate: '2019-12-31T00:00:00Z',
      nature: 'ABONDEMENT',
      netAssetValue: 0,
      netAssetValueDate: '2019-12-31T00:00:00Z'
    }
  ],
  regulation: {
    bankDetails: {
      bic: '',
      iban: '',
      type: ''
    },
    type: '',
    amountNet: 0,
    status: '',
    date: ''
  },
  source: {
    bulletinOption: {
      companyId: null,
      id: null,
      type: OperationDetailsTypeEnum.INCENTIVE,
      legalFramework: ProfitSharingIncentiveLegalFrameworkEnum.normal,
      defaultAffectations: null,
      derogatoryAffectations: null,
      deadlineDate: '2020-06-16T00:00:00+00:00',
      fiscalYearStartDate: '2019-01-01T00:00:00+00:00',
      fiscalYearEndDate: '2019-12-31T00:00:00+00:00',
      totalAmount: 4988657.0,
      minimumAmount: null,
      netShareAmount: 0,
      derogatoryAmount: null,
      possibleChoices: null,
      paymentMethod: null,
      possibleContribution: false,
      saverDetails: null
    },
    planContributions: null,
    plans: [],
    payment: null
  },
  destination: {
    bulletinOption: null,
    planContributions: [
      {
        planId: 2001,
        planName: 'PEE',
        planFullName: 'PEE',
        planType: 'PEE',
        compartments: [
          {
            code: 'PEE',
            label: 'Epargne salariale',
            descriptionLabel: 'PEE_PEE',
            managements: [
              {
                id: '0000099158',
                code: 'PEE',
                label: 'PEE',
                totalAmount: 2966.72,
                supports: [
                  {
                    supportIsin: 'QS0002101U04',
                    supportName: 'BNP PARIBAS ACTIONNARIAT FRANCE',
                    supportType: 'FONDS',
                    hasNoFundSheet: false,
                    source: 'BNPP',
                    supportIdNoee: '897',
                    riskLevelSRRI: '7',
                    riskLevelSRI: '6',
                    performanceValue: -4.0141244,
                    amount: {
                      amount: 2966.72,
                      addedValue: -2660.56499516625,
                      numberOfUnits: 95.9125
                    },
                    rate: null,
                    supportDetailsUrl: null,
                    legalStatus: 'FCP (Fonds commun de placement) ',
                    isMaster: false,
                    hasContribution: false,
                    netAssetValue: {
                      amount: 37.7395,
                      currency: 'EUR',
                      dateValue: '2020-07-13T00:00:00+00:00'
                    },
                    plans: null,
                    hasVersement: false,
                    hasInteressement: false,
                    hasParticipation: false,
                    nextNavDate: '2023-04-12T00:00:00+00:00',
                    advancedProperties: {
                      HasThresholdTrigger: false,
                      AmountNet: 940.74, // ContributionAmountNet => AmountNet: plans
                      ContributionAmountNet: 940.74,
                      ContributionAmountBrut: 1041.79,
                      Availabilities: [
                        {
                          numberOfUnits: 70.9853,
                          datetime: '2025-06-01T00:00:00',
                          amount: 0.0,
                          availabilityLabel: 'DetailOperationEcheanceTypeINDISPONIBLE',
                          useAvailabilityLabel: false
                        }
                      ]
                    },
                    filterProperties: {},
                    isISR: false,
                    schedules: [
                      {
                        scheduleCode: AVAILABILITY_TYPE.UNAVAILABLE,
                        amount: 959.125,
                        date: '2025-06-01T00:00:00',
                        numberOfShares: 95.9125,
                        ontriggerDueDateEditPress: function(): void {
                          throw new Error('Function not implemented.');
                        },
                        onLinkUnderFieldEchenacePress: function(): void {
                          throw new Error('Function not implemented.');
                        },
                        dueDateFieldAmount: 0
                      }
                    ]
                  }
                ],
                order: 0,
                isFree: true,
                filterProperties: {
                  Contribution: true,
                  FlowReallocation: false,
                  ImmediateAsset: true,
                  ImmediatePension: false,
                  OadReallocation: true,
                  PartialReallocation: true,
                  RetirementOnly: false,
                  StockReallocation: true,
                  TaxIn: true
                },
                advancedProperties: {
                  HasIncorrectDrivingData: false
                },
                isFictive: false
              }
            ],
            order: 0,
            isFictive: false,
            filterProperties: {
              Contribution: true,
              FlowReallocation: false,
              ImmediateAsset: true,
              ImmediatePension: false,
              OadReallocation: true,
              PartialReallocation: true,
              RetirementOnly: false,
              StockReallocation: true,
              TaxIn: true
            },
            advancedProperties: {},
            totalAmount: 2966.72
          }
        ],
        filterProperties: {
          VVL: false,
          VVP: false,
          TaxOut: false,
          TaxIn: true,
          RetirementOnly: false,
          Abondement: false,
          Invested: false,
          ThresholdTrigger: false,
          StockReallocation: true,
          PartialReallocation: true,
          FlowReallocation: false,
          OadReallocation: true,
          ImmediateAsset: true,
          ImmediatePension: false,

          Contribution: true
        },
        advancedProperties: {
          Offres: '930650',
          AmountNet: 2678.95
        },
        colors: ['#5B8AB5'],
        planFamily: 'ES',
        label: "Plan d'Epargne Entreprise",
        shortLabel: 'PEE',
        horizon: 'MoyenTerme',
        isCustomPlan: false
      }
    ],
    plans: [
      {
        planId: 2001,
        planName: 'PEE',
        planFullName: 'PEE',
        planType: 'PEE',
        compartments: [
          {
            code: 'PEE',
            label: 'Epargne salariale',
            descriptionLabel: 'PEE_PEE',
            managements: [
              {
                id: '0000099158',
                code: 'PEE',
                label: 'PEE',
                totalAmount: 2966.72,
                supports: [
                  {
                    supportIsin: 'QS0002101U04',
                    supportName: 'BNP PARIBAS ACTIONNARIAT FRANCE',
                    supportType: 'FONDS',
                    hasNoFundSheet: false,
                    source: 'BNPP',
                    supportIdNoee: '897',
                    riskLevelSRRI: '7',
                    riskLevelSRI: '6',
                    performanceValue: -4.0141244,
                    amount: {
                      amount: 2966.72,
                      addedValue: -2660.56499516625,
                      numberOfUnits: 95.9125
                    },
                    rate: null,
                    supportDetailsUrl: null,
                    legalStatus: 'FCP (Fonds commun de placement) ',
                    isMaster: false,
                    hasContribution: false,
                    netAssetValue: {
                      amount: 37.7395,
                      currency: 'EUR',
                      dateValue: '2020-07-13T00:00:00+00:00'
                    },
                    plans: null,
                    hasVersement: false,
                    hasInteressement: false,
                    hasParticipation: false,
                    nextNavDate: '2023-04-12T00:00:00+00:00',
                    advancedProperties: {
                      HasThresholdTrigger: false,
                      AmountNet: 2678.95,
                      ContributionAmountNet: 940.74,
                      ContributionAmountBrut: 1041.79,
                      Availabilities: [
                        {
                          numberOfUnits: 70.9853,
                          datetime: '2025-06-01T00:00:00',
                          amount: 0.0,
                          availabilityLabel: 'DetailOperationEcheanceTypeINDISPONIBLE',
                          useAvailabilityLabel: false
                        }
                      ]
                    },
                    filterProperties: {},
                    isISR: false,
                    schedules: [
                      {
                        scheduleCode: AVAILABILITY_TYPE.UNAVAILABLE,
                        amount: 959.125,
                        date: '2025-06-01T00:00:00',
                        numberOfShares: 95.9125,
                        ontriggerDueDateEditPress: function(): void {
                          throw new Error('Function not implemented.');
                        },
                        onLinkUnderFieldEchenacePress: function(): void {
                          throw new Error('Function not implemented.');
                        },
                        dueDateFieldAmount: 0
                      }
                    ]
                  }
                ],
                order: 0,
                isFree: true,
                filterProperties: {
                  Contribution: true,
                  FlowReallocation: false,
                  ImmediateAsset: true,
                  ImmediatePension: false,
                  OadReallocation: true,
                  PartialReallocation: true,
                  RetirementOnly: false,
                  StockReallocation: true,
                  TaxIn: true
                },
                advancedProperties: {
                  HasIncorrectDrivingData: false
                },
                isFictive: false
              }
            ],
            order: 0,
            isFictive: false,
            filterProperties: {
              Contribution: true,
              FlowReallocation: false,
              ImmediateAsset: true,
              ImmediatePension: false,
              OadReallocation: true,
              PartialReallocation: true,
              RetirementOnly: false,
              StockReallocation: true,
              TaxIn: true
            },
            advancedProperties: {},
            totalAmount: 2966.72
          }
        ],
        filterProperties: {
          VVL: false,
          VVP: false,
          TaxOut: false,
          TaxIn: true,
          RetirementOnly: false,
          Abondement: false,
          Invested: false,
          ThresholdTrigger: false,
          StockReallocation: true,
          PartialReallocation: true,
          FlowReallocation: false,
          OadReallocation: true,
          ImmediateAsset: true,
          ImmediatePension: false,

          Contribution: true
        },
        advancedProperties: {
          Offres: '930650',
          AmountNet: 2678.95
        },
        colors: ['#5B8AB5'],
        planFamily: 'ES',
        label: "Plan d'Epargne Entreprise",
        shortLabel: 'PEE',
        horizon: 'MoyenTerme',
        isCustomPlan: false
      }
    ],
    payment: null
  },
  family: OperationFamilyEnum.INCENTIVE
};
export const mockOperationDetailsArbitration: OperationDetails = {
  identifier: 'O-121545543',
  planCode: '',
  planName: 'PEE',
  code: '196',
  type: OperationHistoryTypesEnum.TRANSFER_IN,
  label: 'Transfert sur mon PEE',
  deductible: false,
  compartmentCode: 'PEE',
  compartmentName: 'Epargne salariale',
  brutAmount: 0,
  netAmount: 4639.96,
  abundanceBrutAmount: 0,
  abundanceNetAmount: 0,
  abundanceIdentifier: null,
  feeAmount: 0,
  socialContributionsAmount: 0,
  deductibleAmount: 0,
  statusCode: 'Termine',
  statusLabel: 'Validé',
  creationDate: '2020-03-27T15:49:05+01:00',
  updateDate: '',
  cancelable: false,
  editable: false,
  masterOperationCode: OperationDetailsBoTypeEnum.COMPTABLE_GAGNANT_CASH,
  masterOperationType: OperationHistoryTypesEnum.TRANSFER_IN,
  details: {
    optionsBulletin: {
      defaultInterestAmount: null,
      amountNetShare: null,
      entryDeadline: null,
      averageAmount: null,
      totalAmount: null,
      type: null,
      fiscalYearStartDate: null,
      fiscalEndStartDate: null,
      frame: null,
      abundance: null
    },
    withholdingTax: {
      taxableNetAmount: null,
      pasAmount: null,
      rateOrigin: null,
      pasRate: null,
      deductibleCSGAmount: null
    },
    amed: {
      entryDeadline: null
    },
    conditionalOrder: {
      thresholdType: 'VCP',
      threshold: 55.0,
      expirationDate: '26/11/2023 00:00:00 +00:00',
      netAssetValue: 27.5,
      netAssetDate: '2023-09-20T00:00:00Z'
    },
    repayment: {
      validSupportingDocuments: null,
      paymentDate: null,
      dateOfTheEvent: null,
      taxableAmount: null,
      unlockCaseIdentifier: null
    }
  },
  instructions: [],
  regulation: {
    bankDetails: {
      bic: '',
      iban: '',
      type: ''
    },
    type: '',
    amountNet: 0,
    status: '',
    date: ''
  },
  source: {
    bulletinOption: {
      companyId: null,
      id: null,
      type: null,
      legalFramework: null,
      defaultAffectations: null,
      derogatoryAffectations: null,
      deadlineDate: null,
      fiscalYearStartDate: null,
      fiscalYearEndDate: null,
      totalAmount: null,
      minimumAmount: null,
      netShareAmount: 0,
      derogatoryAmount: null,
      possibleChoices: null,
      paymentMethod: null,
      possibleContribution: false,
      saverDetails: null
    },
    plans: [
      {
        planId: 1001,
        planName: 'PEE',
        planType: 'PEE',
        planFullName: '',
        isCustomPlan: false,
        compartments: [
          {
            code: 'PEE',
            label: 'Epargne salariale',
            managements: [
              {
                id: '0000098195',
                code: 'PEE',
                label: 'PEE',
                totalAmount: 935.41,
                supports: [
                  {
                    supportIsin: 'QS00021034E1',
                    supportName: 'MULTIPAR BNP PARIBAS PERSPECTIVES CT G',
                    supportType: '',
                    hasNoFundSheet: true,
                    source: '',
                    supportIdNoee: '2328',
                    riskLevelSRI: '3',
                    riskLevelSRRI: '3',
                    performanceValue: 0,
                    amount: {
                      amount: 935.41,
                      addedValue: 85.77543239044,
                      numberOfUnits: 589.8615
                    },
                    rate: null,
                    supportDetailsUrl: null,
                    legalStatus: '',
                    isMaster: false,
                    hasContribution: false,
                    netAssetValue: {
                      amount: 9.8447,
                      currency: 'EUR',
                      dateValue: '2020-03-30T00:00:00+00:00'
                    },
                    plans: null,
                    hasVersement: true,
                    hasInteressement: true,
                    hasParticipation: true,
                    nextNavDate: '2023-03-09T00:00:00+00:00',
                    advancedProperties: {
                      HasThresholdTrigger: false,
                      AmountNet: 935.41,
                      ContributionAmountNet: 844.91,
                      ContributionAmountBrut: 844.91,
                      AvailabilityDate: '2023-06-09T00:00:00+00:00',
                      IsAvailable: true,
                      AvailabilityLabel: 'DetailOperationEcheanceTypeRetraite'
                    },
                    filterProperties: {},
                    isISR: false
                  }
                ],
                order: 0,
                isFree: true,
                filterProperties: {
                  Contribution: true,
                  FlowReallocation: false,
                  PartialReallocation: true,
                  RetirementOnly: false,
                  StockReallocation: true,
                  TaxIn: true,
                  ThresholdTrigger: false,
                  VVL: false,
                  VVP: false
                },
                advancedProperties: {},
                isFictive: false
              }
            ],
            order: 1,
            isFictive: false
          }
        ],
        filterProperties: {
          VVL: false,
          VVP: false,
          TaxOut: false,
          TaxIn: true,
          RetirementOnly: false,
          Abondement: true,
          Invested: true,
          ThresholdTrigger: false,
          StockReallocation: true,
          PartialReallocation: true,
          FlowReallocation: false,
          OadReallocation: false,
          Contribution: true
        },
        advancedProperties: {
          ContributionConsumed: 0,
          ContributionLimit: 1000,
          AmountNet: 935.41
        },
        colors: ['#5B8AB5'],
        planFamily: 'ES',
        label: "​Plan d'Epargne Entreprise",
        shortLabel: 'PEE',
        horizon: 'MoyenTerme'
      }
    ],
    payment: null
  },
  destination: {
    bulletinOption: null,
    plans: [
      {
        planId: 1001,
        planName: 'PEE',
        planType: 'PEE',
        planFullName: '',
        isCustomPlan: false,
        compartments: [
          {
            code: 'PEE',
            label: 'Epargne salariale',
            managements: [
              {
                id: '0000098195',
                code: 'PEE',
                label: 'PEE',
                totalAmount: 850,
                supports: [
                  {
                    supportIsin: 'QS00021034E1',
                    supportName: 'CARMIGNAC PORTFOLIO GRANDE EUROPE (F)',
                    supportType: '',
                    hasNoFundSheet: true,
                    source: '',
                    supportIdNoee: '2328',
                    riskLevelSRI: '3',
                    riskLevelSRRI: '3',
                    performanceValue: 0,
                    amount: {
                      amount: 320,
                      addedValue: 85.77543239044,
                      numberOfUnits: 0
                    },
                    rate: null,
                    supportDetailsUrl: null,
                    legalStatus: '',
                    isMaster: false,
                    hasContribution: false,
                    netAssetValue: {
                      amount: 9.8447,
                      currency: 'EUR',
                      dateValue: '2020-03-30T00:00:00+00:00'
                    },
                    plans: null,
                    hasVersement: true,
                    hasInteressement: true,
                    hasParticipation: true,
                    nextNavDate: '2023-03-09T00:00:00+00:00',
                    advancedProperties: {
                      HasThresholdTrigger: false,
                      AmountNet: 320,
                      ContributionAmountNet: 844.91,
                      ContributionAmountBrut: 844.91,
                      AvailabilityDate: '2023-06-09T00:00:00+00:00',
                      IsAvailable: false,
                      AvailabilityLabel: 'DetailOperationEcheanceTypeRetraite',
                      Availabilities: [
                        {
                          numberOfUnits: 0.62,
                          datetime: '2023-06-09T00:00:00+00:00',
                          useAvailabilityLabel: true,
                          availabilityLabel: ''
                        }
                      ]
                    },
                    filterProperties: {},
                    isISR: false
                  },
                  {
                    supportIsin: 'FR0013508793',
                    supportName: 'BNPP SUPPORT 1',
                    supportType: '',
                    hasNoFundSheet: true,
                    source: '',
                    supportIdNoee: '2328',
                    riskLevelSRI: '3',
                    riskLevelSRRI: '3',
                    performanceValue: 0,
                    amount: {
                      amount: 530,
                      addedValue: 85.77543239044,
                      numberOfUnits: 549.8615
                    },
                    rate: null,
                    supportDetailsUrl: null,
                    legalStatus: '',
                    isMaster: false,
                    hasContribution: false,
                    netAssetValue: {
                      amount: 9.8447,
                      currency: 'EUR',
                      dateValue: '2020-03-30T00:00:00+00:00'
                    },
                    plans: null,
                    hasVersement: true,
                    hasInteressement: true,
                    hasParticipation: true,
                    nextNavDate: '2023-03-09T00:00:00+00:00',
                    advancedProperties: {
                      HasThresholdTrigger: false,
                      AmountNet: 530,
                      ContributionAmountNet: 844.91,
                      ContributionAmountBrut: 844.91,
                      AvailabilityDate: '2023-06-09T00:00:00+00:00',
                      IsAvailable: false,
                      AvailabilityLabel: 'DetailOperationEcheanceTypeIndisponible'
                    },
                    filterProperties: {},
                    isISR: false
                  }
                ],
                order: 0,
                isFree: true,
                filterProperties: {
                  Contribution: true,
                  FlowReallocation: false,
                  PartialReallocation: true,
                  RetirementOnly: false,
                  StockReallocation: true,
                  TaxIn: true,
                  ThresholdTrigger: false,
                  VVL: false,
                  VVP: false
                },
                advancedProperties: {},
                isFictive: false
              }
            ],
            order: 1,
            isFictive: false
          }
        ],
        filterProperties: {
          VVL: false,
          VVP: false,
          TaxOut: false,
          TaxIn: true,
          RetirementOnly: false,
          Abondement: true,
          Invested: true,
          ThresholdTrigger: false,
          StockReallocation: true,
          PartialReallocation: true,
          FlowReallocation: false,
          OadReallocation: false,
          Contribution: true
        },
        advancedProperties: {
          ContributionConsumed: 0,
          ContributionLimit: 1000,
          AmountNet: 530
        },
        colors: ['#5B8AB5'],
        planFamily: 'ES',
        label: "​Plan d'Epargne Entreprise",
        shortLabel: 'PEE',
        horizon: 'MoyenTerme'
      }
    ],
    payment: null
  }
};

export const mockOperationDetailsIncentive: OperationDetails = {
  identifier: 'dd9886ea8-1',
  planCode: '19198',
  planName: 'PERO',
  family: OperationFamilyEnum.INCENTIVE,
  code: 'MV_ARBATDES',
  type: OperationHistoryTypesEnum.INCENTIVE,
  label: 'Affectation de ma participation',
  deductible: true,
  compartmentCode: '0',
  compartmentName: 'Versements obligatoires',
  brutAmount: 1230.6788,
  netAmount: 234.8765,
  abundanceBrutAmount: 230.786899,
  abundanceNetAmount: 223.765,
  abundanceIdentifier: null,
  feeAmount: 120.78,
  socialContributionsAmount: 134.099,
  deductibleAmount: 0,
  statusCode: OperationsHistoryStatusEnum.TERMINE,
  statusLabel: 'Terminé',
  creationDate: '2022-08-26T22:00:00+00:00',
  updateDate: '2022-08-26T22:00:00+00:00',
  cancelable: false,
  editable: false,
  masterOperationCode: OperationDetailsBoTypeEnum.INTERESSEMENT,
  masterOperationType: OperationHistoryTypesEnum.INCENTIVE,
  details: null,
  source: {
    bulletinOption: {
      companyId: null,
      id: null,
      type: OperationDetailsTypeEnum.INCENTIVE,
      legalFramework: ProfitSharingIncentiveLegalFrameworkEnum.normal,
      defaultAffectations: null,
      derogatoryAffectations: null,
      deadlineDate: '2023-05-21T00:00:00',
      fiscalYearStartDate: null,
      fiscalYearEndDate: null,
      totalAmount: 425000,
      minimumAmount: null,
      netShareAmount: 4250,
      derogatoryAmount: null,
      possibleChoices: null,
      paymentMethod: null,
      possibleContribution: true,
      saverDetails: null
    },
    plans: null,
    payment: null
  },
  destination: {
    bulletinOption: null,
    plans: [
      {
        planId: 2001,
        planName: 'PEE',
        planType: 'PEE',
        planFullName: '',
        isCustomPlan: false,
        compartments: [
          {
            code: 'PEE',
            label: undefined,
            managements: [
              {
                isFictive: false,
                id: 'PEE-AMPLISSIM',
                code: 'PEE AMPLISSIM',
                label: '',
                totalAmount: 1530,
                supports: [
                  {
                    supportIsin: 'FR0014000AL1',
                    supportName: 'AM LABEL HARMONIE SOLID ESR-F)- morningstar',
                    supportType: '',
                    hasNoFundSheet: true,
                    source: '',
                    supportIdNoee: '28025',
                    riskLevelSRRI: '1',
                    riskLevelSRI: '',
                    performanceValue: 0,
                    amount: {
                      amount: 1200,
                      addedValue: 1174.1202546,
                      numberOfUnits: 967
                    },
                    rate: null,
                    supportDetailsUrl: null,
                    legalStatus: '',
                    isMaster: false,
                    hasContribution: false,
                    netAssetValue: {
                      amount: 0,
                      currency: 'EUR',
                      dateValue: ''
                    },
                    plans: null,
                    hasVersement: true,
                    hasInteressement: true,
                    hasParticipation: true,
                    nextNavDate: '2022-09-22T22:00:00+00:00',
                    advancedProperties: {},
                    filterProperties: {},
                    isISR: false
                  },
                  {
                    supportIsin: 'FR0010776807',
                    supportName:
                      'Amundi Label Actions Euroland ESR - Hottinguer Patrimoine morninstarg',
                    supportType: '',
                    hasNoFundSheet: true,
                    source: '',
                    supportIdNoee: '1042',
                    riskLevelSRRI: '1',
                    riskLevelSRI: '',
                    performanceValue: 0,
                    amount: {
                      amount: 200,
                      addedValue: 100.01,
                      numberOfUnits: 967
                    },
                    rate: null,
                    supportDetailsUrl: null,
                    legalStatus: '',
                    isMaster: false,
                    hasContribution: true,
                    netAssetValue: {
                      amount: 0,
                      currency: 'EUR',
                      dateValue: ''
                    },
                    plans: null,
                    hasVersement: true,
                    hasInteressement: true,
                    hasParticipation: true,
                    nextNavDate: '2022-09-22T22:00:00+00:00',
                    advancedProperties: {},
                    filterProperties: {},
                    isISR: false
                  },
                  {
                    supportIsin: 'FR0013508793',
                    supportName: 'BNPP SUPPORT 1',
                    supportType: '',
                    hasNoFundSheet: true,
                    source: '',
                    supportIdNoee: '28031',
                    riskLevelSRRI: '1',
                    riskLevelSRI: '',
                    performanceValue: 0,
                    amount: {
                      amount: 130,
                      addedValue: 100.01,
                      numberOfUnits: 967
                    },
                    rate: null,
                    supportDetailsUrl: null,
                    legalStatus: '',
                    isMaster: false,
                    hasContribution: false,
                    netAssetValue: {
                      amount: 0,
                      currency: 'EUR',
                      dateValue: ''
                    },
                    plans: null,
                    hasVersement: true,
                    hasInteressement: true,
                    hasParticipation: true,
                    nextNavDate: '2022-09-22T22:00:00+00:00',
                    advancedProperties: {},
                    filterProperties: {},
                    isISR: false
                  }
                ],
                order: 0,
                isFree: true,
                filterProperties: {
                  Contribution: true,
                  FlowReallocation: false,
                  OadReallocation: true,
                  PartialReallocation: true,
                  RetirementOnly: false,
                  StockReallocation: true,
                  TaxIn: true,
                  VVL: true,
                  VVP: true
                },
                advancedProperties: {}
              }
            ],
            order: 1
          }
        ],
        filterProperties: {
          VVL: true,
          VVP: true,
          TaxOut: false,
          TaxIn: true,
          RetirementOnly: false,
          Abondement: true,
          Invested: true,
          ThresholdTrigger: false,
          StockReallocation: true,
          PartialReallocation: true,
          FlowReallocation: false,
          OadReallocation: true,
          Contribution: true
        },
        advancedProperties: {},
        colors: ['#5B8AB5'],
        planFamily: 'ES',
        label: "​Plan d'Epargne Entreprise",
        shortLabel: 'PEE',
        horizon: 'MoyenTerme'
      },
      {
        planId: 2002,
        planName: 'PERECO',
        planType: 'PERECO',
        planFullName: '',
        isCustomPlan: false,
        compartments: [
          {
            code: 'PER C1',
            label: 'Versements volontaires déductibles',
            managements: [
              {
                isFictive: false,
                id: 'PERECO-C1-L',
                code: 'PERECO C1 L',
                label: 'Gestion Libre',
                totalAmount: 2000,
                supports: [
                  {
                    supportIsin: 'QS0002105TJ2',
                    supportName: 'MULTIMANAGERS ACTIONS EUROPE -FIDELITY',
                    supportType: 'FONDS',
                    hasNoFundSheet: false,
                    supportIdNoee: '1042',
                    riskLevelSRRI: '6',
                    riskLevelSRI: '5',
                    source: 'Morningstar',
                    performanceValue: 19.9222694560339,
                    amount: {
                      amount: 1000,
                      addedValue: 60,
                      numberOfUnits: 967
                    },
                    rate: null,
                    supportDetailsUrl: null,
                    legalStatus: 'FCP',
                    isMaster: false,
                    hasContribution: false,
                    netAssetValue: {
                      amount: 18.8706,
                      currency: 'EUR',
                      dateValue: '2020-09-17T00:00:00+02:00'
                    },
                    plans: null,
                    hasVersement: true,
                    hasInteressement: true,
                    hasParticipation: true,
                    nextNavDate: '09/02/2018 00:00:00 +01:00',
                    advancedProperties: {
                      HasThresholdTrigger: true,
                      ThresholdTriggerValidityDuration: 3,
                      ThresholdTriggerValidityDate: '2023-01-21T00:00:00+01:00',
                      CategorieClassification: 'Actions internationales',
                      DefaultAmount: 289.25476
                    },
                    filterProperties: {},
                    isISR: false
                  },
                  {
                    supportIsin: 'QS0002105TY1',
                    supportName: 'Multipar Monétaire Sélection Classique',
                    supportType: 'FONDS',
                    hasNoFundSheet: true,
                    supportIdNoee: '1039',
                    riskLevelSRRI: '1',
                    riskLevelSRI: '2',
                    performanceValue: -0.387212073073828,
                    source: 'Morningstar',
                    amount: {
                      amount: 1000,
                      addedValue: 40,
                      numberOfUnits: 967
                    },
                    rate: null,
                    supportDetailsUrl: null,
                    legalStatus: 'FCP',
                    isMaster: false,
                    hasContribution: false,
                    netAssetValue: {
                      amount: 11.0915,
                      currency: 'EUR',
                      dateValue: '2020-09-17T00:00:00+02:00'
                    },
                    plans: null,
                    hasVersement: true,
                    hasInteressement: true,
                    hasParticipation: true,
                    nextNavDate: '11/02/2018 00:00:00 +01:00',
                    advancedProperties: {
                      HasThresholdTrigger: false,
                      CategorieClassification: 'Monétaire',
                      DefaultAmount: 39.25476
                    },
                    filterProperties: {},
                    isISR: false
                  }
                ],
                order: 4,
                isFree: true,
                filterProperties: {
                  TaxOut: true,
                  VVL: true,
                  VVP: true
                },
                advancedProperties: {
                  HasContribution: false
                }
              },
              {
                isFictive: false,
                id: 'PERECO-C1-L',
                code: 'PERECO C1 L',
                label: 'Gestion Piloté',
                totalAmount: 2000,
                supports: [
                  {
                    supportIsin: 'QS0002105TJ2',
                    supportName: 'MULTIMANAGERS ACTIONS EUROPE -FIDELITY',
                    supportType: 'FONDS',
                    hasNoFundSheet: false,
                    supportIdNoee: '1042',
                    riskLevelSRRI: '6',
                    riskLevelSRI: '5',
                    performanceValue: 19.9222694560339,
                    source: 'Morningstar',
                    amount: {
                      amount: 1000,
                      addedValue: 60,
                      numberOfUnits: 967
                    },
                    rate: null,
                    supportDetailsUrl: null,
                    legalStatus: 'FCP',
                    isMaster: true,
                    hasContribution: false,
                    netAssetValue: {
                      amount: 18.8706,
                      currency: 'EUR',
                      dateValue: '2020-09-17T00:00:00+02:00'
                    },
                    plans: null,
                    hasVersement: true,
                    hasInteressement: true,
                    hasParticipation: true,
                    nextNavDate: '09/02/2018 00:00:00 +01:00',
                    advancedProperties: {
                      HasThresholdTrigger: true,
                      ThresholdTriggerValidityDuration: 3,
                      ThresholdTriggerValidityDate: '2023-01-21T00:00:00+01:00',
                      CategorieClassification: 'Actions internationales',
                      DefaultAmount: 13.25476
                    },
                    filterProperties: {},
                    isISR: false
                  },
                  {
                    supportIsin: 'QS0002105TY1',
                    supportName: 'Multipar Monétaire Sélection Classique',
                    supportType: 'FONDS',
                    hasNoFundSheet: true,
                    supportIdNoee: '1039',
                    riskLevelSRRI: '1',
                    riskLevelSRI: '2',
                    performanceValue: -0.387212073073828,
                    source: 'Morningstar',
                    amount: {
                      amount: 1000,
                      addedValue: 40,
                      numberOfUnits: 967
                    },
                    rate: null,
                    supportDetailsUrl: null,
                    legalStatus: 'FCP',
                    isMaster: false,
                    hasContribution: false,
                    netAssetValue: {
                      amount: 11.0915,
                      currency: 'EUR',
                      dateValue: '2020-09-17T00:00:00+02:00'
                    },
                    plans: null,
                    hasVersement: true,
                    hasInteressement: true,
                    hasParticipation: true,
                    nextNavDate: '11/02/2018 00:00:00 +01:00',
                    advancedProperties: {
                      HasThresholdTrigger: false,
                      CategorieClassification: 'Monétaire'
                    },
                    filterProperties: {},
                    isISR: false
                  }
                ],
                order: 4,
                isFree: false,
                filterProperties: {
                  TaxOut: true,
                  VVL: true,
                  VVP: true
                },
                advancedProperties: {
                  HasContribution: false
                }
              }
            ],
            order: 3
          }
        ],
        filterProperties: {
          VVL: true,
          VVP: true,
          TaxOut: true,
          TaxIn: true,
          RetirementOnly: true,
          Abondement: true,
          Invested: true,
          ThresholdTrigger: false,
          StockReallocation: true,
          PartialReallocation: true,
          FlowReallocation: false,
          OadReallocation: false,
          Contribution: true,
          HideInformationVvInf25: true
        },
        advancedProperties: {
          ContributionConsumed: 25,
          ContributionLimit: 5100,
          HasContribution: false
        },
        colors: ['#F3B351'],
        planFamily: 'ES',
        label: "​​Plan d'Epargne Retraite COllectif​",
        shortLabel: 'PERECO',
        horizon: 'Retraite'
      }
    ],
    payment: {
      savers: {
        type: OperationDetailsPaymentModeTypeEnum.COMPANY,
        fullAddress: {
          additionalAddress_1: 'Rue de tolbiac',
          additionalAddress_2: null,
          additionalAddress_3: null,
          street: null,
          country: 'France',
          zipcode: '75000',
          city: 'Paris',
          recipient: null
        },
        bankAccountDetail: {
          domiciliation: null,
          titulaire: null,
          rum: null,
          ics: null,
          iban: 'FR760123012301230123040',
          bic: 'FRBNPP011',
          type: 'VIREMENT'
        }
      },
      amount: 1250
    }
  },
  instructions: [],
  regulation: {
    bankDetails: {
      bic: 'JHGK********HGJK',
      iban: 'KJG****KHFH',
      type: 'lkjh'
    },
    type: 'kjg',
    amountNet: 0,
    status: 'Termine',
    date: '2022-08-26T22:00:00+00:00'
  }
};

export const mockOperationDetailsWithRecovery: OperationDetails = {
  identifier: 'dd9886ea8-1',
  planCode: '19198',
  planName: 'PERO',
  code: 'MV_ARBATDES',
  type: OperationHistoryTypesEnum.INCENTIVE,
  label: 'Affectation de ma participation',
  deductible: true,
  compartmentCode: '0',
  compartmentName: 'Versements obligatoires',
  brutAmount: 1230.6788,
  netAmount: 234.8765,
  abundanceBrutAmount: 230.786899,
  abundanceNetAmount: 223.765,
  abundanceIdentifier: null,
  feeAmount: 120.78,
  socialContributionsAmount: 134.099,
  deductibleAmount: 0,
  statusCode: OperationsHistoryStatusEnum.TERMINE,
  statusLabel: 'Terminé',
  creationDate: '2022-08-26T22:00:00+00:00',
  updateDate: '2022-08-26T22:00:00+00:00',
  cancelable: true,
  editable: false,
  masterOperationCode: OperationDetailsBoTypeEnum.INTERESSEMENT,
  masterOperationType: OperationHistoryTypesEnum.INCENTIVE,
  details: null,
  source: {
    payment: {
      savers: {
        type: OperationDetailsPaymentModeTypeEnum.VIREMENT,
        fullAddress: {
          additionalAddress_1: 'Rue de tolbiac',
          additionalAddress_2: null,
          additionalAddress_3: null,
          street: null,
          country: 'France',
          zipcode: '75000',
          city: 'Paris',
          recipient: null
        },
        bankAccountDetail: {
          domiciliation: null,
          titulaire: null,
          rum: null,
          ics: null,
          iban: 'FR760123012301230123040',
          bic: 'FRBNPP011',
          type: 'VIREMENT'
        }
      },
      amount: 1250
    },
    bulletinOption: {
      companyId: null,
      id: null,
      type: OperationDetailsTypeEnum.INCENTIVE,
      legalFramework: ProfitSharingIncentiveLegalFrameworkEnum.normal,
      defaultAffectations: null,
      derogatoryAffectations: null,
      deadlineDate: '2021-05-21T00:00:00',
      fiscalYearStartDate: null,
      fiscalYearEndDate: null,
      totalAmount: 425000,
      minimumAmount: null,
      netShareAmount: 4250,
      derogatoryAmount: null,
      possibleChoices: null,
      paymentMethod: null,
      possibleContribution: true,
      saverDetails: null
    },
    plans: null
  },
  destination: {
    bulletinOption: null,
    plans: [
      {
        planId: 2001,
        planName: 'PEE',
        planType: 'PEE',
        planFullName: '',
        isCustomPlan: false,
        compartments: [
          {
            code: 'PEE',
            label: undefined,
            managements: [
              {
                isFictive: false,
                id: 'PEE-AMPLISSIM',
                code: 'PEE AMPLISSIM',
                label: '',
                totalAmount: 1530,
                supports: [
                  {
                    supportIsin: 'FR0014000AL1',
                    supportName: 'AM LABEL HARMONIE SOLID ESR-F)- morningstar',
                    supportType: '',
                    hasNoFundSheet: true,
                    source: '',
                    supportIdNoee: '28025',
                    riskLevelSRRI: '1',
                    riskLevelSRI: '',
                    performanceValue: 0,
                    amount: {
                      amount: 1200,
                      addedValue: 1174.1202546,
                      numberOfUnits: 967
                    },
                    rate: null,
                    supportDetailsUrl: null,
                    legalStatus: '',
                    isMaster: false,
                    hasContribution: false,
                    netAssetValue: {
                      amount: 0,
                      currency: 'EUR',
                      dateValue: ''
                    },
                    plans: null,
                    hasVersement: true,
                    hasInteressement: true,
                    hasParticipation: true,
                    nextNavDate: '2022-09-22T22:00:00+00:00',
                    advancedProperties: {},
                    filterProperties: {},
                    isISR: false
                  },
                  {
                    supportIsin: 'FR0010776807',
                    supportName:
                      'Amundi Label Actions Euroland ESR - Hottinguer Patrimoine morninstarg',
                    supportType: '',
                    hasNoFundSheet: true,
                    source: '',
                    supportIdNoee: '1042',
                    riskLevelSRRI: '1',
                    riskLevelSRI: '',
                    performanceValue: 0,
                    amount: {
                      amount: 200,
                      addedValue: 100.01,
                      numberOfUnits: 967
                    },
                    rate: null,
                    supportDetailsUrl: null,
                    legalStatus: '',
                    isMaster: false,
                    hasContribution: true,
                    netAssetValue: {
                      amount: 0,
                      currency: 'EUR',
                      dateValue: ''
                    },
                    plans: null,
                    hasVersement: true,
                    hasInteressement: true,
                    hasParticipation: true,
                    nextNavDate: '2022-09-22T22:00:00+00:00',
                    advancedProperties: {},
                    filterProperties: {},
                    isISR: false
                  },
                  {
                    supportIsin: 'FR0013508793',
                    supportName: 'BNPP SUPPORT 1',
                    supportType: '',
                    hasNoFundSheet: true,
                    source: '',
                    supportIdNoee: '28031',
                    riskLevelSRRI: '1',
                    riskLevelSRI: '',
                    performanceValue: 0,
                    amount: {
                      amount: 130,
                      addedValue: 100.01,
                      numberOfUnits: 967
                    },
                    rate: null,
                    supportDetailsUrl: null,
                    legalStatus: '',
                    isMaster: false,
                    hasContribution: false,
                    netAssetValue: {
                      amount: 0,
                      currency: 'EUR',
                      dateValue: ''
                    },
                    plans: null,
                    hasVersement: true,
                    hasInteressement: true,
                    hasParticipation: true,
                    nextNavDate: '2022-09-22T22:00:00+00:00',
                    advancedProperties: {},
                    filterProperties: {},
                    isISR: false
                  }
                ],
                order: 0,
                isFree: true,
                filterProperties: {
                  Contribution: true,
                  FlowReallocation: false,
                  OadReallocation: true,
                  PartialReallocation: true,
                  RetirementOnly: false,
                  StockReallocation: true,
                  TaxIn: true,
                  VVL: true,
                  VVP: true
                },
                advancedProperties: {}
              }
            ],
            order: 1
          }
        ],
        filterProperties: {
          VVL: true,
          VVP: true,
          TaxOut: false,
          TaxIn: true,
          RetirementOnly: false,
          Abondement: true,
          Invested: true,
          ThresholdTrigger: false,
          StockReallocation: true,
          PartialReallocation: true,
          FlowReallocation: false,
          OadReallocation: true,
          Contribution: true
        },
        advancedProperties: {},
        colors: ['#5B8AB5'],
        planFamily: 'ES',
        label: "​Plan d'Epargne Entreprise",
        shortLabel: 'PEE',
        horizon: 'MoyenTerme'
      },
      {
        planId: 2002,
        planName: 'PERECO',
        planType: 'PERECO',
        planFullName: '',
        isCustomPlan: false,
        compartments: [
          {
            code: 'PER C1',
            label: 'Versements volontaires déductibles',
            managements: [
              {
                isFictive: false,
                id: 'PERECO-C1-L',
                code: 'PERECO C1 L',
                label: 'Gestion Libre',
                totalAmount: 2000,
                supports: [
                  {
                    supportIsin: 'QS0002105TJ2',
                    supportName: 'MULTIMANAGERS ACTIONS EUROPE -FIDELITY',
                    supportType: 'FONDS',
                    hasNoFundSheet: false,
                    supportIdNoee: '1042',
                    riskLevelSRRI: '6',
                    riskLevelSRI: '5',
                    source: 'Morningstar',
                    performanceValue: 19.9222694560339,
                    amount: {
                      amount: 1000,
                      addedValue: 60,
                      numberOfUnits: 967
                    },
                    rate: null,
                    supportDetailsUrl: null,
                    legalStatus: 'FCP',
                    isMaster: false,
                    hasContribution: false,
                    netAssetValue: {
                      amount: 18.8706,
                      currency: 'EUR',
                      dateValue: '2020-09-17T00:00:00+02:00'
                    },
                    plans: null,
                    hasVersement: true,
                    hasInteressement: true,
                    hasParticipation: true,
                    nextNavDate: '09/02/2018 00:00:00 +01:00',
                    advancedProperties: {
                      HasThresholdTrigger: true,
                      ThresholdTriggerValidityDuration: 3,
                      ThresholdTriggerValidityDate: '2023-01-21T00:00:00+01:00',
                      CategorieClassification: 'Actions internationales',
                      DefaultAmount: 289.25476
                    },
                    filterProperties: {},
                    isISR: false
                  },
                  {
                    supportIsin: 'QS0002105TY1',
                    supportName: 'Multipar Monétaire Sélection Classique',
                    supportType: 'FONDS',
                    hasNoFundSheet: true,
                    supportIdNoee: '1039',
                    riskLevelSRRI: '1',
                    riskLevelSRI: '2',
                    performanceValue: -0.387212073073828,
                    source: 'Morningstar',
                    amount: {
                      amount: 1000,
                      addedValue: 40,
                      numberOfUnits: 967
                    },
                    rate: null,
                    supportDetailsUrl: null,
                    legalStatus: 'FCP',
                    isMaster: false,
                    hasContribution: false,
                    netAssetValue: {
                      amount: 11.0915,
                      currency: 'EUR',
                      dateValue: '2020-09-17T00:00:00+02:00'
                    },
                    plans: null,
                    hasVersement: true,
                    hasInteressement: true,
                    hasParticipation: true,
                    nextNavDate: '11/02/2018 00:00:00 +01:00',
                    advancedProperties: {
                      HasThresholdTrigger: false,
                      CategorieClassification: 'Monétaire',
                      DefaultAmount: 39.25476
                    },
                    filterProperties: {},
                    isISR: false
                  }
                ],
                order: 4,
                isFree: true,
                filterProperties: {
                  TaxOut: true,
                  VVL: true,
                  VVP: true
                },
                advancedProperties: {
                  HasContribution: false
                }
              },
              {
                isFictive: false,
                id: 'PERECO-C1-L',
                code: 'PERECO C1 L',
                label: 'Gestion Piloté',
                totalAmount: 2000,
                supports: [
                  {
                    supportIsin: 'QS0002105TJ2',
                    supportName: 'MULTIMANAGERS ACTIONS EUROPE -FIDELITY',
                    supportType: 'FONDS',
                    hasNoFundSheet: false,
                    supportIdNoee: '1042',
                    riskLevelSRRI: '6',
                    riskLevelSRI: '5',
                    performanceValue: 19.9222694560339,
                    source: 'Morningstar',
                    amount: {
                      amount: 1000,
                      addedValue: 60,
                      numberOfUnits: 967
                    },
                    rate: null,
                    supportDetailsUrl: null,
                    legalStatus: 'FCP',
                    isMaster: true,
                    hasContribution: false,
                    netAssetValue: {
                      amount: 18.8706,
                      currency: 'EUR',
                      dateValue: '2020-09-17T00:00:00+02:00'
                    },
                    plans: null,
                    hasVersement: true,
                    hasInteressement: true,
                    hasParticipation: true,
                    nextNavDate: '09/02/2018 00:00:00 +01:00',
                    advancedProperties: {
                      HasThresholdTrigger: true,
                      ThresholdTriggerValidityDuration: 3,
                      ThresholdTriggerValidityDate: '2023-01-21T00:00:00+01:00',
                      CategorieClassification: 'Actions internationales',
                      DefaultAmount: 13.25476
                    },
                    filterProperties: {},
                    isISR: false
                  },
                  {
                    supportIsin: 'QS0002105TY1',
                    supportName: 'Multipar Monétaire Sélection Classique',
                    supportType: 'FONDS',
                    hasNoFundSheet: true,
                    supportIdNoee: '1039',
                    riskLevelSRRI: '1',
                    riskLevelSRI: '2',
                    performanceValue: -0.387212073073828,
                    source: 'Morningstar',
                    amount: {
                      amount: 1000,
                      addedValue: 40,
                      numberOfUnits: 967
                    },
                    rate: null,
                    supportDetailsUrl: null,
                    legalStatus: 'FCP',
                    isMaster: false,
                    hasContribution: false,
                    netAssetValue: {
                      amount: 11.0915,
                      currency: 'EUR',
                      dateValue: '2020-09-17T00:00:00+02:00'
                    },
                    plans: null,
                    hasVersement: true,
                    hasInteressement: true,
                    hasParticipation: true,
                    nextNavDate: '11/02/2018 00:00:00 +01:00',
                    advancedProperties: {
                      HasThresholdTrigger: false,
                      CategorieClassification: 'Monétaire'
                    },
                    filterProperties: {},
                    isISR: false
                  }
                ],
                order: 4,
                isFree: false,
                filterProperties: {
                  TaxOut: true,
                  VVL: true,
                  VVP: true
                },
                advancedProperties: {
                  HasContribution: false
                }
              }
            ],
            order: 3
          }
        ],
        filterProperties: {
          VVL: true,
          VVP: true,
          TaxOut: true,
          TaxIn: true,
          RetirementOnly: true,
          Abondement: true,
          Invested: true,
          ThresholdTrigger: false,
          StockReallocation: true,
          PartialReallocation: true,
          FlowReallocation: false,
          OadReallocation: false,
          Contribution: true,
          HideInformationVvInf25: true
        },
        advancedProperties: {
          ContributionConsumed: 25,
          ContributionLimit: 5100,
          HasContribution: false
        },
        colors: ['#F3B351'],
        planFamily: 'ES',
        label: "​​Plan d'Epargne Retraite COllectif​",
        shortLabel: 'PERECO',
        horizon: 'Retraite'
      }
    ],
    payment: null
  },
  instructions: [],
  regulation: {
    bankDetails: {
      bic: 'JHGK********HGJK',
      iban: 'KJG****KHFH',
      type: 'lkjh'
    },
    type: 'kjg',
    amountNet: 0,
    status: 'Termine',
    date: '2022-08-26T22:00:00+00:00'
  }
};

export const mockOperationDetailsWithoutPlans: OperationDetails = {
  identifier: 'dd9886ea8-1',
  planCode: '19198',
  planName: 'PERO',
  code: 'MV_ARBATDES',
  type: OperationHistoryTypesEnum.INCENTIVE,
  label: 'Affectation de ma participation',
  deductible: true,
  compartmentCode: '0',
  compartmentName: 'Versements obligatoires',
  brutAmount: 1230.6788,
  netAmount: 234.8765,
  abundanceBrutAmount: 230.786899,
  abundanceNetAmount: 223.765,
  abundanceIdentifier: null,
  feeAmount: 120.78,
  socialContributionsAmount: 134.099,
  deductibleAmount: 0,
  statusCode: OperationsHistoryStatusEnum.TERMINE,
  statusLabel: 'Terminé',
  creationDate: '2022-08-26T22:00:00+00:00',
  updateDate: '2022-08-26T22:00:00+00:00',
  cancelable: true,
  editable: false,
  masterOperationCode: OperationDetailsBoTypeEnum.INTERESSEMENT,
  masterOperationType: OperationHistoryTypesEnum.INCENTIVE,
  details: null,
  source: {
    bulletinOption: {
      companyId: null,
      id: null,
      type: OperationDetailsTypeEnum.INCENTIVE,
      legalFramework: ProfitSharingIncentiveLegalFrameworkEnum.normal,
      defaultAffectations: null,
      derogatoryAffectations: null,
      deadlineDate: '2021-05-21T00:00:00',
      fiscalYearStartDate: null,
      fiscalYearEndDate: null,
      totalAmount: 425000,
      minimumAmount: null,
      netShareAmount: 4250,
      derogatoryAmount: null,
      possibleChoices: null,
      paymentMethod: null,
      possibleContribution: true,
      saverDetails: null
    },
    plans: null,
    payment: null
  },
  destination: {
    bulletinOption: null,
    plans: [],
    payment: {
      savers: {
        type: OperationDetailsPaymentModeTypeEnum.COMPANY,
        fullAddress: {
          additionalAddress_1: 'Rue de tolbiac',
          additionalAddress_2: null,
          additionalAddress_3: null,
          street: null,
          country: 'France',
          zipcode: '75000',
          city: 'Paris',
          recipient: null
        },
        bankAccountDetail: {
          domiciliation: null,
          titulaire: null,
          rum: null,
          ics: null,
          iban: 'FR760123012301230123040',
          bic: 'FRBNPP011',
          type: 'VIREMENT'
        }
      },
      amount: 1250
    }
  },
  instructions: [],
  regulation: {
    bankDetails: {
      bic: 'JHGK********HGJK',
      iban: 'KJG****KHFH',
      type: 'lkjh'
    },
    type: 'kjg',
    amountNet: 0,
    status: 'Termine',
    date: '2022-08-26T22:00:00+00:00'
  }
};

export const mockOperationDetailsWithoutPayment: OperationDetails = {
  identifier: 'dd9886ea8-1',
  planCode: '19198',
  planName: 'PERO',
  code: 'MV_ARBATDES',
  type: OperationHistoryTypesEnum.INCENTIVE,
  label: 'Affectation de ma participation',
  deductible: true,
  compartmentCode: '0',
  compartmentName: 'Versements obligatoires',
  brutAmount: 1230.6788,
  netAmount: 234.8765,
  abundanceBrutAmount: 230.786899,
  abundanceNetAmount: 223.765,
  abundanceIdentifier: null,
  feeAmount: 120.78,
  socialContributionsAmount: 134.099,
  deductibleAmount: 0,
  statusCode: OperationsHistoryStatusEnum.TERMINE,
  statusLabel: 'Terminé',
  creationDate: '2022-08-26T22:00:00+00:00',
  updateDate: '2022-08-26T22:00:00+00:00',
  cancelable: true,
  editable: false,
  masterOperationCode: OperationDetailsBoTypeEnum.INTERESSEMENT,
  masterOperationType: OperationHistoryTypesEnum.INCENTIVE,
  details: null,
  source: {
    bulletinOption: {
      companyId: null,
      id: null,
      type: OperationDetailsTypeEnum.INCENTIVE,
      legalFramework: ProfitSharingIncentiveLegalFrameworkEnum.normal,
      defaultAffectations: null,
      derogatoryAffectations: null,
      deadlineDate: '2021-05-21T00:00:00',
      fiscalYearStartDate: null,
      fiscalYearEndDate: null,
      totalAmount: 425000,
      minimumAmount: null,
      netShareAmount: 4250,
      derogatoryAmount: null,
      possibleChoices: null,
      paymentMethod: null,
      possibleContribution: true,
      saverDetails: null
    },
    plans: null,
    payment: null
  },
  destination: {
    bulletinOption: null,
    plans: [
      {
        planId: 2001,
        planName: 'PEE',
        planType: 'PEE',
        planFullName: '',
        isCustomPlan: false,
        compartments: [
          {
            code: 'PEE',
            label: undefined,
            managements: [
              {
                isFictive: false,
                id: 'PEE-AMPLISSIM',
                code: 'PEE AMPLISSIM',
                label: '',
                totalAmount: 1530,
                supports: [
                  {
                    supportIsin: 'FR0014000AL1',
                    supportName: 'AM LABEL HARMONIE SOLID ESR-F)- morningstar',
                    supportType: '',
                    hasNoFundSheet: true,
                    source: '',
                    supportIdNoee: '28025',
                    riskLevelSRRI: '1',
                    riskLevelSRI: '',
                    performanceValue: 0,
                    amount: {
                      amount: 1200,
                      addedValue: 1174.1202546,
                      numberOfUnits: 967
                    },
                    rate: null,
                    supportDetailsUrl: null,
                    legalStatus: '',
                    isMaster: false,
                    hasContribution: false,
                    netAssetValue: {
                      amount: 0,
                      currency: 'EUR',
                      dateValue: ''
                    },
                    plans: null,
                    hasVersement: true,
                    hasInteressement: true,
                    hasParticipation: true,
                    nextNavDate: '2022-09-22T22:00:00+00:00',
                    advancedProperties: {},
                    filterProperties: {},
                    isISR: false
                  },
                  {
                    supportIsin: 'FR0010776807',
                    supportName:
                      'Amundi Label Actions Euroland ESR - Hottinguer Patrimoine morninstarg',
                    supportType: '',
                    hasNoFundSheet: true,
                    source: '',
                    supportIdNoee: '1042',
                    riskLevelSRRI: '1',
                    riskLevelSRI: '',
                    performanceValue: 0,
                    amount: {
                      amount: 200,
                      addedValue: 100.01,
                      numberOfUnits: 967
                    },
                    rate: null,
                    supportDetailsUrl: null,
                    legalStatus: '',
                    isMaster: false,
                    hasContribution: true,
                    netAssetValue: {
                      amount: 0,
                      currency: 'EUR',
                      dateValue: ''
                    },
                    plans: null,
                    hasVersement: true,
                    hasInteressement: true,
                    hasParticipation: true,
                    nextNavDate: '2022-09-22T22:00:00+00:00',
                    advancedProperties: {},
                    filterProperties: {},
                    isISR: false
                  },
                  {
                    supportIsin: 'FR0013508793',
                    supportName: 'BNPP SUPPORT 1',
                    supportType: '',
                    hasNoFundSheet: true,
                    source: '',
                    supportIdNoee: '28031',
                    riskLevelSRRI: '1',
                    riskLevelSRI: '',
                    performanceValue: 0,
                    amount: {
                      amount: 130,
                      addedValue: 100.01,
                      numberOfUnits: 967
                    },
                    rate: null,
                    supportDetailsUrl: null,
                    legalStatus: '',
                    isMaster: false,
                    hasContribution: false,
                    netAssetValue: {
                      amount: 0,
                      currency: 'EUR',
                      dateValue: ''
                    },
                    plans: null,
                    hasVersement: true,
                    hasInteressement: true,
                    hasParticipation: true,
                    nextNavDate: '2022-09-22T22:00:00+00:00',
                    advancedProperties: {},
                    filterProperties: {},
                    isISR: false
                  }
                ],
                order: 0,
                isFree: true,
                filterProperties: {
                  Contribution: true,
                  FlowReallocation: false,
                  OadReallocation: true,
                  PartialReallocation: true,
                  RetirementOnly: false,
                  StockReallocation: true,
                  TaxIn: true,
                  VVL: true,
                  VVP: true
                },
                advancedProperties: {}
              }
            ],
            order: 1
          }
        ],
        filterProperties: {
          VVL: true,
          VVP: true,
          TaxOut: false,
          TaxIn: true,
          RetirementOnly: false,
          Abondement: true,
          Invested: true,
          ThresholdTrigger: false,
          StockReallocation: true,
          PartialReallocation: true,
          FlowReallocation: false,
          OadReallocation: true,
          Contribution: true
        },
        advancedProperties: {},
        colors: ['#5B8AB5'],
        planFamily: 'ES',
        label: "​Plan d'Epargne Entreprise",
        shortLabel: 'PEE',
        horizon: 'MoyenTerme'
      },
      {
        planId: 2002,
        planName: 'PERECO',
        planType: 'PERECO',
        planFullName: '',
        isCustomPlan: false,
        compartments: [
          {
            code: 'PER C1',
            label: 'Versements volontaires déductibles',
            managements: [
              {
                isFictive: false,
                id: 'PERECO-C1-L',
                code: 'PERECO C1 L',
                label: 'Gestion Libre',
                totalAmount: 2000,
                supports: [
                  {
                    supportIsin: 'QS0002105TJ2',
                    supportName: 'MULTIMANAGERS ACTIONS EUROPE -FIDELITY',
                    supportType: 'FONDS',
                    hasNoFundSheet: false,
                    supportIdNoee: '1042',
                    riskLevelSRRI: '6',
                    riskLevelSRI: '5',
                    source: 'Morningstar',
                    performanceValue: 19.9222694560339,
                    amount: {
                      amount: 1000,
                      addedValue: 60,
                      numberOfUnits: 967
                    },
                    rate: null,
                    supportDetailsUrl: null,
                    legalStatus: 'FCP',
                    isMaster: false,
                    hasContribution: false,
                    netAssetValue: {
                      amount: 18.8706,
                      currency: 'EUR',
                      dateValue: '2020-09-17T00:00:00+02:00'
                    },
                    plans: null,
                    hasVersement: true,
                    hasInteressement: true,
                    hasParticipation: true,
                    nextNavDate: '09/02/2018 00:00:00 +01:00',
                    advancedProperties: {
                      HasThresholdTrigger: true,
                      ThresholdTriggerValidityDuration: 3,
                      ThresholdTriggerValidityDate: '2023-01-21T00:00:00+01:00',
                      CategorieClassification: 'Actions internationales',
                      DefaultAmount: 289.25476
                    },
                    filterProperties: {},
                    isISR: false
                  },
                  {
                    supportIsin: 'QS0002105TY1',
                    supportName: 'Multipar Monétaire Sélection Classique',
                    supportType: 'FONDS',
                    hasNoFundSheet: true,
                    supportIdNoee: '1039',
                    riskLevelSRRI: '1',
                    riskLevelSRI: '2',
                    performanceValue: -0.387212073073828,
                    source: 'Morningstar',
                    amount: {
                      amount: 1000,
                      addedValue: 40,
                      numberOfUnits: 967
                    },
                    rate: null,
                    supportDetailsUrl: null,
                    legalStatus: 'FCP',
                    isMaster: false,
                    hasContribution: false,
                    netAssetValue: {
                      amount: 11.0915,
                      currency: 'EUR',
                      dateValue: '2020-09-17T00:00:00+02:00'
                    },
                    plans: null,
                    hasVersement: true,
                    hasInteressement: true,
                    hasParticipation: true,
                    nextNavDate: '11/02/2018 00:00:00 +01:00',
                    advancedProperties: {
                      HasThresholdTrigger: false,
                      CategorieClassification: 'Monétaire',
                      DefaultAmount: 39.25476
                    },
                    filterProperties: {},
                    isISR: false
                  }
                ],
                order: 4,
                isFree: true,
                filterProperties: {
                  TaxOut: true,
                  VVL: true,
                  VVP: true
                },
                advancedProperties: {
                  HasContribution: false
                }
              },
              {
                isFictive: false,
                id: 'PERECO-C1-L',
                code: 'PERECO C1 L',
                label: 'Gestion Piloté',
                totalAmount: 2000,
                supports: [
                  {
                    supportIsin: 'QS0002105TJ2',
                    supportName: 'MULTIMANAGERS ACTIONS EUROPE -FIDELITY',
                    supportType: 'FONDS',
                    hasNoFundSheet: false,
                    supportIdNoee: '1042',
                    riskLevelSRRI: '6',
                    riskLevelSRI: '5',
                    performanceValue: 19.9222694560339,
                    source: 'Morningstar',
                    amount: {
                      amount: 1000,
                      addedValue: 60,
                      numberOfUnits: 967
                    },
                    rate: null,
                    supportDetailsUrl: null,
                    legalStatus: 'FCP',
                    isMaster: true,
                    hasContribution: false,
                    netAssetValue: {
                      amount: 18.8706,
                      currency: 'EUR',
                      dateValue: '2020-09-17T00:00:00+02:00'
                    },
                    plans: null,
                    hasVersement: true,
                    hasInteressement: true,
                    hasParticipation: true,
                    nextNavDate: '09/02/2018 00:00:00 +01:00',
                    advancedProperties: {
                      HasThresholdTrigger: true,
                      ThresholdTriggerValidityDuration: 3,
                      ThresholdTriggerValidityDate: '2023-01-21T00:00:00+01:00',
                      CategorieClassification: 'Actions internationales',
                      DefaultAmount: 13.25476
                    },
                    filterProperties: {},
                    isISR: false
                  },
                  {
                    supportIsin: 'QS0002105TY1',
                    supportName: 'Multipar Monétaire Sélection Classique',
                    supportType: 'FONDS',
                    hasNoFundSheet: true,
                    supportIdNoee: '1039',
                    riskLevelSRRI: '1',
                    riskLevelSRI: '2',
                    performanceValue: -0.387212073073828,
                    source: 'Morningstar',
                    amount: {
                      amount: 1000,
                      addedValue: 40,
                      numberOfUnits: 967
                    },
                    rate: null,
                    supportDetailsUrl: null,
                    legalStatus: 'FCP',
                    isMaster: false,
                    hasContribution: false,
                    netAssetValue: {
                      amount: 11.0915,
                      currency: 'EUR',
                      dateValue: '2020-09-17T00:00:00+02:00'
                    },
                    plans: null,
                    hasVersement: true,
                    hasInteressement: true,
                    hasParticipation: true,
                    nextNavDate: '11/02/2018 00:00:00 +01:00',
                    advancedProperties: {
                      HasThresholdTrigger: false,
                      CategorieClassification: 'Monétaire'
                    },
                    filterProperties: {},
                    isISR: false
                  }
                ],
                order: 4,
                isFree: false,
                filterProperties: {
                  TaxOut: true,
                  VVL: true,
                  VVP: true
                },
                advancedProperties: {
                  HasContribution: false
                }
              }
            ],
            order: 3
          }
        ],
        filterProperties: {
          VVL: true,
          VVP: true,
          TaxOut: true,
          TaxIn: true,
          RetirementOnly: true,
          Abondement: true,
          Invested: true,
          ThresholdTrigger: false,
          StockReallocation: true,
          PartialReallocation: true,
          FlowReallocation: false,
          OadReallocation: false,
          Contribution: true,
          HideInformationVvInf25: true
        },
        advancedProperties: {
          ContributionConsumed: 25,
          ContributionLimit: 5100,
          HasContribution: false
        },
        colors: ['#F3B351'],
        planFamily: 'ES',
        label: "​​Plan d'Epargne Retraite COllectif​",
        shortLabel: 'PERECO',
        horizon: 'Retraite'
      }
    ],
    payment: null
  },
  instructions: [],
  regulation: {
    bankDetails: {
      bic: 'JHGK********HGJK',
      iban: 'KJG****KHFH',
      type: 'lkjh'
    },
    type: 'kjg',
    amountNet: 0,
    status: 'Termine',
    date: '2022-08-26T22:00:00+00:00'
  }
};

export const mockOperationDetailsInstallmentStatus: OperationDetails = {
  identifier: 'O-159112589',
  planCode: '',
  instructions: [],
  planName: 'PERECO',
  code: '268',
  type: OperationHistoryTypesEnum.INSTALLMENT,
  label: 'Versement par carte de crédit sur mon PERECO',
  deductible: false,
  compartmentCode: 'PER C1 bis',
  compartmentName: 'Versements volontaires non déductibles',
  brutAmount: 200,
  netAmount: 200,
  abundanceBrutAmount: 0,
  abundanceNetAmount: 0,
  abundanceIdentifier: null,
  feeAmount: 0,
  socialContributionsAmount: 0,
  deductibleAmount: 0,
  statusCode: OperationsHistoryStatusEnum.IN_PROGRESS,
  statusLabel: 'Terminé',
  creationDate: '2023-05-31T00:00:00',
  updateDate: '',
  cancelable: false,
  editable: false,
  masterOperationCode: OperationDetailsBoTypeEnum.VERSEMENT,
  masterOperationType: OperationHistoryTypesEnum.INSTALLMENT,
  details: {
    conditionalOrder: {
      threshold: 0,
      expirationDate: '2023-03-06T17:35:02+01:00',
      netAssetValue: 0,
      thresholdType: 'VCP',
      netAssetDate: '2023-01-06T17:35:02+01:00'
    },
    optionsBulletin: {
      defaultInterestAmount: null,
      amountNetShare: null,
      entryDeadline: null,
      averageAmount: null,
      totalAmount: null,
      type: null,
      fiscalYearStartDate: null,
      fiscalEndStartDate: null,
      frame: null,
      abundance: null
    },
    withholdingTax: {
      taxableNetAmount: null,
      pasAmount: null,
      rateOrigin: null,
      pasRate: null,
      deductibleCSGAmount: null
    },
    amed: {
      entryDeadline: null
    },
    repayment: {
      validSupportingDocuments: null,
      paymentDate: null,
      dateOfTheEvent: null,
      taxableAmount: null,
      unlockCaseIdentifier: null
    }
  },
  regulation: {
    bankDetails: {
      bic: '',
      iban: '',
      type: ''
    },
    type: 'MONETAIRE',
    amountNet: 200,
    status: '',
    date: '01/06/2023 00:00:00 +00:00'
  },
  source: {
    bulletinOption: {
      companyId: null,
      id: null,
      type: OperationDetailsTypeEnum.INCENTIVE,
      legalFramework: ProfitSharingIncentiveLegalFrameworkEnum.normal,
      defaultAffectations: null,
      derogatoryAffectations: null,
      deadlineDate: '2021-05-21T00:00:00',
      fiscalYearStartDate: null,
      fiscalYearEndDate: null,
      totalAmount: 425000,
      minimumAmount: null,
      netShareAmount: 4250,
      derogatoryAmount: null,
      possibleChoices: null,
      paymentMethod: null,
      possibleContribution: true,
      saverDetails: null
    },
    plans: [],
    payment: {
      savers: {
        type: OperationDetailsPaymentModeTypeEnum.CHEQUE,
        fullAddress: {
          additionalAddress_1: 'Rue de tolbiac',
          additionalAddress_2: null,
          additionalAddress_3: null,
          street: null,
          country: 'France',
          zipcode: '75000',
          city: 'Paris',
          recipient: null
        },
        bankAccountDetail: {
          domiciliation: null,
          titulaire: null,
          rum: null,
          ics: null,
          iban: 'FR760123012301230123040',
          bic: 'FRBNPP011',
          type: 'VIREMENT'
        }
      },
      amount: 200
    }
  },
  destination: {
    bulletinOption: null,
    plans: [
      {
        planId: 501001,
        planName: 'PERECO',
        planType: 'PERCO',
        planFullName: '',
        isCustomPlan: false,
        compartments: [
          {
            code: 'PER C1 bis',
            label: 'Versements volontaires non déductibles',
            descriptionLabel:
              "Cette épargne correspond aux versements volontaires que j'ai effectués sans l'option de déductibilité des sommes de mon revenu imposable.",
            managements: [
              {
                isFictive: false,
                id: 'PERECO-C1-L',
                code: 'PERECO C1 L',
                label: 'Gestion Piloté',
                totalAmount: 2000,
                supports: [
                  {
                    supportIsin: 'QS0002105TJ2',
                    supportName: 'MULTIMANAGERS ACTIONS EUROPE -FIDELITY',
                    supportType: 'FONDS',
                    hasNoFundSheet: false,
                    supportIdNoee: '1042',
                    riskLevelSRRI: '6',
                    riskLevelSRI: '5',
                    performanceValue: 19.9222694560339,
                    source: 'Morningstar',
                    amount: {
                      amount: 1000,
                      addedValue: 60,
                      numberOfUnits: 967
                    },
                    rate: null,
                    supportDetailsUrl: null,
                    legalStatus: 'FCP',
                    isMaster: true,
                    hasContribution: false,
                    netAssetValue: {
                      amount: 18.8706,
                      currency: 'EUR',
                      dateValue: '2020-09-17T00:00:00+02:00'
                    },
                    plans: null,
                    hasVersement: true,
                    hasInteressement: true,
                    hasParticipation: true,
                    nextNavDate: '09/02/2018 00:00:00 +01:00',
                    advancedProperties: {
                      HasThresholdTrigger: true,
                      ThresholdTriggerValidityDuration: 3,
                      ThresholdTriggerValidityDate: '2023-01-21T00:00:00+01:00',
                      CategorieClassification: 'Actions internationales',
                      DefaultAmount: 13.25476
                    },
                    filterProperties: {},
                    isISR: false
                  },
                  {
                    supportIsin: 'QS0002105TY1',
                    supportName: 'Multipar Monétaire Sélection Classique',
                    supportType: 'FONDS',
                    hasNoFundSheet: true,
                    supportIdNoee: '1039',
                    riskLevelSRRI: '1',
                    riskLevelSRI: '2',
                    performanceValue: -0.387212073073828,
                    source: 'Morningstar',
                    amount: {
                      amount: 1000,
                      addedValue: 40,
                      numberOfUnits: 967
                    },
                    rate: null,
                    supportDetailsUrl: null,
                    legalStatus: 'FCP',
                    isMaster: false,
                    hasContribution: false,
                    netAssetValue: {
                      amount: 11.0915,
                      currency: 'EUR',
                      dateValue: '2020-09-17T00:00:00+02:00'
                    },
                    plans: null,
                    hasVersement: true,
                    hasInteressement: true,
                    hasParticipation: true,
                    nextNavDate: '11/02/2018 00:00:00 +01:00',
                    advancedProperties: {
                      HasThresholdTrigger: false,
                      CategorieClassification: 'Monétaire'
                    },
                    filterProperties: {},
                    isISR: false
                  }
                ],
                order: 4,
                isFree: false,
                filterProperties: {
                  TaxOut: true,
                  VVL: true,
                  VVP: true
                },
                advancedProperties: {
                  HasContribution: false
                }
              },
              {
                id: '0000538718',
                code: 'Libre',
                label: 'Gestion Libre',
                totalAmount: 200,
                supports: [
                  {
                    supportIsin: 'QS0002105TR5',
                    supportName: 'MULTIPAR MONETAIRE SOC RESP C',
                    supportType: '',
                    hasNoFundSheet: true,
                    source: '',
                    supportIdNoee: '1035',
                    riskLevelSRRI: '1',
                    riskLevelSRI: '',
                    performanceValue: 0,
                    amount: {
                      amount: 100,
                      addedValue: 0,
                      numberOfUnits: 100
                    },
                    rate: null,
                    supportDetailsUrl: null,
                    legalStatus: '',
                    isMaster: false,
                    hasContribution: false,
                    netAssetValue: {
                      amount: 11.2652,
                      currency: 'EUR',
                      dateValue: '2023-06-01T00:00:00+00:00'
                    },
                    plans: null,
                    hasVersement: true,
                    hasInteressement: false,
                    hasParticipation: false,
                    nextNavDate: '2023-12-03T00:00:00+00:00',
                    advancedProperties: {
                      HasThresholdTrigger: false,
                      AmountNet: 100,
                      ContributionAmountNet: 0,
                      ContributionAmountBrut: 0,
                      AvailabilityDate: '2025-12-26T00:00:00+00:00',
                      AvailabilityLabel: 'DetailOperationEcheanceTypeRetraite',
                      IsAvailable: false
                    },
                    filterProperties: {},
                    isISR: false
                  },
                  {
                    supportIsin: 'QS0002105TG8',
                    supportName: 'MULTIPAR GREEN BOND',
                    supportType: '',
                    source: '',
                    hasNoFundSheet: true,

                    supportIdNoee: '1040',
                    riskLevelSRRI: '3',
                    riskLevelSRI: '0',
                    performanceValue: 0,
                    amount: {
                      amount: 50,
                      addedValue: 0,
                      numberOfUnits: 0
                    },
                    rate: null,
                    supportDetailsUrl: null,
                    legalStatus: '',
                    isMaster: false,
                    hasContribution: false,
                    netAssetValue: {
                      amount: 14.0187,
                      currency: 'EUR',
                      dateValue: '2023-06-01T00:00:00+00:00'
                    },
                    plans: null,
                    hasVersement: true,
                    hasInteressement: false,
                    hasParticipation: false,
                    nextNavDate: '2023-12-01T00:00:00+00:00',
                    advancedProperties: {
                      HasThresholdTrigger: false,
                      AmountNet: 50,
                      ContributionAmountNet: 0,
                      ContributionAmountBrut: 0,
                      AvailabilityDate: '',
                      AvailabilityLabel: 'DetailOperationEcheanceTypeRETRAITE',
                      IsAvailable: false
                    },
                    filterProperties: {},
                    isISR: false
                  },
                  {
                    supportIsin: 'QS0004088918',
                    supportName: 'IMPACT ISR RENDEMENT SOLIDAIRE I',
                    supportType: '',
                    hasNoFundSheet: true,
                    source: '',
                    supportIdNoee: '3376',
                    riskLevelSRRI: '3',
                    riskLevelSRI: 'null',
                    performanceValue: 0,
                    amount: {
                      amount: 50,
                      addedValue: 0,
                      numberOfUnits: 0
                    },
                    rate: null,
                    supportDetailsUrl: null,
                    legalStatus: '',
                    isMaster: false,
                    hasContribution: false,
                    netAssetValue: {
                      amount: 24.7843,
                      currency: 'EUR',
                      dateValue: '2023-06-01T00:00:00+00:00'
                    },
                    plans: null,
                    hasVersement: true,
                    hasInteressement: false,
                    hasParticipation: false,
                    nextNavDate: '2023-12-01T00:00:00+00:00',
                    advancedProperties: {
                      HasThresholdTrigger: false,
                      AmountNet: 50,
                      ContributionAmountNet: 0,
                      ContributionAmountBrut: 0,
                      AvailabilityDate: '',
                      AvailabilityLabel: 'DetailOperationEcheanceTypeRETRAITE',
                      IsAvailable: false
                    },
                    filterProperties: {},
                    isISR: false
                  }
                ],
                order: 4,
                isFree: true,
                filterProperties: {
                  TaxIn: true,
                  VVL: true,
                  VVP: false
                },
                advancedProperties: {
                  HasIncorrectDrivingData: false
                },
                isFictive: false
              }
            ],
            order: 3,
            isFictive: false,
            filterProperties: {
              TaxIn: true
            },
            advancedProperties: {},
            totalAmount: 200
          }
        ],
        filterProperties: {
          VVL: true,
          VVP: false,
          TaxOut: true,
          TaxIn: true,
          RetirementOnly: true,
          Abondement: false,
          Invested: true,
          ThresholdTrigger: true,
          StockReallocation: true,
          PartialReallocation: true,
          FlowReallocation: false,
          OadReallocation: true,
          Contribution: true,
          HideInformationVvInf25: true
        },
        advancedProperties: {
          AmountNet: 200
        },
        colors: ['#F3B351'],
        planFamily: 'ES',
        label: "​​Plan d'Epargne Retraite COllectif​",
        shortLabel: 'PERECO',
        horizon: 'Retraite'
      }
    ],
    payment: null
  },
  family: OperationFamilyEnum.INSTALLMENT
};

export const mockOperationDetailsVersement: OperationDetails = {
  identifier: 'dd9886ea8-1',
  planCode: '19198',
  planName: 'PERO',
  family: OperationFamilyEnum.INSTALLMENT,
  code: 'MV_ARBATDES',
  type: OperationHistoryTypesEnum.INCENTIVE,
  label: 'Affectation de ma participation',
  deductible: true,
  compartmentCode: '0',
  compartmentName: 'Versements obligatoires',
  brutAmount: 1230.6788,
  netAmount: 234.8765,
  abundanceBrutAmount: 230.786899,
  abundanceNetAmount: 223.765,
  abundanceIdentifier: null,
  feeAmount: 120.78,
  socialContributionsAmount: 134.099,
  deductibleAmount: 0,
  statusCode: OperationsHistoryStatusEnum.TERMINE,
  statusLabel: 'Terminé',
  creationDate: '2022-08-26T22:00:00+00:00',
  updateDate: '2022-08-26T22:00:00+00:00',
  cancelable: true,
  editable: false,
  masterOperationCode: OperationDetailsBoTypeEnum.VERSEMENT,
  masterOperationType: OperationHistoryTypesEnum.INCENTIVE,
  details: null,
  source: {
    bulletinOption: {
      companyId: null,
      id: null,
      type: OperationDetailsTypeEnum.INCENTIVE,
      legalFramework: ProfitSharingIncentiveLegalFrameworkEnum.normal,
      defaultAffectations: null,
      derogatoryAffectations: null,
      deadlineDate: '2021-05-21T00:00:00',
      fiscalYearStartDate: null,
      fiscalYearEndDate: null,
      totalAmount: 425000,
      minimumAmount: null,
      netShareAmount: 4250,
      derogatoryAmount: null,
      possibleChoices: null,
      paymentMethod: null,
      possibleContribution: true,
      saverDetails: null
    },
    plans: null,
    payment: {
      savers: {
        type: OperationDetailsPaymentModeTypeEnum.VIREMENT,
        fullAddress: {
          additionalAddress_1: 'Rue de tolbiac',
          additionalAddress_2: null,
          additionalAddress_3: null,
          street: null,
          country: 'France',
          zipcode: '75000',
          city: 'Paris',
          recipient: null
        },
        bankAccountDetail: {
          domiciliation: null,
          titulaire: null,
          rum: null,
          ics: null,
          iban: 'FR760123012301230123040',
          bic: 'FRBNPP011',
          type: 'VIREMENT'
        }
      },
      amount: 1250
    }
  },
  destination: {
    bulletinOption: null,
    plans: [
      {
        planId: 2002,
        planName: 'PERECO',
        planType: 'PERECO',
        planFullName: '',
        isCustomPlan: false,
        compartments: [
          {
            code: 'PER C1',
            label: 'Versements volontaires déductibles',
            managements: [
              {
                isFictive: false,
                id: 'PERECO-C1-L',
                code: 'PERECO C1 L',
                label: 'Gestion Libre',
                totalAmount: 2000,
                supports: [
                  {
                    supportIsin: 'QS0002105TJ2',
                    supportName: 'MULTIMANAGERS ACTIONS EUROPE -FIDELITY',
                    supportType: 'FONDS',
                    hasNoFundSheet: false,
                    supportIdNoee: '1042',
                    riskLevelSRRI: '6',
                    riskLevelSRI: '5',
                    source: 'Morningstar',
                    performanceValue: 19.9222694560339,
                    amount: {
                      amount: 1000,
                      addedValue: 60,
                      numberOfUnits: 967
                    },
                    rate: null,
                    supportDetailsUrl: null,
                    legalStatus: 'FCP',
                    isMaster: false,
                    hasContribution: false,
                    netAssetValue: {
                      amount: 18.8706,
                      currency: 'EUR',
                      dateValue: '2020-09-17T00:00:00+02:00'
                    },
                    plans: null,
                    hasVersement: true,
                    hasInteressement: true,
                    hasParticipation: true,
                    nextNavDate: '09/02/2018 00:00:00 +01:00',
                    advancedProperties: {
                      HasThresholdTrigger: true,
                      ThresholdTriggerValidityDuration: 3,
                      ThresholdTriggerValidityDate: '2023-01-21T00:00:00+01:00',
                      CategorieClassification: 'Actions internationales',
                      DefaultAmount: 289.25476
                    },
                    filterProperties: {},
                    isISR: false
                  },
                  {
                    supportIsin: 'QS0002105TY1',
                    supportName: 'Multipar Monétaire Sélection Classique',
                    supportType: 'FONDS',
                    hasNoFundSheet: true,
                    supportIdNoee: '1039',
                    riskLevelSRRI: '1',
                    riskLevelSRI: '2',
                    performanceValue: -0.387212073073828,
                    source: 'Morningstar',
                    amount: {
                      amount: 1000,
                      addedValue: 40,
                      numberOfUnits: 967
                    },
                    rate: null,
                    supportDetailsUrl: null,
                    legalStatus: 'FCP',
                    isMaster: false,
                    hasContribution: false,
                    netAssetValue: {
                      amount: 11.0915,
                      currency: 'EUR',
                      dateValue: '2020-09-17T00:00:00+02:00'
                    },
                    plans: null,
                    hasVersement: true,
                    hasInteressement: true,
                    hasParticipation: true,
                    nextNavDate: '11/02/2018 00:00:00 +01:00',
                    advancedProperties: {
                      HasThresholdTrigger: false,
                      CategorieClassification: 'Monétaire',
                      DefaultAmount: 39.25476
                    },
                    filterProperties: {},
                    isISR: false
                  }
                ],
                order: 4,
                isFree: true,
                filterProperties: {
                  TaxOut: true,
                  VVL: true,
                  VVP: true
                },
                advancedProperties: {
                  HasContribution: false
                }
              },
              {
                isFictive: false,
                id: 'PERECO-C1-L',
                code: 'PERECO C1 L',
                label: 'Gestion Piloté',
                totalAmount: 2000,
                supports: [
                  {
                    supportIsin: 'QS0002105TJ2',
                    supportName: 'MULTIMANAGERS ACTIONS EUROPE -FIDELITY',
                    supportType: 'FONDS',
                    hasNoFundSheet: false,
                    supportIdNoee: '1042',
                    riskLevelSRRI: '6',
                    riskLevelSRI: '5',
                    performanceValue: 19.9222694560339,
                    source: 'Morningstar',
                    amount: {
                      amount: 1000,
                      addedValue: 60,
                      numberOfUnits: 967
                    },
                    rate: null,
                    supportDetailsUrl: null,
                    legalStatus: 'FCP',
                    isMaster: true,
                    hasContribution: false,
                    netAssetValue: {
                      amount: 18.8706,
                      currency: 'EUR',
                      dateValue: '2020-09-17T00:00:00+02:00'
                    },
                    plans: null,
                    hasVersement: true,
                    hasInteressement: true,
                    hasParticipation: true,
                    nextNavDate: '09/02/2018 00:00:00 +01:00',
                    advancedProperties: {
                      HasThresholdTrigger: true,
                      ThresholdTriggerValidityDuration: 3,
                      ThresholdTriggerValidityDate: '2023-01-21T00:00:00+01:00',
                      CategorieClassification: 'Actions internationales',
                      DefaultAmount: 13.25476
                    },
                    filterProperties: {},
                    isISR: false
                  },
                  {
                    supportIsin: 'QS0002105TY1',
                    supportName: 'Multipar Monétaire Sélection Classique',
                    supportType: 'FONDS',
                    hasNoFundSheet: true,
                    supportIdNoee: '1039',
                    riskLevelSRRI: '1',
                    riskLevelSRI: '2',
                    performanceValue: -0.387212073073828,
                    source: 'Morningstar',
                    amount: {
                      amount: 1000,
                      addedValue: 40,
                      numberOfUnits: 967
                    },
                    rate: null,
                    supportDetailsUrl: null,
                    legalStatus: 'FCP',
                    isMaster: false,
                    hasContribution: false,
                    netAssetValue: {
                      amount: 11.0915,
                      currency: 'EUR',
                      dateValue: '2020-09-17T00:00:00+02:00'
                    },
                    plans: null,
                    hasVersement: true,
                    hasInteressement: true,
                    hasParticipation: true,
                    nextNavDate: '11/02/2018 00:00:00 +01:00',
                    advancedProperties: {
                      HasThresholdTrigger: false,
                      CategorieClassification: 'Monétaire'
                    },
                    filterProperties: {},
                    isISR: false
                  }
                ],
                order: 4,
                isFree: false,
                filterProperties: {
                  TaxOut: true,
                  VVL: true,
                  VVP: true
                },
                advancedProperties: {
                  HasContribution: false
                }
              }
            ],
            order: 3
          }
        ],
        filterProperties: {
          VVL: true,
          VVP: true,
          TaxOut: true,
          TaxIn: true,
          RetirementOnly: true,
          Abondement: true,
          Invested: true,
          ThresholdTrigger: false,
          StockReallocation: true,
          PartialReallocation: true,
          FlowReallocation: false,
          OadReallocation: false,
          Contribution: true,
          HideInformationVvInf25: true
        },
        advancedProperties: {
          ContributionConsumed: 25,
          ContributionLimit: 5100,
          HasContribution: false
        },
        colors: ['#F3B351'],
        planFamily: 'ES',
        label: "​​Plan d'Epargne Retraite COllectif​",
        shortLabel: 'PERECO',
        horizon: 'Retraite'
      }
    ],
    payment: null
  },
  instructions: [],
  regulation: {
    bankDetails: {
      bic: 'JHGK********HGJK',
      iban: 'KJG****KHFH',
      type: 'lkjh'
    },
    type: 'kjg',
    amountNet: 0,
    status: 'Termine',
    date: '2022-08-26T22:00:00+00:00'
  } // Versement Bancaire demo
};

export const mockOperationDetailsTransfertDetails: OperationDetails = {
  identifier: '1-2BQGDX5',
  planCode: '',
  planName: 'PEE',
  code: '195',
  type: OperationHistoryTypesEnum.TRANSFER_IN,
  label: 'Transfert sur mon PEE',
  deductible: false,
  compartmentCode: 'PEE',
  compartmentName: 'Epargne salariale',
  brutAmount: 0,
  netAmount: 0,
  abundanceBrutAmount: 0,
  abundanceNetAmount: 0,
  abundanceIdentifier: null,
  statusCode: 'InProgress',
  statusLabel: 'En cours de traitement',
  creationDate: '2023-04-27T10:11:19',
  cancelable: true,
  details: {
    optionsBulletin: {
      defaultInterestAmount: null,
      amountNetShare: null,
      entryDeadline: null,
      averageAmount: null,
      totalAmount: null,
      type: null,
      fiscalYearStartDate: null,
      fiscalEndStartDate: null,
      frame: null,
      abundance: null
    },
    withholdingTax: {
      taxableNetAmount: null,
      pasAmount: null,
      rateOrigin: null,
      pasRate: null,
      deductibleCSGAmount: null
    },
    amed: {
      entryDeadline: null
    },
    conditionalOrder: {
      threshold: 0,
      expirationDate: '2023-03-06T17:35:02+01:00',
      netAssetValue: 0,
      thresholdType: 'VCP',
      netAssetDate: '2023-01-06T17:35:02+01:00'
    },
    repayment: {
      validSupportingDocuments: null,
      paymentDate: null,
      dateOfTheEvent: null,
      taxableAmount: null,
      unlockCaseIdentifier: null
    }
  },
  instructions: [],
  feeAmount: 120.78,
  socialContributionsAmount: 134.099,
  deductibleAmount: 0,
  editable: false,
  masterOperationCode: OperationDetailsBoTypeEnum.VERSEMENT,
  masterOperationType: OperationHistoryTypesEnum.INCENTIVE,
  updateDate: '2022-08-26T22:00:00+00:00',
  regulation: {
    bankDetails: {
      bic: 'JHGK********HGJK',
      iban: 'KJG****KHFH',
      type: 'lkjh'
    },
    type: 'kjg',
    amountNet: 0,
    status: 'Termine',
    date: '2022-08-26T22:00:00+00:00'
  },
  source: {
    bulletinOption: {
      companyId: null,
      id: null,
      type: null,
      legalFramework: null,
      defaultAffectations: null,
      derogatoryAffectations: null,
      deadlineDate: null,
      fiscalYearStartDate: null,
      fiscalYearEndDate: null,
      totalAmount: null,
      minimumAmount: null,
      netShareAmount: 0,
      derogatoryAmount: null,
      possibleChoices: null,
      paymentMethod: null,
      possibleContribution: false,
      saverDetails: null
    },
    plans: [
      {
        planId: 1001,
        planName: 'PEE',
        planType: 'PEE',
        planFullName: '',
        isCustomPlan: false,
        compartments: [
          {
            code: 'PEE',
            label: 'Epargne salariale',
            managements: [
              {
                id: '0000099970',
                code: 'PEE',
                label: 'PEE',
                totalAmount: 1697.5996228,
                supports: [
                  {
                    supportIsin: 'QS0002105TQ7',
                    supportName: 'MULTIPAR EUROPE EQUILIBRE',
                    supportType: '',
                    hasNoFundSheet: true,
                    source: '',
                    supportIdNoee: '1006',
                    riskLevelSRRI: '4',
                    riskLevelSRI: '',
                    performanceValue: 0,
                    amount: {
                      amount: 1697.5996228,
                      addedValue: -156.26038505444,
                      numberOfUnits: 24.8151
                    },
                    rate: null,
                    supportDetailsUrl: null,
                    legalStatus: '',
                    isMaster: false,
                    hasContribution: false,
                    netAssetValue: {
                      amount: 6.841,
                      currency: 'EUR',
                      dateValue: '2023-05-02T00:00:00+00:00'
                    },
                    plans: null,
                    hasVersement: false,
                    hasInteressement: false,
                    hasParticipation: false,
                    nextNavDate: '2023-06-02T00:00:00+00:00',
                    advancedProperties: {
                      HasThresholdTrigger: false,
                      AmountNet: 1697.5996228,
                      ContributionAmountNet: 0,
                      ContributionAmountBrut: 0,
                      AvailabilityDate: '2025-12-26T00:00:00+00:00',
                      AvailabilityLabel: 'DetailOperationEcheanceTypeINDISPONIBLE',
                      IsAvailable: true
                    },
                    filterProperties: {},
                    isISR: false
                  }
                ],
                order: 0,
                isFree: true,
                filterProperties: {
                  Contribution: true,
                  FlowReallocation: false,
                  OadReallocation: true,
                  PartialReallocation: true,
                  RetirementOnly: false,
                  StockReallocation: true,
                  TaxIn: true,
                  ThresholdTrigger: false,
                  VVL: true,
                  VVP: false
                },
                advancedProperties: {},
                isFictive: false
              }
            ],
            order: 1,
            isFictive: false,
            filterProperties: {},
            advancedProperties: {},
            totalAmount: 1697.5996228
          }
        ],
        filterProperties: {
          VVL: true,
          VVP: false,
          TaxOut: false,
          TaxIn: true,
          RetirementOnly: false,
          Abondement: false,
          Invested: true,
          ThresholdTrigger: false,
          StockReallocation: true,
          PartialReallocation: true,
          FlowReallocation: false,
          OadReallocation: true,
          Contribution: true
        },
        advancedProperties: {
          AmountNet: 1697.5996228
        },
        colors: ['#5B8AB5'],
        planFamily: 'ES',
        label: "​Plan d'Epargne Entreprise",
        shortLabel: 'PEE',
        horizon: 'MoyenTerme'
      }
    ],
    payment: null
  },
  destination: {
    bulletinOption: null,
    plans: [
      {
        planId: 1001,
        planName: 'PEE',
        planType: 'PEE',
        planFullName: '',
        isCustomPlan: false,
        compartments: [
          {
            code: 'PEE',
            label: 'Epargne salariale',
            managements: [
              {
                id: '0000099970',
                code: 'PEE',
                label: 'PEE',
                totalAmount: 1697.599579,
                supports: [
                  {
                    supportIsin: 'QS0011114079',
                    supportName: 'MULTIMANAG ACT IN CARMIG C',
                    supportType: '',
                    hasNoFundSheet: true,
                    source: '',
                    supportIdNoee: '1048',
                    riskLevelSRRI: '6',
                    riskLevelSRI: '',
                    performanceValue: 0,
                    amount: {
                      amount: 169.76034454,
                      addedValue: 0,
                      numberOfUnits: 9.6046
                    },
                    rate: null,
                    supportDetailsUrl: null,
                    legalStatus: '',
                    isMaster: false,
                    hasContribution: false,
                    netAssetValue: {
                      amount: 17.6749,
                      currency: 'EUR',
                      dateValue: '2022-07-13T00:00:00+00:00'
                    },
                    plans: null,
                    hasVersement: true,
                    hasInteressement: false,
                    hasParticipation: true,
                    nextNavDate: '2023-06-01T00:00:00+00:00',
                    advancedProperties: {
                      HasThresholdTrigger: false,
                      AmountNet: 169.76034454,
                      ContributionAmountNet: 0,
                      ContributionAmountBrut: 0,
                      AvailabilityDate: '2025-12-26T00:00:00+00:00',
                      AvailabilityLabel: 'DetailOperationEcheanceTypeINDISPONIBLE',
                      IsAvailable: true
                    },
                    filterProperties: {},
                    isISR: false
                  },
                  {
                    supportIsin: 'QS0002105TG8',
                    supportName: 'MULTIPAR GREEN BOND',
                    supportType: '',
                    hasNoFundSheet: true,
                    source: '',
                    supportIdNoee: '1040',
                    riskLevelSRRI: '3',
                    riskLevelSRI: '',
                    performanceValue: 0,
                    amount: {
                      amount: 1527.83923446,
                      addedValue: 0,
                      numberOfUnits: 108.9858
                    },
                    rate: null,
                    supportDetailsUrl: null,
                    legalStatus: '',
                    isMaster: false,
                    hasContribution: false,
                    netAssetValue: {
                      amount: 14.0187,
                      currency: 'EUR',
                      dateValue: '2022-07-12T00:00:00+00:00'
                    },
                    plans: null,
                    hasVersement: true,
                    hasInteressement: false,
                    hasParticipation: true,
                    nextNavDate: '2023-06-01T00:00:00+00:00',
                    advancedProperties: {
                      HasThresholdTrigger: false,
                      AmountNet: 1527.83923446,
                      ContributionAmountNet: 0,
                      ContributionAmountBrut: 0,
                      AvailabilityDate: '2025-12-26T00:00:00+00:00',
                      AvailabilityLabel: 'DetailOperationEcheanceTypeINDISPONIBLE',
                      IsAvailable: true
                    },
                    filterProperties: {},
                    isISR: false
                  }
                ],
                order: 0,
                isFree: true,
                filterProperties: {
                  Contribution: true,
                  FlowReallocation: false,
                  OadReallocation: true,
                  PartialReallocation: true,
                  RetirementOnly: false,
                  StockReallocation: true,
                  TaxIn: true,
                  ThresholdTrigger: false,
                  VVL: true,
                  VVP: false
                },
                advancedProperties: {},
                isFictive: false
              }
            ],
            order: 1,
            isFictive: false,
            filterProperties: {
              Contribution: true,
              FlowReallocation: false,
              OadReallocation: true,
              PartialReallocation: true,
              RetirementOnly: false,
              StockReallocation: true,
              TaxIn: true,
              ThresholdTrigger: false
            },
            advancedProperties: {},
            totalAmount: 1697.599579
          }
        ],
        filterProperties: {
          VVL: true,
          VVP: false,
          TaxOut: false,
          TaxIn: true,
          RetirementOnly: false,
          Abondement: false,
          Invested: true,
          ThresholdTrigger: false,
          StockReallocation: true,
          PartialReallocation: true,
          FlowReallocation: false,
          OadReallocation: true,
          Contribution: true
        },
        advancedProperties: {
          AmountNet: 1697.599579
        },
        colors: ['#5B8AB5'],
        planFamily: 'ES',
        label: "​Plan d'Epargne Entreprise",
        shortLabel: 'PEE',
        horizon: 'MoyenTerme'
      }
    ],
    payment: null
  }
};

export const mockOperationDetailsVersementCB: OperationDetails = {
  identifier: 'dd9886ea8-1',
  planCode: '19198',
  planName: 'PERO',
  code: 'MV_ARBATDES',
  type: OperationHistoryTypesEnum.INSTALLMENT,
  label: 'Affectation de ma participation',
  deductible: true,
  compartmentCode: '0',
  compartmentName: 'Versements obligatoires',
  brutAmount: 1230.6788,
  netAmount: 234.8765,
  abundanceBrutAmount: 230.786899,
  abundanceNetAmount: 223.765,
  abundanceIdentifier: null,
  feeAmount: 120.78,
  socialContributionsAmount: 134.099,
  deductibleAmount: 0,
  statusCode: OperationsHistoryStatusEnum.TERMINE,
  statusLabel: 'Terminé',
  creationDate: '2022-08-26T22:00:00+00:00',
  updateDate: '2022-08-26T22:00:00+00:00',
  cancelable: true,
  editable: false,
  masterOperationCode: OperationDetailsBoTypeEnum.VERSEMENT,
  masterOperationType: OperationHistoryTypesEnum.INCENTIVE,
  details: null,
  source: {
    bulletinOption: {
      companyId: null,
      id: null,
      type: OperationDetailsTypeEnum.INCENTIVE,
      legalFramework: ProfitSharingIncentiveLegalFrameworkEnum.normal,
      defaultAffectations: null,
      derogatoryAffectations: null,
      deadlineDate: '2021-05-21T00:00:00',
      fiscalYearStartDate: null,
      fiscalYearEndDate: null,
      totalAmount: 425000,
      minimumAmount: null,
      netShareAmount: 4250,
      derogatoryAmount: null,
      possibleChoices: null,
      paymentMethod: null,
      possibleContribution: true,
      saverDetails: null
    },
    plans: null,
    payment: {
      savers: {
        fullAddress: {
          additionalAddress_1: 'Rue de tolbiac',
          additionalAddress_2: null,
          additionalAddress_3: null,
          street: null,
          country: 'France',
          zipcode: '75000',
          city: 'Paris',
          recipient: null
        },
        bankAccountDetail: {
          domiciliation: null,
          titulaire: null,
          rum: null,
          ics: null,
          iban: '13906000408314661000051',
          bic: 'AGRIFRPP839',
          type: 'VIREMENT'
        },
        type: OperationDetailsPaymentModeTypeEnum.CB
      },
      amount: 1250
    }
  },
  destination: {
    bulletinOption: null,
    plans: [
      {
        planId: 2002,
        planName: 'PERECO',
        planType: 'PERECO',
        planFullName: '',
        isCustomPlan: false,
        compartments: [
          {
            code: 'PER C1',
            label: 'Versements volontaires déductibles',
            managements: [
              {
                isFictive: false,
                id: 'PERECO-C1-L',
                code: 'PERECO C1 L',
                label: 'Gestion Libre',
                totalAmount: 2000,
                supports: [
                  {
                    supportIsin: 'QS0002105TJ2',
                    supportName: 'MULTIMANAGERS ACTIONS EUROPE -FIDELITY',
                    supportType: 'FONDS',
                    hasNoFundSheet: false,
                    supportIdNoee: '1042',
                    riskLevelSRRI: '6',
                    riskLevelSRI: '5',
                    source: 'Morningstar',
                    performanceValue: 19.9222694560339,
                    amount: {
                      amount: 1000,
                      addedValue: 60,
                      numberOfUnits: 967
                    },
                    rate: null,
                    supportDetailsUrl: null,
                    legalStatus: 'FCP',
                    isMaster: false,
                    hasContribution: false,
                    netAssetValue: {
                      amount: 18.8706,
                      currency: 'EUR',
                      dateValue: '2020-09-17T00:00:00+02:00'
                    },
                    plans: null,
                    hasVersement: true,
                    hasInteressement: true,
                    hasParticipation: true,
                    nextNavDate: '09/02/2018 00:00:00 +01:00',
                    advancedProperties: {
                      HasThresholdTrigger: true,
                      ThresholdTriggerValidityDuration: 3,
                      ThresholdTriggerValidityDate: '2023-01-21T00:00:00+01:00',
                      CategorieClassification: 'Actions internationales',
                      DefaultAmount: 289.25476
                    },
                    filterProperties: {},
                    isISR: false
                  },
                  {
                    supportIsin: 'QS0002105TY1',
                    supportName: 'Multipar Monétaire Sélection Classique',
                    supportType: 'FONDS',
                    hasNoFundSheet: true,
                    supportIdNoee: '1039',
                    riskLevelSRRI: '1',
                    riskLevelSRI: '2',
                    performanceValue: -0.387212073073828,
                    source: 'Morningstar',
                    amount: {
                      amount: 1000,
                      addedValue: 40,
                      numberOfUnits: 967
                    },
                    rate: null,
                    supportDetailsUrl: null,
                    legalStatus: 'FCP',
                    isMaster: false,
                    hasContribution: false,
                    netAssetValue: {
                      amount: 11.0915,
                      currency: 'EUR',
                      dateValue: '2020-09-17T00:00:00+02:00'
                    },
                    plans: null,
                    hasVersement: true,
                    hasInteressement: true,
                    hasParticipation: true,
                    nextNavDate: '11/02/2018 00:00:00 +01:00',
                    advancedProperties: {
                      HasThresholdTrigger: false,
                      CategorieClassification: 'Monétaire',
                      DefaultAmount: 39.25476
                    },
                    filterProperties: {},
                    isISR: false
                  }
                ],
                order: 4,
                isFree: true,
                filterProperties: {
                  TaxOut: true,
                  VVL: true,
                  VVP: true
                },
                advancedProperties: {
                  HasContribution: false
                }
              },
              {
                isFictive: false,
                id: 'PERECO-C1-L',
                code: 'PERECO C1 L',
                label: 'Gestion Piloté',
                totalAmount: 2000,
                supports: [
                  {
                    supportIsin: 'QS0002105TJ2',
                    supportName: 'MULTIMANAGERS ACTIONS EUROPE -FIDELITY',
                    supportType: 'FONDS',
                    hasNoFundSheet: false,
                    supportIdNoee: '1042',
                    riskLevelSRRI: '6',
                    riskLevelSRI: '5',
                    performanceValue: 19.9222694560339,
                    source: 'Morningstar',
                    amount: {
                      amount: 1000,
                      addedValue: 60,
                      numberOfUnits: 967
                    },
                    rate: null,
                    supportDetailsUrl: null,
                    legalStatus: 'FCP',
                    isMaster: true,
                    hasContribution: false,
                    netAssetValue: {
                      amount: 18.8706,
                      currency: 'EUR',
                      dateValue: '2020-09-17T00:00:00+02:00'
                    },
                    plans: null,
                    hasVersement: true,
                    hasInteressement: true,
                    hasParticipation: true,
                    nextNavDate: '09/02/2018 00:00:00 +01:00',
                    advancedProperties: {
                      HasThresholdTrigger: true,
                      ThresholdTriggerValidityDuration: 3,
                      ThresholdTriggerValidityDate: '2023-01-21T00:00:00+01:00',
                      CategorieClassification: 'Actions internationales',
                      DefaultAmount: 13.25476
                    },
                    filterProperties: {},
                    isISR: false
                  },
                  {
                    supportIsin: 'QS0002105TY1',
                    supportName: 'Multipar Monétaire Sélection Classique',
                    supportType: 'FONDS',
                    hasNoFundSheet: true,
                    supportIdNoee: '1039',
                    riskLevelSRRI: '1',
                    riskLevelSRI: '2',
                    performanceValue: -0.387212073073828,
                    source: 'Morningstar',
                    amount: {
                      amount: 1000,
                      addedValue: 40,
                      numberOfUnits: 967
                    },
                    rate: null,
                    supportDetailsUrl: null,
                    legalStatus: 'FCP',
                    isMaster: false,
                    hasContribution: false,
                    netAssetValue: {
                      amount: 11.0915,
                      currency: 'EUR',
                      dateValue: '2020-09-17T00:00:00+02:00'
                    },
                    plans: null,
                    hasVersement: true,
                    hasInteressement: true,
                    hasParticipation: true,
                    nextNavDate: '11/02/2018 00:00:00 +01:00',
                    advancedProperties: {
                      HasThresholdTrigger: false,
                      CategorieClassification: 'Monétaire'
                    },
                    filterProperties: {},
                    isISR: false
                  }
                ],
                order: 4,
                isFree: false,
                filterProperties: {
                  TaxOut: true,
                  VVL: true,
                  VVP: true
                },
                advancedProperties: {
                  HasContribution: false
                }
              }
            ],
            order: 3
          }
        ],
        filterProperties: {
          VVL: true,
          VVP: true,
          TaxOut: true,
          TaxIn: true,
          RetirementOnly: true,
          Abondement: true,
          Invested: true,
          ThresholdTrigger: false,
          StockReallocation: true,
          PartialReallocation: true,
          FlowReallocation: false,
          OadReallocation: false,
          Contribution: true,
          HideInformationVvInf25: true
        },
        advancedProperties: {
          ContributionConsumed: 25,
          ContributionLimit: 5100,
          HasContribution: false
        },
        colors: ['#F3B351'],
        planFamily: 'ES',
        label: "​​Plan d'Epargne Retraite COllectif​",
        shortLabel: 'PERECO',
        horizon: 'Retraite'
      }
    ],
    payment: null
  },
  instructions: [],
  regulation: {
    bankDetails: {
      bic: 'JHGK********HGJK',
      iban: 'KJG****KHFH',
      type: 'lkjh'
    },
    type: 'kjg',
    amountNet: 0,
    status: 'Termine',
    date: '2022-08-26T22:00:00+00:00'
  }
};

export const mockOperationsDetailsVersementPerco: OperationDetails = {
  identifier: 'O-163694854',
  planCode: 'ss',
  planName: 'PERECO',
  code: '359',
  type: OperationHistoryTypesEnum.INSTALLMENT,
  label: 'Versement par carte de crédit sur mon PERECO',
  deductible: false,
  compartmentCode: 'PERCO',
  compartmentName: 'Epargne salariale',
  brutAmount: 10,
  netAmount: 10,
  abundanceBrutAmount: 7,
  abundanceNetAmount: 6.32,
  abundanceIdentifier: null,
  feeAmount: 0,
  socialContributionsAmount: 0.68,
  deductibleAmount: 0,
  statusCode: 'InProgress',
  statusLabel: 'En cours de traitement',
  creationDate: '2024-01-25T00:00:00',
  updateDate: '',
  cancelable: false,
  editable: false,
  masterOperationCode: OperationDetailsBoTypeEnum.VERSEMENT,
  masterOperationType: OperationHistoryTypesEnum.INCENTIVE,
  details: {
    optionsBulletin: {
      defaultInterestAmount: null,
      amountNetShare: null,
      entryDeadline: null,
      averageAmount: null,
      totalAmount: null,
      type: null,
      fiscalYearStartDate: null,
      fiscalEndStartDate: null,
      frame: null,
      abundance: null
    },
    withholdingTax: {
      taxableNetAmount: null,
      pasAmount: null,
      rateOrigin: null,
      pasRate: null,
      deductibleCSGAmount: null
    },
    amed: {
      entryDeadline: null
    },
    conditionalOrder: {
      threshold: 0,
      expirationDate: '2023-03-06T17:35:02+01:00',
      netAssetValue: 0,
      thresholdType: 'VCP',
      netAssetDate: '2023-01-06T17:35:02+01:00'
    },
    repayment: {
      validSupportingDocuments: null,
      paymentDate: null,
      dateOfTheEvent: null,
      taxableAmount: null,
      unlockCaseIdentifier: null
    }
  },
  instructions: [],
  regulation: {
    bankDetails: {
      bic: 'JHGK********HGJK',
      iban: 'KJG****KHFH',
      type: 'lkjh'
    },
    type: 'kjg',
    amountNet: 0,
    status: 'Termine',
    date: '2022-08-26T22:00:00+00:00'
  },
  source: {
    bulletinOption: {
      companyId: null,
      id: null,
      type: null,
      legalFramework: null,
      defaultAffectations: null,
      derogatoryAffectations: null,
      deadlineDate: null,
      fiscalYearStartDate: null,
      fiscalYearEndDate: null,
      totalAmount: null,
      minimumAmount: null,
      netShareAmount: 0,
      derogatoryAmount: null,
      possibleChoices: null,
      paymentMethod: null,
      possibleContribution: false,
      saverDetails: null
    },
    plans: [],
    payment: {
      savers: {
        fullAddress: {
          additionalAddress_1: 'Rue de tolbiac',
          additionalAddress_2: null,
          additionalAddress_3: null,
          street: null,
          country: 'France',
          zipcode: '75000',
          city: 'Paris',
          recipient: null
        },
        bankAccountDetail: {
          domiciliation: null,
          titulaire: null,
          rum: null,
          ics: null,
          iban: '13906000408314661000051',
          bic: 'AGRIFRPP839',
          type: 'VIREMENT'
        },
        type: OperationDetailsPaymentModeTypeEnum.CB
      },
      amount: 10
    }
  },
  destination: {
    bulletinOption: null,
    plans: [
      {
        planId: 2002,
        planName: 'PERECO',

        planType: 'PER C1 bis',
        planFullName: '',
        isCustomPlan: false,
        compartments: [
          {
            code: 'PERCO',
            label: 'Epargne salariale',
            descriptionLabel:
              "Cette épargne correspond aux sommes issues  de la participation et/ou l'intéressement, compte épargne temps, abondement, jours de congé non pris.",
            managements: [
              {
                id: '0000099160',
                code: 'Libre',
                label: 'Libre',
                totalAmount: 0,
                supports: [
                  {
                    supportIsin: 'QS0002105T60',
                    supportName: 'MULTIPAR SOLIDAIRE OBLIG S RP C',
                    supportType: '',
                    hasNoFundSheet: true,
                    source: '',
                    supportIdNoee: '1034',
                    riskLevelSRRI: '2',
                    riskLevelSRI: '',
                    performanceValue: 0,
                    amount: {
                      amount: 200,
                      addedValue: -18.70773567975,
                      numberOfUnits: 136.5575
                    },
                    rate: null,
                    supportDetailsUrl: null,
                    legalStatus: '',
                    isMaster: false,
                    hasContribution: false,
                    netAssetValue: {
                      amount: 0,
                      currency: 'EUR',
                      dateValue: ''
                    },
                    plans: null,
                    hasVersement: false,
                    hasInteressement: true,
                    hasParticipation: true,
                    nextNavDate: '',
                    advancedProperties: {
                      HasThresholdTrigger: false,
                      AmountNet: 0,
                      ContributionAmountNet: 6.32,
                      ContributionAmountBrut: 7
                    },
                    filterProperties: {},
                    isISR: false
                  }
                ],
                order: 0,
                isFree: true,
                filterProperties: {
                  VVL: false,
                  VVP: false
                },
                advancedProperties: {
                  HasIncorrectDrivingData: false
                },
                isFictive: false
              }
            ],
            order: 1,
            isFictive: false,
            filterProperties: {},
            advancedProperties: {},
            totalAmount: 0
          },
          {
            code: 'PER C1',
            label: 'Versements volontaires déductibles',
            descriptionLabel:
              "Cette épargne correspond aux versements volontaires que j'ai effectués en optant pour la déductibilité des sommes de mon revenu imposable.",
            managements: [
              {
                id: '0000434883',
                code: 'Libre',
                label: 'Libre',
                totalAmount: 10,
                supports: [
                  {
                    supportIsin: 'QS0002105T60',
                    supportName: 'MULTIPAR SOLIDAIRE OBLIG S RP C',
                    supportType: 'null',
                    hasNoFundSheet: true,
                    source: 'null',
                    supportIdNoee: '1034',
                    riskLevelSRRI: '2',
                    riskLevelSRI: 'null',
                    performanceValue: 0,
                    amount: {
                      amount: 10,
                      addedValue: 0,
                      numberOfUnits: 0
                    },
                    rate: null,
                    supportDetailsUrl: null,
                    legalStatus: 'null',
                    isMaster: false,
                    hasContribution: true,
                    netAssetValue: {
                      amount: 12.2602,
                      currency: 'EUR',
                      dateValue: '2023-09-19T00:00:00+00:00'
                    },
                    plans: null,
                    hasVersement: true,
                    hasInteressement: false,
                    hasParticipation: false,
                    nextNavDate: 'null',
                    advancedProperties: {
                      HasThresholdTrigger: false,
                      AmountNet: 10,
                      ContributionAmountNet: 0,
                      ContributionAmountBrut: 0,
                      AvailabilityLabel: 'DetailOperationEcheanceTypeRETRAITE',
                      IsAvailable: false
                    },
                    filterProperties: {},
                    isISR: false
                  }
                ],
                order: 0,
                isFree: true,
                filterProperties: {
                  TaxOut: true,
                  VVL: true,
                  VVP: false
                },
                advancedProperties: {
                  HasIncorrectDrivingData: false
                },
                isFictive: false
              }
            ],
            order: 4,
            isFictive: false,
            filterProperties: {
              TaxOut: true
            },
            advancedProperties: {},
            totalAmount: 10
          }
        ],
        filterProperties: {
          VVL: true,
          VVP: false,
          TaxOut: true,
          TaxIn: true,
          RetirementOnly: true,
          Abondement: true,
          Invested: true,
          ThresholdTrigger: true,
          StockReallocation: true,
          PartialReallocation: true,
          FlowReallocation: false,
          OadReallocation: true,

          Contribution: true,
          HideInformationVvInf25: true
        },
        advancedProperties: {
          Offres: '930650',
          ContributionConsumed: 42,
          ContributionLimit: 1400,
          AmountNet: 10
        },
        colors: ['#F3B351'],
        planFamily: 'ES',
        label: "​​Plan d'Epargne Retraite COllectif​",
        shortLabel: 'PERECO',
        horizon: 'Retraite'
      }
    ],
    payment: null
  },
  family: OperationFamilyEnum.INSTALLMENT
};

export const mockOperationDetailsVVProd: OperationDetails = {
  identifier: 'O-176559758',
  planCode: '',
  planName: 'PERECO',
  code: '145',
  type: OperationHistoryTypesEnum.INSTALLMENT,
  label: 'Versement individuel sur mon PERECO',
  deductible: false,
  compartmentCode: 'PER C1',
  compartmentName: 'Versements volontaires déductibles',
  brutAmount: 2,
  netAmount: 2,
  abundanceBrutAmount: 0.4,
  abundanceNetAmount: 0.36,
  abundanceIdentifier: null,
  feeAmount: 0,
  socialContributionsAmount: 0.04,
  deductibleAmount: 0,
  statusCode: 'Termine',
  statusLabel: 'Validé',
  creationDate: '2023-12-15T00:00:00',
  updateDate: '',
  cancelable: false,
  editable: false,
  masterOperationCode: OperationDetailsBoTypeEnum.VERSEMENT,
  masterOperationType: OperationHistoryTypesEnum.INSTALLMENT,
  details: {
    conditionalOrder: {
      thresholdType: 'VCP',
      threshold: 55.0,
      expirationDate: '26/11/2023 00:00:00 +00:00',
      netAssetValue: 27.5,
      netAssetDate: '2023-09-20T00:00:00Z'
    },
    optionsBulletin: {
      defaultInterestAmount: null,
      amountNetShare: null,
      entryDeadline: null,
      averageAmount: null,
      totalAmount: null,
      type: null,
      fiscalYearStartDate: null,
      fiscalEndStartDate: null,
      frame: null,
      abundance: null
    },
    withholdingTax: {
      taxableNetAmount: null,
      pasAmount: null,
      rateOrigin: null,
      pasRate: null,
      deductibleCSGAmount: null
    },
    amed: {
      entryDeadline: null
    },

    repayment: {
      validSupportingDocuments: null,
      paymentDate: null,
      dateOfTheEvent: null,
      taxableAmount: null,
      unlockCaseIdentifier: null
    }
  },
  instructions: [],
  regulation: {
    bankDetails: {
      bic: 'BNPAFRPPXXX',
      iban: '30*****************6731',
      type: 'PRELEVEMENT'
    },
    type: 'MONETAIRE',
    amountNet: 2,
    status: '',
    date: '19/12/2023 00:00:00 +00:00'
  },

  source: {
    bulletinOption: {
      companyId: null,
      id: null,
      type: null,
      legalFramework: null,
      defaultAffectations: null,
      derogatoryAffectations: null,
      deadlineDate: null,
      fiscalYearStartDate: null,
      fiscalYearEndDate: null,
      totalAmount: null,
      minimumAmount: null,
      netShareAmount: 0,
      derogatoryAmount: null,
      possibleChoices: null,
      paymentMethod: null,
      possibleContribution: false,
      saverDetails: null
    },
    plans: [],
    payment: {
      savers: {
        fullAddress: {
          additionalAddress_1: 'Rue de tolbiac',
          additionalAddress_2: null,
          additionalAddress_3: null,
          street: null,
          country: 'France',
          zipcode: '75000',
          city: 'Paris',
          recipient: null
        },
        bankAccountDetail: {
          domiciliation: null,
          titulaire: null,
          rum: null,
          ics: null,
          iban: '13906000408314661000051',
          bic: 'AGRIFRPP839',
          type: 'VIREMENT'
        },
        type: OperationDetailsPaymentModeTypeEnum.CB
      },
      amount: 2
    }
  },

  destination: {
    bulletinOption: null,
    plans: [
      {
        planId: 1001,
        planName: 'PERECO',
        planType: 'PER C1',
        planFullName: '',
        isCustomPlan: false,
        compartments: [
          {
            code: 'PER C1',
            label: 'Versements volontaires déductibles',
            descriptionLabel:
              "Cette épargne correspond aux versements volontaires que j'ai effectués en optant pour la déductibilité des sommes de mon revenu imposable.",
            managements: [
              {
                id: '0000434880',
                code: 'Piloté',
                label: 'Piloté',
                totalAmount: 1,
                supports: [
                  {
                    supportIsin: 'FR0013049442',
                    supportName: 'BNP PARIBAS PERSPECTIVES 2034-2036 I',
                    supportType: '',
                    hasNoFundSheet: true,
                    source: '',
                    supportIdNoee: '25043',
                    riskLevelSRRI: '3',
                    riskLevelSRI: '',
                    performanceValue: 0,
                    amount: {
                      amount: 1,
                      addedValue: 6.88293069767,
                      numberOfUnits: 0.0419
                    },
                    rate: null,
                    supportDetailsUrl: null,
                    legalStatus: '',
                    isMaster: false,
                    hasContribution: false,
                    netAssetValue: {
                      amount: 23.8507,
                      currency: 'EUR',
                      dateValue: '2023-12-19T00:00:00+00:00'
                    },
                    plans: null,
                    hasVersement: true,
                    hasInteressement: false,
                    hasParticipation: false,
                    nextNavDate: '2024-02-02T00:00:00+00:00',
                    advancedProperties: {
                      HasThresholdTrigger: false,
                      AmountNet: 1,
                      ContributionAmountNet: 0,
                      ContributionAmountBrut: 0,
                      AvailabilityDate: '',
                      AvailabilityLabel: 'DetailOperationEcheanceTypeRETRAITE',
                      IsAvailable: false
                    },
                    filterProperties: {},
                    isISR: false
                  }
                ],
                order: 0,
                isFree: false,
                filterProperties: {
                  TaxOut: true,
                  VVL: true,
                  VVP: true
                },
                advancedProperties: {
                  HasIncorrectDrivingData: false
                },
                isFictive: false
              },

              {
                id: '0000434879',
                code: 'Libre',
                label: 'Libre',
                totalAmount: 1,
                supports: [
                  {
                    supportIsin: 'QS000212QB90',
                    supportName: 'MULTIPAR CROISSANCE INCLUSIVE - G',
                    supportType: '',
                    hasNoFundSheet: true,
                    source: '',
                    supportIdNoee: '3399',
                    riskLevelSRRI: '4',
                    riskLevelSRI: '',
                    performanceValue: 0,
                    amount: {
                      amount: 1,
                      addedValue: 0.34891906545,
                      numberOfUnits: 0.0922
                    },

                    rate: null,
                    supportDetailsUrl: null,
                    legalStatus: '',
                    isMaster: false,
                    hasContribution: true,
                    netAssetValue: {
                      amount: 10.8507,
                      currency: 'EUR',
                      dateValue: '2023-12-18T00:00:00+00:00'
                    },

                    plans: null,
                    hasVersement: true,
                    hasInteressement: false,
                    hasParticipation: false,
                    nextNavDate: '2024-02-01T00:00:00+00:00',
                    advancedProperties: {
                      HasThresholdTrigger: false,
                      AmountNet: 1,
                      ContributionAmountNet: 0,
                      ContributionAmountBrut: 0,
                      AvailabilityDate: '',
                      AvailabilityLabel: 'DetailOperationEcheanceTypeRETRAITE',
                      IsAvailable: false
                    },
                    filterProperties: {},
                    isISR: false
                  }
                ],
                order: 0,
                isFree: true,
                filterProperties: {
                  TaxOut: true,
                  VVL: true,
                  VVP: true
                },
                advancedProperties: {
                  HasIncorrectDrivingData: false
                },
                isFictive: false
              }
            ],

            order: 4,
            isFictive: false,
            filterProperties: {
              TaxOut: true
            },
            advancedProperties: {},
            totalAmount: 2
          },

          {
            code: 'PERCO',
            label: 'Epargne salariale',
            descriptionLabel:
              "Cette épargne correspond aux sommes issues  de la participation et/ou l'intéressement, compte épargne temps, abondement, jours de congé non pris.",
            managements: [
              {
                id: '0000109306',
                code: 'Piloté',
                label: 'Piloté',
                totalAmount: 0,
                supports: [
                  {
                    supportIsin: 'FR0013049442',
                    supportName: 'BNP PARIBAS PERSPECTIVES 2034-2036 I',
                    supportType: '',
                    hasNoFundSheet: true,
                    source: '',
                    supportIdNoee: '25043',
                    riskLevelSRRI: '3',
                    riskLevelSRI: '',
                    performanceValue: 1,
                    amount: {
                      amount: 0,
                      addedValue: 0,
                      numberOfUnits: 0
                    },

                    rate: null,
                    supportDetailsUrl: null,
                    legalStatus: '',
                    isMaster: false,
                    hasContribution: false,
                    netAssetValue: {
                      amount: 0,
                      currency: 'EUR',
                      dateValue: ''
                    },
                    plans: null,
                    hasVersement: false,
                    hasInteressement: true,
                    hasParticipation: true,
                    nextNavDate: '2024-02-02T00:00:00+00:00',
                    advancedProperties: {
                      HasThresholdTrigger: false,
                      AmountNet: 0,
                      ContributionAmountNet: 0.18,
                      ContributionAmountBrut: 0.2
                    },
                    filterProperties: {},
                    isISR: false
                  }
                ],
                order: 0,
                isFree: false,
                filterProperties: {
                  VVL: false,
                  VVP: false
                },
                advancedProperties: {
                  HasIncorrectDrivingData: false
                },
                isFictive: false
              },

              {
                id: '0000109305',
                code: 'Libre',
                label: 'Libre',
                totalAmount: 0,
                supports: [
                  {
                    supportIsin: 'QS000212QB90',
                    supportName: 'MULTIPAR CROISSANCE INCLUSIVE - G',
                    supportType: '',
                    hasNoFundSheet: true,
                    source: '',
                    supportIdNoee: '3399',
                    riskLevelSRRI: '4',
                    riskLevelSRI: '',
                    performanceValue: 0,
                    amount: {
                      amount: 0,
                      addedValue: 60.35770958004,
                      numberOfUnits: 70.1364
                    },

                    rate: null,
                    supportDetailsUrl: null,
                    legalStatus: '',
                    isMaster: false,
                    hasContribution: false,
                    netAssetValue: {
                      amount: 0,
                      currency: 'EUR',
                      dateValue: ''
                    },

                    plans: null,
                    hasVersement: false,
                    hasInteressement: true,
                    hasParticipation: true,
                    nextNavDate: '2024-02-01T00:00:00+00:00',
                    advancedProperties: {
                      HasThresholdTrigger: false,
                      AmountNet: 0,
                      ContributionAmountNet: 0.18,
                      ContributionAmountBrut: 0.2
                    },

                    filterProperties: {},
                    isISR: false
                  }
                ],

                order: 0,
                isFree: true,
                filterProperties: {
                  VVL: false,
                  VVP: false
                },
                advancedProperties: {
                  HasIncorrectDrivingData: false
                },
                isFictive: false
              }
            ],

            order: 1,
            isFictive: false,
            filterProperties: {},

            advancedProperties: {},
            totalAmount: 0
          }
        ],

        filterProperties: {
          VVL: true,
          VVP: true,
          TaxOut: true,
          TaxIn: true,
          RetirementOnly: true,
          Abondement: true,
          Invested: true,
          ThresholdTrigger: false,
          StockReallocation: true,
          PartialReallocation: true,
          FlowReallocation: false,
          OadReallocation: true,
          Contribution: true,
          HideInformationVvInf25: true
        },

        advancedProperties: {
          Offres: '930650',
          ContributionConsumed: 81.75,
          ContributionLimit: 1512.5,
          AmountNet: 2
        },
        colors: ['#F3B351'],

        planFamily: 'ES',
        label: "​​Plan d'Epargne Retraite COllectif​",
        shortLabel: 'PERECO',
        horizon: 'Retraite'
      }
    ],

    payment: null
  },

  family: OperationFamilyEnum.INSTALLMENT
};

export const mockOperationDetailsCapitalizedDividend: OperationDetails = {
  identifier: 'dd9886ea8-1',
  planCode: '19198',
  planName: 'PERO',
  code: 'MV_CAPDIVIDENDE',
  family: OperationFamilyEnum.CAPITALIZED_DIVIDEND,
  label: 'Dividende capitalisé',
  type: OperationHistoryTypesEnum.CAPITALIZED_DIVIDEND,
  deductible: true,
  compartmentCode: '0',
  compartmentName: 'je ne sais pas',
  brutAmount: 1230.6788,
  netAmount: 234.8765,
  abundanceBrutAmount: 230.786899,
  abundanceNetAmount: 223.765,
  abundanceIdentifier: null,
  feeAmount: 120.78,
  socialContributionsAmount: 134.099,
  deductibleAmount: 0,
  statusCode: OperationsHistoryStatusEnum.TERMINE,
  statusLabel: 'Terminé',
  creationDate: '2022-08-26T22:00:00+00:00',
  updateDate: '2022-08-26T22:00:00+00:00',
  cancelable: true,
  editable: false,
  masterOperationCode: OperationDetailsBoTypeEnum.VERSEMENT,
  masterOperationType: OperationHistoryTypesEnum.INCENTIVE,
  details: null,
  source: {
    bulletinOption: {
      companyId: null,
      deadlineDate: null,
      defaultAffectations: null,
      derogatoryAffectations: null,
      derogatoryAmount: null,
      fiscalYearEndDate: null,
      fiscalYearStartDate: null,
      id: null,
      legalFramework: null,
      minimumAmount: null,
      netShareAmount: 0,
      paymentMethod: null,
      possibleChoices: null,
      possibleContribution: false,
      saverDetails: null,
      totalAmount: null,
      type: null
    },
    plans: null,
    payment: null
  },
  destination: {
    bulletinOption: null,
    payment: null,
    plans: [
      {
        filterProperties: {},
        advancedProperties: {},
        colors: [],
        planFamily: 'ES',
        label: 'Dividended capitamisé',
        shortLabel: 'capitalisé',
        horizon: 'vv',
        planId: 2002,
        planName: 'PERECO',
        planType: 'PERECO',
        planFullName: '',
        isCustomPlan: false,
        compartments: [
          {
            code: 'PER C1',
            label: 'Versements volontaires déductibles',
            managements: [
              {
                isFictive: false,
                id: 'PERECO-C1-L',
                code: 'PERECO C1 L',
                label: 'Gestion Libre',
                totalAmount: 2000,
                isFree: true,
                order: 1,
                supports: [
                  {
                    supportIsin: 'QS0002105TJ2',
                    supportName: 'MULTIMANAGERS ACTIONS EUROPE -FIDELITY',
                    supportType: 'FONDS',
                    hasNoFundSheet: false,
                    supportIdNoee: '1042',
                    riskLevelSRRI: '6',
                    riskLevelSRI: '5',
                    performanceValue: 19.9222694560339,
                    source: 'Morningstar',
                    amount: {
                      amount: 1000,
                      addedValue: 60,
                      numberOfUnits: 967
                    },
                    rate: null,
                    supportDetailsUrl: null,
                    legalStatus: 'FCP',
                    isMaster: true,
                    hasContribution: false,
                    netAssetValue: {
                      amount: 10,
                      currency: 'EUR',
                      dateValue: '2020-09-17T00:00:00+02:00'
                    },
                    plans: null,
                    hasVersement: true,
                    hasInteressement: true,
                    hasParticipation: true,
                    nextNavDate: '09/02/2018 00:00:00 +01:00',
                    advancedProperties: {
                      AmountNet: 1000,
                      IsTechnicalFund: false,
                      HasThresholdTrigger: true,
                      ThresholdTriggerValidityDuration: 3,
                      ThresholdTriggerValidityDate: '2023-01-21T00:00:00+01:00',
                      CategorieClassification: 'Actions internationales',
                      DefaultAmount: 13.25476,
                      AvailabilityDate: '2023-06-09T00:00:00+00:00',
                      AvailabilityLabel: 'VL test 1',
                      Availabilities: [
                        {
                          numberOfUnits: 1688.7869,
                          datetime: '2016-05-01T00:00:00',
                          amount: 0,
                          availabilityLabel: 'DetailOperationEcheanceTypeDISPONIBLE',
                          useAvailabilityLabel: false
                        },
                        {
                          numberOfUnits: 2269.385,
                          datetime: '2017-05-01T00:00:00',
                          amount: 0,
                          availabilityLabel: 'DetailOperationEcheanceTypeDISPONIBLE',
                          useAvailabilityLabel: false
                        }
                      ]
                    },
                    filterProperties: {
                      TaxOut: true,
                      VVL: true,
                      VVP: true
                    },
                    isISR: false
                  }
                ],
                filterProperties: {
                  TaxOut: true,
                  VVL: true,
                  VVP: true
                },
                advancedProperties: {
                  HasThresholdTrigger: true,
                  ThresholdTriggerValidityDuration: 3,
                  ThresholdTriggerValidityDate: '2023-01-21T00:00:00+01:00',
                  CategorieClassification: 'Actions internationales',
                  DefaultAmount: 13.25476,
                  AvailabilityDate: '2023-06-09T00:00:00+00:00',
                  AvailabilityLabel: 'VL test 1'
                }
              }
            ]
          }
        ]
      }
    ]
  },
  instructions: [],
  regulation: {
    bankDetails: {
      bic: 'AGRIFRPP839',
      iban: '13906000408314661000051',
      type: 'VIREMENT'
    },
    type: 'MONETAIRE',
    amountNet: 0,
    status: '',
    date: ''
  }
};
