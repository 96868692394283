import React from 'react';

import {
  DashboardAnnounce,
  SimpleButton,
} from '@components/index';
import { RouteNames } from '@constants/navigation';
import { useTranslation } from '@ere-uilib/translations';

import { RefundFailedPageProps } from './interfaces';
import { useNavigation } from '@react-navigation/native';

export const RefundFailedPageComponent: React.FC<RefundFailedPageProps> = ({
  clearRefundData,
}) => {
  const { formatMessage } = useTranslation();
  const navigation = useNavigation();
  const renderActions = () => {
    return (
      <SimpleButton
        onPress={() => {
          clearRefundData();
          navigation.navigate(RouteNames.BottomTabNavigator, {
            screen: RouteNames.Home,
          });
        }}
        title={formatMessage({ id: 'Remboursement_boutonPaiement_echec' })}
      />
    );
  };
  return (
    <DashboardAnnounce
      actions={renderActions}
      description={formatMessage({ id: 'Remboursement_demandeRejetee_description' })}
      sourceName={'installmentError'}
      title={formatMessage({ id: 'Remboursement_demandeRejetee_titre' })}
    />
  );
};
