import { PlanFamillyList } from '@constants/index';

import { InputMode } from '@modules/common/constants';
import {FileData} from "@modules/common/types";

type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never };

export type XOR<T, U> = T | U extends object ? (Without<T, U> & U) | (Without<U, T> & T) : T | U;

export interface BankMailAddressType {
  receiver: string | null;
  precision: string | null;
  zipCode: string | null;
  city: string | null;
  country: string | null;
}

export interface AccountBankDetailsType {
  domiciliation: string | null;
  titulaire: string | null;
  rum: string | null;
  ics: string | null;
  iban: string | null;
  bic: string | null;
  type: string | null;
  iso: string | null;
  status: string | null;
  statusLabel: string;
  dateSignatureMandat: string | null;
  inputMode: InputMode | null;
  allowedModification: boolean;
  tsaAddress: BankMailAddressType | null;
  bankAccountIsSet: boolean | null;
  fileMaxSize: number | null;
  totalFileMaxSize: number | null;
}

export interface EsRcBankDetailsType {
  planList: string[] | null;
  transferBankAccountDetails: AccountBankDetailsType | null;
  debitBankAccountDetails: AccountBankDetailsType | null;
  allowedCountries: string[] | null;
  signatureAllowed: boolean;
  fileMaxSize: number | null;
  totalFileMaxSize: number | null;
}

export interface RcBankDetailsType {
  rcPlan : string | null;
  planId : string | null;
  bankAccountIsSet: boolean | null;
  debitBankAccountDetails: AccountBankDetailsType | null;
}
export interface BankDataType {
  rc: {
    rcBankAccountDetailsList: RcBankDetailsType[]
  } | null;
  es: EsRcBankDetailsType | null;
}

export interface BankDetailsInitESType {
  journeyId: string | null;
  contractId: string | null;
  fileMaxSize: number | null;
  totalFileMaxSize: number | null;
}

export interface BankDetailsInitRCType {
  journeyId: string | null;
  status: boolean;
  mail?: string | null;
  phoneNumber?: string | null;
}

export type BankDetailsInitType = XOR<
  BankDetailsInitESType,
  BankDetailsInitRCType &
  Pick<Omit<BankDetailsInitESType, 'contractId'>, 'fileMaxSize' | 'totalFileMaxSize'>
>;

export interface BankDetailsDocument {
  documentId: string | undefined;
  file: FileData | null | undefined;
  hasError: boolean;
  isLoading: boolean;
}

export type RegisterParameter = {
  iban: string;
  bic: string;
  bankAccountUpdateType: string;
  contractId: string;
  countryCode: string;
  languageCode: string;
};

export type RegisterRulesType = {
  shouldUploadDocByDoc: boolean;
  withQuicksignOTP: boolean;
};

export enum PopinContentTypeEnum  {
  MANY_WRONG_OTP= 'TOO_MANY_WRONG_OTP',
  MISSING_SIGNATURE_FAILED= 'MISSING_SIGNATURE_FIELD',
  UNKNOWN= 'UNKNOWN',
  SUCCESS= 'SUCCESS',
};

export interface BankDetailsState {
  bankData: BankDataType;
  initData: BankDetailsInitType;
  document: Record<string, BankDetailsDocument>;
  error: BankDetailsErrorState | null;
  selectedPlanFamily: PlanFamillyList | undefined;
  selectedPlanId: string | undefined;
  selectedOTPChannel: string | undefined;
  selectedCountry: string | null;
  selectedAccountType: string | null;
  quickSignUrl: string | null;
  registerRules: RegisterRulesType;
  updateFinishedStatus: PopinContentTypeEnum | undefined;
  documentMandatRc: string | undefined;
}

export interface BankDetailsErrorState {
  code: string;
  Code?: string; // !!!
  correlationId: string;
  message: string;
  innerMessage: string;
}

export interface RegisterBankDataPayload {
  iban: string;
  bic: string;
  contractId: string;
  languageCode: string;
  countryCode: string;
  bankAccountUpdateType?: string;
}
export type FileMaxSizeType = Partial<Pick<AccountBankDetailsType, 'fileMaxSize' | 'totalFileMaxSize'>>;
