import React from 'react';
import { connect } from 'react-redux';

import {
  getProfitSharingIncentiveInitDataState
} from '@modules/profit-sharing-incentive/selectors';
import {
  ProfitSharingIncentiveActionsType
} from '@modules/profit-sharing-incentive/actions/profitSharingIncentiveActionsTypes';
import {
  getProfitSharingIncentiveRepartitionRequest,
  setProfitSharingIncentiveAssigmentAllRefundAmounts
} from '@modules/profit-sharing-incentive/actions/profitSharingIncentiveActions';
import { AppState } from '@modules/reducers';
import {
  getApplicationSettings,
} from '@modules/settings/selectors';

import { ProfitSharingIncentiveAssigmentPageComponent }  from './ProfitSharingIncentiveAssigmentPage.component'

const mapStateToProps = (state: AppState) => {
  const initData = getProfitSharingIncentiveInitDataState(state)
  return {
    applicationSettings: getApplicationSettings(state),
    submitError: state.error[ProfitSharingIncentiveActionsType.GET_PARTICIPATION_PROFITSHARING_REPARTITION_REQUEST],
    availableAmount: initData?.netShareAmount||0,
    minimumReinvestAmount: initData?.minimumAmount||0,
    isSubmitLoading: state.isLoading[ProfitSharingIncentiveActionsType.GET_PARTICIPATION_PROFITSHARING_REPARTITION_REQUEST],
    paymentMode: initData?.paymentMethod,
    possibleChoices: initData?.possibleChoices,
    hasContribution: !!initData?.possibleContribution,
    legalFramework: initData?.legalFramework,
    type: initData?.type
  };
};

const mapDispatchToProps = {
  onSubmitAmountsRequest: getProfitSharingIncentiveRepartitionRequest,
  onSubmitAllRefundAmounts: setProfitSharingIncentiveAssigmentAllRefundAmounts
};

export const ProfitSharingIncentiveAssigmentPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfitSharingIncentiveAssigmentPageComponent);
