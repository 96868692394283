import { Platform } from 'react-native';

import { TextFieldContext, TextFieldStyle } from './interface';

export const getStyles = (context?: TextFieldContext, style?: TextFieldStyle): TextFieldStyle => ({
  containerStyle: [
    {
      flexDirection: 'row',
      alignItems: 'center'
    },
    context?.code && {
      marginRight: context?.isMobile
          ? context?.theme.metrics.spacing.xs
          : context?.theme.metrics.spacing.xm
    },
    style?.containerStyle
  ],
  inputStyle: [
    {
      flex: context?.code ? 0 : 1,
      paddingBottom: 0,
      height: 60,
      backgroundColor: context?.isDisabled ? context?.theme.colors.basics.grey.c200 : 'transparent',
      borderWidth: 1,
      borderColor: context?.theme.colors.basics.grey.c300,
      borderRadius: context?.theme.metrics.borderRadius.xs,
      fontFamily: context?.theme.fonts.fontFamily.regular,
      fontSize: context?.theme.fonts.fontSize.paragraph.t2,
      lineHeight: Platform.OS === 'android' ? 25 : 22,
      color: context?.isDisabled
        ? context?.theme.colors.basics.grey.c500
        : context?.theme.colors.basics.black
    },
    context?.code && {
      textAlign: 'center',
      width: 60,
      borderRadius: context?.theme.metrics.borderRadius.s
    },
    context?.isLabelOnTop && {
      paddingTop: context?.theme.metrics.spacing.xm
    },
    context?.password && {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center'
    },
    (context?.value || context?.isFocused) && {
      borderColor: context?.theme.colors.basics.primary.c400,
      borderWidth: 2,
      color: context?.theme.colors.basics.black
    },
    context?.errored && {
      borderColor: context?.theme.colors.basics.message.error.c500,
      borderWidth: 2,
      backgroundColor: context?.theme.colors.basics.message.backColor
    },
    !context?.code &&
    typeof context?.leftIconsSize?.width === 'number' &&
    context?.leftIconsSize?.width > 0
      ? {
          paddingLeft: context.leftIconsSize.width
        }
      : {
          paddingLeft: context?.code ? 0 : context?.theme.metrics.spacing.m
        },
    !context?.code &&
      typeof context?.rightIconsSize?.width === 'number' && {
        paddingRight: context.rightIconsSize.width
      },
    style?.inputStyle
  ],
  leftIconsStyle: [
    {
      position: 'absolute',
      flexDirection: 'row',
      left: 0,
      paddingLeft: context?.theme.metrics.spacing.m,
      paddingRight: context?.theme.metrics.spacing.s
    },
    style?.leftIconsStyle
  ],
  rightIconsStyle: [
    {
      position: 'absolute',
      flexDirection: 'row',
      right: 0,
      paddingLeft: context?.theme.metrics.spacing.s,
      paddingRight: context?.theme.metrics.spacing.m
    },
    style?.rightIconsStyle
  ],
  cleanIconStyle: [
    context?.password && {
      marginRight: context?.theme.metrics.spacing.xs
    },
    style?.cleanIconStyle
  ],
  labelStyle: [
    {
      color: context?.theme.colors.basics.grey.c500,
      position: 'absolute',
      left: context?.theme.metrics.spacing.m,
      top: context?.theme.metrics.spacing.s,
      zIndex: context?.isDisabled ? 2 : -1
    },
    context &&
      context.leftIconsSize?.width > 0 && {
        left: context.leftIconsSize.width
      },
    style?.labelStyle
  ],
  errorMessageStyle: [
    {
      color: context?.theme.colors.operationState.failed.c500
    },
    style?.errorMessageStyle
  ]
});
