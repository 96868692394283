import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

export function useStyles() {
  const theme = useTheme();

  const styles = useMemo(() => StyleSheet.create({
    subHeaderInstallmentRowStyle:
      {
        backgroundColor: theme.colors.basics.primary.c500,
        height: 50,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      },
    subHeaderInstallmentRowAmountStyle:
      {
        color: theme.colors.basics.white,
        marginLeft: theme.metrics.spacing.s,
      },
    subHeaderInstallmentRowTitleStyle:
      {
        color: theme.colors.basics.white,
      },
  }), [theme]);

  return styles;
}
