import React, { useCallback, useMemo, useState } from 'react';
import { View } from 'react-native';

import {
  ColumnsContainer,
  DashboardPageWrapperConnected,
  FundSheetModal,
  FundSheetModalDataType,
  NotificationHard,
  NotificationIconTypeEnum,
  OperationsHeader,
  Text,
  useTranslation
} from '@components/index';
import { RouteNames } from '@constants/navigation';
import {
  ProfitSharingIncentiveTypeEnum
} from '@constants/ProfitSharingIncentive';
import {
  ProfitSharingIncentiveRepartitionPlansState
} from '@modules/profit-sharing-incentive/types';

import {
  FiletStepperCardDestination,
  FiletStepperCardOrigin, ProfitSharingIncentiveDestinationCardType,
  ProfitSharingIncentiveDestinationSubCardType, ProfitSharingIncentiveHeader
} from '../components';
import { ProfitSharingIncentiveDefaultAffectationsPageProps } from './interface';
import { useStyles } from './styles';

export const ProfitSharingIncentiveDefaultAffectationsComponent: React.FC<React.PropsWithChildren<ProfitSharingIncentiveDefaultAffectationsPageProps>> = ({
  initData,
  navigation
}) => {
  const { type, legalFramework } = initData ?? {};
  const deadlineDate = useMemo(() => initData?.deadlineDate, [initData]);
  const { formatMessage, getMessageRaw, formatDate } = useTranslation();

  const styles = useStyles();

  const [fundSheetModalData, setFundSheetModalData] =
    useState<FundSheetModalDataType | null>();
  const [displayFundSheetModal, setDisplayFundSheetModal] = useState(false);

  const derogatedAffectations = useMemo(() => initData?.derogatoryAffectations || [], [initData?.derogatoryAffectations]);
  const isDerogatedAffectationsAvailable = useMemo(() => derogatedAffectations.length > 0, [derogatedAffectations]);

  const handleHideFundSheet = useCallback(() => {
    setDisplayFundSheetModal(false);
    setFundSheetModalData(null);
  }, [])

  const renderSubHeader = useCallback(() => {
    let PIDefaultAffectationsTitle = formatMessage({ id: 'PIAffectationdefautTotalInvestParticipationTitre' })

    if (type === ProfitSharingIncentiveTypeEnum.incentive) {
      PIDefaultAffectationsTitle = formatMessage({ id: 'PIAffectationdefautTotalInvestInteressementTitre' });
    }

    return (
      <OperationsHeader
        amount={initData?.netShareAmount || 0}
        title={PIDefaultAffectationsTitle}
      />
    );
  }, [
    formatMessage,
    type,
    initData
  ]);

  const renderAlert = useCallback(() => {
    let PIDefaultAffectationsDescriptionLabelCode = 'PIAffectationdefautCardParticipationDescription';
    if (type === ProfitSharingIncentiveTypeEnum.incentive) {
      PIDefaultAffectationsDescriptionLabelCode = 'PIAffectationdefautCardInteressementDescription';
    }
    const PIDefaultAffectationsDescription = formatMessage({
      id: PIDefaultAffectationsDescriptionLabelCode,
      values: {
        Date_max_affectation: formatDate({ value: deadlineDate })
      },
    });

    return (
      <NotificationHard
        containerStyle={styles.notificationContainerStyle}
        type={NotificationIconTypeEnum.WARNING}
      >
        <Text weight="light">
          {PIDefaultAffectationsDescription && PIDefaultAffectationsDescription}
        </Text>
      </NotificationHard>
    );
  }, [
    styles,
    formatMessage,
    formatDate,
    type,
    deadlineDate
  ]);

  const handleClose = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack()
    } else if (type && legalFramework) {
      navigation.navigate(RouteNames.ProfitSharingIncentive, {
        screen: RouteNames.ProfitSharingIncentiveDescription,
        params: {
          type,
          cadre: legalFramework
        }
      })
    }
  }, [
    navigation,
    type,
    legalFramework
  ])
  const renderHeader = useCallback(() => (
    <ProfitSharingIncentiveHeader
      displayCloseButton
      helpPoint
      helpPointContentHtml={isDerogatedAffectationsAvailable ?
        getMessageRaw({ id: 'PIAffectationdefautDerogatoireAideContenuLabel' })
        : getMessageRaw({ id: 'PIAffectationdefautAideContenuLabel' })
      }
      helpPointModalTitle={formatMessage({ id: 'PIAffectationdefautAideTitreLabel' })}
      helpPointPosition="left"
      isGoBack
      onClose={handleClose}
      title={formatMessage({ id: 'PIAffectationdefautTitreLabel' })}
    />
  ), [getMessageRaw, formatMessage, handleClose, isDerogatedAffectationsAvailable]);

  const renderOrigin = useCallback(() => {
    let PIOriginStepperTitleLabelCode = "";
    switch (type) {
      case ProfitSharingIncentiveTypeEnum.incentive:
        PIOriginStepperTitleLabelCode = 'PIDescriptionMonInteressementTitre';
        break;
      default:
        PIOriginStepperTitleLabelCode = 'PIDescriptionMaParticipationTitre';
        break;
    }

    return (
      <View>
        <FiletStepperCardOrigin
          amount={initData?.netShareAmount || 0}
          title={formatMessage({ id: PIOriginStepperTitleLabelCode })}
        />
      </View>
    );
  }, [
    type,
    formatMessage,
    initData
  ]);

  const buildPlanCardManagements = useCallback((plan: ProfitSharingIncentiveRepartitionPlansState) => {

    const constructedDefaultAffectationsManagements: ProfitSharingIncentiveDestinationCardType['managements'] = [];
    plan.compartments.map(compartment => {
      const isDrivenManagement = !!compartment.managements.find(management => !management.isFree)
      const isMultipleManagement = compartment.managements.length > 1
      compartment.managements
        .filter(management => !management?.advancedProperties?.IsTechnicalManagement)
        .map(management => {
          let constructedSubCards: ProfitSharingIncentiveDestinationSubCardType[] = [];
          const masterSupport = management.supports.find(support => !!support.isMaster)
          const managementAmount = masterSupport?.advancedProperties?.DefaultAmount
            || management?.advancedProperties?.DefaultAmount
            || undefined;

          if (management.isFree) {
            constructedSubCards = management.supports
              .filter(support => !support?.advancedProperties?.IsTechnicalFund)
              .map(support => (
                {
                  title: support.supportName,
                  amount: support?.advancedProperties.DefaultAmount,
                  percentage: undefined,
                  onPress: () => {
                    setDisplayFundSheetModal(true);
                    setFundSheetModalData({
                      id: support.supportIsin,
                      title: support.supportName || ""
                    });
                  }
                }
              ));
          }

          constructedDefaultAffectationsManagements.push({
            title: management.label,
            isFree: management.isFree,
            hideManagementAmount: management.isFree,
            hideManagementRow: !isDrivenManagement && !isMultipleManagement,
            amount: managementAmount,
            percentage: undefined,
            subCards: constructedSubCards
          })
        });
    });
    return constructedDefaultAffectationsManagements
  }, [])

  const renderDestination = useCallback(() => {

    const defaultAffectationsCards: ProfitSharingIncentiveDestinationCardType[] = [];
    initData?.defaultAffectations.map((plan, index) => {
      const constructedManagements: ProfitSharingIncentiveDestinationCardType['managements'] = buildPlanCardManagements(plan);

      defaultAffectationsCards.push({
        filetTitle: index === 0 ?
          formatMessage({ id: 'PIAffectationdefautVersLabel' })
          : undefined,
        planName: plan.planName,
        planType: plan.planType,
        amount: undefined,
        managements: constructedManagements
      });

    });

    if (!defaultAffectationsCards || defaultAffectationsCards.length === 0) {
      return null;
    }

    return (
      <FiletStepperCardDestination
        cards={defaultAffectationsCards}
        disableLine={!isDerogatedAffectationsAvailable}
      />
    );
  }, [
    initData,
    formatMessage,
    isDerogatedAffectationsAvailable,
    buildPlanCardManagements
  ]);

  const renderDerogatedAffectations = useCallback(() => {
    if (!isDerogatedAffectationsAvailable) { return null }
    const derogatedAffectationsCards: ProfitSharingIncentiveDestinationCardType[] = [];
    derogatedAffectations.map((plan, index) => {

      const constructedManagements: ProfitSharingIncentiveDestinationCardType['managements'] = buildPlanCardManagements(plan);

      derogatedAffectationsCards.push({
        filetTitle: index === 0 ?
          formatMessage({ id: 'PIAffectationdefautEtVersLabel' })
          : undefined,
        planName: plan.planName,
        planType: plan.planType,
        amount: undefined,
        managements: constructedManagements
      });

    });

    if (!derogatedAffectationsCards || derogatedAffectationsCards.length === 0) {
      return null;
    }

    return (
      <FiletStepperCardDestination
        cards={derogatedAffectationsCards}
        disableLine
      />
    );
  }, [
    derogatedAffectations,
    isDerogatedAffectationsAvailable,
    formatMessage,
    buildPlanCardManagements
  ]);

  const renderContent = useCallback(() => {
    return (
      <View style={styles.contentContainer}>
        <View style={styles.content}>
          {renderAlert()}
          <View style={styles.filetStepperContainer}>
            {renderOrigin()}
            {renderDestination()}
            {renderDerogatedAffectations()}
          </View>
        </View>
      </View>
    )
  }, [
    renderAlert,
    renderOrigin,
    renderDestination,
    renderDerogatedAffectations,
    styles
  ]);

  return (
    <DashboardPageWrapperConnected>
      <ColumnsContainer
        hasColumnCenter={false}
        hasRenderRightDesktopColumn={false}
        leftColumnStyle={styles.leftColumnStyle}
        renderHeader={renderHeader}
        renderTopContent={renderSubHeader}
      >
        {renderContent()}
        {fundSheetModalData && (
          <FundSheetModal
            id={fundSheetModalData?.id}
            modalIsVisible={displayFundSheetModal}
            onCloseModal={handleHideFundSheet}
            title={fundSheetModalData?.title}
          />
        )}
      </ColumnsContainer>
    </DashboardPageWrapperConnected>
  );
};
