import React from "react"
import { LoaderPlaceholderView } from "@ere-uilib/atoms"
import { useStyles } from "./useStyle"

export const RecentOperationCardContentLoader: React.FC = () => {
  const styles = useStyles()

  return <>
    {[...Array(6)].map((_, i) => (
      <LoaderPlaceholderView
        key={`loader_#${i}`}
        style={[styles.loader,
        i % 2 !== 0 && { width: "30%" }
        ]}
      />
    )
    )}
  </>

}