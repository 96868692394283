import { connect } from 'react-redux';

import { GetMultiFundsRequest, SetCompareMode } from '@modules/funds/actions/fundsActions';
import { FundsActionsType } from '@modules/funds/actions/fundsActionsTypes';
import { AppState } from '@modules/reducers';
import {
  getMultiFundsOverviews,
  getMultiFundsPerformances,
  getCompareMode,
  getMultiFundsRepartitionSupports,
} from '@modules/funds/selectors';

import { CompareFundsModalComponent } from './CompareFundsModal.component';

const mapStateToProps = (state: AppState) => {
  return {
    multiFundsPerformances: getMultiFundsPerformances(state),
    multiFundsOverviews: getMultiFundsOverviews(state),
    multiFundsRepartitionSupports: getMultiFundsRepartitionSupports(state),
    compareMode: getCompareMode(state),
    isApiLoadingMultiOverviews:
      state.isLoading[FundsActionsType.GET_MULTI_FUNDS_OVERVIEWS_REQUEST],
    isApiLoadingMultiPerformances:
      state.isLoading[FundsActionsType.GET_MULTI_FUNDS_PERFORMANCES_REQUEST],
    isApiLoadingMultiRepartitionSupports:
        state.isLoading[FundsActionsType.GET_MULTI_FUNDS_REPARTITION_SUPPORTS_REQUEST],
  };
};

const mapDispatchToProps = {
  onGetMultiFunds: GetMultiFundsRequest,
  setCompareMode: SetCompareMode,
};

export const CompareFundsModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(CompareFundsModalComponent);
