import { connect } from 'react-redux';

import { initBankDataRequest, setSelectedDomiciliationCountry } from '@modules/bank-details/actions/actionsCreators';
import { BankDetailsActionsType } from '@modules/bank-details/actions/actionsTypes';
import { getAddressData, getBankData, getBankDetailsError, getInputMode, getSelectedAccountType, getSelectedPlanFamily, getSignatureAllowed, getStatus } from '@modules/bank-details/selectors';
import { AppState } from '@modules/reducers';

import { BankDomiciliationPageComponent } from './BankDomiciliation.component';

const mapStateToProps = (state: AppState) => ({
  bankData: getBankData(state),
  isLoadingInitBankData: state.isLoading[BankDetailsActionsType.INIT_BANK_DATA_REQUEST],
  initBankDataError: getBankDetailsError(state),
  selectedAccountType: getSelectedAccountType(state),
  selectedPlanFamily: getSelectedPlanFamily(state),
  inputMode: getInputMode(state),
  addressData: getAddressData(state),
  status: getStatus(state),
  allowedSignature: getSignatureAllowed(state),
});

const mapDispatchToProps = {
  setSelectedCountry: setSelectedDomiciliationCountry,
  onGetInitData: initBankDataRequest,
};

export const BankDomiciliationPage = connect(mapStateToProps, mapDispatchToProps)(BankDomiciliationPageComponent);