import React from 'react';
import {
  View,
  TouchableOpacity,
  ScrollView,
} from 'react-native';

import {
  Title,
  FontIcon,
  FundSelect,
  Text,
  RoundedIcon,
} from '@components/index';
import { IconEnum } from '@ere-uilib/enums';
import {
  createUseStyles,
  ThemeType,
  useScreenSizes,
  useTheme,
} from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';

import { FundsComparisonContent, CompareFundsChartPart } from './components';
import { CompareFundsInnerPageDataProps, CompareFundsInnerPageStyles } from './interfaces';
import { getStyles } from './styles';
import {
  FundSourceEnum
} from '@modules/funds/types';

type Props = CompareFundsInnerPageDataProps & CompareFundsInnerPageStyles;

export const CompareFundsInnerPage: React.FC<React.PropsWithChildren<Props>> = ({
  setFundsSelected,
  fundsSelected,
  fundsToCompare,
  onCloseModal,
  multiFundsOverviews,
  multiFundsPerformances,
  multiFundsRepartitionSupports,
  compareMode,
  closeButtonStyle,
  compareTitleStyle,
  compareHeaderDetailsStyle,
  fundsSelectContainerStyle,
  scrollSelectFundsContainerStyle,
  scrollSelectFundsStyle,
  fundSelectFrameStyle,
  roundedIconStyle,
  fundsKeysStyle,
  fundsKeysContainerStyle,
  fundsKeysIconsStyle,
  fundsKeysRightStyle,
  greenLabelContainerStyle,
}) => {
  const { formatMessage } = useTranslation();
  const theme: ThemeType = useTheme();
  const { isMobile, isTablet, windowHeight } = useScreenSizes();
  const styles = useStyles(
    { theme, isMobile, isTablet, windowHeight },
    {
      closeButtonStyle,
      compareTitleStyle,
      compareHeaderDetailsStyle,
      fundsSelectContainerStyle,
      scrollSelectFundsContainerStyle,
      scrollSelectFundsStyle,
      fundSelectFrameStyle,
      roundedIconStyle,
      fundsKeysStyle,
      fundsKeysContainerStyle,
      fundsKeysIconsStyle,
      fundsKeysRightStyle
    }
  );

  const renderCloseButton = () => {
    return (
      <TouchableOpacity
        onPress={() => {
          onCloseModal && onCloseModal();
        }}
      >
        <FontIcon
          color={theme.colors.text.primary.c500}
          name={'fermer'}
          size={theme.metrics.iconSizes.xxm}
        />
      </TouchableOpacity>
    );
  };

  return (
    <View>
      <View style={styles.closeButtonStyle}>{renderCloseButton()}</View>

      <View style={styles.compareTitleStyle}>
        <Title variant="t6">
          {formatMessage({ id: 'MyFunds_Screen1Compare_Header_Title' })}
        </Title>
        {!compareMode && (
          <Title
            style={styles.compareHeaderDetailsStyle}
            variant="t7">
            {formatMessage({ id: 'MyFunds_Screen1Compare_Header_Detail' })}
          </Title>
        )}
      </View>
      <View style={styles.fundsSelectContainerStyle}>
        {!compareMode && (
          <View style={styles.fundsKeysContainerStyle}>
            <View style={styles.fundsKeysIconsStyle}>
              {/* Commented until geen label is reactivated by MOA */}
              {/* <View
                style={[styles.fundsKeysStyle, styles.fundsKeysRightStyle]}
              >
                <RoundedContainer containerStyle={styles.greenLabelContainerStyle} >
                  <SVGLocalLoader
                    name={'greenLabel'}
                  />
                </RoundedContainer>
                <Text variant={'t3'}>
                  {formatMessage({ id: 'MyFunds_Screen1_Solidaire_label' })}
                </Text>
              </View> */}

              <View style={styles.fundsKeysStyle}>
                <RoundedIcon
                  containerBackground={theme.colors.basics.grey.c100}
                  containerSize={20}
                  containerStyle={styles.roundedIconStyle}
                  iconColor={theme.colors.buttons.classic.c500}
                  iconName={IconEnum.CONTRIBUTION}
                  iconSize={theme.metrics.spacing.s}
                />
                <Text variant="t3">
                  {formatMessage({ id: 'MyFunds_Screen1_Abondement_label' })}
                </Text>
              </View>
            </View>
          </View>
        )}

        <View style={styles.scrollSelectFundsContainerStyle}>
          <ScrollView
            style={styles.scrollSelectFundsStyle}
          >
            <View style={styles.scrollableContentStyle}>
              {!compareMode ? (
                <View style={styles.fundSelectFrameStyle}>
                  {fundsToCompare?.map(fund => {
                    const disabled = fund.hasNoFundSheet || fund.source === FundSourceEnum.UNKNOWN
                    return (
                      <FundSelect
                        source={fund.source}
                        fundIsing={fund.isinCode}
                        fundName={fund.supportName}
                        hasAlreadyInvested={!!fund.amount}
                        hasContribution={fund.hasContribution}
                        isDisabled={
                          disabled ||
                          !fund.isinCode ||
                          (fundsSelected.length === 2 &&
                            isMobile &&
                            !fundsSelected?.includes(fund.isinCode)) ||
                          (
                            fundsSelected.length === 4 &&
                            !isMobile &&
                            !fundsSelected?.includes(fund.isinCode)
                          )
                        }
                        isGreenLabel={false} // disabled until geen label is reactivated by MOA
                        isSelected={!!fund.isinCode && fundsSelected?.includes(fund.isinCode)}
                        key={fund.isinCode}
                        setFundsSelected={selectedFund => {
                          fund.isinCode && fundsSelected?.includes(fund.isinCode)
                            ? setFundsSelected(
                              fundsSelected.filter(
                                selected => selected !== selectedFund
                              )
                            )
                            : setFundsSelected((old: any) => [
                              ...old,
                              selectedFund,
                            ]);
                        }}
                      />
                    );
                  })}
                </View>
              ) : (
                <>
                  <View style={styles.fundsTableKeysContainerStyle}>
                    <View style={styles.fundsKeysIconsStyle}>
                      <View style={styles.fundsKeysStyle}>
                        <RoundedIcon
                          containerBackground={theme.colors.basics.grey.c100}
                          containerSize={20}
                          containerStyle={styles.roundedIconStyle}
                          iconColor={theme.colors.buttons.classic.c500}
                          iconName={IconEnum.CONTRIBUTION}
                          iconSize={theme.metrics.spacing.s}
                        />
                        <Text variant="t3">
                          {formatMessage({ id: 'MyFunds_Screen1_Abondement_label' })}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <FundsComparisonContent
                    fundsOverview={multiFundsOverviews || []}
                    fundsPerformance={multiFundsPerformances || []}
                    multiFundsRepartitionSupports={multiFundsRepartitionSupports || []}
                  />
                  <View style={styles.spacerLineStyle} />
                  <CompareFundsChartPart
                    data={multiFundsPerformances || []}
                  />
                </>
              )}
            </View>
          </ScrollView>
        </View>
      </View>
    </View>
  );
};

const useStyles = createUseStyles(getStyles);
