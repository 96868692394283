import {useTheme} from '@ere-uilib/styles';
import {useMemo} from 'react';
import {StyleSheet} from 'react-native';


export const  useStyles =({
  toggleActiveCard,
}:{
  toggleActiveCard: boolean
})=>{
  const theme = useTheme();
  const styles = useMemo(() => StyleSheet.create({
    cardContainer: {
      borderWidth: 1,
      borderRadius: 5,
      flexDirection: 'column',
      justifyContent: 'space-between',
      paddingVertical: 30,
      textAlign: 'center',
      backgroundColor: toggleActiveCard ? theme.colors.buttons.menuItemHover.c500 : "white",
      borderColor: toggleActiveCard ? theme.colors.buttons.menuItemActive.c500 : theme.colors.basics.grey.c600
    },

  }), [toggleActiveCard]);

  return styles;
}