import React from 'react';

import {
  FontIcon, 
  Title
} from '@ere-uilib/atoms';
import { IconEnum } from '@ere-uilib/enums';
import { useTheme } from '@ere-uilib/styles';
import { useTranslation } from '@translations/index';

import {
  EstimatedContributionProps
} from './interfaces';
import { useStyles } from './useStyles';

export const EstimatedContribution: React.FC<React.PropsWithChildren<EstimatedContributionProps>> =  ({
  isContributionError,
  contribution = undefined,
  style,
}) => {

  const theme = useTheme();
  const styles = useStyles({ style });

  const {
    formatCurrencyNumber,
    formatMessage,
  } = useTranslation();

  const isContributionValid =  typeof contribution === 'number' && contribution > 0;
  return (
    <Title
      style={styles.contributionTextStyle}
      variant="t7"
    >
      <FontIcon
        name={IconEnum.CONTRIBUTION}
        size={theme.metrics.iconSizes.xxm}
        style={styles.contributionIcon}
      />
      {isContributionError ? (
        formatMessage({ id: 'repartition_support_contribution_error_label' })
      ) : isContributionValid ? (
        formatCurrencyNumber({ value: contribution || 0 })) :
        formatMessage({ id: 'VVAbondementDescription' })
      }
    </Title>
  );
};

