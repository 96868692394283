import React from 'react';
import { View, StyleProp, ViewStyle, TextStyle, TouchableOpacity } from 'react-native';

import { Text } from '@components/index';
import { useTheme, ThemeType, useScreenSizes } from '@ere-uilib/styles';
import { createUseStyles } from '@ere-uilib/styles/createUseStyles';
import { useTranslation } from '@ere-uilib/translations';

interface DataProps {
  children?: React.ReactNode;
  onPress?: () => void;
  amount?: number;
  percent?: number;
  isPercentage?:boolean
  hasDarkBackground?: boolean
}

type EditAmountButtonStyles = {
  containerStyle?: StyleProp<ViewStyle>;
  amountStyle?: StyleProp<TextStyle>;
  positiveAmountStyle?: StyleProp<TextStyle>;
};

type EditAmountButtonStylesContext = {
            theme?: ThemeType;
            isMobile?: boolean;
            hasDarkBackground?: boolean;
            isPercentage:boolean,
            amount: number
        };

type Props = DataProps & EditAmountButtonStyles

export const EditAmountButton: React.FC<React.PropsWithChildren<Props>> =  ({
  hasDarkBackground,
  amount = 0,
  percent = 0,
  isPercentage = false,
  onPress,
  amountStyle,
  containerStyle,
}: Props) => {
  const theme = useTheme();
  const { isMobile } = useScreenSizes();
  const styles = useStyles({ theme, isMobile, hasDarkBackground, isPercentage, amount },
    { containerStyle, amountStyle });
  const { formatCurrencyNumber, formatPercentNumber } = useTranslation();

  return (
    <View>
      <TouchableOpacity
        onPress={onPress}
        style={styles.containerStyle}>
        <Text
          style={styles.amountStyle}
          variant="t2"
          weight="bold">
          {(amount > 0 && !isPercentage) && '+ '}
          {isPercentage ?
            formatPercentNumber({ value: percent })
            : formatCurrencyNumber({ value: amount })}
        </Text>
      </TouchableOpacity>
    </View>
  );
};

const getStyles = (
  context?: EditAmountButtonStylesContext,
  style?: EditAmountButtonStyles,
): EditAmountButtonStyles => ({
  containerStyle: [{
    borderBottomColor: context?.theme?.colors.basics.grey.c500,
    borderBottomWidth: 2,
    minWidth: context?.theme?.metrics.spacing.xxxbig,
    marginBottom: context?.theme?.metrics.spacing.xs,
    alignSelf: 'flex-end',
  },
  context?.hasDarkBackground && {
    borderBottomColor: context?.theme?.colors.basics.white,
  },
  style?.containerStyle],
  amountStyle: [{
    alignContent: 'flex-end',
    textAlign: 'right',
    marginBottom: context?.theme?.metrics.spacing.xs,
    color: (context && context?.amount > 0 || context?.isPercentage) ?
      context?.theme?.colors.text.link.c500 : context?.theme?.colors.basics.grey.c500,
  },
  context?.hasDarkBackground && {
    color: context?.theme?.colors.basics.white,
  },
  style?.amountStyle],
  positiveAmountStyle: [{
    color: context?.theme?.colors.text.link.c500,
  },
  context?.hasDarkBackground && {
    color: context?.theme?.colors.basics.white,
  },
  style?.positiveAmountStyle],
});

const useStyles = createUseStyles(getStyles);
