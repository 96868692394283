import { FontIcon, Title } from '@ere-uilib/atoms';
import { IconEnum } from '@ere-uilib/enums';
import { useTheme } from '@ere-uilib/styles';
import React from 'react';
import { View } from 'react-native';
import { Props } from './interfaces';
import { useStyles } from './useStyles';



export const DocumentTag = ({
  taggedCategory,
  setResetFilter,
  tagName
}: Props) => {
  const theme = useTheme();
  const style = useStyles();

  const renderAvailableTag = () => {
    return (
      <View style={style.container}>
        <Title
          style={style.title}
          variant="t8">
          {tagName}
        </Title>
        <FontIcon
          color={theme.colors.text.primary.c500}
          name={IconEnum.CLOSE}
          onPress={() => {
            setResetFilter(taggedCategory)
          }}
          size={theme.metrics.iconSizes.xs}
        />
      </View>
    );
  };

  return (
    renderAvailableTag()
  );
};
