import React, { useCallback, useMemo } from 'react';
import { View, StyleProp, ViewStyle } from 'react-native';

import { Text } from '@ere-uilib/atoms';
import { StatusTag } from '@ere-uilib/molecules/tags';
import { StatusTagTypesEnum } from '@ere-uilib/molecules/tags/StatusTag/sharedInterfaces';
import { createUseStyles, useTheme, useScreenSizes } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';
import { NetAssetValueType } from '@ere-uilib/types';
import { OperationsHistoryStatusEnum } from '@ere-uilib/enums';
import {AvailabilityItem} from "@modules/common/types";
import { getStyles } from './styles';

interface Props {
  title?: string | null;
  numberOfUnits?: number;
  statusCode?: string;
  hasContribution?: boolean;
  contribution?: number;
  onTitlePress?: () => void
  containerStyle?: StyleProp<ViewStyle>;
  hasContributionError?: boolean;
  percentage?: number;
  netAssetValue?: NetAssetValueType;
  details?: AvailabilityItem[]
}
const contentStyles = createUseStyles(getStyles);

export const SynthetisFundInnerColumns: React.FC<React.PropsWithChildren<Props>> = ({
  containerStyle,
  netAssetValue,
  onTitlePress,
  statusCode,
  details
}) => {
  const theme = useTheme();
  const isTitlePressable = !!onTitlePress;
  const isMobile = useScreenSizes().isMobile;
  const styles = contentStyles({ isMobile, theme, isTitlePressable }, { containerStyle });
  const {
    formatMessage,
    formatDate,
    formatCurrencyNumber,
    formatShareCountNumber
  } = useTranslation();

  const { amount: assetValueAmount, dateValue: assetValueDate } = netAssetValue ?? {};

  const assetValueFormattedDate = useMemo(() => {
    const formattedDate = formatDate({
      value: assetValueDate,
      options: {
        dateStyle: 'short',
      },
    });
    return assetValueDate && formattedDate;
  }, [formatDate, assetValueDate]);

  const label = useMemo(() => ({
    assetValueTitle: formatMessage({ id: 'DetailOperationDetailPositionVL' }),
    assetValueDateNC: formatMessage({ id: 'DetailOperationVLNC' }),
    pending: formatMessage({ id: 'DetailOperationEnAttente' }),
    numberOfUnitsTitle: formatMessage({ id: 'DetailOperationDetailPositionParts' }),
    dueDateTitle: formatMessage({ id: 'DetailOperationDetailPositionEcheance' })
  }), [formatMessage]);

  const getAssetValueTitle = useCallback(
    () =>
      [label.assetValueTitle, assetValueFormattedDate || label.assetValueDateNC]
        .join((assetValueFormattedDate && ' ') || ' : '),
    [assetValueFormattedDate, label]
  );

  const renderDetailLabel = useCallback((content: string | number | null | undefined) => {
    if (statusCode === OperationsHistoryStatusEnum.IN_PROGRESS || !content)
      return (
        <StatusTag
          containerStyle={styles.statusTag}
          label={label.pending}
          type={StatusTagTypesEnum.EN_ATTENTE}
        />
      );

    return (
      <Text
        style={styles.columnValueStyle}
        variant="t3"
        weight="regular">
        {content}
      </Text>
    );
  }, []);

  return (
    <View style={styles.containerStyle}>
      <View style={styles.startColumnStyle}>
        <Text
          style={styles.columnTitleStyle}
          variant="t3"
          weight="light"
        >
          {getAssetValueTitle()}
        </Text>
        {details?.map(detailItem =>
          renderDetailLabel(
            formatCurrencyNumber({
              value: assetValueAmount || 0, // Why assetValueAmount but not line.amount ?
              options: {
                maximumFractionDigits: 4
              }
            })
          )
        )}
      </View>
      <View style={styles.middleColumnStyle}>
        <Text
            style={styles.columnTitleStyle}
            variant="t3"
            weight="light"
        > {label.numberOfUnitsTitle}
        </Text>
        {details?.map(detailItem =>
          renderDetailLabel(
            formatShareCountNumber({
              value: detailItem?.numberOfUnits || 0,
              options: {
                maximumFractionDigits: 4
              }
            })
          )
        )}
      </View>
      <View style={styles.endColumnStyle}>
        <Text
            style={styles.columnTitleStyle}
            variant="t3"
            weight="light"
        > {label.dueDateTitle}
        </Text>
        {details?.map(detailItem =>
          renderDetailLabel(
            detailItem.useAvailabilityLabel
              ? formatMessage({ id: detailItem?.availabilityLabel || '' })
              : formatDate({
                value: detailItem?.datetime,
                options: {
                  dateStyle: 'short',
                },
              })
          )
        )}
      </View>
    </View>
  );
};
