import React, { useCallback } from 'react';

import { HtmlStyledRenderer } from '@components/ERE-UILib';
import { InformativePopin } from '@ere-uilib/organisms';

import { useContent } from './useContent';
import { useStyles } from './useStyles';

const ICON_HEIGHT = 138;
const ICON_WIDTH = 240;

export const ProfileEditAddressPopin: React.FC = () => {
  const styles = useStyles({ iconWidth: ICON_WIDTH, iconHeight: ICON_HEIGHT });

  const { title, description, isModalVisible, ...others } = useContent();

  const renderContent = useCallback(
    () => (
      <HtmlStyledRenderer
        baseFontStyle={styles.html}
        html={description}
      />
    ),
    [description, styles]
  );

  return (
    <InformativePopin
      content={renderContent}
      iconHeight={ICON_HEIGHT}
      iconStyle={styles.iconStyle}
      iconWidth={ICON_WIDTH}
      isModalVisible={isModalVisible}
      testId="PostalAddress_PopinSuccessOrError_Button_Retry"
      title={title}
      {...others}
    />
  );
};
