import { AuthenticateAction, AuthActionType } from '@modules/auth/actions/authActionsTypes';

import { RetirementActionsType, RetirementAction } from './actions/retirementActionsTypes';
import { RetirementState } from './types';

const initialState: RetirementState = {
  retirementDecision: [],
  retirementDecisionForm: [],
  error: {
    code: '',
    correlationId: '',
    innerMessage: '',
    message: ''
  }
};

export default function retirementReducer(
  state: RetirementState = initialState,
  action: RetirementAction | AuthenticateAction
): RetirementState {
  switch (action?.type) {
  case RetirementActionsType.GET_RETIREMENT_DECISION_SUCCESS:
    return {
      ...state,
      retirementDecision: action.decision
    };
  case RetirementActionsType.POST_RETIREMENT_DECISION_SUCCESS:
    return {
      ...state,
      retirementDecisionForm: action.formDecision,
    };
  case RetirementActionsType.GET_RETIREMENT_DECISION_FAILURE:
  case RetirementActionsType.POST_RETIREMENT_DECISION_FAILURE:
    return {
      ...state,
      error: action.error
    };
  case RetirementActionsType.CLEAR_RETIREMENT_DATA:
  case AuthActionType.LOGOUT_CALLBACK_SUCCESS:
    return initialState;
  default:
    return state;
  }
}
