import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { postRetirementDecisionRequest } from '@modules/retirement/actions/retirementActions';
import { RetirementDecisionSubmitForm } from '@modules/retirement/types';
import { RetirementActionsType } from '@modules/retirement/actions/retirementActionsTypes';

import { useController } from '../components';
import { RetirementPage } from './RetirementPage.component';
import { AppState } from '@modules/index';

export const RetirementPageContainer = () => {
  const dispatch = useDispatch();
  const { retirementDecisionTabFormValues, totalAmount } = useController(true);
  const isLoading = useSelector((state: AppState) => state.isLoading[RetirementActionsType.POST_RETIREMENT_DECISION_REQUEST]);
  const isRetirementApiLoading = useSelector((state: AppState) => state.isLoading[RetirementActionsType.GET_RETIREMENT_DECISION_REQUEST]);
  const handleSubmit = useCallback((data: RetirementDecisionSubmitForm[]) => {
    return dispatch(postRetirementDecisionRequest(data))
  }, [dispatch])

  return (
    <RetirementPage
      onSubmit={handleSubmit}
      tabFormValues={retirementDecisionTabFormValues}
      totalAmount={totalAmount}
      isLoading={isLoading}
      isRetirementLoading={isRetirementApiLoading}
    />
  )
};
