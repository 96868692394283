import { AuthActionType, AuthenticateAction } from '@modules/auth/actions/authActionsTypes'
import { ErrorActionsType } from '@modules/error/actions/errorActionsTypes'
import { updateQuestionWhenResponded } from '@modules/utils/requirementDefinition'

import { CommonRefundAction } from './actions/refundActionsTypes'
import { CommonRefundActionsType } from './types'
import { RefundsCommonState } from './types'
import { UnavailableRefundAction } from './unavailable-refund/actions/refundActionsTypes'

const initialState: RefundsCommonState = {
  decision: {
    availableRepaymentBlockages: [],
    unavailableRepaymentBlockages: [],
    canPerformAvailableRepayment: false,
    canPerformUnavailableRepayment: false,
    questions: [],
    dispositifs: [],
    elligibleDispositifs: [],
    selectedDispositif: [],
    saverDetails: null
  },
  error: {
    code: '',
    correlationId: '',
    message: '',
    innerMessage: ''
  }
}

export const backToQuestionRefunds = (state: RefundsCommonState, editedQuestionIndex: number) => {
  let newQuestions = [...state.decision.questions]
  newQuestions = newQuestions.slice(0, editedQuestionIndex + 1)

  const returnedData = {
    dispositifs: [...state.decision.dispositifs],
    questions: newQuestions.map((question, index) => {
      let newQuestion = { ...question }

      if (index >= editedQuestionIndex) {
        newQuestion = {
          ...question,
          responded: undefined,
          implicitResponseEnabled: false
        }
      }

      return newQuestion
    })
  }

  return returnedData
}

export default function refundReducer(
  state: RefundsCommonState = initialState,
  action: CommonRefundAction | UnavailableRefundAction | AuthenticateAction
): RefundsCommonState {
  switch (action?.type) {
    case CommonRefundActionsType.GET_REFUND_DECISION_REQUEST:
      return {
        ...state,
        decision: initialState.decision
      }
    case CommonRefundActionsType.GET_REFUND_DECISION_SUCCESS:
      return {
        ...state,
        decision: action.decision
      }
    case CommonRefundActionsType.UPDATE_REFUNDS_QUESTION_WITH_SET_RESPONSE:
      return {
        ...state,
        decision: {
          ...state.decision,
          ...updateQuestionWhenResponded(state, action.response, action.index, action.canNotBeAsked)
        }
      }
    case CommonRefundActionsType.UPDATE_UNAVAILABLE_REFUNDS_QUESTION_WITH_SET_RESPONSE:
      return {
        ...state,
        decision: action.newDecision
      }
    case CommonRefundActionsType.REFUNDS_BACK_TO_QUESTION:
      return {
        ...state,
        decision: {
          ...state.decision,
          ...backToQuestionRefunds(state, action.index)
        }
      }
    case CommonRefundActionsType.SET_REFUNDS_ELIGIBLE_DISPOSITIFS:
      return {
        ...state,
        decision: {
          ...state.decision,
          elligibleDispositifs: action.elligibleDispositifs
        }
      }
    case CommonRefundActionsType.SET_REFUNDS_SELECTED_DISPOSITIF:
      return {
        ...state,
        decision: {
          ...state.decision,
          selectedDispositif: action.selectedDispositif
        }
      }
    case CommonRefundActionsType.REFUND_INIT_ERROR:
    case ErrorActionsType.INIT_ALL_ERRORS:
      return {
        ...state,
        error: initialState.error
      }
    case CommonRefundActionsType.CLEAR_REFUND_DATA:
    case AuthActionType.LOGOUT_CALLBACK_SUCCESS:
      return initialState
    default:
      return state
  }
}
