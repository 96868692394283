import { Context, Style } from './interfaces';

export const getStyles = (
  context?: Context,
  style?: Style,
): Style => ({
  notificationContainer: [
    {
      marginVertical: context?.theme?.metrics.spacing.xm,
    },
    style?.notificationContainer,
  ],
  notificationLabel: [
    {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    style?.notificationLabel,
  ],
  buttonContainer: [
    context?.isMobile
      ? {
        paddingHorizontal: context?.theme?.metrics.spacing.s,
      }
      : {
        flexDirection: 'row',
        marginTop: context?.theme?.metrics.spacing.xxl,
      },
    style?.buttonContainer,
  ],
  bodyContainer: [
    !context?.isMobile && {
      paddingLeft: context?.theme?.metrics.spacing.xl,
    },
    style?.bodyContainer,
  ],
  stepperContainer: [
    {
      marginVertical: context?.theme?.metrics.spacing.xm,
    },
    style?.stepperContainer,
  ],
  title: [
    {
      marginVertical: context?.theme?.metrics.spacing.xm,
    },
    style?.title,
  ],
  notification: [
    {
    },
    context?.isMobile && {
      flex: 1,
    },
    style?.notification,
  ],
  headerContainerStyle: [
    {
      marginBottom: context?.theme?.metrics.spacing.s,
    },
    style?.headerContainerStyle,
  ],
  loaderContainer: [
    {
      flexDirection: 'row',
      alignItems: 'center',
      paddingVertical: context?.theme?.metrics.spacing.xs,
    },
    style?.loaderContainer,
  ],
  loader: [
    { flex: 1, marginLeft: context?.theme?.metrics.spacing.xm },
    style?.loader,
  ],
  blockedPageContainer: [
    {
      width: '100%',
      maxWidth: context?.theme?.metrics.contentSizes.centeredContentMaxWidth,
      alignSelf: 'center',
    },
    style?.blockedPageContainer,
  ],
  dialOnceHelpButton: [
    {
      marginTop: context?.theme?.metrics.spacing.s,
      width: context?.theme?.metrics.contentSizes.dialOnceButton,
    },
    context?.isMobile && {
      alignSelf: 'center',
    },
    style?.dialOnceHelpButton,
  ],
});
