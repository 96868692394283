import React, { useState } from 'react';
import { StyleProp, TextStyle, ViewStyle } from 'react-native';

import { Title, Text } from '@ere-uilib/atoms';
import { ActivableButtonSet } from '@components/index';

import { useStyles } from './useStyles';

interface Props {
  title: string;
  subtitle?: string;
  onPress?: () => void;
  disabled?: boolean;
  testId?: string;
  style?: StyleProp<ViewStyle>;
  titleStyle?: StyleProp<TextStyle>;
}

export const CardChoice: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  subtitle,
  onPress,
  disabled,
  style,
  titleStyle,
  testId
}) => {
  const styles = useStyles();
  const [hovered, setHovered] = useState<boolean>(false);

  const isHoveredStyle = hovered ? styles.hoveredTextStyle : {}

  return (
    <ActivableButtonSet
      buttonStyle={[styles.cardChoiceContainer, style]}
      disabled={disabled}
      onChange={onPress}
      hovered={hovered}
      setHovered={setHovered}>
      <Title
        style={[titleStyle, isHoveredStyle]}
        variant="t7"
        testID={testId}
        accessibilityLabel={testId}>
        {title}
      </Title>
      {subtitle && (
        <Text
          variant="t4"
          weight="light"
          style={isHoveredStyle}
        >
          {subtitle}
        </Text>
      )}
    </ActivableButtonSet>
  );
};
