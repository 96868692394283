import React, { useCallback, useState } from 'react';
import { ScrollView, TouchableOpacity, View } from 'react-native';

import {
  FontIcon,
  Modal,
  SquareIcon, Text,
  Title
} from '@ere-uilib/atoms';
import { IconEnum } from '@ere-uilib/enums';
import { ActivableButtonSet } from '@ere-uilib/molecules/buttons';
import { EnumSizeTypes } from '@ere-uilib/molecules/buttons/ActivableButtonSet/interfaces';
import { ModalFrame } from '@ere-uilib/molecules/overlay';
import {
  createUseStyles,
  useScreenSizes, useTheme
} from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';

import { LanguageSelectorProps, Option } from './interface';
import { getStyles } from './styles';

export const LanguageSelector: React.FC<React.PropsWithChildren<LanguageSelectorProps>> = ({
  value,
  options = [],
  onChange,
  containerStyles,
  offlineLayoutAbsolutePosition = false,
  testId
}) => {
  const { formatMessage } = useTranslation();
  const { isDesktop, isTablet, isMobile } = useScreenSizes();
  const theme = useTheme();
  const [modalVisible, setModalVisible] = useState(false);
  const [hovered, setHovered] = useState<boolean>(false);
  const [hoveredIndex, setHoveredIndex] = useState<number>();

  const styles = useStyles({
    theme,
    modalVisible,
    isDesktop,
    isTablet,
    isMobile,
    offlineLayoutAbsolutePosition,
  }, {
    containerStyles,
  });
  const currentOption = options.find(item => item.value === value);
  const onSelect: (item: Option) => void = item => {
    setModalVisible(false);
    onChange(item);
  };

  const handleModalClose = useCallback(() => {
    setModalVisible(false);
  }, []);

  const handleLanguageSelectorPress = useCallback(() => {
    setModalVisible(!modalVisible)
  }, [modalVisible]);

  const handleHovered = (value: boolean, index: number) => {
    setHovered(value)
    setHoveredIndex(index)
  }

  return (
    <View
      accessibilityLabel={testId}
      style={styles.containerStyles}
      testID={testId}>
      <Modal visible={modalVisible}>
        <ModalFrame
          desktopPosition="center"
          mobilePosition="bottom"
          onClose={handleModalClose}
        ><ScrollView
          contentContainerStyle={styles.modalContainer}
        >
            {!isDesktop && (
              <>
                <SquareIcon
                  backgroundColor={theme.colors.basics.grey.c100}
                  iconColor={theme.colors.basics.grey.c500}
                  iconName={IconEnum.PAYS}
                  iconSize={theme.metrics.iconSizes.l}
                />
                <Title
                  style={{ marginVertical: theme.metrics.spacing.m }}
                  variant="t7"
                  weight="bold"
                >
                  {formatMessage({ id: 'LanguageSelectorTitle' })}
                </Title>
              </>
            )}
            <View style={styles.modalLanguages}>
              {options.map((item, index) => {
                const handleSelectLanguage = () => onSelect(item);
                const isSelected = value === item.value;

                return (
                  <View
                    accessibilityLabel={item.displayName && item.label}
                    key={index}
                    style={styles.modalLanguageButtonsContainer}
                    testID={item.displayName && item.label}
                  >
                    <ActivableButtonSet
                      buttonStyle={styles.modalLanguageButtons}
                      hovered={index === hoveredIndex ? hovered : false}
                      isSelected={isSelected}
                      label={item.displayName && item.displayName.toUpperCase()}
                      onChange={handleSelectLanguage}
                      setHovered={hoveredValue => handleHovered(hoveredValue, index)}
                      size={EnumSizeTypes.small}
                    />
                  </View>
                )
              })}
            </View>
          </ScrollView>
        </ModalFrame>
      </Modal>
      <TouchableOpacity
        onPress={handleLanguageSelectorPress}
        style={styles.currentValueStyles}
      >
        <Text
          style={styles.languageLabelText}
          variant="t3">
          {currentOption?.label}
        </Text>
        <FontIcon
          name={modalVisible ? 'chevron-haut' : 'chevron-bas'}
          style={styles.chevronIcon}
        />
      </TouchableOpacity>
    </View>
  );
};

const useStyles = createUseStyles(getStyles);
