import {
  BackgroundGradientTransition,
  Modal,
  ModalFrame,
  SimpleButton,
} from '@ere-uilib/index';
import { useScreenSizes } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';
import { DocumentCategory } from '@modules/document-space/types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ScrollView, View } from 'react-native';
import { getSelectedFilesByCategories } from '../../utils';
import { DocumentFilterContent } from '../DocumentFilterContent';
import { Props } from './interfaces';
import { useStyles } from './useStyles';



export const DocumentFilterModal = ({
  isVisible,
  onClose,
  categories,
  onSetDocumentsCategoriesFilter,
  files,
  onGetDocumentsCategories,
  onSetDocumentsCategoriesReset,
  onSetAllDocumentsCategoriesFilter
}: Props) => {

  const { formatMessage } = useTranslation();
  const { isMobile, isTablet } = useScreenSizes();
  const style = useStyles();
  const [documentsNumber, setDocumentsNumber] = useState(
    getSelectedFilesByCategories(categories, files).length
  );
  const [availableCategories, setAvailableCategories] = useState(categories);

  const selectedCategories = useMemo(() => categories.filter(category => !!category.isSelected), [categories]);
  const resultsBottomActionEnabled = selectedCategories.length > 0;

  const resetAll = useCallback(() => {
    onSetDocumentsCategoriesReset()
    onGetDocumentsCategories();
    setAvailableCategories(categories);
  }, [categories, onGetDocumentsCategories, onSetDocumentsCategoriesReset]);



  const setCategoryToFilter = (category: DocumentCategory) => {
    onSetDocumentsCategoriesFilter(category.id, !!category.isSelected);
  };

  useEffect(() => {
    const matchingSelectedDocuments = getSelectedFilesByCategories(categories, files);
    setDocumentsNumber(matchingSelectedDocuments.length);
    setAvailableCategories(categories);
  }, [categories, files]);

  //   TO DO SET TAG TRACKER
  const renderBottomResultsActions = () => (
    <View style={style.bottomActionsStyle}>
      <View style={style.bottomActionsButtonsContainerStyle}>
        <SimpleButton
          containerStyle={style.simpleButtonLeftStyle}
          design="outlined"
          onPress={resetAll}
          size="large"
          title={formatMessage({ id: 'DocCenterReinitiateButton' })}
        />
        <SimpleButton
          containerStyle={style.simpleButtonRightStyle}
          design="solid"
          onPress={() => { onClose() }}
          size="large"
          title={`${formatMessage({ id: 'DocCenterResultatButton' })} (${documentsNumber})`}
        />
      </View>
    </View>
  );

  const renderBottomDefaultActions = () => (
    <View style={style.bottomActionsStyle}>
      <View style={style.bottomActionsButtonsContainerStyle}>
        <SimpleButton
          containerStyle={style.simpleButtonStyle}
          design="solid"
          onPress={() => {
            onSetAllDocumentsCategoriesFilter()
            onClose()
          }}
          size="large"
          title={formatMessage({
            id: 'DocCenterSeeAllButton',
            values: { variable: files.length }
          })}
        />
      </View>
    </View>
  );

  const submit = useCallback(
    () =>
      resultsBottomActionEnabled && documentsNumber > 0
        ? renderBottomResultsActions()
        : renderBottomDefaultActions(),
    [
      documentsNumber,
      renderBottomDefaultActions,
      renderBottomResultsActions,
      resultsBottomActionEnabled
    ]
  );

  return (
    <Modal visible={isVisible}>
      <ModalFrame
        maxDesktopWidth={800}
        mobilePosition="full"
        onClose={onClose}
        title={formatMessage({ id: 'Saving_Tab1_Filter_title' })}
        titleText={style.modalTitleStyle}>

        <ScrollView
          style={style.scrollview}
          contentContainerStyle={style.scrollViewContainer}
        >
          <DocumentFilterContent
            availableCategories={availableCategories}
            renderBottomActions={submit}
            setCategoryToFilter={setCategoryToFilter}
          />

        </ScrollView>
        {(isMobile || isTablet) && (
          <BackgroundGradientTransition>
            {submit()}
          </BackgroundGradientTransition>
        )}
      </ModalFrame>
    </Modal>
  );
};
