import {
  ControlDataErrorState,
  FetchControlDataState,
  FetchControlDataEditingOptionState,
  ControlDataManagement,
  EditedControlDataType,
} from '../types';
import * as actions from './controlDataActionsTypes';
import { ControlDataActionsType } from './controlDataActionsTypes';

export function getRehydrateControlDataRequest(
  planId: number,
  management: ControlDataManagement
): actions.GetRehydrateControlDataAction {
  return {
    type: ControlDataActionsType.REHYDRATE_CONTROL_DATA,
    planId,
    management
  };
}

// Init Error
export function getControlDataInitError(
  error: ControlDataErrorState
): actions.GetControlDataInitErrorAction {
  return {
    type: ControlDataActionsType.GET_CONTROL_DATA_INIT_ERROR,
    error
  };
}

export function getControlDataRequest():
  actions.GetControlDataRequestAction {
  return {
    type: ControlDataActionsType.GET_CONTROL_DATA_REQUEST,
  };
}

export function getControlDataSuccess(
  data: FetchControlDataState[]
): actions.GetControlDataSuccessAction {
  return {
    type: ControlDataActionsType.GET_CONTROL_DATA_SUCCESS,
    data
  };
}

export function getControlDataFailure(
  error: ControlDataErrorState
): actions.GetControlDataFailureAction {
  return {
    type: ControlDataActionsType.GET_CONTROL_DATA_FAILURE,
    error
  };
}

export function getControlDataEditingOptionsRequest(planId: number):
  actions.GetControlDataEditingOptionsRequestAction {
  return {
    type: ControlDataActionsType.GET_CONTROL_DATA_EDITING_OPTIONS_REQUEST,
    planId
  };
}

export function getControlDataEditingOptionsSuccess(
  editingOptions: FetchControlDataEditingOptionState
):
  actions.GetControlDataEditingOptionsSuccessAction {
  return {
    type: ControlDataActionsType.GET_CONTROL_DATA_EDITING_OPTIONS_SUCCESS,
    editingOptions
  };
}

export function getControlDataEditingOptionsFailure(
  error: ControlDataErrorState
): actions.GetControlDataEditingOptionsFailureAction {
  return {
    type: ControlDataActionsType.GET_CONTROL_DATA_EDITING_OPTIONS_FAILURE,
    error
  };
}

export function postDrivingDataRequest(
  editedDrivingData: EditedControlDataType,
  planId: number
): actions.PostControlDataEditingOptionsRequestAction {
  return {
    type: ControlDataActionsType.POST_CONTROL_DATA_EDITING_OPTIONS_REQUEST,
    editedDrivingData,
    planId
  };
}

export function postDrivingDataSuccess(
  isEditedDrivingDataSuccess: boolean
):
  actions.PostControlDataEditingOptionsSuccessAction {
  return {
    type: ControlDataActionsType.POST_CONTROL_DATA_EDITING_OPTIONS_SUCCESS,
    isEditedDrivingDataSuccess
  };
}

export function postDrivingDataFailure(
  error: ControlDataErrorState
): actions.PostControlDataEditingOptionsFailureAction {
  return {
    type: ControlDataActionsType.POST_CONTROL_DATA_EDITING_OPTIONS_FAILURE,
    error
  };
}
export function clearDrivingData(): actions.ClearDrivingDataAction {
  return {
    type: ControlDataActionsType.CLEAR_DRIVING_DATA
  };
}
