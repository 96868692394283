import React from 'react'
import { HomePageComponent } from './HomePage.component'
import { useController } from './useController'

export const HomePage = () => {
  const {
    userAccounts,
    allocations,
    errors,
    allPlans,
    selectedCompany,
    operations,
    OCDTop,
    OCDBottom,
    tools,
    appSettings,
    isOCDApiLoading,
    isBIApiLoading,
    isToolsApiLoading,
    isOperationsApiLoading,
    EnableFeatureRegroupementContrats,
    contributionByCompany,
    budgetInsightAccounts,
    cardTableLoading,
    isAllocationsApiLoading,
    isAvalabilitiesApiLoading,
    onGetOCD,
    onGetTool,
    onSelectCompany,
    handleSapiendoPress,
    onRedirectToSavingsTabs,
    handleUnlockHelpPress,
    availabilityRetryAction,
    globalSavingsRetryAction,
    recentOperationsRetryaction,
    onGetContributionByCompany,
    onGetBudgetInsightAccounts,
    onGetDashboardDataRequest,
    onGetBudgetInsightManagerUrl,
    availabilities
  } = useController()
  return (
    <HomePageComponent
      tools={tools}
      errors={errors}
      OCDTop={OCDTop}
      allPlans={allPlans}
      OCDBottom={OCDBottom}
      operations={operations}
      appSettings={appSettings}
      allocations={allocations}
      userAccounts={userAccounts}
      availabilities={availabilities}
      isBIApiLoading={isBIApiLoading}
      isOCDApiLoading={isOCDApiLoading}
      selectedCompany={selectedCompany}
      cardTableLoading={cardTableLoading}
      isToolsApiLoading={isToolsApiLoading}
      contributionByCompany={contributionByCompany}
      budgetInsightAccounts={budgetInsightAccounts}
      isOperationsApiLoading={isOperationsApiLoading}
      isAllocationsApiLoading={isAllocationsApiLoading}
      isAvalabilitiesApiLoading={isAvalabilitiesApiLoading}
      EnableFeatureRegroupementContrats={EnableFeatureRegroupementContrats}
      onGetOCD={onGetOCD}
      onGetTool={onGetTool}
      onSelectCompany={onSelectCompany}
      handleSapiendoPress={handleSapiendoPress}
      handleUnlockHelpPress={handleUnlockHelpPress}
      onRedirectToSavingsTabs={onRedirectToSavingsTabs}
      availabilityRetryAction={availabilityRetryAction}
      globalSavingsRetryAction={globalSavingsRetryAction}
      onGetDashboardDataRequest={onGetDashboardDataRequest}
      onGetContributionByCompany={onGetContributionByCompany}
      onGetBudgetInsightAccounts={onGetBudgetInsightAccounts}
      recentOperationsRetryaction={recentOperationsRetryaction}
      onGetBudgetInsightManagerUrl={onGetBudgetInsightManagerUrl}
    />
  )
}
