import React from 'react';
import { ViewStyle, StyleProp, View, ScrollView, TextStyle } from 'react-native';

import {
  DashboardPageWrapperConnected,
  AnnounceContent } from '@components/index';
import { createUseStyles, useTheme, ThemeType, useScreenSizes } from '@ere-uilib/styles';
interface Props extends StylesProps {
  children?: React.ReactNode
  sourceName: string
  title: string | React.ReactNode
  description?: string | React.ReactNode
  loading?: boolean;
  actions?: () => React.ReactNode
}

interface StylesProps {
  containerStyle?: StyleProp<ViewStyle>
  scrollStyle?: StyleProp<ViewStyle>
  scrollContentStyle?: StyleProp<ViewStyle>
  announceContainer?: StyleProp<ViewStyle>
  svgContainer?: StyleProp<ViewStyle>
  contentStyle?: StyleProp<ViewStyle>
  titleStyle?: StyleProp<TextStyle>
  subtitleStyle?: StyleProp<TextStyle>
  buttonStyle?: StyleProp<TextStyle>
  bottomGradientBloc?: StyleProp<ViewStyle>
}

interface StylesContext {
  theme: ThemeType;
  isMobile: boolean;
  isTablet: boolean;
  windowHeight: number;
}

export const DashboardAnnounce: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  sourceName,
  description,
  title,
  loading,
  actions,
}) => {
  const { isMobile, isTablet, windowHeight } = useScreenSizes();
  const theme = useTheme();
  const styles = useStyles({ theme, isMobile, isTablet, windowHeight }, {});

  return (
    <DashboardPageWrapperConnected
      cardContentStyle={{ backgroundColor: theme.colors.basics.white }}
      renderStickyMobileBottom={actions}
    >
      <View style={styles.containerStyle}>
        <ScrollView
          contentContainerStyle={styles.scrollContentStyle}
          style={styles.scrollStyle}
        >

          <AnnounceContent
            actions={actions}
            children={children}
            description={description}
            loading={loading}
            sourceName={sourceName}
            title={title}
            titleStyle={styles.titleStyle}
          />

        </ScrollView>
      </View>
    </DashboardPageWrapperConnected>
  );
};

const getStyles = (
  context?: StylesContext,
  style?: StylesProps,
): StylesProps => ({
  containerStyle: [
    (context?.isMobile || context?.isTablet ) && {
      height: context?.windowHeight,
    },
  ],
  scrollStyle: [
    {
      flex: 1,
    },
    style?.scrollStyle,
  ],
  scrollContentStyle: [
    {
      paddingBottom: context?.theme.metrics.spacing.s,
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100%',
    },
    style?.scrollContentStyle,
  ],
  bottomGradientBloc: [
    {
      marginTop: -(context?.theme.metrics.spacing.xxl || 0),
      paddingHorizontal: context?.theme.metrics.spacing.s,
      paddingBottom: context?.theme.metrics.spacing.l,
    },
  ],
});

const useStyles = createUseStyles(getStyles);
