import {
  MultiSwitchStyles,
  MultiSwitchStylesContext,
} from './interfaces';

export const getStyles = (
  context?: MultiSwitchStylesContext,
  style?: MultiSwitchStyles,
): MultiSwitchStyles => ({
  containerStyle: [
    {
      alignSelf: 'flex-start',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      borderRadius: context?.theme.metrics.spacing.l,
      borderWidth: 1,
      borderColor: context?.theme.colors.basics.primary.c500,
      backgroundColor: context?.theme.colors.basics.white,
      minHeight: 30,
    },
    style?.containerStyle,
  ],
  segment: [
    {
      flexShrink: 1,
      flexGrow: 0,
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 10,
      paddingHorizontal: context?.theme.metrics.spacing.m,
    },
    style?.segment,
  ],
  activeSegment: [
    {
      zIndex: 5,
      borderRadius: context?.theme.metrics.spacing.l,
      position: 'absolute',
      height: '100%',
      backgroundColor: context?.theme.colors.basics.primary.c500,
    },
    style?.activeSegment,
  ],
  defaultText: [
    {
      color: context?.theme.colors.basics.primary.c500,
      fontSize: context?.theme.fonts.fontSize.button.t3,
    },
    style?.defaultText,
  ],
  activeText: [
    {
      color: context?.theme.colors.basics.white,
      fontSize: context?.theme.fonts.fontSize.button.t3,
    },
    style?.activeText,
  ],
});
