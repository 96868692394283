import { connect } from 'react-redux';

import { setIsOidcSessionEnded } from '@modules/auth/actions/authActions';
import { AppState } from '@modules/index';
import { getIsOidcSessionEnded, getUser } from '@modules/auth/selectors';

import { EndOidcSessionWebView as EndOidcSessionWebViewComponent } from './EndOidcSessionWebView.component';

const mapStateToProps = (state: AppState) => {
  return {
    user: getUser(state),
    isOidcSessionEnded: getIsOidcSessionEnded(state),
  };
};

const mapDispatchToProps = {
  setIsOidcSessionEnded: setIsOidcSessionEnded,
};

export const EndOidcSessionWebView = connect(mapStateToProps, mapDispatchToProps)(EndOidcSessionWebViewComponent);
