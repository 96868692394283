import React from 'react';
import { View } from 'react-native';

import {
  DashboardPageWrapperConnected,
  NavigationHeader, OperationCard, SimpleButton,
  useTranslation
} from '@components/index';
import {
  ApiPaymentPeriodicityEnum
} from '@constants/index';
import { RouteNames } from '@constants/navigation';
import { createUseStyles, useScreenSizes, useTheme } from '@ere-uilib/styles';

import { VVPListHeader } from './components';
import { Props } from './interfaces';
import { getStyles } from './styles';

export const VVPListPage: React.FC<React.PropsWithChildren<Props>> = ({
  VVPList,
  navigation,
}: Props) => {

  const theme = useTheme();
  const { isDesktop } = useScreenSizes();

  const styles = useStyles(
    { isMobile: !isDesktop, theme },
    {},
  );

  const { formatMessage } = useTranslation();
  const pageTitle = formatMessage({ id: 'VV_VVP_Actuel_titre' });

  const buttonVVPCreationTitle = formatMessage({ id: 'VV_VVP_Actuel_bouton' });
  const goToVVPCreation = () => {
    navigation.navigate(RouteNames.InstallmentStack, {
      screen: RouteNames.Installments,
    });
  };

  const renderHeader = () =>
    <NavigationHeader
      displayBackButton
      onGoBack={navigation.goBack}
      title={pageTitle}
    />;

  const reccurenceLabels = {
    [ApiPaymentPeriodicityEnum['1M']]: formatMessage({
      id: 'VV_recap_vp_frequencymonthly_info',
    }),
    [ApiPaymentPeriodicityEnum['3M']]: formatMessage({
      id: 'VV_recap_vp_frequency_info',
      values: { frequency: 3 },
    }),
    [ApiPaymentPeriodicityEnum['6M']]: formatMessage({
      id: 'VV_recap_vp_frequency_info',
      values: { frequency: 6 },
    }),
  };
  const handleOperationCardPress = (operationId: string) => {
    navigation.navigate(RouteNames.VVPDetails, {
      id: operationId,
    });
  };

  const renderContent = () => (
    <View style={styles.container}>
      <VVPListHeader />
      <View style={styles.VVPListContainer}>
        {VVPList.map(operation => (
          <OperationCard
            amount={operation.amount}
            onItemPress={() => handleOperationCardPress(operation.id)}
            reccurenceLabel={reccurenceLabels[operation?.periodicity]}
            style={styles.vvpCardsStyle}
            title={formatMessage({ id: 'VV_VVP_Actuel_card_titre' }) + ' ' + operation.planName}
          />
        ))}
      </View>
      <SimpleButton
        containerStyle={styles.buttonStyle}
        onPress={goToVVPCreation}
        title={buttonVVPCreationTitle}
      />
    </View>
  );

  return (
    <DashboardPageWrapperConnected>
      {renderHeader()}
      {renderContent()}
    </DashboardPageWrapperConnected>
  );
};

const useStyles = createUseStyles(getStyles);
