import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useScreenSizes, useTheme } from '@ere-uilib/styles';

export const useStyles = () => {
  const theme = useTheme();
  const { isDesktop } = useScreenSizes();
  const styles = useMemo(
    () =>
      StyleSheet.create({
        cardResponseContainer: {
          zIndex: 1,
          marginVertical: theme.metrics.spacing.xm,
          ...(isDesktop && {
            maxWidth: theme.metrics.contentSizes.centeredContentMaxWidth
          })
        },
        cardResponseChildContainer: {
          paddingVertical: theme.metrics.spacing.s
        },
        rowContainer: {
          flexDirection: 'row',
          alignItems: 'center',
          marginVertical: theme.metrics.spacing.s
        },
        textFieldStyle: {
          maxWidth: 157.5
        },
        textFieldInputStyle: {
          maxWidth: 157.5
        },
        textFieldRightIcon: {
          right: theme.metrics.spacing.m,
          paddingLeft: theme.metrics.spacing.none,
          paddingRight: theme.metrics.spacing.none
        },
        buttonCompute: {
          marginLeft: theme.metrics.spacing.s
        },
        tableContainer: {
          paddingTop: theme.metrics.spacing.xm,
          borderColor: theme.colors.basics.grey.c200,
          borderStyle: 'dashed',
          borderWidth: 0,
          borderTopWidth: 1
        },
        link: {
          color: theme.colors.text.link.c500,
          textDecorationLine: 'underline',
          marginVertical: theme.metrics.spacing.s
        },
        tableLoader: {
          justifyContent: 'center',
          height: 115,
          backgroundColor: theme.colors.basics.grey.c100,
          borderColor: theme.colors.basics.grey.c200,
          borderWidth: 1,
          borderRadius: 5,
          paddingHorizontal: theme.metrics.spacing.l
        },
        loaderPlaceholderView: {
          marginVertical: theme.metrics.spacing.s
        },
        loaderPlaceholderViewHalfLength: {
          width: '50%'
        }
      }),
    [theme, isDesktop]
  );

  return styles;
};
