import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useTheme, useScreenSizes } from '@ere-uilib/styles';

export function useStyles() {
  const theme = useTheme();
  const { isDesktop } = useScreenSizes()

  const styles = useMemo(() => StyleSheet.create({
    title: {
      marginBottom: theme.metrics.spacing.s
    },
    description: {
      marginBottom: theme.metrics.spacing.l
    },
    assigmentSelectorStyle: {
      maxWidth: theme.metrics.contentSizes.leftSideContentMaxWidth,
      marginBottom: theme.metrics.spacing.l,
    },
    bottomButtons: {
      ...(isDesktop && {
        maxWidth: theme.metrics.contentSizes.leftSideContentMaxWidth
      })
    },
    notifications: {
      maxWidth: theme.metrics.contentSizes.leftSideContentMaxWidth,
      marginBottom: theme.metrics.spacing.s
    },
    minimumNotification: {
      marginBottom: theme.metrics.spacing.s
    },
    fiscalityRatecontainer: {
      flexDirection: 'row',
      alignItems: 'flex-start',
      marginTop: theme.metrics.spacing.xs,
      justifyContent: 'space-between',
    },
    fiscalityRateLabel: {
      display: 'flex',
      width: '70%'
    },
    paymentModeDashLine: {
      marginVertical: theme.metrics.spacing.xm,
      marginHorizontal: - theme.metrics.spacing.m
    },
    linkParagraph: {
      marginTop: theme.metrics.spacing.xs
    },
    rowStyle: {
      flexDirection: 'row',
      alignItems: 'center',
    },

  }), [theme, isDesktop]);

  return styles;
}
