import React, { useCallback, useMemo } from 'react';
import { View } from 'react-native';

import { Paragraph, Title, useTranslation } from '@components/ERE-UILib';
import { SVGLocalLoader, Text } from '@ere-uilib/atoms';
import { ContributionPlanType } from '@modules/dashboard/types';

import { useStyles } from './useStyles';

interface ContributionOperationContentProps {
  plan: ContributionPlanType;
}

export const ContributionOperationCardContent: React.FC<React.PropsWithChildren<ContributionOperationContentProps>> = ({ plan }) => {
  const styles = useStyles();
  const { formatMessage, formatCurrencyNumber } = useTranslation();

  const label = useMemo(
    () => ({
      cannotBeOptimized: formatMessage({ id: 'EmployerContributionTopupZone2OptimizerWarningAbondementConsumed' }),
      makePaymentLabel: formatMessage({ id: 'EmployerContributionTopupZone2OptimizerIMakeAPaymentLabel' }),
      getTopupLabel: formatMessage({ id: 'EmployerContributionTopupZone2OptimizerToGetTopUpLabel' }),
      getTopupMaxLabel: formatMessage({ id: 'EmployerContributionTopupZone2OptimizerGetMaxTopUpLabel' }),
      planSupportLabel: formatMessage({
        id: 'EmployerContributionTopupZone2OptimizerPlanSupportColumnTable',
        values: {
          variable: plan.plan
        }
      }),
    }),
    [formatMessage, plan]
  );

  const isAlreadyOptimized = useMemo(
    () => !(plan?.amount && plan?.contributionAmount),
    [plan]
  );

  const renderInstruction = useCallback(() => (
    <React.Fragment>
      <Paragraph
        autoMargin
        variant="t3"
        weight="light">
        {label.getTopupMaxLabel}
      </Paragraph>
      <View style={styles.amountContainerStyle}>
        <SVGLocalLoader
          containerStyle={styles.arrowIconStyle}
          name="rightArrow"
        />
        <View style={styles.amountContentRight}>
          <Text
            variant="t3"
            weight="light">{label.makePaymentLabel}
            <Text
              variant="t3"
              weight="bold">
              {formatCurrencyNumber({ value: Number(plan.amount) })}
            </Text>
          </Text>
          <Text
            variant="t3"
            weight="light">{label.getTopupLabel}
            <Text
              variant="t3"
              weight="bold">
              {formatCurrencyNumber({ value: Number(plan.contributionAmount) })}
            </Text>
          </Text>
        </View>
      </View>
    </React.Fragment>
  ), [formatCurrencyNumber, label, plan, styles]);

  return (
    <React.Fragment>
      <Title
        variant="t7"
        weight="bold">
        {label.planSupportLabel}
      </Title>
      {isAlreadyOptimized && (
        <Paragraph
          autoMargin
          variant="t3"
          weight="light">
          {label.cannotBeOptimized}
        </Paragraph>
      )}
      {!isAlreadyOptimized && renderInstruction()}
    </React.Fragment>
  );

};
