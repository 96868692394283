import { RefundRepartitionPageContext, RefundRepartitionPageStyle } from './interfaces';

export const getStyles = (
  context?: RefundRepartitionPageContext,
  style?: RefundRepartitionPageStyle,
): RefundRepartitionPageStyle => ({
  containerStyle: [
    {
      flex: 1,
      backgroundColor: context?.theme.colors.basics.white,
    },
    style?.containerStyle,
  ],
  installmentsStepperContainerStyle: [{
    alignItems: 'center',
    justifyContent: 'center',
  },
  style?.installmentsStepperContainerStyle,
  ],
  stepperStyle: [
    {
      paddingBottom: context?.theme.metrics.spacing.s,
      marginTop: context && -context?.theme.metrics.spacing.s,
    },
    style?.stepperStyle,
  ],
  subHeaderContainerStyle: [{
    marginBottom: context?.theme.metrics.spacing.xm,
  },
  style?.subHeaderContainerStyle,
  ],
  subHeaderInnerContainerStyle: [{
    paddingBottom: context?.theme.metrics.spacing.l,
  },
  style?.subHeaderInnerContainerStyle,
  ],
  contentTitleStyle: [{
    marginBottom: context?.theme.metrics.spacing.xs,
  },
  style?.contentTitleStyle,
  ],
  contentSubtitleStyle: [{
    marginBottom: context?.theme.metrics.spacing.m,
  },
  style?.contentSubtitleStyle,
  ],
  bottomActionsStyle: [
    {
      width: '100%',
    },
    !(context?.isMobile || context?.isTablet) && {
      marginTop: context?.theme.metrics.spacing.xl,
    },
    style?.bottomActionsStyle,
  ],
  bottomActionsButtonsContainerStyle: [
    {
      width: '100%',
      flexDirection: 'row',
    },
    style?.bottomActionsButtonsContainerStyle,
  ],
  arrowButtonStyle: [
    style?.arrowButtonStyle,
  ],
  simpleButtonStyle: [{
    marginLeft: context?.theme.metrics.spacing.m,
    flex: 1,
  },
  style?.simpleButtonStyle,
  ],
  compareButtonContainerStyle: [
    {
      alignItems: 'center',
    },
    style?.compareButtonContainerStyle,
  ],
  compareButtonStyle: [
    {
      marginBottom: context?.theme.metrics.spacing.s,
      marginTop: context?.theme.metrics.spacing.l,
    },
    style?.compareButtonStyle,
  ],
  notificationContainerStyle: [{
    width: '100%',
  },
  style?.notificationContainerStyle,
  ],
  switchContainerStyle: [{
    flexDirection: 'row',
    backgroundColor: context?.theme?.colors.basics.white,
    height: context?.theme.metrics.spacing.xxxxl,
    alignItems: 'center'
  },
  style?.switchContainerStyle,
  ],

});
