import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useScreenSizes, useTheme } from '@ere-uilib/styles';

export function useStyles() {
  const theme = useTheme();
  const { isDesktop } = useScreenSizes();

  const styles = useMemo(() => StyleSheet.create({
    cardContainerStyle: {
      borderRadius : theme.metrics.borderRadius.xs,
      paddingVertical : theme.metrics.spacing.xm,
      paddingHorizontal : theme.metrics.spacing.s,
      shadowColor: 'rgba(96, 97, 112, 0.2)',
      shadowOffset: {
        width: 0,
        height: 6
      },
      shadowOpacity: 1,
      shadowRadius: 15,
      elevation: 6,
    },
    textCenteredStyle : {
      textAlign : 'center'
    },
    separator : {
      borderBottomWidth: 1,
      width: 50,
      borderColor: theme.colors.basics.grey.c200,
    },
    textDescriptionStyle : {
      fontWeight : '400',
      fontSize : theme.fonts.fontSize.paragraph.t3
    },
    footerContainer : {
      width : '100%',
      justifyContent: 'center',
      gap : theme.metrics.spacing.s
    }, 
    iconStyle : {
      color: theme.colors.basics.black,
      fontSize : theme.metrics.iconSizes.xm
    },
    footerTextStyle : {
      flex : 1 
    },
    linkStyle : {
      fontWeight : '400',
      fontSize : theme.fonts.fontSize.paragraph.t3 
    },
    accountNumContainer: {
      flexDirection: "row",
      alignSelf: "center",
      borderRadius: theme.metrics.borderRadius.xs,
      paddingVertical: theme.metrics.spacing.xxs,
      paddingHorizontal: theme.metrics.spacing.xs,
      backgroundColor: theme.colors.basics.grey.c100

    }
  }), [theme, isDesktop]);

  return styles;
}
