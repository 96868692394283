import React from "react";
import { View } from "react-native";

import { LoaderPlaceholderView } from "@ere-uilib/atoms";
import { CircleLoader } from "@ere-uilib/atoms/loaders/Circle/Circle";
import { LoaderCard } from "@ere-uilib/molecules/Loaders/LoaderCard";

import { HistoryStyle } from "../interface";

interface HitoryPlaceHolderProps {
  styles: HistoryStyle;
  size?: number;
}

const HistoryPlaceHolder: React.FC<React.PropsWithChildren<HitoryPlaceHolderProps>> = ({
  styles,
  size = 3,
}) => {
  return (
    <>
      {Array.from({ length: size }, (_, k) => (
        <>
          <LoaderPlaceholderView style={styles.placeHolderMainTitle} />
          <LoaderCard containerStyle={styles.placeHolderCard}>
            <View style={styles.placeHolderCardTitleContainer}>
              <CircleLoader diameter={36} />
              <LoaderPlaceholderView style={styles.placeHolderCardTitle} />
            </View>
            <View style={styles.placeHolderCardContainer}>
              <LoaderPlaceholderView
                style={styles.placeHolderCardContentTitle}
              />
              <LoaderPlaceholderView
                style={styles.placeHolderCardContentTitle}
              />
              <LoaderPlaceholderView
                style={styles.placeHolderCardContentTitle}
              />
              <LoaderPlaceholderView
                style={styles.placeHolderCardContentTitle}
              />
            </View>
          </LoaderCard>
        </>
      ))}
    </>
  );
};

export default HistoryPlaceHolder;
