import React from 'react';
import { View } from 'react-native';

import { Title, Paragraph } from '@ere-uilib/atoms';
import { createUseStyles, useTheme, useScreenSizes } from '@ere-uilib/styles';
import { useTranslation } from '@translations/index';

import { SupportContentProps } from './interface';
import { getStyles } from './styles';

export const SupportContent = ({
  buttonContainerStyle,
  renderButton
}: SupportContentProps) => {
  const theme = useTheme();
  const { formatMessage } = useTranslation();
  const { isMobile } = useScreenSizes();
  const styles = useStyles(
    { theme, isMobile },
    {
      buttonContainerStyle
    }
  );
  return (
    <View style={styles.containerStyle} >
      <Title
        style={styles.titleStyle}
        variant={isMobile ? 't4' : 't3'}
        weight="bold"
      >
        {formatMessage({ id: 'support_title' })}
      </Title>
      <Paragraph
        style={styles.descriptionStyle}
        variant={isMobile ? 't2' : 't1'}
      >
        {formatMessage({ id: 'support_description' })}
      </Paragraph>
      {renderButton && renderButton()}
    </View>
  );
};

const useStyles = createUseStyles(getStyles);
