import React, { useCallback, useMemo } from 'react'
import { View } from 'react-native'
import { Text } from '@ere-uilib/atoms'
import { IconEnum } from '@ere-uilib/enums'
import { DashboardContentWrapperCardComponent } from '@ere-uilib/molecules'
import { ChevronLink } from '@ere-uilib/molecules/ChevronLink'
import { useTranslation } from '@ere-uilib/translations'
import { RemainingSavingsTimeCardProps, LinkItem, ExtraDataType } from './interface'
import { useStyles } from './useStyles'
import { RemainingSavingsTimeCardContent } from './components'
import { AvailabilitiesLoader } from '../Loaders'

export const RemainingSavingsTimeCard: React.FC<RemainingSavingsTimeCardProps> = ({
  availabilities,
  error,
  onGetAvailabilities,
  isSapiendoActive,
  isUnlockHelpActive,
  onSapiendoPress,
  onUnlockHelpPress,
  isLoading,
  isMobileOrHasOperations = true
}) => {
  const { formatMessage, getMessageRaw } = useTranslation()
  const styles = useStyles(isMobileOrHasOperations)

  const extraData: ExtraDataType[] = useMemo(
    () => [
      {
        icon: IconEnum.CLOCK,
        label: 'benefit-time_content1_label',
        amount: availabilities?.immediateAmount
      },
      {
        icon: IconEnum.CALENDAR_5,
        label: 'benefit-time_content2_label',
        amount: availabilities?.midTermAmount
      },
      {
        icon: IconEnum.RETIREMENT,
        label: 'benefit-time_content3_label',
        amount: availabilities?.longTermAmount,
        ...(isSapiendoActive
          ? {
              linkTitle: formatMessage({ id: 'benefit-time_content3_sub-label' }),
              onPressLink: onSapiendoPress
            }
          : {})
      },
      {
        icon: IconEnum.UNLOCK,
        label: 'Dashboard_CardDisponibility_Term4_label',
        ...(isUnlockHelpActive
          ? {
              linkTitle: formatMessage({ id: 'benefit-time_content4_sub-label' }),
              onPressLink: onUnlockHelpPress
            }
          : {}),
        until: formatMessage({
          id: 'Dashboard_CardDisponibility_Term4_upto_label'
        }),
        amount: availabilities?.earlyReleaseAmount
      }
    ],
    [
      availabilities,
      formatMessage,
      isSapiendoActive,
      isUnlockHelpActive,
      onSapiendoPress,
      onUnlockHelpPress
    ]
  )

  const dictionnnary = useMemo(
    () => ({
      helpPoint: {
        contentHtml: getMessageRaw({
          id: '​Dashboard_CardDisponibility_info_content',
          defaultMessage: ' '
        }),
        contentTitle: formatMessage({
          id: 'Dashboard_CardDisponibility_Title',
          defaultMessage: ' '
        }),
        modalTitle: formatMessage({ id: 'fund-card_help-info_menu' })
      },
      titile: formatMessage({ id: 'Dashboard_CardDisponibility_Title' })
    }),
    [getMessageRaw, formatMessage]
  )

  const renderLink = useCallback(
    (item: LinkItem) => {
      if (!item || !item.linkTitle) {
        return null
      }

      return (
        <ChevronLink
          onPress={item.onPressLink}
          style={[styles.rowStyle, styles.subtitleStyle]}
          title={item.linkTitle}
        />
      )
    },
    [styles]
  )

  const renderLoader = useCallback(() => <AvailabilitiesLoader />, [])

  return (
    <View style={styles.remaningSavingsStyle}>
      <DashboardContentWrapperCardComponent
        errorRetryAction={onGetAvailabilities}
        helpPoint={dictionnnary.helpPoint}
        isError={!!error?.message}
        isLoading={isLoading}
        renderLoader={renderLoader}
        testId="Dashboard_CardDisponibility_Title_testId"
        title={dictionnnary.titile}
        renderFooter={() => (
          <Text
            style={styles.legend}
            variant="t4"
            weight="light">
            Montants bruts
          </Text>
        )}>
        <RemainingSavingsTimeCardContent
          extraData={extraData}
          isMobileOrHasOperations={isMobileOrHasOperations}
          renderChevronLink={renderLink}
        />
      </DashboardContentWrapperCardComponent>
    </View>
  )
}
