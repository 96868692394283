import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { NativeSyntheticEvent, TextInputFocusEventData, View } from 'react-native';

import { FontIcon, Text } from '@ere-uilib/atoms';
import { IconEnum } from '@ere-uilib/enums';
import { useTheme } from '@ere-uilib/styles';

import { TextField } from '../TextField';
import { useStyle } from './useStyle';
dayjs.extend(utc);

interface Ref {
  focus: () => void;
  select: () => void;
}

export interface DateFieldDictionaryType {
  placeholderDay: string;
  placeholderMonth: string;
  placeholderYear: string;
  dateErrorMessage: string;
}

interface Props {
  onChange: (value?: Date) => void;
  maxDate?: Date;
  dictionary: DateFieldDictionaryType;
  value?: Date;
  onSubmit?: (nativeEvent: any) => void;
  hasError?: boolean;
}

function formatDateDigit(value: string, digitNumber: number) {
  const valueLength = value.length;

  if (valueLength === digitNumber) {
    return value;
  }

  let prefix = '';

  for (let i = 0; i < digitNumber - valueLength; i++) {
    prefix = prefix + '0';
  }

  return prefix + value;
}

export const DateField = ({
  onChange,
  maxDate,
  dictionary,
  value,
  onSubmit,
  hasError = false
}: Props) => {
  const defaultDay = value?.getDate().toString();
  const defaultMonth = value && (value.getMonth() + 1).toString();
  const defaultYear = value?.getUTCFullYear().toString();
  const monthRef = useRef<Ref>();
  const yearRef = useRef<Ref>();
  const [isDateValid, setDateValid] = useState<boolean>();
  const [day, setDay] = useState(defaultDay ? formatDateDigit(defaultDay, 2) : '');
  const [month, setMonth] = useState(defaultMonth ? formatDateDigit(defaultMonth, 2) : '');
  const [year, setYear] = useState(defaultYear || '');
  const styles = useStyle();
  const theme = useTheme();

  const checkDate = useCallback(({ day, month, year }: { [p: string]: string }) => {
    if (!(day && month && year)) {
      onChange(undefined);
      return;
    }
    const intYear = parseInt(year);
    const intMonth = parseInt(month);
    const intDay = parseInt(day);
    const date = dayjs.utc(`${intYear}-${intMonth}-${intDay}`, "YYYY-MM-DD");
    let dateValid = date.isValid();
    let newDate;

    if (dateValid) {
      newDate = date.toDate();

      if (maxDate && newDate > maxDate) {
        dateValid = false;
        newDate = undefined;
      }
    }

    setDateValid(dateValid);
    onChange(newDate);
  },
    [onChange, maxDate, setDateValid]
  );

  useEffect(() => {
    checkDate({ day, month, year });
  }, [day, month, year]);

  const handleDayChange = useCallback((value: string) => {
    if (!value) setDateValid(false);
    setDay(value);
    if (value.length === 2) {
      monthRef?.current?.focus?.();

      if (month.length > 0) {
        monthRef?.current?.select?.();
      }
    }
  }, [monthRef, setDateValid, setDay]);

  const handleMonthChange = useCallback((value: string) => {
    if (!value) setDateValid(false);
    setMonth(value);
    if (value.length === 2) {
      yearRef?.current?.focus?.();

      if (year.length > 0) {
        yearRef?.current?.select?.();
      }
    }
  }, [yearRef, setDateValid, setMonth]);

  const handleYearChange = useCallback((value: string) => {
    if (!value) setDateValid(false);
    setYear(value);
  }, [setYear, setDateValid]);

  const handleDayBlur = useCallback((e: NativeSyntheticEvent<TextInputFocusEventData>) => {
    if (!parseInt(e.nativeEvent.text)) {
      return;
    }

    setDay(formatDateDigit(e.nativeEvent.text, 2));
  }, []);

  const handleMonthBlur = useCallback((e: NativeSyntheticEvent<TextInputFocusEventData>) => {
    if (!parseInt(e.nativeEvent.text)) {
      return;
    }

    setMonth(formatDateDigit(e.nativeEvent.text, 2));
  }, []);

  return (
    <View>
      <View style={styles.container}>
        <TextField
          borderOnValue
          errored={isDateValid === false}
          inputProps={{
            value: day,
            placeholder: dictionary.placeholderDay,
            onChangeText: handleDayChange,
            maxLength: 2,
            keyboardType: 'numeric',
          }}
          inputStyle={styles.day}
          onBlur={handleDayBlur}
          onSubmit={onSubmit}
          regexPattern={/^[0-9,;.+-]{1,2}$/}
          style={styles.dayWrapper}
          testId='day_input'
        />
        <TextField
          borderOnValue
          errored={isDateValid === false}
          innerRef={monthRef}
          inputProps={{
            value: month,
            placeholder: dictionary.placeholderMonth,
            onChangeText: handleMonthChange,
            maxLength: 2,
            keyboardType: 'numeric',
          }}
          inputStyle={styles.month}
          onBlur={handleMonthBlur}
          onSubmit={onSubmit}
          regexPattern={/^[0-9,;.+-]{1,2}$/}
          style={styles.monthWrapper}
          testId='month_input'
        />
        <TextField
          borderOnValue
          errored={isDateValid === false}
          innerRef={yearRef}
          inputProps={{
            value: year,
            placeholder: dictionary.placeholderYear,
            onChangeText: handleYearChange,
            maxLength: 4,
            keyboardType: 'numeric',
          }}
          inputStyle={styles.year}
          onSubmit={onSubmit}
          regexPattern={/^[0-9,;.+-]{1,4}$/}
          style={styles.yearWrapper}
          testId='year_input'
        />
      </View>
      {dictionary.dateErrorMessage && (isDateValid === false || hasError) && (
        <View style={styles.error}>
          <FontIcon
            color={theme.colors.operationState.failed.c500}
            name={IconEnum.WARNING}
            size={theme.metrics.iconSizes.m}
          />
          <Text style={styles.errorMessage}>{dictionary.dateErrorMessage}</Text>
        </View>
      )}
    </View>
  )
};
