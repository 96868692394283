import React from 'react';

import { Text, Title } from '@ere-uilib/atoms';
import { useTranslation } from '@ere-uilib/index';
import { InputMode } from '@modules/common/constants';

const label = {
  title: {
    default: 'PostalAdressPageTitle',
    [InputMode.TSA]: 'PostalAdressPageTitle_TSA_SRH_CORRESPONDANT',
    [InputMode.TSA_OR_UPLOAD]: 'PostalAdressPageTitle_TSA_SRH_CORRESPONDANT',
    [InputMode.SERVICE_RH]: 'PostalAdressPageTitle_TSA_SRH_CORRESPONDANT',
    [InputMode.CORRESPONDANT]: 'PostalAdressPageTitle_TSA_SRH_CORRESPONDANT'
  },
  subtitle: {
    [InputMode.DATA]: 'PostalAdressPageMandatoryInputs',
    [InputMode.DATA_AND_UPLOAD]: 'PostalAdressPageMandatoryInputs',
  }
}

export const FormTitle: React.FC<{ inputMode: InputMode }> = ({ inputMode }) => {
  const { formatMessage } = useTranslation();

  return (
    <>
      <Title
        variant={'t4'}
        weight={'bold'}>
        {formatMessage({ id: label.title[inputMode] || label.title.default })}
      </Title>

      {!!label.subtitle[inputMode] && (
        <Text
          variant={'t4'}>
          {formatMessage({ id: label.subtitle[inputMode] })}
        </Text>
      )}
    </>
  );
};
