import { Platform } from 'react-native';

import { OtpValidationSuccessPageContext, OtpValidationSuccessPageStyle } from './interfaces';

export const getStyles = (
  context?: OtpValidationSuccessPageContext,
  style?: OtpValidationSuccessPageStyle
): OtpValidationSuccessPageStyle => ({
  containerStyle: [
    {
      flex: 1,
      alignItems: 'center',
      backgroundColor: context?.theme.colors.background.poppinFilter.c500
    },
    context?.isMobile
      ? {
          justifyContent: 'flex-end'
        }
      : {
          justifyContent: 'center'
        },
    style?.containerStyle
  ],
  innerContainerStyle: [
    {
      justifyContent: 'center',
      alignItems: 'center'
    },
    style?.innerContainerStyle
  ],
  imageContainerStyle: [
    {
      minWidth: 280,
      ...(Platform.OS === 'web' && { minHeight: 140 })
    },
    style?.imageContainerStyle
  ],

  titleStyle: [
    {
      ...(Platform.OS === 'web' && { marginTop: context?.theme.metrics.spacing.l })
    },
    style?.titleStyle
  ],
  simpleButtonStyle: [
    {
      marginTop: context?.theme.metrics.spacing.l
    },
    style?.titleStyle
  ]
});
