import { AddressDetail } from "@modules/common/types";
import { joinDefinedElements } from "@utils/joinDefinedElements";

export const generateAddress = (address:  AddressDetail | null | undefined): string => {
    if (!address)
        return '';

    const  { zipcode, country, city, recipient, street, additionalAddress_1, additionalAddress_2, additionalAddress_3 } = address;
    const town = [zipcode, city];
    const addressLines = [recipient, street, additionalAddress_1, additionalAddress_2, additionalAddress_3, joinDefinedElements(town, ' '), country];
    return joinDefinedElements(addressLines, '\n');
}
