import React from 'react';
import { View, StyleProp, TextStyle, ViewStyle } from 'react-native';

import { ProgressBar, Title } from '@ere-uilib/atoms';
import { createUseStyles, ThemeType, useTheme } from '@ere-uilib/styles';

interface Props {
  progress: number;
  title: string | undefined;
  containerStyle?: StyleProp<ViewStyle>;
  titleStyle?: StyleProp<TextStyle>;
}

type Styles = {
  containerStyle?: StyleProp<ViewStyle>;
  titleStyle?: StyleProp<TextStyle>;
};

type StylesContext = {theme: ThemeType};

export const WalkthroughProgressHeader: React.FC<React.PropsWithChildren<Props>> = ({
  progress,
  title,
  containerStyle,
  titleStyle
}) => {
  const theme = useTheme();
  const styles = useStyles({ theme }, { containerStyle, titleStyle });
  return (
    <View style={[styles.containerStyle]}>
      <Title
style={styles.titleStyle}
variant="t7">{title}</Title>
      <ProgressBar progress={progress} />
    </View>
  );
};

const getStyles = (context?: StylesContext, style?: Styles): Styles => ({
  containerStyle: [
    {
      width: '100%'
    },
    style?.containerStyle
  ],
  titleStyle: [
    {
      textAlign: 'center',
      marginTop: context?.theme.metrics.spacing.s
    },
    style?.titleStyle
  ]
});

const useStyles = createUseStyles(getStyles);
