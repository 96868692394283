import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useRoute } from "@react-navigation/core";

import {
  HtmlStyledRenderConnectedContainer,
  NotificationIconTypeEnum,
  Title,
  LegalMenuFooterConnected,
  BackButton,
  NavigationHeader,
  NotificationHard,
  SimpleButton,
  LogoHeader,
  OfflinePageLayout,
  RadiosButtonList,
  Spinner
} from '@components/index';
import { RouteNames } from '@constants/navigation';
import { useScreenSizes } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';
import { CampaignData, CampaignValueState } from '@modules/campaign/types';
import { AppNavigatorInterface, AppNavigatorRouteNames } from '@navigation/Interfaces';

import { useStyles } from './useStyles';

export type CompaignUserType = {
  campaignId: string,
      value: CampaignValueState
  toDoPostSuccessActionType?: string,
}

export interface Props {
  isSubmitLoading: boolean
  isGetUserCampaignLoading: boolean
  campaignData?: CampaignData
  isFormSuccessfullySubmitted: boolean
  isErrorSubmit: boolean
  getUserCampaign(campaignId: string): void
  submitUserCampaign({
    campaignId,
    value,
    toDoPostSuccessActionType,
  }:CompaignUserType): void
  executeCallback(callbackType: string): void
}

export const ConsentTransferCreditComponent: React.FC<Props> = ({
  campaignData,
  isErrorSubmit,
  isSubmitLoading,
  isGetUserCampaignLoading,
  isFormSuccessfullySubmitted,
  submitUserCampaign,
  executeCallback,
  getUserCampaign
}) => {

  const route = useRoute();
  const {
    toDoPostSuccessActionType,
    campaignId,
    isPostConnexionMode
  } = route.params as AppNavigatorInterface[AppNavigatorRouteNames.ConsentTransferCredit] || {}

  const styles = useStyles();
  const { formatMessage } = useTranslation();
  const { isDesktop } = useScreenSizes();
  const [activeRadio, setActiveRadio] = useState<CampaignValueState|undefined>()
  const navigation = useNavigation();

  const topHTMLText = campaignData?.bodyHaut
  const bottomHTMLText = campaignData?.bodyBas
  const helpPointTopText = campaignData?.helpHaut
  const submitLaterButtonTitle = formatMessage({ id: 'ConsentLaterValidateButton' })
  const bottomTitle = useMemo(() => formatMessage({ id: 'ConsentTransferTitle' }), [formatMessage])
  const bottomRadioButtonsLabel = campaignData?.openQuestion

  const radiosOptions = [
    {
      value: CampaignValueState.YES,
      label: formatMessage({ id: 'ConsentYesRadioButton' }),
      disabled: isFormSuccessfullySubmitted
    },
    {
      value: CampaignValueState.NO,
      label: formatMessage({ id: 'ConsentNoRadioButton' }),
      disabled: isFormSuccessfullySubmitted
    },
  ]

  useEffect(() => {
    getUserCampaign(campaignId)
  }, [campaignId, getUserCampaign])

  useEffect(() => {
    const isCampaignValueValid = campaignData?.responseValue
    && [CampaignValueState.YES, CampaignValueState.NO].includes(campaignData.responseValue);

    if(isCampaignValueValid) {
      setActiveRadio(campaignData?.responseValue)
    }else{
      setActiveRadio(undefined)
    }
  }, [campaignData?.responseValue])

  const handleLeavePage = useCallback(()=>{
    if(isPostConnexionMode) {
      navigation.goBack()
    }else{
      navigation.navigate(RouteNames.BottomTabNavigator, {
        screen: RouteNames.Home
      })
    }
  }, [isPostConnexionMode, navigation])

  const handleGoBack = useCallback(() => {
    handleLeavePage()
    if (toDoPostSuccessActionType) {
      executeCallback(toDoPostSuccessActionType)
    }
  }, [handleLeavePage, toDoPostSuccessActionType, executeCallback])

  const consentSubmit = useCallback(() => {
    return submitUserCampaign({
      campaignId,
      value: activeRadio as CampaignValueState
    })
  }, [
    campaignId,
    activeRadio,
    submitUserCampaign
  ]);

  const consentContinue = useCallback(() => {
    handleLeavePage()
    if (toDoPostSuccessActionType) {
      executeCallback(toDoPostSuccessActionType)
    }
  }, [
    toDoPostSuccessActionType,
    handleLeavePage,
    executeCallback
  ])

  const consentContinueLater = useCallback(() => {
    submitUserCampaign({
      campaignId,
      toDoPostSuccessActionType,
      value: CampaignValueState.LATER
    })
    handleLeavePage()
  }, [
    toDoPostSuccessActionType,
    campaignId,
    submitUserCampaign,
    handleLeavePage
  ])

  const renderSubmitButton = () => !isGetUserCampaignLoading?(
    <View style={styles.buttonsSubmitContainer}>
      {!isFormSuccessfullySubmitted && (
        <SimpleButton
          containerStyle={styles.buttonSubmitLater}
          design="outlined"
          disabled={isSubmitLoading}
          onPress={consentContinueLater}
          title={submitLaterButtonTitle}
        />
      )}
      <SimpleButton
        containerStyle={styles.buttonSubmit}
        disabled={(!activeRadio) || isSubmitLoading}
        loading={isSubmitLoading}
        onPress={isFormSuccessfullySubmitted ? consentContinue : consentSubmit}
        title={formatMessage({
          id: isFormSuccessfullySubmitted ? 'ConsentConfirmationYesButton' : 'ConsentValidateButton'
        })}
      />
    </View>
  ):null;

  const renderMobileActions = () => (
    <View style={styles.mobileFooterContainer}>
      {renderSubmitButton()}
      <LegalMenuFooterConnected />
    </View>
  )

  const renderHelpPointContent = () => (
    <>
      {!!helpPointTopText && <HtmlStyledRenderConnectedContainer html={helpPointTopText} />}
    </>
  )
  const renderMobileHeaderNavigation = () => (
    <NavigationHeader
      displayCloseButton={!isPostConnexionMode}
      helpPoint
      helpPointModalTitle={formatMessage({ id: 'ConsentHelpTitle' })}
      helpPointPosition='left'
      helpPointRenderModalContent={renderHelpPointContent}
      onClose={handleGoBack}
      title={formatMessage({ id: 'ConsentTitle' })}
    />
  )

  const renderNotificationHard = () => {
    const notificationText =
    isFormSuccessfullySubmitted && activeRadio === CampaignValueState.YES
      ? campaignData?.yesTakenIntoAccountMessage
      : isFormSuccessfullySubmitted && activeRadio === CampaignValueState.NO
        ? campaignData?.notTakenIntoAccountMessage
        : formatMessage({ id: 'ConsentErrorLabel' })

    return (isFormSuccessfullySubmitted || isErrorSubmit) ? (
      <NotificationHard
        containerStyle={styles.notificationText}
        text={isErrorSubmit ? notificationText : undefined}
        type={isFormSuccessfullySubmitted ?
          NotificationIconTypeEnum.SUCCESS
          : NotificationIconTypeEnum.WARNING}
      >{(notificationText && (!isErrorSubmit && isFormSuccessfullySubmitted)) && (
          <HtmlStyledRenderConnectedContainer html={notificationText}/>
        )}</NotificationHard>
    ) : <></>
  }
  return (
    <OfflinePageLayout
      displayLogo={isDesktop}
      renderDesktopCardBelowContent={() => <LegalMenuFooterConnected isWhite/>}
      renderMobileHeaderContent={renderMobileHeaderNavigation}
      renderMobileStickyGradientFooterContent={renderMobileActions}>
      <LogoHeader
        helpPointProps={{
          renderModalContent: renderHelpPointContent,
          modalTitle: formatMessage({ id: 'ConsentHelpTitle' }),
        }}
      />
      <View style={styles.container}>
        {!isPostConnexionMode && isDesktop && (
          <BackButton
            onPress={handleGoBack}
            style={styles.backButtonContainer}
            title={formatMessage({ id: 'EerSelfcareStep1RetourLabel' })}
          />
        )}
        {isGetUserCampaignLoading?(
          <Spinner/>
        ):(
          <>
            {!!topHTMLText && <HtmlStyledRenderConnectedContainer html={topHTMLText} />}
            <View style={styles.bottomContainer}>
              <Title
                style={styles.bottomContainerTitle}
                variant="t4"
                weight="bold"
              >{bottomTitle}</Title>
              {bottomRadioButtonsLabel &&
              <HtmlStyledRenderConnectedContainer
                html={bottomRadioButtonsLabel}
              />
              }
              <RadiosButtonList
                activeRadio={activeRadio}
                containerStyle={styles.bottomRadiosButtonsContainer}
                flexDirection="row"
                onChange={radio => setActiveRadio(radio as CampaignValueState)}
                options={radiosOptions}
              />
              {renderNotificationHard()}
              {!isFormSuccessfullySubmitted && !!bottomHTMLText && <HtmlStyledRenderConnectedContainer html={bottomHTMLText} />}
              {isDesktop && (
                renderSubmitButton()
              )}
            </View>
          </>
        )}
      </View>
    </OfflinePageLayout>
  );
};
