import { connect } from 'react-redux';

import {
  getAllSupportsToCompare,
  getInvestedProjectPlans,
  getInvestedRetirementPlans,
  getProfitSharingIncentiveAssigmentAmounts,
  getProfitSharingIncentiveInitDataState,
  getProfitSharingIncentiveRepartitionState,
} from '@modules/profit-sharing-incentive/selectors';
import {
  ProfitSharingIncentiveActionsType
} from '@modules/profit-sharing-incentive/actions/profitSharingIncentiveActionsTypes';
import {
  setProfitSharingIncentiveRepartitionAmountRequest
} from '@modules/profit-sharing-incentive/actions/profitSharingIncentiveActions';
import { AppState } from '@modules/reducers';

import { ProfitSharingIncentiveRepartition } from './ProfitSharingIncentiveRepartition.component';

const mapStateToProps = (state: AppState) => ({
  initData: getProfitSharingIncentiveInitDataState(state),
  repartition: getProfitSharingIncentiveRepartitionState(state),
  assigmentAmounts: getProfitSharingIncentiveAssigmentAmounts(state),
  investedProjectPlans: getInvestedProjectPlans(state),
  investedRetirementPlans: getInvestedRetirementPlans(state),
  allSupportsToCompare: getAllSupportsToCompare(state),
  isContributionError: !!state.error[ProfitSharingIncentiveActionsType.GET_PARTICIPATION_PROFITSHARING_CALCULATED_CONTRIBUTION_REQUEST]?.message,
  isContributionLoading: state.isLoading[ProfitSharingIncentiveActionsType.GET_PARTICIPATION_PROFITSHARING_CALCULATED_CONTRIBUTION_REQUEST]
  || state.isLoading[ProfitSharingIncentiveActionsType.SET_PARTICIPATION_PROFITSHARING_REPARTITION_AMOUNT_REQUEST]
});

const mapDispatchToProps = ({
  setProfitSharingIncentiveRepartitionAmount: setProfitSharingIncentiveRepartitionAmountRequest,
});

export const ProfitSharingIncentiveRepartitionPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfitSharingIncentiveRepartition);
