import { connect } from 'react-redux';

import {
  setArbitrationDueDateAmountModalVisibleState,
  selectArbitrationRepartitionSupport
} from '@modules/arbitration/actions/arbitrationActions';
import { getArbitrationDueDateAmountModalVisibleState } from '@modules/arbitration/selectors';
import { AppState } from '@modules/index';
import { ArbitrationDueDateAmountFieldModalConnectedComponent } from './ArbitrationDueDateAmountFieldModalConnected.component';


const mapStateToProps = (state: AppState) => {
  return {
    visible: getArbitrationDueDateAmountModalVisibleState(state),
  };
};

const mapDispatchToProps = {
  onSetArbitrationDueDateAmountModalVisibleState: setArbitrationDueDateAmountModalVisibleState,
  onSetArbitrationDueDateAmount: selectArbitrationRepartitionSupport,
};

export const ArbitrationDueDateAmountFieldModalConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(ArbitrationDueDateAmountFieldModalConnectedComponent);
