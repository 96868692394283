import React, { useCallback, useRef } from 'react'
import { TouchableOpacity, View } from 'react-native'

import { DashboardPageWrapperConnected } from '@components/ERE360Components'
import {
  ColumnsContainer,
  DocumentCard,
  Link,
  NotificationHard,
  NotificationIconTypeEnum,
  Text
} from '@ere-uilib/index'

import { DocumentFilterByYear, DocumentSkeleton, DocumentSpaceHeader } from '../components'
import { useController } from './useController'
import { useLabels } from './useLabels'
import { useStyles } from './useStyles'

export const EDocumentsFiles = () => {
  const refDashboardContainer = useRef() as React.MutableRefObject<{ scrollToTop: () => void }>
  const styles = useStyles()
  const labels = useLabels()

  const {
    documents,
    currentYear,
    years,
    onFilterDocuments,
    downloadDocument,
    goToSubscription,
    isLoadingDocuments,
    isDownloadingDocument,
    isSubscriber,
    hasNeverBeenASubscriber,
    hasError
  } = useController()

  const renderHeader = useCallback(
    () => (
      <DocumentSpaceHeader
        displayCloseButton
        helpPoint={!!documents.length}
        helpPointContentHtml={labels.helpContent}
        helpPointModalTitle={labels.helpTitle}
        helpPointPosition='left'
        title={labels.headerTitle}
      />
    ),
    [documents, labels]
  )

  const renderDocuments = useCallback(() => {
    if (!documents.length)
      return (
        <Text
          style={styles.empty}
          variant='t3'
          weight='light'>
          {labels.empty}
        </Text>
      )

    return documents.map(document => (
      <TouchableOpacity
        disabled={isDownloadingDocument(document.id)}
        key={`edocument-button-${document.id}`}
        onPress={() => downloadDocument(document.id, document.typeLabel)}>
        <DocumentCard
          description={document.keyLabel}
          documentLoading={isDownloadingDocument(document.id)}
          header={labels.getDocumentCreationDate(document.creationDate)}
          key={`edocument-card-${document.id}`}
          title={labels.getDocumentTitle(document.typeLabel)}
        />
      </TouchableOpacity>
    ))
  }, [documents, downloadDocument, isDownloadingDocument, labels, styles])

  const renderFilter = useCallback(
    (currentYear: number | undefined) => (
      <DocumentFilterByYear
        containerStyle={styles.filterContainer}
        currentYear={currentYear}
        onChange={onFilterDocuments}
        years={years}
      />
    ),
    [onFilterDocuments, styles, years]
  )

  const renderNotification = useCallback(
    () =>
      !isSubscriber && (
        <NotificationHard
          containerStyle={styles.notificationContainer}
          text={labels.notification(hasNeverBeenASubscriber).description}
          title={labels.notification().title}
          type={NotificationIconTypeEnum.INFO}>
          <Link
            onPress={goToSubscription}
            textStyle={styles.notificationLink}
            textVariant='t3'
            textWeight='regular'>
            {labels.notification(hasNeverBeenASubscriber).link}
          </Link>
        </NotificationHard>
      ),
    [goToSubscription, hasNeverBeenASubscriber, isSubscriber, labels, styles]
  )

  const renderError = useCallback(
    () => (
      <NotificationHard
        containerStyle={styles.notificationContainer}
        text={labels.error}
        type={NotificationIconTypeEnum.INFO}
      />
    ),
    [labels, styles]
  )

  const renderLoader = useCallback(
    () => (
      <>
        <View style={styles.filterContainer} />
        {[...Array(3)].map((_, index) => (
          <DocumentSkeleton key={'skeleton-' + index} />
        ))}
      </>
    ),
    [styles]
  )

  return (
    <DashboardPageWrapperConnected
      cardContentStyle={styles.wrapper}
      dashboardPageWrapperRef={refDashboardContainer}>
      <ColumnsContainer
        hasColumnCenter
        hasRenderRightDesktopColumn={false}
        leftColumnStyle={styles.container}
        renderHeader={renderHeader}>
        {isLoadingDocuments && renderLoader()}
        {!isLoadingDocuments && hasError && renderError()}
        {!isLoadingDocuments && !hasError && (
          <>
            {renderNotification()}
            {renderFilter(currentYear)}
            {renderDocuments()}
          </>
        )}
      </ColumnsContainer>
    </DashboardPageWrapperConnected>
  )
}
