import React from 'react';

import { Link, NotificationIconTypeEnum } from '@ere-uilib/atoms';
import { NotificationHard } from '@ere-uilib/molecules';

import { useController } from './useController';
import { useLabel } from './useLabel';
import { useStyles } from './useStyles';

export const ContributionNotification: React.FC = () => {
  const styles = useStyles();
  const label = useLabel();
  const {
    availableContributionAmount,
    suggestedContributionAmount,
    isContributionTargetingOK,
    goToContribution,
    getIconName
  } = useController();

  if (availableContributionAmount === null) return null;

  return (
    <NotificationHard
      containerStyle={styles.container}
      customIconName={getIconName(suggestedContributionAmount)}
      text={label.getParagraph(availableContributionAmount, suggestedContributionAmount)}
      type={NotificationIconTypeEnum.INFO}>
      {isContributionTargetingOK && (
        <Link
          onPress={goToContribution}
          textStyle={styles.link}
          textVariant="t3"
          textWeight="regular">
          {label.link}
        </Link>
      )}
    </NotificationHard>
  );
};
