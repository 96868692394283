import React, { useEffect, useCallback } from 'react';
import { View } from 'react-native';

import {
  TextField,
  useAmountFieldParseManager,
  useTranslation,
  Text,
  Title,
  Modal,
  ModalFrame,
  SimpleButton,
  NotificationIcon,
  NotificationIconTypeEnum,
  IconEnum,
} from '@components/index'

import { FiscalityPercentModalProps } from './interface'
import { useStyles } from './useStyles'

export const FiscalityPercentModal: React.FC<React.PropsWithChildren<FiscalityPercentModalProps>> = ({
  isVisible,
  onSubmit,
  onClosePress,
  initAmount
}) => {
  const styles = useStyles()
  const { formatMessage } = useTranslation()
  
  const {
    amountNumber,
    amountString,
    setAmountString,
    parseAmountNumberToString,
    parseAmountStringToNumber
  } = useAmountFieldParseManager({
    initialAmount: initAmount,
    maxDecimal: 2,
  });

  const handleTextChange = (text:string) => {
    let amountText = text 
    const amountTextAsNumber = parseAmountStringToNumber(text)
    if(amountTextAsNumber && amountTextAsNumber > 100) {
      amountText = "100"
    }
    setAmountString(amountText)
  }
  
  const handleValidation = useCallback(()=>{
    if(!amountNumber) return
    onSubmit?.(amountNumber)
    onClosePress()
  }, [onClosePress, onSubmit, amountNumber])

  const handleOnClearField = useCallback(()=>{
    setAmountString('')
  }, [setAmountString])
  
  useEffect(()=>{
    if(isVisible) {
      const initAmountString = parseAmountNumberToString(initAmount)
      setAmountString(initAmountString)
    }
  }, [isVisible])

  
  return (
    <Modal visible={isVisible}>
      <ModalFrame
        desktopPosition="center"
        mobilePosition="bottom"
        onClose={onClosePress}
        moveUpContentWithKeyboard
      >
        <View style={styles.modalContainer} >
          <NotificationIcon
            customIconName={IconEnum.FISCALITE}
            type={NotificationIconTypeEnum.SUCCESS}
          />
          <Title
            style={styles.titleStyle}
            variant="t6"
          >
            {formatMessage({ id: 'PISaisieTauxImpositionTauxTitre' })}
          </Title>
          <Text
            style={styles.boxTextStyle}
            variant="t3"
            weight="light"
          >
            {formatMessage({ id: 'PISaisieTauxImpositionSaisirTxImpositionDescription' })}
          </Text>
          <TextField
            containerStyle={styles.fieldInputContainer}
            inputProps={{
              value: amountString || '',
              placeholder: formatMessage({ id: 'PISaisieTauxImpositionChampLabel' }),
              onChangeText: handleTextChange,
            }}
            inputStyle={styles.fieldInput}
            labelOnTop
            onCleanField={handleOnClearField}
          />
          <View style={styles.buttonsContainerStyle}>
            <SimpleButton
              containerStyle={styles.buttonStyle}
              design={'outlined'}
              onPress={onClosePress}
              title={formatMessage({ id: 'PISaisieTauxImpositionTauxAnnulerBouton' })}
            />
            <SimpleButton
              containerStyle={[styles.buttonStyle, styles.lastButtonStyle]}
              disabled={!amountNumber}
              onPress={handleValidation}
              title={formatMessage({ id: 'PISaisieTauxImpositionTauxValiderBouton' })}
            />
          </View>
        </View>
      </ModalFrame>
    </Modal>
  );
};
