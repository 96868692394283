import { connect } from 'react-redux';

import {
  checkEERIdentityValidationCodeRequest,
  sendEERIdentityValidationCodeRequest
} from '@modules/validate-identity/actions/validateIdentityActions';
import { ValidateIdentityActionsType } from '@modules/validate-identity/actions/validateIdentityActionsTypes';
import {
  AppState,
} from '@modules/index';
import { getInvitationSelector } from '@modules/invitation/selectors';
import { getIdentityValidationModuleError } from '@modules/validate-identity/selectors';

import { ValidationSignupEmailCode } from './ValidationSignupEmailCode.component';

const mapStateToProps = (state: AppState) => {
  return {
    invitation: getInvitationSelector(state),
    validateIdentityError: getIdentityValidationModuleError(state),
    isCheckingCodeLoading: state.isLoading[ValidateIdentityActionsType.CHECK_EER_IDENTITY_VALIDATION_CODE_REQUEST],
    isSendingCodeLoading: state.isLoading[ValidateIdentityActionsType.SEND_EER_IDENTITY_VALIDATION_CODE_REQUEST],
  };
};

const mapDispatchToProps = ({
  checkEERIdentityValidationCode: checkEERIdentityValidationCodeRequest,
  sendEERIdentityValidationCode: sendEERIdentityValidationCodeRequest,
});
export const ValidationSignupEmailCodePage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ValidationSignupEmailCode);
