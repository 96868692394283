import { CompareFundsChartPartStylesContext, CompareFundsChartPartStyles } from './interface';

export const getStyles = (
  context?: CompareFundsChartPartStylesContext,
  style?: CompareFundsChartPartStyles
): CompareFundsChartPartStyles => ({
  containerStyle: [
    {},
    style?.containerStyle,
  ],
  periodSwitchStyle: [
    {
      marginTop: context?.theme.metrics.spacing.xm,
    },
    style?.periodSwitchStyle,
  ],
  periodSelectorStyle: [
    {
      marginTop: context?.theme.metrics.spacing.xm,
    },
    style?.periodSelectorStyle,
  ],
  valuattionStyle: [
    {
      marginTop: context?.theme.metrics.spacing.xm,
    },
    style?.valuattionStyle,
  ],
  chartStyle: [
    {
      marginBottom: context?.theme.metrics.spacing.s,
    },
    style?.chartStyle,
  ],
  checkboxLegendStyle: [
    {
      marginBottom: context?.theme.metrics.spacing.s,
    },
    style?.checkboxLegendStyle,
  ],
  noDataLabelStyle: [
    {
      padding: context?.theme.metrics.spacing.xm,
    },
    style?.noDataLabelStyle,
  ],
});

