import React, { useCallback } from 'react';
import { View } from 'react-native';

import {
  FontIcon,
  Text,
  Spinner,
} from '@ere-uilib/atoms';
import { SquareIcon } from '@ere-uilib/atoms/images/SquareIcon/SquareIcon';
import { IconEnum } from '@ere-uilib/enums';
import { ModalFrame, SimpleButton, NotificationSoft } from '@ere-uilib/molecules';
import { useTheme } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';

import { OperationTypeModalProps } from './interface';
import { useStyles } from './useStyles';

export const OperationTypeModal: React.FC<React.PropsWithChildren<OperationTypeModalProps>> = ({
  onClose,
  isLoadingOperationModalList,
  informationRow,
  separator,
  textContainer,
  iconContainer,
  onModalButtonPress,
  data,
  isModalValidContent
}) => {
  const { formatMessage } = useTranslation();
  const theme = useTheme();
  const styles = useStyles()

  const renderValidationButton = useCallback((
  ) => {
    if (!data) return null;

    return (
      <SimpleButton
        testId={data.testId}
        containerStyle={styles.buttonContainerStyle}
        onPress={() => onModalButtonPress?.()}
      >
        <Text
          style={styles.validationButtonTitle}>
          {data.buttonTitle}
        </Text>
      </SimpleButton>
    );

  }, [data]);

  const renderSofNotification = useCallback(() => {
    if (isModalValidContent) return null;

    return <NotificationSoft
      text={formatMessage({ id: 'legacyredirect_error_labelsss' })?.toString() || ''}
      type="warning"
    />
  }, [
    formatMessage,
    isModalValidContent,
  ]);

  const renderValidContent = useCallback(() => {
    if (!isModalValidContent || isLoadingOperationModalList) return null;

    return (
      <View>
        <View
          style={styles.headerContainer}
        >
          <SquareIcon
            backgroundColor={theme.colors.basics.primary.c200}
            iconColor={theme.colors.basics.primary.c500}
            iconName={IconEnum.INSTALLMENT}
            iconSize={theme.metrics.iconSizes.m}
            style={{ container: [styles.iconContainer, iconContainer] }}
          />
          <Text
            style={styles.title}
          >
            {data?.title}
          </Text>
        </View>
        {renderValidationButton()}
        <View style={[styles.informationRow, informationRow]}>
          <FontIcon
            color-={theme.colors.basics.grey}
            name="info"
            size={theme.metrics.spacing.xm}
          />
          <View style={[styles.separator, separator]} />
          <View style={[styles.textContainer, textContainer]}>
            <Text
              style={styles.infoLabelTitle}
            >{formatMessage({ id: 'legacyredirect_info_title' })}</Text>
            <Text
              style={styles.infoLabelStyle}
            >{formatMessage({ id: 'legacyredirect_info_label' })}</Text>
          </View>
        </View>
      </View>
    )
  }, [
    isModalValidContent,
    isLoadingOperationModalList,
    data,
    renderValidationButton,
    formatMessage
  ])

  const renderSpinner = useCallback(() => {
    if (!isLoadingOperationModalList) return;

    return <Spinner color={theme.colors.basics.primary.c500} />;
  }, [isLoadingOperationModalList]);

  const renderModalContent = useCallback(() => {
    return (
      <React.Fragment>
        {renderSpinner()}
        {renderValidContent()}
        {renderSofNotification()}
      </React.Fragment>
    )
  }, [
    renderSpinner,
    renderValidContent,
    renderSofNotification
  ]);

  return (
    <ModalFrame
      mobilePosition="bottom"
      onClose={onClose}
    >
      {renderModalContent()}
    </ModalFrame>
  );
};
