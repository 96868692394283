import React from 'react';
import { View, StyleProp, ViewStyle, TextStyle } from 'react-native';

import { Title, Text } from '@ere-uilib/atoms';
import { useTheme, ThemeType, useScreenSizes } from '@ere-uilib/styles';
import { createUseStyles } from '@ere-uilib/styles/createUseStyles';
import { useTranslation } from '@ere-uilib/translations';
import { AccountsState } from '@ere-uilib/types/index';

interface Props {
  userAccount?: AccountsState
  containerStyle?: StyleProp<ViewStyle>;
  globalSavingsDateTitleStyle?: StyleProp<TextStyle>;
  globalAmountStyle?: StyleProp<TextStyle>;
  globalSavingsTitleStyle?: StyleProp<TextStyle>;
  plusMinusStyle?: StyleProp<TextStyle>;
  testId?: string;
}

type GlobalSavingsAmountStyles = {
  containerStyle?: StyleProp<ViewStyle>;
  globalSavingsDateTitleStyle?: StyleProp<TextStyle>;
  globalAmountStyle?: StyleProp<TextStyle>;
  globalSavingsTitleStyle?: StyleProp<TextStyle>;
  plusMinusStyle?: StyleProp<TextStyle>;
  plusMinusNumberStyle?: StyleProp<TextStyle>;
};

type StylesContext = {theme: ThemeType; isMobile: boolean; plusMinusValueState: boolean};

export const GlobalSavingsAmount: React.FC<React.PropsWithChildren<Props>> = props => {
  const {
    userAccount,
    containerStyle,
    globalSavingsDateTitleStyle,
    globalAmountStyle,
    globalSavingsTitleStyle,
    plusMinusStyle,
    testId
  } = props;

  const theme = useTheme();
  const { isMobile } = useScreenSizes();

  const plusMinusValueState = (userAccount && userAccount.totalAddedValue < 0) || false;

  const styles = useStyles({ theme, isMobile, plusMinusValueState }, { containerStyle, globalSavingsDateTitleStyle,
    globalAmountStyle, globalSavingsTitleStyle, plusMinusStyle });

  const { formatMessage, formatDate, formatCurrencyNumber } = useTranslation();
  return (
    <View style={styles.containerStyle}>
      <Title
        style={styles.globalSavingsTitleStyle}
        variant={isMobile ? 't6' : 't3'}
      >
        {formatMessage({ id: 'multientreprise_choice_header_title' })}
      </Title>
      {userAccount?.dateAmount &&
          <Text
            style={styles.globalSavingsDateTitleStyle}
            variant={isMobile ? 't4' : 't2'}
            weight="light"
          >
            {formatMessage({ id: 'saving_sub-title', values:
            {
              variable:
                formatDate({
                  value: userAccount?.dateAmount
                }) }
            })}
          </Text>
      }
      <View>
        <Title
          style={styles.globalAmountStyle}
          testId={testId}
          variant={isMobile ? 't2s' : 't1s'}
        >
          {formatCurrencyNumber({ value: userAccount?.totalAmount || 0 })}
        </Title>
      </View>

      {(typeof userAccount?.totalAddedValue === 'number') &&
      <Text
        style={styles.plusMinusStyle}
        variant={isMobile ? 't3' : 't2'}
        weight='light'
      >
        {formatMessage({
          id: 'saving-division_content1_plus-minus-value'
        })}
        <Text
          style={styles.plusMinusNumberStyle}
          variant={isMobile ? 't3' : 't2'}
        >
          {formatCurrencyNumber({ value: userAccount?.totalAddedValue })}
        </Text>
        
      </Text>
      }
      
    </View>
  );
};

const getStyles = (
  context?: StylesContext,
  style?: GlobalSavingsAmountStyles,
): GlobalSavingsAmountStyles => ({
  containerStyle: [
    {
      marginBottom: context?.theme.metrics.spacing.xxl
    },
    style?.containerStyle
  ],
  globalSavingsTitleStyle: [
    {
      textAlign: 'center',
      fontWeight: context?.isMobile ? "normal" : "700"
    },
    style?.globalSavingsTitleStyle
  ],
  globalSavingsDateTitleStyle: [
    {
      textAlign: 'center',
      fontWeight: '300'
    },
    style?.globalSavingsDateTitleStyle
  ],
  plusMinusStyle: [
    {
      textAlign: 'center',
    },
    style?.plusMinusStyle
  ],
  plusMinusNumberStyle: [
    {
      color: !context?.plusMinusValueState ?
        context?.theme.colors.notifications.success.c500
        : context?.theme.colors.notifications.warning.c500
    },
    style?.plusMinusStyle
  ],
  globalAmountStyle: [
    {
      textAlign: 'center',
      marginTop: context?.theme.metrics.spacing.m,
      fontWeight: '700'
    },
    style?.globalAmountStyle
  ]
});

const useStyles = createUseStyles(getStyles);
