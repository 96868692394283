import React, { useCallback } from "react";
import { ContentLoader, LoaderPlaceholderView } from "@ere-uilib/atoms";
import { abondementGaugeLeft, abondementGaugeRight } from "./constants";
import { useScreenSizes } from "@ere-uilib/styles";
import { View } from "react-native";
import { Path } from "react-native-svg";
import { useStyles } from './useStyle'

export const AbondementLoaderContent: React.FC = () => {
  const { isMobile } = useScreenSizes();
  const styles = useStyles();

  const renderLeftComponent = useCallback(() => {
    return (
      <View style={styles.topCardContainerStyle}>
        <View style={styles.topCardInnerContainerStyle}>
          <LoaderPlaceholderView style={styles.loaderPlaceHolderStyle} />
          <LoaderPlaceholderView style={styles.loaderPlaceHolderStyle} />

          {!isMobile && (
            <>
              <LoaderPlaceholderView style={styles.loaderPlaceHolderStyle} />
              <LoaderPlaceholderView style={styles.loaderPlaceHolderStyle} />
              <LoaderPlaceholderView style={styles.loaderPlaceHolderStyle} />
            </>
          )}
        </View>
      </View>
    );
  }, [isMobile, styles]);

  const renderAbondementGauge = useCallback(() => {

    return (
      <View style={styles.bottomContainerStyle}>
        <ContentLoader
          width="316"
          height="180"
        >
          <Path
            d={abondementGaugeLeft}
            fill="#E7E7E7"
          />
          <Path
            d={abondementGaugeRight}
            fill="#F5F5F5"
          />
        </ContentLoader>
      </View>
    )
  }, [styles]);

  return (
    <View style={styles.topContainerStyle}>
      {renderLeftComponent()}
      {renderAbondementGauge()}
    </View>
  );
}