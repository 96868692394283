import React, { ReactNode } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

import {
  useTheme,
  Text,
  Link,
  HelpPoint,
} from '@components/index';

import { useStyles } from './useStyles';

interface Styles {
  inputContainer: ViewStyle;
}
interface Props {
  children: ReactNode;
  label?: string;
  helpText?: string;
  helpPointContentHtml?: string;
  helpPointModalTitle?: string;
  onPressHelp?: () => void;
  containerStyle?: StyleProp<ViewStyle>;
  styles?: Styles;
}
export function InputLabel({
  children,
  label,
  helpText,
  helpPointContentHtml,
  helpPointModalTitle,
  onPressHelp,
  containerStyle,
  styles,
}: Props) {
  const theme = useTheme();
  const _styles = useStyles();
  return (
    <View style={containerStyle}>
      {label &&
        <Text style={_styles.label}>
          {label}
        </Text>
      }
      <View style={styles?.inputContainer}>
        {children}
        <HelpPoint
          contentHtml={helpPointContentHtml || ''}
          iconSize={theme.metrics.iconSizes.xxm}
          modalTitle={helpPointModalTitle || ''}
          style={_styles.identifierHelp}
        >
          <Link
            onPress={onPressHelp}
            textStyle={_styles.identifierHelp}
          >
            {helpText}
          </Link>
        </HelpPoint>
      </View>
    </View>
  );
}
