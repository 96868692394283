import React, { useMemo } from 'react';
import { View, StyleProp, ViewStyle } from 'react-native';

import { Title, Text } from '@ere-uilib/atoms';
import { useTheme } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';

import getStyles from './styles';

export interface ExpendableLineProps {
  title?: string
  amount?: number
  style?: StyleProp<ViewStyle>
  testId?: string
}

const InstallmentListItem = ({
  title,
  amount,
  style,
  testId
}: ExpendableLineProps) => {
  const theme = useTheme()
  const styles = useMemo(() => getStyles({ theme }), [theme]);
  const { formatCurrencyNumber } = useTranslation();
  const translatedAmount = formatCurrencyNumber({ value: amount ? amount : 0 });
  return (
    <View style={[styles.container, style]}>
      <View style={styles.content}>
        {title && (
          <View style={styles.titleContainer}>
            <Title variant="t7">{title}</Title>
          </View>
        )}
        {typeof amount === 'number' && (
          <Title
            testId={testId}
            variant="t6"
            weight="bold">{translatedAmount}</Title>
        )}
      </View>
    </View>
  );
};

export default InstallmentListItem;
