import { RetirementActionsType } from "@modules/retirement/actions/retirementActionsTypes";

export enum RetirementNavigatorRouteNames {
  OnboardingPage = 'OnboardingPage',
  DetailsPage = 'DetailsPage',
  RetirementPage = 'RetirementPage',
  RetirementRecoverSavingPage = 'RetirementRecoverSavingPage',
  RetirementModal = 'RetirementModal',
}

export type RetirementNavigatorInterface = {
  [RetirementNavigatorRouteNames.OnboardingPage]: undefined;
  [RetirementNavigatorRouteNames.DetailsPage]: undefined;
  [RetirementNavigatorRouteNames.RetirementPage]: undefined;
  [RetirementNavigatorRouteNames.RetirementRecoverSavingPage]: undefined;
  [RetirementNavigatorRouteNames.RetirementModal]: {
    clearAction: RetirementActionsType.CLEAR_RETIREMENT_DATA
  };
};
