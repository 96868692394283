import React from 'react';
import { TouchableOpacity, View } from 'react-native';

import { FontIcon, Text } from '@ere-uilib/atoms';
import { useTheme } from '@ere-uilib/styles';

import { LegalMenuFooterItemProps } from './interface';
import { useStyles } from './styles';

export const LIST_ITEM_HEIGHT = 50;

export const LegalMenuFooterItem = ({ label, iconName, onPress } : LegalMenuFooterItemProps) => {
  const theme = useTheme();
  
  const styles = useStyles(
    {
      LIST_ITEM_HEIGHT,
    }
  );

  return (
    <TouchableOpacity onPress={onPress}>
      <View style={styles.container}>
        <View style={styles.leftPartContainer}>
          {!!iconName && 
            <FontIcon
              color={theme.colors.text.primary.c500}
              name={iconName}
              size={theme.metrics.iconSizes.m}
              style={styles.leftIcon}
            />
          }
      
          <Text
            numberOfLines={2}
            variant="t3">{label}
          </Text>
        </View>
        <FontIcon
          color={theme.colors.text.primary.c500}
          name="chevron-droite"
          size={theme.metrics.iconSizes.xm}
          style={styles.chevronIcon}
        />
      </View>
    </TouchableOpacity>
  );
};
