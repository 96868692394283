import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

const COL_LEFT_FLEX = 2;
const COL_MIDDLE_FLEX = 1;
const COL_RIGHT_FLEX = 1;

export const useStyles = () => {
  const theme = useTheme();

  const styles = useMemo(() => {
    const HEAD_BG = theme.colors.background.default.c500;
    const FOOTER_BG = theme.colors.background.greenBackground.c100;

    return StyleSheet.create({
      tableHeaderMiddleText: {
        textAlign: 'right'
      },
      tableHeaderRightText: {
        textAlign: 'right'
      },
      tableHeadLeft: {
        flex: COL_LEFT_FLEX,
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        borderTopLeftRadius: theme.metrics.borderRadius.xs,
        borderLeftWidth: 0,
        backgroundColor: HEAD_BG
      },
      tableHeadMiddle: {
        flex: COL_MIDDLE_FLEX,
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
        borderLeftWidth: 0,
        backgroundColor: HEAD_BG
      },
      tableHeadRight: {
        flex: COL_RIGHT_FLEX,
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
        borderTopRightRadius: theme.metrics.borderRadius.xs,
        borderLeftWidth: 0,
        backgroundColor: HEAD_BG
      },
      tableRowMiddleText: {
        textAlign: 'right'
      },
      tableRowRightText: {
        textAlign: 'right'
      },
      tableRowLeft: {
        flex: COL_LEFT_FLEX,
        alignItems: 'flex-start'
      },
      tableRowMiddle: {
        flex: COL_MIDDLE_FLEX,
        alignItems: 'flex-end',
        borderLeftWidth: 0
      },
      tableRowRight: {
        flex: COL_RIGHT_FLEX,
        alignItems: 'flex-end',
        borderLeftWidth: 0
      },
      tableFooterLeftText: {
        color: theme.colors.basics.primary.c500
      },
      tableFooterMiddleText: {
        textAlign: 'right',
        color: theme.colors.basics.primary.c500
      },
      tableFooterRightText: {
        textAlign: 'right',
        color: theme.colors.basics.primary.c500
      },
      tableFooterLeft: {
        flex: COL_LEFT_FLEX,
        alignItems: 'flex-start',
        borderBottomLeftRadius: theme.metrics.borderRadius.xs,
        backgroundColor: FOOTER_BG
      },
      tableFooterMiddle: {
        flex: COL_MIDDLE_FLEX,
        alignItems: 'flex-end',
        backgroundColor: FOOTER_BG,
        borderLeftWidth: 0
      },
      tableFooterRight: {
        flex: COL_RIGHT_FLEX,
        alignItems: 'flex-end',
        borderLeftWidth: 0,
        borderBottomRightRadius: theme.metrics.borderRadius.xs,
        backgroundColor: FOOTER_BG
      }
    });
  }, [theme]);

  return styles;
};
