
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useEffect, useMemo, useState } from 'react';
import { ScrollView, StyleProp, View, ViewStyle } from 'react-native';

import {
  DocumentsTabConnected,
  FundOverviewTab,
  PerformanceTab
} from '@components/index';
import { RouteNames } from '@constants/navigation';
import { FundSheetTabsEnum } from '@constants/savings';
import {
  FundSheetHeader
} from '@ere-uilib/index';
import { createUseStyles, ThemeType, useScreenSizes, useTheme } from '@ere-uilib/styles';
import {
  FundRepartitionSupportsState,
  OverviewState,
  SharePriceHistoricItem,
  FundsDocumentsState,
  FundsPerformanceState,
  FundSourceEnum
} from '@modules/funds/types';
import { AppNavigatorInterface } from '@navigation/Interfaces';
import {useNavigation} from "@react-navigation/native";

interface DataProps {
  children?: React.ReactNode;
  isingCode: string | null
  isModal?: boolean
  onCloseModal?: () => void
  innerNavigations: string[]
  navigation?: StackNavigationProp<AppNavigatorInterface>
  fundsRepartitionSupport?: FundRepartitionSupportsState
  fundOverview: OverviewState | undefined
  fundTitle: string | null
  fundsPerformance: FundsPerformanceState | undefined
  fundsPerformanceSharePriceHisto?: SharePriceHistoricItem[]
  fundsDocumentsList: FundsDocumentsState[] | undefined
  isApiLoadingOverview: boolean
  isApiLoadingPerformance: boolean
  isApiLoadingDocuments: boolean
  dateLastVL: string | undefined
  launchDate: string | undefined
}

type FundSheetInnerPageStyles = {
  containerStyle?: StyleProp<ViewStyle>
  headerContainerStyle?: StyleProp<ViewStyle>
  scrollContainerStyle?: StyleProp<ViewStyle>
  scrollInnerContainerStyle?: StyleProp<ViewStyle>
};

type FundSheetInnerPageStylesContext = {
  theme: ThemeType
  isMobile: boolean
  isTablet: boolean
  headerHeight: number
  windowHeight: number
  isModal: boolean
};

type Props = DataProps & FundSheetInnerPageStyles

export const FundSheetInnerPage: React.FC<Props> = ({
  containerStyle,
  isingCode,
  isModal = false,
  onCloseModal,
  innerNavigations,
  fundOverview,
  fundTitle,
  fundsPerformance,
  fundsRepartitionSupport,
  fundsPerformanceSharePriceHisto,
  fundsDocumentsList,
  isApiLoadingOverview,
  isApiLoadingPerformance,
  isApiLoadingDocuments,
  dateLastVL,
  launchDate
}) => {
  const theme = useTheme();
  const navigation = useNavigation();
  const { isMobile, isTablet, windowHeight } = useScreenSizes();
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const styles = useStyles({ theme, isMobile, isTablet, headerHeight, windowHeight, isModal }, { containerStyle });
  const isUnknown = fundOverview?.source === FundSourceEnum.UNKNOWN

  const isObjectEmpty = (obj: object | undefined) => {
    if (!obj) return true

    return !Object.keys(obj).length
  }

  const innerNavigationsFiltered = useMemo(() => innerNavigations.filter(tab =>
    (!isUnknown && tab === FundSheetTabsEnum.APERCU) ||
    (!isObjectEmpty(fundsPerformance) && tab === FundSheetTabsEnum.PERFORMANCES) ||
    (!!fundsDocumentsList?.length && tab === FundSheetTabsEnum.DOCUMENTS)
  ), [fundsDocumentsList, fundsPerformance, innerNavigations, isUnknown])

  const [tmpSelectedNavigation, setTmpSelectedNavigation] = useState(
    innerNavigationsFiltered[0],
  );

  useEffect(() => {
    if (innerNavigationsFiltered !== null) {
      setTmpSelectedNavigation(innerNavigationsFiltered[0]);
    }
  }, [innerNavigationsFiltered,]);

  const renderPages = (pageName: string) => {
    switch (pageName) {
      case FundSheetTabsEnum.APERCU: {
        return (
          <FundOverviewTab
            fundOverview={fundOverview}
            fundsRepartitionSupport={fundsRepartitionSupport}
            isApiLoadingOverview={isApiLoadingOverview}
          />
        );
      }
      case FundSheetTabsEnum.PERFORMANCES:
        return (
          <PerformanceTab
            dateLastVL={dateLastVL}
            fundsPerformanceSharePriceHisto={fundsPerformanceSharePriceHisto}
            isApiLoadingPerformance={isApiLoadingPerformance}
            launchDate={launchDate}
            performanceData={fundsPerformance}
          />
        );
      case FundSheetTabsEnum.DOCUMENTS:
        return (
          <DocumentsTabConnected
            fundOverview={fundOverview}
            fundsDocumentsList={fundsDocumentsList}
            isApiLoadingDocuments={isApiLoadingDocuments}
            isingCode={isingCode}
          />
        );
      case 'fund-card_tabs4_label':
      default:
        return <></>;
    }
  };
  const onGoBackButtonPress = () => navigation?.navigate(RouteNames.BottomTabNavigator, {
    screen: RouteNames.SavingsStack, params: {
      screen: RouteNames.Savings,
    },
  });

  return (
    <View style={styles.containerStyle}>
      <View
        onLayout={({ nativeEvent }) => {
          if (headerHeight !== nativeEvent.layout.height) {
            setHeaderHeight(nativeEvent.layout.height);
          }
        }}
        style={styles.headerContainerStyle}>
        <FundSheetHeader
          innerNavigations={innerNavigationsFiltered}
          isModal={isModal}
          name={fundOverview?.name ? fundOverview.name : fundTitle}
          onCloseModal={onCloseModal}
          onGoBackButtonPress={onGoBackButtonPress}
          selectedNavigation={tmpSelectedNavigation}
          setSelectedNavigation={setTmpSelectedNavigation}
        />
      </View>
      <ScrollView
        contentContainerStyle={styles.scrollInnerContainerStyle}
        style={styles.scrollContainerStyle}>
        {renderPages(tmpSelectedNavigation)}
      </ScrollView>
    </View>
  );
};

const getStyles = (

  context?: FundSheetInnerPageStylesContext,
  style?: FundSheetInnerPageStyles,
): FundSheetInnerPageStyles => ({
  containerStyle: [
    {
      backgroundColor: 'white',
    },
    context?.isModal && {
      flex: 1,
    },
    (context?.isMobile || context?.isTablet) && {
      height: context?.windowHeight,
    },
    style?.containerStyle,
  ],
  headerContainerStyle: [
    (context?.isMobile || context?.isTablet) &&
    {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      zIndex: 2,
    },
    style?.containerStyle,
  ],
  scrollContainerStyle: [
    {
      flex: 1,
    },
    style?.scrollContainerStyle,
  ],
  scrollInnerContainerStyle: [
    (context?.isMobile || context?.isTablet) && {
      paddingTop: context?.headerHeight,
      paddingBottom: context?.theme.metrics.spacing.huge,
    },
    style?.scrollInnerContainerStyle,
  ],
});

const useStyles = createUseStyles(getStyles);
