import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

import { RouteNames } from '@constants/navigation';
import { InstallmentsStepper, NavigationHeader } from '@ere-uilib/molecules';
import { HelpPointPositiontype } from '@ere-uilib/molecules/headers/NavigationHeader/interface';
import { StepperItemType, StepperSizeEnum } from '@ere-uilib/molecules/steppers/InstallmentsStepper/interfaces';
import { createUseStyles, ThemeType, useScreenSizes, useTheme } from '@ere-uilib/styles';
import { InstallmentActionsType } from '@modules/installment/actions/installmentActionsTypes';

interface Props {
  displayCloseButton: boolean
  helpPointContentHtml: string
  helpPointModalTitle?: string
  helpPointPosition: HelpPointPositiontype
  onClose?: () => void
  title: string
  stepperItems: StepperItemType[]
  helpPoint: boolean
}

interface ContextProps {
  theme: ThemeType
}

interface StyleProps {
  stepperContainer?: StyleProp<ViewStyle>
  stepperStyle?: StyleProp<ViewStyle>
}

export const InstallmentHeader: React.FC<React.PropsWithChildren<Props & StyleProps>> = ({
  onClose,
  title,
  displayCloseButton,
  helpPointModalTitle,
  helpPointContentHtml,
  helpPointPosition,
  stepperItems,
  stepperContainer,
  stepperStyle,
  helpPoint,
}) => {
  const theme = useTheme();
  const navigation = useNavigation();
  const { isMobile, isTablet } = useScreenSizes();
  const styles = useStyles({
    theme,
  },
  {
    stepperContainer,
    stepperStyle,
  });
  const defaultCloseAction = () =>
    navigation.navigate(RouteNames.InstallmentStack, {
      screen: RouteNames.InstallmentModal,
      params: {
        clearAction: InstallmentActionsType.CLEAR_INSTALLMENT_DATA,
      },
    });
  return (
    <NavigationHeader
      displayCloseButton={displayCloseButton}
      helpPoint={helpPoint}
      helpPointContentHtml={helpPointContentHtml}
      helpPointModalTitle={helpPointModalTitle}
      helpPointPosition={helpPointPosition}
      onClose={onClose || defaultCloseAction}
      title={title}
    >
      <View style={styles.stepperContainer}>
        <InstallmentsStepper
          containerStyle={styles.stepperStyle}
          lineLength={theme.metrics.spacing.m}
          stepperItems={stepperItems}
          stepperSize={(isMobile || isTablet) ? StepperSizeEnum.SMALL : StepperSizeEnum.BIG}
        />
      </View>
    </NavigationHeader>);
};

const getStyles = (
  context?: ContextProps,
  style?: StyleProps,
): StyleProps => ({
  stepperContainer: [
    {
      alignItems: 'center',
    },
    style?.stepperContainer,
  ],
  stepperStyle: [
    {
      paddingBottom: context?.theme.metrics.spacing.s,
      marginTop: context && -context?.theme.metrics.spacing.s,
    },
    style?.stepperStyle,
  ],
});

const useStyles = createUseStyles(getStyles);
