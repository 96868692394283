import { connect } from 'react-redux';

import { AppState } from '@modules/reducers';

import {
  RefundAskProofPageComponent,
} from './RefundAskProofPage.component';

const mapStateToProps = (state: AppState) => ({

});

const mapDispatchToProps = {

};

export const RefundAskProofPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(RefundAskProofPageComponent);
