import { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { hasNotch } from 'react-native-device-info';
import { useTheme } from '@ere-uilib/styles';

export function useStyles() {
  const theme = useTheme();
  const styles = useMemo(() => StyleSheet.create({
    notificationItem: {
      marginTop: theme.metrics.spacing.s,
    },
    container: {
      position: 'absolute',
      zIndex: 1,
      display: 'flex',
      flex: 1,
      alignSelf: 'center',
      marginRight: theme.metrics.spacing.s,
      width: 400,
      marginTop: hasNotch() ? theme.metrics.spacing.xl : theme.metrics.spacing.none,
    },
  }), [theme]);

  return styles;
}
