import React, { useEffect, useState } from 'react';

import { DashboardPageWrapperConnected } from '@components/index';
import { labelAndTypeCorrelationObject } from '@constants/newFeed';

import HistorymainContent from './components/HistoryMainContent';
import { useHistoryDataAdapter } from './hooks/useHistoryDataAdapter';
import { HistoryProps } from './interface';

export const History: React.FC<HistoryProps> = ({
  getFeedNews,
  newsFeedData,
  isLoading,
  error,
}: HistoryProps) => {
  const { styles, labels } = useHistoryDataAdapter();
  const [selectedItem, setSelectedItem] = useState(labels.tabItems[0]);
  const getSelectedType = () => labelAndTypeCorrelationObject.filter(item => item.tabName === selectedItem)[0]?.type

  useEffect(() => {
    if (newsFeedData.length <= 0) getFeedNews()
  }, []);

  return (
    <DashboardPageWrapperConnected>
      <HistorymainContent
        error={error.message}
        feedNewsData={newsFeedData}
        isLoading={isLoading}
        onChangeSelectedItem={item => {
          setSelectedItem(item);
        }}
        onRetryActionPress={getFeedNews}
        pageTitle={labels.pageTitle}
        selectedItem={selectedItem}
        styles={styles}
        tabItems={labels.tabItems}
        type={getSelectedType()}
      />
    </DashboardPageWrapperConnected>
  );
};
