import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

export function useStyles() {
  const theme = useTheme();

  const styles = useMemo(() => StyleSheet.create({
    linkAddOptionalQuestions: {
      marginVertical: theme.metrics.spacing.xm,
      textAlign: 'center',
    },
    onGoingVvpDispositifNotification: {
      marginTop: theme.metrics.spacing.s,
    },
    dashboardPageWrapperConnected: {
      backgroundColor: theme.colors.basics.white,
    },
  }), [theme]);

  return styles;
}
