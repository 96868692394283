import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ArbitrationTypes } from '@constants/arbitrations';
import { useTranslation } from '@ere-uilib/translations';
import { getReallocationType } from '@modules/arbitration/selectors';
import { DispositifState } from '@modules/common/types';

interface Props {
  selectedPlan?: DispositifState;
  isOad?: boolean;
}

export const useLabels = (props: Props) => {
  const { selectedPlan, isOad } = props;
  const reallocationType = useSelector(getReallocationType)
  const isFlow = reallocationType === ArbitrationTypes.flow;

  const isParialPlan = selectedPlan?.filterProperties.PartialReallocation;
  const messageId =
    reallocationType === ArbitrationTypes.stock
      ? isParialPlan
        ? 'Arbitrage_popinConfirmDispositif_contentPartiel'
        : 'Arbitrage_popinConfirmDispositif_contentTotal'
      : 'Arbitrage_modalBonSavoir_description';
  const { formatMessage, getMessageRaw, formatCurrencyNumber } = useTranslation();
  const popinDescription = formatMessage({
    id: messageId,
    values: {
      dispositif: selectedPlan?.name,
    },
  });
  const labels = useMemo(() => ({
    header: {
      helpContent: getMessageRaw({
        id: (isOad && 'AbritrageOADbesoinAideDescription') ||
          (isFlow && 'FlowReallocationsNeedHelpDescription') ||
          'Arbitrage_choixDispositifsAide_description',
      }),
      helpTitle: formatMessage({
        id: (isOad && 'AbritrageOADbesoinAideTitre') ||
          (isFlow && 'FlowReallocationsNeedHelpTitle') ||
          'Arbitrage_choixDispositifsAide_titre',
      }),
      pageTitle: formatMessage({ id: 'Arbitrage_etape1' }),
    },
    popin: {
      btnTitle: formatMessage({
        id: 'Arbitrage_popinConfirmDispositif_bouton',
      }),
      description: popinDescription,
      title: formatMessage({ id: 'Arbitrage_modalBonSavoir_titre' }),
    },
    content: {
      totalAmountTitle: (amount: number) => formatMessage({
        id: isOad ? 'AbritrageOADSelectionDispositifMontantDescription' : 'Arbitrage_ChoixDisponible_contentMontant',
        values: { montant: formatCurrencyNumber({ value: amount }) },
      })
    },
    goHomeButton: {
      buttonTitle: formatMessage({ id: 'AbritrageOADPageFinaleRetourAcceuilBouton' })
    }
  }), [formatCurrencyNumber, formatMessage, getMessageRaw, isFlow, isOad, popinDescription]);

  return labels;
};
