import React from 'react';
import { StyleProp, TextStyle, ViewStyle } from 'react-native';

import { ThemeType } from '@ere-uilib/styles';
import { StepperStatus } from '@ere-uilib/types';

export enum DirectionEnum {
  ROW = 'row',
  COLUMN = 'column'
}

export enum StepperSizeEnum {
  SMALL = 'small',
  BIG = 'big',
}

export interface BubbleProps {
  status?: StepperStatus;
  label: string;
}

export interface Props {
  withTitle?: boolean
  stepperSize?: StepperSizeEnum
  lineLength?: number
  direction?: DirectionEnum
  containerStyle?: StyleProp<ViewStyle>
  stepperItems: StepperItemType[]
}

export interface Styles{
  lineAndRoundContainer?: StyleProp<ViewStyle>
  titleStyle?: StyleProp<TextStyle>
  containerStyle?: StyleProp<ViewStyle>
  straightline?: StyleProp<ViewStyle>
  lineContainer?: StyleProp<ViewStyle>
  itemContainer?: StyleProp<ViewStyle>
  titleContainer?: StyleProp<ViewStyle>
  stepItem?: StyleProp<ViewStyle>
  titleDisabled?: StyleProp<TextStyle>
  toDoRoundedTitle?: StyleProp<TextStyle>
  defaultRoundedTitle?: StyleProp<TextStyle>
  stepContent?: StyleProp<ViewStyle>
}

export type Context = {
  lineLength?: number
  theme:ThemeType
  stepperSize?: StepperSizeEnum
  isMobile?: boolean
  direction: DirectionEnum
  isDone?: boolean
}

export interface StepperItemType {
  title?: string
  icon?: string
  status?: StepperStatus
  children?: React.ReactNode;
}
