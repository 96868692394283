import React, {FC, useMemo} from "react";
import { View } from "react-native";

import { PlanFamillyList } from "@constants/common";
import { ProfileBankAccountCard } from "@ere-uilib/molecules";
import { useBankSummaryContext } from "@pages/BankDetails/BankSummaryPage/BankSummaryProvider";
import { BankAccountEmpty } from "@pages/BankDetails/BankSummaryPage/components/BankAccountEmpty";
import { BankAccountStatus } from "@pages/BankDetails/BankSummaryPage/components/BankAccountStatus";
import { useTranslate } from "@pages/BankDetails/BankSummaryPage/hooks/useTranslate";
import { useStyles } from "@pages/BankDetails/BankSummaryPage/useStyles";

export const BankAccountCardRC: FC = () => {
    const styles= useStyles();
    const { dictionnary } = useTranslate();
    const { bankData } = useBankSummaryContext();
    const rcBankList = bankData?.rc?.rcBankAccountDetailsList || [];
    const bankListItem = useMemo(() => {
        return (
            rcBankList.map((accountDetails, index)=>{
                return (
                    <>
                        {
                            accountDetails.bankAccountIsSet
                                ?   <BankAccountStatus
                                    bankData={accountDetails.debitBankAccountDetails}
                                    dispositifs={accountDetails.rcPlan ?? ''}
                                    isRefund={false}
                                    key={`accountDetails-${index}`}
                                    planFamily={PlanFamillyList.RC}
                                    planId={accountDetails.planId || ""}
                                    selectedBankDataIndex={index}
                                />
                                :
                                <BankAccountEmpty
                                    dispositifs={accountDetails.rcPlan  ?? ''}
                                    key={`accountEmpty-${index}`}
                                    planFamily={PlanFamillyList.RC}
                                    planId={accountDetails.planId || ""}/>
                        }
                        {index < rcBankList.length -1 && <View style={styles.separator} />}

                    </>)
            })
        )
    }, [rcBankList, styles.separator]);

    return(

        <ProfileBankAccountCard
            cardTitle={dictionnary.CoordonneesBancairesRecapRetraiteCollectiveRCTitre}
            isES>
            <View style={styles.separator} />
            {bankListItem}
        </ProfileBankAccountCard>
    );
}
