import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

export function useStyles() {
  const theme = useTheme();

  const styles = useMemo(() => StyleSheet.create({
    grayTitleStyle: {
      color: theme.colors.basics.grey.c500,
    },
    buttonStyle: {
      flexDirection: 'row',
      borderColor: theme.colors.basics.grey.c300,
      borderWidth: 1,
      padding: theme.metrics.spacing.s,
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: theme.metrics.spacing.xs,
      marginRight: theme.metrics.spacing.s,
    },
    dateStyle: {
      marginTop: theme.metrics.spacing.xs,
    },
    buttonIcon: {
      color: theme.colors.basics.primary.c500,
      fontSize: theme.metrics.iconSizes.xxm,
      marginLeft: theme.metrics.spacing.xm,
    },
    calendarContainer: {
      width: '100%',
      paddingTop: theme.metrics.spacing.xxl,
      minHeight: 400,
    },
  }), [theme]);

  return styles;
}
