import React from "react";
import { View } from "react-native";

import { useTranslation } from "@components/index";
import { Title } from "@ere-uilib/atoms";
import { CheckBox, RadioButton } from "@ere-uilib/molecules";

import { useStyles } from "../useStyles";
import { locator } from "@constants/locator";

interface Props {
  handlePressReceiveMessage: () => void;
  handlePressEmail: () => void;
  handlePressSMS: () => void;
  handlePressNotReceiveMessage: () => void;
  optInEmail: boolean;
  optInSms: boolean;
  hasNotificationsActivated: boolean;
}

export function ContactPreferencetemplate({
  handlePressReceiveMessage,
  handlePressEmail,
  handlePressSMS,
  handlePressNotReceiveMessage,
  optInEmail,
  optInSms,
  hasNotificationsActivated,
}: Props) {
  const { formatMessage } = useTranslation();

  const styles = useStyles();

  return (
    <>
      <Title
      testId={locator._profile._contact_preferences._title}
      variant="t3"
      weight="bold">
        {formatMessage({ id: "Profil_Prefcontact_edit_title" })}
      </Title>
      <View style={styles.form}>
        <View>
          <RadioButton
            label={formatMessage({
              id: "Profil_Prefcontact_edit_optinContract_choice1",
            })}
            onPress={handlePressReceiveMessage}
            value={hasNotificationsActivated}
            testId={locator._profile._contact_preferences._receive_notification}
          />
          <View style={styles.checkboxes}>
            <View style={styles.checkbox}>
              <CheckBox
                label={formatMessage({
                  id: "Profil_Prefcontact_edit_optin_Email_label",
                })}
                onPress={handlePressEmail}
                value={optInEmail}
                testId={locator._profile._contact_preferences._receive_by_email}
              />
            </View>
            <View style={styles.checkbox}>
              <CheckBox
                label={formatMessage({
                  id: "Profil_Prefcontact_edit_optin_SMS_label",
                })}
                onPress={handlePressSMS}
                value={optInSms}
                testId={locator._profile._contact_preferences._receive_by_message}
              />
            </View>
          </View>
        </View>
        <View style={styles.secondRadioButton}>
          <RadioButton
            label={formatMessage({
              id: "Profil_Prefcontact_edit_optinContract_choice2",
            })}
            onPress={handlePressNotReceiveMessage}
            value={!hasNotificationsActivated}
            testId={locator._profile._contact_preferences._dont_receive_notification}
          />
        </View>
      </View>
    </>
  );
}
