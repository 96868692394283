import { ErrorAction, ErrorState } from './types';
import { getErrorMatches } from './utils';

const errorReducer = (state: ErrorState = {}, action: ErrorAction, ): ErrorState => {
  const matches = typeof action?.type === 'string' ? 
    getErrorMatches(action.type) 
    : undefined;
    
  const pureRequestName = matches?.[1]
  const requestStatus = matches?.[2]
  
  switch (requestStatus) {
  case 'REQUEST':
    return {
      ...state,
      [pureRequestName + '_REQUEST']: null,
    };
  case 'FAILURE':
    return {
      ...state,
      [pureRequestName + '_REQUEST']: action.error,
    };
  default:
    return state
  }
};

export default errorReducer;
