import { useMemo } from 'react'

import { useTranslation } from '@components/ERE-UILib'

export const useLabels = () => {
  const { formatMessage, getMessageRaw, formatDate } = useTranslation()

  const labels = useMemo(
    () => ({
      helpContent: getMessageRaw({ id: 'DocCenterHelptextEReleves' }),
      helpTitle: formatMessage({ id: 'DocCenterInfoTitre' }),
      headerTitle: formatMessage({ id: 'DocCenterDocMyEReleveTitle' }),
      getDocumentTitle: (typeLabel: string) =>
        formatMessage({ id: 'DocCenterDocMyEReleveDocType' + typeLabel }),
      getDocumentCreationDate: (creationDate: string) =>
        formatMessage({
          id: 'DocCenterDocMyEReleveCreationDate',
          values: { variable: formatDate({ value: creationDate }) }
        }),
      notification: (hasNeverBeenASubscriber = false) => ({
        description: formatMessage({
          id: hasNeverBeenASubscriber
            ? 'DocCenterDocMyEReleveNoSubscriptionWithNoEReleveAvailableTeaser'
            : 'DocCenterDocMyEReleveNoSubscriptionWithEReleveAvailableTeaser'
        }),
        link: formatMessage({
          id: hasNeverBeenASubscriber
            ? 'DocCenterDocMyEReleveNoSubscriptionWithNoEReleveAvailableLinkReactivation'
            : 'DocCenterDocMyEReleveNoSubscriptionWithEReleveAvailableLinkReactivation'
        }),
        title: formatMessage({ id: 'DocCenterDocMyEReleveSubscriptionTeaserTitle' })
      }),
      empty: formatMessage({ id: 'DocCenterDocMyEReleveNoEReleveAvailableLegend' }),
      error: formatMessage({ id: 'DocCenterDocMyEReleveErrorMessage' }),
    }),
    [formatMessage, getMessageRaw, formatDate]
  )

  return labels
}
