import { connect } from 'react-redux';

import {
  getCalculatedContributionDataRequest,
  setInstallmentRepartitionAmountRequest,
  initCalculatedContributionData,
  setInstallmentRepartitionAmountModalVisibleState,
} from '@modules/installment/actions/installmentActions';
import { InstallmentActionsType } from '@modules/installment/actions/installmentActionsTypes';
import { AppState } from '@modules/index';
import {
  getCalculatedContribution,
  getCalculatedContributionError,
  getInstallmentRepartitionData,
  getInstallmentRepartitionAmountModalVisibleState,
} from '@modules/installment/selectors';

import { InstallmentAmountFieldModalConnectedComponent } from './InstallmentAmountFieldModalConnected.component';

const mapStateToProps = (state: AppState) => {
  return {
    installmentRepartitionData: getInstallmentRepartitionData(state),
    calculatedContribution: getCalculatedContribution(state),
    isSetInstallmentAmountLoading: state.isLoading[InstallmentActionsType.SET_INSTALLMENT_REPARTITION_AMOUNT_REQUEST],
    isCalculContributionLoading: state.isLoading[InstallmentActionsType.GET_CALCULATED_CONTRIBUTION_DATA_REQUEST],
    calculatedContributionError: getCalculatedContributionError(state),
    visible: getInstallmentRepartitionAmountModalVisibleState(state),
  };
};

const mapDispatchToProps = {
  onSetInstallmentRepartitionAmount: setInstallmentRepartitionAmountRequest,
  onCalculContribution: getCalculatedContributionDataRequest,
  onInitCalculatedContribution: initCalculatedContributionData,
  onSetInstallmentRepartitionAmountModalVisibleState: setInstallmentRepartitionAmountModalVisibleState,
};

export const InstallmentAmountFieldModalConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(InstallmentAmountFieldModalConnectedComponent);
