import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';

import {
  SelfcareNavigatorInterface,
  SelfcareNavigatorRouteNames,
} from '@navigation/Interfaces';
import { useDefaultScreensOptions } from '@navigation/useDefaultScreensOptions';
import {
  PersoneoIdentifierFormPage,
  SelfcarePersoneoSuccessPage,
  SelfcareVisiogoSuccessPage,
  VisiogoIdentifierFormPage,
  LetterIdentifierFormPage,
  SelfcareLetterSuccessPage,
} from '@pages/Selfcare';
import { ActivateAccountModeSelectionPage } from "@pages/Walkthrough";

const SelfcareStack = createStackNavigator<SelfcareNavigatorInterface>();

export const SelfcareStackNavigator = () => {
  const defaultScreensOptions = useDefaultScreensOptions();
  return (
    <SelfcareStack.Navigator
      initialRouteName={SelfcareNavigatorRouteNames.PersoneoIdentifierForm}
      screenOptions={{
        gestureEnabled: false,
        headerShown: false,
      }}
    >
      <SelfcareStack.Screen
        component={PersoneoIdentifierFormPage}
        name={SelfcareNavigatorRouteNames.PersoneoIdentifierForm}
        options={defaultScreensOptions.PersoneoIdentifierForm}
      />
      <SelfcareStack.Screen
        component={VisiogoIdentifierFormPage}
        name={SelfcareNavigatorRouteNames.VisiogoIdentifierForm}
        options={defaultScreensOptions.VisiogoIdentifierForm}
      />
      <SelfcareStack.Screen
        component={ActivateAccountModeSelectionPage}
        name={SelfcareNavigatorRouteNames.ActivateAccountModeSelection}
        options={defaultScreensOptions.ActivateAccountModeSelectionPage}
      />
      <SelfcareStack.Screen
        component={SelfcarePersoneoSuccessPage}
        name={SelfcareNavigatorRouteNames.SelfcarePersoneoSuccess}
        options={defaultScreensOptions.SelfcarePersoneoSuccessPage}
      />
      <SelfcareStack.Screen
        component={SelfcareVisiogoSuccessPage}
        name={SelfcareNavigatorRouteNames.SelfcareVisiogoSuccess}
        options={defaultScreensOptions.SelfcareVisiogoSuccessPage}
      />
      <SelfcareStack.Screen
        component={LetterIdentifierFormPage}
        name={SelfcareNavigatorRouteNames.LetterIdentifierForm}
        options={defaultScreensOptions.LetterIdentifierForm}
      />
      <SelfcareStack.Screen
        component={SelfcareLetterSuccessPage}
        name={SelfcareNavigatorRouteNames.SelfcareLetterSuccess}
        options={defaultScreensOptions.SelfcareLetterSuccess}
      />
    </SelfcareStack.Navigator>
  );
};
