import { SwitchButtonsStyles, StylesContext } from "./interfaces";

export const getStyles = (
  context?: StylesContext,
  style?: SwitchButtonsStyles,
): SwitchButtonsStyles => ({

  containerStyle: [
    {
      alignSelf: 'flex-start',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      borderRadius: context?.theme.metrics.spacing.xs,
      borderWidth: 1,
      borderColor: "white",
      backgroundColor: context?.theme.colors.basics.primary.c500,
      minHeight: 30,
      position: "absolute",
      right: "2%",
      marginTop: context?.theme.metrics.spacing.s,
    },
    style?.containerStyle,
  ],

  itemActiveStyle: [{
    paddingVertical: context?.theme.metrics.spacing.xs,
    paddingHorizontal: context?.theme.metrics.spacing.s,
    borderRadius: context?.theme.metrics.spacing.xs,
    backgroundColor: "#FFF",
  },
  style?.itemActiveStyle],

  itemStyle: [{
    paddingVertical: context?.theme.metrics.spacing.xs,
    paddingHorizontal: context?.theme.metrics.spacing.s,
   color: "white"
  }, style?.itemStyle],

  textStyle: [{
    textAlign: 'center',
    color: context?.theme.colors.basics.white
  }, style?.textStyle],

  activeTextStyle: [{
    color: context?.theme.colors.basics.primary.c500,
  }, style?.activeTextStyle]
});
