import {
  useTheme,
  useScreenSizes,
  useTranslation,
} from '@components/index';
import { createUseStyles } from '@ere-uilib/styles';

import { getStyles } from '../styles';

const useStyles = createUseStyles(getStyles);
const tabItemsIds = [
  'MyFil_Tab_all_label',
  'MyFil_Tab_Notif_label',
  'MyFil_Tab_Operation_label',
  'MyFil_Tab_Infos_label',
];
export const useHistoryDataAdapter = () => {
  const theme = useTheme();
  const { isMobile, isTablet, windowHeight } = useScreenSizes();
  const styles = useStyles({ theme, isMobile, isTablet, windowHeight }, {});
  const { formatMessage } = useTranslation();
  const labels = {
    tabItems: tabItemsIds,
    pageTitle: formatMessage({ id: 'MyFil_Title' }),
  };
  return { styles, labels };
};
