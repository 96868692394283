import { put, call, select, takeLatest } from 'redux-saga/effects';

import { RouteNames } from '@constants/navigation';
import * as ValidateIdentityActions from './actions/validateIdentityActions';
import {
  CheckEERIdentityValidationCodeRequestAction,
  ConfirmRegistrationRequestAction,
  FranceConnectConfirmIdentityRequestAction,
  SendEERIdentityValidationCodeRequestAction,
  ValidateEEREmailNewIdentifierCodeRequestAction,
  ValidateEEREmailNewIdentifierRequestAction,
  ValidateEERPhoneNewIdentifierCodeRequestAction,
  ValidateEERPhoneNewIdentifierRequestAction,
  ValidateIdentityActionsType
} from './actions/validateIdentityActionsTypes';
import * as authActions from '@modules/auth/actions/authActions';
import { runManager } from '@modules/moduleManager';
import {
  getInvitationSelector,
  getInvitationUserId
} from '@modules/invitation/selectors';
import { InvitationState } from '@modules/invitation/types';
import { getFranceConnectRegistrationBodyState, getUserInputEmail } from './selectors';
import {
  fetchSendEERValidationIdentityCode,
  fetchCheckEERValidationIdentityCode,
  fetchValidateEEREmailNewIdentifier,
  fetchValidateEEREmailNewIdentifierCode,
  fetchValidateEERPhoneNewIdentifier,
  fetchValidateEERPhoneNewIdentifierCode,
  registrationRequestConfirm,
  franceConnectCreateIdentityValidation,
  franceConnectConfirmationIdentity,
} from '@modules/invitation/services';
import { RootNavigation } from '@navigation/RootNavigation';
import { getAuthConfig } from '@modules/settings/selectors';
import { signinRedirectUsingToken } from '@modules/auth/services';
import { Platform } from 'react-native';

function* sendEERValidationIdentityCode(
  action: SendEERIdentityValidationCodeRequestAction
): any {
  yield put(ValidateIdentityActions.InitError());
  const userId = yield select(getInvitationUserId);
  yield call(fetchSendEERValidationIdentityCode, {
    userId: userId,
  });
  const invitation:InvitationState = yield select(getInvitationSelector);
  const isResend = !!action.isResend;
  const isByPhone = !!invitation.user.mobilePhone;
  if (!isResend) {
    if (isByPhone) {
      RootNavigation?.navigate(RouteNames.RelationshipEntringProcess, {
        screen: RouteNames.ValidationSignupPhoneCode,
      });
    } else {
      RootNavigation?.navigate(RouteNames.RelationshipEntringProcess, {
        screen: RouteNames.ValidationSignupEmailCode,
      });
    }
  }
  yield put(ValidateIdentityActions.sendEERIdentityValidationCodeSuccess());
}

function* checkEERValidationIdentityCode(
  action: CheckEERIdentityValidationCodeRequestAction
): any {

  yield put(ValidateIdentityActions.InitError());
  const userId = yield select(getInvitationUserId);
  yield call(fetchCheckEERValidationIdentityCode, {
    userId: userId,
    validationCode: action.OTPCode,
  });
  yield put(ValidateIdentityActions.checkEERIdentityValidationCodeSuccess());
  return yield RootNavigation?.navigate(RouteNames.RelationshipEntringProcess, {
    screen: RouteNames.ValidateSignupThankYou,
  });
}
function* checkEERValidationSecondLetterIdentityCode(
  action: CheckEERIdentityValidationCodeRequestAction
): any {

  yield put(ValidateIdentityActions.InitError());
  const userId = yield select(getInvitationUserId);
  yield call(fetchCheckEERValidationIdentityCode, {
    userId: userId,
    validationCode: action.OTPCode,
  });
  yield put(ValidateIdentityActions.checkEERSecondLetterIdentityValidationCodeSuccess());
  return yield RootNavigation?.navigate(RouteNames.RelationshipEntringProcess, {
    screen: RouteNames.ValidateSignupThankYou,
  });
}

// Validate New Email identifier
function* validateEEREmailNewIdentifier(
  action: ValidateEEREmailNewIdentifierRequestAction,
): any {
  yield put(ValidateIdentityActions.InitError());
  const invitation:InvitationState = yield select(getInvitationSelector);
  const userId = yield select(getInvitationUserId);
  const userEmail = action.email.replace(/\s/g, '');
  yield call(fetchValidateEEREmailNewIdentifier, {
    userId: userId,
    email: userEmail,
  });
  yield put(ValidateIdentityActions.SetEERNewEmailInput(userEmail.toLowerCase()));
  yield put(ValidateIdentityActions.validateEEREmailNewIdentifierSuccess());

  const isEmailSameAsCurrent = invitation?.user?.email?.toLowerCase() === userEmail.toLowerCase();
  const isUserNeedToDefineMobilePhone = !invitation?.user?.mobilePhone;

  if (isEmailSameAsCurrent) {
    if (isUserNeedToDefineMobilePhone) {
      return yield RootNavigation?.replace(RouteNames.RelationshipEntringProcess, {
        screen: RouteNames.ChooseIdentificationPhone,
      });
    }

    return yield RootNavigation?.navigate(RouteNames.RelationshipEntringProcess, {
      screen: RouteNames.ChoosePassword,
    });
  }
  return yield RootNavigation?.navigate(RouteNames.RelationshipEntringProcess, {
    screen: RouteNames.ChooseIdentificationEmailCodePage,
  });
}

function* validateEEREmailNewIdentifierCode(
  action: ValidateEEREmailNewIdentifierCodeRequestAction,
): any {
  yield put(ValidateIdentityActions.InitError());
  const invitation:InvitationState = yield select(getInvitationSelector);
  const userId = yield select(getInvitationUserId);
  yield call(fetchValidateEEREmailNewIdentifierCode, {
    emailCode: action.emailCode,
    userId: userId,
  });
  yield put(ValidateIdentityActions.validateEEREmailNewIdentifierCodeSuccess());

  const isUserNeedToDefineMobilePhone = !invitation?.user?.mobilePhone;

  if (isUserNeedToDefineMobilePhone) {
    return yield RootNavigation?.replace(RouteNames.RelationshipEntringProcess, {
      screen: RouteNames.ChooseIdentificationPhone,
    });
  }
  return yield RootNavigation?.navigate(
    RouteNames.RelationshipEntringProcess,
    { screen: RouteNames.ChoosePassword }
  );
}

// Validate New Phone identifier
function* validateEERPhoneNewIdentifier(
  action: ValidateEERPhoneNewIdentifierRequestAction,
): any {
  yield put(ValidateIdentityActions.InitError());
  const userId = yield select(getInvitationUserId);
  yield call(fetchValidateEERPhoneNewIdentifier, {
    userId: userId,
    phone: action.phone,
  });
  yield put(ValidateIdentityActions.SetEERNewPhoneInput(action.phone));
  yield put(ValidateIdentityActions.validateEERPhoneNewIdentifierSuccess());

  return yield RootNavigation?.navigate(RouteNames.RelationshipEntringProcess, {
    screen: RouteNames.ChooseIdentificationPhoneCodePage,
  });

}

function* validateEERPhoneNewIdentifierCode(
  action: ValidateEERPhoneNewIdentifierCodeRequestAction,
): any {
  yield put(ValidateIdentityActions.InitError());
  const userId = yield select(getInvitationUserId);
  yield call(fetchValidateEERPhoneNewIdentifierCode, {
    phoneCode: action.phoneCode,
    userId: userId,
  });

  yield put(ValidateIdentityActions.validateEERPhoneNewIdentifierCodeSuccess());

  yield RootNavigation?.navigate(
    RouteNames.RelationshipEntringProcess,
    { screen: RouteNames.ChoosePassword }
  );
}

function* registrationRequestConfirmation(
  action: ConfirmRegistrationRequestAction,
): any {
  yield put(ValidateIdentityActions.InitError());
  // get authConfig from redux
  const authConfig = yield select(getAuthConfig);
  const userId = yield select(getInvitationUserId);
  const response = yield call(registrationRequestConfirm, {
    password: action.password,
    userId: userId,
  });
  yield put(
    ValidateIdentityActions.RegistrationRequestConfirmSuccess(response.data),
  );
  if (Platform.OS === 'web') {
    return yield call(
    signinRedirectUsingToken,
    response.data,
    authConfig
  )}
   // signin mobile app
   const inputEmail = yield select(getUserInputEmail)
   yield put(authActions.signInNativeAppRequest({
    userName: inputEmail,
    password: action.password
   }, false))
}

function* franceConnectRegistrationRequest(
): any {
  yield put(ValidateIdentityActions.InitError());
  const userId = yield select(getInvitationUserId);
  const response = yield call(franceConnectCreateIdentityValidation, userId);
  yield put(
    ValidateIdentityActions.FranceConnectRegistrationSuccess(
      response.data,
    ),
  );
  const redirectUrl = response.data.redirectUrl;
  if (redirectUrl) {yield (window.location.href = redirectUrl);}
}

function* franceConnectConfirmationRequest(action: FranceConnectConfirmIdentityRequestAction
): any {
  yield put(ValidateIdentityActions.InitError());
  const registrationId = yield select(getInvitationUserId);
  const state = yield select(getFranceConnectRegistrationBodyState);
  yield call(franceConnectConfirmationIdentity, registrationId, state, action.code);
  yield put(ValidateIdentityActions.FranceConnectConfirmIdentitySuccess());
  yield RootNavigation?.navigate(RouteNames.RelationshipEntringProcess, { screen: RouteNames.ValidateSignupThankYou });
}

export function* sendEERValidationIdentityCodeSagas() {
  yield takeLatest(
    ValidateIdentityActionsType.SEND_EER_IDENTITY_VALIDATION_CODE_REQUEST,
    runManager(sendEERValidationIdentityCode, ValidateIdentityActionsType.SEND_EER_IDENTITY_VALIDATION_CODE_FAILURE)
  );
}
export function* checkEERValidationSecondLetterIdentityCodeSagas() {
  yield takeLatest(
    ValidateIdentityActionsType.CHECK_EER_SECOND_LETTER_IDENTITY_VALIDATION_CODE_REQUEST,
    runManager(checkEERValidationSecondLetterIdentityCode, ValidateIdentityActionsType.CHECK_EER_SECOND_LETTER_IDENTITY_VALIDATION_CODE_FAILURE)
  );
}

export function* checkEERValidationIdentityCodeSagas() {
  yield takeLatest(
    ValidateIdentityActionsType.CHECK_EER_IDENTITY_VALIDATION_CODE_REQUEST,
    runManager(checkEERValidationIdentityCode, ValidateIdentityActionsType.CHECK_EER_IDENTITY_VALIDATION_CODE_FAILURE)
  );
}

export function* validateEEREmailNewIdentifierSagas() {
  yield takeLatest(
    ValidateIdentityActionsType.VALIDATE_EER_EMAIL_NEW_IDENTIFIER_REQUEST,
    runManager(
      validateEEREmailNewIdentifier,
      ValidateIdentityActionsType.VALIDATE_EER_EMAIL_NEW_IDENTIFIER_FAILURE
    )
  );
}

export function* validateEEREmailNewIdentifierCodeSagas() {
  yield takeLatest(
    ValidateIdentityActionsType.VALIDATE_EER_EMAIL_NEW_IDENTIFIER_CODE_REQUEST,
    runManager(
      validateEEREmailNewIdentifierCode,
      ValidateIdentityActionsType.VALIDATE_EER_EMAIL_NEW_IDENTIFIER_CODE_FAILURE
    )
  );
}

export function* validateEERPhoneNewIdentifierSagas() {
  yield takeLatest(
    ValidateIdentityActionsType.VALIDATE_EER_PHONE_NEW_IDENTIFIER_REQUEST,
    runManager(
      validateEERPhoneNewIdentifier,
      ValidateIdentityActionsType.VALIDATE_EER_PHONE_NEW_IDENTIFIER_FAILURE
    )
  );
}

export function* validateEERPhoneNewIdentifierCodeSagas() {
  yield takeLatest(
    ValidateIdentityActionsType.VALIDATE_EER_PHONE_NEW_IDENTIFIER_CODE_REQUEST,
    runManager(
      validateEERPhoneNewIdentifierCode,
      ValidateIdentityActionsType.VALIDATE_EER_PHONE_NEW_IDENTIFIER_CODE_FAILURE
    )
  );
}

export function* registrationRequestConfirmSagas() {
  yield takeLatest(
    ValidateIdentityActionsType.CONFIRIM_REGISTRATION_REQUEST,
    runManager(registrationRequestConfirmation,
      ValidateIdentityActionsType.CONFIRIM_REGISTRATION_FAILURE)
  );
}
// FRANCE CONNECT
export function* franceConnectRegistrationRequestSagas() {
  yield takeLatest(
    ValidateIdentityActionsType.FRANCE_CONNECT_CREATE_IDENTIY_VALIDATION_REQUEST,
    runManager(franceConnectRegistrationRequest, ValidateIdentityActionsType.FRANCE_CONNECT_CREATE_IDENTIY_VALIDATION_FAILURE)
  );
}

export function* franceConnectConfirmationRequestSagas() {
  yield takeLatest(
    ValidateIdentityActionsType.FRANCE_CONNECT_CONFIRM_IDENTITY_REQUEST,
    runManager(franceConnectConfirmationRequest, ValidateIdentityActionsType.FRANCE_CONNECT_CONFIRM_IDENTITY_FAILURE)
  );
}
