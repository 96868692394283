import React from 'react';
import { View } from 'react-native';

import { LoaderPlaceholderView } from '@ere-uilib/atoms';

import { useStyles } from './useStyles';

export const AnnuityStepperLoader = () => {
  const styles = useStyles();

  return (
    <View style={styles.container}>
      <View style={styles.row1}>
        <LoaderPlaceholderView style={styles.bullet} />
        <LoaderPlaceholderView style={styles.title} />
      </View>
      <View style={styles.row2}>
        <LoaderPlaceholderView style={styles.content1} />
        <View style={styles.content2}>
          <LoaderPlaceholderView  />
          <LoaderPlaceholderView style={styles.line2}  />
        </View>
      </View>
      <LoaderPlaceholderView style={styles.action} />
    </View>
  );
};
