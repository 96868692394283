import { useMemo } from 'react'
import { StyleSheet } from 'react-native'

import { useScreenSizes, useTheme } from '@ere-uilib/styles'

export const useStyles = () => {
  const theme = useTheme()
  const { isMobile } = useScreenSizes()
  const styles = useMemo(
    () =>
      StyleSheet.create({
        questionDescription: {
          marginTop: theme.metrics.spacing.s
        },
        wrapperContainer: {
          marginTop: theme.metrics.spacing.xm
        },
        headerAmountStyle: {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          minWidth: '20%'
        },
        headerTitleStyle: {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          maxWidth: '85%'
        },
        unavailableHeaderTitleStyle: {
          marginBottom: theme.metrics.spacing.s
        },
        recapContainer: {
          marginTop: isMobile ? theme.metrics.spacing.xl : undefined
        },
        recapBodyContainer: {
          paddingHorizontal: theme.metrics.spacing.s,
          paddingVertical: theme.metrics.spacing.xm
        },
        recapBodyTitle: {
          marginBottom: theme.metrics.spacing.xm
        },
        buttonContainer: {
          marginVertical: theme.metrics.spacing.xl
        },
        contentWrapper:{
          marginVertical: theme.metrics.spacing.xs
        },
        
        radioResponseWrapper: {
          marginTop: theme.metrics.spacing.xm
        },
        totalAmountContainer: {
          marginVertical: theme.metrics.spacing.s,
          marginLeft: theme.metrics.spacing.s
        },
        eligibilityListContainer: {
          margin: theme.metrics.spacing.s
        },
        oadStartYesContainer: {
          marginBottom: theme.metrics.spacing.s
        },
        radioButtonGroup: {
          marginLeft: theme.metrics.spacing.s
        }
      }),
    [isMobile, theme]
  )

  return styles
}
