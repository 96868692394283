import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { ScrollView, TouchableOpacity, View } from 'react-native';

import {
  CookiesPolicyButton, DashboardPageWrapperConnected, FontIcon, HtmlStyledRenderConnectedContainer, NotificationSoft, OfflinePageLayout, Spinner, SVGLocalLoader, Text, Title, useScreenSizes, useTheme, useTranslation
} from '@components/index';
import { RouteNames } from '@constants/navigation';
import { IconEnum } from '@ere-uilib/enums';
import { createUseStyles } from '@ere-uilib/styles';

import { LegalInfoPageProps } from './interfaces';
import { getStyles } from './styles';

export const LegalInfoPageToConnect: React.FC<LegalInfoPageProps> = ({
  user,
  navigation,
  route,
  onGetLegalContent,
  footerMenu,
  legalContent,
  isGetLegalContentLoading,
  error,
  consents,
  notConnectedContentStyle,
  notConnectedInnerContentStyle,
  connectedContentStyle,
  connectedInnerContentStyle,
  logoStyle,
  titleStyle,
  fullSpaceContainer,
  scrollViewContainerStyle,
  scrollViewStyle,
}) => {
  const { formatMessage } = useTranslation();
  const theme = useTheme();
  const { isMobile, isTablet, isDesktop } = useScreenSizes();
  const { code }: { code: string } = route?.params;
  const isCookiePolicy = code === 'CookiePolicy';

  useEffect(() => {
    onGetLegalContent(code);
  }, [code]);

  // check connexion valid state
  const expiresAt = user?.expires_at || 0;
  const sessionExpireAt = dayjs((expiresAt * 1000)).format();
  const currentTime = dayjs().format();
  const isUserSesssionExpired = expiresAt === 0 || currentTime >= sessionExpireAt;

  const isCGUAccepeted = consents.find(item => item.code === 'CGU')?.isAccepted;

  const isConnexionValid = !(isUserSesssionExpired || !isCGUAccepeted);
  const useStyles = createUseStyles(getStyles);
  const styles = useStyles({
    theme,
    isMobile,
    isTablet,
    isDesktop,
    isConnexionValid,
  }, {
    notConnectedContentStyle,
    notConnectedInnerContentStyle,
    connectedContentStyle,
    connectedInnerContentStyle,
    logoStyle,
    titleStyle,
    fullSpaceContainer,
    scrollViewContainerStyle,
    scrollViewStyle,
  });

  // get data for page code

  const title = footerMenu.find(item => item.url === code)?.label;
  const content = legalContent?.content;

  const canGoBack = navigation?.canGoBack();
  const onClose = () => {
    if (canGoBack) {
      navigation?.goBack();
    } else {
      navigation.replace(RouteNames.BottomTabNavigator, {
        screen: RouteNames.ProfileStack,
        params: {
          screen: RouteNames.Profile,
        },
      });
    }
  };

  const renderContent = () => {
    return (
      <>
        <Title
          style={styles.titleStyle}
          variant="t4"
          weight="bold"
        >
          {title}
        </Title>
        <View style={styles.fullSpaceContainer}>
          {isGetLegalContentLoading && (
            <Spinner color={theme.colors.basics.primary.c500} />
          )}
          {error && error?.innerMessage !== '' && (
            <NotificationSoft
              text={error?.innerMessage}
              type="warning" />
          )}
          {typeof content === 'string' && content !== '' && (
            <ScrollView
              contentContainerStyle={styles.scrollViewContainerStyle}
              style={styles.scrollViewStyle}>
              {isCookiePolicy && (
                <CookiesPolicyButton />
              )}
              <HtmlStyledRenderConnectedContainer
                html={content}
              />
            </ScrollView>
          )}
        </View>
      </>
    );
  };

  return isConnexionValid ? (
    <DashboardPageWrapperConnected>
      <View style={styles.connectedContentStyle}>
        {!isDesktop && (
          <View style={styles.logoStyle}>
            <SVGLocalLoader name="logoHorizontalDark" />
          </View>
        )}
        <View style={styles.connectedInnerContentStyle}>
          <TouchableOpacity
            onPress={onClose}
            style={styles.backButtonStyle}
          >
            <FontIcon
              color={theme?.colors.text.primary.c500}
              name={IconEnum.CHEVRON_LEFT}
              size={theme.metrics.iconSizes.xs}
            />
            <Text style={styles.backButtonTextStyle}>{formatMessage({ id: 'support_back_button' })}</Text>
          </TouchableOpacity>
          {renderContent()}
        </View>
      </View>
    </DashboardPageWrapperConnected>
  ) : (
    <OfflinePageLayout >
      <View style={styles.notConnectedContentStyle}>
        <View style={styles.notConnectedInnerContentStyle}>
          {renderContent()}
        </View>
      </View>
    </OfflinePageLayout>
  );
};
