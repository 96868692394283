import dayjs from 'dayjs'
import utc from "dayjs/plugin/utc";
import { EDocument } from '@modules/document-space/types'
dayjs.extend(utc);

export const getYear = (date: string | Date) => dayjs(date, {utc: true}).year()

export const getYears = (documents: EDocument[]) => {
  const years = documents.map(document => getYear(document.creationDate))
  return Array.from(new Set(years)).sort((a, b) => b - a)
}

export const filterDocumentsByYear = (documents: EDocument[], year: number | undefined) => {
  if (!year && year !== 0) return documents

  return documents
    .filter(document => year === getYear(document.creationDate))
    .sort((a, b) => dayjs(b.creationDate).utc(true).diff(dayjs(a.creationDate).utc(true)))
}
