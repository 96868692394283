import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { View, TouchableOpacity, Platform, Keyboard } from 'react-native';

import { FontIcon, Title } from '@ere-uilib/atoms';
import { useTheme, createUseStyles, useScreenSizes } from '@ere-uilib/styles';

import { ModalFrameProps } from './interface';
import { getStyles } from './styles';

export const ModalFrame: React.FC<PropsWithChildren<ModalFrameProps>> = ({
  hideCloseButton,
  title,
  onClose,
  mobilePosition = 'bottom',
  desktopPosition = 'center',
  maxDesktopWidth,
  children,
  modalFrame,
  titleContainer,
  titleText,
  titleCloseContainer,
  innerContainer,
  closeContainer,
  moveUpContentWithKeyboard,
  onLayout,
  renderHeader
}) => {
  const theme = useTheme();
  const { isMobile } = useScreenSizes();
  const [paddingBottom, setPaddingBottom] = useState(theme.metrics.spacing.s);
  const platformOsIsWeb = Platform.OS === 'web';
  const styles = useStyles({
    theme,
    isMobile,
    mobilePosition,
    maxDesktopWidth,
    desktopPosition,
    platformOsIsWeb,
  }, {
    modalFrame,
    titleContainer,
    titleText,
    titleCloseContainer,
    innerContainer,
    closeContainer
  });

  const handleKeyboardDidShow = useCallback(e => {
    setPaddingBottom(e.endCoordinates.height + theme.metrics.spacing.s);
  }, [theme]);

  const handleKeyboardDidHide = useCallback(() => {
    setPaddingBottom(theme.metrics.spacing.s);
  }, [theme]);

  useEffect(() => {
    if (!moveUpContentWithKeyboard) {
      return;
    }

    Keyboard.addListener('keyboardDidShow', handleKeyboardDidShow);
    Keyboard.addListener('keyboardDidHide', handleKeyboardDidHide);
  }, [handleKeyboardDidHide, handleKeyboardDidShow, moveUpContentWithKeyboard])

  const handleLayout = useCallback(e => {
    onLayout?.(e)
  }, [onLayout])

  const renderCloseButton = useCallback(() => {
    if (!!title || !onClose || hideCloseButton) return
    return (
      <TouchableOpacity
        onPress={onClose}
        style={styles.closeContainer}>
        <FontIcon
          color={theme.colors.text.primary.c500}
          name="fermer"
          size={theme.metrics.iconSizes.xxm}
        />
      </TouchableOpacity>
    )
  }, [onClose, title, hideCloseButton])

  return (
    <View
      onLayout={handleLayout}
      style={[styles.modalFrame, { paddingBottom }]}>
      {!!title && (
        <View style={styles.titleContainer}>
          <Title
            style={styles.titleText}
            variant="t7">
            {title}
          </Title>
          {onClose && !hideCloseButton && (
            <TouchableOpacity
              onPress={onClose}
              style={styles.titleCloseContainer}>
              <FontIcon
                color={theme.colors.text.primary.c500}
                name="fermer"
                size={theme.metrics.iconSizes.xxm}
              />
            </TouchableOpacity>
          )}

        </View>
      )}
      {renderHeader?.()}
      <View style={styles.innerContainer}>
        {children}
      </View>
      {!title && onClose && !hideCloseButton && (
        <TouchableOpacity
          onPress={onClose}
          style={styles.closeContainer}>
          <FontIcon
            color={theme.colors.text.primary.c500}
            name="fermer"
            size={theme.metrics.iconSizes.xxm}
          />
        </TouchableOpacity>
      )}
    </View>
  );
};

const useStyles = createUseStyles(getStyles);
