import React from 'react';
import {
  View,
  StyleProp,
  ViewStyle,
  TextStyle,
} from 'react-native';

import {
  useTheme,
  Modal,
  ModalFrame,
  SimpleButton,
  SVGLocalLoader,
  Text,
} from '@components/index';

import { ThemeType, createUseStyles} from '@ere-uilib/styles';

type Style = {
  containerStyle?: StyleProp<ViewStyle>;
  contentContainerStyle?: StyleProp<ViewStyle>;
  textContainerStyle?: StyleProp<ViewStyle>;
  iconSuccessStyle?: StyleProp<ViewStyle>;
  paragraphChangePsswrdStyle?: StyleProp<TextStyle>;
  subTitle?: StyleProp<TextStyle>;
};

type Context = {
  theme: ThemeType;
};

interface Props {
  visible?: boolean;
  onPressButton: () => void;
  iconName: string;
  title: string;
  subTitle: string;
  buttonTitle: string;
}

export const ModalSuccess =
({ visible, onPressButton, iconName, title, subTitle, buttonTitle }: Props) => {
  const theme = useTheme();
  const styles = useStyles(
    { theme },
    {}
  );

  return (
    <Modal visible={visible}>
      <ModalFrame
        desktopPosition="center"
        mobilePosition="bottom"
      >
        <View>
          <View style={styles.iconSuccessStyle}>
            <SVGLocalLoader
              name={iconName}
            />
          </View>
          <View style={styles.textContainerStyle}>
            <Text
              variant="t2"
            >
              {title}
            </Text>

            <Text
              style={styles.subTitle}
              variant="t2"
              weight="light"
            >
              {subTitle}
            </Text>
          </View>
          <SimpleButton
            onPress={onPressButton}
            title={buttonTitle}
          />
        </View>
      </ModalFrame>
    </Modal>
  );
};

const getStyles = (
  context?: Context,
  style?: Style,
): Style => ({
  iconSuccessStyle: [
    {
      width: 241,
      height: 140,
      alignSelf: 'center',
    },
    style?.iconSuccessStyle,
  ],
  textContainerStyle: [
    {
      alignItems: 'center',
      marginTop: context?.theme.metrics.spacing.l,
      marginBottom: context?.theme.metrics.spacing.l,
    },
    style?.textContainerStyle,
  ],
  subTitle: [
    { marginTop: 10 },
    style?.subTitle,
  ],
});

const useStyles = createUseStyles(getStyles);
