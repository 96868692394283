import React, { useCallback } from 'react'
import { View } from 'react-native'

import {
  ChoiceButton,
  ColumnsContainer,
  DashboardPageWrapperConnected,
  LoaderPlaceholderView,
  NavigationHeader,
  RetryActionPlaceHolder,
  SVGLocalLoader,
  Title
} from '@components/index'

import { LandingPageWithCardsProps } from './interfaces'
import { useStyles } from './styles'

export const LandingPageWithCards = <T, >({
  data,
  isLoading,
  error,
  onSelectCard,
  onRetry,
  onClose
}: LandingPageWithCardsProps<T>) => {
  const styles = useStyles()

  const renderHeader = useCallback(() => {
    return (
      <NavigationHeader
        displayCloseButton
        helpPoint
        helpPointContentHtml={data.header.help.content}
        helpPointModalTitle={data.header.help.title}
        helpPointPosition="left"
        onClose={onClose}
        title={data.header.title}
      />
    )
  }, [data, onClose])

  const renderLoader = useCallback(() => {
    if (!isLoading) return null

    return [...Array(2)].map((block, i) => (
      <View
        key={`switching-page-skeleton-${i}`}
        style={styles.loaderContainerStyle}>
        {[...Array(3)].map((line, j) => (
          <LoaderPlaceholderView
            key={`switching-page-skeleton-${j}`}
            style={styles.loader}
          />
        ))}
      </View>
    ))
  }, [isLoading, styles])

  const renderError = useCallback(() => {
    if (isLoading || !error?.message) return null

    return <RetryActionPlaceHolder retryAction={onRetry} />
  }, [error, isLoading, onRetry])

  const renderContent = useCallback(() => {
    if (error?.message || isLoading) return null

    return data.content.cards.map((card, index) => {
      const handleCardItemSelect = () => onSelectCard?.(card.type)

      return (
        <View
          key={`switching-page-card-${index}`}
          style={styles.button}>
          <ChoiceButton
            description={card.description}
            onSelect={handleCardItemSelect}
            subDescription={card.footer}
            title={card.title}
          />
        </View>
      )
    })
  }, [data, error, isLoading, onSelectCard, styles])

  return (
    <DashboardPageWrapperConnected cardContentStyle={styles.pageWrapper}>
      <ColumnsContainer
        hasColumnCenter
        hasRenderRightDesktopColumn={false}
        renderHeader={renderHeader}>
        <View style={styles.contentContainer}>
          <View style={styles.svgContainer}>
            <SVGLocalLoader name={data.content.iconName} />
          </View>
          <Title
            style={styles.contentTitle}
            variant="t6">
            {data.content.title}
          </Title>
          {renderLoader()}
          {renderError()}
          {renderContent()}
        </View>
      </ColumnsContainer>
    </DashboardPageWrapperConnected>
  )
}
