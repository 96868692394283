
import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useTheme, useScreenSizes } from '@components/index';

export const useStyles = () => {
  const theme = useTheme();
  const { isMobile } = useScreenSizes()

  const styles = useMemo(() => StyleSheet.create({
    containerStyle: {
      flex: 1,
      alignItems: 'center',
      backgroundColor: theme.colors.background.poppinFilter.c500,
      ...(isMobile ? {
        justifyContent: 'flex-end'
      }
        : {
          justifyContent: 'center'
        })
    },
    modalContainer: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    titleStyle: {
      marginTop: theme.metrics.spacing.xm,
      marginBottom: theme.metrics.spacing.s,
    },
    buttonContainerStyle: {
      alignSelf: 'stretch'
    },
    buttonStyle: {
      marginBottom: theme.metrics.spacing.s
    },
    lastButtonStyle: {
      marginBottom: 0
    },
    modalDescriptionText: {
      flexWrap: 'wrap',
      flexDirection: 'row',
      alignItems: 'center',
      textAlign: 'center',
      marginBottom: theme.metrics.spacing.s
    },
  }), [theme])
  return styles
};
