import { connect } from 'react-redux'

import { AppState } from '@modules/reducers'
import {
  refundsBackToQuestionRequest,
  setRefundsSelectedDispositif,
  updateRefundsQuestionWithResponseRequest,
  setRefundsEligibleDispositifs
} from '@modules/refund/actions/refundActions'
import { getRefundsDecision } from '@modules/refund/selectors'
import {
  getUnavailableRefundDispositifsRequest,
  updateRefundUnavailableQuestionsRequest
} from '@modules/refund/unavailable-refund/actions/refundActions'
import { isUnavailableRefundDispositifTableLoading } from '@modules/refund/unavailable-refund/selectors'

import { RefundsRequirementDefinitonComponent } from './RefundsRequirementDefinitonPage.component'

const mapStateToProps = (state: AppState) => ({
  requirementDecisionData: getRefundsDecision(state),
  unavailableRefundDispositifTableLoading: isUnavailableRefundDispositifTableLoading(state)
})

const mapDispatchToProps = {
  onSelectDispositif: setRefundsSelectedDispositif,
  setEligibleDispositifs: setRefundsEligibleDispositifs,
  onUpdateQuestion: updateRefundsQuestionWithResponseRequest,
  onUpdateUnavailableQuestion: updateRefundUnavailableQuestionsRequest,
  onGetUnavailableRefundDispositifTable: getUnavailableRefundDispositifsRequest,
  onBackToQuestion: refundsBackToQuestionRequest
}

export const RefundsRequirementDefinitonPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(RefundsRequirementDefinitonComponent)
