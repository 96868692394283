import React from 'react'
import ContentLoader from 'react-content-loader/native'
import { ViewStyle, StyleProp } from 'react-native'

import { useTheme } from '@ere-uilib/styles'

interface ContentLoaderProps {
  children: React.ReactNode
  height?: number | string
  width?: number | string
  speed?: number
  backgroundColor?: string
  foregroundColor?: string
  style?: StyleProp<ViewStyle>
}

export const LocalContentLoader:React.FC<React.PropsWithChildren<ContentLoaderProps>> =({
  children,
  height,
  width,
  speed=2,
  backgroundColor,
  foregroundColor,
  style
})=>{

  const theme = useTheme()

  return(
    <ContentLoader
      backgroundColor={backgroundColor ? backgroundColor : theme.colors.basics.grey.c200}
      foregroundColor={foregroundColor ? foregroundColor : theme.colors.basics.white}
      height={height}
      speed={speed}
      style={style}
      width={width}
    >
      {children}
    </ContentLoader>
  )
}