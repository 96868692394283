import { connect } from 'react-redux';

import { AppState } from '@modules/index';
import { getInvitationSelector } from '@modules/invitation/selectors';

import { ValidateSignupThankYouPageComponent } from './ValidateSignupThankYouPage.component';

const mapStateToProps = (state: AppState) => {
  return {
    invitation: getInvitationSelector(state)
  };
};

export const ValidateSignupThankYouPage = connect(
  mapStateToProps,
  null,
)(ValidateSignupThankYouPageComponent);
