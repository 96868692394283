import React from 'react';
import { StyleProp, TextStyle } from 'react-native';

import { FontIcon, IconEnum, Title } from '@components/index';
import { useTheme } from '@ere-uilib/styles';

import { useStyles } from './useStyles';

interface Props {
  title: string
  onPress?(): void
  style?: StyleProp<TextStyle>
}

export const BackButton: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  onPress,
  style,
}) => {
  const theme = useTheme();
  const styles = useStyles();

  return (
    <Title
      onPress={onPress}
      style={style}
      variant="t7"
    >
      <FontIcon
        name={IconEnum.CHEVRON_LEFT}
        size={theme.metrics.iconSizes.xs}
        style={styles.iconBack}
      />
      {title}
    </Title>
  );
};

