import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';

import { RouteNames } from '@constants/navigation';
import { AnnuityNavigatorInterface } from '@navigation/Interfaces';
import { useDefaultScreensOptions } from '@navigation/useDefaultScreensOptions';
import { AnnuityHomePage, AnnuitySimulator } from '@pages/Annuity';

const AnnuityStack = createStackNavigator<AnnuityNavigatorInterface>();

export const AnnuitySimulatorStackNavigator = () => {
  const defaultScreensOptions = useDefaultScreensOptions();

  return (
    <AnnuityStack.Navigator
      initialRouteName={RouteNames.AnnuityHomePage}
      screenOptions={{
        gestureEnabled: false,
        headerShown: false,
      }}
    >
      <AnnuityStack.Screen
        component={AnnuityHomePage}
        name={RouteNames.AnnuityHomePage}
        options={defaultScreensOptions.Annuity}
      />
      <AnnuityStack.Screen
        component={AnnuitySimulator}
        name={RouteNames.AnnuitySimulator}
        options={defaultScreensOptions.Annuity}
      />
    </AnnuityStack.Navigator>
  );
}