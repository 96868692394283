import React, { useState, useCallback, useEffect } from 'react';
import { View } from 'react-native';

import {
  FormValidationCondition,
  TextField,
} from '@ere-uilib/molecules';
import { createUseStyles, useScreenSizes, useTheme } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';
import { usePhoneNumberUtils } from '@ere-uilib/utils';

import { PhoneFieldCheckProps } from './interface';
import { getStyles } from './styles';

export const PhoneFieldCheck: React.FC<React.PropsWithChildren<PhoneFieldCheckProps>> = ({
  style,
  initialValue,
  onChange,
  placeholder,
  onFieldSubmit,
  isFieldSubmitDisabled = false,
  isDisabled = false,
  testId
}) => {
  const { formatMessage } = useTranslation();
  const theme = useTheme();
  const { isMobile, isTablet, windowWidth } = useScreenSizes();
  const { getNumberInfos } = usePhoneNumberUtils();

  const [inputPhoneValue, setInputPhoneValue] = useState(initialValue || '');

  const [country, setCountry] = useState<string>();
  const [formatedPhoneNumber, setFormatedPhoneNumber] = useState<string>();
  const [isNumberValid, setIsNumberValid] = useState<boolean>(false);
  const hasCountry = !!country;

  const styles = useStyles({ isMobile, isTablet, theme, windowWidth }, {});

  useEffect(() => {
    testAndUpdatePhoneNumber(initialValue || '');
  }, []);

  const handlePhoneSubmit = useCallback(() => {
    !isFieldSubmitDisabled && onFieldSubmit?.();
  }, [
    isFieldSubmitDisabled,
    onFieldSubmit,
  ]);

  const testAndUpdatePhoneNumber = useCallback((phoneValue: string) => {
    const phoneNumberInfos = getNumberInfos(phoneValue);
    const newFieldValue =
      phoneNumberInfos.isValid && phoneNumberInfos.parsedPhoneNumber ?
        phoneNumberInfos.parsedPhoneNumber
        : phoneValue;

    setCountry(phoneNumberInfos.country);
    setIsNumberValid(!!phoneNumberInfos.isValid);
    setFormatedPhoneNumber(phoneNumberInfos.formatedPhoneNumber);
    setInputPhoneValue(newFieldValue);

    onChange?.({
      isValid: !!phoneNumberInfos.isValid,
      formatedInternationalPhoneNumber: phoneNumberInfos.formatedPhoneNumber,
      internationalPhoneNumber: phoneNumberInfos.parsedPhoneNumber,
    });
  }, [
    setCountry,
    setIsNumberValid,
    setFormatedPhoneNumber,
    setInputPhoneValue,
    onChange,
    getNumberInfos,
  ]);

  const handleOnPhoneChange = useCallback((phoneValue: string) => {
    if (inputPhoneValue !== phoneValue) {
      testAndUpdatePhoneNumber(phoneValue);
    }
  }, [
    testAndUpdatePhoneNumber,
    inputPhoneValue,
  ]);

  const getPhoneConditions = useCallback(() => {
    const hasCountryConditionText = hasCountry ?
      formatMessage({ id: 'EerSaisirMobileStatutPaysValide' }) + country
      : formatMessage({ id: 'EerSaisirMobileStatutPaysInvalide' });

    const isNumberValidConditionText = isNumberValid ?
      formatMessage({ id: 'EerSaisirMobileStatutNumeroValide' }) + formatedPhoneNumber
      : formatMessage({ id: 'EerSaisirMobileStatutNumeroInvalide' });

    return [
      {
        state: hasCountry,
        label: hasCountryConditionText,
      },
      {
        state: isNumberValid,
        label: isNumberValidConditionText,
      },
    ];
  }, [
    hasCountry,
    country,
    isNumberValid,
    formatedPhoneNumber,
    formatMessage,
  ]);

  const phoneConditions = getPhoneConditions();

  return (
    <View style={style}>
      <View style={styles.textFieldContainerStyle}>
        <TextField
          containerStyle={styles.input}
          inputProps={{
            placeholder: placeholder,
            value: inputPhoneValue,
            onChangeText: handleOnPhoneChange,
          }}
          isDisabled={isDisabled}
          labelOnTop
          onSubmit={handlePhoneSubmit}
          testId={testId}
        />
      </View>
      {phoneConditions.map(condition => {
        return <FormValidationCondition condition={condition} />;
      })}
    </View>
  );
};

const useStyles = createUseStyles(getStyles);
