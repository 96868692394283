import React from 'react';
import { View, Image } from 'react-native';

import { Title, Text } from '@ere-uilib/atoms';
import { SimpleButton } from '@ere-uilib/molecules';
import { createUseStyles, useTheme, useScreenSizes } from '@ere-uilib/styles';
import { labelHtmlCleaner } from '@ere-uilib/utils';

import { ToolCardProps } from './interface';
import { getStyles } from './styles';

export const ToolCard = ({
  title,
  content,
  buttonText,
  pictureUrl,
  containerStyle,
  horizontal,
  onPress,
  testId
}: ToolCardProps) => {
  const theme = useTheme();
  const { isMobile, isTablet } = useScreenSizes();

  const styles = useStyles(
    { theme, isMobile, isTablet, horizontal },
    { containerStyle },
  );

  return (
    <View
      style={styles.containerStyle}>
      <Image
        source={{ uri: pictureUrl }}
        style={styles.imageStyle} />
      <View style={styles.descriptionContainerStyle}>
        <Title
          style={styles.titleStyle}
          variant={'t6'}
          weight="bold">
          {title}
        </Title>
        <Text
          style={styles.hookTextStyle}
          variant="t3"
          weight="light">
          {!!content && labelHtmlCleaner(content)}
        </Text>
        <SimpleButton
          containerStyle={styles.buttonStyle}
          design="outlined"
          onPress={onPress}
          testId={testId}
          title={buttonText}
        />
      </View>
    </View>
  );
};

const useStyles = createUseStyles(getStyles);
