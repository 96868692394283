import React from 'react';
import { useScreenSizes, useTheme } from '@ere-uilib/styles';
import { Text, usePhoneNumberUtils } from '@components/index';
import { useStyles } from './useStyles';
import { Linking, Pressable, View } from 'react-native';

interface Props {
  phoneNumber: string,
  description : string,
}

export const PhoneContactComponent: React.FC<Props> = ({
  phoneNumber,
  description
}) => {
  const { getNumberInfos } = usePhoneNumberUtils();
  const number  = getNumberInfos(phoneNumber).formatedPhoneNumber
  const styles = useStyles();
  const { isMobile } = useScreenSizes();
  return (
    <Pressable
    onPress={ ()=> Linking.openURL(`tel:${phoneNumber}`) }
    style={styles.container}
    disabled = {!isMobile}
    >
      <View style={styles.triangle}/>
        <View style={styles.leftContainer}>
          <Text style={styles.phoneText}>{number}</Text>
        </View>
         <View style={styles.rightContainer}>
            <Text weight='light' style={styles.descriptionContainer}>
              {description}
            </Text>
         </View>
    </Pressable>
  );
};
