import {
  ControlDataErrorState,
  FetchControlDataState,
  FetchControlDataEditingOptionState, ControlDataManagement, EditedControlDataType,
} from "../types";

export enum ControlDataActionsType {
  GET_CONTROL_DATA_REQUEST = 'GET_CONTROL_DATA_REQUEST',
  GET_CONTROL_DATA_SUCCESS = 'GET_CONTROL_DATA_SUCCESS',
  GET_CONTROL_DATA_FAILURE = 'GET_CONTROL_DATA_FAILURE',
  GET_CONTROL_DATA_INIT_ERROR = 'GET_CONTROL_DATA_INIT_ERROR',

  GET_CONTROL_DATA_EDITING_OPTIONS_REQUEST = 'GET_CONTROL_DATA_EDITING_OPTIONS_REQUEST',
  GET_CONTROL_DATA_EDITING_OPTIONS_SUCCESS = 'GET_CONTROL_DATA_EDITING_OPTIONS_SUCCESS',
  GET_CONTROL_DATA_EDITING_OPTIONS_FAILURE = 'GET_CONTROL_DATA_EDITING_OPTIONS_FAILURE',

  REHYDRATE_CONTROL_DATA = 'REHYDRATE_CONTROL_DATA',

  POST_CONTROL_DATA_EDITING_OPTIONS_REQUEST = "POST_CONTROL_DATA_EDITING_OPTIONS_REQUEST",
  POST_CONTROL_DATA_EDITING_OPTIONS_SUCCESS = "POST_CONTROL_DATA_EDITING_OPTIONS_SUCCESS",
  POST_CONTROL_DATA_EDITING_OPTIONS_FAILURE = "POST_CONTROL_DATA_EDITING_OPTIONS_FAILURE",
  CLEAR_DRIVING_DATA = "CLEAR_DRIVING_DATA"
}

export interface GetRehydrateControlDataAction {
  type: ControlDataActionsType.REHYDRATE_CONTROL_DATA;
  planId: number;
  management: ControlDataManagement;
}

// GET control data by devices
export interface GetControlDataRequestAction {
  type: ControlDataActionsType.GET_CONTROL_DATA_REQUEST;
}

export interface GetControlDataSuccessAction {
  type: ControlDataActionsType.GET_CONTROL_DATA_SUCCESS;
  data: FetchControlDataState[];
}

export interface GetControlDataFailureAction {
  type: ControlDataActionsType.GET_CONTROL_DATA_FAILURE;
  error: ControlDataErrorState;
}

export interface GetControlDataInitErrorAction {
  type: ControlDataActionsType.GET_CONTROL_DATA_INIT_ERROR;
  error: ControlDataErrorState;
}

// Update riskProfiles data
export interface GetControlDataEditingOptionsRequestAction {
  type: ControlDataActionsType.GET_CONTROL_DATA_EDITING_OPTIONS_REQUEST;
  planId: number
}
export interface GetControlDataEditingOptionsSuccessAction {
  type: ControlDataActionsType.GET_CONTROL_DATA_EDITING_OPTIONS_SUCCESS;
  editingOptions: FetchControlDataEditingOptionState;
}

export interface GetControlDataEditingOptionsFailureAction {
  type: ControlDataActionsType.GET_CONTROL_DATA_EDITING_OPTIONS_FAILURE;
  error: ControlDataErrorState;
}

export interface PostControlDataEditingOptionsRequestAction {
  type: ControlDataActionsType.POST_CONTROL_DATA_EDITING_OPTIONS_REQUEST;
  planId: number
  editedDrivingData: EditedControlDataType
}

export interface PostControlDataEditingOptionsSuccessAction {
  type: ControlDataActionsType.POST_CONTROL_DATA_EDITING_OPTIONS_SUCCESS;
  isEditedDrivingDataSuccess: boolean
}

export interface PostControlDataEditingOptionsFailureAction {
  type: ControlDataActionsType.POST_CONTROL_DATA_EDITING_OPTIONS_FAILURE;
  error: ControlDataErrorState;
}
export interface ClearDrivingDataAction {
  type: ControlDataActionsType.CLEAR_DRIVING_DATA
}
export type ControlDataAction =
  | GetRehydrateControlDataAction
  | GetControlDataRequestAction
  | GetControlDataSuccessAction
  | GetControlDataFailureAction
  | GetControlDataInitErrorAction
  | GetControlDataEditingOptionsRequestAction
  | GetControlDataEditingOptionsSuccessAction
  | GetControlDataEditingOptionsFailureAction
  | PostControlDataEditingOptionsRequestAction
  | PostControlDataEditingOptionsSuccessAction
  | PostControlDataEditingOptionsFailureAction
  | ClearDrivingDataAction
