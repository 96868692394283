import {
  LegalContentState,
  LegalErrorState,
  MenuFooterState
} from '@modules/legal/types';

import { InitAllErrorsAction } from '@modules/error/actions/errorActionsTypes';

export enum LegalActionsType {
  GET_MENU_FOOTER_REQUEST = 'GET_MENU_FOOTER_REQUEST',
  GET_MENU_FOOTER_SUCCESS = 'GET_MENU_FOOTER_SUCCESS',
  GET_MENU_FOOTER_FAILURE = 'GET_MENU_FOOTER_FAILURE',
  GET_LEGAL_CONTENT_REQUEST = 'GET_LEGAL_CONTENT_REQUEST',
  GET_LEGAL_CONTENT_SUCCESS = 'GET_LEGAL_CONTENT_SUCCESS',
  GET_LEGAL_CONTENT_FAILURE = 'GET_LEGAL_CONTENT_FAILURE',
  SET_CHANGED_LANGUAGE = 'SET_CHANGED_LANGUAGE',
  LEGAL_INIT_ERROR = 'LEGAL_INIT_ERROR'
}

export interface GetMenuFooterRequestAction {
  type: LegalActionsType.GET_MENU_FOOTER_REQUEST;
}

export interface GetMenuFooterSuccessAction {
  type: LegalActionsType.GET_MENU_FOOTER_SUCCESS;
  links: MenuFooterState[];
}

export interface GetMenuFooterFailureAction {
  type: LegalActionsType.GET_MENU_FOOTER_FAILURE;
  error: LegalErrorState;
}

export interface GetLegalContentRequestAction {
  type: LegalActionsType.GET_LEGAL_CONTENT_REQUEST;
  code: string;
}

export interface GetLegalContentSuccessAction {
  type: LegalActionsType.GET_LEGAL_CONTENT_SUCCESS;
  content: LegalContentState;
}

export interface GetLegalContentFailureAction {
  type: LegalActionsType.GET_LEGAL_CONTENT_FAILURE;
  error: LegalErrorState;
}

export interface LegalInitErrorAction {
  type: LegalActionsType.LEGAL_INIT_ERROR;
}

export type LegalAction =
  | GetLegalContentRequestAction
  | GetLegalContentSuccessAction
  | GetLegalContentFailureAction
  | GetMenuFooterSuccessAction
  | GetMenuFooterRequestAction
  | GetMenuFooterFailureAction
  | InitAllErrorsAction
  | LegalInitErrorAction;
