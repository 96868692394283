import { useNavigation } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { View, StyleProp, ViewStyle, TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';

import {
  DashboardPageWrapperConnected,
  Title,
} from '@components/index';
import { locator } from '@constants/locator';
import { RouteNames } from '@constants/navigation';
import {
  createUseStyles,
  useTheme,
  ThemeType,
  useScreenSizes,
} from '@ere-uilib/styles';
import { getDecodedUserTokenId, getUserInfo } from '@modules/auth/selectors';
import { DecodedTokenState, UserInfoState } from '@modules/auth/types';
import { getSelectedCompany } from '@modules/dashboard/selectors';
import { AccountState } from '@modules/dashboard/types';
import { getProfileMenuRequest } from '@modules/profile/actions/profileActions';
import {
  GetProfileMenuRequestAction,
  ProfileActionsType
} from '@modules/profile/actions/profileActionsTypes';
import { getProfileMenu } from '@modules/profile/selectors';
import { ProfileMenuState } from '@modules/profile/types';
import { AppState } from '@modules/reducers';

import ProfileCompany from './ProfileCompany';
import ProfileHeader from './ProfileHeader';
import { ProfileMenu } from './ProfileMenu';

const mapStateToProps = (state: AppState) => {
  return {
    company: getSelectedCompany(state),
    profileMenu: getProfileMenu(state),
    isProfileLoading: state.isLoading[ProfileActionsType.GET_PROFILE_MENU_REQUEST],
    decodedToken: getDecodedUserTokenId(state),
    userInfo: getUserInfo(state),
  };
};

const mapDispatchToProps = {
  onGetProfileMenu: getProfileMenuRequest,
};

interface Props {
  company?: AccountState | undefined
  onGetProfileMenu: () => GetProfileMenuRequestAction
  profileMenu: ProfileMenuState
  containerStyle?: StyleProp<ViewStyle>;
  decodedToken?: DecodedTokenState;
  isProfileLoading?: boolean;
  userInfo: UserInfoState
}

type ProfilePageStyles = {
  containerStyle?: StyleProp<ViewStyle>;
};

type StylesContext = {
  theme: ThemeType
  isMobile: boolean
  isTablet: boolean
  windowHeight: number
};

const ProfilePageComponent: React.FC<React.PropsWithChildren<Props>> = ({
  company,
  onGetProfileMenu,
  profileMenu,
  containerStyle,
  decodedToken,
  isProfileLoading,
  userInfo
}) => {
  const theme = useTheme();
  const { isMobile, isTablet, windowHeight } = useScreenSizes();
  const styles = useStyles({ theme, isMobile, isTablet, windowHeight }, { containerStyle });
  const navigation = useNavigation();

  useEffect(() => {
    onGetProfileMenu();
  }, []);

  const firstName = decodedToken?.given_name ?? userInfo.givenName;
  const lastName = decodedToken?.family_name ?? userInfo.familyName;
  const username = `${firstName} ${lastName}`;
  const lastConnectionDateApiKey = 'https://login.ere.bnpparibas/claims/lastconnection'
  const lastConnectionDate = decodedToken?.[lastConnectionDateApiKey]
  ?? userInfo[lastConnectionDateApiKey];

  return (
    <DashboardPageWrapperConnected>
      <View
        accessibilityLabel={locator._profile._title}
        style={styles.containerStyle}
        testID={locator._profile._title}>
        <ProfileHeader
          lastConnectionDate={lastConnectionDate}
          onLogoutPressed={() => navigation.navigate(RouteNames.Logout)}
          username={username}
        />
        <ProfileCompany
          company={company}
          isLoading={isProfileLoading} />
        <ProfileMenu
          isLoading={isProfileLoading}
          menus={profileMenu?.menus}
        />
      </View>
    </DashboardPageWrapperConnected>
  );
};


const getStyles = (
  context?: StylesContext,
  style?: ProfilePageStyles,
): ProfilePageStyles => ({
  containerStyle: [
    {
      flex: 1,
      backgroundColor: 'white',
    },
    (context?.isMobile || context?.isTablet) && {
      paddingBottom: context.theme.metrics.spacing.xhuge,
    },
    style?.containerStyle,
  ],
});

const useStyles = createUseStyles(getStyles);

export const ProfilePage = connect(mapStateToProps, mapDispatchToProps)(ProfilePageComponent);
