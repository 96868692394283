import React from 'react';

import { useTranslation } from '@ere-uilib/translations';
import { AllDispositifs } from '@modules/arbitration/types';
import { ArbitrationTypes } from '@constants/arbitrations';

export const useDataLabels = (dispositifs: AllDispositifs) => {
  const { formatMessage } = useTranslation();

  const labelIdPrefix = 'Arbitrage_optimiserEpargne_';
  const stockDispositifNames = dispositifs.stockDispositifs.map(dispositif => dispositif.name).join(', ')
  const flowDispositifNames = dispositifs.flowDispositifs.map(dispositif => dispositif.name).join(', ')
  return {
    header: {
      help: {
        title:formatMessage({ id: 'Remboursement_pageErreur_helpTitle' }),
        content: formatMessage({ id: 'Remboursement_pageErreur_helpContent' })
      },
      title: formatMessage({ id: `${labelIdPrefix}title` }),
    },
    content: {
      title: formatMessage({ id: `${labelIdPrefix}SubTitle` }),
      iconName: 'savingsJar',
      cards: [
        {
          type: ArbitrationTypes.stock,
          title: formatMessage({ id: `${labelIdPrefix}actuel_title` }),
          description: formatMessage({ id: `${labelIdPrefix}actuel_contenu` }),
          footer: `${formatMessage({
            id: 'multientreprise_choice_list_label',
          })} ${stockDispositifNames}`,
        },
        {
          type: ArbitrationTypes.flow,
          title: formatMessage({ id: `${labelIdPrefix}aVenir_title` }),
          description: formatMessage({
            id: `${labelIdPrefix}aVenir_contenu`,
          }),
          footer: `${formatMessage({
            id: 'multientreprise_choice_list_label',
          })} ${flowDispositifNames}`,
        },
      ],
    },
  };
};
