import { getSelectedCompany } from '@modules/dashboard/selectors';
import { getContactInfosRequest } from '@modules/profile/actions/profileActions';
import { getContactEmailInfos, getContactIsloading, getContactPhoneInfos } from '@modules/profile/selectors';
import { emailInfosType, phoneInfosType } from '@modules/profile/types';
import { createContext, FC, PropsWithChildren, useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface HelpContactPageContextType {
  onContactInfos: () => void,
  phoneData: phoneInfosType
  emailData: emailInfosType
  isLoading: boolean
  isES?: boolean
  isRC?: boolean
}

export const HelpContactPageContext = createContext<HelpContactPageContextType | null>(
  null
);

export const useHelpContactContext = (): HelpContactPageContextType => {
  const context = useContext(HelpContactPageContext);
  if (!context) {
    throw new Error('useHelpContactContext must be used within HelpContactPageProvider');
  }
  return context;
};

const HelpContactPageProvider: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch();
  const phoneData = useSelector(getContactPhoneInfos);
  const emailData = useSelector(getContactEmailInfos);
  const isLoading = useSelector(getContactIsloading);
  const isES = useSelector(getSelectedCompany)?.isES;
  const isRC = useSelector(getSelectedCompany)?.isRC

    ;
  const onContactInfos = useCallback(() => dispatch(getContactInfosRequest()), []);
  const value: HelpContactPageContextType = {
    onContactInfos,
    phoneData,
    emailData,
    isLoading,
    isES,
    isRC
  };

  return (
    <HelpContactPageContext.Provider value={value}>
      {children}
    </HelpContactPageContext.Provider>
  );
};

export default HelpContactPageProvider;
