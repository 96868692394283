import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

export const useStyles = ({
  LIST_ITEM_HEIGHT
} : {
  LIST_ITEM_HEIGHT: number
}) => {
  const theme = useTheme();
  
  const styles = useMemo(() => StyleSheet.create({
    container: {
      flexDirection: "row",
      alignItems: "center",
      paddingVertical: theme.metrics.spacing.s,
      paddingHorizontal: theme.metrics.spacing.m,
      borderTopWidth: 1,
      borderColor: theme.colors.borders.fields.c200,
      height: LIST_ITEM_HEIGHT
    },
    leftIcon: {
      marginRight: theme.metrics.spacing.xm,
    },
    chevronIcon: {
      fontSize: theme.metrics.iconSizes.s,
    },
    leftPartContainer: {
      flexDirection: "row",
      alignItems: "center",
      flex: 1,
    }
  }), [theme, LIST_ITEM_HEIGHT]);

  return styles;
};