import { StackNavigationProp } from '@react-navigation/stack';
import React, { useMemo, useState } from 'react';
import { View, StyleProp, ViewStyle, TextStyle } from 'react-native';

import {
  Title,
  SimpleButton,
  Text,
  OfflinePageLayout,
  useTheme,
  useScreenSizes,
  ThemeType,
  GlobalSavingsAmount,
  LegalMenuFooterConnected,
  WorkInProgressContent,
  Modal,
  ModalFrame,
  ActivableButtonSet,
  GenericCardWidthThreeParams,
} from '@components/index';
import { locator } from '@constants/locator';
import { createUseStyles } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';
import { getPlansNameByCompany } from '@hooks/useGetCompanyPlansName';
import { AccountsState, AccountState } from '@modules/dashboard/types';
import { getActiveCompanies } from '@modules/dashboard/utils';
import { AppNavigatorInterface } from '@navigation/Interfaces';

interface Props {
  navigation: StackNavigationProp<AppNavigatorInterface>;
  userAccount?: AccountsState
  onSelectCompany: (company: AccountState, navigation: StackNavigationProp<AppNavigatorInterface>) => void
  EnableFeatureRegroupementContrats?: boolean
  selectionTextStyle?: StyleProp<TextStyle>;
  cardContentStyle?: StyleProp<ViewStyle>;
  contentStyle?: StyleProp<ViewStyle>;
  buttonTitleStyle?: StyleProp<TextStyle>;
  buttonSubtitleStyle?: StyleProp<TextStyle>;
}

type CompanyChoicePageStyles = {
  WalkthroughContainerStyle?: StyleProp<ViewStyle>;
  WalkthroughCardContentStyle?: StyleProp<ViewStyle>;
  buttonContainerStyle?: StyleProp<ViewStyle>;

  selectionTextStyle?: StyleProp<TextStyle>;
  mobileGreenWaveContainer?: StyleProp<ViewStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  buttonSubtitleStyle?: StyleProp<TextStyle>;
  buttonTitleStyle?: StyleProp<TextStyle>;
  contentStyle?: StyleProp<ViewStyle>;
  activableButtonSetStyle?: StyleProp<ViewStyle>;
};

type CompanyChoicePageContext = { theme: ThemeType; isMobile: boolean; isTablet: boolean };

export const CompanyChoicePageComponent: React.FC<React.PropsWithChildren<Props>> = ({
  userAccount,
  onSelectCompany,
  navigation,
  buttonSubtitleStyle,
  buttonTitleStyle,
  EnableFeatureRegroupementContrats,
}) => {
  const theme = useTheme();
  const { isMobile, isTablet, isDesktop, windowHeight, windowWidth } = useScreenSizes();
  const [hovered, setHovered] = useState<boolean>(false);
  const [hoveredIndex, setHoveredIndex] = useState<number>();

  const styles = useStyles(
    { theme, isMobile, isTablet },
    {
      buttonSubtitleStyle,
      buttonTitleStyle,
      mobileGreenWaveContainer: {
        width: windowWidth,
        height: windowWidth * 0.8,
      }
    }
  );
  const [inConstructionModalVisible, setInConstructionModalVisible] = useState(false);
  const { formatMessage, formatCurrencyNumber } = useTranslation();

  const activeCompanies = useMemo(() => getActiveCompanies(userAccount?.companies), [userAccount]);

  const onPressCompany = (index: number, company: AccountState) => {
    onSelectCompany(company, navigation);
  };

  const renderLegalMenuFooter = () => {
    return <LegalMenuFooterConnected isWhite={isDesktop} />;
  };

  const handleHovered = (value: boolean, index: number) => {
    setHovered(value)
    setHoveredIndex(index)
  }

  return (
    <OfflinePageLayout
      displayLogo={!isMobile}
      innerContainerStyle={styles.WalkthroughContainerStyle}
      renderDesktopCardBelowContent={renderLegalMenuFooter}
      renderMobileStickyGradientFooterContent={renderLegalMenuFooter}
      style={styles.WalkthroughCardContentStyle}
    >
      <View style={styles.containerStyle}>
        <GlobalSavingsAmount
        testId={locator._choose_company._total_amount}
          userAccount={userAccount} />
        <View style={styles.contentStyle}>
          <Title
            style={styles.selectionTextStyle}
            testId={locator._choose_company._title}
            variant={isMobile ? "t7" : "t6"}
            weight="light">
            {formatMessage({ id: 'MultiEntreprise_Choice_List_title' })}
          </Title>

          {activeCompanies?.map((company: AccountState, index: number) =>
            <ActivableButtonSet
              buttonStyle={styles.activableButtonSetStyle}
              hovered={index === hoveredIndex ? hovered : false}
              key={index}
              onChange={() => {
                onPressCompany(index, company);
              }}
              setHovered={hoveredValue => handleHovered(hoveredValue, index)}

            >
              <GenericCardWidthThreeParams
                hovered={index === hoveredIndex ? hovered : false}
                subtitle={getPlansNameByCompany(company)}
                testId={`${company?.companyName}${locator._choose_company._company_amount}`}
                title={company.companyName}
                value={formatCurrencyNumber({ value: company.totalAmount })}
              />
            </ActivableButtonSet>
          )}
          {EnableFeatureRegroupementContrats &&
            <View style={styles.buttonContainerStyle}>
              <SimpleButton onPress={() => setInConstructionModalVisible(true)}>
                <Text style={styles.buttonTitleStyle}>
                  {formatMessage({ id: 'multientreprise_choice_CTA_title' })}
                </Text>
                <Text
                  style={styles.buttonSubtitleStyle}
                  weight="light"
                >
                  {formatMessage({ id: 'multientreprise_choice_CTA_label' })}
                </Text>
              </SimpleButton>
            </View>
          }
        </View>
        <Modal visible={inConstructionModalVisible}>
          <ModalFrame
            desktopPosition="center"
            mobilePosition="bottom"
            onClose={() => setInConstructionModalVisible(false)}
          >
            <WorkInProgressContent />
          </ModalFrame>
        </Modal>
      </View>
    </OfflinePageLayout>
  );
};

const getStyles = (
  context?: CompanyChoicePageContext,
  style?: CompanyChoicePageStyles,
): CompanyChoicePageStyles => ({
  WalkthroughContainerStyle: [
    context?.isMobile && { padding: context?.theme.metrics.spacing.none },
  ],
  WalkthroughCardContentStyle: [
    {
      display: 'flex',
      flexDirection: 'column',
    },
    context?.isMobile && { padding: context?.theme.metrics.spacing.none },
  ],
  containerStyle: [
    {
      display: 'flex',
      alignItems: 'center',

    },
    context?.isMobile ? {
      paddingVertical: context?.theme.metrics.spacing.xxl,
      paddingHorizontal: context?.theme.metrics.spacing.s,
    } : {
      paddingTop: context?.theme.metrics.spacing.xxbig,
    },
    style?.containerStyle,
  ],
  mobileGreenWaveContainer: [
    {
      position: 'absolute',
    },
    style?.mobileGreenWaveContainer,
  ],
  contentStyle: [
    {
      display: 'flex',
      flex: 1,
      width: '100%',
      maxWidth: 600,
    },
    context?.isMobile ? {
      paddingLeft: context?.theme.metrics.spacing.s,
      paddingRight: context?.theme.metrics.spacing.s,
    } : {
      paddingTop: context?.theme.metrics.spacing.xxl,
      paddingLeft: context?.theme.metrics.spacing.xm,
      paddingRight: context?.theme.metrics.spacing.xm,
    },
    style?.contentStyle,
  ],
  buttonTitleStyle: [
    {
      textAlign: 'center',
      color: 'white',
    },
    style?.buttonTitleStyle,
  ],
  buttonSubtitleStyle: [
    {
      textAlign: 'center',
      color: 'white',
      fontSize: context?.theme.fonts.fontSize.paragraph.t4,
    },
    style?.buttonSubtitleStyle,
  ],
  selectionTextStyle: [{
    textAlign: 'center',
    marginBottom: context?.theme.metrics.spacing.xm,
  }],
  buttonContainerStyle: [
    context?.isMobile ? {
      width: '100%',
    } : {
      alignSelf: 'center',
      width: 350,
    }],
  activableButtonSetStyle: [
    {
      marginBottom: context?.theme.metrics.spacing.xm,
      padding: context?.theme.metrics.spacing.m
    },
    style?.activableButtonSetStyle,
  ],
});

const useStyles = createUseStyles(getStyles);
