import React from 'react';
import { ViewStyle, StyleProp, View } from 'react-native';

import {
  AnnounceContent,
} from '@ere-uilib/molecules';
import {
  OfflinePageLayout,
} from '@ere-uilib/organisms';
import { createUseStyles, useTheme, ThemeType } from '@ere-uilib/styles';

export interface OfflineAnnounceLayoutProps {
  children?: React.ReactNode
  renderMenuLegalFooter?: () => React.ReactNode
  renderInOfflinePageLayoutStart?: () => React.ReactNode
  renderInOfflinePageLayoutEnd?: () => React.ReactNode
  sourceName: string
  actions?: () => React.ReactNode
  onLogoPress?: () => void
  title: string | React.ReactNode
  description: string |React.ReactNode
  displayLogo?: boolean
  loading?: boolean
}
interface StylesProps {
  announceContainer?: StyleProp<ViewStyle>
  mobileFooterContainer?: StyleProp<ViewStyle>
}

interface StylesContext {
  theme: ThemeType
}

export const OfflineAnnounceLayout: React.FC<React.PropsWithChildren<OfflineAnnounceLayoutProps>> = ({
  children,
  sourceName,
  description,
  title,
  actions,
  onLogoPress,
  displayLogo = true,
  loading = false,
  renderMenuLegalFooter,
  renderInOfflinePageLayoutStart,
  renderInOfflinePageLayoutEnd,
}) => {
  const theme = useTheme();
  const styles = useStyles({ theme }, {});

  const renderMobileActions = () => (
    <View>
      {actions && actions()}
      {renderMenuLegalFooter?.()}
    </View>
  );
  return (
    <OfflinePageLayout
      displayLogo={displayLogo}
      onLogoPress={onLogoPress}
      renderDesktopCardBelowContent={renderMenuLegalFooter}
      renderMobileStickyGradientFooterContent={renderMobileActions}
    >
      {renderInOfflinePageLayoutStart && renderInOfflinePageLayoutStart()}
      <View style={styles.announceContainer}>
        <AnnounceContent
          actions={actions}
          description={description}
          loading={loading}
          sourceName={sourceName}
          title={title}
        >
          {children}
        </AnnounceContent>
      </View>
      {renderInOfflinePageLayoutEnd && renderInOfflinePageLayoutEnd()}
    </OfflinePageLayout>);
};

const getStyles = (
  context?: StylesContext,
  style?: StylesProps,
): StylesProps => ({
  announceContainer: [
    {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
  ],
});

const useStyles = createUseStyles(getStyles);
