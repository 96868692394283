import { AppState } from '@modules/reducers'

import { UnavailableRefundActionsType } from './actions/refundActionsTypes'

export const getRefundsError = (state: AppState) => state.unavailableRefunds.error

export const getRefundUnavailabilityDecisionTreeData = (state: AppState) =>
  state.unavailableRefunds.refundUnavailabilityDecisionTree

export const getRefundUnavailableMotifId = (state: AppState) =>
  state.unavailableRefunds.refundUnavailableMotifId

export const getUnavailableRefundDispositifsTableData = (state: AppState) => {
  return (
    state.unavailableRefunds?.unavailableRefundDispositifs?.sort((a, b) => b.amount - a.amount) ||
    null
  )
}

export const isUnavailableRefundDispositifTableLoading = (state: AppState) =>
  state.isLoading[UnavailableRefundActionsType.GET_UNAVAILABLE_REFUND_DISPOSITIFS_REQUEST]
