import { put, all, fork, call, takeLatest, select } from "redux-saga/effects";
import { AUTH_CONFIG_CLIENT_ID } from "@config/index";

import * as SettingsActions from "@modules/settings/actions/settingsActions";
import {
  GetMobileMenuListRequestActions,
  SettingsActionsType
} from '@modules/settings/actions/settingsActionsTypes';
import { runManager } from "@modules/moduleManager";
import {
  fetchMobileMenuList,
  fetchApplicationSettings,
} from "./services";
import { getExternalClientId } from "@modules/settings/selectors";

function* getApplicationSettings(): any {
  // get exnerla ID in the case of perneo/visiogo or bo auth
  const externalId = yield select(getExternalClientId)
  const clientId = externalId || AUTH_CONFIG_CLIENT_ID
  yield put(SettingsActions.applicationSettingsInitError());
  const response = yield call(fetchApplicationSettings, clientId);
    /*const targetClientId = response.data.client_id
    const targetAuthority = response.data.authority
    // call oidc api to get config for the clientId ( we need the specific scope)
    const clientIdConfigurationResponse = yield call(
      getLoginOpenIdConfigForClientId,
      targetClientId,
      targetAuthority
    );
    const clientIdConfiguration = clientIdConfigurationResponse.data;

    yield put(actionSettings.getApplicationSettingsSuccess({...response.data,...clientIdConfiguration} ));*/
    yield put(SettingsActions.getApplicationSettingsSuccess(response.data))
}

export function* getMobileMenuList(
  action: GetMobileMenuListRequestActions
): any {
  yield put(SettingsActions.mobileMenuListInitError());
  const response = yield call(fetchMobileMenuList, action.companyId);
  yield put(SettingsActions.getMobileMenuListSuccess(response.data));
}

function* getApplicationSettingsSagas() {
  yield takeLatest(
    SettingsActionsType.GET_APPLICATION_SETTINGS_REQUEST,
    runManager(
      getApplicationSettings,
      SettingsActionsType.GET_APPLICATION_SETTINGS_FAILURE
    )
  );
}

function* getMobileMenuListSagas() {
  yield takeLatest(
    SettingsActionsType.GET_MOBILE_MENU_LIST_REQUEST,
    runManager(
      getMobileMenuList,
      SettingsActionsType.GET_MOBILE_MENU_LIST_FAILURE
    )
  );
}

export function* SettingsSagas() {
  yield all([fork(getApplicationSettingsSagas)]);
  yield all([fork(getMobileMenuListSagas)]);
}
