import {
  ProfitSharingIncentiveLegalFrameworkEnum, ProfitSharingIncentivePaymentModeTypeEnum,
  ProfitSharingIncentiveTypeEnum
} from '@constants/ProfitSharingIncentive';

import { label as common } from '../label';
import { LegalFrameworkByType } from '../types';

type Label = Omit<typeof common, 'header'> & {
  header: {
    title: string;
    help: {
      title: string;
      content: LegalFrameworkByType<string>;
    };
  };
  title: LegalFrameworkByType<string>;
  origin: {
    title: LegalFrameworkByType<string>
  };
  destination: {
    title1: string;
    title2: string;
  };
  payment: Partial<{
    [key in ProfitSharingIncentivePaymentModeTypeEnum]: {
      title: string;
      description: string;
    };
  }>;
  button: {
    next: string;
  };
};

export const label: Label = {
  ...common,
  header: {
    title: 'Remboursement_virementRecap_title',
    help: {
      title: 'PIValidationChoixPercevoirAideTitre',
      content: {
        [ProfitSharingIncentiveTypeEnum.incentive]: {
          default: 'PIValidationChoixPercevoirAideDescription',
          [ProfitSharingIncentiveLegalFrameworkEnum.ppv]:
            'PIAffectationdefautTotalInvestInteressementPPVAide'
        },
        [ProfitSharingIncentiveTypeEnum.profitsharing]: {
          default: 'PIValidationChoixPercevoirAideDescription'
        }
      }
    }
  },
  title: {
    [ProfitSharingIncentiveTypeEnum.incentive]: {
      default: 'PIAffectationdefautTotalInvestInteressementTitre',
      [ProfitSharingIncentiveLegalFrameworkEnum.normal]:
        'PIAffectationdefautTotalInvestInteressementTitre',
      [ProfitSharingIncentiveLegalFrameworkEnum.complementary]:
        'PIAffectationdefautTotalInvestInteressementCOMPTitre',
      [ProfitSharingIncentiveLegalFrameworkEnum.exceptional]:
        'PIAffectationdefautTotalInvestInteressementEXCEPTitre',
      [ProfitSharingIncentiveLegalFrameworkEnum.ppv]:
        'PIAffectationdefautTotalInvestInteressementPPVTitre'
    },
    [ProfitSharingIncentiveTypeEnum.profitsharing]: {
      default: 'PIAffectationdefautTotalInvestParticipationTitre',
      [ProfitSharingIncentiveLegalFrameworkEnum.normal]:
        'PIAffectationdefautTotalInvestParticipationTitre',
      [ProfitSharingIncentiveLegalFrameworkEnum.complementary]:
        'PIAffectationdefautTotalParticipationCOMPTitre',
      [ProfitSharingIncentiveLegalFrameworkEnum.exceptional]:
        'PIAffectationdefautTotalInvestParticipationEXCEPTitre'
    }
  },
  origin: {
    title: common.header.title
  },
  destination: {
    title1: 'PIAffectationdefautVersLabel',
    title2: 'PIValidationChoixPercIvestEtVersLabel'
  },
  payment: {
    [ProfitSharingIncentivePaymentModeTypeEnum.CHEQUE]: {
      title: 'PIDefinitionBesoinCardRIBManquantTitre',
      description: 'PIDefinitionBesoinCardRIBManquantDescription'
    },
    [ProfitSharingIncentivePaymentModeTypeEnum.COMPANY]: {
      title: 'PIDefinitionBesoinCardEntreprisePayeuseTitre',
      description: 'PIDefinitionBesoinCardEntreprisePayeuseDescription'
    },
    [ProfitSharingIncentivePaymentModeTypeEnum.RIB]: {
      title: 'PIValidationChoixPercIvestCardCompteBancaireTitre',
      description: 'PIValidationChoixPercIvestCardCompteBancaireDescription'
    }
  },
  button: {
    next: 'PIValidationChoixPercIvestValiderAffectationBouton'
  }
};
