import { StylesContext, LogoBnpStyles } from './interfaces';

export const getStyles = (
  context?: StylesContext,
  style?: LogoBnpStyles,
): LogoBnpStyles => ({
  containerStyle: [
    {
      zIndex: 1,
    },
    style?.containerStyle,
  ],
  columnLogoContainerStyles: [
    {
      height: 100,
      justifyContent: 'center',
      alignItems: 'center',
      marginVertical: context?.theme.metrics.spacing.xm,
    },
    style?.columnLogoContainerStyles,
  ],
  rowLogoContainerStyles: [
    !context?.isMobile
      ? {
        width: 255,
        height: 40,
        maxWidth: 255,
        maxHeight: 40,
      }
      : {
        width: 195,
        height: 30,
        maxWidth: 200,
        maxHeight: 30,
      },
    style?.rowLogoContainerStyles,
  ],
});
