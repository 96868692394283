import { CheckBoxStyle, StylesContext } from './interface';

export const getStyles = (
  context?: StylesContext,
  style?: CheckBoxStyle
): CheckBoxStyle => {

  const primaryColor = context?.customColor || context?.theme.colors.basics.primary.c500;
  const secondaryColorBorder = context?.hovered ? context?.theme.colors.basics.primary.c500
    : context?.theme.colors.basics.primary.black;
  const secondaryColorBg = context?.hovered ? context?.theme.colors.basics.primary.c500
    : context?.theme.colors.basics.primary.white;

  return {
    containerStyle: [
      {
        flexDirection: 'row',
        alignItems: 'center',
      },
      style?.containerStyle,
    ],
    labelStyle: [
      {
        marginLeft: context?.theme.metrics.spacing.s,
        color: context?.disabled
          ? context?.theme.colors.basics.grey.c600
          : context?.theme.colors.basics.grey.c900
      },
      style?.labelStyle,
    ],
    childrenStyle: [
      {
        flex: 1,
      },
      style?.childrenStyle,
    ],
    squareViewStyle: [
      {
        height: 20,
        width: 20,
        borderRadius: 2,
        borderWidth: 1,
        justifyContent: 'center',
        alignItems: 'center',
        borderColor: context?.disabled ?
          context?.theme.colors.basics.grey.c200 :
          context?.value
            ? primaryColor
            : secondaryColorBorder,
        backgroundColor: context?.disabled ?
          context?.theme.colors.basics.grey.c200 :
          context?.value
            ? primaryColor
            : secondaryColorBg,
      },
      style?.squareViewStyle,
    ],
  };
};
