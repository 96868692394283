import React, { useCallback, useMemo, useState } from 'react';
import { View, ViewStyle, ScrollView } from 'react-native';

import { Link, NotificationIconTypeEnum, SquareIcon, Text, Spinner } from '@ere-uilib/atoms';
import { IconEnum, OtpRequestTypeEnum, OtpChanelTypeEnum } from '@ere-uilib/enums';
import { ModalFrame, NotificationHard, SimpleButton } from '@ere-uilib/molecules';
import { OtpFields } from '@ere-uilib/molecules/inputs/OtpFields';
import { useTheme } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';
import { OtpParametersState } from '@ere-uilib/types';

import { useStyles } from './useStyles';

interface ViewStyles {
  container?: ViewStyle;
}
interface Props {
  errorMessage: string;
  otpParameters: OtpParametersState|null;
  style?: ViewStyle;
  styles?: ViewStyles;
  onSubmit: (otp: string) => void;
  resendCode: () => void;
  isAskCodeAgainCodeLoading?: boolean;
  isSendCodeLoading?: boolean;
  onClose: () => void;
  isAskAgainSuccess?: boolean;
}

export function OtpForm({
  errorMessage,
  otpParameters,
  style,
  styles: customStyles,
  isAskCodeAgainCodeLoading,
  isSendCodeLoading,
  onSubmit,
  resendCode,
  onClose,
  isAskAgainSuccess,

}: Props) {
  const theme = useTheme();
  const styles = useStyles({ container: style || {}, ...customStyles });
  const [code, setCode] = useState(['', '', '', '', '', '']);
  const { formatMessage } = useTranslation();
  const [isDisabled, setIsDisabled] = useState(false);

  const handleChange = useCallback((value: string[]): void => {
    setCode(value);
  }, []);

  const handleSubmit = useCallback(() => {
    if (!code || code.length !== 6) {
      return;
    }

    onSubmit(code.join(''));
    setIsDisabled(true);
  }, [code, onSubmit]);

  const Icon = useCallback(() => (
    otpParameters?.requestType === OtpRequestTypeEnum.VERIFY ?
      <SquareIcon
        backgroundColor={theme.colors.basics.primary.c200}
        iconColor={theme.colors.basics.primary.c500}
        iconName={IconEnum.CHECK}
        iconSize={theme.metrics.iconSizes.m}
        style={{ container: styles.iconContainerStyle }}
      />
      : (
        <SquareIcon
          backgroundColor={theme.colors.notifications.warning.c100}
          iconColor={theme.colors.notifications.warning.c500}
          iconName={IconEnum.PASSWORD}
          iconSize={theme.metrics.iconSizes.xl}
          style={{ container: styles.iconContainerStyle }}
        />
      )
  ), [otpParameters, styles, theme]);

  const title = useMemo(() => (
    otpParameters?.requestType === OtpRequestTypeEnum.VERIFY ?
      formatMessage({ id: 'Profil_email_edit_otp_title' })
      : formatMessage({ id: 'sessionsecurity_title' })
  ), [formatMessage, otpParameters]);

  const description = useMemo(() => {
    if (otpParameters?.requestType === OtpRequestTypeEnum.VERIFY) {
      return otpParameters?.chanelType === OtpChanelTypeEnum.MAIL ?
        formatMessage({ id: 'Profil_email_edit_otp_label' })
        : formatMessage({ id: 'Profil_email_edit_otp_label' }); // TODO;
    }

    return  otpParameters?.chanelType === OtpChanelTypeEnum.MAIL || otpParameters?.chanelType === OtpChanelTypeEnum.MAIL_DOCAPOSTE ?
      formatMessage({ id: 'sessionsecurity_step2_email_otp_label' })
      : formatMessage({ id: 'sessionsecurity_step2_mobile_otp_label' });

  }, [formatMessage, otpParameters]);

  const chanelLabel = useMemo(() => (
    otpParameters?.chanelType === OtpChanelTypeEnum.MAIL ?
      otpParameters?.chanelLabelMail
      : otpParameters?.chanelLabelSms
  ), [otpParameters]);
  const resendLinkText = useMemo(() => (
    otpParameters?.requestType === OtpRequestTypeEnum.VERIFY ?
      formatMessage({ id: 'Profil_email_edit_otp_resend_linklabel' })
      : formatMessage({ id: 'sessionsecurity_step2_resendotp_linklabel' })
  ), [formatMessage, otpParameters]);
  const buttonText = useMemo(() => (
    otpParameters?.requestType === OtpRequestTypeEnum.VERIFY ?
      formatMessage({ id: 'Profil_email_edit_verify_button' })
      : formatMessage({ id: 'sessionsecurity_step2_verify_button' })
  ), [formatMessage, otpParameters]);

  const askAgainItemDescription = useMemo(() => (
    otpParameters?.chanelType === OtpChanelTypeEnum.MAIL ?
      formatMessage({ id: 'Profil_email_edit_otp_sent_label' })
      : formatMessage({ id: 'Profil_phone_edit_otp_sent_label' })
  ), [formatMessage, otpParameters]);

  const disabledButton = useMemo(() => code.join('').length !== 6, [code]);

  return (
    <View style={styles.container}>
      <ModalFrame
        desktopPosition="center"
        innerContainer={{ padding: 0 }}
        mobilePosition="bottom"
        modalFrame={styles.modalFrame}
        onClose={onClose}
      ><ScrollView contentContainerStyle={styles.mainContainer}>
        <View style={styles.modalContainer}>
          <Icon />
          <Text style={styles.title}>{title}</Text>
          <Text style={styles.description}>{description}</Text>
          <Text>{chanelLabel}</Text>
          <View style={styles.otpFieldsWrapper}>
            <OtpFields
              code={code}
              hasError={Boolean(errorMessage)}
              isDisabled={isSendCodeLoading || isAskCodeAgainCodeLoading}
              onChange={handleChange}
              onSubmit={handleSubmit}
              style={styles.otpFields}
            />
            <Link
              onPress={()=>{
                resendCode();
              }}>{resendLinkText}</Link>
          </View>
          {(isAskCodeAgainCodeLoading) && <Spinner />}
          <View style={styles.notificationWrapper}>
            {Boolean(errorMessage) &&
              <NotificationHard
                containerStyle={styles.notificationHard}
                text={errorMessage}
                type={NotificationIconTypeEnum.WARNING}
              >
                <Link testID='error_help_label'>{formatMessage({ id: 'Profil_email_edit_error_help_label' })}</Link>
              </NotificationHard>
            }
            {isAskAgainSuccess &&
              <NotificationHard
                containerStyle={styles.notificationHard}
                text={askAgainItemDescription}
                type={NotificationIconTypeEnum.SUCCESS}
              />
            }
          </View>

        </View>

        <View style={styles.buttonWrapper}>
          <SimpleButton
            disabled={disabledButton}
            loading={isSendCodeLoading}
            onPress={handleSubmit}
            testId='otp_confirm'
            title={buttonText}
          />
        </View></ScrollView>
      </ModalFrame>
    </View>
  );
}
