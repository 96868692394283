import { connect } from 'react-redux';

import { 
  getProfitSharingIncentiveInitDataState,
} from '@modules/profit-sharing-incentive/selectors';
import { AppState } from '@modules/reducers';

import { ProfitSharingIncentiveOnboardingPageComponent } from './ProfitSharingIncentiveOnboardingPage.component';

const mapStateToProps = (state: AppState) => {
  const { type: profitSharingIncentiveType, legalFramework: profitSharingIncentiveLegalFramework } =
  getProfitSharingIncentiveInitDataState(state) ?? {};

  return {
    profitSharingIncentiveType,
    profitSharingIncentiveLegalFramework
  }
};

export const ProfitSharingIncentiveOnboardingPage = connect(
  mapStateToProps,
  null
)(ProfitSharingIncentiveOnboardingPageComponent);
