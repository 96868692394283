import React from 'react';
import { View } from 'react-native';

import { useScreenSizes } from '@components/index';
import {
  useTheme,
  Title,
  InstallmentsStepper,
} from '@components/index';
import { createUseStyles } from '@ere-uilib/styles';

import { DirectionEnum } from '@ere-uilib/molecules/steppers/InstallmentsStepper/interfaces';

import { OnboardingStepperProps } from './interfaces';
import { getStyles } from './styles';

export const OnboardingStepper: React.FC<OnboardingStepperProps> = ({
  stepperItems,
  renderSubmitButton,
  title
}) => {
  const theme = useTheme();
  const { isDesktop } = useScreenSizes();

  const style = useStyles(
    {
      theme,
      isMobile: !isDesktop,
    },
    {}
  );

  return (
    <View style={style.containerStyle}>
      <Title
        style={style.title}
        variant="t5">
        {title}
      </Title>
      <View style={style.stepperContainer}>
        <InstallmentsStepper
          direction={DirectionEnum.COLUMN}
          stepperItems={stepperItems}
        />
      </View>
      {isDesktop && renderSubmitButton}
    </View>
  );
};

const useStyles = createUseStyles(getStyles);
