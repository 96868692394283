import React, { useMemo } from 'react';
import { View } from 'react-native';

import {
  useTranslation,
  Text,
  SimpleButton,
  IconEnum,
  Link
} from '@components/index';

import { AnnuityCardProps } from './interfaces';
import { useStyles } from './useStyles';
import { documentType } from '@modules/retirement/types';

export const AnnuityCard: React.FC<React.PropsWithChildren<AnnuityCardProps>> = ({
  onSubmit,
  setIsEsPacteFile,
  setIsLinkPressed,
  isLoading
}) => {
  const styles = useStyles();
  const { formatMessage } = useTranslation();

  const dictionary = useMemo(() => {
    return {
      renteTitle: formatMessage({ id: 'RetirementJourneyRecoverHisSavingPensionToComplete' }),
      renteInfo: formatMessage({ id: 'RetirementJourneyRecoverHisSavingGuarantee' }),
      SupplementaryFormBulletin: formatMessage({ id: 'RetirementJourneyRecoverHisSavingNameBulletin' }),
      SupplementaryFormGeneralesTerms: formatMessage({ id: 'RetirementJourneyRecoverHisSavingNameGeneralesTerms' }),
      documentsToKeepTxt: formatMessage({ id: 'RetirementJourneyRecoverHisSavingTextPensionCard' }),
      CGtxtLink: formatMessage({ id: 'RetirementJourneyRecoverHisSavingNameGeneralesTerms' })
    };
  }, [formatMessage]);

  const docTypes = useMemo(() => {
    return {
      documentDocTypes: [
        {
          documentType: "Sortie_dependance_CG",
          documentFormat: "Document",
          order: 1
        }
      ],
      formDocTypes: [
        {
          documentType: 'Sortie_dependance_formulaire',
          documentFormat: "Form",
          order: 1
        },
      ]
    }
  }, []);

  const handleLinkClick = (docType: string) => {
    onSubmit(docType, docType);
    setIsLinkPressed(true)
  }

  const handleDownloadButton = (document: documentType) => {
    setIsEsPacteFile(false);
    setIsLinkPressed(false);
    onSubmit(document.documentType, document.documentType)
  }

  return (
    <View style={styles.SupplementaryFormContainer}>
      <Text
        variant='t3'
        weight='bold'
      >
        {dictionary.renteTitle}
      </Text>
      <Text
        style={styles.SupplementaryFormtxt}
        variant='t3'
        weight='light'
      >
        {dictionary.renteInfo}
      </Text>

      {
        docTypes.formDocTypes.map((document, index) => {
          return (
            <SimpleButton
              containerStyle={styles.buttonStyle}
              design='outlined'
              key={index}
              leftIcon={IconEnum.PDF}
              loading={isLoading}
              onPress={() => handleDownloadButton(document)}
              size='small'
              title={dictionary.SupplementaryFormBulletin}
            />
          )
        })
      }

      <Text
        style={styles.txtStyles}
        variant='t4'
      >
        {dictionary.documentsToKeepTxt}
      </Text>
      {
        docTypes.documentDocTypes.map((document, index) => {
          return (
            <Link
              key={index}
              onPress={() => handleLinkClick(document.documentType)}
              textVariant='t3'
            >
              {dictionary.CGtxtLink}
            </Link>
          )
        })
      }
    </View>
  )
};
