import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useScreenSizes, useTheme } from '@ere-uilib/styles';

export function useStyles() {
  const theme = useTheme();
  const { windowWidth } = useScreenSizes();

  const styles = useMemo(() => StyleSheet.create({
    container: {

    },
    fieldsContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: theme.metrics.spacing.m
    },
    fieldContainer: {
      flex: 1,
      flexDirection: 'row',
      maxWidth: 200,
    },
    field: {
      flex: 1,
    },
    fieldInput: {
      flex: 1,
      minWidth: 120,
      textAlign: 'center',
      paddingLeft: theme.metrics.spacing.m,
      paddingRight: theme.metrics.spacing.m
    },
    fieldsSeparatorText: {
      marginHorizontal: windowWidth > 340 ?
        theme.metrics.spacing.m
        : theme.metrics.spacing.xs,
    },
    fieldLabel: { 
      textAlign: 'center', 
      right: 0, 
      left: 0 
    },
    currencyTextContainer: {
      position: 'absolute',
      right: theme.metrics.spacing.m,
      top: theme.metrics.spacing.l

    },
    currencyText: {

    },
  }), [theme, windowWidth]);

  return styles;
}
