import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

export function useStyles() {
  const theme = useTheme();
  const styles = useMemo(
    () =>
      StyleSheet.create({
        headerContainer: {
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'space-between',
          height: theme.metrics.spacing.xxxl,
          paddingVertical: theme.metrics.spacing.s,
          paddingHorizontal: theme.metrics.borderRadius.s,
          backgroundColor: theme.colors.basics.white,
          borderLeftWidth: theme.metrics.spacing.xs,
          borderTopLeftRadius: theme.metrics.borderRadius.xs,
          borderTopRightRadius: theme.metrics.borderRadius.xs,
          borderColor: theme.colors.basics.grey.c500
        },
        wrapperContainer: {
          borderRadius: theme.metrics.borderRadius.xs,
          marginBottom: theme.metrics.spacing.l,
          backgroundColor: theme.colors.basics.white,
          shadowColor: theme.colors.basics.black,
          shadowOffset: {
            width: 0,
            height: 0
          },
          shadowOpacity: 0.15,
          shadowRadius: 10,
          elevation: 7
        },
        cardContentContainer: {
          paddingHorizontal: theme.metrics.spacing.xm,
          paddingVertical: theme.metrics.spacing.xm
        },
        dashStyle: {
          borderBottomColor: theme.colors.basics.grey.c200,
          borderBottomWidth: 1
        }
      }),
    [theme]
  );

  return styles;
}
