
const CONTRIBUTIONS_IDS: { [key: string]: string } = {
  DONNEES_PERSO: 'ContextHabilitationS2E_donneesPerso',
  INITIE: 'ContextHabilitationS2E_initie',
  FONDS_CCB: 'ContextHabilitationS2E_fondsccb',
  STATUS_FONDS: 'ContextHabilitationS2E_statuFonds',
  SANS_FISCALITE: 'ContextHabilitationS2E_sansFiscalite',
  SANS_FISCALITE_RACHAT: 'ContextHabilitationS2E_sansFiscaliteRachat',
  SANS_RACHAT: 'ContextHabilitationS2E_sansRachat',
  AVOIRS_BLOQUES: 'ContextHabilitationS2E_avoirsBloques',
  Operation_Error_Unvailable_Contract: 'Operation_Error_Unvailable_Contract',

};

const STEPS = [
  'Arbitrage_etape1',
  'Arbitrage_etape2',
  'Arbitrage_etape3',
];

export enum ArbitrationTypes {
  'flow' = 'Flow',
  'stock' = 'Stock',
  'oad' = 'Oad',
  'ineligible' = 'Ineligible'
}

export enum AllocationsEnum {
  Monetary = 'monetaryPercentage',
  Obligatory = 'obligatoryPercentage',
  Diversified = 'diversifiedPercentage',
  Action = 'actionPercentage',
}

export enum RepartitionEnum {
  monetaryPercentage = 'Monétaire',
  obligatoryPercentage = 'Obligataire',
  diversifiedPercentage = 'Diversifié',
  actionPercentage = 'Action',
}

export enum ArbitrationParamsTypes {
  'Flow' = 'FlowReallocations',
  'Stock' = 'StockReallocations',
  'Oad' = 'OadReallocations',
  'Ineligible' = 'Ineligible'
}

export enum ThresholdTriggerTypesEnum {
  VCP = "VCP",
  VPP = "VPP"
}

export enum ThresholdCoursesEnum {
  arbitration = "arbitration",
  refund = "refund"
}
export {
  CONTRIBUTIONS_IDS,
  STEPS,
};
