import { SimulatorType } from "@constants/sapiendo";
import { AccountsErrorState } from "@modules/dashboard/types";

import { InitAllErrorsAction } from "@modules/error/actions/errorActionsTypes";
import {RedirectOperationResponsePayload} from "@modules/redirect-operation/types";

export enum RedirectOperationActionsType {
  GET_OPERATION_REDIRECT_REQUEST = 'GET_OPERATION_REDIRECT_REQUEST',
  GET_OPERATION_REDIRECT_SUCCESS = 'GET_OPERATION_REDIRECT_SUCCESS',
  GET_OPERATION_REDIRECT_FAILURE = 'GET_OPERATION_REDIRECT_FAILURE',
  GET_OPERATION_REDIRECT_URL_REQUEST = 'GET_OPERATION_REDIRECT_URL_REQUEST',
  GET_OPERATION_REDIRECT_URL_SUCCESS = 'GET_OPERATION_REDIRECT_URL_SUCCESS',
  GET_OPERATION_REDIRECT_URL_FAILURE = 'GET_OPERATION_REDIRECT_URL_FAILURE',
  OPERATION_MODAL_INIT_ERROR = 'OPERATION_MODAL_INIT_ERROR',
  SAPIENDO_REDIRECT_URL_INIT_ERROR = 'GET_SAPIENDO_REDIRECT_URL_ERROR',
  GET_SAPIENDO_REDIRECT_URL_REQUEST = 'GET_SAPIENDO_REDIRECT_URL_REQUEST',
  GET_SAPIENDO_REDIRECT_URL_SUCCESS = 'GET_SAPIENDO_REDIRECT_URL_SUCCESS',
  GET_SAPIENDO_REDIRECT_URL_FAILURE = 'GET_SAPIENDO_REDIRECT_URL_FAILURE'
}

export interface GetOperationRedirectRequestAction {
  type: RedirectOperationActionsType.GET_OPERATION_REDIRECT_REQUEST;
  operationType: string;
}

export interface GetOperationRedirectSuccessAction {
  type: RedirectOperationActionsType.GET_OPERATION_REDIRECT_SUCCESS;
  operationModalList: RedirectOperationResponsePayload;
}

export interface GetOperationRedirectFailureAction {
  type: RedirectOperationActionsType.GET_OPERATION_REDIRECT_FAILURE;
  error: any;
}

export interface GetOperationRedirectUrlRequestAction {
  type: RedirectOperationActionsType.GET_OPERATION_REDIRECT_URL_REQUEST;
  operationType: string;
  provider: string;
}

export interface GetOperationRedirectUrlSuccessAction {
  type: RedirectOperationActionsType.GET_OPERATION_REDIRECT_URL_SUCCESS;
}

export interface GetOperationRedirectUrlFailureAction {
  type: RedirectOperationActionsType.GET_OPERATION_REDIRECT_URL_FAILURE;
  error: any;
}

export interface OperationModalInitErrorAction {
  type: RedirectOperationActionsType.OPERATION_MODAL_INIT_ERROR;
}

// SAPIENDO REDIRECT URL

export interface SapiendoRedirectUrlInitErrorAction {
  type: RedirectOperationActionsType.SAPIENDO_REDIRECT_URL_INIT_ERROR;
}



export interface GetSapiendoRedirectUrlRequestAction {
  type: RedirectOperationActionsType.GET_SAPIENDO_REDIRECT_URL_REQUEST;
  simulatorType: SimulatorType
}


export interface GetSapiendoRedirectUrlSuccessAction {
  type: RedirectOperationActionsType.GET_SAPIENDO_REDIRECT_URL_SUCCESS;
}


export interface GetSapiendoRedirectUrlFailureAction {
  type: RedirectOperationActionsType.GET_SAPIENDO_REDIRECT_URL_FAILURE;
  error: AccountsErrorState;
}

export type OperationRedirectAction =
  | GetOperationRedirectRequestAction
  | GetOperationRedirectFailureAction
  | GetOperationRedirectSuccessAction
  | GetOperationRedirectUrlRequestAction
  | GetOperationRedirectUrlFailureAction
  | OperationModalInitErrorAction
  | InitAllErrorsAction
  | SapiendoRedirectUrlInitErrorAction
  | GetSapiendoRedirectUrlRequestAction
  | GetSapiendoRedirectUrlSuccessAction
  | GetSapiendoRedirectUrlFailureAction
