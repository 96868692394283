import { StylesContext, Styles } from './interface'

export const getStyles = (context?: StylesContext, style?: Styles): Styles => ({
  container: {
    flex: 1,
    alignItems: 'center'
  },
  title: {
    alignItems: 'center'
  },
  row: [
    {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    context?.isDesktop && {
      marginRight: context?.theme.metrics.spacing.xm
    }
  ],
  column: {
    marginTop: context?.theme.metrics.spacing.xm
  },
  benefitCard: [
    {
      flex: 1,
      borderWidth: 1,
      borderColor: context?.theme.colors.basics.grey.c200,
      borderRadius: 5,
      alignItems: 'center',
      marginBottom: context?.theme.metrics.spacing.xm,
      padding: context?.theme.metrics.spacing.xm
    },
    context?.isDesktop && {
      marginRight: context?.theme.metrics.spacing.xm
    },
    style?.benefitCard
  ],
  benefitCardTitle: {
    color: context?.theme.colors.basics.primary.c500,
    marginTop: context?.theme.metrics.spacing.s
  },
  benefitCardDescription: {
    marginTop: context?.theme.metrics.spacing.xm
  }
})
