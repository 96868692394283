import { StackNavigationProp } from '@react-navigation/stack';
import React from 'react';

import {
  DashboardAnnounce,
  SimpleButton,
} from '@components/index';
import { RouteNames } from '@constants/navigation';
import { useTranslation } from '@ere-uilib/translations';
import { AppNavigatorInterface } from '@navigation/Interfaces';

export interface ProfitSharingIncentiveFailedProps {
  navigation: StackNavigationProp<AppNavigatorInterface>,
  clearProfitSharingIncentiveData: ()=>void
}

export const ProfitSharingIncentiveFailedPage: React.FC<React.PropsWithChildren<ProfitSharingIncentiveFailedProps>> = ({
  navigation,
  clearProfitSharingIncentiveData,
}) => {
  const { formatMessage } = useTranslation();

  const renderActions = () => {
    return (
      <SimpleButton
        onPress={() => {
          navigation.navigate(RouteNames.BottomTabNavigator, {
            screen: RouteNames.Home,
          });
          clearProfitSharingIncentiveData();
        }}
        title={formatMessage({ id: 'PIPageEchecBouton' })}
      />
    );
  };
  return (
    <DashboardAnnounce
      actions={renderActions}
      description={formatMessage({ id: 'PIPageEchecLabel' })}
      sourceName={'installmentError'}
      title={formatMessage({ id: 'PIPageEchecTitre' })}
    />
  );
};