import React from 'react';
import { View } from 'react-native';

import {
  Table,
  TableCellInterface,
  TableCellTypeEnum,
  Text
} from '@components/index';
import { createUseStyles, useScreenSizes, useTheme } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';

import {
  PlansCell,
  PlansCellItemProps,
  TotalAmountCell
} from './CustomTableCells';
import { FundsComparisonContentProps } from './interfaces';
import { getStyles } from './styles';

export const FundsComparisonContent = ({
  fundsOverview,
  fundsPerformance,
  multiFundsRepartitionSupports,
  containerStyle,
}: FundsComparisonContentProps) => {
  const theme = useTheme();
  const { isMobile } = useScreenSizes();
  const { formatMessage, formatDate } = useTranslation();

  const styles = useStyles(
    { theme, isMobile },
    {
      containerStyle,
    }
  );

  const tableOverViewData: TableCellInterface[][] = [];
  const tablePerformanceData: TableCellInterface[][] = [];

  // OVERVIEW Table data construction
  // create base of each row
  const isAllRiskSRRI = fundsOverview.every(fund => !!fund?.risqueSRRI)

  const riskTitleHead = isAllRiskSRRI ?
    'Fundsheet_Tab1_risk-level_SRRI_Old_title' :
    'Fundsheet_Tab1_risk-level_title'

  const headerRow: TableCellInterface[] = [
    {
      type: TableCellTypeEnum.EMPTY,
    },
  ];
  const riskLevelRow: TableCellInterface[] = [
    {
      type: TableCellTypeEnum.ENTRIES,
      text: formatMessage({ id: riskTitleHead }),
    },
  ];
  const recommendedInvestmentPeriodRow: TableCellInterface[] = [
    {
      type: TableCellTypeEnum.ENTRIES,
      text: formatMessage({ id: 'Fundsheet_Tab1_advised_placement_period_title' }),
    },
  ];
  const volatilityRow: TableCellInterface[] = [
    {
      type: TableCellTypeEnum.ENTRIES,
      text: formatMessage({ id: 'Fundsheet_Tab1_volatility_title' }),
    },
  ];
  const classificationRow: TableCellInterface[] = [
    {
      type: TableCellTypeEnum.ENTRIES,
      text: formatMessage({ id: 'fund-card_classification' }),
    },
  ];
  const zoneGeographRow: TableCellInterface[] = [
    {
      type: TableCellTypeEnum.ENTRIES,
      text: formatMessage({ id: 'Fundsheet_Tab1_geographical_area_title' }),
    },
  ];
  const fundTypeRow: TableCellInterface[] = [
    {
      type: TableCellTypeEnum.ENTRIES,
      text: formatMessage({ id: 'Fundsheet_Tab1_Fund_Type_title' }),
    },
  ];
  const eligibleFundsRow: TableCellInterface[] = [
    {
      type: TableCellTypeEnum.ENTRIES,
      text: formatMessage({ id: 'Fundsheet_Tab1_Eligibility_title' }),
    },
  ];
  const valuationPeriodRow: TableCellInterface[] = [
    {
      type: TableCellTypeEnum.ENTRIES,
      text: formatMessage({ id: 'Fundsheet_Tab1_Valuation_Period_title' }),
    },
  ];
  const totalAmountRow: TableCellInterface[] = [
    {
      type: TableCellTypeEnum.ENTRIES,
      text: formatMessage({ id: 'Fundsheet_Tab1_MultiFund_Position_at_date_title' }),
    },
  ];
  // map overview to add data cells to row
  fundsOverview?.forEach((fundOverview, index) => {
    const isDataInvalid = (data: any) => {
      return (
        data === null ||
        data === '' ||
        data === undefined ||
        data?.length === 0
      );
    };

    const repartitionsSupports = multiFundsRepartitionSupports.find(repartitionsSupport => {
      return repartitionsSupport.supportIsin === fundOverview.isin;
    });

    const invalidDataPlaceholder = <Text
      variant="t3"
      weight="light">
      {formatMessage({ id: 'Funsheet_DataUnavailable' })}
    </Text>;

    // HEADER
    headerRow.push({
      type: TableCellTypeEnum.HEADER,
      text: fundOverview.name,
    });
    // RISK
    const riskLevel = fundOverview?.risqueSRI || fundOverview?.risqueSRRI || '';
    const isRiskSRRI = !!fundOverview?.risqueSRRI
    const isRiskLevelValid =
      (typeof riskLevel === 'string' || typeof riskLevel === 'number') &&
      riskLevel !== 'Inconnu';
    const riskLevelText = isRiskLevelValid
      ? `${riskLevel}/7 ${(isRiskSRRI && !isAllRiskSRRI) ?
        `(${formatMessage({ id: 'Fundsheet_Tab1_risk-level_SRRI_Old_title' })})`
        : ''}`
      : formatMessage({ id: 'Saving_Tab1_item_unknownRisklevel_label' });
    riskLevelRow.push({
      type: TableCellTypeEnum.DEFAULT,
      text: riskLevelText,
      ...(isDataInvalid(riskLevel) && {
        children: invalidDataPlaceholder,
      }),
    });
    // INVESTMENT PERIOD
    const investmentPeriodText = fundOverview.dureePlacement;
    recommendedInvestmentPeriodRow.push({
      type: TableCellTypeEnum.DEFAULT,
      text: investmentPeriodText,
      ...(isDataInvalid(investmentPeriodText) && {
        children: invalidDataPlaceholder,
      }),
    });
    // VOLATILITY
    const volatilityPercent = fundOverview.volatility;
    const volatilityText = `${Math.round((volatilityPercent + Number.EPSILON) * 100) / 100
      }%`;
    volatilityRow.push({
      type: TableCellTypeEnum.DEFAULT,
      text: volatilityText,
      ...(isDataInvalid(volatilityPercent) && {
        children: invalidDataPlaceholder,
      }),
    });
    // CLASSIFICATION
    const classificationText = fundOverview.classification;
    classificationRow.push({
      type: TableCellTypeEnum.DEFAULT,
      text: classificationText,
      ...(isDataInvalid(classificationText) && {
        children: invalidDataPlaceholder,
      }),
    });
    // GEOGRAPHICAL ZONE
    const zoneGeographText = fundOverview.zoneGeograph;
    zoneGeographRow.push({
      type: TableCellTypeEnum.DEFAULT,
      text: zoneGeographText,
      ...(isDataInvalid(zoneGeographText) && {
        children: invalidDataPlaceholder,
      }),
    });
    // FUND TYPE
    const fundTypeTextList: string[] = [];
    if (fundOverview?.isSolidaire) {
      fundTypeTextList.push(formatMessage({ id: 'MyFunds_Screen1_Solidaire_label' }));
    }

    if (fundOverview?.isISR) {
      fundTypeTextList.push(formatMessage({ id: 'MyFunds_Screen1_ISR_label' }));
    }
    fundTypeRow.push({
      type: TableCellTypeEnum.DEFAULT,
      text: fundTypeTextList.join(', '),
      ...(fundTypeTextList.length === 0 && {
        children: invalidDataPlaceholder,
      }),
    });
    // ELIGIBLE FUNDS - CHILDREN & MOCK

    const planCellData: PlansCellItemProps['data'] | undefined = repartitionsSupports
      && repartitionsSupports?.plans?.map(plan => {
        const descriptions: string[] = [];
        if (plan.hasInteressement) {
          descriptions.push(formatMessage({ id: 'Fundsheet_Tab1_Interessement_fund_label' }));
        }
        if (plan.hasParticipation) {
          descriptions.push(formatMessage({ id: 'Fundsheet_Tab1_Participation_fund_label' }));
        }
        if (plan.hasVersement) {
          descriptions.push(formatMessage({ id: 'Fundsheet_Tab1_Versement_Volontaire_fund_label' }));
        }
        return {
          name: plan.planName,
          description: descriptions.join(', '),
          hasContribution: plan.hasContribution,
        };
      });
    eligibleFundsRow.push({
      type: TableCellTypeEnum.DEFAULT,
      children: (
        <PlansCell
          data={planCellData || []}
        />
      ),
      ...(!repartitionsSupports && {
        children: invalidDataPlaceholder,
      }),
    });
    // VALUATION PERIOD
    const valuationPeriodText = fundOverview?.periodiciteValorisation ? formatMessage({
      id: `FundSheetPricingFrequency${fundOverview?.periodiciteValorisation}`
    }) : '';
    !!fundOverview?.periodiciteValorisation &&
      valuationPeriodRow.push({
        type: TableCellTypeEnum.DEFAULT,
        text: valuationPeriodText,
        ...(isDataInvalid(fundOverview?.periodiciteValorisation) && {
          children: invalidDataPlaceholder
        })
      });
    // TOTAL AMOUNT - CHILDREN & MOCK
    totalAmountRow.push({
      type: TableCellTypeEnum.DEFAULT,
      children: repartitionsSupports?.amount && (
        <TotalAmountCell
          data={{
            totalAmount: repartitionsSupports.amount.amount,
            totalShares: repartitionsSupports.amount.numberOfUnits,
            date: repartitionsSupports?.netAssetValue?.dateValue,
            plans: repartitionsSupports.plans?.map(plan => {
              return {
                name: plan.planName,
                amount: plan.totalInvestedAmount,
                shares: plan.totalNumberOfUnits,
              };
            }),
          }}
        />
      ),
      ...(!repartitionsSupports && {
        children: invalidDataPlaceholder,
      }),
    });
  });

  // insert row in table
  tableOverViewData.push(headerRow);
  tableOverViewData.push(riskLevelRow);
  tableOverViewData.push(recommendedInvestmentPeriodRow);
  tableOverViewData.push(volatilityRow);
  tableOverViewData.push(classificationRow);
  tableOverViewData.push(zoneGeographRow);
  // this row should not be displayed if no fund got isISR or isSolidaire
  // disabled until geen label is reactivated by MOA
  // const isFundTypeRowDisplayed = fundsOverview?.filter(fundOverview=>{
  //   return fundOverview.isISR || fundOverview.isSolidaire;
  // })?.length > 0;
  // isFundTypeRowDisplayed && tableOverViewData.push(fundTypeRow);
  tableOverViewData.push(eligibleFundsRow);
  tableOverViewData.push(valuationPeriodRow);
  tableOverViewData.push(totalAmountRow);

  // PERFORMANCE TABLE
  const fundsPerformanceHeaderRow: TableCellInterface[] = [
    {
      type: TableCellTypeEnum.EMPTY,
    },
  ];
  const lastValuationRow: TableCellInterface[] = [
    {
      type: TableCellTypeEnum.ENTRIES,
      text: formatMessage({
        id: 'Fundsheet_Tab3Perf_Last_Valuation_Date_title',
        values: { variable: '' },
      }),
    },
  ];
  const YTDRow: TableCellInterface[] = [
    {
      type: TableCellTypeEnum.ENTRIES,
      text: formatMessage({ id: 'Fundsheet_Tab3Perf_Perf_YTD_title' }),
    },
  ];
  const OneYearRow: TableCellInterface[] = [
    {
      type: TableCellTypeEnum.ENTRIES,
      text: formatMessage({ id: 'Fundsheet_Tab3Perf_Performance_graph_period_1year' }),
    },
  ];
  const ThreeYearsRow: TableCellInterface[] = [
    {
      type: TableCellTypeEnum.ENTRIES,
      text: formatMessage({ id: 'Fundsheet_Tab3Perf_Performance_graph_period_3year' }),
    },
  ];
  const FiveYearsRow: TableCellInterface[] = [
    {
      type: TableCellTypeEnum.ENTRIES,
      text: formatMessage({ id: 'Fundsheet_Tab3Perf_Performance_graph_period_5year' }),
    },
  ];
  fundsPerformance?.forEach((fundsPerformance, index) => {
    // PREPARE PERCENT CELL TO RENDER
    const findArrayElementByName = (name: string) => {
      return fundsPerformance?.sharePerf?.find((element: any) => {
        return element.name === name;
      });
    };

    const renderValues = (name: string) => {
      const value = findArrayElementByName(name)?.value?.toFixed(2);
      return value;
    };
    const renderedText = (perfName: string) => {
      const perfValue = Number(renderValues(perfName));
      if (isNaN(Number(perfValue))) {
        return (
          <Text
            variant="t3"
            weight="light">
            {formatMessage({ id: 'Funsheet_DataUnavailable' })}
          </Text>
        );
      }
      const textColor =
        perfValue < 0
          ? theme.colors.notifications.warning.c500
          : theme.colors.basics.primary.c500;
      let rendredText =
        perfValue > 0 ? `+ ${perfValue}` : `- ${Math.abs(perfValue)}`;
      if (perfValue === 0) {
        rendredText = `${perfValue}`;
      }
      return (
        <Text
          style={{ color: textColor }}
          variant="t3"
          weight="light">
          {`${rendredText} % `}
        </Text>
      );
    };

    // HEADER
    fundsPerformanceHeaderRow.push({
      type: TableCellTypeEnum.HEADER,
      text: fundsPerformance.name,
    });
    // LAST lastValuationRow
    const lastHistoYTD = fundsPerformance?.sharePerf?.find(e => e?.name === 'YTD')?.date;
    const lastYTDDateText = formatDate({
      value: lastHistoYTD,
    });
    lastValuationRow.push({
      type: TableCellTypeEnum.DEFAULT,
      text: lastYTDDateText,
    });
    YTDRow.push({
      type: TableCellTypeEnum.DEFAULT,
      children: renderedText('YTD'),
    });
    OneYearRow.push({
      type: TableCellTypeEnum.DEFAULT,
      children: renderedText('1Y'),
    });
    ThreeYearsRow.push({
      type: TableCellTypeEnum.DEFAULT,
      children: renderedText('3Y'),
    });
    FiveYearsRow.push({
      type: TableCellTypeEnum.DEFAULT,
      children: renderedText('5Y'),
    });
  });

  tablePerformanceData.push(fundsPerformanceHeaderRow);
  tablePerformanceData.push(lastValuationRow);
  tablePerformanceData.push(YTDRow);
  tablePerformanceData.push(OneYearRow);
  tablePerformanceData.push(ThreeYearsRow);
  tablePerformanceData.push(FiveYearsRow);

  return (
    <View style={styles.containerStyle}>
      <Table data={tableOverViewData} />
      <Text
        style={styles.titleStyle}
        variant="t3"
        weight="bold"
      >
        {formatMessage({ id: 'Fundsheet_Tab3Perf_Performance_synthesis_title' })}
      </Text>
      <Table
        containerStyle={styles.PerfTableStyle}
        data={tablePerformanceData}
      />
      <Text
        style={styles.PerfTableSubText01Style}
        variant="t3"
      >
        {formatMessage({ id: 'Fundsheet_Tab3Perf_Warning_net_valuation_legend' })}
      </Text>
      <Text
        style={styles.PerfTableSubText02Style}
        variant="t3"
        weight="light"
      >
        {formatMessage({ id: 'Fundsheet_Tab3Perf_Warning_past_performances_legend' })}
      </Text>
    </View>
  );
};

const useStyles = createUseStyles(getStyles);
