import { AuthActionType, AuthenticateAction } from '@modules/auth/actions/authActionsTypes';
import { ErrorActionsType } from '@modules/error/actions/errorActionsTypes';
import { ForgetPasswordState } from '@modules/forget-password/types';
import {
  ForgetPasswordAction,
  ForgetPasswordActionsType
} from './actions/forgetPasswordActionsTypes';

export const initialState: ForgetPasswordState = {
  forgetPasswordInitBegin: {
    uiidRequestedCode: ''
  },
  forgetPasswordConfirmEmail: {
    phoneNumber: ''
  },
  forgetPasswordConfirmSMS: {
    status: false
  },
  forgetPasswordConfirmSMSCode: {
    status: false
  },
  forgetPasswordConfirm: {
    status: false
  },
  emailInput: '',
  error: {
    code: '',
    correlationId: '',
    message: '',
    innerMessage: ''
  }
};

export default function forgetPasswordReducer(
    state: ForgetPasswordState = initialState,
    action: ForgetPasswordAction | AuthenticateAction,
): ForgetPasswordState {
    switch (action?.type) {
    case ForgetPasswordActionsType.SET_FORGET_PASSWORD_EMAIL_INPUT:
        return {
            ...state, emailInput: action.email,
        };
    case ForgetPasswordActionsType.FORGET_PASSWORD_INIT_BEGIN_SUCCESS:
        return {
            ...state, forgetPasswordInitBegin: { uiidRequestedCode: action.uiidRequestedCode },
        };
    case ForgetPasswordActionsType.FORGET_PASSWORD_CONFIRM_EMAIL_SUCCESS:
        return {
            ...state,
            forgetPasswordConfirmEmail: { phoneNumber: action.phoneNumber },
        };
    case ForgetPasswordActionsType.FORGET_PASSWORD_CONFIRM_SMS_SUCCESS:
        return {
            ...state, forgetPasswordConfirmSMS: { status: action.status },
        };
    case ForgetPasswordActionsType.FORGET_PASSWORD_CONFIRM_SMS_CODE_SUCCESS:
        return {
            ...state, forgetPasswordConfirmSMSCode: { status: action.status },
        };
    case ForgetPasswordActionsType.FORGET_PASSWORD_CONFIRM_SUCCESS:
        return {
            ...state, forgetPasswordConfirm: { status: action.status },
        };
    case ForgetPasswordActionsType.FORGET_PASSWORD_CONFIRM_EMAIL_FAILURE:
        return {
            ...state, error: action.error,
        };
    case ForgetPasswordActionsType.FORGET_PASSWORD_INIT_BEGIN_FAILURE:
        return {
            ...state, error: action.error,

        };
    case ForgetPasswordActionsType.FORGET_PASSWORD_CONFIRM_SMS_FAILURE:
        return {
            ...state, error: action.error,

        };
    case ForgetPasswordActionsType.FORGET_PASSWORD_CONFIRM_SMS_CODE_FAILURE:
        return {
            ...state, error: action.error,

        };
    case ForgetPasswordActionsType.FORGET_PASSWORD_CONFIRM_FAILURE:
        return {
            ...state, error: action.error,

        };
    case ForgetPasswordActionsType.FORGET_PASSWORD_INIT_ERROR:
    case ErrorActionsType.INIT_ALL_ERRORS:
        return {
            ...state, error: initialState.error,
        };
    case AuthActionType.LOGOUT_CALLBACK_SUCCESS:
        return initialState;
    default:
        return state;
    }
}
