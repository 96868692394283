import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useScreenSizes, useTheme } from '@ere-uilib/styles';

export function useStyles() {
  const { isDesktop } = useScreenSizes();
  const theme = useTheme();

  const styles = useMemo(() => StyleSheet.create({
    identifierField: {
      flexDirection: 'row',
      alignSelf: isDesktop ? 'flex-start' : 'center',
      alignItems: 'center',
    },
    inputLabel: { color: theme.colors.basics.grey.c400 },
  }), [isDesktop, theme]);

  return styles;
}
