import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';

import {
  CardResponse,
  HelpPoint,
  IconEnum,
  SimpleButton,
  TextField,
  useTranslation
} from '@components/ERE-UILib';
import { LoaderPlaceholderView, Text } from '@ere-uilib/atoms';
import { FetchAnnuityResultsData } from '@modules/annuity/types';

import { SimulationType, SimulationTypeOperation } from '../../AnnuitySimulator/sharedInterfaces';
import { AMOUNT_MASK } from '../../constants';
import { AnnuityResultsTable } from '../AnnuityResultsTable';
import { useStyles } from './useStyles';

const GUARANTEED_ANNUITIES_DEFAULT = 5;

type AnnuityResultsTableProps = React.ComponentPropsWithoutRef<typeof AnnuityResultsTable>;
type HandleChange = AnnuityResultsTableProps['onChange'];
type SelectsValue = Pick<AnnuityResultsTableProps, 'reversionRate' | 'guaranteedAnnuities'>;
type SubmitValue = SelectsValue & { amount: string };

interface Props
  extends Pick<AnnuityResultsTableProps, 'reversionRatesList' | 'guaranteedAnnuitiesList'> {
  isLoading?: boolean;
  simulationType: SimulationType | undefined;
  reversionRate: number | undefined;
  resultsData: FetchAnnuityResultsData;
  onSubmit: (value: SubmitValue) => void;
  validator: (
    operation: SimulationTypeOperation | undefined
  ) => (amount: string | undefined) => boolean;
}

export const AnnuityResult: React.FC<React.PropsWithChildren<Props>> = ({
  isLoading = false,
  reversionRate,
  resultsData,
  onSubmit,
  reversionRatesList,
  guaranteedAnnuitiesList,
  validator,
  simulationType
}) => {
  const styles = useStyles();
  const { formatMessage, getMessageRaw } = useTranslation();

  const [amount, setAmount] = useState<string | undefined>(simulationType?.amount);
  const [hasRegexError, setHasRegexError] = useState<boolean>(false);
  const [selectsValue, setSelectsValue] = useState<SelectsValue>({
    reversionRate,
    guaranteedAnnuities: GUARANTEED_ANNUITIES_DEFAULT
  });

  const validate = useMemo(() => validator(simulationType?.operation), [validator, simulationType]);

  const isValid = useMemo(() => validate(amount), [amount, validate]);

  const isComputeButtonDisabled =
    hasRegexError || !amount || amount === simulationType?.amount || !isValid;

  const isCapitalToAnnuity = simulationType?.operation === SimulationTypeOperation.CapitalToAnnuity;

  const tableTitleLabelId = isCapitalToAnnuity
    ? 'PensionSimulatorOutcomelSimulationTitle'
    : 'PensionSimulatorOutcomeMonthlyPaymentSimulationTitle';
  const amountInputTitleLabelId = isCapitalToAnnuity
    ? 'PensionSimulatorCapitalSimulationOutcomeSubTitle'
    : 'PensionSimulatorMonthlyPaymentSimulationOutcomeSubTitle';
  const tableSubtitleLabelId = isCapitalToAnnuity
    ? 'PensionSimulatorOutcomeCapitalSimulationHelpLink'
    : 'PensionSimulatorOutcomeMonthlyPaymentSimulationHelpLink';
  const annuityHelpPointContentBody = isCapitalToAnnuity
    ? 'PensionSimulatorOutcomeCapitalSimulationHelpHypotheseBody'
    : 'PensionSimulatorOutcomeMonthlyPaymentSimulationHelpHypotheseBody';
  const annuityHelpPointContentHeader = isCapitalToAnnuity
    ? 'PensionSimulatorOutcomeCapitalSimulationHelpHypotheseHeader'
    : 'PensionSimulatorOutcomeMonthlyPaymentSimulationHelpHypotheseHeader';

  const handleSubmit = useCallback(
    (value: SelectsValue) => {
      if (amount) onSubmit({ amount, ...value });
    },
    [amount, onSubmit]
  );

  const handleCleanField = useCallback(() => setAmount(undefined), []);

  const handleChange = useCallback<HandleChange>(
    (key, value) =>
      setSelectsValue(prev => {
        const nextValue = {
          ...prev,
          [key]: value
        };
        handleSubmit(nextValue);
        return nextValue;
      }),
    [handleSubmit]
  );

  const renderTableLoader = useCallback(
    () => (
      <View style={styles.tableLoader}>
        <LoaderPlaceholderView
          style={[styles.loaderPlaceholderView, styles.loaderPlaceholderViewHalfLength]}
        />
        <LoaderPlaceholderView style={[styles.loaderPlaceholderView]} />
        <LoaderPlaceholderView style={[styles.loaderPlaceholderView]} />
      </View>
    ),
    [styles]
  );

  useEffect(() => {
    handleSubmit(selectsValue);
  }, []);

  return (
    (<CardResponse
      containerStyle={styles.cardResponseContainer}
      headerIconName={IconEnum.SEARCH}
      headerTitle={formatMessage({ id: 'PensionSimulatorMonthlyPaymentSimulationOutcomeTitle' })}>
      <View style={styles.cardResponseChildContainer}>
        <Text variant="t3">
          {formatMessage({
            id: amountInputTitleLabelId
          })}
        </Text>
        <View style={styles.rowContainer}>
          <TextField
            containerStyle={styles.textFieldStyle}
            errored={hasRegexError || (!!amount && !isValid)}
            inputProps={{
              value: amount || '',
              placeholder: formatMessage({ id: 'PensionSimulatorCapitalLegendBox' }),
              onChangeText: setAmount,
              editable: true,
              keyboardType: 'numeric',
              mask: AMOUNT_MASK
            }}
            inputStyle={styles.textFieldInputStyle}
            labelOnTop
            onCleanField={amount ? handleCleanField : undefined}
            regexError={setHasRegexError}
            regexPattern={/^[0-9]+$/}
            rightIconsStyle={styles.textFieldRightIcon}
          />
          {!isComputeButtonDisabled && (
            <View>
              <SimpleButton
                containerStyle={styles.buttonCompute}
                loading={isLoading}
                loadingTitle={formatMessage({
                  id: 'PensionSimulatorCapitalSimulationRecalculationButtonLoading'
                })}
                onPress={() => handleSubmit(selectsValue)}
                size="small"
                title={formatMessage({
                  id: 'PensionSimulatorCapitalSimulationRecalculationButton'
                })}
              />
            </View>
          )}
        </View>
      </View>
      <View style={[styles.cardResponseChildContainer, styles.tableContainer]}>
        <Text variant="t3">{formatMessage({ id: tableTitleLabelId })}</Text>
        <HelpPoint
          contentHtml={getMessageRaw({ id: annuityHelpPointContentBody })}
          modalTitle={formatMessage({ id: annuityHelpPointContentHeader })}>
          <Text
            style={styles.link}
            variant="t4"
            weight="regular">
            {formatMessage({ id: tableSubtitleLabelId })}
          </Text>
        </HelpPoint>
        {isLoading && renderTableLoader()}
        {!isLoading && (
          <AnnuityResultsTable
            guaranteedAnnuities={selectsValue.guaranteedAnnuities}
            guaranteedAnnuitiesList={guaranteedAnnuitiesList}
            onChange={handleChange}
            resultsData={resultsData}
            reversionRate={selectsValue.reversionRate}
            reversionRatesList={reversionRatesList}
          />
        )}
      </View>
    </CardResponse>)
  );
};
