import { FilesCategoriesEnum } from '@ere-uilib/enums';

export enum DocumentTypeOptionValue {
  ID_CARD = 'ID_CARD',
  PASSPORT = 'PASSPORT',
  RESIDENCE_PERMIT = 'RESIDENCE_PERMIT',
  IBAN = 'IBAN'
}

type FilePickerData = {
  [p: string]: Array<{
    documentKey: FilesCategoriesEnum;
    loadingTitleId: string;
    titleId: string;
  }>;
};

export const FILE_PICKER_DATA: FilePickerData = {
  [DocumentTypeOptionValue.ID_CARD]: [
    {
      documentKey: FilesCategoriesEnum.ID_CARD_RECTO,
      loadingTitleId: 'CoordonneesBancairesUploadCNIRectoBouton',
      titleId: 'CoordonneesBancairesUploadCNIRectoBouton'
    },
    {
      documentKey: FilesCategoriesEnum.ID_CARD_VERSO,
      loadingTitleId: 'CoordonneesBancairesUploadCNIVersoBouton',
      titleId: 'CoordonneesBancairesUploadCNIVersoBouton'
    }
  ],
  [DocumentTypeOptionValue.PASSPORT]: [
    {
      documentKey: FilesCategoriesEnum.PASSPORT,
      loadingTitleId: 'CoordonneesBancairesUploadPasseportBouton',
      titleId: 'CoordonneesBancairesUploadPasseportBouton'
    }
  ],
  [DocumentTypeOptionValue.RESIDENCE_PERMIT]: [
    {
      documentKey: FilesCategoriesEnum.RESIDENCE_PERMIT_RECTO,
      loadingTitleId: 'CoordonneesBancairesUploadTitreSejourRectoBouton',
      titleId: 'CoordonneesBancairesUploadTitreSejourRectoBouton'
    },
    {
      documentKey: FilesCategoriesEnum.RESIDENCE_PERMIT_VERSO,
      loadingTitleId: 'CoordonneesBancairesUploadTitreSejourVersoBouton',
      titleId: 'CoordonneesBancairesUploadTitreSejourVersoBouton'
    }
  ],
  [DocumentTypeOptionValue.IBAN]: [
    {
      documentKey: FilesCategoriesEnum.IBAN,
      loadingTitleId: 'CoordonneesBancairesUploadRib',
      titleId: 'CoordonneesBancairesUploadRib'
    }
  ]
};
