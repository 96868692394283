import React  from 'react';
import { View } from 'react-native';

import { Title } from '@ere-uilib/atoms';
import { IconEnum } from '@ere-uilib/enums';
import { SimpleButton } from '@ere-uilib/molecules';
import { createUseStyles, useTheme, useScreenSizes } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';

import { Props } from './interfaces';
import { getStyles } from './styles';

export const RetryActionPlaceHolder: React.FC<React.PropsWithChildren<Props>> = ({
  containerStyle,
  title,
  retryAction,
}) => {
  const theme = useTheme();
  const { isMobile } = useScreenSizes();
  const { formatMessage } = useTranslation();
  const styles = useStyles(
    { theme, isMobile: isMobile },
    { containerStyle },
  );

  return (
    <View style={styles.containerStyle}>
      <Title
        style={styles.titleStyle}
        variant="t7"
        weight="light"
      >
        {title || formatMessage({ id: 'Dashboard_Cards_Retry_label' })}
      </Title>
      {retryAction && (
        <View style={styles.buttonContainer}>
          <SimpleButton
            containerStyle={styles.buttonStyle}
            design="outlined"
            onPress={retryAction}
            rightIcon={IconEnum.CHEVRON_RIGHT}
            rightIconStyle={styles.buttonIconStyle}
            size="small"
            textStyle={styles.buttonTextStyle}
            title={formatMessage({ id: 'Dashboard_Cards_Retry_button' })}
          />
        </View>
      )}
    </View>
  );
};

const useStyles = createUseStyles(getStyles);
