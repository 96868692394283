import React, { useCallback, useEffect, useState } from 'react'

import { AmountFieldModal360 } from '@components/index'
import { useTranslation } from '@ere-uilib/translations'
import { ArbitrationDueDateAmountFieldModalConnectedProps, ModalData } from './interface'
import { ArbitrationDueDateAmountFieldModalConnectedRefType } from './sharedInterfaces'

export const ArbitrationDueDateAmountFieldModalConnectedComponent = React.forwardRef<
    ArbitrationDueDateAmountFieldModalConnectedRefType,
    ArbitrationDueDateAmountFieldModalConnectedProps
>(
    (
        { onSetArbitrationDueDateAmount, onSetArbitrationDueDateAmountModalVisibleState, visible },
        ref
    ) => {
      const defaultData: ModalData = {
        displayData: {
          title: '',
          initialAmount: undefined
        }
      }
      const [data, setData] = useState<ModalData>(defaultData)
      const { formatMessage } = useTranslation()
      // allow method via ref
      React.useImperativeHandle(ref, () => ({
        openWithData: receivedData => {
          const allAmountValue = receivedData?.displayData?.allAmountValue || 0
          const amount = receivedData?.displayData?.initialAmount || 0
          const allAmountLabel =
              amount > 0 && amount < allAmountValue
                  ? formatMessage({ id: 'MaturityArbitrageFeatureDivestTheRest' })
                  : formatMessage({ id: 'MaturityArbitrageFeatureDivestAll' })

          onSetArbitrationDueDateAmountModalVisibleState(true)
          setData({
            ...receivedData,
            displayData: {
            ...receivedData.displayData,
            allAmountLabel: allAmountLabel
          }
        })
      }
    }))

    useEffect(() => {
      if (!visible) {
        setData(defaultData)
      }
    }, [visible])

    const onCancelPressed = useCallback(() => {
      onSetArbitrationDueDateAmountModalVisibleState(false)
      setData(defaultData)
    }, [onSetArbitrationDueDateAmountModalVisibleState])

    const onValidPressed = (amount?: number | null) => {
      if (data.supportIsIn && data.compartmentId && data.managementId) {
        onSetArbitrationDueDateAmount({
          amount: amount || 0,
          compartmentId: data.compartmentId,
          managementId: data.managementId,
          supportIsin: data.supportIsIn,
          selectState: true,
          triggerThreshold: data.triggerThreshold,
          dueDateOption: {
            code: data.displayData.dueDateOption?.code,
            date: data.displayData.dueDateOption?.date,
            amount: data.displayData.dueDateOption?.amount
          }
        })
        onSetArbitrationDueDateAmountModalVisibleState(false)
      }
    }
    return (
      <AmountFieldModal360
        data={data.displayData}
        isValidButtonLoading={false}
        onCancel={onCancelPressed}
        onValid={onValidPressed}
        visible={visible}
      />
    )
  }
)
