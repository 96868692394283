import { FontWeight } from '@ere-uilib/styles';
import { InputMode } from '@modules/common/constants';

type Paragraph = {
  value: string;
  weight?: FontWeight;
};

type LabelByInputMode<T = string> = {
  [inputMode in InputMode | 'default']: T;
};

interface Label {
  descriptions: Partial<LabelByInputMode<(address?: string) => Paragraph[]>>;
  button: {
    next: Partial<LabelByInputMode>;
  };
}

export const label: Label = {
  descriptions: {
    default: () => [{ value: 'PostalAdressPageMailingInformations_TSA2' }],
    [InputMode.TSA]: () => [{ value: 'PostalAdressPageMailingInformations_TSA2' }],
    [InputMode.TSA_OR_UPLOAD]: (address = '') => [
      { value: 'PostalAdressPageMailingInformations_TSA_OR_UPLOAD' },
      { value: 'PostalAdressPageMailingInformations_TSA_OR_UPLOAD2', weight: 'bold' },
      { value: address, weight: 'light' },
      { value: 'PostalAdressPageDigitalUpload_TSA_OR_UPLOAD', weight: 'bold' }
    ],
    [InputMode.CORRESPONDANT]: () => [
      { value: 'PostalAdressPageMailingInformations_CORRESPONDANT' }
    ],
    [InputMode.SERVICE_RH]: () => [{ value: 'PostalAdressPageMailingInformations_SERVICE_RH' }]
  },
  button: {
    next: {
      default: 'PostalAdressPageBackToProfilMenuButton',
      [InputMode.TSA_OR_UPLOAD]: 'PostalAdressPage_TSA_OR_UPLOAD_ContinueButton'
    }
  }
};
