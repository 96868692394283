import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import { useSelector } from 'react-redux';

import { RouteNames } from '@constants/navigation';
import { AppState } from '@modules/reducers';
import {
  getInstallmentDecision,
  getInstallmentEditedVVPDtata,
} from '@modules/installment/selectors';
import { InstallmentNavigatorInterface } from '@navigation/Interfaces';
import { useDefaultScreensOptions } from '@navigation/useDefaultScreensOptions';
import {
  InstallmentLoadingPage,
  InstallmentRepartitionPage,
  RequirementDefinitonPage,
  InstallmentPlanChoicePage,
  InstallmentsPaymentModePage,
  InstallmentSynthesisPage,
  InstallmentFailedPage,
  InstallmentSuccessPage,
  InstallmentPage,
  InstallmentPaymentCBHtmlPage
} from '@pages/Installments';

import {ConfirmModalPageComponent } from "@pages/Common";

const InstallmentStack = createStackNavigator<InstallmentNavigatorInterface>();

export const InstallmentsStackNavigator = () => {
  const defaultScreensOptions = useDefaultScreensOptions();
  const installmentDecisionData = useSelector((state: AppState) => getInstallmentDecision(state));
  const installmentEditedVVPDtata = useSelector((state: AppState) => getInstallmentEditedVVPDtata(state));
  const isDataAvailable = !!installmentDecisionData?.questions?.length || !!installmentEditedVVPDtata;

  return (
    <InstallmentStack.Navigator
      initialRouteName={RouteNames.Installments}
      screenOptions={{
        gestureEnabled: false,
        headerShown: false,
      }}
    >
      <InstallmentStack.Screen
        component={InstallmentPage}
        name={RouteNames.Installments}
        options={defaultScreensOptions.Installments}
      />
      { isDataAvailable && (
        <>
          <InstallmentStack.Screen
            component={InstallmentRepartitionPage}
            name={RouteNames.InstallmentsRepartition}
            options={defaultScreensOptions.InstallmentsRepartition}
          />
          <InstallmentStack.Screen
            component={InstallmentSynthesisPage}
            name={RouteNames.InstallmentsSynthesis}
            options={defaultScreensOptions.InstallmentsSynthesis}
          />
          <InstallmentStack.Screen
            component={InstallmentsPaymentModePage}
            name={RouteNames.InstallmentsPaymentMode}
            options={defaultScreensOptions.InstallmentsPaymentMode}
          />
          <InstallmentStack.Screen
            component={RequirementDefinitonPage}
            name={RouteNames.InstallmentsRequirementDefiniton}
            options={defaultScreensOptions.InstallmentsRequirementDefiniton}
          />
          <InstallmentStack.Screen
            component={InstallmentPlanChoicePage}
            name={RouteNames.InstallmentPlanChoice}
            options={defaultScreensOptions.InstallmentsRequirementDefiniton}
          />
          <InstallmentStack.Screen
            component={InstallmentPaymentCBHtmlPage}
            name={RouteNames.InstallmentPaymentCBHtml}
            options={defaultScreensOptions.InstallmentPaymentCBHtmlPage}
          />
          <InstallmentStack.Screen
            component={InstallmentLoadingPage}
            name={RouteNames.InstallmentLoading}
            options={defaultScreensOptions.InstallmentLoadingPage}
          />
          <InstallmentStack.Screen
            component={InstallmentSuccessPage}
            name={RouteNames.InstallmentSuccess}
            options={defaultScreensOptions.InstallmentSuccessPage}
          />
          <InstallmentStack.Screen
            component={InstallmentFailedPage}
            name={RouteNames.InstallmentFailed}
            options={defaultScreensOptions.InstallmentFailedPage}
          />
        </>
      )}
      <InstallmentStack.Screen
        component={ConfirmModalPageComponent}
        name={RouteNames.InstallmentModal}
        options={defaultScreensOptions.ConfirmModal}
      />
    </InstallmentStack.Navigator>
  );
};
