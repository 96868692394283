import React, { useCallback } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

import { Title, Text } from '@ere-uilib/index';
import { InputMode } from '@modules/common/constants';
import { BankMailAddressType } from '@modules/bank-details/types';

import { useLabels } from '../../hooks';
import { useStyles } from './useStyles';

interface Props {
  tsaAddress?: BankMailAddressType | null,
  inputMode?: string | null,
  status?: string | null,
}

interface StyleProps {
  stepperContainer?: StyleProp<ViewStyle>
  stepperStyle?: StyleProp<ViewStyle>
}

export const MailSendingDocuments: React.FC<React.PropsWithChildren<Props & StyleProps>> = ({ tsaAddress, inputMode, status }) => {
  const styles = useStyles();
  const labels = useLabels();
  const renderMailData = () => {
    if (!tsaAddress) return null;
    return (
      <View style={styles.mailDataContainer}>
        <Text
          variant="t2"
          weight="light">
          {`${tsaAddress?.receiver} ${tsaAddress?.precision} `}
        </Text>
        <Text
          variant="t2"
          weight="light">
          {`${tsaAddress?.zipCode} ${tsaAddress?.city}`}
        </Text>
        <Text
          variant="t2"
          weight="light">
          {tsaAddress?.country}
        </Text>
      </View>
    )
  }

  const renderTSAORUPLOADMode = useCallback(() => {
    if (inputMode !== InputMode.TSA_OR_UPLOAD || !tsaAddress) { return null; }

    return (
      <>
        <Title
          style={styles.titleStyles}
          variant="t7"
          weight="bold">
          {labels.courrierTitle}
        </Title>
        <Text
          variant="t3"
          weight="regular">
          {labels.courrierDescription}
        </Text>
        {renderMailData()}
      </>
    )

  }, [
    inputMode,
    tsaAddress,
  ]);

  const renderTSAMode = useCallback(() => {
    if (inputMode !== InputMode.TSA) { return null; }
    if (!tsaAddress) return null;
    return (
      <>
        <Text
          variant="t3"
          weight="regular">
          {labels.courrierEnvoiDestinationLabel}
        </Text>
        {renderMailData()}
      </>
    )

  }, [
    inputMode,
    tsaAddress
  ]);

  const renderRHMode = useCallback(() => {
    if (inputMode !== InputMode.SERVICE_RH) { return null; }

  }, [
    inputMode
  ]);

  const renderContent = useCallback(() => {
    return (
      <>
        {renderTSAORUPLOADMode()}
        {renderTSAMode()}
        {renderRHMode()}
      </>
    )
  }, [
    inputMode
  ]);

  return (
    <View style={styles.container}>
      {renderContent()}
    </View>
  );
};
