import { CompareFundsInnerPageStylesContext, CompareFundsInnerPageStyles } from './interfaces';

export const getStyles = (
  context?: CompareFundsInnerPageStylesContext,
  style?: CompareFundsInnerPageStyles
): CompareFundsInnerPageStyles => ({
  closeButtonStyle: [
    {
      alignItems: 'flex-end',
      marginRight: context?.theme.metrics.spacing.xm,
      marginTop: context?.theme.metrics.spacing.xm,
    },
    style?.closeButtonStyle,
  ],
  compareTitleStyle: [
    {
      marginTop: context?.theme.metrics.spacing.m,
      padding: context?.theme.metrics.spacing.s,
      marginBottom: context?.theme.metrics.spacing.xl,
      alignItems: 'center',
    },
    style?.closeButtonStyle,
  ],
  compareHeaderDetailsStyle: [
    {
      textAlign: 'center',
      marginTop: context?.theme.metrics.spacing.s,
    },
    style?.compareHeaderDetailsStyle,
  ],
  fundsSelectContainerStyle: [{
    alignSelf: 'center',
    marginBottom: context?.theme.metrics.spacing.l,
    width: '100%',
  },
  style?.fundsSelectContainerStyle],
  scrollableContentStyle: [{
    paddingHorizontal: context?.isMobile ?
      context?.theme.metrics.spacing.s :  context?.theme.metrics.spacing.huge,
  },
  style?.scrollableContentStyle,
  ],
  scrollSelectFundsContainerStyle: [{
    height: '100%',
  },
  style?.scrollSelectFundsContainerStyle],
  scrollSelectFundsStyle: [{
    overflow: 'hidden',
    alignSelf: 'center',
    width: '100%',
  },
  style?.scrollSelectFundsStyle],
  fundSelectFrameStyle: [
    {
      borderTopWidth: 1,
      borderColor: context?.theme.colors.basics.grey.c200,
    },
    style?.fundSelectFrameStyle,
  ],
  roundedIconStyle: [{ alignItems: 'center',
    height: 20,
    width: 20,
    marginRight: context?.theme.metrics.spacing.xs,
    backgroundColor: context?.theme.colors.basics.grey.c100,
  }, style?.roundedIconStyle],
  fundsKeysStyle: [{
    flexDirection: 'row',
    alignItems: 'center',
  },
  style?.fundsKeysStyle],
  fundsKeysRightStyle: [{ marginRight:
    context?.theme.metrics.spacing.xm },
  style?.fundsKeysRightStyle],
  fundsKeysContainerStyle: [
    {
      alignItems: 'flex-end',
      marginBottom: context?.theme.metrics.spacing.xm,
      paddingHorizontal: context?.isMobile ?
        context?.theme.metrics.spacing.s :  context?.theme.metrics.spacing.huge,
    },
    style?.fundsKeysContainerStyle,
  ],
  fundsTableKeysContainerStyle: [
    {
      alignItems: 'flex-end',
      marginBottom: context?.theme.metrics.spacing.xm,
    },
    style?.fundsTableKeysContainerStyle,
  ],
  fundsKeysIconsStyle: [
    { flexDirection: 'row' },
    style?.fundsKeysIconsStyle],
  greenLabelContainerStyle: [{
    backgroundColor: context?.theme.colors.basics.white,
    height: 20,
    width: 20,
  },
  style?.greenLabelContainerStyle],
  spacerLineStyle: [{
    borderTopColor: context?.theme.colors.basics.grey.c200,
    borderTopWidth: 1,
    marginTop: context?.isMobile ?
      context?.theme.metrics.spacing.xl
      : context?.theme.metrics.spacing.xxxl,
    marginBottom: context?.theme.metrics.spacing.xxm,
  },
  style?.spacerLineStyle],
});

