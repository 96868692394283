import { LineChartToolTip } from '@ere-uilib/atoms';
import { createUseStyles, useScreenSizes, useTheme } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';
import React, { useCallback, useState } from 'react';
import { LayoutChangeEvent, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryTheme,
  VictoryGroup,
  VictoryLabel,
  VictoryLegend,
  VictoryStack,
  VictoryContainer,
  VictoryTooltip,
  VictoryVoronoiContainer, VictoryZoomContainer,
} from 'victory-native';
import {
  PerformanceBarChartProps, ToolTipFlyoutProps
} from './interfaces';
import {
  axisStyles,
  barChartPadding,
  barFillStyle,
  barLabelStyle,
  barWidthStyle,
  getStyles,
  legendStyles,
  secondBarFillStyle,
  secondBarLabelStyle,
  yAxisStyles,
  yValuesStyles
} from './styles';

export const PerformanceBarChart: React.FC<React.PropsWithChildren<PerformanceBarChartProps>> = ({
  tooltipDetailStyle,
  containerStyle,
  data1,
  data2,
  legends,
  renderTooltip
}) => {
  const theme = useTheme();
  const { isMobile, isTablet, windowHeight } = useScreenSizes();
  const { formatNumber } = useTranslation();
  const [
    containerSize,
    setContainerSize,
  ] = useState<{ width: number; height: number }>({ width: 1000, height: 300 });
  const [activeOverlay, setActiveOverlay] = useState(true)
  const styles = useStyles(
    { theme, isMobile, isTablet },
    {
      containerStyle,
      tooltipDetailStyle
    },
  );

  const handleContainerLayout = ({ nativeEvent }: LayoutChangeEvent) => {
    const { width, height } = nativeEvent.layout;
    setContainerSize({ width, height });
  };

  const maxBottomLabels = containerSize.width / 40;
  const chartSize = {
    width: containerSize.width,
    height: windowHeight / 2 < 300 ?
      300
      : windowHeight / 2 > 400 ?
        400
        : windowHeight / 2,
  };

  const getY = ({ datum }: any) =>
    formatNumber({
      value: datum.y,
      options: {
        maximumFractionDigits: 1,
        minimumFractionDigits: 1,
      }
    });

  const sortX = (datum: any) => datum.x;

  const CustomToolTip = useCallback((props: ToolTipFlyoutProps) => {
    const { x = 0, y = 0 } = props;
    const isInLeftPart = x < chartSize.width * 0.5;
    const isInTopPart = y < chartSize.height * 0.5;
    return <>
      <LineChartToolTip
        displayLineCursor
        isInRightPart={!isInLeftPart}
        isInTopPart={isInTopPart}
        x={x}
        y={y}
      >
        <View>
          {renderTooltip?.(props)}
        </View>
      </LineChartToolTip>
    </>
  }, [renderTooltip, chartSize])

  const ChartContainerComponent = useCallback(() => {
    if (isMobile || isTablet)
      return <VictoryZoomContainer
        allowZoom={false}
        onZoomDomainChange={(domain, _) => {
          domain.x[1] > 10 ?
            setActiveOverlay(false) :
            setActiveOverlay(true)
        }}
        zoomDimension="x"
        zoomDomain={
          {
            x: [0, 5.3]
          }}
      />

    return <VictoryVoronoiContainer
      labelComponent={
        <VictoryTooltip
          flyoutComponent={<CustomToolTip />}
        />
      }
      labels={() => ' '}
    />
  }, [isMobile, isTablet, CustomToolTip])
  return (
    <View
      onLayout={handleContainerLayout}
      style={styles.containerStyle}
    >
      {(isMobile || isTablet) && activeOverlay && <LinearGradient
        locations={[0, 0.4, 0.8]}
        style={styles.overlay}
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 0 }}
        colors={["#ffffff00", 'white']}
      />}

      <VictoryChart
        containerComponent={ChartContainerComponent()}
        domainPadding={{
          x: isMobile || isTablet ? 15 : 30,
          y: 30
        }}
        theme={VictoryTheme.grayscale}
        height={chartSize.height}
        padding={barChartPadding}
        width={chartSize.width}
      >
        <VictoryAxis
          fixLabelOverlap
          offsetY={barChartPadding.left}
          crossAxis={false}
          dependentAxis
          style={yAxisStyles(theme)}
        />
        <VictoryGroup
          offset={25}
          style={barWidthStyle} >
          <VictoryStack style={barFillStyle} >
            <VictoryBar
              data={data1}
              labels={getY}
              sortKey={sortX}
              sortOrder="ascending"
              barRatio={1}
              style={barLabelStyle(theme)}
              alignment="middle"
            />
          </VictoryStack>
          <VictoryStack
            style={secondBarFillStyle} >
            <VictoryBar
              barRatio={1}
              alignment="middle"
              data={data2}
              style={secondBarLabelStyle(theme)}
              labels={getY}
              sortKey={sortX}
              sortOrder="ascending"
            />
          </VictoryStack>
        </VictoryGroup>
        <VictoryAxis
          fixLabelOverlap
          crossAxis
          offsetY={barChartPadding.bottom}
          style={axisStyles(theme)}
          tickCount={maxBottomLabels}
          tickLabelComponent={
            <VictoryLabel
              angle={-45}
              dx={-20}
              dy={0}
              style={yValuesStyles(theme)}
              textAnchor="start"
            />
          }
        />
      </VictoryChart>
      <VictoryLegend
        containerComponent={<VictoryContainer
          responsive={false}
        />}
        colorScale={["#80C8AC", "#00915A"]}
        height={25}
        data={legends}
        gutter={20}
        orientation="horizontal"
        style={legendStyles(theme)}
        titleOrientation="bottom"
      />
    </View >
  );
};

const useStyles = createUseStyles(getStyles);
