import React, { useState } from 'react'
import { StyleProp, ViewStyle, TouchableOpacity } from 'react-native'

import { Title } from '@ere-uilib/atoms'
import { FontIcon } from '@ere-uilib/atoms'
import { createUseStyles } from '@ere-uilib/styles'
import { theme } from '@ere-uilib/theme'

interface Props {
  dateSelectionRange: number[]
  onSelectDate: (selectedDate: number) => void
  selectedDateIndex: number
}

type Context = {
  theme?: typeof theme
}
type StyleType = {
  containerStyle?: StyleProp<ViewStyle>
}

export const DatePickerSelection = ({
  dateSelectionRange,
  onSelectDate,
  selectedDateIndex
}: Props) => {
  const [selectedIndex, setSelectedIndex] = useState(selectedDateIndex)
  const styles = useStyles({ theme }, {})

  const onDateSelect = (date: number, index: number) => {
    setSelectedIndex(index)
    onSelectDate(date)
  }

  return (
    <>
      {dateSelectionRange.map((date: number, index: number) => (
        <TouchableOpacity
          onPress={() => onDateSelect(date, index)}
          style={styles.containerStyle}>
          <Title variant="t8">{date}</Title>
          {selectedIndex === index && (
            <FontIcon
              color={theme.colors.primaryGreen}
              name="cocher"
              size={theme.iconSize.small}
            />
          )}
        </TouchableOpacity>
      ))}
    </>
  )
}

const getStyles = (context?: Context, style?: StyleType): StyleType => ({
  containerStyle: [
    {
      flex: 1,
      flexDirection: 'row',
      borderBottomWidth: 1,
      borderStyle: 'solid',
      borderBottomColor: context?.theme?.colors?.opacityGrey,
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingLeft: context?.theme?.marginsPaddings.s,
      paddingRight: context?.theme?.marginsPaddings.s,
      paddingBottom: context?.theme?.marginsPaddings.xm,
      paddingTop: context?.theme?.marginsPaddings.xm
    },
    style?.containerStyle
  ]
})

const useStyles = createUseStyles(getStyles)
