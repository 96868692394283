import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

export function useStyles() {
  const theme = useTheme();

  const styles = useMemo(() => StyleSheet.create({
    cardChoiceContainer:
    {
      width: theme.metrics.contentSizes.eerCardChoice,
      padding: theme.metrics.spacing.s,
      borderRadius: theme.metrics.borderRadius.xs,
      borderWidth: 1,
      justifyContent: 'center',
      alignItems: 'center',
      marginVertical: theme.metrics.spacing.s,
      borderColor: theme.colors.borders.fields.c700,
    },
    hoveredTextStyle: {
      color: theme.colors.basics.white
    }

  }), [theme]);

  return styles;
}