import React, { FC, useCallback, useMemo } from 'react';
import { View } from 'react-native';

import { Text } from '@ere-uilib/atoms';
import { Select, Table, TableCellInterface, TableCellTypeEnum } from '@ere-uilib/molecules';
import { ItemState } from '@ere-uilib/molecules/inputs/Select/types';
import { useTheme } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';
import { FetchAnnuityResultsData } from '@modules/annuity/types';
import { useFormatPercentNumber } from '@pages/Annuity/useFormatPercentNumber';
import { formatSelectValue } from '@pages/Annuity/utils';

import { useStyles } from './useStyles';

interface Props {
  reversionRatesList: number[];
  guaranteedAnnuitiesList: number[];
  reversionRate: number | undefined;
  guaranteedAnnuities: number;
  resultsData: FetchAnnuityResultsData;
  onChange: (key: 'reversionRate' | 'guaranteedAnnuities', value: number) => void;
}

export const AnnuityResultsTable: FC<React.PropsWithChildren<Props>> = ({
  reversionRate,
  reversionRatesList,
  guaranteedAnnuities,
  guaranteedAnnuitiesList,
  resultsData,
  onChange,
  ...props
}) => {
  const { formatCurrencyNumber, formatMessage } = useTranslation();
  const formatPercentNumber = useFormatPercentNumber();

  const styles = useStyles();
  const theme = useTheme();

  const isTwoColumns = useMemo(() => !reversionRate, [reversionRate]);

  const reversionRateOptions = useMemo(
    () => reversionRatesList.map(option => formatSelectValue(option, formatPercentNumber)),
    [formatPercentNumber, reversionRatesList]
  );

  const guaranteedAnnuitiesOptions = useMemo(
    () => guaranteedAnnuitiesList.map(option => formatSelectValue(option)),
    [guaranteedAnnuitiesList]
  );

  const handleSelectItemGuaranteedAnnuities = useCallback((item: ItemState) => {
    onChange('guaranteedAnnuities', Number(item.value));
  }, [onChange]);

  const handleSelectItemReversionRate = useCallback((item: ItemState) => {
    onChange('reversionRate', Number(item.value));
  }, [onChange]);

  const header: TableCellInterface[] = useMemo(() => {
    const columns = [
      {
        type: TableCellTypeEnum.DEFAULT,
        children: (
          <Text
            variant="t4"
            weight="light">
            {formatMessage({
              id: 'PensionSimulatorOutcomeSimulationTableTitleRenteType'
            })}
          </Text>
        ),
        containerStyle: styles.tableHeadLeft
      },
      {
        type: TableCellTypeEnum.DEFAULT,
        children: (
          <Text
            variant="t4"
            weight="light">
            {formatMessage({
              id: 'PensionSimulatorOutcomeSimulationTableTitleWithoutReversion'
            })}
          </Text>
        ),
        containerStyle: isTwoColumns ? styles.tableHeadRight : styles.tableHeadMiddle
      }
    ];

    const rightColumn = {
      type: TableCellTypeEnum.DEFAULT,
      children: (
        <>
          <Text
            variant="t4"
            weight="light">
            {formatMessage({
              id: 'PensionSimulatorOutcomeSimulationTableTitleWithReversion'
            })}
          </Text>
          <View style={styles.selectRateContainer}>
            <Text
              variant="t4"
              weight="regular">
              {formatMessage({ id: 'PensionSimulatorOutcomelSimulationTableTitleReversionRate' })}
            </Text>
            <Select
              containerStyle={styles.selectContainer}
              defaultTitle="-"
              iconSize={12}
              items={reversionRateOptions}
              itemsContainerStyle={styles.selectItemsContainerStyle}
              onSelectItem={handleSelectItemReversionRate}
              pickerTextContainerStyle={styles.pickerTextContainer}
              pickerTextStyle={styles.pickerText}
              pickerTitle={formatMessage({ id: 'PensionSimulatorOutcomelSimulationTableTitleReversionRate' })}
              selectedItem={reversionRate ? formatSelectValue(reversionRate, formatPercentNumber) : undefined}
              selectedItemStyle={styles.selectedItem}
              titleStyle={styles.selectTitle}
            />
          </View>
        </>
      ),
      containerStyle: styles.tableHeadRight
    };

    if (!isTwoColumns) columns.push(rightColumn);

    return columns;
  }, [formatMessage, formatPercentNumber, handleSelectItemReversionRate, isTwoColumns, reversionRate, reversionRateOptions, styles]);

  const row1: TableCellInterface[] = useMemo(() => {
    const columns = [
      {
        type: TableCellTypeEnum.ENTRIES,
        children: (
          <Text
            variant="t3"
            weight="light">
            {formatMessage({ id: 'PensionSimulatorOutcomeSimulationTableTitleSimpleRente' })}
          </Text>
        ),
        containerStyle: styles.tableMiddleRowLeftColumn
      },
      {
        type: TableCellTypeEnum.DEFAULT,
        children: (
          <Text
            variant="t3"
            weight="regular">
            {formatCurrencyNumber({ value: +(resultsData.value || 0) })}
          </Text>
        ),
        containerStyle: isTwoColumns
          ? styles.tableMiddleRowRightColumn
          : styles.tableMiddleRowMiddleColumn
      }
    ];

    const rightColumn = {
      type: TableCellTypeEnum.DEFAULT,
      children: (
        <Text
          variant="t3"
          weight="regular">
          {formatCurrencyNumber({ value: +(resultsData.reversionValue || 0) })}
        </Text>
      ),
      containerStyle: styles.tableMiddleRowRightColumn
    };

    if (!isTwoColumns) columns.push(rightColumn);

    return columns;
  }, [formatMessage, styles, formatCurrencyNumber, resultsData, isTwoColumns]);

  const row2: TableCellInterface[] = useMemo(() => {
    const columns = [
      {
        type: TableCellTypeEnum.ENTRIES,
        children: (
          <>
            <Text
              variant="t3"
              weight="light">
              {formatMessage({
                id: 'PensionSimulatorOutcomeSimulationTableTitleRenteWithGuarantiedAnnuities'
              })}
            </Text>
            <View style={styles.selectAnnuityContainer}>
              <Text
                variant="t4"
                weight="regular">
                {formatMessage({
                  id: 'PensionSimulatorOutcomeSimulationTableTitleNumberGuarantiedAnnuities'
                })}
              </Text>
              <Select
                containerStyle={styles.selectContainer}
                defaultTitle="-"
                iconSize={12}
                items={guaranteedAnnuitiesOptions}
                itemsContainerStyle={styles.selectItemsContainerStyle}
                onSelectItem={handleSelectItemGuaranteedAnnuities}
                pickerTextStyle={styles.pickerText}
                pickerTitle={formatMessage({ id: 'PensionSimulatorOutcomeSimulationTableTitleNumberGuarantiedAnnuities' })}
                selectedItem={formatSelectValue(guaranteedAnnuities)}
                selectedItemStyle={styles.selectedItem}
                titleStyle={styles.selectTitle}
              />
            </View>
          </>
        ),
        containerStyle: styles.tableLastRowLeftColumn
      },
      {
        type: TableCellTypeEnum.DEFAULT,
        children: (
          <Text
            variant="t3"
            weight="regular">
            {formatCurrencyNumber({ value: +(resultsData.guaranteedValue || 0) })}
          </Text>
        ),
        containerStyle: isTwoColumns
          ? styles.tableLastRowRightColumn
          : styles.tableLastRowMiddleColumn
      }
    ];

    const rightColumn = {
      type: TableCellTypeEnum.DEFAULT,
      children: (
        <Text
          variant="t3"
          weight="regular">
          {formatCurrencyNumber({ value: +(resultsData.guaranteedReversionValue || 0) })}
        </Text>
      ),
      containerStyle: styles.tableLastRowRightColumn
    };

    if (!isTwoColumns) columns.push(rightColumn);

    return columns;
  }, [
    formatMessage,
    styles,
    guaranteedAnnuitiesOptions,
    handleSelectItemGuaranteedAnnuities,
    guaranteedAnnuities,
    formatCurrencyNumber,
    resultsData,
    isTwoColumns,
    theme,
  ]);

  const tableData: TableCellInterface[][] = useMemo(
    () => [header, row1, row2],
    [header, row1, row2]
  );

  return (
    <Table
      cellPosition={0}
      data={tableData}
    />
  );
};
