import React from 'react'
import { useSelector } from 'react-redux'

import { NotificationSoft, ExpendableLineSet, ExpendableLineListItemProps } from '@ere-uilib/index'
import { useTranslation } from '@ere-uilib/translations'
import { AppState } from '@modules/reducers'
import { getAccountRepartitions, getUngroupedRepartitions } from '@modules/savings/selectors'
import { RepartitionsState, RepartitionFiltersState } from '@modules/savings/types'
import {
  extractRepartitionsGroupsStructures,
  groupRepartitionsBySupportName,
  filterRepartitions
} from '@modules/savings/utils'
import { SavingRepartitionCard } from '../SavingRepartitionCard'

interface RenderGroupedRepartitionsProps {
  onRepartitionCardPress: (id: string, title: string) => void
  repartitions: RepartitionsState[]
  repartitionFilters: RepartitionFiltersState
  planId?: string
}

export const RenderGroupedRepartitionsConnected = ({
  onRepartitionCardPress,
  repartitions,
  repartitionFilters,
  planId
}: RenderGroupedRepartitionsProps) => {
  const { formatMessage } = useTranslation()

  const accountRepartitions = useSelector((state: AppState) => getAccountRepartitions(state))
  const ungroupedRepartitions = useSelector((state: AppState) => getUngroupedRepartitions(state))

  // if it's the total view , use account repartitions instead of grouped one
  const repartitionsToGroup = planId
    ? ungroupedRepartitions?.find((item: { planId: string }) => item.planId === planId)?.items
    : accountRepartitions?.length === 0 ? repartitions : accountRepartitions;
  const repartitionGroupStructures = extractRepartitionsGroupsStructures(repartitionsToGroup || [])

  const isFilterGrouppedByCompartiments =
    repartitionFilters?.groupFilter?.includes('group_compartiment')
  const isFilterGrouppedByManagements =
    repartitionFilters?.groupFilter?.includes('group_management')

  const EmptyPlaceholder = () => (
    <NotificationSoft text={formatMessage({ id: 'saving_nodata_label' })} />
  )

  // if both are active, we have two levels of expadables, compartiment group is superior to management group

  /**
   * COMPARTIMENT GROUP LIST CONSTRUTION
   * Contain Management sub group constructor if both filters are active
   */
  if (isFilterGrouppedByCompartiments) {
    // this list is used by the compartiment expandable set
    const compartimentGroupList: ExpendableLineListItemProps[] = []

    repartitionGroupStructures?.compartimentGroups?.forEach(compartimentGroup => {
      let amountForGroup: number = 0
      const compartimentGroupListChildren: JSX.Element[] = []

      if (isFilterGrouppedByManagements) {
        // both groupped filters are active we need to construct the sub expandable set based on management groups
        // this list is used by the management expandable set
        const managementGroupList: ExpendableLineListItemProps[] = []
        compartimentGroup?.managements?.forEach(managementGroup => {
          let amountForSubGroup: number = 0
          const managemementGroupListChildren: JSX.Element[] = []
          let repartitionsToGroupConsolided: RepartitionsState[] = []

          repartitionsToGroup?.forEach((repartition: RepartitionsState, index: number) => {
            if (
              repartition.compartimentLabel === compartimentGroup.label &&
              repartition.managementLabel === managementGroup.label
            ) {
              repartitionsToGroupConsolided.push(repartition)
            }
          })
          repartitionsToGroupConsolided = filterRepartitions(
            groupRepartitionsBySupportName(repartitionsToGroupConsolided),
            repartitionFilters
          )
          repartitionsToGroupConsolided?.forEach(
            (repartition: RepartitionsState, index: number) => {
              amountForGroup += repartition.amount.amount
              amountForSubGroup += repartition.amount.amount

              managemementGroupListChildren.push(
                <SavingRepartitionCard
                  source={repartition?.source}
                  hasNoFundSheet={repartition?.hasNoFundSheet}
                  addedValue={repartition.amount.addedValue}
                  amount={repartition.amount.amount}
                  key={index + repartition.supportName}
                  legalStatus={repartition.legalStatus}
                  onCardPress={() =>
                    onRepartitionCardPress(repartition.supportIsin, repartition.supportName)
                  }
                  performanceValue={repartition.performanceValue}
                  riskLevel={repartition.riskLevelSRI || repartition.riskLevelSRRI}
                  isRiskLevelSRI={!!repartition.riskLevelSRI}
                  supportName={repartition.supportName}
                />
              )
            }
          )

          managementGroupList.push({
            title: managementGroup?.label,
            amount: amountForSubGroup,
            //disabled: managemementGroupListChildren?.length < 1,
            children:
              managemementGroupListChildren?.length < 1 ? (
                <EmptyPlaceholder />
              ) : (
                managemementGroupListChildren
              ),
            design: 'lightGrey',
            size: 'small',
            isHeaderDoubleBorder: true
          })
        })

        compartimentGroupListChildren.push(
          <ExpendableLineSet
            autoOpenFirstItem
            list={managementGroupList}
          />
        )
      } else {
        let repartitionsToGroupConsolided: RepartitionsState[] = []

        // only compartiment filter is active, we construct the content for a simple expandable set
        repartitionsToGroup?.forEach((repartition: RepartitionsState, index: number) => {
          if (repartition.compartimentLabel === compartimentGroup.label) {
            repartitionsToGroupConsolided.push(repartition)
          }
        })

        repartitionsToGroupConsolided = filterRepartitions(
          groupRepartitionsBySupportName(repartitionsToGroupConsolided),
          repartitionFilters
        )

        // only compartiment filter is active, we construct the content for a simple expandable set
        repartitionsToGroupConsolided?.forEach((repartition: RepartitionsState, index: number) => {
          amountForGroup += repartition.amount.amount
          compartimentGroupListChildren.push(
            <SavingRepartitionCard
              source={repartition.source}
              hasNoFundSheet={repartition?.hasNoFundSheet}
              addedValue={repartition.amount.addedValue}
              amount={repartition.amount.amount}
              key={index + repartition.supportName}
              legalStatus={repartition.legalStatus}
              onCardPress={() =>
                onRepartitionCardPress(repartition.supportIsin, repartition.supportName)
              }
              performanceValue={repartition.performanceValue}
              riskLevel={repartition.riskLevelSRI || repartition.riskLevelSRRI}
              isRiskLevelSRI={!!repartition.riskLevelSRI}
              supportName={repartition.supportName}
            />
          )
        })
      }

      compartimentGroupList.push({
        title: compartimentGroup?.label,
        amount: amountForGroup,
        //disabled: compartimentGroupListChildren?.length < 1,
        children:
          compartimentGroupListChildren?.length < 1 ? (
            <EmptyPlaceholder />
          ) : (
            compartimentGroupListChildren
          ),
        design: 'grey',
        size: 'small',
        isHeaderDoubleBorder: true
      })
    })

    return (
      <ExpendableLineSet
        autoOpenFirstItem
        list={compartimentGroupList}
      />
    )

    /**
     * MANAGEMNET GROUP LIST CONSTRUTION
     */
  } else if (isFilterGrouppedByManagements) {
    // this list is used by the management expandable set
    const managementGroupList: ExpendableLineListItemProps[] = []

    repartitionGroupStructures.managementGroups.forEach(managementGroup => {
      let amountForGroup: number = 0
      const managemementGroupListChildren: JSX.Element[] = []
      let repartitionsToGroupConsolided: RepartitionsState[] = []

      repartitionsToGroup?.forEach((repartition: RepartitionsState, index: number) => {
        if (repartition.managementLabel === managementGroup.label) {
          repartitionsToGroupConsolided.push(repartition)
        }
      })
      repartitionsToGroupConsolided = filterRepartitions(
        groupRepartitionsBySupportName(repartitionsToGroupConsolided),
        repartitionFilters
      )

      repartitionsToGroupConsolided?.forEach((repartition: RepartitionsState, index: number) => {
        amountForGroup += repartition.amount.amount
        managemementGroupListChildren.push(
          <SavingRepartitionCard
            source={repartition?.source}
            hasNoFundSheet={repartition?.hasNoFundSheet}
            addedValue={repartition.amount.addedValue}
            amount={repartition.amount.amount}
            key={index + repartition.supportName}
            legalStatus={repartition.legalStatus}
            onCardPress={() =>
              onRepartitionCardPress(repartition.supportIsin, repartition.supportName)
            }
            performanceValue={repartition.performanceValue}
            riskLevel={repartition.riskLevelSRI || repartition.riskLevelSRRI}
            isRiskLevelSRI={!!repartition.riskLevelSRI}
            supportName={repartition.supportName}
          />
        )
      })

      managementGroupList.push({
        title: managementGroup?.label,
        amount: amountForGroup,
        //disabled: managemementGroupListChildren?.length < 1,
        children:
          managemementGroupListChildren?.length < 1 ? (
            <EmptyPlaceholder />
          ) : (
            managemementGroupListChildren
          ),
        design: 'grey',
        size: 'small',
        isHeaderDoubleBorder: true
      })
    })

    return (
      <ExpendableLineSet
        autoOpenFirstItem
        list={managementGroupList}
      />
    )
  } else {
    // by default display basic ungrouped card in case of filter issue
    return (
      <>
        {repartitions?.map((repartition: RepartitionsState, index: number) => {
          return (
            <SavingRepartitionCard
              source={repartition?.source}
              hasNoFundSheet={repartition?.hasNoFundSheet}
              addedValue={repartition.amount.addedValue}
              amount={repartition.amount.amount}
              key={index + repartition.supportName}
              legalStatus={repartition.legalStatus}
              onCardPress={() =>
                onRepartitionCardPress(repartition.supportIsin, repartition.supportName)
              }
              performanceValue={repartition.performanceValue}
              riskLevel={repartition.riskLevelSRI || repartition.riskLevelSRRI}
              isRiskLevelSRI={!!repartition.riskLevelSRI}
              supportName={repartition.supportName}
            />
          )
        })}
      </>
    )
  }
}
