import React from 'react';
import { ActivityIndicator, ActivityIndicatorProps } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

export const Spinner: React.FC<ActivityIndicatorProps> = ({ color, size }) => {
  const theme = useTheme();
  return <ActivityIndicator
    color={color || theme.colors.basics.primary.c500}
    size={size || 'small'} />;
};
