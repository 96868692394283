import React from 'react';
import { FontIcon, Title } from "@ere-uilib/atoms";
import { useTheme } from "@ere-uilib/styles";
import { StyleProp, View, ViewStyle, TouchableOpacity } from "react-native";
import { useStyles } from './useStyles';

interface Props {
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
  title: string;
};

export const ChevronLink = ({
  onPress,
  title,
  style,
}: Props) => {
  const theme = useTheme();
  const styles = useStyles();

  return (
    <TouchableOpacity
      onPress={onPress}
      style={style}
    >
      <Title
        style={styles.title}
        variant="t7"
        weight="bold"
      >
        {title}
        <View>
          <FontIcon
            color={theme.colors.basics.primary.c500}
            name="chevron-droite"
            size={theme.metrics.iconSizes.xs}
            style={styles.chevron}
          />
        </View>
      </Title>
    </TouchableOpacity>
  )
};
