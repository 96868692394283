import { BankOperationTypesList } from '@constants/savings';
import { BankDetailsActionsType } from '@modules/bank-details/actions/actionsTypes';

export enum BankDetailsNavigatorRouteNames {
  BankSetDomiciliation = 'BankSetDomiciliation',
  BankSetDocuments = 'BankSetDocuments',
  BankDetailsSummary = 'BankDetailsSummary',
  BankSetIban = 'BankSetIban',
  BankCopyIban = 'BankCopyIban',
  BankSignature = 'BankSignature',
  BankSignatureRC = 'BankSignatureRC',
  BankDetailsModal = 'BankDetailsModal'
}

export type BankDetailsNavigatorInterface = {
  [BankDetailsNavigatorRouteNames.BankSetDomiciliation]: undefined;
  [BankDetailsNavigatorRouteNames.BankSetDocuments]: undefined;
  [BankDetailsNavigatorRouteNames.BankDetailsSummary]: undefined;
  [BankDetailsNavigatorRouteNames.BankSetIban]: undefined;
  [BankDetailsNavigatorRouteNames.BankCopyIban]: {
    operationType: BankOperationTypesList | undefined;
  };
  [BankDetailsNavigatorRouteNames.BankDetailsModal]: {
    clearAction: BankDetailsActionsType.CLEAR_BANK_DATA;
  };
  [BankDetailsNavigatorRouteNames.BankSignature]: undefined;
  [BankDetailsNavigatorRouteNames.BankSignatureRC]: undefined;
};
