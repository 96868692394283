import React, { useCallback, useState } from 'react';
import { StyleProp, TouchableOpacity, View, ViewStyle } from 'react-native';

import {
  Modal,
  Text,
  FontIcon,
  Calendar,
  CalendarDefaultViewEnum,
} from '@ere-uilib/atoms';
import {
  ModalFrame,
} from '@ere-uilib/molecules';
import { useTranslation } from '@ere-uilib/translations';

import { useStyles } from './useStyles';

interface DateSelectorProps {
  value?: Date;
  minDate?: Date;
  maxDate?: Date;
  onChange: (value: Date) => void;
  title?: string;
  style?: StyleProp<ViewStyle>;
  defaultView?: CalendarDefaultViewEnum;
  monthNames?: string[];
  dayNames?: string[];
  dayNamesShort?: string[];
}

export function  DateSelector({
  title,
  value,
  minDate,
  maxDate,
  onChange,
  style,
  defaultView,
  monthNames,
  dayNames,
  dayNamesShort,
}: DateSelectorProps) {
  const [modalVisible, setModalVisible] = useState<boolean>();
  const styles = useStyles();
  const { formatDate } = useTranslation();

  const handleChange = useCallback((selectedValue: Date)=>{
    onChange(selectedValue);
    setModalVisible(false);
  }, [onChange]);

  const handleClose = useCallback(() => {
    setModalVisible(false);
  }, []);

  return (
    <>
      <TouchableOpacity
        onPress={()=>{setModalVisible(true);}}
        style={[styles.buttonStyle, style]}>
        <View>
          {title &&
            <Text
              style={styles.grayTitleStyle}
              variant="t3"
            >
              {title}
            </Text>
          }
          <Text
            style={styles.dateStyle}
            variant="t3"
          >
            {value ? (
              formatDate({ value,
                options: {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                } })
            ) :
              '__.__.____'
            }
          </Text>
        </View>
        <FontIcon
          name="calendar"
          style={styles.buttonIcon}
        />
      </TouchableOpacity>
      <Modal visible={modalVisible}>
        <ModalFrame
          desktopPosition="center"
          mobilePosition="bottom"
          onClose={handleClose}
        >
          <View style={styles.calendarContainer}>
            <Calendar
              defaultView={defaultView}
              maxDate={maxDate}
              minDate={minDate}
              onChange={handleChange}
              value={value}
              monthNames={monthNames}
              dayNames={dayNames}
              dayNamesShort={dayNamesShort}
            />
          </View>
        </ModalFrame>
      </Modal>
    </>
  );
}
