import React  from 'react';
import { View } from 'react-native';

import { SVGLocalLoader } from '@ere-uilib/atoms';
import { RetryActionPlaceHolder } from '@ere-uilib/organisms';
import { createUseStyles, useTheme, useScreenSizes } from '@ere-uilib/styles';

import { Props } from './interfaces';
import { getStyles } from './styles';

export const DashboardImagePlaceholder: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  iconName = 'imgPlaceholder',
  buttonAction,
}) => {
  const theme = useTheme();
  const { isMobile } = useScreenSizes();
  const styles = useStyles(
    { theme, isMobile: isMobile },
    {},
  );

  return (
    <View style={styles.container}>
      <View style={styles.imageContainer}>
        <SVGLocalLoader
          height={theme.metrics.contentSizes.imgPlaceholderSize}
          name={iconName}
          width={theme.metrics.contentSizes.imgPlaceholderSize}
        />
      </View>
      <View style={styles.placeholderContainer}>
        <RetryActionPlaceHolder
          retryAction={buttonAction}
          title={title}
        />
      </View>
    </View>
  );
};

const useStyles = createUseStyles(getStyles);
