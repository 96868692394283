import React from 'react';
import { LoaderPlaceholderView } from '@ere-uilib/atoms';
import { LoaderCard } from '@ere-uilib/molecules';
import { useStyles } from './useStyles';

export const OCDLoader: React.FC = () => {
  const styles = useStyles()
  return (
    <LoaderCard
      loaderContainerStyle={styles.loaderContainerStyle}
    >
      <LoaderPlaceholderView style={{ width: '50%' }} />
      <LoaderPlaceholderView style={{ width: '100%' }} />
    </LoaderCard>
  )
};
