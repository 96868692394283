export enum ProfileEditAddressStackNavigatorRouteNames {
  ProfileEditAddressForm = 'ProfileEditAddressForm',
  ProfileEditAddressDocument = 'ProfileEditAddressDocument',
  ProfileEditAddressInformation = 'ProfileEditAddressInformation',
}

export type ProfileEditAddressStackNavigatorInterface = {
  [ProfileEditAddressStackNavigatorRouteNames.ProfileEditAddressForm]: undefined
  [ProfileEditAddressStackNavigatorRouteNames.ProfileEditAddressDocument]: undefined
  [ProfileEditAddressStackNavigatorRouteNames.ProfileEditAddressInformation]: undefined
}
