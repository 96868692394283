export const innerNavigations: string[] = [
  'disposition-tabs1_label',
  'disposition-tabs2_label',
  'disposition-tabs3_label',
  'disposition-tabs4_label',
  // HIDE EVOLUTION CARD UNTIL API IS OK
  // 'disposition-tabs5_label',
];

export enum BankOperationTypesList {
  PRELEVEMENT = 'PRELEVEMENT',
  REMBOURSEMENT = 'REMBOURSEMENT',
  VIREMENT = 'VIREMENT',
}

export enum FundSheetTabsEnum {
  APERCU = 'fund-card_tabs1_label',
  PERFORMANCES = 'fund-card_tabs2_label',
  DOCUMENTS = 'fund-card_tabs3_label',
}

export enum OperationFamilyEnum {
  ARBITRATION = 'ARBITRAGE',
  REFUND = 'REMBOURSEMENT',
  INSTALLMENT = 'VERSEMENT VOLONTAIRE',
  PROFITSHARING = 'PARTICIPATION',
  INCENTIVE = 'INTERESSEMENT',
  CAPITALIZED_DIVIDEND ='DIVIDENDE CAPITALISE'
}

export enum OperationHistoryTypesEnum {
  REFUND = 'Remboursement',
  INSTALLMENT = 'Versement',
  INCENTIVE = 'Affectation interessement',
  PROFITSHARING = 'Affectation participation',
  CONTRIBUTION = 'Affectation abondement',
  ARBITRATION = 'Arbitrage',
  TRANSFER_IN = 'Transfert entrant',
  TRANSFER_OUT = 'Transfert sortant',
  ASSESSMENT = 'Cotisation',
  DIVERSE = 'Divers',
  LIQUIDATION = 'Liquidation',
  BUY_BACK = 'Rachat',
  CAPITALIZED_DIVIDEND ='DIVIDENDE CAPITALISE'
}

const OPERAIONS_HISTORY_TYPES_ROULE = [
  {
    value: 'Remboursement',
    label: 'DashboardOperationFilterRemboursement',
    checked: true,
  },
  {
    value: 'Versement',
    label: 'DashboardOperationFilterVersement',
    checked: true,
  },
  {
    value: 'Affectation interessement',
    label: 'DashboardOperationFilterAffectInteressement',
    checked: true,
  },
  {
    value: 'Affectation abondement',
    label: 'DashboardOperationFilterAffectAbondement',
    checked: true,
  },
  {
    value: 'Affectation participation',
    label: 'DashboardOperationFilterAffectParticipation',
    checked: true,
  },
  {
    value: 'Arbitrage',
    label: 'DashboardOperationFilterArbitrage',
    checked: true,
  },
  {
    value: 'Transfert entrant',
    label: 'DashboardOperationFilterTransfertEntrant',
    checked: true,
  },
  {
    value: 'Transfert sortant',
    label: 'DashboardOperationFilterTransfertSortant',
    checked: true,
  },
  {
    value: 'Cotisation',
    label: 'DashboardOperationFilterCotisation',
    checked: true,
  },
  {
    value: 'Divers',
    label: 'DashboardOperationFilterDivers',
    checked: true,
  },
  {
    value: 'Liquidation',
    label: 'DashboardOperationFilterLiquidation',
    checked: true,
  },
  {
    value: 'Rachat',
    label: 'DashboardOperationFilterRachat',
    checked: true,
  },
];
const OPERATION_HISTORY_RANGE_DATE_ROULE = [
  Number(new Date().getFullYear().toString()),
];

const REPARTITION_SORT_FILTER_LIST: { value: string; label: string }[] = [
  { value: 'sort_amount_desc', label: 'Saving_Tab1_Filter_Amount_label' },
  { value: 'sort_performance', label: 'Saving_Tab1_Filter_Perf_label' },
  { value: 'sort_risk', label: 'Saving_Tab1_Filter_Risk_label' },
];
const REPARTITION_GROUP_FILTER_LIST: { value: string; label: string }[] = [
  { value: 'group_compartiment', label: 'Saving_Tab1_Filter_Origin_label' },
  { value: 'group_management', label: 'Saving_Tab1_Filter_Management_label' },
];

export enum OperationDetailsPaymentModeTypeEnum {
  VIREMENT = 'RIB',
  CHEQUE = 'CHEQUE',
  COMPANY = 'PAYEUSE',
  CB = 'CB',
  UNKNOWN = 'INCONNU',
}

export enum OperationDetailsBoTypeEnum {
  // p+i
  INTERESSEMENT = 'BO_INTERESSEMENT',
  PARTICIPATION = 'BO_PARTICIPATION',
  // Versement
  VERSEMENT = 'VERSEMENT',
  VERSEMENT_CB = 'VERSEMENT_CB',
  VERSEMENT_ECHEANCE_PROGRAMME = 'VERSEMENT_ECHEANCE_PROGRAMME',
  VERSEMENT_PRELEVEMENT = 'VERSEMENT_PRELEVEMENT',
  VVIND = 'VVIND',
  VVP = 'VVP',
  VVL_DEBIT_RC = 'VVL_DEBIT_RC',
  VVL_CB_RC = 'VVL_CB_RC',
  // arbitrage
  ARBITRAGE = 'ARBITRAGE',
  COMPTABLE_GAGNANT_CASH = 'COMPTABLE_GAGNANT_CASH',
  TRANSFERT = 'TRANSFERT',
  // remboursement
  REMBOURSEMENT = 'REMBOURSEMENT',
  REMBOURSEMENT_ANTICIPE = 'REMBOURSEMENT_ANTICIPE',
  TOTAL_INDISPO = 'TOTAL_INDISPO',
  PARTIEL = 'PARTIEL',
  TOTAL = 'TOTAL',
  NONE = 'NONE',
}

export const VersementTypes = [
  OperationDetailsBoTypeEnum.VERSEMENT,
  OperationDetailsBoTypeEnum.VERSEMENT_CB,
  OperationDetailsBoTypeEnum.VERSEMENT_ECHEANCE_PROGRAMME,
  OperationDetailsBoTypeEnum.VERSEMENT_PRELEVEMENT,
  OperationDetailsBoTypeEnum.VVIND,
  OperationDetailsBoTypeEnum.VVP,
  OperationDetailsBoTypeEnum.VVL_DEBIT_RC,
  OperationDetailsBoTypeEnum.VVL_CB_RC,
]
export const RefundTypes = [
  OperationDetailsBoTypeEnum.REMBOURSEMENT,
  OperationDetailsBoTypeEnum.REMBOURSEMENT_ANTICIPE,
  OperationDetailsBoTypeEnum.TOTAL_INDISPO,
  OperationDetailsBoTypeEnum.PARTIEL,
  OperationDetailsBoTypeEnum.TOTAL
]
export const ArbitrageTypes = [
  OperationDetailsBoTypeEnum.ARBITRAGE,
  OperationDetailsBoTypeEnum.COMPTABLE_GAGNANT_CASH,
  OperationDetailsBoTypeEnum.TRANSFERT
]
export const IncentiveTypes = [
  OperationDetailsBoTypeEnum.INTERESSEMENT,
  OperationDetailsBoTypeEnum.PARTICIPATION
]

export enum OperationDetailsTypeEnum {
  PROFITSHARING = 'PARTICIPATION',
  INCENTIVE = 'INTERESSEMENT',
  VERSEMENT = 'VERSEMENT',
}

export {
  OPERAIONS_HISTORY_TYPES_ROULE,
  OPERATION_HISTORY_RANGE_DATE_ROULE,
  REPARTITION_GROUP_FILTER_LIST,
  REPARTITION_SORT_FILTER_LIST,
};
