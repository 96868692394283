import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

export function useStyles() {
  const theme = useTheme();

  const styles = useMemo(() => StyleSheet.create({
    stepperContainer:
      {
        alignItems: 'center',
      },
    stepperStyle:
      {
        paddingBottom: theme.metrics.spacing.s,
        marginTop: -theme.metrics.spacing.s,
      },
  }), [theme]);

  return styles;
}
