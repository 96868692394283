import React, { PropsWithChildren, useState } from 'react';
import { View, TouchableOpacity, StyleProp, ViewStyle, TextStyle } from 'react-native';

import { FontIcon, Text } from '@ere-uilib/atoms';
import { useTheme, ThemeType, createUseStyles } from '@ere-uilib/styles';

interface DataProps extends ExpendableGreenStyles {
  title: string
  startOpened?: boolean
}

type ExpendableGreenStyles = {
  displaySupportsContainerStyle?: StyleProp<ViewStyle>;
  displaySupportsTextStyle?: StyleProp<TextStyle>;
  expandedIconStyle?: StyleProp<TextStyle>;
};

type ExpendableGreenStylesContext = {theme: ThemeType; startOpened:boolean, };

type Props = DataProps & ExpendableGreenStyles

export const ExpendableGreen: React.FC<PropsWithChildren<Props>> =  ({
  title,
  children,
  startOpened = false,
  displaySupportsContainerStyle
}) => {

  const theme = useTheme();
  const styles = useStyles({ theme, startOpened }, { displaySupportsContainerStyle });
  const [expanded, setExpanded] = useState(startOpened);

  return (
    <>
      <TouchableOpacity onPress={() => setExpanded(!expanded)}>
        <View style={styles.displaySupportsContainerStyle}>
          <Text
            style={styles.displaySupportsTextStyle}
            variant="t3">
            {title}
          </Text>
          <FontIcon
            color={theme.colors.basics.primary.c500}
            name={expanded ? 'chevron-haut' : 'chevron-bas'}
            size={theme.metrics.iconSizes.xm}
            style={styles.expandedIconStyle}
          />
        </View>
      </TouchableOpacity>
      {expanded &&  (
        <>{children}</>
      )
      }
    </>
  );
};

const getStyles = (
  context?: ExpendableGreenStylesContext,
  style?: ExpendableGreenStyles,
): ExpendableGreenStyles => ({
  displaySupportsContainerStyle: [{
    marginTop: context?.theme.metrics.spacing.xs,
    flexDirection: 'row',
    alignItems: 'center'
  }, style?.displaySupportsContainerStyle],
  displaySupportsTextStyle: [{
    color: context?.theme.colors.basics.primary.c500
  }, style?.displaySupportsTextStyle],
  expandedIconStyle: [{
    paddingLeft: context?.theme.metrics.spacing.s
  }, style?.expandedIconStyle]

});

const useStyles = createUseStyles(getStyles);
