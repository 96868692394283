import React from 'react';
import { View } from 'react-native';

import { PerformanceBarChart, Text, useTranslation } from '@components/index';
import { PerformanceBarChartData } from '@ere-uilib/molecules/charts/PerformanceBarChart/sharedInterfaces';
import { Event } from '@modules/funds/types';

import { useStyles } from './useStyles';
import {
  useScreenSizes,
} from '@ere-uilib/styles';

export interface PerformanceTemplateProps {
  fundName: string | null | undefined;
  earliestDate: string | undefined;
  latestDate: string | undefined;
  participationData: PerformanceBarChartData[];
  referenceData: PerformanceBarChartData[];
  isMorningStarFund: boolean;
  isFundmart: boolean;
  events?: Event[] | null;
}

export const PerformanceBarChartTemplate: React.FC<PerformanceTemplateProps> = ({
  fundName,
  earliestDate,
  latestDate,
  participationData,
  referenceData,
  isMorningStarFund,
  events,
  isFundmart
}) => {
  const {
    isMobile,
  } = useScreenSizes();
  const styles = useStyles({
    isMobile,
  });
  const { formatMessage, formatDate } = useTranslation();
  const displayEvents = isFundmart && events && events?.length > 0

  return (
    <>
      <Text
        style={styles.histogramTitle}
        variant="t3"
        weight="bold">
        {formatMessage({
          id: 'FundsheetTab3CalendarPerformanceHistogramTitle',
          values: {
            nom_fond: fundName
          }
        })}
      </Text>
      <Text
        style={styles.histogramSubtitle}
        variant="t3"
        weight="light">
        {formatMessage({
          id: 'FundsheetTab3CalendarPerformanceHistogramSubTitle',
          values: {
            devise: '€',
            date: formatDate({ value: earliestDate })
          }
        })}
      </Text>
      <View style={styles.chartContainer}>
        <PerformanceBarChart
          containerStyle={styles.barChartContainerStyle}
          data1={participationData}
          data2={referenceData}
          legends={[
            { name: formatMessage({ id: 'Fundsheet_Tab1_Participation_fund_label' }) },
            { name: formatMessage({ id: 'Fundsheet_Tab4_Benchmark_Index_legend' }) }
          ]}
          renderTooltip={({ datum }) => (
            <Text
              variant="t5"
              weight="light">
              {formatMessage({
                id: 'FundsheetTab3CalendarPerformanceHistogramMouseOverLegend',
                values: { Année: datum?.x }
              })}
            </Text>
          )}
        />
        {displayEvents && (
          <View style={styles.eventContainer}>
            {events?.map(event => (
              <Text
                variant="t4"
                weight="light">
                {event.texte}
              </Text>
            ))}
          </View>
        )}
        <Text
          variant="t4"
          weight="light">
          {isMorningStarFund
            ? formatMessage({
                id: 'Fundsheet_Tab3Perf_Sourcing_Morningstar_legend'
              })
            : formatMessage({
                id: 'Fundsheet_Tab3Perf_Sourcing_BNPPariabs_AM_legend'
              })}
          <Text
            variant="t4"
            weight="light">
            {`, ${formatMessage({
              id: 'FundsheetTab3CalendarPerformanceHistogramSourceDateLegend',
              values: {
                date: formatDate({ value: latestDate })
              }
            })}`}
          </Text>
        </Text>
      </View>
    </>
  );
};
