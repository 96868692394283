import {
  CampaignData,
  CampaignErrorState,
  CampaignValueState
} from '../types';

export enum CampaignActionsType {
  GET_USER_CAMPAIGN_REQUEST = 'GET_USER_CAMPAIGN_REQUEST',
  GET_USER_CAMPAIGN_SUCCESS = 'GET_USER_CAMPAIGN_SUCCESS',
  GET_USER_CAMPAIGN_FAILURE = 'GET_USER_CAMPAIGN_FAILURE',
  SUBMIT_USER_CAMPAIGN_REQUEST = 'SUBMIT_USER_CAMPAIGN_REQUEST',
  SUBMIT_USER_CAMPAIGN_SUCCESS = 'SUBMIT_USER_CAMPAIGN_SUCCESS',
  SUBMIT_USER_CAMPAIGN_FAILURE = 'SUBMIT_USER_CAMPAIGN_FAILURE',
  RESET_CAMPAIGN_REDUCER = 'RESET_CAMPAIGN_REDUCER',
  EXECUTE_CALLBACK = 'EXECUTE_CALLBACK'
}
export interface GetUserCampaignRequestAction {
  type: CampaignActionsType.GET_USER_CAMPAIGN_REQUEST;
  campaignId: string
}

export interface GetUserCampaignSuccessAction {
  type: CampaignActionsType.GET_USER_CAMPAIGN_SUCCESS;
  campaignData: CampaignData
}

export interface GetUserCampaignFailureAction {
  type: CampaignActionsType.GET_USER_CAMPAIGN_FAILURE;
  error: CampaignErrorState
}

export interface SubmitUserCampaignRequestAction {
  type: CampaignActionsType.SUBMIT_USER_CAMPAIGN_REQUEST;
  campaignId: string;
  value: CampaignValueState;
  toDoPostSuccessActionType?: string;
}

export interface SubmitUserCampaignSuccessAction {
  type: CampaignActionsType.SUBMIT_USER_CAMPAIGN_SUCCESS;
  campaignId: string
  isLaterAnswer?: boolean
}

export interface SubmitUserCampaignFailureAction {
  type: CampaignActionsType.SUBMIT_USER_CAMPAIGN_FAILURE;
  error: CampaignErrorState
}

export interface ResetCampaignReducerAction {
  type: CampaignActionsType.RESET_CAMPAIGN_REDUCER;
}

export interface ExecuteCallbackAction {
  type: CampaignActionsType.EXECUTE_CALLBACK,
  callbackActionType: string,
  redirectionAfterCallback?: keyof ReactNavigation.RootParamList
}

export type CampaignAction =
  | GetUserCampaignRequestAction
  | GetUserCampaignSuccessAction
  | GetUserCampaignFailureAction
  | SubmitUserCampaignRequestAction
  | SubmitUserCampaignSuccessAction
  | SubmitUserCampaignFailureAction
  | ResetCampaignReducerAction
