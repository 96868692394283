import {
  FundRepartitionSupportsState,
  FundsDocumentsState,
  FundSourceEnum,
  FundsPerformanceState,
  OverviewState
} from '@modules/funds/types';

import {
  mockPerformanceSharePriceHisto01,
  mockPerformanceSharePriceHisto02,
  mockPerformanceSharePriceHisto03,
  mockPerformanceSharePriceHisto04
} from './mockFundsPerformanceSubTables';

export const mockFundsErrorState = {
  code: 'testCode',
  correlationId: 'testCorrelationId',
  message: 'testMessage',
  innerMessage: 'testInnerMessage',
};

export const mockInnerNavigations = [
  'fund-card_tabs1_label',
  'fund-card_tabs2_label',
  'fund-card_tabs3_label',
];
export const mockLoadingInnerNavigations = [
  'fund-card_tabs3_label',
];

export const mockFundRepartitionSupportsState: FundRepartitionSupportsState = {
  supportIsin: 'mockSupportIsin',
  supportName: 'mockSupportName',
  supportType: 'mockSupportType',
  supportIdNoee: 'mockSupportIdNoee',
  riskLevel: 'mockRiskLevel',
  performanceValue: 20,
  amount: {
    amount: 1000,
    addedValue: 3000,
    numberOfUnits: 10,
  },
  supportDetailsUrl: 'mockSupportDetailsUrl',
  legalStatus: 'mockLegalStatus',
  isMaster: true,
  hasContribution: true,
  netAssetValue: {
    amount: 3000,
    currency: '€',
    dateValue: '2017-01-31T00:00:00',
  },
  plans: [
    {
      planName: 'mockPlan Name',
      totalInvestedAmount: 1000,
      totalNumberOfUnits: 10,
      hasContribution: true,
      hasVersement: true,
      hasInteressement: true,
      hasParticipation: true,
    },
    {
      planName: 'mockPlan Name',
      totalInvestedAmount: 1000,
      totalNumberOfUnits: 10,
      hasContribution: true,
      hasVersement: true,
      hasInteressement: true,
      hasParticipation: true,
    },
  ],
  hasVersement: true,
  hasInteressement: true,
  hasParticipation: true,
};

export const mockFundsDocumentState: FundsDocumentsState = {
  type: 'FACTSHEET',
  datePublish: '2020-01-31T00:00:00',
  format: 'PDF',
  url: 'https://test.com',
  source: 'BNP Paribas Asset Management',
  urlform: null,
  productId: null,
  isin: null,
  code: null,
  gpsShareCode: null,
  value: '-----',
  isBNPP: true,
};

export const mockFundOverView:OverviewState = {
  name: 'BNP PARIBAS PERSPECTIVES 2037-2039 I',
  managementFirm: 'SG0150',
  type: null,
  formeJuridique: 'SICAV',
  classification: 'Action',
  sharePriceHisto: mockPerformanceSharePriceHisto03,
  deDroit: '',
  dateLancement: '2021-09-17T00:00:00',
  periodiciteValorisation: 'Semi-mensuelle',
  sfdrArticle: '1',
  amfCategory: 'C1',
  dureePlacement: '5',
  perfYTD: 5.18,
  risqueSRI: null,
  risqueSRRI: '6',
  narrativeRisks: ['RISK_CREDIT', 'RISK_CHANGE', 'RISK_INFLATION'],
  objectifGestion: '',
  friendlyUrl: null,
  volatility: 11.62,
  source: FundSourceEnum.BNPP,
  dateLastVL: '2023-05-01T00:00:00',
  lastVL: 21.97,
  dateNextVL: '2021-09-21T00:00:00',
  hideGraph: false,
  isSolidaire: true,
  isISR: false,
  zoneGeograph: null,
  isDeleted: false,
  underlyingFundCode: null,
  underlyingFundLastVL: null,
  underlyingFundLastVLDate: null,
  currency: null,
  volatilityS: '11.62',
  productId: null,
  isin: 'FR0013391455',
  code: '25047',
  gpsShareCode: '42896',
  value: '25047-FR0013391455-42896-',
  isBNPP: false,
  isMorningStarFund: false,
};
export const mockFundOverView1:OverviewState = {
  name: 'BNP PARIBAS PERSPECTIVES 2037-2039 I',
  managementFirm: 'SG0150',
  type: null,
  formeJuridique: 'SICAV',
  classification: 'Action',
  deDroit: '',
  dateLancement: '2021-09-17T00:00:00',
  periodiciteValorisation: 'Trimestrielle',
  sfdrArticle: '2',
  amfCategory: 'C2',
  dureePlacement: '5',
  perfYTD: 5.18,
  risqueSRI: '7',
  risqueSRRI: null,
  narrativeRisks: ['RISK_CREDIT', 'RISK_CHANGE', 'RISK_INFLATION'],
  objectifGestion: '',
  friendlyUrl: null,
  volatility: 11.62,
  source: FundSourceEnum.BNPP,
  dateLastVL: '2023-05-01T00:00:00',
  lastVL: 21.97,
  dateNextVL: '2021-09-21T00:00:00',
  hideGraph: false,
  isSolidaire: true,
  isISR: false,
  zoneGeograph: null,
  isDeleted: false,
  underlyingFundCode: null,
  underlyingFundLastVL: null,
  underlyingFundLastVLDate: null,
  currency: null,
  volatilityS: '11.62',
  productId: null,
  isin: 'FR0013391455',
  code: '25047',
  gpsShareCode: '42896',
  value: '25047-FR0013391455-42896-',
  isBNPP: false,
  isMorningStarFund: false,
};

export const mockFundPerformance: FundsPerformanceState = {
  benchHisto: null,
  "source": FundSourceEnum.BNPP,
  "name": "MULTIMANAGERS ACTIONS EUROPE -FIDELITY",
  "classification": "Actions Internationales",
  "periodicite": "Jour",
  "volatility": 11.98612797762273,
  "risque": "6", 
  "isSolidaire": false,
  "isISR": false,
  events: [
    {
      startDate: '01/2009',
      endDate: '09/2009',
      texte: "Durant cette periode, le fond avait une politiue d'investissemnt"
    },
    {
      startDate: '01/2011',
      endDate: '09/20011',
      texte: "Durant cette periode, le fond avait une politiue d'investissemnt"
    }
  ],
  "sharePerf": [
    {
      "name": "ANSI",
      "type": "PerfCal1A",
      "value": 5.129999791040163,
      "date": "2018-04-30T00:00:00"
    },
    {
      "name": "CAL9Y",
      "type": "PerfCal9A",
      "value": 9.644509137959044,
      "date": "2014-04-30T00:00:00"
    },
    {
      "name": "AN10Y",
      "type": "PerfCal10A",
      "value": 4.61378959457317,
      "date": "2018-04-30T00:00:00"
    },
    {
      "name": "CAL10Y",
      "type": "PerfCal10A",
      "value": 20.633911868169097,
      "date": "2013-04-30T00:00:00"
    },
    {
      "name": "CAL7Y",
      "type": "PerfCal10A",
      "value": 9.644509137959044,
      "date": "2016-04-30T00:00:00"
    },
    {
      "name": "CAL8Y",
      "type": "PerfCal8A",
      "value": 9.644509137959044,
      "date": "2015-04-30T00:00:00"
    },
    {
      "name": "CAL6Y",
      "type": "PerfCal6A",
      "value": 9.644509137959044,
      "date": "2017-04-30T00:00:00"
    },
    {
      "name": "AN5Y",
      "type": "PerfCal5A",
      "value": 6.697927991337949,
      "date": "2018-04-30T00:00:00"
    },
    {
      "name": "5Y",
      "type": "PerfCal4A",
      "value": 38.935560264240365,
      "date": "2018-04-30T00:00:00"
    },
    {
      "name": "CAL5Y",
      "type": "PerfCal6A",
      "value": 5.153185774387659,
      "date": "2018-04-30T00:00:00"
    },
    {
      "name": "CAL4Y",
      "type": "PerfCal9A",
      "value": 9.644509137959044,
      "date": "2019-04-30T00:00:00"
    },
    {
      "name": "CAL3Y",
      "type": "PerfCal9A",
      "value": 9.30523017866729,
      "date": "2020-04-30T00:00:00"
    },
    {
      "name": "AN3Y",
      "type": "PerfCal4A",
      "value": 0.4110252020178784,
      "date": "2018-04-30T00:00:00"
    },
    {
      "name": "3Y",
      "type": "PerfCal9A",
      "value": 1.2566085497829116,
      "date": "2018-04-30T00:00:00"
    },
    {
      "name": "CAL2Y",
      "type": "PerfCal6A",
      "value": -0.2117454148726217,
      "date": "2021-04-30T00:00:00"
    },
    {
      "name": "CAL1Y",
      "type": "PerfCal6A",
      "value": 9.644509137959044,
      "date": "2022-04-30T00:00:00"
    },
    {
      "name": "AN1Y",
      "type": "PerfCal6A",
      "value": 0.4964536281869769,
      "date": "2018-04-30T00:00:00"
    },
    {
      "name": "1Y",
      "type": "PerfCal6A",
      "value": 0.506131281558031,
      "date": "2018-04-30T00:00:00"
    },
    {
      "name": "YTD",
      "type": "PerfCal6A",
      "value": -0.558758105532775,
      "date": "2018-05-02T00:00:00"
    }
  ],
  "benchIndex": null,
  "managementFirm": null,
  "shareName": "MULTIMANAGERS ACTIONS EUROPE -FIDELITY [Classic, C]",
  "dureePlacement": null,
  "sharePriceHisto": mockPerformanceSharePriceHisto04,
  "underlyingFundCode": null,
  "benchName": null,
  "benchPerf": [
    {
      "name": "CAL10Y",
      "type": "PerfCal6A",
      "value": 18.633911868169097,
      "date": "2013-04-30T00:00:00"
    },
    {
      "name": "CAL9Y",
      "type": "PerfCal6A",
      "value": 2.644509137959044,
      "date": "2014-04-30T00:00:00"
    },
    {
      "name": "CAL8Y",
      "type": "PerfCal6A",
      "value": 4.644509137959044,
      "date": "2015-04-30T00:00:00"
    },
    {
      "name": "CAL6Y",
      "type": "PerfCal6A",
      "value": 9.644509137959044,
      "date": "2017-04-30T00:00:00"
    },
    {
      "name": "CAL5Y",
      "type": "PerfCal6A",
      "value": 7.153185774387659,
      "date": "2018-04-30T00:00:00"
    },
    {
      "name": "CAL4Y",
      "type": "PerfCal6A",
      "value": 5.644509137959044,
      "date": "2019-04-30T00:00:00"
    },
    {
      "name": "CAL7Y",
      "type": "PerfCal6A",
      "value": 12.644509137959044,
      "date": "2016-04-30T00:00:00"
    },
    {
      "name": "CAL2Y",
      "type": "PerfCal2A",
      "value": -3.2117454148726217,
      "date": "2021-04-30T00:00:00"
    },
    {
      "name": "CAL3Y",
      "type": "PerfCal6A",
      "value": 2.30523017866729,
      "date": "2020-04-30T00:00:00"
    },
    {
      "name": "CAL1Y",
      "type": "PerfCal1A",
      "value": 7.644509137959044,
      "date": "2022-01-30T00:00:00"
    }
  ],
  "hideGraph": false,
  "zoneGeograph": "Europe",
  "perfYTD": -0.558758105532775,
  "currency": "EUR",
  "productId": 15617,
  "isin": "QS0002105TJ2",
  "code": "1042",
  "gpsShareCode": "4921",
  "secId": null,
  "value": "1042-QS0002105TJ2-4921-15617-",
  "isBNPP": true,
  "isMorningStarFund": false
}

export const mockMultiFundsOverView:OverviewState[] = [
  mockFundOverView,
  mockFundOverView1,
  mockFundOverView,
  mockFundOverView1,
];
const mockPerformanceSharePerf:FundsPerformanceState['sharePerf'] = [
  {
    "name": "ANSI",
    "type": "PerfCal6A",
    "value": 5.129999791040163,
    "date": "2018-04-30T00:00:00"
  },
  {
    "name": "AN10Y",
    "type": "PerfCal6A",
    "value": 4.61378959457317,
    "date": "2018-04-30T00:00:00"
  },
  {
    "name": "CAL10Y",
    "type": "PerfCal6A",
    "value": 20.633911868169097,
    "date": "2018-04-30T00:00:00"
  },
  {
    "name": "AN5Y",
    "type": "PerfCal6A",
    "value": 6.697927991337949,
    "date": "2018-04-30T00:00:00"
  },
  {
    "name": "5Y",
    "type": "PerfCal6A",
    "value": 38.935560264240365,
    "date": "2018-04-30T00:00:00"
  },
  {
    "name": "CAL5Y",
    "type": "PerfCal6A",
    "value": 5.153185774387659,
    "date": "2018-04-30T00:00:00"
  },
  {
    "name": "CAL3Y",
    "type": "PerfCal6A",
    "value": 9.30523017866729,
    "date": "2018-04-30T00:00:00"
  },
  {
    "name": "AN3Y",
    "type": "PerfCal6A",
    "value": 0.4110252020178784,
    "date": "2018-04-30T00:00:00"
  },
  {
    "name": "3Y",
    "type": "PerfCal6A",
    "value": 1.2566085497829116,
    "date": "2018-04-30T00:00:00"
  },
  {
    "name": "CAL2Y",
    "type": "PerfCal6A",
    "value": -0.2117454148726217,
    "date": "2018-04-30T00:00:00"
  },
  {
    "name": "CAL1Y",
    "type": "PerfCal6A",
    "value": 9.644509137959044,
    "date": "2018-04-30T00:00:00"
  },
  {
    "name": "AN1Y",
    "type": "PerfCal1A",
    "value": 0.4964536281869769,
    "date": "2018-04-30T00:00:00"
  },
  {
    "name": "1Y",
    "type": "PerfCal5A",
    "value": 0.506131281558031,
    "date": "2018-04-30T00:00:00"
  },
  {
    "name": "YTD",
    "type": "PerfCal6A",
    "value": -0.558758105532775,
    "date": "2018-05-02T00:00:00"
  }
]
export const mockMultiFundsPerformance:FundsPerformanceState[] = [
  {
    benchHisto: null,
    "source": FundSourceEnum.BNPP,
    "name": "MULTIMANAGERS ACTIONS EUROPE -FIDELITY",
    "classification": "Actions Internationales",
    "periodicite": "Jour",
    "volatility": 11.98612797762273,
    "risque": "6",
    "isSolidaire": false,
    "isISR": false,
    "sharePerf": mockPerformanceSharePerf,
    "benchIndex": null,
    "managementFirm": null,
    "shareName": "MULTIMANAGERS ACTIONS EUROPE -FIDELITY [Classic, C]",
    "dureePlacement": null,
    "sharePriceHisto": mockPerformanceSharePriceHisto01,
    "underlyingFundCode": null,
    "benchName": null,
    "benchPerf": null,
    "hideGraph": false,
    "zoneGeograph": "Europe",
    "perfYTD": -0.558758105532775,
    "currency": "EUR",
    "productId": 15617,
    "isin": "QS0002105TJ2",
    "code": "1042",
    "gpsShareCode": "4921",
    "secId": null,
    "value": "1042-QS0002105TJ2-4921-15617-",
    "isBNPP": true,
    "isMorningStarFund": false,
    events: [
      {
        startDate: '01/2009',
        endDate: '09/2009',
        texte: "Durant cette periode, le fond avait une politiue d'investissemnt"
      }
    ],
  },
  {
    benchHisto: null,
    "source": FundSourceEnum.BNPP,
    "name": "MULTIMANAGERS ACTIONS EUROPE -FIDELITY",
    "classification": "Actions Internationales",
    "periodicite": "Jour",
    "volatility": 11.98612797762273,
    "risque": "6", 
    "isSolidaire": false,
    "isISR": false,
    "sharePerf": mockPerformanceSharePerf,
    "benchIndex": null,
    "managementFirm": null,
    "shareName": "MULTIMANAGERS ACTIONS EUROPE -FIDELITY [Classic, C]",
    "dureePlacement": null,
    "sharePriceHisto": mockPerformanceSharePriceHisto02,
    "underlyingFundCode": null,
    "benchName": null,
    "benchPerf": null,
    "hideGraph": false,
    "zoneGeograph": "Europe",
    "perfYTD": -0.558758105532775,
    "currency": "EUR",
    "productId": 15617,
    "isin": "QS0002105TJ2",
    "code": "1042",
    "gpsShareCode": "4921",
    "secId": null,
    "value": "1042-QS0002105TJ2-4921-15617-",
    "isBNPP": true,
    "isMorningStarFund": false,
    events: [
      {
        startDate: '01/2009',
        endDate: '09/2009',
        texte: "Durant cette periode, le fond avait une politiue d'investissemnt"
      }
    ],
  },
  {
    benchHisto: null,
    "source": FundSourceEnum.BNPP,
    "name": "MULTIMANAGERS ACTIONS EUROPE -FIDELITY",
    "classification": "Actions Internationales",
    "periodicite": "Jour",
    "volatility": 11.98612797762273,
    "risque": "6", 
    "isSolidaire": false,
    "isISR": false,
    "sharePerf": mockPerformanceSharePerf,
    "benchIndex": null,
    "managementFirm": null,
    "shareName": "MULTIMANAGERS ACTIONS EUROPE -FIDELITY [Classic, C]",
    "dureePlacement": null,
    "sharePriceHisto": mockPerformanceSharePriceHisto03,
    "underlyingFundCode": null,
    "benchName": null,
    "benchPerf": null,
    "hideGraph": false,
    "zoneGeograph": "Europe",
    "perfYTD": -0.558758105532775,
    "currency": "EUR",
    "productId": 15617,
    "isin": "QS0002105TJ2",
    "code": "1042",
    "gpsShareCode": "4921",
    "secId": null,
    "value": "1042-QS0002105TJ2-4921-15617-",
    "isBNPP": true,
    "isMorningStarFund": false,
    events: [
      {
        startDate: '01/2009',
        endDate: '09/2009',
        texte: "Durant cette periode, le fond avait une politiue d'investissemnt"
      }
    ],
  },
  {
    benchHisto: null,
    "source": FundSourceEnum.BNPP,
    "name": "MULTIMANAGERS ACTIONS EUROPE -FIDELITY",
    "classification": "Actions Internationales",
    "periodicite": "Jour",
    "volatility": 11.98612797762273,
    "risque": "6",
    "isSolidaire": false,
    "isISR": false,
    "sharePerf": mockPerformanceSharePerf,
    "benchIndex": null,
    "managementFirm": null,
    "shareName": "MULTIMANAGERS ACTIONS EUROPE -FIDELITY [Classic, C]",
    "dureePlacement": null,
    "sharePriceHisto": mockPerformanceSharePriceHisto04,
    "underlyingFundCode": null,
    "benchName": null,
    "benchPerf": null,
    "hideGraph": false,
    "zoneGeograph": "Europe",
    "perfYTD": -0.558758105532775,
    "currency": "EUR",
    "productId": 15617,
    "isin": "QS0002105TJ2",
    "code": "1042",
    "gpsShareCode": "4921",
    "secId": null,
    "value": "1042-QS0002105TJ2-4921-15617-",
    "isBNPP": true,
    "isMorningStarFund": false,
    events: [
      {
        startDate: '01/2009',
        endDate: '09/2009',
        texte: "Durant cette periode, le fond avait une politiue d'investissemnt"
      }
    ],
  },
];
