import React from 'react';

import { useTranslation, InformativePopin } from '@components/index';

import {
  ProfitSharingIncentiveRetirementAmountValidationConnectedProps
} from './interfaces';

export const ProfitSharingIncentiveRetirementAmountValidationConnectedComponent = ({
  isVisible,
  onCancel,
  onValidate
}:ProfitSharingIncentiveRetirementAmountValidationConnectedProps) => {
  const { formatMessage } = useTranslation()

  return (
    <InformativePopin
      buttonTitle={formatMessage({
        id: 'PIinvestRCContinuerBouton',
      })}
      cancelButtonTitle={formatMessage({
        id: 'PIinvestRCAnnulerBouton',
      })}
      description={formatMessage({
        id: 'PIinvestRCDescription',
      })}
      iconName="retreat"
      isModalVisible={isVisible}
      onCancel={onCancel}
      onValidate={onValidate}
      title={formatMessage({
        id: 'PIinvestRCTitre',
      })}
    />
  );
};
