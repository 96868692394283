import React from 'react';
import { ColorValue } from "react-native";
import { CustomIconProps } from './interfaces'

export enum CardStepperStepStatus {
  SCHEDULED = 'SCHEDULED',
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  CANCELED = 'CANCELED',
}

export interface CardStepperStepType {
  status: CardStepperStepStatus;
  isDisabled?: boolean;
  arrowColor?: ColorValue;
  customIconProps?: CustomIconProps;
  title?: string;
  description?: string;
  renderMainContent?: () => React.ReactNode;
  renderSecondaryContent?: () => React.ReactNode;
}

export interface CardStepperMeasure {
  indicatorTop: number;
  indicatorHeight: number;
  mainContentHeight: number;
  secondaryContentHeight: number;
}
