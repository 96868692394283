import React from "react";
import { StyleProp, TextStyle, View, ViewStyle } from "react-native";

import {
  Paragraph,
  PieChart,
  Text,
  Title,
  useScreenSizes,
  useTheme,
  useTranslation,
} from "@components/index";
import { PieChartData } from "@ere-uilib/types";

import { useStyles } from './useStyles';

type OadProfilComponentStyles = {
  containerStyle?: StyleProp<ViewStyle>;
  svgContainer?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  textContainerStyle?: StyleProp<ViewStyle>;
  legendContainer?: StyleProp<ViewStyle>;
  legendPointStyle?: StyleProp<ViewStyle>;
  chartContainerStyle?: StyleProp<ViewStyle>;
  chartCardStyle?: StyleProp<ViewStyle>;
  bottomTitle?: StyleProp<TextStyle>;
  chartTitleStyle?: StyleProp<TextStyle>;
};

interface DataProps {
  repartitionData?: PieChartData[];
  simulationData?: {
    value: number;
    key: string;
    color: string;
  }[];
  repartitionLegend: {
    color: string;
    title: string;
  }[]
}

type Props = DataProps & OadProfilComponentStyles

export function OadProfilComponent({
  repartitionData,
  simulationData,
  repartitionLegend
}: Props) {
  const { formatMessage } = useTranslation();
  const theme = useTheme();
  const { isMobile } = useScreenSizes();
  const styles = useStyles();

  return (
    <View style={{ backgroundColor: theme.colors.basics.grey.c100 }}>
      <View style={styles.chartContainerStyle}>
        <View style={styles.chartCardStyle}>
          <Title
            style={styles.chartTitleStyle}
            variant='t8'
            weight="bold"
          >
            {formatMessage({ id: 'AbritrageOADResultatQRRepartitionActuelleDescription' })}
          </Title>
          <PieChart
            chartData={repartitionData}
            hasPlaceholder={false}
            height={125}
            labelFontSize={8}
            labelRadius={40}
            minSectionLargerPercentageValue={isMobile ? 0.06 : 0.03}
            width={125}
          />
        </View>
        <View style={styles.chartCardStyle}>
          <Title
            style={styles.chartTitleStyle}
            variant='t8'
            weight="bold"
          >
            {formatMessage({ id: 'AbritrageOADResultatQRMaSimulationDescription' })}
          </Title>
          <PieChart
            chartData={simulationData}
            height={125}
            labelFontSize={8}
            labelRadius={40}
            minSectionLargerPercentageValue={isMobile ? 0.06 : 0.03}
            width={125}
          />
        </View>
      </View>
      <View style={styles.legendContainer}>
        {repartitionLegend && repartitionLegend.map((repartition, index) => {
          return (
            <View
              key={index}
              style={{ flexDirection: "row", marginLeft: 10 }}>
              <View
                style={[
                  styles.legendPointStyle,
                  { backgroundColor: repartition.color },
                ]}
              />
              <Text
                variant={'t3'}
                weight='light'
              >{repartition.title}</Text>
            </View>
          )
        }
        )}
      </View>
      <View style={styles.textContainerStyle}>
        <Paragraph
          variant="t3"
          weight="light"
        >
          {formatMessage({ id: 'AbritrageOADResultatQRCommentaireContenu' })}
        </Paragraph>
      </View>
    </View>
  );
}

export default OadProfilComponent;
