import React, { useCallback, useRef } from 'react';
import { View, ScrollView, TouchableOpacity } from 'react-native';

import { Text } from '@ere-uilib/atoms';
import { useTranslation } from '@ere-uilib/translations';

import { LinkBarProps } from './interface';
import { useStyles } from './styles';

export const LinkBar: React.FC<React.PropsWithChildren<LinkBarProps>> = ({
  backgroundColor,
  items = [],
  selectedItem,
  onSelect,
  containerStyle,
  containerInnerStyle,
  linkBarListItemStyle,
  linkBarListItemSelectedStyle,
  listItemTextStyle,
  listItemTextSelectedStyle,
  cursorContainerStyle,
  cursorTriangleStyle,
  disabled,
  testId
}) => {

  const itemPositions = useRef<number[]>([]);
  const scrollViewRef = useRef<ScrollView>(null);

  const styles = useStyles();

  const handleNavigation = useCallback((index: number) => {
    onSelect(items[index]);
  }, [items, onSelect]);

  const handleMenuItemPress = useCallback((index: number) => {
    scrollViewRef?.current?.scrollTo?.({ x: itemPositions.current[index], y: 0, animated: true });
    handleNavigation(index);
  }, [handleNavigation]);

  const { formatMessage } = useTranslation();
  const renderItems = useCallback(() => {
    return items.map((item, index) => {
      const isSelectedItem = selectedItem === item;

      return (
        <View
          key={'linkbar-' + item}
          onLayout={event => {
            itemPositions.current[index] = event?.nativeEvent?.layout?.x;
          }}
          style={styles.itemContainer}>
          <TouchableOpacity
            activeOpacity={1}
            disabled={disabled}
            onPress={() => handleMenuItemPress(index)}
            style={[
              styles.linkBarListItemStyle,
              linkBarListItemStyle,
              isSelectedItem && styles.linkBarListItemSelectedStyle,
              isSelectedItem && linkBarListItemSelectedStyle
            ]}>
            <Text
              key={'linkBarListItem' + item + index}
              style={[
                styles.listItemTextStyle,
                listItemTextStyle,
                isSelectedItem && styles.listItemTextSelectedStyle,
                isSelectedItem && listItemTextSelectedStyle
              ]}
              testId={testId + '_linkBarListItem' + '_' + index}>
              {formatMessage({ id: item })}
            </Text>
          </TouchableOpacity>
          {isSelectedItem && (
            <View style={[styles.cursorContainerStyle, cursorContainerStyle]}>
              <View style={[styles.cursorTriangleStyle, cursorTriangleStyle]} />
            </View>
          )}
        </View>
      );
    });
  }, [
    cursorContainerStyle,
    cursorTriangleStyle,
    disabled,
    formatMessage,
    handleMenuItemPress,
    items,
    linkBarListItemSelectedStyle,
    linkBarListItemStyle,
    listItemTextSelectedStyle,
    listItemTextStyle,
    selectedItem,
    styles,
    testId
  ]);

  return (
    <View>
      <ScrollView
        bounces={false}
        contentContainerStyle={[
          styles.containerInnerStyle,
          containerInnerStyle,
        ]}
        horizontal
        ref={scrollViewRef}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        style={[styles.containerStyle, containerStyle]}
      >
        {renderItems()}
      </ScrollView>
      <View style={[styles.line, { backgroundColor }]}/>
    </View>
  );
};