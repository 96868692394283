import { InstallmentsTabContext, InstallmentsTabStyles } from './interfaces';

export const getStyles = (
  context?:  InstallmentsTabContext,
  style?: InstallmentsTabStyles,
): InstallmentsTabStyles => ({
  buttonContainer: [
    {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
      paddingLeft: context?.theme.metrics.spacing.s,
      paddingRight: context?.theme.metrics.spacing.s,
      marginBottom: context?.theme.metrics.spacing.s,
      zIndex: 10
    },
    style?.buttonContainer
  ],
  buttonWrapper: [
    {
      paddingRight: 11,
      backgroundColor: context?.theme.colors.buttons.filter.c500,
      borderRadius: 20
    },
    style?.buttonWrapper
  ],
  helpPointStyle: [
    {
      paddingLeft: context?.theme.metrics.spacing.s
    },
    style?.buttonWrapper
  ]
});
export default getStyles;
