import React, { useCallback, useEffect, useMemo } from 'react';

import { SelfcareTextIdentifierFormTemplate } from '@components/templates';
import { InvitationRegistrationOriginEnum } from '@constants/invitation';
import { useTranslation } from '@ere-uilib/translations';
import { useInvitation } from '@hooks/useInvitation';
import { useSettings } from '@hooks/useSettings';
import { useDispatch } from 'react-redux';
import { createSelfcareInvitationResetError } from '@modules/invitation/actions/invitationActions';
import { useCalendarLabels } from '@ere-uilib/hooks';

interface Props {
  onPressBackButton?: () => void;
  onClose: () => void;
}

export function PersoneoIdentifierFormPageContainer({
  onPressBackButton,
  onClose,
}: Props) {
  const dispatch = useDispatch();
  const { formatMessage, getMessageRaw } = useTranslation();
  const { monthNames, dayNames, dayNamesShort } = useCalendarLabels();
  const {
    createSelfcareInvitationFromExternalIdentityRequest,
    isLoading,
    errorMessage,
  } = useInvitation();
  const { optionEERRegexPersoneo } = useSettings();

  const dictionary = useMemo(() => ({
    identifierInputPlaceholder: formatMessage({
      id: 'EerSelfcareStep2PersoneoPlaceholder',
    }),
    confirmButton: formatMessage({ id: 'EerSelfcareStep2ConfirmerButton' }),
    helpText: formatMessage({
      id: 'EerSelfcareStep2OuTrouverLabel',
    }),
    helpLabel: formatMessage({
      id: 'EerSelfcareStep2PersoneoLabel',
    }),
    dateOfBirth: formatMessage({
      id: 'EerSelfcareStep2DateNaissanceLabel',
    }),
    errorHelper: formatMessage({
      id: 'EerSelfcareStep3CompteIntrouvableErreur',
    }),
    reedMore: formatMessage({
      id: 'EerSelfcareStep3CompteIntrouvableLink',
    }),
    errorReadMoreContentHtml: getMessageRaw({
      id: 'EerSelfcareStep2PersoneoInfoContent',
    }),
    errorReadMoreModalTitle: formatMessage({
      id: 'EerSelfcareStep2PersoneoInfoTitle',
    }),
    inputHelpPointContentHtml: getMessageRaw({
      id: 'EerSelfcareStep2OuTrouverPopinPersoneoContent',
    }),
    inputHelpPointModalTitle: formatMessage({
      id: 'EerSelfcareStep2OuTrouverPopinTitle',
    }),
    backButton: formatMessage({
      id: 'EerSelfcareStep2RetourLabel',
    }),
    pageTitle: formatMessage({
      id: 'EerSelfcareStep2ControleTitle',
    }),
    placeholderDay: formatMessage({
      id: 'EerSelfcareStep2PlaceholderJourLabel',
    }),
    placeholderMonth: formatMessage({
      id: 'EerSelfcareStep2PlaceholderMoisLabel',
    }),
    placeholderYear: formatMessage({
      id: 'EerSelfcareStep2PlaceholderAnneeLabel',
    }),
    dateErrorMessage: formatMessage({
      id: 'EerSaisieDateNaissanceInvalide',
    }),
    monthNames,
    dayNames,
    dayNamesShort,
  }), [formatMessage, getMessageRaw]);

  const handlePressSubmit = useCallback((identifier, dateOfBirth) => {
    if (!identifier || !dateOfBirth) {
      return;
    }
    createSelfcareInvitationFromExternalIdentityRequest({
      type: InvitationRegistrationOriginEnum.PERSONEO,
      birthDate: dateOfBirth,
      identifier,
    });
  }, [createSelfcareInvitationFromExternalIdentityRequest]);

  useEffect(() => {
    dispatch(createSelfcareInvitationResetError())
  }, [dispatch])

  return (
    <SelfcareTextIdentifierFormTemplate
      dictionary={dictionary}
      errorMessage={errorMessage}
      identifierRegex={optionEERRegexPersoneo}
      isLoading={isLoading}
      onClose={onClose}
      onPressBackButton={onPressBackButton}
      onPressSubmit={handlePressSubmit}
    />
  );
}
