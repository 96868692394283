import { PlanCompartmentsType } from '@modules/common/types';

export const getTechnicalFund = (plan: PlanCompartmentsType) => {
  let technicalFund;

  for (const compartment of plan?.compartments) {
    if (technicalFund) break;
    for (const management of compartment?.managements) {
      if (technicalFund) break;
      for (const support of management?.supports) {
        if (support?.advancedProperties?.IsTechnicalFund) {
          technicalFund = {
            compartmentCode: compartment?.code,
            managementId: management?.id,
            ...support
          };
          break;
        }
      }
    }
  }

  return technicalFund;
};
