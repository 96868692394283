import React, { useCallback, useMemo } from 'react';

import { getPlanTypeColors, useTheme, useTranslation } from '@components/ERE-UILib';
import { ItemState } from '@ere-uilib/molecules/inputs/Select/types';
import { ControlDataManagement, DrivingDataInputKeyValueEnum, EditedControlDataType } from '@modules/control-data/types';
import { DrivingDataCardProps } from '../Interface';
import { ControlDataCard } from './ControlDataCard';
import { enumToStringArray } from '../utils';

export const useController = () => {
  const theme = useTheme();
  const { formatMessage, getMessageRaw } = useTranslation();

  //labels
  const pageDictionnary = useMemo(
    () => ({
      title: formatMessage({
        id: 'DrivingDataJourneyEsPageTitle'
      }),
      headerDescription: getMessageRaw({
        id: 'DrivingDataJourneyProfilEditPageHeader'
      }),
      changeEsC2Title: formatMessage({ id: 'DrivingDataJourneyESProfilPERCO' }),
      changeEsC1Title: formatMessage({ id: 'DrivingDataJourneyEsProfilPERC1' }),
      changeEsC1BisTitle: formatMessage({ id: 'DrivingDataJourneyEsProfilPERC1bis' }),
      riskProfile: formatMessage({ id: 'DrivingDataJourneyEsChoiceProfil' }),
      horizon: formatMessage({ id: 'DrivingDataJourneyEsChoiceAge' }),
      riskProfileWarning: formatMessage({ id: 'DrivingDataJourneyESWarningProfil' }),
      choiceProfileWarning: formatMessage({ id: 'DrivingDataJourneyESWarningAge' }),
      validationButtonTitle: formatMessage({ id: 'DrivingDataJourneyEsButtonTitle' }),
      popinButtonTitle: formatMessage({ id: 'DrivingDataJourneyChangeEsConfirmPopinButton' }),
      popinMessage: formatMessage({ id: 'DrivingDataJourneyChangeEsConfirmPopin' }),
      dataNotFilledIn: formatMessage({ id: 'LabelUnderIconSteeringDataNotFilledIn' }),
      drivingDataESAge: formatMessage({ id: 'DrivingDataJourneyESAge' }),
      drivingDataRCForm: getMessageRaw({ id: 'DrivingDataJourneyRcForm' }),

    }),
    [formatMessage, getMessageRaw]
  );

  const onInputValueChange = useCallback(
    (
      labelKey: DrivingDataInputKeyValueEnum.RISK_KEY | DrivingDataInputKeyValueEnum.HORIZON_KEY,
      valueKey: DrivingDataInputKeyValueEnum.RISK_VALUE | DrivingDataInputKeyValueEnum.HORIZON_VALUE,
      item: ItemState,
      element: ControlDataManagement
    ) => {
      const formValues = {
        ...element.formValues,
        [labelKey]: item.label,
        [valueKey]: item.value
      };

      element = {
        ...element,
        formValues
      };

      return element;
    },
    []
  );

  const getControlDataFormValues = useCallback((
    management: ControlDataManagement,
    drivingDataFormState: EditedControlDataType
  ) => {
    const controlDataEditionFormatedData = [...drivingDataFormState.drivingDataRequestViewModels];
    const numberOfFields = enumToStringArray(DrivingDataInputKeyValueEnum).length;

    if (management?.formValues && Object.keys(management?.formValues).length === numberOfFields) {
      const alreadyExist = controlDataEditionFormatedData.find(
        (data) => data.managementId === management.managementId
      );

      if (alreadyExist) {
        const index = controlDataEditionFormatedData.indexOf(alreadyExist);

        controlDataEditionFormatedData[index] = {
          managementId: alreadyExist.managementId,
          riskProfile: String(management?.formValues?.riskProfileLabel),
          horizon: Number(management?.formValues?.horizonValue),
        };

        return { drivingDataRequestViewModels: controlDataEditionFormatedData }
      } else {
        const newData = {
          managementId: String(management.managementId),
          riskProfile: String(management?.formValues?.riskProfileLabel),
          horizon: Number(management?.formValues?.horizonValue),
        };

        controlDataEditionFormatedData.push(newData);

        return { drivingDataRequestViewModels: controlDataEditionFormatedData }

      }
    }
  }, []);

  const renderDrivingDataCard = useCallback((
    {
      controlDataStatus,
      isEditing,
      planId,
      planName,
      authorization,
      contents,
      onEdit,
      onRetry
    }: DrivingDataCardProps
  ) => {
    const colorShades = theme.colors.basics.primary
    return (
      <ControlDataCard
        headerBackgroundColor={colorShades.c100}
        headerBorderColor={colorShades.c500}
        isEditable={authorization}
        isError={!isEditing[planId]
          ? controlDataStatus.isDevicesControlDataError
          : controlDataStatus.isGetUpdatePilotingDataError
        }
        isLoading={isEditing[planId] && (controlDataStatus.isEditApiLoading)}
        key={'control-data-card-' + planId}
        keyExtractor={childIndex => `control-data-card-${planId}-row-${childIndex}`}
        onEditPress={() => onEdit(planId)}
        onRetry={() => onRetry(planId)}
        title={formatMessage({
          id: 'DrivingDataJourneyProfilEditPageCardESTitle',
          values: { libelle_dispositif_epargne: planName }
        })}>
        {contents()}
      </ControlDataCard>
    )
  }, [formatMessage]);

  return {
    pageDictionnary,
    onInputValueChange,
    getControlDataFormValues,
    renderDrivingDataCard
  };
};
