import { useMemo } from 'react'
import { StyleSheet } from 'react-native'

import { useTheme } from '@ere-uilib/styles'

export const useStyles = () => {
  const theme = useTheme()

  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: { flex: 1 }
      }),
    [theme]
  )

  return styles
}
