import React, { useMemo } from 'react'
import { StyleSheet } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

export const useStyles = () => {
  const theme = useTheme();

  const styles = useMemo(() => StyleSheet.create({
    containerStyle: {
      backgroundColor: theme.colors.basics.white,
    },
    headContentContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.metrics.spacing.m
    },
    askConfirmText: {
      marginBottom: theme.metrics.spacing.m
    },
    actionsButtonStyle: {
      marginBottom: theme.metrics.spacing.xhuge
    }
  }), [theme])

  return styles
};

