import {
  InstallmentOperationName
} from "@constants/installment"
import {
  ProfitSharingIncentiveTypeEnum
} from "@constants/ProfitSharingIncentive"

export type ContributionCalculOperationType = ProfitSharingIncentiveTypeEnum | (typeof InstallmentOperationName)

export interface EstimateContributionFetchSupportParamsType {
  supportId: string | null
  executionTriggerValue?: number
  amount: number
}
export interface EstimateCalculContributionFetchParamsType {
  companyId: string
  planId: number
  operationType: ContributionCalculOperationType
  distributions: {
    managementId: string | null;
    amount?: number;
    supports?: EstimateContributionFetchSupportParamsType[];
  }[]
}

export type EstimateCalculContributionApiResponse = {
  companyId: string
  planId: string
  distributions: {
    managementId: string,
    contributionAmountPaid?: number,
    contributionAmount?: number,
    supportTotalContributionAmount?: number,
    supportTotalPaidAmount?: number,
    supports?: {
      supportId: string
      paidAmount: number
      contributionAmount?: number
    }[]
  }[]
  contributionTotalAmount?: number
}

export enum ContributionFamilyEnum {
  PAYMENT = 'payment',
  PROFITSHARING = 'profitSharing',
  INCENTIVE = 'incentive',
}