import { StackNavigationProp } from '@react-navigation/stack';
import React from 'react';
import { ViewStyle } from 'react-native';

import { EERKeyboardAvoidingViewPageWrapper } from '@components/index';
import { OtpForm } from '@ere-uilib/organisms';
import { OtpParametersState } from '@ere-uilib/types';
import { AppNavigatorInterface } from '@navigation/Interfaces';
interface ViewStyles {
  container?: ViewStyle;
}
interface Props {
  errorMessage: string;
  otpParameters: OtpParametersState | null;
  navigation: StackNavigationProp<AppNavigatorInterface>;
  style?: ViewStyle;
  styles?: ViewStyles;
  onSubmit: (otp: string) => void;
  resendCode: () => void;
  isAskCodeAgainCodeLoading?: boolean;
  isSendCodeLoading?: boolean;
  isAskAgainSuccess?: boolean;
}

export function OtpSecurisationFormPageComponent({
  errorMessage,
  otpParameters,
  navigation,
  isAskCodeAgainCodeLoading,
  isSendCodeLoading,
  onSubmit,
  resendCode,
  isAskAgainSuccess,
}: Props) {

  return (
    <EERKeyboardAvoidingViewPageWrapper>
      <OtpForm
        errorMessage={errorMessage}
        isAskAgainSuccess={isAskAgainSuccess}
        isAskCodeAgainCodeLoading={isAskCodeAgainCodeLoading}
        isSendCodeLoading={isSendCodeLoading}
        onClose={navigation.goBack}
        onSubmit={onSubmit}
        otpParameters={otpParameters}
        resendCode={resendCode}
      />
    </EERKeyboardAvoidingViewPageWrapper>
  );
}
