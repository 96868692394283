import {
  StyleProp,
  ViewStyle,
} from 'react-native';

import { SimpleButtonStyles, StylesContext } from './interface';

export const getStyles = (
  context?: StylesContext,
  style?: SimpleButtonStyles
): SimpleButtonStyles => {
  // define common styles for design + disabled props
  let containerBackgroundAndBorderStyle: StyleProp<ViewStyle>;
  let iconsColor:string|undefined;
  let textColor:string|undefined;

  const defaultPrimaryColor = context?.customPrimaryColor ||
    context?.theme.colors.buttons.classic.c500;

  const primaryColor = context?.hovered ?
    context?.theme.colors.buttons.classicHover.c500
    :
    defaultPrimaryColor;
  const secondaryColor = context?.customSecondaryColor || context?.theme.colors.basics.white;
  const disabledColor = context?.theme.colors.buttons.disabled.c500;
  const disableTextAndIconColor = context?.theme.colors.basics.grey.c400;

  if (context?.design === 'solid') {
    containerBackgroundAndBorderStyle = !context?.disabled ? {
      backgroundColor: primaryColor,
    } : {
      backgroundColor: disabledColor,
    };
    iconsColor = secondaryColor;
    textColor = secondaryColor;
  }
  if (context?.design === 'outlined') {
    containerBackgroundAndBorderStyle = !context?.disabled ? {
      backgroundColor: secondaryColor,
      borderWidth: 1,
      borderColor: primaryColor,
    } : {
      backgroundColor: secondaryColor,
      borderWidth: 1,
      borderColor: disabledColor,
    };
  }
  if (context?.design && ['outlined', 'light'].includes(context?.design)) {
    iconsColor = primaryColor;
    textColor = primaryColor;
  }
  if (context?.disabled) {
    iconsColor = disableTextAndIconColor;
    textColor = disableTextAndIconColor;
  }

  return {
    containerStyle: [
      {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 300, // we wnat a full rounded
        opacity: context?.pressed ? 0.5 : 1,
        zIndex: undefined,
      },
      context?.isSticky && {
        position: 'absolute',
        bottom: 0,
      },
      context?.size === 'large' && {
        paddingVertical: context?.theme.metrics.spacing.s,
        paddingHorizontal: context?.theme.metrics.spacing.xm,
        minHeight: 48,
        minWidth: 65
      },
      context?.size === 'small' && {
        paddingVertical: context?.theme.metrics.spacing.xs,
        paddingHorizontal: context?.theme.metrics.spacing.m,
        minHeight: 30,
      },
      containerBackgroundAndBorderStyle,
      style?.containerStyle,
    ],
    innerContainerStyle: [
      {
        alignSelf: 'center',
        maxWidth: '100%',
        flexDirection: 'row',
        alignItems: 'center',
      },
      style?.innerContainerStyle,
    ],
    contentContainerStyle: [
      {
        flexShrink: 1,
      },
      style?.contentContainerStyle,
    ],
    textStyle: [
      {
        textAlign: 'center',
        fontSize: context?.theme.fonts.fontSize.button.t2,
        color: textColor,
      },
      context?.loading && {
        marginLeft: context?.theme.metrics.spacing.s,
      },
      style?.textStyle,
    ],
    leftIconStyle: [
      {
        fontSize: context?.theme.metrics.iconSizes.xxm,
        color: iconsColor,
        marginRight: context?.isContent ||
        context?.isRightIcon ? context?.theme.metrics.spacing.s : 0,
      },
      style?.leftIconStyle,
    ],
    rightIconStyle: [
      {
        fontSize: context?.theme.metrics.iconSizes.xxm,
        color: iconsColor,
        marginLeft: context?.isContent ||
        context?.isLeftIcon ? context?.theme.metrics.spacing.s : 0,
      },
      style?.rightIconStyle,
    ],
  };
};
