import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useScreenSizes, useTheme } from '@ere-uilib/styles';

export const useStyles = () => {
  const theme = useTheme();
  const { isDesktop } = useScreenSizes();

  return useMemo(
    () =>
      StyleSheet.create({
        container: {
          maxWidth: isDesktop ? theme.metrics.contentSizes.centeredContentMaxWidth : '100%',
        },
        title: {
          fontSize: theme.fonts.fontSize.paragraph.t2,
          fontFamily: theme.fonts.fontFamily.regular
        },
        rightIconStyle: {
          bottom: theme.metrics.spacing.s,
        },
      }),
    [theme, isDesktop]
  );
}
