import React, { useCallback } from 'react';
import { Text } from '@ere-uilib/atoms';
import { TouchableOpacity, View } from 'react-native';
import {
  AvailabilitiesDetailByFundsProps
} from './interfaces';
import { useTranslation } from '@ere-uilib/index';
import {
  useStyles
} from './useStyles';
import { RouteNames } from '@constants/navigation';
import { AvailabilitiesFundsState, AvailabilityFund } from '@modules/savings/types';
import { AvailabilityEnum } from '@constants/fund';
import {useNavigation} from "@react-navigation/native";
import {StackNavigationProp} from "@react-navigation/stack";
import {AppNavigatorInterface} from "@navigation/Interfaces";

export const AvailabilitiesDetailsByFunds: React.FC<AvailabilitiesDetailByFundsProps> =
  ({
    availabilitiesFunds
  }) => {
    const { formatMessage, formatCurrencyNumber, formatDate, formatShareCountNumber } = useTranslation();
    const styles = useStyles();
    const navigation = useNavigation<StackNavigationProp<AppNavigatorInterface>>();
    const onRepartitionCardPress = (fundId: string, title: string) => {
      fundId && navigation.navigate(RouteNames.Funds, { id: fundId, title: title })
    }

    const renderAvailabilityItem = useCallback((availability: AvailabilityFund, index: number) => {
      let availabilityDateLabel: string;

      switch (availability.condition) {
        case AvailabilityEnum.Available:
          availabilityDateLabel = formatMessage({ id: 'AvailabilityViewbyFundsAvailableNowTitle' });
          break;
        case AvailabilityEnum.Retirement:
          availabilityDateLabel = formatMessage({ id: 'AvailabilityViewbyFundsPensionTitle' });
          break;
        case AvailabilityEnum.Unavailable:
          const translatedDate = formatDate({
            value: availability.datetime,
            options: {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            }
          });
          availabilityDateLabel = `${formatMessage({ id: 'AvailabilityViewbyFundsAvailableAtMaturityDate' })} ${translatedDate}`;
          break;
        default:
          availabilityDateLabel = "";
      }

      return (
        <View style={styles.availabilityItem} key={`availability-item-${index}`}>
          <Text variant={"t3"} weight={"light"} style={styles.availabilityDate}>{availabilityDateLabel}</Text>
          {availability.numberOfUnits > 0 &&
            <Text variant={"t3"} weight={"light"} style={styles.availabilityNumberOfUnits}>
              {formatShareCountNumber({ value: availability.numberOfUnits })} {formatMessage({ id: 'AvailabilityViewbyFundsAmuntsShare' })}
            </Text>
          }
          <Text variant={"t3"} weight={"light"} style={styles.availabilityAmount}>{formatCurrencyNumber({ value: availability.amount })}</Text>
        </View>
      )
    }, []);

    const renderFundItem = useCallback((fund: AvailabilitiesFundsState, index: number) => {
      return (
        <>
          <View style={styles.fundItemContainer} key={`fundItem_${index}`}>
            <View style={styles.nameAndAmountContainer}>
              <TouchableOpacity
                style={styles.fundName}
                onPress={() =>
                  onRepartitionCardPress(fund.supportIsin, fund.supportName)
                }>
                <Text weight={"bold"} variant={"t3"} style={{ textDecorationLine: 'underline' }}>{fund.supportName}</Text>
              </TouchableOpacity>
              <Text variant={"t3"} weight={"bold"}>{formatCurrencyNumber({ value: fund.amount })}</Text>
            </View>

            <View style={styles.availabilityContainer}>
              {fund.availabilities.map((availability, index) => renderAvailabilityItem(availability, index))}
            </View>
          </View>
        </>
      )
    }, []);

    return (
      <>
        <View style={styles.contentContainer}>
          {availabilitiesFunds?.map(renderFundItem)}
        </View>
        <Text variant="t4" weight="light" style={styles.brutIndicator}>
          {formatMessage({ id: 'RetirementJourneySavingGrossAmountHeader' })}
        </Text>
      </>
    );
  };
