import React, { useCallback } from "react";
import { Modal, SquareIcon, Title } from "@ere-uilib/atoms"
import { ArbitrationEcheancePopinModalProps } from "./interface"
import { ModalFrame, SimpleButton } from "@ere-uilib/molecules"
import { useTheme } from "@ere-uilib/styles";
import { IconEnum } from "@ere-uilib/enums";
import { useStyles } from "./useStyles";
import { useTranslation } from "@ere-uilib/translations";
import { View } from "react-native";

export const ArbitrationEcheancePopinModal: React.FC<React.PropsWithChildren<ArbitrationEcheancePopinModalProps>> = ({
  isModalVisible = false,
  onCloseModal,
  onConfirmButtonPress
}) => {
    const theme = useTheme();
    const styles = useStyles();
    const { formatMessage } = useTranslation();

    const renderActionButtonsContainer = useCallback(() => {
      return (
        <>
          <View
            style={styles.buttonWrapper}>
            <SimpleButton
              design="outlined"
              containerStyle={styles.buttonContainer}
              onPress={onCloseModal}
              title={formatMessage({ id: 'MaturityArbitrageWarningMessageCancellationButton' })}
            />
          </View>
          <View
            style={styles.buttonWrapper}>
            <SimpleButton
              design="solid"
              containerStyle={styles.buttonContainer}
              onPress={onConfirmButtonPress}
              title={formatMessage({ id: 'MaturityArbitrageWarningMessageConfirmationButton' })}
            />
          </View>
        </>
      )
    }, [
      onConfirmButtonPress,
      onCloseModal,
      formatMessage
    ]);

    return (
      <Modal visible={isModalVisible}>
        <ModalFrame>
          <View
            style={styles.contentContainer}>
            <SquareIcon
              backgroundColor={theme.colors.notifications.warning.c100}
              iconColor={theme.colors.notifications.warning.c500}
              iconName={IconEnum.WARNING}
              iconSize={theme.metrics.iconSizes.xl}
              style={{
                container: styles.iconContainer
              }}
            />
            <Title
              style={styles.modalTitleStyle}
              variant="t6">
              {formatMessage({ id: 'MaturityArbitrageWarningMessageLabel' })}
            </Title>
          </View>
          {renderActionButtonsContainer()}
        </ModalFrame>
      </Modal>
    )
  }
