import React from 'react';
import {
  SafeAreaView,
  ScrollView,
  StyleProp,
  TouchableOpacity,
  View,
  ViewStyle
} from 'react-native';

import { BackgroundGradientTransition, FontIcon, PageBackgroundGreen } from '@ere-uilib/atoms';
import { LogoBnp, LogoColorModeEnum, LogoOrientationEnum, WebCard } from '@ere-uilib/molecules';
import { createUseStyles, ThemeType, useScreenSizes, useTheme } from '@ere-uilib/styles';

interface Props {
  children: React.ReactNode;
  displayLogo?: boolean;
  displayMobileBackgroundImage?: boolean;
  onLogoPress?: () => void;
  renderDesktopCardLeftContent?: () => React.ReactNode;
  renderMobileStickyGradientFooterContent?: () => React.ReactNode;
  renderStickyMobileFooterContent?: () => React.ReactNode;
  renderMobileFooter?: () => React.ReactNode;
  renderDesktopCardBelowContent?: () => React.ReactNode;
  renderMobileHeaderContent?: () => React.ReactNode;
  style?: StyleProp<ViewStyle>;
  innerContainerStyle?: StyleProp<ViewStyle>;
  closeButtonAction?: () => void;
}

type OfflinePageLayoutStyles = {
  containerStyle?: StyleProp<ViewStyle>;
  scrollContainerStyle?: StyleProp<ViewStyle>;
  scrollInnerContainerStyle?: StyleProp<ViewStyle>;
  cardMobileContentStyle?: StyleProp<ViewStyle>;
  cardDesktopContentStyle?: StyleProp<ViewStyle>;
  cardLeftContentStyle?: StyleProp<ViewStyle>;
  cardRightContentStyle?: StyleProp<ViewStyle>;
  PageBackgroundGreenStyle?: StyleProp<ViewStyle>;
  bottomStickyGradientBloc?: StyleProp<ViewStyle>;
  bottomStickyBloc?: StyleProp<ViewStyle>;
  logoDisplayStyle?: StyleProp<ViewStyle>;
  offlinePageHeaderStyle?: StyleProp<ViewStyle>;
  webCloseButtonStyle?: StyleProp<ViewStyle>;
};

type StylesContext = {
  theme: ThemeType;
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  windowHeight: number;
};

export const OfflinePageLayout: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  style,
  innerContainerStyle,
  onLogoPress,
  renderDesktopCardLeftContent,
  renderMobileStickyGradientFooterContent,
  renderStickyMobileFooterContent,
  renderDesktopCardBelowContent,
  renderMobileHeaderContent,
  renderMobileFooter,
  displayLogo = true,
  displayMobileBackgroundImage = false,
  closeButtonAction
}) => {
  const theme = useTheme();
  const { isMobile, isTablet, isDesktop, windowHeight } = useScreenSizes();
  const styles = useStyles({ theme, isMobile, isTablet, isDesktop, windowHeight }, {});

  const renderLogo = () => {
    return (
      displayLogo && (
        <LogoBnp
          colorMode={LogoColorModeEnum.dark}
          containerStyle={styles.logoDisplayStyle}
          onLogoPress={onLogoPress}
          orientation={LogoOrientationEnum.row}
        />
      )
    );
  };
  const displayImage = isDesktop || displayMobileBackgroundImage;
  const renderCloseButton = () => {
    return (
      <TouchableOpacity onPress={closeButtonAction}>
        <FontIcon
          color={theme.colors.text.primary.c500}
          name={'fermer'}
          size={theme.metrics.iconSizes.xxm}
        />
      </TouchableOpacity>
    );
  };
  return (
    <PageBackgroundGreen
      displayimage={displayImage}
      style={styles.PageBackgroundGreenStyle}
    >
      <SafeAreaView style={[styles.containerStyle, style]}>
        <ScrollView
          contentContainerStyle={styles.scrollInnerContainerStyle}
          style={styles.scrollContainerStyle}
        >
          {(isMobile || isTablet) && renderMobileHeaderContent?.()}
          {!(isMobile || isTablet) ? (
            <>
              <WebCard roundedBorder>
                <View style={styles.cardDesktopContentStyle}>
                  <View style={styles.webCloseButtonStyle}>
                    {!!closeButtonAction && renderCloseButton()}
                  </View>
                  {renderDesktopCardLeftContent ? (
                    <View style={styles.cardLeftContentStyle}>
                      {renderLogo()}
                      {renderDesktopCardLeftContent()}
                    </View>
                  ) : (
                    renderLogo()
                  )}
                  <View style={[styles.cardRightContentStyle, innerContainerStyle]}>
                    {children}
                  </View>
                </View>
              </WebCard>
              {renderDesktopCardBelowContent?.()}
            </>
          ) : (
            <>
              <View style={[styles.cardMobileContentStyle, innerContainerStyle]}>
                <View style={styles.offlinePageHeaderStyle}>
                  {renderLogo()}
                  <View />
                  {!!closeButtonAction && renderCloseButton()}
                </View>
                {children}
              </View>
              {renderMobileFooter && renderMobileFooter()}
            </>
          )}
        </ScrollView>
        {renderMobileStickyGradientFooterContent && (isMobile || isTablet) && (
          <BackgroundGradientTransition
            color={displayImage ? theme.colors.background.greenBackground.c300 : undefined}
            containerStyle={styles.bottomStickyGradientBloc}>
            {renderMobileStickyGradientFooterContent()}
          </BackgroundGradientTransition>
        )}
        {renderStickyMobileFooterContent && (isMobile || isTablet) && (
          <View style={styles.bottomStickyBloc}>{renderStickyMobileFooterContent()}</View>
        )}
      </SafeAreaView>
    </PageBackgroundGreen>
  );
};

const getStyles = (
  context?: StylesContext,
  style?: OfflinePageLayoutStyles
): OfflinePageLayoutStyles => ({
  containerStyle: [{ maxHeight: context?.windowHeight, flex: 1 }, style?.containerStyle],
  scrollContainerStyle: [{ maxHeight: context?.windowHeight, flex: 1 }, style?.scrollContainerStyle],
  scrollInnerContainerStyle: [{ flexGrow: 1 }, style?.scrollInnerContainerStyle],
  offlinePageHeaderStyle: [
    {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: context?.theme.metrics.spacing.m
    },
    style?.offlinePageHeaderStyle
  ],
  webCloseButtonStyle: [
    {
      zIndex: 2,
      position: 'absolute',
      marginRight: context?.theme.metrics.spacing.m,
      marginTop: context?.theme.metrics.spacing.xl,
      right: 0
    },
    style?.offlinePageHeaderStyle
  ],
  logoDisplayStyle: [
    {
      position: 'absolute',
      paddingLeft: !context?.isDesktop ? context?.theme.metrics.spacing.s : undefined
    },
    context?.isMobile && {
      marginTop: context?.theme.metrics.spacing.xs
    },
    context?.isTablet && {
      marginTop: context?.theme.metrics.spacing.l,
      marginLeft: context?.theme.metrics.spacing.xm
    },
    !(context?.isMobile || context?.isTablet) && {
      marginTop: context?.theme.metrics.spacing.xxl,
      marginLeft: context?.theme.metrics.spacing.l
    }
  ],
  cardDesktopContentStyle: [
    {
      width: '100%',
      flex: 1,
      flexDirection: 'row'
    },
    style?.cardDesktopContentStyle
  ],
  cardMobileContentStyle: [
    {
      flex: 1,
      flexDirection: 'column',
      width: '100%',
      padding: context?.theme.metrics.spacing.s,
      paddingBottom: context?.theme.metrics.spacing.xxl
    },
    style?.cardMobileContentStyle
  ],
  cardLeftContentStyle: [
    {
      flexDirection: 'column',
      maxWidth: 315,
      minWidth: context?.theme.metrics.spacing.xxxhuge
    },
    style?.cardLeftContentStyle
  ],
  cardRightContentStyle: [
    {
      flex: 1,
      flexDirection: 'column'
    },
    style?.cardRightContentStyle
  ],
  PageBackgroundGreenStyle: [
    {
      flex: 1
    },
    !(context?.isMobile || context?.isTablet) && {
      paddingVertical: context?.theme.metrics.spacing.xxm,
      justifyContent: 'center'
    }
  ],

  bottomStickyGradientBloc: [
    {
      marginTop: -(context?.theme.metrics.spacing.xxl || 0)
    }
  ],
  bottomStickyBloc: [
    {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0
    }
  ]
});

const useStyles = createUseStyles(getStyles);
