import { connect } from 'react-redux';

import { signinRedirectCallbackRequest } from '@modules/auth/actions/authActions';

import { OidcCallbackRedirectPageComponent } from './OidcCallbackRedirectPage.component';

const mapDispatchToProps = {
  signinRedirectCallback: signinRedirectCallbackRequest,
};

export const OidcCallbackRedirectPage = connect(
  null,
  mapDispatchToProps,
)(OidcCallbackRedirectPageComponent);
