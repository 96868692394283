import React, { useCallback, useState } from 'react';

import { TextIdentifierDictionaryType, TextIdentifierForm } from '@components/index';

import { SelfcareIdentifierFormTemplate } from '../SelfcareIdentifierForm';
import { useStyles } from './useStyles';

interface Props {
  onPressBackButton?: () => void;
  onClose: () => void;
  onPressSubmit: (identifier: string, birthDate: Date) => void;
  dictionary: TextIdentifierDictionaryType;
  errorMessage?: string;
  isLoading?: boolean;
  identifierRegex?: string;
}

export function SelfcareTextIdentifierFormTemplate({
  onPressBackButton,
  onClose,
  onPressSubmit,
  dictionary,
  errorMessage,
  isLoading,
  identifierRegex
}: Props) {
  const [identifier, setIdentifier] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState<Date>();
  const [dateOfBirthVisible, setDateOfBirthVisible] = useState(false);
  const styles = useStyles();
  const submitButtonActive = !!identifier && !!dateOfBirth;

  const handleChangeIdentifierValue = useCallback(
    value => {
      if (!identifierRegex) {
        return;
      }

      const usedRegex = new RegExp(identifierRegex);
      const isValid = usedRegex.test(value);

      setDateOfBirthVisible(isValid);
      setIdentifier(value);
    },
    [identifierRegex]
  );

  const handleCleanField = useCallback(() => {
    setIdentifier('');
    setDateOfBirthVisible(false);
    setDateOfBirth(undefined);
  }, []);

  const handleSubmit = useCallback(() => {
    if (!dateOfBirth) {
      return;
    }

    onPressSubmit(identifier, dateOfBirth);
  }, [onPressSubmit, identifier, dateOfBirth]);

  const renderContent = useCallback(() => {
    return (
      <TextIdentifierForm
        containerStyle={styles.textIdentifierContainerStyle}
        dateOfBirth={dateOfBirth}
        dateOfBirthVisible={dateOfBirthVisible}
        dictionary={dictionary}
        errorMessage={errorMessage}
        identifier={identifier}
        isLoading={isLoading}
        onChangeDateOfBirth={setDateOfBirth}
        onChangeIdentifierValue={handleChangeIdentifierValue}
        onCleanField={handleCleanField}
        onPressSubmit={handleSubmit}
        regexPattern={/^[0-9]+$/}
      />
    );
  }, [
    dateOfBirth,
    dateOfBirthVisible,
    identifier,
    dictionary,
    errorMessage,
    isLoading,
    handleSubmit,
    handleCleanField,
    handleChangeIdentifierValue
  ]);

  return (
    <SelfcareIdentifierFormTemplate
      dictionary={dictionary}
      displayCloseButton={false}
      errorMessage={errorMessage}
      isLoading={isLoading}
      moveUpContentWithKeyboard
      onClose={onClose}
      onPressBackButton={onPressBackButton}
      onPressSubmit={handleSubmit}
      renderContent={renderContent}
      submitButtonActive={submitButtonActive}
    />
  );
}
