import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import React from 'react';
import { useDispatch } from 'react-redux';

import { RouteNames } from '@constants/navigation';
import { useScreenSizes } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';
import { ArbitrationActionsType } from '@modules/arbitration/actions/arbitrationActionsTypes';
import { DocumentsActionsType } from '@modules/document-space/actions/documentsActionsTypes';
import { InstallmentActionsType } from '@modules/installment/actions/installmentActionsTypes';
import { CommonRefundActionsType } from '@modules/refund/types';

type ClearActionParam = {
  ClearAction: {
    clearAction: CommonRefundActionsType.CLEAR_REFUND_DATA |
    InstallmentActionsType.CLEAR_INSTALLMENT_DATA |
    ArbitrationActionsType.CLEAR_ARBITRATION_DATA
  }
}

export const useModalData = () => {
  const { formatMessage } = useTranslation();
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const route = useRoute<RouteProp<ClearActionParam, 'ClearAction'>>();
  const { isMobile } = useScreenSizes();
  const clearAction = route?.params?.clearAction;

  const onModalClose = () => {
    if (clearAction) {
      dispatch({ type: clearAction });
    }
    if (isMobile) {
      navigation.navigate(RouteNames.BottomTabNavigator, {
        screen: RouteNames.MobileMenuModal,
      })
    } else {
      navigation.navigate(RouteNames.BottomTabNavigator, {
        screen: RouteNames.Home,
      })
    }
  };

  return {
    title: formatMessage({ id: 'ModaleArretParcoursTitle' }),
    label: formatMessage({ id: 'ModaleArretParcoursLabel' }),
    confirmButtonLabel: formatMessage({ id: 'ModaleArretParcoursBoutonAbandon' }),
    cancelButtonLabel: formatMessage({ id: 'ModaleArretParcoursBoutonAnnuler' }),
    onModalClose,
  };
};
