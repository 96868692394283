import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { View } from 'react-native';

import {
  ColumnsContainer,
  DashboardPageWrapperConnected,
  NavigationHeader,
  NotificationSoft,
  Paragraph,
  SimpleButton,
  SVGLocalLoader,
  Title,
  useScreenSizes,
  useTheme,
  useTranslation
} from '@components/index';
import { createUseStyles } from '@ere-uilib/styles';

import { RouteNames } from '@constants/navigation';

import { BudgetInsightConsentementState } from './interfaces';
import { getStyles } from './styles';

export const BudgetInsightConsentementComponent: React.FC<React.PropsWithChildren<BudgetInsightConsentementState>> = ({
  onGetBudgetInsightUrl,
  isApiLoading,
  error
}) => {
  const { formatMessage, getMessageRaw } = useTranslation();
  const { isMobile, isTablet } = useScreenSizes();
  const theme = useTheme();
  const styles = useStyles({ theme, isMobile }, {});
  const navigation = useNavigation();

  const renderHeader = () => {
    return (
      <NavigationHeader
        displayCloseButton
        helpPoint={true}
        helpPointContentHtml={getMessageRaw({ id: 'AgregationCardConsentementInfoDescription' })}
        helpPointModalTitle={formatMessage({ id: 'AgregationCardConsentementInfoTitre' })}
        helpPointPosition="left"
        onClose={() => {
          navigation.navigate(RouteNames.BottomTabNavigator, {
            screen: RouteNames.Home
          });
        }}
        title={formatMessage({ id: 'AgregationCardConsentementTitre' })}
      />
    );
  };

  const renderSubmitButton = () => {
    return (
      <>
        <SimpleButton
          loading={isApiLoading}
          onPress={() => {
            onGetBudgetInsightUrl();
          }}
          title={formatMessage({ id: 'AgregationCardConsentementCestPartiBouton' })}
        />
        {error && error?.innerMessage !== '' && (
          <NotificationSoft
            text={error?.innerMessage}
            type="warning"
          />
        )}
      </>
    );
  };

  return (
    <DashboardPageWrapperConnected
      cardContentStyle={styles.containerStyle}
      renderStickyMobileBottom={renderSubmitButton}>
      <ColumnsContainer
        containerStyle={styles.containerStyle}
        hasColumnCenter
        renderHeader={renderHeader}>
        <View style={styles.innerCardStyle}>
          <View style={styles.imageCardStyle}>
            <SVGLocalLoader name="budgetinsightRedirect" />
          </View>
          <Title
            style={styles.titleStyle}
            variant="t4"
            weight="bold">
            {formatMessage({ id: 'AgregationCardConsentementDescription' })}
          </Title>
          <Paragraph
            style={styles.contentStyle}
            variant="t3"
            weight="light">
            {formatMessage({ id: 'AgregationCardConsentementContenu' })}
          </Paragraph>
          {!(isMobile || isTablet) && renderSubmitButton()}
        </View>
      </ColumnsContainer>
    </DashboardPageWrapperConnected>
  );
};

const useStyles = createUseStyles(getStyles);
