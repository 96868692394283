import { useIsFocused } from '@react-navigation/native';
import React, { FC, useCallback, useEffect } from 'react';

import { LandingPageWithCards } from '@components/index';
import { ArbitrationTypes } from '@constants/arbitrations';
import { RouteNames } from '@constants/navigation';
import { ArbitrationActionsType } from '@modules/arbitration/actions/arbitrationActionsTypes';

import { useDataLabels } from './hooks/useDataLabels';
import { ArbitrationChoicePageProps } from './interfaces';

export const ArbitrationChoicePageComponent: FC<React.PropsWithChildren<ArbitrationChoicePageProps>> = ({
  navigation,
  getArbitrationQuestions,
  isFetching,
  arbitrationError,
  eligibleDispositifs,
  selectArbitrationType
}) => {
  const isFocused = useIsFocused();
  const data = useDataLabels(eligibleDispositifs);

  useEffect(() => {
    if (isFocused) getArbitrationQuestions();
  }, [isFocused, getArbitrationQuestions]);

  const hanldleSelectCard = useCallback(
    (arbitrationType: ArbitrationTypes) => {
      selectArbitrationType(arbitrationType);
      navigation.navigate(RouteNames.ArbitrationStack, {
        screen: RouteNames.ArbitrationOnboarding
      });
    },
    [selectArbitrationType, navigation]
  );

  const handleCloseModal = useCallback(() => {
    navigation.navigate(RouteNames.ArbitrationStack, {
      screen: RouteNames.ArbitrationModal,
      params: {
        clearAction: ArbitrationActionsType.CLEAR_ARBITRATION_DATA
      }
    });
  }, [navigation]);

  const handlePressRetry = useCallback(() => {
    getArbitrationQuestions();
  }, [getArbitrationQuestions]);

  return (
    <LandingPageWithCards
      data={data}
      error={arbitrationError}
      isLoading={isFetching}
      onClose={handleCloseModal}
      onRetry={handlePressRetry}
      onSelectCard={hanldleSelectCard}
    />
  )
};
