import { createNumberMask } from 'react-native-mask-input';

export const RENTE_REVERSION_RATES_DEFAULT: number[] = [20, 40, 60, 80, 100];
export const RENTE_GUARANTEED_ANNUITIES_DEFAULT: number[] = Array.from({ length: 25 }).map((value, index) => index + 1);
export const RENTE_MAX_BIRTH_DATE_DEFAULT = 100;
export const RENTE_MIN_BIRTH_DATE_DEFAULT = 18;
export const RENTE_MIN_AGE_DEFAULT = 55;
export const RENTE_MAX_AGE_DEFAULT = 75;
export const RENTE_MIN_CAPITAL_DEPART_DEFAULT = 100;
export const RENTE_MAX_CAPITAL_DEPART_DEFAULT = 10000000;
export const RENTE_MIN_RENTE_DEPART_DEFAULT = 100;
export const RENTE_MAX_RENTE_DEPART_DEFAULT = 20000;

export const AMOUNT_MASK = createNumberMask({
  delimiter: ' ',
  precision: 0
})