import { useMemo } from "react";
import { Platform } from "react-native";

import { DirectionEnum } from "@ere-uilib/molecules/steppers/InstallmentsStepper/interfaces";
import { useScreenSizes } from "@ere-uilib/styles";
import { useTranslation } from "@ere-uilib/translations";
import { PieChartData } from "@ere-uilib/types";
import { constructStepperItems } from "@pages/Common/utils";

import { ActiveClassCardContentData } from "../interfaces";

interface UseConfig {
  activeClassCardContentData: ActiveClassCardContentData;
  simulatedRepartitionData: PieChartData[];
  manualRepartitionData: PieChartData[];
};

export const useConfig = ({
  activeClassCardContentData,
  simulatedRepartitionData,
  manualRepartitionData,
}: UseConfig) => {
  const { isDesktop } = useScreenSizes();

  const {
    getMessageRaw,
    formatMessage,
  } = useTranslation();

  const currentRepartionData = useMemo(() => {
    const newCurrentRepartionData = Object.keys(activeClassCardContentData).map(activeClassUuid => {
      const {
        color,
        title,
        value,
        key,
      } = activeClassCardContentData[activeClassUuid];

      const activeClass = {
        value,
        key,
        color,
        title,
        uuid: activeClassUuid,
      };

      return activeClass;
    });

    return newCurrentRepartionData;
  }, [activeClassCardContentData]);

  const chartData = useMemo(
    () => [
      {
        title: formatMessage({ id: 'AbritrageOADResultatQRRepartitionActuelleDescription' }),
        repartitionData: currentRepartionData,
        hasPlaceholder: false,
      },
      {
        title: formatMessage({ id: 'AbritrageOADResultatQRMaSimulationDescription' }),
        repartitionData: simulatedRepartitionData
      },
      {
        title: formatMessage({ id: 'AbritrageOADPageSyntheseChoixNouvelleRepartition' }),
        repartitionData: manualRepartitionData
      }
    ],
    [
      formatMessage,
      currentRepartionData,
      simulatedRepartitionData,
      manualRepartitionData,
    ],
  );

  const stepperItems = constructStepperItems({
    size: 3,
    direction: DirectionEnum.ROW,
    activeStep: 2
  });

  const dictionary = useMemo(() => ({
    headerHelpPointContentHtml: getMessageRaw({
      id: 'AbritrageOADPageSyntheseRegroupementAideDescription'
    }),
    headerHelpPointModalTitle: formatMessage({
      id: 'AbritrageOADPageSyntheseRegroupementAideTitre'
    }),

    headerTitle: formatMessage({ id: 'AbritrageOADPageSyntheseRegroupementEtapesTitre' }),
    mainContentDescription: formatMessage({
      id: 'AbritrageOADPageSyntheseRegroupementAllocationDescription'
    }),
    bottomButtonActionsConfirm: formatMessage({
      id: 'AbritrageOADPageSyntheseRegroupementContinuerBouton'
    }),
    subStikyHeaderBandeauTitle: formatMessage({
      id: 'AbritrageOADPageSyntheseRegroupementBandeauRepartirLabel'
    }),
    subStikyHeaderBandeauDescription: formatMessage({
      id: 'AbritrageOADPageSyntheseRegroupementBandeauRepartirDescription'
    }),
    modalFieldPlaceholder: formatMessage({ id: 'AbritrageOADSaisiepourcentageZonesaisie' }),
  }), [
    formatMessage,
    getMessageRaw
  ]);

  const pieChartConfig = useMemo(() => {
    if (Platform.OS !== 'web') {
      return {
        height: 150,
        width: 150,
        labelRadius: 45,
        fontSize: 10
      };
    }

    if (isDesktop) {
      return {
        labelRadius: 120,
        fontSize: 30
      };
    }

    return {
      labelRadius: 140,
      fontSize: 30
    };
  }, [isDesktop]);

  return {
    chartData,
    pieChartConfig,
    dictionary,
    stepperItems,
  };
};
