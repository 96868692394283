import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useScreenSizes, useTheme } from '@ere-uilib/styles';

export function useStyles(isChecked: boolean) {
  const theme = useTheme();
  const { isDesktop, isMobile } = useScreenSizes();

  const styles = useMemo(() => StyleSheet.create({
    containerStyle: {
      backgroundColor: theme.colors.basics.white,
      marginBottom: isDesktop ? theme.metrics.spacing.xl : 0,
      maxWidth: isDesktop ? "60%" : "100%",
      marginHorizontal: "auto"
    },
    containerMailStyle: {
      backgroundColor: theme.colors.basics.white,
      marginBottom: isDesktop ? theme.metrics.spacing.xl : 0,
      width: isDesktop ? "60%" : "100%",
      marginHorizontal: "auto"
    },
    contentContainerStyle: {

    },
    descriptionStyle: {
      marginTop: theme?.metrics.spacing.s,

    },
    helpCardStyle: {
      marginVertical: theme?.metrics.spacing.m,

      alignItems: 'baseline'
    },
    helpDescriptionStyle: {
      borderLeftWidth: 2,
      marginTop: theme?.metrics.spacing.m,
      marginRight: theme?.metrics.spacing.m,
      paddingLeft: theme?.metrics.spacing.m,
    },
    helpIconStyle: {
      marginTop: theme?.metrics.spacing.m,
      marginRight: theme?.metrics.spacing.m,
    },
    selectContainerStyle: {
      marginTop: theme?.metrics.spacing.xm,
      width: "100%",
      zIndex: 10
    },
    bottomActionsStyle: {
      paddingHorizontal: isMobile ? theme?.metrics.spacing.s : 0,

      flexDirection: !isMobile ? 'row' : 'column',
      width: "100%",
      marginTop: isDesktop ? theme.metrics.spacing.xl : 0,
    },
    bottomActionsButtonsContainerStyle:
    {
      width: '100%',
      flexDirection: 'row',
    },
    buttonStyle:
    {
      width: "48%",
    },
    contentStyle:
    {
      flex: 1,
      height: '100%',
      paddingTop: theme?.metrics.spacing.l,
      paddingBottom: theme?.metrics.spacing.l,
      paddingLeft: theme?.metrics.spacing.s
    },
    firstLinesStyle: { textAlign: 'left', marginBottom: theme?.metrics.spacing.m },
    informLinkStyle: { textAlign: 'left', marginBottom: theme?.metrics.spacing.m, color: theme.colors.notifications.warning.c500 },
    underFieldLinkStyle: {
      textAlign: 'left',
    },
    lastLinesStyle: { textAlign: 'left' },
    editButtonStyle: {
      width: theme?.metrics.spacing.xxxl,
      alignSelf: 'stretch',
      paddingTop: theme?.metrics.spacing.l,
      alignItems: 'center'
    },
    selectedItemStyle:
    {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      borderRadius: theme.metrics.spacing.xs,
      height: theme.metrics.spacing.l,
      padding: theme.metrics.spacing.l,
      backgroundColor: isChecked ? theme.colors.basics.grey.c200 : theme.colors.basics.white,
      borderWidth: 1,
      borderColor: theme.colors.basics.grey.c300,
    },
    simpleButtonStyle: {
      marginLeft: theme.metrics.spacing.m,
      flex: isDesktop ? undefined : 1,
    },
    backButtonStyle: {
      flex: isDesktop ? undefined : 1,
    },
    selectInnerStyle: {
      backgroundColor: isChecked ? theme.colors.basics.grey.c200 : theme.colors.basics.white,
    },
    selectTextStyle: {
      color: isChecked ? theme.colors.basics.grey.c400 : theme.colors.basics.black,
    },
    titleStyle:
    {
      color: isChecked ? theme.colors.basics.grey.c400 : theme.colors.basics.black,
      fontFamily: theme.fonts.fontFamily.light,
      marginRight: theme.metrics.spacing.xs,
    },
    errorTitleStyle: {
      textAlign: 'center',
      marginTop: theme.metrics.spacing.s,
    },

  }), [theme, isDesktop, isMobile, isChecked]);

  return styles;
}