import React, { PropsWithChildren } from 'react';
import { View, StyleProp, ViewStyle, TextStyle, TouchableOpacity, Platform } from 'react-native';
import { FontIcon, Text } from '@components/index';
import { createUseStyles, useTheme, ThemeType, useScreenSizes } from '@ere-uilib/styles';

interface ProfileInformationsEditCardProps {
  informationTitle: string;
  isEditable?: boolean;
  onEditPress?: () => void;
  containerBackground?: string;
  valueTestId?: string;
  editTestId?: string;
}

interface ProfileInformationsEditCardStyles {
  containerStyle?: StyleProp<ViewStyle>
  headerContainerStyle?: StyleProp<ViewStyle>
  firstLinesStyle?: StyleProp<TextStyle>
  separator?: StyleProp<ViewStyle>
  lastLinesStyle?: StyleProp<TextStyle>
}

type StyleContext = {
  theme: ThemeType;
  containerBackground: string;
  isDesktop:boolean;
}

type Props = ProfileInformationsEditCardProps & ProfileInformationsEditCardStyles;

export const ProfileInformationsEditCard: React.FC<PropsWithChildren<Props>> = ({
  informationTitle,
  isEditable = true,
  containerBackground,
  onEditPress,
  containerStyle,
  firstLinesStyle,
  lastLinesStyle,
  editTestId,
  children
}) => {
  const theme = useTheme();
  const { isDesktop } = useScreenSizes();
  const styles = useStyle(
    {
      theme,isDesktop,
      containerBackground: containerBackground || theme.colors.basics.white
    },
    { containerStyle, firstLinesStyle, lastLinesStyle }
  );

  return (
    <View style={styles.containerStyle}>
      <View style={styles.headerContainerStyle}>
        <Text
          style={styles.firstLinesStyle}
          variant="t2"
          weight="bold"
        >
          {informationTitle}
        </Text>
        {isEditable &&
          <TouchableOpacity
            accessibilityLabel={editTestId}
            onPress={() => onEditPress?.()}
            testID={editTestId}
          >
            <FontIcon
              color={theme?.colors.basics.primary.c500}
              name="editer"
              size={theme?.metrics.iconSizes.mplus}
            />
          </TouchableOpacity>
        }
      </View>
      <View style={styles.separator} />
      {children}
    </View>
  );
};

const getStyles = (
  context?: StyleContext,
  style?: ProfileInformationsEditCardStyles
): ProfileInformationsEditCardStyles => ({
  containerStyle: [
    {
      width: "100%",
      maxWidth: context?.theme.metrics.contentSizes.centeredContentMaxWidth,
      padding: context?.theme?.metrics.spacing.m,
      backgroundColor: context?.theme.colors.basics.white,
      flexDirection: 'column',
      borderRadius: context?.theme?.metrics.spacing.xs,
      shadowColor:
        Platform.OS === 'android'
          ? context?.theme.colors.basics.black
          : context?.theme.colors.notifications.shadow,
      shadowOffset: {
        width: 5,
        height: 5,
      },
      shadowOpacity: 1,
      shadowRadius: 15,
      elevation: 7,
    },
    style?.containerStyle
  ],
  headerContainerStyle: [{
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  }],
  separator: {
      borderBottomWidth: 1,
      width: "100%",
      borderColor: context?.theme.colors.basics.grey.c200,
      alignSelf: 'center',
      marginVertical: context?.theme?.metrics.spacing.m
    },
  firstLinesStyle: [{
      textAlign: 'left',
      flex: 1
    },
    style?.firstLinesStyle
  ]
});

const useStyle = createUseStyles(getStyles);
