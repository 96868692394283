import { useNavigation } from '@react-navigation/native';
import React, { useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';

import {
  ColumnsContainer,
  DashboardPageWrapperConnected,
  HtmlStyledRenderConnectedContainer,
  NavigationHeader,
  SimpleButton,
  TableLoader,
  Title,
  useScreenSizes,
} from '@components/index';
import { RouteNames } from '@constants/navigation';
import { RetirementActionsType } from '@modules/retirement/actions/retirementActionsTypes';

import { TabForm } from '../components';
import { useController } from '../components';
import { FormValues } from '../components/TabForm/interfaces';
import { RetirementPageProps } from './interfaces';
import { useStyles } from './styles';

export const RetirementPage: React.FC<React.PropsWithChildren<RetirementPageProps>> = ({
  onSubmit,
  totalAmount,
  tabFormValues,
  isLoading,
  isRetirementLoading
}) => {
  const [formValues, setFormValues] = useState<FormValues>(tabFormValues);
  const [isDisabledSubmitButton, setIsDisabledSubmitButton] = useState<boolean>(true);
  const { getNewCompartments, retirementFormDictionnary } = useController();
  const styles = useStyles();
  const { isDesktop } = useScreenSizes()
  const navigation = useNavigation();

  const ManageDisablingSubmitButton = useCallback(() => {
    Object.values(formValues).forEach(formItem => {
      Object.values(formItem.content).forEach(formItemValue => {
        if (formItemValue.capitalEligible || formItemValue.annuityEligible) {
          return setIsDisabledSubmitButton(false);
        }
      });
    });
  }, [setIsDisabledSubmitButton, formValues])

  useEffect(() => {
    setIsDisabledSubmitButton(true)
    ManageDisablingSubmitButton()
  }, [ManageDisablingSubmitButton, formValues]);

  const handleGoBackPress = useCallback(() => {
    return navigation.navigate(RouteNames.RetirementStack, {
      screen: RouteNames.RetirementModal,
      params: {
        clearAction: RetirementActionsType.CLEAR_RETIREMENT_DATA,
      },
    });
  }, [navigation]);

  const renderHeader = useCallback(
    () => (
      <NavigationHeader
        displayCloseButton
        helpPoint
        helpPointContentHtml={retirementFormDictionnary.infoContent}
        helpPointModalTitle={retirementFormDictionnary.infoTitle}
        onClose={handleGoBackPress}
        title={retirementFormDictionnary.savingPageTitle}
      />
    ),
    [handleGoBackPress, retirementFormDictionnary]
  );


  const handleSetFormValues = useCallback(
    (newFormValues: FormValues) => {
      setFormValues(newFormValues)
    }, []
  )

  const renderTabForm = useCallback(() => {
    if (isRetirementLoading) return <TableLoader />
    return (<View style={styles.tabFormWrapper}>
      <TabForm
        getFormValues={handleSetFormValues}
        initialFormValues={tabFormValues}
        totalAmount={totalAmount}
      />
    </View>)
  }, [styles, handleSetFormValues, totalAmount, tabFormValues])

  const renderHtmlContent = useCallback(
    () => (
      <HtmlStyledRenderConnectedContainer
        html={retirementFormDictionnary.hisSavingText2}
      />
    ), [retirementFormDictionnary])

  const renderContent = useCallback(() => (
    <>
      <Title
        style={styles.tabFormTitle}
        variant={isDesktop ? "t4" : "t7"}
        weight="bold"
      >
        {retirementFormDictionnary.hisSavingText1}
      </Title>
      {renderHtmlContent()}
      {renderTabForm()}
    </>)
    , [retirementFormDictionnary, renderTabForm, renderHtmlContent, isDesktop]
  );

  const formatRetirementDecisionFromFormValues = useCallback(() => {
    const newDecision = getNewCompartments(formValues)
    return newDecision
  }, [getNewCompartments, formValues])

  const handleSubmit = useCallback(() => {
    const formattedDecisionData = formatRetirementDecisionFromFormValues()
    if (!formattedDecisionData) return
    onSubmit(formattedDecisionData);

  }, [formatRetirementDecisionFromFormValues, onSubmit]);

  return (
    <DashboardPageWrapperConnected cardContentStyle={styles.container}>
      <ColumnsContainer
        hasRenderRightDesktopColumn={false}
        renderHeader={renderHeader}>
        <View style={styles.contentContainer}>
          {renderContent()}
          <SimpleButton
            containerStyle={styles.buttonContainer}
            disabled={isDisabledSubmitButton}
            loading={isLoading}
            onPress={handleSubmit}
            title={retirementFormDictionnary.continueButton}
          />
        </View>
      </ColumnsContainer>
    </DashboardPageWrapperConnected>
  );
};
