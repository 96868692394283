import { useDispatch, useSelector } from 'react-redux';

import { InvitationActionsType } from '@modules/invitation/actions/invitationActionsTypes';
import { AppState } from '@modules/index';
import {
  createSelfcareInvitationFromExternalIdentityRequest as _createSelfcareInvitationFromExternalIdentityRequest,
  createSelfcareInvitationFromLetterOtpIdentityRequest as _createSelfcareInvitationFromLetterOtpIdentityRequest,
} from '@modules/invitation/actions/invitationActions';
import { getInvitationError } from '@modules/invitation/selectors';
import {
  InvitationIdentificationDataType,
  InvitationLetterOtpIdentificationDataType
} from '@modules/invitation/types';

export function useInvitation() {
  const dispatch = useDispatch();

  const createSelfcareInvitationFromExternalIdentityRequest = ({
    type,
    identifier,
    birthDate,
  }: InvitationIdentificationDataType) => dispatch(
    _createSelfcareInvitationFromExternalIdentityRequest({
      type,
      identifier,
      birthDate,
    })
  );

  const createSelfcareInvitationFromLetterOtpIdentityRequest = ({
    otpCode,
    birthDate,
  }: InvitationLetterOtpIdentificationDataType) => dispatch(
    _createSelfcareInvitationFromLetterOtpIdentityRequest({
      otpCode,
      birthDate,
    })
  );

  const error = useSelector(getInvitationError);
  const isLoading = useSelector((state: AppState) =>
    state.isLoading[
      InvitationActionsType.CREATE_SELFCARE_INVITATION_FROM_EXTERNAL_IDENTITY_REQUEST
    ]
    || state.isLoading[
      InvitationActionsType.CREATE_SELFCARE_INVITATION_FROM_LETTER_OTP_REQUEST
    ]
  );

  return {
    createSelfcareInvitationFromExternalIdentityRequest,
    createSelfcareInvitationFromLetterOtpIdentityRequest,
    errorMessage: error.message,
    isLoading,
  };
}
