import { useScreenSizes, useTheme } from '@ere-uilib/styles';
import { useMemo } from 'react';
import { StyleSheet } from 'react-native';


export const useStyles = () => {
  const theme = useTheme();
  const { isDesktop, isMobile, isTablet } = useScreenSizes();
  const styles = useMemo(() => StyleSheet.create({
    cardContainer: {
      borderBottomWidth: 1,
      borderBottomColor: theme.colors.borders.fields.c200,
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingVertical: theme.metrics.spacing.xm,
      paddingHorizontal: theme.metrics.spacing.s
    },
    leftCard: {
      maxWidth: "80%"
    },
    headerText: {
      color: theme.colors.basics.grey.c500
    },
    rightCard: {
      ...isDesktop && {
        flexDirection: "row-reverse",
        alignItems: 'center'
      }
    },
    tagsContainer: {
      flexDirection: 'column',
    },
    tagsTextContainer: {
      paddingLeft: theme.metrics.spacing.xs,
      flexDirection: 'row',
      marginLeft: theme.metrics.spacing.s,
      marginTop: theme.metrics.spacing.xs,
    },
    tagTextStyle: {
      paddingLeft: theme.metrics.spacing.xs,
    },
    iconPdf: {
      textAlign: 'center'
    },
    tagIconStyle: {
      maxWidth: "100%"
    },
    sizeFileStyle: {
      ...isMobile ? { marginLeft: theme.metrics.spacing.s }
        : { paddingRight: theme.metrics.spacing.xs }
    },
    rowIconContainer: {
      width: 195,
      height: 30,
      maxWidth: 200,
      maxHeight: 30,
      position: "absolute"
    },
    spinnerView: {
      height: 50,
      width: 50,
      justifyContent: 'center',
    }
  }), []);

  return styles;
}