export enum PlanTypeEnum {
  PERU = 'PERU',

  PERECO = 'PERECO',
  PERECOG = 'PERECOG',
  PERECOI = 'PERECOI',

  PERO = 'PERO',
  PEROG = 'PEROG',
  PEROI = 'PEROI',
  PERE = 'PERE',
  A83 = 'A83',

  PERCO = 'PERCO',
  PERCOG = 'PERCOG',
  PERCOI = 'PERCOI',

  PEE = 'PEE',
  PEG = 'PEG',
  PEI = 'PEI',

  PEE2 = 'PEE2',

  BPSS = 'BPSS',

  HES = 'HES',

  PPESV = 'PPESV',

  A82 = 'A82',

  A39 = 'A39',
  PELT = 'PELT',
  PPESVI = 'PPESVI',
}
