import React from 'react';
import { View } from 'react-native';

import { InputLabel } from '@ere-uilib/molecules';

import { IdentifierFormProps } from './types';
import { useStyles } from './useStyles';
import { DateField } from '@ere-uilib/molecules/inputs/DateField';

export function IdentifierForm({
  renderIdentifierInput,
  onChangeDateOfBirth,
  dateOfBirth,
  dateOfBirthVisible = false,
  dictionary,
  containerStyle,
  onPressSubmit,
}: IdentifierFormProps) {
  const styles = useStyles();

  return (
    <View style={[styles.container, containerStyle]}>
      <View>
        <InputLabel
          containerStyle={styles.textInputLabel}
          helpPointContentHtml={dictionary.inputHelpPointContentHtml}
          helpPointModalTitle={dictionary.inputHelpPointModalTitle}
          helpText={dictionary.helpText}
          label={dictionary.helpLabel}
        >
          {renderIdentifierInput()}
        </InputLabel>
      </View>
      {dateOfBirthVisible &&
        <View>
          <InputLabel
            containerStyle={styles.dateOfBirthInputLabelContainer}
            label={dictionary.dateOfBirth}
          >
            <DateField
              value={dateOfBirth}
              onChange={onChangeDateOfBirth}
              maxDate={new Date()}
              dictionary={dictionary}
              onSubmit={onPressSubmit}
            />
          </InputLabel>
        </View>
      }
    </View>
  );
}
