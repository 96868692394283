import { connect } from 'react-redux';

import { AppState } from '@modules/reducers';
import { getRefundDecisionRequest } from '@modules/refund/actions/refundActions';
import { CommonRefundActionsType } from '@modules/refund/types';
import { getApplicationSettings } from '@modules/settings/selectors';

import { RefundOnboardingPageComponent } from './RefundOnboardingPage.component';

const mapStateToProps = (state: AppState) => ({
  isLoading: state.isLoading[CommonRefundActionsType.GET_REFUND_DECISION_REQUEST],
  decision: state.refunds.decision,
  applicationSettings: getApplicationSettings(state),

});
const mapDispatchToProps = {
  getRefundDecisionData: getRefundDecisionRequest,
};
export const RefundOnboardingPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(RefundOnboardingPageComponent);
