import React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

import { FontIcon, Text } from '@ere-uilib/atoms';
import { iconDisplayDetailsByType } from '@ere-uilib/constants/notificationIcons';
import { createUseStyles, ThemeType, useTheme } from '@ere-uilib/styles';

type NotificationTypes = 'info' | 'help' | 'warning' | 'success'

interface FieldNotificationProps {
  type?: NotificationTypes
  text: string
  containerStyle?: StyleProp<ViewStyle>;
  iconContainerStyle?: StyleProp<ViewStyle>;
  textContainerStyle?: StyleProp<ViewStyle>;
}

type Styles = {
  containerStyle?: StyleProp<ViewStyle>;
  iconContainerStyle?: StyleProp<ViewStyle>;
  textContainerStyle?: StyleProp<ViewStyle>;
};

type StylesContext = {theme : ThemeType};

export const FieldNotification: React.FC<React.PropsWithChildren<FieldNotificationProps>> = ({
  type = 'info',
  text,
  containerStyle,
  iconContainerStyle,
  textContainerStyle
}) => {
  const theme = useTheme();

  const currentTypeIconDetail = iconDisplayDetailsByType[type];

  const styles = useStyles({ theme }, { containerStyle, iconContainerStyle, textContainerStyle });

  return (
    <View style={styles.containerStyle}>
      <View style={styles.iconContainerStyle}>
        <FontIcon
          color={currentTypeIconDetail.iconColor}
          name={currentTypeIconDetail.iconName}
          size={theme.metrics.iconSizes.mplus}
        />
      </View>
      <View style={styles.textContainerStyle}>
        <Text
          style={{ color: currentTypeIconDetail.iconColor }}
          variant={'t3'}
          weight={'light'}
        >
          {text}
        </Text>
      </View>
    </View>
  );
};

const getStyles = (
  context?: StylesContext,
  style?: Styles,
): Styles => ({
  containerStyle: [{
    flexDirection: 'row',
    alignItems: 'center'
  }, style?.containerStyle],
  iconContainerStyle: [{
    marginRight: context?.theme.metrics.spacing.s
  }, style?.iconContainerStyle],
  textContainerStyle: [{
    flex: 1
  }, style?.textContainerStyle]
});

const useStyles = createUseStyles(getStyles);
