import { ErrorItemState } from '@modules/error/types';

import {
  DocumentApiFile,
  DocumentCategory,
  DocumentsCategoriesErrorState,
  EDocuments
} from '../types';

export enum DocumentsActionsType {
  GET_DOCUMENTS_CATEGORIES_REQUEST = 'GET_DOCUMENTS_CATEGORIES_REQUEST',
  GET_DOCUMENTS_CATEGORIES_SUCCESS = 'GET_DOCUMENTS_CATEGORIES_SUCCESS',
  GET_DOCUMENTS_CATEGORIES_FAILURE = 'GET_DOCUMENTS_CATEGORIES_FAILURE',
  SET_DOCUMENTS_CATEGORIES_FILTER = 'SET_DOCUMENTS_CATEGORIES_FILTER',
  SET_ALL_DOCUMENTS_CATEGORIES_FILTER = 'SET_ALL_DOCUMENTS_CATEGORIES_FILTER',
  SET_DOCUMENTS_CATEGORIES_RESET = 'SET_DOCUMENTS_CATEGORIES_RESET',
  GET_DOCUMENTS_FILES_REQUEST = 'GET_DOCUMENTS_FILES_REQUEST',
  GET_DOCUMENTS_FILES_SUCCESS = 'GET_DOCUMENTS_FILES_SUCCESS',
  GET_DOCUMENTS_FILES_FAILURE = 'GET_DOCUMENTS_FILES_FAILURE',
  GET_DOCUMENT_BLOB_RESPONSE_REQUEST = 'GET_DOCUMENT_BLOB_RESPONSE_REQUEST',
  GET_DOCUMENT_BLOB_RESPONSE_SUCCESS = 'GET_DOCUMENT_BLOB_RESPONSE_SUCCESS',
  GET_DOCUMENT_BLOB_RESPONSE_FAILURE = 'GET_DOCUMENT_BLOB_RESPONSE_FAILURE',
  GET_EDOCUMENTS_REQUEST = 'GET_EDOCUMENTS_REQUEST',
  GET_EDOCUMENTS_SUCCESS = 'GET_EDOCUMENTS_SUCCESS',
  GET_EDOCUMENTS_FAILURE = 'GET_EDOCUMENTS_FAILURE',
  GET_EDOCUMENTS_SUBSCRIPTION_REQUEST = 'GET_EDOCUMENTS_SUBSCRIPTION_REQUEST',
  GET_EDOCUMENTS_SUBSCRIPTION_SUCCESS = 'GET_EDOCUMENTS_SUBSCRIPTION_SUCCESS',
  GET_EDOCUMENTS_SUBSCRIPTION_FAILURE = 'GET_EDOCUMENTS_SUBSCRIPTION_FAILURE'
}

export interface GetDocumentsCategoriesRequest {
  type: DocumentsActionsType.GET_DOCUMENTS_CATEGORIES_REQUEST;
}

export interface GetDocumentsCategoriesSuccess {
  type: DocumentsActionsType.GET_DOCUMENTS_CATEGORIES_SUCCESS;
  categories: DocumentCategory[];
  files: DocumentApiFile[];
}

export interface GetDocumentsCategoriesFailure {
  type: DocumentsActionsType.GET_DOCUMENTS_CATEGORIES_FAILURE;
  error: DocumentsCategoriesErrorState;
}

export interface SetDocumentsCategoriesFilter {
  type: DocumentsActionsType.SET_DOCUMENTS_CATEGORIES_FILTER;
  categoryId: string;
  isSelected: boolean;
}
export interface SetAllDocumentsCategoriesFilter {
  type: DocumentsActionsType.SET_ALL_DOCUMENTS_CATEGORIES_FILTER;
}

export interface SetDocumentsCategoriesReset {
  type: DocumentsActionsType.SET_DOCUMENTS_CATEGORIES_RESET;
}

export type GetDocumentBlobRequest = {
  type: DocumentsActionsType.GET_DOCUMENT_BLOB_RESPONSE_REQUEST;
  documentTitle: string;
} & (
  | { documentType: string; documentId?: never; }
  | { documentType?: never; documentId: string; }
  )

export interface GetDocumentBlobSuccess {
  type: DocumentsActionsType.GET_DOCUMENT_BLOB_RESPONSE_SUCCESS;
  blobResponse: BlobPart;
}

export interface GetDocumentBlobFailure {
  type: DocumentsActionsType.GET_DOCUMENT_BLOB_RESPONSE_FAILURE;
  error: DocumentsCategoriesErrorState;
}

export interface GetEDocumentsRequest {
  type: DocumentsActionsType.GET_EDOCUMENTS_REQUEST;
}

export interface GetEDocumentsSuccess {
  type: DocumentsActionsType.GET_EDOCUMENTS_SUCCESS;
  eDocuments: EDocuments
}

export interface GetEDocumentsFailure {
  type: DocumentsActionsType.GET_EDOCUMENTS_FAILURE;
  error: ErrorItemState;
}

export interface GetEDocumentsSubscriptionRequest {
  type: DocumentsActionsType.GET_EDOCUMENTS_SUBSCRIPTION_REQUEST;
  isSubscriber: boolean
}

export interface GetEDocumentsSubscriptionSuccess {
  type: DocumentsActionsType.GET_EDOCUMENTS_SUBSCRIPTION_SUCCESS;
  isSubscriber: boolean
}

export interface GetEDocumentsSubscriptionFailure {
  type: DocumentsActionsType.GET_EDOCUMENTS_SUBSCRIPTION_FAILURE;
  error: ErrorItemState;
}

export type DocumentsAction =
  | GetDocumentsCategoriesRequest
  | GetDocumentsCategoriesSuccess
  | GetDocumentsCategoriesFailure
  | SetDocumentsCategoriesFilter
  | SetAllDocumentsCategoriesFilter
  | GetDocumentBlobRequest
  | GetDocumentBlobSuccess
  | GetDocumentBlobFailure
  | SetDocumentsCategoriesReset
  | GetEDocumentsRequest
  | GetEDocumentsSuccess
  | GetEDocumentsFailure
  | GetEDocumentsSubscriptionRequest
  | GetEDocumentsSubscriptionSuccess
  | GetEDocumentsSubscriptionFailure;