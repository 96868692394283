import React, { useCallback, useRef, useState } from 'react';
import { Animated, Platform, TouchableWithoutFeedback, View } from 'react-native';

import { useTranslation } from "@components/ERE-UILib";
import { FontIcon, Text } from '@ere-uilib/atoms';
import { useTheme } from '@ere-uilib/styles';

import { LegalMenuFooterItem, LIST_ITEM_HEIGHT } from '../LegalMenuFooterItem/LegalMenuFooterItem';
import { LegalMenuFooterAccordionProps } from './interface';
import { useStyles } from './styles';

export const LegalMenuFooterAccordion = ({
  links,
  onItemPressed,
  isWhite,
  appVersion
}: LegalMenuFooterAccordionProps) => {
  const theme = useTheme();
  const { formatMessage } = useTranslation();
  const isMobile = !(Platform.OS === "web");
  const defaultHeight = LIST_ITEM_HEIGHT * links.length;
  const contentHeight = isMobile? defaultHeight + LIST_ITEM_HEIGHT  : defaultHeight;
  const animationController = useRef(new Animated.Value(0)).current;
  const [isOpen, setIsOpen] = useState(false);

  const color =
    !isWhite || isOpen ? theme.colors.basics.grey.c400 : theme.colors.basics.white;

  const styles = useStyles({ LIST_ITEM_HEIGHT });

  const titleArrowRotation = animationController.interpolate({
    inputRange: [0, 1],
    outputRange: ['-180deg', '0deg']
  });

  const toggleListItem = useCallback(() => {
    const config = {
      duration: 400,
      toValue: isOpen ? 0 : 1,
      useNativeDriver: false
    };
    Animated.timing(animationController, config).start();
    setIsOpen(!isOpen);
  }, [animationController, setIsOpen, isOpen]);

  const menuHeight = animationController.interpolate({
    inputRange: [0, 1],
    outputRange: [0, contentHeight]
  });

  const backgroundColor = animationController.interpolate({
    inputRange: [0, 1],
    outputRange: [theme.colors.basics.blackTransparent.c000,theme.colors.basics.white]
  });

  return (
    <View style={styles.containerStyle}>
      <Animated.View
        style={[
          styles.container,
          isOpen ? styles.containerOpen : undefined,
          { backgroundColor: backgroundColor }
        ]}>
        <TouchableWithoutFeedback onPress={() => toggleListItem()}>
          <View>
            <View style={styles.titleContainer}>
              <Animated.View
                style={[styles.chevronContainer, { transform: [{ rotateZ: titleArrowRotation }] }]}>
                <FontIcon
                  color={color}
                  name="chevron-bas"
                  size={theme.metrics.iconSizes.xm}
                />
              </Animated.View>
              <Text
                style={{ color }}
                variant="t4">
                {formatMessage({ id: 'TermsetFrontProfileMenuMentionsLegales' })}
              </Text>
            </View>
          </View>
        </TouchableWithoutFeedback>

        <Animated.View style={[styles.accordionContent, { height: menuHeight }]}>
          {links.map((item, index) => (
            <LegalMenuFooterItem
              iconName={item.iconName}
              key={index}
              label={item.label}
              onPress={() => onItemPressed(item)}
            />
          ))}

          {isMobile && (
            <View style={styles.versionContainer}>
              <Text
                style={{ color }}
                variant="t4">
                {`${formatMessage({ id: 'ApplicationDebugVersionApp' })} : ${appVersion?.versionNumber} (${appVersion?.buildNumber})`}
              </Text>
            </View>
          )}
        </Animated.View>
      </Animated.View>
    </View>
  );
};