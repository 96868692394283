import { useNavigation } from '@react-navigation/native';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RouteNames } from '@constants/navigation';
import { useTranslation } from '@ere-uilib/translations';
import { resetProfileAddressStatusRequest } from '@modules/profile/actions/profileActions';
import { getProfileAddressUpdateStatus } from '@modules/profile/selectors';
import { AddressUpdateStatus } from '@modules/profile/types';

import { label } from './label';

export const useContent = () => {
  const { formatMessage, getMessageRaw } = useTranslation();
  const navigation = useNavigation();
  const dispatch = useDispatch();

  const { addressUpdateStatus } = useSelector(
    getProfileAddressUpdateStatus
  );

  const resetUpdateStatus = useCallback(
    () => dispatch(resetProfileAddressStatusRequest()),
    [dispatch]
  );

  const isModalVisible = useMemo(
    () =>
      addressUpdateStatus === AddressUpdateStatus.SUCCESS ||
      addressUpdateStatus === AddressUpdateStatus.ERROR,
    [addressUpdateStatus]
  );

  const goToParent = useCallback(() => {
    resetUpdateStatus();
    navigation.navigate(RouteNames.BottomTabNavigator, {
      screen: RouteNames.ProfileStack,
      params: {
        screen: RouteNames.EditProfileInformations
      }
    });
  }, [navigation, resetUpdateStatus]);

  const goToHome = () => {
    resetUpdateStatus();
    navigation.navigate(RouteNames.BottomTabNavigator, {
      screen: RouteNames.Home
    });
  };

  const content = useMemo(() => {
    const status = addressUpdateStatus || AddressUpdateStatus.SUCCESS;
    return ({
      title: formatMessage({ id: label[status]?.title }),
      description: getMessageRaw({ id: label[status]?.description }),
      buttonTitle: formatMessage({ id: label[status]?.button.validate }),
      cancelButtonTitle: formatMessage({ id: label[status]?.button.cancel }),
      iconName: label[status]?.iconName
    });
  }, [addressUpdateStatus, formatMessage, getMessageRaw]);

  return {
    ...content,
    onValidate: goToParent,
    onCancel: (addressUpdateStatus === AddressUpdateStatus.ERROR && goToHome) || undefined,
    isModalVisible
  };
};
