import { ThemeType } from '@ere-uilib/styles'
import { PerformanceBarChartStyles, StylesContext } from './interfaces'
import { theme } from '@ere-uilib/theme'
import { palette } from '@ere-uilib/styles/theme/themeFolder/colors/palette'

export const getStyles = (
  context?: StylesContext,
  style?: PerformanceBarChartStyles
): PerformanceBarChartStyles => ({
  containerStyle: [
    {
      flex: 1
      // height: "100%"
    },
    (context?.isMobile || context?.isTablet) && {
      marginHorizontal: -context?.theme.metrics.spacing.s
    }
    // style?.containerStyle,
  ],
  tooltipDetailStyle: [
    {
      marginTop: context?.theme.metrics.spacing.xs
    },
    style?.tooltipDetailStyle
  ],
  overlay: {
    height: '100%',
    width: '20%',
    position: 'absolute',
    right: 0,
    zIndex: 1
  }
})

export const yValuesStyles = (theme: ThemeType) => ({
  fontSize: theme.fonts.fontSize.title.t8s,
  fontWeight: '300',
  paddingBottom: theme.metrics.spacing.xxs,
  fontFamily: theme.fonts.fontFamily.light
})

export const legendStyles = (theme: ThemeType) => ({
  labels: {
    fontSize: theme.fonts.fontSize.title.t7,
    fontFamily: theme.fonts.fontFamily.regular
  },
  parent: {
    paddingTop: theme.metrics.spacing.xxm,
    paddingBottom: theme.metrics.spacing.xxm
  }
})

export const axisStyles = (theme: ThemeType) => ({
  axis: { stroke: theme.colors.basics.grey.c400 },
  tickLabels: {
    fontSize: theme.fonts.fontSize.title.t8s,
    fontWeight: '300',
    paddingBottom: theme.metrics.spacing.xxs,
    fontFamily: theme.fonts.fontFamily.light
  }
})

export const barChartPadding = { left: 50, right: 10, top: 0, bottom: 30 }
export const barWidthStyle = {
  data: { width: 20 }
}
export const barFillStyle = {
  data: { fill: () => palette.greenLight_1.c900 }
}
export const secondBarFillStyle = {
  data: { fill: () => palette.greenDark_1.c500 }
}
export const secondBarLabelStyle = (theme: ThemeType) => ({
  labels: {
    fontSize: theme.fonts.fontSize.title.t8s,
    fontFamily: theme.fonts.fontFamily.regular,
    fill: (d: any) => (Number(d.datum._y) > 0 ? palette.black : palette.pinkLight_1.c500)
  }
})
export const barLabelStyle = (theme: ThemeType) => ({
  labels: {
    fontSize: theme.fonts.fontSize.title.t8s,
    fontFamily: theme.fonts.fontFamily.regular,
    fill: (d: any) => (Number(d.datum._y) > 0 ? palette.black : palette.pinkLight_1.c500)
  }
})
export const yAxisStyles = (theme: ThemeType) => ({
  axis: { stroke: theme.colors.basics.grey.c400, size: 60, strokeWidth: 1 },
  grid: { stroke: palette.grey.c800 },
  ticks: { stroke: palette.grey.c400, size: 6, strokeWidth: 1 },
  tickLabels: {
    fontSize: theme.fonts.fontSize.title.t8s,
    fontWeight: '300',
    paddingBottom: theme.metrics.spacing.xxs,
    fontFamily: theme.fonts.fontFamily.light
  }
})
