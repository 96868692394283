import { AuthActionType, AuthenticateAction } from '@modules/auth/actions/authActionsTypes';

import { AnnuityAction, AnnuityActionsType } from './actions/annuityActionsTypes';
import { AnnuityState } from './types';

export const initialState: AnnuityState = {
  resultsData: {
    value: 0,
    guaranteedValue: 0,
    reversionValue: 0,
    guaranteedReversionValue: 0
  },
  error: {
    code: '',
    correlationId: '',
    message: '',
    innerMessage: ''
  }
};

export default function AnnuityReducer(
  state: AnnuityState = initialState,
  action: AnnuityAction | AuthenticateAction
): AnnuityState {
  switch (action?.type) {
  case AnnuityActionsType.ANNUITY_INIT_ERROR:
    return {
      ...state,
      error: initialState.error
    };
  case AnnuityActionsType.SET_ANNUITY_SIMULATION_DATA_SUCCESS:
    return {
      ...state,
      resultsData: action.resultsData
    };
  case AuthActionType.LOGOUT_CALLBACK_SUCCESS:
    return initialState;
  default:
    return state;
  }
}
