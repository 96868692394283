import { RouteProp, useFocusEffect, useRoute } from "@react-navigation/native";
import React, { useCallback } from 'react';
import { View } from 'react-native';

import {
  ColumnsContainer,
  DashboardPageWrapperConnected,
  Link,
  NotificationHard,
  NotificationIconTypeEnum,
  RetryActionPlaceHolder,
  SimpleButton,
  Spinner,
  Text,
  useScreenSizes,
  useTranslation
} from '@components/index';
import { locator } from '@constants/locator';
import { RouteNames } from '@constants/navigation';
import {
  ProfitSharingIncentiveNavigatorInterface,
  ProfitSharingIncentiveNavigatorRouteNames
} from '@navigation/Interfaces';
import {
  ProfitSharingIncentiveLegalFrameworkEnum,
  ProfitSharingIncentiveTypeEnum
} from '@constants/ProfitSharingIncentive';

import { ProfitSharingIncentiveHeader } from '../components';
import { PrimeAmountCard } from './components';
import { ProfitSharingIncentiveDescriptionPageProps } from './interface';
import { label } from './label';
import { useStyles } from './styles';

export const ProfitSharingIncentiveDescriptionPageComponent: React.FC<React.PropsWithChildren<ProfitSharingIncentiveDescriptionPageProps>> = ({
  navigation,
  initData,
  getProfitSharingIncentiveInitData,
  isLoading,
  error,
}) => {
  const route = useRoute<RouteProp<
    ProfitSharingIncentiveNavigatorInterface,
    ProfitSharingIncentiveNavigatorRouteNames.ProfitSharingIncentiveDescription
    >>();

  const { type: profitSharingIncentiveType, cadre: profitSharingIncentiveLegalFramework, transactionId } = route?.params ?? {};
  const styles = useStyles();
  const { isMobile, isTablet } = useScreenSizes();
  const { formatMessage, getMessageRaw, formatDate } = useTranslation();

  const handleOnSubmitPress = useCallback(()=>{
    navigation.navigate(RouteNames.ProfitSharingIncentive, {
      screen: RouteNames.ProfitSharingIncentiveOnboarding,
    });
  }, [navigation]);

  const getInitData = useCallback(
    () => {
      getProfitSharingIncentiveInitData(
          profitSharingIncentiveType,
          profitSharingIncentiveLegalFramework,
          transactionId
      );
    }
    ,
    [
      getProfitSharingIncentiveInitData,
      profitSharingIncentiveLegalFramework,
      profitSharingIncentiveType
    ]
  );

  const renderBottomActions = useCallback(() => {
    return (
      <View style={styles.bottomActionsStyle}>
        <SimpleButton
          containerStyle={styles.simpleButtonStyle}
          loading={isLoading}
          onPress={handleOnSubmitPress}
          testId={locator._pi._lets_go}
          title={formatMessage({ id: label.button.next })}
        />
      </View>
    )
  }, [formatMessage, handleOnSubmitPress, isLoading, styles]);

  const renderAlert = useCallback(() => {
    const description =
      label.alert.description[profitSharingIncentiveType]?.[profitSharingIncentiveLegalFramework] ||
      label.alert.description[profitSharingIncentiveType]?.default;

    const isLinkHidden =
      profitSharingIncentiveType === ProfitSharingIncentiveTypeEnum.incentive &&
      profitSharingIncentiveLegalFramework === ProfitSharingIncentiveLegalFrameworkEnum.ppv;

    return (
      <NotificationHard
        containerStyle={styles.notificationContainerStyle}
        type={NotificationIconTypeEnum.WARNING}
      >
        <Text>
          {!!initData?.deadlineDate && formatMessage({
            id: label.alert.title,
            values: {
              date_fin: formatDate({ value: new Date(initData.deadlineDate) })
            },
          })}
          <Text weight="light">
            {formatMessage({ id: description })}
            {" "}
            {!isLinkHidden && (
              <Link
                onPress={() =>
                  navigation.navigate(RouteNames.ProfitSharingIncentive, {
                    screen: RouteNames.ProfitSharingIncentiveDefaultAffectations
                  })
                }>
                {formatMessage({ id: label.alert.link })}
              </Link>
            )}
          </Text>
        </Text>
      </NotificationHard>
    );
  }, [
    profitSharingIncentiveType,
    profitSharingIncentiveLegalFramework,
    styles.notificationContainerStyle,
    initData,
    formatMessage,
    formatDate,
    navigation
  ]);

  const renderHeader = useCallback(()=> {
    return (
      <ProfitSharingIncentiveHeader
        displayCloseButton
        helpPoint
        helpPointContentHtml={getMessageRaw({ id:
          label.header.help.content[profitSharingIncentiveType]?.[profitSharingIncentiveLegalFramework] ||
          label.header.help.content[profitSharingIncentiveType]?.default,
        })}
        helpPointModalTitle={formatMessage({ id: label.header.help.title, })}
        title={formatMessage({
          id:
            label.header.title[profitSharingIncentiveType]?.[profitSharingIncentiveLegalFramework] ||
            label.header.title[profitSharingIncentiveType]?.default
        })}
      />
    )
  }, [formatMessage, getMessageRaw, profitSharingIncentiveLegalFramework, profitSharingIncentiveType]);

  const renderError = useCallback(() => {
    return <RetryActionPlaceHolder
      retryAction={getInitData}
    />;
  }, [getInitData]);

  const renderContent = useCallback(() => {
    if (error?.message)
      return renderError();

    if(!initData)
      return;

    return (
      <View style={styles.pageContainer}>
        <PrimeAmountCard
          companyAmount={initData.totalAmount}
          derogatoryAmount={initData.derogatoryAmount}
          endDate={new Date(initData.fiscalYearEndDate)}
          primeAmount={initData.netShareAmount}
          startDate={new Date(initData.fiscalYearStartDate)}
          style={styles.premiumAmountCard}
        />
        {renderAlert()}
        {!isMobile && !isTablet && renderBottomActions()}
      </View>
    )
  }, [error, renderError, initData, styles, renderAlert, isMobile, isTablet, renderBottomActions]);

  useFocusEffect(getInitData);

  return (
    <DashboardPageWrapperConnected
      renderStickyMobileBottom={renderBottomActions}
    >
      <ColumnsContainer
        hasRenderRightDesktopColumn={false}
        renderHeader={renderHeader}
      >
        {isLoading && (<Spinner />)}
        {!isLoading && renderContent()}
      </ColumnsContainer>
    </DashboardPageWrapperConnected>
  );
};

