import React, { useMemo } from 'react';
import { StyleSheet, StyleProp, TextStyle } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

export const useStyles = ({
  style,
}:{
  style: StyleProp<TextStyle>
}) => {
  const theme = useTheme();

  const styles = useMemo(()=>StyleSheet.create({
    contributionTextStyle: {
      textAlign: 'right',
      color: theme.colors.basics.primary.c500,
      ...StyleSheet.flatten(style) 
    },
    contributionIcon: {
      marginRight: theme.metrics.spacing.xs,
    },
  }), [
    theme,
    style
  ])

  return styles;
};
