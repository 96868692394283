import { StyleContext, StyleProps } from './interfaces';

export const getStyles = (
  context?: StyleContext,
  style?: StyleProps,
): StyleProps => ({
  buttonContainer: [
    {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      paddingBottom: context?.theme.metrics.spacing.s,
      paddingHorizontal: context?.theme.metrics.spacing.s
    },
    context?.isMobile && {
      borderBottomWidth: 1,
      borderColor: context?.theme.colors.background.default.c500
    },
    style?.buttonContainer
  ],
  iconButton: [
    {
      flexDirection: 'row',
      paddingHorizontal: context?.theme.metrics.spacing.s,
      paddingVertical: 7,
      borderRadius: context?.theme.metrics.borderRadius.full,
      backgroundColor: context?.theme.colors.buttons.filter.c500,
      justifyContent: 'center',
      alignItems: 'center'
    },
    style?.iconButton
  ],
  iconButtonText: [
    {
      color: context?.theme.colors.basics.white,
      marginLeft: context?.theme.metrics.spacing.s
    },
    style?.iconButtonText
  ],
  helpPointStyle: [
    {
      marginLeft: context?.theme.metrics.spacing.s
    },
    style?.helpPointStyle
  ]
});
