export enum OtpChanelTypeEnum {
  MAIL = 'Email',
  SMS = 'SMS',
  MAIL_DOCAPOSTE = 'EmailDocaposte',
  SMS_DOCAPOSTE = 'SmsDocaposte',
  UNKNOWN = 'UNKNOWN'
}

export enum UserInfoTypeEnum {
  MAIL = 'MAIL',
  PHONE = 'PHONE',
  ADDRESS = 'ADDRESS'
}

export enum AddressStatusEnum {
  VALID = 'Valid',
  IN_PROGRESS = 'InProgress',
  INVALID = 'Invalid'
}

export const BankStepsEnum = ['Arbitrage_etape1', 'Arbitrage_etape2', 'Arbitrage_etape3'];
