
import { SelfcareLetterSuccessPageComponent } from './SelfcareLetterSuccessPage.component';
import {getApplicationSettings, getAuthConfig} from "@modules/settings/selectors";
import {getInvitationSelfcareEmail} from "@modules/invitation/selectors";
import {useSelector} from "react-redux";

export const SelfcareLetterSuccessPage: React.FC = () => {
  const applicationSettings = useSelector(getApplicationSettings);
  const selfcareEmail = useSelector(getInvitationSelfcareEmail);
  const authConfig = useSelector(getAuthConfig);
  return (<SelfcareLetterSuccessPageComponent
      selfcareEmail={selfcareEmail}
      authConfig={authConfig}
      applicationSettings={applicationSettings} />);
}
