export enum EnvironnementNameEnum {
  UAT_TEMP = 'UAT_TEMP',
  UAT_CLOUD = 'UAT_CLOUD',
  INT_DEMO = 'INT_DEMO',
  UAT = 'UAT',
  UAT_DEMO = 'UAT_DEMO',
  UAT_DEMO_CLOUD = 'UAT_DEMO_CLOUD',
  INT = 'INT',
  INT_CLOUD = 'INT_CLOUD',
  INT_DEMO_CLOUD = 'INT_DEMO_CLOUD',
  PROD = 'PROD',
  PROD_CLOUD = 'PROD_CLOUD',
  PROD_DEMO = 'PROD_DEMO',
  DEV_LOCAL = 'dev.local',
  PROD_DEMO_CLOUD = 'PROD_DEMO_CLOUD',
  DEV = 'DEV',
  DEV_CLOUD = 'DEV_CLOUD',
}
