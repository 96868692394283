import React, { useState } from 'react';
import { View } from 'react-native';

import { TextField, NotificationSoft } from '@ere-uilib/molecules';
import { createUseStyles, useTheme, useScreenSizes } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';

import { Props, PropsStyle, ValidationContext } from './interfaces';

type DataProps = Props & PropsStyle
export const ChooseIdentificationMail: React.FC<React.PropsWithChildren<DataProps>> = ({
  containerStyle,
  inputStyle,
  titleStyle,
  inputsContainerStyle,
  setDisable,
  setEmailValue,
  appSettings,
  onSubmit,
  testId
}) => {
  const { isMobile, isTablet } = useScreenSizes();

  const theme = useTheme();
  const { formatMessage } = useTranslation();

  const styles = useStyles(
    { theme, isMobile, isTablet },
    { containerStyle, inputStyle, titleStyle, inputsContainerStyle },
  );

  const [value, setValue] = useState('');
  const emailRegex = new RegExp(appSettings.EmailFormat || '');
  return (
    <View style={styles.containerStyle}>
      <View style={styles.inputsContainerStyle}>
        <TextField
          containerStyle={styles.textFieldContainerStyle}
          inputProps={{
            value: value,
            placeholder: 'Adresse e-mail',
            onChangeText: mail => {
              setValue(mail);
              setDisable && setDisable(!emailRegex.test(mail));
              setEmailValue && setEmailValue(mail);
            }
          }}
          labelOnTop
          onSubmit={onSubmit}
          testId={testId}
        />
      </View>
      <NotificationSoft
        text={formatMessage({ id: 'choose-login_info-buble' })}
        type="info"
      />
    </View>
  );
};

const getStyles = (context?: ValidationContext, style?: PropsStyle): PropsStyle => ({
  inputStyle: [style?.inputStyle],
  containerStyle: [
    (context?.isMobile || context?.isTablet) && {
      flex: 1,
      maxWidth: '100%',
    },
    {
      alignItems: 'flex-start',
      maxWidth: 602,
    },
    style?.containerStyle,
  ],
  titleStyle: [
    {
      lineHeight: 28,
      marginBottom: context?.theme.metrics.spacing.xm,
    },
    style?.titleStyle,
  ],
  inputsContainerStyle: [
    {
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'flex-start',
      marginVertical: context?.theme.metrics.spacing.l,
    },
    style?.inputsContainerStyle,
  ],

  separatorStyle: [
    {
      width: 2,
      height: '100%',
      backgroundColor: context?.theme.colors.background.default.c500,
      marginHorizontal: context?.theme.metrics.spacing.s,
    },
    style?.separatorStyle,
  ],
  textFieldContainerStyle: [
    {
      width: '100%',
    },
  ],
  rowStyle: [
    {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-around',
      marginTop: context?.theme.metrics.spacing.xm,
      marginBottom: context?.theme.metrics.spacing.l,
    },
    style?.rowStyle,
  ],
});

const useStyles = createUseStyles(getStyles);
