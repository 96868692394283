import { useMemo } from "react"
import { StyleSheet } from "react-native"

import { useScreenSizes, useTheme } from "@ere-uilib/styles";

export const useStyles = (
  isSelected: boolean,
  hovered: boolean
) => {
  const theme = useTheme();
  const { isMobile } = useScreenSizes();
  const styles = useMemo(() => StyleSheet.create({
    containerStyle: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      flex: 1,
      borderRadius: theme.metrics.borderRadius.s,
      minWidth: 70,
      minHeight: 50,
      borderWidth: 2,
      padding: theme.metrics.spacing.m,
      backgroundColor: (hovered || isSelected) ? "rgba(0, 136, 89, 0.1)" : theme.colors.basics.white,
      borderColor: (hovered || isSelected) ? "rgb(0, 136, 89)" : "grey"
    },
    iconStyle: {
      fontSize: theme.metrics.iconSizes.l,
      marginBottom: theme.metrics.spacing.s
    }
  }), [isMobile, theme, hovered, isSelected]);

  return styles;
}
