import React from 'react';
import { View, StyleProp, ViewStyle, TextStyle } from 'react-native';

import { Paragraph } from '@components/index';
import { ThemeType, useScreenSizes } from '@ere-uilib/styles';
import { createUseStyles, useTheme } from '@ere-uilib/styles';
import { useTranslation } from '@translations/index';

interface TotalAmountCellItemPlans {
  name: string;
  amount: number;
  shares: number;
}

interface TotalAmountCellItem {
  totalAmount: number;
  totalShares: number;
  date?: string;
  plans: TotalAmountCellItemPlans[];
}

export type TotalAmountCellStyle = {
  containerStyle?: StyleProp<ViewStyle>;
  containerTextStyle?: StyleProp<TextStyle>;
  plansContainerStyle?: StyleProp<ViewStyle>;
  plansContainerRowStyle?: StyleProp<ViewStyle>;
  plansContainerTextStyle?: StyleProp<TextStyle>;
};

export type TotalAmountCellItemProps = {
  data: TotalAmountCellItem;
  containerStyle?: StyleProp<ViewStyle>;
  containerTextStyle?: StyleProp<TextStyle>;
  plansContainerStyle?: StyleProp<ViewStyle>;
  plansContainerRowStyle?: StyleProp<ViewStyle>;
  plansContainerTextStyle?: StyleProp<TextStyle>;
};

export type TotalAmountCellContext = {
  theme: ThemeType;
  isDesktop: boolean;
};

export const TotalAmountCell: React.FC<React.PropsWithChildren<TotalAmountCellItemProps>> = ({ data, containerStyle }) => {
  const theme = useTheme();
  const { isDesktop } = useScreenSizes();
  const styles = useStyles({ theme, isDesktop }, { containerStyle });
  const { formatDate, formatCurrencyNumber, formatShareCountNumber, formatMessage } =
    useTranslation();
  return (
    <View style={styles.containerStyle}>
      <Paragraph
        style={styles.containerTextStyle}
        variant="t3">
        {data.date &&
          `${formatMessage({
            id: 'Fundsheet_Tab1_Fund_Position_Dispositif_at_date_title',
            values: {
              variable: formatDate({
                value: data.date,
                options: {
                  dateStyle: 'short'
                }
              })
            }
          })} : `}
        {formatCurrencyNumber({ value: data.totalAmount })}
        {` ${formatMessage({ id: 'Fundsheet_Tab1_Fund_Position_row_legend' })}`}
      </Paragraph>
      <Paragraph
        style={styles.containerTextStyle}
        variant="t3">
        {formatMessage({
          id: 'Fundsheet_Tab1_Fund_Position_nbre_of_shares_legend',
          values: {
            variable: formatShareCountNumber({
              value: data.totalShares
            })
          }
        })}
      </Paragraph>
      {data?.plans?.map(item => {
        return (
          <View style={styles.plansContainerStyle}>
            <View style={styles.plansContainerRowStyle}>
              <Paragraph
                style={styles.plansContainerTextStyle}
                variant="t3"
                weight="light">
                {item.name}
                {`: ${formatCurrencyNumber({ value: item.amount })} `}
                {formatMessage({
                  id: 'Fundsheet_Tab1_Fund_Position_nbre_of_shares_legend',
                  values: {
                    variable: formatShareCountNumber({
                      value: item.shares
                    })
                  }
                })}
              </Paragraph>
            </View>
          </View>
        );
      })}
    </View>
  );
};

const getStyles = (
  context?: TotalAmountCellContext,
  style?: TotalAmountCellStyle
): TotalAmountCellStyle => {
  return {
    containerStyle: [
      {
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center'
      },
      context?.isDesktop && {
        flex: 1
      },
      style?.containerStyle
    ],
    containerTextStyle: [
      {
        textAlign: 'center'
      },
      style?.containerStyle
    ],
    plansContainerStyle: [
      {
        marginTop: context?.theme.metrics.spacing.s,
        alignItems: 'center',
        justifyContent: 'center'
      },
      style?.plansContainerStyle
    ],
    plansContainerRowStyle: [
      {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
      },
      style?.plansContainerRowStyle
    ],
    plansContainerTextStyle: [
      {
        textAlign: 'center'
      },
      style?.plansContainerTextStyle
    ]
  };
};

const useStyles = createUseStyles(getStyles);
