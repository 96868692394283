import { useCallback } from 'react';

import { ApplicationSettingsState } from '@modules/settings/types';
import { SimulationTypeOperation } from '@pages/Annuity/AnnuitySimulator/sharedInterfaces';

import {
  RENTE_MAX_AGE_DEFAULT,
  RENTE_MAX_BIRTH_DATE_DEFAULT,
  RENTE_MAX_CAPITAL_DEPART_DEFAULT,
  RENTE_MAX_RENTE_DEPART_DEFAULT,
  RENTE_MIN_AGE_DEFAULT,
  RENTE_MIN_BIRTH_DATE_DEFAULT,
  RENTE_MIN_CAPITAL_DEPART_DEFAULT,
  RENTE_MIN_RENTE_DEPART_DEFAULT
} from '../constants';
import { getAge, isBirthDateValid } from '../utils';

export const useAnnuitySimulatorValidator = (settings: ApplicationSettingsState) => {
  const validateBirthDate = useCallback(
    (birthDate: Date | undefined) =>
      isBirthDateValid({
        birthDate,
        maximumAge: +(settings.RenteMaxBirthDate || RENTE_MAX_BIRTH_DATE_DEFAULT),
        minimumAge: +(settings.RenteMinBirthDate || RENTE_MIN_BIRTH_DATE_DEFAULT)
      }),
    [settings]
  );

  const validateDepartureAge = useCallback(
    (birthDate: Date | undefined) => (departureAge: number | string | undefined) => {
      if (!departureAge || isNaN(+departureAge)) return false;
      const age = getAge(birthDate);
      return (
        +departureAge >= age &&
        +departureAge >= +(settings.RenteMinBirthDate || RENTE_MIN_BIRTH_DATE_DEFAULT) &&
        +departureAge >= +(settings.RenteMinAge || RENTE_MIN_AGE_DEFAULT) &&
        +departureAge <= +(settings.RenteMaxAge || RENTE_MAX_AGE_DEFAULT) &&
        +departureAge <= +(settings.RenteMaxBirthDate || RENTE_MAX_BIRTH_DATE_DEFAULT)
      );
    },
    [settings]
  );

  const validateAmount = useCallback(
    (operation: SimulationTypeOperation | undefined) => (amount: number | string | undefined) => {
      if (!operation || !amount || isNaN(+amount)) return false;

      if (operation === SimulationTypeOperation.CapitalToAnnuity)
        return (
          +amount >= +(settings.RenteMinCapitalDepart || RENTE_MIN_CAPITAL_DEPART_DEFAULT) &&
          +amount <= +(settings.RenteMaxCapitalDepart || RENTE_MAX_CAPITAL_DEPART_DEFAULT)
        );

      if (operation === SimulationTypeOperation.AnnuityToCapital)
        return (
          +amount >= +(settings.RenteMinRenteDepart || RENTE_MIN_RENTE_DEPART_DEFAULT) &&
          +amount <= +(settings.RenteMaxRenteDepart || RENTE_MAX_RENTE_DEPART_DEFAULT)
        );

      return false;
    },
    [settings]
  );

  return { validateBirthDate, validateDepartureAge, validateAmount };
};
