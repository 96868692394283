import React from 'react';
import { connect } from 'react-redux';

import { getUser } from '@modules/auth/selectors';
import { AppState } from '@modules/index';
import {
  getConsents,
} from '@modules/profile/selectors';
import { getAtInternetClientIdSession, getIsATInternetEnabled } from '@modules/settings/selectors';

import { AppNavigatorComponent } from './AppNavigator.component';
import { setAtInternetCliendIdBySession } from '@modules/settings/actions/settingsActions';

const mapStateToProps = (state: AppState) => {
  return {
    consents: getConsents(state),
    user: getUser(state),
    isATInternetEnabled: getIsATInternetEnabled(state),
    atInternetClientIdSession: getAtInternetClientIdSession(state)

  };
};

const mapDispatchToProps = {
  onSetAtInternetClientIdSession: setAtInternetCliendIdBySession
}

export const AppNavigator = connect(mapStateToProps, mapDispatchToProps)(AppNavigatorComponent);
