import { PlanTypeEnum } from '@ere-uilib/enums'
import { ThemeType } from '@ere-uilib/styles';
import { ColorShades, ColorTypes } from '@ere-uilib/styles/theme/themeFolder';

export const getPlanTypeColors = (planType: string | null, theme: ThemeType):ColorShades => {
  const plansColors = theme.colors.plans;
  
  if(typeof planType !== 'string') return plansColors.UNKNOWN
  const planTypeNomralized = planType.split(' ').join('').toUpperCase()

  const isPlanTypeKnown = planTypeNomralized in PlanTypeEnum
  if(!isPlanTypeKnown) return plansColors.UNKNOWN
  const checkedPlanType = planTypeNomralized as PlanTypeEnum

  const isPlanTypeInColors = Object.keys(plansColors).includes(planTypeNomralized)
  if(isPlanTypeInColors) {
    const checkedColorPlanType = planTypeNomralized as keyof ColorTypes['plans']
    return plansColors[checkedColorPlanType]
  }else{
    switch (true) {
    case [
      PlanTypeEnum.PERECOG,
      PlanTypeEnum.PERECOI
    ].includes(checkedPlanType): return plansColors[PlanTypeEnum.PERECO]

    case [
      PlanTypeEnum.PERCOG,
      PlanTypeEnum.PERCOI
    ].includes(checkedPlanType): return plansColors[PlanTypeEnum.PERCO]

    case [
      PlanTypeEnum.PEROG,
      PlanTypeEnum.PEROI,
      PlanTypeEnum.PERE,
      PlanTypeEnum.A83
    ].includes(checkedPlanType): return plansColors[PlanTypeEnum.PERO]

    case [
      PlanTypeEnum.PEG,
      PlanTypeEnum.PEI
    ].includes(checkedPlanType): return plansColors[PlanTypeEnum.PEE]

    case [
      PlanTypeEnum.PELT,
      PlanTypeEnum.PPESVI
    ].includes(checkedPlanType): return plansColors[PlanTypeEnum.A39]
    
    default:
      return plansColors.UNKNOWN
    }
  }
};