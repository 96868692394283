import { useScreenSizes, useTheme } from "@ere-uilib/styles";
import { useMemo } from "react"
import { StyleSheet } from "react-native"

export const useStyles = ( isGraphDisplayed: boolean) => {
  const theme = useTheme();
  const { isMobile } = useScreenSizes()

  const styles = useMemo(() => StyleSheet.create({
    innerContainerStyle: !isMobile && isGraphDisplayed
      ? {
        flexDirection: 'row', flex: 1,
      } : {
        flexDirection: 'column',
      },
    contentContainerStyle: {
      ...(!isMobile && {
        flex: 1,

      }),
      ...(isGraphDisplayed && !isMobile && {
        width: '50%',
      })
    },
    rowStyle: {
      flexDirection: 'row'
    },
    helpPointStyle: {
      marginRight: theme.metrics.spacing.s,
    },
    cardStyle: {
      backgroundColor: theme.colors.background.default.c500,
      marginTop: theme.metrics.spacing.xm,
      borderRadius: theme.metrics.borderRadius.xs,
      ...(isMobile ?
        {
          paddingLeft: theme.metrics.spacing.m,
          paddingRight: theme.metrics.spacing.m,
        } :
        {
          paddingLeft: theme.metrics.spacing.xm,
          paddingRight: theme.metrics.spacing.xm,
        })
    },
    cardTitleStyle: {
      marginTop: theme.metrics.spacing.s,
    },
    noPlanTextContainer: {
      paddingVertical: theme.metrics.spacing.xm,
    }
  }), [
    theme,
    isMobile,
    isGraphDisplayed
  ]);

  return styles;
}