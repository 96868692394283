import { connect } from 'react-redux';

import {
  removeDocumentBankDataRequest,
  uploadDocumentBankDataRequest
} from '@modules/bank-details/actions/actionsCreators';
import { getDocument, getMaxFileSizes, getSelectedAccountType, getSelectedPlanFamily } from '@modules/bank-details/selectors';
import { AppState } from '@modules/reducers';

import { DocumentUploadComponent } from './DocumentsUpload.component';

const mapStateToProps = (state: AppState) => ({
  fileMaxSize: getMaxFileSizes(state)?.fileMaxSize,
  document: getDocument(state),
  selectedAccountType: getSelectedAccountType(state),
  selectedPlanFamily: getSelectedPlanFamily(state)
});

const mapDispatchToProps = {
  onUploadDocument: uploadDocumentBankDataRequest,
  onRemoveDocument: removeDocumentBankDataRequest
};

export const BankSetDocumentsPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentUploadComponent);
