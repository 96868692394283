import React, { useCallback } from 'react';

import {
  ColumnsContainer,
  DashboardPageWrapperConnected,
  SimpleButton,
  Text,
  useTranslation
} from '@components/index';
import { useScreenSizes } from '@ere-uilib/styles';

import { useStyles } from './useStyles';
import { ProfileEditAddressHeader } from '../components';
import { label } from './label';
import { useData } from './useData';
import { FormTitle } from '../ProfileEditAddressFormPage/components';

export const ProfileEditAddressInformationPage: React.FC = () => {
  const { formatMessage } = useTranslation();
  const styles = useStyles();
  const { isDesktop } = useScreenSizes();
  const { inputMode, TSAAddress, handleNextButtonPress } = useData();

  const renderBottomButton = useCallback(
    () => (
      <SimpleButton
        containerStyle={styles.button}
        design="outlined"
        onPress={handleNextButtonPress}
        size="large"
        title={formatMessage({ id: label.button.next[inputMode] || label.button.next.default })}
      />
    ),
    [styles, handleNextButtonPress, formatMessage, inputMode]
  );

  const renderDescriptions = useCallback(
    () =>
      ((label.descriptions[inputMode] || label.descriptions.default)?.(TSAAddress) || []).map(
        ({ value, weight }) => {
          const text = formatMessage({ id: value, defaultMessage: '' });
          if (!text) return null;
          return (
            <Text
              variant="t3"
              weight={weight || 'regular'}>
              {text}
            </Text>
          );
        }
      ),
    [TSAAddress, formatMessage, inputMode]
  );

  return (
    <DashboardPageWrapperConnected renderStickyMobileBottom={renderBottomButton}>
      <ColumnsContainer
        hasRenderRightDesktopColumn={false}
        leftColumnStyle={styles.container}
        renderHeader={() => <ProfileEditAddressHeader hasCloseButton={false} />}>
        <FormTitle inputMode={inputMode} />
        {renderDescriptions()}
        {isDesktop && renderBottomButton()}
      </ColumnsContainer>
    </DashboardPageWrapperConnected>
  );
};
