export enum Gender {
  Man = 'M',
  Woman = 'F'
}

interface Person {
  gender: Gender | undefined;
  birthDate: Date | undefined;
}

export type Reversion = {
  rate?: number | undefined;
  beneficiary?: Person;
};

export enum SimulationTypeOperation {
  CapitalToAnnuity = 'CAPITAL-ANNUITY',
  AnnuityToCapital = 'ANNUITY-CAPITAL'
}

export interface SimulationType {
  operation?: SimulationTypeOperation | undefined;
  periodicity?: string | undefined;
  amount: string | undefined;
}

export interface Data extends Person {
  departureAge: string | undefined;
  simulationType: SimulationType | undefined;
  reversion: Reversion | undefined;
}

export interface SelectItem<T = string> {
  value: T;
  label: string;
}

export type QuestionAnsweredStatus = {
  [P in keyof Data]: boolean;
}
