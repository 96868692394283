import { useScreenSizes, useTheme } from '@ere-uilib/styles';
import { useMemo } from "react"
import { StyleSheet } from "react-native";

export const useStyles = () => {
  const theme = useTheme();
  const { isMobile, isTablet } = useScreenSizes();
  return useMemo(() => StyleSheet.create({
    contentContainer: {
      borderBottomWidth: 1,
      borderColor: theme.colors.basics.grey.c200,
      marginBottom: theme.metrics.spacing.s,
    },
    buttonContainer:
      {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        paddingRight: theme.metrics.spacing.s,
        paddingLeft: isMobile? theme.metrics.spacing.s : theme.metrics.spacing.xm,
        marginBottom: theme.metrics.spacing.s

      },
    brutIndicator: {
      textAlign: "right"
    }
  }), [theme, isMobile]);
}
