import { StyleProp, ViewStyle } from 'react-native';

import { ThemeType } from '@ere-uilib/styles';


type OtpValidationModalStyles = {
  informationRow?: StyleProp<ViewStyle>
  separator?: StyleProp<ViewStyle>
  textContainer?: StyleProp<ViewStyle>
  buttonContainer?: StyleProp<ViewStyle>
  iconContainer?: StyleProp<ViewStyle>
  container?: StyleProp<ViewStyle>
  innerModal?: StyleProp<ViewStyle>
  titleContainer?: StyleProp<ViewStyle>
  customHelpContainer?: StyleProp<ViewStyle>

};

export type StylesContext = {
  theme: ThemeType,
  isMobile: boolean
};

export const getStyles = (
  context?: StylesContext,
  style?: OtpValidationModalStyles,
): OtpValidationModalStyles => ({
  informationRow: [
    {
      maxWidth: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginTop: context?.theme.metrics.spacing.m
    },
    style?.informationRow
  ],
  container: [
    {
      flex: 1,
      alignItems: 'center',
      backgroundColor: context?.theme.colors.background.poppinFilter.c500
    },
    context?.isMobile
      ? {
        justifyContent: 'flex-end'
      }
      : {
        justifyContent: 'center'
      },
    style?.container
  ],
  innerModal: [
    {
      alignItems: 'center',
      justifyContent: 'flex-start'
    },
    style?.innerModal
  ],
  separator: [
    {
      width: 1,
      height: '100%',
      backgroundColor: context?.theme.colors.basics.grey.c200, //theme.colors.opacityGrey,
      margin: context?.theme.metrics.spacing.s
    },
    style?.separator
  ],
  titleContainer: [
    {
      alignItems: 'center',
      marginTop: context?.theme.metrics.spacing.s
    },
    style?.titleContainer
  ],
  textContainer: [
    {
      maxWidth: '90%',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginTop: context?.theme.metrics.spacing.s
    },
    style?.textContainer
  ],
  buttonContainer: [
    {
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: context?.theme.metrics.spacing.m,
      width: '100%'
    },
    style?.buttonContainer
  ],
  iconContainer: [
    {
      borderRadius: context?.theme.metrics.borderRadius.xs,
      padding: context?.theme.metrics.spacing.s,
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: context?.theme.metrics.spacing.s
    },
    style?.iconContainer
  ],
  customHelpContainer: [
    {
      alignSelf: 'stretch'
    },
    style?.customHelpContainer
  ]
});
