import React from 'react';
import { useScreenSizes } from '@ere-uilib/styles';
import { LoaderCard } from '@ere-uilib/molecules';
import { RepartitionsContentLoader } from './components';

export const RepartitionsLoader: React.FC = () => {
  const isMobile = !useScreenSizes().isDesktop;

  return (
    <LoaderCard
      isMobile={isMobile}
    >
      <RepartitionsContentLoader />
    </LoaderCard>
  );
};