import { connect } from 'react-redux';

import {
  selectInvestedManagement,
  selectInvestedSupport,
  setArbitrationResinvestPerentageModalVisibleState
} from '@modules/arbitration/actions/arbitrationActions';
import {
  getArbitrationResinvestPercentageModalVisibleState,
} from '@modules/arbitration/selectors';
import { AppState } from '@modules/index';

import { ArbitrationPercentFieldModalConnectedComponent } from './ArbitrationPercentFieldModalConnected.component';

const mapStateToProps = (state: AppState) => {
  return {
    visible: getArbitrationResinvestPercentageModalVisibleState(state),
  };
};

const mapDispatchToProps = {
  onSetArbitrationResinvestPerentageModalVisibleState: setArbitrationResinvestPerentageModalVisibleState,
  onSelectInvestedSupport: selectInvestedSupport,
  onSelectInvestedManagement: selectInvestedManagement,

};

export const ArbitrationPercentFieldModalConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(ArbitrationPercentFieldModalConnectedComponent);
