import { NavigatorScreenParams } from '@react-navigation/native'

import { SimulatorType } from '@constants/sapiendo'

import {
  AnnuityNavigatorInterface,
  BankDetailsNavigatorInterface,
  BottomTabNavigatorInterface,
  ForgetPasswordNavigatorInterface,
  InstallmentNavigatorInterface,
  OtpSecurisationNavigatorInterface,
  OtpValidationNavigatorInterface,
  ProfileEditAddressStackNavigatorInterface,
  RefundsNavigatorInterface,
  RetirementNavigatorInterface,
} from './'
import { ArbitrationNavigatorInterface } from './ArbitrationNavigatorInterface'
import { DocumentSpaceNavigatorInterface } from './DocumentSpaceNavigatorInterface'
import { ProfitSharingIncentiveNavigatorInterface } from './ProfitSharingIncentiveNavigatorInterface'
import { RelationShipEnteringNavigatorInterface } from './RelationshipEnteringNavigatorInterface'
import { SelfcareNavigatorInterface } from './SelfcareNavigatorInterface'

export enum AppNavigatorRouteNames {
  // AppNavigator (Root)
  CguAcceptation = 'CguAcceptation',
  BottomTabNavigator = 'BottomTabNavigator',
  CompanyChoicePage = 'CompanyChoicePage',
  CompanyBenefits = 'CompanyBenefits',
  DisponibilityTermPage = 'DisponibilityTermPage',
  SapiendoRedirectPage = 'SapiendoRedirectPage',
  InstallmentStack = 'InstallmentStack',
  RefundsStack = 'RefundsStack',
  ArbitrationStack = 'ArbitrationStack',
  DocumentSpaceStack = 'DocumentSpaceStack',
  OperationTypeModalPage = 'OperationTypeModalPage',
  OtpSecurisation = 'OtpSecurisation',
  OidcLogoutCallbackRedirect = 'OidcLogoutCallbackRedirect',
  NativeLoginPage = 'NativeLoginPage',
  SelfcareLetterLanding = 'SelfcareLetterLanding',
  Logout = 'Logout',
  EndOidcSessionPage = 'EndOidcSessionPage',
  EndOidcSessionCallbackPage = 'EndOidcSessionCallbackPage',
  OidcCallbackRedirect = 'OidcCallbackRedirect',
  OidcCallbackRedirectSilentPage = 'OidcCallbackRedirectSilentPage',
  Support = 'Support',
  Error = 'Error',
  BackOfficeAccess = 'BackOfficeAccess',
  Storybook = 'Storybook',
  LegalInfo = 'LegalInfo',
  RelationshipEntringProcess = 'RelationshipEntringProcess',
  ForgetPassword = 'ForgetPassword',
  OtpValidation = 'OtpValidation',
  RedirectOperationPage = 'RedirectOperationPage',
  ConsentTransferCredit = 'ConsentTransferCredit',
  // // ???? TODO ASK WHAT IS THIS ROUTE
  Funds = 'funds',
  Empty = '',
  Contact = 'ContactPreferences',
  VVPDetails = 'VVPDetails',
  OperationDetails = 'OperationDetails',
  OperationCancel = 'OperationCancel',
  OperationCancelSuccess = 'OperationCancelSuccess',
  SelfcareStack = 'SelfcareStack',
  BiConsentment = 'BiConsentment',
  BiErrorPage = 'BiErrorPage',
  BiConnect = 'BiConnect',
  BiCallback = 'BiCallback',
  BusinessContact = 'BusinessContactPreferences',
  ProfitSharingIncentive = 'ProfitSharingIncentive',
  MaintenanceModal = 'MaintenanceModal',
  AnnuityStack = 'AnnuityStack',
  BankDetailsStack = 'BankDetailsStack',
  RetirementStack = 'RetirementStack',
  ContributionModal = 'ContributionModal',
  ControlData = 'ControlData',
  HelpContacts = 'HelpContacts',
  MaintenancePage = 'MaintenancePage',
  VotAccess = 'VotAccess',
  ProfileEditAddress = 'ProfileEditAddress',
}

export type AppNavigatorInterface = {
  // AppNavigator (Root)
  [AppNavigatorRouteNames.CguAcceptation]: undefined
  [AppNavigatorRouteNames.BottomTabNavigator]: NavigatorScreenParams<BottomTabNavigatorInterface>
  [AppNavigatorRouteNames.CompanyChoicePage]: undefined
  [AppNavigatorRouteNames.CompanyBenefits]: undefined
  [AppNavigatorRouteNames.BusinessContact]: {
    toDoPostSuccessActionType: string
    demandId: string
  }
  [AppNavigatorRouteNames.DisponibilityTermPage]: undefined
  [AppNavigatorRouteNames.SapiendoRedirectPage]: {
    type: SimulatorType
  }
  [AppNavigatorRouteNames.RedirectOperationPage]: {
    operationType: string
    provider: string
  }
  [AppNavigatorRouteNames.InstallmentStack]: NavigatorScreenParams<InstallmentNavigatorInterface>
  [AppNavigatorRouteNames.Contact]: {
    toDoPostSuccessActionType: string
    demandId: string
  }
  [AppNavigatorRouteNames.ConsentTransferCredit]: {
    campaignId: string
    toDoPostSuccessActionType?: string
    isPostConnexionMode?: boolean
  }
  [AppNavigatorRouteNames.RefundsStack]: NavigatorScreenParams<RefundsNavigatorInterface>
  [AppNavigatorRouteNames.ArbitrationStack]: NavigatorScreenParams<ArbitrationNavigatorInterface>
  [AppNavigatorRouteNames.DocumentSpaceStack]: NavigatorScreenParams<DocumentSpaceNavigatorInterface>
  [AppNavigatorRouteNames.OperationTypeModalPage]: {
    type?: string
  }
  [AppNavigatorRouteNames.OtpSecurisation]: NavigatorScreenParams<OtpSecurisationNavigatorInterface>
  [AppNavigatorRouteNames.OtpValidation]: NavigatorScreenParams<OtpValidationNavigatorInterface>
  [AppNavigatorRouteNames.OidcLogoutCallbackRedirect]: undefined
  [AppNavigatorRouteNames.NativeLoginPage]: undefined
  [AppNavigatorRouteNames.SelfcareLetterLanding]: undefined
  [AppNavigatorRouteNames.Logout]: undefined
  [AppNavigatorRouteNames.EndOidcSessionPage]: undefined
  [AppNavigatorRouteNames.EndOidcSessionCallbackPage]: undefined
  [AppNavigatorRouteNames.OidcCallbackRedirect]: undefined
  [AppNavigatorRouteNames.OidcCallbackRedirectSilentPage]: undefined
  [AppNavigatorRouteNames.Support]: undefined
  [AppNavigatorRouteNames.Error]: {
    errorDescriptionLabel?: string
    redirectionRouteName?: string
    redirectionRouteParams?: {} | undefined
    errorTitleLabel?: string
    errorButtonTitleLabel?: string
  }
  [AppNavigatorRouteNames.BackOfficeAccess]: undefined
  [AppNavigatorRouteNames.Storybook]: undefined
  [AppNavigatorRouteNames.LegalInfo]: undefined
  [AppNavigatorRouteNames.SelfcareStack]: NavigatorScreenParams<SelfcareNavigatorInterface>
  [AppNavigatorRouteNames.BiConsentment]: undefined
  [AppNavigatorRouteNames.BiErrorPage]: undefined
  [AppNavigatorRouteNames.BiConnect]: undefined
  [AppNavigatorRouteNames.BiCallback]:
    | {
        errorId: string
      }
    | undefined
  [AppNavigatorRouteNames.VVPDetails]: { id: string }
  [AppNavigatorRouteNames.OperationDetails]: { id: string }
  [AppNavigatorRouteNames.OperationCancel]: undefined
  [AppNavigatorRouteNames.OperationCancelSuccess]: undefined
  [AppNavigatorRouteNames.RelationshipEntringProcess]: NavigatorScreenParams<RelationShipEnteringNavigatorInterface>
  [AppNavigatorRouteNames.ForgetPassword]: NavigatorScreenParams<ForgetPasswordNavigatorInterface>
  [AppNavigatorRouteNames.MaintenanceModal]: undefined
  [AppNavigatorRouteNames.ProfitSharingIncentive]: NavigatorScreenParams<ProfitSharingIncentiveNavigatorInterface>
  [AppNavigatorRouteNames.Funds]: {
    id: string
    title: string
  }
  [AppNavigatorRouteNames.RetirementStack]: NavigatorScreenParams<RetirementNavigatorInterface>
  [AppNavigatorRouteNames.AnnuityStack]: NavigatorScreenParams<AnnuityNavigatorInterface>
  [AppNavigatorRouteNames.BankDetailsStack]: NavigatorScreenParams<BankDetailsNavigatorInterface>
  [AppNavigatorRouteNames.ContributionModal]: undefined
  [AppNavigatorRouteNames.ControlData]: undefined
  [AppNavigatorRouteNames.HelpContacts]: undefined
  [AppNavigatorRouteNames.MaintenancePage]: undefined
  [AppNavigatorRouteNames.VotAccess]: undefined
  [AppNavigatorRouteNames.ProfileEditAddress]: NavigatorScreenParams<ProfileEditAddressStackNavigatorInterface> | undefined
}
