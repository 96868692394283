import { Context, Styles } from './interfaces'

export const getStyles = (context?: Context, style?: Styles): Styles => ({
  offLineLayoutInnerContainer: {
    paddingBottom: 0
  },
  container: [
    context?.isDesktop
      ? {
          marginTop: context?.theme.metrics.spacing.xhuge
        }
      : {
          marginTop: context?.windowWidth ? context.windowWidth * 0.3 : 0
        },
    style?.container
  ],
  containerHeader: [
    {
      alignItems: 'center',
      maxWidth: context?.theme.metrics.contentSizes.centeredContentMaxWidth,
      alignSelf: 'center'
    },
    context?.isMobile
      ? {
          marginBottom: context?.theme.metrics.spacing.s
        }
      : {
          marginBottom: context?.theme.metrics.spacing.xxl
        },
    style?.containerHeader
  ],
  titleStyle: [
    {
      textAlign: 'center'
    },
    context?.isDesktop && {
      marginBottom: context?.theme.metrics.spacing.l
    },
    style?.titleStyle
  ],
  paragraphStyle: [
    {
      textAlign: 'center'
    }
  ],
  containerCard: [
    {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'row',
      flexWrap: 'wrap'
    },
    style?.container
  ],
  imageCard: [
    {
      marginLeft: context?.theme.metrics.spacing.s,
      marginRight: context?.theme.metrics.spacing.s,
      zIndex: 0
    },
    context?.isMobile
      ? {
          marginBottom: context?.theme.metrics.spacing.xm,
          flex: 1,
          minWidth: 300,
          maxWidth: 420
        }
      : {
          marginBottom: context?.theme.metrics.spacing.xxl
        },
    style?.imageCard
  ],

  imageCardSelfcareLetterLanding: [
    {
      marginLeft: context?.theme.metrics.spacing.s,
      marginRight: context?.theme.metrics.spacing.s,
      marginBottom: context?.theme.metrics.spacing.xs
    },
    style?.imageCard
  ],
  textWhiteStyle: [
    {
      color: context?.theme.colors.basics.white
    }
  ],
  selfcareBottomContainer: [
    {
      alignItems: 'center',
      marginTop: context?.theme.metrics.spacing.s
    }
  ],
  smallLoginTitle: [
    {
      alignItems: 'center',
      marginBottom: context?.theme.metrics.spacing.m
    },
    context &&
      !context.isDesktop && {
        color: context.theme.colors.basics.white
      }
  ],
  smallLoginButtonContainer: [
    {
      marginBottom: context?.theme.metrics.spacing.m
    }
  ],
  bottomLinksContainer: [
    {
      alignItems: 'center'
    }
  ],
  helpLinkStyle: [
    {
      textAlign: 'center'
    }
  ],
  rightImageSticker: [
    {
      position: 'absolute',
      height: 290,
      width: 170,
      bottom: -112,
      left: 193
    }
  ],
  leftImageStickerBody: [
    {
      position: 'absolute',
      right: 300,
      height: 216,
      width: 137,
      top: 9
    }
  ],
  leftImageStickerHand: [
    {
      position: 'absolute',
      right: 315,
      width: 30,
      top: 55,
      zIndex: 1,
      height: 20
    }
  ],
  modalContainer: [
    {
      width: '100%',
      paddingTop: context?.theme.metrics.spacing.l,
      minHeight: 400
    }
  ],
  modalTextContainerStyle: [
    {
      marginVertical: context?.theme.metrics.spacing.s
    }
  ],
  modalTextStyle: [
    {
      marginVertical: context?.theme.metrics.spacing.m
    }
  ],
  appVersionStyle: [
    {
      textAlign: 'right',

      color: context?.theme.colors.basics.white,
      marginVertical: context?.theme.metrics.spacing.s,
      marginRight: context?.theme.metrics.spacing.s,
      opacity: 0.5
    }
  ]
})

export const AuthenticationFormStyles = (context?: Context, style?: Styles): Styles => ({
  container: {
    paddingHorizontal: context?.theme.metrics.spacing.m,
    alignContent: 'space-between'
  },
  inputsStyle: {
    borderRadius: 5
  },
  inputLabelStyle: {
    color: context?.theme.colors.basics.black,
    opacity: 0.6,
    zIndex: 1
  },
  loginMemorizationContainer: {
    flexDirection: 'row',
    flex: 1
  },
  loginMemorizationTextContainer: {
    justifyContent: 'center'
  },
  loginMemorizationText: {
    color: 'white'
  },
  forgottenPassword: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  },
  forgottenPasswordText: {
    color: 'white',
    textDecorationLine: 'underline'
  },
  forgottenPasswordContainer: {
    alignSelf: 'center',
    marginBottom: context?.theme.metrics.spacing.xm
  },
  linkStyle: {
    marginTop: '50%',
    textAlign: 'center',
    color: context?.theme.colors.basics.white
  },
  linksStyle: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center'
  },
  inputStyle: {
    color: 'white',
    borderColor: 'transparent',
    backgroundColor: context?.theme.colors.basics.blackTransparent.c300,
    marginBottom: context?.theme.metrics.spacing.xm
  },
  errorTextStyle: {
    color: 'white'
  },
  errorTextContainerStyle: {
    padding: context?.theme.metrics.spacing.none,
    marginBottom: context?.theme.metrics.spacing.xm
  },
  iconContainerStyle: {
    width: context?.theme.metrics.spacing.xbig,
    height: context?.theme.metrics.spacing.xbig,
    borderRadius: context?.theme.metrics.spacing.xs,
    alignSelf: 'center',
    marginBottom: context?.theme.metrics.spacing.xm,
    backgroundColor: context?.theme.colors.basics.greenTransparent.c100,
    alignItems: 'center',
    justifyContent: 'center'
  },
  authBiometricPopinTitle: {
    marginBottom: context?.theme.metrics.spacing.s,
    textAlign: 'center'
  },
  authBiometricPopinSubTitle: {
    marginBottom: context?.theme.metrics.spacing.l,
    textAlign: 'center'
  },
  biometricLoaderContainer: {
    justifyContent: 'center',
    paddingVertical: context?.theme.metrics.spacing.xl,
    paddingHorizontal: context?.theme.metrics.spacing.xl,
    width: context?.theme.metrics.spacing.xhuge,
    height: context?.theme.metrics.spacing.xhuge,
    backgroundColor: context?.theme.colors.basics.white,
    borderRadius: context?.theme.metrics.spacing.s
  },
  bimetricModalOverlayStyle: {
    justifyContent: 'center'
  },
  biometricErrorModalContentWrapper: {
    alignItems: 'center'
  },
  biometricErrorModalContainer: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: context?.theme.colors.background.poppinFilter.c500
  },
  biometricErrorModalTitle: {
    marginTop: context?.theme.metrics.spacing.m,
    marginBottom: context?.theme.metrics.spacing.s
  },
  biometricErrorModalLabel: {
    marginBottom: context?.theme.metrics.spacing.l
  }
})
