import axios from 'axios'

import { API_URL } from '@config/index'
import { ArbitrationParamsTypes } from '@constants/arbitrations'
import { OadReallocationsParams } from '@modules/arbitration/selectors'
import {
  FetchCreateArbitrationAnswerData,
  FetchCreateArbitrationExpectedData,
  GetRiskProfileParams,
  OadAllocationsState,
  ReallocationChoiceType,
  RiskProfileData,
  SubmitedDivestedManagement
} from '@modules/arbitration/types'
import { OperationDecisionState } from '@modules/common/types'
/**
 *
 * @param companyId
 * @returns
 *
 * @mock
 * import { mockArbitration } from '@__mocks__/mockArbitration';
 *
 * return new Promise((resolve) => {
 *   return resolve({ data: mockArbitration })
 * })
 */

interface FetchArbitrationQuestions {
  data: OperationDecisionState & ReallocationChoiceType
}
export async function fetchArbitrationQuestions(
  companyId: string
): Promise<FetchArbitrationQuestions> {
  // comment to use mock
  return axios.get(`${API_URL}/api/v1/companies/${companyId}/plans?operationType=Reallocations`)

  // uncomment to use mock
  //   return new Promise(resolve => {
  //     return resolve({ data: mockArbitration })
  //   })
}

/**
 *
 * @param companyId
 * @param planId
 * @param reallocationType
 * @returns
 *
 * @mock
 * import { mockDispositifDetails } from '@__mocks__/mockDispositifDetails';
 *
 * return new Promise((resolve) => {
 *    return resolve({ data: mockDispositifDetails })
 * })
 */
export async function fetchDispositifDetails(
  companyId: string,
  planId: string,
  reallocationType: ArbitrationParamsTypes
): Promise<any> {
  // comment to use mock
  return axios.get(
    `${API_URL}/api/v1/companies/${companyId}/plans/${planId}?operationType=${reallocationType}`
  )

  // uncomment to use mock
  //  return new Promise(resolve => {
  //    return resolve({ data: mockPlanCompartmentsRepartition })
  //  })
}

export async function fetchReallocationDivestment(
  companyId: string,
  planId: string | undefined,
  investPayloadParams: SubmitedDivestedManagement
): Promise<any> {
  return axios.post(
    `${API_URL}/api/v1/companies/${companyId}/plans/${planId}/reallocationsinvestment`,
    {
      ...investPayloadParams
    }
  )
  // return new Promise(resolve => {
  //   return resolve({ data: mockFlowDivestmentCompartments })
  // })
}

export async function fetchCreateArbitration(
  params: FetchCreateArbitrationExpectedData | undefined
): Promise<FetchCreateArbitrationAnswerData> {
  return axios.post(
    `${API_URL}/api/v1/demands/companies/${params?.companyId}/plans/${params?.planId}/reallocations`,
    params?.body
  )
}

export async function fetchOadAllocations(
  profile: string,
  horizon: string
): Promise<OadAllocationsState> {
  return axios.get(
    `${API_URL}/api/v1/application/portofolios/profiles/${profile}/horizons/${horizon}`
  )
}

export async function fetchOadAllocationsDemandSubmit(
  oadReallocations: OadReallocationsParams
): Promise<FetchCreateArbitrationAnswerData> {
  return axios.post(
    `${API_URL}/api/v1/demands/companies/${oadReallocations.companyId}/plans/${oadReallocations.planId}/oadreallocations`,
    { ...oadReallocations.data }
  )
}

export async function fetchRiskProfileData({
  data,
  planId,
  companyId
}: {
  data: GetRiskProfileParams;
  planId: string;
  companyId: string;
}): Promise<RiskProfileData> {
  return axios.post(
    `${API_URL}/api/v1/demands/companies/${companyId}/plans/${planId}/oadreallocations/calcssriprofil`,
    data
  );
}
