import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import { RouteNames } from '@constants/navigation';
import { IconEnum } from '@ere-uilib/enums';
import { useTranslation } from '@ere-uilib/translations';
import { clearBankData } from '@modules/bank-details/actions/actionsCreators';
import { PopinContentTypeEnum } from '@modules/bank-details/types';
import { RootNavigation } from '@navigation/RootNavigation';

import { useStyles } from './useStyles';

const iconHeight = 138;
const iconWidth = 240;

export const useAdaptedContent = (quickSignSearchParam: PopinContentTypeEnum | undefined, shouldModalDisplay: boolean) => {
  const { formatMessage, getMessageRaw } = useTranslation();
  const [shouldDisplay, setShouldDisplay] = React.useState(false);
  const styles = useStyles({ iconWidth, iconHeight });
  const navigation = useNavigation();
  const dispatch = useDispatch()
  const isSuccess = PopinContentTypeEnum.SUCCESS === quickSignSearchParam;

  React.useEffect(() => { setShouldDisplay(shouldModalDisplay) }, [shouldModalDisplay])

  const handleQuit = () => {
    dispatch(clearBankData())
    setShouldDisplay(false);
    return navigation.navigate(RouteNames.BottomTabNavigator, {
      screen:
        RouteNames.Home
    })
  }

  const handleGoToSummaryCBPage = () => {
    dispatch(clearBankData())
    setShouldDisplay(false);
    return RootNavigation.replace(RouteNames.BankDetailsStack, {
      screen:
        RouteNames.BankDetailsSummary
    })
  }

  const errorPopinActions = {
    cancelButtonTitle: formatMessage({ id: 'CoordonneesBancairesPopinEchecQuitterBouton' }),
    buttonTitle: formatMessage({ id: 'CoordonneesBancairesPopinEchecRevenirCBancairesBouton' }),
    onCancel: handleQuit,
    onValidate: handleGoToSummaryCBPage,
  }

  const successPopinActions = {
    buttonTitle: formatMessage({ id: 'CoordonneesBancairesPopinConfirmRevenirBouton' }),
    onValidate: handleGoToSummaryCBPage,
  }

  const iconProps = {
    ...quickSignSearchParam && [PopinContentTypeEnum.SUCCESS,
    PopinContentTypeEnum.UNKNOWN].includes(quickSignSearchParam) ? {
      iconHeight: 138,
      iconWidth: 240,
      iconStyle: styles.iconStyle,
      iconName: isSuccess ? IconEnum.BANK_DETAILS_SUCCESS : IconEnum.BANK_DETAILS_ERROR,
    } : {
      iconHeight: 60,
      iconWidth: 60,
      iconName: IconEnum.WARNING_WITH_BG,
    }
  }

  const content = React.useMemo(() => ({
    [PopinContentTypeEnum.SUCCESS]: {
      title: formatMessage({ id: 'CoordonneesBancairesPopinConfirmTitre' }),
      description: getMessageRaw({ id: 'CoordonneesBancairesPopinConfirmDescription' }),
    },
    [PopinContentTypeEnum.MANY_WRONG_OTP]: {
      title: formatMessage({ id: 'CoordonneesBancairesMandatQuickSignErreurToomanyTitre' }),
      description: getMessageRaw({ id: 'CoordonneesBancairesMandatQuickSignErreurToomanyTexte' }),
    },
    [PopinContentTypeEnum.MISSING_SIGNATURE_FAILED]: {
      title: formatMessage({ id: 'CoordonneesBancairesMandatQuickSignErreurTimeOutTitre' }),
      description: getMessageRaw({ id: 'CoordonneesBancairesMandatQuickSignErreurTimeOutTexte' }),
    },
    [PopinContentTypeEnum.UNKNOWN]: {
      title: formatMessage({ id: 'CoordonneesBancairesMandatQuickSignErreurAutreTitre' }),
      description: getMessageRaw({ id: 'CoordonneesBancairesMandatQuickSignErreurAutreTexte' }),
    },
  }), [formatMessage, getMessageRaw])

  return {
    content: content[quickSignSearchParam || PopinContentTypeEnum.UNKNOWN ],
    iconProps,
    popinActions: isSuccess ?
      successPopinActions : errorPopinActions,
    styles,
    shouldDisplay,
  }
}