import { useMemo } from 'react'
import { StyleSheet } from 'react-native'

import { useTheme } from '@ere-uilib/styles'
import { colors } from '@ere-uilib/styles/theme/themeFolder'

const KNOB_SIZE = 16

export const useStyles = ({
  toggleOnColor = colors.buttons.classic.c500,
  disabled = false,
  hovered = false,
  pressed = false
}) => {
  const theme = useTheme()

  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center'
        },
        toggle: {
          width: 35,
          height: 20,
          borderRadius: 10,
          borderColor:
            (disabled && theme.colors.basics.grey.c200) ||
            (hovered && theme.colors.basics.grey.c400) ||
            theme.colors.basics.grey.c300,
          paddingHorizontal: 2,
          paddingVertical: 1,
          borderWidth: 1,
          backgroundColor: theme.colors.basics.white,
          opacity: pressed ? 0.5 : 1
        },
        toggleOn: {
          borderColor:
            (disabled && theme.colors.basics.grey.c200) ||
            (hovered && theme.colors.buttons.classicHover.c500) ||
            theme.colors.buttons.classic.c500,
          backgroundColor:
            (disabled && theme.colors.basics.grey.c200) ||
            (hovered && theme.colors.buttons.classicHover.c500) ||
            toggleOnColor
        },
        toggleLoading: {
          width: 22
        },
        knob: {
          width: KNOB_SIZE,
          height: KNOB_SIZE,
          borderRadius: KNOB_SIZE * 0.5,
          shadowColor: theme.colors.basics.grey.c900,
          backgroundColor: theme.colors.basics.grey.c300,
          ...(!disabled && !hovered && {
            shadowOffset: {
              width: 0,
              height: 5
            },
            shadowOpacity: 0.1,
            shadowRadius: 5.0,
            elevation: 5,
          })
        },
        knobOn: {
          backgroundColor:
            (disabled && theme.colors.basics.grey.c200) ||
            (hovered && theme.colors.basics.grey.c400) ||
            theme.colors.basics.white
        },
        label: {
          fontSize: theme.fonts.fontSize.paragraph.t3,
          marginLeft: theme.metrics.spacing.s,
          ...(disabled && {
            color: theme.colors.basics.grey.c200
          })
        },
      }),
    [disabled, hovered, pressed, theme, toggleOnColor]
  )

  return styles
}
