import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useScreenSizes, useTheme } from '@ere-uilib/styles';

export function useStyles() {
  const theme = useTheme();
  const { isDesktop, isMobile, isTablet } = useScreenSizes();

  const styles = useMemo(() => StyleSheet.create({
    container: {
      ...(!isDesktop && {
        alignSelf: 'center',
        width: '100%',
        ...(isTablet && {
          maxWidth: 450,
          marginTop: theme.metrics.spacing.xm,
        }),
        ...(isMobile && {
          maxWidth: 350,
          marginTop: theme.metrics.spacing.s,
        }),
      }),
    },
    inputLabel: {
      marginBottom: theme.metrics.spacing.xm,
    },
    identifierInput: {
      width: 350,
    },
    identifierHelp: {
      alignSelf: 'flex-end',
    },
    dateOfBirthInputLabelContainer: {
      marginTop: theme.metrics.spacing.l,
    },
    errorNotification: {
      marginTop: theme.metrics.spacing.l,
    },
    bottomActionsButtonsContainer: {
      width: '100%',
      flexDirection: 'row',
    },
    simpleButton: {
      marginLeft: theme.metrics.spacing.m,
      flex: 1,
    },
    bottomButtons: {
      marginTop: theme.metrics.spacing.l,
    },
    textInputLabel: { maxWidth: isDesktop ? 430 : undefined },
  }), [theme, isDesktop, isMobile, isTablet]);

  return styles;
}
