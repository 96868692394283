import { InstallmentSynthesisPageContext, InstallmentSynthesisPageStyle } from './interfaces';

export const getStyles = (
  context?: InstallmentSynthesisPageContext,
  style?: InstallmentSynthesisPageStyle,
): InstallmentSynthesisPageStyle => ({
  containerStyle: [
    (context?.isMobile || context?.isTablet) && {
    },
    style?.containerStyle,
  ],
  installmentsStepperContainerStyle: [{
    alignItems: 'center',
    justifyContent: 'center',
  },
  style?.installmentsStepperContainerStyle,
  ],
  stepperStyle: [
    {
      paddingBottom: context?.theme.metrics.spacing.s,
      marginTop: context && -context?.theme.metrics.spacing.s,
    },
    style?.stepperStyle,
  ],
  bottomActionsStyle: [
    {
      width: '100%',
    },
    !(context?.isMobile || context?.isTablet) && {
      marginTop: context?.theme.metrics.spacing.xl,
    },
    style?.bottomActionsStyle,
  ],
  bottomActionsErrorStyle: [
    {
      marginBottom: context?.theme.metrics.spacing.s,
    },
    style?.bottomActionsErrorStyle,
  ],
  bottomActionsButtonsContainerStyle: [
    {
      width: '100%',
      flexDirection: 'row',
    },
    style?.bottomActionsButtonsContainerStyle,
  ],
  arrowButtonStyle: [
    !(context?.isMobile || context?.isTablet) && {
      flex: 1,
    },
    style?.arrowButtonStyle,
  ],
  simpleButtonStyle: [{
    marginLeft: context?.theme.metrics.spacing.m,
    flex: 1,
  },
  style?.simpleButtonStyle,
  ],
  subHeaderContainerStyle: [{
    marginBottom: context?.theme.metrics.spacing.xm,
  },
  style?.subHeaderContainerStyle,
  ],
  advantageCardsContainer: [
    {
      flexDirection: 'row',
      paddingHorizontal: context?.theme?.metrics.spacing.xs,
    },
    style?.advantageCardsContainer,
  ],
  advantageCard: [
    {
      marginHorizontal: context?.theme.metrics.spacing.xs,
      marginVertical: context?.theme.metrics.spacing.xm,
    },
    style?.advantageCard,
  ],
  filetText: [{
    marginBottom: context?.theme.metrics.spacing.xm,
  },
  style?.filetText,
  ],
  straightLineStyle: [{},
    style?.straightLineStyle,
  ],
  fromContent: [
    {
      marginLeft: context?.theme.metrics.spacing.xm,
      width: '100%',
      marginBottom: context?.theme.metrics.spacing.xl,
    },
    style?.fromContent,
  ],
  bankAccountHeader: [
    {
      backgroundColor: context?.theme.colors.basics.white,
      borderBottomColor: context?.theme.colors.basics.grey.c200,
      borderBottomWidth: 1,
    },
    style?.bankAccountHeader,
  ],
  paymentModeHeader: [
    {
      backgroundColor: context?.theme.colors.basics.white,
    },
    style?.paymentModeHeader,
  ],
  bankAccountContainer: [{
    marginLeft: context?.theme.metrics.spacing.s,
    marginTop: context?.theme.metrics.spacing.xm,
    marginBottom: context?.theme.metrics.spacing.xm,
  },
  style?.bankAccountContainer,
  ],
  toContent: [{
    marginLeft: context?.theme.metrics.spacing.xm,
  },
  style?.toContent,
  ],
  filetContainer: [{
    flex: 1,
    marginRight: context?.theme.metrics.spacing.xm,
  }],
});

