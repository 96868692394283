import React from "react";
import { StyleProp, View, ViewStyle } from "react-native";

import { LoaderPlaceholderView } from "@ere-uilib/atoms";
import { CircleLoader } from "@ere-uilib/atoms/loaders/Circle/Circle";
import {
  useTheme,
  createUseStyles,
  ThemeType,
  useScreenSizes,
} from "@ere-uilib/styles";

interface PlaceholderLoaderProps { }

const PlaceholderLoader: React.FC<PlaceholderLoaderProps> = () => {
  const theme = useTheme();
  const { isMobile, isTablet } = useScreenSizes();
  const styles = useStyles(
    {
      theme,
      isMobile,
      isTablet,
    },
    {}
  );
  return (
    <View style={styles.containerStyle}>
      <LoaderPlaceholderView style={styles.loaderViewStyle} />
      {Array.from({ length: 2 }, (_, i) => {
        return (
          <View key={i} style={styles.placeholderContainer}>
            <CircleLoader diameter={36} />
            <LoaderPlaceholderView style={styles.innerPlaceholderView} />
          </View>
        );
      })}
    </View>
  );
};
type PlaceholderStyles = {
  containerStyle?: StyleProp<ViewStyle>;
  loaderViewStyle?: StyleProp<ViewStyle>;
  placeholderContainer?: StyleProp<ViewStyle>;
  innerPlaceholderView?: StyleProp<ViewStyle>;
};

type PlaceholderStylesContext = {
  theme: ThemeType;
  isMobile: boolean;
  isTablet: boolean;
};
const getStyles = (
  context?: PlaceholderStylesContext,
  style?: PlaceholderStyles
): PlaceholderStyles => ({
  containerStyle: [
    {
      borderBottomColor: context?.theme.colors.basics.grey.c200,
      borderBottomWidth: 1,
      paddingVertical: context?.theme.metrics.spacing.xm,
      marginHorizontal: !(context?.isMobile || context?.isTablet)
        ? context?.theme.metrics.spacing.xhuge
        : context?.theme.metrics.spacing.s,
    },
    style?.containerStyle,
  ],
  loaderViewStyle: [
    {
      width: 262,
      marginVertical: context?.theme.metrics.spacing.xm,
    },
    style?.loaderViewStyle,
  ],
  placeholderContainer: [
    {
      flexDirection: "row",
      alignItems: "center",
      paddingVertical: context?.theme.metrics.spacing.xs,
    },
    style?.placeholderContainer,
  ],
  innerPlaceholderView: [
    { flex: 1, marginLeft: context?.theme.metrics.spacing.xm },
    style?.innerPlaceholderView,
  ],
});

const useStyles = createUseStyles(getStyles);
export default PlaceholderLoader;
