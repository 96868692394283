import { connect } from 'react-redux';

import { logoutCallbackRequest } from '@modules/auth/actions/authActions';
import { getApplicationSettingsRequest } from '@modules/settings/actions/settingsActions';
import { InitAllErrors } from '@modules/error/actions/errorActions';
import { AppState } from '@modules/index';
import { getUser } from '@modules/auth/selectors';
import { getApplicationSettings } from '@modules/settings/selectors';

import { AppHeadContainerComponent } from './AppHeadContainer.component';

const mapStateToProps = (state: AppState) => {
  return {
    user: getUser(state),
    appSettings: getApplicationSettings(state),
  };
};

const mapDispatchToProps = {
  clearErrors: InitAllErrors,
  onLogoutCallbackRequest: logoutCallbackRequest,
  onGetApplicationSettingsRequest: getApplicationSettingsRequest,
};

export const AppHeadContainer = connect(mapStateToProps, mapDispatchToProps)(AppHeadContainerComponent);
