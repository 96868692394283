import {
  ProfitSharingIncentiveLegalFrameworkEnum,
  ProfitSharingIncentiveTypeEnum
} from '@constants/ProfitSharingIncentive';

import { label as common } from '../label';
import { LegalFrameworkByType } from '../types';
import {
  PiIncentiveStepsComplementary,
  PiIncentiveStepsExceptional,
  PiIncentiveStepsNormal,
  PiIncentiveStepsPpv,
  PiProfitSharingStepsComplementary,
  PiProfitSharingStepsExceptional,
  PiProfitSharingStepsNormal
} from '../constants';

type Label = typeof common & {
  title: string;
  steps: LegalFrameworkByType<string[]>;
  button: {
    next: string;
  }
};

export const label: Label = {
  ...common,
  title: 'PIMonInteressementEtapesEtapesSuivreLabel', // TODO: @arnaud d'après le nom, ce label est dédié à l'intéressement
  steps: {
    [ProfitSharingIncentiveTypeEnum.incentive]: {
      default: PiIncentiveStepsNormal,
      [ProfitSharingIncentiveLegalFrameworkEnum.normal]: PiIncentiveStepsNormal,
      [ProfitSharingIncentiveLegalFrameworkEnum.complementary]: PiIncentiveStepsComplementary,
      [ProfitSharingIncentiveLegalFrameworkEnum.exceptional]: PiIncentiveStepsExceptional,
      [ProfitSharingIncentiveLegalFrameworkEnum.ppv]: PiIncentiveStepsPpv
    },
    [ProfitSharingIncentiveTypeEnum.profitsharing]: {
      default: PiProfitSharingStepsNormal,
      [ProfitSharingIncentiveLegalFrameworkEnum.normal]: PiProfitSharingStepsNormal,
      [ProfitSharingIncentiveLegalFrameworkEnum.complementary]: PiProfitSharingStepsComplementary,
      [ProfitSharingIncentiveLegalFrameworkEnum.exceptional]: PiProfitSharingStepsExceptional
    }
  },
  button: {
    next: 'PIMonInteressementEtapesCommencerOperationBouton'
  }
};
