import {
  OperationTypeModalConnected
} from "@components/index";
import { createUseStyles, useScreenSizes, useTheme } from '@ere-uilib/styles'
import React, {useEffect} from "react";
import {View} from "react-native";
import {Props} from "./interfaces";
import {getStyles} from "./styles";



export const OperationsModalPageComponent: React.FC<React.PropsWithChildren<Props>> = ({
  route,
  getOperationModalList,
  operationModalList,
  navigation,
  isLoadingOperationModalList,
}) => {
  const theme = useTheme();
  const { isMobile } = useScreenSizes();
  const useStyles = createUseStyles(getStyles);
  const styles = useStyles({ theme, isMobile }, {});
  const operationType: string = route?.params?.type;
  const closeModal = () => {
    navigation.goBack();
  };

  useEffect(() => {
    getOperationModalList(operationType);
  }, []);

  return (
    <View style={styles.container}>
      <OperationTypeModalConnected
        isLoadingOperationModalList={isLoadingOperationModalList}
        onClose={closeModal}
        operationModalList={operationModalList}
        operationType={operationType}
      />
    </View>
  );
};