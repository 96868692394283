import { useMemo } from 'react'

import { useTranslation } from '@components/ERE-UILib'

export const useLabels = () => {
  const { formatMessage } = useTranslation()

  const labels = useMemo(
    () => ({
      headerTitle: formatMessage({ id: 'DocCenterDocMyEReleveSubscriptionTitle' }),
      switchLabel: formatMessage({ id: 'DocCenterDocMyEReleveSubscriptionSubTitle' }),
      unsubscriptionTitle: formatMessage({
        id: 'DocCenterDocMyEReleveSubscriptionStatusDeactivatedConfirmationMessage'
      }),
      unsubscriptionDescription: formatMessage({
        id: 'DocCenterDocMyEReleveSubscriptionStatusDeactivatedConfirmation MessageWarning'
      }),
      unsubscriptionButton: formatMessage({
        id: 'DocCenterDocMyEReleveSubscriptionStatusDeactivatedConfirmationButton'
      }),
      goToEDocumentsFiles: formatMessage({
        id: 'DocCenterDocMyEReleveSubscriptionConsulMyEreleveButton'
      }),
      getSubscriptionStatus: (isSubscriber: boolean) =>
        formatMessage({
          id: isSubscriber
            ? 'DocCenterDocMyEReleveSubscriptionStatusActivated'
            : 'DocCenterDocMyEReleveSubscriptionStatusDeactivated'
        })
    }),
    [formatMessage]
  )

  return labels
}
