import {FC} from "react";
import BankSummaryProvider from "@pages/BankDetails/BankSummaryPage/BankSummaryProvider";
import {BankSummaryPageComponent} from "@pages/BankDetails/BankSummaryPage/BankSummaryPage.component";

 const BankSummaryPageContainer:FC = ()=>
     <BankSummaryProvider >
           <BankSummaryPageComponent/>
    </BankSummaryProvider>;

export default BankSummaryPageContainer;
