import { REACT_APP_ENV } from '@config/index';

import { EnvironnementNameEnum } from './env';

interface LanguageListItem {
  label: string
  value: string
  imageName: string
  displayName: string
}

const isNotProdEnv = [
  EnvironnementNameEnum.DEV as string,
  EnvironnementNameEnum.INT as string,
  EnvironnementNameEnum.UAT as string,
  EnvironnementNameEnum.INT_DEMO as string,
  EnvironnementNameEnum.UAT_DEMO as string,
  EnvironnementNameEnum.UAT_TEMP as string,
  EnvironnementNameEnum.DEV_CLOUD as string,
  EnvironnementNameEnum.INT_CLOUD as string,
  EnvironnementNameEnum.UAT_CLOUD as string,
  EnvironnementNameEnum.INT_DEMO_CLOUD as string,
  EnvironnementNameEnum.UAT_DEMO_CLOUD as string,
].includes(REACT_APP_ENV);

const techLanguageListItem: LanguageListItem = {
  label: 'TECH',
  value: 'tech',
  imageName: 'espagne', // todo ask for the right logo to display
  displayName: 'TECH',
};

export const languageList: LanguageListItem[] = [
  {
    label: 'FR',
    value: 'fr',
    imageName: 'france',
    displayName: 'Français',
  },
  {
    label: 'EN',
    value: 'en',
    imageName: 'angleterre',
    displayName: 'english',
  },
  {
    label: 'ZH',
    value: 'zh',
    imageName: 'chine',
    displayName: '中文',
  },
  {
    label: 'AR',
    value: 'ar',
    imageName: 'arabie-saoudite',
    displayName: 'العربية',
  },
  {
    label: 'RO',
    value: 'ro',
    imageName: 'romanian',
    displayName: 'română',
  },
  {
    label: 'ES',
    value: 'es',
    imageName: 'espagne',
    displayName: 'español',
  },
  {
    label: 'PT',
    value: 'pt',
    imageName: 'portugal',
    displayName: 'português',
  },
  {
    label: 'TR',
    value: 'tr',
    imageName: 'turquie',
    displayName: 'türk',
  },
  {
    label: 'SL',
    value: 'sl',
    imageName: 'slovenie',
    displayName: 'slovenščina',
  },
  {
    label: 'DE',
    value: 'de',
    imageName: 'allemagne',
    displayName: 'deutsch',
  },
  {
    label: 'NL',
    value: 'nl',
    imageName: 'pays-bas',
    displayName: 'nederlands',
  },
  {
    label: 'IT',
    value: 'it',
    imageName: 'italie',
    displayName: 'italiano',
  },
  ...(isNotProdEnv ? [techLanguageListItem] : []),
];

interface LanguageValuesVersions {
  [key: string]: {
    fetchHeaderParameter: string
    translateModuleParameter: string
  }
}

const techLanguageValuesVersions: LanguageValuesVersions = {
  tech: {
    fetchHeaderParameter: 'TECH',
    translateModuleParameter: 'fr',
  },
};

export const languageValuesVersions: LanguageValuesVersions = {
  fr: {
    fetchHeaderParameter: 'fr-FR',
    translateModuleParameter: 'fr',
  },
  en: {
    fetchHeaderParameter: 'en-US',
    translateModuleParameter: 'en',
  },
  zh: {
    fetchHeaderParameter: 'zh-CN',
    translateModuleParameter: 'zh',
  },
  ar: {
    fetchHeaderParameter: 'ar-MA',
    translateModuleParameter: 'ar',
  },
  ro: {
    fetchHeaderParameter: 'ro-RO',
    translateModuleParameter: 'ro',
  },
  es: {
    fetchHeaderParameter: 'es-ES',
    translateModuleParameter: 'es',
  },
  pt: {
    fetchHeaderParameter: 'pt-PT',
    translateModuleParameter: 'pt',
  },
  tr: {
    fetchHeaderParameter: 'tr-TR',
    translateModuleParameter: 'tr',
  },
  sl: {
    fetchHeaderParameter: 'sl-SL',
    translateModuleParameter: 'sl',
  },
  de: {
    fetchHeaderParameter: 'de-DE',
    translateModuleParameter: 'de',
  },
  nl: {
    fetchHeaderParameter: 'nl-NL',
    translateModuleParameter: 'nl',
  },
  it: {
    fetchHeaderParameter: 'it-IT',
    translateModuleParameter: 'it',
  },
  ...(isNotProdEnv && techLanguageValuesVersions),
};
