import { theme } from '@ere-uilib/theme';
import {
  SavingsEvolutionChartStyles,
  StylesContext,
} from './interfaces';

export const getStyles = (
  context?: StylesContext,
  style?: SavingsEvolutionChartStyles,
): SavingsEvolutionChartStyles => ({
  containerStyle: [
    {
      display: 'flex',
      position: 'relative',
      alignSelf: 'flex-start',
      width: '100%',
    },
    (context?.isMobile || context?.isTablet) && {
      marginHorizontal: -context?.theme.metrics.spacing.s,
    },
    style?.containerStyle,
  ],
  tooltipTitleLineStyle: [
    {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: context?.theme.metrics.spacing.xs,
    },
    style?.tooltipTitleLineStyle,
  ],
  tooltipTotalLineStyle: [
    {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: context?.theme.metrics.spacing.s,
    },
    style?.tooltipTotalLineStyle,
  ],
  tooltipTotalOperationsLineStyle: [
    {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: context?.theme.metrics.spacing.xs,
    },
    style?.tooltipTotalLineStyle,
  ],
  tooltipOperationsLineStyle: [
    {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: context?.theme.metrics.spacing.xxs,
    },
    style?.tooltipOperationsLineStyle,
  ],
  tooltipColorDotStyle: [
    {
      width: 8,
      height: 8,
      borderRadius: 4,
      marginRight: context?.theme.metrics.spacing.xs,
    },
    style?.tooltipColorDotStyle,
  ],
  tooltipText: {
    lineHeight: context?.theme.fonts.fontSize.paragraph.t3,
  }
});
