import React, { useMemo } from 'react';

import { useTheme } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';

export const useRepartitionLegend = () => {
  const { formatMessage } = useTranslation();
  const theme = useTheme();

  const repartitionLegend = useMemo(() => ([
    {
      color: theme.colors.oadChart.monetary,
      title: formatMessage({ id: 'AbritrageOADPageSyntheseRegroupementMonetaire' }),
    },
    {
      color: theme.colors.oadChart.obligatory,
      title: formatMessage({ id: 'AbritrageOADPageSyntheseRegroupementObligataire' }),
    },
    {
      color: theme.colors.oadChart.diversified,
      title: formatMessage({ id: 'AbritrageOADPageSyntheseRegroupementDiversifié' }),
    },
    {
      color: theme.colors.oadChart.action,
      title: formatMessage({ id: 'AbritrageOADPageSyntheseRegroupementActions' }),
    },
  ]), [formatMessage, theme]);

  return repartitionLegend;
};