import { Context, Style } from './interfaces';

export const getStyles = (
  context?: Context,
  style?: Style,
): Style => ({
  descriptionContainer: [
    {
      marginTop: context?.theme.metrics.spacing.l,
    },
    style?.descriptionContainer,
  ],
  descriptionTitle: [{
    fontWeight: '700',
    lineHeight: 16.8,
    marginBottom: context?.theme.metrics.spacing.xs,
    fontFamily: context?.theme.fonts.fontFamily.regular,
  },
  style?.descriptionTitle,
  ],
  descriptionSubTitle: [{
    fontWeight: '300',
    lineHeight: 16.8,
    marginBottom: context?.theme.metrics.spacing.xs,
  },
  style?.descriptionSubTitle,
  ],
});
