class ATInternetService {
  constructor() {
    this.tag = this.newTag({ secure: true });
  }

  newTag(options) {
    // ATInternetService on web is based on a cript, we need to take care of care where the script is not available
    try {
      const createdTag = new window.ATInternet.Tracker.Tag(options);
      createdTag?.privacy?.setVisitorMode?.('cnil', 'exempt');
      return createdTag;
    } catch (error) {
      return undefined;
    }
  }

  //@param info: {name: string, level2?: string, chapter1?: string, chapter2?: string, chapter3?: string, customObject?: any}
  sendPage(info) {
    this.tag?.page?.set?.(info);
    this.tag?.dispatch?.();
  }

  //@param info: {elem: any, name: string, level2?: string, chapter1?: string, chapter2?: string, chapter3?: string, type: string, customObject?: any}
  sendClick(info) {
    this.tag?.click?.send?.(info);
  }
}
export let tag = new ATInternetService();
