import {
  ProfitSharingIncentiveLegalFrameworkEnum,
  ProfitSharingIncentiveTypeEnum
} from '@constants/ProfitSharingIncentive';

import { label as common } from '../label';
import { LegalFrameworkByType } from '../types';

type Label = {
  header: {
    title: LegalFrameworkByType<string>;
    help: {
      title: string;
      content: LegalFrameworkByType<string>;
    };
  };
  prime: {
    title: string;
    subtitle: string;
    derogatoryAmount: string;
    companyAmount: string;
    mediumGross: string;
  };
  alert: {
    title: string;
    description: LegalFrameworkByType<string>;
    link: string;
  };
  button: {
    next: string;
  }
};

export const label: Label = {
  ...common,
  header: {
    ...common.header,
    help: {
      ...common.header.help,
      content: {
        [ProfitSharingIncentiveTypeEnum.profitsharing]: {
          default: 'PIAideSansReponsePI'
        },
        [ProfitSharingIncentiveTypeEnum.incentive]: {
          default: 'PIAideSansReponsePI',
          [ProfitSharingIncentiveLegalFrameworkEnum.ppv]: 'PIAideSansReponsePPV'
        }
      }
    }
  }
,  prime: {
    title: 'PIDescriptionPourPeriodeLabel',
    subtitle: 'PIDescriptionMontantNetPrimeLabel',
    derogatoryAmount: 'PIDescriptionMontantDerogatoireLabel',
    companyAmount: 'PIDescriptionTotalDistribueLabel',
    mediumGross: 'PIDescriptionMontantBrutMoyenLabel'
  },
  alert: {
    title: 'PIDescriptionCardSansReponseTitre',
    description: {
      [ProfitSharingIncentiveTypeEnum.profitsharing]: {
        default: 'PIDescriptionCardSansReponseParticipationDescription'
      },
      [ProfitSharingIncentiveTypeEnum.incentive]: {
        default: 'PIDescriptionCardSansReponseInteressementDescription',
        [ProfitSharingIncentiveLegalFrameworkEnum.ppv]: 'PIDescriptionCardSansReponsePPVDescription'
      }
    },
    link: 'PIDescriptionCardSansReponseModaliteDefautLien'
  },
  button: {
    next: 'PIDescriptionSuivantBouton'
  }
};
