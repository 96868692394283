import { useScreenSizes, useTheme } from '@ere-uilib/styles'
import { useMemo } from 'react'
import { StyleSheet } from 'react-native'

export const useStyles = () => {
  const theme = useTheme()
  const { isDesktop } = useScreenSizes()
  const styles = useMemo(
    () =>
      StyleSheet.create({
        dashboardWrapper: { backgroundColor: theme?.colors.basics.white },
        stepperContainer: {
          alignItems: 'center'
        },
        stepperStyle: {
          paddingBottom: theme.metrics.spacing.s,
          marginTop: -theme.metrics.spacing.s
        },
        oadRecapContentContainer: {
          shadowColor: theme.colors.basics.black,
          shadowOffset: {
            width: 0,
            height: 0
          },
          shadowOpacity: 0.15,
          shadowRadius: theme.metrics.borderRadius.s,
          elevation: 7,
          borderRadius: theme.metrics.borderRadius.xs,
          marginBottom: theme.metrics.spacing.xm
        },
        oadRedirectButton: {
          ...(isDesktop && {
            width: 100
          })
        },
        oadRecapContainer: {
          ...(isDesktop && {
            width: 500
          })
        }
      }),
    [theme]
  )

  return styles
}
