import { useTheme } from '@ere-uilib/styles'
import { useMemo } from 'react'
import { StyleSheet } from 'react-native'
import { palette } from '@ere-uilib/styles/theme/themeFolder/colors/palette'

export const useStyles = (isToggleActive: boolean, disabled?: boolean) => {
  const theme = useTheme()

  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          flexDirection: 'row',
          alignItems: 'center'
        },
        toggleContainer: {
          height: theme.metrics.spacing.xm,
          width: theme.metrics.spacing.xl,
          borderRadius: theme.metrics.spacing.xl,
          borderWidth: 1,
          borderColor: disabled
            ? palette.grey.c700
            : isToggleActive
            ? palette.greenDark_1.c300
            : theme.colors.basics.black,
          backgroundColor: disabled
            ? theme.colors.basics.grey.c200
            : isToggleActive
            ? palette.greenDark_1.c300
            : theme.colors.basics.white,
          flexDirection: 'row'
        },
        circleContainer: {
          height: 14,
          width: 14,
          marginTop: 2,
          borderRadius: theme.metrics.spacing.m,
          backgroundColor: disabled
            ? palette.grey.c700
            : isToggleActive
            ? theme.colors.basics.white
            : theme.colors.basics.black,
          marginHorizontal: theme.metrics.spacing.xxs
        },
        toggleText: {
          marginLeft: theme.metrics.spacing.xs
        }
      }),
    [theme, isToggleActive, disabled]
  )

  return styles
}
