import { useIsFocused } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { View } from 'react-native';

import {
  DashboardPageWrapperConnected,
  NavigationHeader,
  ColumnsContainer,
  AnnounceContent,
  useTranslation,
  NotificationHard,
  NotificationIconTypeEnum,
  SimpleButton,
} from '@components/index';
import { createUseStyles, useTheme } from '@ere-uilib/styles';

import { InstallmentLoadingPageProps } from './interfaces';
import { getStyles } from './styles';

export const InstallmentLoadingPageComponent: React.FC<React.PropsWithChildren<InstallmentLoadingPageProps>> = ({
  route,
  navigation,
  isCheckInstallmentPaymentStatusForIdApiLoading,
  onCheckInstallmentPaymentStatusForId,
  installmentError,
}) => {
  const isFocused = useIsFocused();
  const { formatMessage } = useTranslation();
  const theme = useTheme();
  const styles = useStyles({
    theme,
  }, {});

  // check route parameter for payment id
  const paymentId = route?.params?.id;
  useEffect(() => {
    if (isFocused && paymentId && !isCheckInstallmentPaymentStatusForIdApiLoading) {
      onCheckInstallmentPaymentStatusForId(paymentId);
    }
  }, [paymentId, isFocused]);

  const RenderHeader = () => {
    return (
      <NavigationHeader
        helpPoint={false}
        title={formatMessage({ id: 'VV_payment_CB_loading_title' })}
      />
    );
  };
  const RenderActions = () => {
    if (installmentError.message! == '' && !isCheckInstallmentPaymentStatusForIdApiLoading) {
      return (
        <>
          <NotificationHard
            containerStyle={styles.errorNotificationStyle}
            text={formatMessage({ id: 'VV_payment_CB_waiting_error_label' })}
            type={NotificationIconTypeEnum.WARNING}
          />
          <SimpleButton
            design="outlined"
            onPress={() => onCheckInstallmentPaymentStatusForId(paymentId)}
            title={formatMessage({ id: 'VV_payment_CB_waiting_reload_button' })}
          />
        </>
      );
    }
  };

  return (
    <DashboardPageWrapperConnected>
      <ColumnsContainer
        containerStyle={styles.containerStyle}
        hasRenderRightDesktopColumn={false}
        renderHeader={RenderHeader}
      >
        <View style={styles.contentStyle}>
          <AnnounceContent
            description={formatMessage({ id: 'VV_payment_CB_waiting_label' })}
            loading={isCheckInstallmentPaymentStatusForIdApiLoading}
            sourceName={'installmentDone'}
            title={formatMessage({ id: 'VV_payment_CB_waiting_title' })}
          >
            {RenderActions()}
          </AnnounceContent>
        </View>
      </ColumnsContainer>
    </DashboardPageWrapperConnected>
  );
};

const useStyles = createUseStyles(getStyles);
