import { useWindowDimensions } from 'react-native';

import { useTheme } from './theme';

interface ScreenSizes {
  windowWidth: number
  windowHeight: number
  isMobile: boolean
  isTablet: boolean
  isDesktop: boolean
  isPortrait: boolean
  isLandscape: boolean
}

export const useScreenSizes = ():ScreenSizes => {
  const { width, height } = useWindowDimensions();
  const theme = useTheme();
  const {
    tablet,
    desktop,
  } = theme.metrics.breakpoints;

  const windowWidth = width;
  const windowHeight = height;

  const isMobile = windowWidth < tablet;
  const isTablet =  windowWidth >= tablet && windowWidth < desktop;
  const isDesktop =  windowWidth >= desktop;

  const isPortrait = windowHeight >= windowWidth;
  const isLandscape =  !isPortrait;

  return {
    windowWidth,
    windowHeight,
    isMobile,
    isTablet,
    isDesktop,
    isPortrait,
    isLandscape
  };
};
