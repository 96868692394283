import { useState } from 'react';
import { FontIcon, Text } from '@ere-uilib/atoms';
import { useStyles } from './useStyles';
import { Pressable } from 'react-native';

interface Props {
  title: string;
  iconName: string;
  isSelected: boolean
  onSelect?: () => void;
  disabled?: boolean;
  testId?: string;
}

export const ActionButton = ({
  title,
  iconName,
  onSelect,
  disabled = false,
  isSelected,
  testId,
}: Props) => {

  const [hovered, setHovered] = useState<boolean>(false);
  const styles = useStyles(hovered, isSelected);

  return (
    <Pressable
      role="listitem"
      testID={testId}
      onHoverIn={() => setHovered(true)}
      onHoverOut={() => setHovered(false)}
      disabled={disabled}
      onPress={onSelect}
      style={[styles.containerStyle]}
    >
      <FontIcon
        style={styles.iconStyle}
        name={iconName}
      />
      <Text>
        <Text
          style={{}}
          variant={'t3'}
          weight="regular">
          {title}
        </Text>
      </Text>
    </Pressable>
  )
};
