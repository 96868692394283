import { connect } from 'react-redux';

import { AppState } from '@modules/reducers';
import { getAuthConfig } from '@modules/settings/selectors';

import { ForgetPasswordCongratsPageComponent } from './ForgetPasswordCongratsPage.component';

const mapStateToProps = (state: AppState) => {
  return {
    authConfig: getAuthConfig(state),
  };
};


export const ForgetPasswordCongratsPage = connect(
  mapStateToProps,
  null,
)(ForgetPasswordCongratsPageComponent);
