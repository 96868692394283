import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useScreenSizes, useTheme } from '@ere-uilib/styles';

export function useStyles() {
  const theme = useTheme();
  const { isDesktop, isMobile } = useScreenSizes();

  const styles = useMemo(() => StyleSheet.create({
    containerStyle: {
      backgroundColor: theme.colors.basics.white,
      marginBottom: isDesktop ? theme.metrics.spacing.xl : 0,
      maxWidth: isDesktop ? "60%" : "100%",
      marginHorizontal: "auto"
    },
    contentContainerStyle: {
      alignItems: 'center',
      paddingBottom: theme?.metrics.spacing.big,

    },
    fieldContainer: {
      alignItems: "stretch",
      marginTop: theme?.metrics.spacing.m,
    },
    descriptionStyle: {
      marginTop: theme?.metrics.spacing.m,
    },
    helpCardStyle: {
      flexDirection: 'row'
    },
    helpDescriptionStyle: {
      borderLeftWidth: 1,
      marginTop: theme?.metrics.spacing.m,
      marginRight: theme?.metrics.spacing.m,
      paddingLeft: theme?.metrics.spacing.m,
    },
    helpIconStyle: {
      marginTop: theme?.metrics.spacing.m,
      marginRight: theme?.metrics.spacing.m,
    },
    selectContainerStyle: {
      marginTop: theme?.metrics.spacing.xm,
      width: "100%"
    },
    bottomActionsStyle: {
      paddingHorizontal: isMobile ? theme?.metrics.spacing.s : 0,
      flexDirection: !isMobile ? 'row' : 'column',
      width: "100%",
      marginTop: isDesktop ? theme.metrics.spacing.xl : 0,
    },
    simpleButtonStyle: {
      marginLeft: theme.metrics.spacing.m,
      flex: isDesktop ? undefined : 1,
    },
    bottomActionsButtonsContainerStyle:
    {
      width: '100%',
      flexDirection: 'row',
    },
    buttonStyle:
    {
      width: "48%",
    },

    contentStyle:
    {
      flex: 1,
      height: '100%',
      paddingTop: theme?.metrics.spacing.l,
      paddingBottom: theme?.metrics.spacing.l,
      paddingLeft: theme?.metrics.spacing.s
    },
    firstLinesStyle: { textAlign: 'left', marginBottom: theme?.metrics.spacing.m },
    informLinkStyle: { textAlign: 'left', marginBottom: theme?.metrics.spacing.m, color: theme.colors.notifications.warning.c500 },
    underFieldLinkStyle: {
      textAlign: 'left',
    },
    lastLinesStyle: { textAlign: 'left' },
    editButtonStyle: {
      width: theme?.metrics.spacing.xxxl,
      alignSelf: 'stretch',
      paddingTop: theme?.metrics.spacing.l,
      alignItems: 'center'
    },

  }), [theme, isDesktop, isMobile]);

  return styles;
}