import React from 'react';
import { View } from 'react-native';

import { Text } from '@ere-uilib/atoms';
import { StatusTag } from '@ere-uilib/molecules';
import { useTranslation } from '@ere-uilib/translations';

import { RecentOperationsCardContentProps } from './interface';
import { useStyles } from './useStyles';

export const RecentOperationsCardContent = ({ operations }: RecentOperationsCardContentProps) => {
  const { formatDate, formatCurrencyNumber } = useTranslation();
  const styles = useStyles();
  if (operations.length === 0) return null;

  return (
    <>
      {operations.slice(0, 3).map((operation, index, array) => (
        <View
          key={'operation' + index}
          style={index < array.length - 1 && styles.operationDetails}>
          <Text
            accessibilityLabel="operation-status_date"
            style={styles.dateText}
            testID="operation-status_date"
            variant="t4">
            {formatDate({
              value: operation.dateTime
            })}
          </Text>
          {operation.amount ? (
            <>
              <Text
                style={styles.operationTitleText}
                variant="t3">
                {operation.label}
                {' : '}
                <Text
                  variant="t2"
                  weight="bold">
                  {formatCurrencyNumber({ value: operation.amount })}
                </Text>
              </Text>
            </>
          ) : (
            <Text
              style={styles.operationTitleText}
              variant="t3">
              {operation.label}
              {''}
            </Text>
          )}
          <StatusTag
            label={operation.statusLabel}
            type={operation.statusCode}
          />
        </View>
      ))}
    </>
  );
};
