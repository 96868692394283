import React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

import  './style.css';
import { ThemeType } from '@ere-uilib/styles';
import { createUseStyles } from '@ere-uilib/styles/createUseStyles';
import { useTheme } from '@ere-uilib/styles/theme';

interface DataProps{

}

type CookiesPolicyButtonStyles = {
  buttonContainerStyle?: StyleProp<ViewStyle>;
  childrenStyle?: StyleProp<ViewStyle>;
};

type CookiesPolicyButtonStylesContext = {theme: ThemeType;};

type Props = DataProps & CookiesPolicyButtonStyles

export const CookiesPolicyButton: React.FC<React.PropsWithChildren<Props>> =  ({

  buttonContainerStyle
}) => {

  const theme = useTheme();
  const styles = useStyles({
    theme
  }, {
    buttonContainerStyle
  });

  const content = `
  <!— Bouton Démarrer des paramètres OneTrust de cookies —>
  <button id="ot-sdk-btn" class="ot-sdk-show-settings">Paramètres des cookies</button>
  <!— Bouton Terminer des paramètres OneTrust de cookies —>

`;
  return (
    <View style={styles.buttonContainerStyle}>
      <div dangerouslySetInnerHTML={{ __html: content }}/>
    </View>
  );
};

const getStyles = (
  context?: CookiesPolicyButtonStylesContext,
  style?: CookiesPolicyButtonStyles,
): CookiesPolicyButtonStyles => ({
  buttonContainerStyle: [{
    paddingVertical: context?.theme.metrics.spacing.s
  }, style?.buttonContainerStyle]
});

const useStyles = createUseStyles(getStyles);
