import { AuthActionType, AuthenticateAction } from '@modules/auth/actions/authActionsTypes';
import { InvitationAction, InvitationActionsType } from './actions/invitationActionsTypes';
import { ErrorActionsType } from '@modules/error/actions/errorActionsTypes';
import { InvitationBodyState } from './types';

export const initialState: InvitationBodyState = {
  data: {
    user: {
      id: '',
      ereID: '',
      lastName: '',
      firstName: '',
      displayName: null,
      email: '',
      mobilePhone: '',
      registrationUrl: null,
    },
    registrationState: '',
    mobileConfirmationState: '',
    emailConfirmationState: '',
    isImmediateRegistration: undefined,
    immediateRegistrationOrigin: undefined,
  },
  error: {
      code: '',
      correlationId: '',
      message: '',
      innerMessage: '',
  },
  selfcareEmail: null,
};

export default function invitationReducer(
    state: InvitationBodyState = initialState,
    action: InvitationAction | AuthenticateAction,
): InvitationBodyState {
    switch (action?.type) {
    case InvitationActionsType.CREATE_SELFCARE_INVITATION_FROM_LETTER_OTP_SUCCESS:
    case InvitationActionsType.GET_INVITATION_SUCCESS:
        return {
            ...state,
            data: action.data,
        };
    case InvitationActionsType.CREATE_SELFCARE_INVITATION_FROM_LETTER_OTP_REQUEST:
        return {
            ...state,
            error: initialState.error,
            data: initialState.data,
        };
    case InvitationActionsType.CREATE_SELFCARE_INVITATION_FROM_EXTERNAL_IDENTITY_REQUEST:
        return {
            ...state,
            error: initialState.error,
            selfcareEmail: initialState.selfcareEmail,
        };
    case InvitationActionsType.CREATE_SELFCARE_INVITATION_FROM_EXTERNAL_IDENTITY_SUCCESS:
        return {
            ...state,
            selfcareEmail: action.email,
        };
    case InvitationActionsType.GET_INVITATION_FAILURE:
    case InvitationActionsType.CREATE_SELFCARE_INVITATION_FROM_EXTERNAL_IDENTITY_FAILURE:
    case InvitationActionsType.CREATE_SELFCARE_INVITATION_FROM_LETTER_OTP_FAILURE:
        return {
            ...state,
            error: action.error,
        };
    case ErrorActionsType.INIT_ALL_ERRORS:
    case InvitationActionsType.CREATE_SELFCARE_INVITATION_RESET_ERROR:
        return {
            ...state,
            error: initialState.error,
        };
    case AuthActionType.LOGOUT_CALLBACK_SUCCESS:
        return initialState;
    default:
        return state;
    }
}
