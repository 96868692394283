
import { SupportContentStyles, StylesContext } from './interface';

export const getStyles = (
  context?: StylesContext,
  style?: SupportContentStyles
): SupportContentStyles => ({
  containerStyle: [
    {
      ...(context?.isMobile ?
        {
          paddingLeft: context?.theme.metrics.spacing.s,
          paddingRight: context?.theme.metrics.spacing.s,
          paddingTop: context?.theme.metrics.spacing.m
        }
        : {
          paddingTop: context?.theme.metrics.spacing.xxl
        }
      ),
      width: '100%'
    },
    style?.containerStyle
  ],
  titleStyle: [
    {
      marginBottom: context?.theme.metrics.spacing.xbig
    },
    style?.titleStyle
  ],
  descriptionStyle: [
    {
      marginBottom: context?.theme.metrics.spacing.xs
    },
    style?.descriptionStyle
  ],
  buttonContainerStyle: [
    {
      marginTop: context?.theme.metrics.spacing.l
    },
    style?.buttonContainerStyle
  ]
});
