import React, { useEffect, useState } from 'react';

import { AmountFieldModal360 } from '@components/index';
import { useTranslation } from '@ere-uilib/translations';

import {
  ArbitrationAmountFieldModalConnectedProps,
  ModalData
} from './interfaces';
import { ArbitrationAmountFieldModalConnectedRefType } from './sharedInterfaces';

export const ArbitrationAmountFieldModalConnectedComponent = React.forwardRef<ArbitrationAmountFieldModalConnectedRefType, ArbitrationAmountFieldModalConnectedProps>((
  {
    visible,
    onSetArbitrationDesinvestAmountModalVisibleState,
    onSetArbitrationDesinvestAmount,
  },
  ref
) => {
  const defaultData:ModalData = {
    displayData: {
      title: '',
      initialAmount: undefined,
    },
    supportIsIn: undefined,
  };
  const [data, setData] = useState<ModalData>(defaultData);
  const { formatMessage } = useTranslation();
  // allow method via ref
  React.useImperativeHandle(ref, () => ({
    openWithData: receivedData => {
      onSetArbitrationDesinvestAmountModalVisibleState(true);
      setData({
        ...receivedData,
        displayData: {
          ...receivedData.displayData,
          allAmountLabel: formatMessage({ id: 'Arbitrage_modaleDesinvest_Toutdesinvest' }),
        },
      });
    },
  }));

  useEffect(() => {
    if (!visible) {
      setData(defaultData);
    }
  }, [visible]);

  const onCancelPressed = ()=>{
    onSetArbitrationDesinvestAmountModalVisibleState(false);
    setData(defaultData);
  };

  const onValidPressed = (amount?:number|null)=>{
    if (data.supportIsIn && data.compartmentId && data.managementId) {
      onSetArbitrationDesinvestAmount({
        amount: amount || 0,
        compartmentId: data.compartmentId,
        managementId: data.managementId,
        supportIsin: data.supportIsIn,
        selectState: true,
        triggerThreshold: data.triggerThreshold,
      });
      onSetArbitrationDesinvestAmountModalVisibleState(false);
    }
  };
  return (
    <AmountFieldModal360
      data={data.displayData}
      isValidButtonLoading={false}
      onCancel={onCancelPressed}
      onValid={onValidPressed}
      visible={visible}
    />
  );
});
