import { useScreenSizes, useTheme } from "@ere-uilib/styles";
import { useMemo } from "react"
import { StyleSheet } from "react-native"

export const useStyles = () => {
  const theme = useTheme();
  const {isDesktop} = useScreenSizes();

  const styles = useMemo(() => StyleSheet.create({
    container: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingHorizontal: theme.metrics.spacing.l,
      paddingTop: theme.metrics.spacing.xxl
    },
  }), [theme, isDesktop])
  return styles
}