import { useNavigation } from '@react-navigation/native';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { useTranslation } from '@components/ERE-UILib';
import { PlanFamillyList } from '@constants/common';
import { RouteNames } from '@constants/navigation';
import { AddressStatusEnum } from '@constants/profile';
import { StatusTagTypesEnum } from '@ere-uilib/molecules/tags/StatusTag/sharedInterfaces';
import { getDocumentBlobRequestByType } from '@modules/document-space/actions/documentsActions';
import { AddressItem } from '@modules/profile/types';
import { generateAddress } from '@pages/Dashboard/ProfilePage/utils';

import { CardChildInfo } from './types';

const label = {
  description1: {
    [PlanFamillyList.ES]: 'PersonalDetailsPagePostalAdressDescriptionES',
    [PlanFamillyList.RC]: 'PersonalDetailsPagePostalAdressDescriptionRC'
  },
  description2: {
    [PlanFamillyList.ES]: '',
    [PlanFamillyList.RC]: 'PersonalDetailsPagePostalAdressInformationMessageRC'
  },
  status: {
    default: 'PersonalDetailsPagePostalAdressModificationInProgressES',
    [AddressStatusEnum.IN_PROGRESS]: 'PersonalDetailsPagePostalAdressModificationInProgressES'
  }
};

export const useAddressChildInfos = (addresses: AddressItem[] | null): CardChildInfo[] => {
  const { formatMessage } = useTranslation();
  const navigation = useNavigation();

  const dispatch = useDispatch();

  const handleEditPress = useCallback(
    (type: PlanFamillyList) => () => {
      if (type === PlanFamillyList.ES)
        navigation.navigate(RouteNames.ProfileEditAddress);

      if (type === PlanFamillyList.RC)
        dispatch(
          getDocumentBlobRequestByType(
            'Coordonnees_modif_formulaire_RC',
            'Modification des coordonnées personnelles'
          )
        );
    },
    [dispatch, navigation]
  );

  const cards = useMemo(
    () =>
      (addresses || []).map(item => {
        const { address, type, status } = item ?? {};

        const { description1, description2 } = label;

        return {
          informationDescription: description1[type] && formatMessage({ id: description1[type] }),
          informationSubDescription:
            description2[type] && formatMessage({ id: description2[type] }),
          userInformation: (address && generateAddress(address)) || null,
          isEditable: type === PlanFamillyList.RC || status !== AddressStatusEnum.IN_PROGRESS,
          statusType:
            (status === AddressStatusEnum.IN_PROGRESS && StatusTagTypesEnum.IN_PROGRESS) ||
            ((status === AddressStatusEnum.INVALID || !address) && StatusTagTypesEnum.TO_INFORM) ||
            undefined,
          statusLabel: formatMessage({ id: label.status[status] || label.status.default }),
          onEditPress: handleEditPress(type)
        };
      }),
    [addresses, formatMessage, handleEditPress]
  );

  return cards;
};
