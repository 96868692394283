import { palette } from '@ere-uilib/styles/theme/themeFolder/colors/palette'
import { StylesContext, BottomTabsStyles } from './interface'

export const getStyles = (context?: StylesContext, style?: BottomTabsStyles): BottomTabsStyles => ({
  tabBarStyle: [
    {
      position: 'absolute',
      bottom: 0,
      flexDirection: 'row',
      justifyContent: 'space-around',
      height: 75,
      width: '100%',
      backgroundColor: 'transparent',
      shadowRadius: 6,
      shadowColor: palette.greyPurple,
      shadowOffset: {
        width: 0,
        height: -10
      },
      shadowOpacity: 0.1,
      elevation: 30,
      zIndex: 1
    },
    style?.tabBarStyle
  ],
  tabMaskStyle: [
    {
      borderTopWidth: 0,
      elevation: 30,
      flexDirection: 'row',
      width: '100%',
      backgroundColor: 'transparent'
    },
    style?.tabMaskStyle
  ],
  tabBarItemStyle: [
    {
      backgroundColor: context?.theme.colors.basics.white,
      flex: 1,
      alignItems: 'center'
    },
    style?.tabBarItemStyle
  ],
  tabBarIconStyle: [{ marginTop: context?.theme.metrics.spacing.s }, style?.tabBarIconStyle],
  tabBarTitleStyle: [
    {
      fontSize: 10,
      marginTop: context?.theme.metrics.spacing.s,
      textTransform: 'uppercase'
    },
    style?.tabBarTitleStyle
  ],
  imageBackground: [
    {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      shadowColor: palette.greyTransparent.c100,
      shadowOffset: {
        width: 5,
        height: -9
      },
      shadowOpacity: 24,
      shadowRadius: 1,
      elevation: 1,
      height: 30,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: palette.white,
      zIndex: 1
    },
    style?.imageBackground
  ]
})
