import { useCallback } from "react";

import { RepartitionEnum } from "@constants/arbitrations";
import { useTranslation } from "@ere-uilib/translations";

export const useActiveClassLabel = () => {
  const { formatMessage } = useTranslation();

  const getActiveClassLabel = useCallback((value: string) => {
    switch(value) {
    case RepartitionEnum.actionPercentage:
      return formatMessage({ id: 'AbritrageOADPageSyntheseRegroupementActions' });
    case RepartitionEnum.diversifiedPercentage: 
      return formatMessage({ id: 'AbritrageOADPageSyntheseRegroupementDiversifié' });
    case RepartitionEnum.monetaryPercentage:
      return formatMessage({ id: 'AbritrageOADPageSyntheseRegroupementMonetaire' });
    case RepartitionEnum.obligatoryPercentage:
      return formatMessage({ id: 'AbritrageOADPageSyntheseRegroupementObligataire' });
    }

    return value;
  }, [formatMessage]);

  return {
    getActiveClassLabel,
  }
};
