interface ApiErrorCodeProps {
  INF_REG_01: string;
  INF_REG_04: string;
  SESSION_EXPIRED: string;
  INF_NO_COMPANIES: string;
  ERR_REG_15: string;
}

interface ApiErrorStatusProps {
  NOT_FOUND_STATUS: number;
  BAD_REQUEST: number;
}

interface EditUserInfosSecureAuthenticationProps {
  SMS_OTP_REQUIRED: string
  EMAIL_OTP_REQUIRED: string
  BOTH_CHANEL_OTP_REQUIRED: string
  DOCAPOSTE_OTP_REQUIRED: string
}

const API_ERROR_CODE: ApiErrorCodeProps = {
  INF_REG_01: 'INF_REG_01',
  INF_REG_04: 'INF_REG_04',
  SESSION_EXPIRED: 'InvalidScope',
  INF_NO_COMPANIES: 'INF_NO_COMPANIES',
  ERR_REG_15: 'ERR_REG_15'
};

const EDIT_USER_INFOS_SECURE_AUTHENTICATION: EditUserInfosSecureAuthenticationProps = {
  SMS_OTP_REQUIRED: 'SmsOtpRequired',
  EMAIL_OTP_REQUIRED: 'EmailOtpRequired', // to be confirmed
  BOTH_CHANEL_OTP_REQUIRED: 'MfaRequired',
  DOCAPOSTE_OTP_REQUIRED: 'DOCAPOSTE_OTP_REQUIRED',
};

const API_ERROR_STATUS: ApiErrorStatusProps = {
  NOT_FOUND_STATUS: 404,
  BAD_REQUEST: 400
};

export { API_ERROR_CODE, API_ERROR_STATUS, EDIT_USER_INFOS_SECURE_AUTHENTICATION };
