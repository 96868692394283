import { useMemo } from 'react';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

export const useStyles = (containerStyle: StyleProp<ViewStyle>) => {
  const theme = useTheme();

  const styles = useMemo(
    () =>
      StyleSheet.create({
        containerStyle: {
          display: 'flex',
          position: 'relative',
          margin: theme.metrics.spacing.s,
          alignSelf: 'center',
          alignItems: 'center',
          justifyContent: 'center',

          ...StyleSheet.flatten(containerStyle)
        }
      }),
    [theme, containerStyle]
  );

  return styles;
};
