import React, { useMemo } from 'react'
import { StyleProp, ViewStyle, StyleSheet } from 'react-native'

import { useTheme } from '@ere-uilib/styles'
import { palette } from '@ere-uilib/styles/theme/themeFolder/colors/palette'

export const useStyles = ({
  style,
  addShadow
}: {
  style?: StyleProp<ViewStyle>
  addShadow: boolean
}) => {
  const theme = useTheme()

  const styles = useMemo(
    () =>
      StyleSheet.create({
        containerStyle: {
          ...(addShadow && {
            shadowColor: palette.greyTransparent.c000,
            shadowOffset: {
              width: 0,
              height: 5
            },
            shadowOpacity: 0.13,
            shadowRadius: 6.27,
            elevation: 5
          }),
          ...StyleSheet.flatten(style)
        },
        mainRowStyle: {
          backgroundColor: theme.colors.basics.primary.c500,
          minHeight: 50,
          paddingVertical: theme.metrics.spacing.xs,
          flexDirection: 'row',
          alignItems: 'flex-start'
        },
        textContainerStyle: {
            paddingLeft: theme.metrics.spacing.xs,
        },
        contributionRowStyle: {
          backgroundColor: theme.colors.basics.white,
          height: 40,
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          paddingHorizontal: theme.metrics.spacing.xm
        },
        contributionErrorTitle: {
          color: theme.colors.notifications.warning.c500
        },
        textStyle: {
          textAlign: 'left',
          color: theme.colors.basics.white
        },
        contributionRowAmountStyle: {
          marginLeft: theme.metrics.spacing.s
        }
      }),
    [theme, addShadow, style]
  )

  return styles
}
