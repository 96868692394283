import { StylesContext, SavingsDistributionCardStyles } from './interface';

export const getStyles = (
  context?: StylesContext,
  style?: SavingsDistributionCardStyles,
): SavingsDistributionCardStyles => ({
  scrollViewContainerStyle: [
    {
      display: 'flex',
      paddingRight: (context?.isMobile || context?.isTablet)
        ? context?.theme.metrics.spacing.s
        : '15%',
      paddingLeft: (context?.isMobile || context?.isTablet) ? context?.theme.metrics.spacing.s : '15%',
      paddingTop: (context?.isMobile || context?.isTablet) ? '50%' : context?.theme.metrics.spacing.m,
      paddingBottom: (context?.isMobile || context?.isTablet)
        ? '30%'
        : context?.theme.metrics.spacing.none,
      alignSelf: 'stretch',
      backgroundColor: context?.theme.colors.basics.white,
      zIndex: 1
    },
    style?.scrollViewContainerStyle
  ],
  containerStyle: [
    {
      display: 'flex',
      flexDirection: 'row',
      borderBottomWidth: 1,
      borderBottomColor: context?.theme.colors.background.default.c500,
      backgroundColor: context?.theme.colors.basics.white,
      justifyContent: 'space-between',
      paddingTop: context?.theme.metrics.spacing.xm,
      paddingBottom: context?.theme.metrics.spacing.xm,
      paddingLeft: context?.theme.metrics.spacing.s,
      paddingRight: context?.theme.metrics.spacing.s
    },
    style?.containerStyle
  ],
  innerContainerStyle: [
    {
      flex: 1,
      flexDirection: 'column',
      alignItems: 'flex-end'
    },
    style?.innerContainerStyle
  ],
  textContainerStyle: [
    {
      display: 'flex',
      flexDirection: 'row'
    },
    style?.textContainerStyle
  ],
  detailsButtonStyle: [
    {
      borderRadius: context?.theme.metrics.borderRadius.full,
      borderColor: context?.theme.colors.buttons.disabled.c500,
      borderWidth: 1,
      // width: context?.theme.width.menuButton,
      // height: context?.theme.height.detailsButton,
      display: 'flex',
      justifyContent: 'center'
    },
    style?.detailsButtonStyle
  ]
});
