import { InstallmentDecisionPropertiesEnum } from '@constants/installment';
import { StandardPropertyFilterState } from '@modules/common/types';
import { DispositifState, ResponseType } from '@modules/common/types';

export const getEligibleDispositifs = (dispositifs: DispositifState[], respondedQuestions: (ResponseType | undefined)[], responseType?: string) => {
  const localeDispositifList: DispositifState[] = [];
  let earlyAmount = 0;
  let immediateAmount = 0;
  let returnedAMount = 0;
  dispositifs?.map(dispositif => {
    let isNotEligible = false;
    // if no response yet : all dispositifs are set eligible
    if (respondedQuestions?.length === 0) {
      return localeDispositifList.push({
        ...dispositif,
        isEligible: true,
      });
    }
    // for each responses check if dispositif property filter is incompatible
    respondedQuestions?.map(respondedQuestion => {
      // has resposnses are question or answers , we get property filter from question responded key or response propertyFilter key
      const responsePropertyFilter: StandardPropertyFilterState | undefined = respondedQuestion?.respondedPropertyFilter || respondedQuestion?.propertyFilter;
      const responseValue = responsePropertyFilter && responsePropertyFilter?.value;
      !isNotEligible && Object.keys(dispositif.filterProperties).map(filterPropertyKey => {
        const filterProperty = filterPropertyKey as InstallmentDecisionPropertiesEnum;
        // check if response has impact on filterPropertyKey
        if ((responsePropertyFilter && responsePropertyFilter?.name === filterPropertyKey)) {
          if (dispositif.filterProperties[filterProperty] !== responseValue) {
            // response propertyFilter key is matching a dispositif property filter and value not corresponding
            // dispositif is set not eligible
            isNotEligible = true;
          }
        }
      });
    });

    earlyAmount = earlyAmount + (dispositif?.advancedProperties?.EarlyReleaseAmount||0);
    immediateAmount = immediateAmount + (dispositif?.advancedProperties?.ImmediateAmount||0);

    localeDispositifList.push({
      ...dispositif,
      isEligible: !isNotEligible,
    });
  });
  if (responseType === 'Repayment') {returnedAMount = immediateAmount;}
  if (responseType === 'Unavailable') {returnedAMount = earlyAmount;}
  if (responseType === 'All') {returnedAMount = (immediateAmount + earlyAmount);}
  return {
    eligibleDispositifs: localeDispositifList,
    amount: returnedAMount,
  };
};