import React, { useCallback, useMemo, useState } from 'react';
import { View } from 'react-native';

import {
  DashboardPageWrapperConnected,
  Text,
  useTranslation,
  useTheme,
  ColumnsContainer,
  SimpleButton,
  Title,
  Select,
  CheckBox,
} from '@components/index';
import { RouteNames } from '@constants/navigation';
import { PlanFamillyList } from '@constants/index';
import { IconEnum } from '@ere-uilib/enums';
import { ItemState } from '@ere-uilib/molecules/inputs/Select/types';
import { DirectionEnum } from '@ere-uilib/molecules/steppers/InstallmentsStepper/interfaces';
import { useScreenSizes } from '@ere-uilib/styles';
import { constructStepperItems } from '@pages/Common/utils';

import { BankDataHeader } from '../components';
import { MailSendingDocuments, SupportingDocuments } from './components';
import { useLabels } from './hooks';
import { Props } from './interfaces';
import { useStyles } from './useStyles';
import { canUpload } from './utils';

const stepperItems = constructStepperItems({
  size: 3,
  direction: DirectionEnum.ROW,
  activeStep: 1
});

export const BankDomiciliationPageComponent: React.FC<React.PropsWithChildren<Props>> = ({
  navigation,
  bankData,
  onGetInitData,
  setSelectedCountry,
  isLoadingInitBankData,
  initBankDataError,
  selectedAccountType,
  selectedPlanFamily,
  inputMode,
  status,
  addressData,
  allowedSignature
}) => {
  const { formatMessage } = useTranslation();
  const theme = useTheme();
  const [isChecked, setIsChecked] = useState(false);

  const styles = useStyles(isChecked);
  const labels = useLabels();

  const [selectValue, setSelectValue] = useState<ItemState | undefined>();
  const [enableInputMode, setEnableInputMode] = useState<boolean>(false);
  const { isMobile, isTablet } = useScreenSizes();

  const selectList = useMemo<ItemState[]>(
    () =>
      bankData?.es?.allowedCountries?.map(value => ({
        value,
        label: formatMessage({ id: value })
      })) || [],
    [bankData, formatMessage]
  );

  const canDisplayDomiciliation =
    (!allowedSignature && canUpload(inputMode)) || (!enableInputMode && allowedSignature === true);

  const handleBackButton = useCallback(() => {
    setEnableInputMode(currentInputMode => {
      if (!currentInputMode) navigation.goBack();
      return false;
    })
  }, [navigation])

  const handleNavigateToIBANPage = useCallback(
    () =>
      navigation.navigate(RouteNames.BankDetailsStack, {
        screen: RouteNames.BankSetIban
      }),
    [navigation]
  );

  const handleNextButton = useCallback(() => {
    let country: string | null = (selectValue || selectList[0]).value as string;
    if (isChecked) country = null;
    setSelectedCountry(country);

    if (isChecked && !canUpload(inputMode))
      return setEnableInputMode(true);
    ({
      [PlanFamillyList.ES]: onGetInitData,
      [PlanFamillyList.RC]: handleNavigateToIBANPage
    }[selectedPlanFamily || PlanFamillyList.ES]());
  }, [handleNavigateToIBANPage, inputMode, isChecked, onGetInitData, selectList, selectValue, selectedPlanFamily, setSelectedCountry])

  const renderBottomActions = useCallback(() => (
    <>
      {canDisplayDomiciliation ?
        <>
          {!!initBankDataError?.message && <Title
            style={styles.errorTitleStyle}
            variant="t7"
            weight="light"
          >
            {formatMessage({ id: 'Dashboard_Cards_Retry_label' })}
          </Title>}
          <View style={styles.bottomActionsStyle}>
            <View style={styles.bottomActionsButtonsContainerStyle}>
              <SimpleButton
                design="outlined"
                leftIcon={IconEnum.CHEVRON_LEFT}
                onPress={() => navigation.goBack()}
              />
              <SimpleButton
                containerStyle={styles.simpleButtonStyle}
                design="solid"
                loading={allowedSignature && isLoadingInitBankData}
                onPress={handleNextButton}
                size="small"
                title={formatMessage({ id: 'Profil_email_edit_confirm_button' })}
              />
            </View>
          </View>
        </> :
        <View style={styles.bottomActionsStyle}>
          <SimpleButton
            containerStyle={styles.backButtonStyle}
            design="outlined"
            leftIcon={IconEnum.CHEVRON_LEFT}
            onPress={handleBackButton}
            title={labels.backBtnLabel}
          />
        </View>
      }
    </>
  ), [styles, canDisplayDomiciliation, initBankDataError, formatMessage, isLoadingInitBankData, navigation, isChecked, onGetInitData, inputMode]);

  const RenderHeader = () => {
    return (
      <BankDataHeader
        displayBackButton={canDisplayDomiciliation}
        displayCloseButton
        helpPoint={false}
        stepperItems={canDisplayDomiciliation ? stepperItems : undefined}
        title={formatMessage({ id: `CoordonnesBancairesHeaderTitle${selectedAccountType}` })}
      />
    );
  };

  const renderSelectChoiceContent = useCallback(() => {
    if (!canDisplayDomiciliation) return null;
    return (
      <>
        <View style={styles.contentContainerStyle}>
          <Title
            variant="t4"
            weight="bold">
            {labels.countrySubtitle}
          </Title>
          <Text
            style={styles.descriptionStyle}
            variant="t3"
            weight="light"
          >{labels.countryDescription}
          </Text>
        </View>
        <View style={styles.selectContainerStyle}>
          <Select
            defaultTitle={formatMessage({
              id: 'AideDeblocage_estimate_selectreason_label',
            })}
            disabled={isChecked}
            items={selectList}
            onSelectItem={setSelectValue}
            selectedItem={selectValue || selectList[0]}
          />
        </View>
        {selectedPlanFamily === PlanFamillyList.ES &&
          <View style={styles.helpCardStyle}>
            <CheckBox
              containerStyle={{ marginTop: theme.metrics.spacing.s }}
              label={labels.countrySelectChoiceDescription}
              onPress={() => setIsChecked(!isChecked)}
              value={isChecked}
            />
          </View>
        }
      </>
    )
  }, [formatMessage, canDisplayDomiciliation, inputMode, isChecked, labels, selectedPlanFamily, selectList, selectValue, setSelectedCountry, styles]);

  const renderSupportingDocuments = useCallback(() => {
    return (
      <View style={styles.contentContainerStyle}>
        <SupportingDocuments
          inputMode={inputMode}
          status={status}
        />
        <MailSendingDocuments
          inputMode={inputMode}
          status={status}
          tsaAddress={addressData}
        />
      </View>
    )
  }, [addressData, inputMode, status, styles]);
  const renderContent = useCallback(() => {
    return (
      <View style={canDisplayDomiciliation ? styles.containerStyle : styles.containerMailStyle}>
        {canDisplayDomiciliation && renderSelectChoiceContent()}
        {(!allowedSignature || enableInputMode) && renderSupportingDocuments()}
        {!isMobile && !isTablet && renderBottomActions()}
      </View>
    )
  }, [inputMode, canDisplayDomiciliation, isMobile, isTablet, renderBottomActions, renderSelectChoiceContent, renderSupportingDocuments, styles]);

  return (
    <DashboardPageWrapperConnected
      renderStickyMobileBottom={() => renderBottomActions()}
    >
      <ColumnsContainer
        hasRenderRightDesktopColumn={false}
        renderHeader={RenderHeader}
      >
        {renderContent()}
      </ColumnsContainer>
    </DashboardPageWrapperConnected>
  );
};
