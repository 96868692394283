import { InitAllErrorsAction } from '@modules/error/actions/errorActionsTypes';
import {
  AvailabilitiesDetailState, AvailabilitiesFundsState,
  FilterRoulesState,
  Installments,
  OnGoingVVP,
  OperationDetails,
  OperationsHistoryState,
  RepartitionFiltersState,
  RepartitionsState,
  SavingsErrorState,
  SavingsHistoryFiltersState,
  SavingsHistoryState
} from '@modules/savings/types';

export enum SavingsActionsType {
  GET_REPARTITIONS_REQUEST = 'GET_REPARTITIONS_REQUEST',
  GET_REPARTITIONS_SUCCESS = 'GET_REPARTITIONS_SUCCESS',
  GET_REPARTITIONS_BY_PLAN_REQUEST = 'GET_REPARTITIONS_BY_PLAN_REQUEST',
  GET_REPARTITIONS_BY_PLAN_SUCCESS = 'GET_REPARTITIONS_BY_PLAN_SUCCESS',
  GET_REPARTITIONS_FAILURE = 'GET_REPARTITIONS_FAILURE',
  SET_REPARTITION_FILTER = 'SET_REPARTITION_FILTER',
  GET_AVAILABILITIES_DETAILS_REQUEST = 'GET_AVAILABILITIES_DETAILS_REQUEST',
  GET_AVAILABILITIES_DETAILS_SUCCESS = 'GET_AVAILABILITIES_DETAILS_SUCCESS',
  GET_AVAILABILITIES_DETAILS_FAILURE = 'GET_AVAILABILITIES_DETAILS_FAILURE',
  GET_AVAILABILITIES_DETAILS_BY_PLAN_REQUEST = 'GET_AVAILABILITIES_DETAILS_BY_PLAN_REQUEST',
  GET_AVAILABILITIES_DETAILS_BY_PLAN_SUCCESS = 'GET_AVAILABILITIES_DETAILS_BY_PLAN_SUCCESS',
  GET_AVAILABILITIES_DETAILS_BY_PLAN_FAILURE = 'GET_AVAILABILITIES_DETAILS_BY_PLAN_FAILURE',
  GET_OPERATIONS_HISTORY_REQUEST = 'GET_OPERATIONS_HISTORY_REQUEST',
  GET_OPERATIONS_HISTORY_SUCCESS = 'GET_OPERATIONS_HISTORY_SUCCESS',
  GET_OPERATIONS_HISTORY_FAILURE = 'GET_OPERATIONS_HISTORY_FAILURE',
  GET_OPERATIONS_HISTORY_BY_PLAN_REQUEST = 'GET_OPERATIONS_HISTORY_BY_PLAN_REQUEST',
  GET_OPERATIONS_HISTORY_BY_PLAN_SUCCESS = 'GET_OPERATIONS_HISTORY_BY_PLAN_SUCCESS',
  GET_OPERATIONS_HISTORY_BY_PLAN_FAILURE = 'GET_OPERATIONS_HISTORY_BY_PLAN_FAILURE',
  APPLY_OPERATIONS_HISTORY_FILTER_REQUEST = 'APPLY_OPERATIONS_HISTORY_FILTER_REQUEST',
  GET_SELECTED_PLAN_AND_NAVIGATION = 'GET_SELECTED_PLAN_AND_NAVIGATION',
  GET_INSTALLMENTS_REQUEST = 'GET_INSTALLMENTS_REQUEST',
  GET_INSTALLMENTS_SUCCESS = 'GET_INSTALLMENTS_SUCCESS',
  GET_INSTALLMENTS_FAILURE = 'GET_INSTALLMENTS_FAILURE',
  GET_SAVINGS_HISTORY_BY_PLAN_REQUEST = 'GET_SAVINGS_HISTORY_BY_PLAN_REQUEST',
  GET_SAVINGS_HISTORY_BY_PLAN_SUCCESS = 'GET_SAVINGS_HISTORY_BY_PLAN_SUCCESS',
  GET_SAVINGS_HISTORY_BY_PLAN_FAILURE = 'GET_SAVINGS_HISTORY_BY_PLAN_FAILURE',
  GET_SAVINGS_HISTORY_REQUEST = 'GET_SAVINGS_HISTORY_REQUEST',
  GET_SAVINGS_HISTORY_SUCCESS = 'GET_SAVINGS_HISTORY_SUCCESS',
  GET_SAVINGS_HISTORY_FAILURE = 'GET_SAVINGS_HISTORY_FAILURE',
  APPLY_SAVINGS_HISTORY_FILTER_REQUEST = 'APPLY_SAVINGS_HISTORY_FILTER_REQUEST',
  GET_OPERATION_DETAILS_REQUEST = 'GET_OPERATION_DETAILS_REQUEST',
  GET_OPERATION_DETAILS_SUCCESS = 'GET_OPERATION_DETAILS_SUCCESS',
  GET_OPERATION_DETAILS_FAILURE = 'GET_OPERATION_DETAILS_FAILURE',
  CANCEL_OPERATION_REQUEST = 'CANCEL_OPERATION_REQUEST',
  CANCEL_OPERATION_SUCCESS = 'CANCEL_OPERATION_SUCCESS',
  CANCEL_OPERATION_FAILURE = 'CANCEL_OPERATION_FAILURE',
  GET_ONGOING_VVP_HISTORY_REQUEST = 'GET_ONGOING_VVP_HISTORY_REQUEST',
  GET_ONGOING_VVP_HISTORY_SUCCESS = 'GET_ONGOING_VVP_HISTORY_SUCCESS',
  GET_ONGOING_VVP_HISTORY_FAILURE = 'GET_ONGOING_VVP_HISTORY_FAILURE',
  SET_IS_DETAILS_VVP_POPIN = 'SET_IS_DETAILS_VVP_POPIN',
  SET_ONGOING_VVP_DELETE_MODAL_VISIBLE_STATE = 'SET_ONGOING_VVP_DELETE_MODAL_VISIBLE_STATE',
  INIT_SAVINGS_REDUCER = 'INIT_SAVINGS_REDUCER',
  INIT_SAVINGS_LISTINGS = 'INIT_SAVINGS_LISTINGS',
  CLEAR_CACHE_AFTER_OPERATION_DONE = 'CLEAR_CACHE_AFTER_OPERATION_DONE',
  GET_AVAILABILITIES_FUNDS_REQUEST = 'GET_AVAILABILITIES_FUNDS_REQUEST',
  GET_AVAILABILITIES_FUNDS_SUCCESS = 'GET_AVAILABILITIES_FUNDS_SUCCESS',
  GET_AVAILABILITIES_FUNDS_FAILURE = 'GET_AVAILABILITIES_FUNDS_FAILURE',
  GET_AVAILABILITIES_FUNDS_BY_PLAN_REQUEST = 'GET_AVAILABILITIES_FUNDS_BY_PLAN_REQUEST',
  GET_AVAILABILITIES_FUNDS_BY_PLAN_SUCCESS = 'GET_AVAILABILITIES_FUNDS_BY_PLAN_SUCCESS',
  GET_AVAILABILITIES_FUNDS_BY_PLAN_FAILURE = 'GET_AVAILABILITIES_FUNDS_BY_PLAN_FAILURE',
}
export interface GetRepartitionsRequestAction {
  type: SavingsActionsType.GET_REPARTITIONS_REQUEST;
  companyId: string;
  repartitionFilters?: RepartitionFiltersState;
}

export interface GetRepartitionsSuccessAction {
  type: SavingsActionsType.GET_REPARTITIONS_SUCCESS;
  repartitions: RepartitionsState[];
}


export interface GetRepartitionsByPlanRequestAction {
  type: SavingsActionsType.GET_REPARTITIONS_BY_PLAN_REQUEST;
  index: number;
  repartitionFilters?: RepartitionFiltersState;
}

export interface GetRepartitionsByPlanAction {
  type: SavingsActionsType.GET_REPARTITIONS_BY_PLAN_SUCCESS;
  repartitions: RepartitionsState[];
  planId: string;
}

export interface GetRepartitionsFailureAction {
  type: SavingsActionsType.GET_REPARTITIONS_FAILURE;
  error: SavingsErrorState;
}

// repartition filter

export interface SetRepartitionFilter {
  type: SavingsActionsType.SET_REPARTITION_FILTER;
  repartitionFilters: RepartitionFiltersState;
}

//Availabilities Detail

export interface GetAvailabilitiesDetailRequest {
  type: SavingsActionsType.GET_AVAILABILITIES_DETAILS_REQUEST;
  companyId: string;
}

export interface GetAvailabilitiesDetailSuccess {
  type: SavingsActionsType.GET_AVAILABILITIES_DETAILS_SUCCESS;
  availabilitiesDetail: AvailabilitiesDetailState;
}

export interface GetAvailabilitiesDetailFailure {
  type: SavingsActionsType.GET_AVAILABILITIES_DETAILS_FAILURE;
  error: SavingsErrorState;
}

//AvailabilitiesDetails by plan

export interface GetAvailabilitiesDetailByPlanRequest {
  type: SavingsActionsType.GET_AVAILABILITIES_DETAILS_BY_PLAN_REQUEST;
  index: number;
}


export interface GetAvailabilitiesDetailByPlanSuccess {
  type: SavingsActionsType.GET_AVAILABILITIES_DETAILS_BY_PLAN_SUCCESS;
  availabilitiesDetail: AvailabilitiesDetailState;
  planId: string;
}


export interface GetAvailabilitiesDetailByPlanFailure {
  type: SavingsActionsType.GET_AVAILABILITIES_DETAILS_BY_PLAN_FAILURE;
  error: SavingsErrorState;
}

//AvailabilitiesFunds

export interface GetAvailabilitiesFundsRequest {
  type: SavingsActionsType.GET_AVAILABILITIES_FUNDS_REQUEST;
  companyId: string;
}

export interface GetAvailabilitiesFundsSuccess {
  type: SavingsActionsType.GET_AVAILABILITIES_FUNDS_SUCCESS;
  availabilitiesFunds: AvailabilitiesFundsState[];
}

export interface GetAvailabilitiesFundsFailure {
  type: SavingsActionsType.GET_AVAILABILITIES_FUNDS_FAILURE;
  error: SavingsErrorState;
}

//AvailabilitiesFunds by plan

export interface GetAvailabilitiesFundsByPlanRequest {
  type: SavingsActionsType.GET_AVAILABILITIES_FUNDS_BY_PLAN_REQUEST;
  index: number;
}

export interface GetAvailabilitiesFundsByPlanSuccess {
  type: SavingsActionsType.GET_AVAILABILITIES_FUNDS_BY_PLAN_SUCCESS;
  availabilitiesFunds: AvailabilitiesFundsState[];
  planId: string;
}

export interface GetAvailabilitiesFundsByPlanFailure {
  type: SavingsActionsType.GET_AVAILABILITIES_FUNDS_BY_PLAN_FAILURE;
  error: SavingsErrorState;
}

//operations history

export interface GetOperationsHistoryRequestAction {
  type: SavingsActionsType.GET_OPERATIONS_HISTORY_REQUEST;
  companyId: string;
  filterRoules: FilterRoulesState | undefined;
}

export interface GetOperationsHistorySuccessAction {
  type: SavingsActionsType.GET_OPERATIONS_HISTORY_SUCCESS;
  operationsHistory: OperationsHistoryState[];
}

export interface GetOperationsHistoryFailureAction {
  type: SavingsActionsType.GET_OPERATIONS_HISTORY_FAILURE;
  error: SavingsErrorState;
}

//operations history by plan

export interface GetOperationsHistoryByPlanRequestAction {
  type: SavingsActionsType.GET_OPERATIONS_HISTORY_BY_PLAN_REQUEST;
  index: number;
  filterRoules: FilterRoulesState | undefined;
}

export interface GetOperationsHistoryByPlanSuccessAction {
  type: SavingsActionsType.GET_OPERATIONS_HISTORY_BY_PLAN_SUCCESS;
  operationsHistory: OperationsHistoryState[]; // à voir
  planId: string;
}

export interface GetOperationsHistoryByPlanFailureAction {
  type: SavingsActionsType.GET_OPERATIONS_HISTORY_BY_PLAN_FAILURE;
  error: SavingsErrorState;
}

// history filter

export interface ApplyOperationsHistoryFilterRequest {
  type: SavingsActionsType.APPLY_OPERATIONS_HISTORY_FILTER_REQUEST;
  filterRoules: FilterRoulesState;
}

// selected plan
export interface GetSelectedPlanAndNavigation {
  type: SavingsActionsType.GET_SELECTED_PLAN_AND_NAVIGATION;
  selectedPlan: number;
  selectedTab: string;
}

// installements
export interface GetInstallmentsRequestAction {
  type: SavingsActionsType.GET_INSTALLMENTS_REQUEST;
  year: string;
  planId?: string;
}

export interface GetInstallmentsSuccessAction {
  type: SavingsActionsType.GET_INSTALLMENTS_SUCCESS;
  installments: Installments;
}

export interface GetInstallmentsFailureAction {
  type: SavingsActionsType.GET_INSTALLMENTS_FAILURE;
  // todo
  error: any;
}

// Savings History by plan


export interface GetSavingsHistoryByPlanRequestAction {
  type: SavingsActionsType.GET_SAVINGS_HISTORY_BY_PLAN_REQUEST;
  index: number;
}


export interface GetSavingsHistoryByPlanSuccessAction {
  type: SavingsActionsType.GET_SAVINGS_HISTORY_BY_PLAN_SUCCESS;
  savingsHistory: SavingsHistoryState[];
  planId: string;
}


export interface GetSavingsHistoryByPlanFailureAction {
  type: SavingsActionsType.GET_SAVINGS_HISTORY_BY_PLAN_FAILURE;
  error: SavingsErrorState;
}

// savings history

export interface GetSavingsHistoryRequestAction {
  type: SavingsActionsType.GET_SAVINGS_HISTORY_REQUEST;
}

export interface GetSavingsHistorySuccessAction {
  type: SavingsActionsType.GET_SAVINGS_HISTORY_SUCCESS;
  savingsHistory: SavingsHistoryState[];
}

export interface GetSavingsHistoryFailureAction {
  type: SavingsActionsType.GET_SAVINGS_HISTORY_FAILURE;
  error: SavingsErrorState;
}

// savings history filter

export interface ApplySavingsHistoryFilterRequest {
  type: SavingsActionsType.APPLY_SAVINGS_HISTORY_FILTER_REQUEST;
  savingsHistoryFilters: SavingsHistoryFiltersState;
}

// operation details
export interface GetOperationDetailsRequestAction {
  type: SavingsActionsType.GET_OPERATION_DETAILS_REQUEST;
  operationId: string;
}

export interface GetOperationDetailsSuccessAction {
  type: SavingsActionsType.GET_OPERATION_DETAILS_SUCCESS;
  operationDetails: OperationDetails;
}

export interface GetOperationDetailsFailureAction {
  type: SavingsActionsType.GET_OPERATION_DETAILS_FAILURE;
  error: SavingsErrorState;
}

// operation cancel
export interface CancelOperationRequestAction {
  type: SavingsActionsType.CANCEL_OPERATION_REQUEST;
  operationId: string;
}

export interface CancelOperationSuccessAction {
  type: SavingsActionsType.CANCEL_OPERATION_SUCCESS;
}

export interface CancelOperationFailureAction {
  type: SavingsActionsType.CANCEL_OPERATION_FAILURE;
  error: SavingsErrorState;
}

// on going vvp history

export interface GetOnGoingVVPHistoryRequestAction {
  type: SavingsActionsType.GET_ONGOING_VVP_HISTORY_REQUEST;
  selectedPlan: number;
}

export interface GetOnGoingVVPHistorySuccessAction {
  type: SavingsActionsType.GET_ONGOING_VVP_HISTORY_SUCCESS;
  onGoingVVP: OnGoingVVP[];
}

export interface GetOnGoingVVPHistoryFailureAction {
  type: SavingsActionsType.GET_ONGOING_VVP_HISTORY_FAILURE;
  error: SavingsErrorState;
}

export interface SetIsDetailsVvpPopin {
  type: SavingsActionsType.SET_IS_DETAILS_VVP_POPIN;
  isOpened: boolean
}
export interface SetOnGoingVVPDeleteModalVisibleStateAction {
  type: SavingsActionsType.SET_ONGOING_VVP_DELETE_MODAL_VISIBLE_STATE;
  visible: boolean;
}
export interface InitSavingReducerAction {
  type: SavingsActionsType.INIT_SAVINGS_REDUCER;
}

export interface InitSavingListingsAction {
  type: SavingsActionsType.INIT_SAVINGS_LISTINGS;
}

export interface ClearCacheAfterOperationDoneAction {
  type: SavingsActionsType.CLEAR_CACHE_AFTER_OPERATION_DONE,
  selectedNavigationTab?: string,
  planId?: string,
  stack: any,
  screen: any,
  actionType: any
}

export type SavingsAction =
  | GetInstallmentsRequestAction
  | GetInstallmentsSuccessAction
  | GetInstallmentsFailureAction
  | GetRepartitionsFailureAction
  | GetRepartitionsRequestAction
  | GetRepartitionsSuccessAction
  | GetOperationsHistoryRequestAction
  | GetOperationsHistorySuccessAction
  | GetOperationsHistoryFailureAction
  | GetRepartitionsByPlanRequestAction
  | GetRepartitionsByPlanAction
  | GetOperationsHistoryByPlanRequestAction
  | GetOperationsHistoryByPlanSuccessAction
  | GetOperationsHistoryByPlanFailureAction
  | GetAvailabilitiesDetailRequest
  | GetAvailabilitiesDetailSuccess
  | GetAvailabilitiesDetailFailure
  | GetAvailabilitiesDetailByPlanRequest
  | GetAvailabilitiesDetailByPlanSuccess
  | GetAvailabilitiesDetailByPlanFailure
  | GetAvailabilitiesFundsRequest
  | GetAvailabilitiesFundsSuccess
  | GetAvailabilitiesFundsFailure
  | GetAvailabilitiesFundsByPlanRequest
  | GetAvailabilitiesFundsByPlanSuccess
  | GetAvailabilitiesFundsByPlanFailure
  | ApplyOperationsHistoryFilterRequest
  | SetRepartitionFilter
  | GetSelectedPlanAndNavigation
  | GetSavingsHistoryByPlanRequestAction
  | GetSavingsHistoryByPlanSuccessAction
  | GetSavingsHistoryByPlanFailureAction
  | ApplySavingsHistoryFilterRequest
  | GetSavingsHistoryRequestAction
  | GetSavingsHistorySuccessAction
  | GetSavingsHistoryFailureAction
  | InitAllErrorsAction
  | GetOnGoingVVPHistorySuccessAction
  | SetIsDetailsVvpPopin
  | SetOnGoingVVPDeleteModalVisibleStateAction
  | InitSavingReducerAction
  | InitSavingListingsAction
  | ClearCacheAfterOperationDoneAction
  | CancelOperationRequestAction
  | CancelOperationSuccessAction
  | CancelOperationFailureAction
  | GetOperationDetailsRequestAction
  | GetOperationDetailsSuccessAction
  | GetOperationDetailsFailureAction
