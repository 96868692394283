import { IconEnum } from '@ere-uilib/enums';
import { colors } from '@ere-uilib/styles/theme/themeFolder';

export const iconDisplayDetailsByType = {
  info: {
    backgroundColor: colors.notifications.information.c100,
    iconColor: colors.notifications.information.c500,
    iconName: IconEnum.INFO
  },
  help: {
    backgroundColor: colors.notifications.tips.c100,
    iconColor: colors.notifications.tips.c500,
    iconName: IconEnum.IDEA
  },
  warning: {
    backgroundColor: colors.notifications.warning.c100,
    iconColor: colors.notifications.warning.c500,
    iconName: IconEnum.WARNING
  },
  success: {
    backgroundColor: colors.notifications.success.c100,
    iconColor: colors.notifications.success.c500,
    iconName: IconEnum.CHECK
  }
};
