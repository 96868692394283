import { useScreenSizes, useTheme } from '@ere-uilib/styles';
import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

export const useStyles = () => {
  const theme = useTheme();
  const { isMobile, isTablet } = useScreenSizes();

  return useMemo(() => StyleSheet.create({
    loaderContainer:
      {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: theme?.metrics.spacing.xs,
      },
    loader:
      {
        flex: 1,
        marginLeft: theme?.metrics.spacing.xm
      },
    pageContainer:
      {
        margin: theme?.metrics.spacing.xm
      },
    bottomActionsStyle:
      {
        alignItems: !(isMobile || isTablet)? 'flex-start' : undefined,
        marginTop: !(isMobile || isTablet)? theme.metrics.spacing.xl : undefined,
      },
    simpleButtonStyle: {

    },
    notificationContainerStyle: {
      maxWidth: 550,
      flexDirection: 'column',
    },
    premiumAmountCard:
      {
        flex: 1,
        maxWidth: 550,
        marginVertical: theme.metrics.spacing.xm,
      }
  }), [isMobile, isTablet, theme.metrics.spacing.xl, theme.metrics.spacing.xm, theme.metrics.spacing.xs]);
};