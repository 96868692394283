import {
  MultiFundPerformanceChartStyles,
  StylesContext,
} from './interfaces';

export const getStyles = (
  context?: StylesContext,
  style?: MultiFundPerformanceChartStyles,
): MultiFundPerformanceChartStyles => ({
  containerStyle: [
    {
      display: 'flex',
      position: 'relative',
      alignSelf: 'flex-start',
      width: '100%',
    },
    context?.isMobile && {
      marginHorizontal: -context?.theme.metrics.spacing.xs,
    },
    style?.containerStyle,
  ],
  tooltipDataLineStyle: [
    {
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: context?.theme.metrics.spacing.xs,
    },
    style?.tooltipDataLineStyle,
  ],
  tooltipColorDotStyle: [
    {
      width: 8,
      height: 8,
      borderRadius: 4,
      marginRight: context?.theme.metrics.spacing.xs,
    },
    style?.tooltipColorDotStyle,
  ],
});
