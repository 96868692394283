import React from 'react';
import { useState } from 'react';

import {
  DefinePassword
} from '@components/index';
import { ApplicationSettingsState } from '@modules/settings/types';
import { IdentityValidationErrorState } from '@modules/validate-identity/types';
import { useLogoNavigation } from '@utils/index';

interface Props {
  validateIdentityError: IdentityValidationErrorState
  isApiLoading: boolean
  onRegisterConfirmation: (password: string) => void
  applicationSettings: ApplicationSettingsState
}

export const ChoosePasswordPageComponent: React.FC<React.PropsWithChildren<Props>> = ({
  validateIdentityError,
  isApiLoading,
  onRegisterConfirmation,
  applicationSettings
}: Props) => {
  const [password, setPassword] = useState('');
  const { logoNavigateTo } = useLogoNavigation();

  return (
    <DefinePassword
      appSettings={applicationSettings}
      error={validateIdentityError.message}
      isApiLoading={isApiLoading}
      isLeftMenu
      isProgressHeaderDisplayed
      onLogoPress={logoNavigateTo}
      onPress={() => onRegisterConfirmation(password)}
      setPassword={setPassword}
      title="define-password_title"
    />
  );
};

