import { Context, Styles } from './interfaces';

export const getStyles = (
  context?: Context,
  style?: Styles,
): Styles => ({
  containerStyle: [
    {
      flex: 1,
      paddingVertical: context?.theme.metrics.spacing.xxm,
      paddingHorizontal: context?.theme.metrics.spacing.m,
      backgroundColor: context?.theme.colors.basics.white,
      alignContent: 'center',
      justifyContent: 'center'
    },
    style?.containerStyle
  ],
  buttonContainer: [
    {
      marginTop: context?.theme.metrics.spacing.m,
      flexDirection: 'row',
      justifyContent: 'center'
    },
    style?.buttonContainer
  ],
  titleStyle: [
    {
      textAlign: 'center'
    },
    style?.titleStyle
  ],
  buttonStyle: [
    {
      alignContent: 'center',
      justifyContent: 'center',
      minHeight: 0,
      borderWidth: 1,
      borderColor: context?.theme.colors.basics.primary.c500,
      paddingVertical: context?.theme.metrics.spacing.xs,
      paddingHorizontal: context?.theme.metrics.spacing.m,
      backgroundColor: context?.theme.colors.basics.white
    },
    style?.buttonStyle
  ],
  buttonTextStyle: [
    {
      color: context?.theme.colors.basics.primary.c500,
      lineHeight: undefined
    },
    style?.buttonStyle
  ],
  buttonIconStyle: [
    {
      fontSize: context?.theme.fonts.fontSize.title.t7
    },
    style?.buttonIconStyle
  ],
  bodyContainer: [
    {
    },
    style?.bodyContainer
  ]
});
