import {
  CheckBox, MultiFundPerformanceChart,
  MultiFundPerformanceChartLineDataType, MultiSwitch,
  PeriodSelector, Text
} from '@ere-uilib/index';
import {
  createUseStyles,
  useTheme
} from '@ere-uilib/styles';
import {useTranslation} from '@ere-uilib/translations';
import dayjs from 'dayjs';
import React, {useEffect, useState} from 'react';
import {
  View
} from 'react-native';
import {
  CompareFundsChartPartDataProps,
  CompareFundsChartPartStyles
} from './interface';
import {getStyles} from './styles';



type Props = CompareFundsChartPartDataProps & CompareFundsChartPartStyles;

export const CompareFundsChartPart: React.FC<React.PropsWithChildren<Props>> = ({
  data,
  containerStyle,
}) => {
  const theme = useTheme();
  const styles = useStyles(
    { theme },
    { containerStyle }
  );
  const { formatMessage } = useTranslation();
  // manage fund selection
  const fundSelectList:{name:string, color:string}[] = [];
  const defaultSelectedFunds:number[] =  [];
  data?.forEach((item, i)=>{
    fundSelectList.push({
      name: item.name || '',
      color: theme.colors.charts.lineListColor[i],
    });
    defaultSelectedFunds.push(i);
  });
  const [selectedFunds, setSelectedFunds] = useState(defaultSelectedFunds);
  const fundCheckboxPress = (index:number)=>{
    let newSelectedFunds = [...selectedFunds];
    if (selectedFunds.includes(index)) {
      newSelectedFunds = newSelectedFunds.filter(value=>value !== index);
    } else {
      newSelectedFunds.push(index);
    }
    setSelectedFunds(newSelectedFunds);
  };
  // define allowed dates
  const allDatesForFundsSelection:string[] = [];
  const remappedData:MultiFundPerformanceChartLineDataType[] = data?.map((item, index) => {
    return (
      {
        name: item.name || '',
        color: theme.colors.charts.lineListColor[index],
        perfData: item?.sharePriceHisto?.map(perfData=>{
          if (!allDatesForFundsSelection.includes(perfData.date)) {
            allDatesForFundsSelection.push(perfData.date);
          }
          return {
            x: perfData.date,
            y: perfData.value,
          };
        }) || [],
      }
    );
  }) || [];

  // filter selected funds by period

  // ensure reorder of all dates detected asc
  allDatesForFundsSelection.sort((a, b)=>{
    if (a === b) {return 0;}
    return a > b ? 1 : -1;
  });

  // manage date period selection
  const [periodSwitchValue, setPeriodSwitchValue] = useState(1);
  const periodList = [
    {
      value: 1,
      label: formatMessage({
        id: 'Fundsheet_Tab3Perf_Performance_graph_period_1year',
      }) },
    {
      value: 3,
      label: formatMessage({
        id: 'Fundsheet_Tab3Perf_Performance_graph_period_3year',
      }) },
    {
      value: 5,
      label: formatMessage({
        id: 'Fundsheet_Tab3Perf_Performance_graph_period_5year',
      }),
    },
    {
      value: 0,
      label: formatMessage({
        id: 'Fundsheet_Tab3Perf_Performance_graph_period_custom',
      }),
    },
  ];
  const minDate = allDatesForFundsSelection.length > 0 ? new Date(allDatesForFundsSelection[0]) : new Date();
  const maxDate = allDatesForFundsSelection.length > 0 ? new Date(allDatesForFundsSelection[allDatesForFundsSelection.length - 1]) : new Date();

  //const [filteredDates, setFilteredDates] = useState<FilteredDates[] | undefined>(undefined);
  const date = new Date();
  const previousYearDate = new Date();
  previousYearDate.setFullYear(date.getFullYear() - 1);
  previousYearDate.setHours(0, 0, 0, 0);
  const [startDate, setStartDate] = useState(previousYearDate);
  const [endDate, setEndDate] = useState(date);

  const onChangeStartDate = (value:any) => {
    setStartDate(value);
  };

  const onChangeEndDate = (value:any) => {
    setEndDate(value);
  };

  useEffect(() => {
    if (periodSwitchValue !== 0) {
      const newEndDate = new Date();
      const newStartDate = new Date();
      newStartDate.setFullYear(newEndDate.getFullYear() - periodSwitchValue);
      newStartDate.setHours(0, 0, 0, 0);
      setStartDate(newStartDate);
      setEndDate(newEndDate);
    }
  }, [periodSwitchValue]);

  // filter final data
  // limit line to selected funds
  let finalData = remappedData.filter((fund, i)=>selectedFunds.includes(i));
  // limit line to period selected
  const formattedStartDate = dayjs(startDate).format('YYYY-MM-DDTHH:mm:ss');
  const formattedEndDate = dayjs(endDate).format('YYYY-MM-DDTHH:mm:ss');
  finalData = finalData.map((item, i)=>{
    return {
      ...item,
      perfData: item.perfData?.filter((perf, iPerf)=>{
        const axisDate = perf.x;
        // date is bitween selected dates period
        return (axisDate >= formattedStartDate && axisDate <= formattedEndDate);
      }),
    };
  });

  // remove empty lines
  finalData = finalData.filter((item, i)=>{
    return item?.perfData?.length > 0;
  });

  return (
    <View style={styles.containerStyle}>
      <Text
        variant="t3"
        weight="bold"
      >
        {formatMessage({ id: 'Fundsheet_Tab3Perf_Performance_graph_title' })}
      </Text>
      <MultiSwitch
        containerStyle={styles.periodSwitchStyle}
        onChange={value=> {
          setPeriodSwitchValue(value);
        }}
        options={periodList}
        value={periodSwitchValue}
      />
      {periodSwitchValue === 0 && (
        <PeriodSelector
          maxDate={maxDate}
          minDate={minDate}
          onChangeSelectorEndDate={onChangeEndDate}
          onChangeSelectorStartDate={onChangeStartDate}
          selectorEndDate={endDate}
          selectorStartDate={startDate}
          style={styles.periodSelectorStyle}
        />
      )}
      <Text
        style={styles.valuattionStyle}
        variant="t4"
        weight="light"
      >
        {formatMessage({ id: 'Fundsheet_Tab3Perf_Valuation_title', values: { variable: '%' } })}
      </Text>
      {finalData && finalData.length > 0 ? (
        <MultiFundPerformanceChart
          containerStyle={styles.chartStyle}
          data={finalData}
        />
      ) : (
        <Text style={styles.noDataLabelStyle}>
          {formatMessage({ id: 'Fundsheet_Tab3Perf_Performance_nodata_label' })}
        </Text>
      )}
      {fundSelectList.map((item, index)=>{
        const value = selectedFunds.includes(index);
        return (
          <CheckBox
            containerStyle={styles.checkboxLegendStyle}
            customColor={item.color}
            label={item.name}
            onPress={()=>{fundCheckboxPress(index);}}
            value={value}
          />
        );
      })}
      <Text
        variant="t3"
        weight="light"
      >
        {formatMessage({ id: 'Fundsheet_Tab3Perf_Warning_past_performances_legend' })}
      </Text>
    </View>
  );
};

const useStyles = createUseStyles(getStyles);
