import React from 'react';
import { View, TouchableOpacity } from 'react-native';
import { FontIcon, StatusTag, Text } from '@components/index';
import { useTheme } from '@ere-uilib/styles';
import { StatusTagTypesEnum } from '@ere-uilib/molecules/tags/StatusTag/sharedInterfaces';
import { ProfileInformationsEditCardStyles } from "@ere-uilib/molecules/cards/ProfileInformationsContent/useStyles";
import { useStyles } from "./useStyles";

interface ProfileInformationsEditCardProps {
  userInformation: string | null;
  informationDescription: string;
  informatonSubDescription?: string | null;
  editStatusLabel?: string | null;
  editStatusType?: StatusTagTypesEnum | null;
  isEditable?: boolean;
  onEditPress?: () => void;
  valueTestId?: string;
  editTestId?: string;
  statusTestId?: string;
  isFinished?: boolean
}

type Props = ProfileInformationsEditCardProps & ProfileInformationsEditCardStyles;

export const ProfileInformationsContent: React.FC<Props> = ({
  informationDescription,
  userInformation,
  isEditable = false,
  editStatusLabel = null,
  editStatusType = null,
  informatonSubDescription,
  onEditPress,
  containerStyle,
  firstLinesStyle,
  statusTagStyle,
  lastLinesStyle,
  userInformationStyle,
  valueTestId,
  editTestId,
  statusTestId,
  isFinished = true
}) => {
  const theme = useTheme();
  const styles = useStyles(isEditable || isFinished);

  return (
    <View style={containerStyle}>
      <View style={styles.firstLineContainerStyle}>
        <Text
          style={[styles.firstLines, firstLinesStyle]}
          variant="t3"
          weight="light">
          {informationDescription}
        </Text>
        {isEditable && (
          <TouchableOpacity
            testID={editTestId}
            accessibilityLabel={editTestId}
            disabled={false}
            onPress={onEditPress}>
            <FontIcon
              color={theme?.colors.basics.primary.c500}
              name="editer"
              size={theme?.metrics.iconSizes.mplus}
            />
          </TouchableOpacity>
        )}
      </View>

      <View>
        {!!editStatusType && !!editStatusLabel && (
          <StatusTag
            label={editStatusLabel}
            testId={statusTestId}
            displayIcon={false}
            type={editStatusType}
            containerStyle={[styles.statusTagStyle, statusTagStyle]}
          />
        )}
        {editStatusType !== StatusTagTypesEnum.TO_INFORM && !!userInformation && (
          <Text
            style={[styles.userInformation, userInformationStyle]}
            variant="t3"
            weight="regular"
            testId={valueTestId}>
            {userInformation}
          </Text>
        )}
        {!!informatonSubDescription && (
          <Text
            style={[styles.lastLines, lastLinesStyle]}
            variant="t3"
            weight="light">
            {informatonSubDescription}
          </Text>
        )}
      </View>
    </View>
  );
};
