import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import {
  useScreenSizes, useTheme
} from '@components/index';

export const useStyles = () => {
  const theme = useTheme();
  const { isDesktop } = useScreenSizes();
  const styles = useMemo(()=>StyleSheet.create({
    dashboardWrapper: { 
      backgroundColor: theme.colors.basics.white 
    },
    stepperContainer: {
      alignItems: "center",
    },
    stepperStyle: {
      paddingBottom: theme.metrics.spacing.s,
      marginTop: -theme.metrics.spacing.s,
    },
    bottomActionsStyle: {
      width: '100%',
      ...(isDesktop && {
        marginTop: theme.metrics.spacing.xl,
      })
    },
    bottomActionsButtonsContainerStyle: {
      width: '100%',
      flexDirection: 'row',
    },
    simpleButtonStyle: {
      marginLeft: theme.metrics.spacing.m,
      flex: 1,
    },
    cardContainer: {
      marginTop: theme.metrics.spacing.xs,
      marginBottom: theme.metrics.spacing.xxm,
    },
    planCardContainer: {
      marginBottom: theme.metrics.spacing.m,
    },
    compareButtonContainerStyle: {
      borderBottomWidth: 0,
      alignItems: 'center',
    },
    compareButtonStyle: {
      marginBottom: theme.metrics.spacing.xm,
      marginTop: theme.metrics.spacing.xm,
    },
    investText: {
      marginTop: theme.metrics.spacing.xs
    },
    contributionLoadingLayer: {
      position: 'absolute',
      width: "100%",
      height: "100%",
      opacity: 0.7
    },
    planUnderFieldLink: {
      color: theme.colors.basics.white
    }
  }), [theme, isDesktop])
  return styles
};
