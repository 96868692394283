import { InstallmentLoadingPageContext, InstallmentLoadingPageStyle } from './interfaces';

export const getStyles = (
  context?: InstallmentLoadingPageContext,
  style?: InstallmentLoadingPageStyle,
): InstallmentLoadingPageStyle => ({
  containerStyle: [
    {
      width: '100%',
      height: '100%',
      flex: 1,
      backgroundColor: 'white',
    },
    style?.containerStyle,
  ],
  contentStyle: [
    {
      alignItems: 'center',
    },
    style?.containerStyle,
  ],
  errorNotificationStyle: [
    { marginBottom: context?.theme?.metrics.spacing.xxm },
    style?.errorNotificationStyle,
  ],
});

