import React, { useEffect, useState } from 'react';
import { REPARTITION_GROUP_FILTER_LIST, REPARTITION_SORT_FILTER_LIST } from '@constants/index';
import { CheckBox, Modal, ModalFrame, RadioButton, SimpleButton, Title } from '@ere-uilib/index';
import { createUseStyles, useTheme } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';
import { RepartitionFiltersState } from '@ere-uilib/types/index';
import { ScrollView, View } from 'react-native';
import { Props } from './interfaces';
import { getStyles } from './styles';



const RepartitionFiltersInitState: RepartitionFiltersState = {
  sortFilter: REPARTITION_SORT_FILTER_LIST[0].value,
  groupFilter: []
};
export const RepartitionsFilterModal = ({
  isVisible,
  onClose,
  repartitionFilters,
  onApplyFiltersToReduxState
}: Props) => {
  const { formatMessage } = useTranslation();
  const [sortFilterTemp, setSortFilterTemp] = useState<string>(repartitionFilters?.sortFilter);
  const [groupFilterTemp, setGroupFilterTemp] = useState<string[]>(repartitionFilters?.groupFilter);
  const theme = useTheme();
  const styles = useStyles(
    {
      theme
    },
    {}
  );

  const onSortFilterChange = (value: string) => {
    setSortFilterTemp(value);
  };
  const onGroupFilterChange = (value: string) => {
    let newGroupFilter = [...groupFilterTemp];
    if (groupFilterTemp.includes(value)) {
      newGroupFilter = newGroupFilter.filter(target => target !== value);
    } else {
      newGroupFilter.push(value);
    }
    setGroupFilterTemp(newGroupFilter);
  };

  const onResetFilters = () => {
    setSortFilterTemp(RepartitionFiltersInitState.sortFilter);
    setGroupFilterTemp(RepartitionFiltersInitState.groupFilter);
  };
  const onApplyFilters = () => {
    onApplyFiltersToReduxState({
      sortFilter: sortFilterTemp,
      groupFilter: groupFilterTemp
    });
  };
  useEffect(() => {
    if (isVisible) {
      // reset state to current redux filter state on modal display
      setSortFilterTemp(repartitionFilters.sortFilter);
      setGroupFilterTemp(repartitionFilters.groupFilter);
    }
  }, [isVisible]);
  return (
    <Modal visible={isVisible}>
      <ModalFrame
        mobilePosition="full"
        onClose={onClose}
        title={formatMessage({ id: 'Saving_Tab1_Filter_title' })}
        titleText={styles.modalTitleStyle}>
        <ScrollView contentContainerStyle={styles.scrollViewContainer}>
          <Title
            style={{ marginBottom: theme.metrics.spacing.m }}
            variant="t6"
            weight="bold"
          // MarginBottom={theme.marginsPaddings.m}
          >
            {formatMessage({ id: 'Saving_Tab1_Filter_Order_title' })}
          </Title>
          <View style={styles.listContainer}>
            {REPARTITION_SORT_FILTER_LIST.map((classification, index: number) => {
              const ischecked = sortFilterTemp === classification.value;
              return (
                <RadioButton
                  containerStyle={styles.radioButtonRowWrapper}
                  key={index + '_' + classification.value}
                  label={formatMessage({ id: classification.label })}
                  onPress={() => onSortFilterChange(classification.value)}
                  value={ischecked}
                />
              );
            })}
          </View>
          <Title
            style={{ marginBottom: theme.metrics.spacing.m }}
            variant="t6"
            weight="bold">
            {formatMessage({ id: 'Saving_Tab1_Filter_Group_title' })}
          </Title>
          <View style={styles.listContainer}>
            {REPARTITION_GROUP_FILTER_LIST.map((group, index) => {
              const ischecked = groupFilterTemp.includes(group.value);
              return (
                <CheckBox
                  containerStyle={{ marginBottom: theme.metrics.spacing.s }}
                  key={index + '_' + group.value}
                  label={formatMessage({ id: group.label })}
                  onPress={() => onGroupFilterChange(group.value)}
                  value={ischecked}
                />
              );
            })}
          </View>
        </ScrollView>
        <View style={styles.buttonContainer}>
          <SimpleButton
            containerStyle={{ flex: 0.5 }}
            design="outlined"
            onPress={() => {
              onResetFilters();
            }}
            title={formatMessage({ id: 'Saving_Tab1_Filter_Reset_button' })}
          />
          <View style={{ width: theme.metrics.spacing.m }} />
          <SimpleButton
            containerStyle={{ flex: 0.5 }}
            onPress={() => {
              onApplyFilters();
              onClose();
            }}
            title={formatMessage({ id: 'Saving_Tab1_Filter_Apply_title' })}
          />
        </View>
      </ModalFrame>
    </Modal>
  );
};

const useStyles = createUseStyles(getStyles);
