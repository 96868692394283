import {
  ProfitSharingIncentiveLegalFrameworkEnum,
  ProfitSharingIncentiveTypeEnum
} from '@constants/ProfitSharingIncentive';

import { LegalFrameworkByType } from '../types';
import { label as common } from '../label';

type Label = Omit<typeof common, 'header'> & {
  header: {
    title: LegalFrameworkByType<string>;
    help: {
      title: string;
      content: LegalFrameworkByType<string>;
    };
  };
  section: {
    project: {
      title: string;
      subtitle: string;
    };
    retirement: {
      title: string;
      subtitle: string;
    };
  };
  plan: {
    name: string;
    link: {
      amountAll: string;
      amountRemaining: string;
    };
  };
  button: {
    compare: string;
    next: string;
  };
};

export const label: Label = {
  ...common,
  header: {
    title: {
      [ProfitSharingIncentiveTypeEnum.incentive]: {
        default: 'PIChoixInvestInteressementTitre',
        [ProfitSharingIncentiveLegalFrameworkEnum.normal]: 'PIChoixInvestInteressementTitre',
        [ProfitSharingIncentiveLegalFrameworkEnum.complementary]:
          'PIChoixInvestInteressementCOMPTitre',
        [ProfitSharingIncentiveLegalFrameworkEnum.exceptional]:
          'PIChoixInvestInteressementEXCEPTitre',
        [ProfitSharingIncentiveLegalFrameworkEnum.ppv]: 'PIChoixInvestInteressementPPVTitre'
      },
      [ProfitSharingIncentiveTypeEnum.profitsharing]: {
        default: 'PIMaParticipationTitre',
        [ProfitSharingIncentiveLegalFrameworkEnum.normal]: 'PIChoixInvestParticipationTitre',
        [ProfitSharingIncentiveLegalFrameworkEnum.complementary]:
          'PIChoixInvestParticipationCOMPTitre',
        [ProfitSharingIncentiveLegalFrameworkEnum.exceptional]:
          'PIChoixInvestParticipationEXCEPTitre'
      }
    },
    help: {
      title: 'PIChoixInvestTitleHelp',
      content: {
        [ProfitSharingIncentiveTypeEnum.incentive]: {
          default: 'PIChoixInvestContentHelp',
          [ProfitSharingIncentiveLegalFrameworkEnum.ppv]: 'PIChoixInvestInteressementPPVAide'
        },
        [ProfitSharingIncentiveTypeEnum.profitsharing]: {
          default: 'PIChoixInvestContentHelp'
        }
      }
    }
  },
  section: {
    project: {
      title: 'PIChoixInvestInvestProjetTitre',
      subtitle: 'PIChoixInvestInvestProjetDescription'
    },
    retirement: {
      title: 'PIChoixInvestInvestRetraiteTitre',
      subtitle: 'PIChoixInvestInvestRetraiteDescription'
    }
  },
  plan: {
    name: 'PISaisieMonMontantTitre',
    link: {
      amountAll: 'PISaisieToutInvestirLien',
      amountRemaining: 'PISaisieInvestirResteLien'
    }
  },
  button: {
    compare: 'PIChoixInvestComparateurFondsBouton',
    next: 'PIChoixInvestContinuerBouton'
  }
};
