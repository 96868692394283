import { connect } from 'react-redux';

import { AppState } from '@modules/reducers';
import { getOnGoingVVPHistory } from '@modules/savings/selectors';

import { VVPListPage as VVPListPageComponent } from './VVPListPage.component';

const mapStateToProps = (state: AppState) => {
  return {
    VVPList: getOnGoingVVPHistory(state),
  };
};

export const VVPListPage = connect(mapStateToProps, null)(VVPListPageComponent);

