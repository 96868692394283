import { useTranslation } from '@ere-uilib/translations';

export function useCalendarLabels() {
  const { formatMessage } = useTranslation();

  const monthNames = [
    formatMessage({ id: 'CalendrierLibelleMoisJanvier' }),
    formatMessage({ id: 'CalendrierLibelleMoisFevrier' }),
    formatMessage({ id: 'CalendrierLibelleMoisMars' }),
    formatMessage({ id: 'CalendrierLibelleMoisAvril' }),
    formatMessage({ id: 'CalendrierLibelleMoisMai' }),
    formatMessage({ id: 'CalendrierLibelleMoisJuin' }),
    formatMessage({ id: 'CalendrierLibelleMoisJuillet' }),
    formatMessage({ id: 'CalendrierLibelleMoisAout' }),
    formatMessage({ id: 'CalendrierLibelleMoisSeptembre' }),
    formatMessage({ id: 'CalendrierLibelleMoisOctobre' }),
    formatMessage({ id: 'CalendrierLibelleMoisNovembre' }),
    formatMessage({ id: 'CalendrierLibelleMoisDecembre' }),
  ];

  const dayNames = [
    formatMessage({ id: 'CalendrierLibelleJourDimanche' }),
    formatMessage({ id: 'CalendrierLibelleJourLundi' }),
    formatMessage({ id: 'CalendrierLibelleJourMardi' }),
    formatMessage({ id: 'CalendrierLibelleJourMercredi' }),
    formatMessage({ id: 'CalendrierLibelleJourJeudi' }),
    formatMessage({ id: 'CalendrierLibelleJourVendredi' }),
    formatMessage({ id: 'CalendrierLibelleJourSamedi' }),
  ];

  const dayNamesShort = [
    formatMessage({ id: 'CalendrierLibelleCourtJourDimanche' }),
    formatMessage({ id: 'CalendrierLibelleCourtJourLundi' }),
    formatMessage({ id: 'CalendrierLibelleCourtJourMardi' }),
    formatMessage({ id: 'CalendrierLibelleCourtJourMercredi' }),
    formatMessage({ id: 'CalendrierLibelleCourtJourJeudi' }),
    formatMessage({ id: 'CalendrierLibelleCourtJourVendredi' }),
    formatMessage({ id: 'CalendrierLibelleCourtJourSamedi' }),
  ];

  return {
    monthNames,
    dayNames,
    dayNamesShort,
  };
}
