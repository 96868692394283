import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import { createLogger } from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import AsyncStorage from '@react-native-async-storage/async-storage';

import createSagaMiddleware from 'redux-saga';

import { REACT_APP_ENV } from '@config/index';
import { EnvironnementNameEnum } from '@constants/env';
import rootReducer from '@modules/reducers';
import sagas from '@modules/sagas';

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
  version: 0,
  stateReconciler: autoMergeLevel2,
  blacklist: ['error', 'isLoading', 'notificationSystem'],
};

const pReducer = persistReducer<any, any>(persistConfig, rootReducer());

const sagaMiddleware = createSagaMiddleware();

const middleware: any[] = [sagaMiddleware];

// only add logger on UAT_TEMP env to prevent too many and unsecure log on others env
const isEnvAcceptLogger = [
  EnvironnementNameEnum.UAT_TEMP as string,
].includes(REACT_APP_ENV);
if (isEnvAcceptLogger) {
  middleware.push(createLogger({ collapsed: true }));
}

const store = createStore(
  pReducer,
  composeWithDevTools(
    applyMiddleware(...middleware),
  ),
);

const pstore = persistStore(store);

sagaMiddleware.run(sagas);

export { store, pstore };
