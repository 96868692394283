import axios from "axios";

import { API_URL } from "@config/index";
import { SimulatorType } from "@constants/sapiendo";

import {
  RedirectOperationResponsePayload,
  SapiendoRedirectUrlResponsePayload,
} from "./types";

export async function fetchIntialOperationRedirectUrl(
  companyId: string,
  operationType: string
): Promise<RedirectOperationResponsePayload> {
  return axios.get(
    `${API_URL}/api/v1/companies/${companyId}/operationType/${operationType}`
  );
}
export async function fetchOperationRedirectUrl(companyId: string, operationType: string, provider: string): Promise<string> {
  return axios.get(`${API_URL}/api/v1/companies/${companyId}/operationType/${operationType}/${provider}`);
}

export async function fetchSapiendoRedirectUrl(
  simulatorType: SimulatorType,
  companyId: string,
): Promise<SapiendoRedirectUrlResponsePayload> {
  return axios.get(`${API_URL}/api/v1/tools/sapiendo/${companyId}/simulation-url/${simulatorType}`);
}
