import React, { useCallback, useState } from 'react';
import { View } from 'react-native';

import { Title, SVGLocalLoader } from '@ere-uilib/atoms';
import { CheckBox } from '@ere-uilib/molecules';
import { useScreenSizes } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';

import { useStyles } from './useStyles';
import { RetirementDecisionTabFormValues, Props } from './interfaces';
import { useController } from '../useController';

export const TabForm = ({ getFormValues, initialFormValues, totalAmount }: Props) => {
  const styles = useStyles();
  const { formatCurrencyNumber } = useTranslation();
  const { isMobile } = useScreenSizes()
  const [formValues, setFormValues] = useState<RetirementDecisionTabFormValues>(initialFormValues);
  const { tabDictionary } = useController();
  const textVariant = isMobile ? 't8' : 't7'

  const handleAnnuityPress = useCallback((managementUuid, compartmentUuid) => {
    const isAnnuityEligible = formValues[managementUuid].content[compartmentUuid].annuityEligible
    const newFormValues = {
      ...formValues,
      [managementUuid]: {
        ...formValues[managementUuid],
        content: {
          ...formValues[managementUuid].content,
          [compartmentUuid]: {
            ...formValues[managementUuid].content[compartmentUuid],
            annuityEligible: !isAnnuityEligible,
          }
        },
      },
    };

    setFormValues(newFormValues);
    getFormValues?.(newFormValues)
  }, [formValues, getFormValues]);

  const handleCapitalPress = useCallback((managementUuid, compartmentUuid) => {
    const isCapitalEligible = formValues[managementUuid].content[compartmentUuid].capitalEligible
    const newFormValues = {
      ...formValues,
      [managementUuid]: {
        ...formValues[managementUuid],
        content: {
          ...formValues[managementUuid].content,
          [compartmentUuid]: {
            ...formValues[managementUuid].content[compartmentUuid],
            capitalEligible: !isCapitalEligible,
          }
        },
      },
    };

    setFormValues(newFormValues);
    getFormValues?.(newFormValues)
  }, [formValues, getFormValues]);

  const renderCheck = useCallback((isChecked, isEditable, onPress) => {
    return (
      <View style={styles.center}>
        {isEditable && (
          <CheckBox
            onPress={onPress}
            value={isChecked}
          />
        )}
        {!isEditable && isChecked && (
          <View
            style={{ width: 15, height: 12 }}
          >
            <SVGLocalLoader
              name='ligthCheck'
            />
          </View>
        )}
      </View>
    )
  }, [styles]);

  const renderHeaderLeft = useCallback(() => (
    <View style={styles.tabLeft}>
      {/* HEADER LEFT TOP */}
      <View style={styles.tabHeaderLeftTop}>
        <View>
          <Title
            style={styles.textWhite}
            variant='t8'
            weight='light'
          >
            {tabDictionary.mySavings}
          </Title>
        </View>
        <View>
          <Title
            style={styles.textWhite}
            variant='t8'
            weight='light'
          >
            {tabDictionary.grossAmount}
          </Title>
        </View>
      </View>
      {/* TAB HEADER LEFT BOTTOM */}
      <View style={styles.tabHeaderLeftBottom}>
        <View style={styles.headerBottomContentRow}>
          <Title
            style={styles.tabHeaderColumnSubTitle}
            variant='t8'
          >
            {formatCurrencyNumber({ value: totalAmount })}
          </Title>
        </View>
      </View>
    </View >
  ), [tabDictionary, formatCurrencyNumber, styles, totalAmount]);

  const renderHeaderRight = useCallback(() => (
    <View style={styles.tabHeaderRight}>
      <View style={styles.tabHeaderRightTop}>
        <Title
          style={styles.textWhite}
          variant='t8'
          weight='light'
        >
          {tabDictionary.exit}
        </Title>
      </View>
      <View style={styles.tabHeaderRightBottom}>
        <View style={styles.center}>
          <Title
            style={styles.tabHeaderColumnSubTitle}
            variant='t8s'
          >
            {tabDictionary.asset}
          </Title>
        </View>
        <View style={styles.center}>
          <Title
            style={styles.tabHeaderColumnSubTitle}
            variant='t8s'
          >
            {tabDictionary.pension}
          </Title>
        </View>
      </View>
    </View>
  ), [tabDictionary, styles]);

  const renderHeader = useCallback(() => (
    <View style={styles.tabHeader}>
      {/* TAB HEADER LEFT */}
      {renderHeaderLeft()}
      {/* TAB HEADER RIGHT */}
      {renderHeaderRight()}
    </View>
  ), [renderHeaderLeft, renderHeaderRight, styles]);

  const renderContentRowTitle = useCallback(formItem => (
    <View style={styles.tabContentRowSection}>
      {/* TAB CONTENT ROW LEFT */}
      <View style={[styles.tabLeft, styles.tabContentRowLeft]}>
        {/* TAB CONTENT ROW LEFT TITLE */}
        <View>
          <Title
            style={styles.textDark}
            variant={textVariant}
            weight='bold'
          >
            {formItem.title}
          </Title>
        </View>
      </View>
      {/* TAB CONTENT ROW RIGHT */}
      <View style={styles.tabContentRowRight} />
    </View>
  ), [styles, textVariant]);

  const renderContentRowItemLeft = useCallback(formItemValue => (
    <View style={[styles.tabLeft, styles.tabContentRowLeft, styles.tabContentRowItem]}>
      {/* TAB CONTENT ROW LEFT ITEM LABEL */}
      <View style={styles.flex}>
        <Title
          style={styles.textGrey}
          variant={textVariant}
          weight='regular'
        >
          {formItemValue.label}
        </Title>
      </View>
      <View>
        <Title
          style={styles.textGrey}
          variant={textVariant}
          weight='regular'
        >
          {formatCurrencyNumber({ value: formItemValue.value })}
        </Title>
      </View>
    </View>
  ), [formatCurrencyNumber, styles, textVariant]);

  const renderContentRowItemRight = useCallback((formItemValue, formItem) => {
    const handleAnnuityItemPress = () => handleAnnuityPress(formItem.uuid, formItemValue.uuid);
    const handleCapitalItemPress = () => handleCapitalPress(formItem.uuid, formItemValue.uuid);

    return (
      <View style={styles.tabContentRowRight}>
        {renderCheck(formItemValue.capitalEligible, formItemValue.isCapitalEditable, handleCapitalItemPress)}
        {renderCheck(formItemValue.annuityEligible, formItemValue.isAnnuityEditable, handleAnnuityItemPress)}
      </View>
    )
  }, [handleAnnuityPress, handleCapitalPress, renderCheck, styles]);

  const renderContentRowItem = useCallback(formItem => (
    Object.values(formItem.content).map(formItemValue => (
      <View style={styles.tabContentRowSection}>
        {renderContentRowItemLeft(formItemValue)}
        {renderContentRowItemRight(formItemValue, formItem)}
      </View>
    ))
  ), [renderContentRowItemLeft, renderContentRowItemRight, styles])

  const renderContent = useCallback(() => (
    <View>
      {Object.values(formValues).map(formItem => (
        <View style={styles.tabContentRow}>
          {renderContentRowTitle(formItem)}
          {renderContentRowItem(formItem)}
        </View>
      ))}
    </View>
  ), [
    formValues,
    renderContentRowItem,
    renderContentRowTitle,
    styles,
  ]);

  return (
    <View style={styles.tabContainer}>
      {renderHeader()}
      {renderContent()}
    </View>
  )
};
