import { StylesContext, SavingsOperationsHistoryCardStyles } from './interface';

export const getStyles = (
  context?: StylesContext,
  style?: SavingsOperationsHistoryCardStyles,
): SavingsOperationsHistoryCardStyles => ({
  containerStyle: [
    {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingHorizontal: context?.theme.metrics.spacing.s,
      paddingVertical: context?.theme.metrics.spacing.m,
      marginBottom: context?.theme.metrics.spacing.xm,
      borderRadius: context?.theme.metrics.spacing.xs,
      backgroundColor: context?.theme.colors.basics.grey.c100
    },
    style?.containerStyle
  ],
  leftContainerStyle: [
    {
      display: 'flex',
      flexDirection: 'column',
      flex: 1
    },
    style?.leftContainerStyle
  ],
  rightContainerStyle: [
    {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    style?.rightContainerStyle
  ],
  amountContainerStyle: [
    {
      display: 'flex',
      alignSelf: 'flex-start',
      alignItems: 'flex-end',
      marginRight: context?.theme.metrics.spacing.s
    },
    style?.amountContainerStyle
  ],
  statusStyle: [
    {
      marginTop: context?.theme.metrics.spacing.xs
    },
    style?.statusStyle
  ]
});
