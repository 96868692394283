import axios from 'axios'

import { API_URL } from '@config/index';
import {
  EstimateCalculContributionApiResponse,
  EstimateCalculContributionFetchParamsType,
  InstallmentPaymentFrequencyEnum
} from '@constants/index';
import { FilterPropertiesType } from '@modules/common/types';
import {
  InstallmentPaymentEndpointSendDataType,
  InstallmentState,
  InstallmentPaymentInputType
} from '@modules/installment/types'

interface FetchInstallmentDataResponse<T> {
  data: T;
}

const AXIOS_REQUEST_CONFIG = {
  headers: {
    'Content-Type': 'application/json'
  }
};

export async function fetchInstallmentRepartitionData(companyId: string, planId: string, filters:FilterPropertiesType): Promise<FetchInstallmentDataResponse<InstallmentState>> {
  return axios.post(
    `${API_URL}/api/v1/companies/${companyId}/plans/${planId}/payment`,
    { filters }
  );
}

export async function fetchInstallmentPaymentModeAndBankAccountDetailData(
  companyId: string,
  planId: string,
  paymentFrequency: InstallmentPaymentFrequencyEnum
): Promise<InstallmentState> {
  return axios.post(
    `${API_URL}/api/v1/companies/${companyId}/versements/paymentmethod`,
{
      planId,
      paymentFrequency
    }
  )
}

interface FetchInstallmentsDecisionResponse {
  data: InstallmentState
}
export async function fetchInstallmentsDecision(
  companyId: string
): Promise<FetchInstallmentsDecisionResponse> {
  return axios.get(`${API_URL}/api/v1/companies/${companyId}/plans?operationType=Versements`)
}

export async function fetchSendInstallmentPayment({
  installmentPaymentEndpointData,
  companyId,
  planId
}: InstallmentPaymentInputType): Promise<{}> {
  return axios.post(
    `${API_URL}/api/v1/demands/payment/company/${companyId}/plan/${planId}`,
    installmentPaymentEndpointData
  )
}

export async function fetchCheckInstallmentPaymentStatusForId(id: string): Promise<{}> {
  return axios.get(`${API_URL}/api/v1/demands/${id}/payment-status`)
}

export async function fetchDeleteVVPById({
  companyId,
  planId,
  operationId
}: {
  companyId: string
  planId: string
  operationId: string
}): Promise<{}> {
  return axios.post(
    `${API_URL}/api/v1/demands/payment/company/${companyId}/plan/${planId}/delete`,
    JSON.stringify(operationId),
    AXIOS_REQUEST_CONFIG
  )
}

export async function fetchEstimateCalculContributionData({
  companyId,
  planId,
  distributions,
  operationType
}: EstimateCalculContributionFetchParamsType): Promise<
  FetchInstallmentDataResponse<EstimateCalculContributionApiResponse[]>
> {
  return axios.post(
    `${API_URL}/api/v1/companies/estimatecontribution`,
    {
      companyId,
      planId,
      distributions,
      enumContribTransactionType: operationType
    }
  );
}

