import * as actionLegal from './actions/legalActions';
import * as actionLegalTypes from './actions/legalActionsTypes';
import { LegalActionsType } from './actions/legalActionsTypes';
import { runManager } from '@modules/moduleManager';
import { getUser } from '@modules/auth/selectors';
import { UserState } from '@modules/auth/types';
import { fetchLegalContent, fetchMenuFooter } from './services';
import dayjs from 'dayjs';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

function* onLoadLegalMenuFooter(): any {
  yield put(actionLegal.legalInitError());
    const response = yield call(fetchMenuFooter);
    yield put(actionLegal.getMenuFooterSuccess(response.data));
  }

function* onLoadLegalPageContent(
  action: actionLegalTypes.GetLegalContentRequestAction,
): any {
  yield put(actionLegal.legalInitError());
  const user: UserState = yield select(getUser);
  // check connexion valid state
  const expiresAt = user?.expires_at || 0;
  const sessionExpireAt = dayjs((expiresAt * 1000)).format();
  const currentTime = dayjs().format();
  const isUserSesssionExpired = expiresAt === 0 || currentTime >= sessionExpireAt;
  const isLogged = !isUserSesssionExpired;

  const response = yield call(fetchLegalContent, action.code, isLogged);
  const legalContent = isLogged ? response?.data?.[0] : response?.data;
  yield put(actionLegal.getLegalContentSuccess(legalContent));
}

function* getLegalContentSagas(): any {
  yield takeLatest(
    LegalActionsType.GET_LEGAL_CONTENT_REQUEST,
    runManager(onLoadLegalPageContent, LegalActionsType.GET_LEGAL_CONTENT_FAILURE)
  );
}

function* getLegalMenuFooterSagas(): any {
  yield takeLatest(
    LegalActionsType.GET_MENU_FOOTER_REQUEST,
    runManager(onLoadLegalMenuFooter, LegalActionsType.GET_MENU_FOOTER_FAILURE)
  );
}

export function* LegalSagas() {
  yield all([fork(getLegalContentSagas), fork(getLegalMenuFooterSagas)]);
}
