import { useMemo } from "react";
import { StyleSheet } from "react-native";

import { useScreenSizes, useTheme } from "@ere-uilib/styles";

export const useStyles = () => {
  const theme = useTheme();
  const { isMobile } = useScreenSizes();

  const style = useMemo(() => StyleSheet.create({
    containerWrapper: {
      marginVertical: theme.metrics.spacing.xs,
    },
    containerStyle: {
      flexDirection: 'row'
    },
    bottomContainer: {
      marginTop: theme.metrics.spacing.xm,
      flexDirection: 'row',
      justifyContent: 'center',
    },
    bottomTitle: {
      textTransform: 'uppercase',
      marginRight: theme.metrics.spacing.s,
      width: theme.metrics.iconSizes.xxxl,
    },
    svgContainer: {
      width: theme.metrics.iconSizes.xxxl,
      marginRight: theme.metrics.spacing.s,
      minHeight: isMobile ? theme.metrics.iconSizes.mplus : theme.metrics.iconSizes.xxxl
    },
    textStyle: {
      flex: 3,
      textAlign: 'justify'
    },
    cardResponseContainer: {
      marginTop: isMobile
        ? theme.metrics.spacing.xm
        : theme.metrics.spacing.xl,
    },
    headerAmountStyle: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      minWidth: '20%',
    },
    headerTitleStyle: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      maxWidth: '85%',
    },
  }), [theme, isMobile]);

  return style;
};
