import React, { useCallback, useState } from 'react';
import { View, StyleProp, ViewStyle, TextStyle } from 'react-native';

import {
  Paragraph,
  Title,
  SVGLocalLoader,
  Pressable,
} from '@ere-uilib/atoms';
import { createUseStyles, useTheme, ThemeType } from '@ere-uilib/styles';

interface Props extends Styles {
  containerStyle?: StyleProp<ViewStyle>;
  title: string;
  description?: string;
  imageName: string;
  isSmall?: boolean;
  isWhite?: boolean;
  hasShadow?: boolean;
  onPress?: () => void;
  isHovered?: boolean;
  testId?: string
}

type Styles = {
  containerStyle?: StyleProp<ViewStyle>;
  descriptionTextStyle?: StyleProp<TextStyle>;
  imageStyle?: StyleProp<ViewStyle>;
};

type StylesContext = {
  theme: ThemeType
  isSmall: boolean;
  isWhite: boolean;
  hasShadow: boolean;
  pressed: boolean;
};

export const CardImageTitle: React.FC<React.PropsWithChildren<Props>> = ({
  containerStyle,
  title,
  description,
  imageName,
  isSmall = false,
  isWhite = false,
  hasShadow = false,
  onPress,
  isHovered,
  testId
}) => {
  const theme = useTheme();
  const [hovered, setHovered] = useState(false);
  const [pressed, setPressed] = useState<boolean>(false);
  const styles = useStyles({
    theme,
    isSmall,
    isWhite,
    hasShadow: hasShadow || hovered,
    pressed,
  }, {
    containerStyle,
  });
  const renderTitle = useCallback(() => {
    return (
      title &&
      <Title
        testID={`${testId}_title`}
        accessibilityLabel={`${testId}_title`}
        variant={isSmall ? 't6' : 't5s'}
        weight="bold"
      >
        {title}
      </Title>
    );
  }, [
    isSmall,
    title,
  ]);
  const renderDescription = useCallback(() => {
    return (
      description &&
      <Paragraph
        testID={`${testId}_description`}
        accessibilityLabel={`${testId}_description`}
        style={styles.descriptionTextStyle}
        variant={isSmall ? 't3' : 't2'}
        weight="light"
      >
        {description}
      </Paragraph>
    );
  }, [
    description,
    isSmall,
    styles,
  ]);
  const renderImage = useCallback(() => {
    return (
      <View 
      testID={`${testId}_image`}
      accessibilityLabel={`${testId}_image`}
      style={styles.imageStyle}>
        <SVGLocalLoader
          name={imageName}
        />
      </View>
    );
  }, [
    imageName,
    styles,
  ]);

  return (
    <Pressable
      onPressIn={() => setPressed(true)}
      onPressOut={() => setPressed(false)}
      onHoverIn={() => setHovered(true)}
      onHoverOut={() => setHovered(false)}
      disabled={!onPress}
      onPress={onPress}
      style={styles.containerStyle}
    >
      {renderTitle()}
      {renderDescription()}
      {renderImage()}

    </Pressable>
  );
};

const getStyles = (context?: StylesContext, style?: Styles): Styles => ({
  containerStyle: [
    {
      // flex: 1,
      borderRadius: context?.theme.metrics.borderRadius.xs,
      flexDirection: 'column',
      alignItems: 'center',
      opacity: context?.pressed ? 0.5 : 1
    },
    context?.isSmall ? {
      paddingHorizontal: context?.theme.metrics.spacing.s,
      paddingVertical: context?.theme.metrics.spacing.m,
    } : {
      paddingHorizontal: context?.theme.metrics.spacing.xm,
      paddingVertical: context?.theme.metrics.spacing.l,
    },
    context?.isWhite ? {
      backgroundColor: context?.theme.colors.basics.white,
    } : {
      backgroundColor: context?.theme.colors.basics.grey.c100,
    },
    context?.hasShadow && {
      shadowColor: context?.theme.colors.basics.grey.c900,
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.15,
      shadowRadius: 5,
      elevation: 7,
    },
    style?.containerStyle,
  ],
  descriptionTextStyle: [
    {
      textAlign: 'center',
      marginTop: context?.theme.metrics.spacing.xs,
    },
    style?.descriptionTextStyle,
  ],
  imageStyle: [
    {
      alignSelf: 'center',
    },
    context?.isSmall ? {
      width: 245,
      height: 110,
      marginTop: context?.theme.metrics.spacing.m,
    } : {
      width: 300,
      height: 150,
      marginTop: context?.theme.metrics.spacing.l,
    },
    style?.imageStyle,
  ]
});

const useStyles = createUseStyles(getStyles);
