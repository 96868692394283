import {
  ColumnsContainer,
  DashboardPageWrapperConnected,
  DialOncePressable,
  HelpButton,
  OnboardingStepper,
  OperationsNotificationErrorsWrapperList,
  SimpleButton,
  useScreenSizes,
  useTheme,
  useTranslation
} from '@components/index'
import { createUseStyles } from '@ere-uilib/styles'

import { ArbitrationTypes, STEPS } from '@constants/index'
import { RouteNames } from '@constants/navigation'
import { constructStepperItems } from '@pages/Common/utils'
import React, { useEffect } from 'react'
import { View } from 'react-native'
import { ArbitrationHeader } from '../components'
import { ArbitrationOnboardingPageProps } from './interfaces'
import { getStyles } from './styles'
import { useTracking } from '@hooks/useTracking'
import { locator } from '@constants/locator'
import { useNavigation } from '@react-navigation/native';

export const ArbitrationOnboardingPageComponent: React.FC<ArbitrationOnboardingPageProps> = ({
  isLoading,
  ineligibleDispositifs,
  arbitrationAllocation,
  applicationSettings,
  reallocationType,
  atInternetClientIdSession
}) => {
  const { formatMessage, getMessageRaw } = useTranslation()
  const theme = useTheme()
  const { isMobile, isTablet } = useScreenSizes()
  const navigation = useNavigation()

  const style = useStyles(
    {
      theme,
      isMobile: isMobile || isTablet
    },
    {}
  )
  const isFlowArbitration = reallocationType === ArbitrationTypes.flow

  const isAtLeastOneDispotifiEligible =
    reallocationType === ArbitrationTypes.flow
      ? arbitrationAllocation.isEligibleFlow
      : arbitrationAllocation.isEligibleStock

  // filter no eligible dispositifs without blocage code to prevent displaying
  const noEligibleDispositifs =
    reallocationType === ArbitrationTypes.flow
      ? ineligibleDispositifs.flowDispositifs.filter(
        dispositif => !dispositif?.advancedProperties?.IsExternal && dispositif?.blocagesCodes && dispositif?.blocagesCodes?.length > 0
      )
      : ineligibleDispositifs.stockDispositifs.filter(
        dispositif => !dispositif?.advancedProperties?.IsExternal && dispositif?.blocagesCodes && dispositif?.blocagesCodes?.length > 0
      )

  useEffect(() => {
    const sentTag = {
      name: 'Parcours Arbitrage',
      chapter1: '',
    };
    switch (reallocationType) {
      case ArbitrationTypes.stock:
        sentTag.chapter1 = 'Intro_Stock';
        break;
      case ArbitrationTypes.flow:
        sentTag.chapter1 = 'Intro_Flow';
        break;
    }
    useTracking(sentTag, atInternetClientIdSession || '')
  }, []);

  const renderHeader = () => {
    return (
      <>
        <ArbitrationHeader
          displayCloseButton
          helpPoint
          helpPointContentHtml={getMessageRaw({
            id: 'Arbitrage_choixDispositifsAide_description'
          })}
          helpPointModalTitle={formatMessage({
            id: 'Arbitrage_choixDispositifsAide_titre'
          })}
          helpPointPosition="left"
          title={
            isFlowArbitration
              ? formatMessage({
                id: 'Arbitrage_optimiserEpargne_aVenir_title'
              })
              : formatMessage({
                id: 'Arbitrage_optimiserEpargne_actuel_title'
              })
          }
        />
      </>
    )
  }

  const handlePress = () => {
    navigation.navigate(RouteNames.ArbitrationStack, {
      screen: RouteNames.ArbitrationPlanChoice
    })
  }

  const renderSubmitButton = () => {
    return (
      <View style={style.buttonContainer}>
        <SimpleButton
          disabled={isLoading}
          onPress={handlePress}
          title={formatMessage({ id: 'Arbitrage_boutonCestParti' })}
          testId={locator._arbitration._lets_go}
        />
      </View>
    )
  }

  const stepperItems = constructStepperItems({
    size: 3,
    formatMessage,
    messageIds: STEPS
  })
  const renderDispositifError = () => {
    if (isAtLeastOneDispotifiEligible && noEligibleDispositifs.length === 0) {
      return <></>
    }

    return (
      noEligibleDispositifs.length > 0 && (
        <OperationsNotificationErrorsWrapperList
          cardTitleLabelCode={'Arbitrage_blocage_dispositifName'}
          containerStyle={!isAtLeastOneDispotifiEligible && style.blockedPageContainer}
          dispositifs={noEligibleDispositifs}
          isExpandable={isAtLeastOneDispotifiEligible}
          subtitle={formatMessage({ id: 'Arbitrage_blocage_warning_subtitle' })}
          title={formatMessage({
            id: 'Arbitrage_blocage_warning_title'
          })}>
          <DialOncePressable
            integrationKey={applicationSettings?.HelpDialOnceIntegrationKey || ''}
            targetedFlowId={applicationSettings?.HelpActionMenuDialOnceId || ''}>
            <HelpButton
              containerStyle={style.dialOnceHelpButton}
              onPress={() => { }}
              title={formatMessage({ id: 'session_security_help_contact_button' })}
            />
          </DialOncePressable>
        </OperationsNotificationErrorsWrapperList>
      )
    )
  }

  return (
    <DashboardPageWrapperConnected cardContentStyle={style.dashboardWrapper}>
      {
        <ColumnsContainer
          hasRenderRightDesktopColumn={isAtLeastOneDispotifiEligible}
          renderHeader={() => renderHeader()}
          renderRightDesktopColumn={renderDispositifError}>
          {isAtLeastOneDispotifiEligible ? (
            <>
              <OnboardingStepper
                stepperItems={stepperItems}
                title={formatMessage({ id: 'Arbitrage_SousTitreParcours' })}
              />
              {(isMobile || isTablet) && renderDispositifError()}
              {renderSubmitButton()}
            </>
          ) : (
            renderDispositifError()
          )}
        </ColumnsContainer>
      }
    </DashboardPageWrapperConnected>
  );

}

const useStyles = createUseStyles(getStyles)
