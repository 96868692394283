import { useMemo } from 'react';
import { StyleSheet } from "react-native";

import { useTheme } from '@ere-uilib/styles';

export const useStyles = (isMobileOrHasOperations: boolean) => {
  const theme = useTheme();

  const styles = useMemo(() => StyleSheet.create({
    remaningSavingsStyle: {
      flex: 1
    },
    rowStyle: {
      marginLeft: isMobileOrHasOperations ? theme.metrics.spacing.s : undefined,
      flexDirection: !isMobileOrHasOperations ? 'row' : undefined,
      paddingLeft: !isMobileOrHasOperations ? theme.metrics.spacing.l : undefined,
      paddingRight: !isMobileOrHasOperations ? theme.metrics.spacing.l : undefined,
    },
    subtitleStyle: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: !isMobileOrHasOperations ? theme.metrics.spacing.xs : undefined,
    },
    legend: {
      alignSelf: 'flex-end',
      marginTop: theme.metrics.spacing.s,
      color: theme.colors.basics.grey.c500,
      display: !isMobileOrHasOperations ? "none" : undefined,
    },
  }), [theme, isMobileOrHasOperations]);

  return styles;
};

