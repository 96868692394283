import { useMemo } from "react"
import { StyleSheet } from "react-native"

import { useScreenSizes, useTheme } from "@ere-uilib/styles";

export const useStyles = () => {
  const theme = useTheme();
  const { isDesktop } = useScreenSizes();

  const styles = useMemo(() => StyleSheet.create({
    container: {

    },
    title: {
      marginBottom: theme?.metrics.spacing.m
    },
    contentContainer: {
      marginTop: theme?.metrics.spacing.m
    },
    formContainer: {
      marginBottom: theme?.metrics.spacing.m,
      width: isDesktop ? '60%' : '100%',
      backgroundColor: theme?.colors.basics.grey.c100,
      borderWidth: 1,
      borderColor: theme?.colors.basics.grey.c200,
      borderRadius: theme?.metrics.spacing.xs,
      paddingHorizontal: theme?.metrics.spacing.s,
      paddingVertical: theme?.metrics.spacing.xm,
    },
    formTitle: {
      marginBottom: theme?.metrics.spacing.s,
    },
    formItemContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    formItemTxt: {
      lineHeight: theme?.metrics.spacing.xm,
      color: theme?.colors.basics.black,
    },
    buttonStyle: {
      alignSelf: 'flex-start',
      marginVertical: theme?.metrics.spacing.xs,
      maxWidth: '100%'
    },
    warningTxt: {
      marginVertical: theme.metrics.spacing.xs
    }
  }), [theme, isDesktop]);

  return styles;
}
