import React from 'react';
import { StyleProp, TextStyle, ViewStyle } from 'react-native';
export interface TableCellInterface {
  type: TableCellTypeEnum
  text?: string | null
  children?: React.ReactNode
  containerStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
}

export enum TableCellTypeEnum {
  DEFAULT,
  HEADER,
  ENTRIES,
  EMPTY,
  DASHED,
}
