import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useScreenSizes, useTheme } from '@ere-uilib/styles';

export const useStyles = () => {
  const theme = useTheme();
  const { isMobile, isDesktop } = useScreenSizes();

  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          position: 'absolute',
          width: '100%',
          height: '100%',
          justifyContent: isMobile ? 'flex-end' : 'center',
          alignItems: 'center',
          backgroundColor: theme.colors.background.poppinFilter.c500
        },
        modalFrame: {
          ...(isDesktop && {
            maxWidth: theme.metrics.breakpoints.desktop
          })
        },
        modalFrameInnerContainer: {
          flex: 1
        },
        headerContainer: {
          borderBottomWidth: 0
        },
        headerTitleContainer: {
          paddingHorizontal: theme.metrics.spacing.xs
        }
      }),
    [theme, isMobile, isDesktop]
  );

  return styles;
};
