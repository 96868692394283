import React, { useCallback } from 'react';
import { View } from 'react-native';

import { NotificationIconTypeEnum } from '@ere-uilib/atoms';
import { NotificationHard } from '@ere-uilib/molecules';
import { useTranslation } from '@ere-uilib/translations';
import { Notification, NotificationSystemNameEnum } from '@ere-uilib/types/notificationSystemTypes';

import { useStyles } from './useStyles';

interface Props {
  onTimeout: (uid: string) => void;
  onPressCloseButton: (uid: string) => void;
  notifications: Notification[];
}

export function NotificationSystem({
  notifications,
  onPressCloseButton,
  onTimeout,
}: Props) {
  const styles = useStyles();
  const { formatMessage } = useTranslation();
  const renderNotification = useCallback(
    (notification: Notification) => {
      const handlePressCloseButton = () => onPressCloseButton(notification.uid);

      if (notification.timeout) {
        setTimeout(() => {
          onTimeout(notification.uid);
        }, notification.timeout);
      }

      switch (notification.name) {
      case NotificationSystemNameEnum.CONTRIBUTION_ERROR:
        return (
          <NotificationHard
            containerStyle={styles.notificationItem}
            key={notification.uid}
            onPressCloseButton={handlePressCloseButton}
            showCloseButton
            text={formatMessage({ id: 'VV_repartition_contribution_notification_label' })}
            title={formatMessage({ id: 'VV_repartition_contribution_error_title' })}
            type={NotificationIconTypeEnum.WARNING}
          />
        );

      case NotificationSystemNameEnum.ARBITRATION_NO_DESTINATION_ERROR:
        return (
          <NotificationHard
            containerStyle={[styles.notificationItem]}
            key={notification.uid}
            onPressCloseButton={handlePressCloseButton}
            showCloseButton

            title={formatMessage({ id: 'ArbitrageReinvestNoDestinationErreur' })}
            type={NotificationIconTypeEnum.WARNING}
          />
        );
      default:
        return null;
      }
    }, [formatMessage, onPressCloseButton, onTimeout, styles]);
  return (
    <View style={styles.container}>
      {notifications.map(notification => {
        return renderNotification(notification);
      })}
    </View>
  );
}
