import React, { ReactNode } from 'react';
import { View, StyleProp, ViewStyle } from 'react-native';

import { StepperSizeEnum } from '@ere-uilib/molecules/steppers/InstallmentsStepper/interfaces';
import { createUseStyles, useTheme, ThemeType } from '@ere-uilib/styles';

interface RoundedContainerProps {
  variant?: StepperSizeEnum
  isActif?: boolean
  children: ReactNode
  containerStyle?: StyleProp<ViewStyle>
}

interface RoundedContainerStyles {
  containerStyle?: StyleProp<ViewStyle>
}

type StyleContext = {
  theme: ThemeType,
  isActif?: boolean,
  variant?: StepperSizeEnum
}

export const RoundedContainer:React.FC<React.PropsWithChildren<RoundedContainerProps>> = ({
  children,
  containerStyle,
  isActif,
  variant
})=>{
  const theme = useTheme();
  const styles = useStyle(
    {
      theme,
      isActif,
      variant
    }, {
      containerStyle
    });
  return (
    <View style={styles.containerStyle}>
      {children}
    </View>
  );
};

const getStyles = (
  context?: StyleContext,
  style?: RoundedContainerStyles
): RoundedContainerStyles =>({
  containerStyle: [
    {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: context?.variant === 'small'
        ? context?.theme.metrics.contentSizes.installmentSmallStepperCircle
        : context?.theme.metrics.contentSizes.installmentBigStepperCircle,
      width: context?.variant === 'small'
        ? context?.theme.metrics.contentSizes.installmentSmallStepperCircle
        : context?.theme.metrics.contentSizes.installmentBigStepperCircle,
      borderRadius: context?.theme.metrics.borderRadius.full,
      backgroundColor: context?.isActif ? context?.theme.colors.basics.primary.c500 : context?.theme.colors.basics.grey.c200
    },
    style?.containerStyle
  ]
});

const useStyle = createUseStyles(getStyles);
