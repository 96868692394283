import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { SelfcareTextIdentifierFormTemplate } from '@components/templates';
import { InvitationRegistrationOriginEnum } from '@constants/invitation';
import { useCalendarLabels } from '@ere-uilib/hooks';
import { useTranslation } from '@ere-uilib/translations';
import { useInvitation } from '@hooks/useInvitation';
import { useSettings } from '@hooks/useSettings';
import { createSelfcareInvitationResetError } from '@modules/invitation/actions/invitationActions';

interface Props {
  onPressBackButton?: () => void;
  onClose: () => void;
}

export function VisiogoIdentifierFormPageContainer({ onPressBackButton, onClose }: Props) {
  const { formatMessage, getMessageRaw } = useTranslation();
  const { monthNames, dayNames, dayNamesShort } = useCalendarLabels();
  const { createSelfcareInvitationFromExternalIdentityRequest, isLoading, errorMessage } =
    useInvitation();
  const { optionEERRegexVisiogo } = useSettings();
  const dispatch = useDispatch();

  const dictionary = useMemo(
    () => ({
      identifierInputPlaceholder: formatMessage({
        id: 'EerSelfcareStep2VisiogoPlaceholder'
      }),
      confirmButton: formatMessage({ id: 'EerSelfcareStep2ConfirmerButton' }),
      helpText: formatMessage({
        id: 'EerSelfcareStep2OuTrouverLabel'
      }),
      helpLabel: formatMessage({
        id: 'EerSelfcareStep2VisiogoLabel'
      }),
      dateOfBirth: formatMessage({
        id: 'EerSelfcareStep2DateNaissanceLabel'
      }),
      errorHelper: formatMessage({
        id: 'EerSelfcareStep3CompteIntrouvableErreur'
      }),
      reedMore: formatMessage({
        id: 'EerSelfcareStep3CompteIntrouvableLink'
      }),
      helpPointContentHtml: getMessageRaw({
        id: 'EerSelfcareStep2OuTrouverPopinVisiogoContent'
      }),
      helpPointModalTitle: formatMessage({
        id: 'EerSelfcareStep2OuTrouverPopinTitle'
      }),
      errorReadMoreContentHtml: getMessageRaw({
        id: 'EerSelfcareStep2VisiogoInfoContent'
      }),
      errorReadMoreModalTitle: formatMessage({
        id: 'EerSelfcareStep2VisiogoInfoTitle'
      }),
      inputHelpPointContentHtml: getMessageRaw({
        id: 'EerSelfcareStep2OuTrouverPopinVisiogoContent'
      }),
      inputHelpPointModalTitle: formatMessage({
        id: 'EerSelfcareStep2OuTrouverPopinTitle'
      }),
      backButton: formatMessage({
        id: 'EerSelfcareStep2RetourLabel'
      }),
      pageTitle: formatMessage({
        id: 'EerSelfcareStep2ControleTitle'
      }),
      placeholderDay: formatMessage({
        id: 'EerSelfcareStep2PlaceholderJourLabel'
      }),
      placeholderMonth: formatMessage({
        id: 'EerSelfcareStep2PlaceholderMoisLabel'
      }),
      placeholderYear: formatMessage({
        id: 'EerSelfcareStep2PlaceholderAnneeLabel'
      }),
      dateErrorMessage: formatMessage({
        id: 'EerSaisieDateNaissanceInvalide'
      }),
      monthNames,
      dayNames,
      dayNamesShort
    }),
    [formatMessage, getMessageRaw]
  );

  const handlePressSubmit = useCallback(
    (identifier, dateOfBirth) => {
      if (!identifier || !dateOfBirth) {
        return;
      }
      createSelfcareInvitationFromExternalIdentityRequest({
        type: InvitationRegistrationOriginEnum.VISIOGO,
        birthDate: dateOfBirth,
        identifier
      });
    },
    [createSelfcareInvitationFromExternalIdentityRequest]
  );

  useEffect(() => {
    dispatch(createSelfcareInvitationResetError());
  }, [dispatch]);

  return (
    <SelfcareTextIdentifierFormTemplate
      dictionary={dictionary}
      errorMessage={errorMessage}
      identifierRegex={optionEERRegexVisiogo}
      isLoading={isLoading}
      onClose={onClose}
      onPressBackButton={onPressBackButton}
      onPressSubmit={handlePressSubmit}
    />
  );
}
