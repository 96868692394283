import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useScreenSizes, useTheme } from '@ere-uilib/styles';

export const useStyles = () => {
  const theme = useTheme();
  const { isDesktop, isMobile } = useScreenSizes();

  return useMemo(
    () =>
      StyleSheet.create({
        containerStyle: {
          gap: theme.metrics.spacing.xm,
        },
        continueButtonContainer: {
          alignSelf: isDesktop ? 'flex-start' : 'auto',
          marginVertical: theme.metrics.spacing.xs
        },
        tsaAddressStyle: {
          marginRight: isMobile ? 0 : theme.metrics.spacing.xm,
          marginTop: theme.metrics.spacing.xm
        },
        tsaAddressContentStyle: {
          marginTop: theme.metrics.spacing.xs
        }
      }),
    [theme, isDesktop, isMobile]
  );
}
