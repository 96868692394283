import { useMemo } from "react"
import { StyleSheet } from "react-native"
import { useScreenSizes, useTheme } from "@ere-uilib/styles";

export const useStyles = (disabledColor?: string) => {
  const theme = useTheme();
  const { isMobile } = useScreenSizes();

  const styles = useMemo(() => StyleSheet.create({
    headerStyle: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.metrics.spacing.xm
    },
    headerContentContainer: {
      flexDirection: 'row'
    },
    containerStyle:
      isMobile
        ? {
          paddingVertical: theme.metrics.spacing.l,
          paddingHorizontal: theme.metrics.spacing.s
        }
        : {
          paddingTop: theme.metrics.spacing.l,
          paddingRight: theme.metrics.spacing.l,
          paddingBottom: theme.metrics.spacing.l,
          paddingLeft: theme.metrics.spacing.l,
        },
    helpPointStyle: {
      marginRight: theme.metrics.spacing.s
    },
    titleStyle:
    {
      color: disabledColor
      //@todo: ia dont see any regression when removing lines code, "max-content" and "inherit" are incompatible with native app
      //minWidth: "max-content",
      //width: "inherit"
    },
    textStyle: {
      paddingRight: theme.metrics.spacing.m,
      paddingLeft: theme.metrics.spacing.l,
      paddingTop: theme.metrics.spacing.m,
      paddingBottom: theme.metrics.spacing.l
    }
  }), [isMobile, theme, disabledColor]);

  return styles;
}