import { Context, Styles } from './interfaces';

export const getStyles = (
  context: Context,
  style: Styles
): Styles => ({
  containerStyle: [
    {
      paddingTop: context?.theme.metrics.spacing.l,
      alignItems: 'stretch',
    },
    style?.containerStyle,
  ],
  backgroundContainer: [
    {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'stretch',
    },
    style?.backgroundContainer,
  ],
  gradientContainer: [
    { height: context?.theme.metrics.spacing.l },
    style?.gradientContainer,
  ],
  background: [
    {
      flex: 1,
      backgroundColor: context?.color,
    },
    style?.background,
  ],
});
