import { RefundPageContext, RefundPageStyle } from "./interfaces";

export const getStyles = (
  context?: RefundPageContext,
  style?: RefundPageStyle
): RefundPageStyle => ({
  dashboardWrapper: [
    { backgroundColor: context?.theme?.colors.basics.white },
    style?.dashboardWrapper,
  ],
  bottomActionsStyle: [
    {
      width: "100%",
    },
    !(context?.isMobile || context?.isTablet) && {
      marginTop: context?.theme?.metrics.spacing.xl,
    },
    style?.bottomActionsStyle,
  ],
  bottomActionsButtonsContainerStyle: [
    {
      width: "100%",
      flexDirection: "row",
    },
    style?.bottomActionsButtonsContainerStyle,
  ],
  arrowButtonStyle: [style?.arrowButtonStyle],
  simpleButtonStyle: [
    {
      marginLeft: context?.theme?.metrics.spacing.m,
      flex: 1,
    },
    style?.simpleButtonStyle,
  ],
  filetContainer: [
    {
      flex: 1,
      marginRight: context?.theme?.metrics.spacing.xm,
    },
    style?.filetContainer,
  ],

  filetText: [
    {
      marginBottom: context?.theme?.metrics.spacing.xm,
    },
    style?.filetText,
  ],
  straightLineStyle: [{}, style?.straightLineStyle],
  fromContent: [
    {
      marginLeft: context?.theme?.metrics.spacing.xm,
      width: "100%",
      marginBottom: context?.theme?.metrics.spacing.xl,
    },
    style?.fromContent,
  ],
  bankAccountHeader: [
    {
      backgroundColor: context?.theme?.colors.basics.white,
      borderBottomColor: context?.theme?.colors.basics.grey.c200,
      borderBottomWidth: 1,
    },
    style?.bankAccountHeader,
  ],
  bankAccountContainer: [
    {
      marginLeft: context?.theme?.metrics.spacing.s,
      marginTop: context?.theme?.metrics.spacing.s,
      marginBottom: context?.theme?.metrics.spacing.l,
    },
    style?.bankAccountContainer,
  ],
  notificationLabel: [
    {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    style?.notificationLabel,
  ],
  titleContainer: [
    {
      paddingLeft: context?.theme?.metrics.spacing.s,
      paddingRight: context?.theme?.metrics.spacing.xm,
      paddingBottom: context?.theme?.metrics.spacing.xs,
    },
    style?.titleContainer,
  ],
});
