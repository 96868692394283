import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

export function useStyles() {
  const theme = useTheme();

  const styles = useMemo(() => StyleSheet.create({
    label: {
      marginBottom: theme.metrics.spacing.xm,
    },
    identifierHelp: {
      alignSelf: 'flex-end',
      marginTop: theme.metrics.spacing.xs,
    },
  }), [theme]);

  return styles;
}
