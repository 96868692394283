import { StackNavigationProp } from '@react-navigation/stack';
import React, { useCallback } from 'react';
import { View } from 'react-native';

import {
  ColumnsContainer,
  DashboardPageWrapperConnected,
  NavigationHeader,
  useTheme,
  useTranslation
} from '@components/index';
import { RouteNames } from '@constants/navigation';
import { SimpleButton } from '@ere-uilib/molecules';
import { ModalSuccess } from '@ere-uilib/organisms/ModalSuccess';
import { useScreenSizes } from '@ere-uilib/styles';
import { ContactPreferencesRequest } from '@modules/profile/actions/profileActions';
import { AppNavigatorInterface } from '@navigation/Interfaces';

import { ContactPreferencetemplate } from '../templates';
import { useController } from '../useController';
import { locator } from '@constants/locator';

interface Props {
  navigation: StackNavigationProp<AppNavigatorInterface>;
  onSubmit: (values: ContactPreferencesRequest) => void;
  isSuccess?: boolean;
  onPressButtonSuccessModal: () => void;
  hasNotificationsActivated: boolean;
  emailOptIn: boolean;
  smsOptIn: boolean;
  getContactPreferencesRequest: () => void;
  isLoading: boolean;
  phone?: string | null;
}

export function ContactPreferencesConnectedPage({
  navigation,
  isSuccess,
  onSubmit,
  onPressButtonSuccessModal,
  hasNotificationsActivated,
  emailOptIn,
  smsOptIn,
  getContactPreferencesRequest,
  isLoading
}: Props) {
  const { formatMessage, getMessageRaw } = useTranslation();
  const theme = useTheme();
  const {
    isSubmitActive,
    handlePressReceiveMessage,
    handlePressNotReceiveMessage,
    handlePressEmail,
    handlePressSMS,
    handleSubmit,
    handlePressButtonSuccessModal,
    optInSms,
    optInEmail,
    isNotificationsActivated
  } = useController({
    onSubmit,
    onPressButtonSuccessModal,
    navigation,
    hasNotificationsActivated,
    emailOptIn,
    smsOptIn,
    getContactPreferencesRequest
  });

  const { isDesktop } = useScreenSizes();

  const renderHeader = useCallback(
    () => (
      <NavigationHeader
        displayBackButton={true}
        helpPoint={true}
        helpPointContentHtml={getMessageRaw({ id: 'Profil_Prefcontact_edit_info_content' })}
        helpPointModalTitle={formatMessage({ id: 'Profil_Prefcontact_edit_info_title' })}
        helpPointPosition={'right'}
        onGoBack={() =>
          navigation.navigate(RouteNames.BottomTabNavigator, {
            screen: RouteNames.ProfileStack,
            params: {
              screen: RouteNames.Profile
            }
          })
        }
        title={formatMessage({ id: 'Profil_Prefcontact_edit_page_title' })}
      />
    ),
    [getMessageRaw, formatMessage, navigation]
  );

  const renderButton = useCallback(
    () => (
      <View style={{ marginBottom: 80 }}>
        <SimpleButton
          disabled={!isSubmitActive}
          loading={isLoading}
          onPress={handleSubmit}
          title={formatMessage({ id: 'Profil_Prefcontact_edit_page_Bouton' })}
          testId={locator._profile._contact_preferences._register_my_prefernces}
        />
      </View>
    ),
    [isSubmitActive, handleSubmit, isLoading]
  );

  return (
    <DashboardPageWrapperConnected
      cardContentStyle={{ backgroundColor: theme.colors.basics.white }}
      renderStickyMobileBottom={renderButton}
      scrollContentContainerStyle={!isDesktop && { minHeight: 'auto' }}>
      <>
        <ColumnsContainer
          containerStyle={!isDesktop && { height: 'auto' }}
          renderHeader={renderHeader}>
          <>
            <ContactPreferencetemplate
              handlePressEmail={handlePressEmail}
              handlePressNotReceiveMessage={handlePressNotReceiveMessage}
              handlePressReceiveMessage={handlePressReceiveMessage}
              handlePressSMS={handlePressSMS}
              hasNotificationsActivated={isNotificationsActivated}
              optInEmail={optInEmail}
              optInSms={optInSms}
            />
            {isDesktop && (
              <View style={{ marginTop: 60, alignItems: 'flex-start' }}>{renderButton()}</View>
            )}
          </>
        </ColumnsContainer>

        <ModalSuccess
          buttonTitle={formatMessage({ id: 'Profil_Prefcontact_edit_confirm_button' })}
          iconName="update_contact_preferences_success"
          onPressButton={handlePressButtonSuccessModal}
          subTitle={formatMessage({ id: 'Profil_Prefcontact_edit_confirm_subtitle' })}
          title={formatMessage({ id: 'Profil_Prefcontact_edit_confirm_title' })}
          visible={isSuccess}
        />
      </>
    </DashboardPageWrapperConnected>
  );
}
