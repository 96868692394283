import { fork } from 'redux-saga/effects'

import { documentsSagas } from '@modules/document-space/sagas'
import { profitSharingIncentiveSagas } from '@modules/profit-sharing-incentive/sagas'

import { AnnuitySagas } from './annuity/sagas';
import { ArbitrationSagas } from './arbitration/sagas';
import { authLoginSagas } from './auth/authSagas';
import { BankDetailsSagas } from './bank-details/saga';
import { CampaignSagas } from './campaign/sagas';
import { ControlDataSagas } from './control-data/sagas';
import { AccountsSaga } from './dashboard/sagas';
import {
  forgetPasswordConfirmEmailSagas,
  forgetPasswordConfirmSagas,
  forgetPasswordConfirmSMSCodeSagas,
  forgetPasswordConfirmSMSSagas,
  forgetPasswordInitBeginSagas
} from './forget-password/sagas'
import { FundsSaga } from './funds/sagas'
import { HistorySagas } from './history/sagas'
import { InstallmentSagas } from './installment/sagas'
import invitationSagas from './invitation/sagas'
import labelSagas from './label/sagas'
import { LegalSagas } from './legal/sagas'
import { PendingTasksSagas } from './pending-tasks/pendingTasksSagas'
import { ProfileSagas } from './profile/sagas'
import { redirectOperationSagas } from './redirect-operation/sagas'
import { AvailableRefundSagas } from './refund/available-refund/sagas'
import { RefundSagas } from './refund/sagas'
import { UnavailableRefundSagas } from './refund/unavailable-refund/sagas'
import { retirementSagas } from './retirement/retirementSagas'
import { SavingsSaga } from './savings/sagas'
import { SettingsSagas } from './settings/sagas'
import {
  checkEERValidationIdentityCodeSagas, checkEERValidationSecondLetterIdentityCodeSagas, franceConnectConfirmationRequestSagas, franceConnectRegistrationRequestSagas, registrationRequestConfirmSagas, sendEERValidationIdentityCodeSagas, validateEEREmailNewIdentifierCodeSagas, validateEEREmailNewIdentifierSagas, validateEERPhoneNewIdentifierCodeSagas, validateEERPhoneNewIdentifierSagas
} from './validate-identity/validateIdentitySagas';

export default function* sagas() {
  yield fork(SavingsSaga)
  yield fork(FundsSaga)
  yield fork(labelSagas)
  yield fork(CampaignSagas)
  yield fork(LegalSagas)
  yield fork(invitationSagas)
  yield fork(sendEERValidationIdentityCodeSagas)
  yield fork(checkEERValidationSecondLetterIdentityCodeSagas)
  yield fork(checkEERValidationIdentityCodeSagas)
  yield fork(validateEEREmailNewIdentifierSagas)
  yield fork(validateEEREmailNewIdentifierCodeSagas)
  yield fork(validateEERPhoneNewIdentifierSagas)
  yield fork(validateEERPhoneNewIdentifierCodeSagas)
  yield fork(registrationRequestConfirmSagas)
  yield fork(authLoginSagas)
  yield fork(forgetPasswordInitBeginSagas)
  yield fork(forgetPasswordConfirmEmailSagas)
  yield fork(forgetPasswordConfirmSMSSagas)
  yield fork(forgetPasswordConfirmSMSCodeSagas)
  yield fork(forgetPasswordConfirmSagas)
  yield fork(AccountsSaga)
  yield fork(ProfileSagas)
  yield fork(SettingsSagas)
  yield fork(franceConnectRegistrationRequestSagas)
  yield fork(franceConnectConfirmationRequestSagas)
  yield fork(InstallmentSagas)
  yield fork(PendingTasksSagas)
  yield fork(RefundSagas)
  yield fork(AvailableRefundSagas)
  yield fork(UnavailableRefundSagas)
  yield fork(redirectOperationSagas)
  yield fork(HistorySagas)
  yield fork(ArbitrationSagas)
  yield fork(profitSharingIncentiveSagas)
  yield fork(documentsSagas)
  yield fork(retirementSagas)
  yield fork(AnnuitySagas);
  yield fork(BankDetailsSagas);
  yield fork(ControlDataSagas);
}
