import React, { useCallback } from 'react';
import { View } from 'react-native';

import {
  ModalFrame,
  SimpleButton,
  useTranslation,
  Title,
  NotificationIconTypeEnum,
  NotificationIcon,
} from '@components/index';
import { RouteNames } from '@constants/navigation';

import { useCancelOperationModalInfos } from '../hooks'
import { OperationCancelSuccessPageProps } from './interface';
import { useStyles } from './style';
import {useNavigation} from "@react-navigation/native";
import {StackNavigationProp} from "@react-navigation/stack";
import {AppNavigatorInterface} from "@navigation/Interfaces";

export const OperationCancelSuccessPageComponent: React.FC<OperationCancelSuccessPageProps> = ({
  operationDetails,
}) => {
  const { formatMessage } = useTranslation();
  const navigation = useNavigation<StackNavigationProp<AppNavigatorInterface>>();
  const styles = useStyles();

  const {
    cancelModalSuccessTitle,
    renderCancelModalSuccessDescription,
    modalRestartOperationLabel,
    onModalRestartOperation,
  } = useCancelOperationModalInfos({
    operationDetails,
  })

  const handleNavigationToDashboard = useCallback(() => {
    navigation.replace(RouteNames.BottomTabNavigator, {
      screen: RouteNames.Home
    })
  }, [navigation]);

  const renderActions = () => {
    return (
      <View style={styles.buttonContainerStyle}>
        {!!onModalRestartOperation && (
          <SimpleButton
            containerStyle={styles.buttonStyle}
            onPress={onModalRestartOperation}
            title={modalRestartOperationLabel}
          />
        )}
        <SimpleButton
          containerStyle={[
            styles.buttonStyle,
            styles.lastButtonStyle
          ]}
          design={'outlined'}
          onPress={handleNavigationToDashboard}
          title={formatMessage({ id: 'PIAnnulationFermerBouton' })}
        />
      </View>
    )
  }
  return (
    <View
      style={styles.containerStyle}>
      <ModalFrame
        desktopPosition="center"
        mobilePosition="bottom"
      >
        <View style={styles.modalContainer} >
          <NotificationIcon
            type={NotificationIconTypeEnum.SUCCESS}
          />
          <Title
            style={styles.titleStyle}
            variant="t6"
          >
            {cancelModalSuccessTitle}
          </Title>
          {renderCancelModalSuccessDescription?.()}
          {renderActions()}
        </View>
      </ModalFrame>
    </View>
  );
};
