import { connect } from 'react-redux';

import { AppState } from '@modules/index';
import { getBudgetInsightUrl } from '@modules/dashboard/selectors';

import { BudgetInsightConnectComponent } from './BudgetInsightConnectPage.component';

const mapStateToProps = (state: AppState) => {
  return {
    budgetInsightUrl: getBudgetInsightUrl(state)
  };
};

export const BudgetInsightConnectPage = connect(
  mapStateToProps,
  null
)(BudgetInsightConnectComponent);
