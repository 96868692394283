import { connect } from 'react-redux';

import { AppState } from '@modules/index';
import { ForgetPasswordActionsType } from '@modules/forget-password/actions/forgetPasswordActionsTypes';
import {
  ForgetPasswordConfirmEmailRequest,
  ForgetPasswordConfirmSMSCodeRequest,
  ForgetPasswordConfirmSMSMRequest,
  ForgetPasswordInitBeginRequest
} from '@modules/forget-password/actions/forgetPasswordActions';
import { getForgetPasswordError, getUserInputEmail, getUserPhoneNumber } from '@modules/forget-password/selectors';

import { ForgetPasswordValidationCode } from './ForgetPasswordValidationCodePage.component';

const mapStateToProps = (state: AppState) => {
  return {
    inputUserEmail: getUserInputEmail(state),
    userPhoneNumber: getUserPhoneNumber(state),
    forgetPasswordError: getForgetPasswordError(state),
    isForgetPasswordValidateEmailApiLoading: state.isLoading[ForgetPasswordActionsType.FORGET_PASSWORD_CONFIRM_EMAIL_REQUEST],
    isForgetPasswordBeginProcessApiLoading: state.isLoading[ForgetPasswordActionsType.FORGET_PASSWORD_INIT_BEGIN_REQUEST],
    isForgetPasswordValidatePhoneNumberApiLoading: state.isLoading[ForgetPasswordActionsType.FORGET_PASSWORD_CONFIRM_SMS_CODE_REQUEST],
    isForgetPasswordResendSmsEventApiLoading: state.isLoading[ForgetPasswordActionsType.FORGET_PASSWORD_CONFIRM_SMS_REQUEST],
  };
};

const mapDispatchToProps = ({
  onForgetPasswordValidateEmailByCode: ForgetPasswordConfirmEmailRequest,
  onForgetPasswordBeginProcess: ForgetPasswordInitBeginRequest,
  onForgetPasswordConfirmSmsCode: ForgetPasswordConfirmSMSCodeRequest,
  onForgetPasswordResendSms: ForgetPasswordConfirmSMSMRequest,
});
export const ForgetPasswordValidationCodePage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ForgetPasswordValidationCode);
