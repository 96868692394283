import React from "react";
import {useTheme} from "@ere-uilib/styles";
import {LoaderPlaceholderView} from "@ere-uilib/atoms";
import {useStyles} from "@pages/ProfitSharingIncentive/ProfitSharingIncentiveRepartition/styles";


export const LoaderLayer: React.FC<{isContributionLoading?: boolean}> = ({isContributionLoading}) => {
    const theme = useTheme();
    const styles = useStyles();

    if (!isContributionLoading) { return null }
    return (
        <LoaderPlaceholderView
            backgroundColor={theme.colors.basics.white}
            foregroundColor={theme.colors.basics.grey.c200}
            style={styles.contributionLoadingLayer}
        />
    )
}
