
export enum RelationShipEnteringNavigatorRouteNames {
  Welcome = 'Welcome',
  FConnectSignUp = 'FConnectSignUp',
  ValidateSignup = 'ValidateSignup',
  ValidateSignupThankYou = 'ValidateSignupThankYou',
  ChooseIdentificationMail = 'ChooseIdentificationMail',
  ChooseIdentificationEmailCodePage = 'ChooseIdentificationEmailCodePage',
  ChooseIdentificationPhone = 'ChooseIdentificationPhone',
  ChooseIdentificationPhoneCodePage = 'ChooseIdentificationPhoneCodePage',
  ChoosePassword = 'ChoosePassword',
  AccountCreationSuccess = 'AccountCreationSuccess',
  AccountTerms = 'AccountTerms',
  CompanyBenefits = 'CompanyBenefits',
  OnboardingPersoneo = 'OnboardingPersoneo',
  OnboardingVisiogo = 'OnboardingVisiogo',
  ValidationSignupEmailCode = 'ValidationSignupEmailCode',
  ValidationSignupPhoneCode = 'ValidationSignupPhoneCode'
}

export type RelationShipEnteringNavigatorInterface = {
  [RelationShipEnteringNavigatorRouteNames.Welcome]:
    | { token: string; }
    | undefined;
  [RelationShipEnteringNavigatorRouteNames.FConnectSignUp]: undefined
  [RelationShipEnteringNavigatorRouteNames.ValidateSignup]: undefined
  [RelationShipEnteringNavigatorRouteNames.ValidateSignupThankYou]: undefined
  [RelationShipEnteringNavigatorRouteNames.ChooseIdentificationMail]: undefined
  [RelationShipEnteringNavigatorRouteNames.ChooseIdentificationEmailCodePage]: undefined
  [RelationShipEnteringNavigatorRouteNames.ChooseIdentificationPhone]: undefined
  [RelationShipEnteringNavigatorRouteNames.ChooseIdentificationPhoneCodePage]: undefined
  [RelationShipEnteringNavigatorRouteNames.ChoosePassword]: undefined
  [RelationShipEnteringNavigatorRouteNames.AccountCreationSuccess]: undefined
  [RelationShipEnteringNavigatorRouteNames.AccountTerms]: undefined
  [RelationShipEnteringNavigatorRouteNames.CompanyBenefits]: undefined
  [RelationShipEnteringNavigatorRouteNames.OnboardingPersoneo]: undefined
  [RelationShipEnteringNavigatorRouteNames.OnboardingVisiogo]: undefined
  [RelationShipEnteringNavigatorRouteNames.ValidationSignupEmailCode]: undefined
  [RelationShipEnteringNavigatorRouteNames.ValidationSignupPhoneCode]: undefined
}
