import { StyleSheet } from "react-native";
import { useScreenSizes, useTheme } from "@ere-uilib/styles";

export const useStyles = () => {

  const theme = useTheme();
  const { isMobile, isTablet } = useScreenSizes();

  const styles = StyleSheet.create({
    containerStyle: {
      flex: 1
    },
    contentStyle: {
      maxWidth: theme.metrics.contentSizes.centeredContentMaxWidth
    },
    notificationHardStyle: {
      width: '100%',
      marginVertical: theme.metrics.spacing.xm
    },
    emailInputStyle: {
      marginTop: theme?.metrics.spacing.xm
    },
    buttonContainer: (isMobile || isTablet) ? {
      paddingHorizontal: theme?.metrics.spacing.s,
      marginBottom: theme?.metrics.spacing.xxbig
    } : {
      flexDirection: 'row',
      justifyContent: 'center',
      marginTop: theme?.metrics.spacing.xxl
    },
  });

  return {
    styles
  }
}
