import { ProfileMenuStyles, StylesContext } from './interfaces';

export const getStyles = (
  context?: StylesContext,
  style?: ProfileMenuStyles
): ProfileMenuStyles => ({
  profileMenuGlobalContainerStyle: [
    {
      flex: 1
    },
    style?.profileMenuGlobalContainerStyle
  ],
  profileMenuContainerStyle: [
    {
      borderBottomColor: context?.theme.colors.basics.grey.c200,
      borderBottomWidth: 1,
      paddingVertical: context?.theme.metrics.spacing.xm
    },
    style?.profileMenuContainerStyle
  ],
  profileMenuItemContainerStyle: [
    {
      flex: 1,
      height: 50,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    style?.profileMenuItemContainerStyle
  ],
  profileMenuTitleStyle: [
    {
      fontFamily: context?.theme.fonts.fontFamily.bold,
      marginBottom: context?.theme.metrics.spacing.s
    },
    style?.profileMenuTitleStyle
  ],
  profileMenuItemIconViewStyle: [
    {
      flexDirection: 'row',
      alignItems: 'center',
      paddingVertical: context?.theme.metrics.spacing.s
    },
    style?.profileMenuItemIconViewStyle
  ],
  profileMenuItemIconWrapperStyle: [
    {
      marginRight: context?.theme.metrics.spacing.xm,
      width: context?.theme.metrics.spacing.xm
    },
    style?.profileMenuItemIconWrapperStyle
  ],
  subMenuItemTitleAndSubLabelContiner: [
    {
      flexDirection: 'column',
      justifyContent: 'center',
    },
    style?.subMenuItemTitleAndSubLabelContiner
  ],
  subLabel: [
    {
      color: context?.theme.colors.notifications.warning.c500
    },
    style?.subLabel
  ]
});
