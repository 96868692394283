import { Context, Styles } from './interfaces';

export const getStyles = (
  context?: Context,
  style?: Styles,
): Styles => ({
  container: [
    {
      flexWrap: 'wrap'
    },
    style?.container
  ],
  tagsContainer: [
    {
      flexDirection: 'row',
      flexWrap: 'wrap'
    },
    style?.tagsContainer
  ]
});

