import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useScreenSizes, useTheme } from '@ere-uilib/styles';

export function useStyles() {
  const { windowHeight } = useScreenSizes();
  const theme = useTheme();
  const styles = useMemo(() => {
    return StyleSheet.create({
      containerStyle: {
        backgroundColor: theme.colors.basics.grey.c100,
        height: windowHeight,
      },
      form: {
        marginTop: theme.metrics.spacing.xm,
      },
      checkboxes: {
        flexDirection: 'row',
        paddingLeft: theme.metrics.spacing.l,
        paddingTop: theme.metrics.spacing.s,
      },
      checkbox: {
        paddingRight: theme.metrics.spacing.l,
      },
      secondRadioButton: {
        marginTop: theme.metrics.spacing.l,
      },
    });
  }, [theme, windowHeight]);

  return styles;
}
