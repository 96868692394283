import React, { useCallback, useState } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

import { useTranslation } from '@ere-uilib/index';
import { RadioButton } from '@ere-uilib/molecules';

import { Gender } from '../../AnnuitySimulator/sharedInterfaces';
import { AnnuityBottomActionsWrapper } from '../AnnuityBottomActionsWrapper';
import { useStyles } from './useStyles';

interface Props {
  value: Gender | undefined;
  onSubmit?: (value: Gender | undefined) => void;
  onChange?: (value: Gender | undefined) => void;
}

interface Style {
  contentStyle?: StyleProp<ViewStyle>;
}

export const AnnuityGender: React.FC<React.PropsWithChildren<Props & Style>> = ({
  value,
  onSubmit,
  onChange,
  contentStyle
}) => {
  const styles = useStyles();
  const [gender, setGender] = useState(value);
  const { formatMessage } = useTranslation();

  const handleChange = useCallback(
    (gender: Gender) => {
      setGender(gender);
      onChange?.(gender);
    },
    [onChange]
  );

  const renderContent = useCallback(
    (gender: Gender | undefined) => (
      <View style={[styles.container, contentStyle]}>
        <RadioButton
          label={formatMessage({ id: 'PensionSimulatorCapitalReversionFemale' })}
          onPress={() => handleChange(Gender.Woman)}
          value={gender === Gender.Woman}
        />
        <RadioButton
          containerStyle={styles.rightRadioButton}
          label={formatMessage({ id: 'PensionSimulatorCapitalReversionMale' })}
          onPress={() => handleChange(Gender.Man)}
          value={gender === Gender.Man}
        />
      </View>
    ),
    [contentStyle, formatMessage, handleChange, styles]
  );

  if (!onSubmit) return renderContent(gender);

  return (
    <AnnuityBottomActionsWrapper
      confirmButtonDisabled={!gender}
      confirmButtonLabel={formatMessage({
        id: 'PensionSimulatorStartSimulationContinueButton'
      })}
      onValidate={() => onSubmit(gender)}>
      {renderContent(gender)}
    </AnnuityBottomActionsWrapper>
  );
};
