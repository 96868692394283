
import { ArbitrationTypes } from '@constants/arbitrations';
import { useTranslation } from '@ere-uilib/translations';
import {
  ArbitrationQuestionTypesEnum,
  ArbitrationAnswersTypesEnum,
} from '@modules/arbitration/constants';
import {
  ReallocationChoiceType,
} from '@modules/arbitration/types'
import {
  getDecisionAllResponses
} from '@modules/arbitration/utils';
import { OperationDecisionState } from '@modules/common/types';

type questionType = OperationDecisionState['questions'][number];
type responseType = questionType['responses'][number];

export const useDecisionQuestionTypeDataUpgrade = (
  requirementDecisionData: OperationDecisionState & ReallocationChoiceType
): OperationDecisionState => {
  const { formatMessage } = useTranslation();

  const reallocationType = requirementDecisionData.reallocationType;
  const isFlow = reallocationType === ArbitrationTypes.flow;
  const allAnswers = getDecisionAllResponses(requirementDecisionData);
  const isOadReallocation = allAnswers.OadReallocation === true;
  const oadStartQuestion = requirementDecisionData.questions.find(question => question.type === ArbitrationQuestionTypesEnum.OAD_START);
  const isOadForm = oadStartQuestion?.responded?.respondedValue === formatMessage({ id: 'AbritrageOADouiContenu' });
  const disabledMessage = isOadReallocation && isOadForm ? formatMessage({ id: 'OADCasBlocageDescription' }) : undefined;

  const upgradeQuestion = (question: questionType): questionType => {
    let newQuestion = question;

    switch (newQuestion.type) {
      case ArbitrationQuestionTypesEnum.PLAN_CHOICE:
        const hasDrivenSavings = requirementDecisionData.dispositifs.find(dispositif => {
          const hasDrivenSavings = dispositif.advancedProperties.HasDrivenSavings;
          const isOadElligible = dispositif.filterProperties.OadReallocation;
          const isOadAndHasDrivenSavings = isOadElligible && hasDrivenSavings;

          return isOadAndHasDrivenSavings;
        });

        const description = hasDrivenSavings && formatMessage({ id: 'ArbitrageOADSelectionDispositifContenu' });

        newQuestion = {
          ...newQuestion,
          title: formatMessage({ id: 'Arbitrage_choixDispositifs_question' }),
          description,
          respondedTitle: 'AbritrageOADSelectionDispositifQuestion2Recap',
          key: "Arbitrage_choixDispositifs",
          tooltip: "Arbitrage_choixDispositifs",
          responses: newQuestion.responses.map(response => ({
            ...response,
            title: formatMessage({ id: 'Arbitrage_choixDispositifs_title', values: { dispositif: response.title } }),
            value: formatMessage({ id: 'Arbitrage_choixDispositifs_title', values: { dispositif: response.title } }),
            disabledMessage,
          })),
          ...(isFlow && {
            title: formatMessage({ id: 'ArbitrageFlux_Questions_ChoixDispositif_label' }),
            value: formatMessage({ id: 'ArbitrageFlux_Questions_ChoixDispositif_label' }),
            key: "ArbitrageFlux_Questions_ChoixDispositif",
            tooltip: "ArbitrageFlux_Questions_ChoixDispositif",
          }),
          ...(isOadReallocation && {
            title: formatMessage({ id: 'AbritrageOADSelectionDispositifQuestion2' }),
            value: formatMessage({ id: 'AbritrageOADSelectionDispositifQuestion2' }),
            key: "Arbitrage_choixDispositifs",
            tooltip: "Arbitrage_choixDispositifs",
          })
        };

        break;
      case ArbitrationQuestionTypesEnum.COMPARTMENT_CHOICE:
        newQuestion = {
          ...newQuestion,
          title: formatMessage({ id: 'Arbitrage_choixDispositifs_question' }),
          respondedTitle: 'AbritrageOADSelectionDispositifQuestion3Recap',
          key: "Arbitrage_choixDispositifs",
          tooltip: "Arbitrage_choixDispositifs",
          ...(isFlow && {
            title: formatMessage({ id: 'ArbitrageFlux_Questions_ChoixCompartiment_label' }),
            key: "ArbitrageFlux_Questions_ChoixCompartiment",
            tooltip: "ArbitrageFlux_Questions_ChoixCompartiment",
          }),
          ...(isOadReallocation && {
            title: formatMessage({ id: 'AbritrageOADSelectionDispositifQuestion3' }),
            description: formatMessage({ id: 'AbritrageOADSelectionDispositifQuestion3TexteDescription' }),
            key: "Arbitrage_choixDispositifs",
            tooltip: "Arbitrage_choixDispositifs",
          })
        };
        break;
      case ArbitrationQuestionTypesEnum.OAD_START:
        newQuestion = {
          ...newQuestion,
          title: formatMessage({ id: 'AbritrageOADQuestionContenu' }),
          respondedTitle: 'AbritrageOADResultatQRBesoinAideDescription'
        };
        break;
      default:
        break;
    }

    const newResponses = newQuestion?.responses?.map(response => upgradeResponse(
      response,
      newQuestion.type,
    ));

    return {
      ...newQuestion,
      responses: newResponses
    };
  };

  const upgradeResponse = (
    response: responseType,
    questionType?: string,
  ): responseType => {

    let newResponse = response;

    if (questionType === ArbitrationQuestionTypesEnum.COMPARTMENT_CHOICE) {
      newResponse = {
        ...newResponse,
        title: newResponse.title || formatMessage({ id: 'DefaultTitleCompartment' }),
        // text: newResponse.descriptionLabel
      };
    } else if (newResponse.type === ArbitrationAnswersTypesEnum.OAD_START_YES) {
      newResponse = {
        ...newResponse,
        title: formatMessage({ id: 'AbritrageOADouiContenu' }),
        value: formatMessage({ id: 'AbritrageOADouiContenu' }),
        text: formatMessage({ id: 'AbritrageOADbesoinOuiAideTitre' }),
      };
    } else if (newResponse.type === ArbitrationAnswersTypesEnum.OAD_START_NO) {
      newResponse = {
        ...newResponse,
        title: formatMessage({ id: 'AbritrageOADnonContenu' }),
        value: formatMessage({ id: 'AbritrageOADnonContenu' }),
        text: formatMessage({ id: 'AbritrageOADpasBesoinNonAideDescription' })
      };
    }

    return newResponse;
  };

  const newQuestions = requirementDecisionData?.questions?.map(upgradeQuestion);

  return {
    ...requirementDecisionData,
    questions: newQuestions
  };
};
