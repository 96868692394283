import { useMemo } from 'react'
import { StyleSheet } from 'react-native'

import { useTheme } from '@ere-uilib/styles'

export const useStyles = () => {
  const theme = useTheme()

  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          flexDirection: 'row',
          paddingVertical: theme.metrics.spacing.m,
          borderTopWidth: 1,
          borderTopColor: theme.colors.basics.grey.c200
        },
        detailsContainer: {
          flex: 3,
          flexDirection: 'column',
          justifyContent: 'space-evenly'
        },
        amountContainer: { flex: 2, alignItems: 'flex-end' },
        title: {
          fontSize: 14,
          fontFamily: 'BNPPSans-Regular',
          marginTop: theme.metrics.spacing.xs,
          justifyContent: 'space-evenly'
        },
        icon: {
          marginRight: theme.metrics.spacing.xs,
          marginTop: theme.metrics.spacing.xxs
        },
        textAmount: {
          color: theme.colors.basics.primary.c500,
          textDecorationLine: 'underline'
        }
      }),
    [theme]
  )

  return styles
}
