import {
  ArbitrationFailurePageContext,
  ArbitrationFailurePageStyle,
} from './interface';

export const getStyles = (
  context?: ArbitrationFailurePageContext,
  style?: ArbitrationFailurePageStyle,
): ArbitrationFailurePageStyle => ({

});

