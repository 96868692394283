import {InstallmentPaymentModePageStyle, InstallmentRepartitionPageContext} from './interfaces';

export const getStyles = (
  context?: InstallmentRepartitionPageContext,
  style?: InstallmentPaymentModePageStyle
): InstallmentPaymentModePageStyle => ({
  containerStyle: [
    (context?.isMobile || context?.isTablet) && {
      height: context?.windowHeight
    },
    style?.containerStyle
  ],
  installmentsStepperContainerStyle: [
    {
      alignItems: 'center',
      justifyContent: 'center'
    },
    style?.installmentsStepperContainerStyle
  ],
  stepperStyle: [
    {
      paddingBottom: context?.theme.metrics.spacing.s,
      marginTop: context && -context?.theme.metrics.spacing.s
    },
    style?.stepperStyle
  ],
  bottomActionsStyle: [
    {
      width: '100%',
      flexDirection: 'row'
    },
    !(context?.isMobile || context?.isTablet) && {
      paddingTop: context?.theme.metrics.spacing.xl
    },
    style?.bottomActionsStyle
  ],
  arrowButtonStyle: [
    !(context?.isMobile || context?.isTablet) && {
      flex: 1
    },
    style?.arrowButtonStyle
  ],
  simpleButtonStyle: [
    {
      marginLeft: context?.theme.metrics.spacing.m,
      flex: 1
    },
    style?.simpleButtonStyle
  ],
  subHeaderContainerStyle: [
    {
      marginBottom: context?.theme.metrics.spacing.xm
    },
    style?.subHeaderContainerStyle
  ],
  bankAccountInfosCardContainer: [
    {
      marginTop: context?.theme.metrics.spacing.l
    },
    style?.bankAccountInfosCardContainer
  ],
  titleStyle: [
    {
      marginBottom: context?.theme.metrics.spacing.l
    },
    style?.titleStyle
  ],
  advantageCardsContainer: [
    {
      flexDirection: 'row',
      paddingHorizontal: context?.theme?.metrics.spacing.xs
    },
    style?.advantageCardsContainer
  ],
  advantageCard: [
    {
      marginHorizontal: context?.theme.metrics.spacing.xs,
      marginVertical: context?.theme.metrics.spacing.xm
    },
    style?.advantageCard
  ],
  ActivableButtonStyle: [
    {
      marginRight: context?.theme.metrics.spacing.s,
      flex: 1
    },
    style?.ActivableButtonStyle
  ],
  paymentModeContainer: [
    {
      flexDirection: 'row'
    },
    style?.paymentModeContainer
  ]
});
