import { connect } from 'react-redux';

import {
  AppState,
} from '@modules/index';
import {
  setRefundSupportTriggerThresholdAndFormatRepartition,
  setRefundTriggerThresholdModalVisibleState
} from '@modules/refund/available-refund/actions/refundActions';
import { getRefundTriggerThresholdModalVisible } from '@modules/refund/available-refund/selectors';

import { RefundTriggerThresholdModalConnectedComponent } from './RefundTriggerThresoldModalConnected.component';

const mapStateToProps = (state: AppState) => {
  return {
    visible: getRefundTriggerThresholdModalVisible(state),
  };
};

const mapDispatchToProps = {
  onSetSupportTriggerThresholdAndFormatRepartition: setRefundSupportTriggerThresholdAndFormatRepartition,
  onSetModalVisibleState: setRefundTriggerThresholdModalVisibleState,
};

export const RefundTriggerThresholdModalConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(RefundTriggerThresholdModalConnectedComponent);
