import { useIsFocused } from '@react-navigation/native'
import React, { useEffect, useRef, useState } from 'react'
import { View } from 'react-native'

import { SavingRepartitionCard } from '@components/ERE360Components/SavingRepartitionCard'
import {
  BubbleContainer,
  CardWithLeftThread,
  ColumnsContainer,
  CompareFundsModal,
  DashboardPageWrapperConnected,
  FundSheetModal,
  FundSheetModalDataType,
  getPlanTypeColors,
  ManagementCardHeader,
  NotificationHard,
  RefundAmountFieldModalConnected,
  RefundAmountFieldModalConnectedRefType,
  RefundTotalSubheader,
  RefundTriggerThresholdModalConnected,
  SavingRepartitionCardSelectTypeEnum,
  SimpleButton,
  Spinner,
  Switch,
  Text,
  TotalSavingHeader,
  TriggerThresholdComponent,
  TriggerThresholdModalConnectedRefType,
  useScreenSizes,
  useTheme,
  useTranslation
} from '@components/index'
import { ThresholdCoursesEnum, ThresholdTriggerTypesEnum } from '@constants/arbitrations'
import { locator } from '@constants/locator'
import { RouteNames } from '@constants/navigation'
import { NotificationIconTypeEnum } from '@ere-uilib/atoms'
import { IconEnum } from '@ere-uilib/enums'
import { DirectionEnum } from '@ere-uilib/molecules/steppers/InstallmentsStepper/interfaces'
import { createUseStyles } from '@ere-uilib/styles'
import { RefundRepartitionSupportsState } from '@modules/refund/available-refund/types'
import { constructStepperItems } from '@pages/Common/utils'

import { RefundRepartitionPageProps } from './interfaces'
import { getStyles } from './styles'
import { RefundsHeader, RefundsRepartitionValidationModal } from '../../components'

export const RefundRepartitionPageComponent: React.FC<React.PropsWithChildren<RefundRepartitionPageProps>> = ({
  route,
  navigation,
  ClearRefundSupportTriggerThresholdData,
  onGetRefundRepartitionDataRequest,
  onSelectRefundRepartitionAllSupport,
  onSelectRefundRepartitionSupport,
  onSetRefundRepartitionAmount,
  refundRepartitionData,
  isRefundRepartitionDataLoading
}) => {
  const [displayFundSheetModal, setDisplayFundSheetModal] = useState(false)
  const [displayCompareFundsModal, setDisplayCompareFundsModal] = useState(false)
  const [isValidationModalOpened, setIsValidationModalOpened] = useState(false)
  const [fundSheetModalData, setFundSheetModalData] = useState<FundSheetModalDataType | null>()
  const [fundsToCompare, setFundsToCompare] = useState<
    RefundRepartitionSupportsState[] | undefined
  >()

  const isFocused = useIsFocused()
  const triggerThresholdModal = useRef<TriggerThresholdModalConnectedRefType>(null)
  const refundAmountModal = useRef<RefundAmountFieldModalConnectedRefType>(null)
  const { formatMessage, formatCurrencyNumber, formatDate } = useTranslation()
  const theme = useTheme()

  const { isMobile, isTablet } = useScreenSizes()

  const styles = useStyles(
    {
      theme,
      isMobile: isMobile || isTablet,
      isTablet
    },
    {}
  )

  useEffect(() => {
    if (isFocused) {
      if (!refundRepartitionData) {
        onGetRefundRepartitionDataRequest && onGetRefundRepartitionDataRequest()
      }
    }
  }, [isFocused])
  const goToRefundAskProof = () =>
    navigation.navigate(RouteNames.RefundsStack, {
      screen: RouteNames.RefundAskProof
    })
  const checkIfTotalIsMet = () => {
    if (refundRepartitionData?.formValues?.totalAmount) {
      if (
        refundRepartitionData?.totalAmount &&
        refundRepartitionData?.formValues?.totalAmount < refundRepartitionData?.totalAmount
      ) {
        setIsValidationModalOpened(true)
      } else {
        goToRefundAskProof()
      }
    }
  }
  const planColors = refundRepartitionData && theme.colors.basics.primary

  const renderSubHeader = () => (
    <>
      {refundRepartitionData && refundRepartitionData?.totalAmount && (
        <RefundTotalSubheader
          refundTotalAmount={refundRepartitionData?.formValues?.totalAmount || 0}
          totalAmount={refundRepartitionData?.totalAmount}
        />
      )}
    </>
  )

  const stepperItems = constructStepperItems({
    size: 5,
    direction: DirectionEnum.ROW,
    activeStep: 2
  })

  const renderHeader = () => (
    <>
      <RefundsHeader
        displayCloseButton
        helpPoint={false}
        stepperItems={stepperItems}
        title={formatMessage({
          id: 'Remboursement_choixFinalDispositif_title'
        })}
      />
      {(isMobile || isTablet) && renderSubHeader()}
    </>
  )

  const onToggleSwitch = (newState: boolean) => {
    onSelectRefundRepartitionAllSupport({
      selectState: newState
    })
  }

  const renderMainContent = () => {
    // for now we manage only one compartment and one management
    const isMultipleCompartment =
      refundRepartitionData?.compartments?.length && refundRepartitionData?.compartments?.length > 1
    const isAllSelected = refundRepartitionData?.formValues?.isAllSelected || false
    return (
      <View>
        <Text
          style={styles.contentTitleStyle}
          variant="t3"
          weight="bold">
          {formatMessage({ id: 'Remboursement_Saisie_subTitle' })}
        </Text>
        <Text
          style={styles.contentSubtitleStyle}
          variant="t3"
          weight="light">
          {formatMessage({ id: 'Remboursement_Saisie_content' })}
        </Text>
        <View style={styles.switchContainerStyle}>
          <Switch
            isOn={isAllSelected}
            onToggle={() => onToggleSwitch(!isAllSelected)}
          />
          <Text
            variant="t3"
            weight="regular">
            {formatMessage({
              id: 'Remboursement_pageSelectionsupports_SelectionTout_bouton'
            })}
          </Text>
        </View>
        {isRefundRepartitionDataLoading && <Spinner />}
        {!isRefundRepartitionDataLoading &&
          !isMultipleCompartment &&
          refundRepartitionData?.compartments?.map((compartment, i) => {
            // for now we manage only one compartment and one management
            const isMultipleManagements =
              compartment?.managements?.length && compartment?.managements?.length > 1
            const filtredSupports = compartment?.managements?.[0].supports

            const isAllSelected = compartment?.formValues?.isAllSelected || false
            return (
              <CardWithLeftThread
                addShadow={true}
                borderLeftColor={planColors?.c500}
                key={i + '_' + compartment.code}>
                <ManagementCardHeader
                  backGroundColorCustom={planColors?.c100}
                  details={[
                    {
                      label: `${formatMessage({
                        id: 'Remboursement_disponibleDispositif_content'
                      })} ${
                        typeof compartment.totalAmount === 'number' &&
                        formatCurrencyNumber({
                          value: compartment.totalAmount
                        })
                      }`
                    }
                  ]}
                  opened={false}
                  title={`${formatMessage({
                    id: 'Remboursement_repartition_myplan_label'
                  })} ${
                    refundRepartitionData.isCustomPlan ? refundRepartitionData.planFullName : refundRepartitionData.shortLabel +
                    (isMultipleCompartment ? ' - ' + compartment.label : '')
                  }`}>
                  <TotalSavingHeader
                    amount={refundRepartitionData?.formValues?.totalAmount}
                    backgroundColor={planColors?.c500}
                    editable={false}
                    onEditAmountPress={() => {}}
                    title={formatMessage({
                      id: 'Remboursement_Total'
                    })}
                  />
                  {!isMultipleManagements && filtredSupports.length > 1 && (
                    <>
                      <View style={styles.compareButtonContainerStyle}>
                        <SimpleButton
                          containerStyle={styles.compareButtonStyle}
                          design="outlined"
                          onPress={() => {
                            setFundsToCompare(compartment?.managements?.[0].supports)
                            setDisplayCompareFundsModal(true)
                          }}
                          size="small"
                          title={formatMessage({
                            id: 'MyFunds_Screen1Compare_Header_Title'
                          })}
                        />
                      </View>
                    </>
                  )}
                  <View>
                    {compartment?.managements?.[0]?.supports &&
                    compartment?.managements?.[0]?.supports?.length === 0 ? (
                      renderNoDataAlert()
                    ) : (
                      <>
                        {compartment?.managements?.[0]?.supports?.map((support, i) => {
                          return (
                            <View key={i}>
                              {renderFunds({
                                compartmentId: compartment.code,
                                managementId: compartment?.managements?.[0].id,
                                support
                              })}
                            </View>
                          )
                        })}
                      </>
                    )}
                  </View>
                </ManagementCardHeader>
              </CardWithLeftThread>
            )
          })}
        {fundSheetModalData && (
          <FundSheetModal
            id={fundSheetModalData?.id}
            modalIsVisible={displayFundSheetModal}
            onCloseModal={() => {
              setDisplayFundSheetModal(false)
              setFundSheetModalData(null)
            }}
            title={fundSheetModalData?.title}
          />
        )}
        <RefundsRepartitionValidationModal
          onClose={() => {
            setIsValidationModalOpened(false)
          }}
          onSubmit={() => {
            goToRefundAskProof()
            setIsValidationModalOpened(false)
          }}
          visible={isValidationModalOpened}
        />
        <CompareFundsModal
          fundsToCompare={fundsToCompare?.map(fund => {
            return {
              isinCode: fund.supportIsin,
              supportName: fund.supportName,
              addedValue: 0,
              hasContribution: fund.hasContribution,
              amount: fund.amount.amount,
              hasNoFundSheet: fund?.hasNoFundSheet,
              source: fund?.source
            }
          })}
          modalIsVisible={displayCompareFundsModal}
          onCloseModal={() => {
            setDisplayCompareFundsModal(false)
          }}
        />
        <RefundAmountFieldModalConnected ref={refundAmountModal} />
        <RefundTriggerThresholdModalConnected ref={triggerThresholdModal} />
      </View>
    )
  }

  const renderBottomActions = () => (
    <View style={styles.bottomActionsStyle}>
      <View style={styles.bottomActionsButtonsContainerStyle}>
        <SimpleButton
          containerStyle={styles.arrowButtonStyle}
          design="outlined"
          leftIcon={IconEnum.CHEVRON_LEFT}
          onPress={() => navigation.goBack()}
        />
        <SimpleButton
          containerStyle={styles.simpleButtonStyle}
          design="solid"
          disabled={!refundRepartitionData?.formValues?.totalAmount}
          onPress={checkIfTotalIsMet}
          size="small"
          testId={locator._recover_savings._continue}
          title={formatMessage({ id: 'Remboursement_boutonValiderContinuer' })}
        />
      </View>
    </View>
  )

  const renderNoDataAlert = () => {
    return (
      <NotificationHard
        containerStyle={[
          styles.notificationContainerStyle,
          { marginBottom: theme.metrics.spacing.xm }
        ]}
        text={formatMessage({ id: 'Remboursement_Repartition_NoSupport_label' })}
        title={formatMessage({ id: 'Remboursement_Repartition_NoSupport_title' })}
        type={NotificationIconTypeEnum.WARNING}
      />
    )
  }

  const renderFunds = ({
    compartmentId,
    managementId,
    support
  }: {
    compartmentId: string
    managementId: string
    support: RefundRepartitionSupportsState
  }) => {
    const isSelected = support.formValues?.isSelected || false
    const currentDisplayAmount = typeof support.formValues?.amount === 'number' ? 0 : 0
    const _handleSelectAmountField = () => {
      onSetRefundRepartitionAmount({
        amount: support.amount?.amount || 0,
        compartmentId,
        managementId,
        supportIsin: support.supportIsin
      })
    }

    const isLinkUnderfieldLabel =
      support.amount?.amount === support.formValues?.amount ||
      support.formValues?.isDisabled ||
      support.formValues?.triggerThreshold?.amount
        ? undefined
        : !support.formValues?.amount
        ? formatMessage({
            id: 'Remboursement_ToutRecuperer'
          })
        : 'Récupérer le reste'
    return (
      <SavingRepartitionCard
        addedValue={support.amount.addedValue}
        amount={support.amount.amount}
        availableAmount={isSelected ? support.amount.amount : undefined}
        disabled={support.formValues?.isDisabled} // not given yet
        editable={true}
        fieldAmount={
          isSelected
            ? currentDisplayAmount
              ? support?.amount?.amount
              : support?.formValues?.amount
            : undefined
        }
        hasNoFundSheet={support?.hasNoFundSheet}
        isLinkUnderField
        isLinkUnderfieldAlwaysEnabled
        isRefund
        isSelectable
        isSelected={isSelected}
        legalStatus={support.legalStatus || ''}
        nextValorisationDate={support.nextNavDate || undefined}
        onCardPress={() => {
          setDisplayFundSheetModal(true)
          setFundSheetModalData({
            id: support?.supportIsin,
            title: support.supportName
          })
        }}
        onEditAmountPress={() => {
          refundAmountModal.current?.openWithData({
            displayData: {
              title: refundRepartitionData?.isCustomPlan ? refundRepartitionData.planFullName : refundRepartitionData?.shortLabel || '',
              initialAmount: currentDisplayAmount || undefined,
              contentTitle: support.supportName?.toUpperCase(),
              amountParameters: {
                availableAmount: support.amount.amount || 0,
                sharesCount: support.amount.numberOfUnits || 0
              },
              allAmountValue: support.amount.amount
            },
            supportIsIn: support.supportIsin,
            compartmentId,
            managementId
          })
        }}
        onLinkUnderFieldPress={_handleSelectAmountField}
        onSelectChange={() => {
          _handleSelectAmountField()
          onSelectRefundRepartitionSupport({
            selectState: !isSelected,
            compartmentId,
            managementId,
            isinCode: support.supportIsin
          })
        }}
        performanceValue={support.performanceValue}
        selectType={SavingRepartitionCardSelectTypeEnum.CHECKBOX}
        source={support?.source}
        supportName={support.supportName}
        testId={support.supportName}
        // linkUnderfieldLabel={formatMessage({ id: 'Remboursement_ToutRecuperer' })}
        linkUnderfieldLabel={isLinkUnderfieldLabel}>
        {support.advancedProperties?.HasThresholdTrigger && (
          <TriggerThresholdComponent
            data={support?.formValues?.triggerThreshold}
            isEditable={support?.formValues?.isSelected && !support?.formValues?.isDisabled}
            onDeletePress={() =>
              ClearRefundSupportTriggerThresholdData({
                compartmentId: compartmentId,
                managementId: managementId,
                supportIsInCode: support?.supportIsin
              })
            }
            onEditPress={() => {
              const nextAvailabilityDate = support?.advancedProperties?.ThresholdTriggerValidityDate
              const thresholdTriggertype = support?.advancedProperties?.ThresholdTriggerType
              const thresholdTriggerTitle = support?.advancedProperties?.ThresholdTriggerTitle
              const valueDate = () => {
                switch (thresholdTriggertype) {
                  case ThresholdTriggerTypesEnum.VPP:
                    return support.netAssetValue?.dateValue
                  case ThresholdTriggerTypesEnum.VCP:
                    return support?.advancedProperties?.ThresholdTriggerValueDate
                  default:
                    return ''
                }
              }

              const valueAmount = () => {
                switch (thresholdTriggertype) {
                  case ThresholdTriggerTypesEnum.VPP:
                    return support?.netAssetValue?.amount
                  case ThresholdTriggerTypesEnum.VCP:
                    return support?.advancedProperties?.ThresholdTriggerValueAmount
                  default:
                    return 0
                }
              }

              triggerThresholdModal.current?.openWithData({
                displayData: {
                  amount: support.formValues?.triggerThreshold?.amount,
                  title: support.supportName || '',
                  valuationDate: valueDate() || '',
                  triggerPointDate: String(nextAvailabilityDate),
                  sharesValue: valueAmount() || 0,
                  thresholdTriggerType: thresholdTriggertype || '',
                  thresholdTriggerTitle: thresholdTriggerTitle || '',
                  course: ThresholdCoursesEnum.refund
                },
                compartmentId,
                managementId,
                supportIsInCode: support?.supportIsin
              })
            }}
          />
        )}
      </SavingRepartitionCard>
    )
  }

  const renderRightDesktopColumn = () => (
    <>
      <BubbleContainer containerStyle={styles.subHeaderContainerStyle}>
        {renderSubHeader()}
      </BubbleContainer>
    </>
  )

  return (
    <DashboardPageWrapperConnected>
      <View style={styles.containerStyle}>
        <ColumnsContainer
          renderHeader={() => renderHeader()}
          renderRightDesktopColumn={() => renderRightDesktopColumn()}
          renderStickyMobileBottom={() => renderBottomActions()}>
          {renderMainContent()}
          {!isMobile && !isTablet && renderBottomActions()}
        </ColumnsContainer>
      </View>
    </DashboardPageWrapperConnected>
  )
}

const useStyles = createUseStyles(getStyles)
