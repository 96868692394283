import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { AppState } from '@modules/reducers';
import { getUserAccessTokenData } from '@modules/auth/selectors';

export const useIsUserCloned = () => {
  const accessTokenDecoded = useSelector((state: AppState) => getUserAccessTokenData(state));
  const checkIsUserCloned = useMemo(() => {
    return !accessTokenDecoded?.ere360_owner;
  }, [accessTokenDecoded]);

  return checkIsUserCloned;
};
