import { BankDomiciliationModalStyles, StylesContext } from './interfaces';

export const getStyles = (
  context?: StylesContext,
  style?:  BankDomiciliationModalStyles,
):  BankDomiciliationModalStyles => ({
  informationRow: [
    {
      maxWidth: '100%',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      marginTop: context?.theme.metrics.spacing.l
    },
    style?.informationRow
  ],
  separator: [
    {
      width: 1,
      height: '100%',
      backgroundColor: context?.theme.colors.basics.grey.c200, //theme.colors.opacityGrey,
      margin: context?.theme.metrics.spacing.s
    },
    style?.separator
  ],
  textContainer: [
    {
      maxWidth: '90%'
    },
    style?.textContainer
  ],
  itemStyle: [
    {
      borderBottomWidth: 1,
      width: "100%",
      borderColor: context?.theme?.colors.basics.grey.c200,
      paddingHorizontal: context?.theme.metrics.spacing.m,
      paddingVertical: context?.theme.metrics.spacing.s
    },
    style?.itemStyle
  ],
  scrollViewStyle: [{ width: '100%', flex: 1, marginTop: context?.theme.metrics.spacing.s }, style?.scrollViewStyle]
});
