import React, { useEffect } from 'react';

import { OfflineAnnounceLayout360 } from '@components/index';
import { silentCallback } from '@modules/auth/services';
import { useTranslation } from '@translations/index';
import { useLogoNavigation } from '@utils/index';

export const OidcCallbackRedirectSilentPage: React.FC<React.PropsWithChildren<unknown>> = () => {

  const { formatMessage } = useTranslation();
  const { logoNavigateTo } = useLogoNavigation();

  useEffect(() => {
    // this component is fired when to oidc redirect us to oidc/silent, at this moment
    // we can fire any function which can be used to update something in the app
    // for instance silentCallback is called but it does nothing, we can use it later for some purpose
    silentCallback();
  }, []);

  return (
    <OfflineAnnounceLayout360
      description={formatMessage({ id: 'login_transition_label' })}
      onLogoPress={logoNavigateTo}
      sourceName="welcomeGif"
      title={formatMessage({ id: 'login_transition_title' })}
    />
  );
};

