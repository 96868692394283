import { EditProfileInformationsPageContext, EditProfileInformationsPageStyle } from './interfaces';

export const getStyles = (
  context?: EditProfileInformationsPageContext,
  style?: EditProfileInformationsPageStyle
): EditProfileInformationsPageStyle => ({
  containerStyle: [
    {
      backgroundColor: context?.theme.colors.basics.grey.c100
    },
    style?.containerStyle
  ],
  cardItemParentContainer: {
    marginBottom: context?.theme.metrics.spacing.xm
  },
  separator: [
    {
      borderBottomWidth: 1,
      width: '100%',
      borderColor: context?.theme.colors.basics.grey.c200,
      alignSelf: 'center',
      marginVertical: context?.theme?.metrics.spacing.m
    },
    style?.separator
  ],
  contentContainerStyle: [
    {
      alignItems: 'center',
      paddingBottom: context?.theme?.metrics.spacing.big
    },
    style?.contentContainerStyle
  ],
  descriptionTextContainer: [
    {
      marginTop: context?.theme.metrics.spacing.xm,
      maxWidth: context?.theme.metrics.contentSizes.centeredContentMaxWidth
    },
    style?.descriptionTextContainer
  ],
  descriptionText: [
    { textAlign: 'center', lineHeight: context?.theme?.metrics.spacing.xm },
    style?.descriptionText
  ],
  loaderContainer: [{ marginBottom: context?.theme?.metrics.spacing.xm }, style?.loaderContainer]
});
