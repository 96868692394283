import { useScreenSizes, useTheme } from "@ere-uilib/styles";
import { useMemo } from "react";
import { StyleSheet } from "react-native";

export const useStyles = () => {
  const theme = useTheme();
  const { isMobile, isDesktop } = useScreenSizes();

  const styles = useMemo(() => StyleSheet.create({
    title: {
      color: theme.colors.basics.primary.c500
    },
    chevron: {
      ...(!isDesktop && { marginBottom: 1 }),
      marginLeft: theme.metrics.spacing.s,
    },
  }), [theme, isMobile]);

  return styles;
};