import { ArbitrationTypes } from '@constants/arbitrations';
import {
  getDivestmentSource,
  getPlanCompartments,
  getReallocationType,
  getSelectedCompartment,
  getSelectedDispositif
} from '@modules/arbitration/selectors';
import { ManagmentType, SupportType } from '@modules/common/types';
import { useSelector } from 'react-redux';

export const useDeinvestmentSource = () => {
  const selectedDispositif = useSelector(getSelectedDispositif);
  const dinvestedManagementSource = useSelector(getDivestmentSource);
  const chosenCompartment = useSelector(getSelectedCompartment);
  const planCompartments = useSelector(getPlanCompartments);
  const selectedArbitartionType = useSelector(getReallocationType);

  const isFlow = selectedArbitartionType === ArbitrationTypes.flow;

  const filterSupports = (support: SupportType) => support.supportIsin === dinvestedManagementSource?.supportIsinCode;
  const filterManagament = (management: ManagmentType) => management.id === dinvestedManagementSource?.managementId;

  const dispositif = selectedDispositif && selectedDispositif[0];
  const partialReallocation = dispositif?.filterProperties.PartialReallocation;

  const isSrcVisibleManagementHeader =
    (chosenCompartment &&
      chosenCompartment.managements &&
      chosenCompartment.managements.length > 1 &&
      partialReallocation) ||
    !partialReallocation;

  let divestedManagement;

  if (isFlow) {
    divestedManagement = chosenCompartment?.managements.map(management => {
      let supports = management?.supports;
      const percentage = management?.supports.reduce((prevValue, currentSupport) => {
        return prevValue + (currentSupport?.advancedProperties?.PourcentageRepartition ?? 0)
      }, 0)

      if (management && partialReallocation && management.isFree) {
        supports = management.supports.filter(filterSupports) ?? [];
      }

      management.advancedProperties.PourcentageRepartition = percentage

      return {
        ...management,
        supports,
      }
    })
  } else {
    const usedManagement = chosenCompartment?.managements?.find(filterManagament);

    let supports = usedManagement?.supports;

    if (usedManagement && partialReallocation && usedManagement.isFree) {
      supports = usedManagement.supports.filter(filterSupports) ?? [];
    }
    divestedManagement = [{
      ...usedManagement,
      supports,
    }]
  }

  const isSrcVisibleCompartmentTitle = chosenCompartment?.label && chosenCompartment.label !== '';

  return {
    isSrcVisibleCompartmentTitle,
    isSrcVisibleManagementHeader,
    amount: dinvestedManagementSource?.amount,
    partialReallocation,
    sourceCompartment: {
      ...chosenCompartment,
      managements: divestedManagement,
    },
    planData: planCompartments,
  };
};
