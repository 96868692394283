import { useTranslation } from '@ere-uilib/translations';

export const useLabels = () => {
  const { formatMessage } = useTranslation();

  return {
    backBtnLabel: formatMessage({ id: 'CoordonneesBancairesSaisieRetourBouton' }),
    countrySubtitle: formatMessage({ id: 'CoordonneesBancairesChoixPaysSousTitre' }),
    countryDescription: formatMessage({ id: 'CoordonneesBancairesChoixPaysDescription' }),
    countrySelectChoiceDescription: formatMessage({ id: 'CoordonneesBancairesUploadCaseACocherDescription' }),
    cniLabel: formatMessage({ id: 'CoordonneesBancairesRecapPopinCNI' }),
    passportLabel: formatMessage({ id: 'CoordonneesBancairesRecapPopinRIB' }),
    rhCorrespondantTitle: formatMessage({ id: 'CoordonneesBancairesRecapRHCorrespondantTitre' }),
    courrierEnvoiTitle: formatMessage({ id: 'CoordonneesBancairesRecapCourrierEnvoiTitre' }),
    tsaOrUploadtitle: formatMessage({ id: 'CoordonneesBancairesRecapPopinTitre' }),
    rhDescription: formatMessage({ id: 'CoordonneesBancairesRecapRHDescription' }),
    existedMandatLabel: formatMessage({ id: 'CoordonneesBancairesRecapExistedMandat' }),
    correspondantDescription: formatMessage({ id: 'CoordonneesBancairesRecapCorrespondantDescription' }),
    courrierTitle: formatMessage({ id: 'CoordonneesBancairesRecapCourrierTitre' }),
    courrierDescription: formatMessage({ id: 'CoordonneesBancairesRecapCourrierDescription' }),
    courrierEnvoiDestinationLabel: formatMessage({ id: 'CoordonneesBancairesRecapCourrierEnvoiDestinationLabel' }),
    mandatSampleLabel: formatMessage({ id: 'CoordonneesBancairesRecapPopinMandatLabel' }),
    mandatSampleLink: formatMessage({ id: 'CoordonneesBancairesRecapPopinMandatLink' }),
  };
};
