import { useNavigation } from '@react-navigation/native';
import React, { useCallback } from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import { DialOncePressable, MobileMenuComponent, useTranslation } from '@components/index';
import { RouteNames } from '@constants/navigation';
import { RenderMobileMenuItemParams } from '@ere-uilib/organisms/menus/MobileMenuModal/interface';
import { MenuFooterState } from '@ere-uilib/types/index';
import { MobileMenuListState, ApplicationSettingsState } from '@modules/settings/types';
import { useUrlLink } from '@utils/index';

export interface DataProps  {
  isMobileMenuVisible: boolean
  applicationSettings: ApplicationSettingsState
  mobileMenuList: MobileMenuListState[]
  links: MenuFooterState[]
}

export interface LegalMenuFooterStyles {
  modalContainer?:StyleProp<ViewStyle>
  scrollViewContainer?:StyleProp<ViewStyle>
  menuWrapper?:StyleProp<ViewStyle>
  linksContainer?:StyleProp<ViewStyle>
  linksContainerRow?:StyleProp<ViewStyle>
  separator?:StyleProp<ViewStyle>
  legalLinksContainer?:StyleProp<ViewStyle>
  legalLinksContainerRow?:StyleProp<ViewStyle>
}

type LegalMenuFooterProps = DataProps & LegalMenuFooterStyles;

export const MobileMenuModalConnectedComponent: React.FC<LegalMenuFooterProps> =  ({
  isMobileMenuVisible,
  mobileMenuList,
  applicationSettings,
}: LegalMenuFooterProps) => {
  const navigation = useNavigation();
  const { linkToUrl } = useUrlLink();
  const { formatMessage } = useTranslation();

  const renderHelpActionMenu = (renderMenuItem: (params: RenderMobileMenuItemParams) => JSX.Element) => (
    <DialOncePressable
      integrationKey={applicationSettings?.HelpDialOnceIntegrationKey || ''}
      targetedFlowId={applicationSettings?.HelpActionMenuDialOnceId || ''}
    >
      {renderMenuItem({ label: formatMessage({ id: 'actionmenu_help_label' }) })}
    </DialOncePressable>
  );

  const onMenuItemPress = (operationType?: string, url?: string) => {
    if (operationType) {
      navigation.navigate(RouteNames.OperationTypeModalPage, {
        type: operationType,
      });
    } else if (url) {
      linkToUrl(url);
    }
  };

  const handlePressLogoutButton = useCallback(() => {
    navigation.navigate(RouteNames.Logout);
  }, [navigation]);

  return (
    <MobileMenuComponent
      isMobileMenuVisible={isMobileMenuVisible}
      mobileMenuList={mobileMenuList}
      onMenuItemPress={onMenuItemPress}
      onPressLogoutButton={() => handlePressLogoutButton()}
      renderCustomMenuItems={renderHelpActionMenu}
    />
  );
};
