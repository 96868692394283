import { Text } from '@ere-uilib/atoms';
import { FontIcon } from '@ere-uilib/atoms/images/FontIcon/FontIcon';
import { useTheme } from '@ere-uilib/styles';
import { useTranslation } from '@translations/index';
import React, { FunctionComponent, useMemo } from 'react';
import { View } from 'react-native';
import { AvailabilityListitemProps } from './interfaces';
import { useStyles } from './useStyles';
import { ChevronLink } from '@ere-uilib/molecules/ChevronLink';



const AvailabilityListItemByDate: FunctionComponent<AvailabilityListitemProps> = ({
  label,
  icon,
  amount,
  items,
  amountLabel,
  testId,
  linkText,
  linkAction
}) => {
  const theme = useTheme();
  const { formatMessage, formatCurrencyNumber, formatDate } = useTranslation();
  const styles = useStyles();

  const renderListItems = useMemo(() => {
    return items?.reduce<React.ReactElement[]>((acc, item, index, array) => {
      if (!item.amount) return acc;
      const translatedDate = formatDate({
        value: item.dateAmount,
        options: {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }
      });

      const translatedCurrencyNumber = formatCurrencyNumber({ value: item.amount });
      const ListItem = (
        <View
          key={index + item.dateAmount + item.amount}
          style={[
            styles.availabilityContainer,
            index == items?.length - 1 && styles.lastAvailabilityContainer,
        ]}
        >
          <Text
            variant="t3"
            weight={"light"}
          >
            {`${formatMessage({ id: 'DetailOperationLeLabel' })} ${translatedDate}`}
          </Text>

          <Text variant="t3" weight={"light"}>{translatedCurrencyNumber}</Text>
        </View>
      );
      return [...acc, ListItem]
    }, []);
  },[items]);

  return (
    <View style={styles.container}>
      <View style={styles.innerContainerWithIcon}>
        <View style={styles.iconWrapper}>
          <FontIcon
            name={icon}
            size={theme.metrics.iconSizes.mplus}
          />
        </View>
        <View style={styles.textContainer}>
          <View style={styles.columnTextWrapper}>
            <Text variant="t3" weight={"regular"}>{formatMessage({ id: label })}</Text>
            {linkText && linkText !== '' && (
              <ChevronLink
                title={linkText}
                onPress={linkAction}
              />
            )}
          </View>
          <View>
            {amountLabel && (
              <Text variant="t4" weight="regular" style={styles.amountLabel}>{formatMessage({ id: amountLabel })}</Text>
            )}
            <Text
              testId={testId}
              variant="t1"
              weight="bold"
            >{formatCurrencyNumber({ value: amount })}</Text>
          </View>
        </View>
      </View>
      {items && items.length > 0 && (
        <View style={styles.listContainer}>
          {renderListItems}
        </View>
      )}
    </View>
  );
};

export default AvailabilityListItemByDate;
