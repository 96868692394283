import React from 'react';
import { StyleProp, TextStyle, View, ViewStyle } from 'react-native';

import { Title } from '@ere-uilib/atoms';
import { LinkBar } from '@ere-uilib/molecules';

interface HistoryHeaderProps {
  tabItems: string[];
  selectedItem: string;
  tabsContainerStyle: StyleProp<ViewStyle>;
  titleStyle: StyleProp<TextStyle>;
  title: string;
  onChangeSelectedItem: (item: string) => void;
}

export const HistoryHeader: React.FC<React.PropsWithChildren<HistoryHeaderProps>> = ({
  tabItems,
  selectedItem,
  tabsContainerStyle,
  titleStyle,
  title,
  onChangeSelectedItem,
}) => {
  return (
    <View >
      <View style={tabsContainerStyle}>
        <Title
          style={titleStyle}
          variant="t3">
          {title}
        </Title>
      </View>
      <LinkBar
        items={tabItems}
        onSelect={item => {
          onChangeSelectedItem(item);
        }}
        selectedItem={selectedItem}
      />
    </View>
  );
};
