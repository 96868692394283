import axios from "axios";

import { API_URL } from "@config/index";
import { SettingsState } from "@modules/settings/types";

export async function fetchMobileMenuList(
  companyId: string
): Promise<SettingsState> {
  return await axios.get(
    `${API_URL}/api/v1/application/menus/actionmenu/${companyId}`
  );
}

export async function fetchApplicationSettings(clientId: string): Promise<SettingsState> {
  return await axios.get(
    `${API_URL}/api/v1/application/settings?clientId=${clientId}`
  );
}
