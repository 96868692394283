import { ValidationContext, ValidationStyles } from './interface';

export const getStyles = (
  context?: ValidationContext,
  style?: ValidationStyles,
): ValidationStyles => ({
  inputStyle: [style?.inputStyle],
  containerStyle: [
    {},
    style?.containerStyle,
  ],
  subtitleStyle: [
    {},
    style?.subtitleStyle,
  ],
  inputsContainerStyle: [
    {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      marginTop: context?.theme.metrics.spacing.l,
      marginBottom: context?.theme.metrics.spacing.l,
      maxWidth: 402,
    },
    style?.inputsContainerStyle,
  ],
  retryLinkContainer: [
    {
      marginTop: context?.theme.metrics.spacing.s,
      alignItems: 'flex-end',
    },
    style?.retryLinkContainer,
  ],
  retryLink: [
    {
    },
    style?.retryLink,
  ],
  descriptionStyle: [
    {
      marginTop: context?.theme.metrics.spacing.xxl,
    },
  ],
});
