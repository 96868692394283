import React, { useCallback, useState } from 'react';
import { Pressable, View, Text as RNText } from 'react-native';

import { Text, Title, FontIcon } from '@ere-uilib/atoms';
import { OperationsSubHeader, StatusTag } from '@ere-uilib/molecules'
import { useTheme } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';

import { OperationsHeaderProps } from './interfaces';
import { useStyles } from './useStyles';

export const OperationsHeader: React.FC<React.PropsWithChildren<OperationsHeaderProps>> = ({
  title,
  amount,
  details,
  advantageList,
  statusLabel,
  statusType,
  creationDate,
  hideAmount,
  style,
  
}) => {
  const theme = useTheme();

  const styles = useStyles({ style });
  const { 
    formatMessage,
    formatCurrencyNumber, 
    formatDate
  } = useTranslation();

  const [expanded, setExpanded] = useState(false);

  const RenderDetails = useCallback(() => (
    <View style={styles.taxationDetails}>
      {details?.map(detail => {
        return(
          <View style={styles.taxationDetailsRightRow}>
            <RNText>
              <Text
                variant="t4"
                weight="light">
                {detail.title}
              </Text>
                  &nbsp;
              <Text
                style={styles.taxationDetailsRightValue}
                variant="t4"
                weight="regular">
                {detail.value}
              </Text>
            </RNText>
          </View>
        )
      })}
    </View>
  ), [
    styles,
    details
  ]);
  
  return (
    <View style={styles.cardContainer}>
      <View style={styles.cardInnerContainer}>
        <View style={styles.headerContainer}>
          <View style={styles.headerTitleLine}>
            <Title
              style={styles.cardTitle}
              variant='t6'
              weight='bold'>
              {title}
            </Title>
            {
              !hideAmount &&
            <Title
              style={styles.cardAmount }
              variant='t6'
              weight='bold'
            >
              {amount ? formatCurrencyNumber({ value: amount }) : ""}
            </Title>
            }
          </View>
          {!!statusType && !!statusLabel && (
            <StatusTag
              label={statusLabel}
              type={statusType}
            />
          )}
        </View>
        {details && details.length > 0 && (
          <View style={styles.expandContainerStyle}>
            <View style={styles.expandLinkLineContainer}>
              {!!creationDate && (
                <Text
                  style={styles.expandDate}
                  variant='t4'
                  weight='light'
                >
                  {formatMessage({ id: 'DetailOperationLeLabel' })}
                  {' '}
                  <Text variant='t4'>
                    {formatDate({ value: creationDate })}
                  </Text>
                </Text>
              )}
              <Pressable
                disabled={false}
                onPress={() => setExpanded(!expanded)}
                style={styles.expandLink}
              >
                <View style={styles.checkBoxContainer}>
                  <Text
                    style={styles.subTitle}
                    variant="t3">
                    {formatMessage({ id: 'DetailOperationDetailLabel' })}
                  </Text>
                  <FontIcon
                    color={theme.colors.text.link.c500}
                    name={expanded ? 'chevron-haut' : 'chevron-bas'}
                    size={theme.metrics.iconSizes.xm}
                    style={styles.chevronStyle}
                  />
                </View>
              </Pressable>
            </View>
            {expanded && (
              <RenderDetails />
            )}
          </View>
        )}
        {advantageList && advantageList.length > 0 && (
          <OperationsSubHeader advantageList={advantageList} />
        )}
      </View>
    </View>
  );
};