import React from 'react';
import { StyleProp, TextStyle, View, ViewStyle } from 'react-native';

import { FontIcon, Text } from '@ere-uilib/atoms';
import { iconDisplayDetailsByType } from '@ere-uilib/constants/notificationIcons';
import { createUseStyles, ThemeType, useTheme } from '@ere-uilib/styles';

type NotificationTypes = 'info' | 'help' | 'warning' | 'success'

interface Props {
  type?: NotificationTypes
  text?: string
  title?: string
  containerStyle?: StyleProp<ViewStyle>;
  iconContainerStyle?: StyleProp<ViewStyle>;
  textContainerStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  iconColor?: string;
}

type Styles = {
  containerStyle?: StyleProp<ViewStyle>;
  iconContainerStyle?: StyleProp<ViewStyle>;
  textContainerStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
};

type StylesContext = { theme: ThemeType };

export const NotificationSoft: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  type = 'info',
  text,
  containerStyle,
  iconContainerStyle,
  textContainerStyle,
  textStyle,
  title,
  children,
  iconColor,
}) => {
  const theme = useTheme();

  const currentTypeIconDetail = iconDisplayDetailsByType[type];

  const styles = useStyles({ theme }, { containerStyle, iconContainerStyle, textContainerStyle, textStyle });

  return (
    <View style={styles.containerStyle}>
      <View style={styles.iconContainerStyle}>
        <FontIcon
          color={iconColor ? iconColor : currentTypeIconDetail.iconColor}
          name={currentTypeIconDetail.iconName}
          size={theme.metrics.iconSizes.mplus}
        />
      </View>
      <View style={styles.textContainerStyle}>
        {title && (<Text
          variant={'t3'}
          weight={'regular'}
        >
          {title}
        </Text>
        )}
        <Text
          style={styles.textStyle}
          variant={'t3'}
          weight={'light'}
          testId='connection_error'
        >
          {text}
        </Text>
        {children}
      </View>
    </View>
  );
};

const getStyles = (
  context?: StylesContext,
  style?: Styles,
): Styles => ({
  containerStyle: [{
    flexDirection: 'row',
    alignItems: 'center',
    padding: context?.theme.metrics.spacing.xm,
  }, style?.containerStyle],
  iconContainerStyle: [{
    marginRight: context?.theme.metrics.spacing.xm,
  }, style?.iconContainerStyle],
  textContainerStyle: [{
    paddingLeft: context?.theme.metrics.spacing.s,
    borderLeftWidth: 1, // TODO : theme value theme.border.normal is in pixel
    borderLeftColor: context?.theme.colors.basics.grey.c200,
    flex: 1,
  }, style?.textContainerStyle],
  textStyle: [{
  }, style?.textStyle]
});

const useStyles = createUseStyles(getStyles);
