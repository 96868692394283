import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import {useRoute} from "@react-navigation/core";

import {
  BankAccountInfosCard,
  FiletStepperAccordionCard, FiletStepperCardWrapper,
  FiletStepperSimpleCard, FundSheetModal,
  FundSheetModalDataType, NavigationHeader, SimpleButton,
  useScreenSizes, useTranslation
} from '@components/index';
import {
  ApiPaymentPeriodicityEnum,
  PlanFamillyList
} from '@constants/index';
import { useTheme } from '@ere-uilib/styles';
import { OnGoingVVP } from '@modules/savings/types';

import {
  VVPDeleteModal, VVPEditInformativePopIn
} from './components';
import { SubHeader } from './SubHeader';
import { VVPDetailsPageTemplate } from './Template';
import { useStyles } from './useStyles';
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { AppNavigatorInterface } from "@navigation/Interfaces";
import { getOnGoindVVPById } from '@modules/savings/selectors';

export interface Props {
  onEditVVP: ({ operationId }: { operationId: string }) => void
  setIsDetailsVvpPopin(isOpened: boolean): void
  isDetailsVVPPopInOpened: boolean
  isEditVVPLoading: boolean
  onDeleteVVP: ({
    planId,
    operationId,
  }: {
    planId: string
    operationId: string
  }) => void
  setOnGoingVVPDeleteModalVisibleState: (visible: boolean) => void
  isGoingVVPDeleteModalVisible: boolean
  isDeleteVVPLoading: boolean
  installmentError?: string
  installmentInitError: () => void
}
interface Route {
  params: { id: string };
}

export const VVPDetailsPage: React.FC<Props> = ({
  onEditVVP,
  setIsDetailsVvpPopin,
  isDetailsVVPPopInOpened,
  isEditVVPLoading,
  onDeleteVVP,
  setOnGoingVVPDeleteModalVisibleState,
  isGoingVVPDeleteModalVisible,
  isDeleteVVPLoading,
  installmentError,
  installmentInitError,
}) => {
  const theme = useTheme();
  const navigation = useNavigation<StackNavigationProp<AppNavigatorInterface>>();
  const { styles } = useStyles();
  const { isDesktop, isMobile } = useScreenSizes();
  const { formatMessage, formatDate, getMessageRaw } = useTranslation();
  const route = useRoute();
  const value = route?.params as {id: string} | undefined;
  const onGoingVVP = useSelector(getOnGoindVVPById(value?.id));

  const operationId: string | undefined = onGoingVVP?.id;
  const [displayFundSheetModal, setDisplayFundSheetModal] = useState(false);
  const [fundSheetModalData, setFundSheetModalData] = useState<FundSheetModalDataType | null>();

  useEffect(() => {
    setOnGoingVVPDeleteModalVisibleState(false);
    setIsDetailsVvpPopin(false);
    installmentInitError();
  }, [setOnGoingVVPDeleteModalVisibleState, installmentInitError, setIsDetailsVvpPopin]);

  const amount = onGoingVVP?.amount;
  const managements =
    onGoingVVP?.managements.filter(management => management.totalAmount && management.label);

  const bankAccountDetail = useMemo(() => onGoingVVP?.bankAccountDetail, [onGoingVVP]);

  const renderHeader = useCallback(() => (
    <NavigationHeader
      displayBackButton={true}
      helpPoint
      helpPointContentHtml={getMessageRaw({ id: 'VV_VVPDetail_info_description' })}
      helpPointModalTitle={formatMessage({ id: 'VV_VVPDetail_info_label' })}
      helpPointPosition={'right'}
      onGoBack={() => navigation.goBack()}
      title={formatMessage({ id: 'VV_VVPDetail_titre' })}
    />
  ), [formatMessage, navigation, getMessageRaw]);

  const handleDeleteButtonPress = () => {
    installmentInitError();
    setOnGoingVVPDeleteModalVisibleState(true);
  };
  const handleCancelDeleteModalButtonPress = () => {
    setOnGoingVVPDeleteModalVisibleState(false);
  };
  const handleValidDeleteModalButtonPress = () => {
    !!operationId && !!onGoingVVP?.planId && onDeleteVVP({
      planId: `${onGoingVVP.planId}`,
      operationId,
    });
  };
  const handleEditInformativePopinOpen = () => {
    installmentInitError();
    setIsDetailsVvpPopin(true);
  };
  const handleEditInformativePopinClose = () => setIsDetailsVvpPopin(false);
  const handleEditInformativePopinSubmit = () => {
    !!operationId && onEditVVP({
      operationId: operationId,
    });
  };

  const periodicityLabels = useMemo(() => ({
    [ApiPaymentPeriodicityEnum['1M']]: formatMessage({
      id: 'VV_recap_vp_frequencymonthly_info',
    }),
    [ApiPaymentPeriodicityEnum['3M']]: formatMessage({
      id: 'VV_recap_vp_frequency_info',
      values: { frequency: 3 },
    }),
    [ApiPaymentPeriodicityEnum['6M']]: formatMessage({
      id: 'VV_recap_vp_frequency_info',
      values: { frequency: 6 },
    }),
  }), [formatMessage]);

  const periodicityLabel = useMemo(() => {
    if (!onGoingVVP) {
      return '';
    }

    return periodicityLabels[onGoingVVP?.periodicity];
  }, [onGoingVVP, periodicityLabels]);

  const renderActionButtons = () => (
    <View
      style={[
        styles.buttonContainer,
        isMobile && styles.buttonContainerMobile,
        isDesktop && styles.buttonContainerDesktop,
      ]}>
      <SimpleButton
        containerStyle={{ flex: 1 }}
        design="outlined"
        onPress={handleDeleteButtonPress}
        title={formatMessage({ id: 'VV_VVP_Actuel_supprimer_Bouton' })}
      />
      <SimpleButton
        containerStyle={{ flex: 1, marginLeft: 15 }}
        onPress={handleEditInformativePopinOpen}
        title={formatMessage({ id: 'VV_VVP_Actuel_Modifier_bouton' })}
      />
      <VVPEditInformativePopIn
        error={installmentError}
        isButtonLoading={isEditVVPLoading}
        isVisible={isDetailsVVPPopInOpened}
        onClose={handleEditInformativePopinClose}
        onValid={handleEditInformativePopinSubmit}
      />
      <VVPDeleteModal
        error={installmentError}
        isOnValidLoading={isDeleteVVPLoading}
        onCancel={handleCancelDeleteModalButtonPress}
        onValid={handleValidDeleteModalButtonPress}
        visible={isGoingVVPDeleteModalVisible}
      />
    </View>
  );

  const renderSubHeader = useCallback(() => {
    if (!amount || !onGoingVVP) {
      return null;
    }

    return (
      <SubHeader
        amount={amount}
        createdAt={formatDate({ value: onGoingVVP?.createdDate })}
        isES={onGoingVVP.planFamily === PlanFamillyList.ES}
        nextPaymentDate={formatDate({ value: onGoingVVP?.nextPaymentDate })}
        periodicity={periodicityLabel}
        planName={onGoingVVP.planName}
      />
    );
  }, [amount, formatDate, onGoingVVP, periodicityLabel]);

  const renderFundSheetModal = useCallback(() => {
    if (!fundSheetModalData?.id || !fundSheetModalData?.title) {
      return null;
    }
    return (
      <FundSheetModal
        id={fundSheetModalData?.id}
        modalIsVisible={displayFundSheetModal}
        onCloseModal={() => {
          setDisplayFundSheetModal(false);
          setFundSheetModalData(null);
        }}
        title={fundSheetModalData?.title}
      />
    );
  }, [
    fundSheetModalData,
    displayFundSheetModal,
    setDisplayFundSheetModal,
    setFundSheetModalData,
    navigation,
  ]);

  const renderExpendableContent = useCallback(() => {
    if (!bankAccountDetail) return;

    const accountInfoData = {
      ...bankAccountDetail,
      domiciliation: bankAccountDetail?.domiciliation || '',
      titulaire: bankAccountDetail?.titulaire || ''
    };

    return (
        <BankAccountInfosCard
            AccountInfoData={accountInfoData}
        />
    )
  }, [bankAccountDetail]);

  const renderOrigin = useCallback(() => {
    if (!amount) {
      return;
    }

    return (
      <FiletStepperCardWrapper
        disableLine={false}
        headerResponseAmount={-amount}
        headerResponseCustomContainerStyle={[
          styles.bankAccountHeader,
        ]}
        headerResponseTitle={formatMessage({ id: 'VV_VVP_detail_MonCompteBancaire_description' })}
        showHeaderResponseDarkText
        title={formatMessage({ id: 'VV_Repartition_De_label' })}
      >
        <FiletStepperAccordionCard
          accordionTitle={
            formatMessage({ id: 'VV_VVP_detail_coordonneesBancaires_description' })
          }
          expendableContent={renderExpendableContent}
          title={formatMessage({ id: 'VV_VVP_detail_prelevement_description' })}
       />
      </FiletStepperCardWrapper>
    );
  }, [
    amount,
    formatMessage,
    styles
  ]);

  const renderDestination = useCallback(() => {
    if (!onGoingVVP) {
      return;
    }

    const destinationHeaderColor = theme.colors.basics.primary
    const cards = managements?.map(management => {
      const supports = management?.supports.filter(support => support.amount);
      const subCards = supports?.map(support => {
        const onPress = () => {
          if (!support.supportIsin || !support.supportName) {
            return;
          }

          setDisplayFundSheetModal(true);
          setFundSheetModalData({
            id: support.supportIsin,
            title: support.supportName,
          });
        };

        return ({
          title: support.supportName || '',
          amount: support.amount?.amount || 0,
          onPress,
        });
      });

      return ({
        title: management.label || '',
        amount: management.totalAmount || 0,
        isFreeManagement: !!management.isFree,
        subCards,
      });
    });

    return (
      <FiletStepperCardWrapper
        borderLeftColor={destinationHeaderColor.c500}
        disableLine={true}
        headerResponseCustomContainerStyle={{
          backgroundColor: destinationHeaderColor.c500,
        }}
        headerResponseTitle={onGoingVVP.planName}
        title={formatMessage({ id: 'VV_VVP_detail_Vers_description' })}
      >
        {cards?.map((card, i) => {
          return (
            <FiletStepperSimpleCard
              amount={card.amount}
              headerColor={destinationHeaderColor?.c100}
              hideManagementAmount={card.isFreeManagement}
              key={i}
              subCards={card.subCards}
              title={card.title}
            />
          );
        })}
      </FiletStepperCardWrapper>
    );
  }, [formatMessage, managements, onGoingVVP, theme]);

  const renderContent = useCallback(() => {
    return (
      <>
        <View style={styles.filetStepperContainer}>
          {renderOrigin()}
          {renderDestination()}
        </View>
        {renderFundSheetModal()}
      </>
    );
  }, [
    styles.filetStepperContainer,
    renderOrigin,
    renderDestination,
    renderFundSheetModal,
  ]);

  if (!onGoingVVP) {
    return null;
  }

  return (
    <VVPDetailsPageTemplate
      renderActionButtons={renderActionButtons}
      renderContent={renderContent}
      renderHeader={renderHeader}
      renderSubHeader={renderSubHeader}
    />
  );
};
