import { useMemo } from 'react'
import { StyleSheet } from 'react-native'

import { useScreenSizes, useTheme } from '@ere-uilib/styles'

export function useStyles() {
  const theme = useTheme()
  const { isMobile } = useScreenSizes()
  const styles = useMemo(
    () =>
      StyleSheet.create({
        loaderContainer: {
          padding: theme.metrics.spacing.xm,
          borderRadius: theme.metrics.borderRadius.xs,
          marginBottom: theme.metrics.spacing.l,
          backgroundColor: theme.colors.basics.white,
          shadowColor: theme.colors.basics.black,
          shadowOffset: {
            width: 0,
            height: 0
          },
          shadowOpacity: 0.15,
          shadowRadius: 10,
          elevation: 7
        },
        loaderFirstChilld: {
          width: theme.metrics.spacing[isMobile ? 'xxbig' : 'xhuge']
        },
        loader: {
          height: theme.metrics.spacing.m,
          marginVertical: theme.metrics.spacing.s
        },
        loadingLoaderContainer: {
          backgroundColor: theme.colors.basics.grey.c100
        }
      }),
    [theme, isMobile]
  )

  return styles
}
