import { HtmlStyledRenderConnectedContainer, ModalFrame, SimpleButton, Title,
  useScreenSizes, useTheme
} from '@components/index';
import { createUseStyles } from '@ere-uilib/styles';

import React, {useEffect} from 'react';
import {Image, View} from 'react-native';
import {useModalData} from './hooks';
import {MaintenancePageProps} from './interface';
import {getStyles} from './styles';



export const MaintenancePageComponent: React.FC<React.PropsWithChildren<MaintenancePageProps>> = ({
  onGetMaintenanceData
}) => {
  const { isMobile } = useScreenSizes();
  const theme = useTheme();
  const styles = useStyles({ theme, isMobile }, {});
  const {  buttonLabel, content, imageUrl, title, onModalClose } =  useModalData();

  useEffect(() => {
    onGetMaintenanceData();
  }, []);

  return (
    <View style={styles.container}>
      <ModalFrame>
        <View style={styles.imageStyle}>
          <Image
            resizeMode="contain"
            source={{
              uri: imageUrl
            }}
            style={styles.svgStyle}
          />
        </View>
        <View style={styles.modalTextContainer}>
          <Title
            style={styles.modalTitle}
            variant="t6">
            {title}
          </Title>
          <HtmlStyledRenderConnectedContainer html={content || ""} />
        </View>
        <View>
          <SimpleButton
            onPress={() => {onModalClose()}}
            title={buttonLabel}
          />
        </View>
      </ModalFrame>
    </View>
  );
};

const useStyles = createUseStyles(getStyles);
