import React, { useRef, useState, useEffect, useCallback } from 'react';
import { View, TextInput } from 'react-native';

import {
  FormValidationCondition,
  TextField,
} from '@ere-uilib/molecules';
import { createUseStyles, useScreenSizes, useTheme } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';

import { PasswordCheckProps } from './interface';
import { getStyles } from './styles';

export const PasswordCheck: React.FC<React.PropsWithChildren<PasswordCheckProps>> = ({
  textFieldContainerStyle,
  containerStyle,
  setDisable,
  setPassword,
  appSettings,
  submitButtonProps,
  placeholderNewPassword,
  placeholderConfirmNewPassword,
  isDisabled,
  clearInputs,
  testIdNewPassword,
  testIdConfirmNewPassword
}) => {
  // @TODO Demander si utiliser un useReducer a la place ?
  const [InputPassword, setInputPassword] = useState('');
  const [confirmationPassword, setConfirmationPassword] = useState('');
  const [hasMinimumLength, setHasMinimumLength] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSpecialCharacter, setHasSpecialCharacter] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [sameAsConfirmation, setSameAsConfirmation] = useState(false);
  const [isSamePassword, setIsSamePassword] = useState(false);

  const { formatMessage } = useTranslation();
  const theme = useTheme();
  const passwordInputRef = useRef<TextInput>();
  const confirmPasswordInputRef = useRef<TextInput>();
  const { isMobile, isTablet } = useScreenSizes();
  const styles = useStyles(
    { isMobile, isTablet, theme },
    {
      containerStyle,
      textFieldContainerStyle,
    }
  );

  const passwordConditions = [
    {
      state: hasUpperCase,
      label: String(formatMessage({ id: 'regex-password_control2_label' })),
    },
    {
      state: hasLowerCase,
      label: String(formatMessage({ id: 'regex-password_control3_label' })),
    },
    {
      state: hasMinimumLength,
      label: String(formatMessage({ id: 'regex-password_control1_label' })),
    },
    {
      state: hasNumber,
      label: String(formatMessage({ id: 'regex-password_control4_label' })),
    },
    {
      state: hasSpecialCharacter,
      label: String(formatMessage({ id: 'regex-password_control5_label' })),
    },
    {
      state: sameAsConfirmation,
      label: String(formatMessage({ id: 'regex-password_control6_label' })),
    },
  ];

  const passwordIconisClicked = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const passwordValidation = (passwordValue: string) => {
    const HAS_LOWERCASE = new RegExp(
      appSettings?.PasswordRegexAtLeastOneLowercase?.toString() || ''
    );
    const HAS_UPPERCASE = new RegExp(
      appSettings?.PasswordRegexAtLeastOneUppercase?.toString() || ''
    );
    const HAS_NUMBER = new RegExp(
      appSettings?.PasswordRegexAtLeastOneNumber?.toString() || ''
    );
    const HAS_SPECIAL_CHAR = new RegExp(
      appSettings?.PasswordRegexAtLeastOneSpecialCharacter?.toString() || ''
    );
    const HAS_8_CHARS_MIN = new RegExp(
      appSettings?.PasswordRegexMinimumNumberOfCharacters?.toString() || ''
    );

    setHasMinimumLength(HAS_8_CHARS_MIN.test(passwordValue));
    setHasUpperCase(HAS_UPPERCASE.test(passwordValue));
    setHasLowerCase(HAS_LOWERCASE.test(passwordValue));
    setHasNumber(HAS_NUMBER.test(passwordValue));
    setHasSpecialCharacter(HAS_SPECIAL_CHAR.test(passwordValue));

  };

  useEffect(() => {

    if (hasLowerCase && hasMinimumLength && hasUpperCase && hasNumber && hasSpecialCharacter && isSamePassword) {
      setDisable && setDisable(false);
    }
  }, [hasMinimumLength, hasUpperCase, hasLowerCase, hasNumber, hasSpecialCharacter, isSamePassword])

  const confirmationCheck = useCallback((passwordConfirmation: string,
    passwordValue: string) => {

    const isSame =
      passwordValue === passwordConfirmation &&
      passwordValue.length > 1 &&
      passwordConfirmation.length > 1;
    setIsSamePassword(isSame);

    const isDisabled = !(
      hasUpperCase &&
      hasLowerCase &&
      hasMinimumLength &&
      hasNumber &&
      hasSpecialCharacter &&
      isSame
    );
    setSameAsConfirmation(isSame);
    setDisable && setDisable(isDisabled);
    if (!isDisabled && setPassword) {
      setPassword(passwordValue);
    }
  }, [hasUpperCase, hasLowerCase, hasMinimumLength, hasNumber, hasSpecialCharacter, setDisable, setPassword]);

  const handlePasswordSubmit = () => {
    if (submitButtonProps && !submitButtonProps.disabled) {
      submitButtonProps.onPress();
    }
  };

  const handleEmailSubmit = () => {
    confirmPasswordInputRef?.current?.focus();
  };

  useEffect(() => {
    if (isDisabled) {
      setInputPassword('');
      setConfirmationPassword('');
      passwordInputRef?.current?.clear();
      confirmPasswordInputRef?.current?.clear();
      setHasUpperCase(false);
      setHasMinimumLength(false);
      setHasLowerCase(false);
      setHasNumber(false);
      setHasSpecialCharacter(false);
      confirmationCheck(confirmationPassword, InputPassword);

    }
  }, [isDisabled]);

  return (
    <View style={styles.containerStyle}>
      <View style={styles.textFieldContainerStyle}>
        <TextField
          containerStyle={styles.input}
          innerRef={passwordInputRef}
          inputProps={{
            placeholder: placeholderNewPassword,
            value: InputPassword,
            onChangeText: passwordValue => {
              setInputPassword(passwordValue);
              passwordValidation(passwordValue);
              confirmationCheck(passwordValue, confirmationPassword);
            },
          }}
          isDisabled={isDisabled}
          isPasswordVisible={isPasswordVisible}
          labelOnTop
          onSubmit={handleEmailSubmit}
          password
          setIsPasswordVisible={passwordIconisClicked}
          testId={testIdNewPassword}
        />
      </View>

      <View
        style={[
          styles.textFieldContainerStyle,
          { marginBottom: theme.metrics.spacing.l },
        ]}
      >
        <TextField
          containerStyle={styles.input}
          innerRef={confirmPasswordInputRef}
          inputProps={{
            placeholder: placeholderConfirmNewPassword,
            value: confirmationPassword,
            onChangeText: passwordValue => {
              setConfirmationPassword && setConfirmationPassword(passwordValue);
              confirmationCheck(InputPassword, passwordValue);
            },
          }}
          isDisabled={isDisabled}
          isPasswordVisible={isPasswordVisible}
          labelOnTop
          onSubmit={handlePasswordSubmit}
          password
          setIsPasswordVisible={passwordIconisClicked}
          testId={testIdConfirmNewPassword}
        />
      </View>

      {passwordConditions.map((condition, index) => {
        return <FormValidationCondition
          condition={condition}
          key={index} />;
      })}
    </View>
  );
};

const useStyles = createUseStyles(getStyles);
