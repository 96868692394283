import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

const DOCUMENT_ICON_SIZE = 25;

export const useStyles = () => {
  const theme = useTheme();

  const closeIconSize = useMemo(() => theme.metrics.iconSizes.m, [theme]);

  const styles = useMemo(
    () =>
      StyleSheet.create({
        cardContainer: {
          width: '100%',
          maxWidth: 355,
          borderWidth: 1,
          borderColor: theme.colors.basics.grey.c200,
          padding: theme.metrics.spacing.s,
          borderRadius: 5,
          flexDirection: 'row',
          alignItems: 'center'
        },
        closeIcon: {
          width: closeIconSize,
          height: closeIconSize,
          borderRadius: closeIconSize * 0.5,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: theme.colors.basics.grey.c400
        },
        documentIconContainer: {
          width: 64,
          alignItems: 'center',
          justifyContent: 'space-between'
        },
        documentIcon: {
          minHeight: DOCUMENT_ICON_SIZE,
          width: DOCUMENT_ICON_SIZE,
          height: DOCUMENT_ICON_SIZE,
        },
        fileNameContainer: {
          flex: 1,
          marginHorizontal: theme.metrics.spacing.m
        }
      }),
    [closeIconSize, theme]
  );

  return styles;
};
