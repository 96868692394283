import {
  BorderRadiusTypes,
  BreakpointsTypes,
  TextsSpacingRatioTypes,
  LineHeightRatioTypes,
  SpacingTypes,
  MetricsTypes,
  IconSizesTypes,
  OCDsizeTypes,
  ContentSizesTypes,
} from './interfaces';

const textsSpacingRatio: TextsSpacingRatioTypes = {
  paragraph: 1,
  title: 1.5,
};

const lineHeightRatio: LineHeightRatioTypes = {
  title: 1.5,
  text: 1.2,
};

const iconSizes: IconSizesTypes = {
  xxxs: 6,
  xxs: 8,
  xs: 10,
  s: 12,
  xxm: 15,
  xm: 18,
  m: 20,
  mplus: 24,
  l: 30,
  xl: 36,
  xxl: 40,
  xxxl: 60,
};

const spacing: SpacingTypes = {
  none: 0,
  xxs: 2.5,
  xs: 5,
  s: 10,
  m: 15,
  xm: 20,
  xxm: 25,
  l: 30,
  xl: 35,
  xxl: 40,
  xxxl: 45,
  xxxxl: 50,
  big: 55,
  xbig: 60,
  xxbig: 70,
  xxxbig: 80,
  huge: 100,
  xhuge: 130,
  xxhuge: 150,
  xxxhuge: 170,
};

const OCDsize: OCDsizeTypes = {
  OCDMobileTop: 175,
  OCDMobileBottom: 317,
  OCDDesktopFullTop: 113,
  OCDDesktopFullBottom: 190,
  OCDDesktopSmallBottom: 317,
  OCDDesktopSmallTop: 167,
};

const contentSizes: ContentSizesTypes = {
  dialOnceButton: 355,
  eerCardChoice: 355,
  commonSvgLargeHeight: 205,
  commonSvgMediumHeight: 140,
  helpPointIcon: 25,
  installmentBigStepperCircle: 26,
  installmentSmallStepperCircle: 18,
  imgPlaceholderSize: 140,
  centeredContentMaxWidth: 709,
  leftSideContentMaxWidth: 400,
  savingsPlaceholderSvgMobile: 150,
  savingsPlaceholderSvgDesktop: 260,
};

export const borderRadius: BorderRadiusTypes = {
  none: 0,
  xs: 5,
  s: 10,
  m: 20,
  l: 40,
  full: 99999,
};

const breakpoints: BreakpointsTypes = {
  tablet: 768,
  desktop: 1280,
};

export const metrics: MetricsTypes = {
  borderRadius,
  breakpoints,
  lineHeightRatio,
  textsSpacingRatio,
  spacing,
  iconSizes,
  OCDsize,
  contentSizes,
};
