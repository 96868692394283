import { WebViewSourceTypeEnum } from '@ere-uilib/atoms'
import { ArbitrationActionsType } from '@modules/arbitration/actions/arbitrationActionsTypes'
import { InstallmentActionsType } from '@modules/installment/actions/installmentActionsTypes'
import { CommonRefundActionsType } from '@modules/refund/types'

export enum InstallmentNavigatorRouteNames {
  Installments = 'Installments',
  InstallmentsRepartition = 'InstallmentsRepartition',
  InstallmentsSynthesis = 'InstallmentsSynthesis',
  InstallmentsPaymentMode = 'InstallmentsPaymentMode',
  InstallmentsRequirementDefiniton = 'InstallmentsRequirementDefiniton',
  InstallmentPlanChoice = 'InstallmentPlanChoice',
  InstallmentPaymentCBHtml = 'InstallmentPaymentCBHtml',
  InstallmentLoading = 'InstallmentLoading',
  InstallmentSuccess = 'InstallmentSuccess',
  InstallmentFailed = 'InstallmentFailed',
  InstallmentModal = 'InstallmentModal'
}

export type InstallmentNavigatorInterface = {
  [InstallmentNavigatorRouteNames.Installments]: undefined
  [InstallmentNavigatorRouteNames.InstallmentsRepartition]: undefined
  [InstallmentNavigatorRouteNames.InstallmentsSynthesis]: undefined
  [InstallmentNavigatorRouteNames.InstallmentsPaymentMode]: undefined
  [InstallmentNavigatorRouteNames.InstallmentsRequirementDefiniton]: undefined
  [InstallmentNavigatorRouteNames.InstallmentPlanChoice]: undefined
  [InstallmentNavigatorRouteNames.InstallmentPaymentCBHtml]: { source?: WebViewSourceTypeEnum }
  [InstallmentNavigatorRouteNames.InstallmentLoading]: { id: string }
  [InstallmentNavigatorRouteNames.InstallmentSuccess]: undefined
  [InstallmentNavigatorRouteNames.InstallmentFailed]: undefined
  [InstallmentNavigatorRouteNames.InstallmentModal]: {
    clearAction:
      | CommonRefundActionsType.CLEAR_REFUND_DATA
      | InstallmentActionsType.CLEAR_INSTALLMENT_DATA
      | ArbitrationActionsType.CLEAR_ARBITRATION_DATA
  }
}
