import { StackNavigationProp } from '@react-navigation/stack';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { createArbitrationRequest } from '@modules/arbitration/actions/arbitrationActions';
import { ArbitrationActionsType } from '@modules/arbitration/actions/arbitrationActionsTypes';
import { getReallocationDivested, getReallocationType } from '@modules/arbitration/selectors';
import { getArbitrationError } from '@modules/arbitration/selectors';
import { AppState } from '@modules/reducers';
import { AppNavigatorInterface } from '@navigation/Interfaces';

import { ArbitrationSynthesisPage } from './ArbitrationSynthesisPage.component';
import { useDeinvestmentSource } from '../ArbitrationReinvestmentPage/hooks/useDeinvestmentSource';

interface Props {
  navigation: StackNavigationProp<AppNavigatorInterface>;
}

export function ArbitrationSynthesisPageContainer({ navigation }: Props) {
  const {
    sourceCompartment,
    isSrcVisibleManagementHeader,
    isSrcVisibleCompartmentTitle,
    amount = 0,
    partialReallocation,
    planData,
  } = useDeinvestmentSource();
  const reallocationDivested = useSelector(getReallocationDivested);
  const arbitrationType = useSelector(getReallocationType)
  const isCreateArbitrationRequestLoading = useSelector(
    (state:AppState) => state.isLoading[ArbitrationActionsType.CREATE_ARBITRATION_REQUEST]
  );
  const createArbitrationRequestErrorMessage = useSelector(getArbitrationError);
  const dispatch = useDispatch();
  const dispatchCreateArbitration = ()=>{dispatch(createArbitrationRequest());};

  const isTargetVisibleManagementHeader =
    ((reallocationDivested?.compartments[0].managements || []).length > 1 &&
      partialReallocation) ||
    !partialReallocation;

  return (
    <ArbitrationSynthesisPage
      amount={amount}
      arbitrationType={arbitrationType}
      createArbitrationRequest={dispatchCreateArbitration}
      createArbitrationRequestErrorMessage={createArbitrationRequestErrorMessage?.message}
      hideDestinationCompartmentHeader={!isSrcVisibleCompartmentTitle}
      hideDestinationManagementRow={!isTargetVisibleManagementHeader}
      hideOriginCompartmentHeader={!isSrcVisibleCompartmentTitle}
      hideOriginManagementRow={!isSrcVisibleManagementHeader}
      isCreateArbitrationRequestLoading={isCreateArbitrationRequestLoading}
      navigation={navigation}
      planName={planData?.isCustomPlan ? planData.planFullName: planData?.shortLabel}
      planType={planData?.planType}
      reallocationDivested={reallocationDivested}
      sourceCompartment={sourceCompartment}
    />
  );
}
