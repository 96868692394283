import React from 'react';

import { FontIcon } from '@ere-uilib/atoms';
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native';
import { createUseStyles } from '@ere-uilib/styles';
import { theme } from '@ere-uilib/theme';

interface SlideButtonProps {
  direction: 'gauche' | 'bas' | 'droite' | 'haut'
  onClick: () => void
}
type Context = {
  theme?: typeof theme;
};
type SlideButtonStyle = {
  containerStyle?: StyleProp<ViewStyle>;
}


export const SlideButton: React.FC<React.PropsWithChildren<SlideButtonProps>> = ({
  direction,
  onClick
}) => {
  const styles = useStyles({ theme }, {})
  return (
    <TouchableOpacity
      style={styles.containerStyle}
      onPress={onClick}
      testID={`navigate_${direction}`}
      accessibilityLabel={`navigate_${direction}`}
    >
      <FontIcon
        color={theme.colors.white}
        name={`chevron-${direction}`}
        size={theme.iconSize.medium}
      />
    </TouchableOpacity>
  );
};

const getStyles = (
  context?: Context,
  style?: SlideButtonStyle,
): SlideButtonStyle => ({
  containerStyle: [{
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    height: 35,
    width: 35,
    borderRadius: 50,
    backgroundColor: context?.theme?.colors.primaryGreen
  }, style?.containerStyle]
})

const useStyles = createUseStyles(getStyles);
// export default SlideButton
