import { useFocusEffect, useNavigation } from '@react-navigation/native'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RouteNames } from '@constants/navigation'
import {
  getDocumentBlobRequestById,
  getEDocumentsRequest
} from '@modules/document-space/actions/documentsActions'
import { DocumentsActionsType } from '@modules/document-space/actions/documentsActionsTypes'
import {
  getEDocuments,
  hasDocumentsError,
  isSubscribedToEDocuments
} from '@modules/document-space/selectors'
import { AppState } from '@modules/reducers'

import { filterDocumentsByYear, getYears } from './utils'

export const useController = () => {
  const navigation = useNavigation()
  const [currentYear, setCurrentYear] = useState<number | undefined>()
  const dispatch = useDispatch()
  const [selectedDocumentId, setSelectedDocumentId] = useState('')

  const isSubscriber = useSelector(isSubscribedToEDocuments)
  const documents = useSelector(getEDocuments)

  const { isLoadingBlob, isLoadingDocuments } = useSelector((state: AppState) => ({
    isLoadingBlob: state.isLoading[DocumentsActionsType.GET_DOCUMENT_BLOB_RESPONSE_REQUEST],
    isLoadingDocuments: state.isLoading[DocumentsActionsType.GET_EDOCUMENTS_REQUEST]
  }))

  const hasError = useSelector(hasDocumentsError)

  const years = useMemo(() => getYears(documents), [documents])

  const hasNeverBeenASubscriber = useMemo(
    () => !isSubscriber && !documents.length,
    [documents.length, isSubscriber]
  )

  const isDownloadingDocument = useCallback(
    (documentId: string) => isLoadingBlob && selectedDocumentId === documentId,
    [isLoadingBlob, selectedDocumentId]
  )

  const fetchDocuments = useCallback(() => dispatch(getEDocumentsRequest()), [dispatch])

  const downloadDocument = useCallback(
    (documentId: string, documentTitle: string) => {
      setSelectedDocumentId(documentId)
      dispatch(getDocumentBlobRequestById(documentId, documentTitle))
    },
    [dispatch]
  )

  const goToSubscription = useCallback(() => {
    navigation.navigate(RouteNames.DocumentSpaceStack, {
      screen: RouteNames.DocumentSpaceEDocumentsSubscription
    })
  }, [navigation])

  useFocusEffect(
    useCallback(() => {
      fetchDocuments()
    }, [fetchDocuments])
  )

  useEffect(() => {
    setCurrentYear(years[0])
  }, [years])

  return {
    documents: filterDocumentsByYear(documents, currentYear),
    currentYear,
    years,
    onFilterDocuments: setCurrentYear,
    downloadDocument,
    goToSubscription,
    isLoadingDocuments,
    isDownloadingDocument,
    isSubscriber,
    hasNeverBeenASubscriber,
    hasError
  }
}
