import React, { useCallback, useMemo } from 'react'
import { View } from 'react-native'

import { Text } from '@ere-uilib/atoms'
import { PlanFamillyList } from '@ere-uilib/constants'
import { ItemState } from '@ere-uilib/molecules/inputs/Select/types'
import { CardResponse, EligibilityList } from '@ere-uilib/organisms'
import { useTheme } from '@ere-uilib/styles'
import { useTranslation } from '@ere-uilib/translations'
import { ArbitrationAnswersTypesEnum } from '@modules/arbitration/constants'
import { OadAllocationsState } from '@modules/arbitration/types'
import {
  OperationDecisionState,
  OptionsState,
  QuestionsState,
  ResponseType
} from '@modules/common/types'
import { EnumRefundUnavailableDecision } from '@modules/refund/constants'
import {
  refundUnavailableDispositifTableDecisionParamsType,
  RefundUnavailabilityDecisionTreeType,
  UnavailableRefundDispositifState
} from '@modules/refund/unavailable-refund/types'
import {
  OadRiskComponent,
  OadStartYesComponent,
  RadioResponseComponent
} from '@pages/Arbitration/ArbitrationPlanChoicePage/components'
import { DispositifTable, SignatureDateContainer, UnavailableRefundUnlockingReasonSelect } from '@pages/Refunds/components'

import { Dictionary } from './interface'
import { useController } from './useController'
import { useStyles } from './useStyles'
import { HtmlStyledRenderConnectedContainer } from '@components/ERE360Components'

interface Props {
  dictionary: Dictionary
  showRefundEligibilityTotalAmount?: boolean
  shouldFormatAsPlusMinus?: boolean
  questionDescription?: string
  response: ResponseType
  hasCustomResponseRender?: boolean
  requirementDecisionData: OperationDecisionState
  respondedQuestion: QuestionsState[]
  unRespondedQuestion?: QuestionsState[]
  questions: QuestionsState[]
  arbitrationOadAllocations?: OadAllocationsState[]
  onUpdateQuestion: (responsePayload: ResponseType, index: number, canNotBeAsked: boolean) => void
  testIdIndex?: string
  onGetUnavailableRefundDispositifTable?: (
    nodeId: string,
    refundUnavailableDispositifTableDecisionParams: refundUnavailableDispositifTableDecisionParamsType
  ) => void
  renderMixDispositifsEligibleForUnavailable?: () => React.ReactNode
  selectedUnavailableOption: string | undefined
  handleRedirectToPersoneo?: (provider: string) => void
  handleDispositifsRcValidated?: () => void
}

export const RequirementDefinitionCardResponse = ({
  response,
  respondedQuestion,
  unRespondedQuestion,
  requirementDecisionData,
  questions,
  questionDescription,
  showRefundEligibilityTotalAmount,
  shouldFormatAsPlusMinus,
  arbitrationOadAllocations,
  onUpdateQuestion,
  dictionary,
  testIdIndex,
  renderMixDispositifsEligibleForUnavailable,
  selectedUnavailableOption,
  handleRedirectToPersoneo,
  handleDispositifsRcValidated
}: Props) => {
  const { formatMessage } = useTranslation()
  const theme = useTheme()
  const styles = useStyles()

  const isSignatureDateQuestion =
    response.type === EnumRefundUnavailableDecision.REFUND_UNAVAILABLE_SIGNATURE_DATE
  const dataSignatureDates = isSignatureDateQuestion
    ? (response?.data as RefundUnavailabilityDecisionTreeType)
    : undefined

  const {
    eligibleDispositifsData,
    isNoEligibleDispositif,
    options,
    formattedElligibleDispositifs,
    handlePressButton,
    handleSelectResponse
  } = useController({
    requirementDecisionData,
    arbitrationOadAllocations,
    respondedQuestion,
    response,
    questions,
    unRespondedQuestion,
    onUpdateQuestion
  })

  const buttonTitle = useMemo(() => {
    return response.validationButtonTitle || formatMessage({ id: 'Saving_Tab1_Filter_Apply_title' })
  }, [response, formatMessage])

  const headerTitle = !unRespondedQuestion?.[0]?.title
    ? formatMessage({ id: response.title })
    : response.title

  const handleNextPressed = useCallback(
    (selectedItem: ItemState) => {
      handleSelectResponse({
        name: selectedItem.label,
        value: selectedItem.value as string,
        labelCode: ''
      })
    },
    [handleSelectResponse]
  )

  const renderAmount = useCallback(
    (amount: number) => (
      <View
        style={{
          margin: theme.metrics.spacing.s
        }}>
        <Text
          variant="t2"
          weight="light">
          {dictionary.totalAmountTitle(amount)}
        </Text>
      </View>
    ),
    [dictionary, theme]
  )

  if (
    !unRespondedQuestion ||
    unRespondedQuestion.length === 0 ||
    unRespondedQuestion[0].canNotBeAsked
  ) {
    return null
  }

  if (response.type === ArbitrationAnswersTypesEnum.OAD_RADIO) {
    return (
      <View style={styles.wrapperContainer}>
        <RadioResponseComponent
          defaultValue={false}
          onChange={handleSelectResponse}
          title={response.title}
        />
      </View>
    )
  }

  if (
    response.type === EnumRefundUnavailableDecision.REFUND_UNAVAILABLE_DECISION_TREE &&
    response?.data
  ) {
    return (
      <UnavailableRefundUnlockingReasonSelect
        buttonTitle={buttonTitle}
        decisionTreeData={response?.data as RefundUnavailabilityDecisionTreeType}
        handleNextPressed={handleNextPressed}
      />
    )
  }

  const getRadioOptionsData = useCallback(
    (data: RefundUnavailabilityDecisionTreeType | undefined) =>
      data?.next?.responses?.map(item => {
        return { value: item.id, label: formatMessage({ id: item.response }), disabled: false }
      }) || [],
    [formatMessage]
  )

  if (response.type === EnumRefundUnavailableDecision.REFUND_UNAVAILABLE_SIGNATURE_DATE) {
    return (
      <SignatureDateContainer
        dataSignatureDates={getRadioOptionsData(dataSignatureDates)}
        buttonTitle={buttonTitle}
        handleNextPressed={handleNextPressed}
      />
    )
  }

  const renderOadRisk = useCallback(() => {
    return (
      <OadRiskComponent
        icon={response.icon}
        progress={response.progress || 0}
        text={response.text}
      />
    )
  }, [response])

  const totalAmountEligibleDispositifs = showRefundEligibilityTotalAmount
    ? eligibleDispositifsData.amount
    : undefined

  const dispositifTotalAmountText = useMemo(() => {
    if (totalAmountEligibleDispositifs === undefined) {
      return
    }

    return dictionary.totalAmountTitle(totalAmountEligibleDispositifs)
  }, [dictionary, totalAmountEligibleDispositifs])

  const renderTotalAmountEligibleDispositifs = useCallback(() => {
    if (!totalAmountEligibleDispositifs || response.type === 'Unavailable') {
      return null
    }

    return (
      <View style={styles.contentWrapper}>
        <Text
          variant="t3"
          weight="light">
          {dispositifTotalAmountText}
        </Text>
      </View>
    )
  }, [dispositifTotalAmountText, totalAmountEligibleDispositifs, styles])

  const handleButtonAction = useCallback(
    (selectedOption?: OptionsState | undefined) => {
      if (
        response.type === 'Unavailable' &&
        !selectedUnavailableOption &&
        renderMixDispositifsEligibleForUnavailable?.()
      )
        return

      if (response.type === ArbitrationAnswersTypesEnum.OAD_RISK)
        return handlePressButton?.(selectedOption)

      if (response.type === 'Unavailable' && selectedUnavailableOption) {
        if (selectedUnavailableOption === PlanFamillyList.ES)
          return handleRedirectToPersoneo?.(selectedUnavailableOption)
        if (selectedUnavailableOption === PlanFamillyList.RC)
          return handleDispositifsRcValidated?.()
      }
      return handleSelectResponse(selectedOption)
    },
    [
      response.type,
      selectedUnavailableOption,
      handleSelectResponse,
      handlePressButton,
      handleDispositifsRcValidated,
      handleRedirectToPersoneo
    ]
  )

  const handleShowButton = () => {
    if (
      response.type === 'Unavailable' &&
      !selectedUnavailableOption &&
      renderMixDispositifsEligibleForUnavailable?.()
    ) {
      return false
    }
    return true
  }

  const renderDispositifs = useCallback(() => {
    if (response.type === 'Unavailable' && renderMixDispositifsEligibleForUnavailable?.())
      return renderMixDispositifsEligibleForUnavailable()

    return (
      unRespondedQuestion[0].showEligibility && (
        <View style={styles.contentWrapper}>
          <EligibilityList
            list={formattedElligibleDispositifs}
            showEligiblity={unRespondedQuestion[0].showEligibility}
          />
        </View>
      )
    )
  }, [
    formatMessage,
    formattedElligibleDispositifs,
    response.type,
    renderMixDispositifsEligibleForUnavailable,
    unRespondedQuestion
  ])

  const renderDispositifTable = useCallback(() => {
    if (
      response.type !== EnumRefundUnavailableDecision.REFUND_UNAVAILABLE_DISPOSITIF_TABLE ||
      !response?.data
    )
      return null

    return <DispositifTable tableData={response.data as UnavailableRefundDispositifState} />
  }, [response])

  const renderChildren = useCallback(() => {
    if (response.type === ArbitrationAnswersTypesEnum.OAD_RISK) {
      return renderOadRisk()
    }
    return (
      <View>
        {!!response.amount &&
          (!isNoEligibleDispositif || !response.disabledMessage) &&
          renderAmount(response.amount)}
        {renderTotalAmountEligibleDispositifs()}
        {renderDispositifs()}
        {response.type === ArbitrationAnswersTypesEnum.OAD_START_YES && (
          <View style={styles.oadStartYesContainer}>
            <OadStartYesComponent />
          </View>
        )}
        {renderDispositifTable()}
      </View>
    )
  }, [
    formattedElligibleDispositifs,
    isNoEligibleDispositif,
    renderAmount,
    renderOadRisk,
    renderTotalAmountEligibleDispositifs,
    response,
    unRespondedQuestion,
    renderDispositifTable,
    styles,
    renderDispositifs
  ])

  const containerStyle = useMemo(
    () => [
      styles.wrapperContainer,
      questionDescription ? styles.wrapperContainer : {}
    ],
    [styles, questionDescription]
  )

  const renderHTML = useMemo(() => {
    if (response.type === ArbitrationAnswersTypesEnum.OAD_RISK) {
      return ''
    }

    return !response.isRenderInnerResponse ? response.text : ''
  }, [response])

  const renderHtmlContent = useCallback(() => {
    if (!renderHTML) return null;

    return <HtmlStyledRenderConnectedContainer
      baseFontStyle={{ fontFamily: theme.fonts.fontFamily.light }}
      html={renderHTML}
    />
  }, [renderHTML]);

  return (
    <CardResponse
      buttonAction={handleButtonAction}
      buttonLarge={response.validationButtonLarge}
      buttonTitle={buttonTitle}
      containerStyle={containerStyle}
      disabledMessage={response.disabledMessage}
      headerAmount={response.headerAmount || undefined}
      headerAmountStyle={response.headerAmount ? styles.headerAmountStyle : undefined}
      headerTitle={headerTitle}
      headerTitleStyle={response.headerAmount ? styles.headerTitleStyle : undefined}
      isButtonDisabled={isNoEligibleDispositif}
      isDisabled={isNoEligibleDispositif}
      isExternalButton={response.type === ArbitrationAnswersTypesEnum.OAD_PROFIL}
      options={options}
      renderHTML={renderHtmlContent}
      shouldFormatAsPlusMinus={shouldFormatAsPlusMinus}
      showActionButton={handleShowButton()}
      testId={testIdIndex}>
      {renderChildren()}
    </CardResponse>
  )
}
