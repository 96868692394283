import { palette } from '@ere-uilib/styles/theme/themeFolder/colors/palette'
import React from 'react'
import { View, Text, StyleSheet } from 'react-native'

interface PercentElementProps {
  value: number
}

export const PercentElement = (value: number) => (
  <View style={styles.percentElementContainer}>
    <Text
      style={
        value > 0
          ? styles.percentElementContainerText
          : [styles.percentElementContainerText, { color: palette.pinkLight_1.c500 }]
      }>
      {value > 0 ? '+ ' : ' '}
      {value} %
    </Text>
  </View>
)

const styles = StyleSheet.create({
  percentElementContainer: {
    backgroundColor: palette.white,
    flex: 1,
    width: '100%',
    minHeight: 50,
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderColor: palette.grey.c200
  },
  percentElementContainerText: {
    color: palette.greenLight_2.c500,
    fontSize: 14
  }
})
