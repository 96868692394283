import { useCallback, useMemo } from "react";

import { useTranslation } from "@ere-uilib/translations";
import { categoryType, RetirementPlanDocumentType, documentType } from "@modules/retirement/types";

import { tempObjtType, recognizedType } from "./interfaces";

export const useController = (RetirementPlanDocument: RetirementPlanDocumentType) => {

  const { formatMessage } = useTranslation();
  const typeOfSend = RetirementPlanDocument.sendType;

  const title = useMemo(() => {
    return formatMessage({ id: `${typeOfSend}_Title` });
  }, [formatMessage, typeOfSend]);

  const subTitle = useMemo(() => {
    return formatMessage({ id: `${typeOfSend}_SubTitle` });
  }, [typeOfSend, formatMessage]);

  const sortOrderBy = <T extends { order: number }>(array: T[]): T[] => {
    return array.sort((a: T, b: T) => a.order - b.order);
  };

  const onRemoveDuplicateDocumentsByCategory = (categories: categoryType[]): recognizedType[] => {
    const categoryByDocuments = categories.map((category: categoryType) => {
      return {
        categoryId: category.code,
        documents: JSON.stringify(category.documentTypes)
      }
    }).flat(1)

    const tempObj = categoryByDocuments.reduce((acc, curr): tempObjtType => {
      if ((curr.documents) in acc) {
        acc[curr.documents].push({
          categoryId: curr.categoryId,
        })
      } else {
        acc[curr.documents] = [{
          categoryId: curr.categoryId,
        }]
      }
      return acc
    }, {} as tempObjtType)

    const reorganized: recognizedType[] = Object.keys(tempObj).map(key => ({
      documents: key,
      categories: tempObj[key]
    }))

    return reorganized
  }

  const filterDocumentTypesByFormat = useCallback((categories: categoryType[]) => {
    const uniqueDocumentTypes: documentType[] = [];

    categories.forEach(category => {
      const documentTypes = category.documentTypes?.filter(
        (documentType: documentType) => documentType.documentFormat === 'Document') ?? [];
      documentTypes.forEach(documentType => {
        const existingIndex = uniqueDocumentTypes.findIndex(
          existingType => existingType.documentType === documentType.documentType);
        if (existingIndex === -1) {
          uniqueDocumentTypes.push(documentType);
        }
      });
    });

    return uniqueDocumentTypes;
  }, []);

  return {
    sortOrderBy,
    title,
    subTitle,
    onRemoveDuplicateDocumentsByCategory,
    filterDocumentTypesByFormat
  }

}