import { put, all, fork, call, select } from '@redux-saga/core/effects';
import { takeLatest } from 'redux-saga/effects';

import * as actionHistory from './actions/historyActions';
import { HistoryActionsType } from './actions/historyActionsTypes';
import { runManager } from '@modules/moduleManager';
import { getUsedCompanyId } from '@modules/dashboard/selectors';
import {
  fetchGetNewsFeed,
} from './services';

export function* getNewsFeed(): any {
  const companyId = yield select(getUsedCompanyId);
  const response = yield call(fetchGetNewsFeed, companyId);
  yield put(actionHistory.getNewsFeedSuccess(response.data));
}

export function* getNewsFeedSaga(): any {
  yield takeLatest(
    HistoryActionsType.GET_NEWS_FEED_REQUEST,
    runManager(getNewsFeed, HistoryActionsType.GET_NEWS_FEED_FAILURE)
  );
}

export function* HistorySagas() {
  yield all([
    fork(getNewsFeedSaga),
  ]);
}
