import React from 'react';
import { View, StyleProp, ViewStyle, TextStyle } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

import {
  useTheme,
  ThemeType,
  Paragraph,
  Title,
  SimpleButton,
  useTranslation,
  useScreenSizes,
} from '@components/index';
import { createUseStyles } from '@ere-uilib/styles';

import { IconEnum } from '@ere-uilib/enums';
import { locator } from '@constants/locator';

interface Props {
  username?: string
  lastConnectionDate?: string
  onLogoutPressed: () => void
  headerStyle?: StyleProp<ViewStyle>;
  headerTitleStyle?: StyleProp<TextStyle>;
  dateStyle?: StyleProp<ViewStyle>;
  buttonDesktop?: StyleProp<ViewStyle>;
  buttonMobile?: StyleProp<ViewStyle>;
}

type ProfileHeaderStyles = {
  headerStyle?: StyleProp<ViewStyle>;
  headerTitleStyle?: StyleProp<TextStyle>;
  dateStyle?: StyleProp<TextStyle>;
  buttonDesktop?: StyleProp<ViewStyle>;
  buttonMobile?: StyleProp<ViewStyle>;
};

type StylesContext = { theme: ThemeType; isMobile: boolean };

const ProfileHeader: React.FC<React.PropsWithChildren<Props>> = ({
  onLogoutPressed,
  headerStyle,
  headerTitleStyle,
  dateStyle,
  username,
  lastConnectionDate,
}) => {
  const theme = useTheme();
  const { isMobile, isTablet } = useScreenSizes();
  const styles = useStyles({ theme, isMobile }, { headerStyle, headerTitleStyle, dateStyle });
  const { formatMessage, formatDate, getLocal } = useTranslation();
  const isLocalAr = getLocal() === 'ar'

  const lastConnectionDateDay = formatDate({
    value: lastConnectionDate,
    options: {
      dateStyle: 'short',
    },
  })

  const lastConnectionDateTime = formatDate({
    value: lastConnectionDate,
    options: {
      hour: "2-digit", minute: "2-digit"
    },
  })

  return (
    <LinearGradient
      angle={274.42}
      angleCenter={{ x: 0.5, y: 0.5 }}
      colors={theme.colors.gradients.headerProfile}
      end={{ x: 1, y: 0 }}
      // locations={[-0.766, 1.2906]}
      start={{ x: 0, y: 0 }}
      style={styles.headerStyle}
      useAngle={true}
    >
      <Title
        testId={locator._profile._username}
        style={styles.headerTitleStyle}
        variant="t4">{username}</Title>
      <Paragraph
        testId={locator._profile._last_connexion}
        style={styles.dateStyle}
        variant="t4">
        {formatMessage({
          id: 'menu_last-connexion',
          values: {
            variable: lastConnectionDateDay
          },
        })}
        {!isLocalAr && ` ${formatMessage({ id: 'Profil_LoginHour_Prefix_label' })} `}
        {!isLocalAr && lastConnectionDateTime}
      </Paragraph>
      <View style={!(isMobile || isTablet) ? styles.buttonDesktop : styles.buttonMobile}>
        <SimpleButton
          containerStyle={{ borderWidth: 0 }}
          design="outlined"
          leftIcon={IconEnum.CONNECT}
          onPress={onLogoutPressed}
          title={formatMessage({ id: 'TermsetFrontProfileMenuDeconnexion' })}
          testId={locator._profile._logout}
        />
      </View>
    </LinearGradient>
  );
};

const getStyles = (
  context?: StylesContext,
  style?: ProfileHeaderStyles,
): ProfileHeaderStyles => ({
  headerStyle: [
    {
      backgroundColor: context?.theme.colors.basics.primary.c500,
      padding: context?.theme.metrics.spacing.l,
      alignItems: 'center',
      justifyContent: 'center',
    },
    style?.headerStyle,
  ],
  headerTitleStyle: [
    {
      color: context?.theme.colors.basics.white,
      fontWeight: 'bold',
      textAlign: 'center',
    },
    style?.headerTitleStyle,
  ],
  dateStyle: [
    {
      flexGrow: 1,
      fontFamily: context?.theme.fonts.fontFamily.light,
      color: context?.theme.colors.basics.white,
      textAlign: 'center',
    },
    style?.dateStyle,
  ],
  buttonDesktop: [
    {
      position: 'absolute',
      right: context?.theme.metrics.spacing.l,
      alignItems: 'center',
    },
    style?.buttonDesktop,
  ],
  buttonMobile: [
    {
      position: 'relative',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: context?.theme.metrics.spacing.xm,
    },
    style?.buttonMobile,
  ],

});

const useStyles = createUseStyles(getStyles);

export default ProfileHeader;
