import React, { useMemo } from "react";
import { StyleSheet, View } from "react-native";

import { Paragraph, SVGLocalLoader, useScreenSizes, useTheme, useTranslation } from "@components/index";

export function OadStartYesComponent() {
  const { formatMessage } = useTranslation();
  const styles = useStyles();

  return (
    <View style={styles.containerStyle}>
      <View style={styles.svgContainer}>
        <SVGLocalLoader
          maxHeight={'100%'}
          maxWidth={'100%'}
          name="robotOad"
        />
      </View>
      <Paragraph
        style={styles.textStyle}
        variant="t3"
        weight="light"
      >
        {formatMessage({ id: 'AbritrageOADbesoinOuiAideDescription' })}
      </Paragraph>

    </View>
  );
}

const useStyles = () => {
  const theme = useTheme();
  const { isDesktop, isTablet } = useScreenSizes();
  const platFormMaxWidth = useMemo(() => {
    if (isTablet) {
      return '6%'
    }
    if (isDesktop) {
      return "10%"
    }
    
    return "15%"
  }, [isDesktop, isTablet])

  const styles = useMemo(() => StyleSheet.create({
    containerStyle: {
      flexDirection: 'row',
      alignItems: 'center' 
    },
    svgContainer:
    {
      flex: 1,
      maxWidth: platFormMaxWidth,
      minHeight: theme.metrics.spacing.xm,
      marginRight: theme.metrics.spacing.s,
      height: theme.metrics.spacing.huge
    },

    textStyle: {
      flex: 1,
      fontStyle: 'italic',

    },

  }), [platFormMaxWidth, theme])
  return styles
}

export default OadStartYesComponent;
