import { useMemo } from "react";
import { StyleSheet } from "react-native";

import { useScreenSizes, useTheme } from "@ere-uilib/styles";

export const useStyles = () => {
  const { isMobile } = useScreenSizes();
  const theme = useTheme()
  const styles = useMemo(() => StyleSheet.create({
    topCardContainerStyle: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      marginHorizontal: theme.metrics.spacing.m,
    },
    topCardInnerContainerStyle: {
      display: 'flex',
      flex: 1
    },
    topContainerStyle: {
      flex: 1,
      flexDirection: isMobile ? 'column-reverse' : 'row',
      justifyContent: 'center',
    },
    bottomContainerStyle: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
      marginBottom: isMobile ? theme.metrics.spacing.l : undefined,
      marginLeft: !isMobile ? theme.metrics.spacing.xl : undefined
    },
    loaderPlaceHolderStyle: {
      width: '100%',
      marginVertical: theme.metrics.spacing.s,
    }

  }), [
    isMobile,
    theme
  ]);

  return styles;
};