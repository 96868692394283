import axios from 'axios';
import { put, all, fork, call, takeLatest, select } from 'redux-saga/effects';

import { languageValuesVersions } from '@constants/index';
import * as actionLabel from '@modules/label/actions/labelActions';
import * as actionLabelTypes from '@modules/label/actions/labelActionsTypes';
import { LabelActionType } from '@modules/label/actions/labelActionsTypes';
import { getLanguage } from '@modules/label/selectors';
import { fetchLabel } from '@modules/label/services';
import * as actionLegal from '@modules/legal/actions/legalActions';
import { runManager } from '@modules/moduleManager';

function* onLoadLabel(): any {
  const response = yield call(fetchLabel);
  yield put(actionLabel.getLabelSuccess(response.data));
  yield put(actionLegal.getMenuFooterRequest());
}

function* onPutLanguage(
  { language: nextLanguage }: actionLabelTypes.PutLanguageSuccessAction
): any {
  const previousLanguage = yield select(getLanguage);
  if (nextLanguage === previousLanguage) return;
  axios.defaults.headers.common['Accept-Language'] = languageValuesVersions[nextLanguage].fetchHeaderParameter;
  yield put(actionLabel.onPutLanguageSuccess(nextLanguage));
}

function* getLabel() {
  yield takeLatest(
    LabelActionType.GET_LABEL,
    runManager(onLoadLabel, LabelActionType.GET_LABEL_FAILURE)
  );
}
function* setLanguage() {
  yield takeLatest(
    LabelActionType.PUT_LANGUAGE_REQUEST,
    runManager(onPutLanguage, LabelActionType.GET_LABEL_FAILURE)
  );
}

export default function* labelSaga() {
  yield all([fork(getLabel)]);
  yield all([fork(setLanguage)]);
}
