import React, { useCallback } from 'react';

import {
  IdentifierForm,
} from '@components/ERE360Components/IdentifierForm';
import {
  TextField,
} from '@ere-uilib/index';

import { TextIdentifierFormProps } from './types';

export function TextIdentifierForm({
  onPressSubmit,
  dictionary,
  isLoading,
  errorMessage,
  onCleanField,
  onChangeIdentifierValue,
  onChangeDateOfBirth,
  identifier,
  dateOfBirth,
  dateOfBirthVisible,
  inputProps,
  regexPattern,
  containerStyle
}: TextIdentifierFormProps) {
  const submitButtonActive = !!identifier && !!dateOfBirth;

  const renderIdentifierInput = useCallback(() => {
    return (
      <TextField
        borderOnValue
        inputProps={{
          value: identifier,
          keyboardType: 'numeric',
          placeholder: dictionary.identifierInputPlaceholder,
          onChangeText: onChangeIdentifierValue,
          ...inputProps,
        }}
        labelOnTop
        onCleanField={onCleanField}
        regexPattern={regexPattern}
        testId={dictionary.identifierInputPlaceholder}
      />
    );
  }, [
    identifier,
    dictionary,
    onChangeIdentifierValue,
    inputProps,
    onCleanField,
    regexPattern,
  ]);

  const handlePressSubmit = useCallback(() => {
    if (!dateOfBirth) {
      return;
    }

    onPressSubmit();
  }, [dateOfBirth, onPressSubmit]);

  return (
    <IdentifierForm
      containerStyle={containerStyle}
      dateOfBirth={dateOfBirth}
      dateOfBirthVisible={dateOfBirthVisible}
      dictionary={dictionary}
      errorMessage={errorMessage}
      isLoading={isLoading}
      onChangeDateOfBirth={onChangeDateOfBirth}
      onPressSubmit={handlePressSubmit}
      renderIdentifierInput={renderIdentifierInput}
      submitButtonActive={submitButtonActive}
    />
  );
}
