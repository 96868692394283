import React from 'react';

import { InformativePopin } from '@ere-uilib/organisms/overlay/InformativePopin';
import { useTranslation } from '@ere-uilib/translations';

interface Props {
  error?: string
  isVisible: boolean
  isButtonLoading: boolean
  onClose(): void
  onValid(): void
}

export const VVPEditInformativePopIn: React.FC<React.PropsWithChildren<Props>> = ({
  error,
  isVisible,
  isButtonLoading,
  onClose,
  onValid,
} ) => {
  const { formatMessage } = useTranslation();
  const formattedLabels = {
    buttonTitle: formatMessage({ id: 'VV_VVP_ModaleModif_confirm_bouton' }),
    description: formatMessage({ id: 'VV_VVP_ModaleModif_description' }),
    title: formatMessage({ id: 'VV_VVP_ModaleModif_titre' }),
  };

  const onSubmit = () => {
    onValid();
  };

  return  (
    <InformativePopin
      buttonTitle={formattedLabels.buttonTitle}
      description={formattedLabels.description}
      error={error}
      iconName="idea"
      isLoading={isButtonLoading}
      isModalVisible={isVisible}
      onClose={onClose}
      onValidate={onSubmit}
      title={formattedLabels.title}
    />
  );
};

