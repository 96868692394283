import { DisponibilityTermPageContext, DisponibilityTermPageStyle } from './interfaces';

export const getStyles = (
  context?: DisponibilityTermPageContext,
  style?: DisponibilityTermPageStyle,
): DisponibilityTermPageStyle => ({
  containerStyle: [
    {
      flex: 1,
      backgroundColor: context?.theme.colors.basics.white,
      paddingTop: context?.theme.metrics.spacing.xm,
      paddingLeft: context?.theme.metrics.spacing.xxxhuge,
      paddingRight: context?.theme.metrics.spacing.xxxhuge,
    },
    (context?.isMobile || context?.isTablet) && {
      paddingLeft: context?.theme.metrics.spacing.xm,
      paddingRight: context?.theme.metrics.spacing.xm,
    },
    style?.containerStyle,
  ],
  contentContainerStyle: [
    {
      paddingTop: context?.theme.metrics.spacing.xm,
    },
    style?.contentContainerStyle,
  ],
  choiceLabelStyle: [
    {
      marginBottom: context?.theme.metrics.spacing.m,
    },
    !(context?.isMobile || context?.isTablet) && {
      alignSelf: 'center',
    },
    style?.choiceLabelStyle,
  ],
  afterChoiceLabelStyle: [
    {
      marginBottom: context?.theme.metrics.spacing.m,
    },
    style?.choiceLabelStyle,
  ],
  activableButtonContainerStyle: [
    {
      flexDirection: 'row',
    },
    style?.activableButtonContainerStyle,
  ],
  ActivableButtonStyle: [
    {
      marginHorizontal: context?.theme.metrics.spacing.s,
    },
    style?.ActivableButtonStyle,
  ],
});
