import { useTheme } from "@ere-uilib/styles";
import { useMemo } from "react";
import { StyleSheet } from "react-native";

export function useStyle() {
  const theme = useTheme();
  const styles = useMemo(() => StyleSheet.create({
    container: {
      flexDirection: 'row',
    },
    day: {
      width: 80,
      height: 50,
      textAlign: 'center',
      paddingLeft: 0,
    },
    dayWrapper: {
      width: 80,
      height: 50,
    },
    monthWrapper: {
      width: 80,
      height: 50,
      marginLeft: theme.metrics.spacing.s,
    },
    month: {
      width: 80,
      height: 50,
      textAlign: 'center',
      paddingLeft: 0,
    },
    yearWrapper: {
      width: 105,
      height: 50,
      marginLeft: theme.metrics.spacing.s,
    },
    year: {
      width: 105,
      height: 50,
      textAlign: 'center',
      paddingLeft: 0,
    },
    error: {
      marginTop: theme.metrics.spacing.m,
      flexDirection: 'row',
    },
    errorMessage: {
      color: theme.colors.operationState.failed.c500,
      alignSelf: 'center',
      marginLeft: theme.metrics.spacing.s,
    },
  }), [])

  return styles;
}
