import * as actions from './validateIdentityActionsTypes';
import { ValidateIdentityActionsType } from './validateIdentityActionsTypes';
import {
  FranceConnectRegistrationBody,
  IdentityValidationErrorState,
} from '../types';

// Send EER identity validation code

export function sendEERIdentityValidationCodeRequest(params?:{
  isResend?:boolean
}): actions.SendEERIdentityValidationCodeRequestAction {
  return {
    type: ValidateIdentityActionsType.SEND_EER_IDENTITY_VALIDATION_CODE_REQUEST,
    isResend: params?.isResend,
  };
}

export function sendEERIdentityValidationCodeSuccess()
: actions.SendEERIdentityValidationCodeSuccessAction {
  return {
    type: ValidateIdentityActionsType.SEND_EER_IDENTITY_VALIDATION_CODE_SUCCESS,
  };
}

export function sendEERIdentityValidationCodeFailure(
  error: IdentityValidationErrorState,
): actions.SendEERIdentityValidationCodeFailureAction {
  return {
    type: ValidateIdentityActionsType.SEND_EER_IDENTITY_VALIDATION_CODE_FAILURE,
    error,
  };
}

// Check EER identity validation code

export function checkEERIdentityValidationCodeRequest(
  code: string
): actions.CheckEERIdentityValidationCodeRequestAction {
  return {
    type: ValidateIdentityActionsType.CHECK_EER_IDENTITY_VALIDATION_CODE_REQUEST,
    OTPCode: code,
  };
}

export function checkEERIdentityValidationCodeSuccess()
: actions.CheckEERIdentityValidationCodeSuccessAction {
  return {
    type: ValidateIdentityActionsType.CHECK_EER_IDENTITY_VALIDATION_CODE_SUCCESS,
  };
}

export function checkEERIdentityValidationCodeFailure(
  error: IdentityValidationErrorState,
): actions.CheckEERIdentityValidationCodeFailureAction {
  return {
    type: ValidateIdentityActionsType.CHECK_EER_IDENTITY_VALIDATION_CODE_FAILURE,
    error,
  };
}

// Check EER second letter validation identity code

export function checkEERSecondLetterIdentityValidationCodeRequest(
  code: string
): actions.CheckEERSecondLetterIdentityValidationCodeRequestAction {
  return {
    type: ValidateIdentityActionsType.CHECK_EER_SECOND_LETTER_IDENTITY_VALIDATION_CODE_REQUEST,
    OTPCode: code,
  };
}

export function checkEERSecondLetterIdentityValidationCodeSuccess()
: actions.CheckEERSecondLetterIdentityValidationCodeSuccessAction {
  return {
    type: ValidateIdentityActionsType.CHECK_EER_SECOND_LETTER_IDENTITY_VALIDATION_CODE_SUCCESS,
  };
}

export function checkEERSecondLetterIdentityValidationCodeFailure(
  error: IdentityValidationErrorState,
): actions.CheckEERSecondLetterIdentityValidationCodeFailureAction {
  return {
    type: ValidateIdentityActionsType.CHECK_EER_SECOND_LETTER_IDENTITY_VALIDATION_CODE_FAILURE,
    error,
  };
}

// Validate new Email identifier

export function validateEEREmailNewIdentifierRequest(
  email: string
): actions.ValidateEEREmailNewIdentifierRequestAction {
  return {
    type: ValidateIdentityActionsType.VALIDATE_EER_EMAIL_NEW_IDENTIFIER_REQUEST,
    email,
  };
}

export function validateEEREmailNewIdentifierSuccess(): actions.ValidateEEREmailNewIdentifierSuccessAction {
  return {
    type: ValidateIdentityActionsType.VALIDATE_EER_EMAIL_NEW_IDENTIFIER_SUCCESS,
  };
}

export function validateEEREmailNewIdentifierFailure(
  error: IdentityValidationErrorState,
): actions.ValidateEEREmailNewIdentifierFailureAction {
  return {
    type: ValidateIdentityActionsType.VALIDATE_EER_EMAIL_NEW_IDENTIFIER_FAILURE,
    error,
  };
}

// Validate new Email identifier code

export function validateEEREmailNewIdentifierCodeRequest(
  emailCode: string,
): actions.ValidateEEREmailNewIdentifierCodeRequestAction {
  return {
    type: ValidateIdentityActionsType.VALIDATE_EER_EMAIL_NEW_IDENTIFIER_CODE_REQUEST,
    emailCode,
  };
}

export function validateEEREmailNewIdentifierCodeSuccess()
: actions.ValidateEEREmailNewIdentifierCodeSuccessAction {
  return {
    type: ValidateIdentityActionsType.VALIDATE_EER_EMAIL_NEW_IDENTIFIER_CODE_SUCCESS,
  };
}

export function validateEEREmailNewIdentifierCodeFailure(
  error: IdentityValidationErrorState,
): actions.ValidateEEREmailNewIdentifierCodeFailureAction {
  return {
    type: ValidateIdentityActionsType.VALIDATE_EER_EMAIL_NEW_IDENTIFIER_CODE_FAILURE,
    error,
  };
}

// Validate new Phone identifier

export function validateEERPhoneNewIdentifierRequest(
  phone: string
): actions.ValidateEERPhoneNewIdentifierRequestAction {
  return {
    type: ValidateIdentityActionsType.VALIDATE_EER_PHONE_NEW_IDENTIFIER_REQUEST,
    phone,
  };
}

export function validateEERPhoneNewIdentifierSuccess()
: actions.ValidateEERPhoneNewIdentifierSuccessAction {
  return {
    type: ValidateIdentityActionsType.VALIDATE_EER_PHONE_NEW_IDENTIFIER_SUCCESS,
  };
}

export function validateEERPhoneNewIdentifierFailure(
  error: IdentityValidationErrorState,
): actions.ValidateEERPhoneNewIdentifierFailureAction {
  return {
    type: ValidateIdentityActionsType.VALIDATE_EER_PHONE_NEW_IDENTIFIER_FAILURE,
    error,
  };
}

// Validate new Phone identifier code

export function validateEERPhoneNewIdentifierCodeRequest(
  phoneCode: string,
): actions.ValidateEERPhoneNewIdentifierCodeRequestAction {
  return {
    type: ValidateIdentityActionsType.VALIDATE_EER_PHONE_NEW_IDENTIFIER_CODE_REQUEST,
    phoneCode,
  };
}

export function validateEERPhoneNewIdentifierCodeSuccess()
: actions.ValidateEERPhoneNewIdentifierCodeSuccessAction {
  return {
    type: ValidateIdentityActionsType.VALIDATE_EER_PHONE_NEW_IDENTIFIER_CODE_SUCCESS,
  };
}

export function validateEERPhoneNewIdentifierCodeFailure(
  error: IdentityValidationErrorState,
): actions.ValidateEERPhoneNewIdentifierCodeFailureAction {
  return {
    type: ValidateIdentityActionsType.VALIDATE_EER_PHONE_NEW_IDENTIFIER_CODE_FAILURE,
    error,
  };
}

// CONFIRM REGISTRATION REQUEST

export function RegistrationRequestConfirmRequest(
  password: string
): actions.ConfirmRegistrationRequestAction {
  return {
    type: ValidateIdentityActionsType.CONFIRIM_REGISTRATION_REQUEST,
    password,
  };
}

export function RegistrationRequestConfirmSuccess(
  data: any,
): actions.ConfirmRegistrationSuccessAction {
  return {
    type: ValidateIdentityActionsType.CONFIRIM_REGISTRATION_SUCCESS,
    data,
  };
}

export function RegistrationRequestConfirmFailure(
  error: IdentityValidationErrorState,
): actions.ConfirmRegistrationFailureAction {
  return {
    type: ValidateIdentityActionsType.CONFIRIM_REGISTRATION_FAILURE,
    error,
  };
}

// INIT ERROR

export function InitError(): actions.InitErrorAction {
  return {
    type: ValidateIdentityActionsType.INIT_ERROR,
  };
}

export function SetEERNewEmailInput(email: string): actions.SetEERNewEmailInputAction {
  return {
    type: ValidateIdentityActionsType.SET_EER_NEW_EMAIL_INPUT,
    email,
  };
}

export function SetEERNewPhoneInput(phone: string): actions.SetEERNewPhoneInputAction {
  return {
    type: ValidateIdentityActionsType.SET_EER_NEW_PHONE_INPUT,
    phone,
  };
}

// **** FRANCE CONNECT ****

export function FranceConnectRegistrationRequest(
): actions.FranceConnectCreateIdentityValidationRequestAction {
  return {
    type: ValidateIdentityActionsType.FRANCE_CONNECT_CREATE_IDENTIY_VALIDATION_REQUEST,
  };
}

export function FranceConnectRegistrationSuccess(
  data: FranceConnectRegistrationBody,
): actions.FranceConnectCreateIdentityValidationSuccessAction {
  return {
    type: ValidateIdentityActionsType.FRANCE_CONNECT_CREATE_IDENTIY_VALIDATION_SUCCESS,
    data,
  };
}

export function FranceConnectRegistrationFailure(
  error: IdentityValidationErrorState,
): actions.FranceConnectCreateIdentityValidationFailureAction {
  return {
    type: ValidateIdentityActionsType.FRANCE_CONNECT_CREATE_IDENTIY_VALIDATION_FAILURE,
    error,
  };
}

/***  FRANCE CONNECT CONFIRM IDENTITY ***/

export function FranceConnectConfirmIdentityRequest(
  code: string
) : actions.FranceConnectConfirmIdentityRequestAction {
  return {
    type: ValidateIdentityActionsType.FRANCE_CONNECT_CONFIRM_IDENTITY_REQUEST,
    code,
  };
}

export function FranceConnectConfirmIdentitySuccess(): actions.FranceConnectConfirmIdentitySuccessAction {
  return {
    type: ValidateIdentityActionsType.FRANCE_CONNECT_CONFIRM_IDENTITY_SUCCESS,
  };
}

export function FranceConnectConfirmIdentityFailure(
  error: IdentityValidationErrorState
): actions.FranceConnectConfirmIdentityFailureAction {
  return {
    type: ValidateIdentityActionsType.FRANCE_CONNECT_CONFIRM_IDENTITY_FAILURE,
    error,
  };
}
