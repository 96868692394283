import axios from 'axios';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TranslateProvider } from '@components/index';
import { languageValuesVersions } from '@constants/index';
import { onGetLabel } from '@modules/label/actions/labelActions';
import { getLabels, getLanguage } from '@modules/label/selectors';
import { getDeviceType } from '@modules/utils/getDeviceType/deviceType';

axios.defaults.headers.common['X-DEVICE-TYPE'] = getDeviceType();

export const AppLanguageHead: React.FC<React.PropsWithChildren> = ({
    children,
}) => {
    const dispatch = useDispatch();
    const getLabel = useCallback(() => dispatch(onGetLabel()), [dispatch]);
    const labels = useSelector(getLabels);
    const language = useSelector(getLanguage);

    useEffect(() => {
        axios.defaults.headers.common['Accept-Language'] = languageValuesVersions[language].fetchHeaderParameter;
        getLabel();
    }, [getLabel, language]);

    return (
        <>
            <TranslateProvider
                labels={labels}
                language={languageValuesVersions?.[language]?.translateModuleParameter}
            >
                {children}
            </TranslateProvider>
        </>
    );
};

