import { connect } from 'react-redux';

import { ForgetPasswordActionsType } from '@modules/forget-password/actions/forgetPasswordActionsTypes';
import { AppState } from '@modules/index';
import { ForgetPasswordConfirmRequest } from '@modules/forget-password/actions/forgetPasswordActions';
import { getForgetPasswordError } from '@modules/forget-password/selectors';
import { getApplicationSettings } from '@modules/settings/selectors';

import { ForgetPasswordChoosePassword } from './ForgetPasswordChoosePasswordPage.component';

const mapStateToProps = (state: AppState) => {
  return {
    forgetPasswordError: getForgetPasswordError(state),
    isApiLoading: state.isLoading[ForgetPasswordActionsType.FORGET_PASSWORD_CONFIRM_REQUEST],
    applicationSettings: getApplicationSettings(state)
  };
};

const mapDispatchToProps = ({
  onForgetPasswordRegisterConfirmation: ForgetPasswordConfirmRequest
});
export const ForgetPasswordChoosePasswordPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ForgetPasswordChoosePassword);
