import { connect } from 'react-redux';

import { getDecodedUserTokenId, getUserInfo } from '@modules/auth/selectors';
import { AppState } from '@modules/index';

import { DesktopMenuUserInformationsConnectedComponent } from './DesktopMenuUserInformationsConnected.component';

const mapStateToProps = (state: AppState) => {
  return {
    decodedToken: getDecodedUserTokenId(state),
    userInfo: getUserInfo(state),
  };
};

const mapDispatchToProps = {

};

export const DesktopMenuUserInformationsConnected = connect(mapStateToProps, mapDispatchToProps)(DesktopMenuUserInformationsConnectedComponent);
