import { DispositifState } from "@modules/common/types"

export const camputePlansEligibility = (dispositifs: DispositifState[]) => {
  return {
    stockDispositifs: dispositifs && dispositifs
      .filter(dispositif => dispositif.filterProperties.StockReallocation)
      .map(dispositif => dispositif) || [],
    flowDispositifs: dispositifs && dispositifs
      .filter(dispositif => dispositif.filterProperties.FlowReallocation)
      .map(dispositif => dispositif) || [],
    oadDispositifs: dispositifs && dispositifs
      .filter(dispositif => dispositif.filterProperties.OadReallocation)
      .map(dispositif => dispositif) || []
  }
}