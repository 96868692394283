import { connect } from 'react-redux';

import { CheckInstallmentPaymentStatusForIdRequest } from '@modules/installment/actions/installmentActions';
import { InstallmentActionsType } from '@modules/installment/actions/installmentActionsTypes';
import { AppState } from '@modules/index';
import { getInstallmentError } from '@modules/installment/selectors';

import { InstallmentLoadingPageComponent } from './InstallmentLoadingPage.component';

const mapStateToProps = (state: AppState) => {
  return {
    isCheckInstallmentPaymentStatusForIdApiLoading: state.isLoading[
      InstallmentActionsType.CHECK_INSTALLMENT_PAYMENT_STATUS_FOR_ID_REQUEST
    ],
    installmentError: getInstallmentError(state),
  };
};

const mapDispatchToProps = ({
  onCheckInstallmentPaymentStatusForId: CheckInstallmentPaymentStatusForIdRequest,
});

export const InstallmentLoadingPage = connect(mapStateToProps, mapDispatchToProps)(InstallmentLoadingPageComponent);
