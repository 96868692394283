import { AppState } from '@modules/reducers';

export const getInvitationSelector = (state: AppState) => state.invitation.data;

export const getInvitationUserId = (state: AppState) => state.invitation?.data?.user?.id;

export const getInvitationSelfcareEmail = (state: AppState) => state.invitation?.selfcareEmail;

export const getInvitationError = (state: AppState) => state.invitation?.error;

