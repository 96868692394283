import { useCallback, useMemo } from 'react'

import { AllocationsEnum, RepartitionEnum } from '@constants/arbitrations'
import { useTheme } from '@ere-uilib/styles'
import { useTranslation } from '@ere-uilib/translations'
import { SupportType } from '@modules/common/types'
import {
  RepartitionPercentValues,
  RepartitionTotal
} from '@components/templates/RequirementDefinition/interface'
import { UseController } from './interfaces'
import { PieChartData } from '@ere-uilib/types'
import {ArbitrationOadRepartitionData} from "@modules/arbitration/types";

export const useController = ({
  requirementDecisionData,
  arbitrationOadAllocations
}: UseController) => {
  const theme = useTheme();
  const { formatMessage } = useTranslation();
  const getAllocationAndRepartitionColor = useCallback(
    type => {
      switch (type) {
        default:
        case AllocationsEnum.Monetary:
        case RepartitionEnum.monetaryPercentage:
          return theme.colors.oadChart.monetary
        case AllocationsEnum.Obligatory:
        case RepartitionEnum.obligatoryPercentage:
          return theme.colors.oadChart.obligatory
        case AllocationsEnum.Diversified:
        case RepartitionEnum.diversifiedPercentage:
          return theme.colors.oadChart.diversified
        case AllocationsEnum.Action:
        case RepartitionEnum.actionPercentage:
          return theme.colors.oadChart.action
      }
    },
    [theme]
  )

  const { repartition: repartitionTotalValues, total } = useMemo(() => {
    const supports = requirementDecisionData.selectedCompartment?.managements?.reduce(
      (accumulator, management) => {
        if (!management.supports) {
          return accumulator
        }

        return accumulator.concat(management.supports)
      },
      [] as SupportType[]
    )

    if (!supports) {
      return {
        repartition: [],
        total: 0
      }
    }

    const repartition = supports.reduce(
      (accumulator, support) => {
        if (!support.advancedProperties.CategorieClassification) {
          return accumulator
        }

        const currentIndex = accumulator.repartition.findIndex(
          item => item.title === support.advancedProperties.CategorieClassification
        )
        accumulator.total += support.amount.amount

        if (currentIndex >= 0) {
          accumulator.repartition[currentIndex].total += support.amount.amount
          accumulator.repartition[currentIndex].supports.push(support)
        } else {
          accumulator.repartition.push({
            title: support.advancedProperties.CategorieClassification || '',
            total: support.amount.amount,
            color: getAllocationAndRepartitionColor(
              support.advancedProperties.CategorieClassification
            ),
            key: '',
            supports: [support]
          })
        }

        return accumulator
      },
      {
        total: 0,
        repartition: []
      } as {
        total: number
        repartition: RepartitionTotal[]
      }
    )

    return repartition
  }, [getAllocationAndRepartitionColor, requirementDecisionData.selectedCompartment])

  const formatPercentageSupportsData = useCallback(
    (supports: SupportType[]) => {
      const percentageSupports = supports.map(support => {
        const percentageValue = support.amount.amount / total

        return {
          percentageValue,
          data: support
        }
      })

      return percentageSupports
    },
    [total]
  )

  const repartitionPercentValues = useMemo(
    () =>
      repartitionTotalValues.reduce((accumulator, repartition) => {
        const supportsData = formatPercentageSupportsData(repartition.supports)
        let roundedValue = 0

        supportsData.forEach(support => {
          roundedValue += Math.round(100 * support.percentageValue) / 100
        })

        accumulator[repartition.title] = {
          title: repartition.title,
          value: roundedValue,
          key: repartition.key,
          color: repartition.color,
          supports: supportsData
        }
        return accumulator;
      }, {} as RepartitionPercentValues),
    [repartitionTotalValues, formatPercentageSupportsData]
  )

  const repartitionLegend = useMemo(
    () => [
      {
        color: theme.colors.oadChart.monetary,
        title: formatMessage({ id: 'AbritrageOADPageSyntheseRegroupementMonetaire' })
      },
      {
        color: theme.colors.oadChart.obligatory,
        title: formatMessage({ id: 'AbritrageOADPageSyntheseRegroupementObligataire' })
      },
      {
        color: theme.colors.oadChart.diversified,
        title: formatMessage({ id: 'AbritrageOADPageSyntheseRegroupementDiversifié' })
      },
      {
        color: theme.colors.oadChart.action,
        title: formatMessage({ id: 'AbritrageOADPageSyntheseRegroupementActions' })
      }
    ],
    [formatMessage, theme]
  )

  const orderedRepartitionPercentValues = useMemo(() => {
    const orderedRepartition: ArbitrationOadRepartitionData[] = [];

    if (repartitionPercentValues[RepartitionEnum.monetaryPercentage]) {
      orderedRepartition.push({
        ...repartitionPercentValues[RepartitionEnum.monetaryPercentage],
        key: AllocationsEnum.Monetary
      })
    }

    if (repartitionPercentValues[RepartitionEnum.obligatoryPercentage]) {
      orderedRepartition.push({
        ...repartitionPercentValues[RepartitionEnum.obligatoryPercentage],
        key: AllocationsEnum.Obligatory
      })
    }

    if (repartitionPercentValues[RepartitionEnum.diversifiedPercentage]) {
      orderedRepartition.push({
        ...repartitionPercentValues[RepartitionEnum.diversifiedPercentage],
        key: AllocationsEnum.Diversified
      })
    }

    if (repartitionPercentValues[RepartitionEnum.actionPercentage]) {
      orderedRepartition.push({
        ...repartitionPercentValues[RepartitionEnum.actionPercentage],
        key: AllocationsEnum.Action
      })
    }

    return orderedRepartition
  }, [repartitionPercentValues])

  const simulationPercentValues = useMemo(
    () =>
      arbitrationOadAllocations?.map(allocation => {
        let title = ''

        switch (allocation.key) {
          case RepartitionEnum.monetaryPercentage:
            title = RepartitionEnum.monetaryPercentage
            break
          case RepartitionEnum.obligatoryPercentage:
            title = RepartitionEnum.obligatoryPercentage
            break
          case RepartitionEnum.diversifiedPercentage:
            title = RepartitionEnum.obligatoryPercentage
            break
          case RepartitionEnum.actionPercentage:
            title = RepartitionEnum.obligatoryPercentage
            break
        }
        return {
          ...allocation,
          color: getAllocationAndRepartitionColor(allocation.key),
          title
        }
      }),
    [arbitrationOadAllocations, getAllocationAndRepartitionColor]
  )

  return {
    repartitionData: orderedRepartitionPercentValues,
    simulationData: simulationPercentValues,
    repartitionLegend,
    getAllocationAndRepartitionColor
  }
}
