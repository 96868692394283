import { useMemo } from "react";
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

export const useStyles = ({
  style,
  STEP_INDICATOR_HEIGHT
}: {
  style?: StyleProp<ViewStyle>
  STEP_INDICATOR_HEIGHT: number
}) => {
  const theme = useTheme();

  const styles = useMemo(() => StyleSheet.create({
    stepContainer: {
      flexDirection: 'row',
      ...StyleSheet.flatten(style)
    },
    stepIndicator: {
      flexDirection: 'row',
      position: 'relative',
      justifyContent: 'center',
      alignItems: 'center',
      width: 50,
      height: STEP_INDICATOR_HEIGHT,
      borderTopLeftRadius: 32,
      borderBottomLeftRadius: 32,
    },
    card: {
      backgroundColor: theme.colors.basics.white,
      borderRadius: theme.metrics.borderRadius.xs,
      flexGrow: 1,
      flexShrink: 1,
      shadowColor: theme.colors.basics.black,
      shadowOpacity: 0.2,
      shadowRadius: 5,
      overflow: 'hidden',
    },
    cardDisabled: {
      backgroundColor: theme.colors.basics.grey.c200,
      opacity: 0.5,
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
    mainContent: {
      paddingVertical: theme.metrics.spacing.m,
      paddingHorizontal: theme.metrics.spacing.s,
      backgroundColor: theme.colors.basics.white,
    },
    title: {
      marginBottom: theme.metrics.spacing.xs,
    },
    titleAlone: {
      marginBottom: theme.metrics.spacing.none,
    },
  }), [STEP_INDICATOR_HEIGHT, style, theme])

  return styles;
}

