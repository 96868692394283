export enum PerformanceTypeEnum {
  CUMULATED = 'CUMULATED',
  CALENDAR = 'CALENDAR',
  ANNUALIZED = 'ANNUALIZED'
}

export enum PerformanceCumulatedTypesEnum {
  "1Y" = "1Y",
  "3Y" = "3Y",
  "5Y" = "5Y",
  YTD = "YTD",
}

export enum PerformanceCalendarTypesEnum {
  CAL1Y = 'CAL1Y',
  CAL2Y = 'CAL2Y',
  CAL3Y = 'CAL3Y',
  CAL4Y = 'CAL4Y',
  CAL5Y = 'CAL5Y',
  CAL6Y = 'CAL6Y',
  CAL7Y = 'CAL7Y',
  CAL8Y = 'CAL8Y',
  CAL9Y = 'CAL9Y',
  CAL10Y = 'CAL10Y',
}

export enum PerformanceAnnualizedTypeEnum {
  AN1Y = 'AN1Y',
  AN3Y = 'AN3Y',
  AN5Y = 'AN5Y',
  AN10Y = 'AN10Y',
  ANSI = "ANSI"
}