import { MenuFooterState } from './types';

export const dispatchMenuFooterIconName = (links: MenuFooterState[]) =>
  links.map(link => {
    const urlToIconName: { [p: string]: string } = {
      CGU: 'cgu',
      MentionsLegales: 'mentions-legales',
      CookiePolicy: 'cookies'
    };
    return { ...link, iconName: urlToIconName[link.url] };
  });
