import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useScreenSizes, useTheme } from '@ere-uilib/styles';

export function useStyles() {
  const theme = useTheme();
  const { isDesktop } = useScreenSizes();

  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          flex: 1,
          backgroundColor: theme.colors.basics.white
        },
        buttonContainer : {
          flex:1
        },
        bottomActions: {
          width: '100%'
        },
        bottomActionsButtonsContainer: {
          width: '100%',
          flexDirection: 'row'
        },
        simpleButton: {
          marginLeft: theme.metrics.spacing.m,
        },
        simpleButtonFlex: {
          flex: 1
        },
        headerDescriptionBlock: {
          marginTop: theme.metrics.spacing.s,
        },
        riskProfilecontainer: {
          marginTop: theme.metrics.spacing.s,
        },
        riskProfileValue: {
          textAlign: 'center'
        },
        riskProfileLabel: {
          textAlign: 'center'
        },
        cardItem: {
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingVertical: theme.metrics.spacing.m,
          paddingHorizontal: theme.metrics.spacing.s,
          backgroundColor: theme.colors.basics.grey.c100
        },
        cardItemHeader: {
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        },
        cardItemPastils: {
          width: theme.metrics.spacing.s,
          height: theme.metrics.spacing.s,
          borderRadius: theme.metrics.spacing.s,
          marginRight: theme.metrics.spacing.s
        },
        supportContainer: {
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          paddingVertical: theme.metrics.spacing.m,
          paddingHorizontal: theme.metrics.spacing.s,
          borderTopWidth: 1,
          borderBottomWidth: 1,
          borderColor: theme.colors.borders.fields.c200
        },
        supportTitle: {
          textDecorationLine: 'underline',
          flex: 75
        },
        detailButtonIcon: {
          fontSize: theme.metrics.spacing.s
        },
        notificationHardContainer: {
          width: isDesktop ? '100%' : '95%',
          marginBottom: theme.metrics.spacing.xm,
        },
        notificationHardDesktopBlock: {
          alignItems: 'center',
          marginVertical: theme.metrics.spacing.big
        },
        notificationHardContentText: {
          marginTop: theme.metrics.spacing.xxm
        },
        bottomDescriptionContainer: {
          marginVertical: theme.metrics.spacing.l,
        },
        bottomDescription: {
          color: theme.colors.basics.grey.c500,
        },
      }),
    [isDesktop, theme]
  );

  return styles;
}
