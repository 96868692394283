import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import React, { FC } from "react";
import { View } from "react-native";
import { useSelector } from "react-redux";

import { PlanFamillyList } from "@constants/common";
import { RouteNames } from "@constants/navigation";
import { BankOperationTypesList } from "@constants/savings";
import { Text } from "@ere-uilib/atoms";
import { IconEnum } from "@ere-uilib/enums";
import { SimpleButton } from "@ere-uilib/molecules";
import { AppState } from "@modules/reducers";
import { AppNavigatorInterface } from "@navigation/Interfaces";
import { useBankSummaryContext } from "@pages/BankDetails/BankSummaryPage/BankSummaryProvider";
import { useTranslate } from "@pages/BankDetails/BankSummaryPage/hooks/useTranslate";
import { useStyles } from "@pages/BankDetails/BankSummaryPage/useStyles";

type BankAccountEmptyProps = {
    planFamily: PlanFamillyList,
    dispositifs: string,
    planId: string,
    isRefund?: boolean,
}

export const BankAccountEmpty: FC<BankAccountEmptyProps> = ({ planFamily, dispositifs, planId, isRefund }) => {
    const styles = useStyles();
    const { formatMessage } = useTranslate();
    const { onSetSelectedAccountType,
            onSetSelectedPlanFamily,
            setModalData,
            onSelectPlanId } = useBankSummaryContext();

    const navigation = useNavigation<StackNavigationProp<AppNavigatorInterface>>();

    const accountType = isRefund
        ? BankOperationTypesList.VIREMENT
        : BankOperationTypesList.PRELEVEMENT;
    const cardTitle = isRefund
        ? 'CoordonneesBancairesRecapVirement'
        : 'CoordonneesBancairesRecapPrelevement';
    const buttonTitle = isRefund
        ? 'CoordonneesBancairesRecapAjouterCompteVirBouton'
        : 'CoordonneesBancairesRecapAjouterComptePrelBouton';

    const RcActivateState = useSelector((state: AppState) => state.settings.applicationSettings.RcActivateState);
    const handleRedirect = (provider: string) => {
        navigation.navigate(RouteNames.RedirectOperationPage, {
            provider,
            operationType: 'IBAN',
        });
    }

    const handleEdit = (planFamily: PlanFamillyList, plan: string, isRefund?: boolean) => {
        onSetSelectedPlanFamily(planFamily);
        if (planFamily === PlanFamillyList.RC) {
            navigation.navigate(RouteNames.BankDetailsStack, {
                screen: RouteNames.BankSetDocuments
            });
            return;
        }
        const operationType = isRefund
            ? BankOperationTypesList.REMBOURSEMENT
            : BankOperationTypesList.PRELEVEMENT;
        setModalData({ plan, operationType });
    };

    return (
        <View style={styles.contentEmptyStyle}>
            {
                planFamily === PlanFamillyList.RC &&
                <Text style={styles.rcPlanName} variant="t3" weight="light">{formatMessage({ id: 'CoordonneesBancairesRecapDispositifEligibleRC' })}: {dispositifs}</Text>
            }
            <Text
                style={styles.firstLinesStyle}
                variant="t2"
                weight="regular">
                {formatMessage({ id: cardTitle })}
            </Text>
            <Text
                style={styles.informLinkStyle}
                variant="t2"
                weight="regular">
                {formatMessage({ id: 'CoordonneesBancairesRecapARenseigner' })}
            </Text>
            <SimpleButton
                containerStyle={styles.buttonStyle}
                design="outlined"
                isHoverableButton={false}
                leftIcon={IconEnum.AJOUTER}
                leftIconStyle={styles.leftIconStyle}
                onPress={() => {
                    // todo: remove active state one prod test is done
                    if (planFamily === PlanFamillyList.RC && RcActivateState === "Decrochage") {
                        return handleRedirect(planFamily);
                    }
                    onSelectPlanId(planId);
                    onSetSelectedAccountType(accountType);
                    handleEdit(planFamily, dispositifs, isRefund);
                }}
                size="small"
                title={formatMessage({ id: buttonTitle })}
            />
        </View>
    );
}
