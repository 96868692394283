import { useMemo } from "react";
import { Platform, StyleSheet } from "react-native";

import { useTheme } from "@ere-uilib/styles";

const TOP_CONTENT_OFFSET = 60;

export const useStyles = (isAbsolutePositionDisabled?: boolean) => {
  const theme = useTheme();

  const styles = useMemo(() => StyleSheet.create({
    itemsContainerStyle: Platform.select({
      default: {
        overflow: "hidden",
        ...(!isAbsolutePositionDisabled && {
          position: 'absolute',
          top: 60
        }),
        width: '100%',
        borderWidth: 1,
        borderColor: theme.colors.basics.grey.c300,
        borderTopWidth: 0,
        backgroundColor: theme.colors.basics.white,
        maxHeight: 400,
        borderBottomLeftRadius: theme.metrics.spacing.xs,
        borderBottomRightRadius: theme.metrics.spacing.xs
      },
      native: {
        width: '100%',
        borderWidth: 0,
      }
    }),
    itemStyle: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
      paddingHorizontal: theme.metrics.spacing.s,
      paddingVertical: theme.metrics.spacing.m,
      backgroundColor: theme.colors.basics.white,
      borderTopColor: theme.colors.basics.grey.c200,
      borderTopWidth: 1,
      borderRadius: 0,
      minHeight: 47,
    },
    hoveredItem: {
      backgroundColor: theme.colors.buttons.menuItemHover.c500,
    },
    itemTextStyle: {
      color: theme.colors.basics.black,
      ...(Platform.OS === 'web' && { fontFamily: theme.fonts.fontFamily.light }),
    },
    itemTextViewStyle: {
      flex: 1,
    },
    leftIconStyle: {
      marginRight: theme.metrics.spacing.s,
    },
    modalFrameInnerContainer: {
      padding: theme.metrics.spacing.none,
      paddingTop: TOP_CONTENT_OFFSET,
    },
    modalFrameTitle: {
      textAlign: 'center',
      marginBottom: theme.metrics.spacing.xm,
    }
  }), [theme]);

  return styles;
};
