import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { DashboardPageWrapperConnected } from '@components/ERE360Components'
import { RequirementDefinition } from '@components/templates/RequirementDefinition'
import { ArbitrationTypes } from '@constants/arbitrations'
import { RouteNames } from '@constants/navigation'
import {
  ColumnsContainer,
  HeaderResponse,
  SimpleButton,
  useScreenSizes,
  useTranslation
} from '@ere-uilib/index'
import { DirectionEnum } from '@ere-uilib/molecules/steppers/InstallmentsStepper/interfaces'
import { InformativePopin } from '@ere-uilib/organisms/overlay/InformativePopin'

import {
  ArbitrationDecisionPropertiesEnum,
  ArbitrationQuestionTypesEnum
} from '@modules/arbitration/constants'
import { getDecisionAllResponses } from '@modules/arbitration/utils'
import { constructStepperItems } from '@pages/Common/utils'

import { ArbitrationHeader } from '../components/ArbitrationHeader/ArbitrationHeader'
import { useDecisionQuestionTypeDataUpgrade } from './hooks/useDecisionQuestionTypeDataUpgrade'
import { useLabels } from './hooks/useLabels'
import { useOADQuestion } from './hooks/useOADQuestions'
import { ArbitrationPlanChoiceProps } from './interfaces'
import { useStyles } from './useStyles'
import { useTracking } from '@hooks/useTracking'
import { useController } from './'
import { View } from 'react-native'
import { OadProfilComponent } from './components'
import {useNavigation} from "@react-navigation/native";
import {StackNavigationProp} from "@react-navigation/stack";
import {AppNavigatorInterface} from "@navigation/Interfaces";
import { locator } from '@constants/locator';
export const ArbitrationPlanChoice: React.FC<React.PropsWithChildren<ArbitrationPlanChoiceProps>> = ({
  onBackToQuestion,
  onUpdateQuestion,
  requirementDecisionData,
  isVisible,
  clearArbitrationData,
  setIsVisibleArbitrationDecisionSubmitModal,
  selectedDispositif,
  reallocationType,
  arbitrationOadAllocations,
  isLoading,
  setRepartionOad,
  setSimulationOad,
  atInternetClientIdSession
}: ArbitrationPlanChoiceProps) => {
  const upgradedRequirementDecisionData =
    useDecisionQuestionTypeDataUpgrade(requirementDecisionData)
  const allAnswers = getDecisionAllResponses(requirementDecisionData)
  const isOADActive = allAnswers[ArbitrationDecisionPropertiesEnum.OAD_REALLOCATION] === true
  const refColumnsContainer = useRef() as React.MutableRefObject<{ scrollToTop: () => void }>
  const refDashboardContainer = useRef() as React.MutableRefObject<{ scrollToTop: () => void }>
  const { isDesktop } = useScreenSizes();
  const [isEditing, setEditing] = useState(false);
  const { formatMessage } = useTranslation();
  const navigation = useNavigation<StackNavigationProp<AppNavigatorInterface>>();
  const style = useStyles()
  const { OADDelayQuestion, OADRiskQuestion } = useOADQuestion()
  const dictionary = useLabels({
    selectedPlan: selectedDispositif && selectedDispositif[0],
    isOad: isOADActive
  })

  const { repartitionData, simulationData, repartitionLegend } = useController({
    requirementDecisionData,
    arbitrationOadAllocations
  })
  const isAllDispositifsHasOperationInProgress = useMemo(() => {
    if (!requirementDecisionData || requirementDecisionData.questions.length < 2) return false
    const result = requirementDecisionData.questions[1].responses.find(
      response =>
        !response.advancedProperties ||
        (response.advancedProperties &&
          response.advancedProperties.HasRepaymentOrArbitrationTransactionInProgress === false)
    )
    return result === undefined && isOADActive
  }, [requirementDecisionData])

  const handleGoHomePress = useCallback(() => {
    clearArbitrationData()
    navigation.navigate(RouteNames.BottomTabNavigator, {
      screen: RouteNames.Home
    })
  }, [navigation, clearArbitrationData])

  const stepperItems = constructStepperItems({
    size: 3,
    direction: DirectionEnum.ROW,
    activeStep: 1
  })

  useEffect(() => {
    const sentTag = {
      name: 'Parcours Arbitrage',
      chapter1: ''
    }
    switch (reallocationType) {
      case ArbitrationTypes.stock:
        sentTag.chapter1 = 'Choix_dispositif_stock'
        break
      case ArbitrationTypes.flow:
        sentTag.chapter1 = 'Choix_dispositif_flow'
        break
    }
    useTracking(sentTag, atInternetClientIdSession || '')
  }, [])

  const renderHeader = () => {
    return (
      <ArbitrationHeader
        displayCloseButton
        helpPoint
        helpPointContentHtml={dictionary.header.helpContent}
        helpPointModalTitle={dictionary.header.helpTitle}
        helpPointPosition="left"
        stepperItems={stepperItems}
        title={dictionary.header.pageTitle}
      />
    )
  }

  const handleScrollToTop = useCallback(() => {
    if (isDesktop) {
      refDashboardContainer?.current?.scrollToTop()
    } else {
      refColumnsContainer?.current?.scrollToTop()
    }
  }, [refColumnsContainer, refDashboardContainer, isDesktop])

  const handelUpdateQuestion = useCallback(
    (responsePayload, index, canNotBeAsked) => {
      setEditing(false)
      handleScrollToTop()
      onUpdateQuestion(responsePayload, index, canNotBeAsked, OADDelayQuestion, OADRiskQuestion)
    },
    [
      upgradedRequirementDecisionData,
      handleScrollToTop,
      navigation,
      onUpdateQuestion,
      OADDelayQuestion,
      OADRiskQuestion
    ]
  )
  const respondedQuestion = upgradedRequirementDecisionData?.questions?.filter(
    question => question?.responded
  )

  const unRespondedQuestion = upgradedRequirementDecisionData?.questions?.filter(
    question => !question?.responded
  )

  const handleBackToQuestion = useCallback(
    index => {
      setEditing(true)
      onBackToQuestion(index)
    },
    [onBackToQuestion]
  )

  const handleValidatePopin = useCallback(() => {
    // when flow we bypass the divestment step
    const routeName =
      reallocationType === ArbitrationTypes.flow
        ? RouteNames.ArbitrationReinvestment
        : RouteNames.ArbitrationDisinvestmentsSaving
    setIsVisibleArbitrationDecisionSubmitModal(false)
    navigation.navigate(RouteNames.ArbitrationStack, {
      screen: routeName
    })
  }, [reallocationType, setIsVisibleArbitrationDecisionSubmitModal, navigation])

  const handleClosePopin = useCallback(() => {
    setIsVisibleArbitrationDecisionSubmitModal(false)
  }, [setIsVisibleArbitrationDecisionSubmitModal])

  const handleSubmit = useCallback(() => {
    if (!simulationData) return;
    setRepartionOad(repartitionData);
    setSimulationOad(simulationData);
    return navigation.navigate(RouteNames.ArbitrationStack, {
      screen: RouteNames.ArbitrationOADRepartition
    })
  }, [setRepartionOad, setSimulationOad, repartitionData, simulationData])

  // check question displaying
  useEffect(() => {
    if (isEditing || !unRespondedQuestion[0]?.implicitResponseEnabled) {
      return
    }
    const response = {
      respondedValue: unRespondedQuestion[0].responses[0]?.value,
      respondedTitle: unRespondedQuestion[0].responses[0]?.title,
      title: unRespondedQuestion[0].respondedTitle || unRespondedQuestion[0].title,
      respondedPropertyFilter: unRespondedQuestion[0].responses[0]?.propertyFilter
    }
    const questionIndex =
      upgradedRequirementDecisionData.questions.length - 1 - (unRespondedQuestion.length - 1)
      onUpdateQuestion(
      response,
      questionIndex,
      unRespondedQuestion[0].canNotBeAsked || false,
      OADDelayQuestion,
      OADRiskQuestion
    )
  }, [
    onUpdateQuestion,
    unRespondedQuestion,
    requirementDecisionData,
    upgradedRequirementDecisionData,
    respondedQuestion,
    isEditing,
    OADDelayQuestion,
    OADRiskQuestion
  ])

  const renderButton = useCallback(() => {
    if (unRespondedQuestion.length <= 0) return
    const isCurrentPlanChoiceQuestion =
      unRespondedQuestion[0].type === ArbitrationQuestionTypesEnum.PLAN_CHOICE

    if (isAllDispositifsHasOperationInProgress && isCurrentPlanChoiceQuestion) {
      return (
        <SimpleButton
          design="solid"
          onPress={handleGoHomePress}
          title={dictionary.goHomeButton.buttonTitle}
        />
      )
    }
    return
  }, [dictionary, handleGoHomePress, isAllDispositifsHasOperationInProgress, unRespondedQuestion]);

  const renderInformativePopin = useCallback(() => {
    return(<InformativePopin
        buttonTitle={dictionary.popin.btnTitle}
        description={dictionary.popin.description}
        iconName="idea"
        isModalVisible={isVisible || false}
        onClose={handleClosePopin}
        onValidate={handleValidatePopin}
        title={dictionary.popin.title}
        testId={locator._arbitration._i_understand}
      />)
  }, [dictionary, handleClosePopin, handleValidatePopin, isVisible]);

  const renderRecap = useCallback(() => {
    if (isLoading) return
    return (
      <View style={style.oadRecapContainer}>
        <View style={style.oadRecapContentContainer}>
          <HeaderResponse
            iconName="recherche"
            title={formatMessage({ id: 'AbritrageOADResultatQRResultatchoixDescription' })}
          />
          <OadProfilComponent
            repartitionData={repartitionData}
            repartitionLegend={repartitionLegend}
            simulationData={simulationData}
          />
        </View>
        <View>
          <SimpleButton
            containerStyle={style.oadRedirectButton}
            disabled={unRespondedQuestion.length !== 0}
            onPress={handleSubmit}
            title={formatMessage({ id: 'AbritrageOADResultatQRContinuerBouton' })}
            testId={locator._arbitration._oad_continue}
          />
        </View>
      </View>
    )
  }, [isLoading, repartitionData, repartitionLegend, simulationData, unRespondedQuestion])
  return (
    <DashboardPageWrapperConnected
      cardContentStyle={style.dashboardWrapper}
      dashboardPageWrapperRef={refDashboardContainer}>
      <ColumnsContainer
        columnsContainerRef={refColumnsContainer}
        renderHeader={renderHeader}
        renderStickyMobileBottom={renderButton}>
        <RequirementDefinition
          arbitrationOadAllocations={arbitrationOadAllocations}
          dictionary={dictionary.content}
          isLoading={isLoading}
          onBackToQuestion={handleBackToQuestion}
          onUpdateQuestion={handelUpdateQuestion}
          renderEligibilityRecap={undefined}
          renderSubmitButton={undefined}
          requirementDecisionData={upgradedRequirementDecisionData}
          shouldFormatAsPlusMinus={false}
        />
        {isDesktop && renderButton()}
        {unRespondedQuestion.length === 0 && isOADActive && renderRecap()}
      </ColumnsContainer>
      {renderInformativePopin()}
    </DashboardPageWrapperConnected>
  )
}
