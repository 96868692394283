import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getDocumentBlobRequestByType } from '@modules/document-space/actions/documentsActions';
import { DocumentsActionsType } from '@modules/document-space/actions/documentsActionsTypes';
import { AppState } from '@modules/reducers';

export const useController = () => {

  const isDocumentDonwloading = useSelector((state:AppState) => state.isLoading[DocumentsActionsType.GET_DOCUMENT_BLOB_RESPONSE_REQUEST]);

  const error =  useSelector((state:AppState) => state.error[DocumentsActionsType.GET_DOCUMENT_BLOB_RESPONSE_REQUEST]);
   const dispatch = useDispatch();
 
   const onGetDocumentDownloadFile = useCallback(
     (documentType: string, documentTitle: string) => {
       dispatch(getDocumentBlobRequestByType(documentType, documentTitle));
     },
     [dispatch]
   );

  return {
    onGetDocumentDownloadFile,
    isDocumentDonwloading,
    error,
  };
}
