import { PlanTypeEnum } from '@ere-uilib/enums'

import { ColorTypes } from './interfaces';
import { palette } from './palette';

export const colors: ColorTypes = {
  plans: {
    [PlanTypeEnum.PERU]: {
      c100: palette.redDark_1.c100,
      c500: palette.redDark_1.c500
    },
    [PlanTypeEnum.PERECO]: {
      c100: palette.yellowDark_1.c100,
      c500: palette.yellowDark_1.c500
    },
    [PlanTypeEnum.PERO]: {
      c100: palette.orangeLight_1.c100,
      c500: palette.orangeLight_1.c500
    },
    [PlanTypeEnum.PERCO]: {
      c100: palette.orangeDark_1.c100,
      c500: palette.orangeDark_1.c500
    },
    [PlanTypeEnum.PEE]: {
      c100: palette.blueDark_1.c100,
      c500: palette.blueDark_1.c500
    },
    [PlanTypeEnum.PEE2]: {
      c100: palette.blueLight_1.c100,
      c500: palette.blueLight_1.c500
    },
    [PlanTypeEnum.BPSS]: {
      c100: palette.greenLight_1.c100,
      c500: palette.greenLight_1.c500
    },
    [PlanTypeEnum.HES]: {
      c500: palette.greenLight_2.c300,
    },
    [PlanTypeEnum.PPESV]: {
      c100: palette.greenLight_3.c100,
      c500: palette.greenLight_3.c500
    },
    [PlanTypeEnum.A82]: {
      c100: palette.greenDark_1.c100,
      c500: palette.greenDark_1.c600,
    },
    [PlanTypeEnum.A39]: {
      c100: palette.greenDark_2.c100,
      c500: palette.greenDark_2.c500
    },
    UNKNOWN: {
      c100: palette.grey.c100,
      c500: palette.grey.c500
    }
  },
  activeClasses: {
    monetary: {
      c100: palette.blueLight_2.c100,
      c500: palette.blueLight_2.c500
    },
    mandatory: {
      c100: palette.blueDark_1.c100,
      c500: palette.blueDark_1.c500
    },
    diversified: {
      c100: palette.purpleDark_2.c100,
      c500: palette.purpleDark_2.c500
    },
    actions: {
      c100: palette.pinkDark_2.c100,
      c500: palette.pinkDark_2.c500
    }
  },
  investors: {
    safe: {
      c100: palette.greenDark_2.c100,
      c500: palette.greenDark_2.c500
    },
    stable: {
      c100: palette.greenLight_2.c100,
      c500: palette.greenLight_2.c500
    },
    dangerous: {
      c100: palette.orangeDark_1.c100,
      c500: palette.orangeDark_1.c500
    }
  },
  operationState: {
    scheduled: {
      c500: palette.grey.c500,
    },
    inProgress: {
      c500: palette.blueDark_3.c300,
      c600: palette.blueDark_3.c600
    },
    success: {
      c500: palette.greenLight_2.c500
    },
    failed: {
      c500: palette.pinkLight_1.c500
    },
    pending: {
      c500: palette.yellowLight_2.c500,
      c700: palette.yellowDark_3.c700
    },
    canceled: {
      c500: palette.grey.c200
    }
  },
  gradients: {
    headerResponse: [palette.greenDark_4.c500, palette.greenDark_1.c400],
    headerProfile: [palette.greenDark_4.c500, palette.greenDark_1.c400],
    headerDisabled: [palette.grey.c200, palette.grey.c200],
    greenBackground: [palette.greenSoft_1.c500, palette.greenSoft_1.c200]
  },
  notifications: {
    success: {
      c100: palette.greenLight_2.c100,
      c500: palette.greenLight_2.c500
    },
    warning: {
      c100: palette.pinkLight_1.c100,
      c500: palette.pinkLight_1.c500
    },
    tips: {
      c100: palette.yellowLight_2.c100,
      c500: palette.yellowLight_2.c500
    },
    information: {
      c100: palette.grey.c100,
      c500: palette.grey.c500
    },
    shadow: palette.blackTransparent.c100
  },
  amounts: {
    positive: {
      c100: palette.greenLight_2.c100,
      c500: palette.greenLight_2.c500
    },
    negative: {
      c100: palette.pinkLight_1.c100,
      c500: palette.pinkLight_1.c500
    }
  },
  buttons: {
    classic: {
      c300: palette.greenLight_3.c300,
      c500: palette.greenDark_1.c500
    },
    classicHover: {
      c100: palette.greenDark_3.c100,
      c500: palette.greenDark_3.c500
    },
    disabled: {
      c500: palette.grey.c200
    },
    filter: {
      c500: palette.purpleDark_1.c500
    },
    filterHover: {
      c500: palette.purpleLight_1.c500
    },
    menuItemActive: {
      c500: palette.greenDark_1.c500
    },
    menuItemHover: {
      c500: palette.greenDark_3.c100
    }
  },
  text: {
    primary: {
      c500: palette.grey.c900
    },
    secondary: {
      c500: palette.grey.c500
    },
    disabled: {
      c500: palette.grey.c400
    },
    link: {
      c500: palette.greenDark_1.c500
    }
  },
  background: {
    default: {
      c500: palette.grey.c100
    },
    verticalMenu: {
      c500: palette.white
    },
    poppinFilter: {
      c500: palette.blackTransparent.c700
    },
    scrollButtonFilter: {
      c500: palette.white
    },
    tableCellHeader: {
      c500: palette.grey.c200,
      c600: palette.grey.c500
    },
    greenBackground: {
      c100: palette.greenDark_3.c100,
      c200: palette.greenSoft_1.c200,
      c300: palette.greenSoft_1.c300,
      c500: palette.greenSoft_1.c500,
      c600: palette.greenSoft_1.c600
    },
    blueLight: {
      c100: palette.blueLight_3.c100
    },
    error: {
      c100: palette.redLight.c100
    },
    pending: {
      c100: palette.yellowLight_1.c100
    }
  },
  borders: {
    fields: {
      c200: palette.grey.c200,
      c500: palette.grey.c300
    }
  },
  charts: {
    lineListColor: [
      palette.blueDark_3.c500,
      palette.purpleDark_1.c500,
      palette.brown_1.c500,
      palette.yellowDark_3.c500
    ]
  },
  oadChart: {
    monetary: palette.blueLight_2.c500,
    action: palette.pinkDark_2.c500,
    diversified: palette.purpleDark_2.c500,
    obligatory: palette.blueDark_1.c500
  },
  oadProfileRisk: {
    safe: palette.blueLight_2.c500,
    balanced: palette.purpleDark_2.c500,
    dynamic: palette.pinkDark_2.c500,
  },
  basics: {
    black: palette.grey.c900,
    greenLightBackground: palette.greenLight_3.c200,
    blackTransparent: palette.blackTransparent,
    greyTransparent: palette.greyTransparent,
    greenTransparent: palette.greenTransparent,
    primary: {
      c100: palette.greenDark_1.c200,
      c200: palette.greenDark_1.c100,
      c400: palette.greenDark_1.c400,
      c500: palette.greenDark_1.c500,
      c700: palette.greenDark_3.c500
    },
    grey: {
      c100: palette.grey.c100,
      c200: palette.grey.c200,
      c300: palette.grey.c300,
      c400: palette.grey.c400,
      c500: palette.grey.c500,
      c600: palette.grey.c600,
      c900: palette.grey.c900
    },
    white: palette.white,
    message: {
      error: {
        c500: palette.red
      },
      backColor: palette.redLight.c100
    }
  },
  franceConnect: {
    link: palette.franceConnect.link
  }
}
