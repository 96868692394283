import {
  ProfitSharingIncentiveLegalFrameworkEnum,
  ProfitSharingIncentiveTypeEnum
} from '@constants/ProfitSharingIncentive';

import { LegalFrameworkByType } from './types';

type Label = {
  header: {
    title: LegalFrameworkByType<string>;
    help: {
      title: string;
      content: string;
    };
  };
};

export const label: Label = {
  header: {
    title: {
      [ProfitSharingIncentiveTypeEnum.incentive]: {
        default: 'PIMonInteressementTitre',
        [ProfitSharingIncentiveLegalFrameworkEnum.normal]: 'PIMonInteressementTitre',
        [ProfitSharingIncentiveLegalFrameworkEnum.complementary]: 'PIMonInteressementCOMPTitre',
        [ProfitSharingIncentiveLegalFrameworkEnum.exceptional]: 'PIMonInteressementEXCEPTitre',
        [ProfitSharingIncentiveLegalFrameworkEnum.ppv]: 'PIMonInteressementPPVTitre'
      },
      [ProfitSharingIncentiveTypeEnum.profitsharing]: {
        default: 'PIMaParticipationTitre',
        [ProfitSharingIncentiveLegalFrameworkEnum.normal]: 'PIMaParticipationTitre',
        [ProfitSharingIncentiveLegalFrameworkEnum.complementary]: 'PIMaParticipationCOMPTitre',
        [ProfitSharingIncentiveLegalFrameworkEnum.exceptional]: 'PIMaParticipationEXCEPTitre'
      }
    },
    help: {
      title: 'PIDefinitionBesoinAideTitre',
      content: 'PIDefinitionBesoinAideDescription'
    }
  },
};
