import React, { useCallback } from 'react';
import { View } from 'react-native';

import { CardWithLeftThread, Text } from '@ere-uilib/atoms';
import { FiletStepper, HeaderResponse } from '@ere-uilib/molecules';

import { Props } from './interfaces';
import { useStyles } from './useStyles';

export const FiletStepperCardExternalAccounts: React.FC<React.PropsWithChildren<Props>> = ({
  stepTitle,
  title,
  amount,
  description,
  containerStyle,
  headerResponseCustomContainerStyle,
  children
}) => {
  const styles = useStyles();

  //   RENDER ORIGIN CARD
  const renderCard = useCallback(() => {

    return (
      <View >
        <FiletStepper
          containerStyle={styles.filetContainer}
          contentContainer={containerStyle}
          disableLine
          filetContainerStyle={styles.filetSubContainer}>
          <View style={styles.fromContent}>
            <Text style={styles.filetText}>
              {stepTitle}
            </Text>
            <CardWithLeftThread
              addShadow
              containerStyle={styles.cardContainer}
            >

              <HeaderResponse
                amount={amount}
                customContainerStyle={
                  [
                    {
                      backgroundColor: "#FFF",
                      borderTopLeftRadius: 0
                    },
                    headerResponseCustomContainerStyle,
                  ]
                }
                darkText={true}
                shouldFormatAsPlusMinus={false}
                title={title}
              />
              <Text
                style={styles.textContainer}
                variant='t3'>
                {description}
              </Text>

              {children}
            </CardWithLeftThread>
          </View>
        </FiletStepper>
      </View>
    );
  }, []);

  return (
    renderCard()
  );
};