import { useCallback, useMemo, useState } from "react";

import { PieChartData } from "@ere-uilib/types";

import { ActiveClassCardContentData } from "../interfaces";

interface CurrentEditedItem {
  activeClass: string;
  support: string;
};

export interface FormValues {
  [activeClassUuid: string]: {
    [supportUuid: string]: number | undefined;
  };
};

interface UseForm {
  activeClassCardContentData: ActiveClassCardContentData;
  simulatedRepartitionData: PieChartData[];
}

export const useForm = ({
  activeClassCardContentData,
  simulatedRepartitionData,
}: UseForm) => {
  const [formValues, setFormValues] = useState<FormValues>();
  const [currentEditedItem, setCurrentEditedItem] = useState<CurrentEditedItem | null>(null);

  const currentActiveClassName = useMemo(() => {
    if (!currentEditedItem) {
      return;
    }

    const name = activeClassCardContentData[currentEditedItem.activeClass].title;

    return name;
  }, [activeClassCardContentData, currentEditedItem]);

  const currentSupportName = useMemo(() => {
    if (!currentEditedItem) {
      return;
    }

    const name = activeClassCardContentData[currentEditedItem.activeClass].contentData[currentEditedItem.support].title;

    return name;
  }, [activeClassCardContentData, currentEditedItem]);

  const remainingPercentage = useMemo(() => {
    let newRemainingPercentage = 100;

    if (!formValues) {
      return newRemainingPercentage;
    }

    Object.keys(formValues).forEach(activeClassUuid => {
      const activeClass = formValues[activeClassUuid];

      Object.keys(activeClass).forEach(supportUuid => {
        const supportValue = activeClass[supportUuid];

        if (supportValue) {
          newRemainingPercentage -= supportValue;
        }
      });
    });

    return newRemainingPercentage;
  }, [formValues]);

  const formMaxValue = useMemo(() => {
    let maxValue = remainingPercentage;

    if (!formValues || !currentEditedItem) {
      return remainingPercentage;
    }

    if (currentEditedItem?.activeClass && currentEditedItem?.support) {
      const currentEditedValue = formValues[currentEditedItem.activeClass]?.[currentEditedItem.support];

      if (!!currentEditedValue) {
        maxValue = currentEditedValue + remainingPercentage;
      }
    }

    return maxValue;
  }, [currentEditedItem, formValues, remainingPercentage]);

  const manualRepartitionData: PieChartData[] = useMemo(() => {
    const newManualRepartitionData = Object.keys(activeClassCardContentData).map(activeClassUuid => {
      const supportUuids = formValues?.[activeClassUuid];
      let activeClassValue = 0;
      const activeClass = activeClassCardContentData[activeClassUuid];

      if (supportUuids) {
        Object.keys(supportUuids).forEach(supportUuid => {
          const supportValue = supportUuids[supportUuid];

          if (supportValue) {
            activeClassValue += supportValue;
          }
        });
      }

      const repartition = {
        value: activeClassValue / 100,
        key: activeClass.key,
        color: activeClass.color,
        title: activeClass.title,
      };

      return repartition;
    });

    return newManualRepartitionData;
  }, [formValues, activeClassCardContentData]);

  const currentSupportValue = useMemo(() => {
    if (!currentEditedItem?.activeClass || !currentEditedItem?.support) {
      return;
    }

    const value = 100 * activeClassCardContentData[currentEditedItem?.activeClass].contentData[currentEditedItem?.support].actualRepartition;

    return value;
  }, [activeClassCardContentData, currentEditedItem]);

  const initialModalAmount = useMemo(() => {
    if (!currentEditedItem?.activeClass || !currentEditedItem.support || !formValues) {
      return;
    }

    const currentEditedSupportValue = formValues[currentEditedItem?.activeClass]?.[currentEditedItem.support];

    return currentEditedSupportValue;
  }, [currentEditedItem, formValues]);

  const isFormModalVisible = useMemo(() => {
    const isVisible = !!currentEditedItem?.activeClass && !!currentEditedItem?.support;

    return isVisible;
  }, [currentEditedItem]);

  const currentActiveClassSimulationValue = useMemo(() => {
    const activeClass = simulatedRepartitionData.find(item => currentEditedItem?.activeClass === item.key);

    if (!activeClass) {
      return;
    }

    const activeClassValue = activeClass.value * 100;

    return activeClassValue;
  }, [currentEditedItem, simulatedRepartitionData]);

  const updateFormValue = useCallback((activeClassUuid, supportUuid, value?: number) => {
    const newActiveClassCardContentFormValue = { ...formValues };
  
    if (value === undefined) {
      if (Object.keys(newActiveClassCardContentFormValue[activeClassUuid]).length === 1) {
        delete newActiveClassCardContentFormValue[activeClassUuid];
      } else {
        delete newActiveClassCardContentFormValue[activeClassUuid][supportUuid];
      }
    } else {
      newActiveClassCardContentFormValue[activeClassUuid] = {
        ...newActiveClassCardContentFormValue[activeClassUuid],
        [supportUuid]: value,
      };
    }
  
    setFormValues(newActiveClassCardContentFormValue);
  }, [formValues]);

  const hideFormModal = useCallback(() => {
    setCurrentEditedItem(null);
  }, []);

  const handleInputPress = useCallback((activeClass: string, support: string) => {
    setCurrentEditedItem({
      activeClass,
      support,
    });
  }, []);

  const handeAmountFieldModalSubmit = useCallback((value?: number | null) => {
    if (!currentEditedItem?.activeClass || !currentEditedItem?.support || !value) {
      return;
    }

    updateFormValue(currentEditedItem.activeClass, currentEditedItem.support, value);
    hideFormModal();
  }, [updateFormValue, hideFormModal, currentEditedItem]);

  const handleResetInputPress = useCallback((activeClassUuid: string, supportUuid: string) => {
    updateFormValue(activeClassUuid, supportUuid);
  }, [updateFormValue]);

  return {
    formValues,
    formMaxValue,
    remainingPercentage,
    manualRepartitionData,
    initialModalAmount,
    isFormModalVisible,
    currentEditedItem,
    currentActiveClassName,
    currentSupportName,
    currentSupportValue,
    currentActiveClassSimulationValue,
    updateFormValue,
    hideFormModal,
    handleInputPress,
    handeAmountFieldModalSubmit,
    handleResetInputPress,
  };
}
