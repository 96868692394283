import React, { useState } from 'react';
import { View } from 'react-native';

import { Spinner, Text, FontIcon, Pressable } from '@ere-uilib/atoms';
import { createUseStyles, useTheme } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';

import { SimpleIconButtonProps } from './interface';
import { getStyles } from './styles';

export const SimpleIconButton: React.FC<React.PropsWithChildren<SimpleIconButtonProps>> = ({
  title,
  loading = false,
  loadingTitle,
  disabled,
  isHoverableButton = true,
  leftIcon,
  rightIcon,
  leftIconStyle,
  rightIconStyle,
  onPress,
  textStyle,
  containerStyle,
  design = 'solid',
  size = 'large',
  children,
  titleTextProps = {},
  testId
}) => {
  const theme = useTheme();
  const { formatMessage } = useTranslation();

  const [hovered, setHovered] = useState<boolean>(false);
  const [pressed, setPressed] = useState<boolean>(false);

  const styles = useStyles(
    {
      design,
      disabled,
      loading,
      size,
      isContent: !!(title || children),
      isLeftIcon: !!leftIcon,
      isRightIcon: !!rightIcon,
      theme,
      hovered,
      pressed,
    },
    {
      leftIconStyle,
      rightIconStyle,
      containerStyle,
      textStyle,
    }
  );

  const defaultLoadingTitle = formatMessage({
    id: 'operation-status_in-treatment',
  });

  return (
        <Pressable
          onPressIn={()=> setPressed(true)}
          onPressOut={()=> setPressed(false)}
          onHoverIn={() => isHoverableButton && setHovered(true)}
          onHoverOut={() => setHovered(false)}
          disabled={disabled || loading}
          onPress={onPress}
          style={styles.containerStyle}
        >
          <View style={styles.innerContainerStyle}>
            {loading && (
              <Spinner
                color={
                  disabled ?
                    theme.colors.basics.grey.c400
                    : ['outlined', 'light'].includes(design) ?
                      theme?.colors.buttons.classic.c500
                      : theme?.colors.basics.white
                }
              />
            )}
            {!loading && leftIcon && (
              <FontIcon
                name={leftIcon}
                style={styles.leftIconStyle}
              />
            )}
            <View style={styles.contentContainerStyle} testID={testId} accessibilityLabel={testId}>
              {!children && title && (
                <Text
                  {...titleTextProps}
                  style={styles.textStyle}
                >
                  {loading ? loadingTitle || defaultLoadingTitle : title}
                </Text>
              )}
              {children}
            </View>
            {rightIcon && (
              <FontIcon
                name={rightIcon}
                style={styles.rightIconStyle}
              />
            )}
          </View>
        </Pressable>
  );
};

const useStyles = createUseStyles(getStyles);
