import { useMemo } from "react";

import { useTranslation } from "@ere-uilib/translations";

interface UseModal {
  currentSupportValue?: number;
  currentActiveClassName?: string;
  currentActiveClassSimulationValue?: number;
  initialModalAmount?: number;
  currentSupportName?: string;
  formMaxValue: number;
}

export const useModal = ({
  currentSupportValue,
  currentActiveClassName,
  currentActiveClassSimulationValue,
  currentSupportName,
  initialModalAmount,
  formMaxValue,
}: UseModal) => {
  const { formatMessage, formatPercentNumber } = useTranslation();

  const modalSubTitle = useMemo(() => {
    if (!currentActiveClassSimulationValue && currentActiveClassSimulationValue !== 0) {
      return;
    }

    const subTitle = `${formatMessage({
      id: 'AbritrageOADSaisiepourcentageResultatSimulation',
    })} ${currentActiveClassName}: ${formatPercentNumber({ value: currentActiveClassSimulationValue, options: { minimumFractionDigits: 0 } })}`;

    return subTitle;
  }, [currentActiveClassSimulationValue, currentActiveClassName, formatMessage, formatPercentNumber]);

  const modalContentSubTitle = useMemo(() => {
    if (!currentSupportValue) {
      return;
    }

    const subTitle = `${formatMessage({
      id: 'AbritrageOADSaisiepourcentageContenu',
    })} ${formatPercentNumber({ value: currentSupportValue, options: { minimumFractionDigits: 0 } })}`;

    return subTitle;
  }, [formatMessage, formatPercentNumber, currentSupportValue]);

  const modalTitle = useMemo(() => {
    if (!currentActiveClassName) {
      return '';
    }

    const subTitle = `${formatMessage({
      id: 'AbritrageOADSaisiepourcentageClasseActif',
    })} ${currentActiveClassName}`;

    return subTitle;
  }, [formatMessage, currentActiveClassName]);

  const modalData = useMemo(() => ({
    title: modalTitle,
    subTitle: modalSubTitle,
    initialAmount: initialModalAmount,
    contentTitle: currentSupportName,
    contentSubTitle: modalContentSubTitle,
    amountParameters: {
      maxPercent: formMaxValue,
    }
  }), [modalTitle, modalSubTitle, initialModalAmount, currentSupportName, modalContentSubTitle, formMaxValue]);

  return {
    modalData,
  };
}
