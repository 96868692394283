import { ArbitrationTypes } from '@constants/arbitrations'
import { FilterPropertiesType, SupportType } from '@modules/common/types'
import { getUsedCompanyId } from '@modules/dashboard/selectors'
import { AppState } from '@modules/reducers'

import {
  AllDispositifs,
  ArbitrationOadRepartitionData,
  ArbitrationOADSupportPercentageValue,
  EligibleAllocation,
  FetchCreateArbitrationExpectedData,
  OadReallocationsDataTypes
} from './types'
import {
  buildFetchCreateArbitrationBody,
  camputePlansEligibility,
  getDecisionAllResponses
} from './utils'
export const getRiskProfileState = (state: AppState) => state.arbitration.riskProfile
export const getArbitrationState = (state: AppState) => state.arbitration
export const getArbitrationError = (state: AppState) => state.arbitration.error
export const getArbitrationQuestions = (state: AppState) => state.arbitration.decision.questions
export const getArbitrationDispositifs = (state: AppState) => state.arbitration.decision.dispositifs
export const eligibleAllocation = (state: AppState): EligibleAllocation => {
  const dispositifs = state.arbitration.decision.dispositifs
  return {
    isEligibleStock:
      (dispositifs &&
        dispositifs.some((dispositif: any) => dispositif.filterProperties.StockReallocation)) ||
      false,
    isEligibleFlow:
      (dispositifs &&
        dispositifs.some((dispositif: any) => dispositif.filterProperties.FlowReallocation)) ||
      false
  }
}

export const getEligibleDispositifs = (state: AppState): AllDispositifs => {
  const dispositifs = state.arbitration.decision.dispositifs
  return camputePlansEligibility(dispositifs)
}

export const getIneligibleDispositifs = (state: AppState): AllDispositifs => {
  const dispositifs = state.arbitration.decision.dispositifs
  return {
    stockDispositifs:
      (dispositifs &&
        dispositifs
          .filter(dispositif => !dispositif.filterProperties.StockReallocation)
          .map(dispositif => dispositif)) ||
      [],
    flowDispositifs:
      (dispositifs &&
        dispositifs
          .filter(dispositif => !dispositif.filterProperties.FlowReallocation)
          .map(dispositif => dispositif)) ||
      []
  }
}
export const getArbitrationDecision = (state: AppState) => {
  return state.arbitration.decision
}
export const getArbitrationDecisionAllResponses = (state: AppState): FilterPropertiesType =>
  getDecisionAllResponses(state.arbitration.decision)

export const getPlanCompartments = (state: AppState) => {
  return state.arbitration.decision.planCompartments
}

export const getIsVisibleSubmitModal = (state: AppState) => {
  return state.arbitration.decision.isVisibleSubmitModal
}

export const getSelectedDispositif = (state: AppState) => {
  return state.arbitration.decision.selectedDispositif
}
export const getReallocationType = (state: AppState) => {
  return state.arbitration.decision.reallocationType
}

export const getOadAllocations = (state: AppState) => {
  return state.arbitration.arbitrationOadAllocations
}

export const getSelectedCompartment = (state: AppState) => {
  return state.arbitration.decision.selectedCompartment
}

export const getSelectedManagement = (state: AppState) => {
  return state.arbitration.decision.selectedCompartment?.managements.find(management =>
    management?.supports.find((support: SupportType) => support?.formValues?.isSelected)
  )
}

export const getSelectedSupport = (state: AppState) => {
  return getSelectedManagement(state)?.supports.find(
    (support: SupportType) => support?.formValues?.isSelected
  )
}

export const getArbitrationDesinvestAmountModalVisibleState = (state: AppState) =>
  state.arbitration.arbitrationAmountModalVisibleState

export const getDivestmentSource = (state: AppState) =>
  state.arbitration.decision.divestedManagementSource

export const getTargetInvestedData = (state: AppState) => state.arbitration.reallocationDivested

export const getInvestedCompartment = (state: AppState) =>
  state.arbitration.reallocationDivested?.compartments[0]

export const getInvestedTargetData = (state: AppState) => state.arbitration.reallocationDivested

export const getTotalInvestedPercent = (state: AppState) => {
  let totalPercent = 0
  state.arbitration.reallocationDivested?.compartments[0]?.managements.map(management => {
    totalPercent += management?.investedFormValues?.amountPercent || 0
    totalPercent += management.supports.reduce((accumulator, support) => {
      return accumulator + (support?.investedFormValues?.amountPercent || 0)
    }, 0)
  })
  return 100 - totalPercent
}

export const getArbitrationResinvestPercentageModalVisibleState = (state: AppState) =>
  state.arbitration.arbitrationPercentageModalVisibleState

export const getReallocationDivested = (state: AppState) => state.arbitration.reallocationDivested

export const getArbitrationAnswerData = (state: AppState) => state.arbitration.arbitrationAnswerData

export const getFetchCreateArbitrationParameters = (
  state: AppState
): FetchCreateArbitrationExpectedData | undefined => {
  const companyId = getUsedCompanyId(state)
  const selectedDispositif = getSelectedDispositif(state)
  const planId = selectedDispositif?.[0]?.id
  const selectedCompartment = getSelectedCompartment(state)
  const disinvestData = getDivestmentSource(state)
  const reinvestData = getInvestedTargetData(state)
  const reinvestTargetedCompartement = reinvestData?.compartments[0]
  const selectedArbitartionType = getReallocationType(state)
  const isStock = selectedArbitartionType === ArbitrationTypes.stock

  const fetchCreateArbitrationBody = buildFetchCreateArbitrationBody({
    isStock,
    selectedCompartment,
    disinvestData,
    reinvestTargetedCompartement
  })

  if (fetchCreateArbitrationBody && companyId && planId) {
    return {
      companyId: companyId,
      planId: selectedDispositif?.[0]?.id,
      body: fetchCreateArbitrationBody
    }
  } else {
    return undefined
  }
}

export interface OadReallocationsParams {
  companyId: string | undefined
  planId: string | undefined
  data: OadReallocationsDataTypes
}
export const getOadReallocationsSubmitData = (state: AppState): OadReallocationsParams => {
  const selectedDispositif = getSelectedDispositif(state)
  const planId = selectedDispositif?.[0]?.id
  const selectedCompartment = getSelectedCompartment(state)
  const selectedManagement = selectedCompartment?.managements[0]
  const choosenOadRepartition: ArbitrationOadRepartitionData[] =
    getArbitrationOadManualRepartitionData(state)
  const funds: ArbitrationOADSupportPercentageValue[] = []
  choosenOadRepartition.map((repartition: ArbitrationOadRepartitionData) => {
    funds.push(...repartition.supports)
  })
  const formattedFunds = funds.map(fund => {
    return {
      rate: fund.percentageValue,
      executionTriggerValue: 0, // à voir
      supportIsinCode: fund.data.supportIsin
    }
  })
  return {
    companyId: getUsedCompanyId(state),
    planId,
    data: {
      sourcePlanManagementId: selectedManagement?.id || '',
      targetPlanManagements: [
        {
          fundsList: formattedFunds,
          amount: selectedManagement?.totalAmount || 0,
          id: selectedManagement?.id || '',
          rate: 1
        }
      ]
    }
  }
}

export const getArbitrationTriggerThresholdModalVisible = (state: AppState) =>
  state.arbitration.arbitrationTriggerThresholdModalVisibleState

export const getArbitrationOadRepartitionData = (state: AppState) =>
  state.arbitration.arbitrationOadRepartitionData

export const getArbitrationOadSimulationData = (state: AppState) =>
  state.arbitration.arbitrationOadSimulationData

export const getArbitrationOadManualRepartitionData = (state: AppState) =>
  state.arbitration.arbitrationOadManualRepartitionData
export const getActualRepartitionProfileRisk = (state: AppState) => {
  const decistion = state.arbitration.decision
  const respondedQuestionsValue = decistion.questions?.[4]?.responded?.respondedTitle
  return respondedQuestionsValue
}

export const getArbitrationDueDateAmountModalVisibleState = (state: AppState) =>
  state.arbitration.arbitrationDueDateAmountModalVisibleState

export const getArbitrationDueDateAmountFieldModalValue = (state: AppState) =>
  state.arbitration.dueDateAmountFieldValue
