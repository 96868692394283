import { useDispatch, useSelector } from 'react-redux';

import { getRefundDemandStatus, getRefundRepartitionData } from '@modules/refund/available-refund/selectors';
import { ClearCacheAfterOperationDone } from '@modules/savings/actions/savingsActions';

import { RefundSuccessPageComponent  } from './RefundSuccessPage.component';

export const RefundSuccessPage : React.FC = () => {
  const dispatch = useDispatch();
  const repartitions = useSelector(getRefundRepartitionData);
  const refundDemandStatus = useSelector(getRefundDemandStatus);
  return(<RefundSuccessPageComponent
          onClearCacheAfterOperationDone={clearCacheAfterOperation => dispatch(ClearCacheAfterOperationDone(clearCacheAfterOperation))}
          refundDemandStatus={refundDemandStatus}
          repartitions={repartitions} />

 );
};
