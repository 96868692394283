import {
  OverviewState,
  FundsErrorState,
  FundRepartitionSupportsState,
  FundsDocumentsState,
  FundsPerformanceState
} from '@modules/funds/types';

import { InitAllErrorsAction } from '@modules/error/actions/errorActionsTypes';

export enum FundsActionsType {
  GET_FUND_REQUEST = 'GET_FUND_REQUEST',
  GET_FUND_FAILURE = 'GET_FUND_FAILURE',
  GET_FUND_OVERVIEW_REQUEST = 'GET_FUND_OVERVIEW_REQUEST',
  GET_FUND_OVERVIEW_SUCCESS = 'GET_FUND_OVERVIEW_SUCCESS',
  GET_FUND_OVERVIEW_FAILURE = 'GET_FUND_OVERVIEW_FAILURE',
  GET_FUND_REPARTITION_SUPPORTS_REQUEST = 'GET_FUND_REPARTITION_SUPPORTS_REQUEST',
  GET_FUND_REPARTITION_SUPPORTS_SUCCESS = 'GET_FUND_REPARTITION_SUPPORTS_SUCCESS',
  GET_FUND_REPARTITION_SUPPORTS_FAILURE = 'GET_FUND_REPARTITION_SUPPORTS_FAILURE',
  GET_FUND_PERFORMANCE_REQUEST = 'GET_FUND_PERFORMANCE_REQUEST',
  GET_FUND_PERFORMANCE_SUCCESS = 'GET_FUND_PERFORMANCE_SUCCESS',
  GET_FUND_PERFORMANCE_FAILURE = 'GET_FUND_PERFORMANCE_FAILURE',
  GET_FUNDS_DOCUMENTS_LIST_REQUEST = 'GET_FUNDS_DOCUMENTS_LIST_REQUEST',
  GET_FUNDS_DOCUMENTS_LIST_SUCCESS = 'GET_FUNDS_DOCUMENTS_LIST_SUCCESS',
  GET_FUNDS_DOCUMENTS_LIST_FAILURE = 'GET_FUNDS_DOCUMENTS_LIST_FAILURE',
  GET_MULTI_FUNDS_REQUEST = 'GET_MULTI_FUNDS_REQUEST',
  GET_MULTI_FUNDS_SUCCESS = 'GET_MULTI_FUNDS_SUCCESS',
  GET_MULTI_FUNDS_FAILURE = 'GET_MULTI_FUNDS_FAILURE',
  GET_MULTI_FUNDS_OVERVIEWS_REQUEST = 'GET_MULTI_FUNDS_OVERVIEWS_REQUEST',
  GET_MULTI_FUNDS_OVERVIEWS_SUCCESS = 'GET_MULTI_FUNDS_OVERVIEWS_SUCCESS',
  GET_MULTI_FUNDS_OVERVIEWS_FAILURE = 'GET_MULTI_FUNDS_OVERVIEWS_FAILURE',
  GET_MULTI_FUNDS_PERFORMANCES_REQUEST = 'GET_MULTI_FUNDS_PERFORMANCES_REQUEST',
  GET_MULTI_FUNDS_PERFORMANCES_SUCCESS = 'GET_MULTI_FUNDS_PERFORMANCES_SUCCESS',
  GET_MULTI_FUNDS_PERFORMANCES_FAILURE = 'GET_MULTI_FUNDS_PERFORMANCES_FAILURE',
  GET_MULTI_FUNDS_REPARTITION_SUPPORTS_REQUEST = 'GET_MULTI_FUNDS_REPARTITION_SUPPORTS_REQUEST',
  GET_MULTI_FUNDS_REPARTITION_SUPPORTS_SUCCESS = 'GET_MULTI_FUNDS_REPARTITION_SUPPORTS_SUCCESS',
  GET_MULTI_FUNDS_REPARTITION_SUPPORTS_FAILURE = 'GET_MULTI_FUNDS_REPARTITION_SUPPORTS_FAILURE',
  SET_COMPARE_MODE = 'SET_COMPARE_MODE'
}

export interface GetFundRequestAction {
  type: FundsActionsType.GET_FUND_REQUEST;
  isinCode: string;
}

export interface GetFundFailureAction {
  type: FundsActionsType.GET_FUND_FAILURE;
  error: FundsErrorState;
}

// overview
export interface GetFundOverviewRequestAction {
  type: FundsActionsType.GET_FUND_OVERVIEW_REQUEST;
  isinCode: string;
}

export interface GetFundOverviewSuccessAction {
  type: FundsActionsType.GET_FUND_OVERVIEW_SUCCESS;
  fundOverview: OverviewState | undefined;
}

export interface GetFundOverviewFailureAction {
  type: FundsActionsType.GET_FUND_OVERVIEW_FAILURE;
  error: FundsErrorState;
}

// repartition supports

export interface GetFundRepartitionSupportsRequestAction {
  type: FundsActionsType.GET_FUND_REPARTITION_SUPPORTS_REQUEST;
  isinCode: string;
}

export interface GetFundRepartitionSupportsSuccessAction {
  type: FundsActionsType.GET_FUND_REPARTITION_SUPPORTS_SUCCESS;
  fundRepartitionSupport: FundRepartitionSupportsState;
}

export interface GetFundRepartitionSupportsFailureAction {
  type: FundsActionsType.GET_FUND_REPARTITION_SUPPORTS_FAILURE;
  error: FundsErrorState;
}

/// performance

export interface GetFundPerformanceRequestAction {
  type: FundsActionsType.GET_FUND_PERFORMANCE_REQUEST;
  isinCode: string;
}

export interface GetFundPerformanceSuccessAction {
  type: FundsActionsType.GET_FUND_PERFORMANCE_SUCCESS;
  fundPerformance: FundsPerformanceState | undefined;
}

export interface GetFundPerformanceFailureAction {
  type: FundsActionsType.GET_FUND_PERFORMANCE_FAILURE;
  error: FundsErrorState;
}

// Funds Documents List
export interface GetFundsDocumentsListRequestAction {
  type: FundsActionsType.GET_FUNDS_DOCUMENTS_LIST_REQUEST;
  isinCode: string;
}

export interface GetFundsDocumentsListSuccessAction {
  type: FundsActionsType.GET_FUNDS_DOCUMENTS_LIST_SUCCESS;
  fundsDocumentsList: FundsDocumentsState[];
  fundDocTypeOrderList?: string[]
}

export interface GetFundsDocumentsListFailureAction {
  type: FundsActionsType.GET_FUNDS_DOCUMENTS_LIST_FAILURE;
  error: FundsErrorState;
}

/// Multi funds overview

export interface GetMultiFundsRequestAction {
  type: FundsActionsType.GET_MULTI_FUNDS_REQUEST;
  isinCodes: string[];
}

export interface GetMultiFundsSuccessAction {
  type: FundsActionsType.GET_MULTI_FUNDS_SUCCESS;
}

export interface GetMultiFundsFailureAction {
  type: FundsActionsType.GET_MULTI_FUNDS_FAILURE;
  error: FundsErrorState;
}

/// Multi funds overview

export interface GetMultiFundsOverviewsRequestAction {
  type: FundsActionsType.GET_MULTI_FUNDS_OVERVIEWS_REQUEST;
  isinCodes: string[];
}

export interface GetMultiFundsOverviewsSuccessAction {
  type: FundsActionsType.GET_MULTI_FUNDS_OVERVIEWS_SUCCESS;
  multiFundsOverviews: OverviewState[];
}

export interface GetMultiFundsOverviewsFailureAction {
  type: FundsActionsType.GET_MULTI_FUNDS_OVERVIEWS_FAILURE;
  error: FundsErrorState;
}

/// Multi funds performances

export interface GetMultiFundsPerformancesRequestAction {
  type: FundsActionsType.GET_MULTI_FUNDS_PERFORMANCES_REQUEST;
  isinCodes: string[];
}

export interface GetMultiFundsPerformancesSuccessAction {
  type: FundsActionsType.GET_MULTI_FUNDS_PERFORMANCES_SUCCESS;
  multiFundsPerformances: FundsPerformanceState[];
}

export interface GetMultiFundsPerformancesFailureAction {
  type: FundsActionsType.GET_MULTI_FUNDS_PERFORMANCES_FAILURE;
  error: FundsErrorState;
}

/// Multi funds supports

export interface GetMultiFundsRepartitionSupportsRequestAction {
  type: FundsActionsType.GET_MULTI_FUNDS_REPARTITION_SUPPORTS_REQUEST;
  isinCodes: string[];
}

export interface GetMultiFundsRepartitionSupportsSuccessAction {
  type: FundsActionsType.GET_MULTI_FUNDS_REPARTITION_SUPPORTS_SUCCESS;
  multiFundsRepartitionSupports: FundRepartitionSupportsState[];
}

export interface GetMultiFundsRepartitionSupportsFailureAction {
  type: FundsActionsType.GET_MULTI_FUNDS_REPARTITION_SUPPORTS_FAILURE;
  error: FundsErrorState;
}

export interface SetCompareModeAction {
  type: FundsActionsType.SET_COMPARE_MODE;
  compareMode: boolean;
}

export type FundsAction =
  | GetFundRepartitionSupportsSuccessAction
  | GetFundOverviewFailureAction
  | GetFundOverviewRequestAction
  | GetFundOverviewSuccessAction
  | GetFundPerformanceRequestAction
  | GetFundPerformanceSuccessAction
  | GetFundPerformanceFailureAction
  | GetFundsDocumentsListRequestAction
  | GetFundsDocumentsListSuccessAction
  | GetFundsDocumentsListFailureAction
  | GetMultiFundsRequestAction
  | GetMultiFundsSuccessAction
  | GetMultiFundsFailureAction
  | GetMultiFundsOverviewsRequestAction
  | GetMultiFundsOverviewsSuccessAction
  | GetMultiFundsOverviewsFailureAction
  | GetMultiFundsPerformancesRequestAction
  | GetMultiFundsPerformancesSuccessAction
  | GetMultiFundsPerformancesFailureAction
  | GetMultiFundsRepartitionSupportsRequestAction
  | GetMultiFundsRepartitionSupportsSuccessAction
  | GetMultiFundsRepartitionSupportsFailureAction
  | SetCompareModeAction
  | InitAllErrorsAction;
