import React from 'react'
import { TouchableOpacity, View } from 'react-native'

import { Text } from '@components/index'
import { SVGLocalLoader, Title } from '@ere-uilib/atoms'
import { useTranslation } from '@ere-uilib/translations'

import { EditAmountButtonProps } from './interfaces'
import { useStyles } from './useStyles'

export const InputEditAmmont: React.FC<React.PropsWithChildren<EditAmountButtonProps>> = ({
  hasDarkBackground,
  amount = 0,
  percent = 0,
  isPercentage = false,
  isDisabled,
  onPress,
  amountStyle,
  style,
  isExtended,
  onResetPress,
  label,
  options,
  testId,
  showSign = true
}) => {
  const isAmountFilled = isPercentage ? !(percent === 0) : !(amount === 0)
  const isSubAmountFilled = isPercentage ? !(amount === 0) : !(percent === 0)
  const styles = useStyles({
    hasDarkBackground,
    isDisabled,
    isExtended,
    isFilled: isAmountFilled,
    amountStyle,
    style
  })
  const { formatCurrencyNumber, formatPercentNumber, formatMessage } = useTranslation()
  return (
    <View>
      <TouchableOpacity
        onPress={onPress}
        style={styles.containerStyle}>
        {label && (
          <View style={{ alignSelf: 'center', marginBottom: 5 }}>
            <Title
              variant="t7"
              weight="light">
              {formatMessage({ id: 'AbritrageOADPageSyntheseChoixNouvelleRepartition' })}
            </Title>
          </View>
        )}
        <View style={{ flexDirection: 'row' }}>
          <View style={styles.amountsContainer}>
            <View style={styles.mainAmountLine}>
              <Text
                style={styles.amountStyle}
                testId={testId}
                variant="t2"
                weight="bold">
                {showSign && amount > 0 && !isPercentage && '+ '}
                {isPercentage
                  ? formatPercentNumber({ value: percent, options })
                  : formatCurrencyNumber({ value: amount })}
              </Text>
            </View>
            {isExtended && isSubAmountFilled && (
              <View style={styles.subAmountLine}>
                <Text style={styles.subAmountStyle}>
                  {!isPercentage
                    ? formatPercentNumber({ value: percent })
                    : formatCurrencyNumber({ value: amount })}
                </Text>
              </View>
            )}
          </View>
          {onResetPress && isAmountFilled && (
            <TouchableOpacity
              onPress={onResetPress}
              style={styles.resetButtonStyle}>
              <SVGLocalLoader
                height={20}
                name="cross"
                width={20}
              />
            </TouchableOpacity>
          )}
        </View>
      </TouchableOpacity>
    </View>
  )
}
