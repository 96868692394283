import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import React, { useCallback, useEffect } from 'react';
import { View } from 'react-native';
import { useSelector } from 'react-redux';

import {
    DashboardPageWrapperConnected,
    Text,
    NavigationHeader,
    ColumnsContainer,
    Modal,
    RetryActionPlaceHolder,
    Spinner
} from '@components/index';
import { PlanFamillyList } from '@constants/index';
import { RouteNames } from '@constants/navigation';
import { AppState } from '@modules/index';
import { AppNavigatorInterface } from "@navigation/Interfaces";
import { useBankSummaryContext } from "@pages/BankDetails/BankSummaryPage/BankSummaryProvider";
import { BankAccountCardES } from "@pages/BankDetails/BankSummaryPage/components/BankAccountCardES";
import { BankAccountCardRC } from "@pages/BankDetails/BankSummaryPage/components/BankAccountCardRC";
import { useTranslate } from "@pages/BankDetails/BankSummaryPage/hooks/useTranslate";

import { BankDetailsCopyIBANModal, MandateModal } from '../components';
import { useStyles } from './useStyles';

export const BankSummaryPageComponent: React.FC = () => {
    const { dictionnary, formatMessage, formatDate } = useTranslate();
    const navigation = useNavigation<StackNavigationProp<AppNavigatorInterface>>();
    const styles = useStyles();
    const { onGetBankData,
        isLoadingBankData,
        bankDataError,
        bankData,
        modalData,
        setModalData,
        isMandateModalVisible,
        setIsMandateModalVisible,
        selectedPlanFamily,
        selectedRcBankDataIndex } = useBankSummaryContext();
    const RcActivateState = useSelector((state: AppState) => state.settings.applicationSettings.RcActivateState);

    useEffect(() => {
        onGetBankData();
    }, [onGetBankData]);

    const isEsEligible = bankData?.es !== null;
    const isRcEligible = bankData?.rc !== null;

    const RenderHeader = useCallback(() => {
        return (
            <NavigationHeader
                displayBackButton={true}
                helpPoint={true}
                helpPointContentHtml={dictionnary.headerHelpPointContentHtml}
                helpPointModalTitle={dictionnary.headerHelpPointModalTitle}
                helpPointPosition={'right'}
                onGoBack={() =>
                    navigation.navigate(RouteNames.BottomTabNavigator, {
                        screen: RouteNames.ProfileStack,
                        params: {
                            screen: RouteNames.Profile
                        }
                    })
                }
                title={dictionnary.headerTitle}
            />
        );
    }, [
        dictionnary.headerHelpPointContentHtml,
        dictionnary.headerHelpPointModalTitle,
        dictionnary.headerTitle,
        navigation
    ]);

    const mandateModalcontent = useCallback(() => {
        const isSelectedPlanFamilyES = selectedPlanFamily === PlanFamillyList.ES;
        const bankDataSelectedPlanType = isSelectedPlanFamilyES ? bankData?.es : bankData?.rc?.rcBankAccountDetailsList?.[selectedRcBankDataIndex];
        const accountDetailsHasIcs = !!bankDataSelectedPlanType?.debitBankAccountDetails?.ics;
        const accountDetailsHasRum = !!bankDataSelectedPlanType?.debitBankAccountDetails?.rum;

        return (
            <View style={styles.mandateModalContentWrapper}>
                <Text
                    variant="t1"
                    weight="bold">
                    {dictionnary.mandateTitle}
                </Text>
                {isSelectedPlanFamilyES && (
                    <View style={styles.mandateTextBlocWrapper}>
                        <Text
                            style={styles.mandateTextStyle}
                            variant="t2"
                            weight="light">
                            {dictionnary.CoordonneesBancairesMandatStatutLabel}
                        </Text>
                        <Text
                            style={styles.mandateTextStyle}
                            variant="t2"
                            weight="regular">
                            {formatMessage({
                                id: bankDataSelectedPlanType?.debitBankAccountDetails?.statusLabel ?? ''
                            })}
                        </Text>
                    </View>
                )}
                {bankDataSelectedPlanType?.debitBankAccountDetails?.dateSignatureMandat && (
                    <View style={styles.mandateTextBlocWrapper}>
                        <Text
                            style={styles.mandateTextStyle}
                            variant="t2"
                            weight="light">
                            {dictionnary.CoordonneesBancairesMandatDateSignatureLabel}
                        </Text>
                        <Text
                            style={styles.mandateTextStyle}
                            variant="t2"
                            weight="regular">
                            {formatDate({
                                value: bankDataSelectedPlanType?.debitBankAccountDetails?.dateSignatureMandat
                            })}
                        </Text>
                    </View>
                )}
                {accountDetailsHasRum && (
                    <View style={styles.mandateTextBlocWrapper}>
                        <Text
                            style={styles.mandateTextStyle}
                            variant="t2"
                            weight="light">
                            {dictionnary.CoordonneesBancairesMandatRUMLabel}
                        </Text>
                        <Text
                            style={styles.mandateTextStyle}
                            variant="t2"
                            weight="regular">
                            {bankDataSelectedPlanType?.debitBankAccountDetails?.rum}
                        </Text>
                    </View>
                )}
                {accountDetailsHasIcs && (
                    <View style={styles.mandateTextBlocWrapper}>
                        <Text
                            style={styles.mandateTextStyle}
                            variant="t2"
                            weight="light">
                            {dictionnary.CoordonneesBancairesMandatICSLabel}
                        </Text>
                        <Text
                            style={styles.mandateTextStyle}
                            variant="t2"
                            weight="regular">
                            {bankDataSelectedPlanType?.debitBankAccountDetails?.ics}
                        </Text>
                    </View>
                )}
            </View>
        );
    }, [bankData, dictionnary, formatMessage, formatDate, styles]);

    const renderMandateModal = useCallback(() => {
        return (
            <MandateModal
                content={mandateModalcontent}
                isMandateModalVisible={isMandateModalVisible}
                onCloseModal={() => setIsMandateModalVisible(false)}
            />
        );
    }, [isMandateModalVisible, mandateModalcontent, selectedRcBankDataIndex]);

    const renderSpinner = useCallback(() => {
        if (!isLoadingBankData) return null;

        return <Spinner />
    }, [isLoadingBankData]);

    const renderReTryAction = useCallback(() => {
        if (isLoadingBankData || !bankDataError?.message) return null;

        return (
            <RetryActionPlaceHolder retryAction={onGetBankData}/>
        );
    }, [
        bankData,
        isLoadingBankData,
        onGetBankData
    ]);

    return (
        <DashboardPageWrapperConnected>
            <ColumnsContainer
                containerStyle={styles.containerStyle}
                hasRenderRightDesktopColumn={false}
                renderHeader={RenderHeader}>
                {renderReTryAction()}
                {renderSpinner()}
                {!isLoadingBankData && !bankDataError?.message && (
                    <>
                        {renderMandateModal()}
                        <View style={styles.containerStyle}>
                            <View style={styles.contentContainerStyle}>
                                {isEsEligible && (
                                    <BankAccountCardES/>
                                )}
                                {isRcEligible && (RcActivateState === "On" || RcActivateState === "Decrochage") &&
                                 <BankAccountCardRC />
                                }
                            </View>
                        </View>
                        <Modal visible={!!modalData}>
                            <BankDetailsCopyIBANModal
                                {...modalData}
                                onClose={() => {
                                    setModalData(undefined);
                                }}
                            />
                        </Modal>
                    </>
                )}
            </ColumnsContainer>
        </DashboardPageWrapperConnected>
    );
};
