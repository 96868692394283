import { useDispatch, useSelector } from "react-redux";
import { clearInstallmentData } from '@modules/installment/actions/installmentActions';
import { getInstallmentRepartitionData } from "@modules/installment/selectors";
import { InstallmentFailedPage as InstallmentFailedPageComponent } from './InstallmentFailedPage.component';

export const InstallmentFailedPage : React.FC = () => {
  const installRepartitionData = useSelector(getInstallmentRepartitionData);
  const dispatch = useDispatch();
  return(
      <InstallmentFailedPageComponent
          clearInstallmentData={() => dispatch(clearInstallmentData())}
          installmentRepartitionData={installRepartitionData} />
  );
};
