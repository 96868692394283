import { connect } from 'react-redux';

import { getSapiendoRedirectUrlRequest } from '@modules/redirect-operation/actions/redirectOperationActions';

import { SapiendoRedirect } from './SapiendoRedirect.component';

const mapDispatchToProps = ({
  onGetSapiendoRedirectUrlRequest: getSapiendoRedirectUrlRequest
});

export const SapiendoRedirectPage = connect(null, mapDispatchToProps)(SapiendoRedirect);

