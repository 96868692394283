import React, { useCallback, useMemo } from 'react'
import { View } from 'react-native'

import { ThresholdDataProps } from '@components/ERE360Components/FiletStepperSimpleCard/types'
import {
  BubbleContainer,
  ColumnsContainer,
  DashboardPageWrapperConnected,
  FiletStepperCardWrapper,
  FiletStepperSimpleCard,
  NotificationHard,
  NotificationIconTypeEnum,
  SimpleButton,
  TotalSubHeader,
  TriggerThresholdComponent,
  useScreenSizes,
  useTranslation
} from '@components/index'
import { ArbitrationTypes } from '@constants/arbitrations'
import { getPlanTypeColors, IconEnum, useTheme } from '@ere-uilib/index'
import { DirectionEnum } from '@ere-uilib/molecules/steppers/InstallmentsStepper/interfaces'
import { ArbitrationHeader } from '@pages/Arbitration/components/ArbitrationHeader/ArbitrationHeader'
import { constructStepperItems } from '@pages/Common/utils'

import { AVAILABILITY_TYPE } from '../components/Support/components/DueDateCard/interface'
import { Props } from './types'
import { useStyles } from './useStyles'
import { locator } from '@constants/locator'

export function ArbitrationSynthesisPage({
  navigation,
  arbitrationType = ArbitrationTypes.stock,
  hideOriginCompartmentHeader,
  amount,
  planType,
  planName,
  sourceCompartment,
  hideOriginManagementRow,
  reallocationDivested,
  hideDestinationManagementRow,
  hideDestinationCompartmentHeader,
  createArbitrationRequest,
  createArbitrationRequestErrorMessage,
  isCreateArbitrationRequestLoading
}: Props) {
  const { formatMessage, formatDate } = useTranslation()
  const { isMobile, isTablet, isDesktop } = useScreenSizes()
  const styles = useStyles()
  const theme = useTheme()
  const isStock = arbitrationType === ArbitrationTypes.stock
  const isFlow = arbitrationType === ArbitrationTypes.flow

  const destinationCards = useMemo(() => {
    if (!reallocationDivested || reallocationDivested.compartments.length === 0) {
      return
    }

    const managements = reallocationDivested.compartments[0]?.managements.filter(_management => {
      const managmentHasPercentage = _management.investedFormValues?.amountPercent
      const supportHasPercentage = _management.supports.some(
        _support => (_support.investedFormValues?.amountPercent ?? 0) > 0
      )
      return !!managmentHasPercentage || supportHasPercentage
    })

    return managements.map(management => {
      const reinvestedSupports = management.isFree
        ? management.supports.filter(
          support =>
            !!support.investedFormValues && support.investedFormValues?.amountPercent != 0
        )
        : management.supports

      const { PartialReallocation } = reallocationDivested.filterProperties ?? {}

      const percent = PartialReallocation
        ? management.supports.find(
          support => support.investedFormValues?.amountPercent != 0 && support.isMaster
        )?.investedFormValues?.amountPercent
        : management.investedFormValues?.amountPercent

      return {
        title: management.label,
        percentage: percent,
        amount: !management.isFree ? (percent ? (amount * percent) / 100 : 0) : undefined,
        isFreeManagement: management.isFree,
        hideManagementRow: hideDestinationManagementRow,
        subCards: reinvestedSupports.map(support => {
          const { amountPercent } = support.investedFormValues || {}
          return {
            title: support.supportName,
            amount: management.isFree
              ? amountPercent
                ? (amount * amountPercent) / 100
                : 0
              : undefined,
            percentage: management.isFree ? amountPercent : undefined,
            onPress: () => { }
          }
        })
      }
    })
  }, [amount, reallocationDivested, hideDestinationManagementRow])

  const getEcheanceValue = useCallback(
    (code?: AVAILABILITY_TYPE, date?: string) => {
      if (!code) return

      switch (code) {
        case AVAILABILITY_TYPE.AVAILABLE:
          return formatMessage({ id: 'MaturityArbitrageFeatureAvailableNow' })
        case AVAILABILITY_TYPE.UNAVAILABLE:
          return formatMessage({
            id: 'MaturityArbitrageFeatureAvailableAtMaturityDate',
            values: {
              date_de_l_echeance: formatDate({ value: date, options: { dateStyle: 'short' } }) ?? ''
            }
          })
        case AVAILABILITY_TYPE.RETIREMENT:
          return formatMessage({ id: 'MaturityArbitrageFeatureAvailableForRetirement' })
      }
    },
    [formatMessage, formatDate]
  );

  const originCards = useMemo(() => {
    if (!sourceCompartment?.managements) {
      return []
    }

    const originManagement = sourceCompartment?.managements?.map(management => {
      const { filterProperties, supports, label, totalAmount, isFree } = management || {}
      let percentage = 0

      const sourceSupports = isFree ? supports?.filter(support => support.amount?.amount) : supports

      const subCards = sourceSupports?.map(support => {
        percentage += support.advancedProperties.PourcentageRepartition ?? 0
        const code = support?.formValues?.dueDateOption?.code
        const date = support?.formValues?.dueDateOption?.date
        const echeanceValue = getEcheanceValue(code, date)
        const triggerThreshold = support?.formValues?.triggerThreshold

        return {
          title: support.supportName,
          amount:
            isFree && filterProperties?.PartialReallocation
              ? -(support.formValues?.amount ?? 0)
              : undefined,
          percentage: -(support.advancedProperties?.PourcentageRepartition ?? 0),
          onPress: () => { },
          echeanceValue,
          triggerThreshold
        }
      })

      return {
        title: label,
        percentage: -percentage,
        amount: -(totalAmount ?? 0),
        hideManagementAmount: isFree && filterProperties?.PartialReallocation,
        hideManagementOnlyAmount: reallocationDivested?.filterProperties?.FlowReallocation,
        hideManagementRow: hideOriginManagementRow,
        subCards
      }
    })
    return originManagement
  }, [hideOriginManagementRow, sourceCompartment, getEcheanceValue])

  const DICTIONARY = useMemo(() => {
    const common = {
      PAGE_TITLE: formatMessage({
        id: 'Arbitrage_etape3'
      })
    }

    if (isStock) {
      return {
        ...common,
        SUB_HEADER_TITLE: formatMessage({
          id: 'VV_Repartition_Total_title'
        }),
        NOTIFICATION: formatMessage({
          id: 'VV_Repartition_InfoArrondi_label'
        }),
        BUTTON_LABEL: formatMessage({
          id: 'VV_Repartition_Valider_button'
        }),
        ORIGIN_TITLE: formatMessage({ id: 'VV_Repartition_De_label' }),
        DESTINATION_TITLE: formatMessage({ id: 'VV_VVP_detail_Vers_description' })
      }
    }

    return {
      ...common,
      SUB_HEADER_TITLE: formatMessage({
        id: 'ArbitrageSyntheseProchainsVersTitre'
      }),
      NOTIFICATION: undefined,
      BUTTON_LABEL: formatMessage({
        id: 'FlowReallocationsValidationScreenButton'
      }),
      ORIGIN_TITLE: formatMessage({ id: 'ArbitrageSynthesePreferenceInvestLabel' }),
      DESTINATION_TITLE: formatMessage({ id: 'ArbitrageSyntheseRemplacerParLabel' })
    }
  }, [formatMessage, isStock])

  const stepperItems = useMemo(
    () =>
      constructStepperItems({
        size: 3,
        direction: DirectionEnum.ROW,
        activeStep: 3
      }),
    []
  )

  const renderSubHeader = useCallback(
    () => (
      <TotalSubHeader
        amount={isStock ? amount : undefined}
        title={DICTIONARY.SUB_HEADER_TITLE}
      />
    ),
    [DICTIONARY, amount, isStock]
  )

  const renderHeader = useCallback(
    () => (
      <>
        <ArbitrationHeader
          displayCloseButton
          helpPoint={false}
          stepperItems={stepperItems}
          title={DICTIONARY.PAGE_TITLE}
        />
        {(isMobile || isTablet) && renderSubHeader()}
      </>
    ),
    [DICTIONARY, isMobile, isTablet, renderSubHeader, stepperItems]
  )

  const renderNotification = useCallback(() => {
    if (!DICTIONARY.NOTIFICATION) {
      return null
    }

    return (
      <NotificationHard
        containerStyle={styles.notificationContainerStyle}
        text={DICTIONARY.NOTIFICATION}
        type={NotificationIconTypeEnum.INFO}
      />
    )
  }, [styles, DICTIONARY])

  const renderRightDesktopColumn = useCallback(
    () => (
      <>
        <BubbleContainer containerStyle={styles.subHeader}>{renderSubHeader()}</BubbleContainer>
        {renderNotification()}
      </>
    ),
    [styles, renderSubHeader, renderNotification]
  )

  const handleOnValidPress = useCallback(() => {
    createArbitrationRequest()
  }, [createArbitrationRequest])

  const renderBottomActions = useCallback(
    () => (
      <View style={styles.bottomActions}>
        {createArbitrationRequestErrorMessage !== '' && (
          <NotificationHard
            containerStyle={styles.errorNotificationContainerStyle}
            text={createArbitrationRequestErrorMessage}
            type={NotificationIconTypeEnum.WARNING}
          />
        )}
        <View style={styles.bottomActionsButtonsContainer}>
          <SimpleButton
            design="outlined"
            leftIcon={IconEnum.CHEVRON_LEFT}
            onPress={navigation.goBack}
          />
          <SimpleButton
            containerStyle={styles.simpleButton}
            design="solid"
            disabled={false}
            loading={isCreateArbitrationRequestLoading}
            onPress={handleOnValidPress}
            size="small"
            title={DICTIONARY.BUTTON_LABEL}
            testId={locator._arbitration._synthese_validate}
          />
        </View>
      </View>
    ),
    [styles, DICTIONARY, navigation, handleOnValidPress, isCreateArbitrationRequestLoading]
  )

  const dispositifColor = useMemo(() => {
    return theme.colors.basics.primary
  }, [theme])

  const renderTriggerThreshold = useCallback((triggerThreshold: ThresholdDataProps) => {
    return (
      <View style={styles.triggerThresholdContainer}>
        <TriggerThresholdComponent
          data={triggerThreshold}
          isEditable={false}
        />
      </View>
    )
  }, [])

  const renderOrigin = useCallback(() => {
    if (!originCards || originCards.length === 0) {
      return null
    }

    return (
      <View style={styles.originRecapStyle}>
        <FiletStepperCardWrapper
          borderLeftColor={dispositifColor?.c500}
          disableLine={false}
          headerResponseCustomContainerStyle={[
            styles.bankAccountHeader,
            {
              backgroundColor: dispositifColor?.c500
            }
          ]}
          headerResponseTitle={
            planName + (!hideOriginCompartmentHeader ? ' - ' + sourceCompartment?.label : '')
          }
          hideHeader={hideOriginCompartmentHeader}
          showHeaderResponseDarkText={!dispositifColor}
          title={DICTIONARY.ORIGIN_TITLE}>
          {originCards?.map((card, i) => {
            return (
              <>
                <FiletStepperSimpleCard
                  isFlowReallocation={isFlow}
                  amount={card.amount}
                  percentage={card.percentage}
                  headerColor={dispositifColor?.c100}
                  hideManagementRow={card.hideManagementRow}
                  hideManagementAmount={card.hideManagementAmount}
                  key={i}
                  subCards={card.subCards}
                  title={card.title}
                  renderTriggerTreshold={renderTriggerThreshold}
                />
              </>
            )
          })}
        </FiletStepperCardWrapper>
      </View>
    )
  }, [
    originCards,
    dispositifColor,
    styles,
    planName,
    hideOriginCompartmentHeader,
    sourceCompartment,
    DICTIONARY
  ])

  const renderDestination = useCallback(() => {
    if (!planName) {
      return null
    }

    return (
      <FiletStepperCardWrapper
        borderLeftColor={dispositifColor?.c500}
        disableLine={true}
        headerResponseCustomContainerStyle={{
          backgroundColor: dispositifColor?.c500
        }}
        headerResponseTitle={
          planName +
          (!hideDestinationCompartmentHeader
            ? ' - ' + reallocationDivested?.compartments[0]?.label
            : '')
        }
        hideHeader={hideDestinationCompartmentHeader}
        title={DICTIONARY.DESTINATION_TITLE}>
        {destinationCards?.map((card, i) => {
          return (
            <FiletStepperSimpleCard
              isFlowReallocation={isFlow}
              amount={card.amount}
              headerColor={dispositifColor?.c100}
              hideManagementRow={card.hideManagementRow}
              hideManagementAmount={card.isFreeManagement}
              key={i}
              percentage={card.percentage}
              subCards={card.subCards}
              title={card.title}
            />
          )
        })}
      </FiletStepperCardWrapper>
    )
  }, [
    planName,
    dispositifColor,
    DICTIONARY,
    destinationCards,
    reallocationDivested?.compartments,
    hideDestinationCompartmentHeader
  ])

  return (
    <DashboardPageWrapperConnected renderStickyMobileBottom={renderBottomActions}>
      <View style={styles.container}>
        <ColumnsContainer
          renderHeader={() => renderHeader()}
          renderRightDesktopColumn={() => renderRightDesktopColumn()}>
          {!isDesktop && renderNotification()}
          <View style={styles.filetStepperContainer}>
            {renderOrigin()}
            {renderDestination()}
          </View>
          {!isTablet && !isMobile && renderBottomActions()}
        </ColumnsContainer>
      </View>
    </DashboardPageWrapperConnected>
  )
}
