import React, { useCallback, useMemo } from 'react';
import { LayoutChangeEvent, Platform } from 'react-native';
import { View } from 'react-native';

import { Text, Title } from '@ere-uilib/atoms';
import { PieChart } from '@ere-uilib/molecules';
import { useScreenSizes } from '@ere-uilib/styles';

import { ChartDataProps } from './interfaces'; 
import { useStyles } from './useStyles';

interface Props {
  chartData: ChartDataProps[];
  onLayout?: (event: LayoutChangeEvent) => void;
}

export const Chart = ({
  chartData,
  onLayout,
}: Props) => {
  const styles = useStyles({});
  const { isMobile, isTablet, isDesktop } = useScreenSizes();

  const chartConfig = useMemo(() => {
    if (Platform.OS !== 'web') {
      return {
        height: 150,
        width: 150,
        labelRadius: 45,
        fontSize: 10
      };
    }

    if (isDesktop) {
      return {
        labelRadius: 120,
        fontSize: 30
      };
    }

    return {
      labelRadius: 140,
      fontSize: 30
    };
  }, [isDesktop]);

  const renderRiskProfile = useCallback(
    (riskProfile: string | undefined, label?: string) => {
      if (!riskProfile) {
        return null;
      }

      return (
        <View style={styles.riskProfilecontainer}>
          <Text
            style={styles.riskProfileLabel}
            variant={isTablet ? 't2' : 't3'}
            weight='light'
          >{label}</Text>
          <Title
            style={styles.riskProfileValue}
            variant={isTablet ? 't6' : 't8'}
            weight="bold">
            {riskProfile}
          </Title>
        </View>
      );
    },
    [isTablet, styles]
  );

  return (
    <View
      onLayout={onLayout}
      style={styles.pieChartTop}
    >
      {chartData.map(item => (
        <View
          key={item.title}
          style={styles.chartCard}>
          <Title
            style={styles.chartTitleStyle}
            variant={isTablet ? 't6' : 't8'}
            weight="bold"
          >
            {item.title}
          </Title>
          <PieChart
            chartData={item.repartitionData}
            hasPlaceholder={item.hasPlaceholder}
            height={chartConfig.height}
            labelFontSize={chartConfig.fontSize}
            labelRadius={chartConfig.labelRadius}
            minSectionLargerPercentageValue={isMobile ? 0.06 : 0.03}
            width={chartConfig.width}
          />
          {renderRiskProfile(item?.srriProfil, item?.profilLabel)}
        </View>
      ))}
    </View>
  )
};