import React from 'react';
import { View } from 'react-native';

import { SavingsHeaderBand, LinkBar } from '@ere-uilib/molecules';
import { useTheme } from '@ere-uilib/styles';
import { createUseStyles, useScreenSizes } from '@ere-uilib/styles';

import { getStyles } from './styles';

interface SavingsHeaderProps {
    innerNavigations: string[]
    selectedTab: string
    onTabChange: (tabId:string)=> void
    addedValue?: number
    titleKey: string
    amount?: number
    swipeLeft: (nav: string)=>void,
    swipeRight: (nav: string)=> void,
    savingPlansLength: number
    selectedPlan: number
}

export const SavingsHeader = ({
  innerNavigations,
  selectedTab,
  onTabChange,
  addedValue,
  titleKey,
  swipeRight,
  swipeLeft,
  amount,
  savingPlansLength,
  selectedPlan,
}:SavingsHeaderProps)=>{

  const MOBILE = useScreenSizes().isMobile;
  const theme = useTheme();
  const styles = useStyles({ theme, isMobile: MOBILE }, {});
  return (
    <View style={styles.container}>
      <SavingsHeaderBand
        addedValue={addedValue}
        amount={amount}
        savingPlansLength={savingPlansLength}
        selectedPlan={selectedPlan}
        swipeLeft={() => swipeLeft(selectedTab)}
        swipeRight={() => swipeRight(selectedTab)}
        titleKey= {titleKey}
      />
      <View>
        <LinkBar
          items={innerNavigations}
          onSelect={onTabChange}
          selectedItem={selectedTab}
          testId='savings'
        />
      </View>
    </View>
  );
};

const useStyles = createUseStyles(getStyles);
