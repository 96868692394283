import { connect } from 'react-redux';

import { logoutCallbackSuccess } from '@modules/auth/actions/authActions';
import { AppState } from '@modules/reducers';
import {
  getAuthConfig,
  getApplicationSettings,
} from '@modules/settings/selectors';

import { SelfcareLetterLandingPage as SelfcareLetterLandingPageComponent } from './SelfcareLetterLandingPage.component';

const mapStateToProps = (state: AppState) => {
  return {
    authConfig: getAuthConfig(state),
    applicationSettings: getApplicationSettings(state),
  };
};

const mapDispatchToProps = {
  logoutCallbackSuccess: logoutCallbackSuccess,
};

export const SelfcareLetterLandingPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelfcareLetterLandingPageComponent);

