import React from "react";
import { StyleProp, TextStyle, View, ViewStyle } from "react-native";

import { Paragraph, StaticProgressBar, SVGLocalLoader, Text, useTheme, useTranslation } from "@components/index";
import { OadProfileRiskEnum } from "@modules/arbitration/constants";

import { useStyles } from './useStyles';

type OadRiskComponentStyles = {
  containerStyle?: StyleProp<ViewStyle>;
  svgContainer?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  bottomContainer?: StyleProp<ViewStyle>;
  bottomTitle?: StyleProp<TextStyle>;
};

interface DataProps {
  text?: string
  icon?: string
  progress?: number
}

type Props = DataProps & OadRiskComponentStyles

export function OadRiskComponent({
  text,
  icon,
  progress,
}: Props) {
  const { formatMessage } = useTranslation();
  const theme = useTheme();
  const styles = useStyles();

  let progressBarColor;
  switch (progress) {
    case OadProfileRiskEnum.SAFE:
      progressBarColor = theme.colors.oadProfileRisk.safe;
      break;
    case OadProfileRiskEnum.BALANCED:
      progressBarColor = theme.colors.oadProfileRisk.balanced;
      break;
    case OadProfileRiskEnum.DYNAMIC:
    default:
      progressBarColor = theme.colors.oadProfileRisk.dynamic;
      break;
  }

  return (
    <View style={styles.containerWrapper}>
      <View style={{ "flexDirection": 'row' }}>
        <View style={styles.svgContainer}>
          <SVGLocalLoader
            maxHeight={theme.metrics.iconSizes.xxxl}
            maxWidth={"100%"}
            name={icon || ""}
          />
        </View>
        <Paragraph
          style={styles.textStyle}
          variant="t2"
          weight="light"
        >
          {text}
        </Paragraph>
      </View>
      <View style={styles.bottomContainer}>
        <Text
          style={styles.bottomTitle}
          variant="t3"
          weight="bold">
          {formatMessage({ id: 'AbritrageOADSelectionDispositifQuestion5Risque' })}
        </Text>
        <View style={{ flex: 1 }}>
          <StaticProgressBar
            progress={progress || 0}
            progressBarColor={progressBarColor}
          />
        </View>
      </View>
    </View>
  );
}

export default OadRiskComponent;
