import {
  RetirementDecision,
  RetirementErrorState,
  RetirementDecisionSubmitForm,
  RetirementPlanDocumentType
} from '@modules/retirement/types';

export enum RetirementActionsType {
  GET_RETIREMENT_DECISION_REQUEST = 'GET_RETIREMENT_DECISION_REQUEST',
  GET_RETIREMENT_DECISION_SUCCESS = 'GET_RETIREMENT_DECISION_SUCCESS',
  GET_RETIREMENT_DECISION_FAILURE = 'GET_RETIREMENT_DECISION_FAILURE',
  POST_RETIREMENT_DECISION_REQUEST = 'POST_RETIREMENT_DECISION_REQUEST',
  POST_RETIREMENT_DECISION_SUCCESS = 'POST_RETIREMENT_DECISION_SUCCESS',
  POST_RETIREMENT_DECISION_FAILURE = 'POST_RETIREMENT_DECISION_FAILURE',
  CLEAR_RETIREMENT_DATA = 'CLEAR_RETIREMENT_DATA',
}

export interface GetRetirementDecisionRequestAction {
  type: RetirementActionsType.GET_RETIREMENT_DECISION_REQUEST;
}

export interface GetRetirementDecisionSuccessAction {
  type: RetirementActionsType.GET_RETIREMENT_DECISION_SUCCESS;
  decision: RetirementDecision[];
}

export interface GetRetirementDecisionFailureAction {
  type: RetirementActionsType.GET_RETIREMENT_DECISION_FAILURE;
  error: RetirementErrorState;
}

export interface PostRetirementDecisionRequestAction {
  type: RetirementActionsType.POST_RETIREMENT_DECISION_REQUEST;
  formData: RetirementDecisionSubmitForm[];
}

export interface PostRetirementDecisionSuccessAction {
  type: RetirementActionsType.POST_RETIREMENT_DECISION_SUCCESS;
  formDecision: RetirementPlanDocumentType[]
}

export interface PostRetirementDecisionFailureAction {
  type: RetirementActionsType.POST_RETIREMENT_DECISION_FAILURE;
  error: RetirementErrorState;
}
export interface ClearRetirementDataAction {
  type: RetirementActionsType.CLEAR_RETIREMENT_DATA;
}

export type RetirementAction =
  | GetRetirementDecisionRequestAction
  | GetRetirementDecisionSuccessAction
  | GetRetirementDecisionFailureAction
  | PostRetirementDecisionRequestAction
  | PostRetirementDecisionSuccessAction
  | PostRetirementDecisionFailureAction
  | ClearRetirementDataAction
