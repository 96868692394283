import axios from 'axios';

import { API_URL } from '@config/index';
import { CampaignValueState } from '@modules/campaign/types';
import { DashboardState } from '@modules/dashboard/types';

export async function fetchUserCampaign(campaignID: string): Promise<DashboardState> {
  return axios.get(`${API_URL}/api/v1/campaigns/${campaignID}`);
}

export async function postUserCampaign(campaignID: string, value:CampaignValueState): Promise<DashboardState> {
  return axios.post(`${API_URL}/api/v1/consents/accept`, {
    code: "Campaign_"+campaignID,
    value: value
  });
}