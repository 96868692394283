import React, { useEffect, useState } from 'react';

import { AmountFieldModal360 } from '@components/index';
import { useTranslation } from '@ere-uilib/translations';

import {
  ArbitrationPercentagedModalConnectedProps, ModalData
} from './interfaces';
import { ArbitrationPercentageFieldModalConnectedRefType } from './sharedInterfaces';

export const ArbitrationPercentFieldModalConnectedComponent = React.forwardRef<
  ArbitrationPercentageFieldModalConnectedRefType,
  ArbitrationPercentagedModalConnectedProps
>(
  (
    {
      visible,
      onSetArbitrationResinvestPerentageModalVisibleState,
      onSelectInvestedSupport,
      onSelectInvestedManagement,
    },
    ref
  ) => {
    const defaultData: ModalData = {
      displayData: {
        title: '',
        initialAmount: undefined,
      },
      supportIsIn: undefined,
    };
    const [data, setData] = useState<ModalData>(defaultData);
    const { formatMessage } = useTranslation();
    // allow method via ref
    React.useImperativeHandle(ref, () => ({
      openWithData: receivedData => {
        onSetArbitrationResinvestPerentageModalVisibleState(true);

        setData({
          ...receivedData,
          displayData: {
            allAmountLabel: formatMessage({
              id: 'Arbitrage_modaleDesinvest_ToutReinvest',
            }),
            ...receivedData.displayData,
          },
        });
      },
    }));

    useEffect(() => {
      if (!visible) {
        setData(defaultData);
      }
    }, [visible]);

    const onCancelPressed = () => {
      onSetArbitrationResinvestPerentageModalVisibleState(false);
      setData(defaultData);
    };

    const onValidPressed = (percent?: number | null) => {
      if (data.compartmentId && data.managementId) {
        if (data.supportIsIn) {
          onSelectInvestedSupport({
            amountPercent: percent || 0,
            compartmentId: data.compartmentId,
            managementId: data.managementId,
            supportIsin: data.supportIsIn,
          });
        } else {
          onSelectInvestedManagement({
            amountPercent: percent || 0,
            compartmentId: data.compartmentId,
            managementId: data.managementId,
          });
        }

        onSetArbitrationResinvestPerentageModalVisibleState(false);
      }
    };
    return (
      <AmountFieldModal360
        data={data.displayData}
        isAmountPercentage
        isValidButtonLoading={false}
        onCancel={onCancelPressed}
        onValid={onValidPressed}
        visible={visible}
      />
    );
  }
);
