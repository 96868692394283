import { connect } from 'react-redux';

import { backOfficeImplicitConnectionRequest } from '@modules/dashboard/actions/dashboardActions';

import { BackOfficeAccessPage } from './BackOfficeAccessPage.component';

const mapDispatchToProps  = ({
  onSubmit: backOfficeImplicitConnectionRequest
});

export const BackOfficeAccessPageComponent = connect(null, mapDispatchToProps)(BackOfficeAccessPage);
