import { FranceConnectRegistrationBody, IdentityValidationErrorState } from '../types';
import { InitAllErrorsAction } from '@modules/error/actions/errorActionsTypes';

export enum ValidateIdentityActionsType {
  SEND_EER_IDENTITY_VALIDATION_CODE_REQUEST = 'SEND_EER_IDENTITY_VALIDATION_CODE_REQUEST',
  SEND_EER_IDENTITY_VALIDATION_CODE_SUCCESS = 'SEND_EER_IDENTITY_VALIDATION_CODE_SUCCESS',
  SEND_EER_IDENTITY_VALIDATION_CODE_FAILURE = 'SEND_EER_IDENTITY_VALIDATION_CODE_FAILURE',
  CHECK_EER_IDENTITY_VALIDATION_CODE_REQUEST = 'CHECK_EER_IDENTITY_VALIDATION_CODE_REQUEST',
  CHECK_EER_IDENTITY_VALIDATION_CODE_SUCCESS = 'CHECK_EER_IDENTITY_VALIDATION_CODE_SUCCESS',
  CHECK_EER_IDENTITY_VALIDATION_CODE_FAILURE = 'CHECK_EER_IDENTITY_VALIDATION_CODE_FAILURE',
  CHECK_EER_SECOND_LETTER_IDENTITY_VALIDATION_CODE_REQUEST = 'CHECK_EER_SECOND_LETTER_IDENTITY_VALIDATION_CODE_REQUEST',
  CHECK_EER_SECOND_LETTER_IDENTITY_VALIDATION_CODE_SUCCESS = 'CHECK_EER_SECOND_LETTER_IDENTITY_VALIDATION_CODE_SUCCESS',
  CHECK_EER_SECOND_LETTER_IDENTITY_VALIDATION_CODE_FAILURE = 'CHECK_EER_SECOND_LETTER_IDENTITY_VALIDATION_CODE_FAILURE',
  VALIDATE_EER_EMAIL_NEW_IDENTIFIER_REQUEST = 'VALIDATE_EER_EMAIL_NEW_IDENTIFIER_REQUEST',
  VALIDATE_EER_EMAIL_NEW_IDENTIFIER_SUCCESS = 'VALIDATE_EER_EMAIL_NEW_IDENTIFIER_SUCCESS',
  VALIDATE_EER_EMAIL_NEW_IDENTIFIER_FAILURE = 'VALIDATE_EER_EMAIL_NEW_IDENTIFIER_FAILURE',
  VALIDATE_EER_EMAIL_NEW_IDENTIFIER_CODE_REQUEST = 'VALIDATE_EER_EMAIL_NEW_IDENTIFIER_CODE_REQUEST',
  VALIDATE_EER_EMAIL_NEW_IDENTIFIER_CODE_SUCCESS = 'VALIDATE_EER_EMAIL_NEW_IDENTIFIER_CODE_SUCCESS',
  VALIDATE_EER_EMAIL_NEW_IDENTIFIER_CODE_FAILURE = 'VALIDATE_EER_EMAIL_NEW_IDENTIFIER_CODE_FAILURE',
  VALIDATE_EER_PHONE_NEW_IDENTIFIER_REQUEST = 'VALIDATE_EER_PHONE_NEW_IDENTIFIER_REQUEST',
  VALIDATE_EER_PHONE_NEW_IDENTIFIER_SUCCESS = 'VALIDATE_EER_PHONE_NEW_IDENTIFIER_SUCCESS',
  VALIDATE_EER_PHONE_NEW_IDENTIFIER_FAILURE = 'VALIDATE_EER_PHONE_NEW_IDENTIFIER_FAILURE',
  VALIDATE_EER_PHONE_NEW_IDENTIFIER_CODE_REQUEST = 'VALIDATE_EER_PHONE_NEW_IDENTIFIER_CODE_REQUEST',
  VALIDATE_EER_PHONE_NEW_IDENTIFIER_CODE_SUCCESS = 'VALIDATE_EER_PHONE_NEW_IDENTIFIER_CODE_SUCCESS',
  VALIDATE_EER_PHONE_NEW_IDENTIFIER_CODE_FAILURE = 'VALIDATE_EER_PHONE_NEW_IDENTIFIER_CODE_FAILURE',
  CONFIRIM_REGISTRATION_REQUEST = 'CONFIRIM_REGISTRATION_REQUEST',
  CONFIRIM_REGISTRATION_SUCCESS = 'CONFIRIM_REGISTRATION_SUCCESS',
  CONFIRIM_REGISTRATION_FAILURE = 'CONFIRIM_REGISTRATION_FAILURE',
  INIT_ERROR = 'INIT_ERROR',
  SET_EER_NEW_EMAIL_INPUT = 'SET_EER_NEW_EMAIL_INPUT',
  SET_EER_NEW_PHONE_INPUT = 'SET_EER_NEW_PHONE_INPUT',
  FRANCE_CONNECT_CREATE_IDENTIY_VALIDATION_REQUEST = 'FRANCE_CONNECT_CREATE_IDENTIY_VALIDATION_REQUEST',
  FRANCE_CONNECT_CREATE_IDENTIY_VALIDATION_SUCCESS = 'FRANCE_CONNECT_CREATE_IDENTIY_VALIDATION_SUCCESS',
  FRANCE_CONNECT_CREATE_IDENTIY_VALIDATION_FAILURE = 'FRANCE_CONNECT_CREATE_IDENTIY_VALIDATION_FAILURE',
  FRANCE_CONNECT_CONFIRM_IDENTITY_REQUEST = 'FRANCE_CONNECT_CONFIRM_IDENTITY_REQUEST',
  FRANCE_CONNECT_CONFIRM_IDENTITY_SUCCESS = 'FRANCE_CONNECT_CONFIRM_IDENTITY_SUCCESS',
  FRANCE_CONNECT_CONFIRM_IDENTITY_FAILURE = 'FRANCE_CONNECT_CONFIRM_IDENTITY_FAILURE'
}

//******* SEND code for  EER identity validation/

export interface SendEERIdentityValidationCodeRequestAction {
  type: ValidateIdentityActionsType.SEND_EER_IDENTITY_VALIDATION_CODE_REQUEST,
  isResend?: boolean
}

export interface SendEERIdentityValidationCodeSuccessAction {
  type: ValidateIdentityActionsType.SEND_EER_IDENTITY_VALIDATION_CODE_SUCCESS;
}

export interface SendEERIdentityValidationCodeFailureAction {
  type: ValidateIdentityActionsType.SEND_EER_IDENTITY_VALIDATION_CODE_FAILURE;
  error: IdentityValidationErrorState;
}

//******* CHECK code for  EER identity validation/
export interface CheckEERIdentityValidationCodeRequestAction {
  type: ValidateIdentityActionsType.CHECK_EER_IDENTITY_VALIDATION_CODE_REQUEST,
  OTPCode: string;
}

export interface CheckEERIdentityValidationCodeSuccessAction {
  type: ValidateIdentityActionsType.CHECK_EER_IDENTITY_VALIDATION_CODE_SUCCESS;
}

export interface CheckEERIdentityValidationCodeFailureAction {
  type: ValidateIdentityActionsType.CHECK_EER_IDENTITY_VALIDATION_CODE_FAILURE;
  error: IdentityValidationErrorState;
}

//******* CHECK second letter validation identity code/
export interface CheckEERSecondLetterIdentityValidationCodeRequestAction {
  type: ValidateIdentityActionsType.CHECK_EER_SECOND_LETTER_IDENTITY_VALIDATION_CODE_REQUEST,
  OTPCode: string;
}

export interface CheckEERSecondLetterIdentityValidationCodeSuccessAction {
  type: ValidateIdentityActionsType.CHECK_EER_SECOND_LETTER_IDENTITY_VALIDATION_CODE_SUCCESS;
}

export interface CheckEERSecondLetterIdentityValidationCodeFailureAction {
  type: ValidateIdentityActionsType.CHECK_EER_SECOND_LETTER_IDENTITY_VALIDATION_CODE_FAILURE;
  error: IdentityValidationErrorState;
}

//******* VALIDATE_EER_EMAIL_NEW_IDENTIFIER /

export interface ValidateEEREmailNewIdentifierRequestAction {
  type: ValidateIdentityActionsType.VALIDATE_EER_EMAIL_NEW_IDENTIFIER_REQUEST
  email: string
}

export interface ValidateEEREmailNewIdentifierSuccessAction {
  type: ValidateIdentityActionsType.VALIDATE_EER_EMAIL_NEW_IDENTIFIER_SUCCESS;
}

export interface ValidateEEREmailNewIdentifierFailureAction {
  type: ValidateIdentityActionsType.VALIDATE_EER_EMAIL_NEW_IDENTIFIER_FAILURE;
  error: IdentityValidationErrorState;
}

//************ VALIDATE_EER_EMAIL_NEW_IDENTIFIER_CODE */

export interface ValidateEEREmailNewIdentifierCodeRequestAction {
  type: ValidateIdentityActionsType.VALIDATE_EER_EMAIL_NEW_IDENTIFIER_CODE_REQUEST
  emailCode: string
}

export interface ValidateEEREmailNewIdentifierCodeSuccessAction {
  type: ValidateIdentityActionsType.VALIDATE_EER_EMAIL_NEW_IDENTIFIER_CODE_SUCCESS;
}

export interface ValidateEEREmailNewIdentifierCodeFailureAction {
  type: ValidateIdentityActionsType.VALIDATE_EER_EMAIL_NEW_IDENTIFIER_CODE_FAILURE;
  error: IdentityValidationErrorState;
}

//******* VALIDATE_EER_PHONE_NEW_IDENTIFIER /

export interface ValidateEERPhoneNewIdentifierRequestAction {
  type: ValidateIdentityActionsType.VALIDATE_EER_PHONE_NEW_IDENTIFIER_REQUEST
  phone: string
}

export interface ValidateEERPhoneNewIdentifierSuccessAction {
  type: ValidateIdentityActionsType.VALIDATE_EER_PHONE_NEW_IDENTIFIER_SUCCESS;
}

export interface ValidateEERPhoneNewIdentifierFailureAction {
  type: ValidateIdentityActionsType.VALIDATE_EER_PHONE_NEW_IDENTIFIER_FAILURE;
  error: IdentityValidationErrorState;
}

//************ VALIDATE_EER_PHONE_NEW_IDENTIFIER_CODE */

export interface ValidateEERPhoneNewIdentifierCodeRequestAction {
  type: ValidateIdentityActionsType.VALIDATE_EER_PHONE_NEW_IDENTIFIER_CODE_REQUEST
  phoneCode: string
}

export interface ValidateEERPhoneNewIdentifierCodeSuccessAction {
  type: ValidateIdentityActionsType.VALIDATE_EER_PHONE_NEW_IDENTIFIER_CODE_SUCCESS;
}

export interface ValidateEERPhoneNewIdentifierCodeFailureAction {
  type: ValidateIdentityActionsType.VALIDATE_EER_PHONE_NEW_IDENTIFIER_CODE_FAILURE;
  error: IdentityValidationErrorState;
}

// CONFIRM REGISTRATION REQUEST

export interface ConfirmRegistrationRequestAction {
  type: ValidateIdentityActionsType.CONFIRIM_REGISTRATION_REQUEST
  password: string
}

export interface ConfirmRegistrationSuccessAction {
  type: ValidateIdentityActionsType.CONFIRIM_REGISTRATION_SUCCESS;
  data: any;
}

export interface ConfirmRegistrationFailureAction {
  type: ValidateIdentityActionsType.CONFIRIM_REGISTRATION_FAILURE;
  error: IdentityValidationErrorState;
}

// INIT ERROR

export interface InitErrorAction {
  type: ValidateIdentityActionsType.INIT_ERROR;
}

export interface SetEERNewEmailInputAction {
  type: ValidateIdentityActionsType.SET_EER_NEW_EMAIL_INPUT
  email: string
}

export interface SetEERNewPhoneInputAction {
  type: ValidateIdentityActionsType.SET_EER_NEW_PHONE_INPUT
  phone: string
}

// **** FRANCE CONNECT ****

export interface FranceConnectCreateIdentityValidationRequestAction {
  type: ValidateIdentityActionsType.FRANCE_CONNECT_CREATE_IDENTIY_VALIDATION_REQUEST
}

export interface FranceConnectCreateIdentityValidationSuccessAction {
  type: ValidateIdentityActionsType.FRANCE_CONNECT_CREATE_IDENTIY_VALIDATION_SUCCESS
  data: FranceConnectRegistrationBody
}

export interface FranceConnectCreateIdentityValidationFailureAction {
  type: ValidateIdentityActionsType.FRANCE_CONNECT_CREATE_IDENTIY_VALIDATION_FAILURE;
  error: IdentityValidationErrorState;
}

/***  FRANCE CONNECT CONFIRM IDENTITY ***/

export interface FranceConnectConfirmIdentityRequestAction {
  type: ValidateIdentityActionsType.FRANCE_CONNECT_CONFIRM_IDENTITY_REQUEST
  code: string
}


export interface FranceConnectConfirmIdentitySuccessAction {
  type: ValidateIdentityActionsType.FRANCE_CONNECT_CONFIRM_IDENTITY_SUCCESS
}


export interface FranceConnectConfirmIdentityFailureAction {
  type: ValidateIdentityActionsType.FRANCE_CONNECT_CONFIRM_IDENTITY_FAILURE
  error: IdentityValidationErrorState
}

export type ValidateIdentityAction =
  | SendEERIdentityValidationCodeRequestAction
  | SendEERIdentityValidationCodeSuccessAction
  | SendEERIdentityValidationCodeFailureAction
  | CheckEERIdentityValidationCodeRequestAction
  | CheckEERIdentityValidationCodeSuccessAction
  | CheckEERIdentityValidationCodeFailureAction
  | CheckEERSecondLetterIdentityValidationCodeRequestAction
  | CheckEERSecondLetterIdentityValidationCodeSuccessAction
  | CheckEERSecondLetterIdentityValidationCodeFailureAction
  | ValidateEEREmailNewIdentifierRequestAction
  | ValidateEEREmailNewIdentifierSuccessAction
  | ValidateEEREmailNewIdentifierFailureAction
  | ValidateEEREmailNewIdentifierCodeRequestAction
  | ValidateEEREmailNewIdentifierCodeSuccessAction
  | ValidateEEREmailNewIdentifierCodeFailureAction
  | ValidateEERPhoneNewIdentifierRequestAction
  | ValidateEERPhoneNewIdentifierSuccessAction
  | ValidateEERPhoneNewIdentifierFailureAction
  | ValidateEERPhoneNewIdentifierCodeRequestAction
  | ValidateEERPhoneNewIdentifierCodeSuccessAction
  | ValidateEERPhoneNewIdentifierCodeFailureAction
  | InitErrorAction
  | ConfirmRegistrationRequestAction
  | ConfirmRegistrationSuccessAction
  | ConfirmRegistrationFailureAction
  | SetEERNewEmailInputAction
  | SetEERNewPhoneInputAction
  | InitAllErrorsAction
  | FranceConnectCreateIdentityValidationRequestAction
  | FranceConnectCreateIdentityValidationSuccessAction
  | FranceConnectCreateIdentityValidationFailureAction
  | FranceConnectConfirmIdentityRequestAction
  | FranceConnectConfirmIdentitySuccessAction
  | FranceConnectConfirmIdentityFailureAction
