import React from 'react';
import { View, StyleProp, ViewStyle, TextStyle } from 'react-native';

import { SVGLocalLoader, Text } from '@ere-uilib/atoms';
import { createUseStyles, useTheme, ThemeType } from '@ere-uilib/styles';

interface Props {
  imageContainerStyle?: StyleProp<ViewStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  textContainerStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  isModalVisible?: boolean;
  onPress?: () => void;
}

type WorkInProgressStyles = {
  imageContainerStyle?: StyleProp<ViewStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  textContainerStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
};

type StylesContext = {theme:ThemeType};

export const WorkInProgressContent: React.FC<React.PropsWithChildren<Props>> = ({
  containerStyle,
  imageContainerStyle,
  textStyle
}) => {
  const theme = useTheme();
  const styles = useStyles(
    { theme },
    { containerStyle, imageContainerStyle, textStyle },
  );

  return (
    <View style={styles.containerStyle}>
      <View style={styles.imageContainerStyle}>
        <SVGLocalLoader
height={130}
name="waitingProcess"
width={176} />
      </View>
      <View style={styles.textContainerStyle}>
        <Text style={styles.textStyle}>Encore un peu de patience !</Text>
        <Text style={styles.textStyle}>
          Certaines fonctionnalités sont encore en cours de developpement.
        </Text>
      </View>
    </View>
  );
};

const getStyles = (
  context?: StylesContext,
  style?: WorkInProgressStyles,
): WorkInProgressStyles => ({
  containerStyle: [
    {
      alignItems: 'center',
      justifyContent: 'center'
    },
    style?.containerStyle
  ],
  imageContainerStyle: [
    {
      marginBottom: context?.theme.metrics.spacing.l,
      alignItems: 'center',
      width: 176,
      height: 130
    },
    style?.imageContainerStyle
  ],
  textContainerStyle: [
    {
      width: '80%',
      marginBottom: context?.theme.metrics.spacing.xm
    },
    style?.imageContainerStyle
  ],
  textStyle: [
    {
      textAlign: 'center'
    },
    style?.textStyle
  ]
});

const useStyles = createUseStyles(getStyles);
