import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useScreenSizes, useTheme } from '@ere-uilib/styles';
export const useStyles = (defaultInterestAmount: number | null | undefined) => {
  const theme = useTheme();
  const { isDesktop } = useScreenSizes();
  const styles = useMemo(
    () =>
      StyleSheet.create({
        filetContainer: {
          flex: 1,
          marginRight: theme.metrics.spacing.xm
        },
        fromContent: {
          marginLeft: theme.metrics.spacing.xm,
          width: '100%',
          marginTop: isDesktop ? theme.metrics.spacing.m : 0
        },
        filetText: {
          marginBottom: theme.metrics.spacing.xm
        },
        filetSubContainer: {
          marginTop: isDesktop ? theme.metrics.spacing.l : theme.metrics.spacing.m
        },
        interestType: {
          borderTopWidth: 0,
          paddingBottom: theme.metrics.spacing.xs,
          paddingTop: 0,
          borderTopColor: theme.colors.basics.grey.c200
        },
        ineterstAmount: {
          borderTopWidth: 0,
          paddingBottom: 0,
          paddingTop: theme.metrics.spacing.xs,
          borderTopColor: theme.colors.basics.grey.c200
        },
        interestDetailsContainer: {
          paddingTop: theme.metrics.spacing.xs,
          paddingBottom: theme.metrics.spacing.xs
        },
        interestCardHeader: {
          flexDirection: 'column',
          justifyContent: 'center',
          backgroundColor: theme.colors.basics.primary.c500,
          alignItems: 'center',
          minHeight: 45,
          paddingHorizontal: theme.metrics.spacing.s,
          paddingVertical: theme.metrics.spacing.s,
          borderTopRightRadius: theme.metrics.borderRadius.xs,
          borderBottomRightRadius: !!defaultInterestAmount ? 0 : theme.metrics.borderRadius.xs,
          borderBottomLeftRadius: 0,
          overflow: 'hidden',
          width: '100%'
        },
        interestCardHeaderAmountCard: {
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%'
        },
        interestCardRow: { color: theme.colors.basics.white },
        interestCardHeaderCompanyContainer: {
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          width: '100%',
          marginTop: 0
        },
        rightText: {
          textAlign: 'right'
        },
        leftText: {
          textAlign: 'left'
        }
      }),
    [theme, isDesktop, defaultInterestAmount]
  );

  return styles;
};
