import React, { FC } from "react";

import { useTranslation } from "@components/ERE-UILib";
import { OperationTotalSubHeader } from "@ere-uilib/molecules";
import {
    useProfitSharringRepartitionContext
} from "@pages/ProfitSharingIncentive/ProfitSharingIncentiveRepartition/context";

export const SubHeader: FC = () => {
    const { isPercentage, remainingInvestedPercent, remainingInvestedAmount, hasStartedInvest, setDisplayTypeValue, displayTypeValue } = useProfitSharringRepartitionContext();
    const { formatMessage } = useTranslation();
    return (
        <OperationTotalSubHeader
            defaultSelectSwitch={displayTypeValue}
            isAmountPercent={isPercentage}
            isSwitchEnabled={true}
            mainAmount={isPercentage ?
                remainingInvestedPercent
                : remainingInvestedAmount}
            mainTitle={hasStartedInvest ?
                formatMessage({ id: 'PIChoixInvestInvestProjetDejaInvestiLabel' })
                : formatMessage({ id: 'PIChoixInvestMontantRepartirLabel' })}
            onSelectSwitchChanged={setDisplayTypeValue}
            secondaryAmount={isPercentage ?
                remainingInvestedAmount
                : remainingInvestedPercent}
            secondaryTitle={formatMessage({ id: 'PIChoixInvestInvestProjetDejaInvestiSoitLabel' })}
        />
    );
}
