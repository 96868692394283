import { useNavigation } from '@react-navigation/native';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useTranslation } from '@components/ERE-UILib';
import { RouteNames } from '@constants/navigation';
import { InputMode } from '@modules/common/constants';
import { getCountries } from '@modules/profile/selectors';
import { CountryItem } from '@modules/profile/types';

import { FormCountry, FormValues } from '../types';
import { useData as useCommonData } from '../useData';
import { sortCountries } from '../utils';

export const useData = () => {
  const navigation = useNavigation();
  const { formatMessage } = useTranslation();

  const _countries = useSelector(getCountries);

  const { inputMode = InputMode.TSA, onUpdateUserAddress, ...otherCommonData } = useCommonData();

  const stepper = useMemo(
    () => ({
      size: inputMode === InputMode.DATA ? 1 : 2,
      current: 1
    }),
    [inputMode]
  );

  const formatCountry = useCallback(
    (country: CountryItem): FormCountry => ({
      value: country.twoDigitsCode,
      label: formatMessage({ id: country.code, defaultMessage: country.code }),
      defaultLabel: country.label.toUpperCase()
    }),
    [formatMessage]
  );

  const countries = useMemo<FormCountry[]>(
    () => sortCountries((_countries || []).map(formatCountry)),
    [_countries, formatCountry]
  );

  const goToNexPage = useCallback(() => {
    navigation.navigate(RouteNames.ProfileEditAddress, {
      screen: RouteNames.ProfileEditAddressDocument
    });
  }, [navigation]);

  const onSubmit = useCallback(
    (values: FormValues) => {
      if (inputMode === InputMode.DATA) onUpdateUserAddress(values, []);
      else goToNexPage();
    },
    [goToNexPage, inputMode, onUpdateUserAddress]
  );

  return {
    stepper,
    countries,
    inputMode,
    onSubmit,
    ...otherCommonData
  };
};
