import {
  FilterPropertiesType,
} from '@modules/common/types';

import { 
  ArbitrationState
} from '../types';

export const getDecisionAllResponses = (arbitrationDecisionState: ArbitrationState['decision']):FilterPropertiesType=>{
  let allResponses = {};
  const questions = arbitrationDecisionState.questions;
  questions.forEach(question => {
    const respondedPropertyFilter = question?.responded?.respondedPropertyFilter;
    if (!respondedPropertyFilter) {
      return;
    }

    const isPropertyFilter = respondedPropertyFilter?.name
    && respondedPropertyFilter.value;

    const isOption = question?.responded?.selectedOption?.name
    && question?.responded?.selectedOption?.value;

    const propertyFilter =  isPropertyFilter ? {
      [respondedPropertyFilter.name]:
      respondedPropertyFilter.value,
    } : {};

    const option =  isOption && question?.responded?.selectedOption ? {
      [question.responded?.selectedOption?.name]: question.responded?.selectedOption?.value,
    } : {};

    allResponses = {
      ...allResponses,
      ...propertyFilter,
      ...option,
    };
  });

  return allResponses;
};
