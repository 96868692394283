export enum SelfcareNavigatorRouteNames {
  PersoneoIdentifierForm = 'PersoneoIdentifierForm',
  ActivateAccountModeSelection = 'ActivateAccountModeSelection',
  SelfcarePersoneoSuccess = 'SelfcarePersoneoSuccess',
  SelfcareVisiogoSuccess = 'SelfcareVisiogoSuccess',
  LetterIdentifierForm = 'LetterIdentifierForm',
  SelfcareLetterSuccess = 'SelfcareLetterSuccess',
  VisiogoIdentifierForm = 'VisiogoIdentifierForm',
}

export type SelfcareNavigatorInterface = {
  [SelfcareNavigatorRouteNames.PersoneoIdentifierForm]: undefined;
  [SelfcareNavigatorRouteNames.ActivateAccountModeSelection]: undefined;
  [SelfcareNavigatorRouteNames.SelfcarePersoneoSuccess]: undefined;
  [SelfcareNavigatorRouteNames.SelfcareVisiogoSuccess]: undefined;
  [SelfcareNavigatorRouteNames.LetterIdentifierForm]: undefined;
  [SelfcareNavigatorRouteNames.SelfcareLetterSuccess]: undefined;
  [SelfcareNavigatorRouteNames.VisiogoIdentifierForm]: undefined;
}
