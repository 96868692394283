import {
  all,
  call,
  fork,
  put,
  select,
} from '@redux-saga/core/effects';
import { takeLatest } from 'redux-saga/effects';

import { getUsedCompanyId } from '@modules/dashboard/selectors';
import { runManager } from '@modules/moduleManager';

import { getControlDataSuccess, getControlDataEditingOptionsSuccess, postDrivingDataSuccess } from './actions/controlDataActions';
import {
  ControlDataActionsType, GetControlDataEditingOptionsRequestAction, PostControlDataEditingOptionsRequestAction,
} from './actions/controlDataActionsTypes';
import { fetchControlData, fetchControlDataEditingOptions, postControlDataEditedOptions } from './services';

function* getControlData(): any {
  const companyId: string = yield select(getUsedCompanyId);
  const response = yield call(fetchControlData, companyId);
  yield put(getControlDataSuccess(response.data));
}

// update pilotage data
function* getUpdatePilotageData(
  action: GetControlDataEditingOptionsRequestAction
): any {
  const companyId: string = yield select(getUsedCompanyId);
  const response = yield call(fetchControlDataEditingOptions, companyId, action.planId);
  yield put(getControlDataEditingOptionsSuccess(response.data));
}


function* postDrivingData(payload: PostControlDataEditingOptionsRequestAction): any {
  const companyId: string = yield select(getUsedCompanyId);
  const response = yield call(postControlDataEditedOptions, companyId, payload.planId, payload.editedDrivingData);
  yield put(postDrivingDataSuccess(true));
}

function* postDrivingDataSaga(): any {
  yield takeLatest(ControlDataActionsType.POST_CONTROL_DATA_EDITING_OPTIONS_REQUEST,
    runManager(postDrivingData, ControlDataActionsType.POST_CONTROL_DATA_EDITING_OPTIONS_FAILURE)
  );
}
function* getControlDataSagas(): any {
  yield takeLatest(
    ControlDataActionsType.GET_CONTROL_DATA_REQUEST,
    runManager(getControlData, ControlDataActionsType.GET_CONTROL_DATA_FAILURE)
  );
}
function* getUpdatePilotageDataSagas(): any {
  yield takeLatest(
    ControlDataActionsType.GET_CONTROL_DATA_EDITING_OPTIONS_REQUEST,
    runManager(getUpdatePilotageData, ControlDataActionsType.GET_CONTROL_DATA_EDITING_OPTIONS_FAILURE)
  );
}
export function* ControlDataSagas() {
  yield all([
    fork(getControlDataSagas),
    fork(getUpdatePilotageDataSagas),
    fork(postDrivingDataSaga)
  ]);
}
