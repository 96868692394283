import { connect } from 'react-redux';

import { logoutCallbackRequest } from '@modules/auth/actions/authActions';
import { getFundRequest } from '@modules/funds/actions/fundsActions';
import { FundsActionsType } from '@modules/funds/actions/fundsActionsTypes';
import { AppState } from '@modules/reducers';
import {
  getFundsRepartitionSupports,
  getFundsOverview,
  getFundsPerformance,
  getFundsPerformanceSharePriceHisto,
  getFundsDocumentsList,
  getInnerNavigations,
} from '@modules/funds/selectors';

import { FundSheet } from './FundSheet.component';

const mapStateToProps = (state: AppState) => {
  return {
    funds: getFundsOverview(state),
    fundsRepartitionSupport: getFundsRepartitionSupports(state),
    innerNavigations: getInnerNavigations(state),
    fundsPerformance: getFundsPerformance(state),
    fundsPerformanceSharePriceHisto: getFundsPerformanceSharePriceHisto(state) || [],
    fundsDocumentsList: getFundsDocumentsList(state),
    isApiLoadingOverview:
        state.isLoading[FundsActionsType.GET_FUND_OVERVIEW_REQUEST],
    isApiLoadingDocuments:
        state.isLoading[FundsActionsType.GET_FUNDS_DOCUMENTS_LIST_REQUEST],
    isApiLoadingPerformance:
        state.isLoading[FundsActionsType.GET_FUND_PERFORMANCE_REQUEST],
  };
};

const mapDispatchToProps = {
  onGetFunds: getFundRequest,
  onLogoutCallback: logoutCallbackRequest,
};

export const FundSheetPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(FundSheet);
