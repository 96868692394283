import React, { useState } from "react";
import {
  View,
  StyleProp,
  ViewStyle,
  TextStyle,
} from "react-native";
import { TouchableOpacity } from 'react-native-gesture-handler' // Do not use TouchableOpacity from react-native

import { Title } from "@ere-uilib/atoms";
import { FontIcon } from "@ere-uilib/atoms";
import { createUseStyles, ThemeType, useTheme } from "@ere-uilib/styles";

interface FilterDateSelectorProps {
  items: any[];
  selectedItem: any;
  onSetSelectedItem: (item: any) => void;
  containerStyles?: StyleProp<ViewStyle>;
  selectedItemStyle?: StyleProp<ViewStyle>;
  titleStyle?: StyleProp<TextStyle>;
  itemsContainerStyle?: StyleProp<ViewStyle>;
  testId?: string;
}

export const FilterDateSelector: React.FC<React.PropsWithChildren<FilterDateSelectorProps>> = ({
  items,
  selectedItem,
  onSetSelectedItem,
  containerStyles,
  selectedItemStyle,
  titleStyle,
  itemsContainerStyle,
  testId
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const styles = useStyles(
    { theme },
    {
      containerStyles,
      selectedItemStyle,
      titleStyle,
      itemsContainerStyle,
    }
  );

  return (
    <View style={styles.containerStyles}>
      <TouchableOpacity
        accessibilityLabel={testId}
        onPress={() => setIsOpen(!isOpen)}
        style={styles.selectedItemStyle}
        testID={testId}
      >
        <Title
          style={styles.titleStyle}
          variant="t7">
          {selectedItem}
        </Title>
        <FontIcon
          color={theme.colors.basics.white}
          name="chevron-bas"
          size={theme.metrics.iconSizes.xm}
        />
      </TouchableOpacity>
      {isOpen && (
        <View style={styles.itemsContainerStyle}>
          <View style={styles.itemStyle}>
            <Title
              numberOfLines={1}
              style={styles.itemHeaderTextStyle}
              variant="t7"
            >
              Selectionner une date {/* label missing */}
            </Title>
          </View>
          {items.map((item: any, index) => {
            return (
              <TouchableOpacity
                key={`ITEM_${index}`}
                onPress={() => {
                  onSetSelectedItem(item);
                  setIsOpen(false);
                }}
                style={styles.itemStyle}
              >
                <Title
                  style={styles.itemTextStyle}
                  testId={item.toString()}
                  variant="t7">
                  {item}
                </Title>
                {item == selectedItem && (
                  <FontIcon
                    color={theme.colors.basics.primary.c500}
                    name="cocher"
                    size={theme.metrics.iconSizes.xs}
                  />
                )}
              </TouchableOpacity>
            );
          })}
        </View>
      )}
    </View>
  );
};

type FilterDateSelectorStyles = {
  containerStyles?: StyleProp<ViewStyle>;
  selectedItemStyle?: StyleProp<ViewStyle>;
  titleStyle?: StyleProp<TextStyle>;
  itemsContainerStyle?: StyleProp<ViewStyle>;
  itemStyle?: StyleProp<ViewStyle>;
  itemTextStyle?: StyleProp<TextStyle>;
  itemHeaderTextStyle?: StyleProp<TextStyle>;
};

type StylesContext = { theme: ThemeType };

const getStyles = (
  context?: StylesContext,
  style?: FilterDateSelectorStyles
): FilterDateSelectorStyles => ({
  containerStyles: [
    style?.containerStyles,
  ],
  selectedItemStyle: [
    {
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: context?.theme.metrics.borderRadius.l,
      paddingHorizontal: context?.theme.metrics.spacing.s,
      paddingVertical: context?.theme.metrics.spacing.xs,
      backgroundColor: context?.theme.colors.buttons.filter.c500,
    },
    style?.selectedItemStyle,
  ],
  titleStyle: [
    {
      color: context?.theme.colors.basics.white,
      marginRight: context?.theme.metrics.spacing.s,
    },
    style?.titleStyle,
  ],
  itemsContainerStyle: [
    {
      display: "flex",
      position: "absolute",
      top: context?.theme.metrics.spacing.xl,
      right: 0,
      borderRadius: context?.theme.metrics.borderRadius.xs,
      elevation: 2,
      shadowColor: context?.theme.colors.basics.grey.c200,
      shadowOffset: { width: 2, height: 3 },
      shadowRadius: context?.theme.metrics.borderRadius.s,
      overflow: "hidden",
      zIndex: 999
    },
    style?.itemsContainerStyle,
  ],
  itemStyle: [
    {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      paddingHorizontal: context?.theme.metrics.spacing.s,
      paddingVertical: context?.theme.metrics.spacing.m,
      backgroundColor: context?.theme.colors.basics.white,
      borderBottomColor: context?.theme.colors.basics.grey.c200,
      borderBottomWidth: 1,
      borderRadius: 0,
      justifyContent: "space-between",
      minHeight: 50,
    },
    style?.itemStyle,
  ],
  itemTextStyle: [{}, style?.itemTextStyle],
  itemHeaderTextStyle: [
    {
      color: context?.theme.colors.text.disabled.c500,
    },
  ],
});

const useStyles = createUseStyles(getStyles);
