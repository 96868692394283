import { useScreenSizes, useTheme } from '@ere-uilib/styles';
import { useMemo } from 'react';
import { StyleSheet } from 'react-native';


export function useStyles() {
  const { isMobile } = useScreenSizes();
  const theme = useTheme();
  const styles = useMemo(() => StyleSheet.create({
    dashboardWrapper: {
      backgroundColor: theme?.colors.basics.white
    },
    containerStyle: {
      paddingBottom: theme.metrics.spacing.xxl
    },
    bottomActionsStyle:
    {
      ...isMobile ? {
        marginRight: 0,
      } : {
        marginRight: 'auto'
      },
    },
    bottomActionsButtonsContainerStyle:
    {
      justifyContent: 'space-between',
      width: '100%',
      flexDirection: 'row',
    },
    simpleButtonStyle: {
      flex: 1,
      padding: theme.metrics.spacing.s,
    },
    simpleButtonLeftStyle: {
      ...isMobile ? {
        width: '48%',
      } : {
        maxWidth: 130,
      },
      marginRight: theme.metrics.spacing.s,
    },
    simpleButtonRightStyle: {
      ...isMobile ? {
        width: '48%',
      } : {
        maxWidth: 130,
      },
    },
  }), [isMobile]);

  return styles;
}
