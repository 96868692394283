import { NewsFeedTypeState } from '@modules/history/types';
import { groupBy } from 'lodash';

export const groupByDate = (data: NewsFeedTypeState[]): [string, NewsFeedTypeState[]][] => {
  const sortedNewsFeedData = data.sort(
    (a: any, b: any) => new Date(b.date).getTime() - new Date(a.date).getTime()
  );

  const newsFeedGroupedByDate = groupBy(sortedNewsFeedData, feed => {
    return feed.date;
  });
  return Object.keys(newsFeedGroupedByDate).map(key => [key, newsFeedGroupedByDate[key]]);
};
