
export enum BackStringBooleanEnum {
  'TRUE' = 'True',
  'FALSE' = 'False',
}
export type BackStringBoolean = BackStringBooleanEnum.TRUE | BackStringBooleanEnum.FALSE
import { Dimensions } from 'react-native';
//import { theme } from '../theme'

const WIN = Dimensions.get('window');
const WIN_WIDTH = WIN.width;
const WIN_HEIGHT = WIN.height;
//const MOBILE = WIN_WIDTH <= theme.breakpoints.tablet;
//const TABLET_DESKTOP = WIN_WIDTH > theme.breakpoints.tablet;
const PILOTE = true;
export {
  PILOTE,
  WIN_WIDTH,
  WIN_HEIGHT
};
