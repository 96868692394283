import { AppState }  from '@modules/reducers';

export const getForgetPasswordRequestIdSelector = (state: AppState) =>
  state.forgetPassword.forgetPasswordInitBegin.uiidRequestedCode;

export const getForgetPasswordError = (state: AppState) =>
  state.forgetPassword.error;

export const getUserInputEmail = (state: AppState) => state.forgetPassword.emailInput;

export const getUserPhoneNumber = (state: AppState) => state.forgetPassword.forgetPasswordConfirmEmail.phoneNumber;
