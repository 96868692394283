import { StackNavigationProp } from '@react-navigation/stack';
import React from 'react';
import { View, StyleProp, ViewStyle, SafeAreaView } from 'react-native';

import {
  SimpleButton,
  ThankYouInformations,
  useTheme,
  ThemeType,
  useScreenSizes,
  EERPageWrapperContainer,
} from '@components/index';
import { createUseStyles } from '@ere-uilib/styles';

import { RouteNames } from '@constants/navigation';
import { useTranslation } from '@ere-uilib/translations';
import { InvitationState } from '@modules/invitation/types';
import { AppNavigatorInterface } from '@navigation/Interfaces';
import { locator } from '@constants/locator';

interface Props {
  navigation: StackNavigationProp<AppNavigatorInterface>;
  invitation: InvitationState
}

type ValidateSignupThankYouStyles = {
  containerStyle?: StyleProp<ViewStyle>;
  rightContainerStyle?: StyleProp<ViewStyle>;
  fullSpaceContainer?: StyleProp<ViewStyle>
  footerContentContainer?: StyleProp<ViewStyle>
};

type StylesContext = { theme: ThemeType; isMobile: boolean, isTablet: boolean };

export const ValidateSignupThankYouPageComponent: React.FC<React.PropsWithChildren<Props>> = ({ navigation, invitation }) => {
  const theme = useTheme();

  const { isMobile, isTablet } = useScreenSizes();
  const styles = useStyles({ theme, isMobile, isTablet }, {});
  const { formatMessage } = useTranslation();

  const renderMobileFooterContent = () => (
    <View style={styles.footerContentContainer}>
      <SimpleButton
        containerStyle={{ marginTop: theme.metrics.spacing.xm }}
        onPress={() => navigation.navigate(RouteNames.RelationshipEntringProcess, { screen: RouteNames.ChooseIdentificationMail })}
        title="Créer votre compte"
        testId={locator._account_activation._create_account}
      />
    </View>
  );

  return (

    <EERPageWrapperContainer
      activeStep={1}
      displayCloseButton={false}
      helpPointPosition="left"
      pageTitle={formatMessage({ id: 'verify-identity_menu' })}
      renderStickyMobileFooterContent={renderMobileFooterContent}
    >
      <SafeAreaView style={styles.containerStyle}>
        <View style={styles.rightContainerStyle}>
          <View style={styles.fullSpaceContainer}>
            <ThankYouInformations title={formatMessage({ id: 'hello_title', values: { variable: invitation.user.firstName } })} />
            {!(isMobile || isTablet) && (
              <>
                <SimpleButton
                  containerStyle={{ marginTop: theme.metrics.spacing.xm }}
                  onPress={() => navigation.navigate(RouteNames.RelationshipEntringProcess, { screen: RouteNames.ChooseIdentificationMail })}
                  title="Créer votre compte"
                  testId={locator._account_activation._create_account}
                />
              </>
            )}
          </View>
        </View>
      </SafeAreaView>
    </EERPageWrapperContainer>
  );
};

const getStyles = (
  context?: StylesContext,
  style?: ValidateSignupThankYouStyles,
): ValidateSignupThankYouStyles => ({
  containerStyle: [
    !(context?.isMobile || context?.isTablet)
      ? { flex: 1, flexDirection: 'row' }
      : {
        flex: 1,
        flexDirection: 'column',
      },
  ],
  rightContainerStyle: [
    !(context?.isMobile || context?.isTablet)
      ? {
        flex: 2.1,
        paddingHorizontal: context?.theme.metrics.spacing.xxl,
        paddingTop: context?.theme.metrics.spacing.xxl,
        alignItems: 'center',
        justifyContent: 'center',
      }
      : {
        flex: 1,
        padding: context?.theme.metrics.spacing.xxl,
        alignItems: 'center',
        justifyContent: 'center',
      },
  ],
  fullSpaceContainer: [
    {
      alignItems: 'center',
      flex: 1,
    },
  ],
  footerContentContainer: [
    {
      paddingHorizontal: context?.theme.metrics.spacing.xm,
    },
  ],
});

const useStyles = createUseStyles(getStyles);
