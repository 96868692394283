import { useNavigation } from '@react-navigation/native'
import React, { useCallback } from 'react'

import { LandingPageWithCards } from '@components/index'
import { RouteNames } from '@constants/navigation'

import { DocumentGroup } from './constants'
import { useLabels } from './useLabels'

export const Home = () => {
  const data = useLabels()
  const navigation = useNavigation()

  const hanldleSelectCard = useCallback(
    (type: DocumentGroup) => {
      navigation.navigate(RouteNames.DocumentSpaceStack, {
        screen:
          type === DocumentGroup.EDOCUMENT
            ? RouteNames.DocumentSpaceEDocumentsFiles
            : RouteNames.DocumentSpaceGeneralDocuments
      })
    },
    [navigation]
  )

  const handleCloseModal = useCallback(() => {
    navigation.navigate(RouteNames.BottomTabNavigator, {
      screen: RouteNames.Home,
    });
  }, [navigation])

  return (
    <LandingPageWithCards
      data={data}
      onClose={handleCloseModal}
      onSelectCard={hanldleSelectCard}
    />
  )
}
