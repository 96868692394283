import { connect } from 'react-redux';

import { ArbitrationActionsType } from '@modules/arbitration/actions/arbitrationActionsTypes';
import { eligibleAllocation, getIneligibleDispositifs, getReallocationType } from '@modules/arbitration/selectors';
import { AppState } from '@modules/reducers';
import { getApplicationSettings, getAtInternetClientIdSession } from '@modules/settings/selectors';

import { ArbitrationOnboardingPageComponent } from './ArbitrationOnboardingPage.component';

const mapStateToProps = (state: AppState) => ({
  isLoading: state.isLoading[ArbitrationActionsType.GET_ARBITRATION_QUESTIONS_REQUEST],
  arbitrationAllocation: eligibleAllocation(state),
  ineligibleDispositifs: getIneligibleDispositifs(state),
  applicationSettings: getApplicationSettings(state),
  reallocationType: getReallocationType(state),
  atInternetClientIdSession: getAtInternetClientIdSession(state)
});

export const ArbitrationOnboardingPage = connect(
  mapStateToProps,
  null
)(ArbitrationOnboardingPageComponent);
