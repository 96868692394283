import { Title } from '@ere-uilib/atoms';
import React, { useState } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { DocumentThemeProps } from './interfaces';
import { useStyles } from './useStyles';

export const DocumentThemeCard = ({
  id, title, isActive, ...props
}: DocumentThemeProps) => {
  const [toggleActiveCard, setToggleActiveCard] = useState(isActive || false);
  const styles = useStyles({ toggleActiveCard });

  return (
    <TouchableOpacity
      {...props}
      onPress={event => {
        setToggleActiveCard(!toggleActiveCard);
        props.onPress?.(event);
      }}
      style={[styles.cardContainer, props.style]}>
      <Title
        style={style.textStyle}
        variant='t7'
        weight="regular"
      >
        {title}
      </Title>
    </TouchableOpacity>
  );
}

const style = StyleSheet.create({
  textStyle: { textAlign: 'center' }
})