import { useMemo } from "react";
import { StyleSheet } from "react-native";

import { useScreenSizes } from "@ere-uilib/styles";

export const useStyles = () => {
  const { isMobile } = useScreenSizes();

  const styles = useMemo(() => StyleSheet.create({
    loaderContainerStyle: {
      display: 'flex',
      flex: 1,
      flexDirection: isMobile ? 'row' : 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginHorizontal: isMobile ? 0 : 18
    },
  }), [isMobile]);

  return styles;
};