import React, { useCallback, useState } from 'react';

import {
  TextIdentifierDictionaryType,
  LetterIdentifierForm,
} from '@components/index';

import { SelfcareIdentifierFormTemplate } from '../SelfcareIdentifierForm';

interface Props {
  onPressBackButton?: () => void;
  onClose: () => void;
  onPressSubmit: (identifier: string, birthDate: Date) => void
  dictionary: TextIdentifierDictionaryType;
  errorMessage?: string;
  isLoading?: boolean;
  identifierRegex?: string;
}

export function SelfcareLetterIdentifierFormTemplate({
  onPressBackButton,
  onClose,
  onPressSubmit,
  dictionary,
  errorMessage,
  isLoading,
}: Props) {
  const [dateOfBirth, setDateOfBirth] = useState<Date>();
  const [dateOfBirthVisible, setDateOfBirthVisible] = useState(false);
  const [code, setCode] = useState(['', '', '', '', '', '']);

  const submitButtonActive = !!code.length && !!dateOfBirth;

  const handleChangeCode = useCallback((value: string[]) => {
    const codeValid = value.join('').length === 6;

    setDateOfBirthVisible(codeValid);
    setDateOfBirth(undefined);
    setCode(value);
  }, []);

  const handleSubmit = useCallback(() => {
    if (!dateOfBirth) {
      return;
    }

    const codeText = code.join('');

    onPressSubmit(codeText, dateOfBirth);
  }, [onPressSubmit, code, dateOfBirth]);

  const renderContent = useCallback(() => {
    return (
      <LetterIdentifierForm
        code={code}
        dateOfBirth={dateOfBirth}
        dateOfBirthVisible={dateOfBirthVisible}
        dictionary={dictionary}
        errorMessage={errorMessage}
        isLoading={isLoading}
        onChangeCode={handleChangeCode}
        onChangeDateOfBirth={setDateOfBirth}
        onPressSubmit={handleSubmit}
      />
    );
  }, [
    code,
    isLoading,
    dateOfBirth,
    dateOfBirthVisible,
    dictionary,
    errorMessage,
    handleSubmit,
    handleChangeCode,
  ]);

  return (
    <SelfcareIdentifierFormTemplate
      dictionary={dictionary}
      errorMessage={errorMessage}
      isLoading={isLoading}
      onClose={onClose}
      onPressBackButton={onPressBackButton}
      onPressSubmit={handleSubmit}
      renderContent={renderContent}
      submitButtonActive={submitButtonActive}
    />
  );
}
