import React from 'react';

import { StylesContext, LegalInfoPageStyles } from './interfaces';

export const getStyles = (
  context?: StylesContext,
  style?: LegalInfoPageStyles,
): LegalInfoPageStyles => ({
  notConnectedContentStyle: [
    {
      flex: 1,
      maxWidth: '100%'
      // backgroundColor: context?.theme.colors.basics.white
    },
    style?.notConnectedContentStyle
  ],
  notConnectedInnerContentStyle: [
    {
      flex: 1,
      maxWidth: '100%'
      // backgroundColor: context?.theme.colors.basics.white
    },
    context?.isMobile ?
      {
        paddingHorizontal: context?.theme.metrics.spacing.s,
        paddingTop: context?.theme.metrics.spacing.huge
      }
      :
      {
        paddingHorizontal: context?.theme.metrics.spacing.huge,
        paddingTop: context?.theme.metrics.spacing.xhuge
      },
    context?.isDesktop && { maxHeight: 650 },
    style?.notConnectedContentStyle
  ],
  connectedContentStyle: [
    {
      flex: 1,
      maxWidth: '100%',
      backgroundColor: context?.theme.colors.basics.white
    },
    style?.connectedContentStyle
  ],
  connectedInnerContentStyle: [
    {
      flex: 1,
      maxWidth: '100%',
      backgroundColor: context?.theme.colors.basics.white
    },
    context?.isMobile ?
      {
        paddingHorizontal: context?.theme.metrics.spacing.s,
        paddingTop: context?.theme.metrics.spacing.xm,
        paddingBottom: context?.theme.metrics.spacing.xxbig
      }
      :
      {
        paddingHorizontal: context?.theme.metrics.spacing.huge,
        paddingVertical: context?.theme.metrics.spacing.xxbig
      },
    style?.connectedInnerContentStyle
  ],
  logoStyle: [
    context?.isMobile
      ? {
        width: 200,
        height: 30,
        maxWidth: 200,
        maxHeight: 30,
        marginVertical: context?.theme.metrics.spacing.m,
        marginLeft: context?.theme.metrics.spacing.s
      }
      : {
        width: 265,
        height: 40,
        maxWidth: 265,
        maxHeight: 40,
        marginVertical: context?.theme.metrics.spacing.xxl,
        marginLeft: context?.theme.metrics.spacing.xxl
      },
    style?.logoStyle
  ],
  fullSpaceContainer: [
    { flex: 1 },
    style?.fullSpaceContainer
  ],
  scrollViewContainerStyle: [
    {
      flex: 1
    },
    style?.scrollViewContainerStyle
  ],
  scrollViewStyle: [
    {
      flex: 1
    },
    !(context?.isMobile || context?.isTablet) && !context?.isConnexionValid && { maxHeight: 600 },
    style?.scrollViewStyle
  ],
  titleStyle: [
    {
      marginBottom: context?.theme.metrics.spacing.s
    },
    !context?.isMobile && {
      paddingBottom: context?.theme.metrics.spacing.xl
    },
    style?.titleStyle
  ],
  backButtonStyle: [
    {
      flexDirection: 'row',
      alignItems: 'center',
      paddingBottom: context?.theme.metrics.spacing.l
    },
    style?.backButtonStyle
  ],
  backButtonTextStyle: [
    {
      marginLeft: context?.theme.metrics.spacing.s
    },
    style?.backButtonTextStyle
  ]
});
