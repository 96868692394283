import { cloneDeep } from 'lodash';

import {
  EstimateCalculContributionFetchParamsType,
  EstimateContributionFetchSupportParamsType
} from '@constants/contribution';
import {
  ApiPaymentPeriodicityEnum,
  InstallmentDecisionVVPFrequencyEnum,
  PaymentModeList
} from '@constants/installment';
import { PlanFamillyList } from '@constants/index';
import { OperationDecisionState } from '@modules/common/types';
import {
  InstallmentRepartitionState,
  InstallmentState,
  UpdateInstallmentRepartitionAmountsDataProps
} from '@modules/installment/types';
import { OnGoingVVP } from '@modules/savings/types';

export const updateInstallmentRepartitionAmountsData = ({
  previousRepartitionData,
  calculatedContribution,
  installmentAmountValidationParams
}: UpdateInstallmentRepartitionAmountsDataProps): InstallmentRepartitionState => {
  let newRepartitionData: InstallmentRepartitionState = cloneDeep(previousRepartitionData);
  let newPlanTotalAmount = 0;
  let newPlanTotalContribution = 0;
  const shouldUpdateContribution = calculatedContribution;
  // update repartition data
  const newManagements = newRepartitionData?.compartments?.[0]?.managements?.map(management => {
    const calculatedContributionManagement = calculatedContribution?.distributions.find(
      item => item.managementId === management.id
    );
    const isChangedManagement = management.id === installmentAmountValidationParams.managementId;
    const isManagementContributable = !!management.advancedProperties?.HasContribution;

    // check if isManagementDirectContributionCalcul : management is free RC contributable with no support contributable
    const isRC = newRepartitionData.planFamily === PlanFamillyList.RC;
    const isFreeManagement = management.isFree;
    const isManagementDirectAmounts = isRC && !isFreeManagement;
    const isManagementDirectContributionAmounts = isRC;
    // update management
    let newManagementTotalAmount = 0;
    let newManagementTotalContribution = 0;
    let newManagement = management;

    // update management funds
    const newSupports = newManagement?.supports?.map(support => {
      const calculatedContributionFund = calculatedContributionManagement?.supports?.find(
        item => item.supportId === support.supportIsin
      );
      const isChangedFund = support.supportIsin === installmentAmountValidationParams?.fundId;
      const isFundContributable = support.hasContribution;
      const newContribution = shouldUpdateContribution
        ? calculatedContributionFund?.contributionAmount || 0
        : support?.formValues?.contribution || 0;
      // update fund
      const newFund = {
        ...support,
        formValues: {
          amount:
            (isChangedManagement && isChangedFund
              ? installmentAmountValidationParams.amount
              : support.formValues?.amount) || 0,
          ...(isFundContributable || support.isMaster ? { contribution: newContribution } : {})
        }
      };

      // update management Totals
      newManagementTotalAmount += newFund?.formValues?.amount || 0;
      newManagementTotalContribution += newFund?.formValues?.contribution || 0;
      return newFund;
    });

    // manage free RC non free management ( they don't have master fund )
    if (isManagementDirectAmounts) {
      // set management amount total from installmentAmountValidationParams amount instead of funds totals
      newManagementTotalAmount = isChangedManagement
        ? installmentAmountValidationParams.amount
        : management.formValues?.totalAmount || 0;
    }
    // manage free RC management contributable direct contributtion calcul
    if (isManagementDirectContributionAmounts) {
      // set management contribution total from calculated contribution instead of funds totals
      newManagementTotalContribution = shouldUpdateContribution
        ? calculatedContributionManagement?.contributionAmount || 0
        : management.formValues?.totalContribution || 0;
    }

    // finalize new management with updated funds and management totals
    newManagement = {
      ...newManagement,
      supports: newSupports,
      formValues: {
        totalAmount: newManagementTotalAmount,
        ...(isManagementContributable ? { totalContribution: newManagementTotalContribution } : {})
      }
    };

    // update plan totals
    newPlanTotalAmount += newManagementTotalAmount;
    newPlanTotalContribution += newManagementTotalContribution;

    return newManagement;
  });

  // finalise repartition data with updated management and plan totals
  newRepartitionData = {
    ...newRepartitionData,
    compartments: [
      {
        ...newRepartitionData.compartments[0],
        managements: newManagements
      }
    ],
    formValues: {
      totalAmount: newPlanTotalAmount,
      totalContribution: newPlanTotalContribution
    }
  };

  return newRepartitionData;
};

export const removeDuplicatePlanFamilyInstallments = (arr: string[]) => {
  const s = new Set(arr);
  const it = s.values();
  return Array.from(it);
}

export const buildCalculatedContributionDistributionsParameter = ({
  installmentRepartitionData,
  managementId,
  fundId,
  amount
}: {
  installmentRepartitionData: InstallmentRepartitionState;
  managementId: string;
  fundId?: string | null;
  amount: number;
}): EstimateCalculContributionFetchParamsType['distributions'] => {
  const distributions: EstimateCalculContributionFetchParamsType['distributions'] = [];
  const isRC = installmentRepartitionData.planFamily === PlanFamillyList.RC;

  installmentRepartitionData?.compartments?.[0]?.managements?.forEach(management => {
    if (management?.advancedProperties?.HasContribution) {
      const isFreeManagement = management.isFree;
      const isChangedManagement = managementId === management.id;
      const isManagementDirectAmounts = isRC && !isFreeManagement;
      const isManagementDirectContributionAmounts = isRC;

      let managementTotalAmount = 0;
      const supports: EstimateContributionFetchSupportParamsType[] = [];
      if (isManagementDirectContributionAmounts) {
        // in RC if management hasContribution, but no support has contribution, we want the total for the management
        management?.supports?.forEach(support => {
          const isChangedFund = fundId && support.supportIsin === fundId;
          let fundAmount = support.formValues?.amount || 0;
          // we test if we use the formValue or the new field value
          if (isChangedManagement && isChangedFund) {
            fundAmount = amount;
          }
          managementTotalAmount += fundAmount || 0;
        });
        if (isManagementDirectAmounts) {
          managementTotalAmount = isChangedManagement
            ? amount
            : management.formValues?.totalAmount || 0;
        }
      } else {
        // in common situation we want a list of all support with hasContribution
        management?.supports?.forEach(support => {
          // if the management is not free management we want only the isMaster fund to be listed
          if (!isFreeManagement && !support.isMaster) {
            return;
          }
          // we list all funds with hasContribution or Master fund even if non abondable
          if (support?.hasContribution || (!isFreeManagement && support.isMaster)) {
            let supportDistribution: EstimateContributionFetchSupportParamsType;
            const isChangedFund = fundId && support.supportIsin === fundId;
            // we test if we use the formValue or the new field value
            if (isChangedManagement && isChangedFund) {
              supportDistribution = {
                supportId: support.supportIsin,
                amount: amount || 0
              };
            } else {
              supportDistribution = {
                supportId: support.supportIsin,
                amount: support.formValues?.amount || 0
              };
            }
            supports?.push(supportDistribution);
          }
        });
      }
      distributions.push({
        managementId: management.id,
        // cacul is made for management total amount of by managment support list
        ...(isManagementDirectContributionAmounts ? { amount: managementTotalAmount } : {}),
        ...(supports ? { supports: supports } : {})
      });
    }
  });

  return distributions;
};
export const installmentDecisionDataFormatter = (
  decision: OperationDecisionState
): OperationDecisionState => {
  const formattedQuestions = [
    ...decision?.questions?.filter(question => question.isRequired === true),
    ...decision?.questions?.filter(question => question.isRequired === false)
  ];
  return {
    ...decision,
    questions: formattedQuestions
  };
};
export const convertAPIPaymentPeriodicityToInstallmentDecisionVVPFrequency = (
  periodicity: ApiPaymentPeriodicityEnum
): InstallmentDecisionVVPFrequencyEnum | undefined => {
  let output;
  Object.entries(ApiPaymentPeriodicityEnum).forEach(([key, value]) => {
    if (value === periodicity) {
      output = key;
    }
  });
  return output;
};
export const convertInstallmentDecisionVVPFrequencyToAPIPaymentPeriodicity = (
  periodicity: InstallmentDecisionVVPFrequencyEnum
): ApiPaymentPeriodicityEnum | undefined => {
  return ApiPaymentPeriodicityEnum[periodicity] || undefined;
};
export const buildEditVVPData = ({
  state,
  editedVVPData,
  revievedRepartition
}: {
  state: InstallmentState;
  editedVVPData: OnGoingVVP;
  revievedRepartition: InstallmentRepartitionState;
}): InstallmentState => {
  const newPaymentMode = PaymentModeList.debit;
  const paymentModeData = [
    {
      paymentMethod: PaymentModeList.debit,
      bankAccountDetail: editedVVPData.bankAccountDetail
    }
  ];
  let newRepartitionData = revievedRepartition;

  let newPlanTotalAmount = 0;
  // update repartition data
  const newManagements = newRepartitionData?.compartments?.[0]?.managements?.map(management => {
    const managementInEditedVVPData = editedVVPData.managements.find(
      item => item.id === management.id
    );
    const isChangedManagement = !!managementInEditedVVPData;

    // check if isManagementDirectContributionCalcul : management is free RC contributable with no support contributable
    const isRC = newRepartitionData.planFamily === PlanFamillyList.RC;
    const isFreeManagement = management.isFree;
    const isManagementDirectAmounts = isRC && !isFreeManagement;
    // update management
    let newManagementTotalAmount = 0;
    let newManagement = management;

    // update management funds
    const newSupports = newManagement?.supports?.map(support => {
      let newFund = support;
      const vvpFundData =
        managementInEditedVVPData &&
        managementInEditedVVPData.supports.find(support => {
          return newFund.supportIsin === support.supportIsin;
        });
      const isFilledFund = isChangedManagement && !!vvpFundData;
      if (!isManagementDirectAmounts && isFilledFund) {
        // update fund
        newFund = {
          ...support,
          ...(isFilledFund && {
            formValues: {
              amount: vvpFundData?.amount?.amount || 0
            }
          })
        };
      }

      // update management Totals
      newManagementTotalAmount += newFund?.formValues?.amount || 0;
      return newFund;
    });

    // manage free RC non free management ( they don't have master fund )
    if (isManagementDirectAmounts) {
      // set management amount total from installmentAmountValidationParams amount instead of funds totals
      newManagementTotalAmount = isChangedManagement
        ? editedVVPData.amount
        : management.formValues?.totalAmount || 0;
    }

    // finalize new management with updated funds and management totals
    newManagement = {
      ...newManagement,
      supports: newSupports,
      formValues: {
        totalAmount: newManagementTotalAmount
      }
    };

    // update plan totals
    newPlanTotalAmount += newManagementTotalAmount;

    return newManagement;
  });

  // finalise repartition data with updated management and plan totals
  newRepartitionData = {
    ...newRepartitionData,
    compartments: [
      {
        ...newRepartitionData?.compartments?.[0],
        managements: newManagements
      }
    ],
    formValues: {
      totalAmount: newPlanTotalAmount
    }
  };

  return {
    ...state,
    editedVVPData: editedVVPData,
    repartitionData: newRepartitionData,
    paymentMode: newPaymentMode,
    paymentModeData: paymentModeData
  };
};
