import { RouteNames } from '@constants/navigation';
import { StepperHeader } from '@ere-uilib/molecules';
import { useNavigation } from '@react-navigation/native';
import React, { useCallback } from 'react';
import { StyleProp, ViewStyle } from 'react-native';

type StepperProps = React.ComponentPropsWithoutRef<typeof StepperHeader>;

type Props = StyleProps & StepperProps;

interface StyleProps {
  stepperContainer?: StyleProp<ViewStyle>
  stepperStyle?: StyleProp<ViewStyle>
}

export const DocumentSpaceHeader: React.FC<Props> = ({
  displayCloseButton = true,
  ...props
}) => {
  const navigation = useNavigation();

  const handleClose = useCallback(() => {
    navigation.navigate(RouteNames.BottomTabNavigator, {
      screen: RouteNames.Home,
    });
  }, [navigation]);

  const handleGoBack = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  return (
    <StepperHeader
      displayCloseButton={displayCloseButton}
      onClose={handleClose}
      onGoBack={handleGoBack}
      {...props}
    />
  );
};
