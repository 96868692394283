import { useMemo } from 'react';
import { Platform, StyleSheet } from 'react-native';

import { useScreenSizes, useTheme } from '@ere-uilib/styles';

export function useStyles() {
  const theme = useTheme();
  const { isDesktop, isMobile } = useScreenSizes();

  const styles = useMemo(
    () =>
      StyleSheet.create({
        columnsContainer: {
          backgroundColor: theme.colors.basics.white,
          ...isDesktop && {
            maxWidth: '80%',
            marginHorizontal: 'auto'
          }
        },
        bottomActionsContainer: {
          flexDirection: 'row'
        },
        cardContainer: {
          borderRadius: theme.metrics.borderRadius.xs,
          backgroundColor: theme.colors.basics.white,
          marginVertical: theme.metrics.spacing.s,
          ...isMobile && {
            paddingVertical: theme.metrics.spacing.m,
            paddingHorizontal: theme.metrics.spacing.s,
            shadowOpacity: 1,
            shadowRadius: 10,
            shadowColor:
              Platform.OS === 'android'
                ? theme.colors.basics.black
                : theme.colors.notifications.shadow,
            elevation: 7,
            shadowOffset: {
              width: 0,
              height: 2
            },
          }
        },
        radiosButtonListContainer: {
          marginVertical: theme.metrics.spacing.s
        },
        filePickersContainer: {
          alignItems: 'flex-start',
          paddingVertical: theme.metrics.spacing.s
        },
        bottomActionButtonNext: {
          marginLeft: theme.metrics.spacing.m,
          ...!isDesktop && {
            flex: 1
          }
        },
        filePicker: {
          marginBottom: theme.metrics.spacing.m,
        },
        textStyle: {
          marginVertical: theme.metrics.spacing.s,
        }
      }),
    [theme, isDesktop, isMobile]
  );

  return styles;
}
