import React, { useEffect, useMemo, useState } from 'react';

import { AmountFieldModal360 } from '@components/index';
import { useTranslation } from '@ere-uilib/index'

import {
  AmountValidationParamsType,
  CalculContributionParamsType, InstallmentAmountFieldModalConnectedProps,
  ModalData
} from './interfaces';
import { InstallmentAmountFieldModalConnectedRefType } from './sharedInterfaces';

export const InstallmentAmountFieldModalConnectedComponent = React.forwardRef<InstallmentAmountFieldModalConnectedRefType, InstallmentAmountFieldModalConnectedProps>((
  {
    visible,
    calculatedContribution,
    onSetInstallmentRepartitionAmount,
    onCalculContribution,
    onInitCalculatedContribution,
    onSetInstallmentRepartitionAmountModalVisibleState,
    isCalculContributionLoading,
    isSetInstallmentAmountLoading,
    calculatedContributionError,
    installmentRepartitionData,
  },
  ref
) => {
  const { formatMessage }= useTranslation()
  const defaultData:ModalData = {
    displayData: {
      title: '',
      initialAmount: undefined,
    },
    managementId: null,
    fundId: undefined,
    isManagementDirectAmounts: undefined,
    isManagementDirectContributionAmounts: undefined,
  };
  const [data, setData] = useState<ModalData>(defaultData);

  const formError = useMemo(() => {
    const isContributionError = data.displayData.isContributionActive 
    && calculatedContributionError?.message 
    && calculatedContributionError?.message !== ''
    if(isContributionError) {
      return formatMessage({ id: 'VV_repartition_contribution_error_label' })
    }
    return undefined
  }, [
    formatMessage,
    calculatedContributionError?.message, 
    data.displayData.isContributionActive
  ]);

  const targetedManagement = installmentRepartitionData?.compartments?.[0]?.managements?.find(
    management => management.id === data.managementId
  );
  const targetedManagementId = data.managementId ? data.managementId : targetedManagement?.id;
  const targetedFundId = data.fundId
    ? data.fundId
    : targetedManagement?.supports?.find(support => support.isMaster)?.supportIsin;

  const contribution = calculatedContribution?.distributions?.find(management=>targetedManagementId === management.managementId)?.supports?.find(support=>targetedFundId === support.supportId)?.contributionAmount;

  // allow method via ref
  React.useImperativeHandle(ref, () => ({
    openWithData: receivedData => {
      onSetInstallmentRepartitionAmountModalVisibleState(true);
      setData(receivedData);
    },
  }));

  useEffect(() => {
    if (!visible) {
      setData(defaultData);
    }
  }, [visible]);

  const onCancelPressed = ()=>{
    onSetInstallmentRepartitionAmountModalVisibleState(false);
    setData(defaultData);
  };

  const onValidPressed = (amount?:number|null)=>{
    if (
      data.managementId
      && installmentRepartitionData
      &&  (data.isManagementDirectContributionAmounts || targetedFundId)
    ) {
      const params:AmountValidationParamsType = {
        managementId: data.managementId,
        fundId: targetedFundId,
        amount: amount ? amount : 0,
        contribution: calculatedContribution,
        isContributionActive: data?.displayData.isContributionActive,
      };
      onSetInstallmentRepartitionAmount(params);
    }
  };
  const onCalculContributionPressed = (amount?:number|null)=>{
    if (data.managementId && installmentRepartitionData && targetedFundId) {
      const params:CalculContributionParamsType = {
        managementId: data.managementId,
        fundId: targetedFundId,
        amount: amount ? amount : 0,
      };
      onCalculContribution(params);
    }
  };
  return (
    <AmountFieldModal360
      calculatedContribution={contribution}
      data={data.displayData}
      formError={formError}
      isCalculContributionLoading={isCalculContributionLoading}
      isValidButtonLoading={isSetInstallmentAmountLoading}
      onCalculContribution={onCalculContributionPressed}
      onCancel={onCancelPressed}
      onResetCalculatedContribution={onInitCalculatedContribution}
      onValid={onValidPressed}
      visible={visible}
    />
  );
});
