import { useNavigation } from '@react-navigation/native'
import React, { useCallback, useRef } from 'react'
import { View } from 'react-native'

import { DashboardPageWrapperConnected } from '@components/ERE360Components'
import { RequirementDefinition } from '@components/templates/RequirementDefinition'
import { RouteNames } from '@constants/navigation'
import { RefundDecisionPropertiesEnum } from '@constants/refunds'
import { SimpleButton } from '@ere-uilib/molecules'
import { DirectionEnum } from '@ere-uilib/molecules/steppers/InstallmentsStepper/interfaces'
import { EligibilityRecapCard } from '@ere-uilib/organisms'
import { useTheme, useScreenSizes } from '@ere-uilib/styles'
import { ColumnsContainer } from '@ere-uilib/templates'
import { useTranslation } from '@ere-uilib/translations'
import { DispositifState, OperationDecisionState, ResponseType } from '@modules/common/types'
import { refundUnavailableDispositifTableDecisionParamsType } from '@modules/refund/unavailable-refund/types'
import { constructStepperItems } from '@pages/Common/utils'
import { getEligibleDispositifs } from '@utils/eligiblePlansCompute'

import { RefundsHeader } from '../components/RefundsHeader'
import { useController } from './useController'
import { locator } from '@constants/locator'

interface Props {
  setEligibleDispositifs: (elligibleDispositifs: DispositifState[]) => void
  requirementDecisionData: OperationDecisionState
  onUpdateQuestion: (responsePayload: ResponseType, index: number, canNotBeAsked: boolean) => void
  onUpdateUnavailableQuestion: (
    responsePayload: ResponseType,
    index: number,
    canNotBeAsked: boolean
  ) => void
  onBackToQuestion: (index: number) => void
  onSelectDispositif: (dispositif: DispositifState[]) => void
  onGetUnavailableRefundDispositifTable?: (
    nodeId: string,
    questionParams: refundUnavailableDispositifTableDecisionParamsType
  ) => void
  unavailableRefundDispositifTableLoading: boolean
}

export interface IDispositifOption {
  value: string
  labelCode: string
}
export const RefundsRequirementDefinitonComponent: React.FC<React.PropsWithChildren<Props>> = ({
  requirementDecisionData,
  onBackToQuestion,
  onUpdateQuestion,
  onUpdateUnavailableQuestion,
  setEligibleDispositifs,
  onSelectDispositif,
  onGetUnavailableRefundDispositifTable,
  unavailableRefundDispositifTableLoading
}: Props) => {
  const refColumnsContainer = useRef() as React.MutableRefObject<{ scrollToTop: () => void }>
  const refDashboardContainer = useRef() as React.MutableRefObject<{ scrollToTop: () => void }>
  const { isDesktop } = useScreenSizes()
  const theme = useTheme()
  const navigation = useNavigation()
  const { formatMessage, getMessageRaw, formatCurrencyNumber } = useTranslation()

  const pageTitle = formatMessage({ id: 'Remboursment_etape1' })
  const respondedQuestion = requirementDecisionData?.questions?.filter(q => q?.responded)
  const unRespondedQuestion = requirementDecisionData?.questions?.filter(q => !q?.responded)
  const alreadyResponded = respondedQuestion?.map(item => item.responded)

  const {
    selectedUnavailableOption,
    elligibleDispositifs,
    renderMixDispositifsEligibleForUnavailable,
    handleRedirectToPersoneo,
    handleDispositifsRcValidated
  } = useController({
    requirementDecisionData,
    alreadyResponded
  })

  const stepperItems = constructStepperItems({
    size: 5,
    direction: DirectionEnum.ROW,
    activeStep: 1
  })

  const dictionary = {
    totalAmountTitle: (amount: number) => {
      if (amount === 0) {
        return `${formatCurrencyNumber({ value: amount })}
            (${formatMessage({ id: 'Remboursement_TotalVsEncours' })})`
      }

      return formatMessage({
        id: 'Remboursement_ChoixDisponible_contentMontant',
        values: {
          montant: formatCurrencyNumber({ value: amount })
        }
      })
    }
  }

  const RenderHeader = useCallback(
    () => (
      <RefundsHeader
        displayCloseButton
        helpPoint
        helpPointContentHtml={getMessageRaw({
          id: 'Remboursement_needs_info_content'
        })}
        helpPointModalTitle={formatMessage({
          id: 'Remboursement_repartition_info_label'
        })}
        helpPointPosition="left"
        stepperItems={stepperItems}
        title={pageTitle}
      />
    ),
    []
  )

  const handleSubmit = () => {
    const eligibleDispositifsData = getEligibleDispositifs(
      requirementDecisionData.dispositifs,
      alreadyResponded
    )?.eligibleDispositifs
    const eligibleDispositifs = eligibleDispositifsData?.filter(
      dispositif => dispositif.isEligible === true
    )
    eligibleDispositifs && setEligibleDispositifs(eligibleDispositifs)
    if (eligibleDispositifs.length === 1) {
      onSelectDispositif(eligibleDispositifs)

      navigation.navigate(RouteNames.RefundsStack, {
        screen: RouteNames.RefundRepartition
      })
    } else if (eligibleDispositifs.length > 1) {
      navigation.navigate(RouteNames.RefundsStack, {
        screen: RouteNames.RefundPlanChoice
      })
    }
  }

  const renderRecapEligibility = useCallback(() => {
    const mappedElligibleDispositifs = elligibleDispositifs?.map(item => ({
      name: item.name,
      isEligible: true
    }))
    return (
      <EligibilityRecapCard
        cardDescription={formatMessage({
          id: 'Remboursement_Besoin_FiltreEligibilite_label'
        })}
        cardTitle={formatMessage({ id: 'Remboursement_needs_results_title' })}
        elligibleDispositifs={mappedElligibleDispositifs}
      />
    )
  }, [elligibleDispositifs, formatMessage])

  const renderButtonSubmit = () =>
    unRespondedQuestion.length === 0 && (
      <SimpleButton
        disabled={false}
        onPress={handleSubmit}
        title={formatMessage({ id: 'Remboursement_Besoin_Suivant_button' })}
        testId={locator._recover_savings._enter_request}
      />
    )

  const handleScrollToTop = useCallback(() => {
    if (isDesktop) {
      refDashboardContainer?.current?.scrollToTop()
    } else {
      refColumnsContainer?.current?.scrollToTop()
    }
  }, [refColumnsContainer, refDashboardContainer, isDesktop])

  const handelUpdateQuestion = useCallback(
    (responsePayload, index, canNotBeAsked) => {
      handleScrollToTop()

      if (
        responsePayload?.respondedPropertyFilter.name ===
        RefundDecisionPropertiesEnum.REPAYMENT_AVAILABLE
      ) {
        onUpdateQuestion(responsePayload, index, canNotBeAsked)
      } else {
        onUpdateUnavailableQuestion(responsePayload, index, canNotBeAsked)
      }
    },
    [onUpdateQuestion, handleScrollToTop, onUpdateUnavailableQuestion]
  )

  return (
    <View>
      <DashboardPageWrapperConnected
        cardContentStyle={{ backgroundColor: theme.colors.basics.white }}
        dashboardPageWrapperRef={refDashboardContainer}
        renderStickyMobileBottom={renderButtonSubmit}>
        <ColumnsContainer
          columnsContainerRef={refColumnsContainer}
          renderHeader={RenderHeader}
          renderRightDesktopColumn={renderRecapEligibility}>
          <RequirementDefinition
            dictionary={dictionary}
            handleDispositifsRcValidated={handleDispositifsRcValidated}
            handleRedirectToPersoneo={handleRedirectToPersoneo}
            isLoading={unavailableRefundDispositifTableLoading}
            onBackToQuestion={index => onBackToQuestion(index)}
            onGetUnavailableRefundDispositifTable={onGetUnavailableRefundDispositifTable}
            onUpdateQuestion={handelUpdateQuestion}
            renderEligibilityRecap={renderRecapEligibility()}
            renderMixDispositifsEligibleForUnavailable={renderMixDispositifsEligibleForUnavailable}
            renderSubmitButton={renderButtonSubmit()}
            requirementDecisionData={requirementDecisionData}
            selectedUnavailableOption={selectedUnavailableOption}
            showRefundEligibilityTotalAmount
          />
        </ColumnsContainer>
      </DashboardPageWrapperConnected>
    </View>
  )
}
