import React, { useCallback, useMemo, useState } from 'react';
import { View } from 'react-native';

import { Text } from '@ere-uilib/atoms';
import { CardResponse, TextField, useTranslation } from '@ere-uilib/index';

import { SimulationType, SimulationTypeOperation } from '../../AnnuitySimulator/sharedInterfaces';
import { AMOUNT_MASK } from '../../constants';
import { useStyles } from './useStyles';

interface Props {
  value: SimulationType | undefined;
  onSubmit: (data: SimulationType | undefined) => void;
  validator: (
    operation: SimulationTypeOperation | undefined
  ) => (amount: string | undefined) => boolean;
}

export const AnnuitySimulationType: React.FC<React.PropsWithChildren<Props>> = ({ value, onSubmit, validator }) => {
  const styles = useStyles();
  const [simulationType, setSimulationType] = useState<SimulationType | undefined>(value);
  const [hasRegexError, setHasRegexError] = useState<boolean>(false);
  const { formatMessage } = useTranslation();

  const isCapitalToAnnuity = useMemo(
    () => simulationType?.operation === SimulationTypeOperation.CapitalToAnnuity,
    [simulationType?.operation]
  );

  const isValid = useMemo(
    () => !!simulationType && validator(simulationType.operation)(simulationType.amount),
    [simulationType, validator]
  );

  const isButtonNextDisabled = useMemo(() => hasRegexError || !isValid, [hasRegexError, isValid]);

  const hasAmountError = useMemo(
    () => hasRegexError || (!!simulationType?.amount && !isValid),
    [hasRegexError, isValid, simulationType?.amount]
  );

  const handleChangeText = useCallback(
    (operation: SimulationTypeOperation) => (amount: string | undefined) =>
      setSimulationType(prev => ({
        ...prev,
        operation,
        amount
      })),
    []
  );

  const handleCleanField = useCallback(
    () => setSimulationType(prev => ({ ...prev, amount: undefined })),
    []
  );

  return (
    (<View>
      <CardResponse
        buttonAction={() => onSubmit(simulationType)}
        buttonTitle={formatMessage({
          id: 'PensionSimulatorStartSimulationContinueButton'
        })}
        headerTitle={formatMessage({ id: 'PensionSimulatorCapitalTitle' })}
        isButtonDisabled={!isCapitalToAnnuity || isButtonNextDisabled}>
        <Text variant="t3">{formatMessage({ id: 'PensionSimulatorCapitalTitleUnder' })}</Text>
        <View style={styles.formContainer}>
          <View style={styles.leftContainer}>
          <TextField
            containerStyle={styles.leftContainer}
            errored={isCapitalToAnnuity && hasAmountError}
            inputProps={{
              value: (isCapitalToAnnuity && simulationType?.amount) || '',
              placeholder: formatMessage({ id: 'PensionSimulatorCapitalInputLegend' }),
              onChangeText: handleChangeText(SimulationTypeOperation.CapitalToAnnuity),
              editable: true,
              keyboardType: 'numeric',
              mask: AMOUNT_MASK
            }}
            inputStyle={styles.leftInput}
            labelOnTop
            onCleanField={
              isCapitalToAnnuity && simulationType?.amount ? handleCleanField : undefined
            }
            regexError={setHasRegexError}
            regexPattern={/^[0-9]+$/}
            rightIconsStyle={styles.textFieldRightIcon}
          />
          </View>
          <View style={styles.rightContainer} />
        </View>
      </CardResponse>
      <CardResponse
        buttonAction={() => onSubmit(simulationType)}
        buttonTitle={formatMessage({
          id: 'PensionSimulatorStartSimulationContinueButton'
        })}
        containerStyle={styles.cardAnnuityToCapital}
        headerTitle={formatMessage({ id: 'PensionSimulatorMonthlyPaymentTitle' })}
        isButtonDisabled={isCapitalToAnnuity || isButtonNextDisabled}>
        <Text variant="t3">
          {formatMessage({ id: 'PensionSimulatorMonthlyPaymentTitleUnder' })}
        </Text>
        <View style={styles.formContainer}>
         <View style={styles.leftContainer}>
         <TextField
            errored={!isCapitalToAnnuity && hasAmountError}
            inputProps={{
              value: (!isCapitalToAnnuity && simulationType?.amount) || '',
              placeholder: formatMessage({ id: 'PensionSimulatorMonthlyPaymentInputLegend' }),
              onChangeText: handleChangeText(SimulationTypeOperation.AnnuityToCapital),
              editable: true,
              keyboardType: 'numeric',
              mask: AMOUNT_MASK
            }}
            inputStyle={styles.leftInput}
            labelOnTop
            onCleanField={
              !isCapitalToAnnuity && simulationType?.amount ? handleCleanField : undefined
            }
            regexError={setHasRegexError}
            regexPattern={/^[0-9]+$/}
            rightIconsStyle={styles.textFieldRightIcon}
          />
         </View>
          <View style={styles.rightContainer}>
          <TextField
            inputProps={{
              value: formatMessage({ id: 'PensionSimulatorMonthlyPaymentMonthlyPeriodicity' }),
              placeholder: formatMessage({ id: 'PensionSimulatorMonthlyPaymentPeriodicity' }),
              editable: false
            }}
            inputStyle={styles.rightInput}
            isDisabled
            labelOnTop
          />
          </View>
        </View>
      </CardResponse>
    </View>)
  );
};
