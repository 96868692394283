import { useTheme } from '@ere-uilib/styles';
import { useMemo } from 'react';
import { StyleSheet } from 'react-native';


export function useStyles() {
  const theme = useTheme();
  const styles = useMemo(() => StyleSheet.create({

    container: {
      backgroundColor: theme.colors.buttons.menuItemHover.c500,
      flexDirection: 'row',
      borderWidth: 0,
      marginRight: theme.metrics.spacing.s,
      marginTop: theme.metrics.spacing.s,
      borderRadius: theme.metrics.borderRadius.xs,
      paddingVertical: 8,
      alignItems: 'center',
      paddingHorizontal: theme.metrics.spacing.s
    },
    title: {
      lineHeight: undefined,
      color: theme.colors.basics.black,
      marginRight: theme.metrics.spacing.xs
    },

  }), [theme]);

  return styles;
}
