import { connect } from 'react-redux';

import { AppState } from '@modules/index';
import { getInvitationSelfcareEmail } from '@modules/invitation/selectors';
import {
  getApplicationSettings,
} from '@modules/settings/selectors';

import { SelfcareVisiogoSuccessPageComponent } from './SelfcareVisiogoSuccessPage.component';

const mapStateToProps = (state: AppState) => {
  return {
    applicationSettings: getApplicationSettings(state),
    selfcareEmail: getInvitationSelfcareEmail(state),
  };
};

export const SelfcareVisiogoSuccessPage = connect(
  mapStateToProps,
  null,
)(SelfcareVisiogoSuccessPageComponent);
