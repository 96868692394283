import { useTheme } from "@ere-uilib/styles"
import { useMemo } from "react";
import { StyleSheet } from "react-native";

export const useStyles = () => {

  const theme = useTheme();

  const styles = useMemo(() => StyleSheet.create({
    echeanceContainerRow: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      margin: theme.metrics.spacing.s

    }
  }), [theme]);

  return styles;
}