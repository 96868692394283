import React, { useEffect, useCallback, useMemo } from 'react';
import { View } from 'react-native';

import {
  TextField,
  useAmountFieldParseManager,
  useTranslation,
  Text,
  Slider
} from '@components/index'

import { AssigmentSelectionProps } from './interface'
import { useStyles } from './useStyles'
import { locator } from '@constants/locator';

export const AssigmentSelection: React.FC<React.PropsWithChildren<AssigmentSelectionProps>> = ({
  fullAmount,
  initialReinvestAmount,
  isRefund,
  isReinvest,
  minimumReinvestAmount,
  onChange,
  style
}) => {
  const styles = useStyles()
  const { formatMessage } = useTranslation()
  const isEditable = useMemo(()=>isRefund && isReinvest, [isRefund, isReinvest])

  const {
    amountNumber: reinvestAmountNumber,
    amountString: reinvestAmountString,
    setAmountString: setReinvestAmountString,
    parseAmountStringToNumber: parseReinvestAmountStringToNumber,
    parseAmountStringToLimitChar: parseReinvestAmountStringToLimitChar
  } = useAmountFieldParseManager({
    initialAmount: initialReinvestAmount,
    maxDecimal: 2,
  });

  const {
    amountNumber: refundAmountNumber,
    amountString: refundAmountString,
    setAmountString: setRefundAmountString,
    parseAmountStringToNumber: parseRefundAmountStringToNumber,
    parseAmountStringToLimitChar: parseRefundAmountStringToLimitChar
  } = useAmountFieldParseManager({
    initialAmount: fullAmount - initialReinvestAmount,
    maxDecimal: 2,
  });

  const handleReinvestAmountChange = useCallback((newReinvestAmountString:string) => {
    let newReinvestAmountNumber = parseReinvestAmountStringToNumber(
      parseReinvestAmountStringToLimitChar(newReinvestAmountString)
    ) || 0
    const isNewReinvestAmountNumberTooLow = newReinvestAmountNumber < minimumReinvestAmount
    if(isNewReinvestAmountNumberTooLow) {
      newReinvestAmountNumber = minimumReinvestAmount
    }
    
    const isNewReinvestAmountNumberTooBig = newReinvestAmountNumber > fullAmount
    if(isNewReinvestAmountNumberTooBig) {
      newReinvestAmountNumber = fullAmount
    }
    const finalReinvestAmountString = newReinvestAmountNumber.toString()

    const newRefundAmountString = (fullAmount - newReinvestAmountNumber).toString()

    const shouldUpdateStates = finalReinvestAmountString !== reinvestAmountString
    || newRefundAmountString !== refundAmountString;
    
    if( shouldUpdateStates ) {
      setReinvestAmountString(finalReinvestAmountString)
      setRefundAmountString(newRefundAmountString)
    }
  }, [
    setReinvestAmountString,
    setRefundAmountString,
    parseReinvestAmountStringToNumber,
    parseReinvestAmountStringToLimitChar,
    fullAmount,
    minimumReinvestAmount,
    refundAmountString,
    reinvestAmountString
  ])

  const handleRefundAmountChange = useCallback((newRefundAmountString:string) => {
    let newRefundAmountNumber = parseRefundAmountStringToNumber(
      parseRefundAmountStringToLimitChar(newRefundAmountString)
    ) || 0
    const maxRefund = fullAmount - minimumReinvestAmount
    const isNewRefundAmountNumberTooBig = newRefundAmountNumber > maxRefund
    if(isNewRefundAmountNumberTooBig) {
      newRefundAmountNumber = maxRefund
    }
    const finalRefundAmountString = isNewRefundAmountNumberTooBig?
      newRefundAmountNumber.toString()
      : newRefundAmountString
    
    const newReinvestAmountString = (fullAmount - newRefundAmountNumber).toString()
    const shouldUpdateStates = finalRefundAmountString !== refundAmountString 
    || newReinvestAmountString !== reinvestAmountString;

    if( shouldUpdateStates ) {
      setRefundAmountString(finalRefundAmountString)
      setReinvestAmountString(newReinvestAmountString)
    }
  }, [
    setRefundAmountString,
    setReinvestAmountString,
    parseRefundAmountStringToNumber,
    parseRefundAmountStringToLimitChar,
    fullAmount,
    minimumReinvestAmount,
    refundAmountString,
    reinvestAmountString
  ])

  const handleSliderChange = useCallback((newRefundAmountNumber:number) => {
    const newRefundAmountString = newRefundAmountNumber.toString()
    handleRefundAmountChange(newRefundAmountString)
  }, [handleRefundAmountChange])

  useEffect(() => {
    onChange({
      reinvestAmount: reinvestAmountNumber || 0,
      refundAmount: refundAmountNumber || 0,
    })
  }, [
    reinvestAmountNumber,
    refundAmountNumber,
    onChange
  ])
  
  return (
    <View style={style}>
      <View style={styles.fieldsContainer}>
        {isReinvest && (
          <View style={styles.fieldContainer}>
            <TextField
              inputProps={{
                value: reinvestAmountString || '0',
                placeholder: formatMessage({ id: 'PIDefinitionBesoinInvestirMontantLabel' }),
                onChangeText: handleReinvestAmountChange,
                editable: isEditable,
                keyboardType: 'numeric',
              }}
              inputStyle={styles.fieldInput}
              labelOnTop
              labelStyle={styles.fieldLabel}
              style={styles.field}
              testId={locator._pi._invest_field}
            />
            <View
              pointerEvents={'none'}
              style={styles.currencyTextContainer}>
              <Text 
                style={styles.currencyText}
                variant='t2' 
              >
                {"€"}
              </Text>
            </View>
          </View>
        )}
        {isRefund && isReinvest && (
          <View style={styles.fieldsSeparatorText}>
            <Text 
              variant='t4' 
              weight='light'
            >
              {formatMessage({ id: 'PIDefinitionBesoinChoixEtOuLabel' })}
            </Text>
          </View> 
        )}
        {isRefund &&(
          <View style={styles.fieldContainer}>
            <TextField
              inputProps={{
                value: refundAmountString || '0',
                placeholder: formatMessage({ id: 'PIDefinitionBesoinPercevoirMontantLabel' }),
                onChangeText: handleRefundAmountChange,
                editable: isEditable,
                keyboardType: 'numeric',
              }}
              inputStyle={styles.fieldInput}
              labelOnTop
              labelStyle={styles.fieldLabel}
              style={styles.field}
              testId={locator._pi._receive_field}
            />
            <View
              pointerEvents={'none'}
              style={styles.currencyTextContainer}>
              <Text 
                style={styles.currencyText}
                variant='t2' 
              >
                {"€"}
              </Text>
            </View>
          </View>
        )}
      </View>
      {isEditable && (
        <Slider
          maxAcceptedValue={fullAmount - minimumReinvestAmount}
          maxValue={fullAmount}
          minValue={0}
          onChange={handleSliderChange}
          step={1}
          value={refundAmountNumber || 0}
        />
      )}
    </View>
  );
};
