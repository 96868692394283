interface PendingTaskContext {
  demandId: string;
  campaignId?: string;
  NotBlocking?: string;
  content?: string;
  imageLargeUrl?: string;
  imageSmallUrl?: string;
  isBlocking?: boolean;
  title?: string;
}
export interface PendingTask {
  taskType: TaskTypeEnum;
  context: PendingTaskContext
}

export interface PendingTasksResponse {
  data: PendingTask[];
}

// Action types
export enum PendingTasksActionsType {
  GET_PENDING_TASKS_REQUEST = 'GET_PENDING_TASKS_REQUEST',
  GET_PENDING_TASKS_FAILURE = 'GET_PENDING_TASKS_FAILURE',
  GET_PENDING_TASKS_SUCCESS = 'GET_PENDING_TASKS_SUCCESS',
}

export interface GetPendingTasksRequest {
  type: PendingTasksActionsType.GET_PENDING_TASKS_REQUEST,
}

export interface GetPendingTasksFailure {
  type: PendingTasksActionsType.GET_PENDING_TASKS_FAILURE,
  error: PendingTasksError
}

export interface GetPendingTasksSuccess {
  type: PendingTasksActionsType.GET_PENDING_TASKS_SUCCESS,
  pendingTasks: PendingTask[]
}

export enum TaskTypeEnum {
  CAMPAIGN = 'Campaign',
  EMAIL = 'ConfirmEmail',
  MOBILE = 'ConfirmMobile',
  USER_PREFERENCES = 'UserPreferences',
  PREFERENCES_OPTIN = 'OptIn',
  BLOCKING = "Unavailability"
}

export interface PendingTasksError {
  code: string;
  correlationId: string;
  message: string;
  innerMessage: string;
}

export type PendingTasksActions = 
  GetPendingTasksSuccess
  | GetPendingTasksFailure
  | GetPendingTasksRequest