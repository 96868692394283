import React from 'react';
import { View, ScrollView } from 'react-native';

import {
  DashboardPageWrapperConnected,
  useTranslation,
  RoundedIcon,
  NavigationHeader,
  ColumnsContainer,
  Paragraph,
} from '@components/index';
import { createUseStyles, useScreenSizes, useTheme } from '@ere-uilib/styles'
import { RouteNames } from '@constants/navigation';
import { IconEnum } from '@ere-uilib/enums';
import { ModalSuccess } from '@ere-uilib/organisms/ModalSuccess';

import {
  EditPasswordCheckComponent,
} from './components';
import { EditPasswordPageProps } from './interfaces';
import { getStyles } from './styles';
import { locator } from '@constants/locator';
import {useNavigation} from "@react-navigation/native";
import {StackNavigationProp} from "@react-navigation/stack";
import {AppNavigatorInterface} from "@navigation/Interfaces";

export const EditPasswordPage: React.FC<EditPasswordPageProps> = ({
  appSettings,
  error,
  isApiLoading,
  isDisplaySuccessModal,
  editPasswordError,
  onCloseSuccessModal,
  onSetProfileUserPasswordChangeRequest,
}) => {
  const { formatMessage, getMessageRaw } = useTranslation();
  const theme = useTheme();
  const navigation = useNavigation<StackNavigationProp<AppNavigatorInterface>>();
  const { isMobile, isTablet } = useScreenSizes();
  const styles = useStyles({ theme, isMobile, isTablet }, {});

  const RenderHeader = () => {
    return (
      <NavigationHeader
        displayBackButton={true}
        helpPoint={true}
        helpPointContentHtml={getMessageRaw({
          id: 'Profil_ChangePassword_info_label',
        })}
        helpPointModalTitle={formatMessage({
          id: 'Profil_ChangePassword_info_title',
        })}
        helpPointPosition={'right'}
        onGoBack={() => navigation.navigate(RouteNames.BottomTabNavigator, {
          screen: RouteNames.ProfileStack,
          params: {
            screen: RouteNames.Profile,
          },
        })}
        title={formatMessage({ id: 'Profil_ChangePassword_header_title' })}
      />
    );
  };

  return (
    <DashboardPageWrapperConnected>
      <ColumnsContainer
        containerStyle={styles.containerStyle}
        hasRenderRightDesktopColumn={false}
        renderHeader={RenderHeader}
      >
        <View style={styles.contentContainerStyle}>
          <RoundedIcon
            iconColor={theme?.colors.buttons.classic.c500}
            iconName={IconEnum.PASSWORD}
          />
          <Paragraph
            testId={locator._profile._change_password._title}
            style={styles.paragraphChangePsswrdStyle}
            variant="t3"
          >
            {formatMessage({ id: 'Profil_ChangePassword_header_label' })}
          </Paragraph>
          <View style={styles.passwordContainerStyle}>
            <ScrollView
              showsHorizontalScrollIndicator={false}
              showsVerticalScrollIndicator={false}
            >
              <View style={styles.passwordContentStyle}>
                <EditPasswordCheckComponent
                  appSettings={appSettings}
                  error={editPasswordError || error}
                  isApiLoading={isApiLoading}
                  onPress={(currentPassword, newPassword) => {
                    onSetProfileUserPasswordChangeRequest(currentPassword, newPassword);
                  }}
                />
              </View>
            </ScrollView>
          </View>
        </View>
      </ColumnsContainer>
      <ModalSuccess
        buttonTitle={formatMessage({ id: 'Profil_ChangePassword_Confirm_button' })}
        iconName="forget_password_validated"
        onPressButton={() => {
          onCloseSuccessModal();
          navigation.replace(RouteNames.BottomTabNavigator, {
            screen: RouteNames.ProfileStack,
            params: {
              screen: RouteNames.Profile,
            },
          });
        }}
        subTitle={formatMessage({ id: 'Profil_ChangePassword_Confirm_title' })}
        title={formatMessage({ id: 'Profil_email_edit_confirm_title' })}
        visible={isDisplaySuccessModal}
      />
    </DashboardPageWrapperConnected>
  );
};

const useStyles = createUseStyles(getStyles);
