import {
  AuthActionType,
  AuthenticateAction
} from '@modules/auth/actions/authActionsTypes';
import {
  CampaignAction,
  CampaignActionsType
} from './actions/campaignActionsTypes';
import { CampaignState } from './types';

export const initialState: CampaignState = {
  submittedCampaignIds: [],
  isSubmitted: false,
  campaignData: undefined,
  error: {
    code: '',
    correlationId: '',
    message: '',
    innerMessage: '',
  },
};

export default function CampaignReducer(
  state: CampaignState = initialState,
  action: CampaignAction | AuthenticateAction,
): CampaignState {
  switch (action?.type) {
  // TODO CASE FOR ACTION
  case CampaignActionsType.GET_USER_CAMPAIGN_REQUEST:
    return {
      ...state,
      campaignData: initialState.campaignData,
      isSubmitted: false,
      error: initialState.error
    };
  case CampaignActionsType.GET_USER_CAMPAIGN_SUCCESS:
    return {
      ...state,
      campaignData: action.campaignData,
    };
  case CampaignActionsType.SUBMIT_USER_CAMPAIGN_REQUEST:
    return {
      ...state,
      isSubmitted: false,
      error: initialState.error
    };
  case CampaignActionsType.SUBMIT_USER_CAMPAIGN_SUCCESS:
    return {
      ...state,
      submittedCampaignIds: [...state.submittedCampaignIds, action.campaignId],
      ...(!action.isLaterAnswer && {
        isSubmitted: true,
      })
    }
  case CampaignActionsType.SUBMIT_USER_CAMPAIGN_FAILURE:
  case CampaignActionsType.GET_USER_CAMPAIGN_FAILURE:
    return {
      ...state,
      error: action.error,
      isSubmitted: false,
    };
  case CampaignActionsType.RESET_CAMPAIGN_REDUCER:
  case AuthActionType.LOGOUT_CALLBACK_SUCCESS:
    return initialState;
  default:
    return state;
  }
}
