
import { ArbitrationTypes } from '@constants/arbitrations';
import { ResponseType } from '@modules/common/types';

import {
  ArbitrationDecisionPropertiesEnum,
  ArbitrationQuestionTypesEnum,
} from '../constants';
import {
  ArbitrationState
} from '../types';
import {
  getDecisionAllResponses,
  camputePlansEligibility
} from '../utils';

const getDecisionPlanResponses = (decision: ArbitrationState['decision']) => {
  const allAnswers = getDecisionAllResponses(decision)
  const isOADActive = allAnswers[ArbitrationDecisionPropertiesEnum.OAD_REALLOCATION] === true
  const dispositifsByArbitrationTypes = camputePlansEligibility(
    decision.dispositifs
  );
  let eligiblePlans = dispositifsByArbitrationTypes.flowDispositifs

  if (isOADActive) {
    eligiblePlans = dispositifsByArbitrationTypes.oadDispositifs
  } else if (decision.reallocationType === ArbitrationTypes.stock) {
    eligiblePlans = dispositifsByArbitrationTypes.stockDispositifs
  }

  const planResponses: ResponseType[] = eligiblePlans.map(dispositif => {
    const hasOperationInProgress = (dispositif.advancedProperties && dispositif.advancedProperties.HasRepaymentOrArbitrationTransactionInProgress) || false
    const isDisabled = isOADActive && hasOperationInProgress
    const arbitrationAmount = isOADActive ? dispositif.advancedProperties.OadReallocationAmount : dispositif.encours
    const globalAmount = isOADActive ? dispositif.encours : undefined

    return {
      options: [],
      propertyFilter: {
        name: ArbitrationDecisionPropertiesEnum.PLAN_ID,
        value: dispositif.id
      },
      advancedProperties: {
        HasRepaymentOrArbitrationTransactionInProgress: hasOperationInProgress,
      },
      title: dispositif.name,
      type: '',
      amount: arbitrationAmount,
      disabled: isDisabled,
      headerAmount: globalAmount,
    }
  })

  return planResponses;
}

export const updateArbitrationDecisionPlanQuestion = (
  decision: ArbitrationState['decision']
): ArbitrationState['decision'] => {
  const newQuestions = decision.questions.map(question => {
    let newQuestion = question

    switch (question.type) {
      case ArbitrationQuestionTypesEnum.PLAN_CHOICE:
        const planResponses = getDecisionPlanResponses(decision)
        newQuestion = {
          ...newQuestion,
          responded: undefined,
          canNotBeAsked: undefined,
          responses: planResponses,
          implicitResponseEnabled: planResponses && planResponses.length === 1 && !planResponses[0].advancedProperties?.HasRepaymentOrArbitrationTransactionInProgress
        }
        break;

      default:
        break;
    }

    return newQuestion
  })

  return {
    ...decision,
    questions: newQuestions
  }
}
