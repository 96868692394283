import { Context, Styles } from './interfaces';

export const getStyles = (
  context?: Context,
  style?: Styles,
): Styles => {

  let labelStyleDesignStyle: Styles['labelStyle'] = {};
  let buttonStyleStyleDesignStyle: Styles['buttonStyle'] = {};
  if (context?.isColoredBackground) {
    labelStyleDesignStyle = {
      color: !context?.disabled ?
        context?.theme?.colors.basics.white
        : context?.theme?.colors.basics.grey.c200,
    };
    buttonStyleStyleDesignStyle = {
      ...((context?.checked) ? {
        ...(!context?.disabled ? {
          borderWidth: 1,
          borderColor: context?.theme?.colors.basics.white,
        } : {
          backgroundColor: context?.theme?.colors.basics.grey.c400,
        }),
      } : {
        ...(!context?.disabled ? {
          ...(!context?.hovered ? {
            borderWidth: 1,
            borderColor: context?.theme?.colors.basics.white,
          } : {
            borderWidth: 1,
            borderColor: context?.theme?.colors.basics.white,
            backgroundColor: `${context?.theme.colors.basics.white}CE`,
          }),
        } : {
          backgroundColor: context?.theme?.colors.basics.grey.c200,
        }),
      }),
    };
  } else {
    labelStyleDesignStyle = {
      color: !context?.disabled ?
        context?.theme?.colors.basics.black
        : context?.theme?.colors.basics.grey.c600,
    };
    buttonStyleStyleDesignStyle = {
      ...(context?.checked ? {
        ...(!context?.disabled ? {
          backgroundColor: context?.theme?.colors.basics.primary.c500,
        } : {
          backgroundColor: context?.theme?.colors.basics.grey.c500,
        }),
      } : {
        ...(!context?.disabled ? {
          ...(!context?.hovered ? {
            borderWidth: 1,
            borderColor: context?.theme?.colors.basics.black,
          } : {
            borderWidth: 1,
            borderColor: context?.theme?.colors.basics.primary.c500,
            backgroundColor: context?.theme.colors.basics.primary.c500,
          }),
        } : {
          backgroundColor: context?.theme?.colors.basics.grey.c200,
        }),
      }),
    };
  }

  return {
    container: [
      {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: context?.theme.metrics.spacing.none,
      },
      style?.container,
    ],
    labelStyle: [
      {
        marginLeft: context?.theme?.metrics.spacing.s,
      },
      labelStyleDesignStyle,
      style?.labelStyle,
    ],
    buttonStyle: [
      {
        height: 20,
        width: 20,
        borderRadius: context?.theme?.metrics.borderRadius.full,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: context?.pressed ? 0.5 : 1
      },
      buttonStyleStyleDesignStyle,
      style?.buttonStyle,
    ],
    subButtonStyle: [
      {
        height: 10,
        width: 10,
        borderRadius: context?.theme?.metrics.borderRadius.full,
      },
      context?.checked ? {
        ...(!context?.disabled ? {
          backgroundColor: context?.theme?.colors.basics.white,
        } : {
          backgroundColor: context?.theme?.colors.basics.grey.c200,
        }),
      } : {
        ...(!context?.disabled ? {
          ...(!context?.hovered && {
            backgroundColor: 'transparent',
          }),
        } : {
          backgroundColor: context?.theme?.colors.basics.grey.c200,
        }),
      },
      style?.subButtonStyle,
    ],
  };
};
