import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

export function useStyles() {
  const theme = useTheme();
  const subHeaderStyle = useMemo(() => StyleSheet.create({
    container: {
      display: 'flex',
      flex: 1,
      backgroundColor: theme.colors.basics.primary.c200,
    },
    imageBackground: {
      height: 92,
      position: 'absolute',
      zIndex: 1,
    },
    contentContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: theme.metrics.spacing.m,
      width: '100%',
    },
    detailsContainer: {
      alignSelf: 'center',
      maxWidth: 400,
      flex: 1,
      width: '100%',
      padding: theme.metrics.spacing.m,
    },
    headerContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
    header: {
      flex: 1,
      flexDirection: 'row',
      width: '100%',
    },
    leftHeader: {
      flex: 1,
    },
    rightHeader: {
      flex: 1,
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
    leftContentPart: {
      flexShrink: 1,
      flexGrow: 1,
    },
    rightContentPart: {
      flexShrink: 1,
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    taxationDetailsLink: {
      textDecorationLine: 'none',
      alignItems: 'center',
      display: 'flex',
      fontSize: 14,
    },
    taxationDetails: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: theme.metrics.spacing.m,
      width: '100%',
    },
    taxationDetailsLeft: {
      flex: 1,
    },
    taxationDetailsRightRow: {
      flexDirection: 'row',
      flex: 1,
      justifyContent: 'flex-end',
    },
    valueTexts: {
      fontFamily: theme.fonts.fontFamily.regular,
    },
    dateCreationContainer: {
      marginBottom: theme.metrics.spacing.xs,
    },
    nextDateText: {
      textAlign: 'right',
    },
  }), [theme]);

  const styles = useMemo(() => StyleSheet.create({
    buttonContainer: {
      alignSelf: 'center',
      maxWidth: 400,
      width: '100%',
      flexDirection: 'row',
    },
    buttonContainerMobile: {
      paddingHorizontal: theme?.metrics.spacing.s,
    },
    buttonContainerDesktop: {
      marginTop: theme?.metrics.spacing.xxl,
      paddingHorizontal: theme.metrics.spacing.m,
    },
    bankAccountHeader: {
      backgroundColor: theme.colors.basics.white,
      borderBottomColor: theme.colors.basics.grey.c200,
      borderBottomWidth: 1,
    },
    filetStepperContainer: {
      maxWidth: 400,
      alignSelf: 'center',
      width: '100%',
      paddingHorizontal: theme.metrics.spacing.s,
      marginTop: theme.metrics.spacing.l,
    },
  }), [theme]);

  const contentStyles = useMemo(() => StyleSheet.create({
    filetContainer: {
      flex: 1,
      marginRight: theme.metrics.spacing.xm,
    },
    fromContent: {
      marginLeft: theme.metrics.spacing.xm,
      width: '100%',
      marginBottom: theme.metrics.spacing.xl,
    },
    filetText: {
      marginBottom: theme.metrics.spacing.xm,
    },
    bankAccountHeader: {
      backgroundColor: theme.colors.basics.white,
      borderBottomColor: theme.colors.basics.grey.c200,
      borderBottomWidth: 1,
    },
    bankAccountContainer: {
      marginLeft: theme.metrics.spacing.s,
      marginTop: theme.metrics.spacing.xm,
      marginBottom: theme.metrics.spacing.xm,
    },
  }), [theme]);

  return {
    styles,
    subHeaderStyle,
    contentStyles,
  };
}
