import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { StyleProp, TextStyle, ViewStyle, View } from 'react-native';

import {
  useTheme,
  ThemeType,
  useScreenSizes,
  Link,
  DialOncePressable,
  OfflineAnnounceLayout360,
  FontIcon,
} from '@components/index';
import { createUseStyles } from '@ere-uilib/styles';

import { RouteNames } from '@constants/navigation';
import { useTranslation } from '@ere-uilib/translations';
import { SelfCareEmailType } from '@modules/invitation/types';
import { ApplicationSettingsState } from '@modules/settings/types';
import { useLogoNavigation } from '@utils/index';

interface Props {
  selfcareEmail: SelfCareEmailType;
  applicationSettings: ApplicationSettingsState;
}

type AccountCreationSucessPageStyles = {
  bottomLinksContainer?: StyleProp<ViewStyle>;
  iconCloseButton?: StyleProp<ViewStyle>;
  helpLinkStyle?: StyleProp<TextStyle>;
};

type StylesContext = {
  theme: ThemeType;
  isMobile: boolean;
  isTablet: boolean;
};

export const SelfcareSuccessPageTemplate: React.FC<React.PropsWithChildren<Props>> = ({
  selfcareEmail,
  applicationSettings,
}) => {
  const navigation = useNavigation();
  const theme = useTheme();
  const { isMobile, isTablet } = useScreenSizes();
  const styles = useStyles({ theme, isMobile, isTablet }, {});
  const { formatMessage } = useTranslation();
  const { logoNavigateTo } = useLogoNavigation();

  const handlePressClose = () => {
    navigation.navigate(RouteNames.OidcLogoutCallbackRedirect);
  };
  const renderCloseButton = () => (
    <FontIcon
      name="fermer"
      onPress={handlePressClose}
      size={theme.metrics.iconSizes.xxm}
      style={styles.iconCloseButton}
    />
  );

  const renderActions = ()=>{
    return (
      <View style={styles.bottomLinksContainer}>
        <DialOncePressable
          integrationKey={applicationSettings.HelpDialOnceIntegrationKey || ''}
          targetedFlowId={applicationSettings.HelpLoginPageDialOnceId || ''}
        >
          <Link
            onPress={()=>{}}
            textStyle={styles.helpLinkStyle}
          >
            {formatMessage({ id: 'verify-identity_help' })}
          </Link>
        </DialOncePressable>
      </View>
    );
  };

  return (
      <OfflineAnnounceLayout360
        actions={renderActions}
        description={[
          formatMessage({ id: 'EerSelfcareStep3Ligne1Label' }),
          formatMessage({
            id: 'EerSelfcareStep3Ligne2Label',
            values: { email: selfcareEmail },
          }),
        ]}
        onLogoPress={logoNavigateTo}
        renderInOfflinePageLayoutEnd={renderCloseButton}
        sourceName={'installmentDone'}
        title={formatMessage({ id: 'EerSelfcareStep3PageTitle' })}
      />

  );
};

const getStyles = (
  context?: StylesContext,
  style?: AccountCreationSucessPageStyles,
): AccountCreationSucessPageStyles => ({
  bottomLinksContainer: [
    {
      alignItems: 'center',
      marginBottom: context?.theme.metrics.spacing.m,
    },
  ],
  helpLinkStyle: [
    {
      alignItems: 'center',
    },
  ],
  iconCloseButton: [
    {
      position: 'absolute',
    },
    context?.isMobile ? {
      top: context?.theme.metrics.spacing.xm,
      right: context?.theme.metrics.spacing.m,
    } : {
      top: context?.theme.metrics.spacing.xl,
      right: context?.theme.metrics.spacing.l,
    },
  ],

});

const useStyles = createUseStyles(getStyles);
