import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import { getEditorialContentRequest } from '@modules/profile/actions/profileActions';
import { ProfileActionsType } from '@modules/profile/actions/profileActionsTypes';
import { getConsents, getEditorialContent, getProfileError } from '@modules/profile/selectors';

import { EditorialPage as EditorialPageToConnect } from './EditorialPage.component';
import {getLoadingState} from "@modules/funds/selectors";
import {getUser} from "@modules/auth/selectors";
import {getMenuFooter} from "@modules/legal/selectors";
import {getMenuFooterRequest} from "@modules/legal/actions/legalActions";

export const EditorialPage: React.FC = () => {

  const editorialContent = useSelector(getEditorialContent);
  const consents = useSelector(getConsents);
  const user = useSelector(getUser);
  const footerMenu = useSelector(getMenuFooter);
  const isGetEditorialContentLoading = useSelector(getLoadingState(ProfileActionsType.GET_EDITORIAL_CONTENT_REQUEST));
  const error = useSelector(getProfileError);
  const disptach = useDispatch();

  return(<EditorialPageToConnect
      editorialContent={editorialContent}
      onGetEditorialContent={getEditorialContentRequest}
      consents={consents}
      user={user}
      error={error}
      isGetEditorialContentLoading={isGetEditorialContentLoading}
      footerMenu={footerMenu}
      onGetMenuFooter={() => disptach(getMenuFooterRequest())}/>);
};
