import * as actions from '@modules/dashboard/actions/dashboardActionsTypes'
import { DashboardActionsType } from '@modules/dashboard/actions/dashboardActionsTypes'
import {
  AccountsErrorState,
  AccountsState,
  AccountState,
  AllocationsState,
  AvailabilitiesState,
  BudgetInsightErrorType,
  BudgetInsightState,
  CibledContributionContentState,
  CibledContributionErrorState,
  ContributionByCompanyType,
  ContributionByOperationType,
  OCDState,
  RecentOperationsState,
  ToolsFlowTypeEnum,
  ToolState
} from '@modules/dashboard/types'
import { ErrorObjectState } from '@modules/error/types'

export function getDashboardDataRequest(): actions.GetDashboardRequestAction {
  return {
    type: DashboardActionsType.GET_DASHBOARD_REQUEST
  }
}

export function getDashboardDataFailure(
  error: AccountsErrorState
): actions.GetDashboardFailureAction {
  return {
    type: DashboardActionsType.GET_DASHBOARD_FAILURE,
    error
  }
}
export function getDashboardDataSuccess(): actions.GetDashboardSuccessAction {
  return {
    type: DashboardActionsType.GET_DASHBOARD_SUCCESS
  }
}
export function getAccountsRequest(): actions.GetAccountsRequestAction {
  return {
    type: DashboardActionsType.GET_ACCOUNTS_REQUEST
  }
}

export function getAccountsSuccess(accounts: AccountsState): actions.GetAccountsSuccessAction {
  return {
    type: DashboardActionsType.GET_ACCOUNTS_SUCCESS,
    accounts
  }
}

export function getAccountsFailure(error: AccountsErrorState): actions.GetAccountsFailureAction {
  return {
    type: DashboardActionsType.GET_ACCOUNTS_FAILURE,
    error
  }
}

// get account data (repartitions ...)

export function getAccountDataRequest(): actions.GetAccountDataRequestAction {
  return {
    type: DashboardActionsType.GET_ACCOUNTS_DATA_REQUEST
  }
}

export function getAccountDataFailure(
  error: AccountsErrorState
): actions.GetAccountDataFailureAction {
  return {
    type: DashboardActionsType.GET_ACCOUNTS_DATA_FAILURE,
    error
  }
}

/// selected company request

export function putSelectedCompanyRequest(
  company: AccountState
): actions.PutSelectedCompanyRequestAction {
  return {
    type: DashboardActionsType.PUT_SELECTED_COMPANY_REQUEST,
    company
  }
}

export function putSelectedCompanySuccess(
  company: AccountState
): actions.PutSelectedCompanySuccessAction {
  return {
    type: DashboardActionsType.PUT_SELECTED_COMPANY_SUCCESS,
    company
  }
}

/// Availabilities

export function getAvailabilitiesRequest(
  companyId: string
): actions.GetAvailabilitiesRequestAction {
  return {
    type: DashboardActionsType.GET_AVAILABILITIES_REQUEST,
    companyId
  }
}

export function getAvailabilitiesSuccess(
  availabilities: AvailabilitiesState
): actions.GetAvailabilitiesSuccessAction {
  return {
    type: DashboardActionsType.GET_AVAILABILITIES_SUCCESS,
    availabilities
  }
}

export function getAvailabilitiesFailure(
  error: AccountsErrorState
): actions.GetAvailabilitiesFailureAction {
  return {
    type: DashboardActionsType.GET_AVAILABILITIES_FAILURE,
    error
  }
}

/// allocations

export function getAllocationsRequest(companyId: string): actions.GetAllocationsRequestAction {
  return {
    type: DashboardActionsType.GET_ALLOCATIONS_REQUEST,
    companyId
  }
}

export function getAllocationsSuccess(
  allocations: AllocationsState[]
): actions.GetAllocationsSuccessAction {
  return {
    type: DashboardActionsType.GET_ALLOCATIONS_SUCCESS,
    allocations
  }
}

export function getAllocationsFailure(
  error: AccountsErrorState
): actions.GetAllocationsFailureAction {
  return {
    type: DashboardActionsType.GET_ALLOCATIONS_FAILURE,
    error
  }
}

export function dashboardInitError(): actions.DashboardInitErrorAction {
  return {
    type: DashboardActionsType.DASHBOARD_INIT_ERROR
  }
}

export function availabilitiesInitError(): actions.AvailabilitiesInitErrorAction {
  return {
    type: DashboardActionsType.AVAILABILITIES_INIT_ERROR
  }
}

export function allocationsInitError(): actions.AllocationsInitErrorAction {
  return {
    type: DashboardActionsType.ALLOCATIONS_INIT_ERROR
  }
}

export function ocdInitError(): actions.OCDInitErrorAction {
  return {
    type: DashboardActionsType.OCD_INIT_ERROR
  }
}

// Tool

export function getToolRequest(
  flow: ToolsFlowTypeEnum = ToolsFlowTypeEnum.DASHBOARD
): actions.GetToolRequestAction {
  return {
    type: DashboardActionsType.GET_TOOL_REQUEST,
    flow
  }
}

export function getToolSuccess(
  tools: ToolState[],
  flow: ToolsFlowTypeEnum
): actions.GetToolSuccessAction {
  return {
    type: DashboardActionsType.GET_TOOL_SUCCESS,
    tools,
    flow
  }
}

export function getToolFailure(error: AccountsErrorState): actions.GetToolFailureAction {
  return {
    type: DashboardActionsType.GET_TOOL_FAILURE,
    error
  }
}

// OCD

export function getOCDRequest(): actions.GetOCDRequestAction {
  return {
    type: DashboardActionsType.GET_OCD_REQUEST
  }
}

export function getOCDSuccess(OCD: OCDState[]): actions.GetOCDSuccessAction {
  return {
    type: DashboardActionsType.GET_OCD_SUCCESS,
    OCD
  }
}

export function getOCDFailure(error: AccountsErrorState): actions.GetOCDFailureAction {
  return {
    type: DashboardActionsType.GET_OCD_FAILURE,
    error
  }
}

// Recent Operations

export function getRecentOperationsRequest(
  companyId: string
): actions.GetRecentOperationsRequestActions {
  return {
    type: DashboardActionsType.GET_RECENT_OPERATIONS_REQUEST,
    companyId
  }
}

export function getRecentOperationsSuccess(
  recentOperations: RecentOperationsState[]
): actions.GetRecentOperationSuccessAction {
  return {
    type: DashboardActionsType.GET_RECENT_OPERATIONS_SUCCESS,
    recentOperations
  }
}

export function getRecentOperationsFailure(
  error: AccountsErrorState
): actions.GetRecentOperationsFailureAction {
  return {
    type: DashboardActionsType.GET_RECENT_OPERATIONS_FAILURE,
    error
  }
}

export function recentOperationsInitError(): actions.RecentOperationsInitErrorAction {
  return {
    type: DashboardActionsType.RECENT_OPERATIONS_INIT_ERROR
  }
}

// abondment

export function getContributionRequest(): actions.GetContributionRequestAction {
  return {
    type: DashboardActionsType.GET_CONTRIBUTION_REQUEST
  }
}

export function getContributionSuccess(
  contribution: ContributionByCompanyType | null
): actions.GetContributionSuccessAction {
  return {
    type: DashboardActionsType.GET_CONTRIBUTION_SUCCESS,
    contribution
  }
}

export function getContributionFailure(
  error: AccountsErrorState
): actions.GetContributionFailureAction {
  return {
    type: DashboardActionsType.GET_CONTRIBUTION_FAILURE,
    error
  }
}

// abondment by operation

export function getContributionByOperationRequest(): actions.GetContributionByOperationRequestAction {
  return {
    type: DashboardActionsType.GET_CONTRIBUTION_BY_OPERATION_REQUEST
  }
}

export function getContributionByOperationSuccess(
  contributionByOperation: ContributionByOperationType | null
): actions.GetContributionByOperationSuccessAction {
  return {
    type: DashboardActionsType.GET_CONTRIBUTION_BY_OPERATION_SUCCESS,
    contributionByOperation
  }
}

export function getContributionByOperationFailure(
  error: AccountsErrorState
): actions.GetContributionByOperationFailureAction {
  return {
    type: DashboardActionsType.GET_CONTRIBUTION_BY_OPERATION_FAILURE,
    error
  }
}
// backoffice implicit connection
export function backOfficeImplicitConnectionRequest(
  token: string,
  clientId: string
): actions.BackOfficeImplicitConnectionAction {
  return {
    type: DashboardActionsType.BACK_OFFICE_IMPLICIT_CONNECTION,
    token,
    clientId
  }
}

// BI
export function getBudgetInsightUrlRequest(): actions.GetBudgetInsightUrlActionRequest {
  return {
    type: DashboardActionsType.GET_BUDGET_INSIGHT_URL_REQUEST
  }
}

export function getBudgetInsightUrlSuccess(
  budgetInsightUrl: string
): actions.GetBudgetInsightUrlActionSuccess {
  return {
    type: DashboardActionsType.GET_BUDGET_INSIGHT_URL_SUCCESS,
    budgetInsightUrl
  }
}

export function getBudgetInsightUrlFailure(
  error: AccountsErrorState
): actions.GetBudgetInsightUrlActionFailure {
  return {
    type: DashboardActionsType.GET_BUDGET_INSIGHT_URL_FAILURE,
    error
  }
}

// BI Get Accounts Action

export function getBudgetInsightAccountsRequest(): actions.GetBudgetInsightAccountsRequestAction {
  return {
    type: DashboardActionsType.GET_BUDGET_INSIGHT_ACCOUNTS_REQUEST
  }
}

export function getBudgetInsightAccountsSuccess(
  budgetInsightAccounts: BudgetInsightState[]
): actions.GetBudgetInsightAccountsSuccessAction {
  return {
    type: DashboardActionsType.GET_BUDGET_INSIGHT_ACCOUNTS_SUCCESS,
    budgetInsightAccounts
  }
}

export function getBudgetInsightAccountsFailure(
  error: BudgetInsightErrorType
): actions.GetBudgetInsightAccountsFailureAction {
  return {
    type: DashboardActionsType.GET_BUDGET_INSIGHT_ACCOUNTS_FAILURE,
    error
  }
}

export function getBudgetInsightManagerUrlRequest(): actions.GetBudgetInsightManagerUrlActionRequest {
  return {
    type: DashboardActionsType.GET_BUDGET_INSIGHT_MANAGER_URL_REQUEST
  }
}

export function getBudgetInsightManagerUrlSuccess(
  budgetInsightManagerUrl: string
): actions.GetBudgetInsightManagerUrlActionSuccess {
  return {
    type: DashboardActionsType.GET_BUDGET_INSIGHT_MANAGER_URL_SUCCESS,
    budgetInsightManagerUrl
  }
}

export function getBudgetInsightManagerUrlFailure(
  error: AccountsErrorState
): actions.GetBudgetInsightManagerUrlActionFailure {
  return {
    type: DashboardActionsType.GET_BUDGET_INSIGHT_MANAGER_URL_FAILURE,
    error
  }
}

export function getSSORedirectionUrlRequest(): actions.GetSSORedirectionUrlActionRequest {
  return {
    type: DashboardActionsType.GET_SSO_REDIRECT_URL_REQUEST
  }
}

export function getSSORedirectionUrlSuccess(
  data: string
): actions.GetSSORedirectionUrlActionSuccess {
  return {
    type: DashboardActionsType.GET_SSO_REDIRECT_URL_SUCCESS,
    data
  }
}

export function getSSORedirectionUrlFailure(
  error: ErrorObjectState
): actions.GetSSORedirectionUrlActionFailure {
  return {
    type: DashboardActionsType.GET_SSO_REDIRECT_URL_FAILURE,
    error
  }
}

// Contribution Cibled content

export function getCibledContributionPageContentRequest(
  code: string
): actions.GetCibledContributionPageContentRequestAction {
  return {
    type: DashboardActionsType.GET_CIBLED_CONTRIBUTION_PAGE_CONTENT_REQUEST,
    code
  };
}

export function getCibledContributionPageContentSuccess(
  content: CibledContributionContentState
): actions.GetCibledContributionPageContentSuccessAction {
  return {
    type: DashboardActionsType.GET_CIBLED_CONTRIBUTION_PAGE_CONTENT_SUCCESS,
    content
  };
}

export function getCibledContributionPageContentFailure(
  error: CibledContributionErrorState
): actions.GetCibledContributionPageContentFailureAction {
  return {
    type: DashboardActionsType.GET_CIBLED_CONTRIBUTION_PAGE_CONTENT_FAILURE,
    error
  };
}
