import { StylesContext, PasswordCheckStyles, PasswordCheckProps } from './interface';

export const getStyles = (
  context?: StylesContext,
  style?: PasswordCheckStyles,
  props?: PasswordCheckProps,
): PasswordCheckStyles => ({
  containerStyle: [
    {
      justifyContent: 'center',
      flex: 1
    },
    style?.containerStyle
  ],
  buttonStyle: [
    { alignSelf: 'flex-start' }
  ],
  titleStyle: [
    {
      lineHeight: 28,
      marginBottom: 40
    },
    style?.titleStyle
  ],
  input: [
    {
      width: '100%'
    }
  ],
  textFieldContainerStyle: [
    {
      flexDirection: 'row',
      alignSelf: (context?.isMobile || context?.isTablet) ? 'flex-start' : 'flex-start',
      width: '100%',
      marginBottom: context?.theme.metrics.spacing.m
    },
    style?.textFieldContainerStyle
  ],
  errorViewStyle: [
    {
      alignSelf: 'flex-start',
      marginBottom: context?.theme.metrics.spacing.xm
    },
    style?.errorViewStyle
  ]
});
