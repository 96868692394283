import axios from 'axios';

import { API_URL } from '@config/index';

export function fetchGetNewsFeed(
  companyId: string
) {
  // by default we get all newsfeed and filter them in the app without using each api feed
  return axios.get(`${API_URL}/api/v1/companies/${companyId}/profile/newsfeed`);
}
