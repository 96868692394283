import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

export const useStyles = (isLeftActionButton: boolean) => {
  const theme = useTheme();
  const styles = useMemo(
    () =>
      StyleSheet.create({
        modalContentWrapper: {
          alignItems: 'center'
        },
        closeIcon: {
          display: 'flex',
          alignSelf: 'flex-end',
          marginRight: theme.metrics.spacing.xs,
          marginTop: theme.metrics.spacing.xs
        },
        actionsContainer: {
          width: '100%',
          flexDirection: isLeftActionButton ? 'row' : 'column',
          justifyContent: isLeftActionButton ? 'space-between' : 'flex-end',
          paddingBottom: 20
        }
      }),
    [isLeftActionButton, theme.metrics.spacing.xs]
  );

  return styles;
};
