import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';

import { InvitationRegistrationOriginEnum } from '@constants/index';
import { RouteNames } from '@constants/navigation';
import { runManager } from '@modules/moduleManager';
import {
  fetchCreateSelfcareInvitationFromExternalIdentity,
  fetchCreateSelfcareInvitationFromLetterOtpIdentity,
  fetchInvitation
} from './services';
import { RootNavigation } from '@navigation/RootNavigation';

import * as InvitationActions from './actions/invitationActions';
import * as InvitationActionsTypes from './actions/invitationActionsTypes';
import { InvitationActionsType } from './actions/invitationActionsTypes';
import {
  FetchInvitationIdentificationDataType,
  FetchInvitationIdentificationLetterOtpDataType
} from './types';

function* onLoadInvitation(action: InvitationActionsTypes.GetInvitationRequestAction): any {
  const response = yield call(fetchInvitation, action.token);
  if (
    response.data.mobileConfirmationState === 'Confirmed' &&
    response.data.emailConfirmationState === 'Confirmed'
  ) {
    const redirectError = {
      code: '',
      message:
        'Une erreur est survenue. Il semble que votre lien d’inscription soit invalide : peut-être avez-vous déjà créé votre compte ?',
      buttonTitle: 'Retour à la page de connexion',
      redirectionUrl: '/oidc/logout'
    };
    return (
      yield RootNavigation.replace(RouteNames.Error, {}),
      yield put(InvitationActions.RedirectionErrorRequest(redirectError)),
      yield put(InvitationActions.getInvitationSuccess(response.data))
    );
  }

  // set common navigation path
  let navigationPath = RouteNames.ValidateSignup;
  // update navigation path if immediate registartion and origin
  if (response?.data?.isImmediateRegistration) {
    switch (response?.data?.immediateRegistrationOrigin) {
      case InvitationRegistrationOriginEnum.PERSONEO:
        navigationPath = RouteNames.OnboardingPersoneo;
        break;
      case InvitationRegistrationOriginEnum.VISIOGO:
        navigationPath = RouteNames.OnboardingVisiogo;
        break;
      default:
        break;
    }
  }

  yield setTimeout(() => {
    RootNavigation.replace(RouteNames.RelationshipEntringProcess, {
      screen: navigationPath
    });
  }, 5000);
  yield put(InvitationActions.getInvitationSuccess(response.data));
}

function* createSelfcareInvitationFromExternalIdentity(
  action: InvitationActionsTypes.CreateSelfcareInvitationFromExternalIdentityRequestAction
): any {
  const dateFormatedWithoutTime = dayjs(action.identificationData.birthDate).utc(true).format('YYYY-MM-DD');
  const formatedIdentificationData: FetchInvitationIdentificationDataType = {
    ...action.identificationData,
    birthDate: dateFormatedWithoutTime
  };
  const response = yield call(
    fetchCreateSelfcareInvitationFromExternalIdentity,
    formatedIdentificationData
  );
  const email: string = response?.data;
  // navigate to identification orgin coresponding success screen
  switch (action.identificationData.type) {
    case InvitationRegistrationOriginEnum.PERSONEO:
      yield RootNavigation.replace(RouteNames.SelfcareStack, {
        screen: RouteNames.SelfcarePersoneoSuccess
      });
      break;
    case InvitationRegistrationOriginEnum.VISIOGO:
      yield RootNavigation.replace(RouteNames.SelfcareStack, {
        screen: RouteNames.SelfcareVisiogoSuccess
      });
      break;
    default:
      break;
  }

  yield put(InvitationActions.createSelfcareInvitationFromExternalIdentitySuccess(email));
}

dayjs.extend(utc);
function* createSelfcareInvitationFromLetterOtp(
  action: InvitationActionsTypes.CreateSelfcareInvitationFromLetterOtpRequestAction
): any {
  const dateFormatedWithoutTime = dayjs(action.identificationData.birthDate, {utc: true}).format('YYYY-MM-DD');
  const formatedIdentificationData: FetchInvitationIdentificationLetterOtpDataType = {
    ...action.identificationData,
    birthDate: dateFormatedWithoutTime
  };
  const response = yield call(
    fetchCreateSelfcareInvitationFromLetterOtpIdentity,
    formatedIdentificationData
  );

  yield put(InvitationActions.createSelfcareInvitationLetterOtpIdentitySuccess(response?.data));

  if (response?.data.registrationState === 'Confirmed') {
    yield RootNavigation.replace(RouteNames.SelfcareStack, {
      screen: RouteNames.SelfcareLetterSuccess
    });
  } else {
    yield RootNavigation.replace(RouteNames.RelationshipEntringProcess, {
      screen: RouteNames.ValidateSignup
    });
  }
}

function* getInvitation() {
  yield takeLatest(
    InvitationActionsType.GET_INVITATION_REQUEST,
    runManager(onLoadInvitation, InvitationActionsType.GET_INVITATION_FAILURE)
  );
}
function* createSelfcareInvitationFromExternalIdentitySaga() {
  yield takeLatest(
    InvitationActionsType.CREATE_SELFCARE_INVITATION_FROM_EXTERNAL_IDENTITY_REQUEST,
    runManager(
      createSelfcareInvitationFromExternalIdentity,
      InvitationActionsType.CREATE_SELFCARE_INVITATION_FROM_EXTERNAL_IDENTITY_FAILURE
    )
  );
}

function* createSelfcareInvitationFromLetterOtpSaga() {
  yield takeLatest(
    InvitationActionsType.CREATE_SELFCARE_INVITATION_FROM_LETTER_OTP_REQUEST,
    runManager(
      createSelfcareInvitationFromLetterOtp,
      InvitationActionsType.CREATE_SELFCARE_INVITATION_FROM_LETTER_OTP_FAILURE
    )
  );
}

export default function* invitationSaga() {
  yield all([fork(getInvitation)]);
  yield all([fork(createSelfcareInvitationFromExternalIdentitySaga)]);
  yield all([fork(createSelfcareInvitationFromLetterOtpSaga)]);
}
