import { connect } from 'react-redux';

import { AppState } from '@modules/reducers';
import {
  getInstallmentRepartitionData,
  getInstallmentDecisionAllResponses,
} from '@modules/installment/selectors';
import { ClearCacheAfterOperationDone } from '@modules/savings/actions/savingsActions';

import { InstallmentSuccessPageComponent } from './InstallmentSuccessPage.component';

const mapStateToProps = (state: AppState) => {
  return {
    installmentRepartitionData: getInstallmentRepartitionData(state),
    installmentDecisionAllResponses: getInstallmentDecisionAllResponses(state),
  };
};

const mapDispatchToProps = {
  onClearCacheAfterOperationDone: ClearCacheAfterOperationDone,
};

export const InstallmentSuccessPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(InstallmentSuccessPageComponent);
