import React from 'react';
import { View, StyleProp, ViewStyle, TextStyle } from 'react-native';

import { DashedLine, FontIcon, RoundedContainer, Title, Paragraph } from '@components/index';
import { IconEnum } from '@ere-uilib/enums';
import { ThemeType, useScreenSizes } from '@ere-uilib/styles';
import { createUseStyles, useTheme } from '@ere-uilib/styles';

interface PlansCellItem {
  name: string;
  description: string;
  hasContribution: boolean;
}

export type PlansCellStyle = {
  containerStyle?: StyleProp<ViewStyle>;
  containerTextStyle?: StyleProp<TextStyle>;
  iconContainerStyle?: StyleProp<TextStyle>;
  rowStyle?: StyleProp<ViewStyle>;
  descriptionTextStyle?: StyleProp<TextStyle>;
  dashedLineStyle?: StyleProp<ViewStyle>;
};

export type PlansCellItemProps = {
  data: PlansCellItem[];
  containerStyle?: StyleProp<ViewStyle>;
  containerTextStyle?: StyleProp<TextStyle>;
  iconContainerStyle?: StyleProp<TextStyle>;
  rowStyle?: StyleProp<ViewStyle>;
  descriptionTextStyle?: StyleProp<TextStyle>;
  dashedLineStyle?: StyleProp<ViewStyle>;
};

export type PlansCellContext = {
  theme: ThemeType;
  isDesktop: boolean;
};

export const PlansCell: React.FC<React.PropsWithChildren<PlansCellItemProps>> = ({
  data,
  containerStyle,
  containerTextStyle,
  iconContainerStyle,
  rowStyle,
  descriptionTextStyle,
  dashedLineStyle
}) => {
  const theme = useTheme();
  const { isDesktop } = useScreenSizes();
  const styles = useStyles(
    { theme, isDesktop },
    {
      containerStyle,
      containerTextStyle,
      iconContainerStyle,
      rowStyle,
      descriptionTextStyle,
      dashedLineStyle
    }
  );
  return (
    <View style={styles.containerStyle}>
      {data.map((item, index) => {
        return (
          <>
            <View style={styles.rowStyle}>
              <Title
                style={styles.containerTextStyle}
                variant="t7">
                {item.name}
              </Title>
              {item.hasContribution && (
                <RoundedContainer containerStyle={styles.iconContainerStyle}>
                  <FontIcon
                    color={theme.colors.basics.primary.c500}
                    name={IconEnum.CONTRIBUTION}
                    size={15}
                  />
                </RoundedContainer>
              )}
            </View>
            <Paragraph
              style={styles.descriptionTextStyle}
              variant="t4"
              weight="light">
              {item.description}
            </Paragraph>
            {index !== data.length - 1 && (
              <DashedLine
                axis="horizontal"
                dashColor={theme.colors.basics.grey.c200}
                dashGap={4}
                dashLength={4}
                dashThickness={1}
                style={styles.dashedLineStyle}
              />
            )}
          </>
        );
      })}
    </View>
  );
};

const getStyles = (context?: PlansCellContext, style?: PlansCellStyle): PlansCellStyle => {
  return {
    containerStyle: [
      {
        width: '100%',
        alignItems: 'stretch'
      },
      context?.isDesktop && {
        flex: 1
      },
      style?.containerStyle
    ],
    containerTextStyle: [
      {
        textAlign: 'center'
      },
      style?.containerTextStyle
    ],
    iconContainerStyle: [
      {
        marginLeft: context?.theme.metrics.spacing.s,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: context?.theme.colors.basics.grey.c100
      },
      style?.iconContainerStyle
    ],
    rowStyle: [
      {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
      },
      style?.rowStyle
    ],
    descriptionTextStyle: [
      {
        textAlign: 'center',
        marginTop: context?.theme.metrics.spacing.xs
      },
      style?.descriptionTextStyle
    ],
    dashedLineStyle: [
      {
        marginTop: context?.theme.metrics.spacing.m,
        marginBottom: context?.theme.metrics.spacing.m,
        alignSelf: 'stretch'
      },
      style?.dashedLineStyle
    ]
  };
};

const useStyles = createUseStyles(getStyles);
