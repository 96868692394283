import { useNavigation } from '@react-navigation/native';
import { useFormikContext } from 'formik';
import React, { useCallback } from 'react';
import { View } from 'react-native';

import {
  ColumnsContainer,
  DashboardPageWrapperConnected,
  FilePicker,
  SimpleButton,
  Text,
  Title
} from '@components/index';
import { IconEnum } from '@ere-uilib/enums';
import { useScreenSizes } from '@ere-uilib/styles';

import { useData } from './useData';
import { useLabel } from './useLabel';
import { useStyles } from './useStyles';
import { ProfileEditAddressHeader } from '../components';
import { FormValues } from '../types';

export const ProfileEditAddressDocumentPage: React.FC = () => {
  const navigation = useNavigation();
  const { isDesktop } = useScreenSizes();
  const styles = useStyles();

  const {
    maxFileSize,
    document,
    onUploadDocument,
    onRemoveDocument,
    onSubmit,
    isAddressUpdateLoading
  } = useData();

  const { values } = useFormikContext<FormValues>();

  const label = useLabel(maxFileSize);

  const renderBottomActions = useCallback(
    () => (
      <View style={styles.bottomActionsContainer}>
        <SimpleButton
          design="outlined"
          leftIcon={IconEnum.CHEVRON_LEFT}
          onPress={() => navigation.goBack()}
          testId="PostalAddress_PageDocument_Button_Previous"
        />
        <SimpleButton
          containerStyle={styles.bottomActionButtonNext}
          design="solid"
          disabled={!document.file}
          loading={isAddressUpdateLoading}
          onPress={() => onSubmit(values)}
          size="small"
          testId="PostalAddress_PageDocument_Button_Submit"
          title={label.button.next}
        />
      </View>
    ),
    [styles, document, isAddressUpdateLoading, onSubmit, label, navigation, values]
  );

  return (
    <DashboardPageWrapperConnected renderStickyMobileBottom={renderBottomActions}>
      <ColumnsContainer
        hasRenderRightDesktopColumn={false}
        renderHeader={() => <ProfileEditAddressHeader stepper={{ size: 2, current: 2 }} />}>
        <Title
          variant="t4"
          weight="bold">
          {label.title}
        </Title>
        <Text
          style={styles.textStyle}
          variant="t3"
          weight="light">
          {label.description}
        </Text>
        <View style={styles.cardContainer}>
          <Title
            variant="t7"
            weight="regular">
            {label.section.title}
          </Title>
          <Text
            variant="t4"
            weight="light">
            {label.section.description}
          </Text>
          <View style={styles.filePickersContainer}>
            <FilePicker
              containerStyle={styles.filePicker}
              data={document.file}
              dictionary={label.picker}
              fileType=""
              hasError={document.hasError}
              key={'file-picker-Address'}
              onAdd={onUploadDocument}
              onRemove={onRemoveDocument}
              testId="PostalAddress_PageDocument_File"
            />
          </View>
        </View>
        {isDesktop && renderBottomActions()}
      </ColumnsContainer>
    </DashboardPageWrapperConnected>
  );
};
