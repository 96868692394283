import React from 'react';

import {
  AmountFieldModal
} from '@ere-uilib/index';
import { AmountFieldModal360Props } from './interface';
import { useController } from './useController';
import { locator } from '@constants/locator';

export type AmountFieldModal360PropsType = AmountFieldModal360Props
export const AmountFieldModal360 = ({
                                      visible = false,
                                      isAmountPercentage = false,
                                      data,
                                      onCancel,
                                      onValid,
                                      onCalculContribution,
                                      onResetCalculatedContribution,
                                      calculatedContribution,
                                      isCalculContributionLoading,
                                      isValidButtonLoading,
                                      formError,
                                      placeholder,
                                    }: AmountFieldModal360Props) => {
  const {
    title,
    subTitle,
    descriptions,
    initialAmount,
    contentTitle,
    contentSubTitle,
    amountParameters,
    isContributionActive,
    allAmountLabel,
    allAmountValue,
    dueDateOption
  } = data;

  const {
    isAmountValid,
    amountError,
    detailsParameters,
    handleAmountChange,
    handleResetCalculatedContribution,
    handleContributionCalculPressed,
    handleValidPressed,
    handleCancelPressed,
  } = useController({
    visible,
    initialAmount,
    isContributionActive,
    onResetCalculatedContribution,
    onCalculContribution,
    onValid,
    onCancel,
    amountParameters,
    dueDateOption,
    isAmountPercentage
  });

  return (
      <AmountFieldModal
          allAmountLabel={allAmountLabel}
          allAmountValue={allAmountValue}
          calculatedContribution={calculatedContribution}
          contentSubTitle={contentSubTitle}
          contentTitle={contentTitle}
          detailsParameterList={descriptions || detailsParameters}
          fieldError={amountError}
          formError={formError}
          initialAmount={initialAmount}
          isAmountPercentage={isAmountPercentage}
          isCalculContributionLoading={isCalculContributionLoading}
          isContributionActive={isContributionActive}
          isValidButtonDisable={!isAmountValid}
          isValidButtonLoading={isValidButtonLoading}
          onAmountChange={handleAmountChange}
          onCalculContribution={handleContributionCalculPressed}
          onCancel={handleCancelPressed}
          onResetCalculatedContribution={handleResetCalculatedContribution}
          onValid={amount => {
            isAmountValid && handleValidPressed(amount)
          }}
          placeholder={placeholder}
          subTitle={subTitle}
          title={title}
          visible={visible}
          testId={locator._payment._set_modal}
      />
  );
};
