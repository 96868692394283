import React, { useState } from 'react';
import { View } from 'react-native';

import { Spinner, Text, FontIcon, Pressable } from '@ere-uilib/atoms';
import { createUseStyles, useTheme } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';

import { SimpleButtonProps } from './interface';
import { getStyles } from './styles';

export const SimpleButton: React.FC<React.PropsWithChildren<SimpleButtonProps>> = ({
  title,
  loading = false,
  isSticky = false,
  loadingTitle,
  disabled,
  isHoverableButton = true,
  leftIcon,
  rightIcon,
  leftIconStyle,
  rightIconStyle,
  onPress,
  textStyle,
  containerStyle,
  design = 'solid',
  size = 'large',
  children,
  titleTextProps = {},
  customPrimaryColor,
  customSecondaryColor,
  testId
}) => {
  const theme = useTheme();
  const { formatMessage } = useTranslation();

  const [hovered, setHovered] = useState<boolean>(false);
  const [pressed, setPressed] = useState<boolean>(false);

  const styles = useStyles(
    {
      design,
      disabled,
      loading,
      isSticky,
      size,
      isContent: !!(title || children),
      isLeftIcon: !!leftIcon,
      isRightIcon: !!rightIcon,
      theme,
      hovered,
      pressed,
      customPrimaryColor,
      customSecondaryColor,
    },
    {
      leftIconStyle,
      rightIconStyle,
      containerStyle,
      textStyle,
    }
  );

  const defaultLoadingTitle = formatMessage({
    id: 'operation-status_in-treatment',
  });

  return (
    <Pressable
      accessibilityLabel={`${testId}_button`}
      disabled={disabled || loading}
      onHoverIn={() => isHoverableButton && setHovered(true)}
      onHoverOut={() => setHovered(false)}
      onPress={onPress}
      onPressIn={() => setPressed(true)}
      onPressOut={() => setPressed(false)}
      style={styles.containerStyle}
      testID={`${testId}_button`}
    >
      <View style={styles.innerContainerStyle}>
        {loading && (
          <Spinner
            color={
              disabled ?
                theme.colors.basics.grey.c400
                : ['outlined', 'light'].includes(design) ?
                  theme?.colors.buttons.classic.c500
                  : theme?.colors.basics.white
            }
          />
        )}
        {!loading && leftIcon && (
          <FontIcon
            name={leftIcon}
            style={styles.leftIconStyle}
          />
        )}
        <View style={styles.contentContainerStyle}>
          {!children && title !== "" && (
            <Text
              accessibilityLabel={`${testId}_text`}
              testID={`${testId}_text`}
              {...titleTextProps}
              style={styles.textStyle}
            >
              {loading ? loadingTitle || defaultLoadingTitle : title}
            </Text>
          )}
          {children}
        </View>
        {rightIcon && (
          <FontIcon
            name={rightIcon}
            style={styles.rightIconStyle}
          />
        )}
      </View>
    </Pressable>
  );
};

const useStyles = createUseStyles(getStyles);
