import dayjs from 'dayjs';
import duration from "dayjs/plugin/duration";
import React, { useCallback, useEffect, useState } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

import {
  PerformanceBarChartTemplate
} from "@components/ERE360Components/fundSheetTabs/PerformanceTab/components/PerformanceBarChartTemplate";
import {
  FundPerformanceChart, MultiSwitch, PeriodSelector, Text
} from '@components/index';
import { ExpendableLine } from '@ere-uilib/molecules/cards/ExpandableLineSet/ExpandableLine/ExpandableLine';
import {
  useScreenSizes,
  useTheme,
} from '@ere-uilib/styles';
import { SharePriceHistoricItem, FundsPerformanceState, FundSourceEnum } from '@modules/funds/types';
import { useTranslation } from '@translations/index';

import PlaceholderLoader from '../components/PlaceholderLoader';
import { PerformanceTemplate } from './components';
import { useController } from './useController';
import { useStyles } from './useStyles';

dayjs.extend(duration);
interface DataProps {
  fundsPerformanceSharePriceHisto?: SharePriceHistoricItem[];
  performanceData: FundsPerformanceState | undefined;
  isApiLoadingPerformance: boolean;
  dateLastVL: string | undefined;
  launchDate: string | undefined;
  style?: StyleProp<ViewStyle>;
}

interface RemappedSharePriceHistoricItem {
  x: string;
  y: number;
}

export const PerformanceTab: React.FC<DataProps> = ({
  style,
  performanceData,
  isApiLoadingPerformance,
  fundsPerformanceSharePriceHisto = [],
  launchDate
}: DataProps) => {
  const theme = useTheme();
  const { formatMessage, formatDate } = useTranslation();
  const {
    isMobile,
    isTablet
  } = useScreenSizes();
  const styles = useStyles({
    isMobile,
    isTablet
  });
  const [expanded, setExpanded] = useState(true);
  const {
    periodList,
    performanceTabs,
    performanceTableHeader,
    getPerformanceBarChartData,
  } = useController({
    performanceData,
    formatMessage,
    formatDate,
    styles,
    theme
  })
  const { name: fundName, isMorningStarFund, events, sharePerf, benchPerf } = performanceData ?? {};

  const { barChartData: participationData, latestDate, earliestDate } = getPerformanceBarChartData(sharePerf ?? []);
  const { barChartData: referenceData } = getPerformanceBarChartData(benchPerf ?? []);
  const isPerformanceBarChartVisible = !!(participationData?.length && referenceData?.length);
  const getStartEndDatesForNumberOfYear = useCallback((numberOfYear: number) => {
    const usedEndDate = new Date();
    const usedStartDate = new Date();
    usedStartDate.setFullYear(usedEndDate.getFullYear() - numberOfYear);
    usedStartDate.setHours(0, 0, 0, 0);
    return {
      startDate: usedStartDate,
      endDate: usedEndDate,
    };
  }, []);

  const defaultMultiswitchValue = periodList[0].value;
  const [multiswitchValue, setMultiswitchValue] = useState(defaultMultiswitchValue);

  const defaultStartEndDates = getStartEndDatesForNumberOfYear(defaultMultiswitchValue);

  const [startDate, setStartDate] = useState<Date>(defaultStartEndDates.startDate);
  const [endDate, setEndDate] = useState<Date>(defaultStartEndDates.endDate);

  const minDate = fundsPerformanceSharePriceHisto.length > 0 ?
    new Date(fundsPerformanceSharePriceHisto[0].date)
    : new Date();
  const maxDate = fundsPerformanceSharePriceHisto.length > 0 ?
    new Date(fundsPerformanceSharePriceHisto[fundsPerformanceSharePriceHisto.length - 1].date)
    : new Date();

  useEffect(() => {
    if (multiswitchValue !== 0) {
      const newStartEndDates = getStartEndDatesForNumberOfYear(multiswitchValue);
      setStartDate(newStartEndDates.startDate);
      setEndDate(newStartEndDates.endDate);
    }
  }, [
    multiswitchValue,
    getStartEndDatesForNumberOfYear,
  ]);

  const onChangeStartDate = (value: any) => {
    setStartDate(value);
  };

  const onChangeEndDate = (value: any) => {
    setEndDate(value);
  };

  const getRemappedFilteredChartData = useCallback(() => {
    const formattedStartDate = dayjs(startDate).format('YYYY-MM-DDTHH:mm:ss');
    const formattedEndDate = dayjs(endDate).format('YYYY-MM-DDTHH:mm:ss');

    const filteredDatesData: SharePriceHistoricItem[] =
      fundsPerformanceSharePriceHisto.filter(
        item => item.date >= formattedStartDate && item.date <= formattedEndDate
      );

    const remappedFilteredChartData: RemappedSharePriceHistoricItem[] =
      filteredDatesData.map(item => ({
        x: item.date,
        y: item.value,
      }));

    return remappedFilteredChartData;

  }, [startDate, endDate, fundsPerformanceSharePriceHisto]);

  const remappedFilteredChartData = getRemappedFilteredChartData();
  const isFundmart = performanceData?.source === FundSourceEnum.BNPP
  const now = dayjs();
  const dateDuration = dayjs.duration(now.diff(launchDate))
  const isLaunchedMoreThanOneYear = dateDuration.asYears() >= 1

  return (
   <View style={[styles.globalContainer, style]}>
      <View style={styles.containerStyle}>
        {isApiLoadingPerformance ? (
          <PlaceholderLoader />
        ) : (
          <>
            {isLaunchedMoreThanOneYear &&
              <ExpendableLine
                isOpened={expanded}
                onChange={() => setExpanded(!expanded)}
                title={formatMessage({
                  id: 'Fundsheet_Tab3Perf_Performance_synthesis_title',
                })}
                titleStyle={{
                  fontFamily: theme.fonts.fontFamily.bold,
                }}
              >
                <PerformanceTemplate
                  performanceTableHeader={performanceTableHeader}
                  performanceTabs={performanceTabs}
                />
              </ExpendableLine>
            }
            {isPerformanceBarChartVisible && (
              <PerformanceBarChartTemplate
                earliestDate={earliestDate}
                events={events}
                fundName={fundName}
                isFundmart={!!isFundmart}
                isMorningStarFund={!!isMorningStarFund}
                latestDate={latestDate}
                participationData={participationData}
                referenceData={referenceData}
              />
            )}
            <Text
              style={{ marginTop: theme.metrics.spacing.l }}
              variant="t3"
              weight="bold"
            >
              {formatMessage({
                id: 'Fundsheet_Tab3Perf_Performance_graph_title',
              })}
            </Text>
            <MultiSwitch
              containerStyle={{
                marginTop: theme.metrics.spacing.xm,
              }}
              onChange={value => {
                setMultiswitchValue(value);
              }}
              options={periodList}
              value={multiswitchValue}
            />

            {multiswitchValue === 0 && (
              <PeriodSelector
                maxDate={maxDate}
                minDate={minDate}
                onChangeSelectorEndDate={onChangeEndDate}
                onChangeSelectorStartDate={onChangeStartDate}
                selectorEndDate={endDate}
                selectorStartDate={startDate}
                style={{
                  marginTop: theme.metrics.spacing.xm,
                }}
              />
            )}

            <Text
              variant="t4"
              weight="light"
            >
              {formatMessage({
                id: 'Fundsheet_Tab3Perf_Valuation_title',
                values: { variable: '€' },
              })}
            </Text>

            {!!remappedFilteredChartData && remappedFilteredChartData.length > 0 ? (
              <FundPerformanceChart data={remappedFilteredChartData} />
            ) : (
              <Text style={styles.noDataLabelStyle}>
                {formatMessage({
                  id: 'Fundsheet_Tab3Perf_Performance_nodata_label',
                })}
              </Text>

            )}
          </>
        )}
      </View>
    </View >

  );
};
