import { HelpButtonStyles, StylesContext } from './interface';

export const getStyles = (
  context?: StylesContext,
  style?: HelpButtonStyles
): HelpButtonStyles => {

  return {
    containerStyle: [
      {
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: 300,
        backgroundColor: context?.theme.colors.basics.white,
        borderColor: context?.theme.colors.basics.grey.c500,
        borderWidth: 1,
        height: 48
      },
      style?.containerStyle
    ],
    innerContainerStyle: [{
      flex: 1,
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: 14
    },
    style?.innerContainerStyle
    ],
    leftContainerStyle: [
      {
        backgroundColor: context?.theme.colors.basics.white,
        paddingHorizontal: context?.theme.metrics.spacing.xm,
        borderTopLeftRadius: 300,
        borderBottomLeftRadius: 300,
        justifyContent: 'center'
      },
      style?.leftContainerStyle
    ],
    rightContainerStyle: [
      {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: context?.theme.colors.basics.grey.c500,
        borderTopRightRadius: 300,
        borderBottomRightRadius: 300,
        height: 48
      },
      style?.rightContainerStyle
    ],
    triangleViewStyle: [
      {
        width: 14,
        height: 48,
        justifyContent: 'center'
      },
      style?.triangleViewStyle
    ],
    triangleStyle: [
      {
        width: context?.theme.metrics.spacing.none,
        height: context?.theme.metrics.spacing.none,
        backgroundColor: 'transparent',
        borderTopWidth: 9,
        borderRightWidth: 0,
        borderBottomWidth: 9,
        borderLeftWidth: 11,
        borderTopColor: 'transparent',
        borderRightColor: 'transparent',
        borderBottomColor: 'transparent',
        borderLeftColor: context?.theme.colors.basics.white
      },
      style?.triangleStyle
    ],
    leftIconStyle: [
      {
        fontSize: context?.theme.metrics.iconSizes.l,
        color: context?.theme.colors.basics.grey.c500
      },
      style?.leftIconStyle
    ],
    textStyle: [
      {
        textAlign: 'center',
        fontSize: context?.theme.fonts.fontSize.button.t2,
        color: context?.theme.colors.basics.white
      },
      style?.textStyle
    ]
  };
};
