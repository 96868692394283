import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import {
  useTranslation,
  useTheme,
  NotificationHard,
  NotificationIconTypeEnum,
  HtmlStyledRenderConnectedContainer
} from '@components/index';
import { createUseStyles } from '@ere-uilib/styles';
import { ThemeType, useScreenSizes } from '@ere-uilib/styles';
import { useFormatHrefHtml } from '@utils/index';

interface Props {

}

interface ContextProps {
  theme: ThemeType,
  isMobile?: boolean

}

interface StyleProps {
  notification?: StyleProp<ViewStyle>
  notificationLabel?: StyleProp<ViewStyle>
}

export const RefundsNotificationHard: React.FC<React.PropsWithChildren<Props & StyleProps>> = ({

  notification,
  notificationLabel,

}) => {
  const { formatMessage } = useTranslation();
  const theme = useTheme();

  const navigation = useNavigation();
  const { isMobile, isTablet } = useScreenSizes();
  const styles = useStyles({
    theme,
    isMobile: isMobile || isTablet,
  },
    {
      notification,
      notificationLabel,
    });

  const notificationTitle = formatMessage({ id: 'Remboursement_CardCoordonneesBancaires_title' });
  const notificationSubtitle = formatMessage({ id: 'Remboursement_CardCoordonneesBancaires_content' }) + " " + formatMessage({ id: 'Remboursement_CardCoordonneesBancaires_linklabel' });

  return (
    <NotificationHard
      containerStyle={styles.notification}
      title={notificationTitle}
      type={NotificationIconTypeEnum.TIPS}
    >
      <HtmlStyledRenderConnectedContainer
          baseFontStyle={{ fontFamily: theme.fonts.fontFamily.light}}
        html={useFormatHrefHtml(notificationSubtitle)} />
    </NotificationHard>);
};

const getStyles = (
  context?: ContextProps,
  style?: StyleProps,
): StyleProps => ({
  notification: [
    context?.isMobile && {
      flex: 1,
    },
    style?.notification,
  ],
  notificationLabel: [
    {
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    style?.notificationLabel,
  ],

});

const useStyles = createUseStyles(getStyles);
