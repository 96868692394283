import React, { useCallback, useEffect, useMemo } from 'react';

import { SelfcareLetterIdentifierFormTemplate } from '@components/templates';
import { useTranslation } from '@ere-uilib/translations';
import { useInvitation } from '@hooks/useInvitation';
import { useSettings } from '@hooks/useSettings';
import { createSelfcareInvitationResetError } from '@modules/invitation/actions/invitationActions';
import { useDispatch } from 'react-redux';
import { useCalendarLabels } from '@ere-uilib/hooks';

interface Props {
  onPressBackButton?: () => void;
  onClose: () => void;
}

export function LetterIdentifierFormPageContainer({
  onPressBackButton,
  onClose,
}: Props) {
  const { formatMessage, getMessageRaw } = useTranslation();
  const { monthNames, dayNames, dayNamesShort } = useCalendarLabels();
  const {
    createSelfcareInvitationFromLetterOtpIdentityRequest,
    isLoading,
    errorMessage,
  } = useInvitation();
  const { optionEERRegexVisiogo } = useSettings();
  const dispatch = useDispatch();

  const dictionary = useMemo(() => ({
    identifierInputPlaceholder: formatMessage({
      id: 'EerSelfcareStep2CourrierPlaceholder',
    }),
    confirmButton: formatMessage({ id: 'EerSelfcareStep2ConfirmerButton' }),
    helpText: formatMessage({
      id: 'EerSelfcareStep2OuTrouverLabel',
    }),
    helpLabel: formatMessage({
      id: 'EerSelfcareStep2CourrierLabel',
    }),
    dateOfBirth: formatMessage({
      id: 'EerSelfcareStep2DateNaissanceLabel',
    }),
    errorHelper: formatMessage({
      id: 'EerSelfcareStep3CompteIntrouvableErreur',
    }),
    reedMore: formatMessage({
      id: 'EerSelfcareStep3CompteIntrouvableLink',
    }),
    errorReadMoreContentHtml: getMessageRaw({
      id: 'EerSelfcareStep2CourrierInfoContent',
    }),
    errorReadMoreModalTitle: formatMessage({
      id: 'EerSelfcareStep2CourrierInfoTitle',
    }),
    inputHelpPointContentHtml: getMessageRaw({
      id: 'EerSelfcareStep2OuTrouverPopinCourrierContent',
    }),
    inputHelpPointModalTitle: formatMessage({
      id: 'EerSelfcareStep2OuTrouverPopinTitle',
    }),
    backButton: formatMessage({
      id: 'EerSelfcareStep2RetourLabel',
    }),
    pageTitle: formatMessage({
      id: 'EerSelfcareStep2ControleTitle',
    }),
    placeholderDay: formatMessage({
      id: 'EerSelfcareStep2PlaceholderJourLabel',
    }),
    placeholderMonth: formatMessage({
      id: 'EerSelfcareStep2PlaceholderMoisLabel',
    }),
    placeholderYear: formatMessage({
      id: 'EerSelfcareStep2PlaceholderAnneeLabel',
    }),
    dateErrorMessage: formatMessage({
      id: 'EerSaisieDateNaissanceInvalide',
    }),
    monthNames,
    dayNames,
    dayNamesShort,
  }), [formatMessage, getMessageRaw]);

  const handlePressSubmit = useCallback((otpCode, dateOfBirth) => {
    if (!otpCode || !dateOfBirth) {
      return;
    }
    createSelfcareInvitationFromLetterOtpIdentityRequest({
      birthDate: dateOfBirth,
      otpCode,
    });
  }, [createSelfcareInvitationFromLetterOtpIdentityRequest]);

  useEffect(() => {
    dispatch(createSelfcareInvitationResetError())
  }, [dispatch])

  return (
    <SelfcareLetterIdentifierFormTemplate
      dictionary={dictionary}
      errorMessage={errorMessage}
      identifierRegex={optionEERRegexVisiogo}
      isLoading={isLoading}
      onClose={onClose}
      onPressBackButton={onPressBackButton}
      onPressSubmit={handlePressSubmit}
    />
  );
}
