// selectors
import { AppState } from '@modules/reducers';

export const getIdentityValidationModuleError = (state: AppState) => state.validateIdentity.error;

export const getUserInputEmail = (state: AppState) =>
  state.validateIdentity.inputEmail;

export const getUserInputPhone = (state: AppState) =>
  state.validateIdentity.inputPhone;

export const getFranceConnectRegistrationBodyState = (state: AppState) =>
  state.validateIdentity.franceConnectRegistration.franceConnectRegistrationBody.state;
