import { AppRegistry } from 'react-native';

import App from './App';
import '@ere-uilib/styles/globalUILibStyles.css';
import './styles/globalStyles.css';

const appName = "ere360";
AppRegistry.registerComponent(appName, () => App);
if (process.env.NODE_ENV !== 'test') {
  AppRegistry.runApplication(appName, {
    rootTag: document.getElementById('root')
  });
}
