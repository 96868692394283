import React from "react";
import { ContentLoader, LoaderPlaceholderView } from "@ere-uilib/atoms";
import { View } from "react-native";
import { Path } from "react-native-svg";
import { useStyles } from './useStyle';
import { toolCardContentLoaderSvgPath } from "./constants";

export const ToolCardContentLoader: React.FC = () => {
  const styles = useStyles();

  return (
    <View style={styles.containerStyle}>
      <ContentLoader
        height={235}
        style={{ alignSelf: 'center' }}
        width={355}
      >
        <Path
          d={toolCardContentLoaderSvgPath}
        />
      </ContentLoader>
      <View style={styles.innerContainerStyle}>
        <LoaderPlaceholderView
          style={styles.placeholder75}
        />
        <LoaderPlaceholderView
          style={styles.placeholder100}
        />
        <LoaderPlaceholderView
          style={styles.placeholder100}
        />
        <LoaderPlaceholderView
          style={{
            height: 48,
            width: 160
          }}
        />
      </View>
    </View>
  );
}