import React, { useMemo } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

import { FontIcon } from '@ere-uilib/atoms/images/FontIcon';
import { IconEnum } from '@ere-uilib/enums';
import { createUseStyles, ThemeType } from '@ere-uilib/styles';
import { useTheme } from '@ere-uilib/styles/theme';

// eslint-disable-next-line no-shadow
export enum SquareIconSizeEnum {
  BIG = 'big',
  SMALL = 'small'
}

interface Styles {
  container?: StyleProp<ViewStyle>;
}

interface Props {
  iconColor: string,
  iconName: IconEnum,
  iconSize: number,
  size?: SquareIconSizeEnum,
  backgroundColor?: string
  style?: Styles | null
}

export function SquareIcon({ iconColor, iconName, iconSize, style, size = SquareIconSizeEnum.BIG, backgroundColor }: Props) {
  const theme = useTheme();
  const styles = useStyles({ theme }, { ...style });
  const backgroundSize = useMemo(() => {
    return theme.metrics.iconSizes[size === SquareIconSizeEnum.BIG ? 'xxxl' : 'xxl'];
  }, [size, theme.metrics.iconSizes]);
  return (
    <View style={[{ width: backgroundSize, height: backgroundSize }, styles.container, { backgroundColor }]}>
      <FontIcon
        color={iconColor}
        name={iconName}
        size={iconSize}
      />
    </View>
  );
}

interface Context {
  theme: ThemeType
}

const getStyles = (
  context: Context,
  style: Styles
): Styles => ({
  container: [
    {
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: context.theme.metrics.borderRadius.xs
    },
    style?.container
  ]
});

const useStyles = createUseStyles(getStyles);
