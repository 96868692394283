import { OperationDecisionState } from '@modules/common/types'

export interface RefundErrorState {
  code: string
  correlationId: string
  message: string
  innerMessage: string
}

export enum CommonRefundActionsType {
  CLEAR_REFUND_DATA = 'CLEAR_REFUND_DATA',
  REFUND_INIT_ERROR = 'REFUND_INIT_ERROR',
  GET_REFUND_DECISION_REQUEST = 'GET_REFUND_DECISION_REQUEST',
  GET_REFUND_DECISION_SUCCESS = 'GET_REFUND_DECISION_SUCCESS',
  GET_REFUND_DECISION_FAILURE = 'GET_REFUND_DECISION_FAILURE',
  UPDATE_REFUNDS_QUESTION_WITH_SET_RESPONSE = 'UPDATE_REFUNDS_QUESTION_WITH_SET_RESPONSE',
  UPDATE_UNAVAILABLE_REFUNDS_QUESTION_WITH_SET_RESPONSE = 'UPDATE_UNAVAILABLE_REFUNDS_QUESTION_WITH_SET_RESPONSE',
  REFUNDS_BACK_TO_QUESTION = 'REFUNDS_BACK_TO_QUESTION',
  SET_REFUNDS_ELIGIBLE_DISPOSITIFS = 'SET_REFUNDS_ELIGIBLE_DISPOSITIFS',
  SET_REFUNDS_SELECTED_DISPOSITIF = 'SET_REFUNDS_SELECTED_DISPOSITIF',
  CREATE_REPAYMENT_DEMAND_FAILURE = 'CREATE_REPAYMENT_DEMAND_FAILURE',
  GET_REFUND_UNAVAILABILITY_DECISION_TREE_REQUEST = 'GET_REFUND_UNAVAILABILITY_DECISION_TREE_REQUEST'
}

export interface RefundsCommonState {
  decision: OperationDecisionState
  error: RefundErrorState
}
