import { useNavigation } from '@react-navigation/native';
import React, { useCallback, useMemo, useState } from 'react';

import { RouteNames } from '@constants/navigation';
import { StepperHeader, useTranslation } from '@ere-uilib/index';
import { DirectionEnum } from '@ere-uilib/molecules/steppers/InstallmentsStepper/interfaces';
import ConfirmModalComponent from '@pages/Common/ConfirmModalComponent/ConfirmModalComponent';
import { constructStepperItems } from '@pages/Common/utils';

export interface ProfileEditAddressHeaderProps {
  hasCloseButton?: boolean;
  stepper?: {
    size: number;
    current: number;
  };
}

export const ProfileEditAddressHeader: React.FC<ProfileEditAddressHeaderProps> = ({
  hasCloseButton = true,
  stepper
}) => {
  const navigation = useNavigation();
  const { formatMessage } = useTranslation();
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState<boolean>(false);

  const stepperItems = useMemo(() => {
    const { size = 0, current = 0 } = stepper ?? {};

    if (size <= 1) return;

    return constructStepperItems({
      size,
      direction: DirectionEnum.ROW,
      activeStep: current
    });
  }, [stepper]);

  const handleGoback = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const handleClose = useCallback(() => {
    navigation.navigate(RouteNames.BottomTabNavigator, {
      screen: RouteNames.ProfileStack,
      params: {
        screen: RouteNames.EditProfileInformations
      }
    });
  }, [navigation]);

  const toggleConfirmationModal = useCallback(() => {
    setIsConfirmationModalVisible(prev => !prev);
  }, []);

  return (
    <>
      <StepperHeader
        displayBackButton
        displayCloseButton={hasCloseButton}
        helpPoint={false}
        onClose={toggleConfirmationModal}
        onGoBack={handleGoback}
        stepperItems={stepperItems}
        title={formatMessage({ id: 'PostalAdressPageHeader' })}
      />
      <ConfirmModalComponent
        closeModalAction={handleClose}
        goBackAction={toggleConfirmationModal}
        isPage={false}
        modalVisible={isConfirmationModalVisible}
      />
    </>
  );
};
