import { useCallback, useState } from 'react';

import { FileData } from '@modules/common/types';

import { FormValues } from '../types';
import { useData as useCommonData } from '../useData';
import { checkFile } from '../utils';

interface Document {
  file: FileData | null | undefined;
  hasError: boolean;
  isLoading: boolean;
}

const initialDocument: Document = {
  file: undefined,
  isLoading: false,
  hasError: false
};

export const useData = () => {
  const [document, setDocument] = useState<Document>(initialDocument);

  const {
    maxFileSize = 0,
    allowedFileExtensions,
    onUpdateUserAddress,
    isAddressUpdateLoading
  } = useCommonData();

  const onUploadDocument = useCallback(
    (file: FileData) => {
      const isValid = checkFile(file, maxFileSize, allowedFileExtensions);

      setDocument({
        file: (isValid && file) || null,
        isLoading: false,
        hasError: !isValid
      });
    },
    [allowedFileExtensions, maxFileSize]
  );

  const onRemoveDocument = useCallback(() => {
    setDocument(initialDocument);
  }, []);

  const onSubmit = useCallback(
    (values: FormValues) => {
      if (document.file) onUpdateUserAddress(values, [document.file]);
    },
    [document.file, onUpdateUserAddress]
  );

  return {
    document,
    maxFileSize,
    onUploadDocument,
    onRemoveDocument,
    onSubmit,
    isAddressUpdateLoading
  };
};
