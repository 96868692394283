import { useNavigation } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';

import { RouteNames } from '@constants/navigation';
import { useScreenSizes } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';
import { getPendingTasksData } from '@modules/pending-tasks/selectors';
import { getConsentsRequest } from '@modules/profile/actions/profileActions';
import { RootNavigation } from '@navigation/RootNavigation';

export const useModalData = () => {
  const { formatMessage } = useTranslation();
  const maintenance = useSelector(getPendingTasksData);
  const { isMobile } = useScreenSizes();
  const dispatch = useDispatch()
  const imageUrl = isMobile ? maintenance?.[0].context?.imageSmallUrl :  maintenance?.[0].context?.imageLargeUrl;
  const navigation = useNavigation();
  const isBlocking = maintenance?.[0].context?.isBlocking
  const onModalClose = () => {
    isBlocking
      ? navigation.navigate(RouteNames.Logout)
      : (RootNavigation.goBack(),
      dispatch(getConsentsRequest()))
  };
  const title = maintenance?.[0].context?.title;
  const content = maintenance?.[0].context?.content;
  
  return {
    buttonLabel: formatMessage({ id: isBlocking ?
      'IndispoButtonLeave' :
      'IndispoButtonGoToHomepage' }),
    onModalClose,
    imageUrl,
    title,
    content,
  };
};