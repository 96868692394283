import { ArbitrationActionsType } from '@modules/arbitration/actions/arbitrationActionsTypes'
import { InstallmentActionsType } from '@modules/installment/actions/installmentActionsTypes'
import { CommonRefundActionsType } from '@modules/refund/types'

export enum RefundsNavigatorRouteNames {
  RefundOnboarding = 'RefundOnboarding',
  RefundsRequirementDefiniton = 'RefundsRequirementDefiniton',
  UnavailableRefundRC = 'UnavailableRefundRC',
  RefundRepartition = 'RefundRepartition',
  RefundPlanChoice = 'RefundPlanChoice',
  RefundAskProof = 'RefundAskProof',
  RefundsRecap = 'RefundsRecap',
  RefundSuccess = 'RefundSuccess',
  RefundFailed = 'RefundFailed',
  RefundModal = 'RefundModal'
}
export type RefundsNavigatorInterface = {
  [RefundsNavigatorRouteNames.RefundOnboarding]: undefined
  [RefundsNavigatorRouteNames.RefundsRequirementDefiniton]: undefined
  [RefundsNavigatorRouteNames.UnavailableRefundRC]: undefined
  [RefundsNavigatorRouteNames.RefundRepartition]: undefined
  [RefundsNavigatorRouteNames.RefundPlanChoice]: undefined
  [RefundsNavigatorRouteNames.RefundsRecap]: undefined
  [RefundsNavigatorRouteNames.RefundAskProof]: undefined
  [RefundsNavigatorRouteNames.RefundSuccess]: undefined
  [RefundsNavigatorRouteNames.RefundFailed]: undefined
  [RefundsNavigatorRouteNames.RefundModal]: {
    clearAction:
      | CommonRefundActionsType.CLEAR_REFUND_DATA
      | InstallmentActionsType.CLEAR_INSTALLMENT_DATA
      | ArbitrationActionsType.CLEAR_ARBITRATION_DATA
  }
}
