import { Context, Styles } from './interfaces';

export const getStyles = (
  context: Context,
  style: Styles
): Styles => ({
  containerStyle: [
    {
      flex: 1,
      backgroundColor: context?.theme.colors.basics.white
    },
    style?.containerStyle
  ],
  valueContainerStyle: [
    {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: context?.theme.metrics.spacing.xs
    },
    style?.valueContainerStyle
  ],
  headSupportsContainerStyle: [
    {
      padding: context?.theme.metrics.spacing.s
    },
    style?.headSupportsContainerStyle
  ],
  wrapperContentStyle: [
    {
      marginTop: context?.theme.metrics.spacing.s,
      marginHorizontal: -context?.theme.metrics.spacing.s
    },
    style?.wrapperContentStyle
  ]
});
