import React, { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import {
  useTheme,
} from '@components/index';
import { palette } from '@ere-uilib/styles/theme/themeFolder/colors/palette';

export const useStyles = () => {
  const theme = useTheme()
  const styles = useMemo(() => StyleSheet.create({
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    renderContentContainerStyle: {
      marginTop: theme?.metrics.spacing.s
    },
    contentStyles:
    {
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      marginBottom: theme.metrics.spacing.xm,
    },
    titleStyles:
    {
      textAlign: 'center',
      marginVertical: theme.metrics.spacing.s,
    },
    htmlText:
    {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
    },
    rowStyle: {
      flexDirection: 'row',
      marginBottom: theme?.metrics.spacing.m
    },
    iconStyle: {
      width: 20,
      height: 20,
      backgroundColor: palette.greenDark_1.c500,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: theme.metrics.borderRadius.s,
      marginRight: theme?.metrics.spacing.s
    },
    mandatSampleStyle: {
      display: 'flex',
      flexDirection: 'row',
    },
    mandatSampleLinkStyle: {
      marginLeft: theme.metrics.spacing.xs,
      lineHeight: theme.metrics.spacing.m
    }
  }), [theme])
  return styles
};
