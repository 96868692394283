import { BankOperationTypesList, PlanFamillyList } from '@constants/index';
import { AppState } from '@modules/reducers';
import { getApplicationSettings } from '@modules/settings/selectors';

import { BankDetailsActionsType } from './actions/actionsTypes';
import { ALLOWED_FILE_EXTENSIONS, BankStatusTypesEnum } from './constants';
import { FileMaxSizeType } from './types';

export const getBankData = (state: AppState) => state.bankDetails.bankData;

export const getBankInit = (state: AppState) => state.bankDetails.initData;

export const getBankLoadingState = (state: AppState) => state.isLoading[BankDetailsActionsType.REGISTER_BANK_DATA_REQUEST];
export const getBankDataLoadingState = (state: AppState) => state.isLoading[BankDetailsActionsType.INIT_BANK_DATA_REQUEST];

export const getBankDocumentMandatRc = (state: AppState) => state.bankDetails.documentMandatRc;

export const getJourneyId = (state: AppState) => getBankInit(state)?.journeyId;

export const getContractId = (state: AppState) => getBankInit(state)?.contractId;

export const getDocument = (state: AppState) => state.bankDetails.document;

export const getBankDetailsError = (state: AppState) => state.bankDetails.error;

export const getSelectedPlanFamily = (state: AppState) => state.bankDetails.selectedPlanFamily;

export const getSelectedPlanId = (state: AppState) => state.bankDetails.selectedPlanId;

export const getSelectedDomiciliationCountry = (state: AppState) =>
  state.bankDetails.selectedCountry;

export const getSelectedAccountType = (state: AppState) => state.bankDetails.selectedAccountType;

export const getQuickSignUrl = (state: AppState) => state.bankDetails.quickSignUrl;

export const getDocumentId = (documentKey: string) => (state: AppState) =>
  state.bankDetails.document[documentKey].documentId;

export const getDocumentData = (documentKey: string) => (state: AppState) =>
  state.bankDetails.document?.[documentKey]?.file;

export const getUpdateFinishedStatus = (state: AppState) => state.bankDetails.updateFinishedStatus;
export const getESBankData = (state: AppState) => state.bankDetails?.bankData?.es;

export const getSelectedOperation = (state: AppState) =>
  getESBankData(state)?.[
  getSelectedAccountType(state) === BankOperationTypesList.PRELEVEMENT
    ? 'debitBankAccountDetails'
    : 'transferBankAccountDetails'
  ];

export const getInputMode = (state: AppState) => getSelectedOperation(state)?.inputMode;

export const getStatus = (state: AppState) => getSelectedOperation(state)?.status;

export const getAddressData = (state: AppState) => getSelectedOperation(state)?.tsaAddress;

export const getSignatureAllowed = (state: AppState) =>
  getSelectedPlanFamily(state) === PlanFamillyList.ES && !!getESBankData(state)?.signatureAllowed;

export const getShouldUploadDocByDoc = (state: AppState) =>
  getSignatureAllowed(state) && !!getSelectedDomiciliationCountry(state);

export const getWithEREOTP = (state: AppState) =>
  getSignatureAllowed(state) && getStatus(state) === BankStatusTypesEnum.VALIDE;

export const getWithQuicksignOTP = (state: AppState) =>
  getSignatureAllowed(state) && getStatus(state) === BankStatusTypesEnum.INVALIDE;

export const getMaxFileSizes = (state: AppState): FileMaxSizeType | null => {
  const { CBRCTailleFichier = 0 } = getApplicationSettings(state);
  const selectedPlanFamily = getSelectedPlanFamily(state);

  if (selectedPlanFamily === PlanFamillyList.RC) {
    return {
      fileMaxSize: CBRCTailleFichier,
    };
  }

  const { fileMaxSize, totalFileMaxSize } = getBankInit(state);

  const bankData = getESBankData(state)!;

  const { debitBankAccountDetails, transferBankAccountDetails, ...rest } = bankData || {};

  const getValues = (source: FileMaxSizeType | null) => {
    const { fileMaxSize = 0, totalFileMaxSize = 0 } = source ?? {};
    if (fileMaxSize && totalFileMaxSize) {
      return { fileMaxSize, totalFileMaxSize };
    }
    return null;
  };

  const isDebitAccount = getSelectedAccountType(state) === BankOperationTypesList.PRELEVEMENT

  const sources = [
    { fileMaxSize, totalFileMaxSize },
    isDebitAccount
      ? getValues(debitBankAccountDetails)
      : getValues(transferBankAccountDetails),
    getValues(rest)
  ];

  for (const source of sources) {
    const result = getValues(source);
    if (result) {
      return result;
    }
  }

  return null;
};

export const getSelectedDocaposteChanel = (state: AppState) => state.bankDetails.selectedOTPChannel;

export const getAllowedFileExtensions = (state: AppState) => {
  const selectedPlanFamily = getSelectedPlanFamily(state);
  const { CBRCTypeFichier = '' } = getApplicationSettings(state);
  const allowedFileExtensionsRC = CBRCTypeFichier.split(',');
  return (
    (selectedPlanFamily === PlanFamillyList.RC && allowedFileExtensionsRC) ||
    ALLOWED_FILE_EXTENSIONS
  );
};
