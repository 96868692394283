import { ErrorActionsType } from '@modules/error/actions/errorActionsTypes';
import { LegalState } from './types';
import { dispatchMenuFooterIconName } from './utils';
import { LegalAction, LegalActionsType } from './actions/legalActionsTypes';

const initialState: LegalState = {
  legalContentPage: {
    code: '',
    content: ''
  },
  menuFooter: [],
  error: {
    code: '',
    correlationId: '',
    message: '',
    innerMessage: ''
  }
};

export default function LegalReducer(
  state: LegalState = initialState,
  action: LegalAction,
): LegalState {
  switch (action?.type) {
  case LegalActionsType.GET_LEGAL_CONTENT_REQUEST:
    return {
      ...state,
      legalContentPage: initialState.legalContentPage
    };
  case LegalActionsType.GET_LEGAL_CONTENT_SUCCESS:
    return {
      ...state,
      legalContentPage: action.content
    };
    case LegalActionsType.GET_MENU_FOOTER_REQUEST:
    return {
      ...state,
    };
  case LegalActionsType.GET_MENU_FOOTER_SUCCESS:
    return {
      ...state,
      menuFooter: dispatchMenuFooterIconName(action.links),
    };
    
  case LegalActionsType.GET_MENU_FOOTER_FAILURE:
    return {
      ...state,
      error: action.error
    };
  case LegalActionsType.GET_LEGAL_CONTENT_FAILURE:
    return {
      ...state,
      error: action.error
    };
  case LegalActionsType.LEGAL_INIT_ERROR:
  case ErrorActionsType.INIT_ALL_ERRORS:
    return {
      ...state,
      error: initialState.error
    };
  default:
    return state;
  }
}
