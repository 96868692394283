import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ProfileActionsType } from '@modules/profile/actions/profileActionsTypes';
import {
  profileCheckEditMailAuthorizeIsEnableRequest,
  sendProfileUserInfoChangeRequest,
} from '@modules/profile/actions/profileActions';
import { AppState } from '@modules/reducers';
import {
  getEditMailIsEnable,
  getOtpError,
  getProfileError,
  getProfileUserInfos,
} from '@modules/profile/selectors';

import { ProfileEditEmailPageComponent } from './ProfileEditEmailPage.component';
import { AppNavigatorInterface } from '@navigation/Interfaces';
import { StackNavigationProp } from '@react-navigation/stack';
import { UserInfoTypeEnum } from '@constants/profile';

interface Props {
  navigation: StackNavigationProp<AppNavigatorInterface>;
}

export const ProfileEditEmailPage: React.FC<React.PropsWithChildren<Props>> = ({
  navigation
}) => {

  const dispatch = useDispatch();

  const profileUserInfos = useSelector(getProfileUserInfos);
  const error = useSelector(getProfileError);
  const otpError = useSelector(getOtpError)
  const isProfileLoading = useSelector(
    (state: AppState) => state.isLoading[ProfileActionsType.SEND_PROFILE_USER_INFO_CHANGE_REQUEST]);
  const editMailIsEnable = useSelector(getEditMailIsEnable);

  const onSetProfileUserInfoChangeRequest = useCallback((
    userInfosType: UserInfoTypeEnum,
    updatedMail: string
  ) => {
    return dispatch(sendProfileUserInfoChangeRequest(userInfosType, updatedMail))
  }, [dispatch]);

  const onCheckEditMailIsEnable = useCallback(() => {
    return dispatch(profileCheckEditMailAuthorizeIsEnableRequest());
  }, [dispatch]);

  return (
    <ProfileEditEmailPageComponent
      navigation={navigation}
      profileUserInfos={profileUserInfos}
      error={error}
      otpError={otpError}
      isProfileLoading={isProfileLoading}
      editMailIsEnable={editMailIsEnable!}
      onSetProfileUserInfoChangeRequest={onSetProfileUserInfoChangeRequest}
      onCheckEditMailIsEnable={onCheckEditMailIsEnable}
    />
  )
}
