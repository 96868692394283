import * as actions from '@modules/refund/available-refund/actions/refundActionsTypes'
import { RefundActionsType } from '@modules/refund/available-refund/actions/refundActionsTypes'
import {
  RefundRepartitionState,
  RefundAmountValidationParamsType,
  RepaymentDemandResponseState
} from '@modules/refund/available-refund/types'
import { RefundErrorState } from '@modules/refund/types'
import { TriggerThresholdPayloadData } from '@pages/Common'

// Refund Repartition actions
export function getRefundRepartitionRequest(): actions.GetRefundRepartitionRequestAction {
  return {
    type: RefundActionsType.GET_REFUND_REPARTITION_REQUEST
  }
}

export function getRefundRepartitionSuccess(
  repartition: RefundRepartitionState
): actions.GetRefundRepartitionSuccessAction {
  return {
    type: RefundActionsType.GET_REFUND_REPARTITION_SUCCESS,
    repartition
  }
}

export function getRefundRepartitionFailure(
  error: RefundErrorState
): actions.GetRefundRepartitionFailureAction {
  return {
    type: RefundActionsType.GET_REFUND_REPARTITION_FAILURE,
    error
  }
}

export function setRefundSupportTriggerThresholdAndFormatRepartition({
  compartmentId,
  managementId,
  supportIsInCode,
  triggerPointData
}: {
  compartmentId: string
  managementId: string
  supportIsInCode: string
  triggerPointData: TriggerThresholdPayloadData
}): actions.SetSupportTriggerThresholdAndFormatRepartition {
  return {
    type: RefundActionsType.SET_SUPPORT_TRIGGER_POINT_AND_FORMAT_REPARTITION,
    compartmentId,
    managementId,
    supportIsInCode,
    triggerPointData
  }
}

export function clearSupportTriggerThreshold({
  compartmentId,
  managementId,
  supportIsInCode
}: {
  compartmentId: string
  managementId: string
  supportIsInCode: string
}): actions.ClearSupportTriggerThreshold {
  return {
    type: RefundActionsType.CLEAR_SUPPORT_TRIGGER_THRESHOLD,
    compartmentId,
    managementId,
    supportIsInCode
  }
}

export function setRefundTriggerThresholdModalVisibleState(
  state: boolean
): actions.SetRefundTriggerThresholdModalVisibleStateAction {
  return {
    type: RefundActionsType.SET_REFUND_TRIGGER_POINT_MODAL_VISIBLE_STATE,
    state
  }
}

export function setRefundRepartitionAmount(
  params: RefundAmountValidationParamsType
): actions.SetRefundRepartitionAmountAction {
  return {
    type: RefundActionsType.SET_REFUND_REPARTITION_AMOUNT,
    params
  }
}

export function setRefundRepartitionAmountModalVisibleState(
  state: boolean
): actions.SetRefundRepartitionAmountModalVisibleStateAction {
  return {
    type: RefundActionsType.SET_REFUND_REPARTITION_AMOUNT_MODAL_VISIBLE_STATE,
    state
  }
}

// Refund Repartition select all support
export function selectRefundRepartitionAllSupport({
  selectState
}: {
  selectState: boolean
}): actions.SelectRefundRepartitionAllSupportAction {
  return {
    type: RefundActionsType.REFUND_REPARTITION_SELECT_ALL_SUPPORT,
    selectState
  }
}

// Refund Repartition select one support
export function selectRefundRepartitionSupport({
  selectState,
  amount,
  compartmentId,
  managementId,
  isinCode
}: {
  selectState: boolean
  amount?: number
  compartmentId: string
  managementId: string
  isinCode: string
}): actions.SelectRefundRepartitionSupportAction {
  return {
    type: RefundActionsType.REFUND_REPARTITION_SELECT_SUPPORT,
    selectState,
    amount,
    compartmentId,
    managementId,
    isinCode
  }
}

// create repayment demand

export function createRepaymentDemandRequest(): actions.CreateRepaymentRequestAction {
  return {
    type: RefundActionsType.CREATE_REPAYMENT_DEMAND_REQUEST
  }
}

export function createRepaymentDemandSuccess(
  repaymentDemandResponse: RepaymentDemandResponseState // @todo
): actions.CreateRepaymentSuccessAction {
  return {
    type: RefundActionsType.CREATE_REPAYMENT_DEMAND_SUCCESS,
    repaymentDemandResponse
  }
}

export function createRepaymentDemandFailure(
  error: RefundErrorState
): actions.CreateRepaymentFailureAction {
  return {
    type: RefundActionsType.CREATE_REPAYMENT_DEMAND_FAILURE,
    error
  }
}
