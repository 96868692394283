import { connect } from 'react-redux';

import { getRedirectOperationUrlRequest } from '@modules/redirect-operation/actions/redirectOperationActions';
import { RedirectOperationActionsType } from '@modules/redirect-operation/actions/redirectOperationActionsTypes';
import { AppState } from '@modules/reducers';
import { getRedirectError } from '@modules/redirect-operation/selectors';

import { RedirectOperation } from './RedirectOperation.component';

const mapStateToProps = (state: AppState) => ({
  isLoading: state.isLoading[RedirectOperationActionsType.GET_OPERATION_REDIRECT_URL_REQUEST],
  redirectError: getRedirectError(state),
});

const mapDispatchToProps = {
  onGetRedirectOpeationUrl: getRedirectOperationUrlRequest,
};

export const RedirectOperationPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(RedirectOperation);
