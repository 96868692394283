import React from 'react';

import { LoaderPlaceholderView } from '../LoaderPlaceholderView';

interface Props{
  height: number
  testID?: string
}

export function RectangleLoader({
  height,
  testID,
}: Props) {
  return (
    <LoaderPlaceholderView
      style={{ height, borderRadius: undefined }}
      testID={testID} />
  );
}
