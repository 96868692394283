export enum ForgetPasswordNavigatorRouteNames {
  ForgetPasswordBegin = 'ForgetPasswordBegin',
  ForgetPasswordValidationCodePage = 'ForgetPasswordValidationCodePage',
  ForgetPasswordChoosePasswordPage = 'ForgetPasswordChoosePasswordPage',
  ForgetPasswordCongratsPage = 'ForgetPasswordCongratsPage',
}

export type ForgetPasswordNavigatorInterface = {
  [ForgetPasswordNavigatorRouteNames.ForgetPasswordBegin]: {
    shouldRedirectAfterSuccess: string
  } | undefined
  [ForgetPasswordNavigatorRouteNames.ForgetPasswordValidationCodePage]: {
    isForgetPasswordPhoneNumberVerify: boolean
  } | undefined
  [ForgetPasswordNavigatorRouteNames.ForgetPasswordChoosePasswordPage]: undefined
  [ForgetPasswordNavigatorRouteNames.ForgetPasswordCongratsPage]: undefined
}
