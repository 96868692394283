import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

export const useStyles = () => {
  const theme = useTheme();

  const styles = useMemo(
    () =>
      StyleSheet.create({
        content: {
          alignItems: 'center'
        },
        subtitle: {
          fontFamily: theme.fonts.fontFamily.light
        },
        svgContainer: {
          width: 228,
          height: 133,
          minHeight: undefined
        },
        paragraph: {
          textAlign: 'center'
        }
      }),
    [theme]
  );

  return styles;
};
