import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useScreenSizes, useTheme } from '@ere-uilib/styles';

export function useStyles() {
  const theme = useTheme();
  const { isMobile, isTablet } = useScreenSizes();
  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          backgroundColor: theme.colors.basics.white
        },
        contentTitle: {
          textAlign: 'center',
          padding: theme.metrics.spacing.m
        },
        buttonContainer: {
          marginVertical: theme.metrics.spacing.s,
          alignSelf: isMobile || isTablet ? 'auto' : 'flex-start'
        },
        contentContainer: {
          flex: 1,
          marginHorizontal: !(isMobile || isTablet)
            ? theme.metrics.spacing.xhuge
            : theme.metrics.spacing.s
        },
        htmlContentContainer: {
          marginVertical: theme.metrics.spacing.l
        },
        tabFormWrapper: {
          marginTop: theme.metrics.spacing.xm,
          marginBottom: theme.metrics.spacing.l,
          maxWidth: (isMobile || isTablet) ? '100%' : 440
        },
        tabFormTitle: {
          marginBottom: theme.metrics.spacing.s
        }
      }),
    [isMobile, isTablet, theme]
  );

  return styles;
}
