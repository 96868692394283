import {
  DispositifState,
  OperationDecisionState,
  ResponseType
} from '@modules/common/types'

import { CommonRefundActionsType, RefundErrorState, RefundsCommonState } from '../types'
import * as actions from './refundActionsTypes'

export function clearRefundData(): actions.ClearRefundDataAction {
  return {
    type: CommonRefundActionsType.CLEAR_REFUND_DATA
  }
}

export function refundInitError(): actions.RefundInitErrorAction {
  return {
    type: CommonRefundActionsType.REFUND_INIT_ERROR
  }
}

export function getRefundDecisionRequest(): actions.GetRefundDecisionRequestAction {
  return {
    type: CommonRefundActionsType.GET_REFUND_DECISION_REQUEST
  }
}

export function getRefundDecisionSuccess(
  decision: OperationDecisionState
): actions.GetRefundDecisionSuccessAction {
  return {
    type: CommonRefundActionsType.GET_REFUND_DECISION_SUCCESS,
    decision
  }
}

export function getRefundDecisionFailure(
  error: RefundErrorState
): actions.GetRefundDecisionFailureAction {
  return {
    type: CommonRefundActionsType.GET_REFUND_DECISION_FAILURE,
    error
  }
}

/// update question with set response

export function updateRefundsQuestionWithResponseRequest(
  response: ResponseType,
  index: number,
  canNotBeAsked: boolean
): actions.UpdateRefundsQuestionWithResponse {
  return {
    type: CommonRefundActionsType.UPDATE_REFUNDS_QUESTION_WITH_SET_RESPONSE,
    response,
    index,
    canNotBeAsked
  }
}

export function updateUnavailableRefundsQuestionWithResponseRequest(
  newDecision: RefundsCommonState['decision']
): actions.UpdateUnavailableRefundsQuestionWithResponse {
  return {
    type: CommonRefundActionsType.UPDATE_UNAVAILABLE_REFUNDS_QUESTION_WITH_SET_RESPONSE,
    newDecision
  }
}

export function refundsBackToQuestionRequest(index: number): actions.RefundsBackToQuestion {
  return {
    type: CommonRefundActionsType.REFUNDS_BACK_TO_QUESTION,
    index
  }
}

export function setRefundsEligibleDispositifs(
  elligibleDispositifs: DispositifState[]
): actions.SetRefundsEligibleDispositifs {
  return {
    type: CommonRefundActionsType.SET_REFUNDS_ELIGIBLE_DISPOSITIFS,
    elligibleDispositifs
  }
}

export function setRefundsSelectedDispositif(
  selectedDispositif: DispositifState[]
): actions.SetRefundsSelectedDispositif {
  return {
    type: CommonRefundActionsType.SET_REFUNDS_SELECTED_DISPOSITIF,
    selectedDispositif
  }
}
