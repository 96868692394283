import {
  AccountsErrorState,
  AccountsState,
  AccountState,
  AllocationsState,
  AvailabilitiesState,
  BudgetInsightErrorType,
  BudgetInsightState,
  CibledContributionContentState,
  CibledContributionErrorState,
  ContributionByCompanyType,
  ContributionByOperationType,
  OCDState,
  RecentOperationsState,
  ToolsFlowTypeEnum,
  ToolState
} from '@modules/dashboard/types'
import { ErrorObjectState } from '@modules/error/types'

export enum DashboardActionsType {
  GET_DASHBOARD_REQUEST = 'GET_DASHBOARD_REQUEST',
  GET_DASHBOARD_FAILURE = 'GET_DASHBOARD_FAILURE',
  GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS',
  GET_ACCOUNTS_REQUEST = 'GET_ACCOUNTS_REQUEST',
  GET_ACCOUNTS_SUCCESS = 'GET_ACCOUNTS_SUCCESS',
  GET_ACCOUNTS_FAILURE = 'GET_ACCOUNTS_FAILURE',
  GET_ACCOUNTS_DATA_REQUEST = 'GET_ACCOUNTS_DATA_REQUEST',
  GET_ACCOUNTS_DATA_FAILURE = 'GET_ACCOUNTS_DATA_FAILURE',
  PUT_SELECTED_COMPANY_REQUEST = 'PUT_SELECTED_COMPANY_REQUEST',
  PUT_SELECTED_COMPANY_SUCCESS = 'PUT_SELECTED_COMPANY_SUCCESS',
  GET_AVAILABILITIES_REQUEST = 'GET_AVAILABILITIES_REQUEST',
  GET_AVAILABILITIES_SUCCESS = 'GET_AVAILABILITIES_SUCCESS',
  GET_AVAILABILITIES_FAILURE = 'GET_AVAILABILITIES_FAILURE',
  GET_ALLOCATIONS_REQUEST = 'GET_ALLOCATIONS_REQUEST',
  GET_ALLOCATIONS_SUCCESS = 'GET_ALLOCATIONS_SUCCESS',
  GET_ALLOCATIONS_FAILURE = 'GET_ALLOCATIONS_FAILURE',
  DASHBOARD_INIT_ERROR = 'DASHBOARD_INIT_ERROR',
  AVAILABILITIES_INIT_ERROR = 'AVAILABILITIES_INIT_ERROR',
  ALLOCATIONS_INIT_ERROR = 'ALLOCATIONS_INIT_ERROR',
  OCD_INIT_ERROR = 'OCD_INIT_ERROR',
  RECENT_OPERATIONS_INIT_ERROR = 'RECENT_OPERATIONS_INIT_ERROR',
  GET_TOOL_REQUEST = 'GET_TOOL_REQUEST',
  GET_TOOL_SUCCESS = 'GET_TOOL_SUCCESS',
  GET_TOOL_FAILURE = 'GET_TOOL_FAILURE',
  GET_OCD_REQUEST = 'GET_OCD_REQUEST',
  GET_OCD_SUCCESS = 'GET_OCD_SUCCESS',
  GET_OCD_FAILURE = 'GET_OCD_FAILURE',
  GET_RECENT_OPERATIONS_REQUEST = 'GET_RECENT_OPERATIONS_REQUEST',
  GET_RECENT_OPERATIONS_SUCCESS = 'GET_RECENT_OPERATIONS_SUCCESS',
  GET_RECENT_OPERATIONS_FAILURE = 'GET_RECENT_OPERATIONS_FAILURE',
  BACK_OFFICE_IMPLICIT_CONNECTION = 'BACK_OFFICE_IMPLICIT_CONNECTION',
  GET_CONTRIBUTION_REQUEST = 'GET_CONTRIBUTION_REQUEST',
  GET_CONTRIBUTION_SUCCESS = 'GET_CONTRIBUTION_SUCCESS',
  GET_CONTRIBUTION_FAILURE = 'GET_CONTRIBUTION_FAILURE',
  GET_CONTRIBUTION_BY_OPERATION_REQUEST = 'GET_CONTRIBUTION_BY_OPERATION_REQUEST',
  GET_CONTRIBUTION_BY_OPERATION_SUCCESS = 'GET_CONTRIBUTION_BY_OPERATION_SUCCESS',
  GET_CONTRIBUTION_BY_OPERATION_FAILURE = 'GET_CONTRIBUTION_BY_OPERATION_FAILURE',
  GET_BUDGET_INSIGHT_URL_REQUEST = 'GET_BUDGET_INSIGHT_URL_REQUEST',
  GET_BUDGET_INSIGHT_URL_SUCCESS = 'GET_BUDGET_INSIGHT_URL_SUCCESS',
  GET_BUDGET_INSIGHT_URL_FAILURE = 'GET_BUDGET_INSIGHT_URL_FAILURE',
  GET_BUDGET_INSIGHT_ACCOUNTS_REQUEST = 'GET_BUDGET_INSIGHT_ACCOUNTS_REQUEST',
  GET_BUDGET_INSIGHT_ACCOUNTS_SUCCESS = 'GET_BUDGET_INSIGHT_ACCOUNTS_SUCCESS',
  GET_BUDGET_INSIGHT_ACCOUNTS_FAILURE = 'GET_BUDGET_INSIGHT_ACCOUNTS_FAILURE',
  GET_BUDGET_INSIGHT_MANAGER_URL_REQUEST = 'GET_BUDGET_INSIGHT_MANAGER_URL_REQUEST',
  GET_BUDGET_INSIGHT_MANAGER_URL_SUCCESS = 'GET_BUDGET_INSIGHT_MANAGER_URL_SUCCESS',
  GET_BUDGET_INSIGHT_MANAGER_URL_FAILURE = 'GET_BUDGET_INSIGHT_MANAGER_URL_FAILURE',
  GET_CIBLED_CONTRIBUTION_PAGE_CONTENT_REQUEST =
  'GET_CIBLED_CONTRIBUTION_PAGE_CONTENT_REQUEST',
  GET_CIBLED_CONTRIBUTION_PAGE_CONTENT_SUCCESS =
  'GET_CIBLED_CONTRIBUTION_PAGE_CONTENT_SUCCESS',
  GET_CIBLED_CONTRIBUTION_PAGE_CONTENT_FAILURE =
  'GET_CIBLED_CONTRIBUTION_PAGE_CONTENT_FAILURE',
  GET_SSO_REDIRECT_URL_REQUEST = 'GET_SSO_REDIRECT_URL_REQUEST',
  GET_SSO_REDIRECT_URL_SUCCESS = 'GET_SSO_REDIRECT_URL_SUCCESS',
  GET_SSO_REDIRECT_URL_FAILURE = 'GET_SSO_REDIRECT_URL_FAILURE'
}

export interface GetDashboardRequestAction {
  type: DashboardActionsType.GET_DASHBOARD_REQUEST;
}
export interface GetDashboardFailureAction {
  type: DashboardActionsType.GET_DASHBOARD_FAILURE;
  error: AccountsErrorState;
}
export interface GetDashboardSuccessAction {
  type: DashboardActionsType.GET_DASHBOARD_SUCCESS;
}
export interface GetAccountsRequestAction {
  type: DashboardActionsType.GET_ACCOUNTS_REQUEST
}

export interface GetAccountsSuccessAction {
  type: DashboardActionsType.GET_ACCOUNTS_SUCCESS
  accounts: AccountsState
}

export interface GetAccountsFailureAction {
  type: DashboardActionsType.GET_ACCOUNTS_FAILURE
  error: AccountsErrorState
}

/// account data (repartitions ..)

export interface GetAccountDataRequestAction {
  type: DashboardActionsType.GET_ACCOUNTS_DATA_REQUEST
}

export interface GetAccountDataFailureAction {
  type: DashboardActionsType.GET_ACCOUNTS_DATA_FAILURE
  error: AccountsErrorState
}

// set selected company

export interface PutSelectedCompanyRequestAction {
  type: DashboardActionsType.PUT_SELECTED_COMPANY_REQUEST
  company: AccountState
}

export interface PutSelectedCompanySuccessAction {
  type: DashboardActionsType.PUT_SELECTED_COMPANY_SUCCESS
  company: AccountState
}

// Availabilities

export interface GetAvailabilitiesRequestAction {
  type: DashboardActionsType.GET_AVAILABILITIES_REQUEST
  companyId: string
}

export interface GetAvailabilitiesSuccessAction {
  type: DashboardActionsType.GET_AVAILABILITIES_SUCCESS
  availabilities: AvailabilitiesState
}

export interface GetAvailabilitiesFailureAction {
  type: DashboardActionsType.GET_AVAILABILITIES_FAILURE
  error: AccountsErrorState
}

// Allocations

export interface GetAllocationsRequestAction {
  type: DashboardActionsType.GET_ALLOCATIONS_REQUEST
  companyId: string
}

export interface GetAllocationsSuccessAction {
  type: DashboardActionsType.GET_ALLOCATIONS_SUCCESS
  allocations: AllocationsState[]
}

export interface GetAllocationsFailureAction {
  type: DashboardActionsType.GET_ALLOCATIONS_FAILURE
  error: AccountsErrorState
}

// INIT ERROR

export interface DashboardInitErrorAction {
  type: DashboardActionsType.DASHBOARD_INIT_ERROR
}

export interface AvailabilitiesInitErrorAction {
  type: DashboardActionsType.AVAILABILITIES_INIT_ERROR
}

export interface AllocationsInitErrorAction {
  type: DashboardActionsType.ALLOCATIONS_INIT_ERROR
}

export interface OCDInitErrorAction {
  type: DashboardActionsType.OCD_INIT_ERROR
}

export interface RecentOperationsInitErrorAction {
  type: DashboardActionsType.RECENT_OPERATIONS_INIT_ERROR
}

// TOOL

export interface GetToolRequestAction {
  type: DashboardActionsType.GET_TOOL_REQUEST
  flow: ToolsFlowTypeEnum
}

export interface GetToolSuccessAction {
  type: DashboardActionsType.GET_TOOL_SUCCESS
  tools: ToolState[]
  flow: ToolsFlowTypeEnum
}

export interface GetToolFailureAction {
  type: DashboardActionsType.GET_TOOL_FAILURE
  error: AccountsErrorState
}

// OCD

export interface GetOCDRequestAction {
  type: DashboardActionsType.GET_OCD_REQUEST
}

export interface GetOCDSuccessAction {
  type: DashboardActionsType.GET_OCD_SUCCESS
  OCD: OCDState[]
}

export interface GetOCDFailureAction {
  type: DashboardActionsType.GET_OCD_FAILURE
  error: AccountsErrorState
}

//Recent OPERATIONS

export interface GetRecentOperationsRequestActions {
  type: DashboardActionsType.GET_RECENT_OPERATIONS_REQUEST
  companyId: string
}

export interface GetRecentOperationSuccessAction {
  type: DashboardActionsType.GET_RECENT_OPERATIONS_SUCCESS
  recentOperations: RecentOperationsState[]
}

export interface GetRecentOperationsFailureAction {
  type: DashboardActionsType.GET_RECENT_OPERATIONS_FAILURE
  error: AccountsErrorState
}

// backoffice implicit connection

export interface BackOfficeImplicitConnectionAction {
  type: DashboardActionsType.BACK_OFFICE_IMPLICIT_CONNECTION
  token: string
  clientId: string
}

// abondement

export interface GetContributionRequestAction {
  type: DashboardActionsType.GET_CONTRIBUTION_REQUEST
}

export interface GetContributionSuccessAction {
  type: DashboardActionsType.GET_CONTRIBUTION_SUCCESS
  contribution: ContributionByCompanyType | null
}

export interface GetContributionFailureAction {
  type: DashboardActionsType.GET_CONTRIBUTION_FAILURE
  error: AccountsErrorState
}

// abondement by operation
export interface GetContributionByOperationRequestAction {
  type: DashboardActionsType.GET_CONTRIBUTION_BY_OPERATION_REQUEST
}

export interface GetContributionByOperationSuccessAction {
  type: DashboardActionsType.GET_CONTRIBUTION_BY_OPERATION_SUCCESS
  contributionByOperation: ContributionByOperationType | null
}

export interface GetContributionByOperationFailureAction {
  type: DashboardActionsType.GET_CONTRIBUTION_BY_OPERATION_FAILURE
  error: AccountsErrorState
}
// BI
export interface GetBudgetInsightUrlActionRequest {
  type: DashboardActionsType.GET_BUDGET_INSIGHT_URL_REQUEST
}

export interface GetBudgetInsightUrlActionSuccess {
  type: DashboardActionsType.GET_BUDGET_INSIGHT_URL_SUCCESS
  budgetInsightUrl: string
}

export interface GetBudgetInsightUrlActionFailure {
  type: DashboardActionsType.GET_BUDGET_INSIGHT_URL_FAILURE
  error: AccountsErrorState
}

// BI Get Accounts Action

export interface GetBudgetInsightAccountsRequestAction {
  type: DashboardActionsType.GET_BUDGET_INSIGHT_ACCOUNTS_REQUEST
}

export interface GetBudgetInsightAccountsSuccessAction {
  type: DashboardActionsType.GET_BUDGET_INSIGHT_ACCOUNTS_SUCCESS
  budgetInsightAccounts: BudgetInsightState[]
}

export interface GetBudgetInsightAccountsFailureAction {
  type: DashboardActionsType.GET_BUDGET_INSIGHT_ACCOUNTS_FAILURE
  error: BudgetInsightErrorType
}

export interface GetBudgetInsightManagerUrlActionRequest {
  type: DashboardActionsType.GET_BUDGET_INSIGHT_MANAGER_URL_REQUEST
}

export interface GetBudgetInsightManagerUrlActionSuccess {
  type: DashboardActionsType.GET_BUDGET_INSIGHT_MANAGER_URL_SUCCESS
  budgetInsightManagerUrl: string
}

export interface GetBudgetInsightManagerUrlActionFailure {
  type: DashboardActionsType.GET_BUDGET_INSIGHT_MANAGER_URL_FAILURE
  error: AccountsErrorState
}

export interface GetSSORedirectionUrlActionRequest {
  type: DashboardActionsType.GET_SSO_REDIRECT_URL_REQUEST
}

export interface GetSSORedirectionUrlActionSuccess {
  type: DashboardActionsType.GET_SSO_REDIRECT_URL_SUCCESS
  data: string
}

export interface GetSSORedirectionUrlActionFailure {
  type: DashboardActionsType.GET_SSO_REDIRECT_URL_FAILURE
  error: ErrorObjectState
}

export interface GetCibledContributionPageContentRequestAction {
  type: DashboardActionsType.GET_CIBLED_CONTRIBUTION_PAGE_CONTENT_REQUEST;
  code: string;
}

export interface GetCibledContributionPageContentSuccessAction {
  type: DashboardActionsType.GET_CIBLED_CONTRIBUTION_PAGE_CONTENT_SUCCESS;
  content: CibledContributionContentState;
}

export interface GetCibledContributionPageContentFailureAction {
  type: DashboardActionsType.GET_CIBLED_CONTRIBUTION_PAGE_CONTENT_FAILURE;
  error: CibledContributionErrorState;
}

export type AccountsAction =
  | GetDashboardRequestAction
  | GetDashboardFailureAction
  | GetAccountsRequestAction
  | GetAccountsSuccessAction
  | GetAccountsFailureAction
  | GetAvailabilitiesRequestAction
  | GetAvailabilitiesSuccessAction
  | GetAvailabilitiesFailureAction
  | GetAllocationsRequestAction
  | GetAllocationsSuccessAction
  | GetAllocationsFailureAction
  | GetOCDRequestAction
  | GetOCDSuccessAction
  | GetOCDFailureAction
  | DashboardInitErrorAction
  | PutSelectedCompanyRequestAction
  | PutSelectedCompanySuccessAction
  | AvailabilitiesInitErrorAction
  | AllocationsInitErrorAction
  | OCDInitErrorAction
  | GetRecentOperationSuccessAction
  | GetRecentOperationsFailureAction
  | GetRecentOperationsRequestActions
  | RecentOperationsInitErrorAction
  | BackOfficeImplicitConnectionAction
  | GetToolRequestAction
  | GetToolSuccessAction
  | GetToolFailureAction
  | GetContributionRequestAction
  | GetContributionSuccessAction
  | GetContributionFailureAction
  | GetContributionByOperationRequestAction
  | GetContributionByOperationSuccessAction
  | GetContributionByOperationFailureAction
  | GetBudgetInsightUrlActionRequest
  | GetBudgetInsightUrlActionSuccess
  | GetBudgetInsightUrlActionFailure
  | GetBudgetInsightAccountsRequestAction
  | GetBudgetInsightAccountsSuccessAction
  | GetBudgetInsightAccountsFailureAction
  | GetBudgetInsightManagerUrlActionRequest
  | GetBudgetInsightManagerUrlActionSuccess
  | GetBudgetInsightManagerUrlActionFailure
  | GetCibledContributionPageContentRequestAction
  | GetCibledContributionPageContentSuccessAction
  | GetCibledContributionPageContentFailureAction
  | GetSSORedirectionUrlActionRequest
  | GetSSORedirectionUrlActionSuccess
  | GetSSORedirectionUrlActionFailure
  |  GetDashboardSuccessAction
