import { useMemo } from 'react';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';

import {
  useTheme,
  useScreenSizes
} from '@ere-uilib/styles';

export const  useStyles =({
  style,
}:{
  style?: StyleProp<ViewStyle>
})=>{
  const theme = useTheme();
  const { isMobile } =useScreenSizes()

  const styles = useMemo(()=>StyleSheet.create({
    cardContainer: { 
      backgroundColor: theme.colors.basics.grey.c100,
      flexDirection: 'row',
      justifyContent: 'center',
      ...(style && StyleSheet.flatten(style))
    },
    cardInnerContainer: { 
      flex: 1,
      margin: theme.metrics.spacing.xm,
      maxWidth: theme.metrics.contentSizes.centeredContentMaxWidth,
      ...(isMobile && {
        margin: theme.metrics.spacing.s,
      }),
      ...(style && StyleSheet.flatten(style))
    },
    headerContainer: {
      marginBottom: theme.metrics.spacing.s
    },
    headerTitleLine: {
      flexDirection: "row",
      marginBottom: theme.metrics.spacing.s
    },
    cardTitle: { 
      flex: 2,
      textAlign: 'left'
    },
    cardAmount: { 
      flex: 1,
      textAlign: 'right'
    },
    checkBoxContainer: {
      flexDirection: 'row',
      marginBottom: theme.metrics.spacing.none,
    },
    subTitle: {
      marginRight: theme.metrics.spacing.xs,
      color: theme.colors.text.link.c500,
    },
    chevronStyle: {
      alignSelf: 'center',
      paddingLeft: theme.metrics.spacing.xs,
    },
    expandContainerStyle: {
      marginBottom: theme.metrics.spacing.xm,
      ...(isMobile && {
        marginBottom: theme.metrics.spacing.m,
      }),
    },
    expandLinkLineContainer: {
      flexDirection: 'row',
      justifyContent: 'flex-end'
    },
    expandDate: {
      flex: 1
    },
    expandLink: {
    },
    header: {
      flex: 1,
      flexDirection: 'row',
      width: '100%',
    },
    leftHeader: {
      flex: 1,
    },
    rightHeader: {
      flex: 1,
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
    leftContentPart: {
      flexShrink: 1,
      flexGrow: 1,
    },
    taxationDetails: {
      justifyContent: 'flex-end',
    },
    taxationDetailsRightRow: {
      flexDirection: 'row',
      flex: 1,
      justifyContent: 'flex-end',
    },
    taxationDetailsRightValue: {
      lineHeight: theme.metrics.spacing.xm
    },
  }), [theme, style, isMobile])
  return styles

}