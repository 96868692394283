import { View } from 'react-native'
import { RadiosButtonList } from '@ere-uilib/organisms'
import { useStyles } from './useStyles'
import React, { useCallback, useMemo, useState } from 'react'
import { OptionsState } from '@ere-uilib/organisms/inputs/RadiosButtonList/interfaces'
import { CalendarInput } from '../CalendarInput'
import { SimpleButton } from '@ere-uilib/molecules'
import dayjs from 'dayjs'
import { Text } from '@ere-uilib/atoms'
import { useTranslation } from '@ere-uilib/translations'
import { ItemState } from '@ere-uilib/molecules/inputs/Select/types'

interface Props {
  dataSignatureDates: OptionsState[]
  buttonTitle: string
  handleNextPressed: (selectedItem: ItemState) => void
}

export const SignatureDateContainer: React.FC<React.PropsWithChildren<Props>> = ({
  dataSignatureDates,
  buttonTitle,
  handleNextPressed
}) => {
  const styles = useStyles()
  const { formatMessage, formatDate } = useTranslation()

  const defaultSelectedSignatureType =
    dataSignatureDates.length === 1 ? dataSignatureDates?.[0] : undefined

  const [selectedSignatureType, setSelectedSignatureType] = useState<OptionsState | undefined>(
    defaultSelectedSignatureType
  )
  const [selectedSignatureDate, setSelectedSignatureDate] = useState<Date>()
  const isSelectedDateLessThanToday = useMemo(
    () => dayjs(selectedSignatureDate).diff(new Date().setHours(0, 0, 0, 0), 'days') <= 0,
    [selectedSignatureDate]
  )

  const renderNextButtonAction = useCallback(() => {
    const formattedSignatureDate = formatDate({
      value: selectedSignatureDate,
      options: {
        dateStyle: 'short'
      }
    })

    return (
      selectedSignatureType && (
        <SimpleButton
          onPress={() =>
            handleNextPressed({
              label: `${formattedSignatureDate} - ${selectedSignatureType.label}`,
              value: `${formattedSignatureDate}-${selectedSignatureType.value}`
            })
          }
          title={buttonTitle}
          disabled={isSelectedDateLessThanToday}
        />
      )
    )
  }, [buttonTitle, selectedSignatureType, selectedSignatureDate])

  const renderErrorDateText = useCallback(() => {
    return (
      selectedSignatureDate &&
      isSelectedDateLessThanToday && (
        <Text
          variant="t3"
          weight="regular"
          style={styles.errorDateText}>
          {formatMessage({ id: 'UnavailableRepaymentReleaseInvalidSelectionDate' })}
        </Text>
      )
    )
  }, [selectedSignatureDate])

  const handleSelectedSignatureTypeChange = useCallback((value: string) => {
    const selectedSignatureType = dataSignatureDates.find(
      (type: OptionsState) => type?.value === value
    )
    setSelectedSignatureType(selectedSignatureType)
  }, [])

  return (
    <View style={styles.radioGroupContainer}>
      {dataSignatureDates?.length > 1 && (
        <RadiosButtonList
          activeRadio={selectedSignatureType?.value}
          onChange={handleSelectedSignatureTypeChange}
          options={dataSignatureDates}
          labelStyle={styles.labelsRadioStyle}
        />
      )}
      <CalendarInput
        disabled={!selectedSignatureType}
        onDateChange={setSelectedSignatureDate}
        valueDate={selectedSignatureDate}
      />
      {renderErrorDateText()}
      {renderNextButtonAction()}
    </View>
  )
}
