import { useMemo } from 'react';

import { convertFileSize, useTranslation } from '@ere-uilib/index';
import { FilePickerDictionnary } from '@ere-uilib/organisms/inputs/FilePicker/interfaces';

type Label = {
  title: string;
  description: string;
  section: {
    title: string;
    description: string;
  };
  picker: FilePickerDictionnary;
  button: {
    next: string;
  };
};

export const useLabel = (fileMaxSize: number | null) => {
  const { formatMessage, formatNumber } = useTranslation();

  const label = useMemo<Label>(
    () => ({
      title: formatMessage({ id: 'PostalAdressPageSupportingDocumentTitle' }),
      description: formatMessage({
        id: 'PostalAdressPageSupportingDocumentDescriptionES',
        values: { size: convertFileSize(fileMaxSize, 'MB') }
      }),
      section: {
        title: formatMessage({ id: 'PostalAdressPageSupportingDocumentUploadCardTitle' }),
        description: formatMessage({
          id: 'PostalAdressPageSupportingDocumentUploadCardDescription'
        })
      },
      picker: {
        buttonTitle: formatMessage({ id: 'PostalAdressPageSupportingDocumentUploadBouton' }),
        loadingTitle: formatMessage({
          id: 'PostalAdressPageSupportingDocumentUploadCardTitle'
        }),
        errorDescription: formatMessage({
          id: 'PostalAdressPageSupportingDocumentUploadCardErrorMessageES',
          values: { size: formatNumber({ value: convertFileSize(fileMaxSize, 'MB') }) }
        }),
        errorTitle: formatMessage({ id: 'ERR_FOR_02' }),
        // Use labels from Bank Details | TCAAS3091-9019
        popinDescription: formatMessage({
          id: 'CoordonneesBancairesPopinSourceUploadSousTitre',
          values: { size: convertFileSize(fileMaxSize, 'MB') }
        }),
        popinTitle: formatMessage({ id: 'CoordonneesBancairesPopinSourceUploadTitre' }),
        cameraTitle: formatMessage({ id: 'CoordonneesBancairesPopinSourceUploadPhotoTitreBouton' }),
        cameraSubtitle: formatMessage({
          id: 'CoordonneesBancairesPopinSourceUploadPhotoDescriptionBouton'
        }),
        galleryTitle: formatMessage({ id: 'CoordonneesBancairesPopinSourceUploadImageTitreBouton' }),
        gallerySubtitle: formatMessage({
          id: 'CoordonneesBancairesPopinSourceUploadImageDescriptionBouton'
        }),
        explorerTitle: formatMessage({
          id: 'CoordonneesBancairesPopinSourceUploadFichierTitreBouton'
        }),
        explorerSubtitle: formatMessage({
          id: 'CoordonneesBancairesPopinSourceUploadFichierDescriptionBouton'
        })
      },
      button: {
        next: formatMessage({ id: 'PostalAdressPageSupportingDocumentUploadCardSaveButton' })
      }
    }),
    [fileMaxSize, formatMessage, formatNumber]
  );

  return label;
};
