import React from 'react';
import { View } from 'react-native';

import { FontIcon, Title } from '@ere-uilib/atoms';
import { useTheme, createUseStyles } from '@ere-uilib/styles';

import { Props } from './interfaces';
import { getStyles } from './styles';

export const EligibilityTag = ({
  title,
  isAvailable,
}: Props)=> {
  const theme = useTheme();
  const styles = useStyles(
    {
      theme,
      isAvailable,
    },
    {}
  );

  const renderAvailableDispositifs = () => {
    return (
      <View style={styles.container}>
        <FontIcon
          color={theme.colors.notifications.success.c500}
          name={'cocher'}
          size={theme.metrics.iconSizes.xs}
        />
        <Title
          style={styles.title}
          variant="t7">
          {title}
        </Title>
      </View>
    );
  };

  return (
    isAvailable ? renderAvailableDispositifs() : null
  );
};

const useStyles = createUseStyles(getStyles);
