import React, { PropsWithChildren, useContext } from 'react';

import { TabsContext } from './TabsContext';

interface TabProps {
  value: string | number;
}

export const Tab: React.FC<PropsWithChildren<TabProps>> = ({ value, children }) => {
  const { activeTab } = useContext(TabsContext);

  if (value === activeTab) return <>{children}</>;

  return <></>;
};
