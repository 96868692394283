import { RouteNames } from "@constants/navigation";
import { OperationModalState } from "@modules/settings/types";
import { RootNavigation } from "@navigation/RootNavigation";
import { useCallback, useMemo } from "react";

export const useController = ({ operationModalList, operationType }: {
  operationModalList?: OperationModalState
  operationType: string
}) => {

  const isValidEsButton = useMemo(() => (
    operationModalList &&
    !!operationModalList.esButtonLabel &&
    !!operationModalList.esUrl &&
    typeof operationModalList.esUrl === 'string'
  ), [operationModalList]);

  const isValidRcButton = useMemo(() => (
    operationModalList &&
    !!operationModalList.rcButtonLabel &&
    !!operationModalList.rcUrl &&
    typeof operationModalList.rcUrl === 'string'
  ), [operationModalList]);

  const operationProvider = useMemo(() => {
    if (isValidEsButton) return "es";
    if (isValidRcButton) return "rc";

  }, [isValidEsButton, isValidRcButton]);

  const isModalValidContent = useMemo(() =>
    operationType !== undefined &&
    !!operationModalList?.title &&
    (isValidRcButton || isValidEsButton), [
    operationType,
    operationModalList,
    isValidRcButton,
    isValidEsButton
  ]);


  const validationButtonData = useMemo(() => {
    if (!operationModalList || !operationProvider) return;
    const title = operationModalList?.title;
    const testId = `${operationType}_redirect`;

    if (isValidRcButton) {
      return {
        title,
        testId,
        validationCondition: isValidRcButton,
        buttonTitle: operationModalList.rcButtonLabel,
        provider: operationProvider
      };
    }

    if (isValidEsButton) {
      return {
        title,
        testId,
        validationCondition: isValidEsButton,
        buttonTitle: operationModalList?.esButtonLabel,
        provider: operationProvider
      };
    }
  }, [
    operationModalList,
    isValidEsButton,
    isValidRcButton,
    operationProvider
  ])
  const onHandleButtonPress = useCallback(() => {
    if (!operationProvider) return;

    RootNavigation.replace(RouteNames.RedirectOperationPage, {
      provider: operationProvider,
      operationType,
    });
  }, [
    operationProvider,
    operationType
  ]);

  return {
    isModalValidContent,
    validationButtonData,
    onHandleButtonPress
  }
}