import { connect } from 'react-redux';

import { AppState } from '@modules/index';
import { getInvitationSelector } from '@modules/invitation/selectors';
import { getInvitationRequest } from '@modules/invitation/actions/invitationActions';

import { WelcomePageComponent } from './WelcomePage.component';

const mapStateToProps = (state: AppState) => {
  return {
    invitation: getInvitationSelector(state)
  };
};

const mapDispatchToProps = ({
  onFetchInvitation: getInvitationRequest
});
export const WelcomePage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(WelcomePageComponent);
