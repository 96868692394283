import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useScreenSizes, useTheme } from '@ere-uilib/styles';

const COL_LEFT_FLEX = 3;
const COL_MIDDLE_FLEX = 1.5;
const COL_RIGHT_FLEX = 2;

export const useStyles = () => {
  const theme = useTheme();
  const { isDesktop } = useScreenSizes();

  const HEAD_BG = theme.colors.background.tableCellHeader.c500;
  const LEFT_COL_BG = theme.colors.background.default.c500;

  const styles = useMemo(
    () =>
      StyleSheet.create({
        tableHeadLeft: {
          flex: COL_LEFT_FLEX,
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          borderTopLeftRadius: theme.metrics.borderRadius.xs,
          backgroundColor: HEAD_BG
        },
        tableHeadMiddle: {
          flex: COL_MIDDLE_FLEX,
          justifyContent: 'flex-start',
          backgroundColor: HEAD_BG,
          borderLeftColor: theme.colors.basics.white
        },
        tableHeadRight: {
          flex: COL_RIGHT_FLEX,
          justifyContent: 'flex-start',
          borderTopRightRadius: theme.metrics.borderRadius.xs,
          borderLeftColor: theme.colors.basics.white,
          backgroundColor: HEAD_BG
        },
        tableMiddleRowLeftColumn: {
          flex: COL_LEFT_FLEX,
          backgroundColor: LEFT_COL_BG
        },
        tableMiddleRowMiddleColumn: {
          flex: COL_MIDDLE_FLEX
        },
        tableMiddleRowRightColumn: {
          flex: COL_RIGHT_FLEX
        },
        tableLastRowLeftColumn: {
          flex: COL_LEFT_FLEX,
          backgroundColor: LEFT_COL_BG,
          borderWidth: 0,
          borderLeftWidth: 1,
          borderBottomWidth: 1,
          borderBottomLeftRadius: theme.metrics.borderRadius.xs
        },
        tableLastRowMiddleColumn: {
          flex: COL_MIDDLE_FLEX,
          borderWidth: 0,
          borderLeftWidth: 1,
          borderBottomWidth: 1
        },
        tableLastRowRightColumn: {
          flex: COL_RIGHT_FLEX,
          borderWidth: 0,
          borderLeftWidth: 1,
          borderRightWidth: 1,
          borderBottomWidth: 1,
          borderBottomRightRadius: theme.metrics.borderRadius.xs
        },
        selectContainer: {
          marginLeft: theme.metrics.spacing.xs,
          minWidth: 30
        },
        selectItemsContainerStyle: {
          ...(isDesktop && { top: theme.metrics.spacing.xm }),
          width: (isDesktop ?  60 : "100%" ),
          textAlign: 'center',
        },
        pickerText: {
          fontSize: theme.fonts.fontSize.title.t8s
        },
        pickerTextContainer: {
          paddingHorizontal: theme.metrics.spacing.xs
        },
        selectTitle: {
          fontFamily: theme.fonts.fontFamily.regular,
          fontSize: theme.fonts.fontSize.title.t8
        },
        selectedItem: {
          height: theme.metrics.spacing.xxm,
          paddingHorizontal: theme.metrics.spacing.xs,
          paddingVertical: 0
        },
        selectAnnuityContainer: {
          marginTop: theme.metrics.spacing.s,
          flexDirection: 'row',
          alignItems: 'center'
        },
        selectRateContainer: {
          marginTop: theme.metrics.spacing.s,
          flexDirection: 'row',
          alignItems: 'center'
        }
      }),
    [HEAD_BG, LEFT_COL_BG, theme]
  );

  return styles;
};
