import CryptoJS from 'react-native-crypto-js'

export const createAESKey = (passPhrase: string) => {
  const salt = CryptoJS.lib.WordArray.random(128 / 8)

  return CryptoJS.PBKDF2(passPhrase, salt, { keySize: 512 / 32 }).toString()
}

export const encryptData = (data: any, key512Bits: string | undefined) => {
  if (data && key512Bits) {
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), key512Bits).toString()
    return encryptedData
  } else {
    return undefined
  }
}

export const decryptData = (encryptedData: any, key512Bits: string | undefined) => {
  let decryptedData
  try {
    if (encryptedData && key512Bits) {
      decryptedData = CryptoJS.AES.decrypt(encryptedData, key512Bits)?.toString(CryptoJS.enc.Utf8)
      return JSON.parse(decryptedData)
    } else {
      return undefined
    }
  } catch {
    return decryptedData
  }
}
