import {
  getContactPreferencesSuccessOptinGroupEmail,
  getContactPreferencesSuccessOptinPartnerEmail,
  getIsUpdateContactPreferenceOptinSuccess, getProfileError
} from '@modules/profile/selectors';
import {ProfileActionsType} from '@modules/profile/actions/profileActionsTypes';
import {AppState} from '@modules/reducers';
import {
  getContactPreferencesOptinRequest,
  setContactPreferencesOptinRequest,
  setContactPreferencesOptinReset
} from '@modules/profile/actions/profileActions';
import {connect} from 'react-redux';
import {BusinessContactPreferencesConnectedPage as BusinessContactPreferencesPage} from './BusinessContactPreferencesConnectedPage.component';



const mapStateToProps = (state: AppState) => {
  return {
    isSuccess: getIsUpdateContactPreferenceOptinSuccess(state),
    acceptGroupEmail: getContactPreferencesSuccessOptinGroupEmail(state),
    acceptPartnerEmail: getContactPreferencesSuccessOptinPartnerEmail(state),
    isLoading: state.isLoading[ProfileActionsType.SET_CONTACT_PREFERENCES_OPTIN_REQUEST],
    error: getProfileError(state),
  };
};
  
const mapDispatchToProps = ({
  onSubmit: setContactPreferencesOptinRequest,
  onPressButtonSuccessModal: setContactPreferencesOptinReset,
  getContactPreferencesRequest: getContactPreferencesOptinRequest,
});
  
export const BusinessContactPreferencesConnectedPage = connect(mapStateToProps, mapDispatchToProps)(BusinessContactPreferencesPage);