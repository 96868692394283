import { useMemo } from "react"
import { StyleSheet } from "react-native"

import { useTheme } from "@ere-uilib/styles";

export const useStyles = () => {
  const theme = useTheme();

  const styles = useMemo(() => StyleSheet.create({
    container: {
      marginTop: theme.metrics.spacing.s
    },
    title: {
      marginBottom: theme.metrics.spacing.xs
    },
    documentTypesListContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap'
    },
    documentTypeItem: {
      marginRight: theme.metrics.spacing.s
    }
  }), [theme]);

  return styles;
}
