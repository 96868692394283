import { StyleSheet } from 'react-native';

import { ThemeType } from '@ere-uilib/styles';
interface GetStylesProps {
    theme: ThemeType
}
const getStyles = ({ theme }: GetStylesProps) => {
  return StyleSheet.create({
    container: {
      // margin bottom is equal to minus border width to avoir double border in lists.
      marginBottom: -1
    },
    content: {
      flexDirection: 'row',
      alignItems: 'center',
      paddingVertical: theme.metrics.spacing.m,
      paddingHorizontal: theme.metrics.spacing.s,
      borderColor: theme.colors.basics.grey.c100,
      borderBottomWidth: 1 
    },
    titleContainer: {
      flex: 1
    }
  });
};
export default getStyles;
