import { getDocumentsCategoriesFilter } from '@modules/document-space/selectors';
import { DocumentsActionsType } from '@modules/document-space/actions/documentsActionsTypes';
import {
  getDocumentsCategoriesRequest,
  setAllDocumentsCategoriesFilter,
  setDocumentsCategoriesFilter,
  setDocumentsCategoriesReset,
} from '@modules/document-space/actions/documentsActions';
import { AppState } from '@modules/reducers';
import { connect } from 'react-redux';
import { DocumentFilterModal as DocumentFilterModalComponent } from './DocumentFilterModal.component';



const mapStateToProps = (state: AppState) => ({
  categoriesFilter: getDocumentsCategoriesFilter(state),
  isLoadingCategories: state.isLoading[DocumentsActionsType.GET_DOCUMENTS_CATEGORIES_REQUEST],
  categoriesError: state.error[DocumentsActionsType.GET_DOCUMENTS_CATEGORIES_REQUEST]
});

const mapDispatchToProps = ({
  onGetDocumentsCategories: getDocumentsCategoriesRequest,
  onSetDocumentsCategoriesFilter: setDocumentsCategoriesFilter,
  onSetDocumentsCategoriesReset: setDocumentsCategoriesReset,
  onSetAllDocumentsCategoriesFilter: setAllDocumentsCategoriesFilter,
});

export const DocumentFilterModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentFilterModalComponent);
