import { ResponseType } from '@modules/common/types'
import {
  UnavailableRefundErrorState,
  RefundUnavailabilityDecisionTreeResponseType,
  UnavailableRefundDispositifState,
  RefundUnavailabilityDecisionTreeType
} from '@modules/refund/unavailable-refund/types'

export enum UnavailableRefundActionsType {
  GET_REFUND_UNAVAILABILITY_DECISION_TREE_REQUEST = 'GET_REFUND_UNAVAILABILITY_DECISION_TREE_REQUEST',
  GET_REFUND_UNAVAILABILITY_DECISION_TREE_SUCCESS = 'GET_REFUND_UNAVAILABILITY_DECISION_TREE_SUCCESS',
  GET_REFUND_UNAVAILABILITY_DECISION_TREE_FAILURE = 'GET_REFUND_UNAVAILABILITY_DECISION_TREE_FAILURE',
  // Unavailable refund
  GET_UNAVAILABLE_REFUND_DISPOSITIFS_REQUEST = 'GET_UNAVAILABLE_REFUND_DISPOSITIFS_REQUEST',
  GET_UNAVAILABLE_REFUND_DISPOSITIFS_SUCCESSS = 'GET_UNAVAILABLE_REFUND_DISPOSITIFS_SUCCESSS',
  GET_UNAVAILABLE_REFUND_DISPOSITIFS_FAILURE = 'GET_UNAVAILABLE_REFUND_DISPOSITIFS_FAILURE',

  // Unavailable Refund Signature Date
  GET_UNAVAILABLE_REFUND_SIGNATURE_DATE_REQUEST = 'GET_UNAVAILABLE_REFUND_SIGNATURE_DATE_REQUEST',
  GET_UNAVAILABLE_REFUND_SIGNATURE_DATE_SUCCESS = 'GET_UNAVAILABLE_REFUND_SIGNATURE_DATE_SUCCESS',
  GET_UNAVAILABLE_REFUND_SIGNATURE_DATE_FAILURE = 'GET_UNAVAILABLE_REFUND_SIGNATURE_DATE_FAILURE',

  // Main Action to handle update questions
  UPDATE_UNAVAILABLE_REFUND_QUESTIONS_REQUEST = 'UPDATE_UNAVAILABLE_REFUND_QUESTIONS_REQUEST',
  UPDATE_UNAVAILABLE_REFUND_QUESTIONS_SUCCESS = 'UPDATE_UNAVAILABLE_REFUND_QUESTIONS_SUCCESS',
  UPDATE_UNAVAILABLE_REFUND_QUESTIONS_FAILURE = 'UPDATE_UNAVAILABLE_REFUND_QUESTIONS_FAILURE'
}

export interface UpdateRefundUnavailableQuestionsRequestAction {
  type: UnavailableRefundActionsType.UPDATE_UNAVAILABLE_REFUND_QUESTIONS_REQUEST
  response: ResponseType
  index: number
  canNotBeAsked: boolean
  data?: RefundUnavailabilityDecisionTreeType
}
export interface UpdateRefundUnavailableQuestionsSuccessAction {
  type: UnavailableRefundActionsType.UPDATE_UNAVAILABLE_REFUND_QUESTIONS_SUCCESS
  unavailableRefundQuestions: RefundUnavailabilityDecisionTreeType
}

export interface UpdateRefundUnavailableQuestionsFailureAction {
  type: UnavailableRefundActionsType.UPDATE_UNAVAILABLE_REFUND_QUESTIONS_FAILURE
  error: UnavailableRefundErrorState
}

export interface GetRefundUnavailabilityDecisionTreeRequestAction {
  type: UnavailableRefundActionsType.GET_REFUND_UNAVAILABILITY_DECISION_TREE_REQUEST
}

export interface GetRefundUnavailabilityDecisionTreeSuccessAction {
  type: UnavailableRefundActionsType.GET_REFUND_UNAVAILABILITY_DECISION_TREE_SUCCESS
  unavailabilityDecisionTreeResponse: RefundUnavailabilityDecisionTreeResponseType
}

export interface GetRefundUnavailabilityDecisionTreeFailureAction {
  type: UnavailableRefundActionsType.GET_REFUND_UNAVAILABILITY_DECISION_TREE_FAILURE
  error: UnavailableRefundErrorState
}

export interface GetRefundUnavailabilitySignatureDateRequestAction {
  type: UnavailableRefundActionsType.GET_UNAVAILABLE_REFUND_SIGNATURE_DATE_REQUEST
}

export interface GetRefundUnavailabilitySignatureDateSuccessAction {
  type: UnavailableRefundActionsType.GET_UNAVAILABLE_REFUND_SIGNATURE_DATE_SUCCESS
  unavailabilitySignatureDateResponse: RefundUnavailabilityDecisionTreeType
}

export interface GetRefundUnavailabilitySignatureDateFailureAction {
  type: UnavailableRefundActionsType.GET_UNAVAILABLE_REFUND_SIGNATURE_DATE_FAILURE
  error: UnavailableRefundErrorState
}

export interface GetUnavailableRefundDispositifsRequestAction {
  type: UnavailableRefundActionsType.GET_UNAVAILABLE_REFUND_DISPOSITIFS_REQUEST
}

export interface GetUnavailableRefundDispositifsSuccessAction {
  type: UnavailableRefundActionsType.GET_UNAVAILABLE_REFUND_DISPOSITIFS_SUCCESSS
  unavailableRefundDispositifs: UnavailableRefundDispositifState
  motifId: string
}

export interface GetUnavailableRefundDispositifsFailureAction {
  type: UnavailableRefundActionsType.GET_UNAVAILABLE_REFUND_DISPOSITIFS_FAILURE
  error: UnavailableRefundErrorState
}

export type UnavailableRefundAction =
  | GetRefundUnavailabilityDecisionTreeSuccessAction
  | GetRefundUnavailabilityDecisionTreeFailureAction
  | GetUnavailableRefundDispositifsRequestAction
  | GetUnavailableRefundDispositifsSuccessAction
  | GetUnavailableRefundDispositifsFailureAction
  | GetRefundUnavailabilitySignatureDateRequestAction
  | GetRefundUnavailabilitySignatureDateSuccessAction
  | GetRefundUnavailabilitySignatureDateFailureAction
