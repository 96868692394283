import { Text, Pressable } from '@ere-uilib/atoms';
import { createUseStyles, useTheme } from '@ere-uilib/styles';
import React, { Fragment, useState } from 'react';
import { View } from 'react-native';
import {
  ActivableButtonSetProps,
  EnumSizeTypes
} from './interfaces';
import { getStyles } from './styles';

export const ActivableButtonSet: React.FC<React.PropsWithChildren<ActivableButtonSetProps>> = ({
  label,
  children,
  testID,
  disabled = false,
  isSelected = false,
  isClassicStyle = false,
  size = EnumSizeTypes.large,
  onChange,
  buttonStyle,
  hovered,
  setHovered
}: ActivableButtonSetProps) => {

  const theme: any = useTheme();

  const styles = useStyles(
    {
      theme,
      disabled,
      size,
      hovered,
      isSelected,
      isClassicStyle,
    }, {
    buttonStyle,
  });

  return (
    <Pressable
      onHoverIn={() => setHovered(true)}
      onHoverOut={() => setHovered(false)}
      disabled={disabled}
      onPress={onChange}>
      <View
        style={styles.container}
        testID={testID}
      >
        <View style={styles.buttonStyle}>
          {children || (
            <Text
              style={styles.labelStyle}
              variant="t3">
              {label}
            </Text>
          )}
        </View>
      </View>
    </Pressable>
  );
};

const useStyles = createUseStyles(getStyles);
