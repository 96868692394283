import { StylesContext, FundSheetModalStyles } from './interfaces';

export const getStyles = (
  context?: StylesContext,
  style?: FundSheetModalStyles,
): FundSheetModalStyles => ({
  containerStyle: [
    {
      flex: 1
    },
    style?.containerStyle
  ],
  innerContainerPadding: [
    {
      flex: 1,
      paddingVertical: 0,
      paddingHorizontal: 0
    },
    style?.innerContainerPadding
  ]
});
