import React from 'react';
import { View } from 'react-native';

import {
  RetryActionPlaceHolder,
  useTranslation
} from '@components/ERE-UILib';
import { NewsFeedTypeEnum } from '@ere-uilib/enums';
import {
  NewsFeedTypeState
} from '@modules/history/types';

import { HistoryStyle } from '../interface';
import { HistoryHeader } from './HistoryHeader';
import HistoryPlaceHolder from './HistoryPlaceHolder';
import { HistoryRenderItems } from './HistoryRenderItems';

interface HistorymainContentProps {
  tabItems: string[];
  selectedItem: string;
  styles: HistoryStyle;
  pageTitle: string;
  isLoading: boolean;
  error: string
  onRetryActionPress: () => void
  feedNewsData: [string, NewsFeedTypeState[]][]
  onChangeSelectedItem: (item: string) => void
  type: NewsFeedTypeEnum
}

const HistorymainContent: React.FC<React.PropsWithChildren<HistorymainContentProps>> = ({
  tabItems,
  selectedItem,
  styles,
  pageTitle,
  isLoading,
  error,
  onRetryActionPress,
  feedNewsData,
  onChangeSelectedItem,
  type,
}) => {
  const { formatMessage } = useTranslation();
  const filtredItemsByType = () => {
    const result: [string, NewsFeedTypeState[]][] = [];
    feedNewsData.map(item => {
      const tmp = item[1].filter(el => el.type === type);
      if (tmp.length > 0) {
        result.push([item[0], tmp]);
      }
    });
    return result;
  };
  const itemList = type === NewsFeedTypeEnum.ALL ? feedNewsData : filtredItemsByType();

  const renderLoader = () => (
    <HistoryPlaceHolder styles={styles} />
  );
  const renderRetryActionPlaceHolder = () => (
    <View>
      <RetryActionPlaceHolder
        containerStyle={styles.RetryActionPlaceHolderContainerStyle}
        retryAction={onRetryActionPress}
        title={formatMessage({ id: 'Dashboard_Cards_Retry_label' })}
      />
    </View>

  );
  const renderContent = () => {
    if (isLoading) { return renderLoader(); }
    if (error && error !== '') { return renderRetryActionPlaceHolder(); }
    return (<HistoryRenderItems
      itemsList={itemList}
    />
    );
  };
  return (
    <View style={styles.container}>
      <View style={styles.fullWidthContainer}>
        <HistoryHeader
          onChangeSelectedItem={item => {
            onChangeSelectedItem(item);
          }}
          selectedItem={selectedItem}
          tabItems={tabItems}
          tabsContainerStyle={styles.tabsContainer}
          title={pageTitle}
          titleStyle={styles.title}
        />
        {renderContent()}
      </View>
    </View>
  );
};

export default HistorymainContent;
