export const InstallmentOperationName = "VERSEMENT"
export enum PaymentModeList {
  cb = 'CB',
  debit = 'DEBIT',
}
export enum InstallmentDecisionPropertiesEnum {
  RETIREMENT_ONLY = 'RetirementOnly',
  TAX_OUT = 'TaxOut',
  TAX_IN = 'TaxIn',
  ABONDEMENT = 'Abondement',
  VVL = 'VVL',
  VVP = 'VVP',
  HIDE_INFORMATION_VV_INF_25 = 'HideInformationVvInf25',
  COMPANY_CONTRIBUTION = 'CompanyContribution',
  SUPPORT_FILTER = 'SupportFilter',
  FLOW_REALLOCATION = 'FlowReallocation',
  PARTIAL_REALLOCATION = 'PartialReallocation',
  STOCK_REALLOCATION = 'StockReallocation',
  THRESHOLD_TRIGGER = 'ThresholdTrigger',
  INVESTED = 'Invested',
  CONTRIBUTION = 'Contribution',
  OAD_REALLOCATION = 'OadReallocation',
  IMMEDIATE_ASSET = 'ImmediateAsset',
  IMMEDIATE_PENSION = 'ImmediatePension'
}

export enum InstallmentDecisionVVPFrequencyEnum {
  FREQUENCY_1M = '1M',
  FREQUENCY_3M = '3M',
  FREQUENCY_6M = '6M',
}
export enum ApiPaymentPeriodicityEnum {
  '1M' = 'MENSUELLE',
  '3M' = 'TRIMESTRIELLE',
  '6M' = 'SEMESTRIELLE'
}

export enum InstallmentPaymentFrequencyEnum {
  VVL = 'VVL',
  VVP = 'VVP',
}

export enum InstallmentPaymentStatusList {
  OK = 'OK',
  KO = 'KO',
  IN_PROGRESS = 'IN_PROGRESS',
}

export enum RepaymentStatusEnum {
  RepaymentUnavailable = 'RepaymentUnavailable',
  RepaymentAvailable = 'RepaymentAvailable'
}
