import { RoundedContainer, SVGLocalLoader, Text } from '@ere-uilib/atoms';
import { createUseStyles, ThemeType, useTheme } from '@ere-uilib/styles';
import React from 'react';
import { StyleProp, TextStyle, View, ViewStyle } from 'react-native';


interface Props {
  condition: { label: string; state?: boolean };

  passwordValidationContainerStyle?: StyleProp<ViewStyle>;
  validationTextStyle?: StyleProp<TextStyle>;
  checkIconStyle?: StyleProp<ViewStyle>;
}

type FormValidationConditionStyles = {
  passwordValidationContainerStyle?: StyleProp<ViewStyle>;
  validationTextStyle?: StyleProp<TextStyle>;
  checkIconStyle?: StyleProp<ViewStyle>;
  roundedIconStyle?: StyleProp<ViewStyle>;
};

type StylesContext = { theme: ThemeType };

export const FormValidationCondition: React.FC<React.PropsWithChildren<Props>> = ({
  condition,
  validationTextStyle,
  passwordValidationContainerStyle,
}) => {
  const theme = useTheme();
  const styles = useStyles(
    { theme },
    { passwordValidationContainerStyle, validationTextStyle },
  );
  return (
    <View style={styles.passwordValidationContainerStyle}>
      <View style={styles.checkIconStyle}>
        {condition.state ? (
          <RoundedContainer containerStyle={styles.roundedIconStyle} >
            <SVGLocalLoader name={'check'} />
          </RoundedContainer>
        ) : (
          <SVGLocalLoader name="init" />
        )}
      </View>
      <View>
        <Text style={styles.validationTextStyle}>{condition.label}</Text>
      </View>
    </View>
  );
};

const getStyles = (
  context?: StylesContext,
  style?: FormValidationConditionStyles,
): FormValidationConditionStyles => ({
  passwordValidationContainerStyle: [
    {
      flexDirection: 'row',
      width: '100%',
      marginBottom: context?.theme.metrics.spacing.s,
    },
    style?.passwordValidationContainerStyle,
  ],
  validationTextStyle: [
    {
      marginLeft: context?.theme.metrics.spacing.s,
      color: context?.theme.colors.text.secondary.c500,
      fontSize: context?.theme.fonts.fontSize.paragraph.t3,
    },
    style?.validationTextStyle,
  ],
  checkIconStyle: [
    {
      width: 20,
      height: 20,
    },
    style?.checkIconStyle,
  ],
  roundedIconStyle: [
    {
      width: 20,
      height: 20,
    },
    style?.roundedIconStyle,
  ]
});

const useStyles = createUseStyles(getStyles);
