import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import {
  useTheme,
} from '@components/index';

export const useStyles = () => {
  const theme = useTheme()
  const styles = useMemo(() => StyleSheet.create({
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    renderContentContainerStyle: {
      marginTop: theme?.metrics.spacing.s
    },
    titleStyles:
    {
      textAlign: 'center',
      marginVertical: theme.metrics.spacing.s,
    },
    mailDataContainer: {
      marginVertical: theme?.metrics.spacing.s
    }
  }), [theme])
  return styles
};
