declare global {
  interface Window { ReactNativeWebView: {
    postMessage:(data:string)=>void
  }; }
}

export const sendMessageFromWebView = (message:string)=>{
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(message);
  } else if (window.parent) {
    window.parent.postMessage(message, '*');
  } else {
    window.postMessage(message, '*');
  }
};
