import React from 'react';

import { TextField } from '@ere-uilib/molecules';

import { useStyles } from './useStyles';

interface FormFieldProps<T> {
  handleChange: any;
  handleBlur: any;
  setFieldValue: any;
  values: T;
  propertyName: keyof T;
  testId: string;
  placeholder: string;
  errorMessage?: string;
  hasError?: boolean;
}

export const FormField = <T,>({
  handleChange,
  values,
  setFieldValue,
  handleBlur,
  propertyName,
  testId,
  placeholder,
  hasError = false,
  errorMessage
}: FormFieldProps<T>) => {
  const styles = useStyles();

  return (
    <TextField
      containerStyle={styles.container}
      errored={hasError}
      errorMessage={errorMessage}
      inputProps={{
        onChangeText: handleChange(propertyName),
        value: `${values[propertyName]}`,
        placeholder
      }}
      labelOnTop={true}
      onBlur={handleBlur(propertyName)}
      onCleanField={() => setFieldValue(propertyName, '')}
      rightIconsStyle={styles.rightIconStyle}
      testId={testId}
    />
  );
};
