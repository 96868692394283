import { Linking, Platform } from 'react-native';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { getUsedCompanyId } from '@modules/dashboard/selectors';
import { runManager } from '@modules/moduleManager';
import * as OperationActions from '@modules/redirect-operation/actions/redirectOperationActions';
import {
  GetOperationRedirectRequestAction,
  GetOperationRedirectUrlRequestAction,
  GetSapiendoRedirectUrlRequestAction,
  RedirectOperationActionsType
} from '@modules/redirect-operation/actions/redirectOperationActionsTypes';
import { RootNavigation } from '@navigation/RootNavigation';

import { authenticatedCall } from '../apiAuthorization';
import {
  fetchIntialOperationRedirectUrl,
  fetchOperationRedirectUrl,
  fetchSapiendoRedirectUrl
} from './services';

function* getInitOperationRedirect(
  action: GetOperationRedirectRequestAction
): any {
  yield put(OperationActions.operationModalInitError());
  const companyId = yield select(getUsedCompanyId);
  const response = yield authenticatedCall(
    fetchIntialOperationRedirectUrl,
    companyId,
    action.operationType
  );
  yield put(OperationActions.getRedirectOperationSuccess(response.data));
}
function* getOperationRedirectUrl(
  action: GetOperationRedirectUrlRequestAction
): any {
  yield put(OperationActions.operationModalInitError());

  const companyId = yield select(getUsedCompanyId)
  const response = yield authenticatedCall(fetchOperationRedirectUrl, companyId, action.operationType, action.provider);
  yield put(OperationActions.getRedirectOperationUrlSuccess());
  if (Platform.OS === 'web') {
    yield window.location.replace(response.data);
  } else {
    Linking.canOpenURL(response.data).then(() => {
      setTimeout(() => RootNavigation.goBack(), 1000);
      return Linking.openURL(response.data);
    });
  }
}

function* getOperationRedirectSagas() {
  yield takeLatest(
    RedirectOperationActionsType.GET_OPERATION_REDIRECT_REQUEST,
    runManager(
      getInitOperationRedirect,
      RedirectOperationActionsType.GET_OPERATION_REDIRECT_FAILURE
    )
  );
}
function* getOperationRedirectUrlSagas() {
  yield takeLatest(
    RedirectOperationActionsType.GET_OPERATION_REDIRECT_URL_REQUEST,
    runManager(
      getOperationRedirectUrl,
      RedirectOperationActionsType.GET_OPERATION_REDIRECT_URL_FAILURE
    )
  );
}

function* getSapiendoRedirectUrl(action: GetSapiendoRedirectUrlRequestAction): any {
  yield put(OperationActions.operationModalInitError());
  yield put(OperationActions.sapiendoRedirectUrlInitError());

  const companyId = yield select(getUsedCompanyId);
  const response = yield call(fetchSapiendoRedirectUrl, action.simulatorType, companyId);
  const url = response?.data?.url

  yield put(OperationActions.getSapiendoRedirectUrlSuccess());

  if (Platform.OS === 'web') {
    yield window.location.replace(url);
  } else {
    Linking.canOpenURL(url).then(() => {
      setTimeout(() => RootNavigation.goBack(), 1000);
      return Linking.openURL(url);
    });
  }
}

function* getSapiendoRedirectUrlSagas() {
  yield takeLatest(
    RedirectOperationActionsType.GET_SAPIENDO_REDIRECT_URL_REQUEST,
    runManager(
      getSapiendoRedirectUrl,
      RedirectOperationActionsType.GET_SAPIENDO_REDIRECT_URL_FAILURE
    )
  );
}

export function* redirectOperationSagas() {
  yield all([fork(getOperationRedirectUrlSagas)]);
  yield all([fork(getOperationRedirectSagas)]);
  yield all([fork(getSapiendoRedirectUrlSagas)]);
}
