import { Action } from 'redux';

export interface IsLoadingState {
  [key: string]: boolean;
}

const getLoadingMatches = (actionType: string) => {
    return /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(actionType);
};

const isLoadingReducer = (state: IsLoadingState = {}, action: Action) => {
    const matches = typeof action?.type === 'string' ?
        getLoadingMatches(action.type)
        : undefined;
    if (!matches) {
        return state;
    }
    const [requestName, pureRequestName, requestStatus] = matches;
    const requestFullName = pureRequestName + '_REQUEST';

    if((requestStatus === "SUCCESS" || requestStatus === "FAILURE") && state.hasOwnProperty(requestFullName) ) {
        delete state[requestFullName];
        return {
            ...state
        };
    }
    return {
        ...state,
        [requestFullName]: requestStatus === 'REQUEST'
    };
};

export default isLoadingReducer;
