import React from 'react';
import { View } from 'react-native';

import { Title, Text } from '@ere-uilib/atoms';

import { useStyles } from './useStyles';

interface Props {
  title: string
  value: string
  subtitle: string
  testId?: string;
  hovered?: boolean;
  isSelected?: boolean
}


export const GenericCardWidthThreeParams: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  subtitle,
  value,
  testId,
  hovered,
  isSelected
} ) => {
  const styles = useStyles();
  const textStyle = (hovered || isSelected) ? styles.contentTextHovered : styles.contentText

    return (
    <View>
      <View style={styles.contentStyle}>
        <Title
          numberOfLines={3}
          style={[styles.title, textStyle]}
          variant="t7"
          testId={`${title}`}
        >
          {title}
        </Title>

        <Text
          testId={testId}
          style={textStyle}
          variant="t2"
        >
          {value}
        </Text>
      </View>

      <Text
        testId={`${title}:detail`}
        style={[styles.subtitleStyle, textStyle]}
        variant="t4"
        weight="light"
      >
        {subtitle}
      </Text>
    </View>
  );
};
