import {
  StyleProp,
  ViewStyle,
} from 'react-native';

import { ExpendableLineStylesContext, ExpendableLineStyles } from './interfaces';

export const getStyles = (
  context?: ExpendableLineStylesContext,
  style?: ExpendableLineStyles,
): ExpendableLineStyles => {
  let containerBackgroundAndBorderStyle: StyleProp<ViewStyle>;
  let iconColor:string|undefined = context?.theme.colors.basics.primary.c500;
  let arrowColor:string|undefined =
  context?.design === 'green' ?
    context?.theme.colors.basics.white :
    context?.theme.colors.basics.grey.c900;

  let textColor : string | undefined = context?.theme.colors.basics.grey.c900;
  let childrenContainerBorderColor: string | undefined = context?.theme.colors.basics.grey.c200;

  const hoveredOrOpenedManager = () => {
    if (context?.isHovered && !context?.disabled) {
      textColor =  context?.theme.colors.basics.primary.c700;
      arrowColor = context?.theme.colors.basics.primary.c700;
      iconColor = context?.theme.colors.basics.primary.c700;
    }
    if (context?.isOpened) {
      textColor = context?.theme.colors.basics.primary.c500;
      arrowColor = context?.theme.colors.basics.primary.c500;
      iconColor = context?.theme.colors.basics.primary.c500;
    }
  };

  switch (context?.design) {
  case 'grey':
    containerBackgroundAndBorderStyle = {
      backgroundColor: context?.theme.colors.basics.grey.c200,
    };
    hoveredOrOpenedManager();
    childrenContainerBorderColor = context.theme.colors.basics.grey.c400;
    break;
  case 'white':
    containerBackgroundAndBorderStyle = {
      backgroundColor: context?.theme.colors.basics.white,
    };
    hoveredOrOpenedManager();
    childrenContainerBorderColor = context.theme.colors.basics.grey.c200;
    break;
  case 'lightGrey':
    containerBackgroundAndBorderStyle = {
      backgroundColor: context?.theme.colors.background.default.c500,
    };
    hoveredOrOpenedManager();
    childrenContainerBorderColor = context.theme.colors.basics.grey.c400;
    break;
  case 'green':
    containerBackgroundAndBorderStyle = {
      backgroundColor:
      context?.isHovered  && !context?.disabled ?
        context?.theme.colors.basics.primary.c700
        : context?.theme.colors.basics.primary.c500,
    };
    textColor =  context?.theme.colors.basics.white;
    iconColor = context?.theme.colors.basics.white;
    childrenContainerBorderColor = context?.isOpened ?
      context.theme.colors.basics.grey.c200
      : 'transparent';
    break;
  default:
    break;
  }

  return {
    containerStyle: [
      context?.isHeaderDoubleBorder && {
        marginBottom: -1,
        borderBottomWidth: 1,
        borderTopWidth: 1,
        borderColor: context?.theme.colors.basics.grey.c400,
      },
      style?.containerStyle],
    headerStyle: [{
      flexDirection: 'row',
      alignItems: 'center',
      paddingVertical: context?.theme.metrics.spacing.m,
      paddingHorizontal: context?.theme.metrics.spacing.s,
    },
    containerBackgroundAndBorderStyle,
    context?.isHeaderDoubleBorder && {
      marginBottom: -1,
      borderBottomWidth: 1,
      borderColor: context?.theme.colors.basics.grey.c400,
    },
    style?.headerStyle],
    iconStyle: [{
      marginRight: context?.iconPosition === 'left' ?
        context?.theme.metrics.spacing.s : 0,
      marginLeft: context?.iconPosition === 'right' ?
        context?.theme.metrics.spacing.s : 0,
      color: iconColor,
    }, style?.iconStyle],
    arrowStyle: [{
      marginRight: context?.arrowPosition === 'left' ?
        context?.theme.metrics.spacing.s : 0,
      marginLeft: context?.arrowPosition === 'right' ?
        context?.theme.metrics.spacing.s : 0,
      color: arrowColor,
    }, style?.iconStyle],
    titleStyle: [
      {
        flexGrow: 1,
        flexShrink: 1,
        flexWrap: 'wrap',
        color: textColor,
      },
      style?.titleStyle,
    ],
    amountStyle: [
      {
        color: textColor,
      },
      style?.amountStyle,
    ],
    contentStyle: [{
      marginBottom: -1,
      borderBottomWidth: 1,
      borderColor: childrenContainerBorderColor,
      ...!context?.isOpened && {
        display: 'none'
      }
    }, style?.contentStyle],
  };

};
