import { RouteNames } from './navigation'
export interface AtInternetRouteNameConfigType {
  [key: string]: {
    name: string
    chapter1: string
    chapter2?: string
  }
}

const ATINTERNETROUTENAMECONFIG: AtInternetRouteNameConfigType = {
  [RouteNames.CguAcceptation]: {
    name: 'Validation des CGU',
    chapter1: 'Connexion',
    chapter2: 'Post connexion'
  },
  [RouteNames.OidcCallbackRedirect]: {
    name: 'Bonjour',
    chapter1: 'Connexion',
    chapter2: 'Technique'
  },
  [RouteNames.OidcCallbackRedirectSilentPage]: {
    name: 'OidcCallbackRedirectSilentPage',
    chapter1: 'Connexion',
    chapter2: 'Technique'
  },
  [RouteNames.OidcLogoutCallbackRedirect]: {
    name: 'Accueil',
    chapter1: 'Connexion',
    chapter2: 'Accueil'
  },
  [RouteNames.NativeLoginPage]: {
    name: 'NativeLoginPage',
    chapter1: 'Connexion',
    chapter2: 'Mobile biometrie'
  },
  [RouteNames.BiConsentment]: {
    name: 'Consentement agreg',
    chapter1: 'Dashboard',
    chapter2: 'Agregation'
  },
  [RouteNames.BiErrorPage]: { name: 'Echec', chapter1: 'Dashboard', chapter2: 'Agregation' },
  [RouteNames.BiConnect]: { name: 'Connection', chapter1: 'Dashboard', chapter2: 'Agregation' },
  [RouteNames.BiCallback]: { name: 'Call back', chapter1: 'Dashboard', chapter2: 'Agregation' },
  [RouteNames.SelfcareLetterLanding]: {
    name: 'SelfcareLetterLanding',
    chapter1: 'EER',
    chapter2: 'Courrier SC'
  },
  [RouteNames.Error]: { name: 'Erreur', chapter1: 'Technique ', chapter2: 'Erreur' },
  [RouteNames.Logout]: { name: 'Deconnexion', chapter1: 'Connexion', chapter2: 'Deconnexion' },
  [RouteNames.EndOidcSessionPage]: {
    name: 'EndOidcSessionPage',
    chapter1: 'Connexion',
    chapter2: 'Technique'
  },
  [RouteNames.EndOidcSessionCallbackPage]: {
    name: 'SessionPage',
    chapter1: 'Connexion',
    chapter2: 'Technique'
  },
  [RouteNames.CompanyChoicePage]: {
    name: 'Choix entreprise',
    chapter1: 'Connexion',
    chapter2: 'Post connexion'
  },
  [RouteNames.Support]: { name: 'Support', chapter1: 'Support', chapter2: 'Support' },
  [RouteNames.MobileMenuModal]: { name: 'Menu', chapter1: 'Dashboard', chapter2: 'Mobile ' },
  [RouteNames.BackOfficeAccess]: { name: 'Acces', chapter1: 'BackOffice', chapter2: 'Acces' },
  [RouteNames.OperationTypeModalPage]: {
    name: 'OperationTypeModalPage',
    chapter1: 'tbc',
    chapter2: 'tbc'
  },
  [RouteNames.VVPDetails]: { name: 'Détail VVP', chapter1: 'Epargne', chapter2: 'VVP' },
  [RouteNames.OperationDetails]: {
    name: 'Détail Opération',
    chapter1: 'Epargne',
    chapter2: 'Opération '
  },
  [RouteNames.OperationCancel]: {
    name: 'Demande Annulation',
    chapter1: 'Epargne',
    chapter2: 'Opération '
  },
  [RouteNames.OperationCancelSuccess]: {
    name: 'Confirmation Annulation',
    chapter1: 'Epargne',
    chapter2: 'Opération '
  },
  [RouteNames.DisponibilityTermPage]: {
    name: 'DisponibilityTermPage',
    chapter1: 'tbc',
    chapter2: 'tbc'
  },
  [RouteNames.SapiendoRedirectPage]: {
    name: 'Redirection Ma Retraite',
    chapter1: 'Dashboard',
    chapter2: 'Simulateurs'
  },
  [RouteNames.RedirectOperationPage]: {
    name: 'RedirectOperationPage',
    chapter1: 'tbc',
    chapter2: 'tbc'
  },
  [RouteNames.Contact]: { name: 'Contact', chapter1: 'tbc', chapter2: 'tbc' },
  [RouteNames.ConsentTransferCredit]: {
    name: 'Consentement au transfert',
    chapter1: 'Connexion',
    chapter2: 'post connexion'
  },
  [RouteNames.BusinessContact]: {
    name: 'Preference de contact marketing',
    chapter1: 'Connexion',
    chapter2: 'post connexion'
  },
  [RouteNames.MaintenanceModal]: {
    name: 'Maintenance',
    chapter1: 'Connexion',
    chapter2: 'Maintenance '
  },
  [RouteNames.RelationshipEntringProcess]: {
    name: 'RelationshipEntringProcess',
    chapter1: 'tbc',
    chapter2: 'tbc'
  },
  [RouteNames.Welcome]: { name: 'Bienvenue', chapter1: 'Connexion', chapter2: 'post connexion' },
  [RouteNames.FConnectSignUp]: { name: 'France Connect', chapter1: 'EER', chapter2: 'Courrier SC' },
  [RouteNames.ValidateSignup]: {
    name: 'ValidateSignup',
    chapter1: 'EER',
    chapter2: 'Sécurisation'
  },
  [RouteNames.ValidationSignupEmailCode]: {
    name: 'OTP email',
    chapter1: 'EER',
    chapter2: 'Sécurisation'
  },
  [RouteNames.ValidationSignupPhoneCode]: {
    name: 'OTP mobile',
    chapter1: 'EER',
    chapter2: 'Sécurisation'
  },
  [RouteNames.ValidateSignupThankYou]: { name: 'Merci', chapter1: 'EER', chapter2: 'Sécurisation' },
  [RouteNames.ChooseIdentificationMail]: {
    name: 'Saisie email',
    chapter1: 'EER',
    chapter2: 'Création'
  },
  [RouteNames.ChooseIdentificationPhone]: {
    name: 'Saisie mobile',
    chapter1: 'EER',
    chapter2: 'Création'
  },
  [RouteNames.ChooseIdentificationEmailCodePage]: {
    name: 'Saisie OTP mail',
    chapter1: 'EER',
    chapter2: 'Création'
  },
  [RouteNames.ChooseIdentificationPhoneCodePage]: {
    name: 'Saisie OTP mobile',
    chapter1: 'EER',
    chapter2: 'Création'
  },
  [RouteNames.ChoosePassword]: {
    name: 'Saisie mot de passe',
    chapter1: 'EER',
    chapter2: 'Création'
  },
  [RouteNames.AccountCreationSuccess]: {
    name: 'EER Confirmation',
    chapter1: 'EER',
    chapter2: 'Création'
  },
  [RouteNames.AccountTerms]: { name: 'AccountTerms', chapter1: 'tbc', chapter2: 'tbc' },
  [RouteNames.CompanyBenefits]: { name: 'CompanyBenefits', chapter1: 'EER', chapter2: 'tbc' },
  [RouteNames.OnboardingPersoneo]: { name: 'Personeo', chapter1: 'EER', chapter2: 'Connexion' },
  [RouteNames.OnboardingVisiogo]: { name: 'Visiogo', chapter1: 'EER', chapter2: 'Connexion' },
  [RouteNames.ForgetPassword]: { name: 'Perte pwd', chapter1: 'Connexion', chapter2: 'Perte pwd' },
  [RouteNames.ForgetPasswordBegin]: { name: 'Info', chapter1: 'Connexion', chapter2: 'Perte pwd' },
  [RouteNames.ForgetPasswordValidationCodePage]: {
    name: 'Validation code',
    chapter1: 'Connexion',
    chapter2: 'Perte pwd'
  },
  [RouteNames.ForgetPasswordChoosePasswordPage]: {
    name: 'Saisie pwd',
    chapter1: 'Connexion',
    chapter2: 'Perte pwd'
  },
  [RouteNames.ForgetPasswordCongratsPage]: {
    name: 'Confirmation',
    chapter1: 'Connexion',
    chapter2: 'Perte pwd'
  },
  [RouteNames.InstallmentStack]: {
    name: 'VV',
    chapter1: 'Parcours',
    chapter2: 'Versements volontaires'
  },
  [RouteNames.Installments]: {
    name: 'VV 01 - Liste des étapes',
    chapter1: 'Parcours',
    chapter2: 'Versements volontaires'
  },
  [RouteNames.InstallmentsRepartition]: {
    name: 'VV 04 - Repartition',
    chapter1: 'Parcours',
    chapter2: 'Versements volontaires'
  },
  [RouteNames.InstallmentsSynthesis]: {
    name: 'VV 05 - Validation',
    chapter1: 'Parcours',
    chapter2: 'Versements volontaires'
  },
  [RouteNames.InstallmentsRequirementDefiniton]: {
    name: 'VV 02 - Definition du besoin',
    chapter1: 'Parcours',
    chapter2: 'Versements volontaires'
  },
  [RouteNames.InstallmentPlanChoice]: {
    name: 'VV 03 - Choix du dispositif',
    chapter1: 'Parcours',
    chapter2: 'Versements volontaires'
  },
  [RouteNames.InstallmentsPaymentMode]: {
    name: 'VV 06 - Choix mode de paiement',
    chapter1: 'Parcours',
    chapter2: 'Versements volontaires'
  },
  [RouteNames.InstallmentFailed]: {
    name: 'VV 08 - Echec',
    chapter1: 'Parcours',
    chapter2: 'Versements volontaires'
  },
  [RouteNames.InstallmentSuccess]: {
    name: 'VV 08 - Confirmation',
    chapter1: 'Parcours',
    chapter2: 'Versements volontaires'
  },
  [RouteNames.InstallmentLoading]: {
    name: 'VV Chargement',
    chapter1: 'Parcours',
    chapter2: 'Versements volontaires'
  },
  [RouteNames.InstallmentPaymentCBHtml]: {
    name: 'VV 07 - Paiement CB',
    chapter1: 'Parcours',
    chapter2: 'Versements volontaires'
  },
  [RouteNames.InstallmentModal]: {
    name: 'VV Modale',
    chapter1: 'Parcours',
    chapter2: 'Versements volontaires'
  },
  [RouteNames.BankDetailsStack]: {
    name: 'CB',
    chapter1: 'Profil',
    chapter2: 'Coordonnées bancaires'
  },
  [RouteNames.BankSetDomiciliation]: {
    name: '01 - Domiciliation',
    chapter1: 'Profil',
    chapter2: 'Coordonnées bancaires'
  },
  [RouteNames.BankSetDocuments]: {
    name: '02 - Justificatifs',
    chapter1: 'Profil',
    chapter2: 'Coordonnées bancaires'
  },
  [RouteNames.BankSetIban]: {
    name: '03 - Saisie IBAN',
    chapter1: 'Profil',
    chapter2: 'Coordonnées bancaires'
  },
  [RouteNames.BankCopyIban]: {
    name: '04 - Copie',
    chapter1: 'Profil',
    chapter2: 'Coordonnées bancaires'
  },
  [RouteNames.BankSignature]: {
    name: '05 - Signature',
    chapter1: 'Profil',
    chapter2: 'Coordonnées bancaires'
  },
  [RouteNames.BankDetailsSummary]: {
    name: 'Consultation',
    chapter1: 'Profil',
    chapter2: 'Coordonnées bancaires'
  },
  [RouteNames.RefundsStack]: {
    name: 'Remb',
    chapter1: 'Parcours',
    chapter2: 'Remboursement Dispo'
  },
  [RouteNames.RefundOnboarding]: {
    name: 'Remb 01 - Liste des étapes',
    chapter1: 'Parcours',
    chapter2: 'Remboursement Dispo'
  },
  [RouteNames.RefundsRequirementDefiniton]: {
    name: 'Remb 02 - Définition du besoin',
    chapter1: 'Parcours',
    chapter2: 'Remboursement Dispo'
  },
  [RouteNames.UnavailableRefundRC]: {
    name: 'Remb Cas RC',
    chapter1: 'Parcours',
    chapter2: 'Remboursement Dispo'
  },
  [RouteNames.RefundPlanChoice]: {
    name: 'Remb 03 - Choix du dispositif',
    chapter1: 'Parcours',
    chapter2: 'Remboursement Dispo'
  },
  [RouteNames.RefundRepartition]: {
    name: 'Remb 04 - Répartition',
    chapter1: 'Parcours',
    chapter2: 'Remboursement Dispo'
  },
  [RouteNames.RefundAskProof]: {
    name: 'Remb 05 - Justificatifs',
    chapter1: 'Parcours',
    chapter2: 'Remboursement Dispo'
  },
  [RouteNames.RefundModal]: {
    name: 'Remb modale',
    chapter1: 'Parcours',
    chapter2: 'Remboursement Dispo'
  },
  [RouteNames.RefundsRecap]: {
    name: 'Remb 06 - Validation',
    chapter1: 'Parcours',
    chapter2: 'Remboursement Dispo'
  },
  [RouteNames.RefundSuccess]: {
    name: 'Remb 07 - Confirmation',
    chapter1: 'Parcours',
    chapter2: 'Remboursement Dispo'
  },
  [RouteNames.RefundFailed]: {
    name: 'Remb 07 - Echec',
    chapter1: 'Parcours',
    chapter2: 'Remboursement Dispo'
  },
  [RouteNames.ArbitrationStack]: { name: 'Arb', chapter1: 'Parcours', chapter2: 'Arbitrage' },
  [RouteNames.ArbitrationChoice]: {
    name: 'Arb 01 - Choix',
    chapter1: 'Parcours',
    chapter2: 'Arbitrage'
  },
  [RouteNames.ArbitrationOnboarding]: {
    name: 'Arb tbc',
    chapter1: 'Parcours',
    chapter2: 'Arbitrage'
  },
  [RouteNames.ArbitrationOADRepartition]: {
    name: 'Arb Repartition OAD',
    chapter1: 'Parcours',
    chapter2: 'Arbitrage OAD'
  },
  [RouteNames.ArbitrationPlanChoice]: {
    name: 'Arb 03 - Choix dispositif',
    chapter1: 'Parcours',
    chapter2: 'Arbitrage'
  },
  [RouteNames.ArbitrationDisinvestmentsSaving]: {
    name: 'Arb 04 - Desinvestissement',
    chapter1: 'Parcours',
    chapter2: 'Arbitrage'
  },
  [RouteNames.ArbitrationSynthesis]: {
    name: 'Arb 06 - Validation',
    chapter1: 'Parcours',
    chapter2: 'Arbitrage'
  },
  [RouteNames.ArbitrationReinvestment]: {
    name: 'Arb 05 - Reinvestissement',
    chapter1: 'Parcours',
    chapter2: 'Arbitrage'
  },
  [RouteNames.ArbitrationFailure]: {
    name: 'Arb Echec',
    chapter1: 'Parcours',
    chapter2: 'Arbitrage'
  },
  [RouteNames.ArbitrationSuccess]: {
    name: 'Arb 07 - Confirmation',
    chapter1: 'Parcours',
    chapter2: 'Arbitrage'
  },
  [RouteNames.BottomTabNavigator]: { name: 'BottomTabNavigator', chapter1: 'tbc', chapter2: 'tbc' },
  [RouteNames.Home]: { name: 'Dashboard', chapter1: 'Dashboard', chapter2: 'Dashboard' },
  [RouteNames.SavingsStack]: { name: 'Epargne', chapter1: 'Epargne', chapter2: 'Epargne' },
  [RouteNames.Savings]: { name: 'Epargne', chapter1: 'Epargne', chapter2: 'Epargne' },
  [RouteNames.VVPList]: { name: 'Liste des VVP', chapter1: 'Epargne', chapter2: 'VVP' },
  [RouteNames.funds]: { name: 'Fiche Fonds', chapter1: 'Epargne', chapter2: 'Fonds' },
  [RouteNames.HistoryStack]: { name: 'Mon Fil', chapter1: 'Mon Fil', chapter2: 'Mon Fil' },
  [RouteNames.History]: { name: 'Mon Fil', chapter1: 'Mon Fil', chapter2: 'Mon Fil' },
  [RouteNames.ProfileStack]: { name: 'Profil', chapter1: 'Profil', chapter2: 'Profil' },
  [RouteNames.ProfileEditEmail]: {
    name: 'Saisie email',
    chapter1: 'Profil',
    chapter2: 'Coordonnées perso'
  },
  [RouteNames.ProfileEditPhone]: {
    name: 'Saisie mobile',
    chapter1: 'Profil',
    chapter2: 'Coordonnées perso'
  },
  [RouteNames.Profile]: { name: 'Menu', chapter1: 'Profil', chapter2: 'Menu' },
  [RouteNames.EditProfileInformations]: {
    name: 'Menu info perso',
    chapter1: 'Profil',
    chapter2: 'Info Perso'
  },
  [RouteNames.EditPassword]: { name: 'Saisie password', chapter1: 'Profil', chapter2: 'Modif pwd' },
  [RouteNames.ContactPreferences]: {
    name: 'Saisie preferences',
    chapter1: 'Profil',
    chapter2: 'Preference de contact'
  },
  [RouteNames.BusinessContactPreferences]: {
    name: 'Preference de contact marketing',
    chapter1: 'Profil',
    chapter2: 'Preference de contact Mkting'
  },
  [RouteNames.ConnexionBiometrique]: {
    name: 'Saisie biometrique',
    chapter1: 'Connexion',
    chapter2: 'Mobile'
  },
  [RouteNames.ProfileLegalInfo]: {
    name: 'ProfileLegalInfo',
    chapter1: 'Profil',
    chapter2: 'Info légale'
  },
  [RouteNames.Editorial]: { name: 'editorial', chapter1: 'tbc', chapter2: 'tbc' },
  [RouteNames.Storybook]: { name: 'Saisie storybook', chapter1: 'tbc', chapter2: 'tbc' },
  [RouteNames.OtpValidation]: { name: 'OTP Validation', chapter1: 'Sécurité', chapter2: 'OTP' },
  [RouteNames.OtpValidationStart]: { name: 'OTP Début', chapter1: 'Sécurité', chapter2: 'OTP' },
  [RouteNames.OtpValidationSuccess]: {
    name: 'OTP Confirmation',
    chapter1: 'Sécurité',
    chapter2: 'OTP'
  },
  [RouteNames.OtpValidationForm]: { name: 'OTP Saisie', chapter1: 'Sécurité', chapter2: 'OTP' },
  [RouteNames.SelfcareStack]: { name: 'Saisie stack', chapter1: 'EER', chapter2: 'SC' },
  [RouteNames.ActivateAccountModeSelection]: {
    name: 'Saisie selection',
    chapter1: 'EER',
    chapter2: 'Menu SC'
  },
  [RouteNames.PersoneoIdentifierForm]: {
    name: 'Personeo formulaire',
    chapter1: 'EER',
    chapter2: 'Personeo SC'
  },
  [RouteNames.VisiogoIdentifierForm]: {
    name: 'Visiogo formulaire',
    chapter1: 'EER',
    chapter2: 'Visiogo SC'
  },
  [RouteNames.LetterIdentifierForm]: {
    name: 'Courrier formulaire',
    chapter1: 'EER',
    chapter2: 'Courrier SC'
  },
  [RouteNames.SelfcarePersoneoSuccess]: {
    name: 'Saisie success',
    chapter1: 'EER',
    chapter2: 'Personeo SC'
  },
  [RouteNames.SelfcareVisiogoSuccess]: {
    name: 'Saisie success',
    chapter1: 'EER',
    chapter2: 'Visiogo SC'
  },
  [RouteNames.SelfcareLetterSuccess]: {
    name: 'Saisie success',
    chapter1: 'EER',
    chapter2: 'Courrier SC'
  },
  [RouteNames.ProfitSharingIncentive]: {
    name: 'PI Saisie incentive',
    chapter1: 'Parcours',
    chapter2: 'P+I'
  },
  [RouteNames.ProfitSharingIncentiveDescription]: {
    name: 'PI 01 - Montant de la prime',
    chapter1: 'Parcours',
    chapter2: 'P+I'
  },
  [RouteNames.ProfitSharingIncentiveAssigment]: {
    name: 'PI 03 - Jauge répartition',
    chapter1: 'Parcours',
    chapter2: 'P+I'
  },
  [RouteNames.ProfitSharingIncentiveOnboarding]: {
    name: 'PI 02 - Liste des étapes',
    chapter1: 'Parcours',
    chapter2: 'P+I'
  },
  [RouteNames.ProfitSharingIncentiveRepartition]: {
    name: 'PI 04 - Répartition invest',
    chapter1: 'Parcours',
    chapter2: 'P+I'
  },
  [RouteNames.ProfitSharingIncentiveSynthesis]: {
    name: 'PI 05 - Synthése',
    chapter1: 'Parcours',
    chapter2: 'P+I'
  },
  [RouteNames.ProfitSharingIncentiveSuccess]: {
    name: 'PI 06 - Confirmation',
    chapter1: 'Parcours',
    chapter2: 'P+I'
  },
  [RouteNames.ProfitSharingIncentiveDefaultAffectations]: {
    name: 'PI 01b - Affectation par defaut',
    chapter1: 'Parcours',
    chapter2: 'P+I'
  },
  [RouteNames.ProfitSharingIncentiveModal]: {
    name: 'PI Modale abandon',
    chapter1: 'Parcours',
    chapter2: 'P+I'
  },
  [RouteNames.ProfitSharingIncentiveFailedPage]: {
    name: 'PI  Echec',
    chapter1: 'Parcours',
    chapter2: 'P+I'
  },
  [RouteNames.DocumentSpaceStack]: {
    name: 'Saisie stack',
    chapter1: 'Documents',
    chapter2: 'Doc génériques'
  },
  [RouteNames.DocumentSpaceHome]: {
    name: 'Aiguillage documents généraux / e-documents',
    chapter1: 'Documents',
    chapter2: 'Doc génériques'
  },
  [RouteNames.DocumentSpaceGeneralDocuments]: {
    name: 'Liste des documents généraux',
    chapter1: 'Documents',
    chapter2: 'Doc génériques'
  },
  [RouteNames.DocumentSpaceEDocumentsFiles]: {
    name: 'Liste des e-documents',
    chapter1: 'Documents',
    chapter2: 'Doc génériques'
  },
  [RouteNames.DocumentSpaceViewer]: {
    name: 'Visualisation document',
    chapter1: 'Documents',
    chapter2: 'Doc génériques'
  },
  [RouteNames.AnnuityStack]: {
    name: 'Saisie stack',
    chapter1: 'Dashboard',
    chapter2: 'Simulateurs'
  },
  [RouteNames.AnnuityHomePage]: {
    name: '01 - Rente Accueil',
    chapter1: 'Dashboard',
    chapter2: 'Simulateurs'
  },
  [RouteNames.AnnuitySimulator]: {
    name: '02 - Rente  Simulation',
    chapter1: 'Dashboard',
    chapter2: 'Simulateurs'
  },
  [RouteNames.RetirementStack]: {
    name: 'Saisie stack',
    chapter1: 'Parcours',
    chapter2: 'Retraite'
  },
  [RouteNames.OnboardingPage]: {
    name: 'Menu Retraite',
    chapter1: 'Parcours',
    chapter2: 'Retraite'
  },
  [RouteNames.DetailsPage]: { name: 'Préparation', chapter1: 'Parcours', chapter2: 'Retraite' },
  [RouteNames.RetirementPage]: { name: 'Départ', chapter1: 'Parcours', chapter2: 'Retraite' },
  [RouteNames.RetirementRecoverSavingPage]: {
    name: 'Départ démarches',
    chapter1: 'Parcours',
    chapter2: 'Retraite'
  },
  [RouteNames.RetirementModal]: { name: 'Modale', chapter1: 'Parcours', chapter2: 'Retraite' },
  [RouteNames.ContributionModal]: {
    name: 'Détail abondement',
    chapter1: 'Dashboard',
    chapter2: 'Abondement'
  },
  [RouteNames.ControlData]: {
    name: 'Consultation et modif',
    chapter1: 'Profil',
    chapter2: 'Données de pilotage'
  },
  [RouteNames.VotAccess]: { name: 'Vot Access', chapter1: '', chapter2: '' }
}

export { ATINTERNETROUTENAMECONFIG }
