import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

export function useStyles() {
  const styles = useMemo(() => StyleSheet.create({
    container: {
      flexDirection: 'row',
      justifyContent: 'space-between'
    }
  }), []);

  return styles;
}
