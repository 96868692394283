import {
  RefundAmountValidationParamsType,
  RefundRepartitionCompartmentState,
  RefundRepartitionManagementState,
  RefundRepartitionState,
  RefundRepartitionSupportsState,
  RepaymentDemandState,
  RepaymentDemandSupportParam,
  TriggerThresholdProperties
} from './types'

export const buildRepaymentDemandData = (
  repartition: RefundRepartitionState
): RepaymentDemandState => {
  // we build repaymentDemandData
  // for now we only manage one compartment and one management
  const management = repartition?.compartments?.[0].managements?.[0]
  const fundsWithAmount = management?.supports.filter(support => support.formValues?.amount)
  const adaptedFunds: RepaymentDemandSupportParam[] = []
  fundsWithAmount?.forEach(fund => {
    if (fund.supportIsin && fund.formValues?.amount) {
      adaptedFunds.push({
        supportIsinCode: fund.supportIsin,
        amount: fund.formValues.amount,
        ...(fund.formValues?.triggerThreshold?.amount && {
          executionTriggerValue: fund.formValues?.triggerThreshold?.amount
        })
      })
    }
  })
  return {
    planManagement: {
      id: management.id,
      amount: management.formValues?.totalAmount || 0,
      funds: adaptedFunds
    }
  }
}

export const setRepartitionTotalAvailablesAmounts = (
  repartition: RefundRepartitionState
): RefundRepartitionState => {
  // total available amount is only given at management level
  // we want to calcul and set total available amount for compartments and plan level
  if (repartition) {
    let newRepartition: RefundRepartitionState = repartition
    let newPlanTotalAmountAvailable = 0

    const newCompartments: RefundRepartitionCompartmentState[] =
      newRepartition?.compartments.map(compartment => {
        let newCompartment = compartment
        let newCompartmentTotalAmountAvailable = 0

        newCompartment?.managements.forEach(management => {
          newCompartmentTotalAmountAvailable += management.totalAmount || 0
        })

        newCompartment = {
          ...newCompartment,
          totalAmount: newCompartmentTotalAmountAvailable
        }

        newPlanTotalAmountAvailable += newCompartment.totalAmount || 0
        return newCompartment
      }) || []

    newRepartition = {
      ...newRepartition,
      compartments: newCompartments,
      totalAmount: newPlanTotalAmountAvailable
    }
    return newRepartition
  }
  return repartition
}

const isManagementAllSelectedAndFullFilled = (
  managements: RefundRepartitionManagementState[]
): boolean => {
  const filterSelectedAndAllRefundAmountManagement = managements.filter(management => {
    const supports = management.supports
    const filterSelectedAndAllRefundAmountSupport = supports.filter(support => {
      const supportIsSelected = support.formValues?.isSelected || false
      const supportIsAllRefundAmount = support.formValues?.amount === support.amount.amount
      return supportIsSelected && supportIsAllRefundAmount
    })

    const allSupportsAreFullFilled =
      filterSelectedAndAllRefundAmountSupport.length === supports.length

    return allSupportsAreFullFilled
  })

  const allManagementAreFullFilled =
    filterSelectedAndAllRefundAmountManagement.length === managements.length

  return allManagementAreFullFilled
}

const setCalculAllRepartitionFormValuesTotalsAndIsAllselected = (
  repartition: RefundRepartitionState
): RefundRepartitionState => {
  if (repartition) {
    let newRepartition: RefundRepartitionState = repartition
    let newPlanTotalAmount = 0
    let isAllSelected = false
    const newCompartments: RefundRepartitionCompartmentState[] =
      newRepartition?.compartments.map(compartment => {
        let newCompartment = compartment
        let newCompartmentTotalAmount = 0

        const newManagements: RefundRepartitionManagementState[] =
          newCompartment?.managements.map(management => {
            let newManagement = management
            let newManagementTotalAmount = 0

            newManagement?.supports?.forEach(support => {
              newManagementTotalAmount += support?.formValues?.amount || 0
            })

            newManagement = {
              ...newManagement,
              formValues: {
                ...newManagement?.formValues,
                totalAmount: newManagementTotalAmount
              }
            }

            newCompartmentTotalAmount += newManagement?.formValues?.totalAmount || 0
            return newManagement
          }) || []

        const allSupportAreSelectedAndFullFilled =
          isManagementAllSelectedAndFullFilled(newManagements)
        isAllSelected = allSupportAreSelectedAndFullFilled
        newCompartment = {
          ...newCompartment,
          managements: newManagements,
          formValues: {
            ...newRepartition?.formValues,
            totalAmount: newCompartmentTotalAmount,
            isAllSelected: allSupportAreSelectedAndFullFilled
          }
        }

        newPlanTotalAmount += newCompartment?.formValues?.totalAmount || 0
        return newCompartment
      }) || []

    newRepartition = {
      ...newRepartition,
      compartments: newCompartments,
      formValues: {
        ...newRepartition?.formValues,
        isAllSelected: isAllSelected,
        totalAmount: newPlanTotalAmount
      }
    }
    return newRepartition
  }
  return repartition
}

export const setAmountToSupport = ({
  repartition,
  params
}: {
  repartition: RefundRepartitionState | null
  params: RefundAmountValidationParamsType
}): RefundRepartitionState | null => {
  if (repartition) {
    let newRepartition: RefundRepartitionState = repartition

    const newCompartments: RefundRepartitionCompartmentState[] =
      newRepartition?.compartments.map(compartment => {
        let newCompartment = compartment
        const isTargetedCompartment = compartment.code === params.compartmentId
        if (isTargetedCompartment) {
          const newManagements: RefundRepartitionManagementState[] =
            newCompartment?.managements.map(management => {
              let newManagement = management
              const isTargetedManagement =
                isTargetedCompartment && management.id === params.managementId

              if (isTargetedManagement) {
                const newSupports: RefundRepartitionSupportsState[] =
                  newManagement?.supports?.map(support => {
                    let newSupport = support

                    const isTargetedSupport =
                      isTargetedManagement && support.supportIsin === params.supportIsin
                    if (isTargetedSupport) {
                      newSupport = {
                        ...newSupport,
                        formValues: {
                          ...newSupport.formValues,
                          amount: params.amount,
                          ...(typeof params.amount === 'number' && { isSelected: true })
                        }
                      }
                    }
                    return newSupport
                  }) || []

                newManagement = {
                  ...newManagement,
                  supports: newSupports
                }
              }

              return newManagement
            }) || []

          newCompartment = {
            ...newCompartment,
            managements: newManagements
          }
        }

        return newCompartment
      }) || []

    newRepartition = {
      ...newRepartition,
      compartments: newCompartments
    }
    newRepartition = setCalculAllRepartitionFormValuesTotalsAndIsAllselected(newRepartition)
    return newRepartition
  }
  return repartition
}

export const setSelectAllCompartmentSupport = ({
  repartition,
  selectState
}: {
  repartition: RefundRepartitionState | null
  selectState: boolean
}): RefundRepartitionState | null => {
  if (repartition) {
    let newRepartition: RefundRepartitionState = repartition

    const newCompartments: RefundRepartitionCompartmentState[] =
      newRepartition?.compartments.map(compartment => {
        let newCompartment = compartment

        const newManagements: RefundRepartitionManagementState[] =
          newCompartment?.managements.map(management => {
            let newManagement = management

            const newSupports: RefundRepartitionSupportsState[] =
              newManagement?.supports?.map(support => {
                let newSupport = support
                if (selectState) {
                  // for select all support true
                  // we update all supports selection state and we set amount to full available amount
                  // we recacul totalAmount
                  newSupport = {
                    ...newSupport,
                    formValues: {
                      ...newSupport.formValues,
                      isSelected: selectState,
                      amount: support.amount.amount
                    }
                  }
                } else {
                  // for select all support false
                  // we unselect all supports and we remove all saved formValues form each supports
                  // we recacul totalAmount
                  // ( it is just not incremented as we no more get amounts in support form values)
                  newSupport = {
                    ...newSupport,
                    formValues: {
                      isSelected: selectState
                    }
                  }
                }

                return newSupport
              }) || []

            newManagement = {
              ...newManagement,
              supports: newSupports
            }

            return newManagement
          }) || []

        newCompartment = {
          ...newCompartment,
          managements: newManagements
        }

        return newCompartment
      }) || []

    newRepartition = {
      ...newRepartition,
      compartments: newCompartments
    }
    newRepartition = setCalculAllRepartitionFormValuesTotalsAndIsAllselected(newRepartition)
    return newRepartition
  }
  return repartition
}

export const setSelectSupport = ({
  repartition,
  selectState,
  compartmentId,
  managementId,
  isinCode,
  amount
}: {
  repartition: RefundRepartitionState | null
  selectState: boolean
  compartmentId: string
  managementId: string
  isinCode: string
  amount?: number
}): RefundRepartitionState | null => {
  if (repartition) {
    let newRepartition: RefundRepartitionState = repartition
    const targetedSupport = newRepartition?.compartments
      ?.find(compartment => compartment.code === compartmentId)
      ?.managements?.find(management => management.id === managementId)
      ?.supports?.find(support => support.supportIsin === isinCode)
    const isTriggerThresholdWillBeDeleted =
      !selectState && !!targetedSupport?.formValues?.triggerThreshold

    const newCompartments: RefundRepartitionCompartmentState[] =
      newRepartition?.compartments.map(compartment => {
        let newCompartment = compartment
        const isTargetedCompartment = compartment.code === compartmentId
        if (isTriggerThresholdWillBeDeleted || isTargetedCompartment) {
          const newManagements: RefundRepartitionManagementState[] =
            newCompartment?.managements.map(management => {
              let newManagement = management
              const isTargetedManagement = isTargetedCompartment && management.id === managementId

              if (isTriggerThresholdWillBeDeleted || isTargetedManagement) {
                const newSupports: RefundRepartitionSupportsState[] =
                  newManagement?.supports?.map(support => {
                    let newSupport = support

                    const isTargetedSupport =
                      isTargetedManagement && support.supportIsin === isinCode
                    if (isTargetedSupport) {
                      if (selectState) {
                        // for select support true
                        // we update support selection state
                        newSupport = {
                          ...newSupport,
                          formValues: {
                            ...newSupport.formValues,
                            isSelected: selectState,
                            ...(typeof amount === 'number' && { amount })
                          }
                        }
                      } else {
                        // for select support false
                        // we update support selection state and we delete saved formvalues
                        newSupport = {
                          ...newSupport,
                          formValues: {
                            isSelected: selectState
                          }
                        }
                      }
                    }
                    if (isTriggerThresholdWillBeDeleted && !isTargetedSupport) {
                      newSupport = {
                        ...newSupport,
                        formValues: {
                          ...newSupport.formValues,
                          isDisabled: false
                        }
                      }
                    }
                    return newSupport
                  }) || []

                newManagement = {
                  ...newManagement,
                  supports: newSupports
                }
              }

              return newManagement
            }) || []

          newCompartment = {
            ...newCompartment,
            managements: newManagements
          }
        }

        return newCompartment
      }) || []

    newRepartition = {
      ...newRepartition,
      compartments: newCompartments
    }
    newRepartition = setCalculAllRepartitionFormValuesTotalsAndIsAllselected(newRepartition)
    return newRepartition
  }
  return repartition
}

export const setSupportTriggerThreshold = ({
  repartition,
  triggerPointData,
  compartmentId,
  managementId,
  supportIsInCode
}: {
  repartition: RefundRepartitionState | null
  triggerPointData?: TriggerThresholdProperties
  compartmentId: string
  managementId: string
  supportIsInCode: string
}): RefundRepartitionState | null => {
  if (repartition) {
    let newRepartition: RefundRepartitionState = repartition
    const isTriggerPointData = !!triggerPointData
    const newCompartments: RefundRepartitionCompartmentState[] =
      newRepartition?.compartments.map(compartment => {
        let newCompartment = compartment
        const isTargetedCompartment = compartment.code === compartmentId

        const newManagements: RefundRepartitionManagementState[] =
          newCompartment?.managements.map(management => {
            let newManagement = management
            const isTargetedManagement = isTargetedCompartment && management.id === managementId

            const newSupports: RefundRepartitionSupportsState[] =
              newManagement?.supports?.map(support => {
                let newSupport = support

                const isTargetedSupport =
                  isTargetedManagement && support.supportIsin === supportIsInCode
                if (isTargetedSupport) {
                  if (isTriggerPointData) {
                    newSupport = {
                      ...newSupport,
                      formValues: {
                        ...newSupport.formValues,
                        triggerThreshold: triggerPointData
                      }
                    }
                  } else {
                    newSupport = {
                      ...newSupport,
                      formValues: {
                        ...newSupport.formValues,
                        triggerThreshold: undefined
                      }
                    }
                  }
                  return newSupport
                } else {
                  // if not targeted support
                  if (isTriggerPointData) {
                    newSupport = {
                      ...newSupport,
                      formValues: {
                        isDisabled: true
                      }
                    }
                  } else {
                    newSupport = {
                      ...newSupport,
                      formValues: {
                        ...newSupport.formValues,
                        isDisabled: undefined
                      }
                    }
                  }
                  return newSupport
                }
              }) || []

            newManagement = {
              ...newManagement,
              supports: newSupports
            }

            return newManagement
          }) || []

        newCompartment = {
          ...newCompartment,
          managements: newManagements
        }

        return newCompartment
      }) || []

    newRepartition = {
      ...newRepartition,
      compartments: newCompartments
    }
    newRepartition = setCalculAllRepartitionFormValuesTotalsAndIsAllselected(newRepartition)
    return newRepartition
  }
  return repartition
}
