export interface DueDateProps {
  scheduleCode: AVAILABILITY_TYPE
  amount: number
  date?: string | undefined
  numberOfShares: number
  ontriggerDueDateEditPress: () => void
  onLinkUnderFieldEchenacePress: () => void
  dueDateFieldAmount: number
}

export enum AVAILABILITY_TYPE {
  AVAILABLE = 'AVAILABLE',
  UNAVAILABLE = 'UNAVAILABLE',
  RETIREMENT = 'RETIREMENT'
}
