import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

export function useStyles() {
  const theme = useTheme();

  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          maxWidth: theme.metrics.contentSizes.leftSideContentMaxWidth,
          marginBottom: theme.metrics.spacing.s
        },
        link: {
          marginTop: theme.metrics.spacing.s
        }
      }),
    [theme]
  );

  return styles;
}
