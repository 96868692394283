import React, { useCallback } from 'react';
import { View } from 'react-native';

import {
  Title,
  IdentifierForm,
} from '@components/index';
import { OtpFields } from '@ere-uilib/molecules/inputs/OtpFields';

import { OtpCodeIdentifierFormProps } from './types';
import {
  useStyles,
} from './useStyles';

export function LetterIdentifierForm({
  onPressSubmit,
  dictionary,
  isLoading,
  errorMessage,
  onChangeCode,
  onChangeDateOfBirth,
  dateOfBirth,
  dateOfBirthVisible,
  code,
}: OtpCodeIdentifierFormProps) {
  const submitButtonActive = !!code.length && !!dateOfBirth;
  const styles = useStyles();

  const renderIdentifierInput = useCallback(() => {
    return (
      <View style={styles.identifierField}>
        <Title
          style={styles.inputLabel}
          variant={'t4'}
          weight="bold">1 - </Title>
        <OtpFields
          code={code}
          hasError={Boolean(errorMessage)}
          onChange={onChangeCode}
          onSubmit={onPressSubmit}
        />
      </View>
    );
  }, [styles, code, errorMessage, onChangeCode, onPressSubmit]);

  const handlePressSubmit = useCallback(() => {
    if (!dateOfBirth) {
      return;
    }

    onPressSubmit();
  }, [dateOfBirth, onPressSubmit]);

  return (
    <IdentifierForm
      dateOfBirth={dateOfBirth}
      dateOfBirthVisible={dateOfBirthVisible}
      dictionary={dictionary}
      errorMessage={errorMessage}
      isLoading={isLoading}
      onChangeDateOfBirth={onChangeDateOfBirth}
      onPressSubmit={handlePressSubmit}
      renderIdentifierInput={renderIdentifierInput}
      submitButtonActive={submitButtonActive}
    />
  );
}
