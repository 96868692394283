import { StackNavigationProp } from '@react-navigation/stack';
import { useCallback, useEffect, useState } from 'react';

import { RouteNames } from '@constants/navigation';
import { ContactPreferencesOptin } from '@modules/profile/actions/profileActions';
import { AppNavigatorInterface } from '@navigation/Interfaces';

interface UseController {
  navigation: StackNavigationProp<AppNavigatorInterface>
  onSubmit: (values: ContactPreferencesOptin) => void | undefined
  onPressButtonSuccessModal: () => void
  acceptGroupEmail: boolean
  acceptPartnerEmail: boolean
  getContactPreferencesRequest: () => void
  toDoActionType?: string
  demandId?: string
}

export function useController({
  onSubmit,
  onPressButtonSuccessModal,
  navigation,
  
  acceptGroupEmail,
  acceptPartnerEmail,
  toDoActionType,
  demandId,
  getContactPreferencesRequest,
}: UseController) {
  const [optInGroupEmail, setOptInGroupEmail] = useState(acceptGroupEmail);
  const [optInPartnerEmail, setOptInPartnerEmail] = useState(acceptPartnerEmail);
  const [isSubmitActive, setIsSubmitActive] = useState<boolean>(false);
  
  useEffect(() => {
    getContactPreferencesRequest();
  }, []);

  useEffect(() => {
    setOptInGroupEmail(acceptGroupEmail);
  }, [acceptGroupEmail]);

  useEffect(() => {
    setOptInPartnerEmail(acceptPartnerEmail);
  }, [acceptPartnerEmail]);

  useEffect(()=> {
    if (optInPartnerEmail === undefined || optInGroupEmail === undefined) {
      return setIsSubmitActive(false);
    }
    if((optInGroupEmail !== acceptGroupEmail) || (optInPartnerEmail !== acceptPartnerEmail))
    {
      return setIsSubmitActive(true);
    }
    setIsSubmitActive(false);    
  }, [acceptGroupEmail, acceptPartnerEmail, optInGroupEmail, optInPartnerEmail])

  const handlePressGroupEmail = useCallback(async (optin: boolean) => {
    if(optInGroupEmail === undefined) {
      await setOptInGroupEmail(optin);
    }
    else {
      await setOptInGroupEmail(!optInGroupEmail)
    }
  }, [optInGroupEmail, setOptInGroupEmail]);

  const handlePressPartnerEmail = useCallback(async (optin: boolean) => {
    if(optInPartnerEmail === undefined) {
      await setOptInPartnerEmail(optin);
    }
    else {
      await setOptInPartnerEmail(!optInPartnerEmail)
    }
  }, [optInPartnerEmail, setOptInPartnerEmail]);

  const handleSubmit = useCallback(() => {
    onSubmit({
      acceptGroupEmail: optInGroupEmail,
      acceptPartnerEmail: optInPartnerEmail,
      toDoPostSuccessActionType: toDoActionType,
      demandId: demandId || '',
    });
  }, [onSubmit, optInGroupEmail, optInPartnerEmail, toDoActionType, demandId]);

  const handlePressButtonSuccessModal = useCallback(() => {
    onPressButtonSuccessModal();
    navigation.replace(RouteNames.BottomTabNavigator, {
      screen: RouteNames.ProfileStack,
      params: {
        screen: RouteNames.Profile,
      },
    });
  }, [onPressButtonSuccessModal, navigation]);

  return {
    isSubmitActive,
    optInPartnerEmail,
    optInGroupEmail,
    handlePressGroupEmail,
    handlePressPartnerEmail,
    handleSubmit,
    handlePressButtonSuccessModal,
  };
}
