import React, { ChangeEventHandler, useCallback, useRef } from 'react';
import { View } from 'react-native';

import { IconEnum } from '@ere-uilib/enums';
import { SimpleButton } from '@ere-uilib/molecules';
import { FilePreviewCard } from '@ere-uilib/organisms';
import { fileToBase64, removeBase64Head } from '@ere-uilib/utils';

import { FilePickerError } from './components';
import { FilePickerProps } from './interfaces';
import { useStyles } from './useStyles';

export const FilePicker: React.FC<React.PropsWithChildren<FilePickerProps>> = ({
  key,
  data,
  isLoading = false,
  hasError = false,
  fileType = 'image/png,image/jpeg,.pdf',
  onAdd,
  onRemove,
  containerStyle,
  dictionary,
  ...props
}) => {
  const styles = useStyles();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleOpenFileBrowser = useCallback(() => {
    inputRef?.current?.click?.();
  }, []);

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    async event => {
      const file = event?.target?.files?.item(0);
      if (!file) return;
      const base64 = removeBase64Head(await fileToBase64(file));
      onAdd?.({
        size: file.size,
        name: file.name,
        type: file.type,
        base64
      });
    },
    [onAdd]
  );

  const handleRemove = useCallback(() => {
    onRemove?.();
  }, [onRemove]);

  if (data)
    return (
      <FilePreviewCard
        containerStyle={containerStyle}
        data={data}
        isRemoving={isLoading}
        onRemove={handleRemove}
      />
    );

  return (
    <View
      key={key}
      style={containerStyle}>
      <input
        accept={fileType}
        hidden
        key={key + '-input'}
        onChange={handleChange}
        ref={inputRef}
        type="file"
      />
      <SimpleButton
        design="outlined"
        isHoverableButton={false}
        key={key + '-button'}
        leftIcon={IconEnum.AJOUTER}
        loading={isLoading}
        loadingTitle={dictionary.loadingTitle}
        onPress={handleOpenFileBrowser}
        size="small"
        title={dictionary.buttonTitle}
        {...props}
      />
      {hasError && (
        <FilePickerError
          containerStyle={styles.errorContainer}
          description={dictionary.errorDescription}
          title={dictionary.errorTitle}
        />
      )}
    </View>
  );
};
