import { InstallmentRepartitionPageContext, InstallmentRepartitionPageStyle } from './interfaces';

export const getStyles = (
  context?: InstallmentRepartitionPageContext,
  style?: InstallmentRepartitionPageStyle,
): InstallmentRepartitionPageStyle => ({
  containerStyle: [
    (context?.isMobile || context?.isTablet) && {
    },
    style?.containerStyle,
  ],
  installmentsStepperContainerStyle: [{
    alignItems: 'center',
    justifyContent: 'center',
  },
  style?.installmentsStepperContainerStyle,
  ],
  leftDriving: [
    {    
      paddingVertical: 20,
      paddingHorizontal: 15,
      borderRightWidth: 2,
      borderRightColor: context?.theme.colors.basics.grey.c200
    },
    style?.leftDriving
  ],
  linkStyle: [
    {    
      marginTop: context?.theme.metrics.spacing.xxs,
      fontSize: context?.theme.fonts.fontSize.paragraph.t3
    },
    style?.linkStyle
  ],
  stepperStyle: [
    {
      paddingBottom: context?.theme.metrics.spacing.s,
      marginTop: context && -context?.theme.metrics.spacing.s,
    },
    style?.stepperStyle,
  ],
  managementsContainer: [
    {
    },
    style?.managementsContainer,
  ],
  managementContainer: [
    {
      marginTop: context?.theme.metrics.spacing.xl,
    },
    style?.managementContainer,
  ],
  bottomActionsStyle: [
    {
      width: '100%',
      flexDirection: 'row',
    },
    !(context?.isMobile || context?.isTablet) && {
      paddingTop: context?.theme.metrics.spacing.xl,
    },
    style?.bottomActionsStyle,
  ],
  arrowButtonStyle: [
    !(context?.isMobile || context?.isTablet) && {
      flex: 1,
    },
    style?.arrowButtonStyle,
  ],
  simpleButtonStyle: [{
    marginLeft: context?.theme.metrics.spacing.m,
    flex: 1,
  },
  style?.simpleButtonStyle,
  ],
  notificationContainerStyle: [{
    width: '100%',
  },
  style?.notificationContainerStyle,
  ],
  subHeaderContainerStyle: [{
    marginBottom: context?.theme.metrics.spacing.xm,
  },
  style?.subHeaderContainerStyle,
  ],
  alertContainerStyle: [{
    marginTop: context?.theme.metrics.spacing.xm,
  },
  style?.alertContainerStyle,
  ],
  compareButtonContainerStyle: [
    {
      alignItems: 'center',
    },
    style?.compareButtonContainerStyle,
  ],
  compareButtonStyle: [
    {
      marginBottom: context?.theme.metrics.spacing.s,
      marginTop: context?.theme.metrics.spacing.l,
    },
    style?.compareButtonStyle,
  ],
  repartitionFreeFilterwarningLabelContainer: [
    {
      padding: context?.theme.metrics.spacing.s,
      borderBottomWidth: 1,
      borderBottomColor: context?.theme.colors.basics.grey.c100,
    },
    style?.repartitionFreeFilterwarningLabelContainer,
  ],
  contributionCardStyle: [
    {
      marginBottom: context?.theme.metrics.spacing.l,
      marginTop: context?.theme.metrics.spacing.s,
    },
    style?.contributionCardStyle,
  ],
  rightContributionContainer: {
     marginBottom: 30 
  }
});
