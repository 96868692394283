import React from 'react';

import {
  DashboardAnnounce,
  Paragraph,
  SimpleButton,
} from '@components/index';
import { locator } from '@constants/locator';
import { RouteNames } from '@constants/navigation';
import { innerNavigations } from '@constants/savings';
import {
  StepperItemType,
} from '@ere-uilib/molecules/steppers/InstallmentsStepper/interfaces';
import { useScreenSizes, useTheme, createUseStyles } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';
import { clearInstallmentData } from '@modules/installment/actions/installmentActions';

import { InstallmentSuccessPageProps } from './interfaces';
import { getStyles } from './styles';

export const InstallmentSuccessPageComponent: React.FC<React.PropsWithChildren<InstallmentSuccessPageProps>> = ({
  installmentRepartitionData,
  installmentDecisionAllResponses,
  onClearCacheAfterOperationDone,
}) => {
  const { formatMessage } = useTranslation();
  const isVVL = installmentDecisionAllResponses?.VVL;
  const theme = useTheme();
  const { isMobile, isTablet } = useScreenSizes();
  const styles = useStyles({ isMobile: isMobile || isTablet, theme }, {});

  const renderActions = () => {
    return (
      <SimpleButton
        onPress={() => {
          onClearCacheAfterOperationDone({
            stack: RouteNames.BottomTabNavigator,
            screen: RouteNames.Home,
            actionType: clearInstallmentData(),
          });
        }}
        testId={locator._payment._back_home}
        title={formatMessage({ id: 'VV_boutonPaiement_succes' })}
      />
    );
  };

  return (
    <DashboardAnnounce
      actions={renderActions}
      // steps disabled for now
      // TODO : readd stepps when steps are redifined by UX/MOA
      // description={
      //   <InstallmentsStepper
      //     direction={DirectionEnum.COLUMN}
      //     stepperItems={stepperItems}
      //     stepperSize={isMobile ? StepperSizeEnum.SMALL : StepperSizeEnum.BIG}
      //   />
      // }
      sourceName={'installmentDone'}
      title={formatMessage({
        id: 'VV_paiement_messageSucces',
        values: { plan: installmentRepartitionData?.shortLabel },
      })}
    >
      {!isVVL && (
        <Paragraph
          style={styles.successDescriptionParagraph}
          variant="t4">
          {formatMessage({ id: 'VV_paiement_confirmation_vvp_info_label' })}
        </Paragraph>
      )}
    </DashboardAnnounce>
  );
};

const useStyles = createUseStyles(getStyles);
