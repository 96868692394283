import { connect } from 'react-redux';

import { getProfitSharingIncentiveInitDataState } from '@modules/profit-sharing-incentive/selectors';
import { AppState } from '@modules/reducers';

import {
  ProfitSharingIncentiveDefaultAffectationsComponent
} from './ProfitSharingIncentiveDefaultAffectationsPage.component';

const mapStateToProps = (state: AppState) => {
  return {
    initData: getProfitSharingIncentiveInitDataState(state)
  };
};

const mapDispatchToProps = {
};

export const ProfitSharingIncentiveDefaultAffectationsPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfitSharingIncentiveDefaultAffectationsComponent);
