import React, { ReactNode, useCallback, useMemo } from 'react';
import {
  LegalMenuFooterConnected,
  OfflinePageLayout,
  StepperHeader,
  useScreenSizes,
  useTranslation,
  WalkthroughStepper
} from '@components/index';
import { HelpPointPositiontype } from '@ere-uilib/molecules/headers/NavigationHeader/interface';
import { DirectionEnum } from '@ere-uilib/molecules/steppers/InstallmentsStepper/interfaces';
import { constructStepperItems } from '@pages/Common/utils';

import { useStyles } from './useStyles';

interface Props {
  children: ReactNode;
  onPressCloseButton?: () => void;
  onPressBackButton?: () => void;
  activeStep: number;
  renderStickyMobileFooterContent?: () => ReactNode;
  helpPoint?: boolean;
  helpPointContentHtml?: string;
  helpPointModalTitle?: string;
  helpPointPosition?: HelpPointPositiontype;
  displayCloseButton?: boolean;
  displayLeftContent?: boolean;
  pageTitle?: string;
}

export function EERPageWrapperContainer({
  children,
  activeStep,
  onPressCloseButton,
  renderStickyMobileFooterContent,
  helpPoint = false,
  helpPointContentHtml,
  helpPointModalTitle,
  helpPointPosition,
  displayCloseButton = true,
  displayLeftContent = true,
  pageTitle
}: Props) {
  const { isDesktop } = useScreenSizes();
  const styles = useStyles();
  const { formatMessage } = useTranslation();

  const stepperItems = useMemo(
    () =>
      constructStepperItems({
        size: 2,
        direction: DirectionEnum.ROW,
        activeStep
      }),
    [activeStep]
  );

  const renderLegalMenuFooter = useCallback(() => {
    return <LegalMenuFooterConnected isWhite={isDesktop} />;
  }, [isDesktop]);

  const renderMobileFooterContent = useCallback(
    () => (
      <>
        {renderStickyMobileFooterContent?.()}
        {renderLegalMenuFooter()}
      </>
    ),
    [renderLegalMenuFooter, renderStickyMobileFooterContent]
  );

  const renderLeftMenu = useCallback(() => {
    return isDesktop ? <WalkthroughStepper stepOneDone={activeStep === 2} /> : null;
  }, [activeStep, isDesktop]);

  const renderMobileHeader = useCallback(() => {
    return (
      <StepperHeader
        displayCloseButton={displayCloseButton}
        helpPoint={helpPoint}
        helpPointContentHtml={helpPointContentHtml}
        helpPointModalTitle={helpPointModalTitle}
        helpPointPosition={helpPointPosition}
        onClose={onPressCloseButton}
        stepperItems={stepperItems}
        title={pageTitle || formatMessage({ id: 'EerSelfcareStep2VerifierIdentiteLabel' })}
      />
    );
  }, [formatMessage, stepperItems, onPressCloseButton]);

  return (
    <OfflinePageLayout
      displayLogo={isDesktop}
      onLogoPress={() => { }}
      renderDesktopCardBelowContent={renderLegalMenuFooter}
      renderDesktopCardLeftContent={displayLeftContent ? renderLeftMenu : undefined}
      renderMobileHeaderContent={displayLeftContent ? renderMobileHeader : undefined}
      renderMobileStickyGradientFooterContent={renderMobileFooterContent}>
      {children}
    </OfflinePageLayout>
  );
}
