import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useScreenSizes, useTheme } from '@ere-uilib/styles';

const MIN_HEIGHT = 800;
const SPACING_BOTTOM = 200;

export function useStyles() {
  const theme = useTheme();
  const { isDesktop, windowHeight } = useScreenSizes();

  const styles = useMemo(() => StyleSheet.create({
    scrollviewContent: {
      flex: 1,
      paddingVertical: theme.metrics.spacing[isDesktop ? 'xl' : 's']
    },
    contentContainer: {
      backgroundColor: theme.colors.basics.white,
      alignSelf: 'center',
      height: '100%',
      width: '100%',
      maxWidth: theme.metrics.contentSizes.centeredContentMaxWidth,
    },
    content: {
      paddingVertical: theme.metrics.spacing.none
    },
    htmlFrameStyle: {
      width: '100%',
      minHeight: Math.max(windowHeight, MIN_HEIGHT) + SPACING_BOTTOM,
      flex: 1
    },
  }), [theme, isDesktop, windowHeight]);

  return styles;
}
