import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects'

import { RouteNames } from '@constants/navigation'
import { getUsedCompanyId } from '@modules/dashboard/selectors'
import { runManager } from '@modules/moduleManager'
import { RootNavigation } from '@navigation/RootNavigation'

import { getRefundSelectedDispositif } from '../selectors'
import { fetchRefundRepartitionData, fetchRepaymentDemand } from '../services'
import * as RefundActions from './actions/refundActions'
import {
  CreateRepaymentRequestAction,
  GetRefundRepartitionRequestAction,
  RefundActionsType
} from './actions/refundActionsTypes'
import { getRefundRepartitionData as getRefundRepartitionDataFromState } from './selectors'
import { RefundRepartitionState, RepaymentDemandResponseState, RepaymentDemandState } from './types'
import { buildRepaymentDemandData } from './utils'

function* getRefundRepartitionData(action: GetRefundRepartitionRequestAction): any {
  // BASE IT ON GET INSTALLMENT REPARTITION DATA
  const companyId = yield select(getUsedCompanyId)

  // get installments decision selectedPlan
  const decisionPlans = yield select(getRefundSelectedDispositif)
  const selectedPlanId = decisionPlans && decisionPlans?.length && decisionPlans[0]?.id
  const response = yield call(fetchRefundRepartitionData, companyId, selectedPlanId)
  // uncomment to use mock
  // yield delay(1500);
  // const response = {
  //   data: mockRefundRepartition
  // }
  yield put(RefundActions.getRefundRepartitionSuccess(response?.data))
}

function* createRepaymentDemand(action: CreateRepaymentRequestAction): any {
  const companyId = yield select(getUsedCompanyId)
  const refundRepartition: RefundRepartitionState = yield select(getRefundRepartitionDataFromState)
  const repaymentDemandData: RepaymentDemandState = buildRepaymentDemandData(refundRepartition)
  const response = yield call(
    fetchRepaymentDemand,
    companyId,
    refundRepartition.planId,
    repaymentDemandData
  )
  yield put(RefundActions.createRepaymentDemandSuccess(response.data))
  const responseData: RepaymentDemandResponseState = response?.data

  if (responseData?.isSucceeded) {
    return yield RootNavigation.navigate(RouteNames.RefundsStack, {
      screen: RouteNames.RefundSuccess
    })
  }
  return yield RootNavigation.navigate(RouteNames.RefundsStack, {
    screen: RouteNames.RefundFailed
  })
}

function* getRefundDRepartitionSagas() {
  yield takeLatest(
    RefundActionsType.GET_REFUND_REPARTITION_REQUEST,
    runManager(getRefundRepartitionData, RefundActionsType.GET_REFUND_REPARTITION_FAILURE)
  )
}

function* createRepaymentDemandSagas() {
  yield takeLatest(
    RefundActionsType.CREATE_REPAYMENT_DEMAND_REQUEST,
    runManager(createRepaymentDemand, RefundActionsType.CREATE_REPAYMENT_DEMAND_FAILURE)
  )
}

export function* AvailableRefundSagas() {
  yield all([fork(getRefundDRepartitionSagas), fork(createRepaymentDemandSagas)])
}
