import { ATINTERNETROUTENAMECONFIG } from '@constants/atInternetConfig';
import { RouteNames } from '@constants/navigation';

export interface TrackingTag {
  name: string | undefined
  chapter1?: string | undefined
  chapter2?: string | undefined
}

export const manageExceptionRoutes = (
  route: any
): TrackingTag | undefined => {
  if (
    route !== RouteNames.ArbitrationOnboarding &&
    route !== RouteNames.ArbitrationPlanChoice
  ) {
    return {
      name: ATINTERNETROUTENAMECONFIG[route]?.name || route,
      chapter1: ATINTERNETROUTENAMECONFIG[route]?.chapter1 || 'non fourni',
      chapter2: ATINTERNETROUTENAMECONFIG[route]?.chapter2 || 'non fourni',
    };
  }
  return undefined;
};
