import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useScreenSizes, useTheme } from '@ere-uilib/styles';

export function useStyles() {
  const theme = useTheme();
  const { isMobile, isTablet } = useScreenSizes();
  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          backgroundColor: theme.colors.basics.white,
        },
        contentContainer: {
          marginHorizontal: !(isMobile || isTablet) ?
            theme.metrics.spacing.xhuge : theme.metrics.spacing.s,
        },
        actionButtonContainer: {
          flexDirection: 'row',
          marginTop: theme?.metrics.spacing.m
        },
        actionButtonStyle: {
          marginLeft: theme?.metrics.spacing.m
        },
        bottomText: {
          color: theme?.colors.basics.grey.c500,
          marginTop: theme?.metrics.spacing.m
        },
        retirementRecoverSavingPlanCardContainer: {
          marginBottom: theme?.metrics.spacing.xxxxl
        }
      }),
    [isMobile, isTablet, theme]
  );

  return styles;
}
