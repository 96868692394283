import {
  InvitationState,
  InvitationErrorState,
  InvitationIdentificationDataType,
  InvitationLetterOtpIdentificationDataType,
  RedirectionErrorState
} from '../types';

export enum InvitationActionsType {
  GET_INVITATION = 'GET_INVITATION',
  GET_INVITATION_REQUEST = 'GET_INVITATION_REQUEST',
  GET_INVITATION_SUCCESS = 'GET_INVITATION_SUCCESS',
  GET_INVITATION_FAILURE = 'GET_INVITATION_FAILURE',
  REDIRECTION_ERROR_REQUEST = 'REDIRECTION_ERROR_REQUEST',
  CREATE_SELFCARE_INVITATION_FROM_EXTERNAL_IDENTITY_REQUEST = 'CREATE_SELFCARE_INVITATION_FROM_EXTERNAL_IDENTITY_REQUEST',
  CREATE_SELFCARE_INVITATION_FROM_EXTERNAL_IDENTITY_SUCCESS = 'CREATE_SELFCARE_INVITATION_FROM_EXTERNAL_IDENTITY_SUCCESS',
  CREATE_SELFCARE_INVITATION_FROM_EXTERNAL_IDENTITY_FAILURE = 'CREATE_SELFCARE_INVITATION_FROM_EXTERNAL_IDENTITY_FAILURE',
  CREATE_SELFCARE_INVITATION_FROM_LETTER_OTP_REQUEST = 'CREATE_SELFCARE_INVITATION_FROM_LETTER_OTP_REQUEST',
  CREATE_SELFCARE_INVITATION_FROM_LETTER_OTP_SUCCESS = 'CREATE_SELFCARE_INVITATION_FROM_LETTER_OTP_SUCCESS',
  CREATE_SELFCARE_INVITATION_FROM_LETTER_OTP_FAILURE = 'CREATE_SELFCARE_INVITATION_FROM_LETTER_OTP_FAILURE',
  CREATE_SELFCARE_INVITATION_RESET_ERROR = 'CREATE_SELFCARE_INVITATION_RESET_ERROR'
}

export interface GetInvitationAction {
  type: InvitationActionsType.GET_INVITATION
}

export interface GetInvitationRequestAction {
  type: InvitationActionsType.GET_INVITATION_REQUEST
  token: string
}

export interface GetInvitationSuccessAction {
  type: InvitationActionsType.GET_INVITATION_SUCCESS
  data: InvitationState;
}

export interface GetInvitationFailureAction {
  type: InvitationActionsType.GET_INVITATION_FAILURE
  error: InvitationErrorState;
}

// REDIRECTION ERROR

export interface RedirectionErrorAction {
  type: InvitationActionsType.REDIRECTION_ERROR_REQUEST
  redirectionError: RedirectionErrorState
}

// Selfcare invitation request
export interface CreateSelfcareInvitationFromExternalIdentityRequestAction {
  type: InvitationActionsType.CREATE_SELFCARE_INVITATION_FROM_EXTERNAL_IDENTITY_REQUEST,
  identificationData: InvitationIdentificationDataType
}

export interface CreateSelfcareInvitationFromExternalIdentitySuccessAction {
  type: InvitationActionsType.CREATE_SELFCARE_INVITATION_FROM_EXTERNAL_IDENTITY_SUCCESS
  email: string
}

export interface CreateSelfcareInvitationFromExternalIdentityFailureAction {
  type: InvitationActionsType.CREATE_SELFCARE_INVITATION_FROM_EXTERNAL_IDENTITY_FAILURE
  error: InvitationErrorState;
}

// Selfcare invitation request letter otp
export interface CreateSelfcareInvitationFromLetterOtpRequestAction {
    type: InvitationActionsType.CREATE_SELFCARE_INVITATION_FROM_LETTER_OTP_REQUEST,
    identificationData: InvitationLetterOtpIdentificationDataType
  }

export interface CreateSelfcareInvitationFromLetterOtpSuccessAction {
    type: InvitationActionsType.CREATE_SELFCARE_INVITATION_FROM_LETTER_OTP_SUCCESS
    data: InvitationState
  }

export interface CreateSelfcareInvitationFromLetterOtpFailureAction {
  type: InvitationActionsType.CREATE_SELFCARE_INVITATION_FROM_LETTER_OTP_FAILURE
  error: InvitationErrorState;
}

export interface CreateSelfcareInvitationResetErrorAction {
  type: InvitationActionsType.CREATE_SELFCARE_INVITATION_RESET_ERROR
}

export type InvitationAction =
  | GetInvitationAction
  | GetInvitationRequestAction
  | GetInvitationSuccessAction
  | GetInvitationFailureAction
  | RedirectionErrorAction
  | CreateSelfcareInvitationFromExternalIdentityRequestAction
  | CreateSelfcareInvitationFromExternalIdentitySuccessAction
  | CreateSelfcareInvitationFromExternalIdentityFailureAction
  | CreateSelfcareInvitationFromLetterOtpRequestAction
  | CreateSelfcareInvitationFromLetterOtpSuccessAction
  | CreateSelfcareInvitationFromLetterOtpFailureAction
  | CreateSelfcareInvitationResetErrorAction
