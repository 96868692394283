import { useMemo } from "react";
import { StyleSheet } from "react-native";

import { useTheme } from "@ere-uilib/styles";

export const useStyles = () => {
  const theme = useTheme();

  const styles = useMemo(() => StyleSheet.create({
    userInformationContainer:
      {
        marginLeft: theme.metrics.spacing.xm,
        marginBottom: theme.metrics.spacing.xxxxl,
      },
    userInformationTitle:
      {
        color: theme.colors.basics.black,
      },
    userInformationSubtitle:
      {
        color: theme.colors.basics.black,
        marginBottom: theme.metrics.spacing.xs,
      },
    deconnectionButton:
      {
        color: theme.colors.buttons.classic.c500,
        textDecorationLine: "underline",
      },
  }), [theme]);

  return styles;
};