import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DocumentsActionsType } from '@modules/document-space/actions/documentsActionsTypes';
import { getLoadingState } from '@modules/funds/selectors';
import {
  getProfileAddressesRequest,
  getProfileUserInfosRequest
} from '@modules/profile/actions/profileActions';
import { ProfileActionsType } from '@modules/profile/actions/profileActionsTypes';
import { getProfileAddresses, getProfileUserInfos } from '@modules/profile/selectors';

import { EditProfileInformationsPageComponent } from './EditProfileInformationsPage.component';

export const EditProfileInformationsPage: React.FC = () => {
  const dispatch = useDispatch();

  const profileUserInformations = useSelector(getProfileUserInfos);
  const profileAddresses = useSelector(getProfileAddresses);
  const isLoading1 = useSelector(getLoadingState(ProfileActionsType.GET_PROFILE_USER_INFOS_REQUEST));
  const isLoading2 =  useSelector(getLoadingState(ProfileActionsType.GET_PROFILE_ADDRESSES_REQUEST));
  const isLoading3 =  useSelector(getLoadingState(DocumentsActionsType.GET_DOCUMENT_BLOB_RESPONSE_REQUEST));
  const isLoading = isLoading1 || isLoading2 || isLoading3;

  const onGetProfileUserInfosRequest = () =>  dispatch(getProfileUserInfosRequest());
  const onGetProfileAddressesRequest =() =>  dispatch(getProfileAddressesRequest());

  return (
      <EditProfileInformationsPageComponent
          isLoading={isLoading}
          onGetProfileAddressesRequest={onGetProfileAddressesRequest}
          onGetProfileUserInfosRequest={onGetProfileUserInfosRequest}
          profileAddresses={profileAddresses}
          profileUserInformations={profileUserInformations}
      />
  );
};
