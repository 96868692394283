import { useNavigation } from '@react-navigation/native';
import React, { useCallback, useMemo, useState } from 'react';
import { View } from 'react-native';
import { useSelector } from "react-redux";

import {
  DashboardPageWrapperConnected,
  useTranslation,
  ColumnsContainer,
  SimpleButton,
  Title,
  TextField,
  useScreenSizes,
  IconEnum,
  validateBankDetails,
  CheckBox
} from '@components/index';
import { PlanFamillyList } from '@constants/index';
import { DirectionEnum } from '@ere-uilib/molecules/steppers/InstallmentsStepper/interfaces';
import { getBankDataLoadingState, getBankLoadingState } from "@modules/bank-details/selectors";
import { constructStepperItems } from '@pages/Common/utils';

import {
  BankDataHeader,
  BankDetailsSuccessOrErrorPopin,
  BankDetailsErrorPopin
} from '../components';
import { IBAN, BIC } from './constants';
import { Props } from './interfaces';
import { useStyles } from './useStyles';

export const IbanFormComponent: React.FC<Props> = ({
  onRegister,
  onGetInitData,
  selectedAccountType,
  selectedPlanFamily,
  updateStatus
}) => {
  const navigation = useNavigation();
  const { formatMessage } = useTranslation();
  const styles = useStyles();
  const [iban, setIban] = useState('');
  const [bic, setBic] = useState('');
  const [isAcknowledgmentChecked, setIsAcknowledgmentChecked] = useState(false);
  const isBankLoading = useSelector(getBankLoadingState)
  const isBankDataLoading =  useSelector(getBankDataLoadingState)
  const isLoading =  isBankLoading || isBankDataLoading;
  const activeStep = PlanFamillyList.RC === selectedPlanFamily ? 2 : 3;
  const size = PlanFamillyList.RC === selectedPlanFamily ? 3 : 4;
  const stepperItems = constructStepperItems({
    size,
    direction: DirectionEnum.ROW,
    activeStep
  });

  const { isMobile, isTablet } = useScreenSizes();
  const {
    isValidIBAN,
    isValidBIC: isAllowedBIC,
    isSEPACountry,
    IBANCountryCode
  } = useMemo(() => validateBankDetails(iban, bic), [bic, iban]);

  const isAllowedIBAN = useMemo(() => isValidIBAN && isSEPACountry, [isSEPACountry, isValidIBAN]);

  const isButtonDisabled = useMemo(
    () => !isAllowedIBAN || !isAllowedBIC || !isAcknowledgmentChecked,
    [isAllowedBIC, isAllowedIBAN, isAcknowledgmentChecked]
  );

  const handleNextButton = useCallback(() => {
    const listMapping = {
      [PlanFamillyList.ES]: onRegister,
      [PlanFamillyList.RC]: onGetInitData
    };
    listMapping[selectedPlanFamily ?? PlanFamillyList.ES]?.(iban, bic);

  }, [bic, iban, onRegister, onGetInitData, selectedPlanFamily]);

  const renderBottomActions = useCallback(
    () => (
      <View style={styles.bottomActionsStyle}>
        <View style={styles.bottomActionsButtonsContainerStyle}>
          <SimpleButton
            design="outlined"
            leftIcon={IconEnum.CHEVRON_LEFT}
            onPress={() => navigation.goBack()}
          />
          <SimpleButton
            containerStyle={styles.simpleButtonStyle}
            design="solid"
            disabled={isButtonDisabled}
            loading={isLoading}
            onPress={handleNextButton}
            size="small"
            title={formatMessage({ id: 'Profil_email_edit_confirm_button' })}
          />
        </View>
      </View>
    ),
    [styles, navigation, isLoading, formatMessage, isButtonDisabled, handleNextButton]
  );

  const handleChangeIban = useCallback((ibanValue: string) => {
    setIban(ibanValue.toUpperCase());
  }, []);

  const handleChangeBic = useCallback((bicValue: string) => {
    setBic(bicValue.toUpperCase());
  }, []);

  const RenderHeader = useCallback(() => {
    return (
      <BankDataHeader
        displayBackButton
        displayCloseButton
        helpPoint={false}
        stepperItems={stepperItems}
        title={formatMessage({ id: `CoordonnesBancairesHeaderTitle${selectedAccountType}` })}
      />
    );
  }, []);

  return (
    <DashboardPageWrapperConnected renderStickyMobileBottom={() => renderBottomActions()}>
      <ColumnsContainer
        hasRenderRightDesktopColumn={false}
        renderHeader={RenderHeader}>
        <View style={styles.containerStyle}>
          <Title
            variant="t4"
            weight="bold">
            {formatMessage({ id: 'CoordonneesBancairesPopinChoixSaisieTitre' })}
          </Title>
          <View style={styles.fieldContainer}>
            <TextField
              errored={!!iban && !isAllowedIBAN}
              errorMessage={formatMessage({
                id: IBAN.getErrorMessageId({ IBANCountryCode, isValidIBAN, isSEPACountry })
              })}
              inputProps={{
                helpText: IBAN.placeholder,
                placeholder: formatMessage({ id: 'CoordonneesBancairesSaisieIBANZoneLabel' }),
                value: iban,
                onChangeText: handleChangeIban,
                mask: IBAN.mask
              }}
              labelOnTop
            />
          </View>
          <View style={styles.fieldContainer}>
            <TextField
              errored={!!bic && !isAllowedBIC}
              errorMessage={formatMessage({ id: BIC.getErrorMessageId() })}
              inputProps={{
                helpText: BIC.placeholder,
                placeholder: formatMessage({ id: 'CoordonneesBancairesSaisieBICZoneLabel' }),
                value: bic,
                onChangeText: handleChangeBic,
                mask: BIC.mask
              }}
              labelOnTop
            />
          </View>
          <CheckBox
            containerStyle={styles.fieldContainer}
            label={formatMessage({ id: 'CoordonneesBancairesCocheTitulaire' })}
            onPress={() => setIsAcknowledgmentChecked(current => !current)}
            value={isAcknowledgmentChecked}
          />
          {!isMobile && !isTablet && renderBottomActions()}
        </View>
        <BankDetailsSuccessOrErrorPopin
          isModalVisible={!!updateStatus }
          quickSignEnumMember={
            updateStatus
          }
        />
        <BankDetailsErrorPopin />
      </ColumnsContainer>
    </DashboardPageWrapperConnected>
  );
};
