import React, { useState, useEffect, useCallback } from 'react';
import { View } from 'react-native';

import { Text, Link, Spinner } from '@ere-uilib/atoms';
import { NotificationSoft } from '@ere-uilib/molecules';
import { OtpFields } from '@ere-uilib/molecules/inputs/OtpFields';
import { createUseStyles, useScreenSizes, useTheme } from '@ere-uilib/styles';

import { ValidationCodeProps, ValidationStyles } from './interface';
import { getStyles } from './styles';

export const ValidationCode: React.FC<React.PropsWithChildren<ValidationCodeProps>> = ({ containerStyle,
  inputStyle,
  subtitleStyle,
  inputsContainerStyle,
  disabled = false,
  outlined = false,
  errored = false,
  type = 'l',
  isCodeSent, //voir comment implémenter ça proprement (vider les input quand on valide l'otp et qu'on naviguent)
  setDisabled,
  onSubmit,
  description,
  infoNotificationText,
  setOtpPlateformeCode,
  resetOTP,
  retryProps,
}: ValidationCodeProps) => {

  const isMobile = useScreenSizes().isMobile;
  const isTablet = useScreenSizes().isTablet;
  const theme = useTheme();

  const allStyles: ValidationStyles = {
    containerStyle,
    inputStyle,
    subtitleStyle,
    inputsContainerStyle,
  };
  const styles = useStyles(
    { type, disabled, outlined, theme, isMobile: isMobile && isTablet },
    allStyles,
  );

  const [code, setCode] = useState(['', '', '', '', '', '']);
  // voir comment implémenter ça proprement (vider les input quand on valide l'otp et qu'on naviguent)

  useEffect(() => {
    isCodeSent && setCode(() => ['', '', '', '', '', '']);
  }, [isCodeSent]);

  useEffect(()=>{
    setCode(() => ['', '', '', '', '', '']);
  }, []);

  useEffect(()=>{
    setCode(() => ['', '', '', '', '', '']);
  }, [resetOTP]);

  useEffect(() => {
    setDisabled && setDisabled(code.join('').trim().length < 6);
    setOtpPlateformeCode && setOtpPlateformeCode(code.join('').trim());
  }, [code, setDisabled, setOtpPlateformeCode]);

  const handleChange = (value: string[]): void => {
    setCode(value);
  };

  const handleSubmit = useCallback(()=> {
    if (code[5] !== '' && onSubmit) {
      onSubmit();
    }
  }, [code, onSubmit]);

  return (
    <View style={styles.containerStyle}>
      <View>
        <Text style={styles.subtitleStyle}>{description}</Text>
        {infoNotificationText && <NotificationSoft text={infoNotificationText} />}
      </View>
      <View style={styles.inputsContainerStyle}>
        <OtpFields
          code={code}
          hasError={errored}
          onChange={handleChange}
          onSubmit={handleSubmit}
        />
        {retryProps && (
          <View style={styles.retryLinkContainer}>
            {retryProps.isLoading ? (
              <Spinner/>
            ) : (
              <Text
                style={styles.retryLink}
                variant={'t3'}
              >
                <Link onPress={retryProps.onPress} >
                  {retryProps.label}
                </Link>
              </Text>
            )}
          </View>
        )}
      </View>
    </View>
  );
};

const useStyles = createUseStyles(getStyles);
