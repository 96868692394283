import { useMemo } from "react"
import { StyleSheet } from 'react-native';

import { useTheme } from "@ere-uilib/styles";

export const useStyles = () => {
  const theme = useTheme();

  const styles = useMemo(() => StyleSheet.create({
    HelpModalGlobalContainerStyle: {
      flex: 1
    },
    iconContainerStyle: {
      marginBottom: theme.metrics.spacing.xm,
      marginTop: theme.metrics.spacing.l,
      alignSelf: 'center'
    },
    allTextsStyle: {
      textAlign: 'center',
      marginBottom: theme.metrics.spacing.s
    },
  }), [theme])

  return styles;
}
