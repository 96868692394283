import React, { useState } from 'react';
import { View } from 'react-native';

import { RadiosButtonList, Table, TableCellInterface, Text, useTranslation } from '@components/index';
import { OptionsState } from '@ere-uilib/organisms/inputs/RadiosButtonList/interfaces';

import { PerformanceTypeEnum } from '../../sharedInterfaces';
import { useStyles } from './useStyles'

export interface PerformanceTabsType extends OptionsState {
  isMorningStarFund: boolean
  legend?: string
  tableData: TableCellInterface[][]
}
export interface PerformanceTemplateProps {
  performanceTabs: PerformanceTabsType[],
  performanceTableHeader: TableCellInterface[],
}

export const PerformanceTemplate: React.FC<React.PropsWithChildren<PerformanceTemplateProps>> = ({
  children,
  performanceTabs,
  performanceTableHeader
}) => {
  const styles = useStyles();
  const [activeTab, setActiveTab] = useState<PerformanceTypeEnum>(PerformanceTypeEnum.CUMULATED);
  const { formatMessage } = useTranslation();
  const changeSelectedTab = (selectedTab: string) => {
    const isSelectedTabValide = Object.values<string>(PerformanceTypeEnum).includes(selectedTab)
    if (isSelectedTabValide) {
      setActiveTab(selectedTab as PerformanceTypeEnum)
    }
  }

  const PerformanceBody = () => {
    const selectedtableDataTab = performanceTabs?.find(performanceTab => performanceTab.value === activeTab)
    return (
      <View style={styles.tableContainer}>
        {selectedtableDataTab && (
          <Table
            data={[
              [...performanceTableHeader],
              ...selectedtableDataTab?.tableData
            ]}
          />
        )}
        {selectedtableDataTab?.legend && (
          <Text
            style={styles.labelLegend}
            variant="t3"
            weight="light">
            {selectedtableDataTab?.legend}
          </Text>
        )}
        <Text
          style={styles.labelNetValuation}
          variant="t3">
          {formatMessage({
            id: 'Fundsheet_Tab3Perf_Warning_net_valuation_legend',
          })}
        </Text>
        <Text
          style={styles.labelPastPerformance}
          variant="t3"
          weight="light">
          {formatMessage({
            id: 'Fundsheet_Tab3Perf_Warning_past_performances_legend',
          })}
        </Text>
        <Text
          variant="t3"
          weight="light">
          {selectedtableDataTab?.isMorningStarFund
            ? formatMessage({
              id: 'Fundsheet_Tab3Perf_Sourcing_Morningstar_legend',
            })
            : formatMessage({
              id: 'Fundsheet_Tab3Perf_Sourcing_BNPPariabs_AM_legend',
            })}
        </Text>
      </View>
    );
  };
  return (
    <View style={styles.container}>
      <RadiosButtonList
        activeRadio={activeTab as string}
        containerStyle={styles.radioButtonsListContainer}
        flexDirection={"row"}
        onChange={changeSelectedTab}
        options={performanceTabs}
      />
      <PerformanceBody />
      {children}
    </View>
  );
};