import { PIANO_COLLECTION_DOMAIN, SITE_ID } from "@config/index"
import { TrackingTag } from "@utils/manageExceptionRoutes";

export const useTracking = (sentTag: TrackingTag, token: string) => {
  return fetch(`${PIANO_COLLECTION_DOMAIN}/event?s=${SITE_ID}&idclient=${token}`, {
    method: "POST",
    body: JSON.stringify({
      events: [
        {
          name: `page.display`,
          data: {
            page: sentTag?.name,
            page_chapter1: sentTag?.chapter1,
            page_chapter2: sentTag?.chapter2
          }
        }
      ]
    })
  })
}
