import { RetirementPlanDocumentType } from "@modules/retirement/types"

export enum FormTypeEnum {
  Form = 'Form',
  Document = 'Document'
}

export enum FormTypeConditionEnum {
  Asset = "ASSET",
  Pension = "PENSION",
  AssetOrPension = "ASSET_OR_PENSION",
  PensionAndAmount = "PENSION_AND_AMOUNT",
}

export interface categoryByDocumentsType {
  categoryId: string
  documents: string
}

export interface recognizedType {
  categories: recognizedCategoryType[]
  documents: string
}

export interface recognizedCategoryType {
  categoryId: string
}
export interface tempObjtType {
  [key: string]: {
    categoryId: string
  }[]
}

export interface Props {
  RetirementPlanDocument: RetirementPlanDocumentType
  onSubmit: (documentType: string, documentTitle: string) => void
  isLoading: boolean
  isLoadingBulletin: boolean
  shouldDisplayAnnuityCard: boolean
  setIsEsPacteFile: (isPacte: boolean) => void
  setIsLinkPressed: (isLink: boolean) => void
}
