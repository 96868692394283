import { useMemo } from 'react';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

export const useStyles = (containerStyle: StyleProp<ViewStyle>) => {
  const theme = useTheme();

  const styles = useMemo(
    () =>
      StyleSheet.create({
        containerStyle: {
          flexDirection: 'column',
          marginTop: theme.metrics.spacing.xm,
          ...StyleSheet.flatten(containerStyle)
        },

        graphContainer: {
          flexDirection: 'row',
          height: 15,
          borderRadius: 15
        },
        innerContainer: {
          zIndex: 5
        },
        repartitionDetailsContainer: {
          flexDirection: 'row',
          alignItems: 'stretch'
        },
        repartitionDetails: {
          flexDirection: 'column'
        },
        title: {
          fontSize: theme.fonts.fontSize.paragraph.t4,
          alignSelf: 'center',
          textAlign: 'center',
          backgroundColor: 'transparent'
        },
        dashlineContainer: {
          backgroundColor: 'transparent',
          justifyContent: 'space-between'
          //flex: 1
        }
      }),
    [theme, containerStyle]
  );

  return styles;
};
