import { DocumentApiFile, DocumentCategory, DocumentFile, EDocuments } from '@modules/document-space/types';

export const mockEDocuments: EDocuments = {
  eDocuments: [...Array(10)].map((_, index) => ({
    id: (index + 1).toString(),
    language: 'FR',
    typeLabel: `Document ${index + 1}`,
    keyLabel: 'Source: BNP Paribas Asset Management',
    creationDate: `20${10 + index}-01-11T00:00:00.000Z`
  })),
  isSubscriber: false
}

export const mockDocumentsCategories: DocumentCategory[] = [
  {
    id: "1",
    name: "Formulaires de demande"
  },
  {
    id: "2",
    name: "Guides pédagogiques"
  },
  {
    id: "3",
    name: "Types de gestion"
  },
  {
    id: "4",
    name: "Documents liés à l'entreprise"
  },
  {
    id: "5",
    name: "Mes supports de placements",
  },
  {
    id: "6",
    name: "Cas de déblocage",
  },
  {
    id: "7",
    name: "Guide de sortie",
  },
  {
    id: "8",
    name: "Départ à la retraite",
  }
];

export const mockDocumentsFiles: DocumentFile[] = [
  {
    id: '1',
    name: 'Document 1',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    url: '',
    size: 88,
    categories: ['1', '3', '4']
  },
  {
    id: '2',
    name: 'Document 2',
    description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium',
    url: '',
    size: 62,
    categories: ['3', '2']
  },
  {
    id: '3',
    name: 'Document 3',
    description: 'Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur',
    url: '',
    size: 108,
    categories: ['1', '2']
  },
  {
    id: '4',
    name: 'Document 4',
    description: 'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti',
    url: '',
    size: 254,
    categories: ['1', '4']
  },
  {
    id: '5',
    name: 'Document 5',
    description: 'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod',
    url: '',
    size: 85,
    categories: ['4', '5']
  },
  {
    id: '6',
    name: 'Document 6',
    description: 'Ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat.',
    url: '',
    size: 155,
    categories: ['3', '2']
  },
  {
    id: '7',
    name: 'Document 7',
    description: 'Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe',
    url: '',
    size: 103,
    categories: ['3', '2']
  }
];

export const mockDocumentsFilesApi: DocumentApiFile[] = [
  {
    documentName: 'Document1',
    documentTitle: 'Document 1',
    documentType: 'Brochure',
    documentSubTitle: 'Description du Document 1',
    searchTags: [
      'Categorie1', 'Categorie2'
    ]
  },
  {
    documentName: 'Document2',
    documentTitle: 'Document2',
    documentType: 'Brochure',
    documentSubTitle: 'Description du Document 2',
    searchTags: [
      'Categorie2'
    ]
  },
  {
    documentName: 'Document3',
    documentTitle: 'Document3',
    documentSubTitle: 'Description du Document 3',
    documentType: 'Brochure',
    searchTags: [
      'Categorie1'
    ]
  },
  {
    documentName: 'Document4',
    documentTitle: 'Document4',
    documentType: 'Brochure',
    documentSubTitle: 'Description du Document 4',
    searchTags: [
      'Categorie3'
    ]
  }
]
