import { palette } from '@ere-uilib/styles/theme/themeFolder/colors/palette';

import { Context, HistoryStyle } from './interface';

export const getStyles = (context?: Context, style?: HistoryStyle): HistoryStyle => ({
  container: [
    {
      flex: 1,
      backgroundColor: context?.theme.colors.background.default.c500,
      alignSelf: 'stretch',
      zIndex: 1
    },
    style?.container
  ],
  innerContainer: [
    { flex: 1 },
    (context?.isMobile || context?.isTablet) && {
      height: context?.windowHeight
    },
    style?.container
  ],
  tabsContainer: [
    {
      backgroundColor: context?.theme.colors.basics.white
    },
    style?.container
  ],
  fullWidthContainer: [
    {
      flex: 1,
      paddingBottom: context?.theme.metrics.spacing.huge
    },
    style?.fullWidthContainer
  ],
  title: [
    {
      marginVertical: context?.theme.metrics.spacing.m,
      textAlign: 'center'
    }
  ],
  placeHolderMainTitle: {
    width: '20%',
    marginLeft: context?.theme.metrics.spacing.xl
  },
  placeHolderCard: {
    backgroundColor: 'white',
    borderRadius: 5,
    paddingHorizontal: context?.theme.metrics.spacing.s,
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowRadius: context?.theme.metrics.spacing.s,
    shadowColor: palette.blackTransparent.c100,
    elevation: 1
  },
  placeHolderCardTitleContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center'
  },
  placeHolderCardTitle: {
    width: '40%',
    marginLeft: context?.theme.metrics.spacing.s
  },
  placeHolderCardContainer: {
    flex: 1,
    flexWrap: 'wrap',
    alignContent: 'space-between'
  },
  placeHolderCardContentTitle: {
    width: '40%',
    marginTop: context?.theme.metrics.spacing.s
  },
  RetryActionPlaceHolderContainerStyle: {
    margin: context?.theme.metrics.spacing.s
  }
});
