import { useMemo } from 'react'
import { StyleSheet } from 'react-native'

import { useTheme } from '@ere-uilib/styles'

export const useStyles = (showAvailabilities: boolean, isDisabled?: boolean | undefined) => {
  const theme = useTheme()

  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          borderBottomWidth: 1,
          paddingTop: theme.metrics.spacing.xm,
          paddingLeft: theme.metrics.spacing.s,
          paddingRight: theme.metrics.spacing.s,
          backgroundColor: isDisabled ? theme.colors.basics.grey.c100 : undefined,
          borderBottomColor: isDisabled
            ? theme.colors.basics.grey.c200
            : theme.colors.basics.grey.c100
        },
        dueDateTitle: {
          marginBottom: theme.metrics.spacing.s
        },
        toggleContainer: {
          marginTop: theme.metrics.spacing.xs,
          marginBottom: showAvailabilities ? 0 : theme.metrics.spacing.m
        },
        disabledSupportText: {
          color: theme.colors.basics.grey.c500,
          marginBottom: theme.metrics.spacing.m
        }
      }),
    [theme, showAvailabilities, isDisabled]
  )

  return styles
}
