import { Context, Styles } from './interfaces';

export const getStyles = (context: Context, style: Styles): Styles => ({
  containerStyle: [
    {
      flex: 1,
    },
    style?.containerStyle,
  ],
  headerContainerStyle: [
    {
      flex: 1,
      paddingHorizontal: context?.theme.metrics.spacing.s,
      paddingVertical: context?.theme.metrics.spacing.none,
    },
    context?.backGroundColorCustom
      ? {
        backgroundColor: context?.backGroundColorCustom,
      }
      : {
        backgroundColor: context?.theme.colors.basics.grey.c100,
      },
    style?.headerContainerStyle,
  ],

  headerTopAlignement: [
    {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    style?.headerTopAlignement,
  ],
  headerTitleAlignement: [
    {
      flexDirection: 'row',
    },
    style?.headerTitleAlignement,
  ],
  compartmentContainerStyle: [
    {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: context?.theme.metrics.spacing.s,
      marginTop: context?.theme.metrics.spacing.m,
    },
    style?.compartmentContainerStyle,
  ],
  compartmentTitleStyle: [
    {
      color: 'white',
    },
    style?.compartmentTitleStyle],
  helpPointStyle: [
    {
      paddingRight: context?.theme.metrics.spacing.s,
    },
    style?.helpPointStyle,
  ],
  DetailValueStyle: [
    {
      minWidth: 50,
      textAlign: 'right',
      marginLeft: context?.theme.metrics.spacing.s,
    },
    style?.DetailValueStyle,
  ],
  amountTextStyle: [
    {
      textAlign: 'right',
      color: 'white',

    },
    style?.amountTextStyle,
  ],

});
