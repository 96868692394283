import { useMemo } from 'react'
import { StyleSheet } from 'react-native'

import { useScreenSizes, useTheme } from '@ere-uilib/styles'

export const useStyles = () => {
  const theme = useTheme()
  const { isMobile } = useScreenSizes()

  const styles = useMemo(
    () =>
      StyleSheet.create({
        pageWrapper: {
          backgroundColor: theme?.colors.basics.white
        },
        textStyle: {
          textAlign: 'center',
          padding: theme.metrics.spacing.xs
        },
        contentContainer: {
          flex: 1,
          flexDirection: 'column',
          width: isMobile ? '90%' : '60%',
          alignSelf: 'center'
        },
        svgContainer: {
          alignSelf: 'center',
          width: 400,
          height: 200,
          padding: theme.metrics.spacing.m
        },
        contentTitle: {
          textAlign: 'center',
          padding: theme.metrics.spacing.m
        },
        button: {
          paddingVertical: theme.metrics.spacing.xm
        },
        loader: {
          flex: 1,
          padding: theme.metrics.spacing.xs,
          margin: theme.metrics.spacing.xs
        },
        loaderContainerStyle: {
          borderColor: theme.colors.basics.grey.c300,
          borderWidth: 1,
          borderRadius: theme.metrics.spacing.xs,
          padding: theme.metrics.spacing.s,
          margin: theme.metrics.spacing.s
        }
      }),
    [isMobile, theme]
  )

  return styles
}
