import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

export function useStyles() {
  const theme = useTheme();

  const styles = useMemo(() => StyleSheet.create({
    bankAccountContainer: {
      marginLeft: theme.metrics.spacing.s
    },
    paymentDetailContainer: {
      marginTop: theme.metrics.spacing.s,
    },
    notificationHard: {
      alignSelf: 'stretch'
    },
    operationSummary: { textAlign: 'left', marginBottom: theme.metrics.spacing.s  },
    operationAbundanceTitle: { textAlign: 'left', marginBottom: theme.metrics.spacing.s, marginTop: theme.metrics.spacing.l   },
  }), [theme]);

  return styles;
}
