import { useNavigation } from '@react-navigation/native'
import React, { useCallback, useMemo, useState } from 'react'
import { ScrollView, View } from 'react-native'

import {
  ColumnsContainer,
  DashboardPageWrapperConnected,
  FilePicker,
  FilesCategoriesEnum,
  RadiosButtonList,
  SimpleButton,
  Text,
  Title,
  useTranslation
} from '@components/index'
import { RouteNames } from '@constants/navigation'
import { PlanFamillyList } from '@constants/index'
import { IconEnum } from '@ere-uilib/enums'
import { DirectionEnum } from '@ere-uilib/molecules/steppers/InstallmentsStepper/interfaces'
import { OptionsState } from '@ere-uilib/organisms/inputs/RadiosButtonList/interfaces'
import { useScreenSizes } from '@ere-uilib/styles'
import { constructStepperItems } from '@pages/Common/utils'
import { FileData } from "@modules/common/types";

import { BankDataHeader } from '../components'
import { DocumentTypeOptionValue, FILE_PICKER_DATA } from './constants'
import { Props } from './interfaces'
import { useDictionary } from './useDictionary'
import { useStyles } from './useStyles'

export const DocumentUploadComponent: React.FC<Props> = ({
  fileMaxSize = 0,
  document,
  onUploadDocument,
  onRemoveDocument,
  selectedAccountType,
  selectedPlanFamily
}) => {
  const { formatMessage } = useTranslation()
  const navigation = useNavigation()
  const { isDesktop } = useScreenSizes()
  const styles = useStyles()
  const dictionary = useDictionary(fileMaxSize)
  const size = selectedPlanFamily === PlanFamillyList.RC ? 3 : 4
  const [selectedOption, setSelectedOption] = useState<string>(DocumentTypeOptionValue.ID_CARD)

  const checkError = useCallback(
    (documentKey: string) => !!document[documentKey]?.hasError,
    [document]
  )

  const isButtonDisabled = useMemo(() => {
    const isIDDone = FILE_PICKER_DATA[selectedOption].reduce((isValid, { documentKey }) => {
      return isValid && !!document[documentKey]?.file
    }, true)

    const isIBANDone = !!document[FilesCategoriesEnum.IBAN]?.file

    return (selectedPlanFamily === PlanFamillyList.ES && !isIDDone) || !isIBANDone
  }, [document, selectedOption, selectedPlanFamily])

  const handleRemove = useCallback(
    (documentKey: string) => onRemoveDocument(documentKey),
    [onRemoveDocument]
  )

  const handleSelectOption = useCallback(
    (nextOption: string) => {
      if (!nextOption) return
      setSelectedOption(previousOption => {
        FILE_PICKER_DATA[previousOption].map(({ documentKey }) => handleRemove(documentKey))
        return nextOption
      })
    },
    [handleRemove]
  )

  const optionsForRadiosButtonList = useMemo<OptionsState[]>(
    () => [
      {
        value: DocumentTypeOptionValue.ID_CARD,
        label: formatMessage({ id: 'CoordonneesBancairesUploadIDLabel' }),
        disabled: false
      },
      {
        value: DocumentTypeOptionValue.PASSPORT,
        label: formatMessage({ id: 'CoordonneesBancairesUploadPasseportLabel' }),
        disabled: false
      },
      {
        value: DocumentTypeOptionValue.RESIDENCE_PERMIT,
        label: formatMessage({ id: 'CoordonneesBancairesUploadTitreSejourLabel' }),
        disabled: false
      }
    ],
    [formatMessage]
  )

  const handleNextButton = useCallback(() => {
    navigation.navigate(RouteNames.BankDetailsStack, {
      screen: RouteNames.BankSetIban
    })
  }, [navigation])

  const stepperItems = constructStepperItems({
    size,
    direction: DirectionEnum.ROW,
    activeStep: 1
  })

  const renderBottomActions = useCallback(
    () => (
      <View style={styles.bottomActionsContainer}>
        <SimpleButton
          design="outlined"
          leftIcon={IconEnum.CHEVRON_LEFT}
          onPress={() => navigation.goBack()}
        />
        <SimpleButton
          containerStyle={styles.bottomActionButtonNext}
          design="solid"
          disabled={isButtonDisabled}
          onPress={handleNextButton}
          size="small"
          title={formatMessage({ id: 'Profil_email_edit_confirm_button' })}
        />
      </View>
    ),
    [styles, isButtonDisabled, handleNextButton, formatMessage, navigation]
  )

  const RenderHeader = () => {
    return (
      <BankDataHeader
        displayBackButton
        displayCloseButton
        helpPoint={false}
        stepperItems={stepperItems}
        title={formatMessage({ id: `CoordonnesBancairesHeaderTitle${selectedAccountType}` })}
      />
    )
  }

  const renderFilePickers = useCallback(
    (option: string) =>
      FILE_PICKER_DATA[option].map(({ documentKey, loadingTitleId, titleId }, index, array) => {
        const { isLoading = false, file } = document[documentKey] ?? {}

        const buttonTitle = formatMessage({ id: titleId })
        const loadingTitle = formatMessage({ id: loadingTitleId })
        const isLastIndex = index === array.length - 1

        const handleAdd = (file: FileData) => onUploadDocument(documentKey, file)

        return (
          <FilePicker
            containerStyle={!isLastIndex && styles.filePicker}
            data={file}
            dictionary={{
              ...dictionary,
              buttonTitle,
              loadingTitle
            }}
            fileType=""
            hasError={checkError(documentKey)}
            isLoading={isLoading}
            key={'file-picker-' + documentKey.toLowerCase()}
            onAdd={handleAdd}
            onRemove={() => handleRemove(documentKey)}
          />
        )
      }),
    [document, styles, dictionary, checkError, formatMessage, onUploadDocument, handleRemove]
  )

  const renderIdentityFilePickers = useCallback(
    (selectedOption: string) => (
      <View style={styles.cardContainer}>
        <Title
          variant="t7"
          weight="regular">
          {formatMessage({ id: 'CoordonneesBancairesUploadIDTitre' })}
        </Title>
        <Text
          style={styles.textStyle}
          variant="t4"
          weight="light">
          {formatMessage({ id: 'CoordonneesBancairesUploadIDDescription' })}
        </Text>
        <ScrollView
          horizontal
          showsHorizontalScrollIndicator={false}>
          <RadiosButtonList
            activeRadio={selectedOption}
            containerStyle={styles.radiosButtonListContainer}
            flexDirection="row"
            onChange={handleSelectOption}
            options={optionsForRadiosButtonList}
          />
        </ScrollView>
        <View style={styles.filePickersContainer}>{renderFilePickers(selectedOption)}</View>
      </View>
    ),
    [formatMessage, handleSelectOption, optionsForRadiosButtonList, renderFilePickers, styles]
  )

  return (
    <DashboardPageWrapperConnected renderStickyMobileBottom={renderBottomActions}>
      <ColumnsContainer
        hasRenderRightDesktopColumn={false}
        renderHeader={RenderHeader}>
        <Title
          variant="t4"
          weight="bold">
          {formatMessage({ id: 'CoordonneesBancairesUploadTitre' })}
        </Title>
        <Text
          style={styles.textStyle}
          variant="t3"
          weight="light">
          {formatMessage({ id: 'CoordonneesBancairesUploadDescription' })}
        </Text>
        {selectedPlanFamily === PlanFamillyList.ES && renderIdentityFilePickers(selectedOption)}
        <View style={styles.cardContainer}>
          <Title
            variant="t7"
            weight="regular">
            {formatMessage({ id: 'CoordonneesBancairesUploadRIBTitre' })}
          </Title>
          <Text
            style={styles.textStyle}
            variant="t4"
            weight="light">
            {formatMessage({ id: 'CoordonneesBancairesChoixPaysDescription' })}
          </Text>
          <View style={styles.filePickersContainer}>
            {renderFilePickers(DocumentTypeOptionValue.IBAN)}
          </View>
        </View>
        {isDesktop && renderBottomActions()}
      </ColumnsContainer>
    </DashboardPageWrapperConnected>
  )
}
