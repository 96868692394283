import { useNavigation } from '@react-navigation/native';
import React, { useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';

import {
  ColumnsContainer,
  DashboardPageWrapperConnected,
  NavigationHeader,
  TableLoader,
  Title,
  useTranslation
} from '@components/index';
import { locator } from '@constants/locator';
import { RouteNames } from '@constants/navigation';
import { ActionButton } from '@ere-uilib/molecules/buttons/ActionButton/ActionButton';

import { CourierCardContent, PhoneCardContent } from './components';
import { useHelpContactContext } from './HelpContactsPageProvider';
import { useDictionary } from './useDictionary';
import { useStyles } from './useStyles';

export const HelpContactsPageComponent: React.FC = () => {
  const { formatMessage } = useTranslation();
  const styles = useStyles();
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const navigation = useNavigation();
  const { onContactInfos, phoneData, emailData, isES, isRC, isLoading } = useHelpContactContext();
  const dictionary = useDictionary(phoneData?.category);

  useEffect(() => {
    onContactInfos();
  }, [onContactInfos]);

  const RenderHeader = useCallback(() => {
    const closeFunction = () => {
      navigation.navigate(RouteNames.BottomTabNavigator, {
        screen: RouteNames.ProfileStack,
        params: {
          screen: RouteNames.Profile
        }
      });
    };

    return (
      <NavigationHeader
        displayBackButton
        displayCloseButton
        onClose={closeFunction}
        onGoBack={closeFunction}
        title={formatMessage({ id: 'TermsetFrontProfileMenuAideContacts' })}
      />
    );
  }, [navigation, formatMessage]);

  return (
    <DashboardPageWrapperConnected>
      <ColumnsContainer
        hasRenderRightDesktopColumn={false}
        leftColumnStyle={{ paddingVertical: 20 }}
        renderHeader={RenderHeader}>
        {isLoading ? (
          <TableLoader />
        ) : (
          <>
            <Title
              style={{ paddingBottom: 20 }}
              variant={'t3'}>
              {dictionary.contactPageTitle}
            </Title>
            <View style={styles.containerStyle}>
              <View
                role="list"
                style={styles.buttonsContainer}>
                {phoneData.enabled && (
                  <ActionButton
                    iconName={'contact'}
                    isSelected={selectedIndex === 0}
                    onSelect={() => setSelectedIndex(0)}
                    testId={locator._profile._help_contact._phone_menu_button}
                    title={dictionary.ContactPhoneIcon}
                  />
                )}
                <ActionButton
                  iconName={'courrier'}
                  isSelected={selectedIndex === 1}
                  onSelect={() => setSelectedIndex(1)}
                  testId={locator._profile._help_contact._postal_menu_button}
                  title={dictionary.ContactMailingAddress}
                />
              </View>
              {selectedIndex === 0 && (
                <PhoneCardContent
                  contactPhoneInformation={dictionary.contactPhoneInformation}
                  contactPhoneNotice={dictionary.contactPhoneNotice}
                  description={dictionary.descriptionPhone}
                  link={dictionary.contactPhonePageInformationLink}
                  linkDescription={dictionary.linkDescription}
                  phoneNumber={phoneData.phoneNumber}
                  testId={locator._profile._help_contact._phone_card}
                  title={dictionary.titlePhone}
                />
              )}
              {selectedIndex === 1 && (
                <>
                  {isES && (
                    <CourierCardContent
                      address={dictionary.addressES}
                      subTitle={dictionary.subTitleCourrierES}
                      testId={locator._profile._help_contact._postal_es_card}
                      title={dictionary.titleCourrierES}
                    />
                  )}
                  {isRC && (
                    <CourierCardContent
                      address={dictionary.addressRC}
                      subTitle={dictionary.subTitleCourrierRC}
                      testId={locator._profile._help_contact._postal_rc_card}
                      title={dictionary.titleCourrierRC}
                    />
                  )}
                </>
              )}
            </View>
          </>
        )}
      </ColumnsContainer>
    </DashboardPageWrapperConnected>
  );
};
