import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects'

import { getUsedCompanyId } from '@modules/dashboard/selectors'
import { runManager } from '@modules/moduleManager'
import { fetchRefundQuestions } from './services'

import * as RefundCommonActions from './actions/refundActions'
import { CommonRefundActionsType } from './types'
import { getRefundUnavailabilityDecisionTreeRequest } from './unavailable-refund/actions/refundActions'
import { DispositifState } from '@modules/common/types'

function* getRefundDecision(): any {
  const companyId = yield select(getUsedCompanyId)
  const response = yield call(fetchRefundQuestions, companyId)
  yield put(RefundCommonActions.getRefundDecisionSuccess(response.data))
  const isUnavailableExist = response.data?.dispositifs?.find(
    (dispositif: DispositifState) => dispositif?.filterProperties.RepaymentUnavailable
  )
  if (isUnavailableExist) yield put(getRefundUnavailabilityDecisionTreeRequest())
}

function* getRefundDecisionSagas() {
  yield takeLatest(
    CommonRefundActionsType.GET_REFUND_DECISION_REQUEST,
    runManager(getRefundDecision, CommonRefundActionsType.GET_REFUND_DECISION_FAILURE)
  )
}

export function* RefundSagas() {
  yield all([fork(getRefundDecisionSagas)])
}
