import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';

import { HtmlStyledRenderer, Paragraph, Title } from '@ere-uilib/atoms';
import { ExpendableLineListItemProps, ExpendableLineSet } from '@ere-uilib/molecules';
import { useTranslation } from '@ere-uilib/translations';

import { useStyles } from './useStyles';

const LABEL = {
  title: 'EmployerContributionFAQTitle',
  description: 'EmployerContributionFAQZoneIntro',
  items: [
    {
      title: 'EmployerContributionFAQQuestion1',
      description: 'EmployerContributionFAQRéponse1'
    },
    {
      title: 'EmployerContributionFAQQuestion2',
      description: 'EmployerContributionFAQRéponse2'
    },
    {
      title: 'EmployerContributionFAQQuestion3',
      description: 'EmployerContributionFAQRéponse3'
    },
    {
      title: 'EmployerContributionFAQQuestion4',
      description: 'EmployerContributionFAQRéponse4'
    }
  ]
};

export const ContributionFAQTab = () => {
  const styles = useStyles();
  const { formatMessage, getMessageRaw } = useTranslation();

  const expendableLineSetList = useMemo<ExpendableLineListItemProps[]>(
    () =>
      LABEL.items.map(({ title, description }) => ({
        title: formatMessage({ id: title }),
        containerStyle: styles.expendableLineContainer,
        headerStyle: styles.expendableLineHeader,
        titleStyle: styles.expendableLineTitle,
        contentStyle: styles.expendableLineContent,
        iconStyle: styles.expendableLineIcon,
        arrowPosition: 'right',
        children: (
          <HtmlStyledRenderer
            baseFontStyle={styles.htmlStyledRendererBaseFont}
            html={getMessageRaw({ id: description })}
            style={styles.htmlStyledRenderer}
          />
        )
      })),
    [formatMessage, getMessageRaw, styles]
  );

  return (
    <View>
      <Title variant="t4">{formatMessage({ id: LABEL.title })}</Title>
      <Paragraph
        autoMargin
        variant="t3"
        weight="light">
        {formatMessage({ id: LABEL.description })}
      </Paragraph>
      <ExpendableLineSet
        autoOpenFirstItem
        list={expendableLineSetList}
      />
    </View>
  );
};
