import { ControlDataAction, ControlDataActionsType, GetRehydrateControlDataAction } from './actions/controlDataActionsTypes';
import { ControlDataState } from './types';
import { AuthActionType, AuthenticateAction } from '@modules/auth/actions/authActionsTypes';

export const initialState: ControlDataState = {
  data: [],
  editingOptions: undefined,
  error: undefined,
  isEditedDrivingDataSuccess: false,
  editedDrivingData: undefined
};

const manageRehydrating = (state: ControlDataState, action: GetRehydrateControlDataAction) => {
  const data = [...state.data];
  const filtredPlan = data.find(t => t.planId === action.planId)
  if (!filtredPlan) return {
    ...state,
    data
  }
  filtredPlan.managements.find((e) => {
    if (e.managementId === action.management.managementId) {
      return e.formValues = { ...action.management.formValues }
    }
  })

  return {
    ...state,
    data
  }
}
export default function ControlDataReducer(
  state: ControlDataState = initialState,
  action: ControlDataAction | AuthenticateAction
): ControlDataState {
  switch (action?.type) {
    case ControlDataActionsType.REHYDRATE_CONTROL_DATA:
      return manageRehydrating(state, action)
    case ControlDataActionsType.GET_CONTROL_DATA_SUCCESS:
      return {
        ...state,
        data: action.data
      };
    case ControlDataActionsType.GET_CONTROL_DATA_EDITING_OPTIONS_SUCCESS:
      return {
        ...state,
        editingOptions: action.editingOptions
      };
    case ControlDataActionsType.POST_CONTROL_DATA_EDITING_OPTIONS_SUCCESS:
      return {
        ...state,
        isEditedDrivingDataSuccess: action.isEditedDrivingDataSuccess
      };
    case ControlDataActionsType.GET_CONTROL_DATA_INIT_ERROR:
    case ControlDataActionsType.GET_CONTROL_DATA_FAILURE:
    case ControlDataActionsType.GET_CONTROL_DATA_EDITING_OPTIONS_FAILURE:
    case ControlDataActionsType.POST_CONTROL_DATA_EDITING_OPTIONS_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case AuthActionType.LOGOUT_CALLBACK_SUCCESS:
    case ControlDataActionsType.CLEAR_DRIVING_DATA:
      return initialState;
    default:
      return state;
  }
}
