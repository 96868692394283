import { cloneDeep, groupBy } from 'lodash';

import { AppState } from '@modules/index';

import {
  AvailabilitiesDetail,
  AvailabilitiesDetailState,
  AvailabilitiesFundsState,
  OperationsHistory,
  OperationsHistoryState,
  RepartitionCompartimentGroupState,
  RepartitionFiltersState,
  RepartitionGroupsStructuresState,
  RepartitionManagementGroupState,
  Repartitions,
  RepartitionsState,
  SavingsHistory,
  SavingsHistoryState,
  SavingsState
} from './types';

export const filterOperationsHistory = (
  operationsHistory: OperationsHistoryState[],
  state: SavingsState
): [string, OperationsHistoryState[]][] => {
  // apllyFilterTypes
  const operationsFiltredByType: OperationsHistoryState[] = [];
  operationsHistory.map(item => {
    return state.operationsHistoryFilterRoules.TypesRoule.map(roule => {
      if (roule.value === item.type && roule.checked) {
        return operationsFiltredByType.push(item);
      }
    });
  });
  const sortedOperations = operationsFiltredByType.sort(
    (a: any, b: any) =>
      new Date(b.dateTime).getTime() - new Date(a.dateTime).getTime()
  );
  const operationsGroupedByDate = groupBy(sortedOperations, 'dateTime');
  return Object.keys(operationsGroupedByDate).map(key => [
    key,
    operationsGroupedByDate[key],
  ]);
};
export const filterRepartitions = (
  repartitions: RepartitionsState[],
  filters: RepartitionFiltersState
): RepartitionsState[] => {
  // apply order filter
  let sortedRepartitions = repartitions;
  if (repartitions && repartitions?.length > 1) {
    switch (true) {
      case filters?.sortFilter === 'sort_amount_desc':
        sortedRepartitions = repartitions.sort((a: any, b: any) => {
          const aValue = a.amount?.amount;
          const bValue = b.amount?.amount;
          return  bValue - aValue

        });
        break;
      case filters?.sortFilter === 'sort_performance':
        sortedRepartitions = repartitions.sort((a: any, b: any) => {
          const aValue = a.performanceValue;
          const bValue = b.performanceValue;
          return  aValue - bValue

        });
        break;
      case filters?.sortFilter === 'sort_risk':
        sortedRepartitions = repartitions.sort((a: any, b: any) => {
          const riskLevela = a.riskLevelSRI || a.riskLevelSRRI
          const riskLevelb = b.riskLevelSRI || b.riskLevelSRRI
          const aValue = parseInt(riskLevela);
          const bValue = parseInt(riskLevelb);
          return  aValue - bValue
        });
        break;
      default:
        break;
    }
  }
  return sortedRepartitions;
};

export const extractRepartitionsGroupsStructures = (
  repartitions: RepartitionsState[]
): RepartitionGroupsStructuresState => {
  const output: RepartitionGroupsStructuresState = {
    compartimentGroups: [],
    managementGroups: [],
  };

  repartitions?.forEach(element => {
    const compartimentIndex = output.compartimentGroups.findIndex(
      item => item.label === element.compartimentLabel
    );
    const isCompartimentExist = compartimentIndex >= 0;

    const managementIndex = output.managementGroups.findIndex(
      item => item.label === element.managementLabel
    );
    const isManagementExist = managementIndex >= 0;

    // create or update compartiments groups
    if (element.compartimentCode) {
      if (!isCompartimentExist) {
        const currentCompartiment: RepartitionCompartimentGroupState = {
          code: element.compartimentCode,
          label: element.compartimentLabel,
          order: element.compartimentOrder,
          managements: [],
        };
        output.compartimentGroups.push(currentCompartiment);
      }
      // update compartiment managements list if needed
      const compartimentIndexAfterVerification =
        output.compartimentGroups.findIndex(
          item => item.label === element.compartimentLabel
        );
      const currentCompartimentManagements =
        output.compartimentGroups[compartimentIndexAfterVerification]
          .managements;
      const currentCompartimentManagementIndex =
        currentCompartimentManagements.findIndex(
          item => item.label === element.managementLabel
        );
      const isCurrentCompartimentManagementExist =
        currentCompartimentManagementIndex >= 0;

      if (!isCurrentCompartimentManagementExist) {
        const currentCompartimentCompartiment: RepartitionManagementGroupState = {
          code: element.managementCode,
          label: element.managementLabel,
          order: element.managementOrder,
        };
        output.compartimentGroups[
          compartimentIndexAfterVerification
          ].managements.push(currentCompartimentCompartiment);
      }
    }

    // create or update managements groups
    if (element.managementCode) {
      if (!isManagementExist) {
        const currentManagement: RepartitionManagementGroupState = {
          code: element.managementCode,
          label: element.managementLabel,
          order: element.managementOrder,
        };
        output.managementGroups.push(currentManagement);
      }
    }
  });

  // common compartiment and management list function to reorder by order key or labels
  const arrangeByOrderOrLabelAlphabetic = (a: any, b: any) => {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    // if order is equal , try to use alphabetic order of labels
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  };

  // reorder groups and subgroups
  output.compartimentGroups = output.compartimentGroups.sort((a: any, b: any) =>
    arrangeByOrderOrLabelAlphabetic(a, b)
  );
  output.compartimentGroups = output.compartimentGroups.map(
    compartimentGroup => {
      const sortedCompartimentGroupManagements =
        compartimentGroup.managements.sort((a: any, b: any) =>
          arrangeByOrderOrLabelAlphabetic(a, b)
        );
      return {
        ...compartimentGroup,
        managements: sortedCompartimentGroupManagements,
      };
    }
  );
  output.managementGroups = output.managementGroups.sort((a: any, b: any) =>
    arrangeByOrderOrLabelAlphabetic(a, b)
  );

  return output;
};

export const groupRepartitionsBySupportName = (
  repartitions: RepartitionsState[]
) => {
  const result: RepartitionsState[] = [];
  repartitions.forEach(item => {
    const index = result.findIndex(
      element => element.supportIsin === item.supportIsin
    );
    if (index === -1) {
      result.push(cloneDeep(item));
    } else {
      result[index].amount.amount += item.amount.amount;
      if (
        result[index].amount.addedValue !== null &&
        item.amount.addedValue !== null
      ) {
        result[index].amount.addedValue += item.amount.addedValue;
      }
      if (
        result[index]?.amount?.numberOfUnits != null && item.amount?.numberOfUnits != null
      ) {
        result[index].amount.numberOfUnits += item.amount.numberOfUnits;
      }
    }
  });
  return result;
};

export const concatObject = (
  items: any,
  planId: string,
  data:
    | (RepartitionsState[]
    | [string, OperationsHistoryState[]][])
    | AvailabilitiesDetailState
    | AvailabilitiesFundsState[]
    | SavingsHistoryState[]
) => {
  const result = items.filter(
    (item: Repartitions | OperationsHistory | AvailabilitiesDetail) =>
      item.planId !== planId
  );
  return result.concat({ planId: planId, items: data });
};

export const getItemsfromfiltredArray = (
  items: OperationsHistory[],
  planId: string,
  data: OperationsHistoryState[]
): OperationsHistoryState[] => {
  const planItems: OperationsHistoryState[] = [];
  const plan: OperationsHistory[] = items.filter(
    (item: OperationsHistory) => item.planId === planId
  );
  if (plan.length <= 0) {
    return data;
  }
  plan[0].items.map(operation => {
    planItems.push(...operation[1]);
  });
  data.map(operation => {
    planItems.push(operation);
  });
  return planItems;
};

const sortSavingsData = (dataToSort: any, state: AppState) => {
  const savingsPlans = state.dashboard.savingsPlans;
  const returnedArray: any[] = [];
  savingsPlans.map(plan => {
    const foundedArray = dataToSort.filter(
      (rep: any) => rep.planId === plan.planId
    );
    return (
      foundedArray.length > 0 &&
      returnedArray.push({
        planId: foundedArray[0]?.planId,
        items: foundedArray[0]?.items,
      })
    );
  });
  return returnedArray;
};
export const sortSavingsHistoryData = (dataToSort: any, state: AppState) => {
  const savingsPlans = state.dashboard.savingsPlans;
  const returnedArray: SavingsHistory[] = [];
  savingsPlans.map(plan => {
    const foundedArray = dataToSort.filter(
      (rep: SavingsHistory) => rep.planId === plan.planId
    );
    return (
      foundedArray.length > 0 &&
      returnedArray.push({
        planId: foundedArray[0]?.planId,
        allHistory: foundedArray[0]?.allHistory,
      })
    );
  });
  return returnedArray;
};
export const concatSavingHistoryObject = (
  items: SavingsHistory[],
  planId: string,
  period: number,
  data: SavingsHistoryState[]
) => {
  const result: SavingsHistory[] = items.filter(
    (subItem: SavingsHistory) => subItem.planId === planId
  );
  if (result.length > 0) {
    const savingHistory: any = {
      ...result[0],
      allHistory: result[0].allHistory.concat({ period: period, items: data }),
    };
    return items
      .filter((subItem: SavingsHistory) => subItem.planId !== planId)
      .concat(savingHistory);
  } else {
    return items.concat({
      planId: planId,
      allHistory: [
        {
          period: period,
          items: data,
        },
      ],
    });
  }
};
