import { useMemo } from "react"
import { StyleSheet } from "react-native"

import { useTheme } from "@ere-uilib/styles";

export const useStyles = () => {
  const theme = useTheme();
  const styles = useMemo(() => StyleSheet.create({
    contentStyle: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    subtitleStyle: {
      fontSize: theme.fonts.fontSize.paragraph.t4,
    },
    title: {
      flex: 1,
      marginRight: theme.metrics.spacing.m,
    },
    contentText: {
      color: theme.colors.basics.black,
    },
    contentTextHovered: {
      color: theme.colors.basics.white,
    },
  }), []);

  return styles;
}
