import { InstallmentPaymentCBHtmlPageContext, InstallmentPaymentCBHtmlPageStyle } from './interfaces';

export const getStyles = (
  context?: InstallmentPaymentCBHtmlPageContext,
  style?: InstallmentPaymentCBHtmlPageStyle,
): InstallmentPaymentCBHtmlPageStyle => ({
  containerStyle: [
    (context?.isMobile || context?.isTablet) && {
      height: context?.windowHeight
    },
    style?.containerStyle
  ],
  installmentsStepperContainerStyle: [{
    alignItems: 'center',
    justifyContent: 'center'
  },
  style?.installmentsStepperContainerStyle
  ],
  stepperStyle: [
    {
      paddingBottom: context?.theme.metrics.spacing.s,
      marginTop: context && -context?.theme.metrics.spacing.s
    },
    style?.stepperStyle
  ],
  HtmlFrameStyle: [
    {
      width: '100%',
      height: 600
    },
    style?.HtmlFrameStyle
  ]
});

