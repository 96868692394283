import React from 'react';
import { GestureResponderEvent } from 'react-native';
import RenderHtml, {
  defaultSystemFonts,
  MixedStyleDeclaration
} from 'react-native-render-html';

import { useTheme } from '@ere-uilib/styles';

import { HtmlStyledRendererProps } from './intefaces';
import { useUrlLink } from '../../../../utils/useUrlLink';

export const HtmlStyledRenderer: React.FC<HtmlStyledRendererProps> = ({
  html,
  style,
  baseFontStyle,
  onLinkPress
}) => {
  const theme = useTheme();
  const htmlStyles: {
    baseFontStyle: MixedStyleDeclaration
    strongStyles: MixedStyleDeclaration
    anchorStyles: MixedStyleDeclaration
    spanStyles: MixedStyleDeclaration
    paragraphStyles: MixedStyleDeclaration
  } = {
    baseFontStyle: {
      fontFamily: theme.fonts.fontFamily.regular,
      fontSize: theme.fonts.fontSize.paragraph.t3,
      fontWeight: '400',
      color: theme.colors.text.primary.c500,
      ...baseFontStyle,
    },
    strongStyles: {
      fontFamily: theme.fonts.fontFamily.bold,
      fontWeight: '400'
    },
    anchorStyles: {
      fontFamily: theme.fonts.fontFamily.regular,
      color: theme.colors.basics.primary.c500,
      textDecorationLine: 'underline',
      textDecorationColor: theme.colors.basics.primary.c500
    },
    spanStyles: {
      textDecorationLine: 'none',
    },
    paragraphStyles: {
      marginTop: 0,
      marginBottom: theme.fonts.fontSize.paragraph.t3,
      paddingVertical: 0,
      paddingHorizontal: 0,
    }
  };
  const { linkToUrl } = useUrlLink();
  const renderersProps = {
    a: {
      onPress(event: GestureResponderEvent, url: string) {
        const link = url.replace('about://', '');
        onLinkPress? onLinkPress(event, link): linkToUrl(link);
      }
    }
  }

  const systemFonts = [...defaultSystemFonts, baseFontStyle?.fontFamily || ""]

  return (
    <RenderHtml
      baseStyle={{...htmlStyles.baseFontStyle, ...style}}
      systemFonts={systemFonts}
      enableCSSInlineProcessing={true}
      source={{ html }}
      tagsStyles={{
        strong: htmlStyles.strongStyles,
        a: htmlStyles.anchorStyles,
        span: htmlStyles.spanStyles,
        p: htmlStyles.paragraphStyles,
      }}
      allowedStyles={['fontFamily']}
      renderersProps={renderersProps}
    />
  )
};
