import { ArbitrationState } from '@modules/arbitration/types'
import { ResponseType } from '@modules/common/types'
import { InstallmentState } from '@modules/installment/types'
import { RefundsCommonState } from '@modules/refund/types'

export const updateQuestionWhenResponded = (
  state: InstallmentState | RefundsCommonState | ArbitrationState,
  data: ResponseType,
  ResponseIndex: number,
  canNotBeAsked: boolean,
  respondedTitle?: string
) => {
  const returnedData = {
    dispositifs: [...state.decision.dispositifs],
    questions: state.decision.questions.map((question, index) => {
      if (index === ResponseIndex) {
        question.responded = data
        question.canNotBeAsked = canNotBeAsked
        respondedTitle ? (question.respondedTitle = respondedTitle) : undefined
      }

      return question
    })
  }
  return returnedData
}

export const backToQuestion = (
  state: InstallmentState | RefundsCommonState | ArbitrationState,
  editedQuestionIndex: number
) => {
  let newQuestions = [...state.decision.questions]

  if (editedQuestionIndex === 0) {
    newQuestions = newQuestions.slice(0, 3)
  }

  const returnedData = {
    dispositifs: [...state.decision.dispositifs],
    questions: newQuestions.map((question, index) => {
      let newQuestion = { ...question }

      if (index >= editedQuestionIndex) {
        newQuestion = {
          ...question,
          responded: undefined,
          implicitResponseEnabled: false
        }
      }

      return newQuestion
    })
  }

  return returnedData
}
