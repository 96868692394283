import { useNavigation } from '@react-navigation/native';
import React, { useState, useEffect } from 'react';

import {
  useTheme,
  ValidationCodePageTemplate,
  useScreenSizes,
  useTranslation
} from '@components/index';
import { createUseStyles } from '@ere-uilib/styles';

import { useLogoNavigation } from '@utils/index';

import { Props } from './interfaces';
import { getStyles } from './styles';

export const ForgetPasswordValidationCode: React.FC<React.PropsWithChildren<Props>> = ({
  route,
  userPhoneNumber,
  inputUserEmail,
  forgetPasswordError,
  isForgetPasswordValidateEmailApiLoading,
  isForgetPasswordBeginProcessApiLoading,
  onForgetPasswordValidateEmailByCode,
  onForgetPasswordBeginProcess,
  onForgetPasswordConfirmSmsCode,
  onForgetPasswordResendSms,
  isForgetPasswordValidatePhoneNumberApiLoading,
  isForgetPasswordResendSmsEventApiLoading
}) => {
  const theme = useTheme();
  const { isMobile, isTablet } = useScreenSizes();
  const styles = useStyles(
    {
      theme,
      isTablet,
      isMobile
    },
    {}
  );
  const { isForgetPasswordPhoneNumberVerify } = route?.params;
  const { logoNavigateTo } = useLogoNavigation();
  const { formatMessage } = useTranslation();
  const [resetOTP, setResetOTP] = useState(false);
  const navigation = useNavigation();

  useEffect(() => {
    setResetOTP(!resetOTP);
  }, [isForgetPasswordPhoneNumberVerify]);

  const onRetryEventOnPress = () => {
    setResetOTP(!resetOTP);
    isForgetPasswordPhoneNumberVerify
      ? onForgetPasswordResendSms()
      : onForgetPasswordBeginProcess(inputUserEmail);
  };

  const onValidateForm = (otpCode: string) => {
    if (isForgetPasswordPhoneNumberVerify) {
      onForgetPasswordConfirmSmsCode(otpCode);
    } else {
      onForgetPasswordValidateEmailByCode(otpCode);
    }
  };

  const validationCanalIdentifier = isForgetPasswordPhoneNumberVerify
    ? userPhoneNumber
    : inputUserEmail;
  const canGoBack = navigation?.canGoBack();
  const handleCloseButtonAction = () => {
    if (canGoBack) {
      navigation?.goBack();
    }
  };

  return (
    <ValidationCodePageTemplate
      description={
        (!isForgetPasswordPhoneNumberVerify
          ? formatMessage({ id: 'forgot-pwd-newpassword-label' })
          : formatMessage({ id: 'validate-identity_description' })) +
        ` ${validationCanalIdentifier}`
      }
      displayLogo={true}
      errorMessage={forgetPasswordError.message}
      handleCloseButton={handleCloseButtonAction}
      infoNotificationText={
        !isForgetPasswordPhoneNumberVerify
          ? `${formatMessage({ id: 'validate-identity_info-unfound' })}\n${formatMessage({
              id: 'validate-identity_info-instruction'
            })}`
          : undefined
      }
      isApiLoading={
        isForgetPasswordValidateEmailApiLoading || isForgetPasswordValidatePhoneNumberApiLoading
      }
      isEERLeftMenu={false}
      isHeader={false}
      isRetryEventApiLoading={
        isForgetPasswordBeginProcessApiLoading || isForgetPasswordResendSmsEventApiLoading
      }
      onLogoPress={logoNavigateTo}
      onPress={onValidateForm}
      onRetryEventOnPress={onRetryEventOnPress}
      resetOTP={resetOTP}
      subtitleStyle={styles.subtitleStyle}
      title={formatMessage({ id: 'verify-login_reinitialisation_pw' })}
    />
  );
};

const useStyles = createUseStyles(getStyles);
