import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

export function useStyles() {
  const theme = useTheme();

  const styles = useMemo(() => StyleSheet.create({
    modalContainer: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    checkboxContainerStyle: {
      marginBottom: theme.metrics.spacing.l,
    },
    titleStyle: {
      marginTop: theme.metrics.spacing.xm,
      marginBottom: theme.metrics.spacing.s,
    },
    boxTextStyle: {
      marginLeft: theme.metrics.spacing.s,
    },
    fieldInputContainer: {
      marginTop: theme.metrics.spacing.m,
      marginBottom: theme.metrics.spacing.xm,
    },
    fieldInput: {
      flex: 1,
      maxWidth: 200,
    },
    buttonsContainerStyle: {
      flexDirection: 'row',
      alignSelf: 'stretch'
    },
    buttonStyle: { 
      flex: 1
    },
    lastButtonStyle: { 
      marginLeft: theme.metrics.spacing.s
    },
  }), [theme]);

  return styles;
}
