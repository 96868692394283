import { AuthActionType, AuthenticateAction } from '@modules/auth/actions/authActionsTypes'
import { ErrorActionsType } from '@modules/error/actions/errorActionsTypes'

import { CommonRefundAction } from '../actions/refundActionsTypes'
import { CommonRefundActionsType } from '../types'
import { RefundAction, RefundActionsType } from './actions/refundActionsTypes'
import { AvailableRefundState } from './types'
import {
  setAmountToSupport,
  setRepartitionTotalAvailablesAmounts,
  setSelectAllCompartmentSupport,
  setSelectSupport,
  setSupportTriggerThreshold
} from './utils'

const initialState: AvailableRefundState = {
  repartition: null,
  repartitionAmountModalVisibleState: false,
  repartitionTriggerThresholdModalVisibleState: false,
  calculatedContributionError: {
    code: '',
    correlationId: '',
    message: '',
    innerMessage: ''
  },
  repaymentDemandStatus: {
    demandId: '',
    isSucceeded: undefined,
    operationEnded: undefined
  },
  error: {
    code: '',
    correlationId: '',
    message: '',
    innerMessage: ''
  }
}

export default function refundReducer(
  state: AvailableRefundState = initialState,
  action: RefundAction | AuthenticateAction | CommonRefundAction
): AvailableRefundState {
  switch (action?.type) {
    case RefundActionsType.GET_REFUND_REPARTITION_REQUEST:
      return {
        ...state,
        repartition: initialState.repartition
      }
    case RefundActionsType.GET_REFUND_REPARTITION_SUCCESS:
      return {
        ...state,
        repartition: setRepartitionTotalAvailablesAmounts(action.repartition)
      }
    case RefundActionsType.SET_REFUND_REPARTITION_AMOUNT:
      return {
        ...state,
        repartition: setAmountToSupport({
          repartition: state.repartition,
          params: action.params
        })
      }
    case RefundActionsType.SET_REFUND_REPARTITION_AMOUNT_MODAL_VISIBLE_STATE:
      return {
        ...state,
        repartitionAmountModalVisibleState: action.state
      }
    case RefundActionsType.SET_REFUND_TRIGGER_POINT_MODAL_VISIBLE_STATE:
      return {
        ...state,
        repartitionTriggerThresholdModalVisibleState: action.state
      }
    case RefundActionsType.SET_SUPPORT_TRIGGER_POINT_AND_FORMAT_REPARTITION:
      return {
        ...state,
        repartition: setSupportTriggerThreshold({
          repartition: state.repartition,
          triggerPointData: action.triggerPointData,
          compartmentId: action.compartmentId,
          managementId: action.managementId,
          supportIsInCode: action.supportIsInCode
        })
      }
    case RefundActionsType.CLEAR_SUPPORT_TRIGGER_THRESHOLD:
      return {
        ...state,
        repartition: setSupportTriggerThreshold({
          repartition: state.repartition,
          compartmentId: action.compartmentId,
          managementId: action.managementId,
          supportIsInCode: action.supportIsInCode
        })
      }
    case RefundActionsType.REFUND_REPARTITION_SELECT_ALL_SUPPORT:
      return {
        ...state,
        repartition: setSelectAllCompartmentSupport({
          repartition: state.repartition,
          selectState: action.selectState
        })
      }
    case RefundActionsType.REFUND_REPARTITION_SELECT_SUPPORT:
      return {
        ...state,
        repartition: setSelectSupport({
          repartition: state.repartition,
          compartmentId: action.compartmentId,
          managementId: action.managementId,
          isinCode: action.isinCode,
          amount: action.amount,
          selectState: action.selectState
        })
      }
    case RefundActionsType.CREATE_REPAYMENT_DEMAND_SUCCESS:
      return {
        ...state,
        repaymentDemandStatus: {
          demandId: action.repaymentDemandResponse.demandId,
          isSucceeded: action.repaymentDemandResponse.isSucceeded,
          operationEnded: action.repaymentDemandResponse.operationEnded
        }
      }
    case CommonRefundActionsType.SET_REFUNDS_SELECTED_DISPOSITIF:
      return {
        ...state,
        repartition: initialState.repartition
      }
    case CommonRefundActionsType.REFUND_INIT_ERROR:
    case ErrorActionsType.INIT_ALL_ERRORS:
      return {
        ...state,
        error: initialState.error,
        calculatedContributionError: initialState.calculatedContributionError
      }
    case CommonRefundActionsType.CLEAR_REFUND_DATA:
    case AuthActionType.LOGOUT_CALLBACK_SUCCESS:
      return initialState
    default:
      return state
  }
}
