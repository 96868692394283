import { createStackNavigator } from '@react-navigation/stack';
import React from 'react'
import { useSelector } from 'react-redux';

import { RouteNames } from '@constants/navigation';
import { getSelectedCompany } from '@modules/dashboard/selectors';
import { useDefaultScreensOptions } from '@navigation/useDefaultScreensOptions';
import {
  Home,
  EDocumentsFiles,
  EDocumentsSubscription,
  GeneralDocumentsFiles,
  GeneralDocuments,
  DocumentSpaceViewer,
} from '@pages/DocumentSpace';

const DocumentSpaceStack = createStackNavigator();

export const DocumentSpaceStackNavigator = () => {
  const defaultScreensOptions = useDefaultScreensOptions();
  const { isES = false } = useSelector(getSelectedCompany) ?? {};

  // TODO : reactivate data test for page display when it become esier to work on

  return (
    <DocumentSpaceStack.Navigator
      screenOptions={{
        gestureEnabled: false,
        headerShown: false,
      }}
    >
      {isES && (
        <DocumentSpaceStack.Group>
          <DocumentSpaceStack.Screen
            component={Home}
            name={RouteNames.DocumentSpaceHome}
            options={defaultScreensOptions.DocumentSpaceHome}
          />
          <DocumentSpaceStack.Screen
            component={EDocumentsFiles}
            name={RouteNames.DocumentSpaceEDocumentsFiles}
            options={defaultScreensOptions.DocumentSpaceEDocumentsFiles}
          />
          <DocumentSpaceStack.Screen
            component={EDocumentsSubscription}
            name={RouteNames.DocumentSpaceEDocumentsSubscription}
            options={defaultScreensOptions.DocumentSpaceEDocumentsSubscription}
          />
        </DocumentSpaceStack.Group>
      )}
      <DocumentSpaceStack.Screen
        component={GeneralDocuments}
        name={RouteNames.DocumentSpaceGeneralDocuments}
        options={defaultScreensOptions.DocumentSpaceGeneralDocuments}
      />
      <DocumentSpaceStack.Screen
        component={GeneralDocumentsFiles}
        name={RouteNames.DocumentSpaceGeneralDocumentsFiles}
        options={defaultScreensOptions.DocumentSpaceFiles}
      />
      <DocumentSpaceStack.Screen
        component={DocumentSpaceViewer}
        name={RouteNames.DocumentSpaceViewer}
        options={defaultScreensOptions.DocumentSpaceViewer}
      />
    </DocumentSpaceStack.Navigator>
  );
};
