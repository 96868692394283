import React from "react";
import { connect } from "react-redux";

import { getRedirectOperationRequest } from "@modules/redirect-operation/actions/redirectOperationActions";
import { RedirectOperationActionsType } from "@modules/redirect-operation/actions/redirectOperationActionsTypes";
import { AppState } from "@modules/reducers";
import { getOperationModalList } from "@modules/redirect-operation/selectors";

import { OperationsModalPageComponent } from "./OperationsModalPage.component";

const mapStateToProps = (state: AppState) => {
  return {
    operationModalList: getOperationModalList(state),
    isLoadingOperationModalList:
      state.isLoading[RedirectOperationActionsType.GET_OPERATION_REDIRECT_REQUEST],
  };
};

const mapDispatchToProps = {
  getOperationModalList: getRedirectOperationRequest,
};

export const OperationsModalPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(OperationsModalPageComponent);
