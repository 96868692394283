import {useDispatch, useSelector} from 'react-redux';

import { DashboardActionsType } from '@modules/dashboard/actions/dashboardActionsTypes';
import { acceptConsentsRequest } from '@modules/profile/actions/profileActions';
import { ProfileActionsType } from "@modules/profile/actions/profileActionsTypes";
import {
  getConsents,
  getProfileError
} from '@modules/profile/selectors';
import { getLoadingState } from "@modules/funds/selectors";

import { CGUComponent } from './Cgu.component';

export const Cgu : React.FC = () => {
  const consents = useSelector(getConsents);
  const error = useSelector(getProfileError);
  const isAcceptLoading = useSelector(getLoadingState(ProfileActionsType.ACCEPT_CONSENTS_REQUEST));
  const isGetAccountsLoading = useSelector(getLoadingState(DashboardActionsType.GET_ACCOUNTS_REQUEST));
  const disptach = useDispatch();

  return (<CGUComponent
        consents={consents}
        error={error}
        isAcceptLoading={isAcceptLoading}
        isGetAccountsLoading={isGetAccountsLoading}
        onAcceptCGU={(code)=> disptach(acceptConsentsRequest(code))}  />);
}
