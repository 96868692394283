import React, { useCallback } from 'react';
import { View, TouchableOpacity } from 'react-native';

import { Title, FontIcon } from '@ere-uilib/atoms';
import { ArrowButton } from '@ere-uilib/molecules';
import { HelpPoint } from '@ere-uilib/organisms';
import { useScreenSizes, createUseStyles, useTheme } from '@ere-uilib/styles';

import { NavigationHeaderProps, NavigationHeaderStyles } from './interface';
import { getStyles } from './styles';

type Props = NavigationHeaderProps & NavigationHeaderStyles;

export const NavigationHeader: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  children,
  displayBackButton,
  onGoBack,
  displayCloseButton,
  onClose,
  helpPoint,
  helpPointContentHtml,
  helpPointRenderModalContent,
  helpPointContentTitle,
  helpPointModalTitle,
  helpPointPosition = 'left',
  containerStyle,
  leftContainerStyle,
  titleContainerStyle,
  rightContainerStyle,
  headerLine,
  renderLeftComponent,
}: Props) => {
  const theme = useTheme();
  const { isMobile } = useScreenSizes();

  const styles = useStyles(
    {
      theme,
      isMobile,
      helpPointPosition,
      displayBackButton,
      displayCloseButton,
    },
    {
      containerStyle,
      leftContainerStyle,
      titleContainerStyle,
      rightContainerStyle,
      headerLine,
    }
  );

  const onGoBackPress = useCallback(() => {
    if (!onGoBack) return;

    onGoBack()
  }, [onGoBack]);

  const renderBackButton = useCallback(() => {
    if (!displayBackButton) return null;

    return (
      <ArrowButton
        direction={'gauche'}
        onClick={onGoBackPress}
      />
    );
  }, [
    displayBackButton,
    onGoBackPress
  ]);

  const onCloseButtonPress = useCallback(() => {
    if (!onClose) return;

    onClose()
  }, [onClose]);

  const renderCloseButton = useCallback(() => {
    if (!displayCloseButton) return null;

    return (
      <TouchableOpacity
        onPress={onCloseButtonPress}
        style={styles.closeButtonContainer}
      >
        <FontIcon
          color={theme.colors.text.primary.c500}
          name={'fermer'}
          size={theme.metrics.iconSizes.xxm}
        />
      </TouchableOpacity>
    );
  }, [
    displayCloseButton,
    onCloseButtonPress
  ]);

  const renderHelpPoint = useCallback(() => {
    if (!helpPoint) return null;

    return (
      <HelpPoint
        contentHtml={helpPointContentHtml}
        contentTitle={helpPointContentTitle}
        modalTitle={helpPointModalTitle || ''}
        renderModalContent={helpPointRenderModalContent}
        style={styles.helpPointStyle}
      />
    );
  }, [
    helpPoint,
    helpPointContentHtml,
    helpPointContentTitle,
    helpPointModalTitle,
    helpPointRenderModalContent
  ]);

  return (
    <View style={styles.containerStyle}>
      <View style={styles.headerLine}>
        <View
          style={styles.leftContainerStyle}
        >
          {renderBackButton()}
          {helpPointPosition === 'left' && renderHelpPoint()}
          {renderLeftComponent?.()}
        </View>
        <View style={styles.titleContainerStyle}>
          <Title
            style={styles.titleTextStyle}
            variant={isMobile ? 't7' : 't6'}>
            {title}
          </Title>
        </View>
        <View
          style={styles.rightContainerStyle}
        >
          {helpPointPosition === 'right' && renderHelpPoint()}
          {renderCloseButton()}
        </View>
      </View>
      {children}
    </View>
  );
};

const useStyles = createUseStyles(getStyles);
