import { StyleProp, ViewStyle } from 'react-native';

import { ThemeType } from '@ere-uilib/styles';

import { Context, Styles } from './interfaces';

export const getStyles = (
  context: Context,
  style: Styles
): Styles => ({
  container: [
    {
    },
    context?.isMobile && {
      marginTop: context?.theme.metrics.spacing.s
    },
    style.container
  ],
  bodyContainer: [
    {
      paddingHorizontal: context?.theme.metrics.spacing.s,
      paddingVertical: context?.theme.metrics.spacing.xm
    },
    style.bodyContainer
  ],
  bodyTitle: [
    {
      marginBottom: context?.theme.metrics.spacing.xm
    },
    style.bodyTitle
  ]
});
