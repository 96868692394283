import { useTheme } from "@ere-uilib/styles"
import { useMemo } from "react"
import { StyleSheet } from "react-native"

export const useStyles = () => {
  const theme = useTheme();

  const styles = useMemo(() => StyleSheet.create({
    informationRow: {
      maxWidth: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginTop: theme.metrics.spacing.l
    },
    headerContainer: {
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    title: {
      fontSize: theme.fonts.fontSize.title.t6,
      textAlign: 'center',
      marginTop: theme.metrics.spacing.s,
    },
    separator: {
      width: 1,
      height: '100%',
      backgroundColor: theme.colors.basics.grey.c200,
      margin: theme.metrics.spacing.s
    },
    textContainer: {
      maxWidth: '90%'
    },
    iconContainer: {
      borderRadius: theme.metrics.borderRadius.xs,
      padding: theme.metrics.spacing.s,
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: theme.metrics.spacing.s
    },
    buttonContainerStyle: {
      marginTop: theme.metrics.spacing.m
    },
    validationButtonTitle: {
      fontSize: 14,
      color: 'white'
    },
    infoLabelTitle: {
      fontSize: 14
    },
    infoLabelStyle: {
      fontSize: 14,
      fontFamily: 'BNPPSans-Light',
      marginTop: theme.metrics.spacing.s
    }

  }), [theme]);

  return styles;
}