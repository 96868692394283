import { useScreenSizes, useTheme } from "@ere-uilib/styles"
import { useMemo } from "react"
import { StyleSheet } from "react-native"

export const useStyles = (isError: boolean) => {
  const { isMobile } = useScreenSizes();
  const theme = useTheme();

  const styles = useMemo(() => StyleSheet.create({
    containerStyle: {
      ...(
        isMobile ?
          {
            marginHorizontal: theme.metrics.spacing.s,
            marginVertical: theme.metrics.spacing.l,
          }
          : {
            margin: theme.metrics.spacing.l,
          }
      )
    },
    titleStyle: {
      ...(isError && {
        color: theme.colors.text.disabled.c500,
      })
    },
    informationText: {
      alignSelf: 'flex-end',
      marginTop: theme.metrics.spacing.m,
      paddingBottom: isMobile
        ? theme.metrics.spacing.s
        : theme.metrics.spacing.xm,
      color: theme.colors.basics.grey.c500
    },
    commonBlocStyle: {
      padding: 0,
      marginVertical: isMobile ? theme.metrics.spacing.l : theme.metrics.spacing.xl,
    }
  }), [
    theme,
    isMobile,
    isError
  ]);

  return styles;
}

