import { connect } from 'react-redux';

import {
  selectArbitrationRepartitionSupport,
  selectArbitrationRepartitionManagement,
  getRealocationDivestmentRequest,
  clearArbitrationSupportTriggerThreshold,
} from '@modules/arbitration/actions/arbitrationActions';
import { ArbitrationActionsType } from '@modules/arbitration/actions/arbitrationActionsTypes';
import {
  getDivestmentSource,
  getPlanCompartments,
  getSelectedCompartment,
  getSelectedDispositif,
} from '@modules/arbitration/selectors';
import { AppState } from '@modules/reducers';

import {
  ArbitrationDisinvestmentsSavingPage
} from './ArbitrationDisinvestmentsSavingPage.component';

const mapStateToProps = (state: AppState) => ({
  selectedCompartment: getSelectedCompartment(state),
  selectedDispositif: getSelectedDispositif(state),
  planCompartments: getPlanCompartments(state),
  selectedDivestmentSource: getDivestmentSource(state),
  isLoading: state.isLoading[ArbitrationActionsType.GET_REALOCATION_DIVESTMENT_REQUEST],
});

const mapDispatchToProps = {
  onSelectArbitrationDisinvestmentSavingSupport: selectArbitrationRepartitionSupport,
  onSelectArbitrationDisinvestmentSavingManagement: selectArbitrationRepartitionManagement,
  onSubmitArbitrationDivestment: getRealocationDivestmentRequest,
  onClearArbitrationSupportTriggerThreshold: clearArbitrationSupportTriggerThreshold,
};

export const ArbitrationDisinvestmentsSavingPageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ArbitrationDisinvestmentsSavingPage);
