import React from 'react';
import { View } from 'react-native';

import { createUseStyles } from '@ere-uilib/styles';
import { useTheme } from '@ere-uilib/styles/theme';

import { Props } from './interfaces';
import { getStyles } from './styles';

export const CardWithLeftThread: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  addShadow = false,
  backgroundColor,
  borderLeftColor,
  containerStyle,
})=> {
  const theme = useTheme();
  const styles = useStyles({
    theme, addShadow, borderLeftColor, backgroundColor,
  }, {
    containerStyle,
  });

  return (
    <View style={styles.containerStyle}>
      {children}
    </View>
  );
};

const useStyles = createUseStyles(getStyles);
