import { useMemo } from "react";
import { StyleSheet } from "react-native";

import { useScreenSizes, useTheme } from "@ere-uilib/styles";

export const useStyles = (addedValue?: number) => {
  const theme = useTheme();
  const { isDesktop } = useScreenSizes();

  const styles = useMemo(() => StyleSheet.create({
    container: {
      minHeight: isDesktop ? theme.metrics.spacing.xxbig : theme.metrics.spacing.huge,
      paddingHorizontal: theme.metrics.spacing.s,
      flex: 1,
      flexDirection: "row",
      justifyContent: isDesktop ? "center" : "space-between",
      alignItems: "center",
      paddingVertical: theme.metrics.spacing.xm,
    },
    buttonView: {
      width: theme.metrics.iconSizes.xl,
      height: theme.metrics.iconSizes.xl,
    },
    amountContainer: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      maxWidth: 497,
    },
    amount: {
      textAlign: 'center',
    },
    addedValueStyle: {
      textAlign: 'center',
      color: (addedValue && addedValue < 0) ? theme.colors.basics.message.error.c500 : theme.colors.basics.primary.c500
    },
  }), [addedValue, isDesktop, theme]);

  return styles;
};