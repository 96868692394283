import React from 'react';
import { View } from 'react-native';

import { BubbleContainer, Title } from '@ere-uilib/atoms';
import { HeaderResponse } from '@ere-uilib/molecules';
import { createUseStyles, useScreenSizes, useTheme } from '@ere-uilib/styles';

import { EligibilityList } from '../EligibilityList';
import { Props } from './interfaces';
import { getStyles } from './styles';

export const EligibilityRecapCard: React.FC<React.PropsWithChildren<Props>> =  ({
  cardTitle,
  cardDescription,
  elligibleDispositifs,
}) => {
  const theme = useTheme();
  const { isDesktop } = useScreenSizes();
  const styles = useStyles({
    theme,
    isMobile: !isDesktop,
  }, {});
  return (
    <BubbleContainer
      containerStyle={styles.container}
      squarePosition={(!isDesktop) ? 'none' : 'left'}
    >
      <HeaderResponse
        iconName="recherche"
        title={cardTitle}
      />
      <View style={styles.bodyContainer}>
        <Title
          style={styles.bodyTitle}
          variant="t7"
          weight="light"
        >
          {cardDescription}
        </Title>
        <EligibilityList
          list={elligibleDispositifs}
          showEligiblity
        />
      </View>
    </BubbleContainer>
  );
};

const useStyles = createUseStyles(getStyles);
