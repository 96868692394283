import React from 'react'
import { View } from 'react-native'
import { RemainingSavingsTimeCardContentProps } from './interface'
import { useTranslation } from '@ere-uilib/translations'
import { FontIcon, Text, Title } from '@ere-uilib/atoms'
import { useStyles } from './useStyles'
import { useTheme } from '@ere-uilib/styles'

export const RemainingSavingsTimeCardContent = ({
  extraData,
  isMobileOrHasOperations,
  renderChevronLink
}: RemainingSavingsTimeCardContentProps) => {
  const { formatCurrencyNumber, formatMessage } = useTranslation()
  const styles = useStyles(isMobileOrHasOperations)
  const theme = useTheme()

  return (
    <View style={styles.remainingItemListStyle}>
      {extraData?.length &&
        Array.isArray(extraData) &&
        extraData?.map((item, index) => (
          <View
            key={index}
            style={[
              styles.remainingItemStyle,
              index === extraData.length - 1 && styles.lastRemainingItemStyle
            ]}>
            <View style={styles.iconContainerStyle}>
              <View style={styles.iconCircleStyle}>
                <FontIcon
                  color={theme.colors.basics.black}
                  name={item.icon}
                  size={theme.metrics.iconSizes.m}
                />
              </View>
            </View>
            <View style={styles.firstGroupStyle}>
              <View style={styles.labelContainerStyle}>
                <Title
                  style={styles.labelStyle}
                  testId={`${item.label}_title`}
                  variant="t7"
                  weight="light">
                  {formatMessage({ id: item.label })}
                </Title>
              </View>
              {isMobileOrHasOperations && renderChevronLink(item)}
            </View>
            <View style={styles.secondGroupStyle}>
              <View style={styles.amountContainerStyle}>
                {!!item.until && (
                  <Text
                    style={styles.untilStyle}
                    variant="t4">
                    {item.until}
                  </Text>
                )}
                {typeof item.amount === 'number' && (
                  <Text
                    accessibilityLabel={`${item.label}_amount`}
                    // style={styles.amountStyle}
                    testID={`${item.label}_amount`}
                    weight="bold">
                    {formatCurrencyNumber({ value: item.amount })}
                  </Text>
                )}
              </View>
              {!isMobileOrHasOperations && renderChevronLink(item)}
            </View>
          </View>
        ))}
    </View>
  )
}
