import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { View } from 'react-native'

import {
  DashboardHeader,
  DashboardPageWrapperConnected,
  Modal,
  ModalFrame,
  OCDCard,
  useScreenSizes,
  useTheme,
  WorkInProgressContent,
  ToolCardList, ToolCardLoader, Card
} from '@components/index';
import { RouteNames } from '@constants/navigation'
import { innerNavigations } from '@constants/savings'
import { useUrlLink } from '@utils/index'

import {
  BudgetInsightAccountsListCard,
  DashboardContributionCard,
  GlobalSavings,
  RecentOperationsCard,
  RemainingSavingsTimeCard
} from './Components/index'
import { HomePageProps } from './interface'
import { useStyles } from './useStyles'
import { useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { AppNavigatorInterface } from '@navigation/Interfaces'

export const HomePageComponent: React.FC<React.PropsWithChildren<HomePageProps>> = ({
  userAccounts,
  availabilities,
  allocations,
  errors,
  allPlans,
  selectedCompany,
  operations,
  onSelectCompany,
  OCDTop,
  OCDBottom,
  onGetOCD,
  onGetTool,
  tools,
  appSettings,
  isOCDApiLoading,
  isBIApiLoading,
  isAvalabilitiesApiLoading,
  isAllocationsApiLoading,
  isToolsApiLoading,
  isOperationsApiLoading,
  EnableFeatureRegroupementContrats,
  onGetContributionByCompany,
  contributionByCompany,
  budgetInsightAccounts,
  cardTableLoading,
  onGetBudgetInsightAccounts,
  onGetBudgetInsightManagerUrl,
  onGetDashboardDataRequest,
  onRedirectToSavingsTabs,
  handleUnlockHelpPress,
  handleSapiendoPress,
  availabilityRetryAction,
  globalSavingsRetryAction,
  recentOperationsRetryaction
}) => {
  const theme = useTheme()
  const { isMobile, isTablet, isDesktop } = useScreenSizes()
  const [visible, setVisible] = useState(false)
  const [scrollValue, setScrollValue] = useState(0);
  const styles = useStyles()
  const navigation = useNavigation<StackNavigationProp<AppNavigatorInterface>>()
  const route = useRoute() as any

  const carouselSpacingHorizontal = useMemo(
    () => theme.metrics.spacing[isMobile ? 's' : 'xl'],
    [theme, isMobile]
  )

  useEffect(() => {
    if (route.params?.operationtype) {
      navigation.navigate(RouteNames.OperationTypeModalPage, {
        type: route.params?.operationtype
      })
    }
  }, [route])


  useEffect(() => {
    // TODO : Find a way to prevent double mount of conditional route screens ( seems to come from conditional root stacks )
    // prevent multiple screen fast mount to launch effect
    const hack = setTimeout(() => {
      onGetDashboardDataRequest()
    }, 100)
    return () => {
      clearTimeout(hack)
    }
  }, [])

  const setBudgetInsightManagerUrl = useCallback(() => {
    // the callback. Use a better name
    return onGetBudgetInsightManagerUrl()
  }, [onGetBudgetInsightManagerUrl])

  const renderOCDTopCard = useCallback(() => {
    return (
      <View style={styles.ocdOperationsBloc}>
        <OCDCard
          Cards={OCDTop}
          cardSize={operations && operations.length > 0 ? 'small' : 'full'}
          error={errors.OCDError}
          isLoading={isOCDApiLoading}
          key="ocd-carousel-top"
          onGetOCD={onGetOCD}
          spacingAfterLastItem={carouselSpacingHorizontal}
          spacingBeforeFirstItem={carouselSpacingHorizontal}
        />
      </View>
    )
  }, [styles, errors, OCDTop, operations, isOCDApiLoading, carouselSpacingHorizontal, onGetOCD])

  const renderRecentOperationsCard = useCallback(() => {
    const handlePressSeeAll = (planId: number | string) =>
      onRedirectToSavingsTabs(planId, innerNavigations[3])

    return (
      <View style={styles.recentOperationsBloc}>
        <RecentOperationsCard
          error={errors.recentOperationsError}
          isLoading={isOperationsApiLoading}
          onGetOperations={recentOperationsRetryaction}
          onPressSeeAll={handlePressSeeAll}
          operations={operations}
        />
      </View>
    )
  }, [
    errors,
    isOperationsApiLoading,
    recentOperationsRetryaction,
    onRedirectToSavingsTabs,
    operations,
    styles
  ])

  const renderRemainingSavingsTimeCard = useCallback(() => {
    return (
      <RemainingSavingsTimeCard
        availabilities={availabilities}
        error={errors.availabilitiesError}
        isLoading={isAvalabilitiesApiLoading}
        isMobileOrHasOperations={!!operations?.length || isMobile || isOperationsApiLoading}
        isSapiendoActive={appSettings?.EnableFeatureSapiendo}
        isUnlockHelpActive={appSettings?.EnableFeatureAideDeblocage}
        onGetAvailabilities={availabilityRetryAction}
        onSapiendoPress={handleSapiendoPress}
        onUnlockHelpPress={handleUnlockHelpPress}
      />
    )
  }, [
    errors,
    isMobile,
    operations,
    appSettings,
    availabilities,
    isAvalabilitiesApiLoading,
    handleSapiendoPress,
    handleUnlockHelpPress
  ])

  const renderGlobalSavings = useCallback(
    () => (
      <GlobalSavings
        allocations={allocations}
        allPlans={allPlans}
        error={errors?.allocationsError}
        isLoading={isAllocationsApiLoading}
        onGetAllocations={globalSavingsRetryAction}
        onPlanPress={(planId: number) => onRedirectToSavingsTabs(planId, innerNavigations[0])}
      />
    ),
    [
      errors,
      allPlans,
      allocations,
      isAllocationsApiLoading,
      onRedirectToSavingsTabs,
      globalSavingsRetryAction
    ]
  )

  const renderBugetInsightAccountsListCard = useCallback(() => {
    if (!selectedCompany?.isBudgetInsightEligible) return null

    return (
      <BudgetInsightAccountsListCard
        budgetInsightAccounts={budgetInsightAccounts}
        isError={errors.budgetInsightError?.message !== ''}
        isLoading={isBIApiLoading}
        navigation={navigation}
        onGetBudgetInsightAccounts={onGetBudgetInsightAccounts}
        setBudgetInsightManagerUrl={setBudgetInsightManagerUrl}
      />
    )
  }, [
    errors,
    navigation,
    isBIApiLoading,
    selectedCompany,
    budgetInsightAccounts,
    onGetBudgetInsightAccounts,
    setBudgetInsightManagerUrl
  ])

  const renderDashboardContrbutionCard = useCallback(() => {
    return (
      <DashboardContributionCard
        cardTableLoading={cardTableLoading}
        contributionByCompany={contributionByCompany}
        isError={errors.contributionError?.message !== ''}
        onGetContributionByCompany={onGetContributionByCompany}
      />
    )
  }, [errors, cardTableLoading, contributionByCompany, onGetContributionByCompany])

  const renderCardsContainerWithoutOCD = useCallback(() => {
    return (
      <View style={styles.cardsContainerWithoutOCD}>
        <View style={styles.containerROCandRSTC}>
          {renderRecentOperationsCard()}
          {renderRemainingSavingsTimeCard()}
        </View>
        {renderGlobalSavings()}
        {renderBugetInsightAccountsListCard()}
        {renderDashboardContrbutionCard()}
      </View>
    )
  }, [
    renderGlobalSavings,
    renderRecentOperationsCard,
    renderRemainingSavingsTimeCard,
    renderDashboardContrbutionCard,
    renderBugetInsightAccountsListCard
  ])

  const renderOCDBottomCard = useCallback(() => {
    return (
      <View style={(OCDBottom?.length >0 || !!errors.OCDError?.message || isOCDApiLoading )&& styles.commonBlocStyle}>
        <OCDCard
          Cards={OCDBottom}
          error={errors.OCDError}
          key="ocd-carousel-bottom"
          onGetOCD={onGetOCD}
          isLoading={isOCDApiLoading}
          spacingAfterLastItem={carouselSpacingHorizontal}
          spacingBeforeFirstItem={carouselSpacingHorizontal}
        />
      </View>
    )
  }, [styles, errors, OCDBottom, isAllocationsApiLoading, carouselSpacingHorizontal, onGetOCD])

  const renderToolsCardList = useCallback(() => {
    return (
      <ToolCardList
        error={errors.toolsError}
        isToolsApiLoading={isToolsApiLoading}
        onGetTools={onGetTool}
        spacingAfterLastItem={carouselSpacingHorizontal}
        spacingBeforeFirstItem={carouselSpacingHorizontal}
        toolsData={tools}
        useUrlLink={useUrlLink}
      />
    )
  }, [tools, styles, errors, isToolsApiLoading, carouselSpacingHorizontal, onGetTool])

  const renderContent = useCallback(() => {
    return (
      <View style={styles.cardsContainerStyle}>
        {renderOCDTopCard()}
        {renderCardsContainerWithoutOCD()}
        {renderOCDBottomCard()}
         <View style={styles.toolCardContainer}>
            {isToolsApiLoading && (
              <Card >
                <ToolCardLoader />
              </Card>
            )}
            {!isToolsApiLoading && (
              <ToolCardList
                error={errors.toolsError}
                onGetTools={onGetTool}
                toolsData={tools}
                useUrlLink={useUrlLink}
              />
            )}
          </View>
      </View>
    )
  }, [
    styles,
    visible,
    renderOCDTopCard,
    renderOCDBottomCard,
    renderToolsCardList,
    renderCardsContainerWithoutOCD
  ])

  const renderDashboardHeader = useCallback(
    () => (
      <DashboardHeader
        isFeatureRegroupementContratsEnabled={EnableFeatureRegroupementContrats}
        onSelectCompany={onSelectCompany}
        scrollValue={scrollValue}
        selectedCompany={selectedCompany}
        totalAmounAt={userAccounts?.dateAmount}
        userAccounts={userAccounts}
      />
    ),
    [EnableFeatureRegroupementContrats, onSelectCompany, selectedCompany, userAccounts, scrollValue]
  )

  return (
    <DashboardPageWrapperConnected
      renderStickyHeader={renderDashboardHeader}
      setScrollValue={setScrollValue}>
      <View style={styles.contentContainerStyle}>
        {renderContent()}
      </View>
    </DashboardPageWrapperConnected>
  )
}
