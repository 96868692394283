import { useNavigation } from '@react-navigation/native';
import React, { useCallback, useState } from 'react';

import {
  AnnounceContent,
  ColumnsContainer,
  Link,
  Paragraph,
  useScreenSizes,
  useTranslation
} from '@components/ERE-UILib';
import { DashboardPageWrapperConnected } from '@components/ERE360Components';
import { RouteNames } from '@constants/navigation';
import { NavigationHeader, SimpleButton } from '@ere-uilib/molecules';

import { useStyles } from './useStyles';

export const AnnuityHomePage = () => {
  const styles = useStyles();
  const { isDesktop } = useScreenSizes();
  const navigation = useNavigation();
  const { formatMessage, getMessageRaw } = useTranslation();
  const [isMore, setIsMore] = useState<boolean>(false);

  const goToSimulator = useCallback(
    () =>
      navigation.navigate(RouteNames.AnnuityStack, {
        screen: RouteNames.AnnuitySimulator
      }),
    [navigation]
  );

  const readMore = () => setIsMore(true);

  const close = useCallback(() => navigation.goBack(), [navigation]);

  const renderActions = useCallback(
    () => (
      <SimpleButton
        design="solid"
        onPress={goToSimulator}
        size="large"
        title={formatMessage({ id: 'PensionSimulatorStartSimulationButton' })}
      />
    ),
    [formatMessage, goToSimulator]
  );

  const renderHeader = useCallback(
    () => (
      <NavigationHeader
        displayCloseButton
        helpPoint
        helpPointContentHtml={getMessageRaw({ id: 'PensionSimulatorHelpText' })}
        helpPointModalTitle={formatMessage({ id: 'PensionSimulatorTitle' })}
        helpPointPosition="left"
        onClose={close}
        title={formatMessage({ id: 'PensionSimulatorTitle' })}
      />
    ),
    [formatMessage, close]
  );

  return (
    <DashboardPageWrapperConnected renderStickyMobileBottom={renderActions}>
      <ColumnsContainer
        hasColumnCenter
        hasRenderRightDesktopColumn={false}
        leftColumnStyle={styles.content}
        renderHeader={renderHeader}>
        <AnnounceContent
          actions={() => isDesktop && renderActions()}
          description={formatMessage({ id: 'PensionSimulatorCalculateYourPensionMessage' })}
          sourceName="moneyAndCalendar"
          subtitleStyle={styles.subtitle}
          svgContainer={styles.svgContainer}
          title={formatMessage({ id: 'PensionSimulatorWelcomeMessage' })}>
          <Paragraph
            autoMargin
            style={styles.paragraph}
            variant="t4"
            weight="light">
            {formatMessage({ id: 'PensionSimulatorCalculateYourPensionWarningMessage' })}
          </Paragraph>

          {!isDesktop && !isMore && (
            <Link
              onPress={readMore}
              textVariant="t4"
              textWeight="regular">
              {formatMessage({ id: 'PensionSimulatorCalculateYourPensionWarningMessageReadMore' })}
            </Link>
          )}

          {(isDesktop || isMore) && (
            <Paragraph
              style={styles.paragraph}
              variant="t4"
              weight="light">
              {formatMessage({ id: 'PensionSimulatorCalculateYourPensionWarningMessageMore' })}
            </Paragraph>
          )}
        </AnnounceContent>
      </ColumnsContainer>
    </DashboardPageWrapperConnected>
  );
};
