
import { AuthActionType, AuthenticateAction } from '@modules/auth/actions/authActionsTypes';
import { AccountsAction } from '@modules/dashboard/actions/dashboardActionsTypes';

import * as actions from './actions/profitSharingIncentiveActionsTypes';
import { ProfitSharingIncentiveActionsType } from './actions/profitSharingIncentiveActionsTypes';
import { ProfitSharingIncentiveState } from './types';
import {
  updateProfitSharingIncentiveRepartitionAmountsData
} from './utils';

export const initialState: ProfitSharingIncentiveState = {
  initData: null,
  repartition: null,
  repartitionAmountModalVisibleState: false,
  repartitionRetirementValidationModalVisibleState: false,
  calculatedContributionError: {
    code: '',
    correlationId: '',
    message: '',
    innerMessage: '',
  },
  calculatedContribution: null,
  calculateContributionInput: [],
  asignmentAmounts: null,
  error: {
    code: '',
    correlationId: '',
    message: '',
    innerMessage: '',
  },
};

export default function profitSharingIncentiveReducer(
  state: ProfitSharingIncentiveState = initialState,
  action:
    | actions.ProfitSharingIncentiveAction
    | AuthenticateAction
    | AccountsAction
): ProfitSharingIncentiveState {
  switch (action?.type) {
    case ProfitSharingIncentiveActionsType.GET_PARTICIPATION_PROFITSHARING_INIT_DATA_REQUEST:
      return {
        ...initialState
      };
    case ProfitSharingIncentiveActionsType.GET_PARTICIPATION_PROFITSHARING_INIT_DATA_SUCCESS:
      return {
        ...state,
        initData: action.initData
      };
    case ProfitSharingIncentiveActionsType.GET_PARTICIPATION_PROFITSHARING_REPARTITION_REQUEST:
      return {
        ...state,
        repartition: initialState.repartition,
        asignmentAmounts: initialState.asignmentAmounts,
        error: initialState.error
      };
    case ProfitSharingIncentiveActionsType.SET_PARTICIPATION_PROFITSHARING_ASSIGMENT_ALLREFUND_AMOUNTS:
      return {
        ...state,
        repartition: initialState.repartition,
        error: initialState.error,
        asignmentAmounts: action.amounts,
      };
    case ProfitSharingIncentiveActionsType.GET_PARTICIPATION_PROFITSHARING_REPARTITION_SUCCESS:
      return {
        ...state,
        repartition: {
          plans: action.plans
        },
        asignmentAmounts: action.amounts
      };
    case ProfitSharingIncentiveActionsType.SET_PARTICIPATION_PROFITSHARING_REPARTITION_AMOUNT_MODAL_VISIBLE_STATE:
      return {
        ...state,
        repartitionAmountModalVisibleState: action.state,
      };
    case ProfitSharingIncentiveActionsType.SET_PARTICIPATION_PROFITSHARING_REPARTITION_RETIREMENT_VALIDATION_MODAL_VISIBLE_STATE:
      return {
        ...state,
        repartitionRetirementValidationModalVisibleState: action.state,
      };
    case ProfitSharingIncentiveActionsType.SET_PARTICIPATION_PROFITSHARING_REPARTITION_AMOUNT_SUCCESS:
      if (action.profitSharingIncentiveAmountValidationParams) {
        return {
          ...state,
          repartitionAmountModalVisibleState: false,
          repartition: updateProfitSharingIncentiveRepartitionAmountsData({
            previousRepartitionData: state.repartition,
            calculatedContribution: action.calculatedContribution,
            ProfitSharingIncentiveAmountValidationParams: action.profitSharingIncentiveAmountValidationParams,
          }),
          // if we get calculatedContribution we can reset calculatedContributionError
          // else we keep calculatedContributionError state as it is
          calculatedContributionError: action.calculatedContribution ?
            initialState.calculatedContributionError
            : state.calculatedContributionError,
        };
      } else {
        return state
      }

    case ProfitSharingIncentiveActionsType.SET_PARTICIPATION_PROFITSHARING_REPARTITION_AMOUNT_FAILURE:
      if (!action.params) {
        return {
          ...state,
          calculatedContributionError: action.error,
          repartitionAmountModalVisibleState: false,
        };
      }

      return {
        ...state,
        calculatedContributionError: action.error,
        repartitionAmountModalVisibleState: false,
        repartition: updateProfitSharingIncentiveRepartitionAmountsData({
          previousRepartitionData: state.repartition,
          ProfitSharingIncentiveAmountValidationParams: action.params,
        }),
      };

    case ProfitSharingIncentiveActionsType.GET_PARTICIPATION_PROFITSHARING_CALCULATED_CONTRIBUTION_REQUEST:
      return {
        ...state,
        calculatedContribution: initialState.calculatedContribution
      };

    case ProfitSharingIncentiveActionsType.GET_PARTICIPATION_PROFITSHARING_CALCULATED_CONTRIBUTION_SUCCESS:
      return {
        ...state,
        calculatedContribution: action.contributionData
      };
    case ProfitSharingIncentiveActionsType.RESET_PARTICIPATION_PROFITSHARING_CALCULATED_CONTRIBUTION:
      return {
        ...state,
        calculatedContribution: initialState.calculatedContribution
      };
    case ProfitSharingIncentiveActionsType.GET_PARTICIPATION_PROFITSHARING_REPARTITION_FAILURE:
    case ProfitSharingIncentiveActionsType.GET_PARTICIPATION_PROFITSHARING_INIT_DATA_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case ProfitSharingIncentiveActionsType.SET_ESTIMATE_CONTRIBUTION_INPUT:
      return {
        ...state,
        calculateContributionInput: action.payload
      };
    case ProfitSharingIncentiveActionsType.CLEAR_PROFIT_SHARING_INCENTIVE_DATA:
    case AuthActionType.LOGOUT_CALLBACK_SUCCESS:

      return initialState;
    default:
      return state;
  }
}