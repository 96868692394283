import { useMemo } from 'react';
import { StyleSheet, Platform } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

export const useStyles = () => {
  const theme = useTheme();

  return useMemo(
    () =>
      StyleSheet.create({
        modalContainer: {
          flex: 1,
          backgroundColor: theme.colors.basics.white
        },
        mainContainer: { flex: 1 },
        viewContainer: {
          flex: 1
        },
        menuWrapper: {
          paddingBottom: theme.metrics.spacing.xxl
        },
        linksContainerRow: {
          paddingVertical: theme.metrics.spacing.xm,
          paddingHorizontal: theme.metrics.spacing.s,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between'
        },
        separator: {
          height: 1,
          width: '100%',
          backgroundColor: theme.colors.basics.grey.c200
        },
        titleContainer: {
          height: 65,
          justifyContent: 'center',
          paddingLeft: theme.metrics.spacing.s
        },
        titleContainerWhenComponentScrolled: {
          shadowColor: 'rgba(96, 97, 112, 0.2)',
          shadowOffset: {
            width: 0,
            height: 6
          },
          shadowOpacity: 1,
          shadowRadius: 15
        },
        buttonContainerStyle: {
          marginLeft: theme.metrics.spacing.s,
          height: 32,
          alignSelf: 'flex-start',
          paddingVertical: 0,
          marginBottom: 75
        },
        wishMenuLinks: {
          marginBottom: theme.metrics.spacing.xm
        }
      }),
    [theme]
  );
};
