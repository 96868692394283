import { all, call, delay, fork, put, select, takeLatest } from 'redux-saga/effects';

import { mockDocumentsFilesApi, mockEDocuments } from '@__mocks__/mockDocuments';
import {
  getDocumentBlobSuccess,
  getDocumentsCategoriesSuccess,
  getEDocumentsSubscriptionSuccess,
  getEDocumentsSuccess
} from '@modules/document-space/actions/documentsActions';
import {
  DocumentsActionsType,
  GetDocumentBlobRequest,
  GetDocumentsCategoriesRequest,
  GetEDocumentsSubscriptionRequest,
} from '@modules/document-space/actions/documentsActionsTypes';
import { runManager } from '@modules/moduleManager';
import { getUsedCompanyId } from '@modules/dashboard/selectors';
import { getLanguage } from '@modules/label/selectors';
import {
  fetchDocumentsCategories,
  fetchDownloadDocumentByType,
  fetchDownloadDocumentById,
  fetchEDocuments,
  postEDocumentsSubscription
} from './services';

import { DocumentApiFile, DocumentCategory } from './types';
import { Platform } from 'react-native';
import { openDocumentDesktop } from '@utils/openDocumentDesktop';
import { RootNavigation } from '@navigation/RootNavigation';
import { RouteNames } from '@constants/navigation';

function* getDocumentsCategories(action: GetDocumentsCategoriesRequest): any {
  const companyId = yield select(getUsedCompanyId)
  const language = yield select(getLanguage)
  const response = yield call(fetchDocumentsCategories, companyId, language);

  // uncomment to use mock
  // yield delay(1500);
  // const response = {
  //   data: mockDocumentsFilesApi
  // };

  const getDocumentCategories = (data: DocumentApiFile[] | null | undefined) => {
    if (!data?.length) return [];

    const tagMap = data.reduce<Map<string, DocumentCategory>>((acc, file) => {
      file.searchTags.map(searchTag => acc.set(searchTag, { id: searchTag, name: searchTag }));
      return acc;
    }, new Map());

    return Array.from(tagMap.values());
  }

  const documentFiles = response?.data ?? [];
  const documentCategories = getDocumentCategories(documentFiles);
  yield put(getDocumentsCategoriesSuccess(documentCategories, documentFiles));
}

function* getDocumentBlob({ documentType, documentTitle, documentId }: GetDocumentBlobRequest): any {
  const companyId = yield select(getUsedCompanyId);
  const language = yield select(getLanguage);
  let response;

  if (documentId)
    response = yield call(fetchDownloadDocumentById, companyId, documentId);

  if (documentType)
    response = yield call(fetchDownloadDocumentByType, companyId, documentType, language);

  const documentBlob = response?.data ?? "";
  if (Platform.OS === "web") {
    yield openDocumentDesktop(documentBlob)
  } else {
    const reader = new FileReader();
    reader.readAsDataURL(documentBlob);
    reader.onloadend = () => {
      const base64data = reader.result?.toString();
      RootNavigation.navigate(RouteNames.DocumentSpaceStack, {
        screen: RouteNames.DocumentSpaceViewer,
        params: {
          base64PDF: `data:application/pdf;${base64data?.split(';')[1]}`,
          documentTitle
        }
      })
    }
  }

  yield put(getDocumentBlobSuccess(documentBlob));
}


function* getDocumentsCategoriesSagas() {
  yield takeLatest(
    DocumentsActionsType.GET_DOCUMENTS_CATEGORIES_REQUEST,
    runManager(getDocumentsCategories, DocumentsActionsType.GET_DOCUMENTS_CATEGORIES_FAILURE)
  );
}

function* getDocumentBlobSagas() {
  yield takeLatest(
    DocumentsActionsType.GET_DOCUMENT_BLOB_RESPONSE_REQUEST,
    runManager(getDocumentBlob, DocumentsActionsType.GET_DOCUMENT_BLOB_RESPONSE_FAILURE)
  );
}

function* getEDocuments(): any {
  const companyId = yield select(getUsedCompanyId);

  // Uncomment to use mock data
  // yield delay(5000);
  // const response = {
  //   data: mockEDocuments
  // }

  const response = yield call(fetchEDocuments, companyId);
  yield put(getEDocumentsSuccess(response.data));
}

function* getEDocumentsSagas(): any {
  yield takeLatest(
    DocumentsActionsType.GET_EDOCUMENTS_REQUEST,
    runManager(getEDocuments, DocumentsActionsType.GET_EDOCUMENTS_FAILURE)
  );
}

function* setEDocumentsSubscription({ isSubscriber }: GetEDocumentsSubscriptionRequest): any {
  const companyId = yield select(getUsedCompanyId);

  yield call(postEDocumentsSubscription, companyId, isSubscriber);

  yield put(getEDocumentsSubscriptionSuccess(isSubscriber));
}

function* setEDocumentsSubscriptionSagas(): any {
  yield takeLatest(
    DocumentsActionsType.GET_EDOCUMENTS_SUBSCRIPTION_REQUEST,
    runManager(setEDocumentsSubscription, DocumentsActionsType.GET_EDOCUMENTS_SUBSCRIPTION_FAILURE)
  );
}

export function* documentsSagas() {
  yield all([
    fork(getDocumentsCategoriesSagas),
    fork(getDocumentBlobSagas),
    fork(getEDocumentsSagas),
    fork(setEDocumentsSubscriptionSagas)
  ]);
}