import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import React, { useCallback, useMemo } from 'react';
import { View } from 'react-native';

import {
  ColumnsContainer,
  DashboardPageWrapperConnected,
  NavigationHeader,
  Paragraph,
  SimpleButton,
  SVGLocalLoader,
  Title,
  useScreenSizes,
  useTheme,
  useTranslation
} from '@components/index';
import { createUseStyles } from '@ere-uilib/styles';

import { RouteNames } from '@constants/navigation';
import { AppNavigatorInterface, AppNavigatorRouteNames } from "@navigation/Interfaces";

import { BudgetInsightCallbackState } from './interfaces';
import { getStyles } from './styles';
import { RootNavigation } from '@navigation/RootNavigation';

export const BudgetInsightCallbackComponent: React.FC<React.PropsWithChildren<BudgetInsightCallbackState>> = ({ }) => {
  const { formatMessage, getMessageRaw } = useTranslation();
  const { isMobile, isTablet } = useScreenSizes();
  const theme = useTheme();
  const styles = useStyles({ theme, isMobile }, {});
  const navigation = useNavigation();
  const route = useRoute<RouteProp<AppNavigatorInterface, AppNavigatorRouteNames.BiCallback>>();
  const errorId = route.params?.errorId

  const renderHeader = useCallback(() => {
    return (
      <NavigationHeader
        displayCloseButton
        helpPoint={true}
        helpPointContentHtml={getMessageRaw({ id: 'AgregationCardConsentementInfoDescription' })}
        helpPointModalTitle={formatMessage({ id: 'AgregationCardConsentementInfoTitre' })}
        helpPointPosition="left"
        onClose={() => {
          navigation.navigate(RouteNames.BottomTabNavigator, {
            screen: RouteNames.Home
          });
        }}
        title={formatMessage({ id: 'AgregationCardConsentementTitre' })}
      />
    );
  }, []);

  const renderSubmitButton = useCallback(() => {
    return (
      <SimpleButton
        onPress={() => {
          RootNavigation.replace(RouteNames.BottomTabNavigator, {
            screen: RouteNames.Home
          });
        }}
        title={formatMessage({ id: 'VV_boutonPaiement_succes' })}
      />
    );
  }, []);

  const renderSvgLoader = useCallback(() => {
    const svgLoaderName = errorId ? 'invitationError' : 'budgetinsightSuccess'
    return <SVGLocalLoader name={svgLoaderName} />
  }, [errorId])

  const renderMessage = useCallback((translationKey: string) => {
    return formatMessage({ id: translationKey })
  }, [])
  const title = useMemo(() => {
    const translationKey = errorId ? 'FCenrollementModaleKOTitre' : 'AgregationCardPageConfirmationTitre'
    return renderMessage(translationKey)
  }, [errorId])

  const description = useMemo(() => {
    const translationKey = errorId || 'AgregationCardPageConfirmationDescription'
    return renderMessage(translationKey)
  }, [errorId])

  const renderInnerView = useCallback(() => {
    return (
      <>
        <View style={styles.innerCardStyle}>
          <View style={styles.innerView}>
            {renderSvgLoader()}
          </View>
          <Title
            style={styles.titleStyle}
            variant="t4"
            weight="bold">
            {title}
          </Title>
          <Paragraph
            style={styles.contentStyle}
            variant="t3"
            weight="light">
            {description}
          </Paragraph>
          {!(isMobile || isTablet) && renderSubmitButton()}
        </View>
      </>
    )
  }, [isMobile, isTablet, errorId])

  return (
    <DashboardPageWrapperConnected
      cardContentStyle={styles.containerStyle}
      renderStickyMobileBottom={renderSubmitButton}
    >
      <ColumnsContainer
        containerStyle={styles.containerStyle}
        hasColumnCenter
        renderHeader={renderHeader}>
        {renderInnerView()}
      </ColumnsContainer>
    </DashboardPageWrapperConnected>
  );
};

const useStyles = createUseStyles(getStyles);
