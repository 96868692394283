import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

export const useStyles = (isDisabled: boolean) => {
  const theme = useTheme();

  const styles = useMemo(() => {
    const headerBackgroundColor = isDisabled ? theme.colors.basics.grey.c100 : theme.colors.background.greenBackground.c100;

    return StyleSheet.create({
      container: {
        overflow: 'hidden',
        borderWidth: 1,
        borderColor: theme.colors.basics.grey.c200,
        borderRadius: theme.metrics.borderRadius.xs
      },
      cardHeader: {
        paddingVertical: theme.metrics.spacing.m,
        paddingHorizontal: theme.metrics.spacing.s,
        backgroundColor: headerBackgroundColor
      },
      cardHeaderTop: {
        flexDirection: 'row',
        justifyContent: 'space-between'
      },
      description: {
        paddingTop: theme.metrics.spacing.m
      },
      cardContent: {
        paddingVertical: theme.metrics.spacing.m,
        paddingHorizontal: theme.metrics.spacing.s
      }
    });
  }, [isDisabled, theme]);

  return styles;
}