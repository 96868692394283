import React from 'react';
import { connect } from 'react-redux';

import { submitOadReallocationsRequest } from '@modules/arbitration/actions/arbitrationActions';
import { ArbitrationActionsType } from '@modules/arbitration/actions/arbitrationActionsTypes';
import {
  getArbitrationOadManualRepartitionData,
  getArbitrationOadRepartitionData,
  getArbitrationOadSimulationData,
  getRiskProfileState,
  getPlanCompartments,
  getSelectedCompartment
} from '@modules/arbitration/selectors';
import { AppState } from '@modules/reducers';

import { ArbitrationOADRepartitionConfirmationPage } from './ArbitrationOADRepartitionConfirmationPage.component';

const mapStateToProps = (state: AppState) => {
  return {
    riskProfileData: getRiskProfileState(state),
    repartitionData: getArbitrationOadRepartitionData(state),
    simulationData: getArbitrationOadSimulationData(state),
    manualRepartitionData: getArbitrationOadManualRepartitionData(state),
    isLoading: state.isLoading[ArbitrationActionsType.SUBMIT_OAD_REALLOCATIONS_REQUEST],
    selectedCompartment: getSelectedCompartment(state),
    planData: getPlanCompartments(state)
  };
};

const mapDispatchToProps = {
  submitOadReallocations: submitOadReallocationsRequest
};

export const ArbitrationOADRepartitionConfirmationPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ArbitrationOADRepartitionConfirmationPage);
