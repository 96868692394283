import { all, call, fork, put } from '@redux-saga/core/effects';
import { takeLatest } from 'redux-saga/effects';

import { runManager } from '@modules/moduleManager';
import { fetchDataAnnuityResult } from './services';

import * as annuityActions from './actions/annuityActions';
import {
  AnnuityActionsType,
  SetAnnuitySimulationDataRequestAction
} from './actions/annuityActionsTypes';

function* setSimulationAnnuityData(action: SetAnnuitySimulationDataRequestAction): any {
  const response = yield call(fetchDataAnnuityResult, action.simulationData);
  const { capitalBasedSimulation, annuityBasedSimulation } = response?.data ?? {};

  const data = {
    value:
      capitalBasedSimulation?.annuity ||
      annuityBasedSimulation?.capital,
    guaranteedValue:
      capitalBasedSimulation?.guaranteedAnnuity ||
      annuityBasedSimulation?.guaranteedCapital,
    reversionValue:
      capitalBasedSimulation?.reversionAnnuity ||
      annuityBasedSimulation?.reversionCapital,
    guaranteedReversionValue:
      capitalBasedSimulation?.guaranteedReversionAnnuity ||
      annuityBasedSimulation?.guaranteedReversionCapital
  };

  yield put(annuityActions.setAnnuitySimulationDataSuccess(data));
}

function* setSimulationAnnuityDataSagas(): any {
  yield takeLatest(
    AnnuityActionsType.SET_ANNUITY_SIMULATION_DATA_REQUEST,
    runManager(setSimulationAnnuityData, AnnuityActionsType.SET_ANNUITY_SIMULATION_DATA_FAILURE)
  );
}

export function* AnnuitySagas() {
  yield all([fork(setSimulationAnnuityDataSagas)]);
}
