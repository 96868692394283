import { DocumentsActionsType } from '@modules/document-space/actions/documentsActionsTypes';
import {
  getDocumentBlobRequestByType,
  setDocumentsCategoriesFilter
} from '@modules/document-space/actions/documentsActions';
import {
  getDocumentBlob,
  getDocumentsCategories,
  getDocumentsCategoriesSelected,
  getDocumentsFiles
} from '@modules/document-space/selectors';
import { AppState } from '@modules/reducers';
import { connect } from 'react-redux';
import { GeneralDocumentsFiles as GeneralDocumentsFilesComponent } from './GeneralDocumentsFiles.component';



const mapStateToProps = (state: AppState) => ({
  categories: getDocumentsCategories(state),
  selectedCategories: getDocumentsCategoriesSelected(state),
  files: getDocumentsFiles(state),
  blobContent: getDocumentBlob(state),
  isDocumentDonwloading: state.isLoading[DocumentsActionsType.GET_DOCUMENT_BLOB_RESPONSE_REQUEST],
});

const mapDispatchToProps = ({
  onSetDocumentsCategoriesFilter: setDocumentsCategoriesFilter,
  onGetDocumentDownloadFile: getDocumentBlobRequestByType
});

export const GeneralDocumentsFiles = connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneralDocumentsFilesComponent);
