import React from 'react';
import { View } from 'react-native';

import { Text, Title } from '@ere-uilib/atoms';
import { createUseStyles, useTheme } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';
import { StepperStatus } from '@ere-uilib/types';

import { InstallmentsStepper } from '../InstallmentsStepper';
import { DirectionEnum, StepperItemType } from '../InstallmentsStepper/interfaces';
import { WalkthroughStepperProps, WalkthroughStepperStyles } from './interface';
import { getStyles } from './styles';

export const WalkthroughStepper: React.FC<React.PropsWithChildren<WalkthroughStepperProps & WalkthroughStepperStyles>> = ({
  stepOneDone = false,
  stepTwoDone = false,

  leftContainerStyle,
  leftContainerTitleStyle,
  leftContainerRowStyle,
  stepNumberViewSelectedStyle,
  stepNumberViewTitleStyle,
  stepNumberViewTitleSelectedStyle,
  stepListStyle,
  stepTitleStyle,
  stepTitleSelectedStyle,
  stepNumberViewStyle,
  stepSubtitleStyle,
  stepSubtitleSelectedStyle,
  stepSubtitleBlackStyle,
}) => {
  const theme = useTheme();
  const { formatMessage } = useTranslation();

  const styles = useStyles(
    {
      theme,
      stepOneDone,
    },
    {
      leftContainerStyle,
      leftContainerTitleStyle,
      leftContainerRowStyle,
      stepNumberViewSelectedStyle,
      stepNumberViewTitleStyle,
      stepNumberViewTitleSelectedStyle,
      stepListStyle,
      stepTitleStyle,
      stepTitleSelectedStyle,
      stepNumberViewStyle,
      stepSubtitleStyle,
      stepSubtitleSelectedStyle,
      stepSubtitleBlackStyle,
    },
  );

  const initialState: StepperItemType[] = [
    {
      title: formatMessage({ id: 'validate-inscription_step1_label' }),
      status: stepOneDone ? StepperStatus.DONE : StepperStatus.DEFAULT,
    },
    {
      title: formatMessage({ id: 'validate-inscription_step2_label' }),
      status: (stepOneDone && stepTwoDone) ? StepperStatus.DONE :
        !stepOneDone ? StepperStatus.TO_DO : StepperStatus.DEFAULT,
      children: (
        <>
          <Text
            style={styles.stepSubtitleStyle}
            variant="t3"
            weight="light"
          >
            {formatMessage({ id: 'validate-inscription_step2_sub-label_id' })}
          </Text>
          <Text
            style={styles.stepSubtitleStyle}
            variant="t3"
            weight="light"
          >
            {formatMessage({ id: 'validate-inscription_step2_sub-label_pwd' })}
          </Text>
        </>
      ),
    },
  ];

  return (
    <View style={styles.leftContainerStyle}>
      <Title
        style={styles.leftContainerTitleStyle}
        variant="t4"
        weight="bold"
      >
        {formatMessage({ id: 'validate-inscription_title' })}
      </Title>

      <View style={styles.leftContainerRowStyle} >
        <InstallmentsStepper
          direction={DirectionEnum.COLUMN}
          lineLength={60}
          stepperItems={initialState}
        />
      </View>

    </View>
  );
};

const useStyles = createUseStyles(getStyles);
