export enum OtpValidationNavigatorRouteNames {
  OtpValidationStart = 'OtpValidationStart',
  OtpValidationSuccess = 'OtpValidationSuccess',
  OtpValidationForm = 'OtpValidationForm',
}

export type OtpValidationNavigatorInterface = {
  [OtpValidationNavigatorRouteNames.OtpValidationStart]: undefined
  [OtpValidationNavigatorRouteNames.OtpValidationSuccess]: undefined
  [OtpValidationNavigatorRouteNames.OtpValidationForm]: undefined
}
