import React from 'react';
import { View } from 'react-native';

import { Text, Title } from '@ere-uilib/atoms/texts';
import { SlideButton } from '@ere-uilib/molecules/';
import { useScreenSizes } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';

import { useStyles } from './styles';

interface SavingsHeaderBandProps {
  addedValue?: number
  titleKey: string
  amount?: number
  swipeLeft: () => void,
  swipeRight: () => void
  savingPlansLength: number
  selectedPlan: number
}

export const SavingsHeaderBand = ({
  addedValue,
  titleKey,
  amount,
  swipeLeft,
  swipeRight,
  savingPlansLength,
  selectedPlan,
}: SavingsHeaderBandProps) => {
  const { isDesktop } = useScreenSizes()
  const { formatCurrencyNumber, formatMessage } = useTranslation();
  const styles = useStyles(addedValue);
  const isSlideButtonShown = (position: string) => {
    if (position === 'right') {
      return savingPlansLength > 1 && selectedPlan >= 0 && selectedPlan < savingPlansLength - 1;
    }
    if (position === 'left') {
      return savingPlansLength > 1 && selectedPlan > 0 && selectedPlan <= savingPlansLength - 1;
    }
  };
  return (
    <View style={styles.container}>
      <View style={styles.buttonView}>
        {isSlideButtonShown('left') && (
          <SlideButton
            direction="gauche"
            onClick={swipeLeft}
          />
        )}
      </View>

      <View style={styles.amountContainer}>
        <Title
          testId="my_savings_rendered_title"
          variant="t6">
          {titleKey}
        </Title>

        <Title
          style={styles.amount}
          testId="my_savings_amount"
          variant={isDesktop ? 't2s' : 't4'}
          weight="bold">
          {formatCurrencyNumber({ value: amount || 0 })}
        </Title>

        {addedValue !== null && addedValue !== undefined && (
          <Text
            style={styles.addedValueStyle}
            testId="my_savings_potentiel_gain_loss"
            variant="t4">
            {`${formatMessage({ id: 'saving_plus-minus-value' })} ${formatCurrencyNumber({
              value: addedValue || 0
            })}`}
          </Text>
        )}
      </View>

      <View style={styles.buttonView}>
        {isSlideButtonShown('right') && (
          <SlideButton
            direction="droite"
            onClick={swipeRight}
          />
        )}
      </View>
    </View>
  );
};
