import React from 'react';
import { View } from 'react-native';

import {
  DashboardPageWrapperConnected,
  NavigationHeader,
  InstallmentsStepper,
  ColumnsContainer,
  WebView,
  WebViewSourceTypeEnum,
} from '@components/index';
import { RouteNames } from '@constants/navigation';
import {
  DirectionEnum,
  StepperSizeEnum,
} from '@ere-uilib/molecules/steppers/InstallmentsStepper/interfaces';
import { createUseStyles, useTheme, useScreenSizes } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';
import { InstallmentActionsType } from '@modules/installment/actions/installmentActionsTypes';
import { constructStepperItems } from '@pages/Common/utils';

import { InstallmentPaymentCBHtmlPageProps } from './interfaces';
import { getStyles } from './styles';
import {RouteProp, useNavigation, useRoute} from '@react-navigation/native';

export const InstallmentPaymentCBHtmlPageComponent: React.FC<InstallmentPaymentCBHtmlPageProps> = ({ installmentPaymentResponseData }) => {
  const { isMobile, isTablet, windowHeight } = useScreenSizes();
  const theme = useTheme();
  const navigation = useNavigation();
  const { formatMessage } = useTranslation();
  type InstallmentPaymentCBHtmlParam = {
    InstallmentPaymentCBHtml: {
      source: WebViewSourceTypeEnum
    }
  }
  function getQueryParams(url: string) {
    type queryParamsType = any
    const paramArr = url.slice(url.indexOf('?') + 1).split('&');
    const params: queryParamsType = {}
    paramArr.map((param: any) => {
      const [key, val] = param.split('=');
      params[key] = decodeURIComponent(val);
    })
    return params;
  }

  const handlePaymentRedirect = (url: string) => {
    const urlParams = getQueryParams(url)
    const idparam = urlParams.id
    navigation.navigate(RouteNames.InstallmentStack, {
      screen: RouteNames.InstallmentLoading,
      params: {
        id: idparam || '',
      },
    });
  }
  const route = useRoute<RouteProp<InstallmentPaymentCBHtmlParam, 'InstallmentPaymentCBHtml'>>();
  const source = route.params.source
  const styles = useStyles({ theme, isMobile, isTablet, windowHeight }, {});
  const stepperItems = constructStepperItems({
    size: 4,
    direction: DirectionEnum.ROW,
    activeStep: 4,
  });

  const renderHeader = () => (
    <>
      <NavigationHeader
        containerStyle={{
          borderBottomWidth: 1,
          borderBottomColor: theme.colors.basics.grey.c200,
        }}
        displayCloseButton
        headerLine={{ borderBottomWidth: 0 }}
        helpPoint={false}
        onClose={() =>
          navigation.navigate(RouteNames.InstallmentStack, {
            screen: RouteNames.InstallmentModal,
            params: {
              clearAction: InstallmentActionsType.CLEAR_INSTALLMENT_DATA,
            },
          })
        }
        title={formatMessage({ id: 'VV_payment_CB_loading_title' })}
      >
        <View style={styles.installmentsStepperContainerStyle}>
          <InstallmentsStepper
            containerStyle={styles.stepperStyle}
            stepperItems={stepperItems}
            stepperSize={isMobile ? StepperSizeEnum.SMALL : StepperSizeEnum.BIG}
          />
        </View>
      </NavigationHeader>
    </>
  );
  const renderMainContent = () => {
    return (
      <WebView
        onNavigationStateChange={(url: string) => handlePaymentRedirect(url)}
        sourceHtml={{
          html: installmentPaymentResponseData?.payementHtmlPageCode,
          linkOpenedToTop: true,
        }}
        sourceUri={{ uri: installmentPaymentResponseData?.payementHtmlPageCode || '' }}
        sourceType={source}
        style={styles.HtmlFrameStyle}
      />
    );
  };

  return (
    <DashboardPageWrapperConnected
      cardContentStyle={{ backgroundColor: theme.colors.basics.white }}
    >
      <View style={styles.containerStyle}>
        <ColumnsContainer
          hasRenderRightDesktopColumn={false}
          renderHeader={() => renderHeader()}
        >
          {renderMainContent()}
        </ColumnsContainer>
      </View>
    </DashboardPageWrapperConnected>
  );
};

const useStyles = createUseStyles(getStyles);
