import React, { useCallback, useState } from 'react';
import { View } from 'react-native';

import { FontIcon, Pressable, Title } from '@ere-uilib/atoms';
import { createUseStyles, useTheme } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';

import { ExpendableLineProps } from './interfaces';
import { getStyles } from './styles';

export const ExpendableLine: React.FC<React.PropsWithChildren<ExpendableLineProps>> = ({
  title,
  amount,
  isOpened = false,
  onChange,
  size = 'small',
  isHeaderDoubleBorder = false,
  disabled = false,
  children,
  titleWeight = 'regular',
  design = 'white',
  iconName,
  iconDesign = {
    size: 18,
  },
  arrowPosition = 'left',
  iconPosition = 'left',
  containerStyle,
  headerStyle,
  iconStyle,
  titleStyle,
  amountStyle,
  contentStyle,
  arrowStyle,
  testId
}) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const theme = useTheme();
  const styles = useStyles({
    theme,
    isOpened,
    isHovered,
    design,
    disabled,
    isHeaderDoubleBorder,
    size,
    arrowPosition,
    iconPosition,
  },
    {
      containerStyle,
      headerStyle,
      iconStyle,
      titleStyle,
      contentStyle,
      amountStyle,
      arrowStyle,
    });
  const onHeaderPress = () => {
    onChange && onChange(!isOpened);
  };

  const { formatCurrencyNumber } = useTranslation();

  const toggleHovered = useCallback(() => setIsHovered(isHoveredNow => !isHoveredNow), []);

  return (
        <View style={styles.containerStyle}>
          <Pressable
            disabled={disabled}
            onHoverIn={toggleHovered}
            onHoverOut={toggleHovered}
            onPress={onHeaderPress}
            style={styles.headerStyle}>
            {
              !disabled && arrowPosition === 'left' &&
              <FontIcon
                name={isOpened ? 'chevron-haut' : 'chevron-bas'}
                size={iconDesign?.size}
                style={styles.arrowStyle}
              />
            }
            {
              iconName && iconPosition === 'left' &&
              <FontIcon
                color={iconDesign?.color}
                name={iconName}
                size={iconDesign?.size}
                style={[styles.iconStyle, iconDesign?.style]}
              />
            }

            <Title
              style={styles.titleStyle}
              variant={size === 'small' ? 't7' : 't6'}
              weight={titleWeight} >
              {title}
            </Title>

            {typeof amount === 'number' && (
              <Title
                testId={testId}
                style={styles.amountStyle}
                variant={size === 'small' ? 't7' : 't6'}
                weight="bold"
              >
                {formatCurrencyNumber({ value: amount })}
              </Title>
            )}

            {
              iconName && iconPosition === 'right' &&
              <FontIcon
                color={iconDesign?.color}
                name={iconName}
                size={iconDesign?.size}
                style={[styles.iconStyle, iconDesign?.style]}
              />
            }

            {
              !disabled && arrowPosition === 'right' &&
              <FontIcon
                name={isOpened ? 'chevron-haut' : 'chevron-bas'}
                size={iconDesign?.size}
                style={styles.arrowStyle}
              />
            }
          </Pressable>
          <View
            pointerEvents={isOpened ? 'auto' : 'none'}
            style={styles.contentStyle}>
            {children}
          </View>
        </View>
  );
};

const useStyles = createUseStyles(getStyles);
