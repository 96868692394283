export enum NotificationSystemNameEnum {
  CONTRIBUTION_ERROR,
  ARBITRATION_NO_DESTINATION_ERROR
}

export enum NotificationType {
  WARNING,
  INFO,
}

export interface Notification {
  type: NotificationType,
  timeout?: number,
  uid: string,
  name: NotificationSystemNameEnum
}
