import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';

import { RouteNames } from '@constants/navigation';
import { useDefaultScreensOptions } from '@navigation/useDefaultScreensOptions';
import { ConfirmModalPageComponent } from '@pages/Common';
import { 
  RetirementOnboardingPageContainer, 
  RetirementPageContainer, 
  RetirementRecoverSavingPage,
} from '@pages/Retirement';
import { RetirementDetailsPage } from '@pages/Retirement/RetirementDetailsPage';
const RetirementStack = createStackNavigator();

export const RetirementStackNavigator = () => {
  const defaultScreensOptions = useDefaultScreensOptions();

  return (
    <RetirementStack.Navigator
      screenOptions={{
        gestureEnabled: false,
        headerShown: false
      }}>
      <RetirementStack.Screen
        component={RetirementOnboardingPageContainer}
        name={RouteNames.OnboardingPage}
        options={defaultScreensOptions.RetirementOnboardingPage}
      />
      <RetirementStack.Screen
        component={RetirementDetailsPage}
        name={RouteNames.DetailsPage}
        options={defaultScreensOptions.DetailsPage}
      />
      <RetirementStack.Screen
        component={RetirementPageContainer}
        name={RouteNames.RetirementPage}
        options={defaultScreensOptions.RetirementPage}
      />
      <RetirementStack.Screen
        component={RetirementRecoverSavingPage}
        name={RouteNames.RetirementRecoverSavingPage}
        options={defaultScreensOptions.RetirementRecoverSavingPage}
      />
      <RetirementStack.Screen
        component={ConfirmModalPageComponent}
        name={RouteNames.RetirementModal}
        options={defaultScreensOptions.ConfirmModal}
      />
    </RetirementStack.Navigator>
  );
};
