import React, { useCallback, useContext, useMemo } from 'react';
import { Pressable, View } from 'react-native';

import { useTranslation } from '@components/ERE-UILib';
import { FontIcon, Paragraph, Text } from '@ere-uilib/atoms';
import { useTheme } from '@ere-uilib/styles';

import { ExpandableContext } from '../ExpandableContext';
import { useStyles } from './styles';

export const CompartmentCardRepartition = () => {
  const styles = useStyles();
  const theme = useTheme();
  const { formatMessage } = useTranslation();

  const { isExpanded, setIsExpanded } = useContext(ExpandableContext);

  const label = useMemo(
    () => ({
      paragraph: formatMessage({ id: 'PIInformationAllocationOfFunds' }),
      click_here: formatMessage({ id: 'PIInformationAllocationOfFundsClic' }),
      show_my_repartition: formatMessage({ id: 'PIInformationTextAllocationOfFunds' })
    }),
    [formatMessage]
  );

  const expandableIconName = useMemo(
    () => (isExpanded ? 'chevron-haut' : 'chevron-bas'),
    [isExpanded]
  );

  const toggleExpanded = useCallback(() => setIsExpanded(!isExpanded), [isExpanded, setIsExpanded]);

  return (
    <React.Fragment>
      <View style={styles.container}>
        <Paragraph
          variant="t3"
          weight="light">
          {label.paragraph}
          {/*<Link textVariant="t3">{label.click_here}</Link>*/}
        </Paragraph>
        <Pressable onPress={toggleExpanded}>
          <View style={styles.expandableContainer}>
            <Text variant="t3">{label.show_my_repartition}</Text>
            <FontIcon
              name={expandableIconName}
              size={theme.metrics.iconSizes.xm}
              style={styles.expandableIcon}
            />
          </View>
        </Pressable>
      </View>
    </React.Fragment>
  );
};
