import React from 'react';
import { View, StyleProp, ViewStyle } from 'react-native';

import {
  SimpleButton,
  AccountCreationSuccess,
  useTheme,
  ThemeType,
  useScreenSizes,
  EERPageWrapperContainer,
} from '@components/index';
import { createUseStyles } from '@ere-uilib/styles';

import { useTranslation } from '@ere-uilib/translations';
import { InvitationState } from '@modules/invitation/types';
import { AuthConfigType } from '@modules/settings/types';
import { loginOpenId } from '@modules/auth/services';

interface Props {
  authConfig: AuthConfigType
  containerStyle?: StyleProp<ViewStyle>;
  contentStyle?: StyleProp<ViewStyle>;
  invitation: InvitationState
}

type AccountCreationSucessPageStyles = {
  containerStyle?: StyleProp<ViewStyle>;
  contentStyle?: StyleProp<ViewStyle>;
  innerContentStyle?: StyleProp<ViewStyle>;
};

type StylesContext = { theme: ThemeType; isMobile: boolean; isTablet: boolean; };

export const AccountCreationSuccessPageComponent: React.FC<React.PropsWithChildren<Props>> = ({
  authConfig,
  contentStyle,
  containerStyle,
  invitation,
}) => {
  const theme = useTheme();
  const { isMobile, isTablet, isDesktop } = useScreenSizes();
  const styles = useStyles({ theme, isMobile, isTablet }, { containerStyle, contentStyle });
  const { formatMessage } = useTranslation();

  return (

    <EERPageWrapperContainer
      activeStep={2}
      displayCloseButton={false}
      helpPointPosition="left"
      pageTitle={formatMessage({ id: 'verify-identity_menu' })}
    >
      <View style={styles.contentStyle}>
        <View style={styles.innerContentStyle}>
          <AccountCreationSuccess name={invitation?.user?.firstName} />
          <SimpleButton
            containerStyle={{ marginTop: theme.metrics.spacing.xm }}
            onPress={() => loginOpenId(authConfig)}
            title={formatMessage({ id: 'Eer_GotoHome_button' })}
          />
        </View>
      </View>
    </EERPageWrapperContainer>
  );
};

const getStyles = (
  context?: StylesContext,
  style?: AccountCreationSucessPageStyles,
): AccountCreationSucessPageStyles => ({
  containerStyle: [
    !(context?.isMobile || context?.isTablet)
      ? { flex: 1, flexDirection: 'row' }
      : { flex: 1, flexDirection: 'column' },
    style?.containerStyle,
  ],
  contentStyle: [
    !(context?.isMobile || context?.isTablet)
      ? {
        flex: 2.1,
        borderTopRightRadius: context?.theme.metrics.spacing.xm,
        borderBottomRightRadius: context?.theme.metrics.spacing.xm,
        padding: context?.theme.metrics.spacing.xxl,
        paddingBottom: 0,
        alignItems: 'center',
        justifyContent: 'center',
      }
      : {
        flex: 1,
        padding: context?.theme.metrics.spacing.xxl,
        alignItems: 'center',
        justifyContent: 'center',
      },
    style?.contentStyle,
  ],
  innerContentStyle: [
    !(context?.isMobile || context?.isTablet)
      ? {
        flex: 1,
        paddingBottom: context?.theme.metrics.spacing.xxl,
      }
      : {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
      },
    style?.innerContentStyle,
  ],
});

const useStyles = createUseStyles(getStyles);
