import React, { useMemo, useState } from 'react';
import { View } from 'react-native';

import {
  EERContentWrapper,
  WalkthroughStepper,
  OfflinePageLayout,
  NotificationHard,
  LegalMenuFooterConnected,
  useScreenSizes,
  ValidationCode,
  StepperHeader,
  BackAndConfirmButtons,
  EERKeyboardAvoidingViewPageWrapper
} from '@components/index';
import { NotificationIconTypeEnum } from '@ere-uilib/atoms';
import { DirectionEnum } from '@ere-uilib/molecules/steppers/InstallmentsStepper/interfaces';
import { useTranslation } from '@ere-uilib/translations';
import { constructStepperItems } from '@pages/Common/utils';

import { ValidationCodePageTemplateProps } from './interface';
import { useStyles } from './styles';
import { locator } from '@constants/locator';

export const ValidationCodePageTemplate = ({
  title,
  isApiLoading,
  isRetryEventApiLoading,
  errorMessage,
  onRetryEventOnPress,
  onPress,
  onLogoPress,
  isEERLeftMenu,
  isHeader = true,
  EERLeftMenuActiveStep,
  displayLogo = false,
  resetOTP,
  description,
  infoNotificationText,
  subtitleStyle,
  onBackPress,
  handleCloseButton
}: ValidationCodePageTemplateProps) => {
  const { isMobile, isTablet, isDesktop } = useScreenSizes();
  const styles = useStyles({ isEERLeftMenu });
  const { formatMessage, getMessageRaw } = useTranslation();
  const [otpPlateformeCode, setOtpPlateformeCode] = useState('');

  const handleOnPress = () => {
    onPress(otpPlateformeCode);
  };
  const renderLeftMenu = isEERLeftMenu
    ? () => <WalkthroughStepper stepOneDone={EERLeftMenuActiveStep === 2} />
    : () => <View />;
  const renderActions = () => (
    <BackAndConfirmButtons
      confirmButtonLabel={formatMessage({ id: 'validate-identity_button' })}
      containerStyle={styles.buttonStyle}
      isLoading={isApiLoading}
      onBack={onBackPress}
      onValidate={handleOnPress}
      testId={locator._account_activation._confirm}
    />
  );
  const renderMobileFooterContent = () => (
    <View style={styles.footerContentContainer}>
      {renderActions()}
      <LegalMenuFooterConnected />
    </View>
  );
  const renderLegalMenuFooter = () => {
    return <LegalMenuFooterConnected isWhite={isDesktop} />;
  };
  const stepperItems = useMemo(
    () =>
      constructStepperItems({
        size: 2,
        direction: DirectionEnum.ROW,
        activeStep: EERLeftMenuActiveStep
      }),
    [EERLeftMenuActiveStep]
  );
  const renderMobileHeader = () => {
    if (!isHeader) {
      return null;
    }
    return (
      <StepperHeader
        displayCloseButton={false}
        helpPoint
        helpPointContentHtml={getMessageRaw({ id: 'eer_info_content' })}
        helpPointModalTitle={formatMessage({ id: 'eer_info_title' })}
        helpPointPosition="left"
        stepperItems={isEERLeftMenu ? stepperItems : undefined}
        title={title}
      />
    );
  };
  return (
    <EERKeyboardAvoidingViewPageWrapper>
      <OfflinePageLayout
        closeButtonAction={handleCloseButton}
        displayLogo={!(isMobile || isTablet) || displayLogo}
        onLogoPress={onLogoPress}
        renderDesktopCardBelowContent={renderLegalMenuFooter}
        renderDesktopCardLeftContent={renderLeftMenu}
        renderMobileHeaderContent={renderMobileHeader}
        renderMobileStickyGradientFooterContent={renderMobileFooterContent}>
        <EERContentWrapper
          backButtonTitle={onBackPress ? 'retour' : undefined}
          onPressBackButton={onBackPress}
          title={formatMessage({ id: 'validate-identity_title' })}>
          <ValidationCode
            description={description}
            errored={!!errorMessage}
            infoNotificationText={infoNotificationText}
            onSubmit={handleOnPress}
            resetOTP={resetOTP}
            retryProps={
              onRetryEventOnPress
                ? {
                  label: formatMessage({ id: 'validate-identity_button-reverse' }),
                  onPress: onRetryEventOnPress,
                  isLoading: isRetryEventApiLoading
                }
                : undefined
            }
            setOtpPlateformeCode={setOtpPlateformeCode}
            subtitleStyle={subtitleStyle}
          />
          {!!errorMessage && (
            <NotificationHard
              text={errorMessage}
              type={NotificationIconTypeEnum.WARNING}
            />
          )}
          {!(isMobile || isTablet) && renderActions()}
        </EERContentWrapper>
      </OfflinePageLayout>
    </EERKeyboardAvoidingViewPageWrapper>
  );
};
