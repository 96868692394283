const theme = {
  bold: {
    main: 'bold',
    medium: '400',
    thin: '300'
  },
  border: {
    none: 'none',
    solid: ' 1px solid',
    normal: '1px'
  },
  borderRadius: {
    none: 0,
    smallButton: 3,
    card: 5,
    menuRound: 20,
    riskLevel: 20,
    mobileModal: 40,
    button: 50,
    circle: 50,
    insideRadio: 7,
    radio: 10,
    rectangular: 10,
    panel: 5,
    imageBackgroundContent: 20
  },
  breakpoints: {
    mobileS: 320,
    mobileM: 375,
    mobileL: 425,
    tablet: 768,
    laptop: 1024,
    laptopL: 1440,
    Desktop: 2560
  },
  colors: {
    primaryGreen: '#00915A',
    opacityGreen: '#70A78D',
    lightGreen: '#E6F4EF',
    primaryBlue: '#3D669C',
    lightBlue: '#DBE4EC',
    info: '#0076A9',
    darkBlue: '#5B8AB5',
    darkBlueOpac: '#5B8AB580',
    black: '#0F0F0F',
    white: '#FFFFFF',
    lightWhite: '#F5F5F5',
    grey: '#F8F8F8',
    lightGrey: '#DCDCDC',
    reallyLightGrey: '#F7F7F7',
    opacityGrey: '#E8E8E8',
    darkGrey: '#C8C8C8',
    littleDarkGrey: '#E9E9E9',
    backgroundGrey: '#FAFAFA',
    disabledGrey: '#9A9A9A',
    reallyDarkGrey: '#6D6D6D',
    greyWhite: '#F0F0F0',
    red: '#E1677C',
    blackGrey: '#555555',
    transparent: 'transparent',
    pee: '#F3B351',
    modalBackground: '#000000B3',
    ongoing: '#0076A9',
    success: '#82BC68',
    fail: '#E1677C',
    waiting: '#F2C732',
    canceled: '#E8E8E8',
    filtre: '#B46B95'
  },
  content: {
    flex: 'flex',
    row: 'row',
    column: 'column',
    center: 'center',
    space: 'space-between',
    spaceAround: 'space-around',
    leftAlign: 'left'
  },
  fonts: {
    bnp: 'BNPPSans',
    bnpBold: 'BNPPSans-Bold',
    bnpLight: 'BNPPSans-Light'
  },
  fontSizes: {
    temp: 9,
    xs: 12,
    s: 14,
    m: 16,
    l: 18,
    xl: 20,
    xxl: 22,
    big: 24,
    xbig: 36,
    xxbig: 50,
    xxxbig: 80
  },
  fontWeights: {
    bold: '700',
    normal: 'normal',
    light: '300'
  },
  marginsPaddings: {
    none: 0,
    xxs: 3,
    xs: 5,
    s: 10,
    m: 15,
    xm: 20,
    l: 30,
    xl: 35,
    xxl: 40,
    xxxl: 45,
    big: 55,
    xbig: 60,
    xxbig: 70,
    huge: 100,
    xhuge: 130,
    xxhuge: 170
  },
  lineHeight: {
    title: '28px',
    content: '22px'
  },
  lineHeightNew: {
    xs: '18px',
    s: '20px',
    m: '22px',
    l: '24px',
    heading3: '24px',
    heading2: '26px',
    heading1: '32px',
    headingMobile: '28px'
  },
  position: {
    absolute: 'absolute',
    fixed: 'fixed',
    relative: 'relative'
  },
  height: {
    detailsButton: '18px',
    arrowDown: '11px',
    back: '13px',
    biometric: '55px',
    buttonSmall: '30px',
    button: '50px',
    bulletPoint: '13px',
    card: '200px',
    checkbox: '20px',
    radiobutton: '20px',
    circle: '150px',
    close: '13px',
    dispoIcon: '36px',
    edit: '20px',
    round: '35px',
    smallRound: '25px',
    fc: '88px',
    full: `${window.innerHeight}px`,
    fullWithLogo: `${window.innerHeight - 60}px`,
    fullWithLogoDesktop: 'calc(100vh - 80px)',
    profileCompany: '80px',
    go: '10px',
    insideRadio: '14px',
    loading: '28px',
    logo: '40px',
    logoDashboard: '70px',
    donut: '210px',
    img: '205px',
    imgDesktop: '250px',
    inProgress: '20px',
    radio: '20px',
    tag: '20px',
    support: '48px',
    measureGraph: '85px',
    menuIcon: '27px',
    menuMobileIcon: '40px',
    menuTab: '50px',
    menuButton: '55px',
    moneyBox: '165px',
    next: '11px',
    mobileSupport: '40px',
    info: '20px',
    icon: '36px',
    mask: '15px',
    step_check: '38px',
    alert: '25px',
    clock: '25px',
    code: '60px',
    switchButton: '28px',
    switch: '20px',
    tabBar: '90px',
    input: '60px',
    benefitePanelheight: '411px',
    backgroundImageContent: '788px',
    benefitIconContainer: '50px',
    loginImageBackground: '285px',
    OCDMobileTop: '175px',
    OCDMobileBottom: '317px',
    OCDDesktopFullTop: '113px',
    OCDDesktopFullBottom: '190px',
    OCDDesktopSmallBottom: '317px',
    OCDDesktopSmallTop: '167px'
  },
  width: {
    announce: '350px',
    arrowDown: '15px',
    back: '7px',
    biometric: '50px',
    button: '330px',
    buttonText: '290px',
    bulletPoint: '13px',
    checkbox: '20px',
    radiobutton: '20px',
    circle: '150px',
    close: '13px',
    round: '35px',
    smallRound: '25px',
    elem: '110px',
    edit: '20px',
    dispoIcon: '36px',
    donut: '350px',
    go: '20px',
    fc: '300px',
    full: '100vw',
    fullPercentage: '100%',
    fullWithPadding: 'calc(100vw - 30px)',
    insideRadio: '14px',
    logo: '265px',
    logoDashboard: '185px',
    img: '355px',
    imgDesktop: '430px',
    inProgress: '20px',
    loading: '28px',
    contentDesktop: '375px',
    bigContentDesktop: '535px',
    radio: '20px',
    legend: '210px',
    measureGraph: '370px',
    menu: '317px',
    menuIcon: '26px',
    menuMobileIcon: '59px',
    menuButton: '55px',
    moneyBox: '135px',
    moneyBoxMobile: '115px',
    next: '7px',
    stepText: 'calc(100vw - 65px)',
    stepContent: '713px',
    stepTextDesktop: '275px',
    support: '355px',
    mobileSupport: '300px',
    info: '20px',
    icon: '36px',
    mask: '20px',
    step_check: '35px',
    alert: '29px',
    clock: '25px',
    code: '60px',
    switchButton: '45px',
    switch: '45px',
    loginForm: '602px',
    menuSeparator: '3px',
    backgroundImageContent: '1030px',
    benefitIconContainer: '50px',
    loginImageBackground: '375px',
    codeInputContainer: '300px',
    ForgetPasswordContainer: '601px',
    maxInnerPageWidth: '1280px',
    modalDeskTopMaxWidthDefault: '450px',
    modalDeskTopMaxWidthInfo: '350px'
  },
  iconSize: {
    medium: 20,
    mediumPlus: 24,
    xMedium: 15,
    small: 12,
    xsmall: 8,
    large: 40
  }
} as const

export type TTheme = typeof theme

export { theme }
