import { StackNavigationProp } from '@react-navigation/stack';
import React, { useCallback } from 'react';
import { Platform, StyleProp, TextStyle, View, ViewStyle } from 'react-native';

import {
  ColumnsContainer,
  DashboardPageWrapperConnected,
  HtmlRenderer,
  NavigationHeader,
  Text,
  useTheme,
  useTranslation
} from '@components/index';
import { locator } from '@constants/locator';
import { RouteNames } from '@constants/navigation';
import { SimpleButton } from '@ere-uilib/molecules';
import { ModalSuccess } from '@ere-uilib/organisms/ModalSuccess';
import { createUseStyles, ThemeType, useScreenSizes } from '@ere-uilib/styles';
import { ContactPreferencesOptin } from '@modules/profile/actions/profileActions';
import { ProfileErrorState } from '@modules/profile/types';
import { AppNavigatorInterface } from '@navigation/Interfaces';

import { BusinessContactPreferencetemplate } from '../templates';
import { useController } from '../useController';

interface Props {
  navigation: StackNavigationProp<AppNavigatorInterface>;
  onSubmit: (values: ContactPreferencesOptin) => void;
  isSuccess?: boolean;
  onPressButtonSuccessModal: () => void;
  acceptGroupEmail: boolean;
  acceptPartnerEmail: boolean;
  getContactPreferencesRequest: () => void;
  isLoading?: boolean;
  error: ProfileErrorState;
}

type StylesContext = {
  theme: ThemeType;
  isMobile: boolean;
  isTablet: boolean;
  buttonMarging: number;
};

type StylesProps = {
  topButtonText?: StyleProp<ViewStyle>;
  button?: StyleProp<ViewStyle>;
  textContent?: StyleProp<TextStyle>;
  commonBlocStyle?: StyleProp<TextStyle>;
  errorTitleStyle?: StyleProp<TextStyle>;
  container?: StyleProp<ViewStyle>;
};

export function BusinessContactPreferencesConnectedPage({
  navigation,
  isSuccess,
  onSubmit,
  onPressButtonSuccessModal,
  acceptGroupEmail,
  acceptPartnerEmail,
  getContactPreferencesRequest,
  isLoading,
  button,
  error,
  container
}: Props & StylesProps) {
  const { formatMessage, getMessageRaw } = useTranslation();
  const theme = useTheme();
  const {
    isSubmitActive,
    handlePressGroupEmail,
    handlePressPartnerEmail,
    handleSubmit,
    handlePressButtonSuccessModal,
    optInPartnerEmail,
    optInGroupEmail
  } = useController({
    onSubmit,
    onPressButtonSuccessModal,
    navigation,
    acceptGroupEmail,
    acceptPartnerEmail,
    getContactPreferencesRequest
  });

  const renderHeader = useCallback(
    () => (
      <NavigationHeader
        displayBackButton={true}
        helpPoint={true}
        helpPointContentHtml={getMessageRaw({ id: 'OptinInfoContent' })}
        helpPointModalTitle={formatMessage({ id: 'OptinInfoTitle' })}
        helpPointPosition={'right'}
        onGoBack={() =>
          navigation.navigate(RouteNames.BottomTabNavigator, {
            screen: RouteNames.ProfileStack,
            params: {
              screen: RouteNames.Profile
            }
          })
        }
        title={formatMessage({ id: 'Profil_Prefcontact_edit_page_title' })}
      />
    ),
    [getMessageRaw, formatMessage, navigation]
  );

  const { isMobile, isTablet, isDesktop, windowHeight } = useScreenSizes();
  const buttonMarging = windowHeight / (Platform.OS === 'android' ? 7 : 14);
  const styles = useStyles({ theme, isMobile, isTablet, buttonMarging }, { button, container });

  const renderButton = useCallback(
    () => (
      <View style={styles.button}>
        <Text
          style={styles.topButtonText}
          variant="t4"
          weight="regular">
          {formatMessage({
            id: 'OptinAvantagesChoixModifiableLabel'
          })}
        </Text>

        <SimpleButton
          disabled={!isSubmitActive}
          loading={isLoading}
          onPress={handleSubmit}
          testId={locator._profile._your_preferences._save_preferences}
          title={formatMessage({ id: 'Profil_Prefcontact_edit_page_Bouton' })}
        />
        <View style={styles.textContent}>
          <HtmlRenderer
            html={getMessageRaw({ id: 'ProfilPrefcontactEditOptinContractFooterNotice' })}
          />
        </View>
      </View>
    ),
    [styles, formatMessage, isSubmitActive, isLoading, handleSubmit, getMessageRaw]
  );
  return (
    <DashboardPageWrapperConnected
      cardContentStyle={{ backgroundColor: theme.colors.basics.white }}
      renderStickyMobileBottom={renderButton}
      scrollContentContainerStyle={(isMobile || isTablet) && { minHeight: 'auto' }}>
      <>

        <ColumnsContainer
          containerStyle={(isMobile || isTablet) && { height: 'auto' }}
          hasColumnCenter
          renderHeader={renderHeader}>
          <View style={styles.container}>
            <BusinessContactPreferencetemplate
              acceptGroupEmail={acceptGroupEmail}
              acceptPartnerEmail={acceptPartnerEmail}
              handlePressGroupEmail={handlePressGroupEmail}
              handlePressPartnerEmail={handlePressPartnerEmail}
              optInGroupEmail={optInGroupEmail}
              optInPartnerEmail={optInPartnerEmail}
            />
            {isDesktop && (
              <View style={{ marginTop: 60, alignItems: 'flex-start' }}>{renderButton()}</View>
            )}
          </View>

        </ColumnsContainer>
        <ModalSuccess
          buttonTitle={formatMessage({ id: 'Profil_Prefcontact_edit_confirm_button' })}
          iconName="update_contact_preferences_success"
          onPressButton={handlePressButtonSuccessModal}
          subTitle={formatMessage({ id: 'Profil_Prefcontact_edit_confirm_subtitle' })}
          title={formatMessage({ id: 'Profil_Prefcontact_edit_confirm_title' })}
          visible={isSuccess}
        />
      </>
    </DashboardPageWrapperConnected>
  );
}
const getStyles = (context?: StylesContext, style?: StylesProps): StylesProps => ({
  container: [
    !(context?.isMobile || context?.isTablet)
      ? {
        flex: 1,
        alignSelf: 'center',
        paddingHorizontal: context?.theme.metrics.spacing.xxhuge
      }
      : { marginTop: context?.theme.metrics.spacing.m
      },
    style?.container
  ],
  commonBlocStyle: [
    context?.isMobile
      ? {
        marginTop: context?.theme.metrics.spacing.xm,
        padding: 0
      }
      : {
        marginTop: context?.theme.metrics.spacing.xxl,
        padding: 0
      },
    style?.commonBlocStyle
  ],
  topButtonText: [
    {
      marginBottom: context?.theme.metrics.spacing.s
    },
    style?.topButtonText
  ],

  button: [
    !(context?.isMobile || context?.isTablet) && {
      alignItems: 'flex-start',
     
    },
    { marginBottom: context?.buttonMarging,  paddingBottom: context?.theme.metrics.spacing.l },
    style?.button
  ],
  textContent: [
    !(context?.isMobile || context?.isTablet) && {
      alignItems: 'flex-start'
    },
    {
      marginTop: context?.theme.metrics.spacing.m
    },
    style?.textContent
  ],
  errorTitleStyle: [
    {
      color: context?.theme.colors.basics.message.error.c500
    },
    style?.errorTitleStyle
  ]
});

const useStyles = createUseStyles(getStyles);
