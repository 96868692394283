import React from 'react';
import { View, StyleProp, ViewStyle, TextStyle } from 'react-native';

import {
  Text,
  Title,
} from '@ere-uilib/atoms';
import { createUseStyles, useTheme, ThemeType } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';

interface Props extends Styles {
  containerStyle?: StyleProp<ViewStyle>;

  advantageTitle?: string;
  advantageAmount?: string;
  advantageDescription?: string;
  display_netText?: boolean;
  hasContributionError?: boolean;
}

type Styles = {
  containerStyle?: StyleProp<ViewStyle>;
  descriptionTextStyle?: StyleProp<TextStyle>;
  contributionErrorTextStyle?: StyleProp<TextStyle>;
};

type StylesContext = {theme: ThemeType};

export const AdvantageCard: React.FC<React.PropsWithChildren<Props>> = ({
  containerStyle,
  advantageTitle,
  advantageAmount,
  advantageDescription,
  display_netText = false,
  hasContributionError,
}) => {
  const theme = useTheme();
  const styles = useStyles({ theme }, { containerStyle });
  const { formatMessage } = useTranslation();

  const renderAdvantageTitle = ()=> {
    return (
      advantageTitle &&
        <Title
          variant="t7"
          weight="bold"
        >
          {advantageTitle}
          {
            display_netText && (
              <Title
                variant="t7"
                weight="light"
              > {formatMessage({ id: 'VV_recap_netcontribution_label' })}</Title>
            )
          }
        </Title>
    );
  };

  const renderAdvantageAmmont = ()=> {
    return (
      advantageAmount &&
        <Title
          variant="t6"
          weight="bold"
        >
          {hasContributionError ? '- €' : advantageAmount}
        </Title>
    );
  };

  const renderAdvantageDescription = ()=> {
    return (
      <>
        {hasContributionError &&
          <Text
            style={styles.contributionErrorTextStyle}
            variant="t3"
            weight="regular"
          >
            {hasContributionError &&
              formatMessage({ id: 'VV_repartition_contribution_delayed_label' })
            }
          </Text>
        }
        {advantageDescription &&
          <Text
            style={styles.descriptionTextStyle}
            variant="t3"
            weight="light"
          >
            {advantageDescription}
          </Text>
        }
      </>
    );
  };

  return (
    <View style={styles.containerStyle} >

      {renderAdvantageTitle()}
      {renderAdvantageAmmont()}
      {renderAdvantageDescription()}

    </View>
  );
};

const getStyles = (context?: StylesContext, style?: Styles): Styles => ({
  containerStyle: [
    {
      flex: 1,
      backgroundColor: context?.theme.colors.basics.white,
      borderRadius: context?.theme.metrics.borderRadius.xs,
      borderWidth: 1,
      borderColor: context?.theme.colors.basics.grey.c300,
      paddingHorizontal: context?.theme.metrics.spacing.s,
      paddingVertical: context?.theme.metrics.spacing.xm,
      flexDirection: 'column',
      alignItems: 'center',
    },
    style?.containerStyle,
  ],
  descriptionTextStyle: [
    {
      textAlign: 'center',
      marginTop: context?.theme.metrics.spacing.s,
    },
    style?.descriptionTextStyle,
  ],
  contributionErrorTextStyle: [
    {
      textAlign: 'center',
    },
    style?.descriptionTextStyle,
  ],
});

const useStyles = createUseStyles(getStyles);
