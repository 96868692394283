import { useDispatch, useSelector } from 'react-redux';

import {
  checkEERIdentityValidationCodeRequest,
  sendEERIdentityValidationCodeRequest
} from '@modules/validate-identity/actions/validateIdentityActions';
import { ValidateIdentityActionsType } from '@modules/validate-identity/actions/validateIdentityActionsTypes';
import { getInvitationSelector } from '@modules/invitation/selectors';
import { getIdentityValidationModuleError } from '@modules/validate-identity/selectors';
import { getLoadingState } from "@modules/funds/selectors";

import { ValidationSignupPhoneCode } from './ValidationSignupPhoneCode.component';

export const ValidationSignupPhoneCodePage : React.FC = () => {
  const invitation = useSelector(getInvitationSelector);
  const validateIdentityError = useSelector(getIdentityValidationModuleError);
  const isCheckingCodeLoading = !!useSelector(getLoadingState(ValidateIdentityActionsType.CHECK_EER_IDENTITY_VALIDATION_CODE_REQUEST));
  const isSendingCodeLoading = !!useSelector(getLoadingState(ValidateIdentityActionsType.SEND_EER_IDENTITY_VALIDATION_CODE_REQUEST));
  const dispatch = useDispatch();

  return (<ValidationSignupPhoneCode
      validateIdentityError={validateIdentityError}
      invitation={invitation}
      checkEERIdentityValidationCode={(code)=> dispatch(checkEERIdentityValidationCodeRequest(code))}
      sendEERIdentityValidationCode={(validationCode) => dispatch(sendEERIdentityValidationCodeRequest(validationCode))}
      isCheckingCodeLoading= {isCheckingCodeLoading}
      isSendingCodeLoading={isSendingCodeLoading} />);
};
