import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

export const useStyles = () => {
  const theme = useTheme();

  const styles = useMemo(
    () =>
      StyleSheet.create({
        separatorContainer: {
          flexDirection: 'row',
          alignItems: 'center'
        },
        separatorText: {
          paddingHorizontal: theme.metrics.spacing.s
        },
        separatorLine: {
          flex: 1,
          height: 1,
          backgroundColor: theme.colors.basics.grey.c200
        }
      }),
    [theme]
  );

  return styles;
};
