import React from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';

import { Hoverable } from '@ere-uilib/atoms';

import { PickerProps } from '../types';
import { useRender } from './useRender';
import { useStyles } from './useStyles';

export const Picker = ({
  itemsContainerStyle,
  onSelectItem,
  items,
  isOpen,
  selectedItem,
  textStyle,
  textContainerStyle,
  isAbsolutePositionDisabled,
}: PickerProps) => {
  const styles = useStyles(isAbsolutePositionDisabled);
  const {
    renderContent,
  } = useRender({
    textStyle,
  });

  if (!isOpen) {
    return null;
  }

  return (
    <ScrollView
      showsHorizontalScrollIndicator={true}
      style={[styles.itemsContainerStyle, itemsContainerStyle]}>
      {
        items?.map(item => {
          const handleItemSelect = () => {
            onSelectItem(item);
          };

          return (
            <Hoverable>
              {(isHoverable: boolean) =>
                <TouchableOpacity
                  onPress={handleItemSelect}
                  style={[styles.itemStyle, (isHoverable || selectedItem?.value === item.value) && styles.hoveredItem, textContainerStyle]}
                >
                  {renderContent(item)}
                </TouchableOpacity>
              }
            </Hoverable>
          );
        })}
    </ScrollView >
  );
};
