import React, { useCallback } from 'react';
import {
  TouchableOpacity,
  View,
  ScrollView,
  SafeAreaView,
  Platform, NativeSyntheticEvent, NativeScrollEvent
} from 'react-native';

import { Hoverable, Text, FontIcon, Title } from '@ere-uilib/atoms';
import { SimpleButton } from '@ere-uilib/molecules';
import { useTheme } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';

import { MobileMenuProps, RenderMobileMenuItemParams } from './interface';
import { useStyles } from './styles';

export const MobileMenuComponent = ({
  mobileMenuList,
  onMenuItemPress,
  renderCustomMenuItems,
  onPressLogoutButton,
}: MobileMenuProps) => {
  const { formatMessage } = useTranslation();
  const theme = useTheme();
  const styles = useStyles();
  const [scrollValue, setScrollValue] = React.useState(0);

  const renderMobileMenuItem = ({ label, onPress }: RenderMobileMenuItemParams) => {
    return (
      <View>
        <Hoverable>
          {(isHovered: boolean) => (
            <TouchableOpacity
              onPress={onPress}
              style={[
                styles.linksContainerRow,
                { backgroundColor: isHovered
                    ? theme.colors.buttons.menuItemActive.c500
                    : undefined }
              ]}
            >
              <Text
                style={{
                  color:
                    isHovered
                      ? theme.colors.basics.white
                      : theme.colors.basics.black,
                }}
                testId={label}
                weight={"light"}
              >
                {label}
              </Text>
              <FontIcon
                color={theme.colors.basics.white}
                name="right"
                size={24}
              />
            </TouchableOpacity>
          )}
        </Hoverable>
        <View style={styles.separator} />
      </View>
    );
  };

  const handleScroll = useCallback((e: NativeSyntheticEvent<NativeScrollEvent>) => {
    if (setScrollValue) {
      setScrollValue(e.nativeEvent.contentOffset.y)
    }
  }, []);

  return (
    <SafeAreaView style={styles.mainContainer}>
      <View style={styles.modalContainer}>
        <View
          style={[
            styles.titleContainer,
            (scrollValue > 0) && styles.titleContainerWhenComponentScrolled
        ]}>
          <Title
            variant={"t5"}
            weight="bold"
          >
            {formatMessage({ id: 'menu_wish_label' })}
          </Title>
        </View>
        <ScrollView
          showsVerticalScrollIndicator={Platform.OS === "web"}
          onScroll={e => handleScroll(e)}
        >
          <View style={styles.menuWrapper}>
            <View style={styles.wishMenuLinks}>
              {!!mobileMenuList && Array.isArray(mobileMenuList) && mobileMenuList.map(item => {
                return renderMobileMenuItem({
                  label: item.label,
                  onPress: () => onMenuItemPress(item?.operationType, item?.url),
                });
              })}
              {!!renderCustomMenuItems && renderCustomMenuItems(renderMobileMenuItem)}
            </View>

            <SimpleButton
              containerStyle={styles.buttonContainerStyle}
              customPrimaryColor={theme.colors.buttons.classic.c500}
              customSecondaryColor={theme.colors.basics.white}
              design="outlined"
              isHoverableButton={false}
              onPress={onPressLogoutButton}
              size="small"
              testId='dashboard_logout'
              title={formatMessage({ id: 'TermsetFrontProfileMenuDeconnexion' })}
            />
          </View>
        </ScrollView>
      </View>
    </SafeAreaView>
  );
};
