import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useScreenSizes, useTheme } from '@ere-uilib/styles';

export function useStyles() {
  const { windowHeight } = useScreenSizes();
  const theme = useTheme();
  const styles = useMemo(() => {
    return StyleSheet.create({
      containerStyle: {
        backgroundColor: theme.colors.basics.grey.c100,
        height: windowHeight,
      },
      form: {
        marginTop: theme.metrics.spacing.m,
      },
      buttonStyle: {
        marginTop: theme.metrics.spacing.xm
      },
      section: {
        borderBottomWidth: 1,
        paddingBottom: theme.metrics.spacing.l, 
      },
      sectionLast: {
        borderBottomWidth: 0,
        paddingTop: theme.metrics.spacing.l,
      },
      checkboxes: {
        flexDirection: 'row',
        paddingTop: theme.metrics.spacing.m,
      },
      checkbox: {
        paddingRight: theme.metrics.spacing.l,
      },
      secondRadioButton: {
        marginTop: theme.metrics.spacing.l,
      },
      subTitleStyle: {
        marginTop: theme.metrics.spacing.xm
      }
    });
  }, [theme, windowHeight]);

  return styles;
}