import { useMemo } from 'react'

import { useTranslation } from '@ere-uilib/translations'
import {
  ArbitrationAnswersTypesEnum,
  ArbitrationQuestionTypesEnum,
  OadProfileRiskEnum
} from '@modules/arbitration/constants'
import { QuestionsState } from '@modules/common/types'

enum DelayEnum {
  DELAY_0_2 = '0-2',
  DELAY_2_5 = '2-5',
  DELAY_5_10 = '5-10',
  DELAY_10 = '10'
}

export const useOADQuestion = () => {
  const { formatMessage } = useTranslation()

  const OADDelayQuestion: QuestionsState = useMemo(
    () => ({
      key: '',
      showEligibility: false,
      text: '',
      title: formatMessage({ id: 'AbritrageOADSelectionDispositifQuestion4' }),
      respondedTitle: 'AbritrageOADResultatQRHorizonDescription',
      type: ArbitrationQuestionTypesEnum.OAD_DELAY,
      tooltip: 'Tooltip FR',
      responses: [
        {
          title: formatMessage({ id: 'AbritrageOADSelectionDispositifQuestion4maturite1' }),
          text: '',
          value: DelayEnum.DELAY_0_2,
          type: ArbitrationAnswersTypesEnum.OAD_RADIO,
          propertyFilter: {
            name: ArbitrationAnswersTypesEnum.OAD_DELAY,
            value: false
          }
        },
        {
          title: formatMessage({ id: 'AbritrageOADSelectionDispositifQuestion4maturite2' }),
          text: '',
          value: DelayEnum.DELAY_2_5,
          type: ArbitrationAnswersTypesEnum.OAD_RADIO,
          propertyFilter: {
            name: ArbitrationAnswersTypesEnum.OAD_DELAY,
            value: false
          }
        },
        {
          title: formatMessage({ id: 'AbritrageOADSelectionDispositifQuestion4maturite3' }),
          value: DelayEnum.DELAY_5_10,
          text: '',
          type: ArbitrationAnswersTypesEnum.OAD_RADIO,
          propertyFilter: {
            name: ArbitrationAnswersTypesEnum.OAD_DELAY,
            value: false
          }
        },
        {
          title: formatMessage({ id: 'AbritrageOADSelectionDispositifQuestion4maturite4' }),
          value: DelayEnum.DELAY_10,
          text: '',
          type: ArbitrationAnswersTypesEnum.OAD_RADIO,
          propertyFilter: {
            name: ArbitrationAnswersTypesEnum.OAD_DELAY,
            value: false
          }
        }
      ]
    }),
    [formatMessage]
  )

  const OADRiskQuestion: QuestionsState = useMemo(
    () => ({
      key: '',
      showEligibility: false,
      text: '',
      title: formatMessage({ id: 'AbritrageOADSelectionDispositifQuestion5' }),
      respondedTitle: 'AbritrageOADSelectionDispositifQuestion5Recap',
      type: ArbitrationQuestionTypesEnum.OAD_RISK,
      tooltip: 'Tooltip FR',
      responses: [
        {
          title: formatMessage({ id: 'AbritrageOADSelectionDispositifQuestion5risk1' }),
          value: 'Prudent',
          text: formatMessage({ id: 'AbritrageOADSelectionDispositifQuestion5risk1Description' }),
          icon: 'carefulOad',
          progress: OadProfileRiskEnum.SAFE,
          type: ArbitrationAnswersTypesEnum.OAD_RISK,
          propertyFilter: {
            name: ArbitrationAnswersTypesEnum.OAD_RISK,
            value: true
          }
        },
        {
          title: formatMessage({ id: 'AbritrageOADSelectionDispositifQuestion5risk2' }),
          value: 'Equilibre',
          text: formatMessage({ id: 'AbritrageOADSelectionDispositifQuestion5risk2Description' }),
          type: ArbitrationAnswersTypesEnum.OAD_RISK,
          icon: 'equilibreOad',
          progress: OadProfileRiskEnum.BALANCED,
          propertyFilter: {
            name: ArbitrationAnswersTypesEnum.OAD_RISK,
            value: true
          }
        },
        {
          title: formatMessage({ id: 'AbritrageOADSelectionDispositifQuestion5risk3' }),
          value: 'Dynamique',
          text: formatMessage({ id: 'AbritrageOADSelectionDispositifQuestion5risk3Description' }),
          type: ArbitrationAnswersTypesEnum.OAD_RISK,
          icon: 'dynamiqueOad',
          progress: OadProfileRiskEnum.DYNAMIC,
          propertyFilter: {
            name: ArbitrationAnswersTypesEnum.OAD_RISK,
            value: true
          }
        }
      ]
    }),
    [formatMessage]
  )

  return {
    OADRiskQuestion,
    OADDelayQuestion
  }
}
