import { InstallmentSuccessPageContext, InstallmentSuccessPageStyle } from './interfaces';

export const getStyles = (
  context?: InstallmentSuccessPageContext,
  style?: InstallmentSuccessPageStyle,
): InstallmentSuccessPageStyle => ({
  stepSubTextStyle: [
    {
      marginTop: context?.theme.metrics.spacing.xs,
      marginBottom: context?.theme.metrics.spacing.s,
    },
    style?.stepSubTextStyle,
  ],
  successDescriptionParagraph: [
    {
      marginTop: context?.theme.metrics.spacing.s,
      textAlign: 'center',
    },
    style?.successDescriptionParagraph,
  ],
});

