import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

export const useStyles = ({ LIST_ITEM_HEIGHT }: { LIST_ITEM_HEIGHT: number }) => {
  const theme = useTheme();

  const styles = useMemo(() => {
    return StyleSheet.create({
      titleContainer: {
        height: LIST_ITEM_HEIGHT,
        padding: theme.metrics.spacing.s,
        alignItems: 'center'
      },
      container: {
        left: theme.metrics.spacing.none,
        bottom: theme.metrics.spacing.none,
        right: theme.metrics.spacing.none,
        zIndex: 10,
        position: 'absolute'
      },
      containerStyle: {
        height: LIST_ITEM_HEIGHT
      },
      containerOpen: {
        shadowColor: theme.colors.basics.grey.c600,
        shadowOffset: {
          width: 0,
          height: -6
        },
        shadowOpacity: 0.2,
        shadowRadius: 15,
        elevation: 15,
      },
      chevronContainer: {
        marginBottom: theme.metrics.spacing.xs
      },
      accordionContent: {
        overflow: 'hidden'
      },
      versionContainer: {
        height: LIST_ITEM_HEIGHT,
        padding: theme.metrics.spacing.s,
        alignItems: 'center',
        justifyContent: 'center',
        borderTopWidth: 1,
        borderColor: theme.colors.borders.fields.c200
      }
    });
  }, [theme, LIST_ITEM_HEIGHT]);

  return styles;
};
