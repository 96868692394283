import React, { useMemo, useState } from 'react';
import { WishModalContentProps, RenderMenuItemParams } from './interface';
import { useStyles } from './styles';
import { TouchableOpacity, View } from 'react-native';
import { FontIcon, Pressable, Title } from '@ere-uilib/atoms';
import { RouteNames } from '@constants/navigation';
import { useSelector } from 'react-redux';
import { getMobileMenuList } from '@modules/settings/selectors';
import { useNavigation } from '@react-navigation/native';
import { useUrlLink } from '@utils/useUrlLink';
import { useTheme, useTranslation } from '@components/ERE-UILib';

const WishModalContent: React.FC<WishModalContentProps> = ({ isWishMenuModalVisible, setIsWishMenuModalVisible }) => {
  const styles= useStyles();
  const mobileMenuList = useSelector(getMobileMenuList);
  const navigation = useNavigation();
  const { linkToUrl } = useUrlLink();
  const [itemHovered, setItemHovered] = useState<number>(-1);
  const { formatMessage } = useTranslation();
  const theme = useTheme();
  const renderItem = useMemo(() => (({ index, lastItem, onPress, label, isHovered, setItemHovered, testId }: RenderMenuItemParams) => {
    return (
      <View key={index}>
        <Pressable
          onHoverIn={() => {setItemHovered(index)}}
          onHoverOut={() => {setItemHovered(-1)}}
          onPress={onPress}
          style={[
            styles.menuItem,
            (isHovered) && styles.hoveredMenuContainer,
            lastItem && styles.hoveredLastMenuContainer
          ]}
        >
          <View
            style={[
              styles.bottomBorder,
              (isHovered) && styles.hoveredBorder
            ]}>
            <Title
              style={[
                (isHovered) && styles.hoveredTitle,
                lastItem && styles.hoveredLastMenuContainer
              ]}
              variant="t6"
              weight={"light"}
              testId={testId}
            >
              {label}
            </Title>
            <FontIcon
              color={theme.colors.basics.white}
              name="right"
              size={24}/>
          </View>
        </Pressable>
      </View>
    );

  }), [theme, styles]);

  const onPress = (operationType: string, url: string) => {
    setIsWishMenuModalVisible(false);

    if (operationType) {
      navigation.navigate(RouteNames.OperationTypeModalPage, { type: operationType });
    } else if (url) {
      linkToUrl(url);
    }
  }

  return (
    <>
      <View style={styles.wishMenuContainer}>
        <View style={styles.wishMenuTitle}>
          <Title variant={"t5s"} weight={"bold"}>{formatMessage({ id: 'menu_wish_label' })}</Title>
        </View>
        {mobileMenuList?.map((item, index) => {
          return renderItem({
            index: index,
            lastItem: index == mobileMenuList.length - 1,
            onPress: () => onPress(item.operationType, item.url),
            label: item.label,
            isHovered: itemHovered === index,
            setItemHovered,
            testId: 'wish_menu_item_' + item.label,
          });
        })}

        <TouchableOpacity
          onPress={() => setIsWishMenuModalVisible(false)}
          style={styles.wishMenuCloseButton}
        >
          <FontIcon
            name={"fermer"}
            style={styles.iconStyle} />
        </TouchableOpacity>
      </View>
    </>
  )
}

export default WishModalContent;