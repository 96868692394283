import React, { useMemo } from 'react';

import { useTranslation } from '@components/ERE-UILib';
import { locator } from '@constants/locator';
import { Text } from '@ere-uilib/atoms';
import { Table, TableCellInterface, TableCellTypeEnum } from '@ere-uilib/molecules';
import { ContributionByCompanyType } from '@modules/dashboard/types';

import { useStyles } from './useStyles';

type TableProps = React.ComponentPropsWithoutRef<typeof Table>;

interface ContributionTableProps extends Omit<TableProps, 'data'> {
  data: ContributionByCompanyType | null | undefined;
}

export const ContributionTable: React.FC<React.PropsWithChildren<ContributionTableProps>> = ({ data, ...props }) => {
  const styles = useStyles();
  const { formatMessage, formatCurrencyNumber } = useTranslation();

  const label = useMemo(
    () => ({
      headStart: formatMessage({ id: 'EmployerContributionTopupZoneProductTitleColumnTable' }),
      headMiddle: formatMessage({
        id: 'EmployerContributionTopupZoneTopupConsumedTitleColumnTable'
      }),
      headEnd: formatMessage({ id: 'EmployerContributionTopupZoneTopupRemainingTitleColumnTable' }),
      footerStart: formatMessage({ id: 'EmployerContributionTopupZoneTopupTotalLineTable' })
    }),
    [formatMessage]
  );

  const header: TableCellInterface[] = useMemo(
    () => [
      {
        type: TableCellTypeEnum.DEFAULT,
        children: (
          <Text
            variant="t4"
            weight="light">
            {label.headStart}
          </Text>
        ),
        containerStyle: styles.tableHeadLeft
      },
      {
        type: TableCellTypeEnum.DEFAULT,
        children: (
          <Text
            style={styles.tableHeaderMiddleText}
            variant="t4"
            weight="light">
            {label.headMiddle}
          </Text>
        ),
        containerStyle: styles.tableHeadMiddle
      },
      {
        type: TableCellTypeEnum.DEFAULT,
        children: (
          <Text
            style={styles.tableHeaderRightText}
            variant="t4"
            weight="light">
            {label.headEnd}
          </Text>
        ),
        containerStyle: styles.tableHeadRight
      }
    ],
    [label, styles]
  );

  const rows = useMemo(
    () =>
      (data?.planContributions ?? []).map(plan => [
        {
          type: TableCellTypeEnum.DEFAULT,
          children: (
            <Text
              variant="t3"
              weight="bold">
              {plan.planName}
            </Text>
          ),
          containerStyle: styles.tableRowLeft
        },
        {
          type: TableCellTypeEnum.DEFAULT,
          children: (
            <Text
              variant="t3"
              weight="bold">
              {formatCurrencyNumber({ value: plan.consumed })}
            </Text>
          ),
          containerStyle: styles.tableRowMiddle
        },
        {
          type: TableCellTypeEnum.DEFAULT,
          children: (
            <Text
              variant="t3"
              weight="bold">
              {formatCurrencyNumber({ value: plan.balance })}
            </Text>
          ),
          containerStyle: styles.tableRowRight
        }
      ]),
    [data?.planContributions, formatCurrencyNumber, styles]
  );

  const footer: TableCellInterface[] = useMemo(
    () => [
      {
        type: TableCellTypeEnum.DEFAULT,
        children: (
          <Text
            style={styles.tableFooterLeftText}
            variant="t3"
            weight="bold">
            {label.footerStart}
          </Text>
        ),
        containerStyle: styles.tableFooterLeft
      },
      {
        type: TableCellTypeEnum.DEFAULT,
        children: (
          <Text
            style={styles.tableFooterMiddleText}
            variant="t3"
            weight="bold">
            {formatCurrencyNumber({ value: data?.totalConsumed || 0 })}
          </Text>
        ),
        containerStyle: styles.tableFooterMiddle
      },
      {
        type: TableCellTypeEnum.DEFAULT,
        children: (
          <Text
            style={styles.tableFooterRightText}
            variant="t3"
            weight="bold">
            {formatCurrencyNumber({ value: data?.totalBalanced || 0 })}
          </Text>
        ),
        containerStyle: styles.tableFooterRight
      }
    ],
    [formatCurrencyNumber, data, label, styles]
  );

  const tableData = useMemo<TableCellInterface[][]>(
    () => [header, ...rows, footer],
    [header, rows, footer]
  );

  return (
    <Table
      data={tableData}
      testId={locator._dashboard._my_employer_top._title}
      {...props}
    />
  );
};
