import { connect } from 'react-redux';

import { selectInvestedManagement, selectInvestedSupport } from '@modules/arbitration/actions/arbitrationActions';
import {
  getInvestedCompartment,
  getInvestedTargetData,
  getPlanCompartments,
  getSelectedDispositif,
  getTotalInvestedPercent
} from '@modules/arbitration/selectors';
import { AppState } from '@modules/reducers';

import { ArbitrationReinvestment } from './ArbitrationReinvestment.component';

const mapStateToProps = (state: AppState) => ({
  selectedDispositif: getSelectedDispositif(state),
  selectedInvestedCompartment: getInvestedCompartment(state),
  investedTargetData: getInvestedTargetData(state),
  totalInvestedPercent: getTotalInvestedPercent(state),
  planCompartments: getPlanCompartments(state),
});

const mapDispatchToProps = ({
  onSelectInvestedSupport: selectInvestedSupport,
  onSelectInvestedManagement: selectInvestedManagement,
});

export const ArbitrationReinvestmentPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(ArbitrationReinvestment);
