import 'intl';
import React, { PropsWithChildren } from 'react';
import { IntlProvider } from 'react-intl';

import { labelsHtmlHandler } from '@ere-uilib/utils';

interface TranslateRawLabelsProviderProps {
  labels: any
}

export const TranslateRawLabelsProvider = React.createContext({
  labels: {}
} as TranslateRawLabelsProviderProps);

export const useRawLabels = () => React.useContext(TranslateRawLabelsProvider)?.labels;

interface TranslateProviderProps {
  language: string,
  labels: any
}

export const TranslateProvider:React.FC<PropsWithChildren<TranslateProviderProps>> = ({
  children,
  language,
  labels
}) => {
  const cleanedLabels = labelsHtmlHandler({ ...labels });
  return (
    <IntlProvider
      onError={() => {}}
      locale={language}
      messages={cleanedLabels}>
      <TranslateRawLabelsProvider.Provider value={{ labels }}>
        {children}
      </TranslateRawLabelsProvider.Provider>
    </IntlProvider>
  );

};

