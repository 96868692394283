import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  clearDrivingData,
  getControlDataEditingOptionsRequest,
  getControlDataRequest,
  getRehydrateControlDataRequest,
  postDrivingDataRequest
} from '@modules/control-data/actions/controlDataActions';
import {
  getControlData,
  getControlDataEditingOptions,
  getControlDataEditingOptionsError,
  getControlDataError,
  isPostEditionDataSuccess,
  postDrivingDataError,
} from '@modules/control-data/selectors';

import { ControlDataPageComponent } from './ControlDataPage.component';
import { ControlDataActionsType } from '@modules/control-data/actions/controlDataActionsTypes';
import { AppState } from '@modules/index';

export const ControlDataPageContainer = () => {
  const dispatch = useDispatch();
  const controlData = useSelector(getControlData);
  const editingOptions = useSelector(getControlDataEditingOptions);
  const devicesControlDataError = useSelector(getControlDataError);
  const updatePilotingDataError = useSelector(getControlDataEditingOptionsError);
  const postdrivingDataError = useSelector(postDrivingDataError);
  const isPostedDrivingDataSuccess = useSelector(isPostEditionDataSuccess);

  const isInitApiLoading = useSelector((state: AppState) => state.isLoading[ControlDataActionsType.GET_CONTROL_DATA_REQUEST
  ]);
  const isEditApiLoading = useSelector((state: AppState) => state.isLoading[ControlDataActionsType.GET_CONTROL_DATA_EDITING_OPTIONS_REQUEST]);
  const isPostDrivingDataApiLoading = useSelector((state: AppState) => state.isLoading[ControlDataActionsType.POST_CONTROL_DATA_EDITING_OPTIONS_REQUEST
  ]);
  const controlDataStatus = useMemo(() => {
    return {
      isDevicesControlDataError: devicesControlDataError,
      isGetUpdatePilotingDataError: updatePilotingDataError,
      isPostDataError: postdrivingDataError,
      isLoadingInit: isInitApiLoading,
      isEditApiLoading: isEditApiLoading,
      isPostDrivingDataLoading: isPostDrivingDataApiLoading,
      isPostDrivingDataSuccess: isPostedDrivingDataSuccess
    };
  }, [devicesControlDataError, updatePilotingDataError, postdrivingDataError, isInitApiLoading, isEditApiLoading, isPostDrivingDataApiLoading])

  const fetchControlData = useCallback(() => dispatch(getControlDataRequest()), [dispatch]);
  const fetchEditingOptions = useCallback(
    (planId: number) => dispatch(getControlDataEditingOptionsRequest(planId)),
    [dispatch]
  );
  const rehydrateControlData = useCallback(
    (...args: Parameters<typeof getRehydrateControlDataRequest>) =>
      dispatch(getRehydrateControlDataRequest(...args)),
    [dispatch]
  );

  const postEditedControlData = useCallback(
    (...args: Parameters<typeof postDrivingDataRequest>) =>
      dispatch(postDrivingDataRequest(...args)), [dispatch]
  );

  const clearControlData = useCallback(() => dispatch(clearDrivingData()), [dispatch]);

  return (
    <ControlDataPageComponent
      controlDataStatus={controlDataStatus}
      data={controlData}
      editingOptions={editingOptions}
      onGetControlData={fetchControlData}
      onGetEditingOptions={fetchEditingOptions}
      onPostDrivingData={postEditedControlData}
      onRehydrateControlData={rehydrateControlData}
      onClearControlData={clearControlData}
    />
  );
};
