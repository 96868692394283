import { useNavigation } from '@react-navigation/native';
import { useCallback } from 'react';

import { RouteNames } from '@constants/navigation';
import { InputMode } from '@modules/common/constants';

import { useData as useCommonData } from '../useData';

export const useData = () => {
  const navigation = useNavigation();

  const { inputMode = InputMode.TSA, ...commonData } = useCommonData();

  const goToNextPage = useCallback(() => {
    navigation.navigate(RouteNames.ProfileEditAddress, {
      screen: RouteNames.ProfileEditAddressDocument
    });
  }, [navigation]);

  const goToProfileMenu = useCallback(
    () =>
      navigation.navigate(RouteNames.BottomTabNavigator, {
        screen: RouteNames.ProfileStack,
        params: {
          screen: RouteNames.Profile
        }
      }),
    [navigation]
  );

  const handleNextButtonPress = useCallback(() => {
    if (inputMode === InputMode.TSA_OR_UPLOAD) goToNextPage();
    else goToProfileMenu();
  }, [goToNextPage, goToProfileMenu, inputMode]);

  return {
    inputMode,
    handleNextButtonPress,
    ...commonData
  };
};
