import { ApiPaymentPeriodicityEnum } from '@constants/index';
import { OnGoingVVP } from '@modules/savings/types';

export const mockOngoingVVP: OnGoingVVP[] = 
[
  {
    'id': 'VVP01',
    'planId': 2001,
    'planFamily': 'ES',
    'planName': 'PEE',
    'compartmentCode': null,
    'compartmentLabel': null,
    'createdDate': '2019-02-01T00:00:00+00:00',
    'nextPaymentDate': '2020-02-02T00:00:00+00:00',
    'periodicity': ApiPaymentPeriodicityEnum['3M'],
    'bankAccountDetail': null,
    'amount': 40,
    'taxIn': true,
    'taxOut': false,
    'managements': [
      {
        'id': 'PEE-AMPLISSIM',
        'code': 'PEE AMPLISSIM',
        'label': 'PEE AMPLISSIM',
        'totalAmount': 40,
        'supports': [
          {
            'supportIsin': 'FR0010997866',
            'supportName': 'CAM GES GENERATION 2026-30 R',
            'supportType': null,
            'supportIdNoee': '28025',
            'riskLevel': '2',
            'riskLevelSRI': '4',
            'riskLevelSRRI': '4',
            'performanceValue': null,
            'amount': {
              'amount': 25,
              'addedValue': null,
              'numberOfUnits': null,
            },
            'supportDetailsUrl': null,
            'legalStatus': null,
            'isMaster': false,
            'hasContribution': false,
            'netAssetValue': null,
            'plans': null,
            'hasVersement': false,
            'hasInteressement': false,
            'hasParticipation': false,
            'nextNavDate': null,
            'advancedProperties': {},
            'filterProperties': {},
            'isISR': false,
          },
          {
            'supportIsin': 'QS0002105TJ2',
            'supportName': 'MULTIMANAG ACT EUR FIDL C',
            'supportType': null,
            'supportIdNoee': '1042',
            'riskLevel': '2', 
            'riskLevelSRI': '4',
            'riskLevelSRRI': '4',
            'performanceValue': null,
            'amount': {
              'amount': 15,
              'addedValue': null,
              'numberOfUnits': null,
            },
            'supportDetailsUrl': null,
            'legalStatus': null,
            'isMaster': false,
            'hasContribution': false,
            'netAssetValue': null,
            'plans': null,
            'hasVersement': false,
            'hasInteressement': false,
            'hasParticipation': false,
            'nextNavDate': null,
            'advancedProperties': {},
            'filterProperties': {},
            'isISR': false,
          },
        ],
        'order': 0,
        'isFree': false,
        'filterProperties': null,
        'advancedProperties': null,
      },
    ],
  },
  {
    'id': 'VVP02',
    'planId': 2002,
    'planFamily': 'ES',
    'planName': 'PEE',
    'compartmentCode': null,
    'compartmentLabel': null,
    'createdDate': '2019-02-01T00:00:00+00:00',
    'nextPaymentDate': '2020-02-02T00:00:00+00:00',
    'periodicity': ApiPaymentPeriodicityEnum['3M'],
    'bankAccountDetail': null,
    'amount': 40,
    'taxIn': true,
    'taxOut': false,
    'managements': [
      {
        'id': 'PEE-AMPLISSIM',
        'code': 'PEE AMPLISSIM',
        'label': 'PEE AMPLISSIM',
        'totalAmount': 40,
        'supports': [
          {
            'supportIsin': 'FR0010997866',
            'supportName': 'CAM GES GENERATION 2026-30 R',
            'supportType': null,
            'supportIdNoee': '28025',
            'riskLevel': '2',
            'riskLevelSRI': '4',
            'riskLevelSRRI': '4',
            'performanceValue': null,
            'amount': {
              'amount': 25,
              'addedValue': null,
              'numberOfUnits': null,
            },
            'supportDetailsUrl': null,
            'legalStatus': null,
            'isMaster': false,
            'hasContribution': false,
            'netAssetValue': null,
            'plans': null,
            'hasVersement': false,
            'hasInteressement': false,
            'hasParticipation': false,
            'nextNavDate': null,
            'advancedProperties': {},
            'filterProperties': {},
            'isISR': false,
          },
          {
            'supportIsin': 'QS0002105TJ2',
            'supportName': 'MULTIMANAG ACT EUR FIDL C',
            'supportType': null,
            'supportIdNoee': '1042',
            'riskLevel': '2', 
            'riskLevelSRI': '4',
            'riskLevelSRRI': '4',
            'performanceValue': null,
            'amount': {
              'amount': 15,
              'addedValue': null,
              'numberOfUnits': null,
            },
            'supportDetailsUrl': null,
            'legalStatus': null,
            'isMaster': false,
            'hasContribution': false,
            'netAssetValue': null,
            'plans': null,
            'hasVersement': false,
            'hasInteressement': false,
            'hasParticipation': false,
            'nextNavDate': null,
            'advancedProperties': {},
            'filterProperties': {},
            'isISR': false,
          },
        ],
        'order': 0,
        'isFree': false,
        'filterProperties': null,
        'advancedProperties': null,
      },
    ],
  }

]
;

export const mockSavingsHistory5years = [
  {
    "date": "2022-02-28T00:00:00",
    "savingAmount": 6668.313953,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2022-03-31T00:00:00",
    "savingAmount": 6645.3467372,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2022-04-30T00:00:00",
    "savingAmount": 6625.543156,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2022-05-31T00:00:00",
    "savingAmount": 6575.6822123,
    "operationsAmount": 8712.62,
    "operations": [
      {
        "id": "O-153181737",
        "code": "BO_INTERESSEMENT",
        "label": "Intéressement",
        "type": "Affectation interessement",
        "statusCode": "Termine",
        "statusLabel": "Validé",
        "amount": 6652.6,
        "dateTime": "2022-06-23T02:00:00+02:00",
        "compartimentId": "PEE",
        "frontDisplayed": true,
        "planId": 1002,
      },
      {
        "id": "O-153183535",
        "code": "BO_PARTICIPATION",
        "label": "Participation",
        "type": "Affectation participation",
        "statusCode": "Termine",
        "statusLabel": "Validé",
        "amount": 2060.02,
        "dateTime": "2022-06-23T02:00:00+02:00",
        "compartimentId": "PEE",
        "frontDisplayed": true,
        "planId": 1002,
      }
    ]
  },
  {
    "date": "2022-06-30T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": [
      {
        "id": "O-153734948",
        "code": "COMPTABLE_GAGNANT_TITRE",
        "label": "Transfert sur mon PEE",
        "type": "Transfert entrant",
        "statusCode": "Termine",
        "statusLabel": "Validé",
        "amount": 0,
        "dateTime": "2022-07-07T10:53:21+02:00",
        "compartimentId": "PEE",
        "frontDisplayed": true,
        "planId": 1002,
      }
    ]
  },
  {
    "date": "2022-07-31T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2022-08-31T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2022-09-30T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2022-10-31T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  }
];

export const mockSavingsHistory1year = [
  {
    "date": "2022-02-28T00:00:00",
    "savingAmount": 6668.313953,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2022-03-31T00:00:00",
    "savingAmount": 6645.3467372,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2022-04-30T00:00:00",
    "savingAmount": 6625.543156,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2022-05-31T00:00:00",
    "savingAmount": 6575.6822123,
    "operationsAmount": 8712.62,
    "operations": [
      {
        "id": "O-153181737",
        "code": "BO_INTERESSEMENT",
        "label": "Intéressement",
        "type": "Affectation interessement",
        "statusCode": "Termine",
        "statusLabel": "Validé",
        "amount": 6652.6,
        "dateTime": "2022-06-23T02:00:00+02:00",
        "compartimentId": "PEE",
        "frontDisplayed": true,
        "planId": 1002,
      },
      {
        "id": "O-153183535",
        "code": "BO_PARTICIPATION",
        "label": "Participation",
        "type": "Affectation participation",
        "statusCode": "Termine",
        "statusLabel": "Validé",
        "amount": 2060.02,
        "dateTime": "2022-06-23T02:00:00+02:00",
        "compartimentId": "PEE",
        "frontDisplayed": true,
        "planId": 1002,
      }
    ]
  },
  {
    "date": "2022-06-30T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": [
      {
        "id": "O-153734948",
        "code": "COMPTABLE_GAGNANT_TITRE",
        "label": "Transfert sur mon PEE",
        "type": "Transfert entrant",
        "statusCode": "Termine",
        "statusLabel": "Validé",
        "amount": 0,
        "dateTime": "2022-07-07T10:53:21+02:00",
        "compartimentId": "PEE",
        "frontDisplayed": true,
        "planId": 1002,
      }
    ]
  },
  {
    "date": "2022-07-31T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2022-08-31T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2022-09-30T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2022-10-31T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2022-11-30T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 950,
    "operations": [
      {
        "id": "O-156805635",
        "code": "COMPTABLE_ABONDEMENT",
        "label": "Versement de l'abondement sur mon PERECO",
        "type": "Affectation abondement",
        "statusCode": "InProgress",
        "statusLabel": "En cours de traitement",
        "amount": 0,
        "dateTime": "2022-12-26T17:36:27+01:00",
        "compartimentId": "Libre",
        "frontDisplayed": true,
        "planId": 1001,
      },
      {
        "id": "O-156805633",
        "code": "VERSEMENT_CB",
        "label": "Versement par carte de crédit sur mon PERECO",
        "type": "Versement",
        "statusCode": "InProgress",
        "statusLabel": "En cours de traitement",
        "amount": 950,
        "dateTime": "2022-12-26T17:33:04+01:00",
        "compartimentId": "Libre",
        "frontDisplayed": true,
        "planId": 1001,
      }
    ]
  },
  {
    "date": "2022-12-31T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2023-01-31T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2023-02-28T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2023-03-31T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2023-04-30T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2023-05-31T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2023-06-30T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2023-07-31T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2023-08-31T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2023-09-30T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2023-10-31T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2023-11-30T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2023-12-31T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2024-01-31T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2024-02-29T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2024-03-31T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2024-04-30T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2024-05-31T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2024-06-30T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2024-07-31T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2024-08-31T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2024-09-30T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2024-10-31T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2024-11-30T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2024-12-31T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2025-01-31T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2025-02-28T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2025-03-31T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2025-04-30T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2025-05-31T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2025-06-30T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2025-07-31T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2025-08-31T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2025-09-30T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2025-10-31T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2025-11-30T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2025-12-31T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2026-01-31T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2026-02-28T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2026-03-31T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2026-04-30T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2026-05-31T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2026-06-30T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2026-07-31T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2026-08-31T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2026-09-30T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2026-10-31T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  }
];

export const mockSavingsHistory3years = [
  {
    "date": "2022-02-28T00:00:00",
    "savingAmount": 6668.313953,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2022-03-31T00:00:00",
    "savingAmount": 6645.3467372,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2022-04-30T00:00:00",
    "savingAmount": 6625.543156,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2022-05-31T00:00:00",
    "savingAmount": 6575.6822123,
    "operationsAmount": 8712.62,
    "operations": [
      {
        "id": "O-153181737",
        "code": "BO_INTERESSEMENT",
        "label": "Intéressement",
        "type": "Affectation interessement",
        "statusCode": "Termine",
        "statusLabel": "Validé",
        "amount": 6652.6,
        "dateTime": "2022-06-23T02:00:00+02:00",
        "compartimentId": "PEE",
        "frontDisplayed": true,
        "planId": 1002,
      },
      {
        "id": "O-153183535",
        "code": "BO_PARTICIPATION",
        "label": "Participation",
        "type": "Affectation participation",
        "statusCode": "Termine",
        "statusLabel": "Validé",
        "amount": 2060.02,
        "dateTime": "2022-06-23T02:00:00+02:00",
        "compartimentId": "PEE",
        "frontDisplayed": true,
        "planId": 1002,
      }
    ]
  },
  {
    "date": "2022-06-30T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": [
      {
        "id": "O-153734948",
        "code": "COMPTABLE_GAGNANT_TITRE",
        "label": "Transfert sur mon PEE",
        "type": "Transfert entrant",
        "statusCode": "Termine",
        "statusLabel": "Validé",
        "amount": 0,
        "dateTime": "2022-07-07T10:53:21+02:00",
        "compartimentId": "PEE",
        "frontDisplayed": true,
        "planId": 1002,
      }
    ]
  },
  {
    "date": "2022-07-31T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2022-08-31T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2022-09-30T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2022-10-31T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2022-11-30T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 950,
    "operations": [
      {
        "id": "O-156805635",
        "code": "COMPTABLE_ABONDEMENT",
        "label": "Versement de l'abondement sur mon PERECO",
        "type": "Affectation abondement",
        "statusCode": "InProgress",
        "statusLabel": "En cours de traitement",
        "amount": 0,
        "dateTime": "2022-12-26T17:36:27+01:00",
        "compartimentId": "Libre",
        "frontDisplayed": true,
        "planId": 1001,
      },
      {
        "id": "O-156805633",
        "code": "VERSEMENT_CB",
        "label": "Versement par carte de crédit sur mon PERECO",
        "type": "Versement",
        "statusCode": "InProgress",
        "statusLabel": "En cours de traitement",
        "amount": 950,
        "dateTime": "2022-12-26T17:33:04+01:00",
        "compartimentId": "Libre",
        "frontDisplayed": true,
        "planId": 1001,
      }
    ]
  },
  {
    "date": "2022-12-31T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2023-01-31T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2023-02-28T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2023-03-31T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2023-04-30T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2023-05-31T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2023-06-30T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2023-07-31T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2023-08-31T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2023-09-30T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2023-10-31T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2023-11-30T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2023-12-31T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2024-01-31T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2024-02-29T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2024-03-31T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2024-04-30T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2024-05-31T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2024-06-30T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2024-07-31T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2024-08-31T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2024-09-30T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  },
  {
    "date": "2024-10-31T00:00:00",
    "savingAmount": 17660.4088435,
    "operationsAmount": 0,
    "operations": []
  }
];
