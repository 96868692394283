
import { Context, Styles } from './interfaces';

export const getStyles = (
  context?: Context,
  style?: Styles,
): Styles => ({
  container: [
    {
      flex: 1,
      alignItems: 'center',
      backgroundColor: context?.theme.colors.background.poppinFilter.c500,
    },
    context?.isMobile
      ? {
        justifyContent: 'flex-end',
      }
      : {
        justifyContent: 'center',
      },
    style?.container,
  ],
});
