import React from 'react';
import { View } from 'react-native';

import {
  Modal,
  ModalFrame,
  NotificationIcon,
  NotificationIconTypeEnum,
  SimpleButton,
  Title,
  useScreenSizes,
  useTheme,
  NotificationHard,
} from '@components/index';
import { createUseStyles } from '@ere-uilib/styles';

import { useTranslation } from '@ere-uilib/translations';

import { VVPDeleteModalProps } from './interface';
import { getStyles } from './styles';

export const VVPDeleteModal = ({
  visible,
  onCancel,
  onValid,
  error,
  isOnValidLoading,
}:VVPDeleteModalProps) => {
  const theme = useTheme();
  const { isMobile } = useScreenSizes();
  const { formatMessage } = useTranslation();
  const styles = useStyles({ theme, isMobile }, {});

  return (
    <Modal
      visible={visible}
    >
      <ModalFrame>
        <>
          <View style={styles.iconContainer}>
            <NotificationIcon type={NotificationIconTypeEnum.WARNING} />
          </View>
          <View style={styles.textContainer}>
            <Title
              style={styles.title}
              variant="t6">
              {formatMessage({ id: 'VV_VVP_Actuel_ModaleSuppr_titre' })}
            </Title>
            <Title
              style={styles.text}
              variant="t7"
              weight="light">
              {formatMessage({ id: 'VV_VVP_Actuel_ModaleSuppr_description' })}
            </Title>
          </View>
          {!!error && !isOnValidLoading && (
            <NotificationHard
              containerStyle={styles.errorNotification}
              text={error}
              type={NotificationIconTypeEnum.WARNING}
            />
          )}
          <View style={styles.buttonsContainer}>
            <SimpleButton
              containerStyle={[styles.button, styles.firstButton]}
              design="outlined"
              loading={isOnValidLoading}
              onPress={onValid}
              title={formatMessage({ id: 'VV_VVP_Actuel_ModaleSupprYes_bouton' })}
            />
            <SimpleButton
              containerStyle={[styles.button]}
              disabled={isOnValidLoading}
              onPress={onCancel}
              title={formatMessage({ id: 'VV_VVP_Actuel_ModaleSupprNo_bouton' })}
            />
          </View>
        </>
      </ModalFrame>
    </Modal>
  );
};

const useStyles = createUseStyles(getStyles);
