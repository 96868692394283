import React from 'react'
import { View } from 'react-native'
import { GlobalSavingsContentProps } from './interface'
import { useTranslation } from '@ere-uilib/translations'
import { Paragraph, Title } from '@ere-uilib/atoms'
import { GlobalSavingsCard, RepartitionDonut, RepartitionGraph } from '@ere-uilib/molecules'
import { useStyles } from './useStyles'
import { useScreenSizes } from '@ere-uilib/styles'
import { useCallback } from 'react'
import { AllocationsNamesEnum } from '@ere-uilib/types'

export const GlobalSavingsContent = ({
  isGraphDisplayed,
  filteredPlans,
  allocations,
  onPlanPress
}: GlobalSavingsContentProps) => {
  const { formatMessage } = useTranslation()
  const styles = useStyles(isGraphDisplayed)
  const { isMobile } = useScreenSizes()

  const renderRepartition = useCallback(() => {
    if (!isGraphDisplayed) {
      return null
    }
    return isMobile ? (
      <RepartitionGraph allPlans={filteredPlans} />
    ) : (
      <RepartitionDonut allPlans={filteredPlans} />
    )
  }, [filteredPlans, isGraphDisplayed, isMobile])

  return (
    <View style={styles.innerContainerStyle}>
      {renderRepartition()}
      {allocations && allocations.length > 0 && (
        <View style={styles.contentContainerStyle}>
          {allocations.map((item: any, index: number) => {
            const isMoyenTerm = item.name === AllocationsNamesEnum.MOYEN_TERM
            const isListEmpty = !(item.plans?.length && item.plans?.length > 0)
            if (isListEmpty && !isMoyenTerm) {
              return null
            }
            return (
              <View
                key={index}
                style={styles.cardStyle}>
                <Title
                  testId={item.label}
                  style={styles.cardTitleStyle}
                  variant="t7"
                  weight="bold">
                  {item.label}
                </Title>
                {!isListEmpty ? (
                  item.plans.map((savings: any, savingsIndex: number) => (
                    <GlobalSavingsCard
                      index={savingsIndex}
                      key={savingsIndex}
                      onPlanPress={() => onPlanPress(savings.planID)}
                      plan={savings}
                    />
                  ))
                ) : (
                  <View style={styles.noPlanTextContainer}>
                    <Paragraph
                      variant={'t3'}
                      weight="light">
                      {formatMessage({ id: 'Dashboard_CardRepartition_NoPEE_label' })}
                    </Paragraph>
                  </View>
                )}
              </View>
            )
          })}
        </View>
      )}
    </View>
  )
}
