import { getLocaleLanguage } from '@components/ERE-UILib/translations/messages';
import { languageList } from '@constants/languages';

export const getDefaultLanguage = (): string => {
  const locale = getLocaleLanguage();
  const language = locale.language || '';

  return getValidLanguage(language);
};

const getValidLanguage = (language: string): string => {
  // by default we use the first language of language list
  let output = languageList[0].value;
  // check if language is in languages list
  const isLanguageValid = languageList.find(item => item.value === language);
  if (isLanguageValid) {
    output = language;
  }
  return output;
};
