import {
  StylesContext,
  SplashScreenContentStyles,
} from './interfaces';

export const getStyles = (
  context?: StylesContext,
  style?: SplashScreenContentStyles
): SplashScreenContentStyles => ({
  containerStyle: [
    {
      height: context?.windowHeight,
    },
    style?.containerStyle,
  ],
  logoStyle: [
    context?.isMobile && {
      marginTop: context?.theme.metrics.spacing.m,
      marginLeft: context?.theme.metrics.spacing.s,
    },
    context?.isTablet && {
      marginTop: context?.theme.metrics.spacing.big,
    },
    !(context?.isMobile || context?.isTablet) && {
      marginTop: context?.theme.metrics.spacing.huge,
    },
  ],
  innerContainerStyle: [
    {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
      padding: context?.theme.metrics.spacing.s,
      maxWidth: context?.theme.metrics.contentSizes.centeredContentMaxWidth,
    },
  ],
  titleStyle: [
    {
      marginTop: context?.theme.metrics.spacing.xm,
    },
    style?.titleStyle,
  ],
  subtitleStyle: [
    {
      marginVertical: context?.theme.metrics.spacing.xm,
      maxWidth: 450,
      textAlign: 'center',
    },
    style?.subtitleStyle,
  ],
});
