import { Context, Styles } from './interfaces';

export const getStyles = (
  context?: Context,
  style?: Styles,
): Styles => ({
  titleAndSubtitleContainer: [
    {
      marginTop: context?.theme.metrics.spacing.xl,
      marginBottom: context?.theme.metrics.spacing.s,
    }, style?.titleAndSubtitleContainer,
  ],
  notificationContainer: [
    {
      flex: 1,
      marginVertical: context?.theme?.metrics.spacing.xm,
    },
    style?.notificationContainer,
  ],
});
