import { useMemo } from "react"
import { Platform, StyleSheet } from "react-native"

import { useScreenSizes, useTheme } from "@ere-uilib/styles"

import { StyleProps } from "./interface"

const SLIDER_CARD_WIDTH = 355;
const SLIDER_CARD_HEIGHT = 405;

export const useStyles = (context: StyleProps) => {
  const { isMobile } = useScreenSizes();
  const theme = useTheme()
  const { windowWidth } = useScreenSizes()
  const styles = useMemo(() => StyleSheet.create({
    toolsListContentContainer: {
      ...(context.toolsDataLength === 1 && {
        justifyContent: 'center',
      })
    },
    toolsItemContainer: {
      marginBottom: theme.metrics.spacing.m,
      width: Math.min(windowWidth - theme.metrics.spacing.xbig, SLIDER_CARD_WIDTH),
      height: SLIDER_CARD_HEIGHT,
      ...Platform.select({
        ios: {
          shadowColor: theme.colors.basics.black,
          shadowOffset: {
            width: 0,
            height: 2,
          },
          shadowOpacity: 0.5,
          borderWidth: 0.1,
        },
        android: {
          shadowColor: theme.colors.basics.black,
          shadowOffset: {
            width: 0,
            height: 2,
          },
          shadowOpacity: 1,
          elevation: 3,
        },
      }),
    },
    loaderContainerWrapper: {
      paddingHorizontal: isMobile ? theme.metrics.spacing.s : theme.metrics.spacing.xl,
    }
  }), [context.toolsDataLength, theme, isMobile])

  return styles
}