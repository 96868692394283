import React from 'react';
import { SafeAreaView, View } from 'react-native';

import {
  Title,
  Paragraph,
  Spinner,
} from '@components/index';
import {
  LogoBnp,
  LogoColorModeEnum,
  LogoOrientationEnum,
} from '@ere-uilib/molecules';
import { createUseStyles, useTheme, useScreenSizes } from '@ere-uilib/styles';

import { getStyles } from './styles';

export const SplashScreenContent = () => {
  const theme = useTheme();
  const { isMobile, isTablet, windowHeight } = useScreenSizes();
  const styles = useStyles(
    { theme, isMobile, isTablet, windowHeight },
    { }
  );

  return (
    <SafeAreaView style={styles.containerStyle}>
      <LogoBnp
        colorMode={LogoColorModeEnum.dark}
        containerStyle={styles.logoStyle}
        orientation={isMobile ? LogoOrientationEnum.row : LogoOrientationEnum.column}
      />
      <View style={styles.innerContainerStyle}>
        <Title
          style={styles.titleStyle}
          variant="t3"
          weight="bold"
        >
          {/* Hard text asked by Damien to ensure always have text */}
          {'Mon Épargne Entreprise'}
        </Title>
        <Spinner
          size={'large'}
        />
      </View>
    </SafeAreaView>
  );
};

const useStyles = createUseStyles(getStyles);
