import React from 'react';

import { useScreenSizes } from '@ere-uilib/styles';
import { LoaderCard } from '@ere-uilib/molecules';
import { ToolCardContentLoader } from './ToolCardContentLoader';

export const ToolCardLoader: React.FC = () => {
  const isMobile = !useScreenSizes().isDesktop;

  return (
    <LoaderCard
      containerStyle={{ margin: 0 }}
      isMobile={isMobile}
    >
      <ToolCardContentLoader />
    </LoaderCard>
  );
};

