import React, { FC } from 'react';
import { View } from 'react-native';

import { Paragraph, Title } from '@ere-uilib/atoms';
import { createUseStyles, useScreenSizes, useTheme } from '@ere-uilib/styles';

import { getStyles } from '../../styles';
import { locator } from '@constants/locator';

type Props = {
  title?: string;
  subTitle?: string;
};

export const IdLandingPageHeader: FC<Props> = ({ title, subTitle }) => {
  const { isDesktop, isTablet, isMobile, windowWidth } = useScreenSizes();
  const theme = useTheme();
  const styles = useStyles({ theme, isMobile, isTablet, isDesktop, windowWidth }, {});

  return (
    <View style={styles.containerHeader}>
      <Title
        style={[styles.titleStyle, !isDesktop && styles.textWhiteStyle]}
        variant={isMobile ? 't5' : 't3'}
        weight="bold"
        testId={locator._login._title}>
        {title}
      </Title>
      <Paragraph
        style={[styles.paragraphStyle, !isDesktop && styles.textWhiteStyle]}
        variant={isMobile ? 't3' : 't2'}
        weight="light">
        {subTitle}
      </Paragraph>
    </View>
  );
};

const useStyles = createUseStyles(getStyles);
