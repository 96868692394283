import { useIsFocused } from '@react-navigation/native';
import React, { useLayoutEffect } from 'react';
import { View } from 'react-native';

import { DialOncePressableProps } from './interface';

// add window _dialonce variable
declare global {
  interface Window { _dialonce: any; }
}

export const DialOncePressable: React.FC<React.PropsWithChildren<DialOncePressableProps>> = ({
  children,
  integrationKey,
  targetedFlowId,
  style
}) => {
  const isFocused = useIsFocused();

  const callDialOnceScript = () => {
    // set dialonce integrationKey
    window._dialonce = window._dialonce || {};
    window._dialonce.integrationKey = integrationKey;

    // check if dial-once script is already added and add it if it's not found ( prevent multiple script add )
    const dialonceScriptSearch = document.getElementById('dialonce-sdk');
    // the script seams to target button with ID at his incertion ( auto called function )
    // so we want to make the script restart so we delete and relaunch it. to make the script target our button
    if (dialonceScriptSearch) {
      document.head.removeChild(dialonceScriptSearch);
    }
    const dialonceScript = document.createElement('script');
    dialonceScript.id = 'dialonce-sdk';
    dialonceScript.type = 'text/javascript';
    dialonceScript.async = true;
    dialonceScript.src = 'https://cdn.dial-once.com/integrations-widget-widget/assets/auto.js';
    document.head.appendChild(dialonceScript);
  };

  useLayoutEffect(() => {
    // we want to active or reactivate script each time the button is in focus page
    if (isFocused) {
      callDialOnceScript();
    }
  }, [isFocused]);

  return (
    <View
      // the id is removed while the button is not in focus page to prevent script to target it
      nativeID={isFocused ? targetedFlowId : undefined}
      style={style}
    >
      {children}
    </View>
  );

};

