import { useMemo } from 'react';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';

import {
  useTheme
} from '@components/index';

export const  useStyles =({
  style,
}:{
  style?: StyleProp<ViewStyle>
})=>{
  const theme = useTheme();

  const styles = useMemo(()=>StyleSheet.create({
    containerStyle: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      borderRadius: theme.metrics.borderRadius.xs,
      backgroundColor: theme.colors.basics.white,
      paddingHorizontal: theme.metrics.spacing.s,
      paddingVertical: theme.metrics.spacing.xs,
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowRadius: 9,
      shadowColor: theme.colors.notifications.shadow,
      elevation: 1,
    },
    detailContainer: { 
      flex: 1,
      minWidth: 300,
      alignItems: 'center',
      flexDirection: 'row',
      marginVertical: theme.metrics.spacing.xs,
      ...(style && StyleSheet.flatten(style))
    },
    chevronStyle: {
      alignSelf: 'center',
      paddingRight: theme.metrics.spacing.xs,
    },
    textContainer: {
      borderLeftWidth: 1,
      borderColor: theme.colors.basics.grey.c200,
      flex: 1,
      paddingLeft: theme.metrics.spacing.xs,
      paddingRight: theme.metrics.spacing.s,
    }
  }), [theme, style])
  return styles

}