
import React, { useMemo } from 'react';
import { View, StyleSheet} from 'react-native';

import { Text } from '@ere-uilib/atoms';
import { FiletStepper } from '@ere-uilib/molecules';
import { Pen } from '@ere-uilib/molecules/Pen';
import { useTheme } from '@ere-uilib/styles';

interface Props {
  title?: string
  input?: string
  iconName?: string
  onEdit?: () => void
  disableLine?: boolean
}

export const RequirementDefinitionProposalItem: React.FC <Props> = ({
  title,
  input,
  disableLine = false,
  onEdit,
}) => {
  const styles = useStyles();
  return (
    <FiletStepper
      disableLine={disableLine}>
      <View style={styles.containerStyle}>
        <Text
          variant="t3"
          weight="light"
        >
          {`${title} : `}
        </Text>
        <View style={styles.contentContainer}>
          <Text
            style={styles.titleStyle}
            variant="t3"
            weight="bold"
          >
            {input}
          </Text>
          <Pen
            onPress={onEdit}
          />
        </View>
      </View>
    </FiletStepper>
  );
};





const useStyles = () => {
  const theme = useTheme()
  const styles = useMemo(
    () =>
      StyleSheet.create({
        containerStyle: {
          paddingLeft: theme?.metrics.spacing.xm,
          flexDirection: 'row',
          maxWidth: '100%',
          flexWrap: 'wrap',
        },
        contentContainer: {
          flexDirection: 'row',
        },
        titleStyle: {
          marginRight: theme?.metrics.spacing.s,
        },
      }),
    [theme]
  )

  return styles
}

