import { useNavigation } from '@react-navigation/native';
import React, { useCallback } from 'react';
import { View } from 'react-native';

import {
  useTheme,
  useScreenSizes,
  EERContentWrapper,
  Link,
  DialOncePressable,
  EERPageWrapperContainer,
  CardChoice
} from '@components/index';
import { locator } from '@constants/locator';
import { RouteNames } from '@constants/navigation';
import { useTranslation } from '@ere-uilib/translations';

import { Props } from './interfaces';
import { useStyles } from './styles';

export const ActivateAccountModeSelectionPageComponent: React.FC<React.PropsWithChildren<Props>> = ({
  applicationSettings
}) => {
  const theme = useTheme();
  const navigation = useNavigation();
  const { isDesktop } = useScreenSizes();
  const styles = useStyles({ theme, isMobile: !isDesktop }, {});

  const { formatMessage } = useTranslation();

  const renderHelpLink = () => (
    <DialOncePressable
      integrationKey={applicationSettings.HelpDialOnceIntegrationKey || ''}
      targetedFlowId={applicationSettings.HelpLoginPageDialOnceId || ''}
    >
      <Link
        onPress={() => { }}
        textStyle={styles.helpLinkStyle}
        textVariant="t3"
        testID={locator._account_activation._help_link}
      >
        {formatMessage({ id: 'EerSelfcareStep1DifficulteLabel' })}
      </Link>
    </DialOncePressable>
  );

  const handlePressPersoneoChoice = useCallback(() => {
    navigation.navigate(RouteNames.SelfcareStack, {
      screen: RouteNames.PersoneoIdentifierForm,
    });
  }, [navigation]);

  const handlePressVisiogoChoice = useCallback(() => {
    navigation.navigate(RouteNames.SelfcareStack, {
      screen: RouteNames.VisiogoIdentifierForm,
    });
  }, [navigation]);

  const handlePressLetterChoice = useCallback(() => {
    navigation.navigate(RouteNames.SelfcareStack, {
      screen: RouteNames.LetterIdentifierForm,
    });
  }, [navigation]);
  const canGoback = navigation.canGoBack();
  const handlePressBackButton = useCallback(() => navigation.goBack(), [navigation]);
  const handleClose = useCallback(() => navigation.navigate(RouteNames.OidcLogoutCallbackRedirect), [navigation]);

  return (
    <EERPageWrapperContainer
      activeStep={1}
      onPressBackButton={handlePressBackButton}
      onPressCloseButton={handleClose}
    >
      <EERContentWrapper
        backButtonTitle={canGoback ? formatMessage({ id: 'EerSelfcareStep1RetourLabel' }) : undefined}
        containerStyle={{
          flex: 2.1,
          marginHorizontal: isDesktop ? theme.metrics.spacing.xxl : 0,
        }}
        onPressBackButton={handlePressBackButton}
        testId={locator._account_activation._title}
        title={formatMessage({ id: 'EerSelfcareStep1InfoTitle' })}
      >
        <View style={styles.cardContainers}>
          <CardChoice
            disabled={!applicationSettings?.OptionEERIdentifiantPersoneo}
            onPress={handlePressPersoneoChoice}
            style={styles.cardChoiceContainer}
            subtitle={formatMessage({ id: 'EerSelfcareStep1ChoixPersoneoSublabel' })}
            testId={locator._account_activation._personeo}
            title={formatMessage({ id: 'EerSelfcareStep1ChoixPersoneoLabel' })}
          />
          <CardChoice
            disabled={!applicationSettings?.OptionEERIdentifiantVisiogo}
            onPress={handlePressVisiogoChoice}
            style={styles.cardChoiceContainer}
            subtitle={formatMessage({ id: 'EerSelfcareStep1ChoixVisiogoSublabel' })}
            testId={locator._account_activation._visiogo}
            title={formatMessage({ id: 'EerSelfcareStep1Choix_VisiogoLabel' })}
          />
          <CardChoice
            disabled={!applicationSettings?.OptionEERIdentifiantCourrier}
            onPress={handlePressLetterChoice}
            style={styles.cardChoiceContainer}
            testId={locator._account_activation._courriel}
            title={formatMessage({ id: 'EerSelfcareStep1ChoixCourrierLabel' })}
          />
        </View>
        {isDesktop &&
          <View style={styles.desktopFooterContainer}>
            {renderHelpLink()}
          </View>
        }
      </EERContentWrapper>
    </EERPageWrapperContainer>
  );
};
