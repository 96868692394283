import { Notification } from '@ere-uilib/types/notificationSystemTypes';
import * as actions from './notificationSystemActionsTypes';
import { NotificationSystemActionsType } from './notificationSystemActionsTypes';

export function addNotification(notification: Notification): actions.AddNotification {
  return {
    type: NotificationSystemActionsType.ADD_NOTIFICATION,
    notification,
  };
}

export function removeNotification(uid: string): actions.RemoveNotification {
  return {
    type: NotificationSystemActionsType.REMOVE_NOTIFICATION,
    uid,
  };
}
