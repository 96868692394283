import { StylesContext, SavingsEvolutionCardStyles } from './interface';

export const getStyles = (
  context?: StylesContext,
  style?: SavingsEvolutionCardStyles,
): SavingsEvolutionCardStyles => ({
  containerStyle: [
    {
      backgroundColor: context?.theme.colors.basics.white,
      flex: 1,
    },
    context?.isMobile && {
      paddingTop: context?.theme.metrics.spacing.s,
      paddingHorizontal: context?.theme.metrics.spacing.s,
      paddingBottom: context?.theme.metrics.spacing.huge,
    },
    context?.isTablet && {
      paddingTop: context?.theme.metrics.spacing.s,
      paddingHorizontal: context?.theme.metrics.spacing.xm,
      paddingBottom: context?.theme.metrics.spacing.huge,
    },
    context?.isDesktop && {
      paddingTop: context?.theme.metrics.spacing.xm,
      paddingHorizontal: context?.theme.metrics.spacing.xm,
      width: '100%',
      maxWidth: 700,
      alignSelf: 'center',
    },
    style?.containerStyle,
  ],
  labelsViewStyle: [{
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: context?.theme.metrics.spacing.xm,
  }, style?.labelsViewStyle],
  dotStyle: [
    {
      backgroundColor: context?.theme.colors.basics.grey.c500,
      width: 9,
      height: 9,
      borderRadius: 18,
      marginRight: context?.theme.metrics.spacing.s,
    },
    style?.dotStyle,
  ],
  headerStyle: [{
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  style?.headerStyle],
});
