import React, { useMemo, useState } from 'react';
import { BulletList } from 'react-content-loader/native';
import { useWindowDimensions, View } from 'react-native';

import {
  HelpPoint,
  IconButton,
  InnerPageContainer,
  Link,
  OperationsHistoryStatusEnum,
  SavingsOperationsHistoryCard,
  SavingsPlaceholder,
  Title
} from '@components/index';
import { useScreenSizes, useTheme } from '@ere-uilib/styles';
import { theme } from '@ere-uilib/theme';
import { useTranslation } from '@ere-uilib/translations';
import { FilterRoulesState, OperationsHistoryState } from '@ere-uilib/types';

import { FilterModal } from './FilterModal';
import { useStyles } from './useStyles';

interface Props {
  amount?: number
  selectedPlan: number
  operationsHistory: [string, OperationsHistoryState[]][]
  appliedOperationsFilterRoules: FilterRoulesState
  isApiLoading: boolean
  onPress: (filterRoules: FilterRoulesState) => void
  planName: string
  hasOnGoingVVP?: boolean
  isFetchingOnGoingVVP?: boolean
  onPressVVPManagement: () => void
  onOperationCardPress: (operationId: string) => void
}

export const SavingsOperationsHistory = ({
  operationsHistory,
  isApiLoading,
  onPress,
  appliedOperationsFilterRoules,
  planName,
  hasOnGoingVVP,
  isFetchingOnGoingVVP,
  onPressVVPManagement,
  onOperationCardPress
}: Props) => {
  const newTheme = useTheme();
  const [modalVisible, setModalVisible] = useState(false);
  const { isMobile, isTablet } = useScreenSizes();
  const MOBILE = isMobile || isTablet;
  const { formatDate, formatMessage, getMessageRaw } = useTranslation();
  const styles = useStyles(MOBILE);
  const { height } = useWindowDimensions()
  const bulletListHeight = useMemo(() => {
    return height / 4;
  }, [])
  const renderList = () => {
    return (
      <>
        {operationsHistory?.length > 0 ? (
          operationsHistory?.map(
            (
              itemsGroupedByDate: [string, OperationsHistoryState[]],
              index: number
            ) => {
              return (
                <View
                  accessibilityLabel={`history_card_${index}`}
                  key={`${index} ${itemsGroupedByDate[0]}`}
                  style={styles.containerStyle}
                  testID={`history_bloc_${index}`}
                >
                  <Title
                    style={{
                      color: theme.colors.disabledGrey,
                      marginBottom: theme.marginsPaddings.xm,
                      marginTop: theme.marginsPaddings.s,
                    }}
                    testId={`history_date_${index}`}
                    variant="t8"
                  >
                    {formatDate({ value: itemsGroupedByDate[0] })}
                  </Title>
                  {itemsGroupedByDate[1].map(
                    (operation: OperationsHistoryState, oIndex: number) => {
                      const isAmountHidden = operation.amount === 0

                      return (
                        <SavingsOperationsHistoryCard
                          isAmountPlaceholder={isAmountHidden}
                          isPressable={operation.statusCode !== OperationsHistoryStatusEnum.ANNULE}
                          key={`${oIndex}${operation.dateTime}`}
                          onCardPress={() => { operation.statusCode !== OperationsHistoryStatusEnum.ANNULE && onOperationCardPress(operation.id) }}
                          operation={operation}
                          testId={`history_${index}_${oIndex}`}
                        />
                      );
                    }
                  )}
                </View>
              );
            }
          )
        ) : (
          <SavingsPlaceholder
            titleLabelId="Saving_Tabs_noDataFiltered_label"
          />
        )}
      </>
    );
  };

  const VVPManagementButtonTitle = formatMessage({
    id: 'VV_VVP_GererRemboursements_bouton',
  });
  const showVVPManagementButton = !isFetchingOnGoingVVP && hasOnGoingVVP;

  return (
    <InnerPageContainer>
      <View style={styles.buttonContainerStyle}>
        {showVVPManagementButton && (
          <Link
            onPress={onPressVVPManagement}
            testID='manage_my_scheduled_payments_link'
            textStyle={styles.linkStyle}>{VVPManagementButtonTitle}</Link>
        )}
        <View style={styles.rightButtons}>
          <IconButton
            buttonInformations={{
              icon: 'filtres',
              title: `${formatMessage({ id: 'Saving_Tab4_Filter_button' })}`,
            }}
            iconButtonStyle={styles.iconButton}
            iconButtonTitleStyle={styles.iconButtonText}
            iconColor={theme.colors.white}
            index={1}
            onIconButtonPressed={() => setModalVisible(true)}
          />
          <HelpPoint
            contentHtml={getMessageRaw({
              id: `saving_Tab4_info_content_${planName}`,
              defaultMessage: ' ',
            })}
            iconSize={newTheme.metrics.iconSizes.l}
            modalTitle={formatMessage({ id: 'fund-card_help-info_menu' })}
            style={styles.helpPointStyle}
          />
        </View>
      </View>
      <View style={styles.contentContainer}>
        {isApiLoading ?
          <View style={[styles.containerStyle]}>
            <BulletList
              height={bulletListHeight}
              style={{ flex: 1 }}
              width={'100%'}
            />
          </View> :
          renderList()
        }
      </View>
      <FilterModal
        appliedOperationsFilterRoules={appliedOperationsFilterRoules}
        modalVisible={modalVisible}
        onApplyFilter={(filterRoules: FilterRoulesState) =>
          onPress(filterRoules)
        }
        onClose={() => setModalVisible(false)}
      />
    </InnerPageContainer>
  );
};
