import React from 'react';
import { View, TouchableOpacity } from 'react-native';

import { Title, FontIcon } from '@ere-uilib/atoms';
import { StatusTag } from '@ere-uilib/molecules/tags';
import { useTheme, createUseStyles } from '@ere-uilib/styles';
import { useTranslation } from '@ere-uilib/translations';

import { SavingsOperationsHistoryCardProps } from './interface';
import { getStyles } from './styles';

export const SavingsOperationsHistoryCard: React.FC<React.PropsWithChildren<SavingsOperationsHistoryCardProps>> = ({
  operation,
  containerStyle,
  isAmountPlaceholder = false,
  onCardPress,
  testId,
  isPressable = true,
}) => {
  const {
    label,
    type,
    amount,
    statusCode,
    statusLabel,
    id,
  } = operation;
  const { formatCurrencyNumber } = useTranslation();
  const theme = useTheme();
  const styles = useStyles(
    { theme },
    { containerStyle }
  );

  return (
    <TouchableOpacity
      disabled={!isPressable}
      onPress={onCardPress}
      style={styles.containerStyle}>
      <View style={styles.leftContainerStyle}>
        <Title variant="t7">{label}</Title>
        {!isPressable &&
          <Title
            variant="t7"
            weight='light'>{id}</Title>}
        <StatusTag
          containerStyle={styles.statusStyle}
          label={statusLabel}
          type={statusCode} />
      </View>
      <View style={styles.rightContainerStyle}>
        <View style={styles.amountContainerStyle}>
          <Title
            testId={`${testId}_amount`}
            variant="t6"
            weight="bold">
            {!isAmountPlaceholder && (amount ? formatCurrencyNumber({ value: amount }) : "")}
          </Title>
        </View>
        <FontIcon
          accessibilityLabel={`${testId}_link`}
          color={theme.colors.text.primary.c500}
          name="chevron-droite"
          size={theme.metrics.iconSizes.s}
          testID={`${testId}_link`} />
      </View>
    </TouchableOpacity>
  );
};

const useStyles = createUseStyles(getStyles);
