import { AuthenticateAction } from '@modules/auth/actions/authActionsTypes';

import { LabelAction, LabelActionType } from './actions/labelActionsTypes';
import { getDefaultLanguage } from './utils';

export interface LabelState {
  language: string;
  labels: any;
}

export const initialState: LabelState = {
  language: getDefaultLanguage(),
  labels: {
    ERR_AUT_01: 'Libellé non trouvé',
    ERR_BAS_01: 'Erreur inattendue',
    ERR_BAS_02: "Erreur d'argument null",
    ERR_BAS_03: "Erreur d'argument invalide",
    ERR_BAS_04: "Erreur d'argument en dehors de la plage autorisée",
    ERR_BAS_05: 'Erreur en cas d’indexation incorrecte du tableau',
    ERR_BAS_06: 'Erreur un objet Null est référencé.',
    ERR_BAS_07: 'Erreur levée par les méthodes en cas d’état non valide.',
    ERR_BAS_08: "Erreur lors d'un accès à une mémoire non valid",
    ERR_CAS_01: 'Conversion impossible',
    ERR_CAS_02: 'Conversion Enum',
    ERR_FOR_01: 'Erreur de mapping',
    ERR_FOR_02: 'Erreur de format',
    ERR_MAI_01: "Erreur lors de l'envoi de mail",
    ERR_PAS_01:
      'Le nouveau mot de passe est identique à un ancien mot de passe',
    ERR_REG_02:
      "Impossible de mettre à jour l'entrée en relation correspondante",
    ERR_SMS_01: "Erreur lors de l'envoi de sms",
    INF_EML_01: 'Email non spécifié',
    INF_EML_02: "Format de l'email est invalide",
    INF_EML_03: "le domaine de l'email n'est pas autorisé",
    INF_ENT_01: 'Entité non trouvé',
    INF_PAS_01: 'Le mot de passe ne contient pas de minuscule',
    INF_PAS_02: 'Le mot de passe ne contient pas de majuscule',
    INF_PAS_03: 'Le mot de passe ne contient pas de chiffre',
    INF_PAS_04: 'Le mot de passe ne contient pas de caractère spécial',
    INF_PAS_05: "La longueur du mot de passe n'est pas suffisante",
    INF_PAS_06: "Le format du mot de passe n'est pas valide",
    INF_PAS_07: "La requête de demande de réinitialisation n'est pas trouvée",
    INF_REG_01: "Impossible de trouver l'entrée en relation correspondante",
    INF_REG_03: "Cet email est déjà utilisé en tant qu'identifiant",
    INF_REG_04: 'Le jeton est invalide',
    INF_TOK_01: 'Impossible de trouver le jeton',
    INF_TOK_02: 'Impossible de générer le jeton',
    INF_TOK_03: 'Code de confirmation incorrect',
    INF_TOK_04: 'Nombre de générations maximum atteint',
    INF_TOK_05: 'Nombre de tentatives maximum de validation atteint',
    INF_TOK_06:
      'Suite à un nombre trop élevé de saisies erronées, la saisie est bloquée durant LockDuration minutes',
    SmsTemplate_MobileConfirmRequest: 'Votre code est le code​',
    'aboundement_content1_entreprise-bonus':
      'Complément versé par votre entreprise',
    aboundement_content1_label:
      'Cette année, vos versements volontaires vous ont permis de bénéficier de ',
    aboundement_content2_button: 'Faire un versement',
    aboundement_content2_complement: 'Complément encore disponible',
    aboundement_content2_label:
      'Le plafond fixé par votre entreprise est de 10 000 € par an. Il vous reste à percevoir potentiellement ',
    aboundement_title: 'Mon abondement',
    'aboundment-infos_content':
      'L’abondement est une contribution financière que l’employeur peut verser dans les dispositifs d’épargne salariale tels qu’un PEE et/ou PERCO, en complément de l’effort d’épargne de ses collaborateurs.\n\nL’abondement peut alors porter sur tout ou partie des sommes issues des versements volontaires, de l’intéressement et de la participation..',
    'aboundment-infos_title': 'L’abondement c’est quoi ?',
    'articles-consultation_button': "C'est parti !",
    'benefit-time_content1_label': 'Dès maintenant',
    'benefit-time_content2_label': 'Dans 5 ans maximum',
    'benefit-time_content3_label': 'A la retraite',
    'benefit-time_content3_sub-label': 'Estimer votre retraite',
    'benefit-time_content4_label': 'Transférer / Arbitrer',
    'benefit-time_content4_sub-label': "Ca m'intéresse",
    'benefit-time_content4_up-to': "Jusqu'à variable €",
    'benefit-time_title': 'Quand puis-je bénéficier de mon épargne ?',
    benefit_carousel1_content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed fringilla lorem id neque mattis, at suscipit sapien dapibus.\n Phasellus luctus mollis ligula dignissim auctor. Cras sit amet lacus viverra, gravida leo eget, blandit nisl viverra nec elit at vehicula ac pulvinar lacus.\n Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed fringilla lorem id neque mattis, at suscipit sapien dapibus.',
    benefit_carousel1_title: 'Plan Épargne Retraite',
    benefit_carousel2_content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed fringilla lorem id neque mattis, at suscipit sapien dapibus.\n Phasellus luctus mollis ligula dignissim auctor.Cras sit amet lacus viverra, gravida leo eget, blandit nisl viverra nec elit at vehicula ac pulvinar lacus.\n Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed fringilla lorem id neque mattis, at suscipit sapien dapibus.',
    benefit_carousel2_title: 'Plan Épargne Retraite',
    benefit_carousel3_content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed fringilla lorem id neque mattis, at suscipit sapien dapibus.\n Phasellus luctus mollis ligula dignissim auctor.Cras sit amet lacus viverra, gravida leo eget, blandit nisl viverra nec elit at vehicula ac pulvinar lacus.\n Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed fringilla lorem id neque mattis, at suscipit sapien dapibus.',
    benefit_carousel3_title: 'Plan Épargne Retraite',
    benefit_title:
      'Air France vous permet de bénéficier des avantages suivants ',
    bonus_content: 'Choisissez votre affectaction avant le variable',
    bonus_title:
      'Votre prime d’intéressement d’un montant de variable € est disponible !',
    cgu_button: 'Accepter et continuer',
    cgu_menu: 'Conditions Générales d’Utilisation',
    cgu_paragraph1_content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla in urna dui. Quisque sit amet consectetur justo, sit amet rhoncus magna. Maecenas pellentesque viverra maximus.\n Nullam quis mi risus. Aliquam vehicula lorem nec lobortis rutrum. Nunc nisl nulla, varius id bibendum eu, suscipit at justo. Vestibulum eleifend mollis erat vel iaculis.',
    cgu_paragraph1_title: 'Lorem ipsum dolor sit amet',
    cgu_paragraph2_content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla in urna dui. Quisque sit amet consectetur justo, sit amet rhoncus magna. Maecenas pellentesque viverra maximus.\n Nullam quis mi risus. Aliquam vehicula lorem nec lobortis rutrum. Nunc nisl nulla, varius id bibendum eu, suscipit at justo. Vestibulum eleifend mollis erat vel iaculis.',
    cgu_paragraph2_title: 'Lorem ipsum dolor sit amet',
    cgu_paragraph3_content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla in urna dui. Quisque sit amet consectetur justo, sit amet rhoncus magna. Maecenas pellentesque viverra maximus.\n Nullam quis mi risus. Aliquam vehicula lorem nec lobortis rutrum. Nunc nisl nulla, varius id bibendum eu, suscipit at justo. Vestibulum eleifend mollis erat vel iaculis.',
    cgu_paragraph3_title: 'Lorem ipsum dolor sit amet',
    cgu_paragraph4_content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla in urna dui. Quisque sit amet consectetur justo, sit amet rhoncus magna. Maecenas pellentesque viverra maximus.\n Nullam quis mi risus. Aliquam vehicula lorem nec lobortis rutrum. Nunc nisl nulla, varius id bibendum eu, suscipit at justo. Vestibulum eleifend mollis erat vel iaculis.',
    cgu_paragraph4_title: 'Lorem ipsum dolor sit amet',
    cgu_paragraph5_content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla in urna dui. Quisque sit amet consectetur justo, sit amet rhoncus magna. Maecenas pellentesque viverra maximus.\n Nullam quis mi risus. Aliquam vehicula lorem nec lobortis rutrum. Nunc nisl nulla, varius id bibendum eu, suscipit at justo. Vestibulum eleifend mollis erat vel iaculis.',
    cgu_paragraph5_title: 'Lorem ipsum dolor sit amet',
    'choose-login_button': 'Valider mon identifiant',
    'choose-login_info-buble':
      'Cet e-mail sera également utilisé pour vous transmettre des informations liées à la gestion de votre épargne.',
    'choose-login_input1_label': 'E-mail',
    'choose-login_input1_type': 'text',
    'choose-login_menu': 'Votre identifiant  e-mail',
    'choose-login_title': 'Choisissez votre identifiant',
    'choose-pwd_button': 'Envoyer l’ e-mail de réinitialisation',
    'choose-pwd_button-reverse': 'Un problème avec votre e-mail ?',
    'choose-pwd_help': 'Mot de passe oublié',
    'choose-pwd_input1_label': 'Mot de passe',
    'choose-pwd_input1_type': 'text',
    'choose-pwd_input2_label': 'Confirmer votre nouveau mot de passe',
    'choose-pwd_input2_type': 'password',
    'choose-pwd_menu': 'Changement de mot de passe',
    'choose-pwd_title': 'Choisissez votre nouveau mot de passe',
    'confim-changement-pwd_title':
      'Votre mot de passe a bien été modifié variable !',
    'confirm-phone-number_button': 'Confirmer mon numéro',
    'confirm-phone-number_description':
      'Il est indispensable pour sécuriser vos transactions financières.',
    'confirm-phone-number_input1_label': 'Numéro de téléphone',
    'confirm-phone-number_input1_type': 'tel',
    'confirm-phone-number_menu': 'Votre numéro de téléphone',
    'confirm-phone-number_title': 'Confirmez votre numéro de mobile',
    congrats_button: 'Passer à la dernière étape',
    congrats_description: 'Votre compte a été crée avec succès.',
    congrats_title: 'Félicitation variable !',
    'define-password_button': 'Valider mon mot de passe',
    'define-password_input1_label': 'Mot de passe',
    'define-password_input1_type': 'text',
    'define-password_input2_label': 'Confirmer votre mot de passe',
    'define-password_input2_type': 'password',
    'define-password_menu': 'Votre mot  de passe',
    'define-password_title': 'Définissez votre mot de passe',
    'discover-saving_button-reverse': 'Je suis un débutant',
    'discover-saving_button-reverse-bis': 'Je suis expert',
    'discover-saving_content':
      'Tout savoir sur vos dispositifs, quelles que soient vos connaissances.',
    'discover-saving_title': "Découvrez l'épargne salariale !",
    'disposition-tabs1_label': 'Répartition',
    'disposition-tabs2_label': 'Disponibilités',
    'disposition-tabs3_label': 'Versements',
    'disposition-tabs4_label': 'Historique des opérations',
    'disposition-tabs5_label': 'Evolution de mon épargne',
    'disposition_filter-tab1_label': 'Versements de l’entreprise',
    'disposition_filter-tab2_label': 'Participation et intéressement',
    'disposition_filter-tab3_label': 'Abondement',
    'disposition_filter-tab4_label': 'Versements personnel',
    'disposition_filter-tab5_label': 'Versements volontaires déductibles',
    'disposition_filter-tab6_label': 'Versements volontaires non déductibles',
    disposition_performance: 'Performance sur 1 an variables',
    'disposition_risk-level': 'Niveau de risque variables',
    'division-infos_content':
      'Votre épargne est alimentée par des versements de l’entreprise et vos versements volontaires. Elle est répartie sur différents dispositifs (PEE, PERCO...).\n\nChaque dispositif à des caracteristiques particulières comme par exemple  la disponibilité de l’épargne, le type de sortie en rente ou capital ou encore les cas de déblocage.',
    'division-infos_title': 'Répartition de mon épargne',
    error_button: 'Recevoir un nouveau mail d’invitation',
    error_content:
      'Une erreur est survenue, il semblerait que votre accès soit expiré.',
    error_title: 'Oups !',
    filters_content1_content1_content:
      'Ces sommes sont exonérées d’impôt sur le revenu si vous les investissez dans un dispositif d’épargne salariale PEE ou PERCO* (ou dans un CCB pour la participation).',
    filters_content1_content1_label: 'Régime fiscal',
    saving_title: "Découvrez l'épargne salariale !",
    'saving_sub-title': 'Total brut au ',
  },
};

export default function labelReducer(
  state: any = initialState,
  action: LabelAction | AuthenticateAction,
): any {
  switch (action?.type) {
    case LabelActionType.GET_LABEL_SUCCESS:
      return {
        ...state,
        labels: action.labels,
      };
    case LabelActionType.PUT_LANGUAGE_SUCCESS:
      return {
        ...state,
        language: action.language,
      };
    default:
      return state;
  }
}
