import React, { useCallback, useMemo } from 'react';
import { TouchableOpacity, View } from 'react-native';

import {
  FontIcon, LoaderPlaceholderView, Text, Title, useScreenSizes, useTheme, useTranslation
} from '@components/index';
import { CircleLoader } from '@ere-uilib/atoms/loaders/Circle/Circle';
import { createUseStyles } from '@ere-uilib/styles';
import { ProfileMenuItemsState } from '@modules/profile/types';
import { useUrlLink } from '@utils/index';

import { Props } from './interfaces';
import { getStyles } from './styles';

const placeholderItemNumber = [2, 3, 2, 5];

export const ProfileMenu: React.FC<React.PropsWithChildren<Props>> = ({
  menus,
  profileMenuGlobalContainerStyle,
  profileMenuItemContainerStyle,
  profileMenuItemIconViewStyle,
  profileMenuItemIconWrapperStyle,
  profileMenuContainerStyle,
  profileMenuTitleStyle,
  isLoading,
}) => {
  const theme = useTheme();
  const { isMobile, isTablet } = useScreenSizes();
  const { linkToUrl } = useUrlLink();
  const { formatMessage } = useTranslation()
  const styles = useStyles({ theme, isMobile }, {
    profileMenuGlobalContainerStyle,
    profileMenuItemContainerStyle,
    profileMenuItemIconViewStyle,
    profileMenuItemIconWrapperStyle,
    profileMenuContainerStyle,
    profileMenuTitleStyle,
  });
  const redirectionFunction = (link: string | undefined) => {
    typeof link === 'string' && linkToUrl(link);
  };

  const menuExists = menus && menus.length > 0;
  const usedProfileMenuContainerStyle = useMemo(() => [styles.profileMenuContainerStyle, {
    marginHorizontal: !(isMobile || isTablet) ? theme.metrics.spacing.xhuge : theme.metrics.spacing.s,
  }], [
    isMobile,
    isTablet,
    styles.profileMenuContainerStyle,
    theme.metrics.spacing.s,
    theme.metrics.spacing.xhuge,
  ]);

  const PlaceholderContent = useCallback(({ num }: { num: number }) => {
    return (
      <>
        {
          Array.from({ length: num }, (_, i) => {
            return (
              <View
                key={i}
                style={{ flexDirection: 'row', alignItems: 'center', paddingVertical: theme.metrics.spacing.xs }}>
                <CircleLoader diameter={36} />
                <LoaderPlaceholderView style={{ flex: 1, marginLeft: theme.metrics.spacing.xm }} />
              </View>
            );
          })
        }
      </>
    );
  }, [theme.metrics.spacing.xm, theme.metrics.spacing.xs]);

  return (
    <View style={styles.profileMenuGlobalContainerStyle} >
      {isLoading && !menuExists && placeholderItemNumber.map((num, i) => {
        return (
          <View
            key={i}
            style={usedProfileMenuContainerStyle}>
            <LoaderPlaceholderView style={{ width: 262, marginVertical: theme.metrics.spacing.xm }} />
            <PlaceholderContent num={num} />
          </View>
        );
      })}
      {menuExists && menus?.map(
        (item: ProfileMenuItemsState, idx: number) => {
          return (
            <TouchableOpacity
              key={idx}
              disabled={typeof item.url !== 'string'}
              onPress={() => redirectionFunction(item.url)}
              style={usedProfileMenuContainerStyle}>
              <Title
                style={styles.profileMenuTitleStyle}
                variant="t6">{item.label}</Title>
              {item.subMenus?.length > 0 && item.subMenus?.map((subMenuItem: ProfileMenuItemsState, index: number) => {
                return (
                  <TouchableOpacity
                    disabled={subMenuItem.type === 'TOGGLE'}
                    key={index}
                    onPress={() => redirectionFunction(subMenuItem.url)}
                    style={styles.profileMenuItemContainerStyle}>
                    <View style={styles.profileMenuItemIconViewStyle}>
                      {subMenuItem.icon &&
                        <View style={styles.profileMenuItemIconWrapperStyle}>
                          <FontIcon
                            name={subMenuItem.icon}
                            size={20} />
                        </View>
                      }
                      <View style={styles.subMenuItemTitleAndSubLabelContiner}>
                        <Title
                          testId={subMenuItem.url}
                          variant="t6">
                          {subMenuItem.label}
                        </Title>

                        {
                          !!subMenuItem.subLabel && (
                            <Text
                              style={styles.subLabel}
                              variant='t3'
                            >
                              {formatMessage({ id: subMenuItem.subLabel })}
                            </Text>
                          )
                        }
                      </View>
                    </View>
                    {subMenuItem.type === 'LINK' && <FontIcon
                      name="chevron-droite"
                      size={12} />}
                  </TouchableOpacity>
                );
              })
              }
            </TouchableOpacity>
          );
        },
      )}
    </View>
  );
};

const useStyles = createUseStyles(getStyles);

export default ProfileMenu;
