import {StyleProp, TextStyle, ViewStyle} from "react-native";
import {ThemeType} from "@ere-uilib/styles";
import { wrap } from "lodash";

type Styles = {
    containerStyle?: StyleProp<ViewStyle>;
    columnStyle?: StyleProp<ViewStyle>;
    startColumnStyle?: StyleProp<ViewStyle>;
    middleColumnStyle?: StyleProp<ViewStyle>;
    endColumnStyle?: StyleProp<ViewStyle>;
    statusTag?: StyleProp<ViewStyle>;
    columnTitleStyle?: StyleProp<TextStyle>;
    columnValueStyle?: StyleProp<TextStyle>;
    titleContainerStyle?: StyleProp<ViewStyle>;
    titleTextStyle?: StyleProp<TextStyle>;
    rowLabelsContainerStyle?: StyleProp<ViewStyle>;
    checkBoxContainerStyle?: StyleProp<ViewStyle>;
    subTitleStyle?: StyleProp<TextStyle>;
    chevronStyle?: StyleProp<ViewStyle>;
    expandContainerStyle?: StyleProp<ViewStyle>;
    contributionLabelStyle?: StyleProp<TextStyle>;
    percentageText?: StyleProp<TextStyle>;
};

type StylesContext = {
    theme: ThemeType,
    isMobile: boolean,
    isTitlePressable: boolean
};


export const getStyles = (context?: StylesContext, style?: Styles): Styles => ({
    containerStyle: [
        {
            flexDirection: 'row',
            justifyContent: 'space-evenly',
        },
        style?.containerStyle,
    ],
    columnStyle: [
        {
            alignItems: "flex-start",
        },
        style?.columnStyle,
    ],
    startColumnStyle: [
        {
            alignItems: 'center',
            paddingHorizontal: context?.theme.metrics.spacing.xxs,
        },
        style?.startColumnStyle,
    ],
    middleColumnStyle: [
        {
            alignItems: 'center',
            borderLeftWidth: 1,
            borderColor: context?.theme.colors.basics.grey.c200,
            minWidth: 80,
            paddingHorizontal: context?.theme.metrics.spacing.xxs,
        },
        style?.middleColumnStyle,
    ],
    endColumnStyle: [
        {
            alignItems: 'center',
            borderLeftWidth: 1,
            borderColor: context?.theme.colors.basics.grey.c200,
            paddingHorizontal: context?.theme.metrics.spacing.xxs,
        },
        style?.endColumnStyle,
    ],
    statusTag: [
        {
            marginTop: context?.theme.metrics.spacing.xs,
            alignSelf: 'center',
        },
        style?.statusTag,
    ],
    columnTitleStyle: [
        {
            color: context?.theme.colors.basics.grey.c500,
            textAlign: 'center'
        },
        style?.columnTitleStyle,
    ],
    columnValueStyle: [
        {
            marginTop: context?.theme.metrics.spacing.xs
        },
        style?.columnValueStyle,
    ],
});
