import { connect } from 'react-redux';

import { getCibledContributionPageContentRequest, getContributionByOperationRequest, getContributionRequest } from '@modules/dashboard/actions/dashboardActions';
import { DashboardActionsType } from '@modules/dashboard/actions/dashboardActionsTypes';
import { getCibledContributionContent, getContributionByCompany, getContributionByOperation, getError } from '@modules/dashboard/selectors';
import { AppState } from '@modules/reducers';

import { ContributionDetailsTab as ContributionDetailsTabComponent } from './ContributionDetailsTab.component';

const mapStateToProps = (state: AppState) => ({
  isContributionByCompanyLoading: state.isLoading[DashboardActionsType.GET_CONTRIBUTION_REQUEST],
  isContributionByOperationsLoading: state.isLoading[DashboardActionsType.GET_CONTRIBUTION_BY_OPERATION_REQUEST],
  contributionByCompanyError: getError('contributionError', state),
  contributionByCompany: getContributionByCompany(state),
  contributionByOperations: getContributionByOperation(state),
  cibledContributionContent: getCibledContributionContent(state)
});

const mapDispatchToProps = {
  onGetContributionByCompany: getContributionRequest,
  onGetContributionByOperation: getContributionByOperationRequest,
  onGetCibledContribution: getCibledContributionPageContentRequest
};

export const ContributionDetailsTab = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContributionDetailsTabComponent);

