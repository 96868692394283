import React, { useCallback, useEffect, useRef } from 'react'
import { Platform, View } from 'react-native'

import { useStyles } from './styles'
import { useTheme } from '@ere-uilib/styles'
import { useDispatch, useSelector } from 'react-redux'
import { getSSORedirectionUrlRequest } from '@modules/dashboard/actions/dashboardActions'
import { getSsoUrlErrorSelector, getSsoUrlSelector } from '@modules/dashboard/selectors'
import { OfflineAnnounceLayout360 } from '@components/containers'
import { NotificationSoft, SimpleButton } from '@ere-uilib/molecules'
import { useNavigation } from '@react-navigation/native'
import { useTranslation } from '@ere-uilib/translations'
import { useLogoNavigation } from '@utils/useLogoNavigation'
import { Spinner } from '@ere-uilib/atoms'
import { AppState } from '@modules/index'
import { DashboardActionsType } from '@modules/dashboard/actions/dashboardActionsTypes'

export const VotAccess: React.FC = ({}) => {
  const theme = useTheme()
  const styles = useStyles()
  const dispatch = useDispatch()
  const navigation = useNavigation()
  const { formatMessage } = useTranslation()
  const { logoNavigateTo } = useLogoNavigation()

  const isLoading = useSelector(
    (state: AppState) => state?.isLoading[DashboardActionsType.GET_SSO_REDIRECT_URL_REQUEST]
  )
  const ssoUrl = useSelector(getSsoUrlSelector)
  const ssoUrlError = useSelector(getSsoUrlErrorSelector)

  const refDashboardContainer = useRef() as React.MutableRefObject<{ scrollToTop: () => void }>

  useEffect(() => {
    dispatch(getSSORedirectionUrlRequest())
  }, [dispatch, getSSORedirectionUrlRequest])

  const renderActions = () => (
    <SimpleButton
      containerStyle={{ margin: theme?.metrics.spacing.xm }}
      onPress={() => {
        if (Platform.OS === 'web') {
          window.history.back()
        } else {
          navigation.goBack()
        }
      }}
      title={formatMessage({ id: 'Maretraite_redirect_back_button' })}
    />
  )

  const renderSpinnerAndNotificationError = useCallback(() => {
    if (!isLoading) {
      return (
        ssoUrlError?.message && (
          <NotificationSoft
            text={ssoUrlError?.message}
            type="warning"
          />
        )
      )
    }

    return <Spinner color={theme.colors.basics.primary.c500} />
  }, [isLoading, ssoUrlError])

  return (
    <View style={styles.container}>
      <OfflineAnnounceLayout360
        actions={renderActions}
        description={formatMessage({
          id: 'SSORedirectPage'
        })}
        onLogoPress={logoNavigateTo}
        sourceName="waitingProcess"
        title={formatMessage({ id: 'Dashboard_Personeo_RedirectPage_title' })}>
        {renderSpinnerAndNotificationError()}
      </OfflineAnnounceLayout360>
    </View>
  )
}
