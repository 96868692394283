import { DocumentApiFile, DocumentCategory } from "@modules/document-space/types";

export const getSelectedFilesByCategories = (
  categories: DocumentCategory[],
  files: DocumentApiFile[]
) => {
  const searchCategories = categories.filter(category => !!category.isSelected);

  if (!searchCategories.length) return files;

  const matchingSelectedDocuments = files.filter(file =>
    file.searchTags?.some(category => searchCategories.some(search => search.name === category))
  );

  return matchingSelectedDocuments;
};
