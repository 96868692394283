import { AppState } from '@modules/reducers'

export const getRefundsError = (state: AppState) => state.refunds.error

export const getRefundTriggerThresholdModalVisible = (state: AppState) =>
  state.availableRefunds.repartitionTriggerThresholdModalVisibleState

export const getRefundRepartitionAmountModalVisibleState = (state: AppState) =>
  state.availableRefunds.repartitionAmountModalVisibleState

export const getRefundRepartitionData = (state: AppState) => state.availableRefunds.repartition

export const getRefundDemandStatus = (state: AppState) =>
  state.availableRefunds.repaymentDemandStatus
