import React, { useEffect } from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import { LogBox, Platform } from 'react-native';
import Config from "react-native-config"
import SplashScreen from 'react-native-splash-screen'
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import {
  ThemeContext,
  ThemeList,
  ThemeType,
  AppHeadContainer,
  AppLanguageHead,
  EndOidcSessionWebView,
} from '@components/index';
import { NotificationSystemContainer } from '@components/index';
import { store, pstore } from '@modules/store';
import { AppNavigator } from '@navigation/index';

if (Config.IGNORE_ALL_LOGS) {
  LogBox.ignoreAllLogs();
}

const App = () => {
  const [theme, setTheme] = React.useState<ThemeType>(ThemeList.light);

  useEffect(() => {
    Platform.OS !== 'web' && SplashScreen.hide()
  }, [])
  return (
    <Provider store={store}>
      <PersistGate
        loading={null}
        persistor={pstore}>
        <HelmetProvider>
          {/*<Helmet>*/}
          {/*  <meta*/}
          {/*    content={`*/}
          {/*            default-src 'self';*/}
          {/*            script-src 'self';*/}
          {/*            img-src 'self';*/}
          {/*            media-src 'self' https://*.bnpparibas.com;*/}
          {/*            connect-src 'self' https://*.bnpparibas.com */}
          {/*            https://*.bnpparibas*/}
          {/*            https://*.xiti.com/;*/}
          {/*            frame-src 'self' https://*.quicksign.fr*/}
          {/*      `}*/}
          {/*    httpEquiv="Content-Security-Policy"*/}
          {/*  ></meta>*/}
          {/*</Helmet>*/}
          <AppLanguageHead>
         <ThemeContext.Provider value={{ theme, setTheme }}>
                <AppHeadContainer>
                    <EndOidcSessionWebView />
                      <NotificationSystemContainer />
                      <AppNavigator />
                </AppHeadContainer>
          </ThemeContext.Provider>
       </AppLanguageHead></HelmetProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
