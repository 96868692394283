import { useMemo } from "react";
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';

export const useStyles = ({
  style,
  stepSpacing
}: {
    style?: StyleProp<ViewStyle>,
    stepSpacing: number
  }
) => {
  const styles = useMemo(() => StyleSheet.create({
    container: {
      ...StyleSheet.flatten(style)
    },
    stepNotLast: {
      marginBottom: stepSpacing,
    },
  }), [
    stepSpacing,
    style
  ])

  return styles;
}

