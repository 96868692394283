import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useScreenSizes, useTheme } from '@ere-uilib/styles';

export function useStyles({
  shouldDisplayBackButton,
}:{
  shouldDisplayBackButton:boolean
}) {
  const theme = useTheme();
  const { isDesktop } = useScreenSizes();

  const styles = useMemo(() => StyleSheet.create({
    container: {
      width: '100%',
      marginTop: isDesktop ? theme.metrics.spacing.xl : undefined,
      flexDirection: 'row',
    },
    confirmButton: {
      marginLeft: shouldDisplayBackButton ? theme.metrics.spacing.m : undefined,
      flex: 1,
    },
  }), [isDesktop, theme]);

  return styles;
}
