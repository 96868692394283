import axios from 'axios';

import { API_URL } from '@config/index';
import { FetchControlDataState, FetchControlDataEditingOptionState, EditedControlDataType } from '@modules/control-data/types';

export async function fetchControlData(
  companyId: string
): Promise<{ data: FetchControlDataState[] }> {
  return axios.get(`${API_URL}/api/v1/companies/${companyId}/drivingdata`);

}

export async function fetchControlDataEditingOptions(
  companyId: string,
  planId: number
): Promise<{ data: FetchControlDataEditingOptionState }> {
  return axios.get(`${API_URL}/api/v1/companies/${companyId}/plan/${planId}/drivingdata/initdrivingdata`);
}

export async function postControlDataEditedOptions(
  companyId: string,
  planId: number,
  editedDrivingData: EditedControlDataType
): Promise<{ data: EditedControlDataType }> {
  return axios.post(`${API_URL}/api/v1/companies/${companyId}/plan/${planId}/drivingdata/savedrivingdata`, editedDrivingData);
} 
