import { PdfViewer } from '@ere-uilib/atoms/PdfViewer';
import { RouteProp, useRoute } from '@react-navigation/native';
import React from 'react';



type DocumentSpaceViewerParam = {
  Document: {
    base64PDF: string,
    documentTitle: string
  }
}
export const DocumentSpaceViewer: React.FC<React.PropsWithChildren<unknown>> = ({
}) => {
  const route = useRoute<RouteProp<DocumentSpaceViewerParam, 'Document'>>();
  const { base64PDF, documentTitle } = route.params
  return (<PdfViewer base64PDF={base64PDF} title={documentTitle} />
  );
};
