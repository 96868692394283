import { useNavigation } from '@react-navigation/native'
import React, { useCallback, useMemo, useState } from 'react'
import { View } from 'react-native'

import { RouteNames } from '@constants/navigation'
import { OperationHistoryTypesEnum } from '@constants/savings'
import { Title } from '@ere-uilib/atoms'
import { RadiosButtonList } from '@ere-uilib/organisms'
import { useTheme } from '@ere-uilib/styles'
import { useTranslation } from '@ere-uilib/translations'
import { DispositifState } from '@modules/common/types'
import { getEligibleDispositifs } from '@utils/eligiblePlansCompute'

import { UseController } from './interfaces'
import { IDispositifOption } from './RefundsRequirementDefinitonPage.component'
import { getStyles } from './styles'
import { REACT_APP_ENV } from '@config/index'
import { EnvironnementNameEnum } from '@constants/env'

export const useController = ({
  requirementDecisionData,
  alreadyResponded
}: UseController) => {

  const { formatMessage } = useTranslation();
  const navigation = useNavigation();
  const theme = useTheme();
  const styles = getStyles(theme)

  const dispositifsEligibleToRepaymentUnavailable = requirementDecisionData.dispositifs.filter((dispositif: DispositifState) => {
    return dispositif.filterProperties?.RepaymentUnavailable === true
  }).map(item => ({
    name: item.name,
    planFamily: item.planFamily,
    isEligible: true,
  }))

  const elligibleDispositifs = getEligibleDispositifs(
    requirementDecisionData.dispositifs,
    alreadyResponded
  ).eligibleDispositifs

  const groupedData: { [key: string]: string[] } = {};
  dispositifsEligibleToRepaymentUnavailable.forEach(item => {
    if (!item.isEligible) return undefined
    if (!groupedData[item.planFamily]) {
      groupedData[item.planFamily] = [];
    }

    groupedData[item.planFamily].push(item.name);
  });

  const unavailableDispositifsByKeys = Object.keys(groupedData).map(key => ({
    value: key,
    labelCode: groupedData[key].join(', '),
  }));

  const optionsForRadiosButtonList = useMemo(() =>
    unavailableDispositifsByKeys?.map(option => {
      return {
        value: option.value,
        label: option.labelCode,
        disabled: false,
      };
    }) || [], [unavailableDispositifsByKeys]);

  const defaultSelectedValue = unavailableDispositifsByKeys.length > 1 ? undefined : unavailableDispositifsByKeys?.[0]
  const [selectedUnavailableOption, setSelectedUnavailableOption] = useState<IDispositifOption | undefined>(defaultSelectedValue);

  const handleSelectOption = useCallback((optionValue: string) => {
    if (!optionValue) {
      return;
    }
    const newSelectedOption = unavailableDispositifsByKeys?.find(
      item => item.value === optionValue
    );

    setSelectedUnavailableOption(newSelectedOption);
  }, [unavailableDispositifsByKeys, setSelectedUnavailableOption]);

  const renderMixDispositifsEligibleForUnavailable = useCallback(() => {
    return optionsForRadiosButtonList?.length > 1 ? (
      <View style={styles.radioButtonGroup}>
        <View style={styles.unavailableHeaderTitleStyle}>
          <Title variant="t7">
            {formatMessage({ id: 'UnavailableRepaymentOustandingSelection' })}
          </Title>
        </View>
        <RadiosButtonList
          activeRadio={selectedUnavailableOption?.value}
          onChange={handleSelectOption}
          options={optionsForRadiosButtonList}
        />
      </View >
    ) : undefined
  }, [
    formatMessage,
    handleSelectOption,
    optionsForRadiosButtonList,
    selectedUnavailableOption?.value,
    styles.radioButtonGroup,
    styles.unavailableHeaderTitleStyle]);

  const handleRedirectToPersoneo = useCallback((provider: string) => {
    navigation.navigate(RouteNames.RedirectOperationPage, {
      provider,
      operationType: OperationHistoryTypesEnum.REFUND,
    });
  }, [navigation])

  const handleDispositifsRcValidated = useCallback(() => navigation.navigate(RouteNames.RefundsStack, {
    screen: RouteNames.UnavailableRefundRC,
  }), [navigation]);

  const shouldShowQuikWin = (REACT_APP_ENV !== EnvironnementNameEnum.DEV && REACT_APP_ENV !== EnvironnementNameEnum.DEV_LOCAL && REACT_APP_ENV !== EnvironnementNameEnum.INT)

  return {
    selectedUnavailableOption: shouldShowQuikWin ? selectedUnavailableOption?.value : undefined,
    elligibleDispositifs,
    renderMixDispositifsEligibleForUnavailable: shouldShowQuikWin ? renderMixDispositifsEligibleForUnavailable : undefined,
    handleRedirectToPersoneo,
    handleDispositifsRcValidated
  }
}
