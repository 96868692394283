import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

const CARD_HEIGHT = 176;
const CARD_WIDTH = 310;
const CARD_IMAGE_WIDTH = 180;

export const useStyles = () => {
  const styles = useMemo(() => {
    return StyleSheet.create({
      ocdContainer: {
        flex: 1,
        flexDirection: 'row',
        borderRadius: 5,
        overflow: 'hidden',
        width: CARD_WIDTH,
        height: CARD_HEIGHT
      },
      ocdTextContentContainer: {
        flex: 1,
        height: CARD_HEIGHT
      },
      imageStyle: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        width: CARD_IMAGE_WIDTH,
        height: CARD_HEIGHT
      },
      ocdLoadingBlocStyle: {
        width: CARD_WIDTH,
        height: CARD_HEIGHT
      }
    });
  }, []);

  return styles;
};
