import {
ArbitrationReinvestmentContext,
ArbitrationReinvestmentStyle
} from "./interfaces";

export const getStyles = (
  context?: ArbitrationReinvestmentContext,
  style?: ArbitrationReinvestmentStyle
): ArbitrationReinvestmentStyle => ({
  dashboardWrapper: [
    { backgroundColor: context?.theme?.colors.basics.white },
    style?.dashboardWrapper,
  ],
  stepperContainer: [
    {
      alignItems: "center",
    },
    style?.stepperContainer,
  ],
  stepperStyle: [
    {
      paddingBottom: context?.theme.metrics.spacing.s,
      marginTop: context && -context?.theme.metrics.spacing.s,
    },
    style?.stepperStyle,
  ],
  bottomActionsStyle: [
    {
      width: '100%',
    },
    !(context?.isMobile || context?.isTablet) && {
      marginTop: context?.theme.metrics.spacing.xl,
    },
    style?.bottomActionsStyle,
  ],
  bottomActionsButtonsContainerStyle: [
    {
      width: '100%',
      flexDirection: 'row',
    },
    style?.bottomActionsButtonsContainerStyle,
  ],
  arrowButtonStyle: [
    style?.arrowButtonStyle,
  ],
  simpleButtonStyle: [{
    marginLeft: context?.theme.metrics.spacing.m,
    flex: 1,
  },
  style?.simpleButtonStyle,
  ],
  cardContainer: [
    {
      marginTop: context?.theme.metrics.spacing.l,
      marginBottom: context?.theme.metrics.spacing.l,
    },
    style?.cardContainer,
  ],
  compareButtonContainerStyle: [
    {
      borderBottomWidth: 1,
      borderBottomColor: context?.theme.colors.basics.grey.c100,
      alignItems: 'center',
    },
    style?.compareButtonContainerStyle,
  ],
  compareButtonStyle: [
    {
      marginBottom: context?.theme.metrics.spacing.xm,
      marginTop: context?.theme.metrics.spacing.xm,
    },
    style?.compareButtonStyle,
  ],
});
