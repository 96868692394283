import { StackNavigationProp } from '@react-navigation/stack'
import React, { useState } from 'react'

import { FundSheetModal, FundSheetModalDataType } from '@components/index'
import { AppNavigatorInterface } from '@navigation/Interfaces'
import { SavingRepartitionCardPropsType } from '@components/ERE360Components/SavingRepartitionCard/interfaces'
import { SavingRepartitionCard } from '@components/ERE360Components/SavingRepartitionCard'

interface SavingRepartitionCardWithFundSheetProps extends SavingRepartitionCardPropsType {
  supportIsin: string
  navigation: StackNavigationProp<AppNavigatorInterface>
}

export const SavingRepartitionCardWithFundSheet: React.FC<React.PropsWithChildren<SavingRepartitionCardWithFundSheetProps>> = ({ supportIsin, navigation, ...SavingRepartitionCardPassedProps }) => {
  const [fundSheetModalData, setFundSheetModalData] = useState<FundSheetModalDataType | null>()
  const [displayFundSheetModal, setDisplayFundSheetModal] = useState(false)

  const handleDisplayFundSheet = () => {
    setDisplayFundSheetModal(true)
    setFundSheetModalData({
      id: supportIsin,
      title: SavingRepartitionCardPassedProps.supportName || ''
    })
  }
  const handleHideFundSheet = () => {
    setDisplayFundSheetModal(false)
    setFundSheetModalData(null)
  }
  return (
    <>
      <SavingRepartitionCard
        {...SavingRepartitionCardPassedProps}
        onCardPress={handleDisplayFundSheet}
        testId={SavingRepartitionCardPassedProps.testId}
      />
      {fundSheetModalData && (
        <FundSheetModal
          id={fundSheetModalData?.id}
          modalIsVisible={displayFundSheetModal}
          onCloseModal={handleHideFundSheet}
          title={fundSheetModalData?.title}
        />
      )}
    </>
  )
}
