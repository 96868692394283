import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { RouteNames } from '@constants/navigation';
import { getUsedCompanyId } from '@modules/dashboard/selectors';
import { runManager } from '@modules/moduleManager';
import { RootNavigation } from '@navigation/RootNavigation';

import * as RetirementActions from './actions/retirementActions';
import {
  PostRetirementDecisionRequestAction,
  RetirementActionsType
} from './actions/retirementActionsTypes';
import { fetchRetirementDecision, postRetirementDecisions } from './services/retirement';
import { FetchRetirementDecisionResponse, PostRetirementDecisionResponse } from './types';

function* getRetirementDecision() {
  const companyId: string = yield select(getUsedCompanyId);
  const response: FetchRetirementDecisionResponse = yield call(fetchRetirementDecision, companyId);

  yield put(RetirementActions.getRetirementDecisionSuccess(response.data));
}

export function* getRetirementDecisionSagas() {
  yield takeLatest(
    RetirementActionsType.GET_RETIREMENT_DECISION_REQUEST,
    runManager(getRetirementDecision, RetirementActionsType.GET_RETIREMENT_DECISION_FAILURE)
  );
}

function* postRetirementDecision(
  action: PostRetirementDecisionRequestAction
)  {
  const companyId: string = yield select(getUsedCompanyId);
  const response: PostRetirementDecisionResponse = yield call(postRetirementDecisions, {
    formDecision: action.formData,
    companyId
  });

  yield put(
    RetirementActions.postRetirementDecisionSuccess(response.data)
  );
  
  RootNavigation.navigate(RouteNames.RetirementStack, {
    screen: RouteNames.RetirementRecoverSavingPage
  })
}

function* postRetirementDecisionSagas() {
  yield takeLatest(
    RetirementActionsType.POST_RETIREMENT_DECISION_REQUEST,
    runManager(postRetirementDecision, RetirementActionsType.POST_RETIREMENT_DECISION_FAILURE)
  );
}

export function* retirementSagas() {
  yield all([fork(getRetirementDecisionSagas), fork(postRetirementDecisionSagas)]);
}
