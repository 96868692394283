import dayjs from 'dayjs';
import React from 'react';
import { useSelector } from 'react-redux';

import { AppState } from '@modules/index';
import {
  getUser
} from '@modules/auth/selectors';

export const useIsAuthenticated = ()=> {

  const user = useSelector((state: AppState) =>
    getUser(state)
  );
  const sessionExpireAt = dayjs(user?.expires_at && user?.expires_at * 1000).format();
  const currentTime = dayjs().format();
  const isAuthenticated = !!(user?.access_token && user?.access_token !== '') && (sessionExpireAt > currentTime);

  return {
    isAuthenticated
  };

};
