import { RouteNames } from "@constants/navigation";
import { SimulatorType } from "@constants/sapiendo";
import {
  getAllocationsRequest,
  getAvailabilitiesRequest,
  getBudgetInsightAccountsRequest,
  getBudgetInsightManagerUrlRequest,
  getContributionRequest,
  getDashboardDataRequest,
  getOCDRequest,
  getRecentOperationsRequest,
  getToolRequest,
  putSelectedCompanyRequest
} from "@modules/dashboard/actions/dashboardActions";
import { DashboardActionsType } from "@modules/dashboard/actions/dashboardActionsTypes";
import {
  getAccountsSelector,
  getAllPlans,
  getAllocations,
  getAvailabilities,
  getBudgetInsightAccounts,
  getContributionByCompany,
  getError,
  getOCDBottom,
  getOCDTop,
  getRecentOperations,
  getSavingsPlans,
  getSelectedCompany,
  getTools,
  getUsedCompanyId
} from "@modules/dashboard/selectors";
import { ToolsFlowTypeEnum } from "@modules/dashboard/types";
import { AppState } from "@modules/index";
import { RedirectToSavings } from "@modules/savings/actions/savingsActions";
import { getApplicationSettings, getIsFeatureRegroupementContratsEnabled } from "@modules/settings/selectors";
import { AppNavigatorInterface } from "@navigation/Interfaces";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useController = () => {
  const appState = useSelector((state: AppState) => state);
  const dispatch = useDispatch();
  const navigation = useNavigation<StackNavigationProp<AppNavigatorInterface>>();

  //constants
  const userAccounts = useSelector(getAccountsSelector);
  const availabilities = useSelector(getAvailabilities);
  const usedCompanyId = useSelector(getUsedCompanyId);
  const allocations = useSelector(getAllocations);
  const allPlans = useSelector(getAllPlans);
  const selectedCompany = useSelector(getSelectedCompany)
  const operations = useSelector(getRecentOperations)
  const tools = useSelector((state: AppState) => getTools(state, ToolsFlowTypeEnum.DASHBOARD));
  const OCDTop = useSelector(getOCDTop);
  const OCDBottom = useSelector(getOCDBottom)
  const appSettings = useSelector(getApplicationSettings);
  const savingsPlans = useSelector(getSavingsPlans)
  const EnableFeatureRegroupementContrats = useSelector(getIsFeatureRegroupementContratsEnabled);
  const contributionByCompany = useSelector(getContributionByCompany)
  const budgetInsightAccounts = useSelector(getBudgetInsightAccounts);

  //Loaders
  const isAvalabilitiesApiLoading = appState.isLoading[DashboardActionsType.GET_AVAILABILITIES_REQUEST]
  const isAllocationsApiLoading = appState.isLoading[DashboardActionsType.GET_ALLOCATIONS_REQUEST]
  const isOperationsApiLoading = appState.isLoading[DashboardActionsType.GET_RECENT_OPERATIONS_REQUEST]
  const isToolsApiLoading = appState.isLoading[DashboardActionsType.GET_TOOL_REQUEST]
  const isOCDApiLoading = appState.isLoading[DashboardActionsType.GET_OCD_REQUEST]
  const cardTableLoading = appState.isLoading[DashboardActionsType.GET_CONTRIBUTION_REQUEST]
  const isBIApiLoading = appState.isLoading[DashboardActionsType.GET_BUDGET_INSIGHT_ACCOUNTS_REQUEST]

  //Errors
  const errors = {
    availabilitiesError: getError('availabilitiesError', appState),
    allocationsError: getError('allocationsError', appState),
    OCDError: getError('OCDError', appState),
    recentOperationsError: getError('recentOperationsError', appState),
    toolsError: getError('toolsError', appState),
    contributionError: getError('contributionError', appState),
    budgetInsightError: getError('budgetInsightError', appState)
  };

  // Dispatch
  const onGetAvailabilities = useCallback(
    (companyId: string) => dispatch(getAvailabilitiesRequest(companyId)), [dispatch]);

  const onGetOperations = useCallback(
    (companyId: string) => dispatch(getRecentOperationsRequest(companyId)), [dispatch]);

  const onGetAllocations = useCallback(
    (companyId: string) => dispatch(getAllocationsRequest(companyId)), [dispatch]);

  const onSelectCompany = useCallback(
    (...args: Parameters<typeof putSelectedCompanyRequest>
    ) => dispatch(putSelectedCompanyRequest(...args)), [dispatch]);


  const onRedirectToSavings = useCallback(
    (...args: Parameters<typeof RedirectToSavings>
    ) => dispatch(RedirectToSavings(...args)), [dispatch]);


  const onGetOCD = useCallback(() => dispatch(getOCDRequest()), [dispatch]);

  const onGetTool = useCallback(() => dispatch(getToolRequest()), [dispatch]);

  const onGetContributionByCompany = useCallback(() => dispatch(getContributionRequest()), [dispatch]);

  const onGetBudgetInsightAccounts = useCallback(() => dispatch(getBudgetInsightAccountsRequest()), [dispatch]);

  const onGetBudgetInsightManagerUrl = useCallback(() => dispatch(getBudgetInsightManagerUrlRequest()), [dispatch]);

  const onGetDashboardDataRequest = useCallback(() => dispatch(getDashboardDataRequest()), [dispatch]);

  const handleCardRetryAction = useCallback((callback: (id: string) => void) => {
    if (!usedCompanyId) return;

    return callback(usedCompanyId)

  }, [usedCompanyId]);

  //Retry actions
  const recentOperationsRetryaction = useCallback(() => handleCardRetryAction(onGetOperations), [
    handleCardRetryAction,
    onGetOperations
  ]);

  const availabilityRetryAction = useCallback(() => handleCardRetryAction(onGetAvailabilities), [
    handleCardRetryAction,
    onGetAvailabilities
  ]);

  const globalSavingsRetryAction = useCallback(() => handleCardRetryAction(onGetAllocations), [
    handleCardRetryAction,
    onGetAllocations
  ]);

  // navigation
  const handleUnlockHelpPress = useCallback(() => {
    if (!appSettings?.EnableFeatureAideDeblocage) return;

    navigation.navigate(RouteNames.DisponibilityTermPage);
  }, [appSettings, navigation]);

  const handleSapiendoPress = useCallback(() => {
    if (!appSettings?.EnableFeatureSapiendo) return;

    navigation.navigate(RouteNames.SapiendoRedirectPage, {
      type: SimulatorType.RETIREMENT
    });
  }, [appSettings, navigation]);

  const onRedirectToSavingsTabs = useCallback((planId: number | string, selectedTab: string) => {
    if (savingsPlans.length) {
      let selectedPlanIndex = savingsPlans.findIndex(saving => saving.planId === String(planId));
      if (selectedPlanIndex < 0) selectedPlanIndex = 0;
      onRedirectToSavings(selectedPlanIndex, selectedTab);
      navigation?.navigate(RouteNames.BottomTabNavigator, {
        screen: RouteNames.SavingsStack,
        params: {
          screen: RouteNames.Savings
        }
      });
    }

  }, [navigation, onRedirectToSavings, savingsPlans]);

  //constants booleans;
  const isSapiendoActive = useMemo(() => appSettings?.EnableFeatureSapiendo, [appSettings]);
  const isUnlockHelpActive = useMemo(() => appSettings?.EnableFeatureAideDeblocage, [appSettings]);

  return {
    userAccounts,
    availabilities,
    allocations,
    usedCompanyId,
    errors,
    allPlans,
    selectedCompany,
    operations,
    OCDTop,
    OCDBottom,
    tools,
    appSettings,
    isOCDApiLoading,
    isBIApiLoading,
    isAvalabilitiesApiLoading,
    isAllocationsApiLoading,
    isToolsApiLoading,
    isOperationsApiLoading,
    savingsPlans,
    EnableFeatureRegroupementContrats,
    contributionByCompany,
    budgetInsightAccounts,
    cardTableLoading,
    isSapiendoActive,
    isUnlockHelpActive,
    onGetOCD,
    onGetTool,
    onRedirectToSavings,
    onSelectCompany,
    handleSapiendoPress,
    onGetContributionByCompany,
    onGetBudgetInsightAccounts,
    onGetDashboardDataRequest,
    onGetBudgetInsightManagerUrl,
    recentOperationsRetryaction,
    availabilityRetryAction,
    handleUnlockHelpPress,
    globalSavingsRetryAction,
    onRedirectToSavingsTabs
  };
}
