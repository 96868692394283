import { AppState } from '@modules/reducers';

export const getDocumentsCategories = (state: AppState) => state.documents.categories;
export const getDocumentsCategoriesSelected = (state: AppState) => state.documents.categories.filter(category => !!category.isSelected);

export const getDocumentsCategoriesFilter = (state: AppState) => state.documents.categoriesFilter;

export const getDocumentsFiles = (state: AppState) => state.documents.files;
export const getDocumentBlob = (state: AppState) => state.documents.blobDocument;
export const getEDocuments = (state: AppState) => state.documents.eDocuments;
export const isSubscribedToEDocuments = (state: AppState) => state.documents.isSubscriber;
export const hasDocumentsError = (state: AppState) => !!state.documents.error?.message;
