import {
  ArbitrationAction,
  ArbitrationActionsType
} from '@modules/arbitration/actions/arbitrationActionsTypes'
import { AuthActionType, AuthenticateAction } from '@modules/auth/actions/authActionsTypes'
import { SupportType } from '@modules/common/types'
import { ErrorActionsType } from '@modules/error/actions/errorActionsTypes'
import { TriggerThresholdProperties } from '@modules/refund/available-refund/types'
import { backToQuestion } from '@modules/utils/requirementDefinition'

import { ArbitrationState, DueDateOption } from './types'

const initialState: ArbitrationState = {
  decision: {
    dispositifs: [],
    questions: [],
    elligibleDispositifs: [],
    isVisibleSubmitModal: false,
    reallocationType: null
  },
  error: {
    code: '',
    correlationId: '',
    message: '',
    innerMessage: ''
  },
  arbitrationOadManualRepartitionData: [],
  arbitrationOadSimulationData: [],
  arbitrationOadRepartitionData: [],
  arbitrationAmountModalVisibleState: false,
  arbitrationPercentageModalVisibleState: false,
  arbitrationAnswerData: undefined,
  arbitrationOadAllocations: undefined,
  arbitrationTriggerThresholdModalVisibleState: false,
  riskProfile: undefined,
  arbitrationDueDateAmountModalVisibleState: false,
  dueDateAmountFieldValue: undefined
}

const setSelectedManagement = (
  isSelected: boolean,
  managementId: string,
  amount: number,
  state: ArbitrationState
) => {
  const oldDecision = state.decision

  if (!oldDecision.selectedCompartment) {
    return state.decision
  }

  const oldManagements = oldDecision.selectedCompartment.managements
  const selectedManagementIndex = oldManagements.findIndex(
    management => management.id === managementId
  )

  const newManagements = oldManagements.map((management, index) => {
    let formValues

    if (index === selectedManagementIndex) {
      formValues = {
        ...management.formValues,
        isSelected,
        selectedMasterSupportId: management.supports.filter(support => support.isMaster)[0]
          ?.supportIsin,
        amount
      }
    }

    const newManagement = {
      ...management,
      formValues
    }

    return newManagement
  })

  const divestedManagementSource = {
    ...state.decision.divestedManagementSource,
    managementId,
    amount,
    supportIsinCode: undefined
  }

  const newDecision = {
    ...oldDecision,
    divestedManagementSource,
    selectedCompartment: {
      ...oldDecision.selectedCompartment,
      managements: [...newManagements]
    }
  }

  return newDecision
}

const setSelectedSupport = (
  supports: SupportType[],
  isSelectedManagement: boolean,
  selectedSupportIndex: number,
  amount?: number,
  triggerThresholdData?: TriggerThresholdProperties,
  dueDateOption?: DueDateOption
) => {
  const newSupports: SupportType[] = supports.map((support, supportIndex) => {
    const isSelectedSupport = isSelectedManagement && selectedSupportIndex === supportIndex

    let formValues

    if (isSelectedSupport) {
      formValues = {
        ...support.formValues,
        isSelected: true,
        amount,
        triggerThreshold: triggerThresholdData,
        dueDateOption
      }
    }

    const newSupport = {
      ...support,
      formValues
    }

    return newSupport
  })

  return newSupports
}

const setSelectedManagementAndSupport = (
  isSelected: boolean | undefined,
  managementId: string | undefined,
  isinCode: string | undefined,
  amount: number | undefined,
  state: ArbitrationState,
  triggerThresholdData?: TriggerThresholdProperties | undefined,
  dueDateOption?: DueDateOption
) => {
  if (!state.decision.selectedCompartment) {
    return state.decision
  }

  const oldManagements = state.decision.selectedCompartment.managements

  const selectedManagementIndex = oldManagements.findIndex(
    management => management.id === managementId
  )

  const selectedSupports = oldManagements[selectedManagementIndex].supports

  const selectedSupportIndex = selectedSupports.findIndex(
    support => support.supportIsin === isinCode
  )

  const newManagements = oldManagements.map((management, managementIndex) => {
    const isSelectedManagement = managementIndex === selectedManagementIndex

    const supports: SupportType[] = setSelectedSupport(
      management.supports,
      isSelectedManagement,
      selectedSupportIndex,
      amount,
      triggerThresholdData,
      dueDateOption
    )

    let managementFormValues

    if (isSelectedManagement) {
      managementFormValues = {
        ...management.formValues,
        amount,
        isSelected,
        dueDateOption
      }
    }

    const newManagement = {
      ...management,
      formValues: managementFormValues,
      supports
    }

    return newManagement
  })

  const divestedManagementSource = {
    ...state.decision.divestedManagementSource,
    managementId,
    amount,
    supportIsinCode: isinCode,
    executionTriggerValue: triggerThresholdData?.amount,
    dueDateOption
  }

  const newDecision = {
    ...state.decision,
    divestedManagementSource,
    selectedCompartment: {
      ...state.decision.selectedCompartment,
      managements: newManagements
    }
  }

  return newDecision
}

const setInvestedSelectedSupport = (
  isSelected: boolean | undefined,
  managementId: string | undefined,
  isinCode: string | undefined,
  amountPercent: number | undefined,
  state: ArbitrationState
) => {
  if (!state.reallocationDivested) {
    return state.reallocationDivested
  }
  const constructedCompartment = state.reallocationDivested.compartments[0]

  const constructedManagements = constructedCompartment.managements
  const selectedManagementIndex = constructedManagements.findIndex(
    management => management.id === managementId
  )

  const constructedSupports = constructedManagements[selectedManagementIndex].supports

  const selectedSupportIndex = constructedSupports.findIndex(
    support => support.supportIsin === isinCode
  )

  constructedSupports[selectedSupportIndex].investedFormValues = {
    ...constructedSupports[selectedSupportIndex].investedFormValues,
    isSelected: isSelected,
    amountPercent: amountPercent
  }

  const reallocationDivested = {
    ...state.reallocationDivested,
    compartments: [constructedCompartment]
  }
  return reallocationDivested
}

const setInvestedSelectedManagement = (
  isSelected: boolean | undefined,
  managementId: string | undefined,
  amountPercent: number | undefined,
  state: ArbitrationState
) => {
  if (!state.reallocationDivested) {
    return state.reallocationDivested
  }
  const constructedCompartment = state.reallocationDivested.compartments[0]

  const constructedManagements = constructedCompartment.managements
  const selectedManagementIndex = constructedManagements.findIndex(
    management => management.id === managementId
  )

  const selectedDispositif =
    state.decision.selectedDispositif && state.decision.selectedDispositif[0]
  const isPartialReallocation = selectedDispositif?.filterProperties.PartialReallocation

  if (!isPartialReallocation) {
    constructedManagements[selectedManagementIndex].investedFormValues = {
      ...constructedManagements[selectedManagementIndex]?.investedFormValues,
      amountPercent: amountPercent,
      isSelected: isSelected
    }
  } else {
    const constructedSupport = constructedManagements[selectedManagementIndex].supports.filter(
      support => support.isMaster
    )[0]
    constructedSupport.investedFormValues = {
      ...constructedSupport.investedFormValues,
      isSelected: isSelected,
      amountPercent: amountPercent
    }
  }

  const reallocationDivested = {
    ...state.reallocationDivested,
    compartments: [constructedCompartment]
  }

  return reallocationDivested
}

export default function arbitrationReducer(
  state: ArbitrationState = initialState,
  action: ArbitrationAction | AuthenticateAction
): ArbitrationState {
  switch (action?.type) {
    case ArbitrationActionsType.SET_DIVESTED_MANAGEMENT_SOURCE:
      return {
        ...state,
        decision: {
          ...state.decision,
          divestedManagementSource: action.params
        }
      }
    case ArbitrationActionsType.ARBITRATION_REPARTITION_SELECT_MANAGEMENT:
      return {
        ...state,
        decision: setSelectedManagement(
          action.selectState,
          action.managementId,
          action.amount,
          state
        )
      }
    case ArbitrationActionsType.SET_SELECTED_DISPOSITIF:
      return {
        ...state,
        decision: {
          ...state.decision,
          selectedDispositif: action.dispositif
        }
      }
    case ArbitrationActionsType.CLEAR_ARBITRATION_SUPPORT_TRIGGER_THRESHOLD:
    case ArbitrationActionsType.ARBITRATION_REPARTITION_SELECT_SUPPORT:
      return {
        ...state,
        decision: setSelectedManagementAndSupport(
          action.params?.selectState,
          action.params?.managementId,
          action.params?.supportIsin,
          action.params?.amount,
          state,
          action.params?.triggerThreshold,
          action.params.dueDateOption
        )
      }
    case ArbitrationActionsType.SET_ARBITRATION_SUPPORT_TRIGGER_THRESHOLD:
      return {
        ...state,
        decision: setSelectedManagementAndSupport(
          action.params?.selectState,
          action.params?.managementId,
          action.params?.supportIsin,
          action.params?.amount,
          state,
          action.TriggerThreshold,
          action.params?.dueDateOption
        )
      }
    case ArbitrationActionsType.ARBITRATION_INVESTED_SUPPORT:
      return {
        ...state,
        reallocationDivested: setInvestedSelectedSupport(
          action.params?.selectState,
          action.params?.managementId,
          action.params?.supportIsin,
          action.params?.amountPercent,
          state
        )
      }
    case ArbitrationActionsType.ARBITRATION_INVESTED_MANAGEMENT:
      return {
        ...state,
        reallocationDivested: setInvestedSelectedManagement(
          action.params?.selectState,
          action.params?.managementId,
          action.params?.amountPercent,
          state
        )
      }
    case ArbitrationActionsType.SET_SELECTED_COMPARTMENT:
      return {
        ...state,
        decision: {
          ...state.decision,
          selectedCompartment: action.compartment
        }
      }
    case ArbitrationActionsType.SET_IS_VISIBLE_SUBMIT_MODAL:
      return {
        ...state,
        decision: {
          ...state.decision,
          isVisibleSubmitModal: action.isVisible
        }
      }
    case ArbitrationActionsType.SET_ARBITRATION_DESINVEST_AMOUNT_MODAL_VISIBLE_STATE:
      return {
        ...state,
        arbitrationAmountModalVisibleState: action.state
      }
    case ArbitrationActionsType.SET_ARBITRATION_REINVEST_PERCENTAGE_MODAL_VISIBLE_STATE:
      return {
        ...state,
        arbitrationPercentageModalVisibleState: action.isVisibleState
      }
    case ArbitrationActionsType.GET_DISPOSITIF_DETAIL_REQUEST:
      return {
        ...state,
        decision: {
          ...state.decision,
          planCompartments: initialState.decision.planCompartments
        }
      }
    case ArbitrationActionsType.ARBITRATION_BACK_TO_QUESTION:
      return {
        ...state,
        decision: {
          ...state.decision,
          ...backToQuestion(state, action.index)
        }
      }
    case ArbitrationActionsType.UPDATE_ARBITRATION_QUESTION_WITH_RESPONSE_SUCCESS:
      return {
        ...state,
        decision: action.newDecision
      }
    case ArbitrationActionsType.SELECT_ARBITRATION_TYPE_SUCCESS:
      return {
        ...state,
        decision: {
          ...state.decision
          //questions: action.updatedPlansQuestionResponses,
        }
      }
    case ArbitrationActionsType.GET_ARBITRATION_QUESTIONS_SUCCESS:
      return {
        ...state,
        decision: action.decision
      }
    case ArbitrationActionsType.GET_DISPOSITIF_DETAIL_SUCCESS:
      return {
        ...state,
        decision: {
          ...state.decision,
          planCompartments: action.planCompartments
        }
      }
    case ArbitrationActionsType.GET_REALOCATION_DIVESTMENT_SUCCESS:
      return {
        ...state,
        reallocationDivested: action.reallocationDivested
      }
    case ArbitrationActionsType.SET_REALLOCATION_TYPE:
      return {
        ...state,
        decision: {
          ...state.decision,
          reallocationType: action.reallocationType
        }
      }
    case ArbitrationActionsType.CREATE_ARBITRATION_SUCCESS:
      return {
        ...state,
        arbitrationAnswerData: action.arbitrationAnswerData
      }
    case ArbitrationActionsType.GET_OAD_ALLOCATIONS_SUCCESS:
      return {
        ...state,
        arbitrationOadAllocations: action.arbitrationOadAllocations
      }
    case ArbitrationActionsType.SET_ARBITRATION_TRIGGER_POINT_MODAL_VISIBLE_STATE:
      return {
        ...state,
        arbitrationTriggerThresholdModalVisibleState: action.state
      }
    case ArbitrationActionsType.GET_REALOCATION_DIVESTMENT_FAILURE:
    case ArbitrationActionsType.GET_ARBITRATION_QUESTIONS_FAILURE:
    case ArbitrationActionsType.GET_DISPOSITIF_DETAIL_FAILURE:
    case ArbitrationActionsType.CREATE_ARBITRATION_FAILURE:
    case ArbitrationActionsType.GET_RISK_PROFILE_FAILURE:
      return {
        ...state,
        error: action.error
      }
    case ArbitrationActionsType.ARBITRATION_QUESTION_INIT_ERROR:
    case ArbitrationActionsType.CREATE_ARBITRATION_REQUEST:
    case ErrorActionsType.INIT_ALL_ERRORS:
      return {
        ...state,
        error: initialState.error
      }
    case ArbitrationActionsType.SET_OAD_REPARTITION_DATA:
      return {
        ...state,
        arbitrationOadRepartitionData: action.arbitrationOadRepartitionData
      }
    case ArbitrationActionsType.SET_OAD_SIMULATION_DATA:
      return {
        ...state,
        arbitrationOadSimulationData: action.arbitrationOadSimulationData
      }
    case ArbitrationActionsType.SET_OAD_MANUAL_REPARTITION_DATA:
      return {
        ...state,
        arbitrationOadManualRepartitionData: action.arbitrationOadManualRepartitionData
      }
    case ArbitrationActionsType.GET_ARBITRATION_QUESTIONS_REQUEST:
    case AuthActionType.LOGOUT_CALLBACK_SUCCESS:
    case ArbitrationActionsType.CLEAR_ARBITRATION_DATA:
      return initialState
    case ArbitrationActionsType.GET_RISK_PROFILE_SUCCESS:
      return {
        ...state,
        riskProfile: action.riskProfile
      }
    case ArbitrationActionsType.SET_ARBITRATION_DUE_DATE_AMOUNT_MODAL_VISIBLE_STATE:
      return {
        ...state,
        arbitrationDueDateAmountModalVisibleState: action.state
      }
    case ArbitrationActionsType.SET_ARBITRATION_DUE_DATE_AMOUNT_FIELD_VALUE:
      return {
        ...state,
        dueDateAmountFieldValue: action.dueDateAmountFieldValue
      }
    default:
      return state
  }
}
