import React from 'react';

import { SVGLocalLoader, Title, useTranslation } from '@components/index';
import { IconEnum } from '@ere-uilib/enums';
import { createUseStyles, useScreenSizes, useTheme } from '@ere-uilib/styles';

import { Props } from './interfaces';
import { getStyles } from './styles';

export const VVPListHeader: React.FC<React.PropsWithChildren<Props>> = ({ }: Props) => {
  const theme = useTheme();
  const { isDesktop } = useScreenSizes();

  const styles = useStyles(
    {
      isMobile: !isDesktop,
      theme
    },
    {}
  );

  const { formatMessage } = useTranslation();
  const placeholderTitle = formatMessage({ id: 'VV_VVP_Actuel_description' });

  return (
    <>
      <SVGLocalLoader
        name={IconEnum.VVP_ICON}
        SVGStyle={styles.SVGStyle}
      />
      <Title
        style={styles.title}
        variant="t7">
        {placeholderTitle}
      </Title>
    </>
  );
};

const useStyles = createUseStyles(getStyles);
