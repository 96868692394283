import { PlanFamillyList } from '@constants/common';
import { AddressStatusEnum, OtpChanelTypeEnum } from '@constants/profile';
import { OtpRequestTypeEnum } from '@ere-uilib/enums';
import { InputMode } from '@modules/common/constants';
import { AddressDetail } from '@modules/common/types';

import { ContactPreferencesOptin } from './actions/profileActions';

export interface ConsentsState {
  code: string;
  content: string;
  isAccepted: boolean;
}

export interface EditorialContentState {
  [x: string]: any;

  code: string;
  content: string;
}

export interface EditorialErrorState {
  code: string;
  correlationId: string;
  message: string;
  innerMessage: string;
}

export interface ProfileErrorState {
  code: string;
  correlationId: string;
  message: string;
  innerMessage: string;
}

export interface OtpErrorState {
  code: string;
  correlationId: string;
  message: string;
  innerMessage: string;
}

export interface ProfileMenuState {
  lastConnectionDate: string;
  menus: ProfileMenuItemsState[];
}

export interface ProfileMenuItemsState {
  label: string;
  subLabel: string;
  url: string;
  operationType: string;
  type: string;
  device: 'mobile' | 'web' | null;
  icon: string;
  subMenus: ProfileMenuItemsState[];
}

export interface ProfileUserInfosState {
  ereIdentifier: string | null;
  mail: string | null;
  phone: string | null;
  gender: string | null;
  birthDate: string | null;
}

export interface OtpParametersState {
  requestType?: OtpRequestTypeEnum;
  chanelType?: OtpChanelTypeEnum;
  chanelLabelMail?: string;
  chanelLabelSms?: string;
  otpIdentifierMail?: string;
  otpIdentifierSms?: string;
  outputActionType?: string;
  outputActionParameters?: object;
  demandId?: string;
}

interface OtpFlow {
  isAskAgainSuccess?: boolean;
}

interface ContactPreference {
  isUpdateSuccess: boolean;
  hasNotificationsActivated: boolean;
  emailOptIn: boolean;
  smsOptIn: boolean;
}

export interface ProfileState {
  consents: ConsentsState[];
  profileMenu: ProfileMenuState;
  userInfos: string;
  error: ProfileErrorState;
  editorialContentPage: EditorialContentState;
  editorialContentError: EditorialErrorState;
  otpParameters: OtpParametersState | null;
  isProfilEditPasswordSuccess: boolean;
  contactPreference: ContactPreference;
  otpError: OtpErrorState;
  otpFlow: OtpFlow;
  contactPreferencesOptin: ContactPreferencesOptin;
  isBiometricLockedFromProfileMenu: boolean;
  bankData: BankDataState;
  editPasswordError: ProfileErrorState
  editPhoneNumberIsEnable: boolean;
  editMailIsEnable: boolean;
  address: AddressState;
  addressUpdateStatus: AddressUpdateStatus | undefined;
  contactInfos: ContactInfosType;
}

export enum AddressUpdateStatus {
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS'
}

export interface AddressState {
  addresses: AddressItem[] | null;
  countries: CountryItem[] | null;
}

export type ProfileAddressUpdateStatusState = Pick<ProfileState, 'addressUpdateStatus'>

export interface AddressItem {
  type: PlanFamillyList;
  status: AddressStatusEnum;
  address: AddressDetail | null;
  inputMode: InputMode;
  maxFileSize: number;
  tsaAddress?: AddressDetail | null;
}

export interface CountryItem {
  twoDigitsCode: string;
  code: string;
  label: string;
}

export interface AccountBankDataType {
  domiciliation: string | null;
  titulaire: string | null;
  rum: string | null;
  ics: string | null;
  iban: string | null;
  bic: string | null;
  type: string | null;
  iso: string | null;
}

export interface BankDataState {
  esPlanList: string[];
  rcPlanList: string[];
  esDebitBankAccountDetails: AccountBankDataType | null;
  esTransferBankAccountDetails: AccountBankDataType | null;
  rcDebitBankAccountDetails: AccountBankDataType | null;
  rcTransferBankAccountDetails: AccountBankDataType | null;
}

export interface emailInfosType {
  directRelationship: boolean,
  enabled: boolean,
  email: string
}
export interface phoneInfosType {
  enabled: boolean,
  phoneNumber: string,
  description: string,
  category: string
}
export interface postalInfosType {
  enabled: boolean
}
export interface ContactInfosType {
  email: emailInfosType,
  phone: phoneInfosType,
  postalEs?: postalInfosType,
  postalRc?: postalInfosType
}
