import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useTheme } from '@ere-uilib/styles';

export function useStyles({
  thumbSize,
  trackHeight,
}:{
  thumbSize:number,
  trackHeight:number,
}) {
  const theme = useTheme();
  const styles = useMemo(() => StyleSheet.create({
    container: {
      
    },
    track: { 
      height: trackHeight,
      borderRadius: trackHeight,
    },
    thumb: {
      width: thumbSize,
      height: thumbSize,
      backgroundColor: theme.colors.basics.primary.c700,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: theme.metrics.borderRadius.xs,
    },
    arrows: {
      color: theme.colors.basics.white,
      fontSize: thumbSize * 0.4
    },
    firstArrows: {
      marginRight: thumbSize * 0.15
    },
    mark: {
      position: 'absolute',
      top: -trackHeight/2,
      height: trackHeight,
    },
    minMark: {
      left: 0,
      borderTopLeftRadius: trackHeight,
      borderBottomLeftRadius: trackHeight,
      backgroundColor: theme.colors.basics.grey.c500,
      borderRightWidth: 1,
      borderRightColor: theme.colors.basics.white
    },
    maxMark: {
      right: -thumbSize,
      borderBottomRightRadius: trackHeight,  
      borderTopRightRadius: trackHeight,  
      backgroundColor: theme.colors.basics.grey.c500,
      borderLeftWidth: 1,
      borderLeftColor: theme.colors.basics.white
    },
  }), [
    theme,
    trackHeight,
    thumbSize
  ]);

  return styles;
}
