import React from "react";

import { OtpValidationModal } from "@components/index";
import { useIsUserCloned } from "@utils/useIsUserCloned";

import { OtpValidationStartPageProps } from "./interface";

export const OtpValidationStartPageComponent: React.FC<React.PropsWithChildren<OtpValidationStartPageProps>> = ({
  otpParameters,
  isAskCodeLoading,
  onSendMessagePress,
}) => {
  const isUserCloned = useIsUserCloned();
  return (
    <OtpValidationModal
      isActionsDisabled={isUserCloned}
      isLoading={isAskCodeLoading}
      onSendMessagePress={onSendMessagePress}
      otpParameters={otpParameters}
    />
  );
};
