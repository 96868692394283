import { useNavigation } from '@react-navigation/native';
import { useCallback, useMemo } from "react";

import { RouteNames } from '@constants/navigation';
import { useTranslation } from "@ere-uilib/translations";
import { RetirementActionsType } from '@modules/retirement/actions/retirementActionsTypes';
import { RetirementPlanDocumentType } from '@modules/retirement/types';

import { TypeOfSendEnum } from "./interfaces";

export const useController = (retirementDecisionForm: RetirementPlanDocumentType[]) => {

  const navigation = useNavigation();
  const { formatMessage } = useTranslation();
  
  const dictionary = useMemo(() => {
    return {
      infoTitle: formatMessage({ id: 'RetirementJourneyRecoverHisSavingInfoTitle' }),
      infoContent: formatMessage({ id: 'RetirementJourneyRecoverHisSavingInfoContent' }),
      savingPageTitle: formatMessage({ id: 'RetirementJourneyMyRetirementPageTitle' }),
    };
  }, [formatMessage]);

  const sendType = retirementDecisionForm.find(el=> el.sendType === TypeOfSendEnum.BoneOnline)?.sendType;

  const handleExitPress = useCallback(() => {
    return navigation.navigate(RouteNames.RetirementStack, {
      screen: RouteNames.RetirementModal,
      params: {
        clearAction: RetirementActionsType.CLEAR_RETIREMENT_DATA,
      },
    });
  }, [navigation]);

  const shouldDisplayAnnuityCard = useMemo(()=> {
    const hasPensionCategory = retirementDecisionForm.filter(decision =>
      decision.documentTypesPlans.some(docType =>
        docType.categories.some(category => category.pension)
      )
    );
  
    return hasPensionCategory.length > 0;
  }, [retirementDecisionForm]);

  return {
    dictionary,
    sendType,
    handleExitPress,
    shouldDisplayAnnuityCard
  }

}