
import { QuestionsState } from "@modules/common/types";

export enum ArbitrationDecisionPropertiesEnum {
  OAD_REALLOCATION = 'OadReallocation',
  PLAN_ID = 'planId',
  COMPARTMENT_CODE = 'compartmentCode',
}

export enum ArbitrationQuestionTypesEnum {
  PLAN_CHOICE = 'planChoice',
  COMPARTMENT_CHOICE = 'compartmentChoice',
  OAD_START = 'OadStart',
  OAD_RISK = 'OadRisk',
  OAD_PROFIL = 'OadProfil',
  OAD_DELAY = 'OadDelay',
  OAD_MANUAL_REPARTITION = 'OadManualRepartition',
}
export enum ArbitrationAnswersTypesEnum {
  OAD_START_YES = 'OADStartYes',
  OAD_START_NO = 'OADStartNo',
  OAD_RISK = 'OadRisk',
  OAD_PROFIL = 'OadProfil',
  OAD_RADIO = 'OADRadio',
  OAD_DELAY = 'OADDelay',
}

export enum OadProfileRiskEnum {
  SAFE = 20,
  BALANCED = 50,
  DYNAMIC = 80,
}

export const planCompartmentsQuestion : QuestionsState = {
  key: '',
  showEligibility: false,
  text: "",
  title: "",
  respondedTitle: "",
  tooltip: "",
  responses: [],
  implicitResponseEnabled: false,
  type: ArbitrationQuestionTypesEnum.COMPARTMENT_CHOICE
}

export const planQuestion: QuestionsState = {
  key: '',
  showEligibility: false,
  text: "",
  title: "",
  respondedTitle: "",
  tooltip: "",
  responses: [],
  implicitResponseEnabled: false,
  type: ArbitrationQuestionTypesEnum.PLAN_CHOICE
}

export const OADStartQuestion: QuestionsState = {
  key: '',
  showEligibility: false,
  text: "",
  title: "",
  respondedTitle: "",
  type: ArbitrationQuestionTypesEnum.OAD_START,
  tooltip: "",
  responses: [
    {
      // yes
      title: '',
      value: '',
      text: '',
      type: ArbitrationAnswersTypesEnum.OAD_START_YES,
      propertyFilter: {
        name: ArbitrationDecisionPropertiesEnum.OAD_REALLOCATION,
        value: true
      }
    },
    {
      // non
      title: '',
      value: '',
      text: '',
      type: ArbitrationAnswersTypesEnum.OAD_START_NO,
      propertyFilter: {
        name: ArbitrationDecisionPropertiesEnum.OAD_REALLOCATION,
        value: false
      }
    }
  ],
}
