import { connect } from 'react-redux';

import { otpFlowCompletedValidationRequest } from '@modules/profile/actions/profileActions';
import { ProfileActionsType } from '@modules/profile/actions/profileActionsTypes';
import { AppState } from '@modules/reducers';
import { getOtpParameters } from '@modules/profile/selectors';

import { OtpValidationSuccessPageComponent } from './OtpValidationSuccessPage.component';

const mapStateToProps = (state: AppState) => {
  return {
    OtpParameters: getOtpParameters(state),
    isOtpFlowCompletedLoading: state.isLoading[ProfileActionsType.OTP_FLOW_COMPLETED_VALIDATION_REQUEST]
  };
};

const mapDispatchToProps = ({
  onValidation: otpFlowCompletedValidationRequest
});

export const OtpValidationSuccessPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(OtpValidationSuccessPageComponent);

