import { AuthActionType, AuthenticateAction } from '@modules/auth/actions/authActionsTypes';
import { AccountsAction } from '@modules/dashboard/actions/dashboardActionsTypes';

import { DocumentsAction, DocumentsActionsType } from './actions/documentsActionsTypes';
import { DocumentsState } from './types';

export const initialState: DocumentsState = {
  categories: [],
  rawCategories: [],
  files: [],
  categoriesFilter: [],
  blobDocument: "",
  eDocuments: [],
  isSubscriber: false,
  error: null,
};

const setResetCategories = (
  state: DocumentsState
) => {
  let constructedCategories = state.categories;
  constructedCategories.map((category, index) => {
    return delete category.isSelected;
  });
  constructedCategories = [
    ...constructedCategories];
  return constructedCategories;
}

export default function documentsReducer(
  state: DocumentsState = initialState,
  action: DocumentsAction | AuthenticateAction | AccountsAction
): DocumentsState {
  switch (action.type) {
    case DocumentsActionsType.GET_DOCUMENTS_CATEGORIES_SUCCESS:
      return {
        ...state,
        rawCategories: action.categories,
        categories: action.categories,
        files: action.files,
        error: null
      };
    case DocumentsActionsType.SET_DOCUMENTS_CATEGORIES_FILTER:
      return {
        ...state,
        categories: state.categories.map(
          (category, i) => category.id === action.categoryId ?
            { ...category, isSelected: action.isSelected }
            : category
        )
      };
    case DocumentsActionsType.SET_DOCUMENTS_CATEGORIES_RESET:
      return {
        ...state,
        categories: setResetCategories(state),
      };
    case DocumentsActionsType.GET_DOCUMENT_BLOB_RESPONSE_SUCCESS:
      return {
        ...state,
        blobDocument: action.blobResponse
      };
    case DocumentsActionsType.SET_ALL_DOCUMENTS_CATEGORIES_FILTER:
      return {
        ...state,
        categories: state.rawCategories.map(category => ({
          ...category,
          isSelected: false
        })),
      };
    case DocumentsActionsType.GET_EDOCUMENTS_SUCCESS:
      return {
        ...state,
        ...action.eDocuments,
        error: null
      }
    case DocumentsActionsType.GET_EDOCUMENTS_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isSubscriber: action.isSubscriber
      }
    case DocumentsActionsType.GET_DOCUMENTS_CATEGORIES_FAILURE:
    case DocumentsActionsType.GET_EDOCUMENTS_FAILURE:
      return {
        ...state,
        error: action.error
      }
    case AuthActionType.LOGOUT_CALLBACK_SUCCESS:
      return initialState;
    // case CLEAR DOC DATA:
    default:
      return state;
  }
}