import { StyleSheet } from 'react-native';

import { Context, Styles } from './interfaces';

export const getStyles = (context?: Context, style?: Styles): Styles => {
  const textColor = context?.darkText ? context?.theme?.colors.basics.black : context?.theme?.colors.basics.white;
  return {
    container: [
      {
        backgroundColor: context?.isDisabled
          ? context?.theme?.colors.basics.grey.c200
          : context?.theme?.colors.basics.primary.c500,
        borderTopRightRadius: context?.theme?.metrics.borderRadius.xs,
        borderTopLeftRadius: context?.theme?.metrics.borderRadius.xs,
        flexDirection: 'row',
        alignItems: 'center',
        minHeight: 45,
        paddingHorizontal: context?.theme?.metrics.spacing.s,
        paddingVertical: context?.theme?.metrics.spacing.s,
        overflow: 'hidden',

      },
      style?.container,
    ],
    iconTextContainer: [
      {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
      },
      style?.iconTextContainer,
    ],
    rightContainer: [
      {
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        flex: 1,
      },
      style?.rightContainer,
    ],
    title: [
      {
        flex: 1,
        color: textColor,
      },
      style?.title,
    ],
    disabledTitle: [
      {
        flex: 1,
        color: context?.theme?.colors.basics.grey.c500,
      },
      style?.disabledTitle,
    ],
    titleRight: [
      {
        color: textColor,
        textAlign: 'right',
        flex: 1,
      },
      style?.titleRight,
    ],
    defaultBackgroundContainer: [
      {
        ...StyleSheet.absoluteFillObject,
        zIndex: -1,
      },
    ],
    iconContainer: [{
      marginRight: context?.theme?.metrics.spacing.s,
    }, style?.iconContainer],
    amount: [
      {
        color: textColor,
        flex: 1,
      },
      style?.amount,
    ],
    disabledAmount: [
      {
        color: context?.theme?.colors.basics.grey.c500,
      }
    ]
  };
};
