import React from 'react';
import { TouchableOpacity, View } from 'react-native';

import { FontIcon, NotificationIcon, NotificationIconTypeEnum, SquareIconSizeEnum, Text, Title } from '@ere-uilib/atoms';
import { IconEnum } from '@ere-uilib/enums';
import { createUseStyles, useScreenSizes, useTheme } from '@ere-uilib/styles';

import { getStyles } from './styles';
import { Props } from './types';

export const NotificationHard: React.FC<React.PropsWithChildren<Props>> = ({
  type = NotificationIconTypeEnum.INFO,
  text,
  title,
  children,
  isLarge,
  containerStyle,
  textContainerStyle,
  showCloseButton = false,
  onPressCloseButton,
  renderBottomContent,
  customIconName,
  hideIcon,
}: Props) => {
  const theme = useTheme();
  const isMobile = useScreenSizes().isMobile;
  const screenWidth = useScreenSizes().windowWidth;

  const styles = useStyles({
    isLarge,
    isMobile,
    screenWidth,
    theme,
  },
    {
      containerStyle,
      textContainerStyle,
    });

  return (
    <View style={styles.containerStyle}>
      {showCloseButton && (
        <TouchableOpacity
          onPress={onPressCloseButton}
          style={{ alignSelf: 'flex-end' }}
        >
          <FontIcon
            color={theme?.colors.text.primary.c500}
            name={IconEnum.CLOSE}
            size={theme.metrics.iconSizes.s}
          />
        </TouchableOpacity>
      )}
      <View style={styles.contentStyle}>
        <View style={{ marginRight: theme.metrics.spacing.m }}>
          {!hideIcon && (
            <NotificationIcon
              customIconName={customIconName}
              size={SquareIconSizeEnum.SMALL}
              type={type}
            />
          )}
        </View>
        <View style={styles.textContainerStyle}>
          {title && <Title
            style={styles.titleStyle}
            variant={'t7'}>{title}</Title>}
          {text && <Text
            variant={'t3'}
            weight="light">{text}</Text>}
          {children}
        </View>
      </View>
      {renderBottomContent}
    </View>
  );
};

const useStyles = createUseStyles(getStyles);
