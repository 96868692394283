import React from 'react';
import { TouchableOpacity, View } from 'react-native';

import { SVGLocalLoader } from '@ere-uilib/atoms';
import { createUseStyles, useScreenSizes, useTheme } from '@ere-uilib/styles';

import { LogoBnpProps } from './interfaces';
import { LogoColorModeEnum, LogoOrientationEnum } from './sharedInterfaces';
import { getStyles } from './styles';

export const LogoBnp: React.FC<React.PropsWithChildren<LogoBnpProps>> = ({
  containerStyle,
  onLogoPress,
  orientation,
  colorMode
}) => {
  const theme = useTheme();
  const { isMobile } = useScreenSizes();

  const styles = useStyles({ theme, isMobile }, { containerStyle });

  return (
    <View style={styles.containerStyle}>
      <TouchableOpacity 
      disabled={!onLogoPress} 
      onPress={onLogoPress}>
        {orientation === LogoOrientationEnum.column ? (
          <View style={styles.columnLogoContainerStyles}>
            <SVGLocalLoader
              height={70}
              name={colorMode === LogoColorModeEnum.dark ? 'logoVerticalDark' : 'logoVerticalLight'}
              width={183}
            />
          </View>
        ) : (
          <View style={styles.rowLogoContainerStyles}>
            <SVGLocalLoader
              name={
                colorMode === LogoColorModeEnum.dark ? 'logoHorizontalDark' : 'logoHorizontalLight'
              }
            />
          </View>
        )}
      </TouchableOpacity>
    </View>
  );
};

const useStyles = createUseStyles(getStyles);
