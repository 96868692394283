import React from 'react';
import {
  ScrollView,
  View,
  TouchableOpacity
} from 'react-native';

import { Text } from '@ere-uilib/atoms';
import { createUseStyles, useTheme, useScreenSizes } from '@ere-uilib/styles';

import { SavingsDistributionCardProps } from './interface';
import { getStyles } from './styles';

export const SavingsDistributionCard: React.FC<React.PropsWithChildren<SavingsDistributionCardProps>> =
  props => {
    const {
      repartitions,
      supportName,
      performanceValue,
      amount,
      addedValue,
      supportDetailsUrl,
      detailsLabel,
      green,
      containerStyle,
      scrollViewContainerStyle,
      innerContainerStyle,
      textContainerStyle,
      detailsButtonStyle
    } = props;
    const theme = useTheme();
    const { isMobile, isTablet } = useScreenSizes();
    const styles = useStyles(
      { theme, isMobile, isTablet },
      {
        scrollViewContainerStyle,
        containerStyle,
        innerContainerStyle,
        detailsButtonStyle
      },
    );

    return (
      <ScrollView
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        style={styles.scrollViewContainerStyle}
      >
        {repartitions &&
          repartitions.map((item: any, index: number) => {
            const riskLevelText =
              typeof item.riskLevel === 'number'
                ? `${item.riskLevel}/7`
                : item.riskLevel;
            return (
              <View style={styles.containerStyle}>
                <View
                  style={[
                    styles.innerContainerStyle,
                    { alignItems: 'flex-start' }
                  ]}
                >
                  <Text>{item.supportName.toUpperCase()}</Text>
                  <View style={styles.textContainerStyle}>
                    <Text>{riskLevelText}</Text>
                  </View>
                </View>
                <View style={styles.innerContainerStyle}>
                  {item.addedValue !== null && (
                    <View style={styles.textContainerStyle}>
                      <Text
                        style={{
                          color: item.addedValue < 0
                            ? theme.colors.text.disabled.c500
                            : theme.colors.text.primary.c500
                        }}
                      >
                        €
                      </Text>
                      <Text
                        style={{
                          color: item.addedValue < 0
                            ? theme.colors.text.disabled.c500
                            : theme.colors.text.primary.c500
                        }}
                      >
                        400
                      </Text>
                    </View>
                  )}
                  <TouchableOpacity
                    style={styles.detailsButtonStyle}
                  >
                    <Text>
                      details
                      {/* {item.detailsLabel} */}
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            );
          })}
      </ScrollView>
    );
  };

const useStyles = createUseStyles(getStyles);
