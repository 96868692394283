import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

import { useScreenSizes, useTheme } from '@ere-uilib/styles';

export function useStyles() {
  const theme = useTheme();
  const { isDesktop, isMobile } = useScreenSizes();

  const styles = useMemo(() => StyleSheet.create({
    containerStyle: {
      backgroundColor: theme.colors.basics.white,
      marginBottom: isDesktop ? theme.metrics.spacing.xl : 0,
      maxWidth: isDesktop ? "60%" : "100%",
      marginHorizontal: "auto"
    },
    fieldContainer: {
      alignItems: "stretch",
      marginTop: theme?.metrics.spacing.m,
    },
    bottomActionsStyle: {
      paddingHorizontal: isMobile ? theme?.metrics.spacing.s : 0,
      flexDirection: !isMobile ? 'row' : 'column',
      width: "100%",
      marginTop: isDesktop ? theme.metrics.spacing.xl : 0,
    },
    simpleButtonStyle: {
      marginLeft: theme.metrics.spacing.m,
      flex: isDesktop ? undefined : 1,
    },
    bottomActionsButtonsContainerStyle: {
      width: '100%',
      flexDirection: 'row',
    },
  }), [theme, isDesktop, isMobile]);

  return styles;
}