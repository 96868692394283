import { useMemo } from 'react';
import { StyleSheet } from 'react-native';

export function useStyles() {
  const styles = useMemo(() => StyleSheet.create({
    containerStyle: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
  }), []);

  return styles;
}
