import { connect } from 'react-redux';

import {
  setArbitrationDesinvestAmountModalVisibleState,
  selectArbitrationRepartitionSupport
} from '@modules/arbitration/actions/arbitrationActions';
import { getArbitrationDesinvestAmountModalVisibleState } from '@modules/arbitration/selectors';
import { AppState } from '@modules/index';

import { ArbitrationAmountFieldModalConnectedComponent } from './ArbitrationAmountFieldModalConnected.component';

const mapStateToProps = (state: AppState) => {
  return {
    visible: getArbitrationDesinvestAmountModalVisibleState(state),
  };
};

const mapDispatchToProps = {
  onSetArbitrationDesinvestAmountModalVisibleState: setArbitrationDesinvestAmountModalVisibleState,
  onSetArbitrationDesinvestAmount: selectArbitrationRepartitionSupport,
};

export const ArbitrationAmountFieldModalConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(ArbitrationAmountFieldModalConnectedComponent);
